/**
 * Created by bkanber on 7/17/17.
 */

import {fetchAuthenticated} from "../utilities";

export const REQUEST_WORKFLOWS = 'REQUEST_WORKFLOWS';
export const RECEIVE_WORKFLOWS = 'RECEIVE_WORKFLOWS';


export function requestWorkflows() {
    return {
        type: REQUEST_WORKFLOWS,
    };
}

export function receiveWorkflows(json) {
    return {
        type: RECEIVE_WORKFLOWS,
        workflows: json.data,
        receivedAt: Date.now()
    }
}

/**
 * @returns {Function}
 */
export function fetchWorkflows() {
    return function (dispatch) {
        dispatch(requestWorkflows())

        return fetchAuthenticated(`/manage/api/workflow`, {credentials: 'include'})
            .then(response => response.json())
            .then(json => dispatch(receiveWorkflows(json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

/**
 * @param state
 * @returns {*}
 */
export function shouldFetchWorkflows(state) {
    if (state.workflowsById.isFetching || state.workflowsById.lastUpdated) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchWorkflowsIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchWorkflows(getState())) {
            return dispatch(fetchWorkflows())
        }
    }
}
