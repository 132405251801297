import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from "../Common/Toolbox";
import Form from "../Common/Form/Form";
import Button from "../Common/Form/Button";
import _omit from 'lodash/omit';
import {getBadges} from "../../selectors/badges";
import {getSections} from "../../selectors/sections";
import {connect} from 'react-redux';
import {getTeams} from "../../selectors/teams";

class GodzillaPostsFiltersToolbox extends Component {

    static propTypes = {
        filters: PropTypes.object,
        onSave: PropTypes.func,
        onClose: PropTypes.func,
        onReset: PropTypes.func,
        badges: PropTypes.array,
        sections: PropTypes.array,
        teams: PropTypes.array
    };

    static defaultProps = {
        filters: { },
        badges: [],
        sections: [],
        teams: []
    };

    getWritten() {
        const {filters} = this.state;
        if (!filters['written_before'] && !filters['written_after']) {
            return null;
        }
        let written = {};
        if (filters['written_before']) {
            written.max = filters['written_before'];
        }
        if (filters['written_after']) {
            written.min = filters['written_after'];
        }
        return written;
    }

    getCleanFilters() {
        let filters = {...this.state.filters};

        const written = this.getWritten();
        if (written) {
            filters.written = written;
        }

        filters = _omit(filters, ['written_before', 'written_after']);

        // Don't let featured=false, just remove it to show all posts
        if (typeof filters.featured !== 'undefined' && filters.featured === false) {
            filters = _omit(filters, ['featured']);
        }

        console.log(filters);

        return filters;
    }

    synchronizeFilters() {
        this.setState({filters: {...this.props.filters}});
    }

    synchronizeFiltersIfNeeded(prevProps, prevState) {
        if (JSON.stringify(prevProps.filters) !== JSON.stringify(this.props.filters)) {
            this.synchronizeFilters();
        }

    }

    constructor(props) {
        super(props);
        this.state = {
            filters: {},
        };
        this.handleFieldChange = this.handleFieldChange.bind(this);
    }

    componentDidMount() {
        this.synchronizeFilters();
    }

    componentDidUpdate(prevProps, prevState) {
        this.synchronizeFiltersIfNeeded(prevProps, prevState);
    }

    handleFieldChange(name, value) {
        let cleanValue = value;
        if (cleanValue === '') cleanValue = null;
        this.setState({
            filters: {...this.state.filters, [name]: cleanValue}
        });
    }

    render() {
        return (
            <Toolbox
                style={{
                    top: 60,
                    right: 0
                }}
                key={'post-filters-popup'}
                onClose={this.props.onClose}
                title={'Filters'}
                content={<Form
                    values={this.state.filters}
                    onFieldChange={this.handleFieldChange}
                    usePadding={true}
                    fields={[
                        {
                            type: 'text',
                            placeholder: 'Title, Content, or Tags',
                            name: 'search',
                            title: "Quick Search",
                        },
                        this.props.teams.length > 0 ? {
                            type: 'select',
                            name: 'team',
                            title: "Team",
                            choices: [
                                {value: null, text: 'All Teams'},
                                ...this.props.teams.map(team => ({value: team.id, text: team.name}))
                            ]
                        } : null,
                        {
                            type: 'select',
                            name: 'status',
                            choices: [
                                {text: 'All Statuses', value: null},
                                {text: 'Published', value: 'published'},
                                {text: 'Submitted', value: 'submitted'},
                                {text: 'Not Published', value: 'notpublished'},
                                {text: 'Draft', value: 'draft'},
                            ],
                            options: {
                                defaultValue: "Post Status"
                            },
                            title: "Filter by Status",
                        },
                        {
                            type: 'select',
                            name: 'type',
                            choices: [
                                {text: 'All Types', value: null},
                                {text: 'Instagram', value: 'instagram'},
                                {text: 'TikTok', value: 'tiktok'},
                                {text: 'Youtube', value: 'youtube'},
                                {text: 'Pinterest', value: 'pinterest'},
                                {text: 'Facebook', value: 'facebook'},
                                {text: 'Twitter', value: 'twitter'},
                                {text: 'Post', value: 'post'},
                                {text: 'Draft', value: 'draft'},
                                {text: 'Video Upload', value: 'video'},
                            ],
                            title: "Filter by Post Type",
                        },
                        {
                            type: 'checkbox',
                            name: 'featured',
                            title: 'Featured',
                            options: {hideTitle: true},
                        },
                        {
                            type: 'range',
                            name: 'rating',
                            title: 'Rating',
                            options: {
                                min: 0,
                                max: 5,
                                step: 1,
                            }
                        },
                        {
                            type: 'text',
                            name: 'user',
                            placeholder: 'Name, Blog, or Email',
                            title: "Search by User",
                        },
                        {
                            type: 'text',
                            name: 'location',
                            placeholder: 'eg: New York, or France, or London',
                            title: "User Location",
                        },
                        this.props.badges.length > 0 ? {
                            type: 'select',
                            name: 'badge',
                            choices: [
                                {text: "Select a Badge", value: null},
                                ...this.props.badges.map(badge => {
                                    return {text: badge.name, value: badge.mongo_id};
                                })
                            ],
                            title: "User Badge",
                        } : null,
                        this.props.sections.length > 0 ? {
                            type: 'select',
                            name: 'sections',
                            choices: [
                                {text: "Select a Section", value: null},
                                ...this.props.sections.map(section => {
                                    return {text: section.name, value: section.mongo_id};
                                })
                            ],
                            title: "Section or Category",
                        } : null,
                        {
                            type: 'date',
                            name: 'written_before',
                            title: 'Written Before',
                            placeholder: 'Written Before',
                        },
                        {
                            type: 'date',
                            name: 'written_after',
                            title: 'Written After',
                            placeholder: 'Written After',
                        },
                        {
                            type: 'generic',
                            name: 'submitresetbtns',
                            content: (
                                <div className="clearfix">
                                    <Button
                                        classes={['v3', 'btn-primary', 'pull-left']}
                                        content={'Search'}
                                        onClick={() => this.props.onSave(this.getCleanFilters())}
                                    />
                                    <Button
                                        classes={['v3', 'btn-secondary', 'pull-right']}
                                        content={'Reset'}
                                        onClick={this.props.onReset}
                                    />
                                </div>
                            )
                        }

                    ].filter(f => !!f)}
                />
                }
            />

        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        badges: getBadges(state, props),
        sections: getSections(state, props),
        teams: getTeams(state, props),
    };
};

export default connect(mapStateToProps)(GodzillaPostsFiltersToolbox);
