/**
 * Created by bkanber on 6/29/17.
 */

import {
    REQUEST_NETWORKS, RECEIVE_NETWORKS
} from '../actions';

import {indexArrayById} from '../utilities';

export function networksById(state = {lastUpdated: null, isFetching: false, items: {}},
                             action) {

    switch (action.type) {

        case REQUEST_NETWORKS:
            return {...state, isFetching: true};
            break;

        case RECEIVE_NETWORKS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.receivedAt,
                items: {...state.items, ...indexArrayById(action.networks)}
            };
            break;

        default:
            return state;
    }

}
