/**
 * Library imports
 */
import 'babel-polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import { Provider } from 'react-redux'
import configureStore from './configureStore'


/**
 * Component imports
 */
import RepImagesProfileTool from './components/Test/RepImagesProfileTool';
import EMVAnalysisTool from './components/Test/EMVAnalysisTool';
import ConnectedInfluencerRequirements from './containers/Influencer/InfluencerRequirements';
import {fetchPresence, fetchPresenceIfNeeded} from "./actions/presence";
import HBInfluencerLeaderboard from './components/Test/HBInfluencerLeaderboard';
import HBInfluencerWelcome from './components/Test/HBInfluencerWelcome';
import AnalyzeInstagramBusiness from "./components/Test/AnalyzeInstagramBusiness";
import InstagramBusinessHashtagSearch from "./components/Test/InstagramBusinessHashtagSearch";
import ConnectedEditProfile from './components/Influencer/EditProfile/EditProfile';
import ConnectedContributeDashHomepage from './components/Influencer/Dashboard/Homepage';
import ResponsiveEditProfile from "./components/Influencer/EditProfile/ResponsiveEditProfile";
import LangSwitcherNavButton from "./components/Common/LangSwitcherNavButton";
import {fetchI18n, updateLanguage} from "./actions/i18n";
import ContributeAudienceAnalyticsStripe
    from "./components/Influencer/Dashboard/Partials/ContributeAudienceAnalyticsStripe";
import MACCreatorDashboard from "./components/Pages/MAC/Creator/Dashboard";
import MACCreatorTopNav from "./components/MAC/Creator/TopNav";
import MACRoutes from "./routes/MAC";
import PublicCampaignJoiner from "./components/Influencer/Requirements/PublicCampaignJoiner";
import PublicListJoiner from "./components/Influencer/Requirements/PublicListJoiner";

/**
 * Create the redux store
 * @type {Store<S>}
 */
let store = configureStore();

/**
 * Bootstrap state.
 */
(function($) {
    $(document).ready(function() {

        const savedLanguage = window.localStorage.getItem('tidal-lang');
        if (savedLanguage) {
            store.dispatch(updateLanguage(savedLanguage));
        }

        store.dispatch(fetchPresence())
            .then((action) => {
                const presence = action.presence || {};
                const channel = presence.channel || {};
                const isI18nEnabled = channel.enable_i18n || false;
                if (isI18nEnabled) {
                    store.dispatch(fetchI18n());
                }
            });


    });
})(jQuery);

/**
 * Influencer 'join list' landing page (ie, for Bobbi Brown)
 */
(function($) {

    let $doc = $(document);
    let init = () => {
        let $containers = $doc.find('.tidal-list-joiner:not(.react-did-init)');
        $containers.each(function() {
            let listId;

            let $container = $(this);
            $container.addClass('react-did-init');

            if (typeof $container.data('list') !== 'undefined') {
                listId = $container.data('list');
            }

            ReactDOM.render(
                <Provider store={store}>
                    <PublicListJoiner listId={listId} />
                </Provider> ,
                $container.get(0)
            );

        });

    }

    $doc.ready(init);


})(jQuery);
/**
 * Influencer 'join campaign' landing page (ie, for MSC)
 */
(function($) {

    let $doc = $(document);
    let init = () => {
        let $containers = $doc.find('.tidal-campaign-joiner:not(.react-did-init)');
        $containers.each(function() {
            let campaignId;

            let $container = $(this);
            $container.addClass('react-did-init');

            if (typeof $container.data('campaign') !== 'undefined') {
                campaignId = $container.data('campaign');
            }

            ReactDOM.render(
                <Provider store={store}>
                    <PublicCampaignJoiner campaignId={campaignId} />
                </Provider> ,
                $container.get(0)
            );

        });

    }

    $doc.ready(init);


})(jQuery);
/**
 * Influencer Requirements Page
 */
(function($) {

    let $doc = $(document);

    let init = () => {

        let $containers = $doc.find('.react-influencer-requirements:not(.react-did-init)');

        $containers.each(function() {
            let activationId, campaignId;

            let $container = $(this);
            $container.addClass('react-did-init');

            if (typeof $container.data('activation-id') !== 'undefined') {
                activationId = $container.data('activation-id');
            }

            if (typeof $container.data('campaign-id') !== 'undefined') {
                campaignId = $container.data('campaign-id');
            }

            ReactDOM.render(
                <Provider store={store}>
                    <ConnectedInfluencerRequirements activationId={activationId} campaignId={campaignId} />
                </Provider> ,
                $container.get(0)
            );

        });

    }

    $doc.ready(init);


})(jQuery);

/**
 * EMV analysis tool
 */
(function($) {

    let $doc = $(document);

    let init = () => {

        let $containers = $doc.find('.react-influencers-emv-analysis:not(.react-did-init)');

        $containers.each(function() {
            let $container = $(this);
            $container.addClass('react-did-init');

            ReactDOM.render(
                <EMVAnalysisTool />,
                $container.get(0)
            );

        });

    };

    $doc.ready(init);

})(jQuery);

/**
 * Rep images tool (facebook application/test)
 */
(function($) {

    let $doc = $(document);

    let init = () => {

        let $containers = $doc.find('.react-rep-posts-test:not(.react-did-init)');

        $containers.each(function() {
            let $container = $(this);
            $container.addClass('react-did-init');

            ReactDOM.render(
                <RepImagesProfileTool />,
                $container.get(0)
            );

        });

    };

    $doc.ready(init);
    window.Gator.addEventListener('click-profile-tab', init);

})(jQuery);

/**
 * H&B Influencer Leaderboard
 */
(function($) {

    let $doc = $(document);

    let init = () => {

        let $containers = $doc.find('.hb-infl-leaderboard-wrap:not(.react-did-init)');

        $containers.each(function() {
            let $container = $(this);
            $container.addClass('react-did-init');
            const listenerId = $container.data('listener-id');

            ReactDOM.render(
                <Provider store={store}>
                    <HBInfluencerLeaderboard listenerId={listenerId} />
                </Provider> ,
                $container.get(0)
            );

        });

    };

    $doc.ready(init);

})(jQuery);

/**
 * H&B Influencer Welcome screens
 */
(function($) {

    let $doc = $(document);

    let init = () => {

        let $containers = $doc.find('.hb-infl-welcome-wrap:not(.react-did-init)');

        $containers.each(function() {
            let $container = $(this);
            $container.addClass('react-did-init');

            ReactDOM.render(
                <Provider store={store}>
                    <HBInfluencerWelcome />
                </Provider> ,
                $container.get(0)
            );

        });

    };

    $doc.ready(init);

})(jQuery);

/**
 * Audience Explorer
 */
(function($) {

    let $doc = $(document);

    let init = () => {

        let $containers = $doc.find('.react-contribute-audience-stripe:not(.react-did-init)');

        $containers.each(function() {
            let $container = $(this);
            $container.addClass('react-did-init');

            ReactDOM.render(
                <Provider store={store}>
                    <ContributeAudienceAnalyticsStripe />
                </Provider> ,
                $container.get(0)
            );

        });

    };

    $doc.ready(init);

})(jQuery);

/**
 * Instagram business analyzer
 */
(function($) {

    let $doc = $(document);

    let init = () => {

        let $containers = $doc.find('.react-ig-business-analyzer:not(.react-did-init)');

        $containers.each(function() {
            let $container = $(this);
            $container.addClass('react-did-init');

            ReactDOM.render(
                <Provider store={store}>
                    <AnalyzeInstagramBusiness />
                </Provider> ,
                $container.get(0)
            );

        });

    };

    $doc.ready(init);

})(jQuery);

/**
 * Instagram hashtag search
 */
(function($) {

    let $doc = $(document);

    let init = () => {

        let $containers = $doc.find('.react-ig-business-hashtag-search:not(.react-did-init)');

        $containers.each(function() {
            let $container = $(this);
            $container.addClass('react-did-init');

            ReactDOM.render(
                <Provider store={store}>
                    <InstagramBusinessHashtagSearch />
                </Provider> ,
                $container.get(0)
            );

        });

    };

    $doc.ready(init);

})(jQuery);


/**
 * React EditProfile
 */
(function($) {

    let $doc = $(document);

    let init = () => {

        let $containers = $doc.find('.react-edit-profile:not(.react-did-init)');

        $containers.each(function() {
            let userId;

            let $container = $(this);
            $container.addClass('react-did-init');

            userId = $container.data('user-id');

            ReactDOM.render(
                <Provider store={store}>
                    <ResponsiveEditProfile userId={userId} padding={true} />
                </Provider> ,
                $container.get(0)
            );

        });

    };

    $doc.ready(init);


})(jQuery);


/**
 * React Contribute Dash Homepage
 */
(function($) {

    let $doc = $(document);

    let init = () => {

        let $containers = $doc.find('.react-contribute-dash-homev2:not(.react-did-init)');

        $containers.each(function() {
            let userId;

            let $container = $(this);
            $container.addClass('react-did-init');

            userId = $container.data('user-id');

            ReactDOM.render(
                <Provider store={store}>
                    <ConnectedContributeDashHomepage userId={userId} />
                </Provider> ,
                $container.get(0)
            );

        });

    };

    $doc.ready(init);


})(jQuery);

/**
 * React language switcher component
 */
(function($) {

    let $doc = $(document);

    let init = () => {

        let $containers = $doc.find('.react-lang-switcher:not(.react-did-init)');

        $containers.each(function() {
            let $container = $(this);
            $container.addClass('react-did-init');

            ReactDOM.render(
                <Provider store={store}>
                    <LangSwitcherNavButton />
                </Provider> ,
                $container.get(0)
            );

        });

    };

    $doc.ready(init);


})(jQuery);


/**
 * Custom MAC Creator dash
 */
(function ($) {
    console.log("Custom MAC IIFE");

    let init = () => {
        const container = document.getElementById('mac-creator-dash');
        if (container) {
            console.log("MAC dashboard container found, rendering");
            // Topnav render
            const navContainer = document.getElementById('tidal-header');
            if (navContainer) {
                ReactDOM.render(<Provider store={store}><MACCreatorTopNav/></Provider>, navContainer);
            }

            // Main body render
            const dataChannel = container.dataset.channel;
            ReactDOM.render(
                <Provider store={store}>
                    <MACRoutes theme={dataChannel} />
                </Provider> ,
                container
            );

        }

    };

    $(document).ready(init);

})(jQuery);