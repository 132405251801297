import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BasicCell from './Basic';
import Text from '../../Common/Form/Text';

export default class EditableTextCell extends Component {

    static defaultProps = {
        isSaving: false,
    };

    static propTypes = {
        displayValue: PropTypes.any,
        value: PropTypes.any.isRequired,
        row: PropTypes.object.isRequired,
        column: PropTypes.object.isRequired,
        style: PropTypes.object,
        onEditStart: PropTypes.func,
        onSave: PropTypes.func,
        onCancel: PropTypes.func,
        placeholder: PropTypes.string,
        isSaving: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            value: props.value,
        };
        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    toggleEditing() {
        const isEditing = !this.state.isEditing;
        this.setState({isEditing});
    }

    handleCancel() {
        this.setState({isEditing: false});
        if (this.props.onCancel) {
            this.props.onCancel(this.state.value);
        }
    }

    handleSave() {
        this.setState({isEditing: false});

        if (this.props.onSave) {
            this.props.onSave(this.state.value);
        }

    }

    renderEditIcon() {
        let iconClass = this.props.isSaving ? 'fa fa-spinner fa-spin' : 'fa fa-pencil';
        return <a
            className="dark edit"
            onClick={this.toggleEditing}
            role="button"><i className={iconClass} /></a>
    }

    renderSaveIcon() {
        return <a
            className="dark save"
            onClick={this.handleSave}
            role="button">Save</a>
    }

    renderCancelIcon() {
        return <a
            className="dark cancel"
            onClick={this.handleCancel}
            role="button"><i className="fa fa-ban" /></a>
    }

    getIcons() {
        if (this.state.isEditing) {

            return [
                this.renderCancelIcon(),
                this.renderSaveIcon()
            ];
        } else {
            return [
                this.renderEditIcon()
            ];
        }
    }

    renderIcons() {
        return <div className="editable-cell-icons">{this.getIcons()}</div>
    }

    getDisplayValue() {
        return this.props.displayValue ? this.props.displayValue : this.props.value;
    }

    getEditor() {
        return <Text
            classes={['editable-cell-text-input']}
            value={this.state.value}
            placeholder={this.props.placeholder}
            onChange={event => this.setState({value: event.target.value})}
        />

    }

    getContent() {

        if (this.state.isEditing) {
            return this.getEditor();
        }

        return this.getDisplayValue();

    }

    getWrapper() {
        let classes = ['editable-cell-wrapper', 'tidal-form', 'nopadding'];
        if (this.state.isEditing) {
            classes.push('editing');
        }

        return <div
            className={classes.join(' ')}
        >
            {this.getContent()}
            {this.renderIcons()}
        </div>
    }

    render() {
        return (
            <BasicCell
                classes={[this.state.isEditing ? 'nopadding' : '']}
                row={this.props.row}
                column={this.props.column}
                style={this.props.style}
                value={this.getWrapper()}
            />

        );
    }
}
