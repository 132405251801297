import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import PostsHeader from './Header';
import PostsPagination from './Pagination';
import { ConnectedPostCard } from '../../containers/Post/ConnectedPostCard'
import Filters from './Filters';
import Table from './Table/Table';

export default class Posts extends Component {
    constructor(props) {
        super(props);

        this.handleApplyAction = this.handleApplyAction.bind(this);
        this.handleFetchPosts = this.handleFetchPosts.bind(this);
        this.handleResetFilters = this.handleResetFilters.bind(this);
        this.handleSelectAllPosts = this.handleSelectAllPosts.bind(this);
        this.handleChangePostView = this.handleChangePostView.bind(this);
        this.handleChangeSort = this.handleChangeSort.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.handleChangeLimit = this.handleChangeLimit.bind(this);
        this.handleRemoveFilter = this.handleRemoveFilter.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);

        this.state = {
            currentView: 'medium',
        }
    }

    componentDidMount() {

        this.props.fetchBadgesIfNeeded();
        let needsDelay = false;

        if (this.props.filters && Object.keys(this.props.filters).length > 0) {
            for (const key in this.props.filters) {
                const val = this.props.filters[key];
                this.handleChangeFilter(key, val, false);
                needsDelay = true;
            }
        }

        if (this.props.limit) {
            this.handleChangeLimit(this.props.limit);
            needsDelay = true;
        }

        if (this.props.sort) {
            this.handleChangeSort(this.props.sort);
            needsDelay = true;
        }

        if (needsDelay) {
            setTimeout(() => this.handleFetchPosts(), 10);
        } else {
            this.handleFetchPosts();
        }

    }

    componentDidUpdate(){
        this.props.postQuery.needsFetching && this.handleFetchPosts();
    }

    handleApplyAction(action) {
        if(this.props.postQuery.selectedPostIds.length > 0) {
            this.props.applyAction(this.props.postQuery.selectedPostIds, action)
        }
    }

    handleFetchPosts() {
        const { filters, sort, page, limit } =  this.props.postQuery;
        this.props.fetchPosts(filters, sort, page, limit, this.props.queryId);
    }

    handleResetFilters() {
        this.props.changePage(1, this.props.queryId);
        this.props.resetFilters(this.props.queryId);
    }

    handleChangeSort(sort, fetchPosts) {
        this.props.changePage(1, this.props.queryId);
        this.props.changeSort(sort, fetchPosts, this.props.queryId);
    }

    handleChangeFilter(key, value, fetchPosts) {
        this.props.changePage(1, this.props.queryId);
        this.props.changeFilter(key, value, fetchPosts, this.props.queryId);
    }

    handleRemoveFilter(key, fetchPosts) {
        this.props.changePage(1, this.props.queryId);
        this.props.removeFilter(key, fetchPosts, this.props.queryId);
    }

    handleChangePage(page) {
        this.scrollToTop();
        this.props.changePage(page, this.props.queryId);
    }

    handleChangeLimit(limit, fetchPosts){
        this.props.changePage(1, this.props.queryId);
        this.props.changeLimit(limit, fetchPosts, this.props.queryId);
    }

    handleSelectAllPosts() {
        if(this.props.postQuery.allPostsSelected) {
            this.props.unselectAllPosts(this.props.postQuery.postIds, this.props.queryId);
        } else {
            this.props.selectAllPosts(this.props.postQuery.postIds, this.props.queryId);
        }
    }

    scrollToTop() {

        // Scroll to top of the search container
        const boundingRect = ReactDOM.findDOMNode(this).getBoundingClientRect();
        const currentScroll = window.scrollY;

        if (boundingRect && typeof boundingRect.top !== 'undefined') {
            window.scrollTo(0, boundingRect.top + currentScroll);
        }

    }
    handleChangePostView(newView) {
        /*  Table Views not yet implemented so don't allow it to change to them yet.
        const acceptableViews = ['small', 'medium', 'large', 'table', 'expanded-table'];
         */
        const acceptableViews = ['small', 'medium', 'large', 'table', 'expanded-table'];
        if(acceptableViews.indexOf(newView) != -1) {
            this.setState({
                currentView: newView,
            });
        }
    }

    renderHeader() {

        if (this.props.showHeader === false) {
            return null;
        }

        return(
            <PostsHeader
                campaigns={this.props.campaigns}
                changeFilter={this.handleChangeFilter}
                removeFilter={this.handleRemoveFilter}
                changeLimit={this.handleChangeLimit}
                resetFilters={this.handleResetFilters}
                toggleShowFilters={this.handleToggleShowFilters}
                changeSort={this.handleChangeSort}
                changeView={this.handleChangePostView}
                selectAll={this.handleSelectAllPosts}
                postQuery={this.props.postQuery}
                badges={this.props.badges}
                currentCardView={this.state.currentView}
                applyAction={this.handleApplyAction}
                fetchPosts={this.handleFetchPosts}
                disableCampaignField={this.props.disableCampaignField}
            />
        )
    }

    renderPostTable() {
        return(
            <Table
                changeSort={this.handleChangeSort}
                sort={this.props.postQuery.sort}
                postIds={this.props.postQuery.postIds}
                queryId={this.props.queryId}
                currentView={this.state.currentView}
                isFetching={this.props.postQuery.isFetching}
            />
        )
    }

    renderPostCards() {
        const className = this.props.postQuery.isFetching ? 'post-cards-wrapper blurred' : 'post-cards-wrapper';
        return (
            <div className={className}> {
                this.props.postQuery.postIds.map(postId => {
                    return (
                        <ConnectedPostCard
                            postId={postId}
                            size={this.state.currentView}
                            queryId={this.props.queryId}
                        />
                    )
                })
            }
            </div>)
    }

    renderPosts() {
        if (this.state.currentView === 'table' || this.state.currentView === 'expanded-table') {
            return this.renderPostTable();
        } else {
            return this.renderPostCards();
        }
    }

    renderPagination() {

        if (this.props.showPagination === false) {
            return null;
        }

        if(this.props.postsLoaded) {
            /**
             * This page logic handles the situation of resetting to page 1
             * prematurely when updating filters (but before hitting Search).
             */
            let page = this.props.postQuery.page;
            if (this.props.postQuery.meta && this.props.postQuery.meta.page) {
                page = this.props.postQuery.meta.page;
            }
           return (
               <PostsPagination
                   page={page}
                   hits={this.props.postQuery.meta.hits}
                   limit={this.props.postQuery.limit}
                   changePage ={this.handleChangePage}
                   fetchPosts={this.handleFetchPosts}
               />
           )
        }
    }

    render() {

        const {posts, postQuery} = this.props;

        if (posts.length === 0) {
            if (postQuery.isFetching) {
                return(
                    <div className={'posts-wrapper'}>
                        <div className="tidal-alert alert alert-grey">Loading posts...</div>
                    </div>
                );
            } else {
                return(
                    <div className={'posts-wrapper'}>
                        <div className="tidal-alert alert alert-grey">No posts found...</div>
                    </div>
                );
            }
        }

        return(
            <div className={'posts-wrapper'}>
                { this.renderHeader() }
                { this.renderPosts() }
                { this.renderPagination() }
            </div>
        )

    }
}

Posts.propTypes = {
    changeFilter: PropTypes.func,
    removeFilter: PropTypes.func,
    changeSort: PropTypes.func,
    changePage: PropTypes.func,
    changeLimit: PropTypes.func,
    resetFilters: PropTypes.func,
    selectAllPosts: PropTypes.func,
    unselectAllPosts: PropTypes.func,
    applyAction: PropTypes.func,
    badges: PropTypes.object,
    campaigns: PropTypes.object,

    fetchPosts: PropTypes.func,
    posts: PropTypes.object,
    postsLoaded: PropTypes.bool,
    queryId: PropTypes.number,
    postQuery: PropTypes.object,

    showHeader: PropTypes.bool,
    showPagination: PropTypes.bool,
    disableCampaignField: PropTypes.bool,

    filters: PropTypes.object,
    sort: PropTypes.object,
    limit: PropTypes.number
};


Posts.defaultProps = {
    changeFilter: () => {},
    removeFilter: () => {},
    changeSort: () => {},
    changePage: () => {},
    changeLimit: () => {},
    selectAllPosts: () => {},
    unselectAllPosts: () => {},
    applyAction: () => {},

    fetchPosts: () => {},
    posts: {},
    postsLoaded: false,

    showHeader: true,
    showPagination: true,
    disableCampaignField: false,
    filters: {}
};
