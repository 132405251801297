/**
 * Created by bkroger on 8/17/18.
 */

import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import {makeMomentFromDate} from "../../../utilities";


export default class DateRange extends Component {

    constructor (props) {
        super(props);

        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.getStartDate = this.getStartDate.bind(this);
        this.getEndDate = this.getEndDate.bind(this);
    }

    handleChangeDate(date, which) {
        let originalValue = {...this.props.value};

        if (!originalValue) originalValue = {};

        if (date) {
            if (which === 'min') {
                originalValue.min = date.toISOString();
            } else if (which === 'max') {
                originalValue.max = date.toISOString();
            }
        } else {
            delete originalValue[which];
        }

        if (!originalValue.min && !originalValue.max) {
            originalValue = null;
        }

        if (this.props.onChange) {
            this.props.onChange(originalValue)
        }
    }

    getStartDate() {
        if (!this.props.value) return null;
        if (!this.props.value.min) return null;
        if (typeof this.props.value.min.isValid !== 'undefined') {
            // have a moment already
            return this.props.value.min;
        }

        // Have a serialized Carbon object.
        if (typeof this.props.value.min === 'object' && typeof this.props.value.min.date !== 'undefined') {
            return makeMomentFromDate(this.props.value.min);
        }

        if (typeof this.props.value.min === 'string') {
            let dateObject = moment(this.props.value.min);
            if (dateObject.isValid()) {
                return dateObject;
            } else {
                return null;
            }
        }
        return null;
    }

    getEndDate() {
        if (!this.props.value) return null;
        if (!this.props.value.max) return null;
        if (typeof this.props.value.max.isValid !== 'undefined') {
            // have a moment already
            return this.props.value.max;
        }

        // Have a serialized Carbon object.
        if (typeof this.props.value.max === 'object' && typeof this.props.value.max.date !== 'undefined') {
            return makeMomentFromDate(this.props.value.max);
        }

        if (typeof this.props.value.max === 'string') {
            let dateObject = moment(this.props.value.max);
            if (dateObject.isValid()) {
                return dateObject;
            } else {
                return null;
            }
        }
        return null;
    }


    render() {

        return(
            <div className="date-range-wrapper">
                <DatePicker
                    className="form-control date-range-min"
                    selected={this.getStartDate()}
                    selectsStart
                    startDate={this.getStartDate()}
                    endDate={this.getEndDate()}
                    onChange={(date) => this.handleChangeDate(date, 'min')}
                    placeholderText={this.props.placeholder}
                    fixedHeight
                    startOpen={this.props.startOpen}
                    inline={this.props.inline}
                />
                <DatePicker
                    className="form-control date-range-max"
                    selected={this.getEndDate()}
                    selectsEnd
                    startDate={this.getStartDate()}
                    endDate={this.getEndDate()}
                    onChange={(date) => this.handleChangeDate(date, 'max')}
                    placeholderText={this.props.placeholder}
                    fixedHeight
                    startOpen={this.props.startOpen}
                    inline={this.props.inline}
                />
            </div>
        )

    }

}

DateRange.defaultProps = {
    placeholder: "MM/DD/YYYY",
    startOpen: false,
    inline: false,
};

DateRange.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    startOpen: PropTypes.bool,
    inline: PropTypes.bool
};