import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GenericSettingsForm from "../Common/GenericSettingsForm";
import {fetchAuthenticated} from "../../actions/auth";
import {connect} from 'react-redux';
import {createListInteraction} from "../../actions";
import Alert from "../Common/Alert";
import AudienceStatsSettingsTable from "./AudienceStatsSettingsTable";
import * as URI from "urijs";

class SettingsForm extends Component {

    static propTypes = {
        listId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        fetchAuthenticated: PropTypes.func,
        createListInteraction: PropTypes.func,
        beta: PropTypes.bool,
    };

    static defaultProps = {
        beta: false,
    };

    state = {
        isDirty: false,
        isSaving: false,
        didSave: false,
        isFetchingSettings: false,
        settings: {},
        geo_suggestions: [],
    };

    componentDidMount() {
        this.fetchSettings();
    }

    fetchSettings() {
        this.setState({isFetchingSettings: true});
        const url = `/manage/api/userlist/${this.props.listId}/setting`;
        this.props.fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => {
                this.setState({
                    settings: json.data,
                    isFetchingSettings: false,
                    isDirty: false,
                });
            });

    }

    async fetchGeoSuggestions(input) {

        const slot = window.Gator.getDashboardSlot();
        const uri = new URI(`/${slot}/api/socialdata/_/geo`);
        uri.search({q: input});
        const resp = await this.props.fetchAuthenticated(uri.toString());
        const json = await resp.json();
        this.setState({
            geo_suggestions: json.data
        });
    }

    handleSave(form) {
        const combinedSettings = {...this.state.settings, ...form};
        this.setState({isDirty: false});
        return this.props.createListInteraction('UpdateSettings', combinedSettings)
            .then(() => this.fetchSettings());
    }

    handleFieldChange(key, value, callback) {
        this.setState({
            isDirty: true,
            settings: {
                ...this.state.settings,
                [key]: value,
            }
        }, callback)
    }

    getOneSheetSettingsPane() {
        const {show_audience} = this.state.settings;
        return {
            title: 'One-Sheet Settings',
            key: 'one-sheet-settings',
            fields: [

                {
                    type: 'checkbox',
                    name: 'show_portfolio_images',
                    title: 'Show Portfolio Images',
                },
                {
                    type: 'checkbox',
                    name: 'show_top_posts',
                    title: 'Show Top Posts',
                },
                {
                    type: 'checkbox',
                    name: 'show_age',
                    title: 'Show Creator Age',
                },
                {
                    type: 'checkbox',
                    name: 'show_audience',
                    title: 'Show Audience Data',
                },
                show_audience ? {
                    type: 'generic',
                    content: <AudienceStatsSettingsTable
                        settings={this.state.settings}
                        onChange={this.handleFieldChange.bind(this)}
                    />
                } : null,
                {
                    type: 'color',
                    name: 'onesheet_gradient_color_start',
                    title: <span>Background Gradient Color #1 - <a role="button" onClick={() => this.handleSave({onesheet_gradient_color_start: null})}>Remove</a></span>,
                    options: {defaultValue: '#66CC00'}
                },
                {
                    type: 'color',
                    name: 'onesheet_gradient_color_stop',
                    title: <span>Background Gradient Color #2 - <a role="button" onClick={() => this.handleSave({onesheet_gradient_color_stop: null})}>Remove</a></span>,
                    options: {defaultValue: '#66CC00'}
                },
                {
                    type: 'color',
                    name: 'onesheet_gradient_text_color',
                    title: <span>Gradient Text Color Overlay - <a role="button" onClick={() => this.handleSave({onesheet_gradient_text_color: null})}>Remove</a></span>,
                    options: {defaultValue: '#FFFFFF'}
                },
                {
                    type: 'color',
                    name: 'onesheet_chart_color_start',
                    title: <span>Charts Custom Color #1 - <a role="button" onClick={() => this.handleSave({onesheet_chart_color_start: null})}>Remove</a></span>,
                    options: {defaultValue: '#B9ED91'}
                },
                {
                    type: 'color',
                    name: 'onesheet_chart_color_stop',
                    title: <span>Charts Custom Color #2 - <a role="button" onClick={() => this.handleSave({onesheet_chart_color_stop: null})}>Remove</a></span>,
                    options: {defaultValue: '#206601'}
                },
                {
                    type: 'tags-autocomplete',
                    name: 'highlight_locations',
                    title: 'Highlight Locations',
                    placeholder: 'Start typing location...',
                    help: "Choose some locations to highlight or float to the top of the one-sheet audience charts.",
                    autocomplete: {
                        suggestions: this.state.geo_suggestions,
                        handleInputChange: (input) => {
                            if (input.length > 2) {
                                this.fetchGeoSuggestions(input);
                            }
                        }
                    }
                },

            ]
        };
    }
    getPublicListSettings() {
        const {
            is_shared,
            is_password_protected,
            public_password,
            public_shortlink,
            show_public_custom_intro,
            show_predicted_performance,
            public_accent_color,
            public_header_color
        } = this.state.settings;

        if (!is_shared) {
            return [];
        }

        const innerGroupStyle = {padding: 15, background: '#fafafa', marginBottom: 0};
        return [
            {
                type: 'checkbox',
                name: 'is_password_protected',
                title: 'Password Protect Public List'
            },
            (is_password_protected) ? {
                type: 'text',
                name: 'public_password',
                title: 'Set Password for Public List'
            } : null,
            {
                type: 'select',
                name: 'layout',
                title: 'Public List Layout',
                choices: [
                    {text: 'Table Layout', value: 'table'},
                    {text: 'One-Sheet Layout', value: 'influencer'},
                ],
                options: {defaultValue: 'Table Layout'}
            },
            {
                type: 'image',
                name: 'public_brand_logo',
                title: 'Custom Brand Logo'
            },
            {
                type: 'checkbox',
                name: 'show_public_custom_intro',
                title: 'Show Overview Message',
            },
            show_public_custom_intro ? {
                type: 'textarea',
                name: 'public_custom_intro',
                title: 'Overview Message',
                help: 'This will be displayed at the top of the page; use it to introduce the list or provide instructions for ratings and notes.'
            } : null,
            {
                type: 'checkbox',
                name: 'show_predicted_performance',
                title: 'Show Predicted Campaign Performance'
            },
            show_predicted_performance ? {
                type: 'number',
                name: 'predict_performance_budget',
                title: 'Campaign Budget for Performance Prediction',
                options: {groupStyle: innerGroupStyle}
            } : null,
            show_predicted_performance ? {
                type: 'number',
                name: 'predict_performance_num_blog',
                title: 'Number of Blog Posts',
                options: {groupStyle: innerGroupStyle}
            } : null,
            show_predicted_performance ? {
                type: 'number',
                name: 'predict_performance_num_instagram',
                title: 'Number of Instagram Posts',
                options: {groupStyle: innerGroupStyle}
            } : null,
            show_predicted_performance ? {
                type: 'number',
                name: 'predict_performance_num_facebook',
                title: 'Number of Facebook Posts',
                options: {groupStyle: innerGroupStyle}
            } : null,
            show_predicted_performance ? {
                type: 'number',
                name: 'predict_performance_num_twitter',
                title: 'Number of Tweets',
                options: {groupStyle: innerGroupStyle}
            } : null,
            {
                type: 'color',
                name: 'public_accent_color',
                title: <span>Custom Accent Color - <a role="button" onClick={() => this.handleSave({public_accent_color: null})}>Remove</a></span>,
                options: {defaultValue: '#66CC00'}
            },
            {
                type: 'color',
                name: 'public_header_color',
                title: <span>Custom Headerbar Color - <a role="button" onClick={() => this.handleSave({public_header_color: null})}>Remove</a></span>,
                options: {defaultValue: '#111111'}
            },
        ].filter(f => !!f);
    }
    render() {

        if (this.state.isFetchingSettings) {
            return (
                <Alert classes={['info']} content={'Loading settings, please wait...'} />
            );
        }

        const {beta} = this.props;
        const {is_shared, public_shortlink, layout} = this.state.settings;

        return (
            <GenericSettingsForm
                isDirty={this.state.isDirty}
                values={this.state.settings}
                onSave={this.handleSave.bind(this)}
                onFieldChange={this.handleFieldChange.bind(this)}
                spec={[
                    {
                        title: "Basic Settings",
                        key: 'basics',
                        fields: [
                            {
                                type: 'text',
                                name: 'name',
                                title: 'List Name'
                            },
                            {
                                type: 'checkbox',
                                name: 'is_hidden',
                                title: 'Archive List'
                            },
                        ].filter(f => !!f)
                    },
                    {
                        title: 'Sharing Settings',
                        key: 'sharing-settings',
                        fields: [
                            (is_shared && public_shortlink) ? {
                                type: 'generic',
                                content: (
                                    <Alert classes={['success']}
                                           content={"Your list is now public! View or share the link: " + public_shortlink}
                                    />
                                )

                            } : null,
                            (is_shared && public_shortlink) ? {
                                type: 'text',
                                name: 'public_shortlink',
                                title: 'Public Link',
                                readOnly: true,
                                help: 'Copy and send this link to your intended recipients.'
                            } : null,
                            {
                                type: 'checkbox',
                                name: 'is_shared',
                                title: 'Share List Publicly'
                            },
                            ...this.getPublicListSettings(),
                            {
                                type: 'generic',
                                content: <div style={{height: 100}} />
                            }
                        ].filter(f => !!f)
                    },
                    (layout === 'influencer') ? this.getOneSheetSettingsPane() : null,
                    beta ? {
                        title: "Invitation Settings",
                        key: 'invite-copy',
                        fields: [
                            {
                                type: 'text',
                                name: 'email-invite-to-channel-subject',
                                title: 'Invite To Influencer Hub Email Subject'
                            },
                            {
                                type: 'wysiwyg',
                                name: 'email-invite-to-channel-body',
                                title: 'Invite to Influencer Hub Email Body',
                            },
                            {
                                type: 'text',
                                name: 'email-invite-to-campaign-subject',
                                title: 'Invite To Campaign Email Subject'
                            },
                            {
                                type: 'wysiwyg',
                                name: 'email-invite-to-campaign-body',
                                title: 'Invite to Campaign Email Body',
                            },
                        ]
                    } : null

                ].filter(s => !!s)}
            />
        );
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
        createListInteraction: (type, ctx) => dispatch(createListInteraction(props.listId, type, ctx)),
    };
};

export default connect(undefined, mapDispatchToProps)(SettingsForm);
