/**
 * Created by bkroger on 6/19/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class TimelineMessage extends Component {

    static defaultProps = {
        layout: 'left',
    };

    static propTypes = {
        layout: PropTypes.string,
        timestamp: PropTypes.string,
        /** Add className="v3 h7 light" */
        link: PropTypes.node,
        message: PropTypes.oneOf([
            PropTypes.string,
            PropTypes.node
        ]),
    };

    renderFooter() {
        const {timestamp, link} = this.props;

        return (
            <div className="timeline-message-bottom">
                {timestamp && <span className="v3 h7 light">{timestamp}</span> }
                {link ? link : null}
            </div>
        );
    }

    renderMessage() {
        const {message} = this.props;
        let content;

        if (typeof message === 'string') {
            content = <p className="v3 light">{message}</p>
        } else {
            content = message;
        }


        return (
            <div className="timeline-message-text">
                {content}
            </div>
        );
    }

    render() {

        const {layout} = this.props;

        const float = layout === 'left' ? 'left' : 'right';

        const style = {
            float: float
        };

        const arrowStyle = {};

        if (layout === 'left') {
            arrowStyle.borderLeft = '20px solid #F6F6F6';
            arrowStyle.right = '-20px';
        } else {
            arrowStyle.borderRight = '20px solid #F6F6F6';
            arrowStyle.left = '-20px';
        }

        return (
            <div className="timeline-message-content" style={style}>
                <div className="timeline-message-arrow" style={arrowStyle}></div>
                <div className="timeline-message-top">
                    {this.renderMessage()}
                </div>
                {this.renderFooter()}
            </div>
        )
    }

}
