import React, {Component} from 'react';
import PropTypes from 'prop-types';
import StarRating from "../Common/StarRating";
import {
    formatNumber,
    formatNumberK,
    getImageUrl,
    makeMomentFromDate,
    limitChars, isImageValid, proxyImageUrl
} from "../../utilities";
import {socialIconForType, socialNameForType} from "../../utilities/social";
import he from 'he';

class SimplePostCard extends Component {

    static propTypes = {
        post: PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            images: PropTypes.array,
            user: PropTypes.shape({
                name: PropTypes.string.isRequired,
                verified_reach: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            }).isRequired,
            type: PropTypes.string,
            engagements: PropTypes.number,
            written_at: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
        }),
        onClickPost: PropTypes.func,
        onClickUser: PropTypes.func,
        onRatePost: PropTypes.func,
        useImageProxy: PropTypes.bool,
    };

    state = {
        imageIndex: 0
    };

    componentDidMount() {
        this.checkCurrentImage();
    }

    checkCurrentImage() {
        const index = this.state.imageIndex;
        const images = this.props.post.images || [];
        const image = images[index];

        const nextImageIfPossible = () => {
            if (index < images.length - 1) {
                this.setState(prevState => ({imageIndex: prevState.imageIndex + 1}), this.checkCurrentImage.bind(this));
            }
        };

        if (image) {
            const imageUrl = getImageUrl(image);
            isImageValid(imageUrl, 100000)
                .catch(err => {
                    nextImageIfPossible();
                })
                .then((isGood) => {
                    if (!isGood) {
                        nextImageIfPossible();
                    }
                })
        } else {
            nextImageIfPossible();
        }

    }
    renderImage() {
        const images = this.props.post.images || [];
        const image = images[this.state.imageIndex];
        let style = {};

        if (image) {
            let imageUrl = getImageUrl(image);
            if (this.props.useImageProxy) {
                imageUrl = proxyImageUrl(imageUrl);
            }
            style.backgroundImage = `url(${imageUrl})`;
        }

        return (
            <>
                <div
                    role="button"
                    className="post-card-image"
                    style={style}
                    onClick={this.props.onClickPost}
                />
                <div className="post-card-image-inner-wrapper">
                <div
                    role="button"
                    className="post-card-image-inner"
                    style={style}
                    onClick={this.props.onClickPost}
                />
                </div>
            </>
        );
    }

    renderMeta() {
        const {post} = this.props;
        const {user} = post;
        const written = makeMomentFromDate(post.written_at);
        const rating = post.rating || null;
        const shortTitle = limitChars(he.decode(post.title), 75);

        return (
            <div className="post-card-meta">
                <div
                    role="button"
                    className="v3 h8 bold post-card-title"
                    onClick={this.props.onClickPost}
                >{shortTitle}</div>
                <div
                    role="button"
                    className="v3 h8 post-card-user"
                    onClick={this.props.onClickUser}
                >by {user.name}</div>
                <div className="v3 h8 muted post-card-date">{written.format('MMM Do, YYYY')}</div>

                {this.props.onRatePost &&
                    <StarRating
                        onRatingChange={this.props.onRatePost}
                        rating={rating}
                    />
                }

                {this.renderMetaRight()}
            </div>
        );
    }

    renderContent() {
        const {post} = this.props;
        if (typeof post.content === 'undefined' || !post.content) {
            return null;
        }
        return (
            <div className="post-card-content">
                {post.content}
            </div>
        );
    }

    renderMetaRight() {
        const {post} = this.props;
        const {user} = post;
        const iconClass = socialIconForType(post.type);
        const niceType = socialNameForType(post.type);
        const formattedEngagements = formatNumber(post.engagements || 0);
        const formattedImpressions = formatNumberK(post.views || 0);
        return (
            <div className="post-card-meta-right">
                <div className="v3 h9 muted post-card-type"><i className={iconClass} /> {niceType}</div>
                {post.engagements && <div className="v3 h9 muted post-card-engagements">{formattedEngagements} Engs.</div> }
            </div>
        );
    }

    render() {

        return (
            <div className="simple-post-card-v3">
                {this.renderImage()}
                {this.renderContent()}
                {this.renderMeta()}
            </div>
        );

    }
}

export default SimplePostCard;
