import React, {useState, useEffect, useMemo} from 'react';
import Toolbox from "../../Common/Toolbox";
import Importer from "../../Importer/Importer";
import {bindActionCreators} from "redux";
import {createActivationInteraction} from "../../../actions/activations";
import {connect} from "react-redux";
import TinyThermometer from "../../Common/TinyThermometer";

function ImportTrackingNumbersToolbox({onClose, createActivationInteraction}) {

    const [numCompleted, setNumCompleted] = useState(0);
    const [pendingOperations, setPendingOperations] = useState([]);
    const totalOperations = pendingOperations.length + numCompleted;


    // upload metadata in the pending queue until there's none left
    useEffect(() => {

        if (pendingOperations.length > 0) {
            const operation = pendingOperations[0];
            const context = { tracking_number: operation.tracking_number }
            createActivationInteraction(operation.activation_id, 'ShipProduct', context)
                .then(() => {
                    setNumCompleted(numCompleted + 1);
                    setPendingOperations(pendingOperations.slice(1));
                });
        }

        // if we're out of pending operations, but we have more than one complete, that means we're done and can close the toolbox
        if (pendingOperations.length === 0 && numCompleted > 0 && onClose) {
            onClose();
        }

    }, [pendingOperations]);

    const ProgressBar = useMemo(() => {
        // avoid div by 0
        if (totalOperations === 0) {
            return null;
        }

        const pct = Math.round((numCompleted / totalOperations) * 100);
        return (
            <div style={{padding: 20}}>
                <TinyThermometer pct={pct} label={`${pct}%`} />
            </div>
        );

    }, [totalOperations]);


    return (
        <Toolbox
            title="Import Tracking Numbers"
            addlClasses={'import-tracking-numbers-toolbox toolbox-fixed toolbox-lg'}
            onClose={onClose}
            content={<Importer
                banner={ProgressBar}
                columns={[
                    {key: 'activation_id', title: 'Activation ID', required: true},
                    {key: 'tracking_number', title: 'Tracking Number', required: true},
                ]}
                onImport={(data) => {
                    const filtered = data.filter(row => row.activation_id && row.tracking_number);
                    setPendingOperations(filtered);
                }}
            />}
        />
    );

}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createActivationInteraction: createActivationInteraction,
}, dispatch);

export default connect(null, mapDispatchToProps)(ImportTrackingNumbersToolbox);