import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ContributorToDoRow from "./ContributorToDoRow";

export const missingFieldRules = {
    'name': {title: 'Add your name', desc: 'Who are you?', hash: 'basics'},
    'gender': {title: 'Add your gender', desc: 'It\'s up to you!', hash: 'basics'},
    'birthday_year': {title: 'Add your birthday year', desc: 'What year were you born?', hash: 'basics'},
    'birthday_month': {title: 'Add your birthday month', desc: 'In which month were you born?', hash: 'basics'},
    'birthday_day': {title: 'Add your birthday day', desc: 'On which day were you born?', hash: 'basics'},
    'country_code': {title: 'Add your country', desc: 'What country are you located in?', hash: 'location'},
    'state_name': {title: 'Add your state', desc: 'What state are you located in?', hash: 'location'},
    'city_name': {title: 'Add your city', desc: 'What city are you located in?', hash: 'location'},
    'zipcode': {title: 'Add your zipcode', desc: 'What zip code are you located in?', hash: 'location'},
    'profile_bio': {title: 'Add your bio', desc: 'A short paragraph about yourself.', hash: 'your-profile'},
    'profile_picture': {title: 'Upload a profile picture', desc: 'Put your best foot forward, brands are visual.', hash: 'your-profile'},
    'phone': {title: 'Add your phone number', desc: 'Required for paid campaigns.', hash: 'basics'},
    'facets.Category': {title: 'Choose a content category', desc: 'How would you categorize the content you create?', hash: 'facets'},
    'facets.Language': {title: 'Add your language', desc: 'What language do you speak?', hash: 'facets'},
    'facets.Race': {title: 'Add your race/ethnicity', desc: 'What is your racial background?', hash: 'facets'},
    'facets.Parenthood': {title: 'Add parenthood status', desc: 'Do you have kids?', hash: 'facets'},
    'facets.Relationship': {title: 'Add relationship status', desc: 'Are you married? Single? Divorced?', hash: 'facets'},
    'Facebook': {title: 'Connect your Facebook', desc: 'If you don\'t have one, consider it!', hash: 'social-networks'},
    'Twitter': {title: 'Connect to Twitter', desc: 'Most brands still care about Twitter presence.', hash: 'social-networks'},
    'Instagram': {title: 'Connect your Instagram', desc: 'If you don\'t have one, consider it!', hash: 'social-networks'},
    'blogs': {title: 'Connect your blog', desc: 'Most campaigns require one.', hash: 'social-networks'},
    'verified_blog': {title: 'Verify your blog', desc: 'This will increase your verified followers.', hash: 'social-networks'},
};

class ContributorTodosPane extends Component {

    _t(k, v) {
        if (this.props.translate) {
            return this.props.translate(k, v);
        }
        return v;
    }

    handleClickTodo(item) {
        if (this.props.onClickTodo) {
            return this.props.onClickTodo(item);
        }
        return window.location.href = '/contribute/do/page/user/profile#' + item.hash;
    }

    renderSingleToDo(item, fieldName) {
        const title = this._t(`contribute.profile.todo.${fieldName}.title`, item.title);
        const description = this._t(`contribute.profile.todo.${fieldName}.description`, item.desc);
        return (
            <ContributorToDoRow
                key={`contributor-todo-row-${item.title}`}
                title={<a role="button" onClick={this.handleClickTodo.bind(this, item)} className="todo-title">{title}</a>}
                desc={<p>{description}</p>}
                icon={<a role="button" className="plus-btn" onClick={this.handleClickTodo.bind(this, item)}>+</a>}
            />
        )
    }

    render() {

        const {missingFields} = this.props || [];

        return (
            <div className="contributor-todos-pane" style={this.props.style}>
                {missingFields.map(fieldName => {
                    const rule = missingFieldRules[fieldName] || {};
                    return this.renderSingleToDo(rule, fieldName);
                })}
            </div>
        )
    }

}


ContributorTodosPane.propTypes = {
    missingFields: PropTypes.array,
    onClickTodo: PropTypes.func,
    translate: PropTypes.func,
    style: PropTypes.object,
};

ContributorTodosPane.defaultProps = {
    style: {}
};

export default ContributorTodosPane;
