import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";
import PropTypes from 'prop-types';
import Form from '../Common/Form/Form';

class CommentOnIssueForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {comment: ''},
            isSubmitting: false,
            didSubmit: false,
        };
    }

    static propTypes = {
        activation: PropTypes.object.isRequired,
        issueId: PropTypes.number.isRequired,
        onSaved: PropTypes.func,
        style: PropTypes.object,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
    };

    resetForm() {
        this.setState({
            form: {comment: ''}
        });
    }

    handleSubmit() {
        const {activation, issueId} = this.props;
        this.setState({isSubmitting: true});

        const context = {...this.state.form, issue_id: issueId};

        return this.props.createInteraction(activation.id, 'CommentOnIssue', context)
            .then(resp => {
                this.setState({
                    isSubmitting: false,
                    didSubmit: true
                })
            })
            .then(() => this.resetForm())
            .then(() => this.props.fetchActivation(activation.id))
            .then(() => {
                if (this.props.onSaved) {
                    this.props.onSaved(context);
                }
            })
            .then(() => {
                setTimeout(() => this.setState({didSubmit: false}), 3000);
            });
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    render() {
        const {isSubmitting, didSubmit} = this.state;
        const buttonTitleText = (isSubmitting, didSubmit) => {
            let text = 'Add Comment';
            if (isSubmitting) text = 'Adding Comment...';
            if (didSubmit) text = 'Comment Added!';
            return text;
        };
        return <Form
            style={this.props.style}
            values={this.state.form}
            onFieldChange={this.handleFieldChange.bind(this)}
            fields={[
                {
                    type: 'wysiwyg',
                    title: 'Comment',
                    placeholder: 'Type your comment here',
                    name: 'comment',
                    options: {
                        hideTitle: true
                    }
                },
                {
                    type: 'button',
                    title: buttonTitleText(isSubmitting, didSubmit),
                    options: {
                        hideTitle: true,
                        onClick: this.handleSubmit.bind(this),
                        classes: ['v3 btn-secondary small bold fullwidth', ((isSubmitting || didSubmit) ? 'disabled' : '')]
                    }
                },
            ]} />
    }
}


const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };
};

export default connect(null, mapDispatchToProps)(CommentOnIssueForm);
