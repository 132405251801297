import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getCampaign} from "../../../../selectors/campaigns";
import {fetchActivation} from "../../../../actions";
import {fetchCatalogProducts} from "../../../../actions/products";
import {getCatalogProducts} from "../../../../selectors/products";
import {base64_png_1pxSolidEEE, formatDollar, ucwords} from "../../../../utilities";
import Alert from '../../../Common/Alert';
import Tooltip from "../../../Common/Tooltip";

class SelectableProductGrid extends Component {

    static defaultProps = {
        showStockAvailable: false,
    };

    static propTypes = {
        activation: PropTypes.object,
        campaign: PropTypes.object,
        onSaved: PropTypes.func,
        createInteraction: PropTypes.func,
        fetchProducts: PropTypes.func,
        fetchActivation: PropTypes.func,
        showStockAvailable: PropTypes.bool,
        products: PropTypes.array,
    };

    state = {
        didAssign: false,
        isFetching: true,
        isSelecting: false,
        isRemoving: false,
    };

    componentDidMount() {
        const {campaign, fetchProducts, activation} = this.props;
        const activationCatalogId = (activation.settings || {}).product_catalog_id;
        const campaignCatalogId = (campaign.settings || {}).product_catalog_id;
        const catalogId = activationCatalogId || campaignCatalogId;

        fetchProducts(catalogId)
            .then(() => this.setState({isFetching: false}))
    }

    getSelectedProductIds() {
        //return an array of all product ids attached to the activation
        return ((this.props.activation || {})
            .products || [])
            .map(p => p.id);
    }

    renderAlert(){
        return <Alert classes={['info']} content={'Loading Product Samples...'}/>;
    }

    isProductSelected(product) {
        //return boolean if product id is in activation
        return this.getSelectedProductIds().indexOf(product.id) > -1;
    }

    isProductAvailable(product) {
        //return true boolean if product is not consumed
        const { consumables } = product;
        const availableCount = consumables.filter(c => c.is_consumed === false).length;
        return availableCount > 0;
    }

    handleClickProduct(product) {
        //if product is already selected, pass handleAssign a false boolean
        const isSelected = this.isProductSelected(product);

        if (this.props.activation.status.is_product_shipped) {
            return null;
        }

        return this.handleAssign(product, !isSelected);
    }

    handleAssign(product, shouldSelect = true) {

        const {activation, createInteraction, fetchActivation, fetchProducts, campaign, onSaved} = this.props;
        const type = shouldSelect ? 'isSelecting' : 'isRemoving';
        this.setState({hasError: false, [type]: product.id});
        const payload = {product_id: product.id, select: shouldSelect};

        return createInteraction(activation.id, 'SelectProduct', payload)
            .then(dispatched => {
                if (dispatched && dispatched.meta && dispatched.meta.error) {
                    this.setState({hasError: true, error: dispatched.meta.error});
                }
            })
            .catch(err => {
                this.setState({hasError: true, error: err.message});
            })
            .then(() => fetchActivation(activation.id))
            .then(() => fetchProducts(campaign.id))
            .then(() => {
                this.setState({didAssign: !this.state.hasError, [type]: false})
            })
            .then(() => {
                if (!this.state.hasError && onSaved) {
                    onSaved();
                }
            });
    }

    renderGrid() {
        return (
            <div className="select-product-grid container-fluid">
                <div className="row">
                    {this.renderProducts()}
                </div>
            </div>
        );
    }

    getProducts() {

        return (this.props.products || [])
            .sort((a, b) => {
                const isASelected = this.isProductSelected(a);
                const isBSelected = this.isProductSelected(b);
                const isAAvailable = this.isProductAvailable(a);
                const isBAvailable = this.isProductAvailable(b);

                const aName = a.name;
                const bName = b.name;

                //sort order: selectedA, selectedB, availableA, availableB, unavailable items.
                if (isASelected && !isBSelected) {
                    return -1;
                } else if (isBSelected && !isASelected) {
                    return 1;
                } else if (isAAvailable && !isBAvailable) {
                    return -1;
                } else if (isBAvailable && !isAAvailable) {
                    return 1;
                } else {
                    return aName.localeCompare(bName);
                }

            });

    }

    renderProducts() {
        return this.getProducts()
            .map(this.renderProduct.bind(this));
    }

    getProductImageUrl(product) {

        const defaultImage = 'data:image/png;base64,' + base64_png_1pxSolidEEE;
        const images = product.images || [];

        if (images && images.length > 0) {
            return images[0];
        }

        return defaultImage;
    }

    statusStyle(status) {
        const background = status ? 'rgba(0,0,0,0.67)' : 'none';

        let text;

        switch(status) {
            case 'unavailable':
                text = 'Unavailable';
                break;
            case 'selecting':
                text = 'Selecting...';
                break;
            case 'removing':
                text = 'Removing...';
                break;
            case 'selected':
                text = 'Selected!';
                break;
        }

        return(
            <div className={status}
                 style= {{
                     position: 'absolute',
                     height: '100%',
                     width: '100%',
                     background: background
                 }}>
                <h4 style= {{
                    color: 'white',
                    textAlign: 'center',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    position: 'relative',
                }}>{text}</h4>
            </div>
        );
    }

    renderProduct(product) {

        let bgImage = this.getProductImageUrl(product);
        const {name, product_url, monetary_value} = product;
        const { isSelecting, isRemoving } = this.state;
        const isAvailable = this.isProductAvailable(product);
        const isSelected = this.isProductSelected(product);
        function getStatus(product, isSelecting, isRemoving) {
            if (isSelected) {
                return 'selected';
            } else if (!isAvailable) {
                return 'unavailable';
            } else if (isSelecting === product.id) {
                return 'selecting';
            } else if (isRemoving === product.id) {
                return 'removing';
            } else {
                return null;
            }
        }

        let classes = ['product-box', (isSelected ? 'selected' : '')];
        const cursorStyle = this.props.activation.status.is_product_shipped ? 'not-allowed' : 'pointer';
        let style = {backgroundImage: `url(${bgImage.url})`, position: 'relative', cursor: cursorStyle};
        const price = monetary_value ? formatDollar(monetary_value) : '';
        const moreInfo = product_url ? <a href={product_url} target='_blank'>More Info</a> : null;
        const dash = price && moreInfo ? ' - ' : '';

        return (
            <div className="product-box-wrapper col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div className={classes.join(' ')}>

                    <div className="product-image" style={style} onClick={this.handleClickProduct.bind(this, product)}>
                        {this.statusStyle(getStatus(product, isSelecting, isRemoving))}
                    </div>

                    {this.renderProductSelectIcon(product, getStatus(product, isSelecting, isRemoving))}

                    <div className="product-meta">
                        <div className="product-name">
                            {name}
                        </div>
                        <div className="product-price">
                            {monetary_value ? formatDollar(monetary_value) : null} -&nbsp;
                            {this.renderProductInfoTooltip(product)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderProductInfoTooltip(product) {
        return (
            <Tooltip
                html={(
                    <div className="product-info-tooltip">
                        {product.description &&
                            <p className="v3 h7 light"><span className="v3 h7 bold">Description: </span>{product.description}</p>
                        }
                        {product.size &&
                            <p className="v3 h7 light"><span className="v3 h7 bold">Size: </span>{ucwords(product.size)}</p>
                        }
                        {product.color &&
                            <p className="v3 h7 light"><span className="v3 h7 bold">Color: </span>{ucwords(product.color)}</p>
                        }
                        {product.category &&
                            <p className="v3 h7 light"><span className="v3 h7 bold">Category: </span>{ucwords(product.category)}</p>
                        }
                        {product.product_url &&
                            <a href={product.product_url} target="_blank">More Info</a>
                        }
                    </div>
                )}
                position="top"
                trigger="click"
            >
                <a role="button" style={{display: 'inline-block'}}>
                    More Info
                </a>
            </Tooltip>
        )
    }

    renderProductSelectIcon(product, status) {

        if ((status === 'selecting') || (status === 'removing')) {
            return <a role="button" className="product-select-icon-wrapper">
                <i className="fas fa-spinner fa-spin" />
            </a>;
        } else if (status === 'unavailable') {
            return null;
        } else {
            let classes = ['product-select-icon-wrapper', (this.props.activation.status.is_product_shipped ? 'shipped' : '')];
            return (
                <a
                    role="button"
                    className={classes.join(' ')}
                    onClick={this.handleClickProduct.bind(this, product)}
                >
                    <i className="fa fas fa-heart"></i>
                </a>
            );
        }
    }

    render() {
        return (
            <div>
                {this.state.isFetching ? this.renderAlert() : null}
                {this.renderGrid()}
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {activation} = props;
    const activationCatalogId = (activation.settings || {}).product_catalog_id;
    const campaign = getCampaign(state, {campaignId: props.activation.campaign_id});
    const campaignCatalogId = (campaign.settings || {}).product_catalog_id;
    const catalogId = activationCatalogId || campaignCatalogId;

    return {
        campaign: campaign,
        products: getCatalogProducts(state, {catalogId}),
    };

};

const mapDispatchToProps = (dispatch) => {

    return {

        fetchProducts: (catalogId) => dispatch(fetchCatalogProducts(catalogId)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),

    };

};

const ConnectedSelectableProductGrid = connect(mapStateToProps, mapDispatchToProps)(SelectableProductGrid);

ConnectedSelectableProductGrid.propTypes = {
    activation: PropTypes.object.isRequired,
    campaignId: PropTypes.number.isRequired,
    onSaved: PropTypes.func,
    showStockAvailable: PropTypes.bool,
};

export default ConnectedSelectableProductGrid;