/**
 * Created by bkroger on 5/14/18.
 */


import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../../../actions/activations";
import PropTypes from 'prop-types';
import SelectableSocialPostsTable from '../Pieces/SelectableSocialPostsTable';
import Alert from '../../../Common/Alert';
import Button from '../../../Common/Form/Button';
import {formatNumber, makeFormData} from "../../../../utilities";
import {fetchAuthenticated} from "../../../../actions/auth";
import {socialIconForType, socialNameForType} from "../../../../utilities/social";
import Table from '../../../Table/Table';
import LinkCell from "../../../Table/Cell/Link";
import NumberCell from "../../../Table/Cell/Number";
import Toolbox from '../../../Common/Toolbox';
import * as URI from 'urijs';
import _find from 'lodash/find';
import SubmitSocialPostUrl from "./SubmitSocialPostUrl";
import SubmitSocialUrlFallback from "../Pieces/SubmitSocialUrlFallback";
import AddTikTok from "../../EditProfile/Templates/AddTikTok";
import InstastoryStatsToolbox from "../Pieces/InstastoryStatsToolbox";
import AddInstagram from "../../EditProfile/Templates/AddInstagram";

/**
 */
class SubmitSocialPost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetchingSocials: false,
            didFetchSocials: false,
            selectedSocialId: null,
            socials: null,

            // Candidate posts.
            candidates: null,
            liveposts: null,
            paginator: null,
            page: 1,
            pages: 2,
            pagePaginators: { 1: '' },

            // Will only be set if we need authorization to the social
            authUrl: null,
            isFetchingPosts: false,
            didFetchPosts: false,

            isFetchingSubmittedPosts: false,
            submittedPosts: null,
            submittingPostIds: {},
            error: null,
            success: false,

            showAuthToolbox: false, // experimental
            showTikTokToolbox: false,
            showInstagramToolbox: false,


            showInstastoryStatsToolbox: true,
            instastoryStatsToolboxRecordId: null
        };

        this.fetchActivationSocials = this.fetchActivationSocials.bind(this);
        this.fetchSocialPosts = this.fetchSocialPosts.bind(this);
        this.handleSelectPost = this.handleSelectPost.bind(this);
        this.handleReceivePostsResponse = this.handleReceivePostsResponse.bind(this);
        this.fetchSubmittedPosts = this.fetchSubmittedPosts.bind(this);
        this.toggleSubmittingPost = this.toggleSubmittingPost.bind(this);
        this.handleSelectSocial = this.handleSelectSocial.bind(this);
        this.handleAuthWindowMessage = this.handleAuthWindowMessage.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    async refresh() {
        await this.fetchSubmittedPosts();
        await this.fetchActivationSocials();
        await this.fetchSocialPosts();
    }

    handleReceivePostsResponse(json) {

        if (json.meta.ok) {
            return this.setState({
                liveposts: json.data,
                error: null,
                paginator: json.meta.paginator || null,
                pagePaginators: {
                    ...this.state.pagePaginators,
                    [(this.state.page + 1)]: ((json.meta.paginator || {}).before || {}).max_id || null
                }
            });
        } else {
            return this.setState({
                liveposts: null,
                error: "There was an issue finding your " + this.getNiceType() + " posts. You may need to re-connect the account.",
            });
        }

        if (json.meta.auth_url) {
            return this.setState({authUrl: json.meta.auth_url});
        }
    }

    fetchSubmittedPosts() {
        const {activation} = this.props;
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/activation/${activation.id}/post`;

        this.setState({isFetchingSubmittedPosts: true});

        return this.props.fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => this.setState({submittedPosts: json.data}))
            .then(() => this.setState({isFetchingSubmittedPosts: false}))

    }

    handleSelectSocial(social) {
        if (!social) {
            return;
        }

        this.setState({
            selectedSocialId: social.id,
            error: null,
        }, () => this.fetchSocialPosts());
    }

    fetchSocialPosts(maxId = null) {
        const {selectedSocialId} = this.state;

        if (!selectedSocialId) {
            return;
        }

        const {activation} = this.props;
        const slot = window.Gator.getDashboardSlot();
        let url = `/${slot}/api/activation/${activation.id}/livesocialpost/${selectedSocialId}`;

        if (maxId) {
            url = url + '?max_id=' + maxId;
        }

        if (this.props.isInstastory) {
            url = url + '?instastory=true';
        }

        this.setState({isFetchingPosts: true});

        return this.props.fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(this.handleReceivePostsResponse)
            .then(() => this.setState({isFetchingPosts: false, didFetchPosts: true}));
    }

    fetchActivationSocials() {
        const {activation, type} = this.props;
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/activation/${activation.id}/social?type=${type}`;

        this.setState({isFetchingSocials: true});

        return this.props.fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => {
                return this.setState({
                    authUrl: (json.meta || {}).auth_url || null,
                    socials: json.data,
                    isFetchingSocials: false,
                    didFetchSocials: true,
                });
            });

    }

    toggleSubmittingPost(post, value = null) {

        if (value === null) {
            value = !this.state.submittingPostIds[post.source_id];
        }

        this.setState({
            submittingPostIds: {
                ...this.state.submittingPostIds,
                [post.source_id]: value
            }
        });


    }

    handleSelectPost(post) {

        this.toggleSubmittingPost(post, true);

        const payload = {...post, social_id: this.state.selectedSocialId};

        // Special handling for Facebook Page posts.
        // (Because they are still Social\Post\Facebook on the  backend but need to be accounted for separately)
        if (this.props.type === 'Tidal\\Social\\FacebookPage') {
            payload.type = 'Tidal\\Social\\Post\\FacebookPage';
        }

        this.props.createInteraction(this.props.activation.id, 'SubmitSocialPost', payload)
            .catch(err => {
                this.setState({
                    error: "There was an error submitting your post."
                })
            })
            .then((resp) => {
                console.log(resp);
                this.toggleSubmittingPost(post, false);
                this.setState({
                    success: true,
                    showInstastoryStatsToolbox: this.props.isInstastory,
                    instastoryStatsToolboxRecordId: post.source_id
                })
                return this.fetchSubmittedPosts();
            })
            .then(this.props.refreshActivation)
        ;
    }

    handleUnselectPost(post) {

        const {activation} = this.props;
        const {status} = activation;

        if (status.is_invoiced || status.is_completed) {
            return this.setState({
                error: "You can not remove posts after your payment has been approved. If you need help, please use the 'Have an Issue?' link at the top of the page."
            });
        }

        // Find the post record from the social id
        const postRecords = activation.postRecords || activation.post_records || [];
        const records = postRecords.filter(record => record.post.permalink === post.permalink);

        if (records.length === 0) {
            return this.setState({
                error: "Could not find post by permalink."

            });

        }
        const postRecord = records[0];

        this.setState({isRemovingPost: true, didRemovePost: false});
        return this.props.createInteraction(activation.id, 'RemovePost', {activation_post_id: postRecord.id})
            .catch(err => {
                this.setState({
                    error: "There was an error removing your post."
                })
            })
            .then(() => this.setState({isRemovingPost: false, didRemovePost: true}))
            .then(() => this.fetchSubmittedPosts())
            .then(this.props.refreshActivation);



    }

    renderInstagramAuthButtons() {

        return (
            <div>
                <Button
                    content={`Connect Instagram Business/Creator`}
                    onClick={() => {
                        console.log("IG Business");
                        this.setState(
                            {authUrl: this.state.authUrl.replace('attach_instagram?', 'attach_instagram_business?')},
                            () => this.launchAuthWindow()
                        );
                    }}
                    classes={['v3', 'btn', 'tidal-btn', 'bold', 'medium', 'btn-primary']}
                />
                {/*<Button*/}
                {/*    content={`Connect Instagram Basic`}*/}
                {/*    onClick={() => {*/}
                {/*        console.log("IG Basic");*/}
                {/*        this.setState(*/}
                {/*            {authUrl: this.state.authUrl.replace('attach_instagram_business?', 'attach_instagram?')},*/}
                {/*            () => this.launchAuthWindow()*/}
                {/*        );*/}
                {/*    }}*/}
                {/*    classes={['v3', 'btn', 'tidal-btn', 'bold', 'medium', 'btn-secondary']}*/}
                {/*/>*/}
                {this.renderConsentNotice()}
            </div>

        );
    }

    toggleAddInstagram() {
        this.setState({showInstagramToolbox: !this.state.showInstagramToolbox})
    }
    toggleAddTikTok() {
        this.setState({showTikTokToolbox: !this.state.showTikTokToolbox})
    }

    renderInstagramToolbox() {
        if (!this.state.showInstagramToolbox) {
            return null;
        }
        return (<Toolbox
            addlClasses={"add-instagram-toolbox toolbox-fixed toolbox-sm"}
            supportMobile={true}
            title={this.props.translate('contribute.social.connect.add_an_instagram', 'Add an Instagram')}
            content={<AddInstagram
                onClickInstagramBusinessLink={() => {
                    this.toggleAddInstagram();
                    this.launchAuthWindow('instagram-business')
                }}
                userId={this.props.activation.user_id}
                onClose={()=>{
                    this.toggleAddInstagram();
                    this.fetchActivationSocials();
                }}
            />}
            style={{minHeight: 500}}
            onClose={this.toggleAddInstagram.bind(this)}
        />)
    }

    renderTikTokToolbox() {
        if (!this.state.showTikTokToolbox) {
            return null;
        }
        return (<Toolbox
            title={'Add a TikTok'}
            content={<AddTikTok
                userId={this.props.activation.user_id}
                onClose={()=>{
                    this.toggleAddTikTok();
                    this.fetchActivationSocials();
                }}
            />}
            onClose={this.toggleAddTikTok.bind(this)}
            style={{
                width: 500,
                left: '50%',
                marginLeft: -250,
                maxHeight: 600,
                top: 0
            }}
        />);
    }

    renderAuthButton() {
        return (
            <div>
                <Button
                    content={`Connect Your ${this.getNiceType()} Account`}
                    onClick={() => {
                        if (this.props.type === 'Tidal\\Social\\TikTok') {
                            this.toggleAddTikTok();
                        } else if (this.props.type === 'Tidal\\Social\\Instagram') {
                            this.toggleAddInstagram();
                        } else {
                            this.launchAuthWindow();
                        }
                    }}
                    classes={['v3', 'btn', 'tidal-btn', 'bold', 'medium', 'btn-primary']}
                />
                {this.renderConsentNotice()}
            </div>
        );

    }

    renderConsentNotice() {

        let specifics = null;
        const niceType = this.getNiceType();

        if (niceType === 'Instagram') {
            specifics = 'In order to connect an Instagram Professional account, that account must first be attached to a Facebook Page.';
        }

        if (niceType === 'Facebook') {
            specifics = 'When connecting to Facebook we ask for permission to manage your Pages so that you can attach a Page later. We do not modify Pages in any way, and will not use your Page data unless you explicitly attach a Page later.'
        }

        if (niceType === 'Facebook Page') {
            specifics = <span><strong>If you get an error connecting:</strong> If you get an error immediately after logging into Facebook, please close the popup and try again.</span>;
        }

        return (
            <div>
                <p className="v3 light h7 pane-info" style={{marginTop: 20}}>
                    <strong>How we use this data: </strong> We never post anything to your account or modify it in any way.
                </p>
                <p className="v3 light h7 pane-info" style={{marginTop: 10}}>
                    When you connect an account we ask {this.getNiceType()} for basic stats like your public profile, followers count, and number of engagements.
                </p>
                {specifics &&
                    <p className="v3 light h7 pane-info" style={{marginTop: 10}}>
                        {specifics}
                    </p>
                }
            </div>
        );
    }

    getSubmittingPostIds() {
        let out = [];
        Object.keys(this.state.submittingPostIds).forEach(id => {
            if (this.state.submittingPostIds[id]) {
                out.push(id);
            }
        });
        return out;
    }

    getNiceType(coreSocialOnly = false) {
        if (this.props.isInstastory && !coreSocialOnly) {
            return 'Instastory';
        }
        return socialNameForType(this.props.type);
    }

    renderSocialIcon() {
        const classes = socialIconForType(this.props.type);
        return <i className={classes} />;
    }

    renderSocialSelector(showAlert = true) {

        return (
            <div>
                {showAlert && <Alert
                    classes={['info']}
                    content={`Please choose an ${this.getNiceType(true)} account, or attach a new one below.`}
                    style={{marginBottom: 20}}
                />}

                {this.renderSocialSelectTable()}

                {this.renderAuthButton()}
                {this.renderTikTokToolbox()}
                {this.renderInstagramToolbox()}

                {(this.state.error ? <SubmitSocialUrlFallback
                    activation={this.props.activation}
                    campaign={this.props.campaign}
                    createInteraction={this.props.createInteraction}
                    refreshActivation={this.props.refreshActivation}
                    type={this.props.type}
                    expanderText={"Having trouble connecting? Click here to submit a post manually."}
                /> : null)}
            </div>
        );
    }

    handlePageChange(page) {

        this.setState({
            page: page,
            pages: page+1
        }, () => {

            if (this.state.pagePaginators[this.state.page]) {
                const maxId = this.state.pagePaginators[this.state.page];
                this.fetchSocialPosts(maxId);
            } else {
                this.fetchSocialPosts();
            }

        });

    }

    renderSocialSelectTable() {

        if (!this.state.socials || this.state.socials.length === 0) {
            return null;
        }

        return (
            <div style={{marginBottom: 20}}>
                <Table
                    items={this.state.socials}
                    columns={[
                        {
                            key: 'social',
                            title: 'Account (Click to Select)',
                            width: 300,
                            default: true,
                            sortable: false,
                            cell: (row, column) => <LinkCell row={row} column={column}
                                                             value={<strong>{row.item.name || row.item.slug || row.item.raw}</strong>}
                                                             onClick={() => this.handleSelectSocial(row.item) }
                            />
                        },
                        {
                            key: 'followers',
                            title: 'Followers',
                            width: 300,
                            default: true,
                            sortable: false,
                            cell: (row, column) => <NumberCell row={row} column={column} value={row.item.followers_count} formatter={formatNumber}/>
                        },
                        {
                            key: 'url',
                            title: 'Profile',
                            width: 400,
                            default: true,
                            sortable: false,
                            cell: (row, column) => <LinkCell row={row} column={column}
                                                             value={'@' + row.item.slug}
                                                             href={row.item.url}
                                                             target={'_blank'}
                            />
                        },
                    ]}
                />

            </div>
        );
    }

    isBlocked() {

        const {activation} = this.props;
        return activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count;
    }

    renderFacebookLinkMode() {

        return <SubmitSocialPostUrl
            activation={this.props.activation}
            campaign={this.props.campaign}
            type={this.props.type}
            refreshActivation={this.props.refreshActivation}
            fetchAuthenticated={this.props.fetchAuthenticated}
            createInteraction={this.props.createInteraction}
        />;

    }

    renderBody() {

        if (this.isBlocked()) {
            // Updated Nov 2022: We no longer _actually_ block submissions, but we still want to show the message
            // return null;
        }

        if (this.props.facebookLinkMode) {
            return this.renderFacebookLinkMode();
        }

        const {isFetchingPosts, didFetchPosts, didFetchSocials, selectedSocialId, isFetchingSocials, isFetchingSubmittedPosts} = this.state;

        if (isFetchingPosts) {
            return <Alert classes={['info']} content={'Finding posts...'} />;
        }

        if ((isFetchingSocials || isFetchingSubmittedPosts) && !didFetchPosts) {
            return <Alert classes={['info']} content={'Loading, please wait...'} />;
        }

        if (!selectedSocialId && didFetchSocials) {
            return this.renderSocialSelector(true);
        }

        if (didFetchPosts) {
            return this.renderSelectPosts();
        }
    }

    renderInstastoryStatsToolbox() {
        // This was built before we had stats permission, so this is a manual submit fallback
        // if (!this.state.showInstastoryStatsToolbox) {
            return null;
        // }

        return (
            <InstastoryStatsToolbox
                onClose={() => this.setState({showInstastoryStatsToolbox: false})}
                createInteraction={this.props.createInteraction}
                activation={this.props.activation}
                sourceId={this.state.instastoryStatsToolboxRecordId}
            />
        );
    }

    renderSelectPosts() {

        const {didFetchPosts, liveposts, error} = this.state;

        if (didFetchPosts && error) {
            return this.renderSocialSelector(false);
        }

        return (
            <div className="social-posts">

                {this.renderInstastoryStatsToolbox()}
                <p className="v3 light h7 pane-info" style={{marginBottom: 20}}>Use the checkboxes below to select the posts to submit to the campaign.</p>

                <SelectableSocialPostsTable
                    page={this.state.page}
                    pages={this.state.pages}
                    usePagination={!!this.state.paginator}
                    onPageChange={this.handlePageChange.bind(this)}
                    posts={liveposts || []}
                    selectedPostIds={this.getSelectedPostIds()}
                    submittingPostIds={this.getSubmittingPostIds()}
                    onSelect={this.handleSelectPost.bind(this)}
                    onUnselect={this.handleUnselectPost.bind(this)}
                    isInstastory={this.props.isInstastory}
                />

                {<SubmitSocialUrlFallback
                    activation={this.props.activation}
                    campaign={this.props.campaign}
                    createInteraction={this.props.createInteraction}
                    refreshActivation={this.props.refreshActivation}
                    type={this.props.type}
                    onClickExpanderOverride={this.props.isInstastory ? this.props.onClickSubmitManually : undefined}
                />}
            </div>

        );

    }

    getSelectedPostIds() {

        return (this.state.submittedPosts || [])
            .map(post => {
                if (post.source_id) {
                    return post.source_id;
                }
                if ((post.metadata || {}).social_id) {
                    return post.metadata.social_id;
                }
                return null;
            });

    }

    renderTopAlerts() {

        let output = [
        ];

        if (this.state.error) {
            output.push(<Alert
                classes={['danger']}
                content={this.state.error}
                onClose={() => this.setState({error: null})}
            />);
        }

        if (this.state.success) {
            output.push(<Alert
                classes={['success']}
                style={{marginBottom: 20}}
                content={'Your post was submitted successfully!'}
                onClose={() => this.setState({success: false})}
            />);
        }

        if (this.isBlocked()) {
            output.push(
                <Alert
                    classes={['danger']}
                    content="Your drafts must be submitted and approved before you can post content!"
                />
            );
        }

        return output;

    }

    handleAuthWindowMessage(msg) {
        const {data} = msg;

        if (this.authWindow) {
            this.authWindow.close();
        }

        if (data.from !== 'AttachSocial') {
            return;
        }

        const {id, type} = data;

        this.fetchActivationSocials()
            .then(async () => {
                // Need to check if the ID we got from the auth window is now in the response.
                const social = _find(this.state.socials, {id});

                if (social) {
                    this.handleSelectSocial(social);
                } else {
                    await this.handleCreateSocialFromToken(data);
                }

                // if there was no id, and there's only one social, select the first social
                if (!id && this.state.socials.length === 1) {
                    this.handleSelectSocial(this.state.socials[0]);
                }

            });


    }

    handleCreateSocialFromToken(data) {

        const slot = window.Gator.getDashboardSlot();
        const activation = this.props.activation;
        const url = `/${slot}/api/activation/${activation.id}/social`;
        const payload = {
            token: data.t,
            type: data.type,
        };
        const options = {
            method: 'POST',
            body: makeFormData(payload)
        };

        return this.props.fetchAuthenticated(url, options)
            .then(() => this.fetchActivationSocials());
    }

    launchAuthWindow() {
        const {authUrl} = this.state;

        let url = new URI(authUrl);
        const params = url.search(true);
        url.search({...params, after: '__pm'});

        if (this.authWindow) {
            this.authWindow.close();
        }

        this.authWindow = window.open(url.toString(), '_blank', 'height=600,width=800');

        window.addEventListener('message', this.handleAuthWindowMessage.bind(this));

    }



    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }
    render() {

        if (this.isBlockedByNDA()) {
            return this.renderNDAAlert();
        }

        const socialType = this.getNiceType();

        return (
            <div className="influencer-page-template submit-social-posts">
                <div className="widget">
                    <div className="content padded">
                        <h4 className="v3 bold action-pane-title">Submit {socialType} Posts {this.props.titleBtn}</h4>
                        {this.renderTopAlerts()}
                        {this.renderBody()}
                    </div>
                </div>
            </div>
        )
    }

}

SubmitSocialPost.propTypes = {
    activation: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    refreshActivation: PropTypes.func,
    fetchAuthenticated: PropTypes.func,
    createInteraction: PropTypes.func,
    titleBtn: PropTypes.node,
    facebookLinkMode: PropTypes.bool,
    isInstastory: PropTypes.bool,
    onClickSubmitManually: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
    return {
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params))
    };
};

export default connect(null, mapDispatchToProps)(SubmitSocialPost);
