/**
 * Created by bkroger on 6/19/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class EventDivider extends Component {

    render() {

        const {message, timestamp} = this.props;

        // const textStyle = {};
        //
        // if (timestamp !== 'undefined' || timestamp !== 'null' || timestamp !== '') {
        //     textStyle.marginRight = '20px';
        // } else {
        //     textStyle.marginRight = '0';
        // }

        return (
            <div className="timeline-event-divider">
                <div className="timeline-event-divider-line"></div>
                <div className="timeline-event-divider-text">
                    <h6 className="v3 light">{message}</h6>
                    {
                        timestamp &&
                        <span className="v3 light h8">{timestamp}</span>
                    }
                </div>
            </div>
        )
    }

}


EventDivider.defaultProps = {
    message: null,
    timestamp: null
};

EventDivider.propTypes = {
    message: PropTypes.string.isRequired,
    timestamp: PropTypes.string
};