import React, {Component} from 'react';
import Table from '../Table/Table';
import Toolbox from '../Common/Toolbox';
import Button from '../Common/Form/Button';
import BasicCell from '../Table/Cell/Basic';
import LinkCell from '../Table/Cell/Link';
import ImageCell from '../Table/Cell/Image';
import NumberCell from '../Table/Cell/Number';
import CheckboxCell from '../Table/Cell/Checkbox';
import _find from 'lodash/find';
import _get from 'lodash/get';
import {base64_png_1pxSolidEEE, makeFormData, fetchAuthenticated, limitChars, formatDollar} from "../../utilities";
import {calculateEmv, EMV_INDUSTRY_DEFAULTS} from "../../utilities/emv";

class EMVAnalysisTool extends Component {

    state = {
        posts: null,
        isFetchingPosts: false,
        didFetchPosts: false,

        social: null,
        socials: null,
        isFetchingSocials: false,
        didFetchSocials: false,

        showSocialSelect: false,
        showTable: false,

        updatingPosts: {}
    };

    componentDidMount() {
        this.fetchSocials();
    }

    makeEmvObject(post) {

        let out = {
            num_facebook_posts: 0,
            facebook_reaction_count: (post.engagements || {})['facebook-reaction_count'] || 0,
            facebook_comment_count: (post.engagements || {})['facebook-comment_count'] || 0,
            facebook_reach: (this.state.social || {}).followers_count || 0,
        };

        return out;
    }

    getPostEmv(post) {
        const record = this.makeEmvObject(post);
        const emv = calculateEmv(record, EMV_INDUSTRY_DEFAULTS);
        return emv;
    }

    getPostEmvDollars(post) {
        const emv = this.getPostEmv(post);
        const total = emv.total;
        return formatDollar(total);
    }

    fetchPosts() {
        this.setState({isFetchingPosts: true});
        return fetchAuthenticated(`/contribute/api/social/${this.state.social.id}/livepost`)
            .then(resp => resp.json())
            .then(json => this.setState({posts: json.data, isFetchingPosts: false, didFetchPosts: true}))

    }

    fetchSocials() {
        this.setState({isFetchingSocials: true});
        return fetchAuthenticated(`/contribute/api/social`)
            .then(resp => resp.json())
            .then(json => this.setState({socials: json.data, isFetchingSocials: false, didFetchSocials: true}))
    }

    getNiceSocialType(social) {
        switch (social.type) {
            case 'Tidal\\Social\\Facebook': return 'Facebook';
            case 'Tidal\\Social\\FacebookPage': return 'Facebook Page';
            case 'Tidal\\Social\\Instagram': return 'Instagram';
            case 'Tidal\\Social\\Twitter': return 'Twitter';
        }
    }
    renderSocialSelectToolbox() {
        if (!this.state.showSocialSelect) return null;
        return <Toolbox
            title={'Select an Account'}
            content={
                [
                    <div className="alert tidal-alert alert-grey"
                         style={{marginBottom: 0, borderRadius: 0, textShadow: 'none'}}
                    >
                        Visit <a href="/profile#social" data-widget="social">Social Networks</a> to connect more accounts.
                    </div>,
                    ...(this.state.socials || [])
                        .filter(social => {
                            return [
                                'Tidal\\Social\\Facebook',
                                'Tidal\\Social\\FacebookPage',
                            ].indexOf(social.type) > -1
                        })
                        .map(social => <div className="fake-li">
                            <a role="button" onClick={() => this.handleSelectSocial(social)}>{this.getSourceLabel(social)}</a>
                        </div>)
                ]
            }
            onClose={() => this.setState({showSocialSelect: false})}
            style={{
                width: 330,
                left: '50%',
                marginLeft: -115
            }}
        />
    }

    getSourceLabel(social) {
        return `${this.getNiceSocialType(social)}: ${social.name}`
    }

    handleSelectSocial(social) {
        this.setState({
            social,
            showSocialSelect: false,
            showTable: true,
            posts: null
        }, () => this.fetchPosts());

    }


    renderPostsTable(items) {
        const defaultImage = 'data:image/png;base64,' + base64_png_1pxSolidEEE;

        return <Table items={items || []}
               stickySortTabs={true}
               columns={[
                   {
                       key: 'image',
                       default: true,
                       sortable: false,
                       title: 'Image',
                       width: 80,
                       cell: (row, column) => <ImageCell
                           row={row} column={column}
                           url={(row.item.image_urls || [])[0] || defaultImage}
                       />
                   },
                   {
                       key: 'source',
                       default: true,
                       sortable: false,
                       width: 180,
                       title: 'Source',
                       cell: (row, column) => <BasicCell
                           row={row} column={column}
                           value={this.getSourceLabel(row.item.user)}
                       />
                   },
                   {
                       key: 'title',
                       title: 'Post Title',
                       default: true,
                       sortable: false,
                       width: 360,
                       cell: (row, column) => <BasicCell row={row} column={column}
                                                         value={limitChars(row.item.title, 100)}

                       />
                   },
                   {
                       key: 'emv',
                       title: "EMV",
                       default: true,
                       sortable: false,
                       width: 140,
                       cell: (row, column) => {
                           const emv = this.getPostEmvDollars(row.item);
                           return <BasicCell row={row} column={column}
                                             value={<strong>{emv}</strong>}
                           />
                       }
                   },
                   {
                       key: 'total_engagements',
                       title: "Engagements",
                       default: true,
                       sortable: false,
                       width: 120,
                       cell: (row, column) => <NumberCell row={row} column={column}
                                                          value={row.item.total_engagements}
                       />
                   },
                   {
                       key: 'reactions',
                       title: "Reactions",
                       default: true,
                       sortable: false,
                       width: 120,
                       cell: (row, column) => <NumberCell row={row} column={column}
                                                          value={_get(row.item, 'engagements.facebook-reaction_count')}
                       />
                   },
                   {
                       key: 'comments',
                       title: "Comments",
                       default: true,
                       sortable: false,
                       width: 120,
                       cell: (row, column) => <NumberCell row={row} column={column}
                                                          value={_get(row.item, 'engagements.facebook-comment_count')}
                       />
                   },

               ]}
        />

    }

    renderProfilePostsTable() {

        if (this.state.isFetchingPosts) {
            return <div className="alert tidal-alert alert-grey">Analyzing timeline posts...</div>
        }

        if (this.state.posts !== null && this.state.posts.length === 0 && this.state.didFetchPosts) {
            return <div className="alert tidal-alert alert-grey">Couldn't find any posts on your timeline.</div>
        }

        return this.renderPostsTable(this.state.posts || []);
    }

    renderPostsToolbox() {
        if (!this.state.showTable) {
            return;
        }

        return (
            <Toolbox
                title={'Earned Media Value Analysis'}
                content={this.renderProfilePostsTable()}
                onClose={() => this.setState({showTable: false})}
                style={{
                    width: 1000,
                    left: '50%',
                    marginLeft: -500,
                    maxHeight: 600,
                    overflow: 'auto'
                }}
            />
        );
    }

    render() {
        return (
            <div className="homepage striped">

                {this.renderSocialSelectToolbox()}
                {this.renderPostsToolbox()}

                <div className="container">
                    <div className="row">


                        <div className="col-xs-12 col-md-4">
                            <p className="homepage-section-title thin">
                                Earned Media Value Analyzer
                            </p>
                        </div>

                        <div className="col-xs-12 col-md-8">
                            <div className="right-wrap">
                                <div className="infobox">
                                    <p className="lead"><a role="button" onClick={() => this.setState({showSocialSelect: true, showTable: false})}>Analyze your timeline</a> to show the Earned Media Value you generate per post.</p>
                                    <p className="sub">Your Earned Media Value is a strong indicator of your overall influence. Measured in dollars, EMV represents the value of your content and the engagements on it.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        );
    }
}

export default EMVAnalysisTool;
