import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getTierName } from '../../../utilities/user';
import {getDomain, limitChars, formatNumber, ucwords} from "../../../utilities";
import Toolbox from '../../Common/Toolbox';
import Video from '../../Common/Video';
import {validateUrl} from "../../../utilities";
import PostDetailSidebarModeration from "./SidebarModeration";
import Button from "../../Common/Form/Button";

const statTypes = ['Blog Comments', 'Instagram Comments', 'Instagram Likes', 'Facebook Comments', 'Facebook Shares', 'Facebook Reactions', 
'Twitter Favorites', 'Twitter Retweets', 'Pinterest Comments', 'Pinterest Re-Pins', 'Pinterest Likes'];

const makeRows = (items) => {
    return items.filter(spec => spec.isValid())
        .map(spec => (<div className='fake-li padded bottom-border' key={spec.label}>{spec.label}
            <span className='pull-right'>{spec.value}</span>
        </div>))
};

class PostDetailSidebar extends Component {

    static propTypes = {
        onOpenUser: PropTypes.func.isRequired,
        post: PropTypes.object.isRequired,
        campaigns: PropTypes.array,
        sections: PropTypes.object.isRequired,
        onPublishCampaign: PropTypes.func,
        onPublishChannel: PropTypes.func,
        onFeature: PropTypes.func.isRequired,
        onRejectCampaign: PropTypes.func.isRequired,
        onComplete: PropTypes.func,
        hasOverridePermission: PropTypes.bool,
        onEditSection: PropTypes.func,
        onBump: PropTypes.func,
    };

    static defaultProps = {
        onComplete: () => {},
    };

    constructor(props) {
        super(props);
        this.state = {
            didError: [],
            isSubmitting: false,
            didSubmit: false,
        };
    };

    renderPostDetails() {
        const {post} = this.props;
        const { written_at, permalink, tags } = post;
        const formattedTags = (tags && tags.length)
            ? limitChars(tags.join(', '), 60)
            : <span style={{fontStyle: 'italic'}}>No Tags</span>;

        const postItems = [
            {
                label: 'Written:',
                value: (written_at && written_at.date) ? moment(written_at.date).format('MMM Do, YYYY') : '-',
                isValid: () => !!(written_at && written_at.date)
            },
            {
                label: 'Original URL:',
                value: permalink
                    ? <a rel="noopener nofollow" href={permalink} target='_blank'>{getDomain(permalink)}
                        <i className='v3 icon external-link' />
                    </a>
                    : '-',
                isValid: () => true
            },
            {
                label: 'Tags:',
                value: formattedTags,
                isValid: () => true
            }
        ];

        const editButton = <Button
            content={'Edit'}
            classes={['v3 btn-secondary small pull-right']}
            onClick={() => {
                const slot = window.Gator.getDashboardSlot();
                const url = `/${slot}/do/page/posts/editv3?id=${this.props.post.id}`;
                window.open(url, '_blank');
            }}
        />;

        return (
            <Toolbox
                embedded={true}
                title={<div>Post Details {editButton}</div>}
                addlClasses='post-detail-overview-toolbox'
                content={<div className='post-detail-overview-toolbox-content'>
                    {makeRows(postItems)}
                </div>
                }
            />
        )
    };

    renderPublicationDetails() {
        return (
            <PostDetailSidebarModeration
                post={this.props.post}
                sections={this.props.sections}
                campaigns={this.props.campaigns}
                onPublishChannel={this.props.onPublishChannel}
                onPublishCampaign={this.props.onPublishCampaign}
                onRejectCampaign={this.props.onRejectCampaign}
                onFeature={this.props.onFeature}
                onComplete={this.props.onComplete}
                onEditSection={this.props.onEditSection}
            />
        );
    };


    renderUserDetails() {
        const { user } = this.props.post;
        const { name, id, tier, human_location, verified_reach, blog_name, blog_url } = user || '';
        const slot = window.Gator.getDashboardSlot();
        const userUrl = `/${slot}/do/page/users/detail?id=${encodeURIComponent((user || {}).id)}`;

        const userItems = [
            {
                label: 'User:',
                value: <a className="dark" role="button" href={userUrl} onClick={this.props.onOpenUser}>{name}</a>,
                isValid: () => !!(name && id)
            },
            {
                label: 'Tier:',
                value: getTierName(tier),
                isValid: () => !!tier
            },
            {
                label: 'Reach:',
                value: formatNumber(verified_reach),
                isValid: () => !!verified_reach
            },
            {
                label: 'Location:',
                value: human_location,
                isValid: () => !!human_location
            },
            {
                label: 'Blog:',
                value: blog_url ? <a href={blog_url} target='_blank' rel='noopener noreferrer'>{blog_name}
                    <i className='v3 icon external-link' style={{marginLeft: '5px'}}/>
                    </a> 
                    : blog_name,
                isValid: () => !!blog_name
            }
        ];

        const content = makeRows(userItems);

        return (
            (content && content.length) 
            ? <Toolbox
                embedded={true}
                title={<div>User Details</div>}
                addlClasses='post-detail-overview-toolbox'
                content={<div className='post-detail-overview-toolbox-content'>{content}</div>}
            />
            : null
        )
    };

    renderStats() {
        const { driver_stats } = this.props.post;

        // Not de-duped
        const statsList = Object.keys(driver_stats || {}).map(statKey => {
            const value = parseFloat(driver_stats[statKey] || '0');
            let cleanKey = ucwords(statKey.replace(/[-_]/g, ' '));
            // also remove any numbers from the key
            cleanKey = cleanKey.replace(/[0-9]/g, '');
            // remove repeated spaces
            cleanKey = cleanKey.replace(/\s+/g, ' ').trim();

            return {
                label: cleanKey,
                value: value,
            };
        })

        // Now use a map to de-dupe by summing the values
        let deDupedStats = {};
        for (const statsItem of statsList) {
            const { label, value } = statsItem;
            if (!deDupedStats[label]) {
                deDupedStats[label] = 0;
            }
            deDupedStats[label] += value;
        }

        // Then convert back to our array structure
        const combinedStatsRows = Object.keys(deDupedStats).map(statKey => {
            return {
                label: statKey,
                value: formatNumber(deDupedStats[statKey]),
                isValid: () => !!deDupedStats[statKey]
            };
        });

        const content = makeRows(combinedStatsRows);

        return (
           (content && content.length) 
           ? <Toolbox
                embedded={true}
                title={<div>Stats</div>}
                addlClasses='post-detail-overview-toolbox'
                content={<div className='post-detail-overview-toolbox-content'>{content}</div>}
            />
            : null
        )
    };

    renderVideo() {
        const {videos} = this.props.post;
        const content = <figure className='post-detail-video-wrapper'>
            {
                (videos || []).map(video => {
                    return <div className='post-detail-video' key={video.source}>
                        {
                            ((this.state.didError.indexOf(video.source) > -1) && validateUrl(video.source))
                                ? 'Video missing or invalid.'
                                : (<Video
                                    src={video.source}
                                    provider={video.provider}
                                    // embed={video.embed_code}
                                    onError={() => {
                                        this.setState({
                                            didError: [
                                                ...this.state.didError,
                                                video.source
                                            ]
                                        })
                                    }}
                                />)
                        }
                    </div>
                })
            }
        </figure>;

        return videos && videos.length
            ? <Toolbox
                embedded={true}
                title={<div>Attached Videos</div>}
                addlClasses='post-detail-overview-toolbox'
                content={<div className='post-detail-overview-toolbox-content'>{content}</div>}
            />
            : null
    };

    render() {
        return (<>
            {this.renderPostDetails()}
            {this.renderPublicationDetails()}
            {this.renderUserDetails()}
            {this.renderStats()}
            {this.renderVideo()}
        </>)
    }
}

export default PostDetailSidebar;