import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '../Table/Table';
import BasicCell from "../Table/Cell/Basic";
import {getVisibleInfluencerRequirementTypes} from "../../utilities/campaign";

class ActivationRequirementsTable extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
    };

    getItems() {
        return getVisibleInfluencerRequirementTypes(this.props.activation);
    }

    render() {

        return (

            <Table
                stickySortTabs={true}
                items={this.getItems()}
                columns={[
                    {
                        key: 'name',
                        default: true,
                        sortable: false,
                        width: 400,
                        title: "Requirement",
                        cell: (row, column) => {

                            const isComplete = typeof row.item.isComplete === 'function'
                                ? row.item.isComplete(this.props.activation)
                                : false;

                            const signal = isComplete
                                ? <i className={'v3 signal success'} />
                                : <i className={'v3 signal warning'} />;

                            const content = <span>{signal} {row.item.title}</span>;

                            return <BasicCell row={row} column={column}
                                              value={content}
                            />;
                        },
                    },
                    {
                        key: 'description',
                        default: true,
                        sortable: false,
                        width: 800,
                        title: "Description",
                        cell: (row, column) => <BasicCell row={row} column={column} value={row.item.description} />,
                    },


                ]}
            />

        );
    }
}

export default ActivationRequirementsTable;
