import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Checkbox extends Component {

    render() {
        const {checked, white, label, onClick, style, wrapperStyle} = this.props;
        const checkboxClass = 'legacy-disabled tidal-checkbox ' + (checked ? 'checked' : '') + (white ? 'white' : '');
        const icon = this.props.icon ? this.props.icon : (checked ? <i className="fa fa-check"></i> : null);

        const checkboxStyle = {...style};
        const actualWrapperStyle = {...wrapperStyle};

        // If there's no label given (ie, we're just rendering a checkbox,
        // get rid of the right margin on the checkbox
        // This gives us slightly cleaner positioning when we dont have labels
        if (!label) {
            checkboxStyle.marginRight = 0;
        }

        if (this.props.useBoldTitle) {
            actualWrapperStyle.fontWeight = 'bold';
        }

        return (
            <div className="v3 tidal-checkbox-wrapper" onClick={onClick} style={actualWrapperStyle}>
                <div className={checkboxClass} style={checkboxStyle}>
                    {icon}
                </div>
                {label}
            </div>
        );
    }
}

Checkbox.defaultProps = {
    checked: false,
    white: false,
    label: null,
    style: {},
    wrapperStyle: {},
    useBoldTitle: true,
};

Checkbox.propTypes = {
    checked: PropTypes.bool,
    white: PropTypes.bool,
    label: PropTypes.node,
    onClick: PropTypes.func,
    icon: PropTypes.any,
    style: PropTypes.object,
    wrapperStyle: PropTypes.object,
    useBoldTitle: PropTypes.bool,
};

