import {REQUEST_LEDGER, RECEIVE_LEDGER, INVALIDATE_LEDGER} from "../actions/ledger";
import * as ActionTypes from '../actions/types';
import {indexArrayById} from "../utilities";
import {receiveItems} from "../utilities/reducer";
import {combineReducers} from 'redux';


const bills = (state = {}, action) => {

    switch (action.type) {
        case ActionTypes.RECEIVE_BILLS:
            return receiveItems(state, action);

        default:
            return state;
    }

};

export const ledgerv3 = combineReducers({
    bills
});


export const DEFAULT_LEDGER_STATE = {
    isFetching: false,
    lastUpdated: null,
    didInvalidate: false,
    transactions: {},
    available: null,
    balance: null,
    businessUnits: [],
    businessUnitBalances: {},
    campaignBalances: {}
};

export function ledger(state = DEFAULT_LEDGER_STATE, action) {

    switch (action.type) {

        case RECEIVE_LEDGER:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.receivedAt,
                didInvalidate: false,
                transactions: indexArrayById(action.transactions),
                available: action.available,
                balance: action.balance,
                businessUnits: action.businessUnits,
                businessUnitBalances: action.businessUnitBalances,
                campaignBalances: indexArrayById(action.campaignBalances)
            };


        case REQUEST_LEDGER:
            return {...state, didInvalidate: false, isFetching: true};

        case INVALIDATE_LEDGER:
            return {...state, didInvalidate: true};

        default:
            return state;
    }

}
