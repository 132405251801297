/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import GenericDonutChart from '../../Chart/GenericDonutChart';
import {ucwords} from '../../../utilities';
import d3 from 'd3';

export default class CampaignAudienceGenderDonutChart extends Component {

    getData() {
        if (!this.props.audience || this.props.audience.length === 0) return null;

        let totals = {
            Male: 0,
            Female: 0
        };

        for (let row of this.props.audience) {
            let gender = ucwords(row[1]);
            totals[gender] ++;
        }

        return totals;

    }

    renderChart() {
        const data = this.getData();
        if (!data) return null;

        return <GenericDonutChart
            data={data}
            title="Audience Gender"
            labelFormatter={
                (value, ratio, id) => d3.format('.0%')(ratio)
            }
            tooltipValueFormatter={
                (value, ratio, id) => d3.format('.0%')(ratio)
            }
        />


    }

    render() {

        return (

            <div className="widget">
                <div className="title">
                    <div className="icon left">
                        <i className="fa fa-pie-chart"></i>
                    </div>
                    <h2>
                        Audience Gender
                    </h2>
                </div>
                <div className="content padded clearfix">
                    {this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
                </div>
            </div>

        );
    }
}
