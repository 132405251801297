/**
 * Created by visgotti on 7/13/17.
 */

import {RECEIVE_EMAIL} from '../actions';
import {
    UPDATE_EMAIL_LABEL
} from '../actions/emailById';

export const DEFAULT_EMAILS_STATE = {
    emails: {},
};

export function emailsById(state = DEFAULT_EMAILS_STATE, action) {
    switch (action.type) {
        case RECEIVE_EMAIL:
            state = {
                ...state,
                emails: {
                    ...state.emails
                }
            };
            state.emails[action.emailId] = action.email;
            return state;
            break;
        case UPDATE_EMAIL_LABEL:
            state = {
                ...state,
                emails: {
                    ...state.emails
                }
            };
            state.emails[action.emailId] = {
                ...state.emails[action.emailId],
            };

            state.emails[action.emailId].label = action.label

            return state;
        default:
            return state;
    }
}
