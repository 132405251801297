import React, {Component} from 'react';
import PropTypes from 'prop-types';
import html2canvas from "html2canvas";

class ChartWrapper extends Component {

    constructor(props) {
        super(props);

        this.chartRef = React.createRef();
        this.exportChart = this.exportChart.bind(this);
    }

    svgToCanvas(targetElem) {
        const svgElem = targetElem.querySelectorAll('svg, text, tspan');
        for (let node of svgElem) {
            node.setAttribute("font-family", window.getComputedStyle(node, null).getPropertyValue("font-family"));
            node.setAttribute("font-size", window.getComputedStyle(node, null).getPropertyValue("font-size"));
            node.replaceWith(node);
        }
    }

    exportChart() {
        const chart = this.chartRef.current;
        const {title} = this.props;

        // scroll window to top before calling, this prevents image from getting cut off
        window.scrollTo(0,0);

        // font fix
        this.svgToCanvas(chart);

        html2canvas(chart).then(function(canvas) {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = `${title}.png`;

            // must add to the DOM otherwise won't work in Firefox
            document.body.appendChild(link);

            link.click();
            link.remove();
            //window.open(image.replace('image/png', 'application/octet-stream'));
        });
    }

    render() {
        const {title, titleBadge, icon, chart, showExportButton, showXTicks} = this.props;
        return (
            <div className={"chart-widget-wrapper " + (showXTicks ? 'show-x-ticks' : '')} style={this.props.wrapperStyle}>
                <div className="widget" id={`${title}`} style={this.props.style}>
                    <div className="title">
                        <div className="icon left">
                            <i className={`v3 icon ${icon}`}></i>
                        </div>
                        <h2>
                            {title}
                            {titleBadge &&
                                <span className="badge badge-darkgrey">{titleBadge}</span>
                            }
                        </h2>
                        {showExportButton &&
                            <div className="export-chart-btn" onClick={this.exportChart}>
                                <i className="v3 icon download"></i>
                            </div>
                        }
                    </div>

                    <div className="content padded clearfix" ref={this.chartRef}>
                        {chart}
                    </div>
                </div>
            </div>
        )
    }

}

export default ChartWrapper;

ChartWrapper.defaultProps = {
    showExportButton: true,
    showXTicks: false,
};

ChartWrapper.propTypes = {
    title: PropTypes.string,
    titleBadge: PropTypes.node,
    icon: PropTypes.string,
    chart: PropTypes.node,
    showExportButton: PropTypes.bool,
    showXTicks: PropTypes.bool,
    style: PropTypes.object,
    wrapperStyle: PropTypes.object,
};