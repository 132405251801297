import React, {useState} from 'react';
import { connect } from 'react-redux';
import Toolbox from "../../Common/Toolbox";
import {bindActionCreators} from "redux";
import {createCampaignInteraction} from "../../../actions";

function ExportToolsToolbox({onClose, campaignId, style, addlClasses, createCampaignInteraction, onClickUploadMetadata}) {
    const [isUpdatingReport, setIsUpdatingReport] = useState(false);
    const [didUpdateReport, setDidUpdateReport] = useState(false);
    const [reportUpdateError, setReportUpdateError] = useState(null);
    const handleUpdateReport = () => {
        setIsUpdatingReport(true);
        setReportUpdateError(null);
        createCampaignInteraction(campaignId, 'CaptureStats', {})
            .then((resp) => {
                console.log(resp);
                setIsUpdatingReport(false);
                setDidUpdateReport(true);
                if (resp && resp.meta && resp.meta.error) {
                    setReportUpdateError(resp.meta.error);
                    setDidUpdateReport(false);
                }
            })
            .catch((error) => {
                setReportUpdateError(error);
                setIsUpdatingReport(false);
            });
    }
    let reportUpdateBtnText = 'Update Campaign Report';
    if (isUpdatingReport) {
        reportUpdateBtnText = 'Updating...';
    }
    if (didUpdateReport) {
        reportUpdateBtnText = 'Report Updated';
    }
    if (reportUpdateError) {
        reportUpdateBtnText = 'Error: ' + reportUpdateError;
    }

    return (
        <Toolbox
            title={'Export/Import Tools'}
            style={style}
            addlClasses={addlClasses}
            onClose={onClose}
            content={
                <div>
                    <div className='fake-li condensed'>
                        <a href={`/manage/do/widget/campaign/exportv2?id=${campaignId}`}>Download
                            Activations to Excel</a>
                    </div>
                    <div className='fake-li condensed'>
                        <a href={`/manage/do/widget/campaign/export?id=${campaignId}&format=xlsx&download=1`}>Download
                            Stats to Excel</a>
                    </div>
                    <div className='fake-li condensed'>
                        <a href={`/manage/do/widget/campaign/exportShipping?id=${campaignId}&format=xlsx&download=1`}>Download Shipping List (Beta)</a>
                    </div>
                    <div className='fake-li condensed'>
                        <a href={`/manage/do/widget/campaign/exportSocialHandles?id=${campaignId}&format=xlsx&download=1`}>Download Social Handles</a>
                    </div>
                    <div className='fake-li condensed'>
                        <a href={`/manage/do/widget/campaign/exportppt?id=${campaignId}`}>Download Powerpoint
                            Presentation</a>
                    </div>
                    <div className='fake-li condensed'>
                        <a role="button" onClick={onClickUploadMetadata}>Upload Tracking Numbers</a>
                    </div>
                    <div className='fake-li condensed'>
                        <a role="button" onClick={handleUpdateReport}>{reportUpdateBtnText}</a>
                    </div>
                </div>
            }
        />
    )
}

const mapDispatchToProps = (dispatch) => bindActionCreators({createCampaignInteraction}, dispatch);
export default connect(undefined, mapDispatchToProps)(ExportToolsToolbox);
