import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ConnectedUserListsTable from '../../../containers/User/ListTable';

export default class UserDetailListsPane extends Component {


    render() {

        return (

            <div className={'user-detail-body-lists'}>
                <ConnectedUserListsTable
                    user={this.props.user}
                    tableClass="table table-spaced"
                />
                <div className="add-to-list-btn-wrapper">
                    <a
                        href="#"
                        className="btn tidal-btn btn-primary"
                        onClick={this.props.onClickAddToList}
                    >Add To A List <i className="fa fa-angle-down" /></a>
                </div>
            </div>

        );
    }

}

UserDetailListsPane.propTypes = {
    user: PropTypes.object.isRequired,
    onClickAddToList: PropTypes.func.isRequired,
};
