import React, {Component} from 'react';
import PropTypes from 'prop-types'

class TabBar extends Component {

    static propTypes = {
        tabs: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            isVisible: PropTypes.bool,
            title: PropTypes.string.isRequired,
        })).isRequired,

        activeTab: PropTypes.string.isRequired,
        onClickTab: PropTypes.func,
    };

    handleClickTab(tab) {

        if (this.props.onClickTab) {
            return this.props.onClickTab(tab.key);
        }

    }

    renderTab(tab) {

        const isActive = this.props.activeTab === tab.key;

        if (typeof tab.isVisible !== 'undefined' && !tab.isVisible) {
            return null;
        }

        return (
            <li
                className={isActive ? 'active' : ''}
                key={`tab-item-${tab.key}`}
            >
                <a
                    role="button"
                    onClick={this.handleClickTab.bind(this, tab)}
                >{tab.title}</a>
            </li>

        );

    }

    render() {
        return (

            <ul className="nav nav-tabs tidal-nav-tabs">
                {this.props.tabs.map(tab => this.renderTab(tab))}
            </ul>
        );

    }
}

export default TabBar;
