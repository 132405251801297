import React, {Component} from 'react';
import PropTypes from 'prop-types';
import UserStarRating from '../../../containers/User/StarRating';
import {formatNumberK, preventDefaultIfPossible, c3ColorPattern, proxyImageUrl} from "../../../utilities";
import HamburgerMenu from '../../Common/DetailView/HamburgerMenu';
import NavigationButtons from '../../Common/DetailView/NavigationButtons';
import SocialHeader from '../../Common/DetailView/SocialHeader';

export default class UserDetailHeader extends Component {

    constructor(props) {
        super(props);
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
        this.handleCloseLightbox = this.handleCloseLightbox.bind(this);
        this.handleOpenNextUser = this.handleOpenNextUser.bind(this);
        this.handleOpenPrevUser = this.handleOpenPrevUser.bind(this);
    }

    handleOpenPrevUser(e) {
        preventDefaultIfPossible(e);
        if (this.props.openPrevUser) {
            this.props.openPrevUser();
        }
    }

    handleOpenNextUser(e) {
        preventDefaultIfPossible(e);
        if (this.props.openNextUser) {
            this.props.openNextUser();
        }
    }

    handleCloseLightbox(e) {
        preventDefaultIfPossible(e);
        if (this.props.handleCloseLightbox) {
            this.props.handleCloseLightbox();
        }
    }

    handleToggleMenu(e) {
        preventDefaultIfPossible(e);
        if (this.props.onMenuToggle) {
            this.props.onMenuToggle(!this.props.showMenu);
        }
    }

    renderAvatar() {

        const {basics} = this.props.user;
        let style = {};

        if (basics.picture) {
            let url = basics.picture;
            if (this.props.printMode) {
                url = proxyImageUrl(url);
            }
            style.backgroundImage = 'url(' + url + ')';
        } else {
            style.background = '#EEE';
        }

        return (
            <div className="user-detail-avatar-wrapper">
                <div className="user-detail-avatar" style={style}></div>
            </div>
        );
    }

    renderSocialIcons() {
        const {user} = this.props;
        const {basics, profile} = user;
        const {verified_reach, overall_avg_engagement_pct, tier, age} = basics;
        const {instagram_followers, twitter_reach, blog_traffic, facebook_followers, pinterest_followers, youtube_subscribers, facebook_page_followers} = profile;
        const totalFacebookFollowers = (Number(facebook_followers) || 0) + (Number(facebook_page_followers) || 0);
        const {profileCompletionPct} = this.props;

        //console.log(profileCompletionPct);

        const primary = [
            this.props.showProfileCompletionPct ? ({
                key: 'profile_completion_pct',
                title: '% Complete',
                value: profileCompletionPct
            }) : null,
            verified_reach > 0 ? ({
                title: 'Total Reach',
                value: verified_reach
            }) : null,
        ];

        const secondary = [
            !!overall_avg_engagement_pct && overall_avg_engagement_pct > 0 ? {
                title: 'engagement',
                value: overall_avg_engagement_pct,
                icon: 'v3 icon comments'
            } : null,
            !!instagram_followers ? {
                title: 'instagram',
                value: formatNumberK(instagram_followers),
                icon: 'v3 icon instagram'
            } : null,
            !!twitter_reach ? {
                title: 'twitter',
                value: formatNumberK(twitter_reach),
                icon: 'v3 icon twitter'
            } : null,
            !!blog_traffic ? {
                title: 'blog',
                value: formatNumberK(blog_traffic),
                icon: 'v3 icon rss',
            } : null,
            !!totalFacebookFollowers ? {
                title: 'facebook',
                value: formatNumberK(totalFacebookFollowers),
                icon: 'v3 icon facebook'
            } : null,
            !!pinterest_followers ? {
                title: 'pinterest',
                value: formatNumberK(pinterest_followers),
                icon: 'v3 icon pinterest'
            } : null,
            !!youtube_subscribers ? {
                title: 'youtube',
                value: formatNumberK(youtube_subscribers),
                icon: 'v3 icon youtube'
            } : null,
            !!tier ? {
                title: 'tier',
                value: tier,
                icon: 'v3 icon fire'
            } : null,
            !!age ? {
                title: 'age',
                value: age
            } : null,
        ];

        return <SocialHeader
            primary={primary}
            secondary={secondary}
            showProfileCompletionPct={this.props.showProfileCompletionPct}
            showSecondaryStats={this.props.showSecondaryStats}
        />
    }

    renderListIcons() {

        const {user, listsById, badgesById} = this.props;
        const badges = (user.profile.badgeIds || []).map(id => badgesById.items[id]).filter(b => !!b);
        const lists = (user.listEntries || []).map(entry => listsById.lists[entry.list_id]).filter(l => !!l);

        if (badges.length === 0 && lists.length === 0) return null;

        const getStyle = index => {
            const color = c3ColorPattern.pattern[index % c3ColorPattern.pattern.length];
            return {backgroundColor: color};
        };

        return (
            <div className="user-detail-list-icons pull-right">
                {lists.slice(0, 10).map((list, index) => {
                    const listName = (list || {}).name || 'Unnamed List';
                    return (
                        <div key={`user-detail-list-icons-${listName}`}
                            onClick={this.props.onClickLists}
                            data-tooltip={'List: ' + listName}
                            className="list-icon"
                            style={getStyle(index)}
                        >{listName[0]}</div>
                    );
                })}

                {badges.slice(0, 5).map((badge, index) =>
                    <div
                        style={getStyle(index + lists.length)}
                        onClick={this.props.onClickLists}
                        data-tooltip={'Badge: ' + badge.name}
                        className="list-icon">{badge.name[0]}</div>
                )}
            </div>

        );
    }

    render() {
        const {user, listContext, openNextUser, openPrevUser, handleCloseLightbox, onSelectUser, showMenu, menuContent, showStarRating, onClickEditBasics} = this.props;
        const {basics} = user;
        let classes = ['col-xs-12 user-detail-header'];

        if (this.props.onSelectUser) {
            classes.push('selectable')
        }

        let actionsClasses = ['user-detail-actions'];

        if (this.props.showMenu) {
            actionsClasses.push('cover')
        }

        return (
            <div className="row user-detail-header-row">
                <div className={classes.join(' ')}>

                    { this.props.showActions &&
                        <div className={actionsClasses.join(' ')}>

                            {
                                this.props.showMenuIcon &&
                                <HamburgerMenu
                                    content={menuContent}
                                    onClose={this.handleToggleMenu}
                                    title='Actions & Tools'
                                    toggleMenu={this.handleToggleMenu}
                                    expanded={showMenu}
                                    onCheckboxClick={onSelectUser}
                                />
                            }

                            <NavigationButtons
                                onClose={handleCloseLightbox}
                                handleClickForward={openNextUser ? this.handleOpenNextUser : null}
                                handleClickBackward={openPrevUser ? this.handleOpenPrevUser : null}
                            />
                        </div>
                    }

                    {this.renderAvatar()}

                    <div className="user-detail-header-basics">

                        <h6 className="section-header nomargin v3 bold">
                            {basics.name}
                        </h6>

                        <p className="v3 h9 light">
                            {basics.human_location}
                        </p>

                        { showStarRating &&
                            <UserStarRating
                                user={user}
                                list={listContext}
                            />
                        }

                        { onClickEditBasics &&
                            <a onClick={onClickEditBasics} role="button" className="edit-profile-link basics">Edit</a>
                        }

                    </div>

                    {this.renderSocialIcons()}

                    {
                        this.props.showListsIcons &&
                        this.renderListIcons()
                    }

                </div>
            </div>
        );
    }
}

UserDetailHeader.defaultProps = {
    showNextPrevBtns: true,
    showCloseBtn: true,
    showMenuIcon: true,
    showStarRating: true,
    showProfileCompletionPct: false,
    showSecondaryStats: true,
    showActions: true,
};

UserDetailHeader.propTypes = {
    user: PropTypes.object,
    showMenu: PropTypes.bool,
    onMenuToggle: PropTypes.func,
    menuContent: PropTypes.node.isRequired,
    handleCloseLightbox: PropTypes.func,
    onClickLists: PropTypes.func,
    openNextUser: PropTypes.func,
    openPrevUser: PropTypes.func,
    listsById: PropTypes.object,
    badgesById: PropTypes.object,
    listContext: PropTypes.object,
    onSelectUser: PropTypes.func,

    showMenuIcon: PropTypes.bool,
    showListsIcons: PropTypes.bool,
    showStarRating: PropTypes.bool,
    showProfileCompletionPct: PropTypes.bool,
    showSecondaryStats: PropTypes.bool,
    showActions: PropTypes.bool,
    profileCompletionPct: PropTypes.string,

    onClickEditBasics: PropTypes.func,
    printMode: PropTypes.bool
};