import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../actions/activations";
import {getCampaign} from "../../selectors/campaigns";
import {fetchActivation} from "../../actions";
import Toolbox from '../Common/Toolbox';
import Button from '../Common/Form/Button';
import Alert from '../Common/Alert';
import _find from 'lodash/find';
import {fetchCampaignProducts} from "../../actions/products";
import {getCatalogProducts} from "../../selectors/products";
import ConnectedCampaignProductsTable from '../../containers/Products/CampaignProductsTable';

class AssignProductToolbox extends Component {
    static defaultProps = {
        showStockAvailable: false,

    };

    static propTypes = {
        activation: PropTypes.object,
        campaign: PropTypes.object,
        onSaved: PropTypes.func,
        createInteraction: PropTypes.func,
        fetchProducts: PropTypes.func,
        fetchActivation: PropTypes.func,
        showStockAvailable: PropTypes.bool,
    };

    state = {
        isAssigning: false,
        didAssign: false,
    };

    componentDidMount() {

        const {campaign, fetchProducts} = this.props;
        fetchProducts(campaign.id);

    }

    handleAssign(product, shouldSelect = true) {

        const {activation, createInteraction, fetchActivation, fetchProducts, campaign, onSaved} = this.props;
        this.setState({isAssigning: true, hasError: false});
        const payload = {product_id: product.id, select: shouldSelect};

        return createInteraction(activation.id, 'SelectProduct', payload)
            .then(dispatched => {
                if (dispatched && dispatched.meta && dispatched.meta.error) {
                    this.setState({hasError: true, error: dispatched.meta.error});
                }
            })
            .catch(err => {
                this.setState({hasError: true, error: err.message});
            })
            .then(() => fetchActivation(activation.id))
            .then(() => fetchProducts(campaign.id))
            .then(() => {
                this.setState({isAssigning: false, didAssign: !this.state.hasError})
            })
            .then(() => {
                if (!this.state.hasError && onSaved) {
                    onSaved();
                }
            });

    }

    renderTable() {
        let alert = null;
        const alertStyle = {
            position: 'sticky',
            top: 0,
            zIndex: 200
        };

        if (this.state.didAssign) {
            alert = <Alert classes={['success']} content={'Product selected!'} style={alertStyle} />
        }

        if (this.state.isAssigning) {
            alert = <Alert classes={['info']} content={'Selecting product...'} style={alertStyle} />
        }

        if (this.state.hasError) {
            alert = <Alert classes={['danger']} content={"Couldn't select product! Did you try to select more than the allowed number of products?"} style={alertStyle} />
        }

        return (
            <div>
                {alert}
                <ConnectedCampaignProductsTable
                    assignedProductIds={(this.props.activation.products || []).map(p => p.id)}
                    campaignId={this.props.campaign.id}
                    catalogId={this.props.campaign.settings.product_catalog_id}
                    onClickAssign={(coupon, select) => {
                        this.handleAssign(coupon, select);
                    }}
                    showStockAvailable={this.props.showStockAvailable}
                />
            </div>
        );
    }

    render() {

        return this.renderTable();

    }
}

const mapStateToProps = (state, props) => {

    const campaign = getCampaign(state, {campaignId: props.activation.campaign_id});
    return {
        campaign: campaign,
        products: getCatalogProducts(state, {catalogId: (campaign.settings || {}).product_catalog_id}),
    };

};

const mapDispatchToProps = (dispatch) => {
    return {

        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchProducts: (id) => dispatch(fetchCampaignProducts(id)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),

    };

};

const ConnectedAssignProductToolbox = connect(mapStateToProps, mapDispatchToProps)(AssignProductToolbox);

ConnectedAssignProductToolbox.propTypes = {
    activation: PropTypes.object.isRequired,
    campaignId: PropTypes.number.isRequired,
    onSaved: PropTypes.func,
    showStockAvailable: PropTypes.bool,
};

export default ConnectedAssignProductToolbox;