import React, {Component} from 'react';
import PropTypes from 'prop-types';
import C3Chart from 'react-c3js';
import d3 from 'd3';
import {c3ColorPattern, countries} from '../../../utilities';

export default class SocialLocationsChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loaded: false
        };
    }

    componentDidMount() {
        let promises = [];

        this.props.socials.forEach(social => {
            let promise = this.props.fetchSocialReport(social.id)
            if (promise) {
                promises.push(promise);
            }
        });

        Promise.all(promises).then(() => {
            this.setState({loading: false, loaded: true});
        });
    }

    getFilteredSocials() {
        return (this.props.socials || []).filter(social => {
            return typeof social.report !== 'undefined' && social.report !== null;
        });
    }

    getData() {
        const socials = this.getFilteredSocials();

        if (socials.length === 0) {
            return null;
        }

        let totalCount = 0;
        let countryStubs = {};
        let cityStubs = {};
        let normalizedCities = [];
        let normalizedCountries = [];
        let xs = {};

        for (let social of socials) {
            totalCount += parseInt(social.report.follower_locations.total_count, 10);

            for (let [countryCode, stats] of Object.entries(social.report.follower_locations.countries)) {
                let country = countries[countryCode] || countryCode;
                countryStubs[country] = (countryStubs[country] || 0) + parseInt(stats.count, 10);
            }

            for (let [city, stats] of Object.entries(social.report.follower_locations.cities)) {
                cityStubs[city] = (cityStubs[city] || 0) + parseInt(stats.count, 10);
            }
        }

        for (let piece in countryStubs) {
            let count = countryStubs[piece];
            let pct = count / totalCount;
            normalizedCountries.push({
                location: piece,
                pct: pct,
                count: count
            });
        }

        for (let piece in cityStubs) {
            let count = cityStubs[piece];
            let pct = count / totalCount;
            normalizedCities.push({
                location: piece,
                pct: pct,
                count: count
            });
        }

        normalizedCountries.sort((a, b) => parseFloat(a.pct) > parseFloat(b.pct) ? -1 : 1 );
        normalizedCities.sort((a, b) => parseFloat(a.pct) > parseFloat(b.pct) ? -1 : 1 );

        normalizedCountries = normalizedCountries.slice(0, this.props.limit);
        normalizedCities = normalizedCities.slice(0, this.props.limit);

        let columns = normalizedCountries.map(locdata => {
            xs[locdata.location] = 'x_country';
            return [
                locdata.location,
                locdata.pct
            ];
        }).concat(
            normalizedCities.map(locdata => {
                xs[locdata.location] = 'x_city';
                return [
                    locdata.location,
                    locdata.pct
                ];
            })
        );

        if (columns.length === 0) {
            return null;
        }

        columns.push(
            ['x_country', 'Top Countries'],
            ['x_city', 'Top Cities'],
        );


        let out = {
            unload: true,
            xs: xs,
            type: 'bar',
            columns
        };

        return out;
    }

    renderChart() {
        if (this.state.loading === true || this.state.loaded === false) return null;
        const data = this.getData();
        if (!data) return null;

        const axis = {
            rotated: false,
            x: {type: 'category'},
            y: {
                tick: {
                    format: d3.format('%')
                }
            }
        };

        const tooltip = {
            format: {
                value: (value, ratio, id) => {
                    return d3.format('%')(value);
                }
            }
        };

        return <C3Chart
            data={data}
            color={c3ColorPattern}
            axis={axis}
            tooltip={tooltip}
            size={this.props.size}
            legend={{show: false}}
            bar={{width: {ratio: 0.9}}}
            transition={{duration: 0}}
        />


    }

    render() {

        const data = this.getData();
        // No data -- don't render
        if (data === null && this.state.loaded === true && this.state.loading === false) return null;

        const style = this.props.style;
        return (

            <div className="widget" style={style}>
                <div className="title">
                    <div className="icon left">
                        <i className="fa fa-line-chart"></i>
                    </div>
                    <h2>
                        Audience Geography
                    </h2>
                </div>
                <div className="content padded clearfix">
                    {this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
                </div>
            </div>

        );
    }


}

SocialLocationsChart.defaultProps = {
    socials: [],
    style: {width: '580px', height: '350px', marginTop: 0, flex: 'none'},
    size: {height: 270},
    limit: 6,
};

SocialLocationsChart.propTypes = {
    socials: PropTypes.array,
    style: PropTypes.object,
    size: PropTypes.object,
    fetchSocialReport: PropTypes.func,
    limit: PropTypes.number
};
