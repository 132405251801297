/**
 * Created by bkroger on 10/18/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";
import {countries} from "../../../../utilities";
import moment from 'moment';
import _ from 'lodash';
import Select from "../../../Common/Form/Select";

const REQUIRED_FORM_FIELDS = ['name'];

class Basics extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
        refreshUser: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        isProfileFieldVisible: PropTypes.func.isRequired,
        translate: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {},
            isUpdating: false,
            didUpdate: false,
            hasError: false,
            error: null,
            hasEmptyFields: false,
        };

        this.handleMissingFields = this.handleMissingFields.bind(this);
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
            hasError: false,
            error: null,
            hasEmptyFields: false
        });
    }

    handleSelectChange(name, option) {
        this.handleFieldChange(name, option.value);
    }

    handleMissingFields(obj) {
        for (let val in obj) {
            if (!this.props.isProfileFieldVisible(val)) {
                continue;
            }
            if (obj[val] === null || obj[val] === '') {
                return true;
            }
        }
        return false;
    }

    handleClickSave() {
        this.setState({hasError: false, error: null, isUpdating: true, didUpdate: false, hasEmptyFields: false});

        /**
         * Check for empty fields and update state if any exist
         */
        // get all native values for the user along with any entered in the form
        const nativeValues = this.props.user || {};
        const formValues = this.state.form;
        let obj = {...nativeValues, ...formValues};

        // filter the values object to include only those relevant to this page's form
        obj = _.pick(obj, REQUIRED_FORM_FIELDS);
        console.log(obj);

        if (this.handleMissingFields(obj) === true) {
            this.setState({
                isUpdating: false,
                hasEmptyFields: true
            });
        } else {
            this.setState({hasEmptyFields: false});
            this.props.createInteraction(this.props.user.id, 'UpdateBasics', this.state.form)
                .then(resp => {
                    if (resp.meta.error) {
                        this.setState({isUpdating: false, hasError: true, error: resp.meta.error})
                    } else {
                        this.props.refreshUser(this.props.user.id);
                        this.setState({isUpdating: false, didUpdate: true});
                        setTimeout(() => this.setState({form: {}, didUpdate: false}), 2000);
                    }
                });
        }

    }

    getFieldValues() {
        const nativeValues = this.props.user || {};
        const formValues = this.state.form;

        let obj = {...nativeValues, ...formValues};

        if (obj.birthday_day) obj.birthday_day = parseInt(obj.birthday_day, 10);
        if (obj.birthday_month) obj.birthday_month = parseInt(obj.birthday_month, 10);
        if (obj.birthday_year) obj.birthday_year = parseInt(obj.birthday_year, 10);

        return obj;
    }

    handleRemoveLocation() {
        this.setState({isUpdating: true, didUpdate: false});

        this.props.createInteraction(this.props.user.id, 'RemoveLocation', this.state.form)
            .then(() => this.props.refreshUser(this.props.user.id))
            .then(() => this.setState({
                form: {
                    city_name: '',
                    state_name: '',
                    zipcode: ''
                },
                isUpdating: false,
                didUpdate: true
            }))
            .then(() => setTimeout(() => this.setState({form: {}, didUpdate: false}), 2000));
    }

    renderSaveAlert() {

        const _t = this.props.translate;
        let style = {
            position: 'sticky',
            top: 0,
            zIndex: 200,
        };

        if (this.state.isUpdating) {
            return <Alert
                classes={['info']} content={_t('contribute.profile.saving', "Saving your changes...")} style={style}
            />
        }

        if (this.state.didUpdate) {
            return <Alert
                classes={['success']} content={_t('contribute.profile.saved', "Changes saved!")} style={style}
            />
        }

        if (this.state.hasError) {
            return <Alert
                classes={['danger']} content={this.state.error} style={style}
            />
        }

        if (this.state.hasEmptyFields) {
            return <Alert
                classes={['danger']} content={_t('contribute.profile.missingfields', "Some fields are missing information")} style={style}
            />
        }

        if (Object.keys(this.state.form).length > 0) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>{_t('contribute.profile.unsavedchanges', 'You have unsaved changes.')} <a className="v3 bold" onClick={this.handleClickSave.bind(this)} role="button">{_t('contribute.profile.savenow', 'Save now.')}</a></span>}
                    style={style}
                />
            );
        }
    }

    render() {
        const _t = this.props.translate;
        const MONTHS = [
            _t('months.january', 'January'),
            _t('months.february', 'February'),
            _t('months.march', 'March'),
            _t('months.april', 'April'),
            _t('months.may', 'May'),
            _t('months.june', 'June'),
            _t('months.july', 'July'),
            _t('months.august', 'August'),
            _t('months.september', 'September'),
            _t('months.october', 'October'),
            _t('months.november', 'November'),
            _t('months.december', 'December')
        ];

        const maxBirthYear = (moment().year()) - 14;
        const isVisible = this.props.isProfileFieldVisible;

        return (
            <div className="pane-inner-content basics">
                {this.renderSaveAlert()}
                <Form
                    values={this.getFieldValues()}
                    onFieldChange={this.handleFieldChange.bind(this)}
                    style={{paddingBottom: 0}}
                    fields={[
                        isVisible('name') ? {
                            type: 'text',
                            name: 'name',
                            title: _t('contribute.basics.name.title', 'Your Name'),
                            help: _t('contribute.basics.name.help', 'Your real name. Any checks we write you will be made out to this name.'),
                            placeholder: '',
                        } : null,
                        isVisible('display_name') ? {
                            type: 'text',
                            name: 'display_name',
                            title: _t('contribute.basics.publicname.title', 'Public Name'),
                            help: _t('contribute.basics.publicname.help', 'The name we\'ll display publicly for you.'),
                            placeholder: '',
                        } : null,
                        isVisible('gender') ? {
                            type: 'select',
                            name: 'gender',
                            title: _t('contribute.basics.gender.title', 'Gender'),
                            help: '',
                            choices: [
                                {text: _t('gender.female', 'Female'), value: 'female'},
                                {text: _t('gender.male', 'Male'), value: 'male'},
                                {text: _t('gender.nonbinary', 'Non-Binary'), value: 'non-binary'},
                                {text: _t('gender.other', 'Other'), value: 'other'},
                                {text: _t('gender.unspecified', 'Unspecified / Prefer Not To Say'), value: 'unspecified'},
                            ],
                        } : null,
                        {
                            type: 'generic',
                            name: 'birthday',
                            title: _t('contribute.basics.birthdate.title', 'Your Birthdate'),
                            help: _t('contribute.basics.birthdate.help', 'Required in order to be eligible for free samples and payments.'),
                            content:
                                <div className="birthdate-select-group">
                                    {isVisible('birthday_month') && <Select
                                        value={this.getFieldValues().birthday_month}
                                        options={MONTHS.map((month, index) => ({text: month, value: index+1}))}
                                        onChange={this.handleSelectChange.bind(this, 'birthday_month')}
                                        defaultValue={'- Select Month -'}
                                    />}
                                    {isVisible('birthday_day') && <Select
                                        value={this.getFieldValues().birthday_day}
                                        options={_.range(1,31,1).map(d => ({text: d, value: d}))}
                                        onChange={this.handleSelectChange.bind(this, 'birthday_day')}
                                        defaultValue={'- Select Day -'}
                                    />}
                                    {isVisible('birthday_year') && <Select
                                        value={this.getFieldValues().birthday_year}
                                        options={_.range(1901, maxBirthYear, 1).map(y => ({text: y, value: y}))}
                                        onChange={this.handleSelectChange.bind(this, 'birthday_year')}
                                        defaultValue={'- Select Year -'}
                                    />}
                                </div>
                        },
                        isVisible('phone') ? {
                            type: 'text',
                            name: 'phone',
                            title: _t('contribute.basics.phone.title', 'Phone Number'),
                            help: _t('contribute.basics.phone.help', 'Required for most campaigns. We\'ll send an SMS if we can\'t reach you via email.'),
                        } : null,
                        isVisible('country_code') ? {
                            type: 'select',
                            name: 'country_code',
                            title: _t('contribute.basics.country.title', 'Country'),
                            help: '',
                            choices: Object.keys(countries).map(code => ({text: countries[code], value: code})),
                            options: {
                                defaultValue: "- " + _t('contribute.basics.country.default', "Select your Country") + " -",
                            },
                        } : null,
                        isVisible('city_name') ? {
                            type: 'text',
                            name: 'city_name',
                            title: _t('contribute.basics.city.title', 'City'),
                        } : null,
                        isVisible('state_name') ? {
                            type: 'text',
                            name: 'state_name',
                            title: _t('contribute.basics.state.title', 'State'),
                        } : null,
                        isVisible('zipcode') ? {
                            type: 'text',
                            name: 'zipcode',
                            title: _t('contribute.basics.zipcode.title', 'Zip Code'),
                        } : null,
                    ]}
                />
                <p className={'v3 light h7 pane-info'} style={{paddingTop: 0, paddingBottom: 20}}>
                    {_t('contribute.profile.locationconsent', 'Adding your location to your profile will help brands find you easier. We do not show your zip code to brands. If you would like to have your location removed from our database, click the following link')}: <a role="button" onClick={this.handleRemoveLocation.bind(this)}>{_t('contribute.basics.removelocation', 'Remove Location')}</a>
                </p>
            </div>
        );
    }

}

export default Basics;
