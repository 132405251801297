/**
 * Created by visgotti on 9/29/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

const POST_SORT_OPTIONS = [
    {
        label: 'Sort by Newest',
        sort: {
            by: 'written',
            dir: 'desc',
        },
    },

    {
        label: 'Sort by Oldest',
        sort: {
            by: 'written',
            dir: 'asc',
        },
    },

    {
        label: 'Sort by Engagements',
        sort: {
            by: 'engagements',
            dir: 'desc',
        },
    },

    {
        label: 'Sort by Views',
        sort: {
            by: 'views',
            dir: 'desc',
        },
    },

    {
        label: 'Sort by Rating',
        sort: {
            by: 'rating',
            dir: 'desc',
        },
    },

    {
        label: 'Sort by Title A-Z',
        sort: {
            by: 'title',
            dir: 'asc',
        },
    },

    {
        label: 'Sort by Title Z-A',
        sort: {
            by: 'title',
            dir: 'desc',
        },
    },

];
export default class PostsHeaderDropdownMenus extends Component {

    constructor(props){
        super(props);

        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleActionChange = this.handleActionChange.bind(this);
    }

    handleSortChange(event) {
        const target = event.target;
        const key = target.name;
        const value = JSON.parse(target.value);
        this.props.changeSort(value, true);
    }

    handleActionChange(event) {
        const value = event.target.value;
        this.props.applyAction(value)
    }

   renderActionDropdown() {
        return(
            <div className="form-group tidal-form">
                <select className="form-control"
                    onChange={this.handleActionChange}
                >
                    <option value> Actions </option>
                    <option value="send to olapic"> Send To Olapic </option>
                    <option value="send to content metric">Send to Content Metric</option>
                    <option value="unpublish">Unpublish</option>
                    <option value="download">Download</option>
                </select>
            </div>
        )
   }

    renderSortByDropdown() {
        return(
            <div className="form-group tidal-form">
                <select
                    className="form-control"
                    onChange={this.handleSortChange}
                    value={JSON.stringify(this.props.currentSort)}
                >
                    <option value>- Sort by -</option>
                    {
                        POST_SORT_OPTIONS.map(datum => {
                            return(
                                <option
                                    name={datum.label}
                                    value={JSON.stringify(datum.sort)}
                                >
                                    {datum.label}
                                </option>
                            )
                        })
                    }
                </select>
            </div>
        )
   }

    render() {
        // hide the actions for now so just return null.
        return (
            <span className="post-dropdowns pull-left">
                { this.props.showActions && this.renderActionDropdown() }
                { this.props.showSort && this.renderSortByDropdown() }
            </span>
        )
    }
}

PostsHeaderDropdownMenus.propTypes = {
    changeSort: PropTypes.func,
    applyAction: PropTypes.func,
    showSort: PropTypes.bool,
    showActions: PropTypes.bool,
    currentSort: PropTypes.object
};

PostsHeaderDropdownMenus.defaultProps = {
    changeSort: () => {},
    applyAction: () => {},
    showSort: true,
    showActions: false,
};


