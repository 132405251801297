import {RECEIVE_CAMPAIGN_REQUIREMENTS, UPDATED_CAMPAIGN_REQUIREMENT, UPDATE_CAMPAIGN_REQUIREMENT, DELETE_CAMPAIGN_REQUIREMENT, DELETED_CAMPAIGN_REQUIREMENT} from "../actions/requirements";
import {indexArrayById} from "../utilities";

export function campaignRequirementsById(state = {}, action) {
    switch (action.type) {

        case DELETED_CAMPAIGN_REQUIREMENT: {
            const newState = {...state};
            delete newState[action.requirementId];
            return newState;
        }

        case UPDATED_CAMPAIGN_REQUIREMENT:
        case DELETE_CAMPAIGN_REQUIREMENT:
            return {
                ...state,
                [action.requirementId]: {
                    ...state[action.requirementId],
                    isUpdating: false
                }
            };

        case UPDATE_CAMPAIGN_REQUIREMENT:
            return {
                ...state,
                [action.requirementId]: {
                    ...state[action.requirementId],
                    isUpdating: true
                }
            };

        case RECEIVE_CAMPAIGN_REQUIREMENTS:
            return {
                ...state,
                ...indexArrayById(action.data)
            };

        default:
            return state;
    }
}