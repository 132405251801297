import React, {Component} from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

class Row extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            wasEverVisible: false,
            rowWidth: 1440,
        };
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    }

    componentDidMount() {
        this.calculateRowWidth();
    }

    componentDidUpdate(prevProps) {
        const prevVisible = (prevProps.visibleColumns || []).join(' ');
        const nowVisible = (this.props.visibleColumns || []).join(' ');

        if (nowVisible !== prevVisible) {
            this.calculateRowWidth();
        }

    }

    calculateRowWidth() {
        let width = 0;
        this.getVisibleColumns().forEach(col => {
            const _w = col.width || 80;
            width = width + _w;
        });
        this.setState({rowWidth: width});

    }

    handleVisibilityChange(isVisible) {

        let payload = {isVisible};

        if (isVisible === true) {
            payload.wasEverVisible = true;
        }

        this.setState({...payload}, () => {
            if (this.props.onVisibilityChange) {
                this.props.onVisibilityChange(this.makeRowData());
            }
        });

    }

    isColumnVisible(key) {
        const visible = this.getVisibleColumnKeys();
        return visible.indexOf(key) > -1;
    }

    getVisibleColumnKeys() {

        const {visibleColumns, columns} = this.props;

        if (visibleColumns) {
            return visibleColumns;
        }

        return columns.filter(col => !!col && !!col.default).map(col => col.key);
    }

    getVisibleColumns() {
        return this.props.columns.filter(col => !!col && this.isColumnVisible(col.key));
    }

    /**
     * @deprecated
     * @param key
     * @returns {null}
     */
    getColumnSpecByKey(key) {
        const {columns} = this.props;
        const filtered = columns.filter(col => !!col && col.key === key);
        if (filtered.length > 0) {
            return filtered[0];
        }
        return null;
    }

    /**
     * @deprecated
     * @param key
     */
    renderColumnByKey(key) {
        const spec = this.getColumnSpecByKey(key);
        if (typeof spec.cell === 'function') {
            return spec.cell(this.makeRowData(), spec);
        }
    }

    renderColumn(col) {
        if (typeof col.cell === 'function') {
            return col.cell(this.makeRowData(), col);
        }
    }

    renderColumns() {
        return (
            <div className="primary">
                {this.getVisibleColumns()
                    .map(col => this.renderColumn(col))}
            </div>
        );
    }

    /**
     * @returns {{
     *   item: Object,
     *   index: number,
     *   isVisible: boolean,
     *   wasEverVisible: boolean,
     *   isSelected: boolean,
     *   isExpanded: boolean
     * }}
     */
    makeRowData() {
        const {item, index, isRowSelected, isRowExpanded} = this.props;
        const {isVisible, wasEverVisible} = this.state;

        let row = { item, index, isVisible, wasEverVisible };

        row.isSelected = isRowSelected(row);
        row.isExpanded = isRowExpanded(row);

        return row;
    }

    getClassName() {
        let classes = ['godzilla-table-row'];
        const row = this.makeRowData();

        if (row.isSelected) {
            classes.push('selected');
        }

        if (row.isExpanded) {
            classes.push('expanded');
        }

        return classes.join(' ');
    }

    renderExpansion() {
        const row = this.makeRowData();
        const {expander} = this.props;

        if (!expander || !row.isExpanded) {
            return null;
        }

        return (
            <div
                className="expansion"
                ref={this.props.onExpanderRefReady}
            >
                {expander(row)}
            </div>
        );
    }

    getStyle() {

        return {
            width: this.state.rowWidth,
        };

    }

    render() {
        return (
            <VisibilitySensor
                scrollCheck
                onChange={this.handleVisibilityChange}
                partialVisibility={true}
                intervalCheck={true}
                intervalDelay={1000}
                key={`row-visibility-${this.props.index}-${this.props.item.id}`}
            >
                <div
                    className={this.getClassName()}
                    style={this.getStyle()}
                >
                    {this.renderColumns()}
                    {this.renderExpansion()}
                </div>
            </VisibilitySensor>
        );
    }
}

Row.defaultProps = {
    isRowSelected: () => false,
    isRowExpanded: () => false,
};

Row.propTypes = {
    item: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    visibleColumns: PropTypes.array,
    index: PropTypes.number,
    isRowSelected: PropTypes.func,
    isRowExpanded: PropTypes.func,
    expander: PropTypes.func,
    onExpanderRefReady: PropTypes.func,
    onVisibilityChange: PropTypes.func,
};

export default Row;