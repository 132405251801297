import React, {useEffect} from 'react';
import {NavLink, BrowserRouter} from 'react-router-dom';
import LangSwitcherNavButton from "../../Common/LangSwitcherNavButton";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {translate} from "../../../actions/i18n";
import {fetchPresence} from "../../../actions/presence";
import {getCurrentChannel, getPresenceTeam} from "../../../selectors/presence";

function MACTopNav({translate, channel, team, didFetchi18n}) {

    if (!channel || !didFetchi18n) {
        // For some reason, in this separate component, translations don't render if we don't have them on first call, so delay render
        return null;
    }

    /**
     * Logo logic:
     * - First look at team.creator_logo_url
     * - If that doesn't exist, look at channel.creator_logo_url
     * - If that doesn't exist, use the ELC logic (see below)
     * - ELC Logic: if channel.slug === 'bobbi-brown', use bobbi-brown-black.png, otherwise use mac-header-white.png
     */
    let logoUrl = null;

    if (team && team.creator_logo_url) {
        logoUrl = team.creator_logo_url;
    }

    if (!logoUrl && channel && channel.creator_logo_url) {
        logoUrl = channel.creator_logo_url;
    }

    /**
     * TODO: Eventually remove this ELC logic and use creator_logo_url settings exclusively
     */
    if (!logoUrl && (channel && channel.slug === 'bobbi-brown')) {
        logoUrl = 'https://cdn.tid.al/bobbi-brown/bobbi-brown-black.png';
    }

    if (!logoUrl) {
        logoUrl = 'https://cdn.tid.al/img/mac/mac-header-white.png';
    }

    const LINKS = [
        {
            href: "/contribute/do/page/dash/home",
            text: translate('mac.topnav.dash', "Dash"),
            className: "dashboard"
        },
        {
            href: "/contribute/do/page/dash/briefs",
            text: translate('mac.topnav.briefs', "Briefs"),
            className: "briefs"
        },
        {
            href: "/contribute/do/page/dash/community",
            text: translate('mac.topnav.community', "Community"),
            className: "community"
        },
        {
            href: "/contribute/do/page/dash/trending",
            text: translate('mac.topnav.trending', "Trending"),
            className: "trending"
        },
        {
            href: "/contribute/do/page/dash/achievements",
            text: translate('mac.topnav.reporting', "Reporting"),
            className: "reporting"
        },
        {
            href: "/contribute/do/page/dash/profile",
            text: <i className="v3 icon user" />,
            className: "editprofile"
        },
        {
            href: "/contribute/user/logout",
            text: <i className="v3 icon sign-out" />,
            className: "logout"
        },

    ];

    return (
        <div className="row navigation">
            <div className="container">
                <div className="row">

                    <>
                        <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4">

                            <ul className="navigation-menu">
                                <li>
                                    <a href="/contribute" className="logo">
                                        <img src={logoUrl} />
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <a id="hamburger-menu-toggle" className="fa fa-bars mobile-nav" style={{marginRight: 15}}></a>
                        <div id="hamburger-menu-toggled" className="mobile-nav"></div>

                        <ul id="hamburger-menu" className="mobile-nav row pull-right">

                            {LINKS.map((spec,i) => (
                                <li key={spec.className+i} className={"nav-li-loggedin " + spec.className}>
                                    <a href={spec.href} className="button">{spec.text}</a>
                                </li>
                            ))}

                            <li className="nav-li-loggedin lang">
                                <LangSwitcherNavButton />
                            </li>

                        </ul>

                        <ul className="tidal-js-menu" style={{float: "right"}}>

                            {LINKS.map((spec,i) => (
                                <li key={spec.className+i} className={"nav-li-loggedin " + spec.className}>
                                    <a href={spec.href} className="button">{spec.text}</a>
                                </li>
                            ))}

                            <li className="nav-li-loggedin lang">
                                <LangSwitcherNavButton />
                            </li>

                        </ul>
                    </>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => ({
    channel: getCurrentChannel(state, props),
    team: getPresenceTeam(state, props),
    didFetchi18n: state.i18n.didFetch
});

const mapDispatchToProps = dispatch => bindActionCreators({
    translate: translate,
    fetchPresence: fetchPresence
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MACTopNav);