/**
 * Created by visgotti on 9/26/17.
 */

import React, {Component} from 'react';
import {formatNumber} from '../../../utilities';
import PropTypes from 'prop-types';

export default class PostsHeaderCheckbox extends Component {

    renderCount() {
        const {hits} = this.props;
        if (!hits) return null;
        const formatted = formatNumber(hits);
        return (
            <span className="badge badge-darkgrey large rightmargin">{formatted + ' Posts'}</span>
        );
    }

    renderCheckbox() {
        const {allSelected} = this.props;
        const checkboxClass = "tidal-checkbox " + (allSelected ? 'checked' : '');
        const icon = allSelected ? <i className="fa fa-check"></i> : null;

        return <span className={checkboxClass} onClick={this.props.onSelectAll}>
            {icon}
        </span>
    }

    render() {
        return (
            <h2 className="section-header">
                {/*{this.renderCheckbox()}*/}
                {this.renderCount()}
            </h2>

        )
    }
}

PostsHeaderCheckbox.propTypes = {
    allSelected: PropTypes.bool,
    onSelectAll: PropTypes.func,
    hits: PropTypes.number,
};

PostsHeaderCheckbox.defaultProps = {
    allSelected: false,
    onSelectAll: null
};