import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GenericCategoryBarChart from '../../Chart/GenericCategoryBarChart';
import {ucwords} from "../../../utilities";
import d3 from 'd3';

export default class CampaignAffinityCategoryBarChart extends Component {

    renderChart() {

        const {limit, seriesName} = this.props;

        const orderAndSliceAffinities = obj =>
            Object.entries(obj || {})
                .sort((a, b) => {
                    const aVal = parseFloat(a[1], 10), bVal = parseFloat(b[1], 10);
                    return aVal > bVal ? -1 : 1;
                })
                .slice(0, limit)
                .reduce((o, item) => {
                    const title = ucwords(item[0].replace('interest: ', ''));
                    o[title] = item[1];
                    return o;
                }, {});

        const data = orderAndSliceAffinities(this.props.data);

        return <GenericCategoryBarChart
            data={data}
            seriesName={seriesName}
            tooltip={{
                format: {
                    value: (value, ratio, id) => {
                        const format = d3.format('.0%');
                        return format(value);
                    }
                }
            }}
            yAxisTickFormatter={d3.format('.0%')}
        />

    }

    render() {

        const {style, title, iconClass} = this.props;

        return (
            <div className="widget" style={style}>
                <div className="title">
                    <div className="icon left">
                        <i className={iconClass}></i>
                    </div>
                    <h2>
                        {title}
                    </h2>
                </div>
                <div className="content padded clearfix">
                    {this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
                </div>
            </div>
        )
    }

}

CampaignAffinityCategoryBarChart.defaultProps = {
    iconClass: 'fa fa-heart',
    limit: 8
};

CampaignAffinityCategoryBarChart.propTypes = {
    data: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    seriesName: PropTypes.string.isRequired,

    style: {},
    iconClass: PropTypes.string,
    limit: PropTypes.number
};
