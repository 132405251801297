import React, {useEffect, useMemo, useState} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../../actions/auth";
import ICTopicSection from "./ICTopicSection";
import {translate} from "../../../actions/i18n";

function DashboardCommunity({fetchAuthenticated, translate}) {
    const [topics, setTopics] = useState(null);
    const fetchTopics = async () => {
        const resp = await fetchAuthenticated('/contribute/api/innercircle/_/topic');
        const json = await resp.json();
        setTopics(json.data);
    };

    useEffect(() => {
        fetchTopics()
    }, []);

    return (topics || []).map(topic => {
        return <ICTopicSection topic={topic} key={topic.id} fetchAuthenticated={fetchAuthenticated} translate={translate} />;
    });
}
export default connect(undefined, function (dispatch) {
    return bindActionCreators({
        fetchAuthenticated: fetchAuthenticated,
        translate: translate
    }, dispatch);
})(DashboardCommunity);
