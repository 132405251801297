import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";
import {socialTypeFromNiceName, socialNameForType} from "../../../../utilities/social";

class SubmitSocialPostUrl extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        campaign: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
        refreshActivation: PropTypes.func,
        fetchAuthenticated: PropTypes.func,
        createInteraction: PropTypes.func,
    };

    state = {
        url: '',

        isFetchingSubmittedPosts: false,
        submittedPosts: null,

        isSubmitting: false,
        didSubmit: false,
    };

    componentDidMount() {
        this.fetchSubmittedPosts();
    }

    fetchSubmittedPosts() {
        const {activation} = this.props;
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/activation/${activation.id}/post`;

        this.setState({isFetchingSubmittedPosts: true});

        return this.props.fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => this.setState({submittedPosts: json.data}))
            .then(() => this.setState({isFetchingSubmittedPosts: false}))
    }

    handleSubmitLink() {
        this.setState({isSubmitting: true});

        const ctx = {url: this.state.url, social_type: this.props.type};
        this.props.createInteraction(this.props.activation.id, 'SubmitSocialPostUrl', ctx)
            .then(() => {
                this.setState({
                    isSubmitting: false,
                    didSubmit: true,
                    url: '',
                });
                this.fetchSubmittedPosts();
                this.props.refreshActivation();
            });
    }

    renderAlert() {

        return (
            <Alert
                classes={['info']}
                content={`Facebook is performing maintenance on our integration, so for now please submit the URL to your Facebook post. You will be asked to reconnect your Facebook account in the near future.`}
                style={{marginBottom: 20}}
            />

        );

    }

    renderForm() {
        return (
            <Form
                usePadding={false}
                values={{url: this.state.url}}
                onFieldChange={(k, v) => this.setState({[k]: v})}
                fields={[
                    {
                        type: 'text',
                        name: 'url',
                        placeholder: 'https://www.facebook.com/your-post',
                        title: 'Your Post Link',
                        help: 'Submit the URL to your post here.'
                    },
                    {
                        type: 'button',
                        title: (this.state.isSubmitting ? 'Submitting Link...' : 'Submit Link'),
                        options: {
                            onClick: () => this.handleSubmitLink(),
                            hideTitle: true,
                            classes: ['v3', 'btn-primary']
                        }
                    }

                ]}
            />
        );
    }

    renderPreviouslySubmitted() {

        if (this.state.isFetchingSubmittedPosts || this.state.submittedPosts === null) {
            return null;
        }

        let typeToCheck = this.props.type;
        if (typeToCheck === 'Tidal\\Social\\FacebookPage') {
            typeToCheck = 'Tidal\\Social\\Facebook';
        }

        const posts = (this.state.submittedPosts || [])
            .filter(post => post.type && (socialTypeFromNiceName(post.type) === typeToCheck));

        if (!posts || posts.length === 0) {
            return null;
        }

        const niceName = socialNameForType(this.props.type);

        return (
            <div>
                <p>You've submitted the following {niceName} posts:</p>
                <ul>
                    {posts.map(post => <li key={`post-url-${post._id}`}>{post.permalink}</li>)}
                </ul>

                <p>To remove a post, please raise an issue using the "Have an Issue?" button.</p>
            </div>
        )


    }

    render() {
        return (

            <div>
                {this.renderAlert()}
                {this.renderForm()}
                {this.renderPreviouslySubmitted()}

            </div>

        );
    }
}

export default SubmitSocialPostUrl;

