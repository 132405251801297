/**
 * Created by visgotti on 9/13/17.
 */

import {
    REQUEST_POSTS,
    REQUEST_POSTS_FAILED,
    RECEIVE_POST,
    RECEIVE_POSTS,
    CHANGE_POST_FILTER,
    REMOVE_POST_FILTER,
    RESET_POST_FILTERS,
    CHANGE_POST_SORT,
    CHANGE_POST_LIMIT,
    CHANGE_POST_PAGE,
    SELECT_POST,
    UNSELECT_POST,
    SELECT_ALL_POSTS,
    UNSELECT_ALL_POSTS,
} from '../actions';



const DEFAULT_FILTERS = {
    status: 'all',
    rating: null,
    featured: false,
    type: null,
};

const DEFAULT_SORT = {
    by: 'written',
    dir: 'desc',
};

const DEFAULT_LIMIT = 15;

export const DEFAULT_POST_QUERY = {
    needsFetching: false,
    isFetching: false,
    meta: {},
    allPostsSelected: false,
    queryId: 1,
    page: 1,
    limit: DEFAULT_LIMIT,
    filters: { ...DEFAULT_FILTERS},
    sort: {...DEFAULT_SORT},
    postIds: [],
    selectedPostIds: [],
};

export const DEFAULT_POST_QUERIES = {
    isLoaded: false,
    currentId: 1,
    queries: { }
};


export function postQueries( state = DEFAULT_POST_QUERIES, action ) {

    if (typeof action.queryId === 'undefined') {
        return state;
    }

    const existingQuery = {...(state.queries[action.queryId] || DEFAULT_POST_QUERY), queryId: action.queryId};

    switch (action.type) {
        case RESET_POST_FILTERS:
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        filters: { ...DEFAULT_FILTERS},
                        sort: { ...DEFAULT_SORT },
                        limit: DEFAULT_LIMIT,
                        page: 1,
                    },
                }
            }
        case CHANGE_POST_FILTER:
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        filters: {
                            ...existingQuery.filters,
                            [action.filter.key]: action.filter.value
                        },
                        needsFetching: action.needsFetching,
                    },
                }
            };
        case CHANGE_POST_SORT:
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        sort: {
                            ...existingQuery.sort,
                            by: action.by,
                            dir: action.dir,
                        },
                        needsFetching: action.needsFetching,
                    },
                }
            };
        case REMOVE_POST_FILTER: {
            const newState = {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        filters: {
                            ...existingQuery.filters,
                        },
                        needsFetching: action.needsFetching,
                    },
                }
            }
            delete newState.queries[action.queryId].filters[action.key];
            return newState;
        }
        case CHANGE_POST_PAGE: {
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        page: action.page,
                        needsFetching: true,
                    },

                }
            }
        }
        case CHANGE_POST_LIMIT: {
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        limit: action.limit,
                        needsFetching: action.needsFetching,
                    },
                }
            }
        }
        case REQUEST_POSTS:
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        needsFetching: false,
                        isFetching: true,
                        filters: action.filters,
                        sort: action.sort,
                        page: action.page,
                        limit: action.limit
                    },
                }
            };
        case RECEIVE_POST:
            const newPostIds = [...state.queries[action.queryId].postIds];
            newPostIds.push(action.postId);
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        postIds: newPostIds,
                    },
                }
            };
        case RECEIVE_POSTS:
            return {
                ...state,
                isLoaded: true,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        postIds: [],
                        meta: action.meta,
                        isFetching: false,
                    },
                }
            };
        case REQUEST_POSTS_FAILED:
            return {
                ...state,
                isLoaded: true,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        postIds: [],
                        isFetching: false,
                    },
                }
            };
        case SELECT_POST:
            if(state.queries[action.queryId].selectedPostIds.indexOf(action.postId) > -1) return state;
            let newArray = [...state.queries[action.queryId].selectedPostIds]
            newArray.push(action.postId)

            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        selectedPostIds: newArray,
                    }
                }
            };
        case UNSELECT_POST:
            if(!(state.queries[action.queryId].selectedPostIds.indexOf(action.postId) > -1)) return state;
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        selectedPostIds: state.queries[action.queryId].selectedPostIds.filter((postId) => postId !== action.postId),
                    }
                }
            };
        case SELECT_ALL_POSTS:
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        allPostsSelected: true
                    }
                }
            };
        case UNSELECT_ALL_POSTS:
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {
                        ...existingQuery,
                        allPostsSelected: false
                    }
                }
            };
        default: return state;
    }
}
