import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from './Toolbox';

export default class SectionalToolbox extends Component {

    static defaultProps = {
        style: {},
        embedded: false,
        sections: []
    };

    static propTypes = {
        addlClasses: PropTypes.array,
        style: PropTypes.object,
        onClose: PropTypes.func,
        title: PropTypes.node,
        embedded: PropTypes.bool,
        accordion: PropTypes.bool,
        sections: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            title: PropTypes.node,
            content: PropTypes.node,
            isExpanded: PropTypes.bool,
            padded: PropTypes.bool,
            contentStyle: PropTypes.object,
        }))
    };


    constructor(props) {
        super(props);
        this.state = {
            expandedSections: {}
        };

        this.toggleSection = this.toggleSection.bind(this);
        this.isSectionExpanded = this.isSectionExpanded.bind(this);
        this.renderSection = this.renderSection.bind(this);
    }

    componentDidMount() {

        // Expand any sections that are expanded by default
        for (let section of this.props.sections) {

            if (!section) {
                continue;
            }

            if (section.isExpanded) {
                this.toggleSection(section.key);
            }
        }

    }

    getClassNames() {
        let classes = ['sectional-toolbox', this.props.addlClasses];
        return classes.join(' ');
    }

    toggleSection(key) {
        let newSections = { };
        if (!this.props.accordion) {
            newSections = {...this.state.expandedSections}
        }
        newSections[key] = !this.isSectionExpanded(key);
        this.setState({
            expandedSections: newSections
        });
    }

    isSectionExpanded(key) {
        return !!this.state.expandedSections[key];
    }

    renderContent() {
        return this.props.sections.map(this.renderSection);
    }

    renderSection(spec) {

        if (!spec) return;
        const isExpanded = this.isSectionExpanded(spec.key);
        const icon = isExpanded
            ? <i className="fa fa-angle-down" />
            : <i className="fa fa-angle-right" />

        const padded = typeof spec.padded !== 'undefined' ? spec.padded : true;

        let sectionStyle = {};
        let titleStyle = {};
        let contentStyle = spec.contentStyle || {};

        if (!padded) {
            sectionStyle.padding = 0;
            titleStyle.padding = 15;
            contentStyle.marginTop = 0;
        }

        return (

            <div className="toolbox-section" style={sectionStyle}>
                <h2 role="button" onClick={this.toggleSection.bind(this, spec.key)} style={titleStyle}><a role="button">{spec.title} {icon}</a></h2>
                {isExpanded && <div className="section-content" style={contentStyle}>{spec.content}</div>}
            </div>

        );
    }

    render() {
        return (

            <Toolbox
                addlClasses={this.getClassNames()}
                title={this.props.title}
                onClose={this.props.onClose}
                style={this.props.style}
                embedded={this.props.embedded}
                content={this.renderContent()}
            />

        );
    }
}
