import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../Common/Alert';
import Button from '../../Common/Form/Button';
import Form from '../../Common/Form/Form';
import { getCampaignsByDate } from '../../../selectors/campaigns';
import connect from 'react-redux/es/connect/connect';

class BulkReactivate extends Component {
    constructor(props) {
        super(props);
        this.state={
            campaign_id: null
        }
    }

    static defaultProps = {
        isUpdating: false,
    };

    static propTypes = {
        onReactivate: PropTypes.func,
        activationIds: PropTypes.array.isRequired,
        isUpdating: PropTypes.bool,
    };

    handleFieldChange(name, value) {
        this.setState({[name]: value});
    }

    renderAlert() {
        const count = this.props.activationIds.length;
        const influencers = count === 1 ? 'user' : 'users';
        return [
            <Alert
                classes={['info']}
                content={<span>Add {count} {influencers} to another campaign.</span>}
            />
        ];
    }

    renderForm() {
        return <Form
            values={{campaign_id: this.state.campaign_id}}
            onFieldChange={this.handleFieldChange.bind(this)}
            fields={[
                {
                    type: 'generic',
                    content: this.renderAlert()
                },
                {
                    type: 'select',
                    name: 'campaign_id',
                    title: "Select a Campaign",
                    choices: [
                        {text: '- Select a Campaign -', value: null},
                        ...this.props.campaigns.map(c => ({text: c.name, value: c.id}))
                    ],
                },
                {
                    type: 'generic',
                    content: this.renderButton()
                }
            ]}
        />
    }

    renderButton() {
        const {isUpdating} = this.props;
        const style = {
            textAlign: 'center',
            padding: 20
        };
        const count = this.props.activationIds.length;
        const influencers = count === 1 ? 'User' : 'Users';

        return (
            <div style={style}>
                <Button
                    content={`Add ${count} ${influencers} to campaign`}
                    classes={['v3', 'large', 'btn-primary', (isUpdating ? 'disabled' : '')]}
                    onClick={isUpdating ? null : this.props.onReactivate(this.state.campaign_id)}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="activation-status-change-form">
                {this.renderForm()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        campaigns: getCampaignsByDate(state, props),
    };
};

const ConnectedBulkReactivate = connect(mapStateToProps, null)(BulkReactivate);
export default ConnectedBulkReactivate;
