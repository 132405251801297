import React from 'react';

export const rechartsDonutChartLabels = (labelFormatter, formatPct) => {
    return ({cx, cy, midAngle, innerRadius, outerRadius, percent, value}) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        let output = formatPct ? labelFormatter(percent) : labelFormatter(value);

        return (
            <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central" fontSize="11">
                {percent >= .05 &&
                    output
                }
            </text>
        );
    };
};