import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../Common/Alert';
import SubmitInstastoryVideo from './SubmitInstastoryVideo';
import Button from '../../../Common/Form/Button';

import {connect} from 'react-redux';
import Form from "../../../Common/Form/Form";
import {socialNameForType, socialTypeFromNiceName} from "../../../../utilities/social";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../../../actions/auth";

class SubmitTiktok extends Component {

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        refreshActivation: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        fetchAuthenticated: PropTypes.func.isRequired,
    };

    state = {
        keybase: 0,
        isUploading: false,
        isRemoving: false,
        didUploadSuccessfully: false,
        isFetchingSubmittedPosts: false,
        submittedPosts: null,
        interactionPayload: {
            'video_ids': [],
            'tiktok_username': '',
            'url': '',
            'views': 0,
            'likes': 0,
            'comments': 0,
            'shares': 0,
        },
        error: null
    };

    componentDidMount() {
        this.fetchSubmittedPosts();
    }

    fetchSubmittedPosts() {
        const {activation} = this.props;
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/activation/${activation.id}/post`;

        this.setState({isFetchingSubmittedPosts: true});

        return this.props.fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => this.setState({submittedPosts: json.data}))
            .then(() => this.setState({isFetchingSubmittedPosts: false}))
    }

    isUploading(val) {
        this.setState({isUploading: val});
    }

    renderAlert() {

        if (this.state.error) {

            return <Alert
                classes={['danger']}
                content={"Error uploading TikTok video: " + this.state.error}
                style={{marginBottom: 20}}
            />;

        }

        const requiredCount = (this.props.activation.settings || {}).num_tiktok_posts;
        const uploadedCount = (this.props.activation.status || {}).num_tiktok_posts_uploaded;
        const {isUploading, isRemoving} = this.state;
        const isFulfilled = uploadedCount >= requiredCount;

        let content = `You are required to post ${requiredCount} TikTok videos. So far you have uploaded ${uploadedCount}. Please upload related MP4 videos and screenshots below.`;

        if (isUploading) {
            content = 'Uploading your content...';
        }

        if (isRemoving) {
            content = 'Removing content...';
        }

        return <Alert
            classes={[isFulfilled ? 'success' : 'info']}
            content={content}
            style={{marginBottom: 20}}
        />;



    }

    /**
     * Updates our state array for tracking video ids
     * @param json
     * @return json
     */
    handleVideoSubmit(json) {
        //Collect video_ids and push them into the interactionPayload.video_ids
        let payload = {...this.state.interactionPayload};

        payload.video_ids.push(json.data.id);

        this.setState({
            // Resets the overall flag so validation errors come back for 2nd upload
            didUploadSuccessfully: false,
            isUploading: false,
            interactionPayload: payload
        });

        return json;
    }

    /**
     * Responsible for calling the backend interaction for submitting all Instastory data
     *
     * @param json
     */
    handleSubmit(event) {
        const {activation} = this.props;
        const {interactionPayload} = this.state;

        this.setState({
            isUploading: true,
        });

        return this.props.createInteraction(activation.id, 'SubmitTiktok', interactionPayload)
            .then((resp) => {

                if (resp.meta.error) {
                    this.setState({
                        error: resp.meta.error,
                        isUploading: false,
                    });
                    window.scrollTo(0, 0);
                } else {
                    this.props.refreshActivation(activation.id);
                    this.fetchSubmittedPosts();

                    this.setState({
                        isUploading: false,
                        isRemoving: false,
                        didUploadSuccessfully: true,
                        error: null,
                        interactionPayload: {
                            'video_ids': [],
                            'views': 0,
                            'likes': 0 ,
                            'comments': 0,
                            'shares': 0,
                            'tiktok_username': '',
                            'url': ''
                        },
                        keybase: this.state.keybase + 1
                    });
                }
            });
    }

    getInvalidFields() {
        let invalid = [];

        const videosPer = 1;

        const {views, comments, likes, shares, url, tiktok_username, video_ids} = this.state.interactionPayload;

        // If you just finished uploading don't show invalid fields
        if (this.state.didUploadSuccessfully) {
            return invalid;
        }

        if (!views || views === '0' || views === 0 || views === '') {
            invalid.push('views');
        }

        if (!likes || likes === '0' || likes === 0 || likes === '') {
            invalid.push('likes');
        }

        if (!comments || comments === '0' || comments === 0 || comments === '') {
            invalid.push('comments');
        }

        if (!shares || shares === '0' || shares === 0 || shares === '') {
            invalid.push('shares');
        }

        if (!url || url === '') {
            invalid.push('url');
        }

        if (!tiktok_username || tiktok_username === '') {
            invalid.push('tiktok_username');
        }

        if (video_ids.length < videosPer) {
            invalid.push('video_ids');
        }

        return invalid;
    }

    isFormValid() {
        return this.getInvalidFields().length === 0;
    }


    renderPreviouslySubmitted() {

        if (this.state.isFetchingSubmittedPosts || this.state.submittedPosts === null) {
            return null;
        }

        const posts = (this.state.submittedPosts || [])
            .filter(post => post.type && post.type === 'tiktok');

        if (!posts || posts.length === 0) {
            return null;
        }

        const niceName = socialNameForType('tiktok');

        return (
            <div style={{
                margin: '20px 0',
                padding: '20px 0',
                borderTop: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
            }}>
                <h5 className="v3 bold action-pane-title">Previously Submitted</h5>
                <p>You've submitted the following {niceName} posts:</p>
                <ul>
                    {posts.map(post => <li key={`post-url-${post._id}`}>{post.permalink}</li>)}
                </ul>

                <p>To remove a post, please raise an issue using the "Have an Issue?" button.</p>
            </div>
        )


    }
    /**
     * Render our widget for uploading videos and images related to instastories
     */

    render() {
        const requiredCount = (this.props.activation.settings || {}).num_tiktok_posts;
        const videosPer = 1;
        const isValid = this.isFormValid();

        let submitContent = (
            <span>
            {this.state.isUploading ? <i className="fa fa-spinner fa-spin" /> : ''} Submit TikTok Video
            </span>
        );

        if (this.state.didUploadSuccessfully) {
            submitContent = 'TikTok Video Submitted!';
        }

        return (
            <div className="influencer-page-template submit-tiktok">
                <div className="widget">
                    <div className="title">
                        <h2>Upload TikTok Video</h2>
                    </div>
                    <div className="content padded">
                        {this.renderAlert()}

                        <h4 className="v3 bold action-pane-title">Submit TikTok Video</h4>

                        <p className="v3 light h7 pane-info"
                           style={{marginBottom: 20}}
                        >
                            <strong><i className="v3 icon info" /> How to Submit: </strong>
                            You are required to create and post <strong>{requiredCount} TikTok video{requiredCount === 1 ? '' : 's'}</strong>. First, upload and post the video to TikTok. Then return here, and <em>for each</em> post, upload {videosPer} video{videosPer === 1 ? '' : 's'} and stats for that post.</p>
                        <p className="v3 light h7 pane-info"
                           style={{marginBottom: 40}}
                        >
                            Once you've uploaded the videos and screenshots, use the "Submit TikTok Video" button at the bottom. Need help? Use the "Have an Issue?" button or <a href="http://docs.tid.al/" target="_blank"><strong>visit the help center</strong></a>.

                        </p>

                        {this.renderPreviouslySubmitted()}

                        <SubmitInstastoryVideo
                            videosRequired={1}
                            videoType={'TikTok'}
                            key={`vid-submit-${this.state.keybase}`}
                            isUploading={this.isUploading.bind(this)}
                            onSubmit={this.handleVideoSubmit.bind(this)}
                            campaign={this.props.campaign}
                            activation={this.props.activation}
                            refreshActivation={this.props.refreshActivation} />

                        <Form

                            invalidFields={this.getInvalidFields()}
                            usePadding={false}
                            onFieldChange={(key, value) => {
                                this.setState(prevState => {
                                    return {
                                        // Reset this flag so validation errors come back for 2nd upload
                                        didUploadSuccessfully: false,
                                        interactionPayload: {
                                            ...prevState.interactionPayload,
                                            [key]: value
                                        }
                                    };
                                });
                            }}
                            values={this.state.interactionPayload}
                            fields={[
                                {
                                    type: 'text',
                                    name: 'tiktok_username',
                                    title: 'TikTok Username',
                                    help: 'Provide your TikTok username, you can find it as part of your profile URL.',
                                    placeholder: 'eg: @bkbkbk1212'
                                },
                                {
                                    type: 'text',
                                    name: 'url',
                                    title: 'TikTok Post URL',
                                    help: 'The URL to the TikTok video. You can use the app\'s share function to get the URL, or get it from the address bar in your web browser.',
                                    placeholder: 'eg: https://www.tiktok.com/@bkbkbk1212/video/6908810174329392389?lang=en&is_copy_url=1&is_from_webapp=v1'
                                },
                                {
                                    type: 'number',
                                    name: 'likes',
                                    title: 'Number of Likes',
                                    help: "Report the number of likes of your TikTok post."
                                },
                                {
                                    type: 'number',
                                    name: 'views',
                                    title: 'Number of Views',
                                    help: "Report the number of views of your TikTok post."
                                },
                                {
                                    type: 'number',
                                    name: 'shares',
                                    title: 'Number of Shares',
                                    help: "Report the total number of shares on your TikTok post."
                                },
                                {
                                    type: 'number',
                                    name: 'comments',
                                    title: 'Number of Comments',
                                    help: "Report the total number of comments on your TikTok post."
                                },
                            ]}
                        />

                        <Button
                            content={submitContent}
                            classes={['v3', 'btn-primary', 'large', (!isValid ? 'disabled' : '')]}
                            onClick={isValid ? this.handleSubmit.bind(this) : undefined}
                        />

                        {(!isValid
                            ? <Alert
                                classes={['danger']}
                                content={"Please fill out all fields."}
                                style={{marginTop: 20}}
                            />
                            : null)}
                    </div>
                </div>
            </div>

        );
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({fetchAuthenticated: fetchAuthenticated}, dispatch);

export default connect(null, mapDispatchToProps)(SubmitTiktok);
