import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Video extends Component {

    constructor(props) {
        super(props);
        this.state = {
            didError: false,
        };
        this.handleError = this.handleError.bind(this);
    }

    handleError(e) {
        if (this.props.onError) {
            this.props.onError(e);
        } else {
            this.setState({didError: true});
        }
    }

    renderVideoFileSrcTags() {
        const { src } = this.props;
        const webmUrl = src;
        const mp4Url = src.replace(/\/webm\/720p\//, '/mp4/ios/').replace(/.webm$/, '.mp4');
        return <>
            <source src={webmUrl} type={'video/webm; codecs="vp8, vorbis"'} />
            <source src={mp4Url} type={'video/mp4; codecs="avc1.64001E, mp4a.40.2"'} />
        </>
    }

    renderVideo() {
        const { src, style, embed, provider, className, controls=true, autoplay=false, muted=false } = this.props;
        const defaultStyle = {maxWidth: '100%', maxHeight: '320px', width: '100%', ...style};
        // If provider is file, instagram, or tiktok, render video tag
        const videoProviders = ['file', 'instagram', 'tiktok', 'other'];
        console.log("provider: ", provider)

        if (provider && videoProviders.indexOf(provider) > -1) {
            return (
                <video
                    controls={controls}
                    autoPlay={autoplay}
                    muted={muted}
                    style={defaultStyle}
                    className={className}
                    onError={(e)=>this.handleError(e)}
                    // expose ref as a prop
                    ref={this.props.videoRef}
                >
                    {this.renderVideoFileSrcTags()}
                </video>
            );
        } else {
            // return <div className={className} style={defaultStyle} dangerouslySetInnerHTML={{__html: embed}}/>;
            return <iframe
                className='provider-video'
                src={src}
                style={defaultStyle}
                title={src}
                frameBorder='0'
                onError={(e)=>this.handleError(e)}
            />;
        }
    }

    render() {
        return (
            <div className = 'tidal-video-wrapper'>
                {this.renderVideo()}
            </div>
        )
    }
}

Video.propTypes = {
    style: PropTypes.object,
    src: PropTypes.string,
    embed: PropTypes.node,
    onError: PropTypes.func,
    provider: PropTypes.string,
    className: PropTypes.string,
    controls: PropTypes.bool,
    autoplay: PropTypes.bool,
    muted: PropTypes.bool,
    videoRef: PropTypes.object,
};

Video.defaultProps = {
    className: 'tidal-video',
};
