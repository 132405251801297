import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ConnectedEditProfile from "./EditProfile";
import _debounce from 'lodash/debounce';

class ResponsiveEditProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
        };

        this.checkIsMobile = this.checkIsMobile.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.debouncedHandleWindowResize = _debounce(this.handleWindowResize);
    }

    componentDidMount() {
        window.addEventListener('resize', this.debouncedHandleWindowResize, false);
        this.checkIsMobile();
    }

    checkIsMobile() {
        this.setState({
            isMobile: window.innerWidth <= 991
        });
    }

    handleWindowResize() {
        this.checkIsMobile();
    }

    render() {
        const {userId, padding} = this.props;

        return <ConnectedEditProfile userId={userId} padding={padding} isMobile={this.state.isMobile} />
    }

}

export default ResponsiveEditProfile;

ResponsiveEditProfile.defaultProps = {
    padding: false,
};

ResponsiveEditProfile.propTypes = {
    userId: PropTypes.number.isRequired,
    padding: PropTypes.bool,
};