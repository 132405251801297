import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from '../../Common/Toolbox';
import Form from '../../Common/Form/Form';

export default class FilterPopup extends Component {
    constructor(props){
        super(props);
        this.getFormFields = this.getFormFields.bind(this);
    }

    static defaultProps = {
        style: {},
        filters: {},
    };

    static propTypes = {
        onFilterChange: PropTypes.func,
        fields: PropTypes.array,
        style: PropTypes.object,
        filters: PropTypes.object,
        onResetSearch: PropTypes.func
    };

    getFormFields(){
        let fields = [...this.props.fields];
        const { onResetSearch } = this.props;
        if (onResetSearch) {
            fields.push(
                {
                    type: 'generic',
                    content: <div style={{cursor: 'pointer'}} onClick={onResetSearch}> Reset Filter
                        <i className='fa fa-redo pull-right' style={{marginTop: '4px'}}/>
                    </div>
                }
            )
        }
        return fields;
    }

    render() {
        return <Toolbox
            style={{
                minHeight: 50,
                boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                border: '1px solid #EEE',
                borderTop: 'none',
                ...this.props.style
            }}
            content={ <Form
                style={{paddingBottom: 0}}
                fields={this.getFormFields()}
                onFieldChange={this.props.onFilterChange}
                values={this.props.filters}
                /> }
        />
    }
}