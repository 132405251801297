/**
 * Created by bkanber on 8/7/17.
 */

import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    fetchUserProfileIfNeeded,
    fetchUserIfNeeded,
    fetchBadgesIfNeeded,
    fetchListsIfNeeded,
    fetchUser, fetchUserProfile, fetchUserStats
} from '../../actions';
import {DEFAULT_USER_STATE} from '../../reducers/users';
import UserDetail from '../../components/User/Detail/index';
import {
    fetchUserActivationsIfNeeded, fetchUserActivationStatsIfNeeded,
    fetchUserPostsIfNeeded, fetchUserInterestsIfNeeded
} from "../../actions/index";
import {fetchSocialPostsIfNeeded, fetchSocialReportIfNeeded, fetchUserSocialsIfNeeded} from "../../actions/socials";
import {blockUser, createUserInteraction, fetchUserListsIfNeeded} from "../../actions/users";
import {fetchAuthenticated} from "../../actions/auth";
import {getCurrentChannel} from "../../selectors/presence";
import {getTeams} from "../../selectors/teams";

const mapStateToProps = (state, ownProps) => {

    const {userId} = ownProps;
    const {campaignsById, postsById, activationsById, socialsById, socialPostsById, activationStatsById, listsById, badgesById} = state;
    const user = state.usersById[userId] || DEFAULT_USER_STATE;
    const posts = user.postIds.map(postId => postsById[postId]);
    const activations = user.activationIds.map(aid => activationsById[aid]);
    const activationStats = user.activationIds.map(activationId => activationStatsById[activationId]);
    const teams = getTeams(state, ownProps);

    // Socials with posts attached.
    const socials = user.socialIds.map(sid => {
        let social = socialsById[sid] || {};
        return {
            ...social,
            posts: (social.postIds || []) .map(postId => socialPostsById[postId])
        };
    });
    const channel = getCurrentChannel(state, ownProps);

    return {
        user,
        posts,
        activations,
        activationStats,
        socials,
        campaignsById,
        listsById,
        badgesById,
        channel,
        teams
    };
}


const mapDispatchToProps = (dispatch, ownProps) => {
    const {userId} = ownProps;
    const slot = window.Gator.getDashboardSlot();

    return {
        fetchUserStats: () => dispatch(fetchUserStats(userId)),
        fetchUser: () => dispatch(fetchUser(userId)),
        fetchProfile: () => dispatch(fetchUserProfile(userId)),
        fetchActivations: () => dispatch(fetchUserActivationsIfNeeded(userId)),
        fetchSocials: () => dispatch(fetchUserSocialsIfNeeded(userId)),
        fetchSocialPosts: (socialId) => dispatch(fetchSocialPostsIfNeeded(socialId)),
        fetchPosts: () => dispatch(fetchUserPostsIfNeeded(userId)),
        fetchActivationStats: () => dispatch(fetchUserActivationStatsIfNeeded(userId)),
        fetchSocialReport: (socialId) => dispatch(fetchSocialReportIfNeeded(socialId)),
        fetchUserLists: () => dispatch(fetchUserListsIfNeeded(userId)),
        fetchInterests: () => dispatch(fetchUserInterestsIfNeeded(userId)),
        fetchAudience: () => dispatch(fetchAuthenticated(`/${slot}/api/user/${userId}/audience`)),
        blockUser: (userId, block) => dispatch(blockUser(userId, block)),
        fetchBadgesIfNeeded: () => dispatch(fetchBadgesIfNeeded()),
        fetchListsIfNeeded: () => dispatch(fetchListsIfNeeded()),
        createInteraction: (id, type, context) => dispatch(createUserInteraction(id, type, context)),
    }

}

const ConnectedUserDetail = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserDetail);

ConnectedUserDetail.propTypes = {
    userId: PropTypes.number,
    handleCloseLightbox: PropTypes.func,
    currentView: PropTypes.string,
    openNextUser: PropTypes.func,
    openPrevUser: PropTypes.func,
    listContext: PropTypes.object
};

export default ConnectedUserDetail;
