import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions/campaigns";
import PropTypes from 'prop-types';
import Alert from '../Common/Alert';
import Button from '../Common/Form/Button';

class InvitationForm extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onComplete: PropTypes.func,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
    };

    state = {
        isUpdating: false,
        didUpdate: false,
        error: false,
    };

    handleButtonClick() {

        const {activation, onComplete, fetchActivation, createInteraction} = this.props;

        this.setState({isUpdating: true});

        return createInteraction(activation.id, 'Invite', [])
            .catch(err => this.setState({isUpdating: false, didUpdate: false, error: true}))
            .then(() => fetchActivation(activation.id))
            .then(() => this.setState({isUpdating: false, didUpdate: true}))
            .then(() => {
                if (onComplete) {
                    onComplete();
                }
            });

    }

    renderAlert() {

        const {activation} = this.props;
        const {didUpdate, isUpdating, error} = this.state;

        if (didUpdate) {
            return <Alert classes={['success']} content={`${activation.user.name} was successfully invited!`} />
        }

        if (isUpdating) {
            return <Alert classes={['info']} content={`Inviting ${activation.user.name}...`} />
        }

        if (error) {
            return <Alert classes={['danger']} content={`There was a problem inviting ${activation.user.name}.`} />
        }

        return <Alert classes={['info']} content={`Invite ${activation.user.name} to this campaign. Doing this will remove the user from any waitlists and reactivate the user if their activation was canceled.`} /> ;


    }

    renderButtons() {
        const {isUpdating} = this.state;

        const hasIssue = this.hasPaymentIssue();
        const style = {
            textAlign: 'center',
            padding: 20
        };

        let niceText = 'Invite ' + this.props.activation.user.name;

        if (hasIssue) {
            niceText = niceText + ' Anyway';
        }

        return (
            <div style={style}>
                <Button
                    content={niceText}
                    classes={['v3', 'large', (hasIssue ? 'btn-secondary' : 'btn-primary'), (isUpdating ? 'disabled' : '')]}
                    onClick={this.handleButtonClick.bind(this)}
                />
            </div>
        );

    }

    hasPaymentIssue() {

        const {activation} = this.props;
        return activation.settings.use_payments && !activation.payment_amount;
    }

    renderPaymentIssueAlert() {

        if (this.hasPaymentIssue()) {
            return <Alert
                classes={['danger']}
                content={"There is no payment amount configured for " + this.props.activation.user.name + "! You should set the payment amount before sending the invitation."}
            />
        }


    }

    render() {

        return (
            <div className="activation-status-change-form">
                {this.renderAlert()}
                {this.renderPaymentIssueAlert()}
                {this.renderButtons()}
            </div>
        );

    }
}


const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };
};

export default connect(null, mapDispatchToProps)(InvitationForm);
