import {makeMomentFromDate} from "../utilities";

export const getCampaigns = (state, props = {}) => Object.values(state.campaigns.byId.items);

export const getCampaignsByDate = (state, props = {}) => {
    const campaigns = getCampaigns(state, props);
    return campaigns.sort((a, b) => {
        const aDate = a.created_at || {date: '1999-01-01 00:00:00', timezone: 'America/New_York'};
        const bDate = b.created_at || {date: '1999-01-01 00:00:00', timezone: 'America/New_York'};
        const aTs = makeMomentFromDate(aDate);
        const bTs = makeMomentFromDate(bDate);
        return aTs.isAfter(bTs) ? -1 : 1;
    });
};

export const getCampaign = (state, props = {}) => {
    let campaignId = null;

    if (props.campaign && props.campaign.id) {
        campaignId = props.campaign.id;
    } else if (props.campaignId) {
        campaignId = props.campaignId;
    }

    const campaign = state.campaigns.byId.items[campaignId] || {};
    return campaign;
};

export const getCampaignCoupons = (state, props = {}) => {

    const campaign = getCampaign(state, props);
    const allCoupons = Object.values(state.campaigns.coupons || {}) || [];

    return allCoupons.filter(coupon => coupon.campaign_id === campaign.id);
};

export const getCampaignForms = (state, props = {}) => {
    let campaignId = null;

    if (props.campaign && props.campaign.id) {
        campaignId = props.campaign.id;
    } else if (props.campaignId) {
        campaignId = props.campaignId;
    }

    return Object.values(state.campaigns.forms)
        .filter(form => form.campaign_id === campaignId);

};

export const getCampaignStats = (state, props = {}) => {
    let campaignId = null;

    if (props.campaign && props.campaign.id) {
        campaignId = props.campaign.id;
    } else if (props.campaignId) {
        campaignId = props.campaignId;
    }

    return (state.campaignStatsById[campaignId] || {}).items || {};
};