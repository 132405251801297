export const LIGHTBOX_VIEW_USER_DETAIL = 'LIGHTBOX_VIEW_USER_DETAIL';
export const LIGHTBOX_VIEW_TALENTSPIDER_CANDIDATE = 'LIGHTBOX_VIEW_TALENTSPIDER_CANDIDATE';

export const OPEN_LIGHTBOX = 'OPEN_LIGHTBOX';
export const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX';
export const SET_LIGHTBOX_STACK = 'SET_LIGHTBOX_STACK';

export function makeUserLightboxStack(userIds, addlContext = {}) {
    return userIds.map(userId => openLightbox(LIGHTBOX_VIEW_USER_DETAIL, {...addlContext, id: userId}));
}
export function makeTalentspiderCandidateLightboxStack(candidates, talentspider, addlContext = {}) {
    return candidates.map(candidate => openLightbox(LIGHTBOX_VIEW_TALENTSPIDER_CANDIDATE, {...addlContext, id: candidate.id, candidate: candidate, talentspider, blog: candidate.blog}));
}

export function setLightboxStack(stack) {
    return {
        type: SET_LIGHTBOX_STACK,
        stack
    };
}

export function openTalentspiderCandidateLightbox(talentspider, candidate, blog, addlContext = {}, candidates = []) {
    return function (dispatch) {
        const ctx = {...addlContext, talentspider, candidate, blog, id: candidate.id};

        if (candidates) {
            const stack = makeTalentspiderCandidateLightboxStack(candidates, talentspider, addlContext);
            dispatch(setLightboxStack(stack));
        }

        return dispatch(openLightbox(LIGHTBOX_VIEW_TALENTSPIDER_CANDIDATE, ctx));
    }
}

export function openUserLightbox(userId, addlContext = {}, userIdsStack = null) {
    return function (dispatch) {
        const ctx = {...addlContext, id: userId};

        if (userIdsStack) {
            const stack = makeUserLightboxStack(userIdsStack, addlContext);
            dispatch(setLightboxStack(stack));
        }

        return dispatch(openLightbox(LIGHTBOX_VIEW_USER_DETAIL, ctx));
    }
}

export function openLightbox(view, context) {
    return {
        type: OPEN_LIGHTBOX,
        view,
        context
    };
}

export function closeLightbox(resetStack = true) {
    return {
        type: CLOSE_LIGHTBOX,
        resetStack
    };
}