import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Tabs extends Component {

    static propTypes = {
        /**
         * Tabs spec
         */
        tabs: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.node,
            isActive: PropTypes.bool,
            onClick: PropTypes.func,
            classes: PropTypes.array,
            style: PropTypes.object,
            badge: PropTypes.node,
        }))
    };

    renderTabs() {
        return this.props.tabs.map(this.renderTab.bind(this));
    }

    renderTab(tab) {
        if (!tab) {
            return null;
        }

        let classes = ['godzilla-table-tab', (tab.isActive ? 'active' : ''), ...(tab.classes || [])];
        return <a
            key={`tab-${tab.title}`}
            role="button"
            className={classes.join(' ')}
            onClick={tab.onClick}
            style={(tab.style || {})}
        >{tab.title} {this.renderBadge(tab)}</a>
    }

    renderBadge(tab) {
        if (!tab.badge) return;
        return <span className="badge">{tab.badge}</span>
    }

    render() {

        return <div className="godzilla-table-tabs-wrapper">
            {this.renderTabs()}
        </div>
    }
}