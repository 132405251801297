import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BasicCell from './Basic';

class LinkCell extends BasicCell {

    getValue() {
        const {value, row, onClick, target, href} = this.props;
        let content;

        if (typeof value === 'function') {
            content = value(row);
        } else {
            content = value;
        }

        return <a
            className="dark"
            onClick={onClick}
            role="button"
            href={href}
            target={target}
        >{content}</a>

    }

}

LinkCell.propTypes = {
    value: PropTypes.any,
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    href: PropTypes.string,
    target: PropTypes.string,
};

export default LinkCell;