import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {preventDefaultIfPossible, ucwords, c3ColorPattern} from "../../../utilities";

export default class UserDetailInterestsPane extends Component {

    constructor(props) {
        super(props);

        this.state = {
            category: 'Uncategorized'
        };

        this.handleToggleCategory = this.handleToggleCategory.bind(this);
    }

    handleToggleCategory(name, event) {
        preventDefaultIfPossible(event);
        if (this.state.category === name) {
            this.setState({category: null});
        } else {
            this.setState({category: name});
        }
    }

    fetchData() {
        if (this.props.fetchInterests) {
            this.props.fetchInterests();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.id !== this.props.user.id && this.props.fetchInterests) {
            this.fetchData();
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    getCategorySummaries() {
        const {user} = this.props;
        const interests = user.interests || {};
        const categories = {};

        for (const interestId in interests) {
            const interest = interests[interestId];
            const {category = 'Uncategorized', score} = interest;

            if (typeof categories[category] === 'undefined') {
                categories[category] = {count: 0, score: 0, avg: 0, name: category};
            }

            categories[category].count++;
            categories[category].score += parseInt(score, 10);
            categories[category].avg = categories[category].score / categories[category].count;

        }

        const asArray = Object.values(categories).sort((a, b) => a.score < b.score ? 1 : -1);

        return asArray;

    }

    getMaxCategoryScore() {
        const categories = this.getCategorySummaries();
        return categories[0].score;
    }

    getMaxCategoryAvgScore() {
        const categories = this.getCategorySummaries().sort((a, b) => a.avg > b.avg ? -1 : 1);
        return categories[0].avg;
    }

    renderTable() {
        const categories = this.getCategorySummaries();
        return (
            <div className="user-interests-explorer">
                {categories.map((category, index) => this.renderCategory(category, index))}
            </div>
        );

    }

    getColorForIndex(index) {
        if (typeof c3ColorPattern.pattern[index] === 'undefined') {
            return c3ColorPattern.pattern[c3ColorPattern.pattern.length - 1];
        } else {
            return c3ColorPattern.pattern[index];
        }
    }

    renderCategoryName(name) {
        if (name === 'default') return 'General';
        let display = ucwords(name);
        return display;
        // Don't pluralize?
        // if (display.substr(-1) === 'y') {
        //     return display.substr(0, display.length - 1) + 'ies';
        // }
        // return display + 's';
    }

    renderCategory(category, index) {

        const {name, score, count, avg} = category;
        const maxScore = this.getMaxCategoryScore();
        const percentage = Math.ceil(100 * score / maxScore);
        const color = this.getColorForIndex(index);

        let icon;

        if (this.state.category === name) {
            icon = <i className="fa fa-angle-down" />
        } else {
            icon = <i className="fa fa-angle-right" />
        }


        const thermoStyle = {
            backgroundColor: color,
            width: percentage + '%'
        };


        return (
            <div className="fake-li padded category-wrapper">
                <span className="category-name" onClick={this.handleToggleCategory.bind(this, name)}>{this.renderCategoryName(name)} {icon}</span>
                <span className="thermometer-wrapper">
                    <span className="thermometer-inner" style={thermoStyle}></span>
                </span>
                {this.renderCategoryInterests(name, percentage)}
            </div>
        )
    }

    renderCategoryInterests(category, categoryWidth = 100) {
        if (this.state.category !== category) return null;
        const interests = this.getCategoryInterests(category);

        return (
            <div className="category-interests-wrapper">
                {interests.map((interest, index) => this.renderInterest(interest, category, index, categoryWidth))}
            </div>
        )
    }

    renderInterest(interest, category, index, categoryWidth = 100) {

        // all interests in category
        const interests = this.getCategoryInterests(category);
        const maxScore = interests[0].score;
        // Size of this bar is scaled against the category its part of
        const percentage = 100 * ((parseInt(interest.score, 10) / maxScore) * (categoryWidth/100));
        const color = this.getColorForIndex(index);

        const thermoStyle = {
            backgroundColor: color,
            width: percentage + '%'
        };

        return (
            <div className="fake-li interest-wrapper">
                <span className="interest-name">
                    {interest.name}
                </span>
                <span className="thermometer-wrapper" data-tooltip={"Affinity level: " + interest.level}>
                    <span className="thermometer-inner" style={thermoStyle}></span>
                </span>
            </div>
        )
    }

    getCategoryInterests(category) {
        const {user} = this.props;
        return Object.values(user.interests || {})
            .filter(interest => typeof interest.category === 'undefined' || interest.category === category)
            .sort((a, b) => {
                const aScore = parseInt(a.score, 10);
                const bScore = parseInt(b.score, 10);
                return aScore < bScore ? 1 : -1;
            }) ;
    }

    render() {

        const {user} = this.props;

        return (

            <div className={'user-detail-body-interests'}>
                {
                    user.isFetchingInterests
                        ? <div className="alert tidal-alert alert-grey">Loading interests...</div>
                        : this.renderTable()
                }
            </div>

        );
    }

}

UserDetailInterestsPane.propTypes = {
    user: PropTypes.object,
    fetchInterests: PropTypes.func
};
