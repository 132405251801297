/**
 * Created by bkroger on 6/19/18.
 */

import React, {Component} from 'react';
import Avatar from './Avatar';
import Message from './Message';
import PropTypes from 'prop-types';

export default class TimelineMessageRow extends Component {

    render() {
        return (
            <div className="timeline-message-wrapper clearfix" style={this.props.style}>
                <Avatar layout={this.props.layout} image={this.props.image}/>
                <Message
                    message={this.props.message}
                    layout={this.props.layout}
                    timestamp={this.props.timestamp}
                    link={this.props.link}
                />
            </div>
        )
    }

}

TimelineMessageRow.defaultProps = {
    layout: 'left',
    style: {},
};

TimelineMessageRow.propTypes = {
    message: PropTypes.string.isRequired,
    layout: PropTypes.string,
    image: PropTypes.string.isRequired,
    timestamp: PropTypes.string,
    link: PropTypes.node,
    style: PropTypes.object,
};

