import {REQUEST_PRESENCE, RECEIVE_PRESENCE, INVALIDATE_PRESENCE} from "../actions/presence";
import {PRESENCE_TOGGLE_NOTIFICATIONS} from "../actions/index";
import * as ActionTypes from '../actions/types';
import {PRESENCE_TOGGLE_TEAMSWITCHER, PRESENCE_UPDATE_FILTER} from "../actions/types";
import {PRESENCE_SHOW_FAVORITES} from "../actions/types";

export function presence(
    state = {

        isFetching: false,
        didInvalidate: true,
        lastUpdated: null,

        showNotifications: false,
        showTeamSwitcher: false,
        showFavorites: false,
        showSearch: false,
        showHelpTicket: false,
        showHelp: false,

        channel: {},
        user: {
            id: null,
            teams: [],
            roles: []
        },
        filters: {
            team_id: null,
            campaign_id: null,
        }

    },
    action
) {
    switch (action.type) {

        case ActionTypes.PRESENCE_SET_CHANNEL_URL:
            return {
                ...state,
                channel: {
                    ...state.channel,
                    url: action.url,
                }
            };

        case PRESENCE_UPDATE_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };

        case ActionTypes.PRESENCE_SHOW_HELP_TICKET:
            return {
                ...state,
                showHelpTicket: !state.showHelpTicket
            };

        case ActionTypes.PRESENCE_SHOW_HELP:
            return { ...state, showHelp: true };
        case ActionTypes.PRESENCE_HIDE_HELP:
            return { ...state, showHelp: false };


        case ActionTypes.PRESENCE_SHOW_SEARCH:
            return {
                ...state,
                showSearch: !state.showSearch
            };

        case PRESENCE_SHOW_FAVORITES:
            return {
                ...state,
                showFavorites: !state.showFavorites
            };

        case PRESENCE_TOGGLE_TEAMSWITCHER:
            return {
                ...state,
                showTeamSwitcher: !state.showTeamSwitcher
            };

        case INVALIDATE_PRESENCE:
            return {...state, didInvalidate: true};

        case PRESENCE_TOGGLE_NOTIFICATIONS:
            return Object.assign({}, state, {showNotifications: !state.showNotifications});

        case REQUEST_PRESENCE:
            return {...state, isFetching: true, didInvalidate: false};

        case RECEIVE_PRESENCE:
            return {
                ...state,
                ...action.presence,
                lastUpdated: action.receivedAt,
                isFetching: false,
                didInvalidate: false
            };

        default:
            return state;
    }
}
