/**
 * Created by bkroger on 7/16/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";
import Button from "../../../Common/Form/Button";

class SignNDA extends Component {

    constructor(props) {
        super(props);
        this.handleSaved = this.handleSaved.bind(this);
        this.state = {
            isSaving: false,
            error: null,
            form: {
                acceptCheckbox: false,
                acceptText: '',
                signature: '',
                dated: '',
            }
        }
    }

    async handleSaved() {

        if (this.props.refreshActivation) {
            await this.props.refreshActivation();
        }

    }

    _t(k, v) {
        if (this.props.translate) {
            return this.props.translate(k, v);
        }
        return v;
    }

    renderIntro() {
        // copy is HTML so we need to dangerously set after purifying it
        const copy = this.props.activation.settings.nda_about;
        return (
            <div className="v3 light h7 pane-info nda-about" dangerouslySetInnerHTML={{__html: copy}} />
        );
    }

    renderContract() {
        const copy = this.props.activation.settings.nda_contract;
        return (
            <div className="nda-contract-wrapper">
                <div className="nda-contract" dangerouslySetInnerHTML={{__html: copy}} />
            </div>
        );
    }

    checkIsValid() {
        this.setState({error: null});
        if (!this.state.form.acceptCheckbox) {
            this.setState({error: "You must accept the NDA terms by selecting the checkbox to continue."});
            return false;
        }

        if (this.state.form.acceptText.toLowerCase() !== 'i accept the nda') {
            this.setState({error: "You must type 'I Accept the NDA' to continue."});
            return false;
        }

        if (!this.state.form.signature || this.state.form.signature.length < 4) {
            this.setState({error: "You must type your name to sign the NDA."});
            return false;
        }

        if (!this.state.form.dated) {
            this.setState({error: "You must type today's date to confirm your agreement."});
            return false;
        }

        return true;
    }

    handleDecline() {

        const context = {
            response: 'decline',
            signature: 'Declined NDA'
        };

        return this.props.createInteraction(this.props.activation.id, 'RespondToInvitation', context)
            .then(res => {
                this.setState({
                    isSubmitting: false,
                    didSubmit: true})
            })
            .then(this.handleSaved.bind(this))
    }
    async handleSubmit() {
        if (!this.checkIsValid()) {
            return;
        }

        this.setState({
            isSaving: true,
        });

        const ndaPayload = {
            signature: this.state.form.signature,
            acceptance: this.state.form.acceptText,
            dated: this.state.form.dated
        };

        const offerPayload = {
            response: 'accept',
            signature: this.state.form.signature,
            contract: 'nda'
        };


        await this.props.createInteraction(this.props.activation.id, 'SignNda', ndaPayload);
        await this.props.createInteraction(this.props.activation.id, 'RespondToInvitation', offerPayload);
        await this.handleSaved();

        this.setState({ isSaving: false });
    }

    renderSignedAlert()
    {
        return (
            <Alert classes={['success']} content={this._t('campaign.nda.signed', 'You have successfully signed the NDA.')} />
        );
    }
    renderForm() {

        // check if signed, if so, show message
        if (this.props.activation && this.props.activation.status && this.props.activation.status.is_nda_signed === true) {
            return this.renderSignedAlert();
        }

        if (this.props.activation && this.props.activation.status && this.props.activation.status.did_user_decline_invitation === true) {
            return (
                <Alert classes={['danger']} content={this._t('campaign.nda.declined', 'You have declined the NDA and invitation.')} />
            );
        }


        // simple validation
        const isValid = this.state.form.acceptCheckbox
            && this.state.form.acceptText.toLowerCase() === 'i accept the nda'
            && this.state.form.signature
            && this.state.form.signature.length > 3
            && this.state.form.dated;

        return (
            <Form
                values={this.state.form}
                onFieldChange={(name, value) => {
                    this.setState({
                        form: {
                            ...this.state.form,
                            [name]: value
                        }
                    });
                }}
                fields={[

                    this.state.error ? {
                        key: 'errormsg',
                        type: 'generic',
                        content: (
                            <Alert classes={['danger']} content={this.state.error} />
                        )
                    } : null,
                    {
                        type: 'checkbox',
                        name: 'acceptCheckbox',
                        title: this._t('campaign.nda.accept_checkbox', 'I have read and agree to the terms of the Non-Disclosure Agreement'),
                        required: true,

                    },
                    {
                        type: 'text',
                        name: 'acceptText',
                        title: this._t('campaign.nda.accept_text', 'Please type "I Accept the NDA" here to confirm your agreement.'),
                        required: true,
                    },
                    {
                        type: 'text',
                        name: 'signature',
                        title: this._t('campaign.nda.signature', 'Please type your name to sign the agreement.'),
                        required: true,
                    },
                    {
                        type: 'text',
                        name: 'dated',
                        title: this._t('campaign.nda.dated', 'Type today\'s date to confirm your agreement.'),
                        required: true,
                    },
                    {
                        type: 'button',
                        name: 'submit',
                        title: this._t('campaign.nda.submit', 'Sign NDA and Accept'),
                        options: {
                            hideTitle: true,
                            classes: ['v3', isValid ? 'btn-primary' : 'btn-disabled btn-secondary'],
                            onClick: this.handleSubmit.bind(this),
                            after: (
                                <Button
                                    classes={['v3', 'btn', 'btn-secondary']}
                                    onClick={() => this.handleDecline()}
                                    content={this._t('campaign.nda.decline', 'Decline NDA and Offer')}
                                    style={{float: 'right'}}
                                />

                            )
                        }
                    }

                ]}
            />
        )
    }
    render() {

        return(
            <div className="influencer-page-template sign-nda">
                <div className="widget">

                    <div className="content padded">
                        <h4 className="v3 bold action-pane-title">{this._t('campaign.nda.pane.title', 'Read and Sign Non-Disclosure Agreement')}</h4>
                        {this.renderIntro()}
                        {this.renderContract()}
                        {this.renderForm()}
                    </div>
                </div>
            </div>
        );

    }

}

SignNDA.propTypes = {
    campaign: PropTypes.object.isRequired,
    activation: PropTypes.object.isRequired,
    refreshActivation: PropTypes.func.isRequired,
    createInteraction: PropTypes.func.isRequired,
    translate: PropTypes.func,
};

export default SignNDA;