/**
 * Created by bkroger on 7/19/18.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import Form from '../../../Common/Form/Form';
import Twig from 'twig';

class CouponCode extends Component {

    constructor(props) {
        super(props);

        this.state = { };

        this.handleClickSave = this.handleClickSave.bind(this);
    }

    handleClickSave() {
        const coupon = this.getCoupon();
        if (coupon && typeof this.props.activation !== 'undefined' && typeof this.props.activation.id !== 'undefined') {

            const context = {
                coupon_id: coupon.id,
                consumable_id: coupon.consumable_record_id
            };

            return this.props.createInteraction(this.props.activation.id, 'ClaimCouponCode', context)
                .then(this.props.refreshActivation);
        }
    }

    getCoupon() {
        const {activation} = this.props;
        if (activation && activation.coupon_codes && activation.coupon_codes.length >= 1) {
            return activation.coupon_codes[0];
        }
        return null;
    }

    getCouponCodeGuidelinesText() {
        const {activation} = this.props;

        if (activation && activation.settings && activation.settings.coupon_code_guidelines) {
            const template = Twig.twig({
                data: activation.settings.coupon_code_guidelines || '',
            });
            const rendered = template.render(this.props.twigContext);
            return {__html: DOMPurify.sanitize(rendered)};
        }

        return {__html: "You have been given the following coupon code for use in this campaign. Please copy and save this coupon code and then indicate that you have received it using the button below."};
    }

    renderGuidelines() {
        return (
            <div className="v3 light h7 pane-info" dangerouslySetInnerHTML={this.getCouponCodeGuidelinesText()}></div>
        );
    }

    render() {
        const {activation} = this.props;

        const coupon = this.getCoupon();

        const btnStatus = activation && activation.coupon_codes && activation.coupon_codes.length >= 1 ? '' : 'disabled';

        return(
            <div className="influencer-page-template coupon-code">
                <div className="widget">

                    <div className="content padded">

                        <h4 className="v3 bold action-pane-title">Get Coupon Code</h4>

                        {this.renderGuidelines()}
                        <Form
                            style={{marginTop: 20}}
                            usePadding={false}
                            values={
                                {'coupon_code': coupon ? coupon.code : 'You have not been given a coupon code yet'}
                            }
                            fields={[
                                {
                                    type: 'text',
                                    name: 'coupon_code',
                                    title: 'Coupon Code',
                                    readOnly: true,
                                    classes: ['v3 bold']
                                },
                                {
                                    type: 'button',
                                    name: 'submit',
                                    title: 'Accept Coupon Code',
                                    options: {
                                        classes: ['v3 btn btn-primary medium bold ' + btnStatus],
                                        hideTitle: true,
                                        onClick: () => {
                                            this.handleClickSave();
                                        }
                                    }
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>
        )
    }

}


CouponCode.propTypes = {
    campaign: PropTypes.object.isRequired,
    activation: PropTypes.object.isRequired,
    refreshActivation: PropTypes.func.isRequired,
    twigContext: PropTypes.object.isRequired,
    createInteraction: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(null, mapDispatchToProps)(CouponCode);
