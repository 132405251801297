import React, {Component} from 'react';
import {Tooltip as Tippy} from 'react-tippy';

class Tooltip extends Component {

    render() {
        return (

            <Tippy

                interactive={true}
                theme={'light'}
                animation={'fade'}
                arrow={true}
                className="tooltip-wrapper"

                {...this.props}
            >
                {this.props.children}
            </Tippy>

        );
    }

}

export default Tooltip;
