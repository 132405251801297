import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions/campaigns";
import PropTypes from 'prop-types';
import Alert from '../Common/Alert';
import Form from '../Common/Form/Form';

class ShipProductForm extends Component {

    static defaultProps = {
        usePadding: true,
    };

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onComplete: PropTypes.func,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
        usePadding: PropTypes.bool,
    };

    state = {
        isUpdating: false,
        didUpdate: false,
        isRefreshing: false,
        error: false,
        tracking_number: (this.props.activation.metadata || {}).tracking_number || null,
    };

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate() {
        const {activation} = this.props;
        const metadata = activation.metadata || {};
        const tracking_number = metadata.tracking_number || null;
        if (this.state.tracking_number === null && tracking_number !== null) {
            this.setState({tracking_number});
        }
    }

    refresh() {
        this.setState({isRefreshing: true});
        this.props.fetchActivation(this.props.activation.id)
            .then(() => this.setState({isRefreshing: false}))
    }

    handleButtonClick() {

        const {activation, onComplete, fetchActivation, createInteraction} = this.props;

        this.setState({isUpdating: true});

        return createInteraction(activation.id, 'ShipProduct', {tracking_number: this.state.tracking_number})
            .catch(err => this.setState({isUpdating: false, didUpdate: false, error: true}))
            .then(() => fetchActivation(activation.id))
            .then(() => this.setState({isUpdating: false, didUpdate: true}))
            .then(() => {
                if (onComplete) {
                    onComplete();
                }
            });

    }

    renderAlert() {

        const {activation} = this.props;
        const {didUpdate, isUpdating, error} = this.state;

        if (didUpdate) {
            return <Alert classes={['success']} content={`${activation.user.name}'s product was marked as shipped!`} />
        }

        if (isUpdating) {
            return <Alert classes={['info']} content={`Shipping product for ${activation.user.name}...`} />
        }

        if (error) {
            return <Alert classes={['danger']} content={`There was a problem updating ${activation.user.name}'s shipment status.`} />
        }


        return <Alert classes={['info']} content={`Indicate that a product sample has been shipped to ${activation.user.name}. If you enter a tracking number below, it will be included in an email to the recipient.`} /> ;

    }


    renderForm() {
        const {isUpdating, tracking_number} = this.state;
        const form = {tracking_number};

        return (
            <Form
                usePadding={this.props.usePadding}
                onFieldChange={(name, value) => this.setState({[name]: value})}
                values={form}
                fields={[

                    {
                        type: 'text',
                        name: 'tracking_number',
                        title: 'Shipping Tracking Number',
                        placeholder: 'eg: 1Z010001212409124',
                        help: 'Add a UPS, FedEx, or USPS tracking number so the recipient can track their shipment.'
                    },
                    {
                        type: 'button',
                        title: 'Mark as Shipped',
                        options: {
                            classes: ['v3 btn btn-primary medium bold', (isUpdating ? 'disabled' : '')],
                            hideTitle: true,
                            onClick: this.handleButtonClick.bind(this),
                        }
                    }
                ]}
            />
        );


    }
    render() {

        return (
            <div className="activation-status-change-form">
                {this.renderAlert()}
                {this.renderForm()}
            </div>
        );

    }
}


const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };
};

export default connect(null, mapDispatchToProps)(ShipProductForm);
