import React from 'react';
import Checkbox from "./Checkbox";
import Text from "./Text";

function MultiSelect({choices, onChange, value, addlClasses, allowOther=true}) {

    let classes = ['react-form-multiselect', addlClasses];

    const selected = value || [];
    const handleChange = (newSelected) => {
        if (onChange) {
            onChange({value: newSelected});
        }
    };

    const OtherInput = () => {

        const otherValues = selected.filter((value) => !choices.map((choice) => choice.value).includes(value));
        const otherValue = otherValues.length > 0 ? otherValues[0] : '';

        const setOtherValue = (newValue) => {
            if (newValue === '') {
                handleChange(selected.filter((value) => !otherValues.includes(value)));
            } else {
                handleChange([...selected.filter((value) => !otherValues.includes(value)), newValue]);
            }
        }

        if (allowOther) {
            return (
                <Checkbox
                    wrapperStyle={{display: 'block'}}
                    label={
                        <Text
                            onChange={(e) => setOtherValue(e.target.value)}
                            value={otherValue}
                            style={{display: "inline-block", width: "calc(100% - 30px)"}}
                            placeholder={"Other (type your own)"}
                        />
                    }
                    checked={otherValue !== ''}
                />
            );
        }
        return null;
    }

    return (
        <div className={classes.join(' ')}>
            {choices.map((choice, index) => {
                return <Checkbox
                    wrapperStyle={{display: 'block'}}
                    key={`multi-select-${choice.value}-${index}`}
                    label={choice.text}
                    checked={selected.includes(choice.value)}
                    useBoldTitle={false}
                    onClick={() => {
                        if (selected.includes(choice.value)) {
                            handleChange(selected.filter((value) => value !== choice.value));
                        } else {
                            handleChange([...selected, choice.value]);
                        }
                    }}
                />
            })}
            {OtherInput()}
        </div>
    );
}

export default MultiSelect;