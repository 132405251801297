export const REQUEST_BILLS = 'REQUEST_BILLS';
export const RECEIVE_BILLS = 'RECEIVE_BILLS';

export const UPDATE_QUERY_PAGE = 'UPDATE_QUERY_PAGE';
export const UPDATE_QUERY_SORT = 'UPDATE_QUERY_SORT';
export const UPDATE_QUERY_FILTERS = 'UPDATE_QUERY_FILTERS';
export const REQUEST_QUERY = 'REQUEST_QUERY';
export const RECEIVE_QUERY = 'RECEIVE_QUERY';

export const ACTIVATIONS_UPDATE_QUERY_PAGE = 'ACTIVATIONS_UPDATE_QUERY_PAGE';
export const ACTIVATIONS_UPDATE_QUERY_SORT = 'ACTIVATIONS_UPDATE_QUERY_SORT';
export const ACTIVATIONS_UPDATE_QUERY_FILTERS = 'ACTIVATIONS_UPDATE_QUERY_FILTERS';
export const ACTIVATIONS_REQUEST_QUERY = 'ACTIVATIONS_REQUEST_QUERY';
export const ACTIVATIONS_RECEIVE_QUERY = 'ACTIVATIONS_RECEIVE_QUERY';

export const ACTIVATION_UPDATE_METADATA = 'ACTIVATION_UPDATE_METADATA';
export const ACTIVATION_UPDATED_METADATA = 'ACTIVATION_UPDATED_METADATA';
export const ACTIVATION_UPDATE_METADATA_FAILED = 'ACTIVATION_UPDATE_METADATA_FAILED';

export const ACTIVATION_CREATE_INTERACTION = 'ACTIVATION_CREATE_INTERACTION';
export const ACTIVATION_CREATED_INTERACTION = 'ACTIVATION_CREATED_INTERACTION';
export const ACTIVATION_CREATE_INTERACTION_FAILED = 'ACTIVATION_CREATE_INTERACTION_FAILED';

export const ACTIVATION_REQUEST_STATS = 'ACTIVATION_REQUEST_STATS';
export const ACTIVATION_REQUEST_STATS_FAILED = 'ACTIVATION_REQUEST_STATS_FAILED';
export const ACTIVATION_RECEIVE_STATS = 'ACTIVATION_RECEIVE_STATS';

export const CAMPAIGN_REQUEST_COUPONS = 'CAMPAIGN_REQUEST_COUPONS';
export const CAMPAIGN_RECEIVE_COUPONS = 'CAMPAIGN_RECEIVE_COUPONS';
export const CAMPAIGN_CREATE_COUPON = 'CAMPAIGN_CREATE_COUPON';
export const CAMPAIGN_CREATED_COUPON = 'CAMPAIGN_CREATED_COUPON';
export const CAMPAIGN_REQUEST_FORMS = 'CAMPAIGN_REQUEST_FORMS';
export const CAMPAIGN_RECEIVE_FORMS = 'CAMPAIGN_RECEIVE_FORMS';


export const PRODUCTS_REQUEST_CATALOGS = 'PRODUCTS_REQUEST_CATALOGS';
export const PRODUCTS_RECEIVE_CATALOGS = 'PRODUCTS_RECEIVE_CATALOGS';
export const PRODUCTS_RECEIVE_CATALOG_PRODUCTS = 'PRODUCTS_RECEIVE_CATALOG_PRODUCTS';
export const PRODUCTS_REQUEST_CATALOG_PRODUCTS = 'PRODUCTS_REQUEST_CATALOG_PRODUCTS';
export const PRODUCTS_RECEIVE_CAMPAIGN_PRODUCTS = 'PRODUCTS_RECEIVE_CAMPAIGN_PRODUCTS';
export const PRODUCTS_REQUEST_CAMPAIGN_PRODUCTS = 'PRODUCTS_REQUEST_CAMPAIGN_PRODUCTS';
export const PRODUCTS_DELETE_CATALOG_PRODUCT = 'PRODUCTS_DELETE_CATALOG_PRODUCT';
export const UPDATE_CAMPAIGN_PRODUCT = 'UPDATE_CAMPAIGN_PRODUCT';
export const UPDATED_CAMPAIGN_PRODUCT = 'UPDATED_CAMPAIGN_PRODUCT';

export const IMAGES_UPDATE_QUERY_PAGE = 'IMAGES_UPDATE_QUERY_PAGE';
export const IMAGES_UPDATE_QUERY_SORT = 'IMAGES_UPDATE_QUERY_SORT';
export const IMAGES_UPDATE_QUERY_FILTERS = 'IMAGES_UPDATE_QUERY_FILTERS';
export const IMAGES_REQUEST_QUERY = 'IMAGES_REQUEST_QUERY';
export const IMAGES_RECEIVE_QUERY = 'IMAGES_RECEIVE_QUERY';

export const VIDEOS_UPDATE_QUERY_PAGE = 'VIDEOS_UPDATE_QUERY_PAGE';
export const VIDEOS_UPDATE_QUERY_SORT = 'VIDEOS_UPDATE_QUERY_SORT';
export const VIDEOS_UPDATE_QUERY_FILTERS = 'VIDEOS_UPDATE_QUERY_FILTERS';
export const VIDEOS_REQUEST_QUERY = 'VIDEOS_REQUEST_QUERY';
export const VIDEOS_RECEIVE_QUERY = 'VIDEOS_RECEIVE_QUERY';

export const CREATE_BADGE = 'CREATE_BADGE';
export const CREATED_BADGE = 'CREATED_BADGE';
export const UPDATE_BADGE = 'UPDATE_BADGE';
export const UPDATED_BADGE = 'UPDATED_BADGE';

export const CHANNEL_CREATE_INTERACTION = 'CHANNEL_CREATE_INTERACTION';
export const CHANNEL_CREATED_INTERACTION = 'CHANNEL_CREATED_INTERACTION';
export const CHANNEL_CREATE_INTERACTION_FAILED = 'CHANNEL_CREATE_INTERACTION_FAILED';
export const CHANNEL_REQUEST_SETTINGS = 'CHANNEL_REQUEST_SETTINGS';
export const CHANNEL_RECEIVE_SETTINGS = 'CHANNEL_RECEIVE_SETTINGS';
export const REQUEST_CHANNEL_INTERACTIONS = 'REQUEST_CHANNEL_INTERACTIONS';
export const RECEIVE_CHANNEL_INTERACTIONS = 'RECEIVE_CHANNEL_INTERACTIONS';

export const REQUEST_SECTIONS = 'REQUEST_SECTIONS';
export const RECEIVE_SECTIONS = 'RECEIVE_SECTIONS';
export const CREATE_SECTION = 'CREATE_SECTION';
export const CREATED_SECTION = 'CREATED_SECTION';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const UPDATED_SECTION = 'UPDATED_SECTION';

export const TALENTSPIDER_CREATE_INTERACTION = 'TALENTSPIDER_CREATE_INTERACTION';
export const TALENTSPIDER_CREATED_INTERACTION = 'TALENTSPIDER_CREATED_INTERACTION';
export const TALENTSPIDER_REQUEST_CANDIDATE = 'TALENTSPIDER_REQUEST_CANDIDATE';

export const CANDIDATES_UPDATE_QUERY_PAGE = 'CANDIDATES_UPDATE_QUERY_PAGE';
export const CANDIDATES_UPDATE_QUERY_SORT = 'CANDIDATES_UPDATE_QUERY_SORT';
export const CANDIDATES_UPDATE_QUERY_FILTERS = 'CANDIDATES_UPDATE_QUERY_FILTERS';
export const CANDIDATES_REQUEST_QUERY = 'CANDIDATES_REQUEST_QUERY';
export const CANDIDATES_RECEIVE_QUERY = 'CANDIDATES_RECEIVE_QUERY';


export const PRESENCE_TOGGLE_TEAMSWITCHER = 'PRESENCE_TOGGLE_TEAMSWITCHER';
export const PRESENCE_SHOW_FAVORITES = 'PRESENCE_SHOW_FAVORITES';
export const PRESENCE_SHOW_SEARCH = 'PRESENCE_SHOW_SEARCH';
export const PRESENCE_SHOW_HELP_TICKET = 'PRESENCE_SHOW_HELP_TICKET';
export const PRESENCE_SHOW_HELP = 'PRESENCE_SHOW_HELP';
export const PRESENCE_HIDE_HELP = 'PRESENCE_HIDE_HELP';
export const PRESENCE_UPDATE_FILTER = 'PRESENCE_UPDATE_FILTER';

export const PRESENCE_SET_CHANNEL_URL = 'PRESENCE_SET_CHANNEL_URL';

export const AUTH_SET_STATE = 'AUTH_SET_STATE';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_SHARED_PASSWORD = 'AUTH_SET_SHARED_PASSWORD';

export const AUTH_REQUEST_LOGIN = 'AUTH_REQUEST_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';

export const AUTH_REQUEST_SIGNUP = 'AUTH_REQUEST_SIGNUP';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_SIGNUP_FAIL = 'AUTH_SIGNUP_FAIL';

export const AUTH_REQUEST_PASSWORD_RESET = 'AUTH_REQUEST_PASSWORD_RESET';
export const AUTH_PASSWORD_RESET_SUCCESS = 'AUTH_PASSWORD_RESET_SUCCESS';
export const AUTH_PASSWORD_RESET_FAIL = 'AUTH_PASSWORD_RESET_FAIL';

export const POSTS_UPDATE_QUERY_PAGE = 'POSTS_UPDATE_QUERY_PAGE';
export const POSTS_UPDATE_QUERY_SORT = 'POSTS_UPDATE_QUERY_SORT';
export const POSTS_UPDATE_QUERY_FILTERS = 'POSTS_UPDATE_QUERY_FILTERS';
export const POSTS_RECEIVE_QUERY = 'POSTS_RECEIVE_QUERY';
export const POSTS_REQUEST_QUERY = 'POSTS_REQUEST_QUERY';
export const UPDATE_POST_RATING = 'UPDATE_POST_RATING';
export const UPDATED_POST_RATING = 'UPDATED_POST_RATING';
export const FEATURE_POST = 'FEATURE_POST';
export const FEATURED_POST = 'FEATURED_POST';

export const AUTH_REQUEST_SUBSCRIPTIONS = 'AUTH_REQUEST_SUBSCRIPTIONS';
export const AUTH_RECEIVE_SUBSCRIPTIONS = 'AUTH_RECEIVE_SUBSCRIPTIONS';
export const AUTH_UPDATE_SUBSCRIPTIONS = 'AUTH_UPDATE_SUBSCRIPTIONS';
export const AUTH_UPDATED_SUBSCRIPTIONS = 'AUTH_UPDATED_SUBSCRIPTIONS';

export const EMAILS_UPDATE_QUERY_PAGE = 'EMAILS_UPDATE_QUERY_PAGE';
export const EMAILS_UPDATE_QUERY_SORT = 'EMAILS_UPDATE_QUERY_SORT';
export const EMAILS_UPDATE_QUERY_FILTERS = 'EMAILS_UPDATE_QUERY_FILTERS';
export const EMAILS_REQUEST_QUERY = 'EMAILS_REQUEST_QUERY';
export const EMAILS_RECEIVE_QUERY = 'EMAILS_RECEIVE_QUERY';
export const EMAIL_CREATE_INTERACTION = 'EMAIL_CREATE_INTERACTION';
export const EMAIL_CREATED_INTERACTION = 'EMAIL_CREATED_INTERACTION';
export const EMAIL_REQUEST = 'EMAIL_REQUEST';
export const EMAIL_RECEIVE = 'EMAIL_RECEIVE';
export const EMAIL_TOGGLE_COMPOSE = 'EMAIL_TOGGLE_COMPOSE';

export const LISTENTRIES_UPDATE_QUERY_PAGE = 'LISTENTRIES_UPDATE_QUERY_PAGE';
export const LISTENTRIES_UPDATE_QUERY_SORT = 'LISTENTRIES_UPDATE_QUERY_SORT';
export const LISTENTRIES_UPDATE_QUERY_FILTERS = 'LISTENTRIES_UPDATE_QUERY_FILTERS';
export const LISTENTRIES_REQUEST_QUERY = 'LISTENTRIES_REQUEST_QUERY';
export const LISTENTRIES_RECEIVE_QUERY = 'LISTENTRIES_RECEIVE_QUERY';

export const LIST_CREATE_INTERACTION = 'LIST_CREATE_INTERACTION';
export const LIST_CREATED_INTERACTION = 'LIST_CREATED_INTERACTION';
export const LIST_CREATE_INTERACTION_FAILED = 'LIST_CREATE_INTERACTION_FAILED';
export const LIST_REQUEST_ENTRY = 'LIST_REQUEST_ENTRY';
export const LIST_RECEIVE_ENTRY = 'LIST_RECEIVE_ENTRY';
export const LIST_ENTRY_REMOVE_NOTES = 'LIST_ENTRY_REMOVE_NOTES';


export const CAMPAIGN_CREATE_INTERACTION = 'CAMPAIGN_CREATE_INTERACTION';
export const CAMPAIGN_CREATED_INTERACTION = 'CAMPAIGN_CREATED_INTERACTION';
export const CAMPAIGN_CREATE_INTERACTION_FAILED = 'CAMPAIGN_CREATE_INTERACTION_FAILED';

export const I18N_MISSED_TRANSLATION = 'I18N_MISSED_TRANSLATION';
export const I18N_ENSURE_MISSED = 'I18N_ENSURE_MISSED';
export const I18N_UPDATE_LANGUAGE = 'I18N_UPDATE_LANGUAGE';
export const I18N_REQUEST = 'I18N_REQUEST';
export const I18N_RECEIVE = 'I18N_RECEIVE';
