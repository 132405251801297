/**
 * Created by bkroger on 9/12/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw, ContentState, AtomicBlockUtils, DefaultDraftBlockRenderMap} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {makeFormData} from "../../../utilities";
import {fetchAuthenticated} from "../../../actions/auth";
import Toolbox from "../Toolbox";
import Textarea from "./Textarea";
import Alert from "../Alert";
import classNames from 'classnames';
import Immutable from 'immutable';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";


const VIDEO_CONFIG = {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: true,
    previewImage: false,
    alignmentEnabled: true,
    uploadCallback: () => {
        console.log("Video upload callback called.");
    },
    inputAccept: "video/x-msvideo, video/mpeg, video/ogg, video/mp2t, video/webm, video/3gpp, video/3gpp2, video/mp4, video/quicktime, video/x-ms-wmv",
    alt: { present: false, mandatory: false },
    defaultSize: {
        height: "auto",
        width: "auto"
    },
    title: 'Video'
};

function EmbeddedVideoBlock({src}) {
    const [didFail, setDidFail] = React.useState(false);
    const embeddedVideo = <video
            src={src}
            style={{maxWidth: 600, maxHeight: 600}}
            controls={true}
            muted={true}
            onError={err => { setDidFail(true); }}
        ></video>;
    const loadingMessage = <span>Video is still processing. You can save and close.</span>
    return (
        <div style={{padding: 20, margin: "20px 0", background: "#999", textAlign: "center"}}>
            {didFail ? loadingMessage : embeddedVideo}
        </div>
    );

}

const customBlockRenderer = (contentBlock, config, getEditorState) => {
    if (contentBlock.getType() === 'atomic') {
        const contentState = config.getEditorState().getCurrentContent();
        const entity = contentState.getEntity(contentBlock.getEntityAt(0));
        if (entity && entity.type === 'tidal-video') {
            const {src} = entity.getData();
            return {
                component: function(props) {
                    return <EmbeddedVideoBlock src={src} key={src} />
                },
                editable: false,
            };
        }
    }
    return undefined;
};

// Recreate the <Option> thingy from react-draft wysiwyg
class OptionComponentWrapper extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        children: PropTypes.any,
        value: PropTypes.string,
        className: PropTypes.string,
        activeClassName: PropTypes.string,
        active: PropTypes.bool,
        disabled: PropTypes.bool,
        title: PropTypes.string,
    };

    onClick = () => {
        const { disabled, onClick, value } = this.props;
        if (!disabled) {
            onClick(value);
        }
    };

    render() {
        const { children, className, activeClassName, active, disabled, title } = this.props;
        return (
            <div
                className={classNames(
                    'rdw-option-wrapper',
                    className,
                    {
                        [`rdw-option-active ${activeClassName}`]: active,
                        'rdw-option-disabled': disabled,
                    },
                )}
                onClick={this.onClick}
                aria-selected={active}
                title={title}
            >
                {children}
            </div>
        );
    }
}

// Inner part of the video uploader
class VideoUploadLayoutComponent extends Component {
    static propTypes = {
        expanded: PropTypes.bool,
        onExpandEvent: PropTypes.func,
        doCollapse: PropTypes.func,
        onChange: PropTypes.func,
        config: PropTypes.object,
        translations: PropTypes.object,
    };

    static defaultProps = {
        config: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            previewImage: false,
            alignmentEnabled: true,
            uploadCallback: undefined,
            inputAccept: "*",
            alt: { present: false, mandatory: false },
            defaultSize: {
                height: "auto",
                width: "auto"
            },
            title: undefined
        }
    }

    state = {
        videoSrc: '',
        dragEnter: false,
        uploadHighlighted: this.props.config.uploadEnabled && !!this.props.config.uploadCallback,
        showImageLoading: false,
        height: this.props.config.defaultSize.height,
        width: this.props.config.defaultSize.width,
        alt: '',
    };

    componentDidUpdate(prevProps) {
        const { config } = this.props;
        if (prevProps.expanded && !this.props.expanded) {
            this.setState({
                videoSrc: '',
                dragEnter: false,
                uploadHighlighted: config.uploadEnabled && !!config.uploadCallback,
                showImageLoading: false,
                height: config.defaultSize.height,
                width: config.defaultSize.width,
                alt: '',
            });
        } else if (
            config.uploadCallback !== prevProps.config.uploadCallback ||
            config.uploadEnabled !== prevProps.config.uploadEnabled
        ) {
            this.setState({
                uploadHighlighted: config.uploadEnabled && !!config.uploadCallback,
            });
        }
    }

    onDragEnter = event => {
        this.stopPropagation(event);
        this.setState({
            dragEnter: true,
        });
    };

    onVideoDrop = event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            dragEnter: false,
        });

        // Check if property name is files or items
        // IE uses 'files' instead of 'items'
        let data;
        let dataIsItems;
        if (event.dataTransfer.items) {
            data = event.dataTransfer.items;
            dataIsItems = true;
        } else {
            data = event.dataTransfer.files;
            dataIsItems = false;
        }
        for (let i = 0; i < data.length; i += 1) {
            if (
                (!dataIsItems || data[i].kind === 'file') &&
                data[i].type.match('^video/')
            ) {
                const file = dataIsItems ? data[i].getAsFile() : data[i];
                this.uploadVideo(file);
            }
        }
    };

    showImageUploadOption = () => {
        this.setState({
            uploadHighlighted: true,
        });
    };

    addVideoFromState = () => {
        const { videoSrc, alt } = this.state;
        let { height, width } = this.state;
        const { onChange } = this.props;
        if (!isNaN(height)) {
            height += 'px';
        }
        if (!isNaN(width)) {
            width += 'px';
        }
        onChange(videoSrc, height, width, alt);
    };

    showImageURLOption = () => {
        this.setState({
            uploadHighlighted: false,
        });
    };

    toggleShowImageLoading = () => {
        const showImageLoading = !this.state.showImageLoading;
        this.setState({
            showImageLoading,
        });
    };

    updateValue = event => {
        this.setState({
            [`${event.target.name}`]: event.target.value,
        });
    };

    selectVideo = event => {
        if (event.target.files && event.target.files.length > 0) {
            this.uploadVideo(event.target.files[0]);
        }
    };

    uploadVideo = file => {
        this.toggleShowImageLoading();
        const { uploadCallback } = this.props.config;
        uploadCallback(file)
            .then(({ data }) => {
                this.setState({
                    showImageLoading: false,
                    dragEnter: false,
                    videoSrc: data.link || data.url,
                });
                this.fileUpload = false;
            })
            .catch(() => {
                this.setState({
                    showImageLoading: false,
                    dragEnter: false,
                });
            });
    };

    fileUploadClick = event => {
        this.fileUpload = true;
        event.stopPropagation();
    };

    stopPropagation = event => {
        if (!this.fileUpload) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            this.fileUpload = false;
        }
    };

    renderAddVideoModal() {
        const {
            videoSrc,
            uploadHighlighted,
            showImageLoading,
            dragEnter,
            height,
            width,
            alt,
        } = this.state;

        const {
            config: {
                popupClassName,
                uploadCallback,
                uploadEnabled,
                urlEnabled,
                previewImage,
                inputAccept,
                alt: altConf,
            },
            doCollapse,
            translations,
        } = this.props;

        return (
            <div
                className={classNames('rdw-image-modal', popupClassName)}
                onClick={this.stopPropagation}
            >
                <div className="rdw-image-modal-header">
                    {uploadEnabled && uploadCallback && (
                        <span
                            onClick={this.showImageUploadOption}
                            className="rdw-image-modal-header-option"
                        >
              {translations['components.controls.image.fileUpload']}
                            <span
                                className={classNames('rdw-image-modal-header-label', {
                                    'rdw-image-modal-header-label-highlighted': uploadHighlighted,
                                })}
                            />
            </span>
                    )}
                    {urlEnabled && (
                        <span
                            onClick={this.showImageURLOption}
                            className="rdw-image-modal-header-option"
                        >
              {translations['components.controls.image.byURL']}
                            <span
                                className={classNames('rdw-image-modal-header-label', {
                                    'rdw-image-modal-header-label-highlighted': !uploadHighlighted,
                                })}
                            />
            </span>
                    )}
                </div>
                {uploadHighlighted ? (
                    <div onClick={this.fileUploadClick}>
                        <div
                            onDragEnter={this.onDragEnter}
                            onDragOver={this.stopPropagation}
                            onDrop={this.onVideoDrop}
                            className={classNames('rdw-image-modal-upload-option', {
                                'rdw-image-modal-upload-option-highlighted': dragEnter,
                            })}
                        >
                            <label
                                htmlFor="file"
                                className="rdw-image-modal-upload-option-label"
                            >
                                {previewImage && videoSrc ? (
                                    <img
                                        src={videoSrc}
                                        alt={videoSrc}
                                        className="rdw-image-modal-upload-option-image-preview"
                                    />
                                ) : (
                                    videoSrc ||
                                    translations['components.controls.image.dropFileText']
                                )}
                            </label>
                        </div>
                        <input
                            type="file"
                            id="file"
                            accept={inputAccept}
                            onChange={this.selectVideo}
                            className="rdw-image-modal-upload-option-input"
                        />
                    </div>
                ) : (
                    <div className="rdw-image-modal-url-section">
                        <input
                            className="rdw-image-modal-url-input"
                            placeholder={translations['components.controls.video.enterlink']}
                            name="videoSrc"
                            onChange={this.updateValue}
                            onBlur={this.updateValue}
                            value={videoSrc}
                        />
                        <span className="rdw-image-mandatory-sign">*</span>
                    </div>
                )}
                {altConf.present && (
                    <div className="rdw-image-modal-size">
                        <span className="rdw-image-modal-alt-lbl">Alt Text</span>
                        <input
                            onChange={this.updateValue}
                            onBlur={this.updateValue}
                            value={alt}
                            name="alt"
                            className="rdw-image-modal-alt-input"
                            placeholder="alt"
                        />
                        <span className="rdw-image-mandatory-sign">
              {altConf.mandatory && '*'}
            </span>
                    </div>
                )}
                <div className="rdw-image-modal-size">
                    &#8597;&nbsp;
                    <input
                        onChange={this.updateValue}
                        onBlur={this.updateValue}
                        value={height}
                        name="height"
                        className="rdw-image-modal-size-input"
                        placeholder="Height"
                    />
                    <span className="rdw-image-mandatory-sign">*</span>
                    &nbsp;&#8596;&nbsp;
                    <input
                        onChange={this.updateValue}
                        onBlur={this.updateValue}
                        value={width}
                        name="width"
                        className="rdw-image-modal-size-input"
                        placeholder="Width"
                    />
                    <span className="rdw-image-mandatory-sign">*</span>
                </div>
                <span className="rdw-image-modal-btn-section">
          <button
              className="rdw-image-modal-btn"
              onClick={this.addVideoFromState}
              disabled={
                  !videoSrc || !height || !width || (altConf.mandatory && !alt)
              }
          >
            {translations['generic.add']}
          </button>
          <button className="rdw-image-modal-btn" onClick={doCollapse}>
            {translations['generic.cancel']}
          </button>
        </span>
                {showImageLoading ? (
                    <Alert classes={['info']} content={"Uploading, please wait..."} style={{marginTop: 20}}/>
                    // <div className="rdw-image-modal-spinner">
                    //     Uploading...
                    // </div>
                ) : (
                    undefined
                )}
            </div>
        );
    }

    render() {
        const {
            config: { className, title },
            expanded,
            onExpandEvent,
            translations,
        } = this.props;
        return (
            <div
                className="rdw-image-wrapper"
                aria-haspopup="true"
                aria-expanded={expanded}
                aria-label="rdw-image-control"
            >
                <OptionComponentWrapper
                    className={classNames(className)}
                    value="unordered-list-item"
                    onClick={onExpandEvent}
                    title={title || translations['components.controls.image.image']}
                >
                    <i className="fas fa-file-video" />
                </OptionComponentWrapper>
                {expanded ? this.renderAddVideoModal() : undefined}
            </div>
        );
    }
}

// Outer part of the video uploader, this renders the button
class VideoUploadControl extends Component {
    static propTypes = {
        editorState: PropTypes.object,
        onChange: PropTypes.func,
        modalHandler: PropTypes.object,
        config: PropTypes.object,
        translations: PropTypes.object,
        handleUpload: PropTypes.func
    };

    constructor(props) {
        super(props);
        const { modalHandler } = this.props;
        this.state = {
            expanded: false,
        };
        modalHandler.registerCallBack(this.expandCollapse);
    }

    componentWillUnmount() {
        const { modalHandler } = this.props;
        modalHandler.deregisterCallBack(this.expandCollapse);
    }

    onExpandEvent = () => {
        this.signalExpanded = !this.state.expanded;
    };

    doExpand = () => {
        this.setState({
            expanded: true,
        });
    };

    doCollapse = () => {
        this.setState({
            expanded: false,
        });
    };

    expandCollapse = () => {
        this.setState({
            expanded: this.signalExpanded,
        });
        this.signalExpanded = false;
    };

    addVideo = (src, height, width, alt) => {
        const { editorState, onChange, config } = this.props;
        const entityData = { src, height, width, type: 'tidal-video' };
        if (config.alt.present) {
            entityData.alt = alt;
        }

        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'tidal-video',
            'IMMUTABLE',
            {src: src}
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
            editorState,
            {currentContent: contentStateWithEntity}
        );
        const appendedState =AtomicBlockUtils.insertAtomicBlock(
                newEditorState,
                entityKey,
                ' '
            );

        onChange(appendedState);
        this.doCollapse();
    };

    render() {
        const { config = {}, translations } = this.props;
        const { expanded } = this.state;
        const VideoComponent = config.component || VideoUploadLayoutComponent;
        return (
            <VideoComponent
                config={config}
                translations={translations}
                onChange={this.addVideo}
                expanded={expanded}
                onExpandEvent={this.onExpandEvent}
                doExpand={this.doExpand}
                doCollapse={this.doCollapse}
            />
        );
    }
}

class WYSIWYG extends Component {

    static defaultProps = {
        classes: [],
        style: {},
        allowHtmlMode: true,
        allowVideoEmbed: true,
    };

    static propTypes = {
        classes: PropTypes.array,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        fetchAuthenticated: PropTypes.func,
        style: PropTypes.object,
        allowHtmlMode: PropTypes.bool,
        allowVideoEmbed: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        let html = props.value;
        if (html === null) {
            html = '';
        }

        const editorState = this.makeEditorStateFromHTML(html);

        this.state = {
            editorState,
            showHtmlMode: false,
            isRawHtmlEdited: false,
            editorRawHtml: null
        };

        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    componentDidUpdate(prevProps) {

        let currentPropsHtml = this.props.value;
        if (currentPropsHtml === null) {
            currentPropsHtml = '';
        }

        let prevPropsHtml = prevProps.value;
        if (prevPropsHtml === null) {
            prevPropsHtml = '';
        }

        // Compare current state HTML to props HTML
        const currentStateHTML = this.getEditorHTML();
        const currentPropsState = this.makeEditorStateFromHTML(currentPropsHtml);
        const currentPropsHTML = this.getHTMLFromEditorState(currentPropsState);
        const prevPropsState = this.makeEditorStateFromHTML(prevPropsHtml);
        const prevPropsHTML = this.getHTMLFromEditorState(prevPropsState);

        if (prevPropsHTML !== currentPropsHTML && currentPropsHTML !== currentStateHTML) {
            this.setState({editorState: currentPropsState});
        }

    }

    getHTMLFromEditorState(editorState) {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()), undefined, undefined, function (entity, text) {
            if (entity.type === 'tidal-video') {
                const videoSrc = entity.data.src;
                let renderVideoTag = true;
                // if it's a brightcove video, render an iframe
                if (videoSrc.indexOf('brightcove') !== -1) {
                    renderVideoTag = false;
                }

                if (renderVideoTag) {
                    return ` <video src="${entity.data.src}" controls muted></video> `;
                } else {
                    return `<iframe src="${entity.data.src}" frameborder="0" allowfullscreen></iframe>`;
                }
            }
        });

        return html;
    }

    getEditorHTML() {

        if (typeof this.state.editorState !== 'undefined') {
            return this.getHTMLFromEditorState(this.state.editorState);
        }

        return '';

    }

    setEditorHTML(html) {
        const editorState = this.makeEditorStateFromHTML(html);
        return this.setState({editorState});
    }

    makeEditorStateFromHTML(html) {
        const contentBlock = htmlToDraft(html, function (nodeName, node) {
            // Handle custom video element.
            if (nodeName === 'video') {
                return {
                    type: 'tidal-video',
                    mutability: 'IMMUTABLE',
                    data: {src: node.src}
                }
            }
        });

        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    }

    onEditorStateChange(editorState) {
        if (typeof this.state.editorState !== 'undefined') {
            this.setState({
                editorState
            });

            const html = this.getHTMLFromEditorState(editorState);

            this.props.onChange(html);
        }
    };

    async handleUploadVideo(file) {
        console.log("Getting signed url");
        const slot = window.Gator.getDashboardSlot();
        const signingParams = { objectName: file.name, contentType: file.type };
        const signResp = await this.props.fetchAuthenticated(`/${slot}/api/video/_/signature`, {
            method: 'post',
            body: makeFormData(signingParams)
        });
        const signRespObj = await signResp.json();
        const signingPayload = signRespObj.data;
        const signedUrl = signingPayload.signedUrl;
        console.log("Got signed url", signingPayload);


        try {
            const headers = new Headers();
            headers.set('Content-Type', file.type);
            const uploadReq = await fetch(signedUrl, {method: 'PUT', body: file, mode: "cors", headers});
            console.log("Uploaded file");
        } catch (err) {
            console.log("Error uploading file", err);
            return;
        }

        // now create the video object
        const videoObjectResp = await this.props.fetchAuthenticated(`/${slot}/api/video/_/object`, {
            method: 'post',
            body: makeFormData({...signingPayload})
        })
        const videoObjectData = await videoObjectResp.json();
        console.log("Created video object", videoObjectData);

        return {
            data: {
                link: videoObjectData.data.src,
                src: videoObjectData.data.src,
                url: videoObjectData.data.src,
                id: videoObjectData.data.id,
            }
        };
    }

    handleUploadImage(file) {

        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/image`;

        const formData = new FormData();
        formData.append('image', file);

        return this.props.fetchAuthenticated(url, {
            method: 'post',
            body: formData
        })
            .then(resp => resp.json())
            .then(json => {
                return {
                    data: {
                        link: json.data.original_url
                    }
                }
            });

    }

    renderHtmlModePopup() {

        if (!this.state.showHtmlMode) {
            return null;
        }

        return (
            <Toolbox
                title={'Edit HTML'}
                onClose={() => this.setState({showHtmlMode: false})}
                style={{
                    top: 40,
                    width: '100%',
                }}
                content={
                    <div>
                        {this.state.isRawHtmlEdited && <Alert
                            classes={['info']}
                            content={<span>You have unsaved changes to your HTML. <a onClick={() => this.saveRawHtml()} role="button" className="v3 bold">Save HTML.</a></span>}
                        />
                        }
                        <Textarea
                            value={this.state.editorRawHtml}
                            onChange={event => this.setState({
                                editorRawHtml: event.target.value,
                                isRawHtmlEdited: true
                            })}
                            style={{
                                fontFamily: 'Courier New',
                                background: '#FAFAFA',
                                height: 300,
                                overflow: 'auto',
                                border: 'none',
                            }}
                        />
                    </div>
                }
            />

        );

    }

    saveRawHtml() {
        this.setEditorHTML(this.state.editorRawHtml);
        this.setState({showHtmlMode: false});
    }

    getToolbarCustomButtons() {

        let buttons = [];

        if (this.props.allowVideoEmbed) {
            buttons.push(
                <VideoUploadControl config={{...VIDEO_CONFIG, uploadCallback: this.handleUploadVideo.bind(this)}} />
            );
        }
        if (this.props.allowHtmlMode) {
            buttons.push(
                <div
                    className={"rdw-option-wrapper"}
                    style={{verticalAlign: 'top', display: 'inline-block', marginLeft: 10, paddingTop: 3}}
                    onClick={() => this.setState({
                        showHtmlMode: !this.state.showHtmlMode,
                        editorRawHtml: this.getEditorHTML(),
                        isRawHtmlEdited: false,
                    })}
                ><i className="fas fa-code"/></div>
            );
        }


        return buttons;
    }

    render() {
        let classes = ['form-control', ...this.props.classes];

        const { editorState } = this.state;
        const blockRenderMap = Immutable.Map({ 'tidal-video': { element: 'video' } });
        const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

        return (
            <div className="form-wysiwyg-wrapper" style={{position: 'relative'}}>
                {this.renderHtmlModePopup()}
                <Editor
                    className={classes.join(' ')}
                    placeholder={this.props.placeholder}
                    editorState={editorState}
                    wrapperClassName="tidal-wysiwyg-wrapper"
                    editorClassName="tidal-wysiwyg-editor"
                    toolbarClassName="tidal-wysiwyg-toolbar"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbarCustomButtons={this.getToolbarCustomButtons()}
                    blockRenderMap={extendedBlockRenderMap}
                    customBlockRenderFunc={customBlockRenderer}
                    toolbar={{
                        options: ['blockType', 'inline', 'list', 'textAlign', 'link', 'image'],
                        inline: {
                            inDropdown: true,
                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                        },
                        list: {
                            inDropdown: true
                        },
                        textAlign: {
                            inDropdown: true
                        },
                        link: {
                            inDropdown: true
                        },
                        image: {
                            urlEnabled: true,
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: this.handleUploadImage.bind(this),
                            previewImage: true,
                            alt: { present: true}
                        },

                    }}
                />
            </div>
        )
    }


}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({fetchAuthenticated}, dispatch);

export default connect(undefined, mapDispatchToProps)(WYSIWYG);
