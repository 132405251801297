import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class IFrame extends Component {
    constructor(props) {
        super(props);
        this.setReference = this.setReference.bind(this);
        this._updateIframe = this._updateIframe.bind(this);

        this.state = {
            contentHeight: null,
            didResize: false,
        };
    }

    componentDidMount() {
        this._updateIframe();
    }

    componentDidUpdate() {
        this._updateIframe();

        if (this.state.contentHeight !== this.getIframeContentHeight() && this.state.didResize === false) {
            this.doAutoresize();
        }
    }

    _updateIframe() {
        const iframe = this.iframe;
        const idocument = iframe.contentDocument;
        const ihead = idocument.getElementsByTagName('head')[0];

        // Required for Firefox
        // Empty iframe in FF loads about:blank, which races our innerHTML set
        // So I guess opening and then closing forces the about:blank load and avoids the race
        idocument.open();
        idocument.close();

        idocument.body.innerHTML = this.props.content;

        this.props.stylesheets.forEach(url => {
            const ref = idocument.createElement('link');
            ref.rel = 'stylesheet';
            ref.type = 'text/css';
            ref.href = url;
            ihead.appendChild(ref);
        });

    }

    getIframeContentHeight() {
        const iframe = this.iframe;
        const idocument = iframe.contentDocument;

        if (idocument && idocument.body && idocument.body.scrollHeight) {
            return idocument.body.scrollHeight;
        }

        return null;
    }

    doAutoresize() {
        if (!this.props.autoresize) {
            return;
        }

        const contentHeight = this.getIframeContentHeight();

        if (contentHeight) {
            this.setState({contentHeight, didResize: true});
        }

    }

    setReference(iframe) {
        this.iframe = iframe;
        window._testiframe = iframe;
    }

    makeStyle() {
        const {style} = this.props;

        let combinedStyle = {...style};

        if (this.state.contentHeight && this.props.autoresize) {
            combinedStyle.height = parseInt(this.state.contentHeight, 10);
        }

        return combinedStyle;

    }

    render() {
        const style = this.makeStyle();

        return (
            <iframe
                style={style}
                ref={this.setReference}
                onLoad={() => {
                    this.doAutoresize();
                }}
            />

        )
    }
}

IFrame.defaultProps = {
    style: {},
    stylesheets: [],
    autoresize: false,
};

IFrame.propTypes = {
    content: PropTypes.string.isRequired,
    style: PropTypes.object,
    stylesheets: PropTypes.array,
    autoresize: PropTypes.bool,
};
