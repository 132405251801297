/**
 * Created by visgotti on 11/1/17.
 */
import React, {Component} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import {capitalizeFirstLetter, formatNumber, formatNumberK, getImageUrl, limitChars} from '../../utilities';

import PostTimeSeriesChart from './Chart/PostTimeSeriesChart';
import PostActions from './Actions';
import StarRating from '../Common/StarRating';
import Image from '../Common/Image';

/**
 * Images shorter than this will be hidden from the UI
 * @type {number}
 */
export const POST_CARD_MIN_IMAGE_HEIGHT = 220;

export default class PostCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showStats: false,
            isVisible: false,
            wasEverVisible: false
        };
        this.handleToggleSelection = this.handleToggleSelection.bind(this);
        this.handleRatingChange = this.handleRatingChange.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
        this.handleImageLoad = this.handleImageLoad.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        // Will be a ref passed to the smart Image loader
        this.imagesViewport = null;
    }


    componentDidUpdate(prevProps, prevState) {
        this.props.fetchPostIfNeeded(this.props.postId);
        if (this.state.isVisible === true && prevState.isVisible === false && prevState.wasEverVisible === false) {
            this.props.fetchUserIfNeeded(this.props.post.user.id);
        }
    }

    handleVisibilityChange(isVisible) {

        if (isVisible === true) {
            this.setState({isVisible, wasEverVisible: true});
        } else {
            this.setState({isVisible});
        }
    }

    handleRatingChange(rating) {
        const postId = this.props.postId;
        this.props.updatePostRating(postId, rating);
    }

    handleToggleStats(show) {
        this.setState({
            showStats: show
        });
    }

    handleToggleSelection() {
        if (this.props.isSelected) {
            this.props.unselectPost(this.props.postId, this.props.queryId);
        } else {
            this.props.selectPost(this.props.postId, this.props.queryId);
        }
    }

    handleImageError(event) {
        try {
            event.target.parentNode.parentNode.parentNode.style.display = 'none';
        } catch(e) {
            console.log(e);
        }
    }

    renderCheckBox() {
        const isChecked = this.props.isSelected;
        const checkboxClass = "tidal-checkbox " + (isChecked ? 'checked' : '');
        const icon = isChecked ? <i className="fa fa-check"></i> : null;

        return (
            <span
                className={checkboxClass}
                onClick={this.handleToggleSelection}
            >
                {icon}
            </span>
        )
    }


    renderStats() {
        this.props.fetchPostStatsIfNeeded(this.props.postId);

        let showTitle = false;
        let height;

        if (this.props.size === 'medium' || this.props.size === 'large') {
            showTitle = true;
            height = 190;
        } else {
            showTitle = false;
            height = 220;
        }

        return (
            <div className="post-stats" >
                <PostTimeSeriesChart
                    stats={this.props.post.stats}
                    views={this.props.post.engagements}
                    height={height}
                    showTitle={showTitle}
                />
            </div>
        )
    }

    handleImageLoad(e) {

        if (!e.target) {
            return;
        }

        const height = e.target.naturalHeight;

        if (height < POST_CARD_MIN_IMAGE_HEIGHT) {
            // targets the <li>
            e.target.parentNode.parentNode.parentNode.style.display = 'none';
        }

    }

    renderPhotos() {
        const { images, type, title } = this.props.post;

        /**
         * No images -- just show the excerpt
         */
        if (images === undefined || images === null || images.length === 0) {
            return (
                <div className="post-content" >
                    <div className="post-content-inner">
                        {this.renderSocialMediaIcon(type)}
                        {limitChars(title, 60)}
                    </div>
                </div>
            )
        }

        /**
         * If only one image, show it background-size cover
         */
        if (images.length === 1) {

            const imageUrl = getImageUrl(images[0]);
            const style = {backgroundImage: 'url(' + imageUrl + ')'};

            return (
                <div className="post-photos">
                    <div className="single-post-photo" style={style}>
                    </div>
                </div>
            );

        }

        return (
            <div
                className="post-photos"
                ref={(e) => { this.imagesViewport = e; }}
            >
                <ul>
                    {
                        images.map(image => {
                            return(
                                <li>
                                    <div className="post-photo">
                                        <Image
                                            image={image}
                                            onError={this.handleImageError}
                                            onLoad={this.handleImageLoad}
                                            containment={this.imagesViewport}
                                        />
                                    </div>
                                </li>
                            )
                        })
                    }
                    <li>
                        <div className="additional-post-content" >
                            <div className="additional-post-content-inner">
                                {this.renderSocialMediaIcon(type)}
                                {limitChars(title, 60)}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }

    renderCheckboxTab() {
        const columnClasses =  (!this.state.showStats) ?
            'checkbox-tab post-data-tab active' :
            'checkbox-tab post-data-tab';

        return (
            <div className={columnClasses}>
                <div className="post-check-box">
                    { this.renderCheckBox() }
                </div>
            </div>
        )
    }

    renderTitleTab() {
        const { title, written_at } = this.props.post;
        const username = this.props.post.user.name;
        const userSlug = this.props.post.user.slug;

        const columnClasses =  (!this.state.showStats) ?
            'title-tab post-data-tab active' :
            'title-tab post-data-tab';

        return (
            <div className={columnClasses}>
                <div className="post-data-wrapper">
                    <div className="post-data-title"><a href={"/manage/do/page/posts/view?slug=" + this.props.post.slug} className={'lightbox-launcher'}>{title}</a></div>
                    <div className="post-data-user">by <a href={"/manage/do/page/users/detail?slug=" + userSlug} className={'dark'} onClick={e => {
                        e.preventDefault();
                        this.props.openUserLightbox(this.props.post.user.id);
                    }}>{username}</a></div>
                    <div className="post-data-date">{ moment(written_at.date).format('LL')}</div>
                </div>
            </div>
        )
    }

    renderRatingTab() {
        const { rating, is_featured, human_status, is_ftc_compliant } = this.props.post;
        const {showComplianceIssues} = this.props;
        const columnClasses =  (!this.state.showStats) ?
            'rating-tab post-data-tab active' :
            'rating-tab post-data-tab';

        return (
            <div className={columnClasses}>
                <div className="post-data-wrapper">
                    <div className="post-rating">
                        <StarRating rating={rating} onRatingChange={(rating) => this.handleRatingChange(rating)}/>
                    </div>
                    <div className="post-status">
                        <span className="status-list">
                            {
                                (showComplianceIssues === true && is_ftc_compliant === false) &&
                                    <i
                                        style={{marginRight: 4}}
                                        className="fa fa-exclamation-triangle text-danger"
                                        data-tooltip="<strong>Potential compliance issue:</strong> This post does not contain the #ad, #advertisement, or #sponsored hashtags. You may want to contact the creator and ask them to mark this post as an advertisement."
                                    />
                            }
                            {is_featured ? human_status + ', ' + 'Featured' : human_status }
                        </span>
                        <span>
                            <PostActions
                                applyAction={this.props.applyAction}
                                postId={this.props.post._id}
                                post={this.props.post}
                                fetchPostActivationRecords={this.props.fetchPostActivationRecords}
                                featurePost={this.props.featurePost}
                                publishPost={this.props.publishPost}
                                updatePostActivationStatus={this.props.updatePostActivationStatus}
                            />
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    renderSocialMediaIcon() {
        const { type } = this.props.post;
        const types = ['twitter', 'instagram', 'facebook', 'pinterest', 'youtube'];

        if(types.indexOf(type) > -1) {
            return <i className={`fa fa-${type}`}></i>
        } else {
            return<i className={'fa fa-file-o'}></i>
        }
    }

    renderSocialMediaIconWithTitle() {
        const { type } = this.props.post;
        const types = ['twitter', 'instagram', 'facebook', 'pinterest', 'youtube'];

        if(types.indexOf(type) > -1) {
            return <span><i className={`fa fa-${type}`}></i> {capitalizeFirstLetter(type)}</span>
        } else {
            return <span><i className={'fa fa-file-o'}></i> Post </span>
        }
    }

    renderSocialMediaTab() {
        const { type, rating, engagements } = this.props.post;
        const socialMediaClasses = (this.state.showStats) ? 'post-social-media active' : 'post-social-media';
        const postColumnClasses = (this.state.showStats) ?
            'social-media-tab post-data-tab active' :
            'social-media-tab post-data-tab';
        const user = this.props.user;
        const userReach = (user.basics || {verified_reach: null}).verified_reach || null;
        const formattedUserReach = userReach === null ? '...' : formatNumberK(userReach);

        return (
            <div className={postColumnClasses}>
                <div
                    className={socialMediaClasses}
                    onClick={(event) => {
                        event.stopPropagation();
                        this.handleToggleStats(true)
                    }}
                >
                    <div className="post-data-wrapper">

                        <div className="post-rating">
                            <StarRating rating={rating} onRatingChange={(rating) => this.handleRatingChange(rating)}/>
                        </div>

                        <div>
                            { this.renderSocialMediaIconWithTitle(type)}
                        </div>

                        <div>
                            <span data-tooltip="Total engagements on this post.">
                                <i className="fa fa-comments"></i> {formatNumber(engagements)}
                            </span>
                        </div>


                        <div>
                            <span data-tooltip="Creator's verified reach.">
                                <i className="fa fa-bullhorn"></i> {formattedUserReach}
                            </span>
                        </div>
                    </div>

                    <div className="social-media-graph">
                        <PostTimeSeriesChart
                            stats={this.props.post.stats}
                            size="small"
                        />
                    </div>

                </div>
            </div>
        )
    }

    renderSmallCardTop() {
        const { title, written_at, is_featured, human_status } = this.props.post;
        const username = this.props.post.user.name;
        const userSlug = this.props.post.user.slug;

        const className =  (!this.state.showStats) ?
            'post-data-top active' :
            'post-data-top';

        return (
            <div className={className}
                 onClick={() => this.handleToggleStats(false)}
            >

                <div className="post-data-title"><a href={"/manage/do/page/posts/view?slug=" + this.props.post.slug} className={'lightbox-launcher'}>{title}</a></div>
                <div className="post-data-user">by <a href={"/manage/do/page/users/detail?slug=" + userSlug} className={'dark'} onClick={e => {
                    e.preventDefault();
                    this.props.openUserLightbox(this.props.post.user.id);
                }}>{username}</a></div>
                <div className="post-data-date">{ moment(written_at.date).format('LL')}</div>
                <div className="post-status">
                    <span className="status-list">
                        {is_featured ? human_status + ', ' + 'Featured' : human_status }
                    </span>
                    <span>
                        <PostActions
                            applyAction={this.props.applyAction}
                            postId={this.props.post._id}
                            post={this.props.post}
                            fetchPostActivationRecords={this.props.fetchPostActivationRecords}
                            updatePostActivationStatus={this.props.updatePostActivationStatus}
                            featurePost={this.props.featurePost}
                            publishPost={this.props.publishPost}
                        />
                    </span>

                </div>

                <div className="post-check-box">
                    { this.renderCheckBox() }
                </div>
            </div>
        )
    }

    /*
     Since the small card layout is different than the medium and large card,
     have a new function which groups elements differently than the medium and large.
     */
    renderSmallCard() {
        return (
            <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="post-card-small">
                    <div className="post-data">
                        { this.renderSmallCardTop() }
                        { this.renderSocialMediaTab()  }
                    </div>

                    { this.state.showStats ? this.renderStats() : this.renderPhotos() }
                </div>
            </div>
        );
    }

    renderCard(outerClass) {
        const postDataClass =  (!this.state.showStats) ? 'post-data active' : 'post-data';

        return (
            <div className={outerClass}>
                <VisibilitySensor
                    scrollCheck
                    onChange={this.handleVisibilityChange}
                    partialVisibility={false}
                >
                <div className={"post-card-" + this.props.size}>
                    <div className={postDataClass}
                         onClick={() => this.handleToggleStats(false)}
                    >
                        {/*{ this.renderCheckboxTab() }*/}
                        { this.renderTitleTab() }
                        { this.renderSocialMediaTab()  }
                        { this.renderRatingTab() }
                    </div>

                    { this.state.showStats ? this.renderStats() : this.renderPhotos() }

                </div>
                </VisibilitySensor>
            </div>
        );
    }

    render() {
        if (this.props.post) {

            switch(this.props.size) {
                case 'small':
                    return this.renderSmallCard();
                case 'medium':
                    return this.renderCard('col-lg-4 col-md-6 col-sm-6 col-xs-12');
                case 'large':
                    return this.renderCard('col-md-6 col-sm-12');
            }
        }
        return null
    }
}

PostCard.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    post: PropTypes.object,
    user: PropTypes.object,
    isSelected: PropTypes.bool,
    queryId: PropTypes.number,
    showComplianceIssues: PropTypes.bool,

    fetchPostIfNeeded: PropTypes.func,
    fetchPostStats: PropTypes.func,
    selectPost: PropTypes.func,
    unselectPost: PropTypes.func,
    applyAction: PropTypes.func,
    fetchUserProfileIfNeeded: PropTypes.func,
    fetchUserIfNeeded: PropTypes.func,
    updatePostRating: PropTypes.func,
    featurePost: PropTypes.func,
    publishPost: PropTypes.func,
    fetchPostActivationRecords: PropTypes.func,
    updatePostActivationStatus: PropTypes.func,
    openUserLightbox: PropTypes.func
};

PostCard.defaultProps = {
    size: 'medium',
    post: undefined,
    user: null,
    isSelected: false,
    queryId: 1,
    showComplianceIssues: true,
    fetchPostIfNeeded: () => {},
    selectPost: () => {},
    unselectPost: () => {},
    applyAction: () => {},
};
