/**
 * Created by bkanber on 6/29/17.
 */

import {fetchAuthenticated} from "../utilities";

export const RECEIVE_NETWORKS = 'RECEIVE_NETWORKS';
export const REQUEST_NETWORKS = 'REQUEST_NETWORKS';

export function requestNetworks() {
    return {
        type: REQUEST_NETWORKS,
    };
}

export function receiveNetworks(json) {
    return {
        type: RECEIVE_NETWORKS,
        networks: json.data,
        receivedAt: Date.now()
    }
}

/**
 * @returns {Function}
 */
export function fetchNetworks() {
    return function (dispatch) {
        dispatch(requestNetworks())

        return fetchAuthenticated(`/manage/api/network`, {credentials: 'include'})
            .then(response => response.json())
            .then(json => dispatch(receiveNetworks(json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

/**
 * @param state
 * @returns {*}
 */
export function shouldFetchNetworks(state) {
    if (state.networksById.isFetching || state.networksById.lastUpdated) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchNetworksIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchNetworks(getState())) {
            return dispatch(fetchNetworks())
        }
    }
}
