/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import GenericDonutChart from '../../Chart/GenericDonutChart';
import d3 from 'd3';

/**
 * @deprecated
 * This is the old version of audience chart
 * New versions in components/Chart/Audience
 */
export default class CampaignAudienceAgeDonutChart extends Component {

    getData() {
        if (!this.props.audience || this.props.audience.length === 0) return null;

        let totals = { };

        for (let row of this.props.audience) {
            let age = row[0];
            if (typeof totals[age] === 'undefined') {
                totals[age] = 0;
            }
            totals[age] ++;
        }

        return totals;

    }

    renderChart() {
        const data = this.getData();
        if (!data) return null;

        return <GenericDonutChart
            data={data}
            title="Audience Age"
            labelFormatter={
                (value, ratio, id) => id
            }
            tooltipValueFormatter={
                (value, ratio, id) => d3.format('.0%')(ratio)
            }
        />


    }

    render() {

        return (

            <div className="widget">
                <div className="title">
                    <div className="icon left">
                        <i className="fa fa-pie-chart"></i>
                    </div>
                    <h2>
                        Audience Age
                    </h2>
                </div>
                <div className="content padded clearfix">
                    {this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
                </div>
            </div>

        );
    }
}
