import React, {useState, useEffect} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchAuthenticated} from "../../../../actions/auth";
import Toolbox from "../../../Common/Toolbox";
import PDFBuilderEditor from "../../../BriefBuilder/Editor";

function InteractiveBriefViewer({campaign, onClose, fetchAuthenticated}) {
    const [brief, setBrief] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchBrief = async () => {
        const slot = window.Gator.getDashboardSlot();
        const response = await fetchAuthenticated(`/${slot}/api/campaign/${campaign.id}/brief`);
        const briefResp = await response.json();
        setBrief(briefResp.data);
        setLoading(false);
    }

    useEffect(() => {
        fetchBrief();
    }, [campaign]);

    return (

        <div className="interactive-brief-viewer">
            <a role={"button"} onClick={onClose} className="v3 close absolute" />
            <PDFBuilderEditor docSpec={brief} isEditable={false} />
        </div>
    );
}
const mapStateToProps = undefined;
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAuthenticated: fetchAuthenticated
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(InteractiveBriefViewer);
