import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ConnectedManageProduct from './ManageProduct';
import Toolbox from "../Common/Toolbox";

class AddProduct extends Component {

    static propTypes = {
        onClose: PropTypes.func,
        catalogId: PropTypes.number,
        refresh: PropTypes.func,
        embedded: PropTypes.bool,
    };

    static defaultProps = {
        embedded: false,
    };

    getStyle() {
        let style = {
            top: 60,
            left: '50%',
            width: 800,
            marginLeft: -400,
            backgroundColor: '#EEE',
        };

        if (this.props.embedded) {
            // delete style.marginLeft;
            // delete style.top;
            // delete style.left;
            // style.width = '100%';
            // style.position = 'relative';
        }

        return style;
    }

    render() {
       return <Toolbox
            style={this.getStyle()}
            title='Add a Product'
            onClose={this.props.onClose}
            content={<ConnectedManageProduct
                onClose={this.props.onClose}
                interactionType={'AddProduct'}
                catalogId={this.props.catalogId}
                refresh={this.props.refresh}
            />}
        />
    }
}

export default AddProduct;