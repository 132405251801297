import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LiteTable from "./LiteTable";
import LiteTableHeader from "./LiteTableHeader";

class LiteTableWrapper extends Component {

    renderPopups() {
        if (this.props.popups) {
            return this.props.popups;
        }
    }

    render() {
        const classes = [`lite-table-wrapper ${this.props.addlClasses}`];
        return (
            <div className={classes}>

                <LiteTableHeader
                    headerSwitches={this.props.headerSwitches}
                    buttons={this.props.buttons}
                />

                <LiteTable
                    items={this.props.items}
                    rowSwitches={this.props.rowSwitches}
                />

                {this.renderPopups()}

            </div>
        )
    }

}

export default LiteTableWrapper;

LiteTableWrapper.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.node),
    wrapperClasses: PropTypes.array,

    /** Specify action icons for the top-right */
    headerSwitches: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.node,
        tooltip: PropTypes.string,
        isActive: PropTypes.bool,
        /**
         * Called when user clicks a switch; we attempt to bind the callback
         * @param {{name: string, icon: string, tooltip: string, isActive: boolean}} spec
         */
        onClick: PropTypes.func,
    })),

    /** Specify action icons for right side of table row */
    rowSwitches: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.node,
        tooltip: PropTypes.string,
        /**
         * Called when user clicks a switch; we attempt to bind the callback
         * @param {{name: string, icon: string, tooltip: string, isActive: boolean}} spec
         */
        onClick: PropTypes.func,
    })),

    /** Array of items to render. */
    items: PropTypes.array.isRequired,

    addlClasses: PropTypes.array,
};