import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GenericProfilePerformanceScoreTable from "../../GenericProfilePerformanceScoreTable";

class GenericProfilePerformancePane extends Component {

    static propTypes = {
        performance: PropTypes.shape({
            score: PropTypes.number,
            results: PropTypes.array
        }).isRequired
    };

    render() {
        return (
            <div className="generic-profile-pane-wrapper padding-top">
                <GenericProfilePerformanceScoreTable
                    score={this.props.performance.score}
                    results={this.props.performance.results}
                />

            </div>
        );
    }
}

export default GenericProfilePerformancePane;
