import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableWrapper from "../../Table/Wrapper";
import BasicCell from "../../Table/Cell/Basic";
import {makeCellKey} from "../../../utilities";
import CheckboxCell from "../../Table/Cell/Checkbox";
import Button from "../../Common/Form/Button";


class DuplicatesTable extends Component {
    static propTypes = {
        data: PropTypes.array,
        allowForceCreate: PropTypes.bool,
        onClaimUsers: PropTypes.func,
        onClickForceCreate: PropTypes.func,
    };

    static defaultProps = {
        allowForceCreate: false,
    };

    state = {
        selectedIds: []
    };

    getButtons() {
        const allowClaim = this.state.selectedIds.length > 0;
        return [
            <Button
                content={'Claim Users'}
                classes={['v3', 'small', 'bold', 'btn-primary', (allowClaim ? '' : 'disabled')]}
                style={{marginLeft: -20}}
                onClick={(allowClaim && this.props.onClaimUsers) ? () => this.props.onClaimUsers(this.state.selectedIds) : undefined}
            />,

            this.props.allowForceCreate && <Button
                content={'Create Anyway'}
                classes={['v3', 'small', 'bold', 'btn-secondary']}
                onClick={this.props.allowForceCreate ? this.props.onClickForceCreate : undefined}
            />,

        ];

    }
    render() {
        return (
            <TableWrapper
                items={this.props.data.map(item => ({...item, id: item.user.id}))}
                columns={this.getColumns()}
                buttons={this.getButtons()}

                showPagination={false}
                showFilters={false}
                showTotals={false}
            />
        );
    }

    getColumns() {
        const makeSocialCell = (socialType, title) => {
            return {
                key: 'user.socials.' + socialType,
                title,
                width: 200,
                default: true,
                cell: (row, column) => {
                    const matches = (row.item.user.socials || [])
                        .filter(social => social.type === socialType);
                    let content = '-';
                    if (matches.length > 0) {
                        content = matches.map(social => {
                            if (social.slug) return social.slug;
                            if (social.url) return social.url;
                            return null;
                        })
                            .filter(slug => !!slug)
                            .join('; ');
                    }
                    return <BasicCell column={column} row={row} key={makeCellKey(row, column)}
                                      value={content}
                    />;
                }
            };
        };

        return [
            {
                key: 'select',
                default: true,
                width: 60,
                cell: (row, column) => {
                    return (
                        <CheckboxCell
                            row={row}
                            column={column}
                            value={this.state.selectedIds.indexOf(row.item.id) > -1}
                            onClick={() => {
                                if (this.state.selectedIds.indexOf(row.item.id) > -1) {
                                    this.setState({selectedIds: this.state.selectedIds.filter(id => id !== row.item.id)});
                                } else {
                                    this.setState({selectedIds: [...this.state.selectedIds, row.item.id]});
                                }
                            }}
                        />
                    );
                }
            },
            {
                key: 'user.name',
                title: 'Name',
                default: true,
                width: 200,
                cell: (row, column) => <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={row.item.user.name} />
            },
            {
                key: 'user.email',
                title: 'Email',
                default: true,
                width: 200,
                cell: (row, column) => <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={row.item.user.email} />
            },
            {
                key: 'reason',
                title: 'Details',
                default: true,
                width: 300,
                cell: (row, column) => <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={row.item.reason} />
            },
            makeSocialCell('Tidal\\Social\\Instagram', 'Instagram'),
            makeSocialCell('Tidal\\Social\\Twitter', 'Twitter'),
            makeSocialCell('Tidal\\Social\\YouTube', 'YouTube'),
            makeSocialCell('Tidal\\Social\\Pinterest', 'Pinterest'),
            makeSocialCell('Tidal\\Social\\Facebook', 'Facebook'),
            makeSocialCell('Tidal\\Social\\FacebookPage', 'Facebook Page'),

        ];
    }
}

export default DuplicatesTable;