import React, {forwardRef, useRef, useImperativeHandle} from 'react';
import {DragSource, DropTarget} from "react-dnd";
import Row from "./Row";

const WrappableRow = forwardRef(function ({connectDragSource, connectDropTarget, ...props}, ref) {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    useImperativeHandle(ref, () => ({
        getNode: () => elementRef.current
    }));
    return (
        <div ref={elementRef}>
            <Row {...props} />
        </div>
    )
});

const dragSpec = {
    beginDrag(props, monitor, component) {
        return {
            id: props.item.id,
            index: props.index
        };
    },
    endDrag(props, monitor, component) {
        const dropResult = monitor.getDropResult();
        if (props.onDrop) {
            props.onDrop(dropResult);
        }
    }
};

const dragCollect = (connect, monitor) => {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
};

const DraggableRow = DragSource('table-row', dragSpec, dragCollect)(WrappableRow);

const dropSpec = {
    drop(props, monitor, component) {
        const startIndex = monitor.getItem().index;
        const endIndex = props.index;
        return {
            itemId: monitor.getItem().id,
            startIndex,
            endIndex,
            targetItemId: props.item.id
        };
    },
};
const dropCollect = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget()
    };
};

const DragDropRow = DropTarget('table-row', dropSpec, dropCollect)(DraggableRow);

export default DragDropRow;