/**
 * Created by bkroger on 5/18/18.
 */

import React, {Component} from 'react';
import Alert from '../../../Common/Alert';

class Waitlisted extends Component {

    render() {
        const _t = this.props.translate || ((k, v) => v);
        const titleText = _t('campaign.waitlisted.title', "Waitlisted");
        const alertText = _t('campaign.waitlisted.alert_text', "You have been waitlisted for this campaign. You will receive an email if you are accepted.");
        const descriptionText = _t('campaign.waitlisted.description', "This means that we are currently reviewing your profile for eligibility in this campaign.");

        return (
            <div className="influencer-page-template activation-waitlisted">
                <div className="widget">
                    <div className="content padded">

                        <h4 className="v3 bold action-pane-title">{titleText}</h4>

                        <Alert
                            classes={['info']}
                            content={alertText}
                            style={{marginBottom: 20}}
                        />

                        <p className={'v3 light h7 pane-info'}>
                            {descriptionText}
                        </p>

                    </div>
                </div>
            </div>
        )
    }
}

export default Waitlisted;