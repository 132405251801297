/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatNumber} from '../../../utilities';
import RechartsTimeseriesAreaChart from "../../Chart/RechartsTimeseriesAreaChart";
import ChartWrapper from "../../Chart/ChartWrapper";


const COARSE_ENGAGEMENT_FIELDS = {

    'facebook_engagements': 'Facebook',
    'blog_engagements': 'Blog',
    'instagram_engagements': 'Instagram',
    'instastory_engagements': 'Instastory',
    'pinterest_engagements': 'Pinterest',
    'twitter_engagements': 'Twitter',
    'youtube_engagements': 'YouTube',
    'total_other_engagements': 'Other'
};

// Not used -- yet. TODO: add state control to switch between coarse and granular
const GRANULAR_ENGAGEMENT_FIELDS = {
    'blog_comment_count': 'Blog Comments',
    'facebook_comment_count': 'Facebook Comments',
    'facebook_comment_plugin_count': 'Facebook Comments (Plugin)',
    'facebook_reaction_count': 'Facebook Reactions',
    'facebook_share_count': 'Facebook Shares',
    'instagram_comment_count': 'Instagram Comments',
    'instagram_like_count': 'Instagram Likes',
    'other_engagements': 'Other Engagements',
    'pinterest_comment_count': 'Pinterest Comments',
    'pinterest_like_count': 'Pinterest Likes',
    'pinterest_repin_count': 'Pinterest Repins',
    'tidal_vote_count': 'Post Votes',
    'twitter_favorite_count': 'Twitter Favorites',
    'twitter_retweet_count': 'Retweets',
    'twitter_tweet_count': 'Tweets',
    'youtube_comment_count': 'YouTube Comments',
    'youtube_dislike_count': 'YouTube Dislikes',
    'youtube_like_count': 'YouTube Likes',
    'instastory_engagement_count': 'Instastory Engagements',
};

const COMBINE_ENGAGEMENTS = {
    'facebook_engagements': ['facebook_comment_count', 'facebook_comment_plugin_count', 'facebook_reaction_count', 'facebook_share_count'],
    'facebook_page_engagements': ['facebook_page_comment_count', 'facebook_page_share_count', 'facebook_page_reaction_count'],
    'blog_engagements': ['blog_comment_count', 'other_engagements', 'tidal_vote_count'],
    'instagram_engagements': ['instagram_comment_count', 'instagram_like_count'],
    'instastory_engagements': ['instastory_engagement_count'],
    'pinterest_engagements': ['pinterest_comment_count', 'pinterest_like_count', 'pinterest_repin_count'],
    'twitter_engagements': ['twitter_favorite_count', 'twitter_retweet_count', 'twitter_tweet_count'],
    'youtube_engagements': ['youtube_comment_count', 'youtube_dislike_count', 'youtube_like_count'],
    'tiktok_engagements': ['tiktok_like_count', 'tiktok_share_count', 'tiktok_comment_count'],
    'total_other_engagements' : ['tidal_vote_count']
};

export default class CampaignEngagementsOverTimeChart extends Component {

    static defaultProps = {
        style: {},
    };

    static propTypes = {
        style: PropTypes.object,
    };

    getStats() {
        const stats = this.props.stats || [];
        if (!stats || stats.length === 0) return null;

        return stats.slice().map(stat => {

            for (let sumKey in COMBINE_ENGAGEMENTS) {
                let thisTotal = 0;
                let fields = COMBINE_ENGAGEMENTS[sumKey];
                for (let field of fields) {
                    let val = parseInt(stat[field] || 0, 10);
                    thisTotal += val;
                }
                stat[sumKey] = thisTotal;
            }

            return stat;
        });
    }

    getTotalEngagements() {
        const stats = this.props.stats || [];
        if (!stats || stats.length === 0) return null;
        return stats[stats.length - 1].total_engagements;
    }

    renderChart() {
        const stats = this.getStats();
        if (!stats || stats.length === 0) return null;
        const lastStat = stats[stats.length - 1];

        return (
            <RechartsTimeseriesAreaChart
                data={stats}
                stacked={true}
                seriesSettings={[
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'facebook_engagements',
                        name: `Facebook (total ${formatNumber(lastStat.facebook_engagements)})`,
                        stroke: '#B9ED91',
                        fill: '#B9ED91',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'blog_engagements',
                        name: `Blog (total ${formatNumber(lastStat.blog_engagements)})`,
                        stroke: '#206601',
                        fill: '#206601',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'instagram_engagements',
                        name: `Instagram (total ${formatNumber(lastStat.instagram_engagements)})`,
                        stroke: '#257900',
                        fill: '#257900',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'pinterest_engagements',
                        name: `Pinterest (total ${formatNumber(lastStat.pinterest_engagements)})`,
                        stroke: '#A6E874',
                        fill: '#A6E874',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'twitter_engagements',
                        name: `Twitter (total ${formatNumber(lastStat.twitter_engagements)})`,
                        stroke: '#469A01',
                        fill: '#469A01',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'youtube_engagements',
                        name: `Youtube (total ${formatNumber(lastStat.youtube_engagements)})`,
                        stroke: '#95E15B',
                        fill: '#95E15B',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'tiktok_engagements',
                        name: `TikTok (total ${formatNumber(lastStat.tiktok_engagements)})`,
                        stroke: '#58B305',
                        fill: '#58B305',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'total_other_engagements',
                        name: `Other (total ${formatNumber(lastStat.total_other_engagements)})`,
                        stroke: '#4a9604',
                        fill: '#4a9604',
                        fillOpacity: 0.8,
                        stackId: 1
                    }
                ]}
                axisSettings={[
                    {
                        axisType: 'x',
                        dataKey: 'created_at',
                        stroke: '#888',
                        interval: 0
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'left',
                        stroke: '#888',
                        tickFormatter: (value) => formatNumber(value)
                    }
                ]}
            />
        )
    }

    render() {
        const totalEngagements = this.getTotalEngagements();
        const titleBadge = totalEngagements !== null ? `${formatNumber(totalEngagements)} total engagements` : null;

        return (
            <ChartWrapper
                title="Engagements Over Time"
                titleBadge={titleBadge}
                icon="line-chart"
                chart={this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
            />
        )
    }

}
