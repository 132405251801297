/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import d3 from 'd3';
import CampaignEngagementsOverTimeChart from '../Chart/EngagementsOverTime';
import CampaignReachOverTimeChart from '../Chart/ReachOverTime';
import CampaignPostTypesDonutChart from '../Chart/PostTypesDonut';
import CampaignMediaValueDonutChart from '../Chart/MediaValueDonut';
import CampaignSocialReachDonutChart from '../Chart/SocialReachDonut';
import CampaignROIOverTimeChart from '../Chart/ROIOverTime';
import CampaignContentOverTimeChart from '../Chart/ContentOverTime';
import CampaignEngagementPctOverTimeChart from '../Chart/EngagementPctOverTime';
import CampaignParticipantGenderDonutChart from '../Chart/ParticipantGenderDonut';
import CampaignParticipantTierDonutChart from '../Chart/ParticipantTierDonut';
import CampaignPostsPerActivationDonutChart from '../Chart/PostsPerActivationDonut';
import CampaignAudienceGenderDonutChart from '../Chart/AudienceGenderDonut';
import CampaignAudienceAgeDonutChart from '../Chart/AudienceAgeDonut';
import CampaignAudienceAffinityDonutChart from '../Chart/AudienceAffinityDonut';
import CampaignActivationStatsTableWrapper from './ActivationStatsTable/index';
import CampaignActivationROITableWrapper from './ActivationROITable/index';
import CampaignInvitationStatsChart from "../Chart/InvitationStats";
import CampaignReportEMVSettings from './EMVSettings';
import CampaignAudienceGeographyBarChart from '../Chart/AudienceGeography';
import CampaignAffinityCategoryBarChart from '../Chart/AffinityCategoryBarChart';
import GenericCategoryBarChart from '../../Chart/GenericCategoryBarChart';
import ConnectedPosts from '../../../containers/Post/ConnectedPosts';

import {formatNumber, formatNumberK, makeMomentFromDate, preventDefaultIfPossible, ucwords} from '../../../utilities';
import {calculateRoi, EMV_TIDAL_DEFAULTS} from '../../../utilities/emv';
import ChartWrapper from "../../Chart/ChartWrapper";
import RechartsDonutChart from "../../Chart/RechartsDonutChart";
import RechartsBarChart from "../../Chart/RechartsBarChart";
import AudienceDonutChart from "../../Chart/Audience/AudienceDonutChart";
import AudienceBarChart from "../../Chart/Audience/AudienceBarChart";
import AudienceCategoryChart from "../../Chart/Audience/AudienceCategoryChart";
import AudienceMentionExplorer from "../../Chart/Audience/AudienceMentionExplorer";
import RechartsTimeseriesAreaChart from "../../Chart/RechartsTimeseriesAreaChart";
import _get from "lodash/get";
import moment from "moment";
import Toolbox from "../../Common/Toolbox";
import DateRange from "../../Common/Form/DateRange";
import Alert from "../../Common/Alert";
import {IGNORE_INTERPOLATE_FIELDS, linearInterpolateSeries} from "../../../utilities/stats";
import CampaignMediaValueByNetworkDonutChart from "../Chart/MediaValueByNetworkDonut";

export default class CampaignReport extends Component {


    static defaultProps = {
        print: false,

        showHeader: true,
        showROITable: true,
        showInfluencersTable: true,
        showTopPosts: true,
        style: {},

        showMainMenu: true,
        showDownloadMenu: true,
        showDateFilterMenu: true,
    };

    static propTypes = {

        style: PropTypes.object,
        subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        showHeader: PropTypes.bool,
        showROITable: PropTypes.bool,
        showInfluencersTable: PropTypes.bool,
        showTopPosts: PropTypes.bool,
        showMainMenu: PropTypes.bool,
        showDownloadMenu: PropTypes.bool,
        showDateFilterMenu: PropTypes.bool,

        print: PropTypes.bool,
        campaign: PropTypes.object.isRequired,
        activations: PropTypes.array,
        stats: PropTypes.object,
        audience: PropTypes.array,
        report: PropTypes.object,

        updateEMVParams: PropTypes.func,
        fetchAffinity: PropTypes.func,
        fetchCampaign: PropTypes.func,
        fetchReport: PropTypes.func,
        fetchStats: PropTypes.func,
        fetchActivations: PropTypes.func,
        fetchActivationStats: PropTypes.func,
        fetchUsers: PropTypes.func,
        fetchAudience: PropTypes.func,
        fetchGeography: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            currentView: 'overview',
            showDownloadToolbox: false,
            showSettingsToolbox: false,
            showEMVSettingsToolbox: false,
            showDateFilterToolbox: false,
            dateRange: null,
            print: props.print,
            interpolatedTimeseries: null
        };
        this.handleChangeView = this.handleChangeView.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleToggleDownloadToolbox = this.handleToggleDownloadToolbox.bind(this);
        this.handleToggleSettingsToolbox = this.handleToggleSettingsToolbox.bind(this);
        this.handleToggleEMVSettingsToolbox = this.handleToggleEMVSettingsToolbox.bind(this);
        this.handleToggleDateFilterToolbox = this.handleToggleDateFilterToolbox.bind(this);
        this.handleEMVChange = this.handleEMVChange.bind(this);
    }

    handleEMVChange(emvs) {
        console.log("Got new EMVS for campaign");
        console.log(emvs);
        this.props.updateEMVParams(emvs);
    }

    handleToggleEMVSettingsToolbox(event) {
        preventDefaultIfPossible(event);
        this.setState({
            showEMVSettingsToolbox: !this.state.showEMVSettingsToolbox,
            showSettingsToolbox: false
        });
    }

    handleToggleSettingsToolbox(event) {
        preventDefaultIfPossible(event);
        this.setState({
            showSettingsToolbox: !this.state.showSettingsToolbox,
            showEMVSettingsToolbox: false
        });
    }

    handleToggleDownloadToolbox(event) {
        preventDefaultIfPossible(event);
        this.setState({
            showDownloadToolbox: !this.state.showDownloadToolbox
        });
    }

    handlePrint(event) {
        preventDefaultIfPossible(event);
        this.setState({print: true, showDownloadToolbox: false}, () => {

            setTimeout(() => {
                // To force resize of c3js charts
                window.dispatchEvent(new Event('resize'));

                setTimeout(() => {

                    // Window.print seems to block FYI
                    window.print();

                    // Go back to non-print view
                    setTimeout(() => {
                        this.setState({print: false}, () => {
                            // To force resize of c3js charts
                            window.dispatchEvent(new Event('resize'));
                        });
                    }, 1000);

                }, 1000);
            }, 100);
        });
    }

    componentDidMount() {
        this.props.fetchCampaign(this.props.campaignId); // probably unnecessary, but just in case
        this.props.fetchReport();
        this.props.fetchActivations();
        this.props.fetchUsers();

        // this.props.fetchActivationStats();
        // this.props.fetchAudience();
        // this.props.fetchGeography();
        // this.props.fetchAffinity();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If we have this.props.report.timeseries, and we don't have this.state.interpolatedTimeseries, then interpolate it
        if (this.props.report.timeseries && !this.state.interpolatedTimeseries) {

            let duration = {days: 1};
            this.setState({
                interpolatedTimeseries: linearInterpolateSeries(this.props.report.timeseries, duration, IGNORE_INTERPOLATE_FIELDS, 'created_at')
            });

        }

    }

    getTimeseries() {

        // if we have date filter, use that
        const dateRange = this.state.dateRange;
        const minDate = (dateRange && dateRange.min) ? makeMomentFromDate(dateRange.min) : null;
        const maxDate = (dateRange && dateRange.max) ? makeMomentFromDate(dateRange.max) : null;

        if (minDate || maxDate) {
            return (this.state.interpolatedTimeseries || []).filter(t => {
                    const date = makeMomentFromDate(t.created_at);
                    return (!minDate || date.isSameOrAfter(minDate)) && (!maxDate || date.isSameOrBefore(maxDate));
                });
        }

        return this.props.report.timeseries || [];
    }

    getStat() {
        const stats = this.getTimeseries();
        if (!stats || stats.length === 0) return null;
        return stats[stats.length - 1];
    }

    getValidActivations() {
        return this.props.activations
            .filter(a => (a.stats.length > 0 && a.user))
            .filter(activation => {
                // also filter out by date range
                if (this.state.dateRange && (this.state.dateRange.min || this.state.dateRange.max)) {
                    const minDate = this.state.dateRange.min ? makeMomentFromDate(this.state.dateRange.min) : null;
                    const maxDate = this.state.dateRange.max ? makeMomentFromDate(this.state.dateRange.max) : null;
                    const activationDate = makeMomentFromDate(activation.created_at);
                    return (!minDate || activationDate.isSameOrAfter(minDate)) && (!maxDate || activationDate.isSameOrBefore(maxDate));
                } else {
                    return true;
                }
            }) ;
    }

    getActiveUsers() {
        return this.getValidActivations().map(a => a.user).filter(u => u !== null);
    }

    renderDownloadToolbox() {

        const downloadBase = `/manage/do/widget/campaign/export?id=${this.props.campaign.id}`;
        const downloadTsv = downloadBase + '&format=tsv&download=1';
        const downloadXlsx = downloadBase + '&format=xlsx&download=1';
        const downloadPptx = `/manage/do/widget/campaign/exportppt?id=${this.props.campaign.id}`;

        return (
            <div className="toolbox campaign-report-download-toolbox">
                <h2 className="title">Download Report</h2>
                <a className="close absolute" onClick={this.handleToggleDownloadToolbox}><i className="fa fa-times"></i></a>

                <div className="fake-li">
                    <a href={downloadPptx}>
                        <i className="fa fa-file-powerpoint-o"></i> Download PPT Presentation
                    </a>
                </div>

                <div className="fake-li">
                    <a href={downloadXlsx}>
                        <i className="fa fa-file-excel-o"></i> Download Excel File
                    </a>
                </div>
                <div className="fake-li">
                    <a href={downloadTsv}>
                        <i className="fa fa-file-text-o"></i> Download Tab-Separated File
                    </a>
                </div>
                <div className="fake-li">
                    <a href="#" onClick={this.handlePrint}>
                        <i className="fa fa-file-pdf-o"></i> Download PDF
                    </a>
                </div>

            </div>

        );

    }

    renderHeader() {

        if (!this.props.showHeader) return null;

        let subtitle = this.props.subtitle || 'Campaign Report';

        // If the report is loaded, show the report date timestamp in the subtitle, and also estimate the time until next report (which should be 30 hours from last report)
        if (this.props.report && this.props.report.timeseries && this.props.report.timeseries.length > 0) {
            const lastReport = this.props.report.timeseries[this.props.report.timeseries.length - 1];
            const lastReportDate = makeMomentFromDate(lastReport.created_at);
            let lastReportAgo = lastReportDate.fromNow();
            // if the last report date is in the future (which can happen if the user's clock is wrong):
            if (lastReportAgo.indexOf('ago') === -1) {
                lastReportAgo = 'just now';
            }

            const nextReportDate = lastReportDate.clone().add(30, 'hours');
            let nextReportIn = nextReportDate.fromNow();
            if (nextReportDate.isBefore(moment())) {
                nextReportIn = 'soon';
            }
            subtitle = `Report as of ${lastReportAgo} (next update ${nextReportIn})`;
        }

        return (
            <div className="row">
                <div className="col-xs-12" style={{marginTop: 40, marginBottom: 15}}>
                    <h2 className="pull-left"><i className="fa fa-star"></i> {this.props.campaign.name}
                        <small className="title-info">&nbsp;{subtitle}</small>
                    </h2>

                    <div className="right header-action-icons hide-print">
                        {this.props.showDateFilterMenu && (
                            <a href="#" onClick={this.handleToggleDateFilterToolbox}
                               data-tooltip="Filter by date.">
                                <i className="fa fa-calendar"></i>
                            </a>
                        )}
                        {this.props.showMainMenu && (
                            <a href="#" onClick={this.handleToggleSettingsToolbox}
                               data-tooltip="Settings for this report.">
                                <i className="fa fa-bars"></i>
                            </a>
                        )}

                        {this.props.showDownloadMenu && (
                            <a href="#" onClick={this.handleToggleDownloadToolbox}
                               data-tooltip="Download a spreadsheet or PDF version of the report.">
                                <i className="fa fa-download"></i>
                            </a>
                        )}
                    </div>

                </div>
                {this.renderDateFilterInfoRow()}
            </div>
        )
    }

    handleChangeView(view, event) {
        event.preventDefault();
        this.setState({
            currentView: view
        });
    }

    renderTabs() {
        const stats = this.getStat();
        const roi = calculateRoi(stats, this.props.campaign.emv_params || EMV_TIDAL_DEFAULTS);
        const engagements = stats ? stats.total_engagements : null;
        const reach = stats ? stats.total_reach : null;
        const creators = this.getActiveUsers().length;

        const roiInfo = roi ? <span className="visible-lg-inline">({formatNumber(roi, 1)}x return)</span> : null;
        const engagementsInfo = engagements ?
            <span className="visible-lg-inline">({formatNumberK(engagements)} engagements)</span> : null;
        const creatorsInfo = creators ? <span className="visible-lg-inline">({creators} creators)</span> : null;
        const reachInfo = reach ? <span className="visible-lg-inline">({formatNumberK(reach)} reach)</span> : null;


        return (
            <div className="row hide-print">
                <div className="col-xs-12">
                    <ul className="nav nav-tabs tidal-nav-tabs campaign-report-nav">

                        <li className={this.state.currentView === 'overview' ? 'active' : ''}>
                            <a href="#" onClick={this.handleChangeView.bind(this, 'overview')}
                            >Campaign Overview</a>
                        </li>

                        <li className={this.state.currentView === 'roi' ? 'active' : ''}>
                            <a href="#" onClick={this.handleChangeView.bind(this, 'roi')}
                            >Return on Investment {roiInfo}</a>
                        </li>
                        <li className={this.state.currentView === 'engagements' ? 'active' : ''}>
                            <a href="#" onClick={this.handleChangeView.bind(this, 'engagements')}>Content &amp;
                                Engagements {engagementsInfo}</a>
                        </li>
                        <li className={this.state.currentView === 'creators' ? 'active' : ''}>
                            <a href="#"
                               onClick={this.handleChangeView.bind(this, 'creators')}>Creators {creatorsInfo}</a>
                        </li>
                        <li className={this.state.currentView === 'audience' ? 'active' : ''}>
                            <a href="#" onClick={this.handleChangeView.bind(this, 'audience')}>Audience {reachInfo}</a>
                        </li>
                    </ul>
                </div>
            </div>
        );

    }

    renderSubView() {

        if (this.state.print) {
            return [
                this.renderEngagementsSubView(),
                this.renderRoiSubView(),
                this.renderAudienceSubView(),
                this.renderCreatorsSubView()
            ];
        }

        switch (this.state.currentView) {
            case 'roi':
                return this.renderRoiSubView();
                break;
            case 'engagements':
                return this.renderEngagementsSubView();
                break;
            case 'audience':
                return this.renderAudienceSubView();
                break;
            case 'creators':
                return this.renderCreatorsSubView();
                break;
            case 'overview':
            default:
                return this.renderOverviewSubView();
        }
    }

    renderOverviewSubView() {
        return (
            <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignPostTypesDonutChart stats={this.getTimeseries()} />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignSocialReachDonutChart stats={this.getTimeseries()} />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignParticipantTierDonutChart users={this.getActiveUsers()} />
                </div>
                <div className="col-xs-12 col-sm-12">
                    <CampaignEngagementPctOverTimeChart stats={this.getTimeseries()} />
                </div>

            </div>
        );

    }

    renderReachByTierChart() {
        const data = (this.props.report.by_tier || {}).total_reach || {};
        let chartData = [];

        for (let entry in data) {
            chartData.push({
                name: entry,
                value: data[entry]
            })
        }

        const chart = <RechartsBarChart data={chartData} seriesName="Reach by Tier" />;

        return (
            <ChartWrapper
                title="Reach by Tier"
                icon="users"
                chart={chart}
            />
        )
    }

    renderAvgReachByTierChart() {
        const data = (this.props.report.by_tier || {}).avg_reach || {};
        let chartData = [];

        for (let entry in data) {
            chartData.push({
                name: entry,
                value: data[entry]
            })
        }

        const chart = <RechartsBarChart data={chartData} seriesName="Avg Reach by Tier" />

        return (
            <ChartWrapper
                title="Avg Reach by Tier"
                icon="users"
                chart={chart}
            />
        )
    }

    renderAudienceDemographicsCharts() {
        const audience = this.props.report.audience;

        if (!audience) {
            return null;
        }

        const distribution = audience.distribution;
        // Clean up authenticity a bit
        let authenticity = {
            'Undetermined': 1,
            'Authentic': 0,
            'Not Authentic': 0,
            ...distribution.is_authentic
        };

        authenticity['Undetermined'] = 1 - (authenticity['Authentic'] + authenticity['Not Authentic']);



        return (
            <React.Fragment>
                {distribution.category && (
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <ChartWrapper
                        style={{height: 406}}
                        title={"Content Category"}
                        icon={"search"}
                        showExportButton={true}
                        chart={ <AudienceCategoryChart
                            data={distribution.category}
                            limit={10}
                        /> }
                    />
                </div>
                )}
                {distribution.gender && (
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <ChartWrapper
                        title={"Audience Gender"}
                        icon={"gender"}
                        chart={ <AudienceDonutChart data={distribution.gender} title={"Gender"} /> }
                    />
                </div>
                )}
                {distribution.relationship && (
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <ChartWrapper
                        title={"Relationship Status"}
                        icon={"heart"}
                        chart={ <AudienceDonutChart data={distribution.relationship} title={"Relationship"} /> }
                    />
                </div>
                )}
                {distribution.parenthood && (
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <ChartWrapper
                        title={"Parenthood Status"}
                        icon={"child"}
                        chart={ <AudienceDonutChart data={distribution.parenthood} title={"Parenthood"} /> }
                    />
                </div>
                )}
                {distribution.hhi && (
                <div className="col-xs-12 col-sm-8 col-md-8">
                    <ChartWrapper
                        title={"Household Income"}
                        icon={'dollar-sign'}
                        chart={ <AudienceBarChart
                            data={distribution.hhi}
                            title={'Household Income'}
                            dataKeys={['$0 - $25k', '$25k - $50k', '$50k - $75k', '$75k - $100k', '$100k - $150k', '$150k - $200k', '$200k+']}
                        /> }
                    />
                </div>
                )}
                {distribution.age && (
                <div className="col-xs-12 col-sm-8 col-md-8">
                    <ChartWrapper
                        title={"Audience Age"}
                        icon={'users'}
                        chart={ <AudienceBarChart
                            data={distribution.age}
                            title={'Age'}
                            dataKeys={['13-17', '18-24', '25-34', '35-44', '45-64', '65-']}
                        /> }
                    />
                </div>
                )}
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <ChartWrapper
                        title={"Audience Authenticity"}
                        icon={"check-circle"}
                        chart={ <AudienceDonutChart data={authenticity} title={"Authenticity"} /> }
                    />
                </div>
                {distribution.race && (
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <ChartWrapper
                        title={"Audience Race"}
                        icon={"user"}
                        chart={ <AudienceDonutChart data={distribution.race} title={"Race"} /> }
                    />
                </div>)}
                {(distribution.top_interests || distribution.iqdata_interests) && (
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <ChartWrapper
                        style={{height: 406}}
                        title={"Top Interests"}
                        icon={"search"}
                        showExportButton={true}
                        chart={ <AudienceCategoryChart
                            data={distribution.top_interests || distribution.iqdata_interests}
                            limit={10}
                        /> }
                    />
                </div>)}
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <AudienceMentionExplorer
                        style={{height: 406}}
                        titles={distribution.mentioned_titles}
                        organizations={distribution.mentioned_organizations}
                        people={distribution.mentioned_people}
                        events={distribution.mentioned_events}
                        products={distribution.mentioned_products}
                        locations={distribution.mentioned_locations}
                        hashtags={distribution.top_hashtags}
                    />
                </div>
            </React.Fragment>

        );
    }
    renderAudienceSubView() {

        const affinity = this.props.report.affinities || {};

        const bestAffinities = (affinity.best_affinities || {});
        const topAffinities = (affinity.top_affinities || {});
        const demographics = this.props.report.audience_demographics;
        const audience = this.props.report.audience;
        const hasTierData = !!this.props.report.by_tier;

        return (

            <div className="row">
                <div className="col-xs-12 col-sm-12">
                    <CampaignEngagementPctOverTimeChart stats={this.getTimeseries()}/>
                </div>

                {this.renderAudienceDemographicsCharts()}

                {hasTierData && (
                    <>
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            {this.renderReachByTierChart()}
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-6">
                            {this.renderAvgReachByTierChart()}
                        </div>
                    </>
                )}

                {
                    (topAffinities && Object.keys(topAffinities).length > 0) ?

                    <div className="col-xs-12 col-sm-6 col-md-6">
                        <CampaignAffinityCategoryBarChart
                            data={topAffinities}
                            title={<span>Most Engaging Affinities <span
                                data-tooltip="Engagements in this campaign came from users who are interested in the following topics."><i
                                className="fa fa-question-circle"></i></span></span>}
                            seriesName={'Top Affinities'}
                            iconClass="fa fa-heart-o"
                        />
                    </div>
                        : null
                }
                {
                    (bestAffinities && Object.keys(bestAffinities).length > 0) ?
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            <CampaignAffinityCategoryBarChart
                                data={bestAffinities}
                                title={<span>Most Effective Affinities <span
                                    data-tooltip="Users who are interested in the following topics are most likely to engage with your content."><i
                                    className="fa fa-question-circle"></i></span></span>}
                                seriesName={'Best Affinities'}
                                iconClass="fa fa-heart"
                            />
                        </div>
                        : null
                }

                <div className="col-xs-12 col-sm-6 col-md-6">
                    <CampaignAudienceGeographyBarChart which="countries" campaign={this.props.campaign}/>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <CampaignAudienceGeographyBarChart which="cities" campaign={this.props.campaign}/>
                </div>
            </div>

        )

    }

    renderCreatorsSubView() {
        return (
            <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignSocialReachDonutChart stats={this.getTimeseries()}/>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignParticipantGenderDonutChart users={this.getActiveUsers()}/>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignParticipantTierDonutChart users={this.getActiveUsers()}/>
                </div>
                {/*<div className="col-xs-12 col-sm-12">*/}
                    {/*<CampaignInvitationStatsChart campaign={this.props.campaign} report={this.props.report}/>*/}
                {/*</div>*/}

                {
                    this.props.showInfluencersTable
                    &&
                    <div className="col-xs-12 col-sm-12">
                        <CampaignActivationStatsTableWrapper
                            campaign={this.props.campaign}
                            campaignStats={this.getTimeseries()}
                            activations={this.getValidActivations()}
                        />
                    </div>
                }
            </div>
        );

    }

    renderEngagementsByNetworkChart() {

        const stat = this.getStat();
        if (!stat) {
            return null;
        }
        const map = {
            "Instagram": ['instagram_comment_count', 'instagram_like_count'],
            "Instastory": ['instastory_engagement_count'],
            "Twitter": ['twitter_tweet_count', 'twitter_retweet_count', 'twitter_favorite_count'],

            "Facebook": ['facebook_reaction_count', 'facebook_share_count', 'facebook_comment_count', 'facebook_comment_plugin_count'],
            "FB Page": ['facebook_page_reaction_count', 'facebook_page_share_count', 'facebook_page_comment_count'],
            "Pinterest": ['pinterest_comment_count', 'pinterest_save_count'],
            "Blog": ['blog_pageviews_count', 'other_engagements'],
            "YouTube": ['youtube_like_count', 'youtube_dislike_count', 'youtube_comment_count', 'youtube_view_count'],
            "TikTok": ['tiktok_like_count', 'tiktok_share_count', 'tiktok_comment_count']
        };

        const data = { };
        for (let key in map) {
            const fields = map[key];
            let sum = 0;
            for (let field of fields) {
                const val = parseInt(stat[field] || 0, 10);
                sum += val;
            }
            data[key] = sum;
        }

        let chartData = [];

        for (let entry in data) {
            chartData.push({
                name: [entry],
                value: data[entry]
            })
        }

        const chart = <RechartsDonutChart
            title="By Network"
            data={chartData}
            labelFormatter={(value) => formatNumberK(value)}
            labelPercent={false}
        />;

        return (

            <ChartWrapper
                title="Engagements by Network"
                icon="pie-chart"
                chart={chart || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
            />
        );
    }


    renderEngagementsByTierChart() {
        const data = (this.props.report.by_tier || {}).total_engagements || {};
        let chartData = [];

        for (let entry in data) {
            chartData.push({
                name: entry,
                value: data[entry]
            })
        }

        const chart = <RechartsBarChart data={chartData} seriesName="Total Engagements by Tier" />;

        return (
            <ChartWrapper
                title="Engagements by Tier"
                icon="line-chart"
                chart={chart}
            />
        )
    }

    renderAvgEngagementsByTierChart() {
        const data = (this.props.report.by_tier || {}).avg_engagements || {};
        let chartData = [];

        for (let entry in data) {
            chartData.push({
                name: entry,
                value: data[entry]
            })
        }

        const chart = <RechartsBarChart data={chartData} seriesName="Average Engagements by Tier" />;

        return (
            <ChartWrapper
                title="Avg Engagements by Tier"
                icon="line-chart"
                chart={chart}
            />
        )
    }

    renderEngagementPctByTierChart() {
        const data = (this.props.report.by_tier || {}).avg_engagement_pct || {};
        let chartData = [];

        for (let entry in data) {
            chartData.push({
                name: entry,
                value: data[entry]
            })
        }

        const chart = <RechartsBarChart
            data={chartData}
            seriesName="Engagement Percent by Tier"
            tickFormatter={(decimal, fixed = 2) => `${(decimal * 100).toFixed(fixed)}%`}
            valueFormatter={(value) => `${formatNumber((value*100), 2)}%`}
        />;

        return (
            <ChartWrapper
                title="Engagement % by Tier"
                icon="line-chart"
                chart={chart}
            />
        )
    }


    renderEngagementsSubView() {
        const {campaign} = this.props;
        const campaignId = (campaign && campaign.id) ? campaign.id : null;
        const latest = this.getStat() || {};
        const hasTierData = !!this.props.report.by_tier;

        return (
            <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignPostTypesDonutChart stats={this.getTimeseries()}/>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignContentOverTimeChart stats={this.getTimeseries()}/>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <ChartWrapper
                        icon={'comments'}
                        title={'Sentiment Analysis'}
                        chart={<RechartsBarChart
                            seriesName={'Sentiment Analysis'}
                            data={[
                                {name: 'Positive', value: latest.sentiment_positive || 0},
                                {name: 'Negative', value: latest.sentiment_negative || 0},
                                {name: 'Mixed', value: latest.sentiment_mixed || 0},
                                {name: 'Neutral', value: latest.sentiment_neutral || 0},
                            ]}
                            tickFormatter={(d) => formatNumber(100*d, 2) + '%'}
                            valueFormatter={(d) => formatNumber(100*d, 2) + '%'}
                        />}

                    />
                </div>
                <div className="col-xs-12 col-sm-8">
                    <CampaignEngagementsOverTimeChart stats={this.getTimeseries()}/>
                </div>
                <div className="col-xs-12 col-sm-4">
                    {this.renderEngagementsByNetworkChart()}
                </div>


                {hasTierData && (
                    <>
                        <div className="col-xs-12 col-sm-4">
                            {this.renderEngagementsByTierChart()}
                        </div>

                        <div className="col-xs-12 col-sm-4">
                            {this.renderAvgEngagementsByTierChart()}
                        </div>

                        <div className="col-xs-12 col-sm-4">
                            {this.renderEngagementPctByTierChart()}
                        </div>
                    </>
                )}

                <div className="col-xs-12 col-sm-8 col-md-8">
                    <CampaignReachOverTimeChart stats={this.getTimeseries()}/>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignPostsPerActivationDonutChart
                        activations={this.getValidActivations().filter(a => a.post_count > 0)}/>
                </div>

                {this.renderAffiliateLinkClicksChart()}

                {
                    (this.state.print !== true && this.props.showTopPosts) &&
                    <div className="col-xs-12" style={{padding: 20, background: '#EEE'}}>
                        <h2><i className="fa fa-file"/> Most Engaging Posts</h2>
                    </div>
                }

                {
                    (this.state.print !== true && this.props.showTopPosts) &&
                    <div className="col-xs-12" style={{background: '#EEE'}}>
                        {
                            campaignId &&
                            <ConnectedPosts
                                filters={{campaign: this.props.campaign.id}}
                                sort={{by: "engagements", dir: "desc"}}
                                limit={9}
                                queryId={`campaign-report-engaging-posts-${campaignId}`}
                                showHeader={false}
                                showPagination={false}
                            />
                        }
                    </div>
                }
            </div>

        )
    }

    renderAffiliateLinkClicksChart() {

        const stats = this.getTimeseries();
        if (!stats || stats.length === 0) {
            return null;
        }

        const lastStat = stats[stats.length - 1];
        if (!lastStat || !lastStat.affiliate_link_clicks) {
            return null;
        }

        return (
            <div className="col-xs-12 col-sm-4 col-md-4">
            <ChartWrapper
                title={'Affiliate Link Clicks'}
                icon="dollar-sign"
                chart={
                    <RechartsTimeseriesAreaChart
                        data={stats}
                        stacked={true}
                        seriesSettings={[
                            {
                                chartType: 'area',
                                type: 'linear',
                                dataKey: 'affiliate_link_clicks',
                                name: `Affiliate Clicks (total ${formatNumber(lastStat.affiliate_link_clicks)})`,
                                stroke: '#206601',
                                fill: '#206601',
                                fillOpacity: 0.8,
                                stackId: 1
                            },
                        ]}
                        axisSettings={[
                            {
                                axisType: 'x',
                                dataKey: 'created_at',
                                stroke: '#888',
                                interval: 0
                            },
                            {
                                axisType: 'y',
                                yAxisId: 'left',
                                stroke: '#888',
                                tickFormatter: (value) => formatNumber(value)
                            }
                        ]}
                    />
                }
            />
            </div>
        );
    }

    renderTotalPayoutsByTierChart() {
        const data = (this.props.report.by_tier || {}).total_payment || {};
        let chartData = [];

        for (let entry in data) {
            chartData.push({
                name: entry,
                value: data[entry],
            })
        }

        const chart = <RechartsBarChart
            data={chartData}
            seriesName="Total Payouts by Tier"
            tickFormatter={(decimal) => `$${formatNumber(decimal)}`}
            valueFormatter={(value) => `$${formatNumber(value)}`}
        />;

        return (
            <ChartWrapper
                title="Total Payouts by Tier"
                icon="dollar-sign"
                chart={chart}
            />
        )
    };

    renderAvgPayoutPerTierChart() {
        const data = (this.props.report.by_tier || {}).avg_payment || {};
        let chartData = [];

        for (let entry in data) {
            chartData.push({
                name: entry,
                value: data[entry],
            })
        }

        const chart = <RechartsBarChart
            data={chartData}
            seriesName="Avg Payout per Tier"
            tickFormatter={(decimal) => `$${formatNumber(decimal)}`}
            valueFormatter={(value) => `$${formatNumber(value)}`}
        />;

        return (
            <ChartWrapper
                title="Avg Payout per Tier"
                icon="dollar-sign"
                chart={chart}
            />
        )
    }

    renderRoiSubView() {

        const stats = this.getStat();
        const roi = stats ? calculateRoi(stats, this.props.campaign.emv_params) : null;
        // const hasTierData = !!this.props.report.by_tier;
        // Set hasTierData to false to hide the tier charts by matt's request
        const hasTierData = false;

        return (

            <div className="row">
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignMediaValueDonutChart stats={this.getTimeseries()}
                                                  emvParams={this.props.campaign.emv_params}/>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignMediaValueByNetworkDonutChart stats={this.getTimeseries()} emvParams={this.props.campaign.emv_params} />
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4">
                    <CampaignROIOverTimeChart stats={this.getTimeseries()}
                                              emvParams={this.props.campaign.emv_params}/>
                </div>

                {hasTierData && (
                    <>
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            {this.renderTotalPayoutsByTierChart()}
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-6">
                            {this.renderAvgPayoutPerTierChart()}
                        </div>
                    </>
                )}

                {
                    this.props.showROITable
                    &&
                    <div className="col-xs-12 col-sm-12">
                        <CampaignActivationROITableWrapper
                            roi={roi}
                            activations={this.getValidActivations()}
                            emvParams={this.props.campaign.emv_params}
                        />
                    </div>
                }
            </div>

        )
    }

    renderBody() {

        return (

            <div className="campaign-report-body">
                {this.renderSubView()}
            </div>
        )
    }

    renderSettingsToolbox() {
        return (
            <div className={'toolbox campaign-settings-menu'} style={{right: 16}}>
                <h2 className={'title'}>Report Settings</h2>
                <a className={'close absolute'} onClick={this.handleToggleSettingsToolbox}><i
                    className={'fa fa-times'}></i></a>
                <div className={'fake-li'}>
                    <a href="#" onClick={this.handleToggleEMVSettingsToolbox}>Customize EMV Parameters</a>
                </div>
            </div>
        );
    }

    render() {

        const containerClass =
            'campaign-report-container '
            + (this.state.print ? 'container container-print' : 'container-fluid');

        return (
            <div className={containerClass} id="react-campaign-report" style={this.props.style}>

                {this.renderHeader()}
                { this.state.showDownloadToolbox && this.renderDownloadToolbox() }
                { this.state.showSettingsToolbox && this.renderSettingsToolbox() }
                { this.state.showDateFilterToolbox && this.renderDateFilterToolbox() }
                {
                    this.state.showEMVSettingsToolbox &&
                    <CampaignReportEMVSettings
                        emvParams={this.props.campaign.emv_params}
                        onClose={this.handleToggleEMVSettingsToolbox}
                        onChange={this.handleEMVChange}
                    />
                }

                {this.renderTabs()}
                {this.renderBody()}

            </div>
        )
    }

    handleToggleDateFilterToolbox() {
        this.setState({
            showDateFilterToolbox: !this.state.showDateFilterToolbox
        });
    }

    renderDateFilterToolbox() {
        return (
            <Toolbox
                onClose={this.handleToggleDateFilterToolbox}
                title="Date Filter"
                addlClasses={"toolbox-fixed toolbox-sm"}
                content={
                <div style={{}}>
                    <DateRange
                        startOpen={true}
                        inline={true}
                        value={this.state.dateRange}
                        onChange={(dateRange) => this.setState({dateRange})}
                    />
                </div>
                }
            />
        )
    }

    renderDateFilterInfoRow() {

        if (!this.state.dateRange) {
            return null;
        }
        const {min, max} = this.state.dateRange;
        const minMoment = min ? makeMomentFromDate(min) : null;
        const maxMoment = max ? makeMomentFromDate(max) : null;

        // If we have both min and max, show the date range
        // If we only have min, show "Since {min}"
        // If we only have max, show "Until {max}"
        // If we have neither, show nothing
        const resetLink = <a className="bolder" role="button" onClick={() => this.setState({dateRange: null})}>Reset</a>;

        if (minMoment && maxMoment) {
            return (
                <div className="col-xs-12">
                    <Alert classes={['info']} content={<span>Showing data from {minMoment.format('MMMM Do, YYYY')} to {maxMoment.format('MMMM Do, YYYY')}. {resetLink}</span>} />
                </div>
            )
        }
        if (minMoment) {
            return (
                <div className="col-xs-12">
                    <Alert classes={['info']} content={<span>Showing data since {minMoment.format('MMMM Do, YYYY')}. {resetLink}</span>} />
                </div>
            )
        }
        if (maxMoment) {
            return (
                <div className="col-xs-12">
                    <Alert classes={['info']} content={<span>Showing data until {maxMoment.format('MMMM Do, YYYY')}. {resetLink}</span>} />
                </div>
            )
        }

    }
}
