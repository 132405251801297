import React, {useEffect, useMemo, useState} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../../actions/auth";
import Form from "../../Common/Form/Form";
import {createChannelInteraction} from "../../../actions/channel";
import DOMPurify from "dompurify";
import {makeMomentFromDate} from "../../../utilities";
import MiniProfile from "../../Common/MiniProfile";

function SingleReply({post}) {
    const cleanContent = useMemo(function () {
        return {__html: DOMPurify.sanitize(post.content || '')};
    }, [post]);
    const postedAtMoment = makeMomentFromDate(post.created_at);
    return (
        <div className="reply">

            <div className="byline">
                <MiniProfile
                    primary={post.user.name}
                    secondary={postedAtMoment.fromNow()}
                    imageUrl={post.user.profile_picture_url}
                />
            </div>

            <div className="content" dangerouslySetInnerHTML={cleanContent} />
        </div>
    );
}
function NewReplyForm({createChannelInteraction, parentPost, onCreate}) {
    const [form, setForm] = useState({});
    const handleFieldChange = (k, v) => {
        setForm(prev => ({...prev, [k]: v}));
    };
    const handleSubmit = () => {
        createChannelInteraction('CreateInnerCirclePost', {
            in_reply_to_id: parentPost.id,
            topic_id: parentPost.topic_id,
            title: 'Re: ' + parentPost.title,
            content: form.content
        })
            .then(resp => {
                setForm({content: ''});
                if (onCreate) {
                    onCreate(resp);
                }
            })
    };
    return (
        <div className="reply-form">

            <Form
                fields={[
                    {
                        type: 'textarea',
                        name: 'content',
                        title: 'Reply',
                        placeholder: 'Type your reply here.'
                    },
                    {
                        type: 'button',
                        name: 'submit',
                        title: 'Post Reply',
                        options: {
                            hideTitle: true,
                            classes: ['v3 btn tidal-btn btn-secondary'],
                            onClick: handleSubmit
                        }
                    }
                ]}
                values={form}
                onFieldChange={handleFieldChange}
            />
        </div>
    );
}
function ThreadDiscussion({postId, post, fetchAuthenticated, createChannelInteraction}) {
    const [replies, setReplies] = useState([]);
    const repliesCount = replies.length;
    const fetchReplies = () => {
        return fetchAuthenticated(`/contribute/api/innercircle/_/reply/${postId}`)
            .then(resp => resp.json())
            .then(json => setReplies(json.data));
    };
    useEffect(function () {
        fetchReplies();
    }, [postId]);

    return (
        <div className="thread-discussion-wrapper">
            <h2 className="red-indent">Discussion</h2>

            <div className="thread-discussion">
                {repliesCount === 0 ? <p className="help-block" style={{marginLeft: 20}}>No replies yet. Start a discussion!</p> : ''}

                {repliesCount > 0 ? (
                    <div className="replies">
                        {replies.map(reply => <SingleReply key={reply.id} post={reply} />)}
                    </div>
                ) : null}

                <NewReplyForm
                    parentPost={post}
                    createChannelInteraction={createChannelInteraction}
                    onCreate={resp => {
                        fetchReplies();
                    }}
                />
            </div>
        </div>
    )
}

export default connect(
    function (state, props) {
        return { };
    },
    function (dispatch) {
        return bindActionCreators({
            fetchAuthenticated: fetchAuthenticated,
            createChannelInteraction: createChannelInteraction
        }, dispatch);
    }
)(ThreadDiscussion);