import React, {Component} from 'react';
import PropTypes from 'prop-types';
import EventDivider from '../../Common/TimelineView/EventDivider';
import MessageRow from '../../Common/TimelineView/MessageRow';
import {makeMomentFromDate} from "../../../utilities";
import CommentOnIssueForm from '../../Activation/CommentOnIssueForm';
import DOMPurify from 'dompurify';

export default class CommentedOnIssueInteractionMessage extends Component {

    static propTypes = {
        interaction: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        onCommentSaved: PropTypes.func,
    };

    state = {
        showCommentForm: false
    };

    toggleCommentForm() {
        this.setState({showCommentForm: !this.state.showCommentForm});
    }

    renderMessage() {
        const {interaction, activation} = this.props;

        return (
            <div className={'interaction-group'}>
                <h4 className="v3 light" style={{marginBottom: 20}}>{interaction.results.issue.title}</h4>
                <div className="v3 quote" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(interaction.context.comment)}} />
            </div>
        );

    }

    handleCommentSaved(payload) {
        if (this.props.onCommentSaved) {
            this.props.onCommentSaved(payload);
        }
    }

    renderLink() {

        const {showCommentForm} = this.state;
        const {activation, interaction} = this.props;

        return (
            <div>
                <a role="button" className="v3 light" onClick={this.toggleCommentForm.bind(this)}>Respond to Comment</a>

                {showCommentForm &&
                    <CommentOnIssueForm
                        activation={activation}
                        issueId={interaction.context.issue_id}
                        onSaved={this.handleCommentSaved.bind(this)}
                        style={{
                            float: 'left',
                            width: '100%',
                            marginTop: 20,
                            borderTop: '1px solid #EEE'
                        }}
                    />
                }
            </div>
        );


    }

    render() {

        const {interaction, activation} = this.props;
        const timestamp = makeMomentFromDate(interaction.created_at).format('MMM Do, YYYY');
        const layout = activation.user_id === interaction.actor.id ? 'right' : 'left';

        return (
            <div className={'timeline-group raised-issue'}>

                <EventDivider
                    message={`${interaction.actor.name} Commented on an Issue`}
                    timestamp={timestamp}
                />

                <MessageRow
                    message={this.renderMessage()}
                    image={interaction.actor.picture}
                    timestamp={timestamp}
                    layout={layout}
                    link={this.renderLink()}
                />
            </div>

        );

    }
}