import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {updateLanguage, translate} from "../../actions/i18n";
import Toolbox from "./Toolbox";
import Checkbox from "./Form/Checkbox";
import LANG_CODES from '../../utilities/languages';
import {getCurrentUser} from "../../selectors/presence";

function LangSwitcherNavButton({lang, languages, translate, updateLanguage, user}) {
    const _t = translate;
    const [showPopup, setShowPopup] = useState(false);

    if (languages.length <= 1) {
        return null;
    }

    const isLoggedIn = !!user && !!(user || {}).id;
    return (
        <>
            <a
                onClick={() => setShowPopup(!showPopup)}
                role="button"
                className="button"><i className="fas fa-globe" /> {lang} <i className="v3 icon angle-down" /> </a>
            {showPopup && (
                <Toolbox
                    addlClasses={"toolbox-fixed toolbox-sm"}
                    title={_t('language.title', "Select Language")}
                    onClose={() => setShowPopup(false)}
                    content={
                        <div className="">
                            {languages.map(code => {
                                const codeEntry = LANG_CODES[code];
                                let label = code;
                                if (typeof codeEntry !== 'undefined') {
                                    label = label + ' - ' + codeEntry[0] + ' - ' + codeEntry[1];
                                }
                                return (
                                    <div key={code} className="fake-li condensed padded">
                                        <Checkbox
                                            label={label}
                                            checked={lang === code}
                                            onClick={() => updateLanguage(code, true, !isLoggedIn)}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                    }
                />
            )}
        </>
    );
}

const mapStateToProps = (state, props) => ({
    ...state.i18n,
    user: getCurrentUser(state, props)
});

const mapDispatchToProps = dispatch => bindActionCreators({updateLanguage, translate}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LangSwitcherNavButton);