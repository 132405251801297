import React from 'react';
import {formatNumber} from '../utilities';
import _get from 'lodash/get';
import Tooltip from '../components/Common/Tooltip';

/**
 * @param tier
 * @returns {*}
 */
export const getFullTierName = tier => {
    const _t = parseInt(tier, 10);
    switch (_t) {
        case 0: return 'Up-and-Comer';
        case 1: return 'Prosumer 1';
        case 2: return 'Prosumer 2';
        case 3: return 'Prosumer 3';
        case 4: return 'Maven 1';
        case 5: return 'Maven 2';
        case 6: return 'Maven 3';
        case 7: return 'Celeb 1';
        case 8: return 'Celeb 2';
        case 9: return 'Celeb 3';
        case 10: return 'Celeb 4';
        case 11: return 'Celeb 5';
        case 12: return 'Celeb 6';

        default: return tier;
    }
}
/**
 * @param tier
 * @returns {*}
 */
export const getTierName = tier => {
    const _t = parseInt(tier, 10);
    switch (_t) {
        case 0: return 'Up-and-Comer';
        case 1:
        case 2:
        case 3: return 'Prosumer';
        case 4:
        case 5:
        case 6: return 'Maven';
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12: return 'Celeb';

        default: return tier;
    }
}

/**
 * @see https://stackoverflow.com/questions/46155/how-can-you-validate-an-email-address-in-javascript
 * @param email
 * @returns {boolean}
 */
export const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
};

export const responsivenessLabel = val => {
    if (val > 0.7) {
        return 'Very High';
    } else if (val > 0.5) {
        return 'High';
    } else if (val > 0.39) {
        return 'Good';
    } else if (val >= 0.1) {
        return 'Average';
    } else {
        return 'Low';
    }
};

export const frecencyLabel = val => {

    if (val > 7) {
        return 'Very High';
    } else if (val > 5) {
        return 'High';
    } else if (val > 2) {
        return 'Medium';
    } else if (val > 1) {
        return 'Low';
    } else {
        return 'Very Low';
    }

};

export const frecencyTooltip = val => {
    if (val > 7) {
        return "This creator is activated very frequently with lots of recent activity.";
    } else if (val > 5) {
        return "This creator is activated frequently and is recently active.";
    } else if (val > 2) {
        return "This creator is activated somewhat frequently with recent activity.";
    } else if (val > 1) {
        return "This creator has some recent activity.";
    } else {
        return "This creator has little recent activity.";
    }
}

export const PARENTHOOD_FACET_OPTIONS = [
    'No Children',
    'Has Children',
    'Pregnant',
    'Twins',
    'Triplets',
];

export const LANGUAGE_FACET_OPTIONS = [
    'English',
    'Spanish',
    'Portuguese',
    'French',
    'Russian',
    'German',
    'Polish',
    'Chinese',
    'Korean',
    'Japanese',
    'Hindi',
    'Arabic',
    'Turkish',
    'Italian',
    'Dutch',
    'Swedish',
    'Greek',
    'Hebrew',

    'Afrikaans',
    'Armenian',
    'Pushto',
    'Amharic',
    'Iloko',
    'Quechua',
    'Indonesian',
    'Romanian',
    'Assamese',
    'Icelandic',
    'Azerbaijani',
    'Sanskrit',
    'Bashkir',
    'Javanese',
    'Sinhala',
    'Belarusian',
    'Slovak',
    'Bengali',
    'Kannada',
    'Slovenian',
    'Bosnian',
    'Georgian',
    'Sindhi',
    'Bulgarian',
    'Kazakh',
    'Somali',
    'Catalan',
    'Central Khmer',
    'Cebuano',
    'Kirghiz',
    'Albanian',
    'Czech',
    'Serbian',
    'Chuvash',
    'Kurdish',
    'Sundanese',
    'Welsh',
    'Latin',
    'Swahili',
    'Danish',
    'Latvian',
    'Lithuanian',
    'Tamil',
    'Luxembourgish',
    'Tatar',
    'Malayalam',
    'Telugu',
    'Esperanto',
    'Marathi',
    'Tajik',
    'Estonian',
    'Macedonian',
    'Tagalog',
    'Basque',
    'Malagasy',
    'Thai',
    'Persian',
    'Mongolian',
    'Turkmen',
    'Finnish',
    'Malay',
    'Burmese',
    'Uighur',
    'Scottish Gaelic',
    'Nepali',
    'Ukrainian',
    'Irish',
    'Newari',
    'Urdu',
    'Galician',
    'Uzbek',
    'Gujarati',
    'Norwegian',
    'Vietnamese',
    'Haitian',
    'Oriya',
    'Yiddish',
    'Punjabi',
    'Yoruba',
    'Chinese (Simplified)',
    'Croatian',
    'Chinese (Traditional)',
    'Hungarian',

    'Other',
];

export const RACE_FACET_OPTIONS = [
    'Asian',
    {'text': 'Black or African American', 'value': 'Black'},
    'Indian',
    {'text': 'Latino or Hispanic', 'value': 'Hispanic'},
    {'text': 'Two or More Races/Ethnicities', 'value': 'Mixed'},
    'White',
    {'text': 'Other', 'value': 'Other'},
];

export const OCCUPATION_FACET_OPTIONS = [
    'Actor',
    'Administration',
    'Advertising',
    'Aerospace',
    'Agriculture',
    'Architect',
    'Armed Services',
    'Automotive',
    'Bookkeeping and Accounting',
    'Construction',
    'Cosmetician',
    'Custodian',
    'Customer Service Representative',
    'Data Entry',
    'Engineering',
    'Finance',
    'First Responder',
    'Food Prep',
    'Government Employee',
    'Human Resources',
    'IT Services',
    'Insurance',
    'Journalist',
    'Landscaping',
    'Law Enforcement',
    'Law',
    'Maintenance Technician',
    'Management',
    'Manufacturing',
    'Marketing',
    'Modelling',
    'Musician',
    'Nursing',
    'Office Clerk',
    'Personal Trainer',
    'Pharmacist',
    'Photographer',
    'Physician',
    'Postal Service',
    'Public Relations',
    'Public Services',
    'Publishing',
    'Real Estate',
    'Retail Sales',
    'Sales',
    'Scientist',
    'Security',
    'Self-Employed',
    'Shipping and Logistics',
    'Software Engineering',
    'Student',
    'Talent Management',
    'Teacher',
    'Technician',
    'Trade Skills',
    'Transportation',
    'Travel and Hospitality',
    'Unemployed',
    'Veterinarian',
    'Videographer',
    'Waiter or Waitress',
    'Warehouse',
    'Writer',
    'Other',

];

export const CATEGORY_FACET_OPTIONS = [
    'Arts',
    'Automotive',
    'Beauty',
    'Business',
    'Corporate/Brand',
    'DIY',
    'Entertainment',
    'Fashion',
    'Finance',
    'Food',
    'Gadgets',
    'Health and Fitness',
    'House and Home',
    'Journalism',
    'Literature',
    'Lifestyle',
    'Liquor',
    'News',
    'Parenting',
    'Sports',
    'Shopping',
    'Tabloid',
    'Technology',
    'Travel',
    'Other',

];

export const RELATIONSHIP_FACET_OPTIONS = [
    'Single',
    'Married',
    'Engaged',
    'Long-Term',
    'Dating'
];

export const AGE_FACET_OPTIONS = [
    '18-21',
    '21-30',
    '30-40',
    '40-60',
    '60+'
];

/**
 * @param user
 * @param isFieldVisible
 * @return {Number} As a percentage (ie, points/max * 100)
 */
export const calcProfileCompletion = (user, isFieldVisible) => {

    let _missing = [];
    let _found = [];

    let points = 0;

    /**
     * Fields that should exist.
     * @type {string[]}
     */
    const exists = ['name', 'gender', 'birthday_year', 'birthday_month', 'birthday_day', 'country_code', 'state_name', 'city_name', 'zipcode', 'profile_bio', 'profile_picture', 'blogs', 'socials', 'phone', 'facets.Category', 'facets.Language', 'facets.Race', 'facets.Parenthood', 'facets.Relationship'];

    /**
     * Social types to count
     * @type {string[]}
     */
    const socialTypes = ['Twitter', 'Instagram', 'Facebook'];

    /**
     * Custom rule: blog verified
     */
    const hasVerifiedBlog  = (user.blogs || []).filter(b => b.is_verified).length > 0;

    /**
     * Total points is # of exists + # of social types + # of custom rules (1)
     */
    const max = exists.length + socialTypes.length + 1;

    /**
     * Add up points for existing fields
     */
    exists.forEach(key => {

        // If the field isn't 'visible', count it positively
        if (!isFieldVisible(key)) {
            points++;
            _found.push(key);
            return;
        }

        const val = _get(user, key, null);

        if (val !== null && val !== '') {
            points++;
            _found.push(key);
        } else {
            _missing.push(key);
        }
    });

    /**
     * Add up points for socials
     */
    socialTypes.forEach(key => {

        if (!isFieldVisible(key)) {
            points++;
            _found.push(key);
            return;
        }

        let type = 'Tidal\\Social\\' + key;
        const hasSocial = (user.socials || []).filter(s => s.type === type);
        if (hasSocial.length > 0) {
            points++;
            _found.push(key);
        } else {
            _missing.push(key);
        }
    });

    /**
     * Points for custom rules
     */
    if (hasVerifiedBlog || !isFieldVisible('verified_blog')) {
        points++;
    } else {
        _missing.push('verified_blog');
    }

    console.log({_found});
    const pct = formatNumber(100 * points / max);
    return {
        points,
        max,
        pct,
        found: _found,
        missing: _missing
    };

};

/**
 * @deprecated
 * @param entry
 * @returns {*}
 */
export const getUserStatusIcon = (entry) => {
    let icon = '';
    let tooltip = '';

    const type = entry.collectable_type;
    const status = entry.user_status || {};

    if (status.is_unsubscribed) {
        icon = 'v3 signal danger large';
        tooltip = 'User has unsubscribed from invitations.';
    } else if (status.is_admin) {
        icon = 'v3 icon cog';
        tooltip = 'Administrative user';
    } else if (status.is_active) {
        icon = 'v3 icon verified';
        tooltip = 'Active user';
    } else if (status.is_authenticated) {
        icon = 'v3 icon success';
        tooltip = 'Authenticated user';
    } else if (type === 'Tidal\\User') {
        icon = 'v3 icon user';
        tooltip = 'User';
    }

    return <Tooltip title={tooltip}>
        <i className={icon}/>
    </Tooltip>;
};

export const getHumanChannelStatus = (code = 0) => {

    switch (code) {
        case 0: return 'Joined';
        case 1: return 'Invited';
        case 2: return 'Blocked';
        case 4: return 'Imported';
        case 8: return 'Opted Out';
        case 16: return 'Spam';
        case 32: return 'Active';
        case 64: return 'Rejected';
        case 128: return 'Unresponsive';
        case 256: return 'Suspended';
        case 512: return 'Preferred';
        default: return 'Unknown';
    }

};
