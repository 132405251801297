/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import {formatNumber} from '../../../utilities';
import GenericTimeseriesAreaChart from '../../Chart/GenericTimeseriesAreaChart';
import RechartsTimeseriesAreaChart from "../../Chart/RechartsTimeseriesAreaChart";
import ChartWrapper from "../../Chart/ChartWrapper";

const REACH_FIELDS = {
    'blog_pageviews_count': 'Blog Pageviews',
    'blog_reach': 'Blog Uniques',
    'facebook_reach': 'Facebook Reach',
    'facebook_page_reach': 'FB Page Reach',
    'instagram_reach': 'Instagram Reach',
    'instastory_reach': 'Instastory Reach',
    'other_impressions': 'Other Impressions',
    'pinterest_reach': 'Pinterest Reach',
    'tidal_hits_count': 'CMS Impressions',
    'twitter_reach': 'Twitter Reach',
    'youtube_view_count': 'YouTube Views',
    'youtube_reach': 'YouTube Subscribers',
    'tiktok_reach': 'TikTok Reach',
};

export default class CampaignReachOverTimeChart extends Component {


    /**
     * Need to add up total reach and total impressions
     */
    getStats() {
        const stats = this.props.stats || [];
        return stats.map(stat => {
            stat.total_reach_impressions = (stat.total_reach || 0);
            return stat;
        });
    }

    getTotalImpressions() {
        const stats = this.getStats();
        if (!stats || stats.length === 0) return null;
        return stats[stats.length - 1].total_reach_impressions;
    }

    renderChart() {
        const stats = this.getStats();
        if (!stats || stats.length === 0) return null;
        const lastStat = stats[stats.length - 1];

        return (
            <RechartsTimeseriesAreaChart
                data={stats}
                stacked={true}
                seriesSettings={[
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'blog_pageviews_count',
                        name: `Blog Pageviews (total ${formatNumber(lastStat.blog_pageviews_count)})`,
                        stroke: '#206601',
                        fill: '#206601',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'blog_reach',
                        name: `Blog Uniques (total ${formatNumber(lastStat.blog_reach)})`,
                        stroke: '#257900',
                        fill: '#257900',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'facebook_reach',
                        name: `Facebook Reach (total ${formatNumber(lastStat.facebook_reach)})`,
                        stroke: '#469A01',
                        fill: '#469A01',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'instagram_reach',
                        name: `Instagram Reach (total ${formatNumber(lastStat.instagram_reach)})`,
                        stroke: '#58B305',
                        fill: '#58B305',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'other_impressions',
                        name: `Other Impressions (total ${formatNumber(lastStat.other_impressions)})`,
                        stroke: '#66CC00',
                        fill: '#66CC00',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'pinterest_reach',
                        name: `Pinterest Reach (total ${formatNumber(lastStat.pinterest_reach)})`,
                        stroke: '#7ED82F',
                        fill: '#7ED82F',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'tidal_hits_count',
                        name: `CMS Impressions (total ${formatNumber(lastStat.tidal_hits_count)})`,
                        stroke: '#82DB3D',
                        fill: '#82DB3D',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'twitter_reach',
                        name: `Twitter Reach (total ${formatNumber(lastStat.twitter_reach)})`,
                        stroke: '#95E15B',
                        fill: '#95E15B',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'youtube_view_count',
                        name: `Youtube Views (total ${formatNumber(lastStat.youtube_view_count)})`,
                        stroke: '#A6E874',
                        fill: '#A6E874',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'youtube_reach',
                        name: `Youtube Subscribers (total ${formatNumber(lastStat.youtube_reach)})`,
                        stroke: '#B9ED91',
                        fill: '#B9ED91',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'tiktok_reach',
                        name: `TikTok Reach (total ${formatNumber(lastStat.tiktok_reach)})`,
                        stroke: '#cdefb0',
                        fill: '#cdefb0',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: 'tiktok_view_count',
                        name: `TikTok Video Views (total ${formatNumber(lastStat.tiktok_view_count)})`,
                        stroke: '#d1eeb8',
                        fill: '#d1eeb8',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                ]}
                axisSettings={[
                    {
                        axisType: 'x',
                        dataKey: 'created_at',
                        stroke: '#888',
                        interval: 0
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'left',
                        stroke: '#888',
                        tickFormatter: (value) => formatNumber(value)
                    }
                ]}
            />
        )
    }

    render() {
        const total = this.getTotalImpressions();
        const titleBadge = total !== null ? `${formatNumber(total)} total reach + impressions` : null;

        return (
            <ChartWrapper
                title="Reach and Impressions Over Time"
                titleBadge={titleBadge}
                icon="line-chart"
                chart={this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
            />
        )
    }

}
