import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../Common/Alert';

import {connect} from 'react-redux';
import {fetchAuthenticated, xhrAuthenticated} from "../../../../actions/auth";
import VideoTile from '../Pieces/VideoTile';
import {formatNumber, makeFormData} from "../../../../utilities";
import ReactS3Uploader from 'react-s3-uploader';
import _get from "lodash/get";

class UploadVideos extends Component {

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        refreshActivation: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        fetchAuthenticated: PropTypes.func.isRequired,
        xhrAuthenticated: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        onComplete: PropTypes.func,
    };

    static defaultProps = {
        disabled: false,
    };

    state = {
        isUploading: false,
        isRemoving: false,
        uploadProgress: null,
        error: null,
    };

    componentDidMount() {
        this.inputRef = null;
    }

    renderAlert() {

        if (!this.isAllowedToUpload()) {
            let msg = 'You must accept the brief invitation and terms before you can upload videos.';
            if (_get(this.props, 'activation.status.is_waitlisted')) {
                msg = 'You are currently waitlisted. Please wait until you are approved before uploading videos.';
            }

            return <Alert
                classes={['info']}
                content={msg}
                style={{marginBottom: 20}}
            />
        }

        const requiredCount = (this.props.activation.settings || {}).num_videos;
        const uploadedCount = (this.props.activation.video_uploads || []).length;
        const {isUploading, isRemoving} = this.state;
        const isFulfilled = uploadedCount >= requiredCount;

        let content = `You are required to upload ${requiredCount} high resolution videos.`;

        if (isUploading) {
            content = <span>Uploading your video. Please wait as this may take a long time...</span>;
        }

        if (isRemoving) {
            content = 'Removing video...';
        }

        if (!requiredCount) {
             return null;
        }

        return <Alert
            classes={[isFulfilled ? 'success' : 'info']}
            content={content}
            style={{marginBottom: 20}}
        />;



    }

    clearInput() {
        this.inputRef.value = '';
    }

    renderBody() {

        return (
            <div>
                {this.renderUploader()}
                {this.renderVideos()}
            </div>
        );

    }

    renderVideos() {

        const videos = this.props.activation.video_uploads || [];
        return <div className="clearfix videos-container">{videos.map(this.renderVideo.bind(this))}</div>
    }

    renderVideo(video) {
        return (
            <VideoTile
                video={video}
                onRemoveVideo={this.handleRemoveVideo.bind(this, video)}
            />
        );
    }

    handleRemoveVideo(video) {
        if (this.state.isRemoving) {
            return null;
        }

        this.setState({isRemoving: true});
        const {activation} = this.props;
        const payload = {video_id: video.id, action: 'remove'};
        return this.props.createInteraction(activation.id, 'UploadVideo', payload)
            .then(() => this.props.refreshActivation(activation.id))
            .then(() => this.setState({isRemoving: false}));

    }

    handleSubmit(json) {
        const {activation} = this.props;
        const payload = {video_id: json.data.id, video: json.data, action: 'add'};
        return this.props.createInteraction(activation.id, 'UploadVideo', payload)
            .then(() => this.props.refreshActivation(activation.id))
            .then(() => this.setState({isUploading: false}))
            .then(() => {
                if (this.props.onComplete) {
                    this.props.onComplete();
                }
                this.clearInput();
            });
    }

    handleGetSignedUrl(file, callback) {
        const params = {
            objectName: file.name,
            contentType: file.type,
        };
        const slot = window.Gator.getDashboardSlot();
        this.props.fetchAuthenticated(`/${slot}/api/video/_/signature`, {
            method: 'post',
            body: makeFormData(params)
        })
            .then(resp => resp.json())
            .then(json => {
                if (json.meta && json.meta.error) {
                    this.setState({error: json.meta.error});
                    this.clearInput();
                } else {
                    callback(json.data);
                }
            });
    }

    handleFinalizeUpload(signResult) {
        const slot = window.Gator.getDashboardSlot();
        this.props.fetchAuthenticated(`/${slot}/api/video/_/object`, {
            method: 'post',
            body: makeFormData({
                ...signResult,
                user_id: this.props.activation.user_id
            })
        })
            .then(resp => resp.json())
            .then(json => {

                if (json.meta && json.meta.error) {
                    this.setState({error: json.meta.error, isUploading: false, uploadProgress: null});
                } else {
                    this.handleSubmit(json);
                }
            });

    }

    isAllowedToUpload() {
        const {activation} = this.props;
        const {status} = activation;
        return status && status.did_user_accept_invitation && !status.is_waitlisted;
    }

    renderUploader() {

        if (!this.isAllowedToUpload()) {
            return null;
        }

        return (

            <div className="form-group">
                <label><strong>Choose a Video to Upload</strong></label>
                <ReactS3Uploader
                    getSignedUrl={this.handleGetSignedUrl.bind(this)}
                    className="form-control"
                    accept="video/mp4,video/x-m4v,.mp4,video/*"
                    uploadRequestHeaders={{}}
                    onProgress={pct => {
                        this.setState({uploadProgress: pct})
                    }}
                    onError={(msg) => {
                        this.setState({error: msg});
                    }}
                    onFinish={this.handleFinalizeUpload.bind(this)}
                    preprocess={(file, next) => {
                        this.setState({isUploading: true, error: null, uploadProgress: null});
                        next(file);
                    }}
                    inputRef={ref => this.inputRef = ref}
                    disabled={this.props.disabled}
                />
                <p className="help-block">Upload an MP4 video.</p>
            </div>

        );
    }

    renderUploadProgress() {
        const {isUploading, uploadProgress} = this.state;

        if (!isUploading || !uploadProgress) {
            return null;
        }

        let alertText = <span>Uploading video ({formatNumber(uploadProgress)}%)...</span>;

        if (uploadProgress && uploadProgress > 99) {
            alertText = <span>Finishing upload, please wait... <i className="fa fa-spinner fa-spin" /> </span>;
        }


        return (
            <div
                className="tidal-progress"
                style={{
                    position: 'relative',
                    height: 50,
                    background: '#eee',
                    marginBottom: 20,
                }}
            >

                <div className="tidal-progress-inner"
                     style={{
                         width: uploadProgress + '%',
                         background: '#6C0',
                         position: 'absolute',
                         top: 0,
                         left: 0,
                         height: 50,
                     }}
                />

                <div className="tidal-progress-label"
                     style={{
                         lineHeight: '50px',
                         height: 50,
                         position: 'absolute',
                         paddingLeft: 20,
                         color: '#666',
                     }}
                >{alertText}</div>

            </div>
        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return <Alert
            classes={['danger']}
            content={this.state.error}
            style={{marginTop: 15, marginBottom: 15}}
        />;
    }

    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }

    render() {

        if (this.isBlockedByNDA()) {
            return this.renderNDAAlert();
        }

        return (
            <div className="influencer-page-template submit-videos">
                <div className="widget">
                    <div className="title">
                        <h2>Upload Videos</h2>
                    </div>
                    <div className="content padded">
                        <h4 className="v3 bold action-pane-title">Upload Videos</h4>
                        {this.renderAlert()}
                        {this.renderError()}
                        {this.renderUploadProgress()}
                        {this.renderBody()}
                    </div>
                </div>
            </div>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
        xhrAuthenticated: (method, url, formData, onLoad, onProgress) => dispatch(xhrAuthenticated(method, url, formData, onLoad, onProgress))
    };
};

export default connect(null, mapDispatchToProps)(UploadVideos);