import {
    REQUEST_TEAMS, RECEIVE_TEAMS,
    REQUEST_TEAM, RECEIVE_TEAM,
    REQUEST_TEAM_USERS, RECEIVE_TEAM_USERS,
    ADD_TEAM_USER, ADDED_TEAM_USER,
    REMOVE_TEAM_USER, REMOVED_TEAM_USER,
    CREATE_TEAM, CREATED_TEAM,
    UPDATE_TEAM_SETTINGS, UPDATED_TEAM_SETTINGS
} from "../actions/teams";

export function team(state = {
    isFetching: false,
    isFetchingUsers: false,
    isUpdating: false,
    didInvalidate: false,
    lastUpdated: null,
    lastUpdatedUsers: null,
    userIds: []
}, action) {

    switch (action.type) {

        case UPDATED_TEAM_SETTINGS:
            return {
                ...state,
                ...action.team,
                isUpdating: false,
                lastUpdated: action.receivedAt,
                didInvalidate: false
            };

        case REQUEST_TEAM_USERS:
            return {
                ...state,
                isFetchingUsers: true
            };

        case RECEIVE_TEAM_USERS:
            return {
                ...state,
                userIds: action.users.map(u => u.id),
                isFetchingUsers: false,
                lastUpdatedUsers: action.receivedAt
            };

        case REQUEST_TEAM:
            return {...state, isFetching: true, didInvalidate: false};

        case RECEIVE_TEAM:
            return {
                ...state,
                ...action.team,
                isFetching: false,
                lastUpdated: action.receivedAt,
                didInvalidate: false
            };

        case ADD_TEAM_USER:
        case REMOVE_TEAM_USER:
        case UPDATE_TEAM_SETTINGS:
            return {
                ...state,
                isUpdating: true
            };

        case ADDED_TEAM_USER:
        case REMOVED_TEAM_USER:
            return {
                ...state,
                isUpdating: false,
                didInvalidate: true
            };

        default:
            return state;
    }

}

export function teamsById(state = {
    isFetching: false,
    lastUpdated: null,
    didInvalidate: true,
    items: {}
}, action) {
    switch (action.type) {

        case CREATED_TEAM:
            return {...state, didInvalidate: true};

        case REQUEST_TEAMS:
            return {...state, isFetching: true, didInvalidate: false};

        case RECEIVE_TEAMS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.receivedAt,
                didInvalidate: false
            };

        case REQUEST_TEAM:
        case RECEIVE_TEAM:
        case REQUEST_TEAM_USERS:
        case RECEIVE_TEAM_USERS:
        case ADD_TEAM_USER:
        case ADDED_TEAM_USER:
        case REMOVE_TEAM_USER:
        case REMOVED_TEAM_USER:
        case UPDATED_TEAM_SETTINGS:
        case UPDATE_TEAM_SETTINGS:

            return {
                ...state,
                items: {
                    ...state.items,
                    [action.teamId]: team(state.items[action.teamId], action)
                }
            };

        default:
            return state;
    }
}
