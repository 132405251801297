/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import {formatNumber} from '../../../utilities';
import ChartWrapper from "../../Chart/ChartWrapper";
import RechartsDonutChart from "../../Chart/RechartsDonutChart";

export default class CampaignPostsPerActivationDonutChart extends Component {

    getTotal() {
        if (!this.props.activations.length) return null;
        let total = 0;
        for (let activation of this.props.activations) {
            total += activation.post_count;
        }
        return total;
    }

    getData() {
        if (!this.props.activations || this.props.activations.length === 0) return null;

        let data = { };

        for (let activation of this.props.activations) {

            let posts = activation.post_count + ' Posts';

            if (typeof data[posts] === 'undefined') {
                data[posts] = 0;
            }

            data[posts]++;
        }

        let chartData = [];
        for (let entry in data) {
            chartData.push({
                name: entry,
                value: data[entry]
            })
        }

        return chartData;

    }

    renderChart() {
        const data = this.getData();
        if (!data) return null;

        return <RechartsDonutChart
            data={data}
            title="Posts per Creator"
        />


    }

    render() {
        const total = this.getTotal();
        const titleBadge = total !== null ? `${formatNumber(total)} posts` : null;

        return (

            <ChartWrapper
                title="Posts per Creator"
                titleBadge={titleBadge}
                icon="pie-chart"
                chart={this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
            />

        );
    }
}
