/**
 * Created by bkanber on 8/1/17.
 */

import React, {Component} from 'react';
import CampaignActivationStatsTableRowSocialCell from './TableRowSocialCell';

export default class CampaignActivationStatsTableRow extends Component {

    getStat() {
        const stats = this.props.activation.stats;
        if (!stats || stats.length === 0) return null;
        return stats[0];
    }

    getNumPosts(social) {
        const stat = this.getStat();
        if (!stat) return null;

        switch (social) {
            case 'total':
                return stat.total_posts || 0;
            default:
                return stat['num_' + social + '_posts'] || 0;
        }
    }

    getReach(social) {
        const stat = this.getStat();
        if (!stat) return null;

        return stat[social + '_reach'] || null;
    }

    getEngagements(social) {
        const stat = this.getStat();
        if (!stat) return null;
        const fields = this.props.getEngagementFields(social);

        let total = 0;

        for (const field of fields) {
            const val = parseInt(stat[field] || 0, 10);
            total+=val;
        }
        return total;
    }

    getEngagementPct(social) {
        const reach = this.getReach(social);
        const engagements = this.getEngagements(social);

        if (reach && engagements && reach > 0) {
            return 100 * engagements / reach;
        } else {
            return null;
        }
    }

    renderCreator() {
        const user = this.props.activation.user;

        return (
            <td style={{minWidth: '100px', paddingLeft: 20}}>

                {
                    (user.basics && user.basics.slug && user.basics.name) &&

                    <a target="_blank"
                       rel="noopener"
                       href={"/manage/do/page/users/detail?slug=" + user.basics.slug}>
                        {user.basics.name}
                    </a>
                }

            </td>
        );
    }

    renderSocialCell(social) {
        const posts = this.getNumPosts(social);
        const reach = this.getReach(social);
        const engagements = this.getEngagements(social);

        return (
            <CampaignActivationStatsTableRowSocialCell
                posts={posts}
                reach={reach}
                social={social}
                engagements={engagements}
            />
        )

    }

    render() {
        const {shouldRenderColumn} = this.props;
        return (
            <tr>
                {this.renderCreator()}
                {shouldRenderColumn('blog') && this.renderSocialCell('blog')}
                {shouldRenderColumn('twitter') && this.renderSocialCell('twitter')}
                {shouldRenderColumn('instagram') && this.renderSocialCell('instagram')}
                {shouldRenderColumn('instastory') && this.renderSocialCell('instastory')}
                {shouldRenderColumn('pinterest') && this.renderSocialCell('pinterest')}
                {shouldRenderColumn('facebook') && this.renderSocialCell('facebook')}
                {shouldRenderColumn('facebook_page') && this.renderSocialCell('facebook_page')}
                {shouldRenderColumn('youtube') && this.renderSocialCell('youtube')}
                {shouldRenderColumn('tiktok') && this.renderSocialCell('tiktok')}
                {this.renderSocialCell('total')}
            </tr>
        )
    }

}
