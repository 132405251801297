import _merge from 'lodash/merge';

/**
 * @param state
 * @param action
 * @param extras
 * @param defaultItemState
 * @returns {{}}
 */
export const modifyItem = (state, action, extras = {}, defaultItemState = {}) => {
    const objectId = getObjectIdFromAction(action);
    const mergedObject = _merge({}, defaultItemState, state[objectId], extras, {id: objectId});
    return {
        ...state,
        [objectId]: mergedObject
    };
};

export const getObjectIdFromAction = (action) => {
    if (action.id) return action.id;
    if (action.data && action.data.id) return action.data.id;
};

/**
 * @param state
 * @param action
 * @param extras
 * @param defaultItemState
 * @returns {{}}
 */
export const receiveItemData = (state, action, extras = {}, defaultItemState = {}) => {
    const objectId = getObjectIdFromAction(action);
    return modifyItem(state, action, {
        ...action.data,
        _meta: {...(state[objectId] || {})._meta, ...action.meta},
        ...extras,
    }, defaultItemState);
};

export const receiveItem = (state, action, extras = {}, defaultItemState = {}) => {
    return receiveItemData(state, action, {isFetching: false, receivedAt: new Date(), ...extras}, defaultItemState);
};

export const requestItem = (state, action, extras = {}, defaultItemState = {}) => {
    return modifyItem(state, action, {isFetching: true, ...extras}, defaultItemState);
};

/**
 * Receive items from action. Requires action to have a data key which is an array, and each item must have an id property
 * @param state
 * @param action
 * @param extras
 * @param defaultItemState
 * @returns {{}}
 */
export const receiveItems = (state, action, extras = {}, defaultItemState = {}) => {
    let thisstate = {...state};

    if (action.data) {
        for (let item of action.data) {
            let fakeAction = {
                id: item.id,
                data: item
            };
            thisstate = receiveItem(thisstate, fakeAction, {receivedAt: new Date(), ...extras}, defaultItemState);
        }
    }

    return thisstate;
};

/**
 *
 * @param state
 * @param key
 * @returns {{}}
 */
export const deleteKey = (state, key) => {
    let newState = {...state};
    delete newState[key];
    return newState;
};
