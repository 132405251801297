
import {receiveItems, modifyItem, receiveItem, requestItem} from "../utilities/reducer";
import {combineReducers} from 'redux';
import {makeQueriesReducerFor} from './queries';
import * as ActionTypes from '../actions/types';
import {RECEIVE_EMAIL} from "../actions";
import {REQUEST_EMAIL} from "../actions/emailById";

export const DEFAULT_EMAILS_QUERY = {
    id: "default",
    sort: {by: "created_at", dir: "desc"},
    limit: 20,
    page: 1,
    filters: {},
    context: {},
    pages: {}
};

const queries = makeQueriesReducerFor('EMAILS_');

const emailsById = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.EMAILS_RECEIVE_QUERY:
            return receiveItems(state, action);

        case ActionTypes.EMAIL_RECEIVE:
        case RECEIVE_EMAIL:
            return receiveItem(state, action);

        case ActionTypes.EMAIL_REQUEST:
        case REQUEST_EMAIL:
            return requestItem(state, action);

        default:
            return state;
    }
};

const emailComposeReducer = (state = {showCompose: false, campaignId: null}, action) => {

    switch (action.type) {

        case ActionTypes.EMAIL_TOGGLE_COMPOSE:
            return {
                ...state,
                showCompose: action.showCompose,
                campaignId: action.campaignId
            };

        default:
            return state;
    }
};

export const emails = combineReducers({
    queries,
    items: emailsById,
    compose: emailComposeReducer
});
