import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from "../Common/Toolbox";
import Form from "../Common/Form/Form";
import Button from "../Common/Form/Button";
import {formatNumber, formatNumberK} from "../../utilities";

class ListFiltersToolbox extends Component {

    static propTypes = {
        filters: PropTypes.object,
        onSubmit: PropTypes.func,
        onClose: PropTypes.func,
        onReset: PropTypes.func,
        style: PropTypes.object,
    };

    state = {
        form: {...this.props.filters}
    };

    handleSubmit() {
        if (this.props.onSubmit) {
            let formData = {};
            for (const key in this.state.form) {
                if (this.state.form.hasOwnProperty(key)) {
                    const value = this.state.form[key];
                    if (value && value !== '') {
                        formData[key] = value;
                    }
                }
            }
            this.props.onSubmit(formData);
        }
    }

    renderForm() {
        return (
            <Form
                values={{...this.props.filters, ...this.state.form}}
                onFieldChange={(key, value) => this.setState({form: {...this.state.form, [key]: value}})}
                fields={[
                    {
                        type: 'text',
                        name: 'profile.name',
                        title: 'Name',
                        placeholder: 'eg: John Smith'
                    },
                    {
                        type: 'text',
                        name: 'profile.email.raw',
                        title: 'Email Address',
                        placeholder: 'eg: email@domain.com'
                    },
                    {
                        type: 'text',
                        name: 'profile.social_slugs',
                        title: 'Social Handle',
                        placeholder: 'eg: bkanbertidal'
                    },
                    {
                        type: 'range',
                        name: 'profile.reach',
                        title: 'Reach',
                        options: {
                            min: 0,
                            max: 250000,
                            step: 1000,
                            valueFormatter: formatNumberK
                        }

                    },
                    {
                        type: 'select',
                        name: 'collectable_type',
                        title: 'Item Type',
                        choices: [
                            {text: 'All Types', value: ''},
                            {text: 'User', value: 'Tidal\\User'},
                            {text: 'Blog', value: 'Tidal\\Blog'},
                            {text: 'Social', value: 'Tidal\\Social'},
                        ]

                    },

                    {
                        type: 'generic',
                        content: (
                            <div className="clearfix">
                                <Button
                                    content={'Search'}
                                    classes={['v3', 'btn-primary', 'pull-left']}
                                    onClick={this.handleSubmit.bind(this)}
                                />
                                <Button
                                    content={'Reset'}
                                    classes={['v3', 'btn-secondary', 'pull-right']}
                                    onClick={this.props.onReset}
                                />
                            </div>
                        )
                    }
                ]}
            />
        );
    }

    render() {

        return (
            <Toolbox
                onClose={this.props.onClose}
                style={this.props.style}
                title={"Filters"}
                content={this.renderForm()}
            />
        );

    }
}

export default ListFiltersToolbox;