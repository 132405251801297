import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getLists} from "../../selectors/lists";
import Alert from "../Common/Alert";
import Checkbox from "../Common/Form/Checkbox";
import Button from "../Common/Form/Button";
import {bindActionCreators} from "redux";
import {fetchLists} from "../../actions";

class AddEntriesToList extends Component {
    static propTypes = {
        lists: PropTypes.array,
        onClickSubmit: PropTypes.func,
        isSubmitting: PropTypes.bool,
        fetchLists: PropTypes.func,
    };

    state = {
        listId: null,
        isSubmitting: false,
    };

    componentDidMount() {
        if (!this.props.lists.length) {
            this.props.fetchLists();
        }
    }

    handleSubmit() {
        if (!this.state.listId) {
            return;
        }
        if (!this.props.onClickSubmit) {
            return;
        }

        return this.props.onClickSubmit(this.state.listId);
    }

    renderAlert() {

        return <Alert classes={['info']} content={'Add selected items to another list.'} />

    }

    renderSelector() {

        return (
            <div
                className="fake-table"
                style={{
                    overflow: 'auto',
                    maxHeight: 300
                }}
            >
                {this.props.lists.map(list => {
                    return (
                        <div
                            className="fake-li bottom-border"
                            style={{paddingLeft: 15}}
                            key={'list-item-wrapper-'+list.id}
                        >
                            <Checkbox
                                onClick={() => this.setState({listId: list.id})}
                                checked={this.state.listId === list.id}
                                label={list.name}
                                useBoldTitle={false}
                            />
                        </div>

                    );
                })}
            </div>
        );

    }

    renderSubmit() {
        const {isSubmitting} = this.props;
        const {listId} = this.state;

        return (
            <div style={{padding: 15}}>

                <Button
                    content={isSubmitting ? 'Adding Items to List...' : 'Add Items to List'}
                    classes={['v3', 'btn-primary', 'medium', 'fullwidth', (isSubmitting || !listId) ? 'disabled' : '']}
                    onClick={this.handleSubmit.bind(this)}
                />
            </div>
        );
    }


    render() {
        return (

            <div>
                {this.renderAlert()}
                {this.renderSelector()}
                {this.renderSubmit()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        lists: getLists(state, props)
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchLists: fetchLists
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddEntriesToList);
