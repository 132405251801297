import _find from 'lodash/find';
import {getTeams} from "./teams";

export const getCurrentChannel = (state, props = {}) => {
    const presence = state.presence || {};
    return presence.channel || {};
};

export const getChannelPlan = (state, props = {}) => {
    const channel = getCurrentChannel(state, props);
    return channel.plan_type;
};

export const getCurrentUser = (state, props = {}) => {
    const presence = state.presence || {};
    return presence.user || {};
};

export const getCurrentUserTeams = (state, props = {}) => {

    const user = getCurrentUser(state, props);
    const teamIds = user.teams || [];
    const allTeams = getTeams(state, props);

    const teams = teamIds.map(id => _find(allTeams, {id})).filter(i => !!i);
    return teams;

};

export const getAclRoles = (state, props = {}) => {
    const user = getCurrentUser(state, props);
    return user.roles || [];
};

export const hasAclRole = (role, state, props = {}) => {
    const roles = getAclRoles(state, props);
    return roles.indexOf(role) > -1;
};

/**
 * This refers to the 'Team' filter in the manage dash
 * @param state
 * @param props
 * @returns {unknown|null}
 */
export const getCurrentTeam = (state, props = {}) => {
    const teamId = state.presence.filters.team_id;

    if (!teamId) {
        return null;
    }

    const teams = getTeams(state, props) || [];
    const team = _find(teams, {id: teamId});

    return team;

};

/**
 * This refers to the team in the contribute/creator dash 'presence' object (by url)
 * @param state
 * @param props
 * @returns {*|null}
 */
export const getPresenceTeam = (state, props = {}) => {
    const presence = state.presence || {};
    return presence.team || null;
}