import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FormSelect from "../Common/Form/Select";
import {getCampaignsByDate} from "../../selectors/campaigns";
import {connect} from 'react-redux';
import Table from "../Table/Table";
import BasicCell from "../Table/Cell/Basic";
import {makeCellKey} from "../../utilities";
import EditableTextCell from "../Table/Cell/EditableText";
import Button from "../Common/Form/Button";

class InviteEntriesFromList extends Component {

    static propTypes = {
        onSubmit: PropTypes.func,
        isSubmitting: PropTypes.bool,
        entries: PropTypes.arrayOf(PropTypes.object).isRequired,
    };

    state = {
        target_type: 'Tidal\\Channel',
        target_id: null,
        entries: {},
    };

    handleSubmit() {
        if (this.props.isSubmitting) {
            return;
        }

        if (this.props.onSubmit) {
            this.props.onSubmit(this.state);
        }
    }

    componentDidMount() {
        let entriesObject = {};
        for (let entry of this.props.entries) {
            entriesObject[entry.id] = {
                payment_amount: null,
                email: entry.profile.email
            };
        }
        this.setState({entries: entriesObject});
    }

    getEntryOverride(id, field) {
        const entry = this.state.entries[id] || {};
        return entry[field] || '';
    }

    setEntryOverride(id, field, value) {
        this.setState({
            entries: {
                ...this.state.entries,
                [id]: {
                    ...this.state.entries[id],
                    [field]: value
                }
            }
        })
    }

    handleSelectTarget(target) {
        if (target === 'hub') {
            this.setState({
                target_type: 'Tidal\\Channel',
                target_id: null
            });
        } else {
            const campaignId = target.substring(target.indexOf(':') + 1);
            this.setState({
                target_type: 'Tidal\\Campaign',
                target_id: campaignId
            });
        }
    }

    getTargetValue() {
        const {target_type, target_id} = this.state;
        if (target_type === 'Tidal\\Channel') {
            return 'hub';
        }
        return 'campaign:' + target_id;
    }

    renderTargetSelect() {

        return (
            <div className="clearfix" style={{position: 'relative'}}>
                <div className="form-group" style={{width: '60%'}}>
                    <label><strong>Invite To:</strong></label>
                    <FormSelect
                        value={this.getTargetValue()}
                        onChange={({value}) => this.handleSelectTarget(value)}
                        options={[
                            {text: "Influencer Hub", value: 'hub'},
                            ...this.props.campaigns.map(campaign => {
                                return {text: campaign.name, value: 'campaign:'+campaign.id};
                            })
                        ]}
                    />
                </div>
                <Button
                    content={(this.props.isSubmitting ? 'Inviting ' : 'Invite ')+ this.props.entries.length + ' Users'}
                    classes={['v3', 'btn-primary', 'medium', (this.props.isSubmitting ? 'disabled' : '')]}
                    style={{position: 'absolute', right: 0, bottom: 15}}
                    onClick={this.handleSubmit.bind(this)}
                />
            </div>
        );
    }
    renderOptionsTable() {
        return (
            <Table
                stickySortTabs={true}
                visibleColumns={[
                    'name',
                    'email',
                    (this.state.target_type !== 'Tidal\\Channel' ? 'payment_amount' : null),
                ].filter(col => !!col)}
                columns={[
                    {
                        key: 'name',
                        width: 300,
                        title: 'Name',
                        cell: (row, column) => <BasicCell
                            column={column} row={row} key={makeCellKey(row, column)}
                            value={row.item.profile.name}
                        />
                    },
                    {
                        key: 'payment_amount',
                        width: 300,
                        title: 'Payment Amount',
                        cell: (row, column) => <EditableTextCell
                            column={column} row={row} key={makeCellKey(row, column)}
                            value={this.getEntryOverride(row.item.id, 'payment_amount')}
                            onSave={value => this.setEntryOverride(row.item.id, 'payment_amount', value)}
                        />
                    },
                    {
                        key: 'email',
                        width: 400,
                        title: 'Email Address',
                        cell: (row, column) => {

                            if (row.item.collectable_type === 'Tidal\\User' && !row.item.profile.metadata.shadow) {
                                return <BasicCell
                                    column={column} row={row} key={makeCellKey(row, column)}
                                    value={row.item.profile.email}
                                />
                            }

                            return <EditableTextCell
                                column={column} row={row} key={makeCellKey(row, column)}
                                value={this.getEntryOverride(row.item.id, 'email')}
                                onSave={value => this.setEntryOverride(row.item.id, 'email', value)}
                            />
                        }
                    },
                ]}
                items={this.props.entries}
            />
        );
    }
    render() {
        return (
            <div
                style={{padding: 15}}
            >
                {this.renderTargetSelect()}
                {this.renderOptionsTable()}
            </div>
        );

    }

}

const mapStateToProps = (state, props) => {
    return {
        campaigns: getCampaignsByDate(state, props)
    };
};

export default connect(mapStateToProps)(InviteEntriesFromList);