import * as ActionTypes from '../actions/types';
import {makeQueriesReducerFor} from "./queries";
import {combineReducers} from "redux";
import {receiveItems, modifyItem, receiveItem} from "../utilities/reducer";
import {fetchAuthenticated, makeFormData} from "../utilities";

export const DEFAULT_POSTS_QUERY = {
    id: "default",
    sort: {by: "ordinality", dir: "desc"},
    limit: 20,
    page: 1,
    filters: {},
    context: {},
    pages: {}
};

const queries = makeQueriesReducerFor('POSTS_');

const postsById = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.POSTS_RECEIVE_QUERY:
            return receiveItems(state, action);
        case ActionTypes.FEATURED_POST:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    is_featured: action.feature
                }
            };
        case ActionTypes.UPDATED_POST_RATING:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    isUpdatingRating: false,
                    lastUpdatedRating: action.receivedAt,
                    rating: action.rating
                }
            };
        case ActionTypes.UPDATE_POST_RATING:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    isUpdatingRating: true
                }
            };
        default:
            return state;
    }
};

export const posts = combineReducers({
    queries,
    items: postsById
});