/**
 * Created by bkroger on 10/22/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from "../../../Common/Alert";
import Form from "../../../Common/Form/Form";

class AccountTools extends Component {

    static defaultProps = {
        privacyPolicyUrl: 'https://tid.al/l/privacy',
    };

    static propTypes = {
        user: PropTypes.object.isRequired,
        refreshUser: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        privacyPolicyUrl: PropTypes.string,
        translate: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {},
            isUpdating: false,
            didUpdate: false,
            hasError: false,
            error: null,
            showDeleteConfirm: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.getFieldValues();
        }
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
            hasError: false,
            error: null,
        });
    }

    getFieldValues() {
        const nativeValues = this.props.user || {};
        const formValues = this.state.form;

        let allowDebugging = false;
        allowDebugging = (nativeValues.settings || {}).allow_debugging || false;
        if (typeof formValues.allow_debugging !== 'undefined') {
            allowDebugging = formValues.allow_debugging;
        }

        return {allow_debugging: allowDebugging};
    }

    handleClickSave() {
        this.setState({isUpdating: true, didUpdate: false});

        this.props.createInteraction(this.props.user.id, 'UpdateSettings', this.state.form)
            .then(resp => {
                if (resp.meta.error) {
                    this.setState({isUpdating: false, hasError: true, error: resp.meta.error})
                } else {
                    this.props.refreshUser(this.props.user.id);
                    this.setState({isUpdating: false, didUpdate: true});
                    setTimeout(() => this.setState({form: {}, didUpdate: false}), 2000);
                }
            });
    }

    renderSaveAlert() {

        const _t = this.props.translate;
        let style = {
            position: 'sticky',
            top: 0,
            zIndex: 200,
            marginBottom: 20
        };

        if (this.state.isUpdating) {
            return <Alert
                classes={['info']} content={_t('contribute.profile.saving', "Saving your changes...")} style={style}
            />
        }

        if (this.state.didUpdate) {
            return <Alert
                classes={['success']} content={_t('contribute.profile.saved', "Changes saved!")} style={style}
            />
        }

        if (this.state.hasError) {
            return <Alert
                classes={['danger']} content={this.state.error} style={style}
            />
        }

        if (Object.keys(this.state.form).length > 0) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>{_t('contribute.profile.unsavedchanges', 'You have unsaved changes.')} <a className="v3 bold" onClick={this.handleClickSave.bind(this)} role="button">{_t('contribute.profile.savenow', 'Save now.')}</a></span>}
                    style={style}
                />
            );
        }
    }

    async handleDelete() {
        const resp = await this.props.createInteraction(this.props.user.id, 'DeleteAccount', {});
        window.location.href = '/contribute/user/logout';
    }

    render() {
        const _t = this.props.translate;

        return (
            <div className="pane-inner-content account-tools">
                {this.renderSaveAlert()}

                <div className="account-tools-section">
                    <Form
                        usePadding={false}
                        values={this.getFieldValues()}
                        onFieldChange={this.handleFieldChange.bind(this)}
                        fields={[
                            {
                                type: 'checkbox',
                                name: 'allow_debugging',
                                title: _t('contribute.account.debug.title', 'Allow Debugging'),
                                options: {hideTitle: true},
                                help: <span>{_t('contribute.account.debug.help', 'This setting allows us to record your activity in this dashboard so we can help you debug account issues. Your session will be recorded by MouseFlow.com. Enable this setting if you reach out to support for account help.')}</span>
                            }
                        ]}

                    />
                </div>

                <div className="account-tools-section">
                    <a href={this.props.privacyPolicyUrl} target="_blank" className="v3 btn btn-secondary medium" style={{width: '100%'}}>{_t('contribute.account.rights.title', 'My Rights and Privacy Policy')}</a>
                    <p className={'v3 light h7'}>
                        {_t('contribute.account.rights.help', 'Read about your right to privacy and how we handle your data.')}
                    </p>
                </div>

                <div className="account-tools-section">
                    <a href="/contribute/do/widget/user/getPersonalData" target="_blank" className="v3 btn btn-secondary medium" style={{width: '100%'}}>{_t('contribute.account.export.title', 'Export My Data')}</a>
                    <p className={'v3 light h7'}>
                        {_t('contribute.account.export.help', 'Use this tool to export and view your personal data as JSON.')}
                    </p>
                </div>

                <div className="account-tools-section">
                    {
                        this.state.showDeleteConfirm
                        ? ( <a onClick={() => this.handleDelete()} className="v3 btn tidal-btn medium bg-alert-danger" style={{color: 'white', width: '100%'}}>{_t('contribute.account.delete.title_confirm', 'Yes, Really Delete My Account')}</a> )
                        : ( <a onClick={() => this.setState({showDeleteConfirm: true})} className="v3 btn tidal-btn medium bg-alert-danger" style={{color: 'white', width: '100%'}}>{_t('contribute.account.delete.title', 'Delete My Account')}</a> )
                    }
                    <p className={'v3 light h7'}>
                        {_t('contribute.account.delete.help', 'Delete your account. This cannot be undone. It may take up to a week for your account to be fully deleted. If you\'ve successfully worked with any brands we may retain copies of your submitted content and payment details for our accounting purposes.')}
                    </p>
                </div>

            </div>
        );
    }

}

export default AccountTools;
