import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tooltip from "../../../Common/Tooltip";

class LiteTableHeader extends Component {

    renderButtons() {
        if (this.props.buttons) {
            return (
                <div className="buttons">
                    {this.props.buttons}
                </div>
            )
        }
    }

    renderSwitch(spec) {

        if (!spec) {
            return null;
        }

        let classes = ['switch'];

        if (spec.isActive) {
            classes.push('active');
        }

        let icon = spec.icon;
        if (typeof spec.tooltip === 'string') {
            icon = <Tooltip title={spec.tooltip}>{spec.icon}</Tooltip>
        }

        return (
            <a
                key={spec.key || spec.name}
                role="button"
                className={classes.join(' ')}
                onClick={spec.onClick ? spec.onClick.bind(this) : null}
            >{icon}</a>
        );

    }

    renderSwitches() {
        const {headerSwitches} = this.props;

        if (!headerSwitches || headerSwitches.length === 0) {
            return null;
        }

        return (
            <div className={'switches'}>
                {(headerSwitches || []).map(this.renderSwitch.bind(this))}
            </div>
        );
    }

    render() {
        return (
            <div className="lite-table-header">
                <div className="header-actions">
                    {this.renderSwitches()}
                    {this.renderButtons()}
                </div>
            </div>
        )
    }

}

export default LiteTableHeader;

LiteTableHeader.propTypes = {

    /** Buttons to include to the right of switches */
    buttons: PropTypes.arrayOf(PropTypes.node),

    /** Specify action icons for the top-right */
    headerSwitches: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.element,
        tooltip: PropTypes.string,
        isActive: PropTypes.bool,
        onClick: PropTypes.func
    }))

};