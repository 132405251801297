import React, {Component} from 'react';

import {average, formatDollar, formatNumber, formatNumberK, standardDeviation} from '../../../../utilities';

export default class ActivationROITable extends Component {

    constructor(props) {
        super(props);
        this.renderRow = this.renderRow.bind(this);
    }


    getAvgs() {
        const rois = this.props.activations.map(a => parseFloat(a.stats[0].roi));
        const avgRoi = average(rois);
        const maxRoi = Math.max.apply(Math, rois);
        const cpes = this.props.activations.map(a => {
            const engagements = a.stats[0].total_engagements;
            const cost = a.stats[0].cost;
            const cpe = engagements ? (cost / engagements) : 1000;
            return cpe;
        });
        const avgCpe = average(cpes);
        const maxCpe = Math.max.apply(Math, cpes);
        const minCpe = Math.min.apply(Math, cpes);
        const cpeStdDev = standardDeviation(cpes);
        return {rois, avgRoi, maxRoi, avgCpe, maxCpe, minCpe, cpes, cpeStdDev};
    };

    calculatePercentOfMax(roi) {
        const {maxRoi} = this.getAvgs();
        let percentOfMax = ( roi / maxRoi) * 100;
        percentOfMax = Math.min(100, percentOfMax);
        return percentOfMax;
    }

    calculateBarWidths(cpe) {

        const {avgRoi, maxRoi, avgCpe, minCpe, maxCpe, cpeStdDev} = this.getAvgs();
        const breakevenMaxWidth = 0.40;
        const belowAvgMaxWidth = 0.35;
        const aboveAvgMaxWidth = 1 - breakevenMaxWidth - belowAvgMaxWidth;
        const thisCpePct = Math.max(cpe / maxCpe, 0.01);

        let breakEvenBarWidth = breakevenMaxWidth;
        let belowAvgBarWidth = belowAvgMaxWidth;
        let aboveAvgBarWidth = aboveAvgMaxWidth;

        if (thisCpePct > breakevenMaxWidth + belowAvgMaxWidth) {
            // thisCpePct = .80. aboveAvgBarWidth should be .14, or
            aboveAvgBarWidth = thisCpePct - (breakevenMaxWidth + belowAvgMaxWidth);
        } else if (thisCpePct > breakevenMaxWidth) {
            // thisCpePct = 0.5, belowAvg should be .5-.33
            aboveAvgBarWidth = 0;
            belowAvgBarWidth = thisCpePct - (breakevenMaxWidth);
        } else {
            aboveAvgBarWidth = 0;
            belowAvgBarWidth = 0;
            breakEvenBarWidth = thisCpePct;
        }

        breakEvenBarWidth *= 100;
        aboveAvgBarWidth *= 100;
        belowAvgBarWidth *= 100;

        return {breakEvenBarWidth, aboveAvgBarWidth, belowAvgBarWidth};
    }

    renderLegend() {

        const {maxCpe} = this.getAvgs();
        const legendWidths = this.calculateBarWidths(maxCpe);

        return (
            <tr>
                <td><strong>Legend</strong></td>
                <td className="roiChart__tdWrapper">

                    <div className="roiChart">

                        <div className="roiChart__bar green"
                             style={{width: legendWidths.breakEvenBarWidth + '%'}}>
                            Best CPE
                        </div>
                        <div className="roiChart__bar grey"
                             style={{width: legendWidths.belowAvgBarWidth + '%'}}>
                            Average
                        </div>
                        <div className="roiChart__bar black"
                             style={{width: legendWidths.aboveAvgBarWidth + '%'}}>
                            Worst CPE
                        </div>
                    </div>
                </td>
            </tr>
        );

    }

    renderUserLink(user) {
        let link = '/manage/do/page/users/detail?slug=' + user.basics.slug;
        return (
            <a href={link} className="lightbox-launcher">{user.basics.name}</a>
        );
    }

    renderRow(row) {

        let user = row.user;

        const roi = parseFloat(row.stats[0].roi);
        const formattedRoi = formatNumber(roi, 1);
        const emv = parseFloat(row.stats[0].emv);
        const cost = parseFloat(row.stats[0].cost);
        const engagements = parseFloat(row.stats[0].total_engagements);
        const blogImpressions = parseFloat(row.stats[0].total_impressions);
        const reach = parseFloat(row.stats[0].total_reach);
        const engPct = parseFloat(row.stats[0].overall_engagement_pct);
        const totalImpressions = blogImpressions + reach;

        const cpe = (engagements != 0) ? cost / engagements : null;
        const cpm = (totalImpressions != 0) ? cost / totalImpressions : null;
        const cpmr = (reach) ? (1000 * (cost / reach)) : null;

        const barWidths = this.calculateBarWidths(cpe);

        const tooltip = 'Total Cost: <strong>' + formatDollar(cost) + '</strong>'
            +'<br>CPE: <strong>'+ formatDollar(cpe, 2) +'</strong>'
            +'<br>CPM-R: <strong>'+ formatDollar(cpmr, 2) +'</strong>'
            +'<br>Earned Media Value: <strong>' + formatDollar(emv) + '</strong>'
            +'<br>ROI: <strong>' + formatNumber(roi, 1) + 'x</strong>'
            +'<br>Engagements: <strong>' + formatNumber(engagements) + '</strong>'
            +'<br>Eng %: <strong>' + formatNumber(100 * engPct, 1) + '%</strong>'
            +'<br>Reach: <strong>' + formatNumber(reach) + '</strong>'
            +'<br>Impressions: <strong>' + formatNumber(totalImpressions) + '</strong>'
        ;

        const labelPosition = 100 - (barWidths.breakEvenBarWidth + barWidths.belowAvgBarWidth + barWidths.aboveAvgBarWidth);
        // let labelStyle = {right: labelPosition + '%'};
        let labelStyle = {left: 0};

        return (
            <tr key={row.activation_id}>
                <td>
                    {this.renderUserLink(user)}
                </td>

                <td className="roiChart__tdWrapper">

                    <div className="roiChart">

                        <div className="roiChart__label" style={labelStyle} data-tooltip={tooltip}>
                            {
                                cpe ?
                                (<span>{formatDollar(cpe, 2)} CPE</span>) : null
                            }
                            {
                                (engagements && engagements > 0) ?
                                (<span>&nbsp;/ {formatNumberK(engagements)} Engs ({formatNumber(100 * engPct, 1)}%)</span>) : null
                            }
                            {
                                (reach && reach > 0) ?
                                (<span>&nbsp;/ {formatNumberK(reach)} Reach</span>) : null
                            }
                        </div>

                        <div className="roiChart__bar green"
                             style={{width: barWidths.breakEvenBarWidth + '%'}}></div>

                        <div className="roiChart__bar grey"
                             style={{width: barWidths.belowAvgBarWidth + '%'}}></div>

                        <div className="roiChart__bar black"
                             style={{width: barWidths.aboveAvgBarWidth + '%'}}></div>

                    </div>
                </td>
            </tr>
        )
    }

    render() {
        const legend = this.renderLegend();
        const rows = this.props.activations.map(this.renderRow);

        if (rows.length === 0) {
            return (

                <div
                    className="alert alert-grey centeredtext"
                    style={{borderRadius: 0, marginBottom: 0}}
                >
                    No results here.
                </div>

            );

        }

        return (
            <table className="table influencer-roi-table">
                <tbody>

                {legend}
                {rows}

                </tbody>

            </table>
        );
    }
}

