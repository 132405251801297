import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MiniProfile from "../MiniProfile";
import SocialHeader from "./SocialHeader";
import NavigationButtons from "./NavigationButtons";
import ScrollingImageRow from "../ScrollingImageRow";
import {socialIconForType, socialNameForType} from "../../../utilities/social";
import GenericProfileFacetsTable from "../GenericProfileFacetsTable";
import GenericProfileSocialsTable from "../GenericProfileSocialsTable";
import GenericProfileSummaryBox from "../GenericProfileSummaryBox";
import StarRating from "../StarRating";
import {getCollectableTypeIcon, getNiceCollectableTypeName} from "../../../utilities";
import TabBar from "./TabBar";
import GenericProfilePerformanceScoreTable from "../GenericProfilePerformanceScoreTable";
import GenericProfileBasicsPane from "./Pane/GenericProfileBasicsPane";
import GenericProfilePerformancePane from "./Pane/GenericProfilePerformancePane";
import GenericProfileSocialsPane from "./Pane/GenericProfileSocialsPane";
import GenericProfileContentPane from "./Pane/GenericProfileContentPane";
import GenericProfileAudiencePane from "./Pane/GenericProfileAudiencePane";

const determineInitialTab = tab => {
    switch (tab) {
        case 'Social': return 'social';
        case 'Performance': return 'performance';
        case 'Profile':
        default:
            return 'profile';
    }
};

export default class GenericProfileDetail extends Component {

    static propTypes = {
        name: PropTypes.string,
        slug: PropTypes.string,
        picture: PropTypes.string,
        reach: PropTypes.number,
        images: PropTypes.arrayOf(PropTypes.string),
        socials: PropTypes.arrayOf(PropTypes.object),
        facets: PropTypes.object,
        tags: PropTypes.array,
        stats: PropTypes.object,
        metadata: PropTypes.object,
        website: PropTypes.string,
        collectable_type: PropTypes.string,
        performance: PropTypes.shape({
            score: PropTypes.number,
            results: PropTypes.array
        }),
        tier_name: PropTypes.string,

        socialdata: PropTypes.object,

        tab: PropTypes.string,
        rating: PropTypes.number,
        onRatingChange: PropTypes.func,

        onClose: PropTypes.func,
        onClickPrev: PropTypes.func,
        onClickNext: PropTypes.func,
    };

    static defaultProps = {
        tab: 'profile'
    };
    
    constructor(props) {
        super(props);
        this.state = {
            tab: determineInitialTab(props.tab)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Reset pane
        if (prevProps.slug !== this.props.slug) {
            this.setState({tab: 'profile'});
        }
    }

    makeSocialReaches() {
        let reachesMap = {};
        for (const social of this.props.socials) {
            const type = social.type;
            const reach = social.followers_count;
            if (!reachesMap[type]) {
                reachesMap[type] = 0;
            }

            reachesMap[type] += parseInt(reach);
        }

        let output = [];
        for (const type of Object.keys(reachesMap)) {
            const niceName = socialNameForType(type);
            const iconClass = socialIconForType(type);
            output.push({
                value: reachesMap[type],
                title: niceName,
                icon: iconClass
            });
        }

        if (this.props.tier_name) {
            output.push({
                value: this.props.tier_name,
                title: 'tier',
                icon: 'v3 icon fire'
            });
        }

        return output;
    }

    renderHeader() {

        const entryItem = {collectable_type: this.props.collectable_type, profile: {...this.props}};
        const iconClass = getCollectableTypeIcon(entryItem);
        const niceType = getNiceCollectableTypeName(entryItem);
        const icon = <i className={iconClass} />;
        const secondary = <span>{icon} {niceType}</span>;

        return (

            <div className="generic-profile-header">
                <MiniProfile
                    primary={this.props.name}
                    secondary={secondary}
                    imageUrl={this.props.picture}
                    switches={[
                        <StarRating rating={this.props.rating} onRatingChange={this.props.onRatingChange} />
                    ]}
                />
                <SocialHeader
                    primary={{title: "Total Reach", value: this.props.reach}}
                    secondary={this.makeSocialReaches()}
                />
                <NavigationButtons
                    onClose={this.props.onClose}
                    handleClickBackward={this.props.onClickPrev}
                    handleClickForward={this.props.onClickNext}
                />

            </div>
        );

    }


    renderTabs() {

        return (
            <div className="user-detail-nav-tabs-wrapper">
                <TabBar
                    tabs={[
                        {key: 'profile', title: 'Profile'},
                        {
                            key: 'content',
                            title: 'Content',
                            isVisible: (this.props.content || []).length > 0
                        },
                        {
                            key: 'social',
                            title: 'Social',
                            isVisible: (this.props.socials || []).length > 0
                        },
                        {key: 'performance', title: 'Performance'},
                        {
                            key: 'audience',
                            title: 'Audience',
                            isVisible: !!(this.props.socialdata)
                        },
                    ]}
                    activeTab={this.state.tab}
                    onClickTab={tab => this.setState({tab})}
                />
            </div>
        );

    }

    renderPane() {

        switch (this.state.tab) {
            case 'profile':
                return this.renderProfilePane();
                break;
            case 'performance':
                return this.renderPerformancePane();
                break;
            case 'social':
                return this.renderSocialPane();
                break;
            case 'content':
                return this.renderContentPane();
                break;
            case 'audience':
                return this.renderAudiencePane();
                break;
        }

    }

    renderAudiencePane() {
        return <GenericProfileAudiencePane
            socialdata={this.props.socialdata}
        />;
    }

    renderContentPane() {
        return <GenericProfileContentPane {...this.props} />;
    }

    renderSocialPane() {
        return (
            <GenericProfileSocialsPane socials={this.props.socials} />
        );
    }

    renderProfilePane() {
        return (
            <GenericProfileBasicsPane {...this.props} />
        );
    }

    renderPerformancePane() {
        const {performance} = this.props;
        return <GenericProfilePerformancePane performance={performance} />;
    }

    render() {

        return (

            <div className="generic-profile" style={this.props.style}>
                {this.renderHeader()}
                {this.renderTabs()}
                {this.renderPane()}
            </div>

        );

    }
}
