import React, {useEffect, useMemo, useState} from 'react';
import MiniProfile from "../../Common/MiniProfile";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../../actions/auth";
import {getBadges} from "../../../selectors/badges";
import Tooltip from "../../Common/Tooltip";
import _find from 'lodash/find';
import {Link} from "react-router-dom";
import {formatNumberK} from "../../../utilities";
import {translate} from "../../../actions/i18n";
import {getCurrentUser} from "../../../selectors/presence";

function SingleBadge({badge}) {
    if (!badge) {
        return null;
    }

    let content = null;
    let style = {};
    const tooltip = (
        <div>
            <label><strong>{badge.name}</strong></label>
            {badge.description && <p>{badge.description}</p>}
        </div>
    );

    if (badge.image) {
        style.backgroundImage = `url(${badge.image})`;
    } else {
        content = badge.name.substr(0, 1).toUpperCase();
    }

    return (
        <Tooltip html={tooltip}>
            <div style={style} className="badge">{content}</div>
        </Tooltip>
    )
}

function ProfileStripe({fetchAuthenticated, badges, translate, presenceUser}) {
    const _t = (k, v) => translate ? translate(k, v) : v;
    const [user, setUser] = useState({});
    const firstName = user.name ? user.name.split(' ')[0] : 'Friend';
    const fetchUser = async () => {
        const resp = await fetchAuthenticated(`/contribute/api/user/_`);
        const json = await resp.json();
        setUser(json.data);
    };
    useEffect(() => {
        fetchUser();
    }, []);

    const BadgesList = useMemo(function( ){
        return (user.badgeIds || []).map(id => {
            const badge = _find(badges, {id});
            if (badge) {
                return <SingleBadge badge={badge} key={badge.id} />;
            } else {
                return null;
            }
        }).filter(b => !!b);
    }, [user, badges])

    const verifiedReach = formatNumberK(user.verified_reach || 0);
    const userIsAdmin = ((presenceUser || {}).roles || []).indexOf("login") > -1;

    const wdEditProfile = _t('creator.words.edit_profile', 'Edit Profile');
    const wdReach = _t('creator.words.reach', 'Reach');
    const wdUnknownLocation = _t('creator.words.unknown_location', 'Unknown Location');
    const wdViewAchievements = _t('creator.words.view_achievements', 'View Achievements');
    const wdWelcome = _t('creator.words.welcome', 'Welcome');
    const wdAdminDash = _t('creator.words.admin_dash', 'Admin Dashboard');

    return (
        <div className="profile-stripe">

            <div className="profile-left">

                <MiniProfile
                    primary={`${wdWelcome}, ${firstName}!`}
                    secondary={(
                        <div>
                            <span><i className="v3 icon map-marker red rightmargin"  />{user.human_location || wdUnknownLocation}</span>
                            <Link to={'/contribute/do/page/dash/profile'} className="v3 btn tidal-btn btn-secondary small">
                                <i className="v3 icon cog red rightmargin" /> {wdEditProfile}
                            </Link>
                            {userIsAdmin && <a href={'/manage'} className="v3 btn tidal-btn btn-secondary small">
                                <i className="v3 icon external-link red rightmargin" /> {wdAdminDash}
                            </a>}
                        </div>
                    )}
                    imageUrl={user.picture}
                    switches={[
                        <div className="attr"><i className="v3 red icon fa-chart-line rightmargin" /> {verifiedReach} {wdReach}</div>,
                        // <div className="attr"><i className="v3 red icon fa-eye rightmargin" /> 128k Views</div>,
                        // <div className="attr"><i className="v3 red icon bullhorn rightmargin" /> 23k Engs</div>,
                    ]}
                />

            </div>
            <div className="profile-right">

                <div className="badges-wrapper">
                    {BadgesList}
                </div>
                <Link
                    to={'/contribute/do/page/dash/achievements'}
                    className={'v3 tidal-btn btn btn-secondary medium'}
                ><><i className="v3 icon fa-star-shooting red rightmargin" /> {wdViewAchievements}</>
                </Link>
            </div>
        </div>
    )
}

export default connect(
    function (state, props) {
        return {
            badges: getBadges(state, props),
            presenceUser: getCurrentUser(state, props)
        };
    },
    function (dispatch) {
        return bindActionCreators({
            fetchAuthenticated: fetchAuthenticated,
            translate: translate
        }, dispatch);
    }
)(ProfileStripe);