/**
 * Created by visgotti on 12/8/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

class PostActions extends Component {
    constructor(props) {
        super(props);
        this.handleToggleShowPostActions = this.handleToggleShowPostActions.bind(this);
        this.state = {
            showPostActions: false,
            loadingMoreActions: false
        }
    }

    handleApplyAction(action) {
        this.props.applyAction(this.props.post_id, action)
    }

    handleToggleShowPostActions() {
        this.setState(prevState => {
            return {
                showPostActions: !prevState.showPostActions,
            }
        })
    }

    renderCampaignStatusOption(activationRecord) {

        const campaignName = activationRecord.campaign.name;
        const {status, post_id} = activationRecord;

        if (status === 'Published') {

            return <div className="post-action"
                        onClick={() => this.props.updatePostActivationStatus(activationRecord.id, post_id, 'Not Published')}
            >
                <i className="fa fa-ban"/> Reject from {campaignName}
            </div>

        } else if (status === 'Submitted') {

            return <div className="post-action"
                        onClick={() => this.props.updatePostActivationStatus(activationRecord.id, post_id, 'Published')}
            >
                <i className="fa fa-star"/> Accept on {campaignName}
            </div>

        } else if (status === 'Not Published') {

            return <div className="post-action"
                        onClick={() => this.props.updatePostActivationStatus(activationRecord.id, post_id, 'Published')}
            >
                <i className="fa fa-star"/> Re-Accept on {campaignName}
            </div>

        } else {
            return null;
        }

    }

    renderCampaignStatusOptions() {
        const post = this.props.post;
        const promise = this.props.fetchPostActivationRecords(this.props.postId);

        if (promise) {
            this.setState({loadingMoreActions: true});
            promise.then(() => this.setState({loadingMoreActions: false}));
        }

        return (post.activationRecords || [])
            .map(activationRecord => this.renderCampaignStatusOption(activationRecord));

    }

    renderLoadingMore() {
        if (this.state.loadingMoreActions) {
            return <div className="post-action">
                <i className="fa fa-spinner fa-spin" /> Loading options...
            </div>
        }

        return null;
    }

    renderFeature() {
        const post = this.props.post;

        if (post.is_featured) {
            return <div className="post-action"
                        onClick={() => this.props.featurePost(post._id, false)}
            ><i className="fa fa-star" /> Un-Feature</div>
        } else {
            return <div className="post-action"
                        onClick={() => this.props.featurePost(post._id, true)}
            ><i className="fa fa-star-o" /> Feature</div>

        }


    }

    renderPublish() {
        const post = this.props.post;

        if (post.is_published) {
            return <div className="post-action"
                        onClick={() => this.props.publishPost(post._id, false)}
            ><i className="fa fa-ban" /> Un-Publish from CMS</div>
        } else {
            return <div className="post-action"
                        onClick={() => this.props.publishPost(post._id, true)}
            ><i className="fa fa-check" /> Publish to CMS</div>
        }

    }

    renderActionOptions() {
        const post = this.props.post;

        if (this.state.showPostActions) {

            return (
                <div
                    className="post-actions"
                >
                    <div className="post-action-arrow"/>

                    {this.renderLoadingMore()}
                    {this.renderCampaignStatusOptions()}
                    {this.renderPublish()}
                    {this.renderFeature()}

                    <div className="post-action">
                        <a href={"/manage/do/page/posts/export?slug=" + post.slug}>
                            <i className="fa fa-cloud-download"/> Download
                        </a>
                    </div>
                </div>
            )
        }

        return null
    }

    render() {
        return (
            <div onClick={this.handleToggleShowPostActions}>
                {
                    <i className="fa fa-navicon post-action-icon"/>
                }
                { this.renderActionOptions() }
            </div>
        )
    }
}

PostActions.propTypes = {
    applyAction: PropTypes.func,
    postId: PropTypes.string,
    post: PropTypes.object.isRequired,

    fetchPostActivationRecords: PropTypes.func.isRequired,
    featurePost: PropTypes.func.isRequired,
    publishPost: PropTypes.func.isRequired,
    updatePostActivationStatus: PropTypes.func.isRequired,

};

export default PostActions;