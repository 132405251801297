import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PostTypesChart from '../../Post/Chart/PostTypesChart';
import PostRatingsChart from '../../Post/Chart/PostRatingsChart';
import ActivationsEMVChart from '../../Activation/Chart/ActivationsEMVChart';
import UserReachChart from '../Chart/Reach';
import UserEngagementPctChart from '../Chart/EngagementPct';
import SocialEngagementPctComparisonChart from '../../Social/Chart/EngagementPctComparison';
import SocialLocationsChart from '../../Social/Chart/Locations';
import AudienceDonutChart from "../../Chart/Audience/AudienceDonutChart";
import {capitalizeFirstLetter, formatNumber, formatNumberK, makeMomentFromDate} from "../../../utilities";
import AudienceBarChart from "../../Chart/Audience/AudienceBarChart";
import AudienceCategoryChart from "../../Chart/Audience/AudienceCategoryChart";
import RechartsTimeseriesAreaChart from "../../Chart/RechartsTimeseriesAreaChart";
import ChartWrapper from "../../Chart/ChartWrapper";
import {linearInterpolateSeriesToTargetCount} from "../../../utilities/stats";

export default class UserDetailStatsPaneManage extends Component {

    state = {
        audience: null,
        stats: null,
    };

    fetchData() {
        this.props.fetchPosts();
        this.props.fetchSocials();
        const activationsPromise = this.props.fetchActivations();

        if (activationsPromise) {
            activationsPromise.then(() => {
                this.props.fetchActivationStats();
            });
        } else {
            this.props.fetchActivationStats();
        }

        this.props.fetchUserStats()
            .then(json => this.setState({stats: linearInterpolateSeriesToTargetCount(json.data, 8)}));

        this.props.fetchAudience()
            .then(resp => resp.json())
            .then(resp => {
                this.setState({audience: resp.data});
            });


    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.id !== this.props.user.id) {
            this.fetchData();
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    getTidalOrSocialDataField(tidalField, socialdataField) {
        const tidalData = this.getTidalAudienceFieldDistribution(tidalField);
        if (tidalData !== null) {
            return tidalData;
        }

        const sdData = this.getSocialDataAudienceField(socialdataField);
        if (sdData) {
            return sdData;
        }

        return null;
    };

    getTidalAudienceFieldDistribution(field) {
        const audience = (this.state.audience || {}).tidal;
        if (!audience) return null;

        const distribution = audience.distribution;
        if (!distribution) return null;

        const data = distribution[field];
        if (!data) return null;

        return data;
    };

    getRawSocialDataAudienceField(field) {
        const audience = this.state.audience;
        if (!audience) return null;
        const sd = audience.socialdata;
        if (!sd) return null;
        const followers = sd.audience_followers;
        if (!followers) return null;
        const data = followers.data;
        if (!data) return null;

        const rawValues = data[field];
        if (!rawValues) return null;
        return rawValues;

    }

    getSocialDataAudienceField(field) {
        const rawValues = this.getRawSocialDataAudienceField(field);
        if (!rawValues) return null;
        return this.parseSocialDataRaw(rawValues);

    }

    parseSocialDataRaw(rawValues) {
        let out = {};
        for (const row of rawValues) {
            const rawTitle = row.name || row.code;
            const title = capitalizeFirstLetter(rawTitle.toLowerCase());
            const val = row.weight;
            out[title] = val;
        }

        return out;
    }

    wrapSocialDataChart({chart, title}) {
        return (
            <div className="widget">
                <div className="title">
                    <h2>{title}</h2>
                </div>
                <div className="content">
                    {chart}
                </div>
            </div>

        );
    }

    renderSocialDataGenderChart() {
        const data = this.getTidalOrSocialDataField('gender', 'audience_genders');
        if (!data) return null;

        return this.wrapSocialDataChart({
            title: 'Audience Gender',
            chart: <AudienceDonutChart data={data} title={"Audience Gender"} height={275} />
        });
    }

    renderSocialDataAgeChart() {
        const data = this.getTidalOrSocialDataField('age', 'audience_ages');
        if (!data) return null;

        return this.wrapSocialDataChart({
            title: 'Audience Age',
            chart: <AudienceBarChart data={data} title={'Audience Age'} height={275} />
        })
    }

    renderSocialDataBrandAffinitiesChart() {
        const data = this.getSocialDataAudienceField('audience_brand_affinity');
        if (!data) return null;
        return this.wrapSocialDataChart({
            title: 'Audience Brands',
            chart: <AudienceCategoryChart data={data} limit={8 }/>
        });
    }

    renderSocialDataLanguagesChart() {
        const data = this.getSocialDataAudienceField('audience_languages');
        if (!data) return null;
        return this.wrapSocialDataChart({
            title: 'Audience Language',
            chart: <AudienceCategoryChart data={data} limit={8 }/>
        });
    }

    renderSocialDataEthnicitiesChart() {
        const data = this.getTidalOrSocialDataField('race', 'audience_ethnicities');
        if (!data) return null;
        return this.wrapSocialDataChart({
            title: 'Audience Ethnicity',
            chart: <AudienceCategoryChart data={data} limit={8 }/>
        });
    }

    renderSocialDataInterestsChart() {
        const data = this.getTidalOrSocialDataField('category', 'audience_interests');
        if (!data) return null;
        return this.wrapSocialDataChart({
            title: 'Audience Interests',
            chart: <AudienceCategoryChart data={data} limit={8 }/>
        });
    }

    renderSocialDataCountriesChart() {
        const geo = this.getRawSocialDataAudienceField('audience_geo');
        if (!geo) return null;
        const countries = geo.countries;
        if (!countries) return null;
        const data = this.parseSocialDataRaw(countries);
        return this.wrapSocialDataChart({
            title: 'Audience Countries',
            chart: <AudienceCategoryChart data={data} limit={8} />
        })
    }
    renderSocialDataCitiesChart() {
        const geo = this.getRawSocialDataAudienceField('audience_geo');
        if (!geo) return null;
        const cities = geo.cities;
        if (!cities) return null;
        const data = this.parseSocialDataRaw(cities);
        return this.wrapSocialDataChart({
            title: 'Audience Cities',
            chart: <AudienceCategoryChart data={data} limit={8} />
        })
    }
    renderSocialDataStatesChart() {
        const geo = this.getRawSocialDataAudienceField('audience_geo');
        if (!geo) return null;
        const states = geo.states;
        if (!states) return null;
        const data = this.parseSocialDataRaw(states);
        return this.wrapSocialDataChart({
            title: 'Audience States',
            chart: <AudienceCategoryChart data={data} limit={8} />
        })
    }

    renderSocialDataCredibilityChart() {
        const audience = this.state.audience;
        if (!audience) return null;
        const sd = audience.socialdata;
        if (!sd) return null;

        const followers = sd.audience_followers;
        const likers = sd.audience_likers;
        const commenters = sd.audience_commenters;

        let data = {};

        if (followers && followers.success && followers.data && followers.data.audience_credibility) {
            data['Followers'] = followers.data.audience_credibility;
        }

        if (likers && likers.success && likers.data && likers.data.audience_credibility) {
            data['Likers'] = likers.data.audience_credibility;
        }

        if (commenters && commenters.success && commenters.data && commenters.data.audience_credibility) {
            data['Commenters'] = commenters.data.audience_credibility;
        }

        if (Object.keys(data).length === 0) {
            return null;
        }

        return this.wrapSocialDataChart({
            title: 'Audience Credibility',
            chart: <AudienceBarChart data={data} title={'Audience Credibility'} height={275} />
        });
    }



    renderSocialDataCharts() {
        if (!this.state.audience) {
            return null;
        }

        return [

            // Audience gender
            this.renderSocialDataGenderChart(),
            // Audience age
            this.renderSocialDataAgeChart(),
            // Credibility
            this.renderSocialDataCredibilityChart(),
            // Audience location
            this.renderSocialDataCountriesChart(),
            this.renderSocialDataCitiesChart(),
            this.renderSocialDataStatesChart(),
            // Audience interests
            this.renderSocialDataInterestsChart(),
            // Audience brand affinities
            this.renderSocialDataBrandAffinitiesChart(),
            // Audience ethnicity
            this.renderSocialDataEthnicitiesChart(),
            // Language
            this.renderSocialDataLanguagesChart(),
        ].filter(chart => !!chart);
    }

    renderNativeCharts() {
        if (this.props.printMode) {
            return null;
        }
        const filteredStats = this.props.activationStats.filter(s => typeof s !== 'undefined');
        return (
            <>
                {this.renderFollowersOverTimeChart()}
                {this.renderPerformanceOverTimeChart()}
                {
                    (this.props.socials || []).length > 0 ?
                        (<UserReachChart user={this.props.user}/>) : null
                }

                {
                    filteredStats.length > 0 ?
                        <UserEngagementPctChart
                            activationStats={filteredStats}
                            campaignsById={this.props.campaignsById}/>
                        : ''
                }
                {
                    filteredStats.length > 0 ?
                        <ActivationsEMVChart activationStats={filteredStats}/>
                        : ''
                }
                {
                    this.props.posts.length > 0 ?
                        <PostTypesChart posts={this.props.posts}/>
                        : ''
                }
                {
                    this.props.posts.length > 0 ?
                        <PostRatingsChart posts={this.props.posts}/>
                        : ''
                }

                {
                    (this.props.socials.length > 0) ?
                        <SocialEngagementPctComparisonChart
                            socials={this.props.socials}
                            style={{height: '350px', marginTop: 0}}
                            showHandle={false}
                        />
                        : ''
                }

                {
                    (this.props.socials.length > 0) ?
                        <SocialLocationsChart
                            socials={this.props.socials}
                            style={{width: '350px', height: '350px', marginTop: 0}}
                            fetchSocialReport={this.props.fetchSocialReport}
                        />
                        : ''
                }
            </>
        );
    }
    render() {


        return (

            <div className={this.props.wrapperClass || 'user-detail-body-stats'}>

                {this.renderSocialDataCharts()}
                {this.renderNativeCharts()}

            </div>

        );
    }

    renderStatsOverTimeChart({chartTitle, dataKey, seriesNameFn, yTickFormatter, valueFormatter}) {
        if (!this.state.stats) {
            return null;
        }
        const lastStat = this.state.stats[this.state.stats.length - 1];
        if (!seriesNameFn) {
            seriesNameFn = (lastStat) => chartTitle;
        }
        if (!yTickFormatter) {
            yTickFormatter = (value) => formatNumberK(value);
        }
        if (!valueFormatter) {
            valueFormatter = (value) => formatNumber(value);
        }

        return this.wrapSocialDataChart({
            title: chartTitle,
            chart: <RechartsTimeseriesAreaChart
                data={this.state.stats}
                height={275}
                seriesSettings={[
                    {
                        chartType: 'area',
                        type: 'linear',
                        dataKey: dataKey,
                        name: seriesNameFn(lastStat),
                        stroke: '#206601',
                        fill: '#206601',
                        fillOpacity: 0.8,
                        stackId: 1,
                        formatter: valueFormatter
                    },
                ]}
                axisSettings={[
                    {
                        axisType: 'x',
                        dataKey: 'created_at',
                        stroke: '#888',
                        interval: 0,
                        tickFormatter: (value) => makeMomentFromDate(value).format('M/D')
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'left',
                        stroke: '#888',
                        tickFormatter: yTickFormatter
                    }
                ]}
            />
        } );

    }
    renderPerformanceOverTimeChart() {
        return this.renderStatsOverTimeChart({
            name: (lastStat) => `Performance Score`,
            chartTitle: 'Performance Score',
            dataKey: 'performance_score',
            yTickFormatter: (value) => formatNumber(100*value, 0) + '%',
            valueFormatter: (value) => formatNumber(100*value, 0) + '%',
        });
    }
    renderFollowersOverTimeChart() {
        return this.renderStatsOverTimeChart({
            name: (lastStat) => `Followers (total ${formatNumberK(lastStat.verified_reach)})`,
            chartTitle: 'Followers Growth',
            dataKey: 'verified_reach'
        });
    }
}

UserDetailStatsPaneManage.propTypes = {
    printMode: PropTypes.bool,
    wrapperClass: PropTypes.string,
    user: PropTypes.object,
    socials: PropTypes.array,
    posts: PropTypes.array,
    campaignsById: PropTypes.object,
    activations: PropTypes.array,
    activationStats: PropTypes.array,

    fetchSocials: PropTypes.func,
    fetchPosts: PropTypes.func,
    fetchActivationStats: PropTypes.func,
    fetchActivations: PropTypes.func,
    fetchSocialReport: PropTypes.func,
    fetchAudience: PropTypes.func,
    fetchUserStats: PropTypes.func,
};
