import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

export default class DropdownButton extends Component {

    static defaultProps = {
        classes: [],
        style: {},
        wrapperStyle: {},
        icon: <i className="fa fa-angle-down" />,
        showDropdown: false,
    };

    static propTypes = {
        classes: PropTypes.array,
        onClickPrimary: PropTypes.func,
        style: PropTypes.object,
        wrapperStyle: PropTypes.object,
        content: PropTypes.node.isRequired,
        icon: PropTypes.node,
        dropdown: PropTypes.node,
        showDropdown: PropTypes.bool,
    };

    state = {
        showDropdown: false
    };

    componentDidUpdate(prevProps, prevState) {

        if (this.props.showDropdown !== prevProps.showDropdown) {
            this.setState({showDropdown: this.props.showDropdown});
        }

    }

    handleClickPrimary(e) {
        if (this.props.onClickPrimary) {
            this.props.onClickPrimary(e);
        } else {
            this.setState({showDropdown: !this.state.showDropdown});
        }
    }

    renderDropdown() {
        if (!this.state.showDropdown) {
            return null;
        }

        const hideDropdown = () => this.setState({showDropdown: false});

        if (typeof this.props.dropdown === 'function') {
            return this.props.dropdown({hideDropdown});
        }

        return this.props.dropdown;
    }

    render() {

        return (
            <div className="dropdown-btn-wrapper" style={this.props.wrapperStyle}>
                <Button
                    content={this.props.content}
                    onClick={this.handleClickPrimary.bind(this)}
                    classes={this.props.classes}
                    style={this.props.style}
                />
                <Button
                    content={this.props.icon}
                    classes={this.props.classes}
                    onClick={() => this.setState({showDropdown: !this.state.showDropdown})}
                />
                {this.renderDropdown()}
            </div>

        );
    }
}