import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableWrapper from '../Table/Wrapper';
import BasicCell from "../Table/Cell/Basic";
import Select from '../Common/Form/Select';

export default class ImporterMapper extends Component {

    static propTypes = {
        columns: PropTypes.array.isRequired,
        mapping: PropTypes.object.isRequired,
        banner: PropTypes.node,
        buttons: PropTypes.array,
        popups: PropTypes.array,
        title: PropTypes.string,
        dataHeadings: PropTypes.array,
        onMappingChange: PropTypes.func,
    };

    handleMappingChange(key, value) {

        if (this.props.onMappingChange) {
            this.props.onMappingChange(
                {
                    ...this.props.mapping,
                    [key]: value
                }
            );
        }

    }

    getColumns() {
        return [

            {
                key: 'ours',
                title: 'Data Field',
                default: true,
                sortable: false,
                width: 300,
                cell: (row, column) => <BasicCell row={row} column={column} value={row.item.title} />,
            },
            {
                key: 'yours',
                title: 'Spreadsheet Column',
                default: true,
                sortable: false,
                width: 300,
                cell: (row, column) => {

                    const mappingValue = typeof this.props.mapping[row.item.key] !== 'undefined' ? this.props.mapping[row.item.key] : null;
                    const select = <Select
                        options={[
                            {text: 'Select a Column', value: null},
                            ...this.props.dataHeadings.map((text, value) => ({text, value}))
                        ]}
                        onChange={({value}) => this.handleMappingChange(row.item.key, value)}
                        value={mappingValue}
                    />;

                    return (
                        <BasicCell
                            row={row}
                            column={column}
                            value={select}
                        />
                    );
                }
            },
            {
                key: 'required',
                title: 'Required',
                default: true,
                sortable: false,
                width: 100,
                cell: (row, column) => <BasicCell row={row} column={column} value={row.item.required ? 'Required' : 'Optional'} />,
            },
            {
                key: 'notes',
                title: 'Notes',
                default: true,
                sortable: false,
                width: 400,
                cell: (row, column) => <BasicCell row={row} column={column} value={row.item.notes} />
            }


        ];
    }

    render() {
        return (
            <TableWrapper
                wrapperClasses={['importer-mapper']}
                banner={this.props.banner}
                items={this.props.columns}
                columns={this.getColumns()}
                buttons={this.props.buttons}
                popups={this.props.popups}
                title={this.props.title}
                showPagination={false}
                showFilters={false}
                showTotals={false}
            />
        );
    }
}