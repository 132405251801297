import {
    REQUEST_USER_POSTS, RECEIVE_USER_POSTS, REQUEST_POST, RECEIVE_POST,
    RECEIVE_POST_STATS, REQUEST_POST_STATS,
    UPDATED_POST_RATING, UPDATE_POST_RATING, FEATURE_POST, FEATURED_POST,
    REQUEST_POST_ACTIVATION_RECORDS, RECEIVE_POST_ACTIVATION_RECORDS,
    PUBLISHED_POST, INVALIDATE_POST,
    UPDATE_POST_ACTIVATION_STATUS, UPDATED_POST_ACTIVATION_STATUS
} from '../actions';

import {UPDATED_POST} from '../actions/postsById';

import { indexArrayById } from '../utilities';

export function activationPostRecordsById(state = {}, action) {
    switch (action.type) {

        case UPDATED_POST_ACTIVATION_STATUS:
            return {
                ...state,
                [action.activationPostRecordId]: action.activationRecord
            };

        case RECEIVE_POST_ACTIVATION_RECORDS:
            const objects = indexArrayById(action.activationRecords);
            return {...state, ...objects};

        default: return state;
    }

}

export const DEFAULT_POST_STATE = {

    didInvalidate: false,

    isFetching: false,
    lastUpdated: null,

    isUpdatingRating: false,
    lastUpdatedRating: null,

    isFetchingActivationRecords: false,
    lastUpdatedActivationRecords: null,

    isFetchingStats: false,
    lastUpdatedStats: null,

    activationRecordIds: [],
    imageIds: [],
    stats: {}

};

export function postsById(state = {}, action) {

    switch (action.type) {

        case INVALIDATE_POST:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    didInvalidate: true
                }
            };

        case PUBLISHED_POST:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    is_published: action.publish,
                    didInvalidate: true
                }
            };

        case UPDATED_POST:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    is_updated: action.update
                }
            };

        case RECEIVE_POST_ACTIVATION_RECORDS:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    isFetchingActivationRecords: false,
                    lastUpdatedActivationRecords: action.receivedAt,
                    activationRecordIds: action.activationRecords.map(ar => ar.id)
                }
            };

        case REQUEST_POST_ACTIVATION_RECORDS:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    isFetchingActivationRecords: true
                }
            };

        case FEATURED_POST:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    is_featured: action.feature
                }
            };

        case UPDATED_POST_RATING:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    isUpdatingRating: false,
                    lastUpdatedRating: action.receivedAt,
                    rating: action.rating
                }
            };

        case UPDATE_POST_RATING:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    isUpdatingRating: true
                }
            };

        case RECEIVE_USER_POSTS:
            let newState = Object.assign({}, state);

            for (let post of action.posts) {
                newState[post._id] = {
                    ...DEFAULT_POST_STATE,
                    ...post,
                    imageIds: indexArrayById(post.images),
                    isFetching: false,
                    lastUpdated: action.receivedAt,
                    didInvalidate: false
                };
            }

            return newState;

        case RECEIVE_POST:
            return {
                ...state,
                [action.postId] : {
                    ...DEFAULT_POST_STATE,
                    ...state[action.postId],
                    ...action.data,
                    imageIds: indexArrayById(action.data.images),

                    isFetching: false,
                    lastUpdated: action.receivedAt,
                    didInvalidate: false
                }
            };

        case RECEIVE_POST_STATS:
            return {
                ...state,
                [action.postId]: {
                    ...DEFAULT_POST_STATE,
                    ...state[action.postId],

                    isFetchingStats: false,
                    lastUpdatedStats: action.receivedAt,

                    stats: {
                        ...action.stats,
                    }
                }
            };

        case REQUEST_POST_STATS:
            return {
                ...state,
                [action.postId]: {
                    ...state[action.postId],
                    isFetchingStats: true,
                }
            };

        case REQUEST_POST:
            return {
                ...state,
                [action.postId]: {
                    ...DEFAULT_POST_STATE,
                    ...state[action.postId],
                    isFetching: true,
                    didInvalidate: false
                }
            };

        default:
            return state;
    }
}
