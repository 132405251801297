
import {fetchAuthenticated} from "./auth";
import {makeFormData} from "../utilities";

export const REQUEST_EMAIL_TEMPLATE = 'REQUEST_EMAIL_TEMPLATE';
export const RECEIVE_EMAIL_TEMPLATE = 'RECEIVE_EMAIL_TEMPLATE';
export const REQUEST_EMAIL_TEMPLATE_PREVIEW = 'REQUEST_EMAIL_TEMPLATE_PREVIEW';
export const RECEIVE_EMAIL_TEMPLATE_PREVIEW = 'RECEIVE_EMAIL_TEMPLATE_PREVIEW';
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';
export const UPDATED_EMAIL_TEMPLATE = 'UPDATED_EMAIL_TEMPLATE';

export const REQUEST_EMAIL_TEMPLATES = 'REQUEST_EMAIL_TEMPLATES';
export const RECEIVE_EMAIL_TEMPLATES = 'RECEIVE_EMAIL_TEMPLATES';

export const REQUEST_CAMPAIGN_PAGE_TEMPLATES = 'REQUEST_CAMPAIGN_PAGE_TEMPLATES';
export const RECEIVE_CAMPAIGN_PAGE_TEMPLATES = 'RECEIVE_CAMPAIGN_PAGE_TEMPLATES';
export const REQUEST_PAGE_TEMPLATE = 'REQUEST_PAGE_TEMPLATE';
export const RECEIVE_PAGE_TEMPLATE = 'RECEIVE_PAGE_TEMPLATE';
export const REQUEST_PAGE_TEMPLATE_PREVIEW = 'REQUEST_PAGE_TEMPLATE_PREVIEW';
export const RECEIVE_PAGE_TEMPLATE_PREVIEW = 'RECEIVE_PAGE_TEMPLATE_PREVIEW';
export const UPDATE_PAGE_TEMPLATE = 'UPDATE_PAGE_TEMPLATE';
export const UPDATED_PAGE_TEMPLATE = 'UPDATED_PAGE_TEMPLATE';

export function fetchEmailTemplates(pinned = null) {
    let url = `/manage/api/emailtemplate`;
    if (pinned === true) {
        url = url + '?pinned=true';
    } else if (pinned === false) {
        url = url + '?pinned=false';
    }

    return function (dispatch) {
        dispatch({
            type: REQUEST_EMAIL_TEMPLATES,
            pinned
        });

        return dispatch(fetchAuthenticated(url))
            .then(resp => resp.json())
            .then(json => {
                json.data.forEach(template => dispatch({
                    type: RECEIVE_EMAIL_TEMPLATE,
                    key: template.id,
                    receivedAt: new Date(),
                    template: template
                }));
                return dispatch({
                    type: RECEIVE_EMAIL_TEMPLATES,
                    meta: json.meta,
                    data: json.data
                });
            });

    }
}

export function updatePageTemplate(id, {source}) {
    const url = `/manage/api/pagetemplate/${id}`;
    const payload = {source, _method: 'PATCH'};

    return function(dispatch) {
        dispatch({
            type: UPDATE_PAGE_TEMPLATE,
            id,
            source
        });

        return dispatch(fetchAuthenticated(
            url,
            {
                credentials: 'include',
                method: 'POST',
                body: makeFormData(payload)
            }
        ))
            .then(response => response.json())
            .then(json => dispatch({
                type: UPDATED_PAGE_TEMPLATE,
                id,
                template: json.data,
                receivedAt: new Date()
            }))
            .catch(err => {
                console.log("Error updating page template");
                console.log(err)
            });


    }

}

export function fetchPageTemplatePreview(campaign, templateId, name, metadata = {}) {
    const encodedMetadata = JSON.stringify(metadata);
    const url = `/manage/do/widget/campaign/preview_page?id=${campaign.id}&name=${name}&withMetadata=${encodedMetadata}&withTemplateVars=1`;

    return function (dispatch) {

        dispatch({
            type: REQUEST_PAGE_TEMPLATE_PREVIEW,
            id: templateId,
            campaign,
            metadata,
            name
        });

        return dispatch(fetchAuthenticated(url))
            .then(response => response.text())
            .then(text => dispatch({
                type: RECEIVE_PAGE_TEMPLATE_PREVIEW,
                campaign,
                name,
                metadata,
                preview: text,
                id: templateId,
                receivedAt: new Date()
            }))
            .catch(err => {
                console.log("Error fetching page template preview");
                console.log(err)
            });

    }

}

export function fetchPageTemplate(id) {
    return function(dispatch) {
        dispatch({
            type: REQUEST_PAGE_TEMPLATE,
            id
        });

        const url = `/manage/api/pagetemplate/${id}`;
        return dispatch(fetchAuthenticated(url))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: RECEIVE_PAGE_TEMPLATE,
                id,
                template: json.data,
                receivedAt: new Date()
            }))
            .catch(err => {
                console.log("Error fetching campaign template");
                console.log(err);
                return err;
            });
    }

}

export function fetchCampaignPageTemplates(campaignId) {
    return function(dispatch) {
        dispatch({
            type: REQUEST_CAMPAIGN_PAGE_TEMPLATES,
            campaignId
        });

        const url = `/manage/api/pagetemplate?campaign=${campaignId}`;
        return dispatch(fetchAuthenticated(url))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: RECEIVE_CAMPAIGN_PAGE_TEMPLATES,
                campaignId,
                templates: json.data,
                receivedAt: new Date()
            }))
            .catch(err => {
                console.log("Error fetching campaign page templates");
                console.log(err);
                return err;
            });
    }
}

export const unmakeEmailTemplateKey = key => key.split(':').pop();

export const makeEmailTemplateKey = (campaign, key) => {
    // Key might already have the prefix so check for that first.
    const prefix = 'campaign:'+campaign.mongo_id+':';
    if (key.substr(0, prefix.length) !== prefix) {
        return prefix + key;
    }
    return key;
};

export function updatedEmailTemplate(campaign, key, json) {
    return {
        type: UPDATED_EMAIL_TEMPLATE,
        campaign,
        key,
        template: json.data,
        receivedAt: new Date()
    }
}

export function updateEmailTemplate(campaign, key, {subject, body}) {
    const fullKey = makeEmailTemplateKey(campaign, key);
    const url = `/manage/api/emailtemplate/${fullKey}`;
    const payload = {subject, body, _method: 'PATCH'};

    return function(dispatch) {
        dispatch({
            type: UPDATE_EMAIL_TEMPLATE,
            key: fullKey,
            campaign,
            subject,
            body
        });

        return dispatch(fetchAuthenticated(
            url,
            {
                method: 'POST',
                body: makeFormData(payload)
            }
        ))
            .then(response => response.json())
            .then(json => dispatch(updatedEmailTemplate(campaign, fullKey, json)))
            .catch(err => {
                console.log("Error updating email template");
                console.log(err)
            });


    }

}

export function receiveEmailTemplatePreview(campaign, key, text) {
    const fullKey = makeEmailTemplateKey(campaign, key);
    return {
        type: RECEIVE_EMAIL_TEMPLATE_PREVIEW,
        key: fullKey,
        preview: text,
        campaign,
        receivedAt: new Date()
    }
}

export function fetchEmailTemplatePreview(campaign, key, metadata = {}) {
    const fullKey = makeEmailTemplateKey(campaign, key);
    const encodedMetadata = JSON.stringify(metadata);
    const url = `/manage/do/widget/campaign/preview_email?id=${campaign.id}&key=${fullKey}&withMetadata=${encodedMetadata}&withTemplateVars=1`;

    return function (dispatch) {

        dispatch({
            type: REQUEST_EMAIL_TEMPLATE_PREVIEW,
            campaign,
            metadata,
            key: fullKey
        });

        return dispatch(fetchAuthenticated(url))
            .then(response => response.text())
            .then(text => dispatch(receiveEmailTemplatePreview(campaign, key, text)))
            .catch(err => {
                console.log("Error fetching email template preview");
                console.log(err)
            });

    }

}

export function receiveEmailTemplate(campaign, key, json) {
    const fullKey = makeEmailTemplateKey(campaign, key);
    return {
        type: RECEIVE_EMAIL_TEMPLATE,
        campaign,
        key: fullKey,
        receivedAt: new Date(),
        template: json.data
    };
}

export function fetchEmailTemplate(campaign, key) {
    const fullKey = makeEmailTemplateKey(campaign, key);
    const url = `/manage/api/emailtemplate/${fullKey}`;

    return function(dispatch) {
        dispatch({
            type: REQUEST_EMAIL_TEMPLATE,
            campaign,
            shortKey: key,
            key: fullKey
        });

        return dispatch(fetchAuthenticated(url))
            .then(response => response.json())
            .then(json => dispatch(receiveEmailTemplate(campaign, key, json)))
            .catch(err => {
                console.log("Error fetching email template");
                console.log(err)
            });

    }

}

/**
 * @param campaign
 * @param key
 * @param state
 * @returns {*}
 */
export function shouldFetchEmailTemplate(campaign, key, state) {
    const fullKey = makeEmailTemplateKey(campaign, key);
    const obj = state.emailTemplatesByKey[fullKey] || null;

    if (!obj) {
        return true;
    } else if (obj.isFetching || obj.isUpdating) {
        return false;
    } else {
        return true;
    }

}

/**
 * @param campaign
 * @param key
 * @returns {function(*, *)}
 */
export function fetchEmailTemplateIfNeeded(campaign, key) {
    return (dispatch, getState) => {
        if (shouldFetchEmailTemplate(campaign, key, getState())) {
            console.log("ShouldFetchEmailTemplate, returning promise for " + key);
            return dispatch(fetchEmailTemplate(campaign, key));
        } else {
            console.log("ShouldFetchEmailTemplate not returning promise for " + key);

        }
    }
}
