import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Sort extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {

        const {column, isActive, direction, onClick} = this.props;
        const sortKey = column.sortKey ? column.sortKey : column.key;
        let sortDir = column.sortDir ? column.sortDir : 'asc';

        if (!onClick) {
            return null;
        }

        if (!column.sortable) {
            return null;
        }

        if (isActive && direction) {
            if (direction === 'asc') {
                sortDir = 'desc';
            } else {
                sortDir = 'asc';
            }
        }

        onClick({by: sortKey, dir: sortDir});
    }

    getStyle() {

        const {column} = this.props;
        const style = {};

        if (column.width) {
            style.width = column.width;
            style.minWidth = column.width;
        }

        if (column.sticky) {
            if (column.stickyLeft) {
                style.left = column.stickyLeft;
            } else {
                style.left = 0;
            }
        }

        return style;

    }

    getTitle() {
        const {column} = this.props;
        if (column.title) {
            return <span className="title">{column.title}</span>
        }

    }

    getTotal() {
        const {column, showTotals} = this.props;
        if (showTotals && column.total) {
            return <span className="total">{column.total}</span>
        }
    }

    getSortIcon() {

        let icon = <i className="fa fa-angle-down" />
        if (this.props.direction) {
            if (this.props.direction === 'desc') {
                icon = <i className="fa fa-angle-up" />
            }
        }

        return icon;
    }

    getSort() {
        const {column} = this.props;

        if (!column.sortable) {
            return null;
        }

        return <span className="sort">
            {this.getSortIcon()}
        </span>

    }

    getClassName() {

        const {column, isActive, showTotals} = this.props;
        let classes = ["godzilla-table-sort-cell"];

        if (column.sortable) {
            classes.push('sortable');
        }

        if (isActive) {
            classes.push('active');
        }

        if (showTotals && column.total) {
            classes.push('has-total');
        }

        if (column.sticky) {
            classes.push('stick-to-left');
        }

        return classes.join(" ");
    }

    render() {
        return (
            <div className={this.getClassName()}
                 style={this.getStyle()}
                 onClick={this.handleClick}
                 data-tooltip={this.props.column.tooltip}
            >
                {this.getTitle()}
                {this.getTotal()}
                {this.getSort()}
            </div>
        );
    }
}

Sort.defaultProps = {
    showTotals: false,
};

Sort.propTypes = {
    column: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    showTotals: PropTypes.bool,
    direction: PropTypes.string,
    onClick: PropTypes.func,
};

export default Sort;