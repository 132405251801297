import React, {Component} from 'react';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import TableWrapper from '../Table/Wrapper';
import ImporterMapper from './Mapper';
import BasicCell from "../Table/Cell/Basic";
import Button from "../Common/Form/Button";
import Toolbox from '../Common/Toolbox';

function make_cols(refstr/*:string*/) {
    let o = [];
    let range = XLSX.utils.decode_range(refstr);
    for (let i = 0; i <= range.e.c; ++i) {
        o.push({name: XLSX.utils.encode_col(i), key: i});
    }
    return o;
}

export default class Importer extends Component {

    static defaultProps = {
        title: "Import Spreadsheet",
        actionText: "Import Data",
        importAllColumns: false,
    };

    static propTypes = {
        banner: PropTypes.node,
        onImport: PropTypes.func,
        title: PropTypes.string,
        actionText: PropTypes.string,
        columns: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            required: PropTypes.bool,
        })).isRequired,
        importAllColumns: PropTypes.bool,
    };

    state = {
        hasFile: false,
        showPreview: false,
        data: [],
        cols: [],
        mapping: {},
    };

    areRequiredFieldsMapped() {
        let isSatisfied = true;
        for (let col of this.props.columns) {
            if (col.required) {
                if (typeof this.state.mapping[col.key] === 'undefined' || this.state.mapping[col.key] === null) {
                    isSatisfied = false;
                }
            }
        }

        return isSatisfied;

    }

    handleImport() {

        let output = this.state.data.slice(1).map(orig => {
            let newRow = {};

            for (let ourField in this.state.mapping) {
                if (!this.state.mapping.hasOwnProperty(ourField)) {
                    continue;
                }

                let theirField = this.state.mapping[ourField];
                let value = orig[theirField];
                newRow[ourField] = value;
            }

            // Add any columns that weren't mapped
            if (this.props.importAllColumns) {
                for (let i = 0; i < orig.length; i++) {
                    const columnHeader = this.state.data[0][i];
                    if (typeof this.state.mapping[i] === 'undefined') {
                        newRow[columnHeader] = orig[i];
                    }
                }
            }

            return newRow;
        });

        if (this.props.onImport) {
            this.props.onImport(output);
        }

    }

    handleFile(event) {

        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        const file = event.target.files[0];

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array'});

            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {header: 1});

            /* Update state */
            this.setState({
                data: data,
                cols: make_cols(ws['!ref']),
                hasFile: true,
            });
        };

        if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
    };

    renderUploaderBanner() {

        return (
            <div>
                <div
                    className={'alert tidal-alert alert-grey'}
                    style={{marginBottom: 0}}
                >Select an Excel Spreadsheet from your computer to get started.
                </div>

                <div className="form-group">
                    <input
                        className="form-control"
                        type="file"
                        onChange={this.handleFile.bind(this)}
                        style={{
                            border: 'none',
                            padding: 20,
                        }}
                    />
                </div>

                {/*<div style={{marginTop: 40}} className="godzilla-header"><h2 className="title">Preview Import</h2></div>*/}
            </div>
        );
    }

    renderMapper() {
        return <ImporterMapper
            banner={this.props.banner}
            buttons={this.renderButtons()}
            popups={this.renderPopups()}
            title={this.props.title}
            columns={this.props.columns}
            mapping={this.state.mapping}
            dataHeadings={(this.state.data || [])[0] || []}
            onMappingChange={mapping => this.setState({mapping})}
        />

    }

    renderPopups() {
        return [
            this.state.showPreview ? this.renderPreview() : null,
        ];
    }

    renderButtons() {

        if (!this.state.hasFile) {
            return [];
        }

        const isValid = this.areRequiredFieldsMapped();
        let classes = [];

        if (isValid) {
            classes = ['v3', 'btn-primary'];
        } else {
            classes = ['v3', 'btn-secondary', 'disabled'];
        }

        const importBtn = <Button
            content={this.props.actionText}
            classes={classes}
            onClick={isValid ? this.handleImport.bind(this) : null}
        />;

        const previewBtn = <Button
            content={"Show Preview"}
            classes={['v3', 'btn-secondary', (isValid ? '' : 'disabled')]}
            onClick={isValid ? () => this.setState({showPreview: !this.state.showPreview}) : null}
        />;

        return [importBtn, previewBtn];

    }

    renderPreview() {

        return (
            <Toolbox
                style={{
                    top: '60px',
                    width: '1000px',
                    left: '50%',
                    marginLeft: '-500px',
                    maxHeight: 400,
                    overflow: 'auto',
                }}
                title={'Preview Import'}
                content={this.renderPreviewTable()}
                onClose={() => this.setState({showPreview: false})}
            />
        )

    }

    renderPreviewTable() {
        return (
            <TableWrapper
                showPagination={false}
                showHeader={false}
                items={(this.state.data || []).slice(1)}
                stickySortTabs={true}
                columns={
                    this.props.columns.map(colDef => {
                        return {
                            default: true,
                            sortable: false,
                            width: 200,
                            cell: (row, column) => {

                                const hasMapping = typeof this.state.mapping[colDef.key] !== 'undefined'
                                    && this.state.mapping[colDef.key] !== null;

                                let value = 'Not Mapped';
                                if (hasMapping) {
                                    value = row.item[this.state.mapping[colDef.key]];
                                }

                                return <BasicCell row={row} column={column} value={value}/>
                            },
                            ...colDef
                        };
                    })
                }
            />
        )
    }

    render() {
        if (!this.state.hasFile) {
            return this.renderUploaderBanner();
        } else {
            return this.renderMapper();
        }
    }
}
