import React from 'react';
import {CompactPicker} from 'react-color';

export default function Color({onChange, value, options={}}) {

    const handleChange = (color, event) => {
        console.log({color, event});
        if (onChange) {
            onChange(color.hex, event);
        }
    };

    const getValue = () => {
        if (!value) return options.defaultValue || '#000000';
        return value;
    };

    return (

        <CompactPicker
            color={getValue()}
            onChange={handleChange}
        />

    );
}