import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TinyThermometer from "./TinyThermometer";
import {formatNumber} from "../../utilities";

class GenericProfilePerformanceScoreTable extends Component {

    static propTypes = {
        score: PropTypes.number.isRequired,
        results: PropTypes.arrayOf(PropTypes.shape({
            weighted: PropTypes.number,
            normalized: PropTypes.number,
            value: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            field: PropTypes.string,
            min: PropTypes.number,
            max: PropTypes.number,
            weight: PropTypes.number,
        })).isRequired
    };

    renderResult(result) {

        const pct = Math.round(result.normalized * 100);
        const tooltip = result.tooltipFn ? result.tooltipFn(result) : result.description;
        return (
            <div className="fake-li performance-wrapper">
                <span className="performance-name">
                    {result.name}
                </span>
                <span className="thermometer-wrapper">
                    <TinyThermometer pct={pct} tooltip={tooltip} />
                </span>
            </div>
        )
    }

    renderScore() {
        const pct = Math.round(this.props.score * 100);

        return (
            <div className="fake-li performance-wrapper">
                <span className="performance-name">
                    <strong>Overall Performance Score</strong>
                </span>
                <span className="thermometer-wrapper">
                    <TinyThermometer
                        pct={pct}
                        color={"#000000"}
                        tooltip={`Overall performance score is measured from 0 - 100%, based on the factors below. This creator's overall score is <strong>${formatNumber(pct)}%</strong>.`}
                        style={{marginTop: '13px', height: '14px'}}
                    />
                </span>
            </div>
        )

    }

    render() {

        return (
            <div className="user-performance-explorer">
                {this.renderScore()}
                {this.props.results.map(result => this.renderResult(result))}
            </div>
        );


    }

}

export default GenericProfilePerformanceScoreTable;
