/**
 * Created by bkanber on 8/1/17.
 */

import React, {Component} from 'react';

import CampaignActivationStatsTable from './Table';

export default class CampaignActivationStatsTableWrapper extends Component {

    render() {

        const campaign = this.props.campaign;
        const numActivations = this.props.activations.length;
        const numStats = this.props.campaignStats.length;
        const isValid = (
            numActivations > 0 &&
                numStats > 0 &&
                campaign.lastUpdatedActivations !== null &&
                campaign.lastUpdatedActivationStats !== null &&
                campaign.lastUpdatedUsers !== null
        );

        return (

            <div className="widget">

                <div className="title nobottommargin">
                    <div className="icon left">
                        <i className="fa fa-users"></i>
                    </div>
                    <h2>
                        Activated Creators and Engagements
                        { (isValid && numActivations > 0) ?
                            <span className="badge badge-darkgrey">
                                {this.props.activations.length} creators with stats available
                            </span>
                            : ''
                        }
                    </h2>
                </div>

                <div className="content nopadding" style={{minHeight: '60px'}}>

                    {

                        isValid ?
                            <CampaignActivationStatsTable
                                activations={this.props.activations}
                                campaign={this.props.campaign}
                                campaignStats={this.props.campaignStats}
                            />
                            :
                            <div className="alert tidal-alert alert-grey">Loading stats...</div>
                    }

                </div>

            </div>


        );
    }

}