import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../actions/activations";
import {getCampaign, getCampaignCoupons} from "../../selectors/campaigns";
import {fetchCampaignCoupons} from "../../actions";
import {fetchActivation} from "../../actions";
import SectionalToolbox from '../Common/SectionalToolbox';
import Button from '../Common/Form/Button';
import Alert from '../Common/Alert';
import CreateCouponForm from '../Campaign/Coupons/CreateCouponForm';
import CampaignCouponsTable from '../Campaign/Coupons/Table';
import _find from 'lodash/find';

class AssignCouponCodeToolbox extends Component {
    static defaultProps = {
        style: {},
    };

    static propTypes = {
        style: PropTypes.object,
        activation: PropTypes.object,
        campaign: PropTypes.object,
        coupons: PropTypes.array,
        onClose: PropTypes.func,
        onSaved: PropTypes.func,
        createInteraction: PropTypes.func,
        fetchCoupons: PropTypes.func,
        fetchActivation: PropTypes.func,
    };

    state = {
        isAssigning: false,
        didAssign: false,
    };

    componentDidMount() {

        const {campaign, fetchCoupons} = this.props;
        fetchCoupons(campaign.id);

    }

    getUnclaimedCoupons() {
        return this.props.coupons
            .filter(coupon => !coupon.is_consumed);
    }

    getUnclaimedCoupon() {
        const available = this.getUnclaimedCoupons();
        if (available.length > 0) {
            return available[0];
        }
        return null;
    }

    handleAssign(coupon) {

        const {activation, createInteraction, fetchActivation, fetchCoupons, campaign, onSaved} = this.props;
        this.setState({isAssigning: true});
        const payload = {coupon_id: coupon.id};

        return createInteraction(activation.id, 'AssignCouponCode', payload)
            .then(() => fetchActivation(activation.id))
            .then(() => fetchCoupons(campaign.id))
            .then(() => this.setState({isAssigning: false, didAssign: true}))
            .then(() => {
                if (onSaved) {
                    onSaved();
                }
            });

    }

    handleAutomaticallyAssign() {
        const coupon = this.getUnclaimedCoupon();
        if (!coupon) {
            return;
        }
        return this.handleAssign(coupon);
    }

    renderAutomaticallyAssignSection() {

        const {isAssigning, didAssign} = this.state;
        const unclaimed = this.getUnclaimedCoupons();

        if (unclaimed.length > 0) {
            return (
                <div>
                    <Alert
                        classes={['info']}
                        content={`You have ${unclaimed.length} unassigned coupons; click to automatically assign one.`}
                        style={{marginBottom: 20}}
                    />

                    <Button
                        content={isAssigning ? 'Assigning Coupon...' : 'Automatically Assign Coupon'}
                        classes={['v3', 'btn-primary', 'fullwidth', (isAssigning ? 'disabled' : '')]}
                        onClick={this.handleAutomaticallyAssign.bind(this)}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <Alert
                        classes={['danger']}
                        content={`You don't have any unused coupons! Use "Create and Assign" instead.`}
                        style={{marginBottom: 20}}
                    />
                </div>
            );

        }


    }

    handleCreateAndAssign(dispatched) {

        const allCoupons = dispatched.data || [];
        const couponId = dispatched.meta.created_id;
        const coupon = _find(allCoupons, {id: couponId});

        if (coupon) {
            return this.handleAssign(coupon);
        }

    }

    renderCreateAndAssignSection() {
        return (
            <div>
                <Alert classes={['info']} content={'Create and assign a new coupon code below.'} style={{marginBottom: 20}} />
                <CreateCouponForm
                    usePadding={false}
                    campaign={this.props.campaign}
                    onCreated={this.handleCreateAndAssign.bind(this)}
                    btnText={'Create and Assign'}
                />
            </div>
        );
    }

    renderChooseExistingSection() {
        if (this.state.didAssign) {
            return <Alert classes={['success']} content={'Coupon assigned!'} />
        }

        if (this.state.isAssigning) {
            return <Alert classes={['info']} content={'Assigning coupon...'} />
        }

        return <CampaignCouponsTable
            showHeader={false}
            campaign={this.props.campaign}
            onClickAssign={(coupon) => {
                this.handleAssign(coupon);
            }}
        />;
    }

    getSections() {
        return [
            {
                key: 'auto',
                title: 'Automatically Assign',
                isExpanded: true,
                padded: true,
                content: this.renderAutomaticallyAssignSection(),
            },
            {
                key: 'create-assign',
                title: 'Create and Assign',
                isExpanded: false,
                padded: true,
                content: this.renderCreateAndAssignSection(),
            },
            {
                key: 'choose-existing',
                title: 'Choose Existing',
                isExpanded: false,
                padded: true,
                content: this.renderChooseExistingSection(),
            },

        ];
    }

    render() {

        return <SectionalToolbox
            title={'Assign Coupon Code'}
            style={this.props.style}
            onClose={this.props.onClose}
            sections={this.getSections()}
            accordion={true}
        />

    }
}

const mapStateToProps = (state, props) => {

    const campaign = getCampaign(state, {campaignId: props.activation.campaign_id});
    return {
        campaign: campaign,
        coupons: getCampaignCoupons(state, {campaign}),
    };

};

const mapDispatchToProps = (dispatch) => {
    return {

        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchCoupons: (id) => dispatch(fetchCampaignCoupons(id)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),

    };

};

const ConnectedAssignCouponCodeToolbox = connect(mapStateToProps, mapDispatchToProps)(AssignCouponCodeToolbox);

ConnectedAssignCouponCodeToolbox.propTypes = {
    activation: PropTypes.object.isRequired,
};

export default ConnectedAssignCouponCodeToolbox;