/**
 * Created by bkroger on 10/2/18.
 */

import React, {Component} from  'react';
import PropTypes from 'prop-types';
import InfluencerRequirementsCampaignHeader from './CampaignHeader';
import InfluencerRequirementsList from './RequirementsList';
import _get from 'lodash/get';

class InfluencerRequirementsLeftPane extends Component {

    static defaultProps = {
        useMobileNav: false,
    };

    static propTypes = {
        onClickCreateIssue: PropTypes.func,
        onClickShowIssues: PropTypes.func,
        useMobileNav: PropTypes.bool,
        refreshUser: PropTypes.func,
        user: PropTypes.object,
        translate: PropTypes.func,
    };

    getTranslator() {
        if (this.props.translate) {
            return this.props.translate;
        }
        return (k, v) => v;
    }

    renderMenuBar() {

        return (
            <div className="influencer-requirements-menu-wrapper col-xs-12 col-md-5 col-lg-6 clearfix">
                {this.renderRightMenu()}
            </div>
        );

    }

    renderRightMenu() {

        const _t = this.getTranslator();
        const activation = this.props.activation;
        const status = activation.status;
        const openIssuesCount = _get(status, 'open_issues_count', 0);
        const totalIssuesCount = _get(status, 'total_issues_count', 0);

        const showIssues = totalIssuesCount > 0;
        let issuesIcon = openIssuesCount > 0 ? <i className="v3 icon error" /> : <i className="v3 icon exclamation-triangle" />;
        let issuesText = openIssuesCount > 0 ? `${openIssuesCount} ${_t('campaign.open_issues', 'Open Issues')}` : _t('campaign.view_issues', 'View Issues');
        const showIssuesClass = openIssuesCount > 0 ? 'has-open-issues' : '';

        return (
            <div className="influencer-requirements-switches-wrapper pull-right">

                {
                    showIssues &&
                    <a className={"v3 bold h7 show-issues " + showIssuesClass} role="button" onClick={this.props.onClickShowIssues}>
                        {issuesIcon} {issuesText}
                    </a>
                }

                <a className="v3 bold h7 create-issue" role="button" onClick={this.props.onClickCreateIssue}>
                    <i className="v3 icon info" /> {_t('campaign.have_an_issue', 'Have an Issue?')}
                </a>


            </div>
        );

    }



    render() {

        const {activation, campaign, onViewChange, currentView, refreshActivation, useMobileNav} = this.props;

        return (
            <div className="influencer-left-pane-wrapper col-xs-12 col-md-4 col-lg-4">

                <InfluencerRequirementsCampaignHeader
                    activation={activation}
                    campaign={campaign}
                    translate={this.getTranslator()}
                />

                <InfluencerRequirementsList
                    activation={activation}
                    onViewChange={onViewChange}
                    currentView={currentView}
                    refreshActivation={refreshActivation}
                    useMobileNav={useMobileNav}
                    onClickCreateIssue={this.props.onClickCreateIssue}
                    onClickShowIssues={this.props.onClickShowIssues}
                    user={this.props.user}
                    refreshUser={this.props.refreshUser}
                    translate={this.getTranslator()}
                />

            </div>
        )

    }

}

export default InfluencerRequirementsLeftPane;
