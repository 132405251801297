/**
 * Created by bkanber on 6/29/17.
 */

import {
    REQUEST_BADGES, RECEIVE_BADGES,
    ADD_USERS_TO_BADGE, ADDED_USERS_TO_BADGE, REMOVE_USERS_FROM_BADGE, REMOVED_USERS_FROM_BADGE
} from '../actions/badges';
import * as ActionTypes from '../actions/types';
import { receiveItems, modifyItem } from "../utilities/reducer";

export function badgesById(state = {lastUpdated: null, isFetching: false, items: {}},
                           action) {

    switch (action.type) {

        case ADDED_USERS_TO_BADGE:
        case REMOVED_USERS_FROM_BADGE:
        {
            let newState = Object.assign({}, state);
            newState.items[action.badgeId] = Object.assign({}, newState.items[action.badgeId], {_isUpdating: false});
            return newState;
        }

        case ADD_USERS_TO_BADGE:
        case REMOVE_USERS_FROM_BADGE:
        {
            let newState = Object.assign({}, state);
            newState.items[action.badgeId] = Object.assign({}, newState.items[action.badgeId], {_isUpdating: true});
            return newState;
        }

        case REQUEST_BADGES:
            return Object.assign({}, state, {isFetching: true});
            break;

        case RECEIVE_BADGES:
            let newState = Object.assign({}, state, {
                isFetching: false,
                lastUpdated: action.receivedAt
            });

            for (let badge of action.badges) {
                newState.items[badge.id] = badge;
            }

            return newState;
            break;
        case ActionTypes.CREATE_BADGE:
            return {...state, isCreatingBadge: true};
        case ActionTypes.CREATED_BADGE:
            return {...state, isCreatingBadge: false};
        case ActionTypes.UPDATE_BADGE:
            return modifyItem(state, action, {isUpdatingBadge: true});
        case ActionTypes.UPDATED_BADGE:
            return modifyItem(state, action, {isUpdatingBadge: false});
        default:
            return state;
    }

}
