/**
 * Created by bkroger on 5/14/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../Common/Alert';
import SubmitBlogPostForm from '../../../Activation/SubmitBlogPostForm';

import TableWrapper from '../../../Table/Wrapper';
import BasicCell from '../../../Table/Cell/Basic';
import LinkCell from '../../../Table/Cell/Link';
import DateCell from '../../../Table/Cell/Date';
import moment from 'moment';

class SubmitBlogPosts extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        campaign: PropTypes.object.isRequired,
        refreshActivation: PropTypes.func,
        createInteraction: PropTypes.func,
        translate: PropTypes.func

    };

    renderForm() {

        return <SubmitBlogPostForm
            activation={this.props.activation}
            createInteraction={this.props.createInteraction}
            onComplete={() => { }}
            usePadding={true}
            style={{
                background: '#F6F6F6',
                paddingBottom: 1,
            }}
        />

    }

    getBlogPosts() {
        return (this.props.activation.post_records || this.props.activation.postRecords || [])
            .filter(record => {
                const meta = record.metadata || {};
                return typeof meta.type === 'undefined' || meta.type === 'post' || meta.type === 'blog';
            });
    }

    renderTable() {

        const _t = this.props.translate;
        const items = this.getBlogPosts();
        if (items.length === 0) {
            return null;
        }

        return (
            <div>
                <div className="divider-line" />
                <TableWrapper
                    title={_t('campaign.blog.table.title', 'Submitted Blog Posts')}
                    showPagination={false}
                    showFilters={false}
                    items={items}
                    columns={[
                        {
                            key: 'created_at',
                            title: _t('campaign.blog.table.created', 'Submitted On'),
                            default: true,
                            sortable: false,
                            width: 150,
                            cell: (row, column) => <DateCell row={row} column={column}
                                                             value={moment(row.item.created_at)}
                            />
                        },
                        {
                            key: 'title',
                            title: _t('campaign.blog.table.post_title', 'Post Title'),
                            default: true,
                            sortable: false,
                            width: 400,
                            cell: (row, column) => <LinkCell row={row} column={column}
                                                             value={row.item.post.title}
                                                             href={row.item.post.permalink}
                                                             target={'_blank'}
                            />
                        },
                        {
                            key: 'status',
                            title: _t('campaign.blog.table.status', 'Submission Status'),
                            default: true,
                            sortable: false,
                            width: 200,
                            cell: (row, column) => <BasicCell row={row} column={column}
                                                              value={(() => {
                                                                  const status = row.item.status;
                                                                  if (status === 'Published') return 'Accepted';
                                                                  return status;
                                                              })()}
                            />
                        },
                    ]}

                />
            </div>

        );

    }

    renderInner() {
        const numRequired = this.getNumRequired();
        const plural = numRequired === 1 ? '' : 's';
        return (
            <div>

                <p className="v3 light h7 pane-info" style={{marginBottom: 20}}>Please submit {numRequired} blog post{plural} below.</p>

                {this.renderForm()}

                {this.renderTable()}


            </div>
        );
    }

    getNumRequired() {
        const {activation} = this.props;
        return activation.settings.num_blog_posts;
    }

    isBlocked() {

        const {activation} = this.props;
        return activation.status.required_drafts_count > 0
                && activation.status.accepted_drafts_count < activation.status.required_drafts_count;
    }

    renderBlockedAlert() {
        const _t = this.props.translate;
        return <Alert
            classes={['danger']}
            content={_t('campaign.blog.alert_draft', "Your drafts must be submitted and approved before you can post content!")}
        />

    }

    render() {
        const _t = this.props.translate;
        const numRequired = this.getNumRequired();
        const wdBlogPosts = numRequired === 1 ? _t('campaign.blog.blog_post', 'Blog Post') : _t('campaign.blog.blog_posts', 'Blog Posts');

        return(
            <div className="influencer-page-template submit-blog-posts">
                <div className="widget">
                    <div className="content padded">

                        <h4 className="v3 bold action-pane-title">{_t('campaign.blog.submit', 'Submit')} {numRequired} {wdBlogPosts}</h4>

                        {this.isBlocked() ? this.renderBlockedAlert() : this.renderInner()}

                    </div>
                </div>
            </div>
        )
    }

}

export default SubmitBlogPosts;
