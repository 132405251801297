import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";
import {ucwords} from "../../../../utilities";
import {RACE_FACET_OPTIONS, LANGUAGE_FACET_OPTIONS, CATEGORY_FACET_OPTIONS, PARENTHOOD_FACET_OPTIONS, RELATIONSHIP_FACET_OPTIONS, OCCUPATION_FACET_OPTIONS} from "../../../../utilities/user";

class Facets extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
        refreshUser: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        isProfileFieldVisible: PropTypes.func.isRequired,
        translate: PropTypes.func.isRequired,
        channel: PropTypes.object.isRequired,
        team: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {},
            showCustomRace: false,
            isUpdating: false,
            didUpdate: false,
            hasError: false,
            error: null,
        };

        // Logic to determine if the Race field should start as a text field or a dropdown
        const fieldValues = this.getFieldValues();
        const initRaceValue = fieldValues.Race || null;
        const dropdownRaceValues = RACE_FACET_OPTIONS.map(opt => {
            if (typeof opt === 'string') {
                return opt;
            } else {
                return opt.value;
            }
        });
        if (initRaceValue && !dropdownRaceValues.includes(initRaceValue)) {
            this.state.showCustomRace = true;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.getFieldValues();
        }
    }

    handleFieldChange(name, value) {
        // switch to custom race field if "other" is selected
        if (name === 'Race' && value === 'Other' && !this.state.showCustomRace) {
            // return early
            return this.setState({
                showCustomRace: true,
                form: {
                    ...this.state.form,
                    [name]: 'Other'
                }
            })
        }

        // main logic:
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
            hasError: false,
            error: null,
        });

    }

    handleClickSave() {
        this.setState({isUpdating: true, didUpdate: false});
        const context = {...this.state.form, channel_id: this.props.channel.id};

        this.props.createInteraction(this.props.user.id, 'SetTags', context)
            .then(resp => {
                if (resp.meta.error) {
                    this.setState({isUpdating: false, hasError: true, error: resp.meta.error})
                } else {
                    this.props.refreshUser(this.props.user.id);
                    this.setState({isUpdating: false, didUpdate: true});
                    setTimeout(() => this.setState({form: {}, didUpdate: false}), 2000);
                }
            });
    }

    getFieldValues() {
        const user = this.props.user || {};
        const userProfile = user.profile || [];
        const formValues = this.state.form;
        const nativeValues = {
            tags: user.tags,
            ...(user.facets || {})
        };

        // Merge in any profile fields.
        for (const profileField of userProfile) {
            if (profileField.channel_id === this.props.channel.mongo_id) {
                nativeValues[profileField.key] = profileField.value;
            }
        }

        return {...nativeValues, ...formValues};
    }

    renderSaveAlert() {

        const _t = this.props.translate;
        let style = {
            position: 'sticky',
            top: 0,
            zIndex: 200,
        };

        if (this.state.isUpdating) {
            return <Alert
                classes={['info']} content={_t('contribute.profile.saving', "Saving your changes...")} style={style}
            />
        }

        if (this.state.didUpdate) {
            return <Alert
                classes={['success']} content={_t('contribute.profile.saved', "Changes saved!")} style={style}
            />
        }

        if (this.state.hasError) {
            return <Alert
                classes={['danger']} content={this.state.error} style={style}
            />
        }

        if (Object.keys(this.state.form).length > 0) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>{_t('contribute.profile.unsavedchanges', 'You have unsaved changes.')} <a className="v3 bold" onClick={this.handleClickSave.bind(this)} role="button">{_t('contribute.profile.savenow', 'Save now.')}</a></span>}
                    style={style}
                />
            );
        }
    }

    renderCustomRace() {
        const _t = this.props.translate;
        return {
            type: 'text',
            name: 'Race',
            title: _t('contribute.facets.race.title', "Race/Ethnicity"),
            help: "Type in your preferred response."
        }
    }

    renderOneSelect(key, name, choices, addl = {}) {
        if (!this.props.isProfileFieldVisible(key)) {
            return null;
        }

        const _t = this.props.translate;
        const exclusionKey = 'facets.' + name;
        if (!this.props.isProfileFieldVisible(exclusionKey)) {
            return null;
        }

        const translatedChoices = choices.map(choice => {
            if (typeof choice === 'string') {
                return {text: _t(`contribute.facets.${name}.choices.${choice}`, choice), value: choice};
            } else {
                return {text: _t(`contribute.facets.${name}.choices.${choice.value}`, choice.text), value: choice.value};
            }
        });

        return {
            type: 'select',
            name: name,
            title: ucwords(name),
            choices: [
                {text: _t('contribute.facets.remove_this_field', 'Remove this Field'), value: ''},
                ...translatedChoices.map(choice => {
                    if (typeof choice === 'string') {
                        return {text: choice, value: choice};
                    } else {
                        return choice;
                    }
                })
            ],
            options: {
                defaultValue: `- ${_t('contribute.facets.select_your', 'Select your')} ${ucwords(name)} -`,
            },
            ...addl
        };

    }

    renderCustomFields() {

        const _t = this.props.translate;

        /**
         * Custom field spec from the team or channel.
         */
        let spec = null;
        if (this.props.team && this.props.team.custom_profile_fields) {
            spec = this.props.team.custom_profile_fields;
        } else if (this.props.channel && this.props.channel.custom_profile_fields) {
            spec = this.props.channel.custom_profile_fields;
        }

        if (!spec) {
            return [];
        }

        let jsonSpec;
        if (typeof spec === 'string') {
            jsonSpec = JSON.parse(spec);
        } else {
            jsonSpec = JSON.parse(JSON.stringify(spec));
        }
        if (!jsonSpec) {
            return [];
        }

        return jsonSpec.map(field => {

            field.name = 'custom.' + field.name;
            field.title = _t('contribute.profile.' + field.name + '.title', field.title);
            if (field.help) {
                field.help = _t('contribute.profile.' + field.help + '.help', field.help);
            }
            if (field.placeholder) {
                field.placeholder = _t('contribute.profile.' + field.placeholder + '.placeholder', field.placeholder);
            }
            if (field.choices) {
                field.choices = field.choices.map(choice => ({...choice, text: _t('contribute.profile.' + field.name + '.choices.' + choice.value, choice.text)}));
            }
            return field;
        });



    }

    render() {
        const _t = this.props.translate;
        const isVisible = this.props.isProfileFieldVisible;

        // Race/Ethnicity field has a type-in option for 'Other'
        const RaceEthnicityField = this.state.showCustomRace
            ? this.renderCustomRace()
            : this.renderOneSelect('race', 'Race', RACE_FACET_OPTIONS, {title: _t('contribute.facets.race.title', "Race/Ethnicity")});

        return (
            <div className="pane-inner-content">
                {this.renderSaveAlert()}
                <p className={'v3 light h7 pane-info'}>
                    {_t('contribute.facets.intro', 'These tags and categories will be added to your profile and will help brands find you easier. All fields are optional and can be removed at any time.')}
                </p>
                <Form
                    values={this.getFieldValues()}
                    onFieldChange={this.handleFieldChange.bind(this)}
                    fields={[
                        this.renderOneSelect('category', 'Category', CATEGORY_FACET_OPTIONS, {title: _t('contribute.facets.category.title', "Primary Content Category")}),
                        this.renderOneSelect('language', 'Language', LANGUAGE_FACET_OPTIONS, {title: _t('contribute.facets.language.title', "Primary Language")}),
                        RaceEthnicityField,
                        this.renderOneSelect('relationship', 'Relationship', RELATIONSHIP_FACET_OPTIONS, {title: _t('contribute.facets.relationship.title', "Relationship Status")}),
                        this.renderOneSelect('parenthood', 'Parenthood', PARENTHOOD_FACET_OPTIONS, {title: _t('contribute.facets.parenthood.title', "Parenthood Status")}),
                        this.renderOneSelect('occupation', 'Occupation', OCCUPATION_FACET_OPTIONS, {title: _t('contribute.facets.occupation.title', "Occupation or Job Title")}),
                        isVisible('tags') ? {
                            type: 'tags',
                            name: 'tags',
                            title: _t('contribute.facets.tags.title', 'Other Tags'),
                        } : null,
                        ...this.renderCustomFields()
                    ]}
                />
            </div>
        );
    }

}

export default Facets;