
import React, {Component} from 'react';
import {POST_TABLE_TABS} from './Table';
import PropTypes from 'prop-types';

export default class PostTableSettings extends Component {

    handleCheckboxClick(name, event) {
        let cols = this.props.selectedColumns.slice(0);
        const selectedIndex = cols.indexOf(name);

        if (selectedIndex > -1) {
            cols.splice( selectedIndex, 1 );
        } else {
            cols.push(name);
        }

        if (typeof this.props.onChange === 'function') {
            this.props.onChange(cols);
        }

    }

    renderCheckbox(tabdef) {

        const isSelected = this.props.selectedColumns.indexOf(tabdef.name) > -1;
        const checkboxClass = isSelected ? 'tidal-checkbox checked' : 'tidal-checkbox';
        const checkIcon = isSelected ? <i className="fa fa-check"></i> : <span></span>;

        return (
            <div className="fake-li padded" onClick={this.handleCheckboxClick.bind(this, tabdef.name)}>
                <div className={checkboxClass}>
                    {checkIcon}
                </div>
                {tabdef.name}
            </div>
        )
    }

    renderCheckboxes() {
        return POST_TABLE_TABS.filter(td => !td.alwaysShow).map(td => {return this.renderCheckbox(td)});
    }

    render() {
        return (
            <div className="posts-table-select-columns toolbox">
                <h2 className="title">Show/Hide Columns</h2>
                <a className="close absolute" onClick={this.props.onClose}><i className="fa fa-times"></i></a>

                {this.renderCheckboxes()}
            </div>
        );
    }

}

PostTableSettings.defaultProps = {
    selectedColumns: []
};

PostTableSettings.propTypes = {
    selectedColumns: PropTypes.array,
    onChange: PropTypes.func,
    onClose: PropTypes.func
};