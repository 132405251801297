import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput'

export default class Tags extends Component {

    static defaultProps = {
        classes: [],
        style: {},
    };

    static propTypes = {
        classes: PropTypes.array,
        name: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        style: PropTypes.object,
    };

    render() {
        return (
            <TagsInput
                value={this.props.value || []}
                onChange={this.props.onChange}
            />
        );
    }
}
