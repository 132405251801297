import {fetchAuthenticated} from "../actions/auth";
import {receiveEmail} from "./emailQueries";
import * as ActionTypes from "./types";
import {makeFormData} from "../utilities";


export const UPDATE_EMAIL_LABEL = 'UPDATE_EMAIL_LABEL';
export const UPDATED_EMAIL_LABEL = 'UPDATED_EMAIL_LABEL';
export const REQUEST_EMAIL = 'REQUEST_EMAIL';
export const UPDATE_EMAIL_CAMPAIGN = 'UPDATE_EMAIL_CAMPAIGN';
export const UPDATED_EMAIL_CAMPAIGN = 'UPDATED_EMAIL_CAMPAIGN';
export const REQUEST_EMAIL_THREAD = 'REQUEST_EMAIL_THREAD';
export const RECEIVE_EMAIL_THREAD = 'RECEIVE_EMAIL_THREAD';


/**
 * @param emailId
 * @returns {function(*, *)}
 */
export function fetchEmailIfNeeded(emailId) {
    return (dispatch, getState) => {
        if (shouldFetchEmail(getState(), emailId)) {
            return dispatch(fetchEmail(emailId))
        } else {
            return new Promise((resolve, reject) => resolve());
        }
    }
}

export function markEmails(emailIds, label) {
    return function (dispatch) {
        emailIds.forEach(emailId => {
            dispatch(markEmail(emailId, label))
        })
    }
}

function markEmail(emailId, label) {
    let url, method;
    if (label === 'spam') {
        url ='/manage/api/email/' + emailId + '/spam';
        method = 'POST'
    } else {
        url = '/manage/api/email/' + emailId + '/label/' + encodeURIComponent(label);
        method = 'PATCH'
    }
    console.log('the url was', url)
    return function(dispatch) {
        dispatch(fetchAuthenticated(url, {
            method,
        }))
        .then(response => response.json())
        .then(json => dispatch({type: UPDATE_EMAIL_LABEL, label: label, emailId}))
        .catch(err => {
            console.log("Error marking emails");
            console.log(err)
        });
    }
}



/**
 * @param emailId
 * @returns {function(*, *)}
 */
export function fetchEmailThreadIfNeeded(emailId) {
    return (dispatch, getState) => {
        if (shouldFetchEmailThread(getState(), emailId)) {
            return dispatch(fetchEmailThread(emailId))
        } else {
            return new Promise((resolve, reject) => resolve());
        }
    }
}

/**
 * @param state
 * @param emailId
 * @returns {boolean}
 */
export function shouldFetchEmailThread(state, emailId) {
    const email = state.emailsById.emails[emailId];
    if (typeof email === 'undefined') return true;
    if (email.isFetchingThread) return false;
    if (email.didInvalidate) return true;
    if (email.lastUpdatedThread) return false;

    return true;
}

/**
 * @param emailId
 * @returns {Function}
 */
export function fetchEmailThread(emailId) {

    return function (dispatch) {

        dispatch({
            type: REQUEST_EMAIL_THREAD,
            emailId
        });

        const url = `/manage/api/email/${emailId}/thread`;

        return fetch(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => {
                // Receive all emails
                json.data
                    .filter(email => email.id !== emailId)
                    .forEach(email => dispatch(receiveEmail(email.id, {data: email})));

                return dispatch({
                    type: RECEIVE_EMAIL_THREAD,
                    emailId,
                    receivedAt: new Date(),
                    emails: json.data,
                    thread_id: json.meta.thread_id,
                });

            })
            .catch(err => {
                console.log("Error fetching threads");
                console.log(err);
                return err;
            })
    }
}

export function updateEmailCampaign(emailId, campaignId) {
    return function (dispatch) {
        const url = `/manage/api/email/${emailId}/campaign`;
        const payload = {campaign_id: campaignId, _method: 'PATCH'};

        dispatch({
            type: UPDATE_EMAIL_CAMPAIGN,
            emailId,
            campaignId
        });

        return fetch(url, {
            credentials: 'include',
            method: 'POST',
            body: makeFormData(payload)
        })
            .then(resp => resp.json())
            .then(json => {
                return dispatch({
                    type: UPDATED_EMAIL_CAMPAIGN,
                    emailId,
                    email: json.data,
                    receivedAt: new Date()
                });
            })
            .catch(err => {
                console.log("Error updating email campaign");
                console.log(err);
                return err;
            });
    }
}


/**
 * @param state
 * @param emailId
 * @returns {boolean}
 */
export function shouldFetchEmail(state, emailId) {
    const email = state.emailsById.emails[emailId];
    if (typeof email === 'undefined') return true;
    if (email.didInvalidate) return true;
    return false;
}

export function fetchEmail(emailId) {
    return function (dispatch) {
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/email/${emailId}`;
        dispatch({type: REQUEST_EMAIL, emailId, id: emailId});
        return dispatch(fetchAuthenticated(url))
            .then(resp => resp.json())
            .then(json => dispatch(receiveEmail(emailId, json)))
            .catch(err => {
                console.log("Error fetching email");
                console.log(err);
                return err;
            });
    }
}


export const createEmailInteraction = (emailId, type, context) => {

    return (dispatch) => {

        const dashboard = window.Gator.getDashboardSlot();
        const uri = `/${dashboard}/api/email/${emailId}/interaction`;

        dispatch({
            type: ActionTypes.EMAIL_CREATE_INTERACTION,
            id: emailId,
            interactionType: type,
            context
        });

        return dispatch(fetchAuthenticated(uri, {
            method: 'POST',
            body: makeFormData({
                type,
                context: JSON.stringify(context)
            })
        }))
            .then(resp => resp.json())
            .then(json => {
                dispatch({
                    type: ActionTypes.EMAIL_CREATED_INTERACTION,
                    id: emailId,
                    meta: json.meta,
                    data: json.data
                });
                return json
            });
    }
};
