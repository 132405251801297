import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SocialProfile from '../../Social/Profile';

export default class UserDetailSocialPaneRow extends Component {

    render() {
        return (
            <div className={'user-detail-body-social-row'}>
                <SocialProfile {...this.props} />
            </div>
        );
    }

}

UserDetailSocialPaneRow.propTypes = {
    user: PropTypes.object.isRequired,
    social: PropTypes.object.isRequired,
};
