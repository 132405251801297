/**
 * Created by bkroger on 4/26/18.
 */

import {connect} from 'react-redux';
import {createUserInteraction, fetchCampaign, fetchCampaignsIfNeeded} from '../../actions';
import {DEFAULT_CAMPAIGN_STATE} from "../../reducers/campaigns";
import InfluencerRequirements from '../../components/Influencer/Requirements/InfluencerRequirements';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation, fetchActivationRequirements} from "../../actions/campaigns";
import * as ActivationSelectors from '../../selectors/activations';
import {getCurrentChannel, getCurrentUser} from "../../selectors/presence";
import {fetchPresence} from "../../actions/presence";
import {translate} from "../../actions/i18n";

const mapStateToProps = (state, props) => {
    const campaignId = props.campaignId || null;
    const campaign = props.campaignId ? (state.campaignsById.items[campaignId] || DEFAULT_CAMPAIGN_STATE) : DEFAULT_CAMPAIGN_STATE;
    const {useMobileNav, useAutoScroll} = props;


    const activation = ActivationSelectors.getActivation(state, props);
    const userId = (activation || {}).user_id;

    return {
        channel: getCurrentChannel(state, props),
        campaign,
        activation,
        useMobileNav,
        useAutoScroll,
        userId,
        interactions: ActivationSelectors.getActivationInteractions(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCampaign: (id) => dispatch(fetchCampaign(id)),
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
        fetchPresence: () => dispatch(fetchPresence()),
        fetchUserPersonalProfile: (userId) => dispatch(createUserInteraction(userId, 'PersonalProfileRequest', {})),
        translate: (key, defaultValue) => dispatch(translate(key, defaultValue)),
    }
};

const ConnectedInfluencerRequirements = connect(mapStateToProps, mapDispatchToProps)(InfluencerRequirements);

export default ConnectedInfluencerRequirements;
