import React from 'react';
import {capitalizeFirstLetter} from "../../../../utilities";
import AudienceDonutChart from "../../../Chart/Audience/AudienceDonutChart";
import AudienceBarChart from "../../../Chart/Audience/AudienceBarChart";
import AudienceCategoryChart from "../../../Chart/Audience/AudienceCategoryChart";

export default function ({socialdata}) {

    const getRawSocialDataAudienceField = (field) => {
        const sd = socialdata;
        if (!sd) return null;
        const followers = sd.audience_followers;
        if (!followers) return null;
        const data = followers.data;
        if (!data) return null;

        const rawValues = data[field];
        if (!rawValues) return null;
        return rawValues;

    }

    const parseSocialDataRaw = (rawValues) => {
        let out = {};
        for (const row of rawValues) {
            const rawTitle = row.name || row.code;
            const title = capitalizeFirstLetter(rawTitle.toLowerCase());
            const val = row.weight;
            out[title] = val;
        }

        return out;
    }

    const getSocialDataAudienceField = (field) => {
        const rawValues = getRawSocialDataAudienceField(field);
        if (!rawValues) return null;
        return parseSocialDataRaw(rawValues);

    }

    const wrapSocialDataChart = ({chart, title}) => {
        return (
            <div className="widget">
                <div className="title">
                    <h2>{title}</h2>
                </div>
                <div className="content">
                    {chart}
                </div>
            </div>

        );
    }

    const renderSocialDataGenderChart = () => {
        const data = getSocialDataAudienceField('audience_genders');
        if (!data) return null;

        return wrapSocialDataChart({
            title: 'Audience Gender',
            chart: <AudienceDonutChart data={data} title={"Audience Gender"} height={275} />
        });
    }

    const renderSocialDataAgeChart = () => {
        const data = getSocialDataAudienceField('audience_ages');
        if (!data) return null;

        return wrapSocialDataChart({
            title: 'Audience Age',
            chart: <AudienceBarChart data={data} title={'Audience Age'} height={275} />
        })
    }

    const renderSocialDataBrandAffinitiesChart = () => {
        const data = getSocialDataAudienceField('audience_brand_affinity');
        if (!data) return null;
        return wrapSocialDataChart({
            title: 'Audience Brands',
            chart: <AudienceCategoryChart data={data} limit={8 }/>
        });
    }

    const renderSocialDataLanguagesChart = () => {
        const data = getSocialDataAudienceField('audience_languages');
        if (!data) return null;
        return wrapSocialDataChart({
            title: 'Audience Language',
            chart: <AudienceCategoryChart data={data} limit={8 }/>
        });
    }

    const renderSocialDataEthnicitiesChart = () => {
        const data = getSocialDataAudienceField('audience_ethnicities');
        if (!data) return null;
        return wrapSocialDataChart({
            title: 'Audience Ethnicity',
            chart: <AudienceCategoryChart data={data} limit={8 }/>
        });
    }

    const renderSocialDataInterestsChart = () => {
        const data = getSocialDataAudienceField('audience_interests');
        if (!data) return null;
        return wrapSocialDataChart({
            title: 'Audience Interests',
            chart: <AudienceCategoryChart data={data} limit={8 }/>
        });
    }

    const renderSocialDataCountriesChart = () => {
        const geo = getRawSocialDataAudienceField('audience_geo');
        if (!geo) return null;
        const countries = geo.countries;
        if (!countries) return null;
        const data = parseSocialDataRaw(countries);
        return wrapSocialDataChart({
            title: 'Audience Countries',
            chart: <AudienceCategoryChart data={data} limit={8} />
        })
    }
    const renderSocialDataCitiesChart = () => {
        const geo = getRawSocialDataAudienceField('audience_geo');
        if (!geo) return null;
        const cities = geo.cities;
        if (!cities) return null;
        const data = parseSocialDataRaw(cities);
        return wrapSocialDataChart({
            title: 'Audience Cities',
            chart: <AudienceCategoryChart data={data} limit={8} />
        })
    }
    const renderSocialDataStatesChart = () => {
        const geo = getRawSocialDataAudienceField('audience_geo');
        if (!geo) return null;
        const states = geo.states;
        if (!states) return null;
        const data = parseSocialDataRaw(states);
        return wrapSocialDataChart({
            title: 'Audience States',
            chart: <AudienceCategoryChart data={data} limit={8} />
        })
    }

    const renderSocialDataCredibilityChart = () => {
        const sd = socialdata;
        if (!sd) return null;

        const followers = sd.audience_followers;
        const likers = sd.audience_likers;
        const commenters = sd.audience_commenters;

        let data = {};

        if (followers && followers.success && followers.data && followers.data.audience_credibility) {
            data['Followers'] = followers.data.audience_credibility;
        }

        if (likers && likers.success && likers.data && likers.data.audience_credibility) {
            data['Likers'] = likers.data.audience_credibility;
        }

        if (commenters && commenters.success && commenters.data && commenters.data.audience_credibility) {
            data['Commenters'] = commenters.data.audience_credibility;
        }

        if (Object.keys(data).length === 0) {
            return null;
        }

        return wrapSocialDataChart({
            title: 'Audience Credibility',
            chart: <AudienceBarChart data={data} title={'Audience Credibility'} height={275} />
        });
    }




    const renderSocialDataCharts = () => {

        return [
            renderSocialDataGenderChart(),
            renderSocialDataAgeChart(),
            renderSocialDataCredibilityChart(),
            renderSocialDataCountriesChart(),
            renderSocialDataCitiesChart(),
            renderSocialDataStatesChart(),
            renderSocialDataInterestsChart(),
            renderSocialDataBrandAffinitiesChart(),
            renderSocialDataEthnicitiesChart(),
            renderSocialDataLanguagesChart(),
        ].filter(chart => !!chart);
    }

    return (

        <div className={'user-detail-body-stats generic-profile-pane-wrapper'}>
            {renderSocialDataCharts()}
        </div>
    );
}
