/**
 * Created by bkanber on 8/7/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class ContributeDashProfileImages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            idsToRemove: [],
        };

        this.handleImageError = this.handleImageError.bind(this);
        this.handleRemoveImage = this.handleRemoveImage.bind(this);
    }

    handleImageError(event) {
        if (typeof this.props.onImageError === 'function') {
            this.props.onImageError(event);
        }
        event.target.style.display = 'none'
    }

    handleRemoveImage(id) {

        // add id to array of idsToRemove
        this.setState({idsToRemove: [...this.state.idsToRemove, id]});

        // get new array of user's rep images, not including those to be removed
        const newImageIds = this.props.imageIds.filter(imageId => {
            return this.state.idsToRemove.indexOf(imageId) === -1;
        });

        // forced removal of id -- necessary to avoid race condition
        const index = newImageIds.indexOf(id);
        newImageIds.splice(index, 1);

        // pass the updated array of rep image ids to parent where form lives
        this.props.onChangeImage(newImageIds);

        if (this.props.onRemoveImage) {
            this.props.onRemoveImage(id);
        } else {
            // remove image tile from the DOM
            document.getElementById(`rep-image-${id}`).remove();
        }
    }

    renderProfileImages() {

        const images = this.props.images.reverse();

        const limit = this.props.limit;

        if (!images) return null;


        const renderImage = (image) => {

            return (
                <div className="profile-image-tile" id={`rep-image-${image.id}`} key={`rep-image-${image.id}`}>
                    <img
                        key={image.id}
                        className="profile-image"
                        src={image.url ? image.url : image.original_url}
                        onError={this.handleImageError}
                    />
                    <a
                        role="button"
                        className="v3 close primary bg-white"
                        onClick={() => {
                            this.handleRemoveImage(image.id);
                        }}
                    >
                    </a>
                </div>

            )
        };

        const imageTags = images
            .slice(0, limit)
            .map((image) => renderImage(image));

        return (
            <div className="profile-images-row">{imageTags}</div>
        );

    }

    render() {
        return this.renderProfileImages();
    }
}
ContributeDashProfileImages.propTypes = {
    limit: PropTypes.number,
    images: PropTypes.array,
    onChangeImage: PropTypes.func,
    onRemoveImage: PropTypes.func,
};

ContributeDashProfileImages.defaultProps = {
    limit: 10,
};
