export const EMAIL_LABELS = [
    'Needs Attention',
    'Replied',
    'Customer Support',
    'Tech Support',
    'Unresolved',
    'In Progress',
    'Resolved',
    'Finished',
    'Possible Spam',
    'Spam'
];

export const isEmailValid = email => !!email.match(/.*@.*\..*/);