import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BasicCell from './Basic';
import {makeMomentFromDate} from "../../../utilities";
import moment from 'moment';

export default class DateCell extends Component {

    static defaultProps = {
        format: 'MMM Do, YYYY',
    };

    static propTypes = {
        row: PropTypes.object.isRequired,
        column: PropTypes.object.isRequired,
        onClick: PropTypes.func,
        style: PropTypes.object,
        value: PropTypes.any,
        format: PropTypes.string,
    };

    getValue() {
        const {value, format} = this.props;
        let ts;
        if (!value) return '-';

        // Check if we already have a moment object.
        if (typeof value.format !== 'undefined') {
            return value.format(format);
        }

        if (typeof value === 'string') {
            ts = moment(value);
        } else if (typeof value === 'object' && typeof value.date !== 'undefined') {
            ts = makeMomentFromDate(value);
        } else {
            return '-';
        }

        if (!ts) {
            return '-';
        }

        return ts.format(format);
    }

    render() {

        return <BasicCell
            row={this.props.row}
            column={this.props.column}
            value={this.getValue()}
            style={this.props.style}
            onClick={this.props.onClick}
        />

    }


}

