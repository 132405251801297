import React, {useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {getTeams} from "../../selectors/teams";
import {createChannelInteraction} from "../../actions/channel";
import Checkbox from "../Common/Form/Checkbox";
import {preventDefaultIfPossible} from "../../utilities";

function ManageUserTeams({teams, teamIds, userId, createChannelInteraction, onUpdated}) {
    const teamsCount = (teams || []).length;
    const [isWorking, setIsWorking] = useState(false);
    const userIsInTeam = (teamId) => teamIds.map(id => parseInt(id, 10)).indexOf(parseInt(teamId, 10)) > -1;

    const handleCheckboxClick = async (teamId, event) => {
        preventDefaultIfPossible(event);
        setIsWorking(true);
        const action = userIsInTeam(teamId) ? 'remove' : 'add';
        await createChannelInteraction('AddUsersToTeam', {
            user_ids: [userId],
            team_ids: [teamId],
            action
        });
        setIsWorking(false);
        if (onUpdated && typeof onUpdated === 'function') {
            onUpdated();
        }
    };

    return (
        <div className="fake-table">
            {(teams || []).map((team, tIndex) => (
                <div key={team.id} className={"fake-li padded " + ((tIndex < teamsCount-1) ? 'bottom-border' : '')}>
                    <Checkbox
                        checked={userIsInTeam(team.id)}
                        label={team.name}
                        icon={isWorking ? <i className="v3 icon spinner" /> : undefined}
                        onClick={handleCheckboxClick.bind(this, team.id)}
                    />
                </div>
            ))}

        </div>
    );
}

const mapStateToProps = (state, props) => ({
    teams: getTeams(state, props),
});

const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    createChannelInteraction: createChannelInteraction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserTeams);