import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Number extends Component {

    static defaultProps = {
        classes: [],
        style: {},
        readOnly: false,
        step: 1,

    };

    static propTypes = {
        classes: PropTypes.array,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        style: PropTypes.object,
        readOnly: PropTypes.bool,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
    };

    render() {
        let classes = ['form-control', ...this.props.classes];
        const rawValue = this.props.value;
        // If null or undefined, use a ''
        const value = (rawValue === null || typeof rawValue === 'undefined') ? '' : rawValue;

        return (
            <input
                type="number"
                className={classes.join(' ')}
                placeholder={this.props.placeholder}
                name={this.props.name}
                value={value}
                onChange={this.props.onChange}
                style={this.props.style}
                readOnly={this.props.readOnly}
                min={this.props.min}
                max={this.props.max}
                step={this.props.step}
            />
        );
    }
}