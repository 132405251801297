/**
 * Created by bkroger on 5/2/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PaymentDetailsForm from '../../../Activation/PaymentDetailsForm';
import Alert from '../../../Common/Alert';


class PaymentDetailsTemplate extends Component {

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        refreshActivation: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        translate: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.handleSaved = this.handleSaved.bind(this);
    }

    async handleSaved() {

        if (this.props.refreshActivation) {
            await this.props.refreshActivation();
        }
    }

    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }
    render() {

        if (this.isBlockedByNDA()) {
            return this.renderNDAAlert();
        }

        const _t = this.props.translate;
        return (
            <div className="influencer-page-template verify-payment-details">
                <div className="widget " data-collapsed="0">

                    <div className="excerpt hidden">
                        <p>Click to open...</p>
                    </div>

                    <div className="content padded clearfix">

                        <h4 className="v3 bold action-pane-title">{_t('campaign.payment.title', 'Update Payment Details')}</h4>

                        <p className="v3 light h7 pane-info">{_t('campaign.payment.info', 'Please confirm your payment information below. All fields required unless otherwise indicated.')}</p>

                        <PaymentDetailsForm
                            activation={this.props.activation}
                            onSaved={this.handleSaved.bind(this)}
                            style={{marginTop: 20}}
                            usePadding={false}
                            createInteraction={this.props.createInteraction}
                        />
                    </div>
                </div>
            </div>
        )
    }

}

export default PaymentDetailsTemplate;