import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RechartsDonutChart from "../RechartsDonutChart";
import {formatNumber} from "../../../utilities";

class AudienceDonutChart extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        title: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        colors: PropTypes.array,
    };

    /**
     * Converts an object to an array of objects (name/value)
     */
    getData() {
        let out = [];
        for (let key in this.props.data) {
            out.push({name: key, value: this.props.data[key]});
        }
        return out;
    }

    render() {
        return (
            <RechartsDonutChart
                colors={this.props.colors}
                width={this.props.width}
                height={this.props.height}
                data={this.getData()}
                title={this.props.title}
                tooltipFormatter={value => formatNumber(100 * value, 1) + '%'}
            />
        );
    }
}
export default AudienceDonutChart;