/**
 * Created by bkroger on 6/19/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TimelineHeader from  './Header';
import EventDivider from './EventDivider';
import TimelineMessageRow from './MessageRow';
import ActionItem from './ActionItem';

export default class TimelineWrapper extends Component {

    static defaultProps = {
        showHeader: false,
        style: {},
    };

    static propTypes = {
        showHeader: PropTypes.bool,
        style: PropTypes.object,
    };

    render() {

        return (
            <div className="timeline-history-wrapper" style={this.props.style}>
                {this.props.showHeader && <TimelineHeader />}
                {this.props.children}
            </div>
        );
    }

}