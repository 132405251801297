/**
 * Created by visgotti on 7/13/17.
 */

import {indexArrayById} from '../utilities';
import merge from 'lodash/merge';

import {
    REQUEST_SOCIAL_LISTENER, RECEIVE_SOCIAL_LISTENER,
    RECEIVE_SOCIAL_LISTENERS,
    RECEIVE_SOCIAL_LISTENER_POSTS,
    RECEIVE_SOCIAL_LISTENER_SOCIALS,
    RECEIVE_USER_SOCIALS,
    REQUEST_SOCIAL_REPORT, RECEIVE_SOCIAL_REPORT,
    REQUEST_SOCIAL_POSTS, RECEIVE_SOCIAL_POSTS,
    CREATE_SOCIAL_LISTENER, CREATED_SOCIAL_LISTENER,
    REQUEST_SOCIAL_LISTENER_REPORT, RECEIVE_SOCIAL_LISTENER_REPORT
} from '../actions';
import {REQUEST_SOCIAL_LISTENER_POSTS, REFRESHED_SOCIAL_LISTENER, REFRESH_SOCIAL_LISTENER, REQUEST_SOCIAL_LISTENER_SOCIALS } from "../actions/socials";

export function socialsById(state = {}, action) {
    switch (action.type) {

        case RECEIVE_SOCIAL_REPORT:
            return {...state,
                [action.socialId]: {
                    ...state[action.socialId],
                    isFetchingReport: false,
                    lastUpdatedReport: action.receivedAt,
                    report: action.report
                }
            };

        case REQUEST_SOCIAL_REPORT:
            return {...state, [action.socialId]: {...state[action.socialId], isFetchingReport: true}};

        case RECEIVE_USER_SOCIALS:
            return merge({}, state, indexArrayById(action.socials));

        case RECEIVE_SOCIAL_LISTENER_SOCIALS:
            return merge({}, state,  indexArrayById(action.socials));

        case RECEIVE_SOCIAL_LISTENER_POSTS: {
            const socials = action.posts.map(s => s.social);
            return merge({}, state, indexArrayById(socials));
        }

        case REQUEST_SOCIAL_POSTS:
            return {...state, [action.socialId]: {...state[action.socialId], postIds: [], isFetchingPosts: true}};

        case RECEIVE_SOCIAL_POSTS:
            return {
                ...state,
                [action.socialId]: {
                    ...state[action.socialId],
                    postIds: action.posts.map(p => p.id),
                    isFetchingPosts: false,
                    lastUpdatedPosts: action.receivedAt
                }
            };

        default:
            return state;
    }
}

export function socialPostsById(state = {}, action) {
    switch (action.type) {
        case RECEIVE_SOCIAL_LISTENER_POSTS:
        case RECEIVE_SOCIAL_POSTS:
            return merge({}, state, indexArrayById(action.posts));
        default:
            return state;
    }
}

export function socialListenersById(state = {}, action) {
    switch (action.type) {

        case REFRESHED_SOCIAL_LISTENER:
            return {
                ...state,
                [action.listenerId]: {
                    ...state[action.listenerId],
                    isRefreshing: false,
                    didInvalidate: true
                }
            };

        case REFRESH_SOCIAL_LISTENER:
            return {
                ...state,
                [action.listenerId]: {
                    ...state[action.listenerId],
                    isRefreshing: true
                }
            };

        case RECEIVE_SOCIAL_LISTENER_REPORT:
            return {
                ...state,
                [action.listenerId]: {
                    ...state[action.listenerId],
                    isFetchingReport: false,
                    lastUpdatedReport: action.receivedAt,
                    report: action.report
                }
            };

        case REQUEST_SOCIAL_LISTENER_REPORT:
            return {
                ...state,
                [action.listenerId]: {
                    ...state[action.listenerId],
                    isFetchingReport: true
                }
            };

        case RECEIVE_SOCIAL_LISTENER_POSTS: {
            const postIds = action.posts.map(p => p.id);
            const socialIds = action.posts.map(p => p.social_id);
            const listener = state[action.listenerId];

            return {
                ...state,
                [action.listenerId]: {
                    ...listener,
                    postIds,
                    socialIds,
                    isFetching: false,
                    isFetchingPosts: false,
                    lastUpdatedPosts: action.receivedAt
                }
            };
        }

        case REQUEST_SOCIAL_LISTENER_POSTS:
            return {...state, [action.listenerId]: {...state[action.listenerId], isFetchingPosts: true}};

        case RECEIVE_SOCIAL_LISTENER_SOCIALS: {
            const socialIds = action.socials.map(s => s.id);
            const listener = state[action.listenerId];

            return {
                ...state,
                [action.listenerId]: {
                    ...listener,
                    socialIds,
                    isFetching: false,
                    isFetchingPosts: false,
                    lastUpdatedPosts: action.receivedAt
                }
            };
        }

        case REQUEST_SOCIAL_LISTENER_SOCIALS:
            return {...state, [action.listenerId]: {...state[action.listenerId], isFetchingPosts: true}};

        case RECEIVE_SOCIAL_LISTENERS:
            return merge({}, state, indexArrayById(action.listeners));
            break;

        case CREATED_SOCIAL_LISTENER:
        case RECEIVE_SOCIAL_LISTENER:
            return {
                ...state,
                [action.listenerId]: {
                    ...action.listener,
                    isFetching: false,
                    lastUpdated: action.receivedAt,
                    report: {}
                }
            };
            break;

        case REQUEST_SOCIAL_LISTENER:
            return {...state, [action.listenerId]: {
                id: action.listenerId,
                isFetching: true,
                postIds: [],
                socialIds: [],
                report: {}
            }};
            break;

        default:
            return state;
    }
}
