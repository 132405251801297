/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatNumber, formatNumberK} from '../../../utilities';
import {calculateEmv, calculateEmvByNetwork, EMV_TIDAL_DEFAULTS} from "../../../utilities/emv";
import RechartsDonutChart from "../../Chart/RechartsDonutChart";
import ChartWrapper from "../../Chart/ChartWrapper";
import {socialNameForType} from "../../../utilities/social";

export default class CampaignMediaValueByNetworkDonutChart extends Component {

    getStat() {
        const stats = this.props.stats || [];
        if (stats.length === 0) return null;
        return stats[stats.length - 1];
    }

    getTotal() {
        const stat = this.getStat();
        if (!stat) return null;

        const {total} = calculateEmv(stat, this.props.emvParams);
        return total;
    }

    getData() {
        const stat = this.getStat();
        if (!stat) return null;

        const rawData = calculateEmvByNetwork(stat, this.props.emvParams);
        const out = [];

        // foreach key in rawData, find the niceName, add an object with name and value to out
        for (const key in rawData) {
            const niceName = socialNameForType(key);
            const value = rawData[key].total;
            if (value > 0) {
                out.push({name: niceName, value: value});
            }
        }

        return out;

    }

    renderChart() {
        const data = this.getData();
        console.log(data);
        if (!data) return null;

        return <RechartsDonutChart
            data={data}
            title="Media Value by Network"
            labelFormatter={(value) => `$${formatNumberK(value)}`}
            labelPercent={false}
            tooltipFormatter={(value) => `$${formatNumber(value)}`}
            colors={this.props.colors}
        />

    }

    render() {
        const total = this.getTotal();
        const titleBadge = total !== null ? `$${formatNumberK(total)}` : null;

        return (

            <ChartWrapper
                title="Media Value by Network"
                titleBadge={titleBadge}
                icon="dollar-sign"
                chart={this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
            />

        );
    }
}

CampaignMediaValueByNetworkDonutChart.defaultProps = {
    emvParams: EMV_TIDAL_DEFAULTS
};

CampaignMediaValueByNetworkDonutChart.propTypes = {
    emvParams: PropTypes.object,
    stats: PropTypes.array,
    colors: PropTypes.array,
};
