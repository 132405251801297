import React, {useState, useEffect, useMemo} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Link, Redirect} from 'react-router-dom';
import {getCurrentChannel, getCurrentUser, getPresenceTeam} from "../../../selectors/presence";
import { getCampaignEndOrDueDate, magicFilterBriefs } from "../../../utilities/mac";
import {getCampaigns} from "../../../selectors/campaigns";
import {translate} from "../../../actions/i18n";

function CampaignTile({campaign, translate}) {
    const _t = (k, v) => translate ? translate(k, v) : v;
    const [redirTo, setRedir] = useState(null);
    const imageUrl = campaign.image || campaign.fallback_image_url || 'https://dlbezd06tkli6.cloudfront.net/m/89d2c643174919bbbe5adb7404d10f1023538e06.jpg';
    const tileStyle = { backgroundImage: `url(${imageUrl})` };
    const renderDueDate = () => {
        const campaignEndDate = getCampaignEndOrDueDate(campaign);
        if (!campaignEndDate) {
            return _t('creator.words.open_ended', 'Open-Ended');
        }

        return _t('creator.words.due', 'Due') + ': ' + campaignEndDate.format('MMM Do');
    };
    const renderTag = () => {
        return null; // Don't render tags for now
    };

    if (redirTo) {
        return <Redirect to={redirTo} push={true} />;
    }

    return (
        <div className="brief campaign-tile" onClick={() => setRedir(`/contribute/do/page/dash/brief?id=${campaign.id}`)}>
            <div className="brief-content" style={tileStyle}>
                <div className="brief-transparency">
                    <div className="center-title">
                        <h3>{campaign.name}</h3>

                    </div>
                </div>
            </div>
            <table className="brief-extras">
                <tbody>
                <tr>
                    <td>
                        <span className="icon-bubble">
                            <i className="v3 icon calendar" />
                        </span>
                    </td>
                    <td>{renderDueDate()}</td>
                </tr>
                <tr>
                    <td>
                        <span className="icon-bubble">
                            <i className="v3 icon fa-user-plus" />
                        </span>
                    </td>
                    <td>{campaign.total_activations} {_t('creator.words.participants', 'Participants')}</td>
                </tr>
                <tr>
                    <td></td>
                    <td>{renderTag()}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}

function DashboardBriefs({user, campaigns, team, channel, translate}) {
    const filteredCampaigns = useMemo(function () {
        let teamToUse = team;
        let filterByTeam = true;
        if (channel.filter_teams === false) {
            teamToUse = null;
            filterByTeam = false;
        }
        console.log("Magic filter briefs being called with team: ", teamToUse);
        return magicFilterBriefs(campaigns, user, teamToUse, true, filterByTeam);
    }, [campaigns, user, team, channel]);

    const campaignCount = (filteredCampaigns || []).length;

    const briefsWord = translate('creator.words.briefs', 'Briefs');
    const seeAllWord = translate('creator.words.see_all', 'See All');
    const descBriefs = translate('creator.sections.briefs.description', 'Participate in the latest briefs and campaigns here.');

    return (
        <div className="dash-section">
            <div className="section-header">
                <h1 className="blue-indent">{briefsWord}
                    <span className="large badge">{campaignCount}</span>
                    <Link to="/contribute/do/page/dash/briefs" className="seeall">{seeAllWord}<i className="v3 icon fa-arrow-right" /> </Link>
                </h1>
                <p className="section-subtitle">{descBriefs}</p>
            </div>

            <div className="section-content">


                <div className="briefs">
                    {(filteredCampaigns || []).slice(0, 3).map(campaign => <CampaignTile campaign={campaign} key={campaign.id} translate={translate} />)}
                </div>

            </div>

        </div>
    );
}

export default connect(function (state, props) {
    return {
        user: getCurrentUser(state, props),
        campaigns: getCampaigns(state, props),
        team: getPresenceTeam(state, props),
        channel: getCurrentChannel(state, props)
    };
}, function (dispatch) {
    return bindActionCreators({
        translate: translate
    }, dispatch);
})(DashboardBriefs);