import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Button extends Component {

    static defaultProps = {
        classes: [],
        style: {},
    };

    static propTypes = {
        classes: PropTypes.array,
        onClick: PropTypes.func,
        style: PropTypes.object,
        content: PropTypes.node.isRequired,
    };

    render() {

        let defaultClasses = ['btn', 'tidal-btn'];
        let optionalClasses = this.props.classes;
        let classes = [...defaultClasses, ...optionalClasses];

        return <a
            role="button"
            onClick={this.props.onClick}
            className={classes.join(' ')}
            style={this.props.style}
        >{this.props.content}</a>
    }
}