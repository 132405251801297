import React, {useState} from 'react';
import Toolbox from "../../Common/Toolbox";
import Form from "../../Common/Form/Form";
import Tooltip from "../../Common/Tooltip";

function PortfolioInteractivity({item, addRating, addNote}) {
    const [showNoteForm, setShowNoteForm] = useState(false);
    const [didVoteUp, setDidVoteUp] = useState(window.localStorage.getItem(`public-voted-up-${item.id}`) === '1');
    const [didVoteDown, setDidVoteDown] = useState(window.localStorage.getItem(`public-voted-down-${item.id}`) === '1');
    const [didLeaveNote, setDidLeaveNote] = useState(false);
    const [noteText, setNoteText] = useState('');
    const handleVoteUp = () => {
        addRating(item.id, 5);
        window.localStorage.setItem(`public-voted-up-${item.id}`, '1');
        setDidVoteUp(true);
    };
    const handleVoteDown = () => {
        addRating(item.id, 1);
        window.localStorage.setItem(`public-voted-down-${item.id}`, '1');
        setDidVoteDown(true);
    }
    const didVoteStyle = {color: 'white', fontWeight: 'bold'}
    const handleSubmitNote = () => {
        addNote(item.id, noteText);
        setNoteText('');
        setShowNoteForm(false);
        setDidLeaveNote(true);
    };
    const renderNoteBox = () => {
        if (!showNoteForm) {
            return null;
        }
        return (
            <Toolbox
                title="Add a Note"
                onClose={() => setShowNoteForm(false)}
                addlClasses="toolbox-fixed toolbox-sm"
                content={
                    <Form
                        values={{note: noteText}}
                        onFieldChange={(key, value) => setNoteText(value)}
                        fields={[
                            {
                                type: 'textarea',
                                name: 'note',
                                title: 'Leave a Note',
                            },
                            {
                                type: 'button',
                                name: 'submit',
                                title: 'Add Note',
                                options: {
                                    hideTitle: 'true',
                                    classes: ['v3 medium', 'btn-primary'],
                                    onClick: handleSubmitNote
                                }
                            }
                        ]}
                    />
                }
            />
        )
    }
    const commentCount = item.notes.length + (didLeaveNote ? 1 : 0);
    const commentOrComments = commentCount === 1 ? 'Comment' : 'Comments';
    const votesToKeep = (item.scores || []).filter(record => record.score === 5);
    const votesToRemove = (item.scores || []).filter(record => record.score === 1);
    let voteKeepText = 'Vote to Keep';
    let voteRemoveText = 'Vote to Remove';

    if (didVoteUp) {
        voteKeepText = 'You Voted to Keep';
    } else if (votesToKeep.length > 0) {
        voteKeepText = `${votesToKeep.length} Voted to Keep`;
    }

    if (votesToKeep.length > 0) {
        voteKeepText = <Tooltip
            html={
            <div className="fake-table">
                {votesToKeep.map((record, index) => (
                    <div className={"fake-li " + ((index < votesToKeep.length-1) ? 'bottom-border' : '')} key={record.id}><strong>{record.scorer.name}</strong> Voted to Keep</div>
                ))}
            </div>
        }
        >{voteKeepText}</Tooltip>
    }

    if (didVoteDown) {
        voteRemoveText = 'You Voted to Remove';
    } else if (votesToRemove.length > 0) {
        voteRemoveText = `${votesToRemove.length} Voted to Remove`;
    }

    if (votesToRemove.length > 0) {
        voteRemoveText = <Tooltip
            html={
                <div className="fake-table">
                    {votesToRemove.map((record, index) => (
                        <div className={"fake-li " + ((index < votesToRemove.length - 1) ? 'bottom-border' : '')} key={record.id}><strong>{record.scorer.name}</strong> Voted to Remove</div>
                    ))}
                </div>
            }
        >{voteRemoveText}</Tooltip>
    }

    return (
        <>
            {renderNoteBox()}
            <div className="interactive-wrapper">

                <div className="interactive">
                    <div className="menu-item">
                        <a role="button" onClick={handleVoteUp} style={didVoteUp ? didVoteStyle : undefined}><i className="v3 icon fa-thumbs-up" /> {voteKeepText}</a>
                    </div>
                    <div className="menu-item">
                        <a role="button" onClick={handleVoteDown} style={didVoteDown ? didVoteStyle : undefined}><i className="v3 icon fa-thumbs-down" /> {voteRemoveText}</a>
                    </div>
                    <div className="menu-item">
                        <a role="button" onClick={() => setShowNoteForm(!showNoteForm)}><i className="v3 icon comments" /> {commentCount} {commentOrComments}</a>
                    </div>

                </div>

            </div>
        </>
    );

}

export default PortfolioInteractivity;