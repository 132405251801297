/**
 * Created by bkroger on 7/19/18.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../../../actions/activations";
import {fetchActivation} from "../../../../actions";
import PropTypes from 'prop-types';
import Alert from '../../../Common/Alert';
import Form from '../../../Common/Form/Form';
import Button from '../../../Common/Form/Button';
import RaiseIssueForm from '../../../Activation/RaiseIssueForm';
import Toolbox from "../../../Common/Toolbox";

class ReceiveProducts extends Component {

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        fetchActivation: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
    };

    state = {
        isConfirming: false,
        didConfirm: false,
        createIssue: false,
    };

    isShipped() {
        return this.props.activation.status.is_product_shipped;
    }

    renderAlert() {

        if (this.isShipped()) {
            return <Alert
                classes={['info']}
                content={'Your product samples have been shipped! Your tracking number is below. When you receive your product samples, please click the button indicating receipt.'}
            />
        } else {
            return <Alert
                classes={['info']}
                content={'Your product samples have not yet been shipped. You should receive an email when they do. Come back to this page when you receive your samples to confirm receipt.'}
            />
        }

    }

    handleReceiveProduct() {

        this.setState({isConfirming: true});
        this.props.createInteraction(this.props.activation.id, 'ReceiveProduct', {})
            .then(() => this.props.fetchActivation(this.props.activation.id))
            .then(() => this.setState({isConfirming: false, didConfirm: true}));

    }

    renderIssuesPopup() {
        if (this.state.createIssue) {
            return this.renderCreateIssue();
        }
    }

    renderCreateIssue() {
        return <Toolbox
            onClose={() => this.setState({createIssue: false})}
            style={{
                position: 'fixed',
                top: 60,
                left: '50%',
                width: 500,
                maxWidth: 'calc(100vw - 30px)',
                height: 400,
                zIndex: 300,
                WebkitTransform: 'translateX(-50%)',
                msTransform: 'translateX(-50%)',
                transform: 'translateX(-50%)',
            }}
            title={'Create an Issue'}
            content={
                <RaiseIssueForm
                    activation={this.props.activation}
                    onClose={() => this.setState({createIssue: false})}
                />
            }
        />
    }

    renderForm() {

        if (!this.isShipped()) {
            return null;
        }

        const values = {
            tracking_number: (this.props.activation.metadata || {}).tracking_number || 'Tracking Number Unavailable'
        };

        const {activation} = this.props;

        const {isConfirming, didConfirm} = this.state;

        const productReceived = activation.status.is_product_received;

        let successBtnTxt = "My Shipment Arrived";

        if (isConfirming) {
            successBtnTxt = 'Confirming Receipt...';
        }

        if (didConfirm || productReceived) {
            successBtnTxt = 'Confirmed!';
        }

        return <Form
            style={{marginTop: 20}}
            values={values}
            usePadding={false}
            fields={[
                {
                    type: 'text',
                    readOnly: true,
                    name: 'tracking_number',
                    title: 'Shipping Tracking Number',
                    help: 'You may use the tracking number above to track your shipment.',
                    placeholder: 'Tracking Number Unavailable',
                },
                {
                    type: 'generic',
                    content: (
                        <div className="form-group clearfix">
                            <Button
                                content={successBtnTxt}
                                classes={['v3', 'medium', 'bold', 'btn-primary', (isConfirming || productReceived ? 'disabled' : '')]}
                                style={{float: 'left'}}
                                onClick={!productReceived ? this.handleReceiveProduct.bind(this) : null}
                            />

                            <Button
                                content={'I Have an Issue'}
                                classes={['v3', 'medium', 'bold', 'btn-secondary']}
                                style={{float: 'right'}}
                                onClick={() => this.setState({createIssue: true})}
                            />
                        </div>
                    )
                }

            ]}
        />

    }

    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }
    render() {

        if (this.isBlockedByNDA()) {
            return this.renderNDAAlert();
        }

        return(
            <div className="influencer-page-template receive-products">
                <div className="widget">

                    <div className="content padded">
                        <h4 className="v3 bold action-pane-title">Track Product Samples</h4>
                        {this.renderAlert()}
                        {this.renderForm()}
                    </div>

                </div>

                {this.renderIssuesPopup()}

            </div>
        )
    }

}


const mapDispatchToProps = dispatch => {
    return {
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };
};

export default connect(null, mapDispatchToProps)(ReceiveProducts);
