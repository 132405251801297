import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Toolbox from "../../../Common/Toolbox";
import {getCurrentChannel, getCurrentUser} from "../../../../selectors/presence";
import {translate} from "../../../../actions/i18n";
import {DEFAULT_USER_STATE} from "../../../../reducers/users";
import {
    blockUser, createUserInteraction, fetchBadgesIfNeeded, fetchListsIfNeeded,
    fetchSocialPostsIfNeeded, fetchSocialReportIfNeeded,
    fetchUserActivationsIfNeeded, fetchUserActivationStatsIfNeeded,
    fetchUserIfNeeded, fetchUserInterestsIfNeeded, fetchUserListsIfNeeded, fetchUserPosts, fetchUserPostsIfNeeded,
    fetchUserProfileIfNeeded,
    fetchUserSocialsIfNeeded
} from "../../../../actions";
import {fetchAuthenticated} from "../../../../actions/auth";
import UserDetailStatsPaneContribute from "../../../User/Detail/StatsPaneContributeDash";

const WrappedStatsPane = connect(
    (state, ownProps) => {
        const {userId} = ownProps;
        const {campaignsById, postsById, activationsById, socialsById, socialPostsById, activationStatsById, listsById, badgesById} = state;
        const user = state.usersById[userId] || DEFAULT_USER_STATE;
        const posts = user.postIds.map(postId => postsById[postId]);
        const activations = user.activationIds.map(aid => activationsById[aid]);
        const activationStats = user.activationIds.map(activationId => activationStatsById[activationId]);

        // Socials with posts attached.
        const socials = user.socialIds.map(sid => {
            let social = socialsById[sid] || {};
            return {
                ...social,
                posts: (social.postIds || []) .map(postId => socialPostsById[postId])
            };
        });
        const channel = getCurrentChannel(state, ownProps);

        return {
            user,
            posts,
            activations,
            activationStats,
            socials,
            campaignsById,
            listsById,
            badgesById,
            channel
        };

    },
    (dispatch, ownProps) => {
        const {userId} = ownProps;
        const slot = window.Gator.getDashboardSlot();

        return {
            fetchUser: () => dispatch(fetchUserIfNeeded(userId)),
            fetchProfile: () => dispatch(fetchUserProfileIfNeeded(userId)),
            fetchActivations: () => dispatch(fetchUserActivationsIfNeeded(userId)),
            fetchSocials: () => dispatch(fetchUserSocialsIfNeeded(userId)),
            fetchSocialPosts: (socialId) => dispatch(fetchSocialPostsIfNeeded(socialId)),
            fetchPosts: () => dispatch(fetchUserPosts(userId)),
            fetchActivationStats: () => dispatch(fetchUserActivationStatsIfNeeded(userId)),
            fetchSocialReport: (socialId) => dispatch(fetchSocialReportIfNeeded(socialId)),
            fetchUserLists: () => dispatch(fetchUserListsIfNeeded(userId)),
            fetchInterests: () => dispatch(fetchUserInterestsIfNeeded(userId)),
            fetchAudience: () => dispatch(fetchAuthenticated(`/${slot}/api/user/${userId}/audience`)),
            blockUser: (userId, block) => dispatch(blockUser(userId, block)),
            fetchBadgesIfNeeded: () => dispatch(fetchBadgesIfNeeded()),
            fetchListsIfNeeded: () => dispatch(fetchListsIfNeeded()),
            createInteraction: (id, type, context) => dispatch(createUserInteraction(id, type, context)),
        }

    }
)(function (props) {
    return (
        <div className="generic-profile">
            <UserDetailStatsPaneContribute {...props} wrapperClass={"user-detail-body-stats generic-profile-pane-wrapper"} />
        </div>
    )
});

function ContributeAudienceAnalyticsStripe({user, translate}) {
    const [showPopup, setShowPopup] = React.useState(false);

    const sideTitle = translate('contribute.audience.stripe.title', 'Meet Your Audience');
    const linkTitle = translate('contribute.audience.stripe.link', "View your audience analytics");
    const stripeText = translate('contribute.audience.stripe.text', "Have you ever wondered who follows you on social?");
    const popupTitle = translate('contribute.audience.popup.title', "Your Audience");

    const renderPopup = () => {
        if (!showPopup) {
            return null;
        }

        return (
            <Toolbox
                addlClasses={"toolbox-fixed toolbox-lg"}
                title={popupTitle}
                content={<WrappedStatsPane userId={user.id} />}
                onClose={() => setShowPopup(false)}
            />
        );

    }
    return (
        <div className="homepage striped">
            {renderPopup()}
            <div className="container">
                <div className="row">

                    <div className="col-xs-12 col-md-4">
                        <p className="homepage-section-title thin">{sideTitle}</p>
                    </div>

                    <div className="col-xs-12 col-md-8">
                        <div className="right-wrap">
                            <div className="infobox">
                                <p className="lead">
                                    {stripeText} <a role="button" onClick={() => setShowPopup(!showPopup)}>{linkTitle}</a>.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>


    );
}

const mapStateToProps = (state, props) => {
    return {
        user: getCurrentUser(state, props),
    };
};

const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    translate: translate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContributeAudienceAnalyticsStripe);