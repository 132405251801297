import * as ActionTypes from '../actions/types';
import {modifyItem} from "../utilities/reducer";

const initialState = { };

const modifyQuery = (state, action, fieldName) => {
    const queryId = action.query.id || "default";
    return {
        ...state,
        [queryId]: {
            // In case it doesn't exist yet
            ...(action.query || {}),
            // Old query state
            ...state[queryId],
            id: queryId,
            isDirty: true,
            [fieldName]: action[fieldName],
            // Saves the last known value
            [`last_${fieldName}`]: (state[queryId] || {})[fieldName]
        }
    };

};

const requestPageResults = (state, action) => {
    const queryId = action.query.id || "default";
    const page = action.query.page || 0;

    return {
        ...state,
        [queryId]: {
            ...state[queryId],
            ...action.query,
            isFetching: true,
        }
    };

};
const receivePageResults = (state, action) => {
    const queryId = action.query.id || "default";
    const page = action.query.page || 0;
    const ids = (action.data || []).map(item => item._key || item.id).filter(id => !!id);

    return {
        ...state,
        [queryId]: {
            ...state[queryId],
            isDirty: false,
            isFetching: false,
            pages: {
                ...(state[queryId] || {}).pages,
                [page]: {
                    ids,
                    meta: action.meta
                }
            }
        }
    };

};

export const makeQueriesReducerFor = (prefix) => {
    return (state = initialState, action) => {
        const { type } = action;
        switch (type) {
            case prefix + ActionTypes.UPDATE_QUERY_PAGE:
                return modifyQuery(state, action, 'page');

            case prefix + ActionTypes.UPDATE_QUERY_SORT:
                return modifyQuery(state, action, 'sort');

            case prefix + ActionTypes.UPDATE_QUERY_FILTERS:
                return modifyQuery(state, action, 'filters');

            case prefix + ActionTypes.RECEIVE_QUERY:
                return receivePageResults(state, action);

            case prefix + ActionTypes.REQUEST_QUERY:
                return requestPageResults(state, action);

            default:
                return state;
        }
    };
};
