/**
 * Created by visgotti on 11/26/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import PostsHeaderCheckbox from './Header/Checkbox';
import PostsHeaderPostType from './Header/PostsStatus';
import PostsHeaderDropdownMenus from './Header/DropdownMenus';
import PostHeaderDisplayControls from './Header/DisplayControls';

class PostsHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container-fluid posts-header">
                <div className="row">
                    <div className="col-xs-12">
                        <span className="pull-left">
                            <PostsHeaderCheckbox
                                hits={this.props.postQuery.meta.hits}
                                allSelected={this.props.postQuery.allPostsSelected}
                                onSelectAll={this.props.selectAll}
                            />
                        </span>

                        <PostsHeaderPostType
                            changeFilter={this.props.changeFilter}
                            activeStatus={this.props.postQuery.filters.status}
                        />

                        <PostsHeaderDropdownMenus
                            currentSort={this.props.postQuery.sort}
                            changeSort={this.props.changeSort}
                            applyAction={this.props.applyAction}
                            showSort={this.props.currentCardView !== 'table' && this.props.currentCardView !== 'expanded-table'}
                        />

                        <PostHeaderDisplayControls
                            campaigns={this.props.campaigns}
                            changeFilter={this.props.changeFilter}
                            removeFilter={this.props.removeFilter}
                            resetFilters={this.props.resetFilters}
                            changeLimit={this.props.changeLimit}
                            filters={this.props.postQuery.filters}
                            limit={this.props.postQuery.limit}
                            badges={this.props.badges}
                            currentView={this.props.currentCardView}
                            changeView={this.props.changeView}
                            fetchPosts={this.props.fetchPosts}
                            disableCampaignField={this.props.disableCampaignField}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

PostsHeader.propTypes = {
    changeView: PropTypes.func,
    resetFilters: PropTypes.func,
    selectAll: PropTypes.func,
    changeFilter: PropTypes.func,
    changeLimit: PropTypes.func,
    removeFilter: PropTypes.func,
    fetchPosts: PropTypes.func,
    changeSort: PropTypes.func,
    applyAction: PropTypes.func,
    currentCardView: PropTypes.string,
    postQuery: PropTypes.object,
    badges: PropTypes.object,
    campaigns: PropTypes.object,
    disableCampaignField: PropTypes.bool
};

PostsHeader.defaultProps = {
    disableCampaignField: false
};

export default PostsHeader;
