/**
 * Created by bkanber on 7/5/17.
 */

import {
    SELECT_USER,
    REQUEST_USER, RECEIVE_USER, INVALIDATE_USER,
    REQUEST_USER_PROFILE, RECEIVE_USER_PROFILE,
    REQUEST_USER_IMAGES, RECEIVE_USER_IMAGES,
    REQUEST_USER_POSTS, RECEIVE_USER_POSTS,
    REQUEST_USER_ACTIVATIONS, RECEIVE_USER_ACTIVATIONS,
    REQUEST_USER_LISTS, RECEIVE_USER_LISTS,
    REQUEST_USER_ACTIVATION_STATS, RECEIVE_USER_ACTIVATION_STATS,
    ADDED_USER_TO_LIST, ADD_USERS_TO_LIST,
    ADDED_USER_TO_BADGE,
    RECEIVE_CAMPAIGN_USERS, RECEIVE_CAMPAIGN_USER,
    REQUEST_USER_SOCIALS, RECEIVE_USER_SOCIALS,
} from '../actions';

import {
    CREATE_USER_NOTE, CREATED_USER_NOTE, RECEIVE_USER_NOTES, REQUEST_USER_NOTES,
    UPDATE_USER_FACET, UPDATED_USER_FACET,
    UPDATE_USER_TAGS, UPDATED_USER_TAGS,
    BLOCK_USER, BLOCKED_USER,
    UPDATE_USER_EMAIL, UPDATED_USER_EMAIL,
    REQUEST_USER_INTERESTS, RECEIVE_USER_INTERESTS
} from "../actions/users";

export const DEFAULT_USER_STATE = {

    id: null,

    isFetching: false,
    isFetchingProfile: false,
    isFetchingPosts: false,
    isFetchingImages: false,
    isFetchingActivations: false,
    isFetchingLists: false,
    isFetchingActivationStats: false,
    isFetchingSocials: false,
    isFetchingNotes: false,
    isFetchingInterests: false,

    lastUpdatedActivations: null,
    lastUpdatedActivationStats: null,
    lastUpdatedLists: null,
    lastUpdatedProfile: null,
    lastUpdatedPosts: null,
    lastUpdatedSocials: null,
    lastUpdatedNotes: null,
    lastUpdatedInterests: null,

    didInvalidate: false,
    didInvalidateLists: false,
    didInvalidateProfile: false,

    basics: {},
    profile: {},

    imageIds: [],
    postIds: [],
    activationIds: [],
    socialIds: [],
    listEntries: [],
    networkEntries: [],
    notes: [],
    interests: {}
};

function user(state = DEFAULT_USER_STATE, action) {
    switch (action.type) {

        case RECEIVE_USER_INTERESTS:
            return {
                ...state,
                isFetchingInterests: false,
                lastUpdatedInterests: action.receivedAt,
                interests: action.interests
            };

        case REQUEST_USER_INTERESTS:
            return {
                ...state,
                isFetchingInterests: true
            };

        case UPDATED_USER_EMAIL:
            return {
                ...state,
                basics: {
                    ...state.basics,
                    email: action.email,
                    shadow: action.shadow,
                    email_valid: action.email_valid
                }
            };

        case BLOCKED_USER:
        case BLOCK_USER:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    is_blocked: action.block
                }
            };

        case UPDATED_USER_TAGS:
        case UPDATE_USER_TAGS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    tags: action.tags
                }
            };

        case UPDATED_USER_FACET:
        case UPDATE_USER_FACET:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    facets: {
                        ...(state.profile.facets || {}),
                        [action.facet]: action.value
                    }
                }
            };

        case RECEIVE_USER_NOTES:
        case CREATED_USER_NOTE:
            return {...state,
                isFetchingNotes: false,
                lastUpdatedNotes: action.receivedAt,
                notes: action.notes
            };

        case REQUEST_USER_NOTES:
            return {...state, isFetchingNotes: true};

        case RECEIVE_USER_SOCIALS:
            return Object.assign({}, state, {
                isFetchingSocials: false,
                lastUpdatedSocials: action.receivedAt,
                socialIds: action.socials.map(s => s.id)
            });

        case REQUEST_USER_SOCIALS:
            return Object.assign({}, state, {isFetchingSocials: true});

        case ADDED_USER_TO_BADGE:
            return Object.assign({}, state, {didInvalidateProfile: true});
            break;

        case ADDED_USER_TO_LIST:
            return Object.assign({}, state, {didInvalidateLists: true});
            break;

        case REQUEST_USER_LISTS:
            return Object.assign({}, state, {
                didInvalidateLists: false,
                isFetchingLists: true
            });
            break;

        case RECEIVE_USER_LISTS:
            return Object.assign({}, state, {
                isFetchingLists: false,
                lastUpdatedLists: action.receivedAt,
                listEntries: action.listEntries
            });
            break;

        case REQUEST_USER_ACTIVATION_STATS:
            return Object.assign({}, state, {
                isFetchingActivationStats: true
            });
            break;

        case RECEIVE_USER_ACTIVATION_STATS:
            return Object.assign({}, state, {
                isFetchingActivationStats: false,
                lastUpdatedActivationStats: action.receivedAt
            });
            break;

        case REQUEST_USER_IMAGES:
            return Object.assign({}, state, {
                isFetchingImages: true
            });
            break;

        case RECEIVE_USER_IMAGES:
            return Object.assign({}, state, {
                isFetchingImages: false,
                imageIds: action.images.map(image => image.id)
            });
            break;

        case REQUEST_USER_ACTIVATIONS:
            return Object.assign({}, state, {
                isFetchingActivations: true
            });
            break;

        case RECEIVE_USER_ACTIVATIONS:
            return Object.assign({}, state, {
                isFetchingActivations: false,
                activationIds: action.activations.map(activation => activation.id),
                lastUpdatedActivations: action.receivedAt
            });
            break;

        case REQUEST_USER_POSTS:
            return Object.assign({}, state, {
                isFetchingPosts: true
            });
            break;

        case RECEIVE_USER_POSTS:
            return Object.assign({}, state, {
                isFetchingPosts: false,
                lastUpdatedPosts: action.receivedAt,
                postIds: action.posts.map(post => post._id)
            });
            break;

        case INVALIDATE_USER:
            return Object.assign({}, state, {
                didInvalidate: true
            });

        case REQUEST_USER:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                id: action.userId
            });

        case REQUEST_USER_PROFILE:
            return Object.assign({}, state, {
                isFetchingProfile: true,
                didInvalidate: false,
                id: action.userId
            });

        case RECEIVE_USER:
        case RECEIVE_CAMPAIGN_USER:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                basics: action.user,
                id: action.user.id,
                lastUpdated: action.receivedAt,
                listEntries: action.user.listEntries || [],
                networkEntries: action.user.networkEntries || [],
            });

        case RECEIVE_USER_PROFILE:
            return Object.assign({}, state, {
                isFetchingProfile: false,
                didInvalidateProfile: false,
                profile: action.profile,
                lastUpdatedProfile: action.receivedAt
            });

        default:
            return state;
    }
}

export function usersById(state = { }, action) {
    switch (action.type) {
        case REQUEST_USER_INTERESTS:
        case RECEIVE_USER_INTERESTS:
        case UPDATED_USER_EMAIL:
        case BLOCKED_USER:
        case BLOCK_USER:
        case UPDATED_USER_TAGS:
        case UPDATE_USER_TAGS:
        case UPDATE_USER_FACET:
        case UPDATED_USER_FACET:
        case REQUEST_USER_NOTES:
        case RECEIVE_USER_NOTES:
        case CREATE_USER_NOTE:
        case CREATED_USER_NOTE:
        case ADDED_USER_TO_BADGE:
        case ADDED_USER_TO_LIST:
        case RECEIVE_USER_IMAGES:
        case REQUEST_USER_IMAGES:
        case RECEIVE_USER_LISTS:
        case REQUEST_USER_LISTS:
        case RECEIVE_USER_ACTIVATION_STATS:
        case REQUEST_USER_ACTIVATION_STATS:
        case RECEIVE_USER_ACTIVATIONS:
        case REQUEST_USER_ACTIVATIONS:
        case RECEIVE_USER_POSTS:
        case REQUEST_USER_POSTS:
        case INVALIDATE_USER:
        case RECEIVE_USER:
        case REQUEST_USER:
        case RECEIVE_USER_PROFILE:
        case REQUEST_USER_PROFILE:
        case RECEIVE_USER_SOCIALS:
        case REQUEST_USER_SOCIALS:
            return Object.assign({}, state, {
                [action.userId]: user(state[action.userId], action)
            });

        case RECEIVE_CAMPAIGN_USERS: {
            let newState = Object.assign({}, state);
            for (let _user of action.users) {
                newState[_user.id] = user(state[_user.id], {
                    type: RECEIVE_CAMPAIGN_USER,
                    user: _user,
                    receivedAt: action.receivedAt
                });
            }
            return newState;
        }
        break;


        default:
            return state;
    }
}

