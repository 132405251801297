import React, {useState} from "react";
import {getBestImageForPost} from "../../../utilities/innercircle";
import {Redirect} from "react-router-dom";

function ICPostTile({post}) {
    const [redirect, setRedirect] = useState(null);
    const imageUrl = getBestImageForPost(post);
    const tileStyle = { backgroundImage: `url(${imageUrl})` };
    const firstTag = (post.tags && post.tags.length > 0) ? post.tags[0] : null;
    const handleClick = () => {
        setRedirect(`/contribute/do/page/dash/thread?id=${post.id}`);
    };
    if (redirect) {
        return <Redirect to={redirect} push={true} />
    }
    return (
        <div className="ic-post" style={tileStyle} onClick={handleClick}>
            <div className="ic-post-content">
                <div className="meta-top">
                    <h4>{post.title}</h4>
                    <p className="byline">By {post.user.name}</p>
                </div>
                <div className="meta-bottom">
                    {firstTag && <div>{firstTag}</div>}
                </div>
            </div>
        </div>
    );
}

export default ICPostTile;