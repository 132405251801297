import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {preventDefaultIfPossible, formatNumberK} from "../../utilities";
import MultiEmailField from './MultiEmailField';
import EmailAddressBook from './AddressBook';
import {createChannelInteraction} from "../../actions/channel";
import FormSelect from "../Common/Form/Select";
import Button from "../Common/Form/Button";
import {fetchEmailTemplates} from "../../actions/templates";
import {fetchAuthenticated} from "../../actions/auth";
import Toolbox from "../Common/Toolbox";
import {getCampaign} from "../../selectors/campaigns";
import Alert from "../Common/Alert";
import Text from "../Common/Form/Text";
import {isEmailValid} from "../../utilities/email";
import _chunk from 'lodash/chunk';
import _concat from 'lodash/concat';
import Importer from "../Importer/Importer";
import Twig from 'twig';

// Size to chunk if bulk sending
const BULK_BATCH_SIZE = 100;

class EmailCompose extends Component {

    constructor(props) {
        super(props);

        this.redactor = null;
        this.textarea = null;

        this.state = {

            from: props.from ? props.from : '{"type": "Tidal\\\\Channel"}',
            to: props.to ? props.to : [],
            cc: props.cc ? props.cc : [],
            bcc: props.bcc ? props.bcc : [],
            replyTo: props.replyTo ? props.replyTo : '',

            subject: props.subject ? props.subject : '',
            body: props.body ? props.body : '',
            attachments: [ ],

            showCC: false,
            showAttachments: false,
            showAddressBook: false,
            showTemplates: false,
            showMailMerge: false,
            showMailMergePreview: false,
            mailMerge: null,

            isUploadingAttachment: false,
            isSending: false,
            didSend: false,
            chunksTotal: null,
            chunksSent: null,
            error: null,

        };

        this.toggleState = this.toggleState.bind(this);
    }

    componentDidMount() {

        const $self = this;

        this.redactor = window.Gator.setupOneRedactor(this.textarea);

        const redactorCallback = function() {
            const value = this.code.get();
            $self.setState({body: value});
        };

        this.redactor.on('change.callback.redactor', redactorCallback);
        this.redactor.on('paste.callback.redactor', redactorCallback);
        this.redactor.on('visual.callback.redactor', redactorCallback);
        this.redactor.on('source.callback.redactor', redactorCallback);

        if (this.props.allowTemplates) {
            this.props.fetchEmailTemplates(false);
        }

    }

    isValid() {
        const {body, subject, to, from} = this.state;
        if (subject.length < 3 || body.length < 3 || to.length < 1 || !from) {
            return false;
        }
        return true;
    }

    toggleState(key, e) {
        preventDefaultIfPossible(e);
        this.setState({[key]: !this.state[key]});
    }

    renderAttachmentsHeader() {
        const isOpen = this.state.showAttachments;
        const iconClass = isOpen ? 'fa fa-angle-down' : 'fa fa-angle-right';
        const numAttachments = this.state.attachments.length;
        let title = 'Attachments';
        if (numAttachments === 1) {
            title = '1 Attachment';
        }
        if (numAttachments > 1) {
            title = numAttachments + ' Attachments';
        }
        return (
            <div
                className="toolbox-section"
            >
                <h2><i className={iconClass}></i> <a
                    onClick={this.toggleState.bind(this, 'showAttachments')}
                    href="#">{title}</a></h2>
            </div>
        )
    }

    handleUploadAttachment(e) {

        this.setState({isUploadingAttachment: true});
        const formData = new FormData();
        formData.append('attachment', e.target.files[0]);
        const url = `/manage/api/email/-1/attachment`;
        return this.props.fetchAuthenticated(url, {
            method: 'POST',
            body: formData
        })
            .then(resp => resp.json())
            .then(json => {
                this.setState({
                    attachments: [...this.state.attachments, json.data],
                    isUploadingAttachment: false
                });
            })
            .catch(err => {
                console.log("Error uploading attachment");
                console.log(err);
            })

    }

    handleRemoveAttachment(index, e) {
        preventDefaultIfPossible(e);
        const attachments = this.state.attachments.slice(0);
        attachments.splice(index, 1);
        this.setState({attachments});
    }

    renderAttachmentsSection() {

        if (!this.state.showAttachments) return null;
        return (

            <div className="toolbox-section tidal-form">
                <div className="form-group">
                    <input
                        type="file"
                        className="form-control"
                        name="attachment"
                        onChange={this.handleUploadAttachment.bind(this)}
                    />
                </div>

                <div className="attachments">
                    {
                        this.state.attachments.map((a, i) => (
                            <div className="fake-li">
                                <a
                                    onClick={this.handleRemoveAttachment.bind(this, i)}
                                    className="inline no-hover"
                                    data-tooltip="Remove this attachment."
                                    style={{marginRight: 10}}
                                    href="#"><i className="fa fa-times-circle" /></a>

                                <a
                                    className="dark inline no-hover"
                                    href={a.original_url}
                                    target="_blank"
                                    data-tooltip="View this attachment."
                                >{a.name} <small>{formatNumberK(a.size)}B</small></a>

                            </div>
                        ))
                    }
                    {
                        this.state.isUploadingAttachment && (
                            <div className="fake-li">
                                <a className="dark">
                                    <i className="fa fa-spinner fa-spin" style={{marginRight: 10}} /> Uploading...
                                </a>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    renderCCHeader() {

        const isOpen = this.state.showCC;
        const iconClass = isOpen ? 'fa fa-angle-down' : 'fa fa-angle-right';

        return (
            <div
                className="toolbox-section"
            >
                <h2><i className={iconClass}></i> <a
                    onClick={ this.toggleState.bind(this, 'showCC')}
                    href="#">From, CC, &amp; BCC</a></h2>
            </div>
        )
    }

    renderCampaignAttachment() {
        const campaignId = (this.props.campaignId && this.props.campaignId !== 'all') ? this.props.campaignId : null;
        const campaign = this.props.campaign;
        if (!campaignId || !campaign) {
            return null;
        }

        return (
            <Alert
                classes={['info']}
                style={{marginBottom: 20}}
                content={
                    <span>Attaching to campaign <strong>{campaign.name}</strong>.</span>
                }
            />
        );
    }

    renderCCSection() {

        if (!this.state.showCC) return null;
        const {presence, teamsById} = this.props;
        const userTeams = (presence.user.teams || []).map(id => teamsById.items[id]).filter(t => !!t);
        const displayTeams = userTeams.filter(team => {
            if (team.email_address === presence.channel.support_email
                && team.email_address_name === presence.channel.support_email_name)
            {
                return false;
            }
            return true;
        });

        return (

            <div className="toolbox-section tidal-form">
                <div className="form-group">
                    <FormSelect
                        value={this.state.from}
                        onChange={from => this.setState({from: from.value})}
                        options={[
                            {
                                text: `From: ${(presence.channel.support_email_name || '')} <${presence.channel.support_email}>`,
                                value: `{"type": "Tidal\\\\Channel"}`
                            },
                            ...displayTeams.map(team => {
                                return {
                                    text: `From Team: ${(team.email_address_name || '')} <${team.email_address}>`,
                                    value: `{"type": "Tidal\\\\Team", "id": ${team.id}}`
                                }
                            })
                        ]}
                    />
                </div>
                <div className="form-group">
                    <Text
                        name="replyTo"
                        value={this.state.replyTo}
                        placeholder={"Reply-to Address (eg: noreply@youremail.com)"}
                        onChange={e => this.setState({replyTo: e.target.value})}
                    />
                </div>
                <div className="form-group">
                    <MultiEmailField
                        emails={this.state.cc}
                        onChange={cc => this.setState({cc})}
                        placeholder="CC"
                    />
                </div>
                <div className="form-group">
                    <MultiEmailField
                        emails={this.state.bcc}
                        onChange={bcc => this.setState({bcc})}
                        placeholder="BCC"
                    />
                </div>
            </div>
        )
    }

    renderMailMergePreview() {
        const mailMerge = this.state.mailMerge;
        if (!mailMerge) {
            return null;
        }

        // collect all field names from the first row
        const fields = Object.keys(mailMerge[0]);

        return (
            <Toolbox
                title="Mail Merge Preview"
                onClose={this.toggleState.bind(this, 'showMailMergePreview')}
                content={
                <div>
                    <Alert classes={['info']} content={"The fields below were detected in your mail merge spreadsheet. You can use them in your email template. Close this panel to continue."} />
                    <div className="fake-table">
                        {fields.map(field => {
                            const valuesPreview = mailMerge.slice(0, 5).map(row => row[field]).join(', ');
                            return (
                                <div className="padded fake-li">
                                    <strong>{`{{ ${field} }}`}</strong>
                                    <span className="pull-right">{valuesPreview}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                }
                addlClasses={'mail-merge-preview toolbox-fixed toolbox-sm'}
            />
        )
    }

    renderMailMergeUploaderPopup() {
        return (
            <Toolbox
                title="Upload Mail Merge"
                onClose={this.toggleState.bind(this, 'showMailMerge')}
                content={
                    <Importer
                        importAllColumns={true}
                        columns={[
                            {
                                key: 'email',
                                title: 'Email',
                                required: true
                            },
                            {
                                key: 'full_name',
                                title: 'Full Name',
                                required: false
                            }

                        ]}
                        onImport={rows => {

                            console.log("Got mail merge spreadsheet");
                            console.log(rows);

                            const toPayload = rows
                                .filter(row => {
                                    return row.email && isEmailValid(row.email);
                                })
                                .map(row => {
                                    return {
                                        type: 'email',
                                        email: row.email.toLowerCase(),
                                        name: row.full_name || row.email
                                    };
                                });

                            this.setState({
                                mailMerge: rows,
                                to: toPayload,
                                showMailMerge: false,
                                showMailMergePreview: true,
                            });
                        }}
                    />
                }
                addlClasses={'mail-merge-uploader toolbox-fixed toolbox-md'}
            />
        )
    }

    renderComposeSection() {

        const {showAddressBook, showTemplates, showMailMerge, showMailMergePreview, mailMerge, to} = this.state;
        const toUsers = (to || []).map(addr => {
            if (!addr.name) {
                addr.name = addr.email;
            }
            return addr;
        });

        return (

            <div className="toolbox-section tidal-form">

                {this.renderCampaignAttachment()}

                <div className="form-group">
                    <MultiEmailField
                        emails={toUsers}
                        onChange={to => this.setState({to})}
                        placeholder="To"
                    />
                </div>

                <div className="form-group" style={{marginBottom: 10}}>
                    <input
                        type="text"
                        placeholder="Subject"
                        className="form-control"
                        value={this.state.subject}
                        onChange={e => this.setState({subject: e.target.value})}
                    />
                </div>

                <div className="form-group">

                    <p className="help-block pull-left" style={{fontSize: 14, marginLeft: 5}}>
                        <a
                            onClick={this.toggleState.bind(this, 'showAddressBook')}
                            className="dark" role="button"><i className="fa fa-address-book"></i> Open Address Book</a>
                    </p>
                    <p className="help-block pull-left" style={{fontSize: 14, marginLeft: 10}}>
                        <a
                            onClick={() => {
                                if (mailMerge) {
                                    this.toggleState('showMailMergePreview');
                                } else {
                                    this.toggleState('showMailMerge');
                                }
                            }}
                            className={"dark " + (this.state.mailMerge ? 'bolder' : '')} role="button"><i className="fas fa-file-spreadsheet"></i> {this.state.mailMerge ? "Using" : "Upload"} Mail Merge</a>
                    </p>

                    {(this.props.allowTemplates && this.props.templates.length > 0)
                        && <p className="help-block pull-right" style={{fontSize: 14}}>
                            <a
                                onClick={this.toggleState.bind(this, 'showTemplates')}
                                className="dark" role="button"><i className="fa fa-file-alt"/> Templates</a>
                        </p>
                    }

                    {showAddressBook
                        && <EmailAddressBook
                            selected={this.state.to}
                            onChange={to => this.setState({to})}
                            onClose={this.toggleState.bind(this, 'showAddressBook')}
                        />
                    }

                    {showTemplates && this.renderTemplatesPopup()}

                    {showMailMerge && this.renderMailMergeUploaderPopup()}
                    {showMailMergePreview && this.renderMailMergePreview()}

                </div>

                <div className="form-group">
                    <textarea
                        className={'form-control'}
                        name={'body'}
                        value={this.state.body}
                        ref={(el) => this.textarea = el}
                    ></textarea>
                </div>

            </div>

        )
    }

    handleApplyTemplate(template) {
        this.setState({showTemplates: false});
        this.redactor.redactor('code.set', template.html);
    }

    renderTemplatesPopup() {
        if (!this.state.showTemplates) {
            return null;
        }

        const content = (
            <div className="fake-table">
                {this.props.templates.map(template => {
                    return (
                        <div className="fake-li">
                            <a
                                role="button"
                                className="dark"
                                onClick={() => this.handleApplyTemplate(template)}
                            >{template.key}</a>
                        </div>
                    );
                })}
            </div>
        );

        return (
            <Toolbox
                title="Apply Template"
                onClose={() => this.setState({showTemplates: false})}
                content={content}
                style={{
                    right: 0,
                    zIndex: 1000
                }}
            />
        );
    }

    // Base send context without recipients
    buildBaseSendContext() {
        // This is the base context
        const ctx = {
            from: JSON.parse(this.state.from),
            // to: this.state.to,
            // cc: this.state.cc,
            // bcc: this.state.bcc,
            subject: this.state.subject,
            body: this.state.body,
            attachments: this.state.attachments,
        };

        if (this.props.campaignId && this.props.campaignId !== 'all') {
            ctx.campaign_id = this.props.campaignId;
        }

        if (this.state.replyTo && isEmailValid(this.state.replyTo)) {
            ctx.replyTo = this.state.replyTo;
        }

        return ctx;
    }

    buildSendContextChunks() {

        // This is the base context
        const ctx = this.buildBaseSendContext();
        const {to, cc, bcc} = this.state;
        let chunks = [];

        // if we're using mail merge, we need to chunk in groups of 1, because the body gets rendered per chunk, not per recipient
        const chunkBatchSize = this.state.mailMerge ? 1 : BULK_BATCH_SIZE;

        if (to.length === 1) {
            chunks.push({...ctx, to, cc, bcc});
        } else {
            const recipients = _concat(to, cc, bcc);
            const recipientChunks = _chunk(recipients, chunkBatchSize);
            chunks = recipientChunks.map(rchunk => ({...ctx, to: rchunk}));
        }

        return chunks;

    }

    async handleSend() {
        if (!this.isValid()) {
            return;
        }

        const chunks = this.buildSendContextChunks();
        console.log({chunks});

        this.setState({
            didSend: false,
            isSending: true,
            chunksTotal: chunks.length,
            chunksSent: 0
        });

        let error = '';

        for (let chunk of chunks) {
            // if we have a mailMerge, update the chunk body by rendering with twig
            if (this.state.mailMerge) {
                // find the mailMerge row for this email address
                const toEmail = chunk.to[0].email;
                const row = this.state.mailMerge.find(row => row.email.toLowerCase() === toEmail.toLowerCase());
                if (row) {
                    const template = Twig.twig({
                        data: chunk.body
                    });
                    chunk.body = template.render(row);
                }
            }

            const resp = await this.props.createChannelInteraction('SendEmail', chunk);

            if (resp.meta.error) {
                error = error + 'Error: ' + resp.meta.error + ' ';
            } else {
                this.setState(prevState => ({chunksSent: prevState.chunksSent + 1}));
            }

        }

        if (error === '') {
            error = null;
        }

        this.setState({
            isSending: false,
            didSend: !error,
            error,
        });

        if (this.props.onSent && !error) {
            this.props.onSent();
        }

    }

    renderCloseButton() {
        if (!this.props.onClose) {
            return null;
        }

        return <a role="button" className="v3 close absolute" onClick={this.props.onClose} />
    }

    renderSendButton() {

        const isValid = this.isValid();
        const {isSending, didSend, chunksTotal, chunksSent} = this.state;

        const isDisabled = !isValid || isSending || didSend;

        const classes = [
            'btn tidal-btn btn-primary fullwidth',
            (isDisabled ? 'disabled' : '')
        ];

        const sendIconClass = "fa fa-send";
        const {sendBtnText} = this.props;
        let actualSendBtnText = sendBtnText;
        if (isSending) {
            actualSendBtnText = 'Sending, please wait...';
            if (chunksTotal > 1) {
                let sentPct = Math.round(100 * (chunksSent / chunksTotal));
                actualSendBtnText = actualSendBtnText + ' (' + sentPct + '%)';
            }
        }
        if (didSend) {
            actualSendBtnText = 'Sent!';
        }

        const content = <span><i className={sendIconClass} /> {actualSendBtnText}</span>;

        return (
            <div className="toolbox-section">
                <Button
                    content={content}
                    classes={classes}
                    onClick={isDisabled ? undefined : this.handleSend.bind(this)}
                />
            </div>
        );

    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <Alert classes={['danger']} content={this.state.error} />
        );
    }

    render() {

        const {showTitle, embedded, style} = this.props;
        const wrapperClass = 'toolbox ' + (embedded ? 'toolbox-embedded' : '');

        return(

                <div className={wrapperClass} style={style}>
                    {showTitle && <h2 className="title"><i className="fa fa-send"></i> Compose</h2>}

                    {this.renderCloseButton()}
                    {this.renderComposeSection()}
                    {this.renderCCHeader()}
                    {this.renderCCSection()}
                    {this.renderAttachmentsHeader()}
                    {this.renderAttachmentsSection()}
                    {this.renderError()}
                    {this.renderSendButton()}

                </div>

        );

    }

}

EmailCompose.defaultProps = {
    subject: '',
    body: '',
    to: [],
    cc: [],
    bcc: [],
    from: '{"type": "Tidal\\\\Channel"}',
    showTitle: true,
    sendBtnText: 'Send Now',
    embedded: true,
    style: {},
    allowTemplates: true,
};

EmailCompose.propTypes = {
    campaignId: PropTypes.any,
    campaign: PropTypes.any,
    presence: PropTypes.object,
    teamsById: PropTypes.object,

    subject: PropTypes.string,
    body: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.array,
    cc: PropTypes.array,
    bcc: PropTypes.array,

    showTitle: PropTypes.bool,
    sendBtnText: PropTypes.string,
    embedded: PropTypes.bool,
    style: PropTypes.object,

    templates: PropTypes.array,
    createChannelInteraction: PropTypes.func,
    fetchEmailTemplates: PropTypes.func,
    fetchAuthenticated: PropTypes.func,
    allowTemplates: PropTypes.bool,
    onClose: PropTypes.func,
    onSent: PropTypes.func,
};

const mapStateToProps = (state, props) => {
    return {
        presence: state.presence,
        teamsById: state.teamsById,
        templates: Object.values(state.emailTemplatesByKey),
        campaign: getCampaign(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createChannelInteraction: (type, ctx) => dispatch(createChannelInteraction(type, ctx)),
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
        fetchEmailTemplates: (pinned = null) => dispatch(fetchEmailTemplates(pinned)),
    };
};

const ConnectedEmailCompose = connect(mapStateToProps, mapDispatchToProps)(EmailCompose);

export default ConnectedEmailCompose;
