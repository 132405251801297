import React, {useState, useEffect} from 'react';
import GenericSettingsForm from "../Common/GenericSettingsForm";
import EntryProfileCustomImages from "./EntryProfileCustomImages";
import Checkbox from "../Common/Form/Checkbox";
import he from 'he';
import Alert from "../Common/Alert";

export default function EntrySettingsForm({entry, fetchEntry, markEntryUpdating, createListInteraction}) {
    const [settings, setSettings] = useState({...entry.settings});
    const [isDirty, setDirty] = useState(false);
    const enabledStats = (settings || {}).enabled_stats || ['instagram.reach', 'instagram.avg_engagement', 'instagram.engagement_pct'];

    const makeEnableStatsCheckbox = (title, fieldName) => {
        const isChecked = enabledStats.indexOf(fieldName) > -1;
        return (
            <div className="form-group">
                <Checkbox
                    label={title}
                    checked={isChecked}
                    onClick={() => {
                        setDirty(true);
                        if (isChecked) {
                            // Remove from array
                            setSettings({
                                ...settings,
                                enabled_stats: enabledStats.filter(item => item !== fieldName)
                            });
                        } else {
                            // Add to array
                            setSettings({
                                ...settings,
                                enabled_stats: [...enabledStats, fieldName]
                            });
                        }
                    }}

                />
            </div>
        );
    };

    const makeToggleCustomField = (title, fieldType, fieldName) => {
        return [
            {
                type: fieldType,
                name: fieldName,
                title: title,
            }
        ].filter(f => !!f);
    }

    const handleSave = async (formData) => {
        console.log("Saving with formData", formData);
        const entryId = entry.id;
        markEntryUpdating(entryId, true);
        await createListInteraction('UpdateEntrySettings', {entry_id: entryId, ...formData, enabled_stats: (settings || {}).enabled_stats});
        const fetchResp = await fetchEntry(entryId);

        if (fetchResp && fetchResp.data && fetchResp.data.settings) {
            setSettings(fetchResp.data.settings);
        }

        setDirty(false);
        markEntryUpdating(entryId, false);
        return Promise.resolve();
    };

    const handleFieldChange = (k, v) => {
        setDirty(true);
        setSettings({...settings, [k]: v});
    }

    const getValues = () => {
        let out = {};
        for (const key in settings) {
            if (typeof settings[key] === 'string') {
                out[key] = he.decode(settings[key]);
            } else {
                out[key] = settings[key];
            }
        }
        return out;
    };

    return (
        <>
            <GenericSettingsForm
                onSave={handleSave}
                values={getValues()}
                onFieldChange={handleFieldChange}
                isDirty={isDirty}
                spec={[
                    {
                        key: 'profile',
                        title: 'Profile Display',
                        fields: [
                            ...makeToggleCustomField('Custom Name', 'text', 'custom_name'),
                            ...makeToggleCustomField('Custom Profile Text', 'textarea', 'custom_profile_text'),
                            ...makeToggleCustomField('Custom Editorial Description', 'textarea', 'custom_description'),
                            ...makeToggleCustomField('Custom Profile Image', 'image', 'custom_profile_image'),
                            ...makeToggleCustomField('Show Creator Age', 'checkbox', 'show_age'),
                            {
                                type: 'generic',
                                content: (
                                    <div className="edit-profile-wrapper">
                                        <label><span style={{fontWeight: 'bold'}}>Custom Portfolio Images</span></label>
                                        <EntryProfileCustomImages
                                            entry={{...entry, settings: settings}}
                                            onChange={(imgs) => {
                                                console.log("images changed");
                                                console.log(imgs);
                                                handleSave({custom_images: imgs})

                                            }}
                                        />

                                    </div>
                                )
                            }
                        ].filter(f => !!f)

                    },
                    {
                        key: 'stats',
                        title: 'Select Stats to Display',
                        fields: [
                            {
                                type: 'generic',
                                content: (
                                    [
                                        makeEnableStatsCheckbox('Instagram Reach', 'instagram.reach'),
                                        makeEnableStatsCheckbox('Instagram Engagements per Post', 'instagram.avg_engagement'),
                                        makeEnableStatsCheckbox('Instagram Engagement Percent', 'instagram.engagement_pct'),

                                        makeEnableStatsCheckbox('TikTok Reach', 'tiktok.reach'),
                                        makeEnableStatsCheckbox('TikTok Engagements per Post', 'tiktok.avg_engagement'),
                                        makeEnableStatsCheckbox('TikTok Engagement Percent', 'tiktok.engagement_pct'),

                                        makeEnableStatsCheckbox('YouTube Reach', 'youtube.reach'),
                                        makeEnableStatsCheckbox('YouTube Engagements per Post', 'youtube.avg_engagement'),
                                        makeEnableStatsCheckbox('YouTube Engagement Percent', 'youtube.engagement_pct'),

                                        makeEnableStatsCheckbox('Twitter Reach', 'twitter.reach'),
                                        makeEnableStatsCheckbox('Twitter Engagements per Post', 'twitter.avg_engagement'),
                                        makeEnableStatsCheckbox('Twitter Engagement Percent', 'twitter.engagement_pct'),

                                        makeEnableStatsCheckbox('Facebook Reach', 'facebook-page.reach'),
                                        makeEnableStatsCheckbox('Facebook Engagements per Post', 'facebook-page.avg_engagement'),
                                        makeEnableStatsCheckbox('Facebook Engagement Percent', 'facebook-page.engagement_pct'),
                                    ]
                                )
                            }
                            // ...makeToggleCustomField('Custom Engagement Percent', 'number', 'custom_eng_pct'),
                            // ...makeToggleCustomField('Custom Total Reach', 'number', 'custom_reach'),
                            // ...makeToggleCustomField('Custom Instagram Reach', 'number', 'custom_instagram_reach'),
                            // ...makeToggleCustomField('Custom Twitter Reach', 'number', 'custom_twitter_reach'),
                            // ...makeToggleCustomField('Custom TikTok Reach', 'number', 'custom_tiktok_reach'),
                            // ...makeToggleCustomField('Custom YouTube Reach', 'number', 'custom_youtube_reach'),
                            // ...makeToggleCustomField('Custom Facebook Reach', 'number', 'custom_facebook_reach'),
                        ]
                    },
                ]}
            />
        </>

    );
}
