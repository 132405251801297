/**
 * Created by bkroger on 8/28/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {makeMomentFromDate} from '../../utilities';
import {connect} from 'react-redux';
import {fetchUserIfNeeded} from '../../actions/index.js';
import _get from 'lodash/get';
import {getUser} from '../../selectors/users';
import Avatar from '../Common/Avatar';
import DOMPurify from 'dompurify';

class Comment extends Component {

    static defaultProps = {

    };

    static propTypes = {
        comment: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }

    getCommentContent() {
        const {comment} = this.props;
        return {__html: DOMPurify.sanitize(comment.content)}
    }

    render() {

        const {comment} = this.props;
        const author = comment.source_author;

        const timestamp = makeMomentFromDate(comment.created_at).format('MMM Do, YYYY - h:mm a');

        return (
            <div className="draft-comment v3">
                <div className="draft-comment-meta">
                    <Avatar url={_get(author, 'picture')} />
                    <div className="meta-text-container">
                        <div className="username v3 h7 bold">
                            {_get(author, 'name', 'Loading User...')}
                        </div>
                        <div className="timestamp v3 h8 light">
                            {timestamp}
                        </div>
                    </div>
                </div>
                <div className="draft-comment-content v3 h7 light" dangerouslySetInnerHTML={this.getCommentContent()}>
                </div>

            </div>
        )
    }

}


export default Comment;

