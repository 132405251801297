import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {formatNumber, preventDefaultIfPossible} from "../../utilities";
import Toolbox from '../Common/Toolbox';
import Form from '../Common/Form/Form';
import Alert from '../Common/Alert';
import {createCampaignInteraction} from "../../actions/campaigns";
import {getCampaignsByDate} from "../../selectors/campaigns";
import TinyThermometer from "../Common/TinyThermometer";

const BATCH_SIZE = 25;

class ActivateUsersToolbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isActivating: false,
            didActivate: false,
            campaign_id: props.selectedCampaignId ? props.selectedCampaignId : null,
            allow_duplicates: false,
            should_invite: false,
            payment_amount: '',
            totalBatches: null,
            batchesDone: 0
        };
        this.handleActivateUsers = this.handleActivateUsers.bind(this);

    }

    async handleActivateUsers(event) {
        preventDefaultIfPossible(event);
        if (this.state.isActivating) return null;

        const campaignId = this.state.campaign_id;

        if (!campaignId) {
            return;
        }

        const userIds = this.props.userIds;
        // split up userIds into batches of BATCH_SIZE
        const batches = [];
        for (let i = 0; i < userIds.length; i += BATCH_SIZE) {
            batches.push(userIds.slice(i, i + BATCH_SIZE));
        }

        // activate each batch
        this.setState({totalBatches: batches.length, batchesDone: 0, isActivating: true});

        for (let i = 0; i < batches.length; i++) {

            console.log("Activating batch " + i + " of " + batches.length + " batches.");
            const context = {
                allow_duplicates: this.state.allow_duplicates,
                user_ids: batches[i],
                should_invite: this.state.should_invite,
                payment_amount: (this.state.payment_amount && this.state.payment_amount !== '') ? this.state.payment_amount : null
            };

            const json = await this.props.createCampaignInteraction(campaignId, 'ActivateUsers', context);
            this.setState({batchesDone: this.state.batchesDone + 1});
        }

        this.setState({isActivating: false, didActivate: true});

        if (this.props.onActivated) {
            this.props.onActivated({
                userIds,
                campaignId: campaignId,
            });
        }

    }

    renderToolboxContent() {

        const {isActivating, didActivate, campaign_id} = this.state;

        let usersCount = (this.props.userIds || []).length;
        let usersOrUser = usersCount === 1 ? 'User' : 'Users';

        let isDisabled = isActivating || !campaign_id;
        let btnText = isActivating ? 'Activating Users, Please Wait...' : `Activate ${usersCount} ${usersOrUser}`;
        let btnClasses = ['v3', 'btn', 'tidal-btn', 'medium', 'bold'];

        if (didActivate) {
            btnText = 'Added Users!';
        }

        if (isDisabled) {
            btnClasses = [...btnClasses, 'btn-secondary', 'disabled'];
        } else {
            btnClasses = [...btnClasses, 'btn-primary'];
        }

        return (

            <Form
                values={{
                    campaign_id: this.state.campaign_id,
                    allow_duplicates: this.state.allow_duplicates,
                    payment_amount: this.state.payment_amount,
                    should_invite: this.state.should_invite
                }}
                fields={[
                    {
                        type: 'generic',
                        content: <Alert
                            classes={['info']}
                            content={`Add ${usersCount} user${usersCount === 1 ? '' : 's'} to a campaign.`}
                        />
                    },
                    this.props.skippedItems ? {
                        type: 'generic',
                        content: <Alert
                            classes={['danger']}
                            content={`Only users can be activated with this tool. To activate blogs or social accounts, use the Invite tool instead.`}
                        />
                    } : null,
                    {
                        type: 'select',
                        name: 'campaign_id',
                        title: 'Select a Campaign',
                        help: "Choose a campaign to add creators to.",
                        choices: [
                            {text: '- Select a Campaign -', value: null},
                            ...this.props.campaigns.map(campaign => ({text: campaign.name, value: campaign.id}))
                        ]
                    },
                    {
                        type: 'number',
                        name: 'payment_amount',
                        title: "Payment Amount",
                        help: "Set payment amount for all users."
                    },
                    {
                        type: 'checkbox',
                        name: 'should_invite',
                        title: "Invite After Adding",
                        help: (
                            this.state.should_invite
                                ? "Users will be sent an invitation email."
                                : "Users will not yet be sent an invitation email."
                        )
                    },
                    {
                        type: 'checkbox',
                        name: 'allow_duplicates',
                        title: "Allow Duplicates",
                        help: (
                            this.state.allow_duplicates
                                ? "Already added users will be duplicated."
                                : "Already added users will not be duplicated."
                        )
                    },
                    {
                        type: 'button',
                        title: btnText,
                        options: {
                            hideTitle: true,
                            classes: btnClasses,
                            onClick: isDisabled ? null : this.handleActivateUsers.bind(this)
                        }
                    },
                    isActivating ? {
                        type: 'generic',
                        content: (
                            <TinyThermometer
                                addlClasses={['green']}
                                pct={100*(this.state.batchesDone / this.state.totalBatches)}
                                label="Please wait..."
                            />
                        )

                    } : null
                ]}
                onFieldChange={(key, value) => this.setState({[key]: value})}
            />

        );

    }

    render() {

        let title = 'Activate Users';

        if (this.props.userIds && this.props.userIds.length > 0) {
            let usersOrUser = this.props.userIds.length === 1 ? 'User' : 'Users';
            title = 'Activate ' + formatNumber(this.props.userIds.length) + ' ' + usersOrUser;
        }

        return (

            <Toolbox
                title={title}
                style={this.props.style}
                onClose={this.props.onClose}
                content={this.renderToolboxContent()}
            />

        );


    }
}

ActivateUsersToolbox.defaultProps = {
    queryId: null,
    userIds: [],
    style: {}
};

ActivateUsersToolbox.propTypes = {
    userIds: PropTypes.array.isRequired,
    campaigns: PropTypes.array,
    onClose: PropTypes.func,
    onActivated: PropTypes.func,
    style: PropTypes.object,
    createCampaignInteraction: PropTypes.func,
    skippedItems: PropTypes.number,
    selectedCampaignId: PropTypes.number,
};

const mapStateToProps = (state, props) => {
    return {
        campaigns: getCampaignsByDate(state, props),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createCampaignInteraction: (id, type, ctx) => dispatch(createCampaignInteraction(id, type, ctx)),
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateUsersToolbox);

