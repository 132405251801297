import {RECEIVE_PRESENCE} from "../actions/presence";
import * as ActionTypes from '../actions/types';

export function i18n(
    state = {
        lang: 'en',
        teamId: 'default',
        translations: {},
        missed: {},
        timerId: null,
        languages: [],
        isFetching: false,
        didFetch: false,
        lastUpdated: null,
    },
    action
) {
    switch (action.type) {

        case ActionTypes.I18N_REQUEST:
            return {
                ...state,
                isFetching: true
            };
            break;

        case ActionTypes.I18N_RECEIVE:
            return {
                ...state,
                isFetching: false,
                didFetch: true,
                lastUpdated: (new Date()).getTime(),
                ...action.payload
            };
            break;

        case ActionTypes.I18N_UPDATE_LANGUAGE:
            return {
                ...state,
                lang: action.lang
            };
            break;

        case ActionTypes.I18N_MISSED_TRANSLATION:
            return {
                ...state,
                missed: {...state.missed, [action.fullKey]: action.payload},
                timerId: action.timerId
            };
            break;

        case ActionTypes.I18N_ENSURE_MISSED:
            return {
                ...state,
                missed: {},
                timerId: null,
            };
            break;

        default:
            return state;
    }
}
