import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatNumber, limitChars, makeMomentFromDate} from "../../utilities";
import Tooltip from "./Tooltip";

class GenericProfileFacetsTable extends Component {

    static propTypes = {
        facets: PropTypes.object,
        tags: PropTypes.array,
        stats: PropTypes.object,
        metadata: PropTypes.object,
    };

    makeFacets() {

        let output = [];
        const {metadata = {}, stats = {}} = this.props;

        if (stats.mozrank) {
            output.push({name: 'MozRank', value: formatNumber(stats.mozrank, 1)});
        }

        if (stats.domain_authority) {
            output.push({name: 'Domain Authority', value: formatNumber(stats.domain_authority)});
        }

        if (metadata.actual_price_range || metadata.ratecard_price_range) {
            output.push({name: "Price Range", value: metadata.actual_price_range || metadata.ratecard_price_range});
        }

        if (stats.avg_engagement_pct) {
            output.push({name: "Engagement Percent", value: formatNumber(100 * stats.avg_engagement_pct) + '%'});
        }

        if (stats.posts_per_month) {
            output.push({name: "Posts / mo", value: formatNumber(stats.posts_per_month)});
        }

        if (metadata.latest_post_at) {
            output.push({name: "Latest Post", value: makeMomentFromDate(metadata.latest_post_at).format('MMM Do, YYYY')});
        }

        if (stats.posts_count) {
            output.push({name: "# Feed Posts", value: formatNumber(stats.posts_count)});
        }

        if (stats.avg_post_freq) {
            output.push({name: "Post Frequency", value: formatNumber(stats.avg_post_freq * 30, 0) + ' / mo'});
        }


        if (this.props.tags && this.props.tags.length > 0) {
            const tagSummary = limitChars(this.props.tags.join(', '), 20);
            const tagsHtml = <div>{this.props.tags.slice(0, 10).map(tag => <div className="v3 h8 light fake-li condensed bottom-border">{tag} </div>)}</div>;

            output.push({
                name: 'Tags',
                value: <Tooltip html={tagsHtml}>{tagSummary}</Tooltip>
            });
        }

        let nativeFacets = Object.entries(this.props.facets || {})
            .map(facet => {
                return {name: facet[0], value: facet[1]}
            })
            .filter(facet => facet.value !== 'N/A');

        output = output.concat(nativeFacets);

        return output;

    }
    render() {

        const facets = this.makeFacets();

        return (
            <div className="fake-table">
                {facets.map(facet => (
                    <div className="v3 h8 light fake-li condensed bottom-border">
                        <span>{facet.name}</span>
                        <span className="pull-right">{facet.value}</span>
                    </div>
                ))}
            </div>
        );
    }

}

export default GenericProfileFacetsTable;
