import React, {Component} from 'react';
import ConnectedManagePosts from "../../Pages/Manage/Content/Posts";
import PropTypes from  'prop-types';

class ContentPaneManageDash extends Component {

    render() {

        const {user} = this.props;

        return (
            <ConnectedManagePosts
                title={`${user.basics.name}'s Posts`}
                key={`user-posts-${user.id}`}
                query={{
                    id: `user-posts-${user.id}`,
                    filters: {
                        'user.id': user.id,
                        hideInstastory: true,
                    }
                }}
            />
        )
    }

}

export default ContentPaneManageDash;

ContentPaneManageDash.propTypes = {
    user: PropTypes.object.isRequired,
};