/**
 * Created by bkroger on 5/2/18.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../../../actions/activations";
import PropTypes from 'prop-types';
import FormSelect from '../../../Common/Form/Select.js';
import {fetchAuthenticated} from "../../../../utilities";
import Alert from "../../../Common/Alert";
import Button from "../../../Common/Form/Button";

class AdditionalInformationTemplate extends Component {

    constructor(props) {
        super(props);

        const formId = props.activation.settings.custom_form_id;
        const responses = (props.activation.form_responses || {})[formId] || {};

        this.state = {
            form: {
                form_id: formId,
                ...responses,
            },
            spec: null,
            isFetching: false,
            isSaving: false,
            isFileUploading: false,
            error: null,
        };

        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
    }

    componentDidMount() {
        this.fetchFormSpec();
    }


    fetchFormSpec() {
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/campaign/${this.props.campaign.id}/form/${this.state.form.form_id}`;
        this.setState({isFetching: true});
        return fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => {
                const spec = JSON.parse(json.data.json);
                this.setState({spec: spec.fields, isFetching: false});
            })
            .catch(err => {
                console.log("Error fetching form");
                console.log(err)
            });
    }

    validateForm() {

        const {spec, form} = this.state;
        let missingRequiredFields = [];

        spec.forEach(s => {
            const {name, required} = s;
            const value = form[name];
            if (required && !value) {
                missingRequiredFields.push(name);
            }
        });

        return missingRequiredFields;

    }

    handleFileUpload(fieldName, event) {
        this.setState({isFileUploading: fieldName});

        // upload to server and then set the value of the field to the url
        const fd = new FormData();
        fd.append('file', event.target.files[0]);
        const slot = window.Gator.getDashboardSlot();
        // we use the email attachment API to upload the file because it gives us a nice CDN url
        const url = `/${slot}/api/image/_/file`;
        const options = {
            method: 'POST',
            body: fd,
            enctype: 'multipart/form-data'
        };

        return fetchAuthenticated(url, options)
            .then(res => res.json())
            .then(json => {
                this.setState({form: {...this.state.form, [fieldName]: json.data.url}, isFileUploading: false});
            });

    }

    handleFieldChange(event) {
        const {name, value} = event.target;
        let form = this.state.form;
        form[name] = value;
        this.setState({form, error: null});
    }

    handleClickSave() {
        if (typeof this.props.activation !== 'undefined' && typeof this.props.activation.id !== 'undefined') {

            const context = {...this.state.form};

            // validate and break early
            const missingRequiredFields = this.validateForm();
            if (missingRequiredFields.length > 0) {
                const numMissing = missingRequiredFields.length;
                this.setState({
                    error: `Please fill out the required fields (${numMissing}).`,
                });
                return;
            }

            this.setState({ isSaving: true });
            return this.props.createInteraction(this.props.activation.id, 'SubmitFormResponse', context)
                .then(this.props.refreshActivation)
                .then(() => this.setState({isSaving: false}));
        } else {
            console.error('No activation id found');
        }
    }

    renderSpecForm(specs) {
        return specs.map(spec => this.renderFormField(spec));
    }

    renderFormControl(spec) {
        const {type, name, help, label, placeholder, options, required} = spec;
        const value = this.state.form[name];
        const isUploading = this.state.isFileUploading === name;

        switch (type) {

            case 'file':
                return <>

                    <input
                        type="file"
                        name={"file"}
                        className="form-control"
                        onChange={this.handleFileUpload.bind(this, name)}
                    />

                    {isUploading && <p className="help-block">Uploading your file, please wait...</p>}
                    {value && <p className="help-block">File uploaded successfully. <a href={value} target={"_blank"}>Click to view</a>.</p>}

                    <input
                        type="hidden"
                        name={name}
                        value={value}
                    />
                </>;

            case 'text':
                return <input
                    onChange={this.handleFieldChange}
                    name={name}
                    className="form-control"
                    value={value}
                    placeholder={placeholder}
                />

            case 'select':
                return <FormSelect
                    options={options}
                    value={value}
                    onChange={option => {
                        const event = {target: {name: name, value: option.value}};
                        return this.handleFieldChange(event);
                    }}
                />

            case 'textarea':
                return <textarea
                    className="form-control"
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={this.handleFieldChange}
                />

            case 'hidden':
                return <input
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    className="form-control"
                    onChange={this.handleFieldChange}
                    readOnly={true}
                />

        }

    }

    renderFormField(spec) {

        let {type, name, help, label, placeholder, required} = spec;

        label = label.replace('<strong>', '').replace('</strong>', '');
        const requiredLabel = required ? ' *' : '';


        return (
            <div className="form-group">
                {label && <label><strong>{label}{requiredLabel}</strong></label>}
                {this.renderFormControl(spec)}
                {help && <span className="help-block">{help}</span>}
            </div>
        )
    }

    renderAlert() {

        if (this.state.isFetching) {
            return <Alert
                classes={['info']}
                content={'Loading data...'}
                style={{marginBottom: 20}}
            />
        }

        if (this.state.error) {
            return <Alert
                classes={['danger']}
                content={this.state.error}
                style={{marginBottom: 20}}
            />
        }

        return <p className="v3 light h7 pane-info">Please fill out and submit the details below.</p>

    }

    renderBtn() {
        const {isFetching, isSaving} = this.state;
        let btnText = 'Submit';
        let classes = ['v3', 'btn', 'tidal-btn', 'medium', 'bold'];

        if (isFetching) {
            return null;
        }

        if (isSaving) {
            btnText = 'Submitting...';
            classes = [...classes, 'disabled', 'btn-secondary'];
        } else {
            classes = [...classes, 'btn-primary'];
        }

        return (
            <div className="form-group">
                <Button
                    content={btnText}
                    onClick={isSaving ? undefined : this.handleClickSave.bind(this)}
                    classes={classes}
                />
            </div>
        );

    }

    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }

    render() {

        if (this.isBlockedByNDA()) {
            return this.renderNDAAlert();
        }

        return (
            <div className="influencer-page-template payment_details">
                <div className="widget">

                    <div className="content padded">

                        <h4 className="v3 bold action-pane-title">Additional Information</h4>

                        {this.renderAlert()}

                        {this.renderSpecForm(this.state.spec || [])}

                        {this.renderBtn()}


                    </div>
                </div>
            </div>
        );

    }

}

AdditionalInformationTemplate.propTypes = {
    campaign: PropTypes.object.isRequired,
    activation: PropTypes.object.isRequired,
    refreshActivation: PropTypes.func.isRequired,
    createInteraction: PropTypes.func,
    fetchAuthenticated: PropTypes.func,
};


const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(null, mapDispatchToProps)(AdditionalInformationTemplate);
