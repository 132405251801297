/**
 * Created by bkroger on 10/18/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";
import RepresentativeImagesEditor from "../RepresentativeImagesEditor";

class YourProfile extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
        refreshUser: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        isProfileFieldVisible: PropTypes.func.isRequired,
        translate: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {},
            isUpdating: false,
            didUpdate: false,
            hasError: false,
            error: null,
        };
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
            hasError: false,
            error: null,
        }, () => {
            // handle removing profile pic
            if (name === 'image_id' && value === null) {
                this.setState({
                    form: {
                        ...this.state.form,
                        remove_picture: true
                    }
                });
            }
        });
    }

    handleClickSave() {
        this.setState({isUpdating: true, didUpdate: false});

        this.props.createInteraction(this.props.user.id, 'UpdatePublicProfile', this.state.form)
            .then(resp => {
                if (resp.meta.error) {
                    this.setState({isUpdating: false, hasError: true, error: resp.meta.error})
                } else {
                    this.props.refreshUser(this.props.user.id);
                    this.setState({isUpdating: false, didUpdate: true});
                    setTimeout(() => this.setState({form: {}, didUpdate: false}), 2000);
                }
            });
    }

    getFieldValues() {
        const {user} = this.props || {};
        const nativeValues = {...user, image_id: (user.profile_picture || {}).mongo_id};
        const formValues = this.state.form;

        return {...nativeValues, ...formValues};
    }

    renderSaveAlert() {

        const _t = this.props.translate;
        let style = {
            position: 'sticky',
            top: 0,
            zIndex: 200,
        };

        if (this.state.isUpdating) {
            return <Alert
                classes={['info']} content={_t('contribute.profile.saving', "Saving your changes...")} style={style}
            />
        }

        if (this.state.didUpdate) {
            return <Alert
                classes={['success']} content={_t('contribute.profile.saved', "Changes saved!")} style={style}
            />
        }

        if (this.state.hasError) {
            return <Alert
                classes={['danger']} content={this.state.error} style={style}
            />
        }

        if (Object.keys(this.state.form).length > 0) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>{_t('contribute.profile.unsavedchanges', 'You have unsaved changes.')} <a className="v3 bold" onClick={this.handleClickSave.bind(this)} role="button">{_t('contribute.profile.savenow', 'Save now.')}</a></span>}
                    style={style}
                />
            );
        }
    }

    render() {
        const isVisible = this.props.isProfileFieldVisible;
        const _t = this.props.translate;

        return (
            <div className="pane-inner-content">
                {this.renderSaveAlert()}
                <Form
                    values={this.getFieldValues()}
                    onFieldChange={this.handleFieldChange.bind(this)}
                    fields={[
                        isVisible('profile_bio') ? {
                            type: 'textarea',
                            name: 'profile_bio',
                            title: _t('contribute.profile.bio.title', 'Short Bio'),
                            help: _t('contribute.profile.bio.help', 'Two or three sentences about you, your interests, and the content you typically create.'),
                        } : null,
                        isVisible('image_id') ? {
                            type: 'image',
                            name: 'image_id',
                            title: _t('contribute.profile.picture.title', 'Profile Picture'),
                        } : null,
                        isVisible('representative_images') ? {
                            type: 'generic',
                            name: 'representative_images',
                            title: _t('contribute.profile.images.title', 'Profile Images'),
                            content: <RepresentativeImagesEditor
                                user={this.props.user}
                                onChangeImage={(imageIds) => this.setState({form: {...this.state.form, representative_image_ids: imageIds}})}
                            />
                        } : null,
                    ]}
                />
            </div>
        );
    }

}

export default YourProfile;