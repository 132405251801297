import React, {Component} from 'react';
import PropTypes from 'prop-types';
import C3Chart from 'react-c3js';
import d3 from 'd3';
import {c3ColorPattern, ucwords} from '../../../utilities';

export default class CampaignAudienceGeographyBarChart extends Component {

    getData() {

        const {campaign, which} = this.props;
        const {report = {}} = campaign;
        const {geography = {}} = report;
        const raw = geography[which];
        const total = geography.total || 0;
        if (!total) return null;

        let normalized = [];

        for (let location in raw) {
            let count = raw[location];
            let pct = count / total;

            normalized.push({
                location,
                count,
                pct: pct,
            });
        }

        normalized.sort((a, b) => parseFloat(a.pct) > parseFloat(b.pct) ? -1 : 1 );
        normalized = normalized.slice(0, this.props.limit);

        let columns = normalized.map(locdata => {
            return [
                locdata.location,
                locdata.pct
            ];
        });

        if (columns.length === 0) {
            return null;
        }

        columns.push(
            ['x', ucwords('Top ' + which)],
        );

        console.log("Audience geographies for " + which);
        console.log(columns);

        let out = {
            unload: true,
            x: 'x',
            type: 'bar',
            columns
        };

        return out;
    }

    renderChart() {
        const data = this.getData();
        if (!data) return null;

        const axis = {
            rotated: false,
            x: {type: 'category'},
            y: {
                tick: {
                    format: d3.format('%')
                }
            }
        };

        const tooltip = {
            format: {
                value: (value, ratio, id) => {
                    return d3.format('%')(value);
                }
            }
        };

        return <C3Chart
            data={data}
            color={c3ColorPattern}
            axis={axis}
            tooltip={tooltip}
            size={this.props.size}
            legend={{show: true}}
            bar={{width: {ratio: 0.6}}}
        />


    }

    render() {

        const data = this.getData();
        // No data -- don't render
        if (data === null) return null;
        const {which, style} = this.props;

        return (

            <div className="widget" style={style}>
                <div className="title">
                    <div className="icon left">
                        <i className="fa fa-globe"></i>
                    </div>
                    <h2>
                        Top {ucwords(which)}
                    </h2>
                </div>
                <div className="content padded clearfix">
                    {this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
                </div>
            </div>

        );
    }


}

CampaignAudienceGeographyBarChart.defaultProps = {
    style: {},
    size: {},
    limit: 6,
    which: 'countries'
};

CampaignAudienceGeographyBarChart.propTypes = {
    campaign: PropTypes.object.isRequired,
    which: PropTypes.string.isRequired,
    size: PropTypes.object,
    style: PropTypes.object,
    limit: PropTypes.number,
};
