/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatNumberK} from '../../../utilities';
import RechartsDonutChart from "../../Chart/RechartsDonutChart";
import ChartWrapper from "../../Chart/ChartWrapper";

const REACH_TYPES = {
    'blog_reach': 'Blog Uniques',
    'facebook_reach': 'Facebook',
    'facebook_page_reach': 'FB Page',
    'instagram_reach': 'Instagram',
    'instastory_reach': 'Instastory',
    'pinterest_reach': 'Pinterest',
    'twitter_reach': 'Twitter',
    'youtube_reach': 'YouTube',
    'tiktok_reach': 'TikTok'
};

export default class CampaignSocialReachDonutChart extends Component {

    static propTypes = {
        stats: PropTypes.array
    };

    getStat() {
        const stats = this.props.stats || [];
        if (stats.length === 0) return null;
        return stats[stats.length - 1];
    }

    getTotal() {
        const stat = this.getStat();
        if (!stat) return null;
        return stat.total_reach;
    }

    getData() {
        const stat = this.getStat();
        if (!stat) return null;

        let data = [];

        for (let key in REACH_TYPES) {
            let val = stat[key];
            let label = REACH_TYPES[key];

            if (val && val > 0) {
                data.push({
                    name: label,
                    value: val
                });
            }
        }

        return data;

    }

    renderChart() {
        const data = this.getData();
        if (!data) return null;

        return <RechartsDonutChart
            data={data}
            title="Social Reach"
            labelFormatter={(value) => formatNumberK(value)}
            labelPercent={false}
        />
    }

    render() {
        const total = this.getTotal();
        const titleBadge = total !== null ? formatNumberK(total) : null;

        return (

            <ChartWrapper
                title="Social Reach"
                titleBadge={titleBadge}
                icon="bullhorn"
                chart={this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
            />

        );
    }
}
