export const entryHasStats = (entry) => {
    const {profile} = entry;
    const {metadata, socials} = profile;
    const channel_stats = (metadata || {}).channel_stats;
    const channels = channel_stats ? Object.keys(channel_stats) : [];

    if (socials && socials.length > 0) {
        return true;
    }

    channels.forEach(id => {
        const channel = channel_stats[id];
        for (let stat in channel) {
            if (stat !== 0 || stat !== null) {
                return true;
            }
        }
    })

    return false;
};

export const numEntryPosts = (entry) => {
    const {profile} = entry;
    const {metadata} = profile;
    const channel_stats = (metadata || {}).channel_stats;
    const channels = channel_stats ? Object.keys(channel_stats) : [];
    let count = 0;

    channels.forEach(id => {
        const channel = channel_stats[id];
        const num_posts = Number(channel['num_posts']);
        if (!!num_posts) {
            count += num_posts;
        }
    })

    return count;
};