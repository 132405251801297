/**
 * @param selectedIds
 * @param lastSelectedIndex
 * @param items
 * @param row
 * @param event
 * @returns {{selectedIds: {}, lastSelectedIndex: *}}
 */
export const toggleRowSelectedWithMultiSelect = ({selectedIds = {}, lastSelectedIndex = null}, items = [], row, event = null) => {

    const isRowSelected = _row => !!selectedIds[_row.item.id];
    let newSelectedIds = {...selectedIds};

    if (event && event.shiftKey && lastSelectedIndex !== null) {
        event.preventDefault();
        document.getSelection().removeAllRanges();

        const thisIndex = row.index;
        const lowIndex = Math.min(thisIndex, lastSelectedIndex);
        const highIndex = Math.max(thisIndex, lastSelectedIndex);

        for (let index = lowIndex; index <= highIndex; index++) {
            // skip the last selected index so it doesn't get toggled
            if (index === lastSelectedIndex) {
                continue;
            }
            const _row = {item: items[index]};
            newSelectedIds[_row.item.id] = !isRowSelected(_row);
        }




    } else {
        newSelectedIds[row.item.id] = !isRowSelected(row);
    }

    return {
        selectedIds: newSelectedIds,
        lastSelectedIndex: row.index
    };
};