import {connect} from 'react-redux';
import {fetchUserActivationsIfNeeded, fetchUserActivations} from "../../../actions";
import ActivationsHoverView from "../../../components/User/Pieces/ActivationsHoverView";
import {DEFAULT_USER_STATE} from "../../../reducers/users";

const mapStateToProps = (state, props) => {

    const userId = props.userId;
    const user = state.usersById[userId] || DEFAULT_USER_STATE;

    const activations = Object.values(state.activationsById)
        .filter(activation => activation.user_id === userId)
        .map(activation => {
            const campaign = state.campaignsById.items[activation.campaign_id] || {archived: false};
            return {...activation, campaign};
        });

    return {
        activations,
        isLoading: user.isFetchingActivations
    };
};

const mapDispatchToProps = (dispatch, props) => {

    const userId = props.userId;

    return {
        fetchActivations: () => dispatch(fetchUserActivations(userId)),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(ActivationsHoverView);

