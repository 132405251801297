import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {limitChars} from "../../utilities";
import Button from '../Common/Form/Button';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";

class ActivationPostRecordCard extends Component {

    static propTypes = {
        record: PropTypes.shape({
            post: PropTypes.object.isRequired
        }).isRequired,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,

    };

    state = {
        isUpdating: false,
    };

    renderImage() {

        const {post} = this.props.record;
        let style = {};
        let icon;

        if (post.images.length > 0) {
            const imageUrl = post.images[0];
            style = {backgroundImage: 'url(' + imageUrl + ')'};
            icon = null;
        } else {
            style = {backgroundColor: '#F6F6F6'};
            icon = post.type !== null
                ? <i className={"fa fa-" + post.type} aria-hidden="true" />
                : <i className="fa fa-file-text" aria-hidden="true" /> ;
        }

        return (
            <div className="post-image" style={style}>
                <a className="post-image-link lightbox-launcher" href={this.getPostUrl()}>{icon}</a>
            </div>
        );

    }

    getPostUrl() {
        return '/manage/do/page/posts/view?slug=' + this.props.record.post.slug;
    }

    renderPostTitle() {

        const {post} = this.props.record;
        return (
            <div className="title">
                <a href={this.getPostUrl()}
                   className="post-title lightbox-launcher">{limitChars(post.title, 50)}</a>
            </div>
        );
    }

    handleAcceptPost() {

        const {record} = this.props;
        const activationId = record.activation_id;
        const payload = {post_record_id: record.id};
        this.setState({isUpdating: true});

        return this.props.createInteraction(activationId, 'AcceptPost', payload)
            .then(() => this.setState({isUpdating: false}))
            .then(() => this.props.fetchActivation(activationId));
    }

    renderActionButtons() {

        const {record} = this.props;
        const {isUpdating} = this.state;

        return (
            <div className="post-actions">

                {record.status === 'Submitted'
                    ? <Button content={isUpdating ? 'Working...' : 'Approve Post'}
                              classes={['v3', 'btn-primary', 'small', 'fullwidth']}
                              onClick={this.handleAcceptPost.bind(this)}
                    />
                    : null
                }


            </div>
        );
    }

    render() {
        return (
            <div className="post simple-post-card">

                {this.renderImage()}
                {this.renderActionButtons()}

                <div className="post-meta">
                    {this.renderPostTitle()}
                </div>

            </div>
        )
    }
}

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ActivationPostRecordCard);