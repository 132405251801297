import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createChannelInteraction} from "../../../actions/channel";
import {connect} from "react-redux";
import Alert from "../../Common/Alert";
import SimpleCreateUserForm from "../../Auth/SimpleCreateUserForm";
import DuplicatesTable from "./DuplicatesTable";
import Button from "../../Common/Form/Button";

const responseHasError = (json) => {
    return json &&
        (
            (json.meta && json.meta.error)
            || (json.data && json.data.exception)
        );
};

class Wizard extends Component {

    static propTypes = {
        createChannelInteraction: PropTypes.func,
        onCreatedUser: PropTypes.func,
        onClaimedUsers: PropTypes.func,
        onError: PropTypes.func,
        launchUserEditor: PropTypes.bool,
    };

    static defaultProps = {
        launchUserEditor: false
    };

    state = {
        error: null,
        user_id: null,
        user: null,
        submittedForm: null,
        isSubmitting: false,
        shouldCheckDuplicates: true,
        didCheckDuplicates: false,
        didCreate: false,
        didMatchEmail: false,
        duplicates: [],
        didClaimUsers: false,
    };

    componentDidUpdate(prevProps, prevState) {
    }

    handleResponseError(json) {

        const error = json.meta.error || json.data.exception;

        this.setState({
            user_id: null,
            user: null,
            isSubmitting: false,
            error
        });

        if (this.props.onError) {
            this.props.onError(error);
        }

    }

    handleDuplicatesResponse(json) {

        this.setState({
            isSubmitting: false,
            didCheckDuplicates: true,
            shouldCheckDuplicates: false,
            duplicates: json.data.results.duplicates,
            didMatchEmail: json.data.results.did_match_email,
        });

        if (json.data.results.duplicates.length === 0) {
            this.handleCreateUser(this.state.submittedForm);
        }

    }

    handleCreatedUser(json) {
        this.setState({
            isSubmitting: false,
            user_id: json.data.results.user_id,
            user: json.data.results,
            didCreate: true,
        });

        if (this.props.launchUserEditor) {
            const url = `/manage/do/page/users/edit?id=${json.data.results.user_id}`;
            window.open(url, '_blank');
        }

        if (this.props.onCreatedUser) {
            this.props.onCreatedUser(json.data.results);
        }
    }

    handleSubmit(form) {
        if (this.state.shouldCheckDuplicates) {
            return this.handleCheckDuplicates(form)
        } else {
            return this.handleCreateUser(form);
        }
    }

    handleCheckDuplicates(form) {

        this.setState({
            error: null,
            user: null,
            user_id: null,
            isSubmitting: true,
            duplicates: [],
            submittedForm: form
        });

        const context = {
            ...form,
            show_duplicates: true,
        };

        return this.props.createChannelInteraction('CreateUser', context)
            .then(json => {

                if (responseHasError(json)) {
                    this.handleResponseError(json);
                } else if (json && json.data) {
                    this.handleDuplicatesResponse(json);
                }

            });

    }

    handleCreateUser(form) {

        this.setState({
            error: null,
            user: null,
            isSubmitting: true,
            didCreate: false,
        });

        const context = {
            ...form,
        };

        this.props.createChannelInteraction('CreateUser', context)
            .then(json => {
                if (responseHasError(json)) {
                    this.handleResponseError(json);
                } else {
                    this.handleCreatedUser(json);
                }
            });

    }

    handleClaimUsers(userIds) {
        this.setState({isSubmitting: true});
        this.props.createChannelInteraction('ClaimUsers', {
            user_ids: userIds
        })
            .then(json => {
                this.setState({
                    didClaimUsers: true,
                    isSubmitting: false,
                });

                if (this.props.onClaimedUsers) {
                    this.props.onClaimedUsers(userIds);
                }

                if (this.props.launchUserEditor) {
                    for (let userId of userIds) {
                        const url = `/manage/do/page/users/edit?id=${userId}`;
                        window.open(url, '_blank');
                    }
                }


            })
    }

    handleForceCreate() {
        return this.handleCreateUser(this.state.submittedForm);
    }

    renderAlert() {
        const style = {marginBottom: 15};

        if (this.state.didCreate) {
            return (
                <Alert
                    classes={['success']}
                    content={"Created user!"}
                />
            );
        }

        if (this.state.didClaimUsers) {
            return (
                <Alert
                    classes={['success']}
                    content={"Added users to your My Creators list!"}
                />
            );

        }
        if (this.state.didCheckDuplicates && this.state.duplicates.length > 0) {
            return (
                <Alert
                    classes={['info']}
                    content={"We found possible matches for your user. Please review them below."}
                />
            );
        }

        if (this.state.error) {
            return (
                <Alert classes={['danger']} content={this.state.error} style={style} />
            );
        }

        if (this.state.isSubmitting) {
            return (
                <Alert
                    classes={['info']}
                    style={style}
                    content={ "Creating user..." }
                />
            );

        }

        return (
            <Alert
                classes={['info']}
                style={style}
                content={"Create a new user here. You can add more info on the next page."}
            />
        );
    }

    handleReset() {
        this.setState({
            error: null,
            user_id: null,
            user: null,
            submittedForm: null,
            isSubmitting: false,
            shouldCheckDuplicates: true,
            didCheckDuplicates: false,
            didCreate: false,
            didMatchEmail: false,
            duplicates: [],
            didClaimUsers: false,
        });
    }

    renderForm() {

        if (this.state.didClaimUsers || this.state.didCreate) {
            return (
                <div style={{padding: 15}}>
                    <Button
                        content={'Create Another'}
                        classes={['v3', 'tidal-btn', 'medium', 'bold', 'btn-secondary']}
                        onClick={this.handleReset.bind(this)}
                    />
                </div>
            );
        }

        if (this.state.didCheckDuplicates && this.state.duplicates && this.state.duplicates.length > 0) {
            return <DuplicatesTable
                data={this.state.duplicates}
                allowForceCreate={!this.state.didMatchEmail}
                onClaimUsers={this.handleClaimUsers.bind(this)}
                onClickForceCreate={this.handleForceCreate.bind(this)}
            />;
        }

        return (
            <SimpleCreateUserForm
                onSubmit={this.handleSubmit.bind(this)}
                isSubmitting={this.state.isSubmitting}
            />
        );
    }

    render() {
        return (
            <div className="create-user-wizard">
                {this.renderAlert()}
                {this.renderForm()}
            </div>
        );
    }
}

const mapStateToProps = undefined;
const mapDispatchToProps = (dispatch) => {
    return {
        createChannelInteraction: (type, context) => dispatch(createChannelInteraction(type, context)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
