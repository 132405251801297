/**
 * Created by visgotti on 11/1/17.
 */
import {connect} from 'react-redux';
import {
    fetchPostIfNeeded, fetchPostStatsIfNeeded, applyAction, updatePostRating,
    featurePost, fetchPostActivationRecordsIfNeeded, publishPost,
    updatePostActivationStatus
} from '../../actions/postsById';
import { fetchUserProfileIfNeeded, fetchUserIfNeeded } from '../../actions';
import { selectPost, unselectPost } from '../../actions/postQueries';

import PostCard from '../../components/Post/PostCard';
import PostRow from '../../components/Post/Table/Row';
import {DEFAULT_USER_STATE} from "../../reducers/users";
import {DEFAULT_POST_STATE} from "../../reducers/postsById";
import {DEFAULT_POST_QUERY} from "../../reducers/postQueries";
import {openUserLightbox} from "../../actions/lightbox";

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        openUserLightbox: (userId, ctx, userIds) => dispatch(openUserLightbox(userId, ctx, userIds)),
        fetchPostIfNeeded: (postId) => {
            dispatch(fetchPostIfNeeded(postId));
        },
        fetchPostStatsIfNeeded: (postId) => {
            dispatch(fetchPostStatsIfNeeded(postId))
        },
        selectPost: (postId, queryId) => {
            dispatch(selectPost(postId, queryId));
        },
        unselectPost: (postId, queryId) => {
            dispatch(unselectPost(postId, queryId));
        },
        applyAction: (postId, action) => {
            dispatch(applyAction([postId], action))
        },
        fetchUserProfileIfNeeded: (userId) => {
            dispatch(fetchUserProfileIfNeeded(userId))
        },
        fetchUserIfNeeded: (userId) => {
            dispatch(fetchUserIfNeeded(userId))
        },
        updatePostRating: (postId, rating) => dispatch(updatePostRating(postId, rating)),
        featurePost: (postId, feature) => dispatch(featurePost(postId, feature)),
        publishPost: (postId, publish) => dispatch(publishPost(postId, publish)),
        fetchPostActivationRecords: (postId) => dispatch(fetchPostActivationRecordsIfNeeded(postId)),
        updatePostActivationStatus: (activationPostRecordId, postId, status) => dispatch(updatePostActivationStatus(activationPostRecordId, postId, status))
    };
};

function mapStateToProps(state, ownProps) {
    let post = state.postsById[ownProps.postId] || DEFAULT_POST_STATE;
    const user = state.usersById[post.user.id] || DEFAULT_USER_STATE;

    post = {...post, activationRecords: (post.activationRecordIds || []).map(id => state.activationPostRecordsById[id]) };

    return {
        post,
        user,
        isSelected: ((state.postQueries.queries[state.postQueries.currentId] || DEFAULT_POST_QUERY).selectedPostIds.indexOf(ownProps.postId) > -1)
    };
}

export const ConnectedPostCard = connect(mapStateToProps, mapDispatchToProps)(PostCard);
export const ConnectedPostRow = connect(mapStateToProps, mapDispatchToProps)(PostRow);
