import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Form from '../../Common/Form/Form';
import {makeFormData} from '../../../utilities';

class SchedluePost extends Component {
    
    static propTypes = {
        postId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        postSections: PropTypes.array.isRequired,
        allSections: PropTypes.object.isRequired,
        fetchAuthenticated: PropTypes.func.isRequired,
        status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        onComplete: PropTypes.func,
    };
        
    static defaultProps = {
       onComplete: () => {},
    };

    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            didSubmit: false,
            form: {
                scheduleDate: '',
                section: ''
            }
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleFieldChange(name, value) {
        if (name === 'scheduleDate') {
            value = moment(value).format('YYYY-MM-DD hh:mm a');
        };

        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    getBtnText = (type) => {
        const {isSubmitting, didSubmit} = this.state;
        let btnText = 'Set Schedule';

        switch(type) {
            case null:
            case 'schedule':
                if (!!isSubmitting) btnText = 'Scheduling...';
                else if (!!didSubmit) btnText = 'Scheduled!';
                else btnText = 'Set Schedule';
                break;
            case 'unschedule':
                if (!!isSubmitting) btnText = 'Unscheduling...';
                else if (!!didSubmit) btnText = 'Unscheduled!';
                else btnText = 'Unschedule';
                break;
        };
        return btnText;
    }
    
    handleSubmit = (type) => {
        const {section_id, scheduleDate} = this.state.form;

        let params = {
            post_id: this.props.postId,
        };

        if (type === 'schedule') {
            if (!!scheduleDate) {
                params.date = moment(scheduleDate).format('YYYY-MM-DD');
                params.time = moment(scheduleDate, 'YYYY-MM-DD hh:mm a').format('H');
            };
            params.action = type;
            params['section_id'] = section_id;
        };

        const slot = window.Gator.getDashboardSlot();

        let url;
        switch (type) {
            case 'schedule': 
                url = `/${slot}/do/widget/posts/api_post_schedule`;
                break;
            case 'unschedule': 
                url = `/${slot}/do/widget/posts/api_delete_schedule`;
                break;
            default: break;
        };

        this.setState({
            isSubmitting: true
        })
    
        return this.props.fetchAuthenticated(url, {
            method: 'post',
            body: makeFormData({
                ...params
            })
        })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isSubmitting: false,
                    didSubmit: true,
                })
            })
            .then(() => this.props.onComplete())
            .then(() => this.setState({
                didSubmit: false
            }))
            .catch(console.error)
    };

    render() {
        const {isSubmitting, didSubmit} = this.state;
        const {allSections, postSections, status} = this.props;
        const type = !status ? 'schedule' : 'unschedule';
        const sectionOptions = Object.keys(allSections)
            .filter(index => !!(postSections.indexOf(allSections[index]['mongo_id']) >= 0))
            .map(index => {
                return {text: allSections[index].name, value: allSections[index]['mongo_id']}
            });

        return <Form
            onFieldChange={this.handleFieldChange.bind(this)}
            values={this.state.form}
            fields={[
                {
                    type: 'date',
                    name: 'scheduleDate',
                    title: 'Schedule this post to be published on:',
                    options: {showTimeSelect: true, dateFormat: 'MMMM DD, YYYY hh:mm a'}
                },
                {
                    type: 'select',
                    name: 'section_id',
                    title: 'Section:',
                    choices: [
                        {text: '- Select a Section -', value: null},
                        ...sectionOptions
                    ]
                },
                {
                    type: 'button',
                    name: 'submit',
                    title: this.getBtnText(type),
                    options: {
                        hideTitle: true,
                        classes: ['v3', 'large', (!!isSubmitting || !!didSubmit) ? 'btn-secondary': 'btn-primary'],
                        onClick: () => this.handleSubmit(type),
                    }
                },
            ]}
        />
    }
};

export default SchedluePost;