import React, {useMemo, useState} from 'react';
import RechartsDonutChart from "../../Chart/RechartsDonutChart";
import RechartsTimeseriesAreaChart from "../../Chart/RechartsTimeseriesAreaChart";
import {formatNumber, formatNumberK, makeMomentFromDate} from "../../../utilities";
import {socialNameForType, socialTypeFromNiceName} from "../../../utilities/social";
import _get from 'lodash/get';
import RechartsBarChart from "../../Chart/RechartsBarChart";
import AudienceCategoryChart from "../../Chart/Audience/AudienceCategoryChart";
const colors = ['#333', '#555', '#777', '#888', '#999', '#AAA', '#BBB', '#CCC'];

function ChartWrapper({children, title, style}) {
    return (
        <div className="widget" style={style}>
            <div className="title">
                <h2 className="red-indent">{title}</h2>
            </div>
            <div className="content">
                {children}
            </div>
        </div>

    );
}

function MyPerformanceCharts({socials, stats, audience, translate}) {

    const getAudienceSeries = (key) => {
        const input = _get(audience, 'tidal.distribution.'+key, null);
        if (!input) {
            return [];
        }
        let out = [];
        for (const dKey in input) {
            out.push({name: dKey, value: input[dKey]});
        }
        return out;
    }
    const hasAudienceSeries = (key) => {
        const data = getAudienceSeries(key);
        if (data.length === 0) {
            return false;
        }
        const sum = data.reduce((prev, curr) => prev + curr.value, 0);
        if (!sum) {
            return false;
        }
        return true;
    }
    const latestStats = useMemo(() => {
        return (stats && stats.length > 0) ? stats[stats.length - 1] : null;
    }, [stats]);

    const socialReachCounts = useMemo(function() {

        const naming = {
            'twitter_followers': 'Twitter',
            'facebook_page_followers': 'Facebook',
            'instagram_followers': 'Instagram',
            'pinterest_followers': 'Pinterest',
            'youtube_subscribers': 'YouTube',
            'tiktok_followers': 'TikTok',
        };

        const out = [];

        for (const statKey in naming) {
            const niceName = naming[statKey];
            if (latestStats && latestStats[statKey]) {
                const intStatVal = parseInt(latestStats[statKey] || 0, 10);
                if (intStatVal > 0) {
                    out.push({name: niceName, value: intStatVal});
                }
            }
        }
        return out;
    }, [latestStats]);

    const VerifiedReachBadge = (latestStats && latestStats.verified_reach) ? (
        <span className="large badge">{formatNumberK(latestStats.verified_reach)}</span>
    ) : null;

    const followerGrowthPct = useMemo(() => {
        const statsLen = stats.length;
        if (statsLen < 2) {
            return 0;
        }
        const firstFollowers = parseInt(stats[0].verified_reach || 0, 10);
        const lastFollowers = parseInt(stats[stats.length - 1].verified_reach || 0, 10);
        const diff = lastFollowers - firstFollowers;

        if (firstFollowers === 0) {
            return 1;
        }

        const pct = diff / firstFollowers;
        return pct;

    }, [stats]);

    const FollowerGrowthBadge = true ? (
        <span className="large badge">{formatNumber(100*followerGrowthPct, 1)}%</span>
    ) : null;

    const wdSocialReach = translate('creator.achievements.performance.social_reach', 'Social Reach');
    const wdFollowerGrowth = translate('creator.achievements.performance.follower_growth', 'Follower Growth');
    const wdAudienceGender = translate('creator.achievements.performance.audience_gender', 'Audience Gender');
    const wdAudienceAge = translate('creator.achievements.performance.audience_age', 'Audience Age');
    const wdAudienceCountries = translate('creator.achievements.performance.audience_countries', 'Audience Countries');
    const wdAudienceLanguages = translate('creator.achievements.performance.audience_languages', 'Audience Languages');
    const wdAudienceInterests = translate('creator.achievements.performance.audience_interests', 'Audience Interests');
    const wdAudienceBrands = translate('creator.achievements.performance.audience_brands', 'Audience Brands');
    const wdFollowers = translate('creator.words.followers', 'Followers');

    return (
        <div className="charts-wrapper">

            <ChartWrapper
                title={<>{wdSocialReach} {VerifiedReachBadge}</>}
            >
                <RechartsDonutChart
                    data={socialReachCounts}
                    colors={colors}
                    width={400}
                />

            </ChartWrapper>

            <ChartWrapper
                title={ <>{wdFollowerGrowth} {FollowerGrowthBadge} </>}
            >
                <RechartsTimeseriesAreaChart
                    width={600}
                    data={stats || []}
                    seriesSettings={[
                        {
                            chartType: 'area',
                            type: 'monotoneX',
                            dataKey: 'verified_reach',
                            name: wdFollowers,
                            stroke: '#CCC',
                            fill: '#DDD',
                            fillOpacity: 0.8,
                            stackId: 1
                        },
                    ]}
                    axisSettings={[
                        {
                            axisType: 'x',
                            dataKey: 'created_at',
                            stroke: '#888',
                            interval: 0,
                            tickFormatter: (value) => {
                                const date = makeMomentFromDate(value);
                                return date.format('MMM D');
                            }
                        },
                        {
                            axisType: 'y',
                            yAxisId: 'left',
                            stroke: '#888',
                            tickFormatter: (value) => formatNumber(value)
                        }
                    ]}

                />
            </ChartWrapper>


            {hasAudienceSeries('gender') && (
                <ChartWrapper
                    title={wdAudienceGender}
                >
                    <RechartsDonutChart
                        data={getAudienceSeries('gender')}
                        colors={colors}
                        width={400}
                    />

                </ChartWrapper>
            )}

            {hasAudienceSeries('age') && (
                <ChartWrapper
                    title={wdAudienceAge}
                >

                    <RechartsBarChart
                        data={getAudienceSeries('age').sort((a, b) => {
                            const aAge = parseInt(a.name.split('-')[0] || '0', 10);
                            const bAge = parseInt(b.name.split('-')[0] || '0', 10);
                            return aAge < bAge ? -1 : 1;
                        })}
                        colors={['#AAA']}
                        width={600}
                        showLegend={false}
                        useSingleColor={true}
                        tickFormatter={(pct) => formatNumber(pct*100, 0) + '%'}
                        valueFormatter={(pct) => formatNumber(pct*100, 1) + '%'}
                    />

                </ChartWrapper>
            )}


            {hasAudienceSeries('country') && (
                <ChartWrapper
                    title={wdAudienceCountries}
                    style={{maxWidth: 600}}
                >
                    <AudienceCategoryChart
                        thermometerStyle={{background: '#222'}}
                        data={_get(audience || {}, 'tidal.distribution.country', {})}
                        colors={colors}
                        limit={8}
                    />
                </ChartWrapper>
            )}

            {hasAudienceSeries('language') && (
                <ChartWrapper
                    title={wdAudienceLanguages}
                    style={{maxWidth: 600}}
                >
                    <AudienceCategoryChart
                        thermometerStyle={{background: '#222'}}
                        data={_get(audience || {}, 'tidal.distribution.language', {})}
                        colors={colors}
                        limit={8}
                    />
                </ChartWrapper>
            )}

            {hasAudienceSeries('iqdata_interests') && (
                <ChartWrapper
                    title={wdAudienceInterests}
                    style={{maxWidth: 600}}
                >
                    <AudienceCategoryChart
                        thermometerStyle={{background: '#222'}}
                        data={_get(audience || {}, 'tidal.distribution.iqdata_interests', {})}
                        colors={colors}
                        limit={8}
                    />
                </ChartWrapper>
            )}
            {hasAudienceSeries('brand') && (
                <ChartWrapper
                    title={wdAudienceBrands}
                    style={{maxWidth: 600}}
                >
                    <AudienceCategoryChart
                        thermometerStyle={{background: '#222'}}
                        data={_get(audience || {}, 'tidal.distribution.brand', {})}
                        colors={colors}
                        limit={8}
                    />
                </ChartWrapper>
            )}
        </div>
    );
}

export default MyPerformanceCharts;