/**
 * Created by bkanber on 7/7/17.
 */

import {
    RECEIVE_USERS, QUERY_USERS, INVALIDATE_USERS_QUERY,
    SET_USERS_LIST_CONTEXT, SET_USERS_BADGE_CONTEXT, SET_USERS_CAMPAIGN_CONTEXT,
    REQUEST_USERS_STATS, RECEIVE_USERS_STATS
} from '../actions';

export const DEFAULT_USER_FILTERS = {
    tier: null,
    group: "brand",
    team: null,
    interests: null,

    name: null,
    email: null,
    blog_name: null,

    age: {min: 16, max: 80},
    gender: null,
    human_location: null,
    category: null,
    language: null,
    race: null,
    relationship: null,
    parenthood: null,

    instagram: null,
    tiktok: null,
    reach: {min: 0, max: 250000},
    instagramReach: {min: 0, max: 250000},
    tiktokReach: {min: 0, max: 250000},
    blogReach: {min: 0, max: 250000},
    youtubeReach: {min: 0, max: 250000},
    pinterestReach: {min: 0, max: 250000},
    twitterReach: {min: 0, max: 250000},
    facebookReach: {min: 0, max: 250000},

    response_probability: {min: 0, max: 1},
    frecency: {min: 0, max: 10},
    content: null,

    campaign: null,
    notCampaign: null,

    country_name: null,
    created_at: null,
    channelStatus: null,

    'audience.gender': null,
    'audience.age': null,
    'audience.ethnicity': null,
    'audience.language': null,
    'audience.brands': null,
    'audience.interests': null,
    'audience.cities': null,
    'audience.states': null,
    'audience.countries': null,
    // Catch-all for city/state/country
    'audienceGeo': null,

};

export const DEFAULT_USER_QUERY = {

    queryId: null,
    isFetching: false,
    isFetchingStats: false,
    didInvalidate: false,
    lastUpdated: null,
    lastUpdatedStats: null,
    page: 1,
    limit: 20,
    meta: {},
    sort: null,
    filters: null,
    userIds: [],
    listContext: null,
    badgeContext: null,
    campaignContext: null,
    stats: null,
    statsMeta: null

};

function userQuery( state = DEFAULT_USER_QUERY, action ) {

    switch (action.type) {

        case RECEIVE_USERS_STATS:
            return {...state,
                isFetchingStats: false,
                lastUpdatedStats: action.receivedAt,
                stats: action.stats,
                statsMeta: action.meta
            };

        case REQUEST_USERS_STATS:
            return {...state, isFetchingStats: true};

        case INVALIDATE_USERS_QUERY:
            return Object.assign({}, state, {
                didInvalidate: true
            });
            break;

        case SET_USERS_BADGE_CONTEXT:
            return Object.assign({}, state, {
                badgeContext: action.badgeId,
            });
            break;

        case SET_USERS_LIST_CONTEXT:
            return Object.assign({}, state, {
                listContext: action.listId,
            });
            break;

        case SET_USERS_CAMPAIGN_CONTEXT:
            return Object.assign({}, state, {
                campaignContext: action.campaignId,
                filters: Object.assign({}, state.filters || {}, {notCampaign: action.campaignId})
            });
            break;

        case RECEIVE_USERS:

            return Object.assign({}, state, {
                queryId: action.queryId,
                isFetching: false,
                userIds: action.users.map(user => user.id),
                meta: action.meta,
                page: parseInt(action.meta.page, 10),
                lastUpdated: action.receivedAt,
            });

            break;

        case QUERY_USERS:

            return Object.assign({}, state, {
                queryId: action.queryId,
                isFetching: true,
                didInvalidate: false,
                sort: action.sort,
                filters: action.filters,
                page: action.page,
                limit: action.limit
            });

            break;

        default: return state;
    }

}

export function userQueries(
    state = {},
    action
) {
    switch (action.type) {
        case RECEIVE_USERS_STATS:
        case REQUEST_USERS_STATS:
        case SET_USERS_CAMPAIGN_CONTEXT:
        case SET_USERS_LIST_CONTEXT:
        case SET_USERS_BADGE_CONTEXT:
        case INVALIDATE_USERS_QUERY:
        case QUERY_USERS:
        case RECEIVE_USERS:

            return Object.assign({}, state, {
                [action.queryId]: userQuery(state[action.queryId] || DEFAULT_USER_QUERY, action)
            });

        default: return state;
    }
}
