import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '../Table/Table';
import BasicCell from "../Table/Cell/Basic";
import DateCell from "../Table/Cell/Date";
import StarRating from "../Common/StarRating";
import Button from '../Common/Form/Button';

import moment from 'moment';
import {formatNumber, makeMomentFromDate, preventDefaultIfPossible} from "../../utilities";
import _sumBy from 'lodash/sumBy';

class ActivationPostsTable extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onClickDraft: PropTypes.func,
        onAcceptPost: PropTypes.func,
        onRatePost: PropTypes.func,
        onClickPost: PropTypes.func,
        onRemovePost: PropTypes.func,
    };

    state = {
        deadImages: [],
        acceptingPosts: {},
        removingPosts: {},
        removedPosts: {},
    };

    componentDidMount() {
        this.checkImages();
    }

    checkImages() {

        const posts = this.getItems();
        const minArea = 40000;

        const killImage = (src) => {
            this.setState({deadImages: [...this.state.deadImages, src]});
        };

        posts.forEach(post => {

            const images = post.images || [];

            images.forEach(image => {

                let img = new Image();

                img.onload = (e) => {
                    const height = e.target.naturalHeight;
                    const width = e.target.naturalWidth;
                    const area = height * width;
                    if (area < minArea) {
                        killImage(image);
                    }
                };

                img.onerror = (e) => {
                    killImage(image);
                };

                img.src = image;
            });

        });

    }

    renderTitleCell(row, column) {

        let imageTiles = (row.item.images || row.item.image_urls || [])
            .filter(image => this.state.deadImages.indexOf(image) === -1)
            .slice(0, 4)
            .map(image => <div className="posts-table-title-image" style={{backgroundImage: 'url(' + image + ')'}}/> );

        let imageDiv = imageTiles.length > 0 ? <div className="posts-table-title-images">{imageTiles}</div> : null;

        let lightboxUrl = row.item.type === 'draft' ? '#' : `/manage/do/page/posts/view?slug=${row.item.slug}`;
        let onClickTitle = row.item.type === 'draft'
            ? (e) => {
                preventDefaultIfPossible(e);
                this.props.onClickDraft({source_id: row.item.source_id})
            }
            : (e) => {
                preventDefaultIfPossible(e);
                if (this.props.onClickPost) {
                    this.props.onClickPost(row.item);
                } else {
                    window.Gator.Lightbox.load(lightboxUrl);
                }
            };

        let postTitle = row.item.title;
        if (!postTitle || postTitle === '') {
            postTitle = '[Untitled Post]';
        }

        let title = <a role="button" href={lightboxUrl} onClick={e => e.preventDefault()} className="dark" >{postTitle}</a>;

        let content = <div onClick={onClickTitle} style={{cursor: 'pointer'}}>{imageDiv}{title}</div>;

        let style = {
            maxWidth: column.width,
        };

        return <BasicCell row={row} column={column} value={content} style={style} classes={['posts-table-title-cell']} />
    }

    renderTypeCell(row, column) {

        let icon, value;

        switch (row.item.type) {

            case 'youtube':
                icon = <i className="v3 icon youtube" />;
                value = 'YouTube';
                break;

            case 'pinterest':
                icon = <i className="v3 icon pinterest" />;
                value = 'Pinterest';
                break;

            case 'facebook':
                icon = <i className="v3 icon facebook" />;
                value = 'Facebook';
                break;

            case 'twitter':
                icon = <i className="v3 icon twitter" />;
                value = 'Twitter';
                break;

            case 'instagram':
            case 'instagram-business':
                icon = <i className="v3 icon instagram" />;
                value = 'Instagram';
                break;

            case 'instastory':
                icon = <i className="v3 icon instagram" />;
                value = 'Instastory';
                break;

            case 'post':
            case 'blog':
                icon = <i className="v3 icon file" />;
                value = 'Post';
                break;

            case 'draft':
                icon = <i className="v3 icon pencil" />;
                value = 'Draft';
                break;

            case 'tiktok':
                icon = <i className="v3 icon video" />;
                value = 'TikTok';
                break;

            case 'video':
                icon = <i className="v3 icon video" />;
                value = 'Video';
                break;

            default:
                icon = <i className="v3 icon file" />;
                value = row.item.type;
        }

        const {permalink} = row.item;
        const content = permalink 
            ? <span><a href={permalink} target='_blank' rel='noopener'>{icon}</a> {value}</span> 
            : <span>{icon} {value}</span>;

        return <BasicCell row={row} column={column} value={content} />
    }

    renderRatingCell(row, column) {
        if (row.item.type === 'draft') {
            return <BasicCell row={row} column={column} value={'N/A'} />;
        }

        const RatingTool = <StarRating
            rating={row.item.rating}
            onRatingChange={this.handleRatingChange.bind(this, row.item)}
        />
        return <BasicCell row={row} column={column} value={RatingTool} />;
    }

    getColumns() {
        return [
            {
                key: 'title',
                title: "Title",
                default: true,
                sortable: false,
                width: 400,
                cell: this.renderTitleCell.bind(this),
            },
            {
                key: 'type',
                title: "Type",
                default: true,
                sortable: false,
                width: 130,
                cell: this.renderTypeCell.bind(this),
            },
            {
                key: 'rating',
                title: "Rating",
                default: true,
                sortable: false,
                width: 110,
                cell: this.renderRatingCell.bind(this),
            },
            {
                key: 'created',
                title: "Created",
                default: true,
                sortable: false,
                width: 150,
                cell: (row, column) => <DateCell row={row} column={column} value={moment(row.item.created)} />,
            },
            {
                key: 'updated',
                title: "Updated",
                default: true,
                sortable: false,
                width: 150,
                cell: (row, column) => <DateCell row={row} column={column} value={moment(row.item.updated)} />,
            },
            {
                key: 'total_engagements',
                title: "Engagements",
                default: true,
                sortable: false,
                width: 150,
                cell: (row, column) => {
                    let value = row.item.engagements;
                    if (row.item.type === 'draft') {
                        value = 'N/A';
                    } else {
                        value = formatNumber(value);
                    }
                    return <BasicCell row={row} column={column} value={value} />
                },
            },
            // {
            //     key: 'total_impressions',
            //     title: "Impressions",
            //     default: true,
            //     sortable: false,
            //     width: 150,
            //     cell: (row, column) => <BasicCell row={row} column={column} value={row.item.views} />,
            // },
            {
                key: 'status',
                title: "Status",
                default: true,
                sortable: false,
                width: 200,
                cell: (row, column) => {
                    let value = 'Needs Approval';
                    // hide until we can fix the dupe issue
                    let RemovePostBtn = <Button content={this.isRemovingPost(row.item) ? 'Removing...' : 'Remove'}
                                                  classes={['v3', 'medium', 'bold', 'small', 'btn-secondary']}
                                                  style={{marginLeft: 8}}
                                                  onClick={this.handleRemovePost.bind(this, row.item)}
                    />;

                    if (row.item.type === 'draft') {
                        const approveVotes = (row.item.votes || []).filter(v => v.approve).length;
                        const reviseVotes = (row.item.votes || []).filter(v => !v.approve).length;
                        RemovePostBtn = null;

                        if (row.item.draft_status === 'accepted') {
                            value = 'Approved';
                        } else {
                            if (approveVotes || reviseVotes) {
                                let statusPieces = []
                                if (approveVotes) {
                                    statusPieces.push(<div>{approveVotes} vote(s) to Approve</div>)
                                }
                                if (reviseVotes) {
                                    statusPieces.push(<div>{reviseVotes} vote(s) to Revise</div>)
                                }
                                value = (<div style={{wordBreak: 'break-word', wordWrap: 'break-word', whiteSpace: 'normal'}}>{statusPieces}</div>);

                            } else {
                                value = 'Drafting';
                            }
                        }
                    }

                    if ((row.item.activationPost || {}).status === 'Published') {
                        value = 'Approved';
                    }

                    if ((row.item.activationPost || {}).status === 'Submitted') {

                        value = <Button content={this.isApprovingPost(row.item) ? 'Approving...' : 'Approve'}
                                        classes={['v3', 'medium', 'bold', 'small', 'btn-secondary']}
                                        onClick={this.handleApprovePost.bind(this, row.item)}
                        />

                    }

                    const btns = <>{value}{RemovePostBtn}</>

                    return <BasicCell row={row} column={column} value={btns} />
                },
            },


        ];
    }

    isRemovingPost(post) {
        if (post.type === 'draft') return false;
        const record = post.activationPost;
        if (!record) return false;
        return !!this.state.removingPosts[record.id];
    }

    isApprovingPost(post) {
        const record = post.activationPost;
        return !!this.state.acceptingPosts[record.id];
    }

    handleRatingChange(post, rating) {
        const record = post.activationPost;
        this.props.onRatePost(record, rating);

    }

    handleRemovePost(post) {
        const record = post.activationPost;
        this.setState({removingPosts: {
            ...this.state.removingPosts,
            [record.id] : true
        }});
        this.props.onRemovePost(record)
            .then(() => this.setState({
                removingPosts: {
                    ...this.state.removingPosts,
                    [record.id]: false
                },
                removedPosts: {
                    ...this.state.removedPosts,
                    [record.id]: true
                }
            }))

    }

    handleApprovePost(post) {
        const record = post.activationPost;
        this.setState({acceptingPosts: {
                ...this.state.acceptingPosts,
                [record.id] : true
        }});

        this.props.onAcceptPost(record)
            .then(() => this.setState({
                acceptingPosts: {
                    ...this.state.acceptingPosts,
                    [record.id]: false
                }
            }))

    }

    getItems() {
        // We need to invert the postRecord/post relationship
        const activation = this.props.activation || {};
        const postRecords = activation.postRecords || activation.post_records;
        const posts = (postRecords || []).map(pr => {
            let prWithoutPost = {...pr};
            delete(prWithoutPost.post);
            let post = {...pr.post, activationPost: prWithoutPost};
            return post;
        });

        const drafts = (this.props.activation.drafts || []).map(draft => {
            return {
                ...draft,
                type: 'draft',
                created: makeMomentFromDate(draft.created_at).toISOString(),
                updated: makeMomentFromDate(draft.updated_at).toISOString(),
                id: draft.source_id
            };
        });

        const combinedPosts = [...drafts, ...posts].filter(p => {
            if (!!this.state.removedPosts[p.id]) return false;
            if (!!this.state.removedPosts[(p.activationPost || {}).id]) {
                console.log("Post is removed, filtering out");
                return false;
            }
            return true;
        });

        const dedupedPosts = [];
        for (const post of combinedPosts) {
            if (dedupedPosts.map(p => p.id).includes(post.id)) continue;
            dedupedPosts.push(post);
        }

        return dedupedPosts;
    }

    render() {
        return (

            <Table
                style={{
                    width: _sumBy(this.getColumns(), 'width') + 2,
                }}
                showSort={false}
                items={this.getItems()}
                columns={this.getColumns()}
            />

        );
    }
}

export default ActivationPostsTable;
