import {makeFormData} from "../utilities";
import {fetchAuthenticated} from './auth';

export const REQUEST_POSTS = 'REQUEST_POSTS';
export const RECEIVE_POSTS = 'RECEIVE_POSTS';
export const REQUEST_POSTS_FAILED = 'REQUEST_POSTS_FAILED';

export const REQUEST_POST_STATS = 'REQUEST_POST_STATS';
export const RECEIVE_POST_STATS = 'RECEIVE_POST_STATS';
export const RECEIVE_POST_STATS_FAILED = 'RECEIVE_POST_STATS_FAILED';

export const REQUEST_POST = 'REQUEST_POST';
export const RECEIVE_POST = 'RECEIVE_POST';
export const RECEIVE_POST_IMAGES = 'RECEIVE_POST_IMAGES';

export const SELECT_POST = 'SELECT_POST';
export const UNSELECT_POST = 'UNSELECT_POST';

export const APPLY_POST_ACTION = 'APPLY_POST_ACTION';
export const APPLIED_POST_ACTION = 'APPLIED_POST_ACTION';

export const UPDATE_POST_RATING = 'UPDATE_POST_RATING';
export const UPDATED_POST_RATING = 'UPDATED_POST_RATING';

export const FEATURE_POST = 'FEATURE_POST';
export const FEATURED_POST = 'FEATURED_POST';

export const PUBLISH_POST = 'PUBLISH_POST';
export const PUBLISHED_POST = 'PUBLISHED_POST';

export const REQUEST_POST_ACTIVATION_RECORDS = 'REQUEST_POST_ACTIVATION_RECORDS';
export const RECEIVE_POST_ACTIVATION_RECORDS = 'RECEIVE_POST_ACTIVATION_RECORDS';

export const INVALIDATE_POST = 'INVALIDATE_POST';

export const UPDATE_POST_ACTIVATION_STATUS = 'UPDATE_POST_ACTIVATION_STATUS';
export const UPDATED_POST_ACTIVATION_STATUS = 'UPDATED_POST_ACTIVATION_STATUS';

export const UPDATE_POST = 'UPDATE_POST';
export const UPDATED_POST = 'UPDATED_POST';


/**
 * @param postId
 * @param post (form data)
 * @returns {Function}
 */
export function updatePost(postId, post) {
    return function (dispatch) {

        dispatch({
            type: UPDATE_POST,
            postId,
            post
        });

        const uri = `/manage/api/post/${postId}`;

        return dispatch(fetchAuthenticated(uri, {
            method: 'POST',
            body: makeFormData({...post, _method: 'PUT'})
        }))
            .then(resp => resp.json())
            .then(json => {
                if (json && json.meta && json.meta.error) {
                    throw json.meta.error;
                }
                return dispatch({
                    type: UPDATED_POST,
                    postId,
                    updatedPost: json.data,
                    receivedAt: new Date()
                });
            });
    }
}


/**
 * @param activationPostRecordId
 * @param postId
 * @param status Either 'Published' or 'Not Published'
 * @returns {Function}
 */
export function updatePostActivationStatus(activationPostRecordId, postId, status) {
    return function (dispatch) {

        dispatch({
            type: UPDATE_POST_ACTIVATION_STATUS,
            activationPostRecordId,
            postId,
            status
        });

        const url = `/manage/api/activationpost/${activationPostRecordId}/status`;

        return dispatch(fetchAuthenticated(url, {
            method: 'POST',
            body: makeFormData({_method: 'patch', status})
        }))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: UPDATED_POST_ACTIVATION_STATUS,
                activationPostRecordId,
                postId,
                status,
                activationRecord: json.data,
                meta: json.meta
            }))
            .then(prev => {
                dispatch(invalidatePost(postId));
                return prev;
            })
            .catch(err => {
                console.log("Error updating post activation status");
                console.log(err);
                return err;
            });

    }
}

export function invalidatePost(postId) {
    return {
        type: INVALIDATE_POST,
        postId
    };
}

/**
 * @param state
 * @param postId
 * @returns {*}
 */
export function shouldFetchPostActivationRecords(state, postId) {

    if(!(postId in state.postsById)) {
        return true;
    }

    const post = state.postsById[postId];

    if (post.isFetchingActivationRecords === true ||
        (
            post.lastUpdatedActivationRecords !== null && typeof post.lastUpdatedActivationRecords !== 'undefined'
        )
    ) {
        return false;
    } else {
        return true;
    }
}

/**
 * @param postId
 * @returns {function(*, *)}
 */
export function fetchPostActivationRecordsIfNeeded(postId) {
    return (dispatch, getState) => {
        if (shouldFetchPostActivationRecords(getState(), postId)) {
            return dispatch(fetchPostActivationRecords(postId))
        }
    }
}

export function fetchPostActivationRecords(postId) {
    return function (dispatch) {

        dispatch({
            type: REQUEST_POST_ACTIVATION_RECORDS,
            postId,
        });

        return dispatch(fetchAuthenticated(`/manage/api/post/${postId}/activationRecord`))
            .then(response => response.json())
            .then(json => dispatch({
                type: RECEIVE_POST_ACTIVATION_RECORDS,
                postId,
                activationRecords: json.data,
                receivedAt: new Date()
            }))
            .catch(err => {
                console.log("Error fetching post activation records");
                console.log(err);
                return err;
            });
    }
}

export function publishPost(postId, publish) {
    return function (dispatch) {

        dispatch({
            type: PUBLISH_POST,
            postId,
            publish
        });

        return dispatch(fetchAuthenticated(`/manage/api/post/${postId}/publish/${publish}`, {
            method: 'POST',
            body: makeFormData({_method: 'PUT'})
        }))
            .then(response => response.json())
            .then(json => {
                if (json && json.meta && json.meta.error) {
                    throw json.meta.error;
                }
                return dispatch({
                    type: PUBLISHED_POST,
                    postId,
                    publish,
                    receivedAt: new Date()
                });
            });
    }
}
export function featurePost(postId, feature) {
    return function (dispatch) {

        dispatch({
            type: FEATURE_POST,
            postId,
            feature
        });

        return dispatch(fetchAuthenticated(`/manage/api/post/${postId}/featured/${feature}`, {
            method: 'POST',
            body: makeFormData({_method: 'PUT'})
        }))
            .then(response => response.json())
            .then(json => {
                if (json && json.meta && json.meta.error) {
                    throw json.meta.error;
                }
                return dispatch({
                    type: FEATURED_POST,
                    postId,
                    feature,
                    receivedAt: new Date()
                });
            });
    }
}

export function updatePostRating(postId, rating) {
    return function (dispatch) {

        dispatch({
            type: UPDATE_POST_RATING,
            postId,
            rating
        });

        return dispatch(fetchAuthenticated(`/manage/api/post/${postId}/rating/${rating}`, {
            method: 'POST',
            body: makeFormData({_method: 'PUT'})
        }))
            .then(response => response.json())
            .then(json => dispatch({
                type: UPDATED_POST_RATING,
                postId,
                rating,
                receivedAt: new Date()
            }))
            .catch(err => {
                console.log("Error updating post rating");
                console.log(err);
                return err;
            });
    }
}

export function shouldFetchPostStats(state, postId) {

    const post = state.postsById[postId];

    if (typeof post === 'undefined') {
        return true;
    }

    if(!('stats' in post) ) {
        return true;
    }

    if (post.isFetchingStats === true) {
        return false;
    }

    if (typeof post.lastUpdatedStats === 'undefined' || post.lastUpdatedStats === null) {
        return true;
    }

    return false;
}

export function fetchPostStatsIfNeeded(postId) {
    return (dispatch, getState) => {
        if (shouldFetchPostStats(getState(), postId)) {
            return dispatch(fetchPostStats(postId))
        }
    }
}

export function fetchPostStats(postId) {
    return function (dispatch) {

        dispatch({
            type: REQUEST_POST_STATS,
            postId
        });

        return dispatch(fetchAuthenticated(`/manage/api/post/${postId}/stat`))
            .then(response => response.json())
            .then(json => {
                dispatch(receivePostStats(postId, json.data))
            })
            .catch(err => {
                console.log("Error fetching post stats");
                console.log(err)
            });
    }
}

export function receivePostStats(postId, stats) {
    return {
        type: RECEIVE_POST_STATS,
        postId,
        stats,
        receivedAt: new Date()
    };
}


/**
 * @param state
 * @param postId
 * @returns {*}
 */
export function shouldFetchPost(state, postId) {
    if(!(postId in state.postsById)) {
        return true;
    }

    const post = state.postsById[postId];

    if (post.didInvalidate === true) {
        return true;
    }

    if (post.isFetching === true) {
        return false;
    }

    if (typeof post.lastUpdated === 'undefined' || post.lastUpdated === null) {
        return true;
    }

    return false;
}

/**
 * @param postId
 * @returns {function(*, *)}
 */
export function fetchPostIfNeeded(postId) {
    return (dispatch, getState) => {
        if (shouldFetchPost(getState(), postId)) {
            return dispatch(fetchPost(postId))
        }
    }
}

export function fetchPosts(filters = {}, sort = {}, page=1, limit=10, queryId) {
    return function (dispatch) {
        dispatch(requestPosts(queryId, filters, sort, page, limit));
        formatWrittenFilters(filters);
        let url = jsonToQueryString(filters, sort, page, limit);

        return dispatch(fetchAuthenticated(url))
            .then(response => response.json())
            .then(json => {
                dispatch(receivePosts(json.data, json.meta, queryId))
            })
            .catch(err => {
                dispatch({
                    type: REQUEST_POSTS_FAILED,
                    queryId,
                });
                console.log("Error fetching posts");
                console.log(err)
            });
    }
}

export function fetchPost(postId, queryId) {
    return function (dispatch) {
        dispatch(requestPost(postId));
        return dispatch(fetchAuthenticated(`/manage/api/post/${postId}`))
            .then(response => response.json())
            .then(json => dispatch(receivePost(postId, json.data, queryId)) )
            .catch(err => {
                console.log("Error fetching post");
                console.log(err)
            });
    }
}

export function applyAction(postIds, action) {
    return{
        type: APPLY_POST_ACTION,
        postIds: postIds,
        action: action,
    }
}

export function requestPosts(queryId, filters, sort, page, limit) {
    return {
        type: REQUEST_POSTS,
        queryId,
        filters,
        sort,
        page,
        limit
    }
}

export function receivePosts(posts, meta, queryId) {
    return function (dispatch) {

        dispatch({
            type: RECEIVE_POSTS,
            meta,
            queryId
        })

        posts.forEach(post => {
            dispatch(receivePost(post._id, post, queryId))
            dispatch(receivePostImages(post.images))
        });
    };
}

export function requestPost(postId, queryId) {
    return {
        type: REQUEST_POST,
        postId,
        queryId,
    };
}

export function receivePost(postId, data, queryId) {
    return {
        type: RECEIVE_POST,
        postId,
        data,
        queryId,
        receivedAt: new Date()
    }
}

export function receivePostImages(images) {
    return {
        type: RECEIVE_POST_IMAGES,
        images,
    }
}


// Helper function to change the writtenMax and writtenMin to the correct json format
// for filters before sending out the post request
function formatWrittenFilters(filters) {
    if('writtenMax' in filters || 'writtenMin' in filters) {
        filters['written'] = {};
    } else {
        // if neither of them are in the filters we make sure there's no written field for the request
        delete filters['written'];
        return;
    }

    // check to see which one's we need to format correctly.
    if('writtenMax' in filters){
        let max = filters.writtenMax
        delete filters['writtenMax'];
        filters.written['max'] = max;
    }
    if('writtenMin' in filters) {
        let min = filters.writtenMin
        delete filters['writtenMin']
        filters.written['min'] = min;
    }
}

// Helper function to conver the filter json into the appropriate query string.
function jsonToQueryString(filters, sort, page, limit) {
    return '/manage/api/post?filters=' + JSON.stringify(filters) + '&sort=' + JSON.stringify(sort) + '&page=' + page + '&limit=' + limit;
}