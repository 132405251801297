/**
 * Created by bkanber on 7/26/17.
 */
import React, {Component} from 'react';
import C3Chart from 'react-c3js';
import d3 from 'd3';
import {c3ColorPattern, formatNumber} from '../../../utilities';

export default class UserEngagementPctChart extends Component {

    renderChart() {

        let totalReach = 0;
        let totalEngagements = 0;
        let avgPct = 0;

        const campaigns = this.props.campaignsById.items;
        const json = this.props.activationStats.map(stats => {
            const stat = stats[0];
            const eng = stat.total_engagements;
            const reach = stat.total_reach;
            const pct = 100 * (eng / reach);
            const campaign = campaigns[stat.campaign_id];

            totalReach += reach;
            totalEngagements += eng;

            return {
                campaign: campaign.name,
                "Engagement Pct": pct,
                "Reach": stat.total_reach,
                "Engagements": stat.total_engagements,
            }
        });

        if (totalReach > 0) {
            avgPct = 100 * (totalEngagements / totalReach);
        }

        const data = {
            json,
            keys: {
                x: 'campaign',
                value: ['Engagement Pct']
            },
            type: 'bar'
        };

        const axis = {
            x: {
                type : 'category',
            }
        };

        const tooltip = {
            format: {
                value: function (value, ratio, id) {
                    return d3.format('.2f')(value) + '%';
                }
            }
        }

        return (
            <div className="widget" style={{width: '350px', height: '360px', marginTop: 0, flex: 'none'}}>
                <div className="title">
                    <div className="icon left"><i className="fa fa-bar-chart-o"></i></div>
                    <h2>
                        Engagement Pct

                        {
                            avgPct > 0 ?
                                <span className="badge badge-darkgrey">
                                    Avg {formatNumber(avgPct, 2)}%
                                </span>
                                : ''
                        }

                    </h2>
                </div>
                <div className="content">

                    <C3Chart
                        data={data}
                        size={{width: 300, height: 280}}
                        color={c3ColorPattern}
                        axis={axis}
                        legend={{hide: true}}
                        tooltip={tooltip}
                        transition={{duration: 0}}
                    />

                </div>
            </div>
        );

    }

    render() {

        return this.renderChart();

    }
}

