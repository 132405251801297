import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '../../Common/Form/Button';

class ManageSections extends Component {
    
    static propTypes = {
        allSections: PropTypes.object.isRequired,
        postSections: PropTypes.array.isRequired,
        postSlug: PropTypes.string.isRequired,
        onComplete: PropTypes.func,
        onEditSection: PropTypes.func.isRequired,
    };

    static defaultProps = {
        onComplete: () => {},
    };

    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            didSubmit: false,
        };
    };

    makeRow = (row) => {
        const id = row['mongo_id'];
        const {postSections} = this.props;
        const {isSubmitting, didSubmit} = this.state;
        const classes = ['v3', 'small', (!!isSubmitting[id] || !!didSubmit[id]) ? 'btn-secondary': 'btn-primary'];
        const btnStyle = {height: '100%', padding: 10, lineHeight: 'normal'};

        return <div className='fake-li padded bottom-border' key={row.id}>{row.name}
        <span className='pull-right'>
            {
                postSections.indexOf(id) > -1
                ? <Button
                    content={this.getBtnText(id, 'remove')}
                    classes={classes}
                    onClick={() => this.editSection(id, false)}
                    style={btnStyle}
                />
                : <Button 
                    content={this.getBtnText(id, 'add')}
                    classes={classes}
                    onClick={() => this.editSection(id, true)}
                    style={btnStyle}
                />
            }
            </span>
        </div>
    };

    getBtnText = (id, type) => {
        const {isSubmitting, didSubmit} = this.state;
        let btnText;
        
        switch (type) {
            case 'add':
                if (!!isSubmitting[id]) btnText = 'Adding Section...';
                else if (!!didSubmit[id]) btnText = 'Added Section!';
                else btnText = 'Add Section';
                break;
            case 'remove':
                if (!!isSubmitting[id]) btnText = 'Removing Section...';
                else if (!!didSubmit[id]) btnText = 'Removed Section!';
                else btnText = 'Remove Section';
                break;
        }
        return btnText;
    };

    editSection = (section, shouldAdd) => {
        this.setState({
            isSubmitting: {[section]: true}
        });

        return this.props.onEditSection(section, shouldAdd)
            .then(() => {
                this.setState({
                    didSubmit: {[section]: false}
                })
            })
            .then(() => {
                this.setState({
                    isSubmitting: {[section]: false}, 
                    didSubmit: {[section]: true}
                })
            })
            .then(() => this.props.onComplete())
            .then(() => this.setState({
                didSubmit: {[section]: false}
            }))
            .catch(console.error);
    };

    render() {
        const {allSections} = this.props;
        const ids = Object.keys(allSections) || [];

        return <>
            {
                !!(ids.length) && ids.map(id => {
                    return this.makeRow(allSections[id]);
                })
            }
        </>
    }
}

export default ManageSections;