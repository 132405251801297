import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableWrapper from '../../Table/Wrapper';

import {connect} from 'react-redux';
import {fetchCampaignCoupons} from "../../../actions";
import {getCampaignCoupons, getCampaign} from "../../../selectors/campaigns";
import BasicCell from "../../Table/Cell/Basic";
import NumberCell from "../../Table/Cell/Number";
import LinkCell from "../../Table/Cell/Link";
import {formatDollar} from "../../../utilities";
import Button from '../../Common/Form/Button';
import CreateCouponToolbox from './CreateCouponToolbox';

class CampaignCouponsTable extends Component {

    static defaultProps = {
        showHeader: true,
    };

    static propTypes = {
        campaign: PropTypes.object,
        coupons: PropTypes.array,
        fetchCoupons: PropTypes.func,
        onClickAssign: PropTypes.func,
        showHeader: PropTypes.bool,
    };

    state = {
        showCreateToolbox: false,
    };

    refresh() {
        const {campaign, fetchCoupons} = this.props;
        return fetchCoupons(campaign.id);
    }

    componentDidMount() {
        this.refresh();
    }

    getColumns() {
        return [
            this.props.onClickAssign ? {
                key: 'AssignBtn',
                sortable: false,
                default: true,
                width: 140,
                title: 'Assign Coupon',
                cell: (row, column) => <BasicCell row={row} column={column}
                                                  value={<Button content={row.item.is_consumed ? 'Used' : 'Assign'}
                                                                 classes={['v3', 'btn-secondary', 'small', (row.item.is_consumed ? 'disabled' : '')]}
                                                                 onClick={row.item.is_consumed ? null : () => this.props.onClickAssign(row.item)}
                                                  />}
                />,

            } : null,
            {
                key: 'code',
                sortable: false,
                default: true,
                width: 300,
                title: 'Coupon Code',
                cell: (row, column) => <BasicCell row={row} column={column} value={row.item.code} />,
            },
            {
                key: 'monetary_value',
                sortable: false,
                default: true,
                width: 200,
                title: 'Value',
                cell: (row, column) => <NumberCell row={row} column={column} value={row.item.monetary_value} formatter={formatDollar} />,
            },
            {
                key: 'link_url',
                sortable: false,
                default: true,
                width: 300,
                title: 'URL',
                cell: (row, column) => <LinkCell row={row} column={column} value={row.item.link_url} target={'_blank'} href={row.item.link_url} />,
            },

        ];
    }

    getButtons() {
        return [
            <Button
                classes={['v3', 'btn-primary', (this.state.showCreateToolbox ? 'active' : '')]}
                content={'Add Coupon Code'}
                onClick={() => this.setState({showCreateToolbox: !this.state.showCreateToolbox})}
            />
        ];
    }

    getPopups() {
        const {showCreateToolbox} = this.state;
        const toolboxStyle = {
            width: 400,
            left: '50%',
            marginLeft: -200,
            top: 60,
        };

        return [

            showCreateToolbox
                ? <CreateCouponToolbox
                    style={toolboxStyle}
                    campaign={this.props.campaign}
                    onClose={() => this.setState({showCreateToolbox: false})}
                    onCreated={() => {
                        setTimeout(() => this.setState({showCreateToolbox: false}), 1000)
                    }}
                    />
                : null,

        ];
    }

    render() {
        return <TableWrapper
            showHeader={this.props.showHeader}
            stickySortTabs={true}
            items={this.props.coupons}
            columns={this.getColumns()}
            title={'Manage Coupons'}
            showFilters={false}
            showPagination={false}
            showTotals={false}
            buttons={this.getButtons()}
            popups={this.getPopups()}
        />
    }
}

const mapStateToProps = (state, props) => {
    return {
        campaign: getCampaign(state, props),
        coupons: getCampaignCoupons(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCoupons: (id) => dispatch(fetchCampaignCoupons(id)),
    };
};

const ConnectedCampaignCouponsTable = connect(mapStateToProps, mapDispatchToProps)(CampaignCouponsTable);

ConnectedCampaignCouponsTable.propTypes = {
    campaign: PropTypes.object.isRequired,
    onClickAssign: PropTypes.func,
    showHeader: PropTypes.bool,
};

export default ConnectedCampaignCouponsTable;