/**
 * Created by bkanber on 7/17/17.
 */

import {RECEIVE_WORKFLOWS, REQUEST_WORKFLOWS} from '../actions';

export function workflowsById(state = {items: {}, lastUpdated: null, isFetching: null},
                              action) {

    switch (action.type) {
        case REQUEST_WORKFLOWS:
            return Object.assign({}, state, {
                isFetching: true
            });

        case RECEIVE_WORKFLOWS:
            let newState = Object.assign({}, state, {
                isFetching: false,
                lastUpdated: action.receivedAt
            });

            for (let workflow of action.workflows) {
                newState.items[workflow.id] = workflow;
            }

            return newState;


        default:
            return state;
    }
}
