import React, {Component} from 'react';
import PropTypes from 'prop-types';
import VideoTile from '../Influencer/Requirements/Pieces/VideoTile';
import {formatNumber, makeFormData} from "../../utilities"
import ReactS3Uploader from 'react-s3-uploader';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchAuthenticated} from "../../actions/auth";

class SubmitVideo extends Component {

    static propTypes = {
        isUploading: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        refreshActivation: PropTypes.func.isRequired,
        fetchAuthenticated: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    };

    state = {
        showVideo: false,
        videos: [],
        isUploading: false,
        didUpload: false,
        error: null,
        uploadProgress: null,
    };
    /**
     * Render the body widget for uploading videos
     *
     * @returns {XML}
     */
    renderBody() {

        return (
            <div>
                {this.renderUploader()}
                {this.renderVideos()}
            </div>
        );

    }

    /**
     * Render video previews
     *
     * @returns {XML}
     */
    renderVideos() {

        const videos = this.state.videos || [];
        return <div className="clearfix videos-container">{videos.map(this.renderVideo.bind(this))}</div>
    }

    /**
     * Return a single video component used to render video previews
     *
     *
     * @see renderVideos()
     * @param video
     * @returns {XML}
     */
    renderVideo(video) {
        return (
            <VideoTile
                video={video}
            />
        );
    }

    handleGetSignedUrl(file, callback) {
        const params = {
            objectName: file.name,
            contentType: file.type,
        };
        const slot = window.Gator.getDashboardSlot();
        this.props.fetchAuthenticated(`/${slot}/api/video/_/signature`, {
            method: 'post',
            body: makeFormData(params)
        })
            .then(resp => resp.json())
            .then(json => {
                if (json.meta && json.meta.error) {
                    this.setState({error: json.meta.error});
                    this.clearInput();
                } else {
                    callback(json.data);
                }
            });
    }

    handleFinalizeUpload(signResult) {
        const slot = window.Gator.getDashboardSlot();
        this.props.fetchAuthenticated(`/${slot}/api/video/_/object`, {
            method: 'post',
            body: makeFormData({
                ...signResult,
                user_id: this.props.activation.user_id
            })
        })
            .then(resp => resp.json())
            .then(json => {

                if (json.meta && json.meta.error) {
                    this.setState({error: json.meta.error, isUploading: false, uploadProgress: null});
                } else {
                    // Call the onSubmit callback if it exists
                    if (this.props.onSubmit) {
                        this.props.onSubmit(json);
                    }
                    // Add the video object to state
                    this.setState({
                        videos: [...this.state.videos, json.data],
                        isUploading: false,
                        didUpload: true
                    });

                }
            });

    }
    renderUploader() {

        return (

            <div className="form-group">
                <label><strong>Choose a Video to Upload</strong></label>
                {this.renderUploadProgress()}
                <ReactS3Uploader
                    getSignedUrl={this.handleGetSignedUrl.bind(this)}
                    className="form-control"
                    accept="video/mp4,video/x-m4v,.mp4,video/*"
                    uploadRequestHeaders={{}}
                    onProgress={pct => {
                        this.setState({uploadProgress: pct})
                    }}
                    onError={(msg) => {
                        this.setState({error: msg});
                    }}
                    onFinish={this.handleFinalizeUpload.bind(this)}
                    preprocess={(file, next) => {
                        this.setState({isUploading: true, error: null, uploadProgress: null});
                        next(file);
                    }}
                    inputRef={ref => this.inputRef = ref}
                    disabled={this.props.disabled}
                />
                <p className="help-block">Upload an MP4 video.</p>
            </div>

        );
    }

    toggleShowVideoUploader() {
        this.setState({ showVideo: !this.state.showVideo });
    }

    renderUploadProgress() {
        const {isUploading, uploadProgress, didUpload} = this.state;

        if (!didUpload && (!isUploading || !uploadProgress)) {
            return null;
        }

        let alertText = <span>Uploading video ({formatNumber(uploadProgress)}%)...</span>;

        if (uploadProgress && uploadProgress > 99) {
            alertText = <span>Finishing upload, please wait... <i className="fa fa-spinner fa-spin" /> </span>;
        }

        if (didUpload) {
            alertText = <span>Video uploaded! Save the draft below to add the video.</span>;
        }

        return (
            <div
                className="tidal-progress"
                style={{
                    position: 'relative',
                    height: 50,
                    background: '#eee',
                    marginBottom: 20,
                }}
            >

                <div className="tidal-progress-inner"
                     style={{
                         width: uploadProgress + '%',
                         background: '#6C0',
                         position: 'absolute',
                         top: 0,
                         left: 0,
                         height: 50,
                     }}
                />

                <div className="tidal-progress-label"
                     style={{
                         lineHeight: '50px',
                         height: 50,
                         position: 'absolute',
                         paddingLeft: 20,
                         color: '#666',
                     }}
                >{alertText}</div>

            </div>
        );
    }

    render() {
        const iconClassName = this.state.showVideo ? 'angle-down' : 'angle-right';
        return (
            <div className="">
                <h5
                    onClick={this.toggleShowVideoUploader.bind(this)}
                    className="v3 bold action-pane-title"
                    style={{cursor: 'pointer'}}
                >Add Videos <i className={`v3 icon ${iconClassName}`}></i></h5>

                {this.state.showVideo && (<div>
                    {this.renderBody()}
                </div>)}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAuthenticated,
}, dispatch);
export default connect(null, mapDispatchToProps)(SubmitVideo);