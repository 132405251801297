/**
 * Created by bkroger on 4/30/18.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getCurrentChannel} from "../../../../selectors/presence";
import moment from 'moment';
import {formatDollar} from '../../../../utilities';
import DOMPurify from 'dompurify';
import Alert from "../../../Common/Alert";

class InfluencerRequirementsCampaignHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showGuidelines: false
        };

        this.handleToggleGuidelines = this.handleToggleGuidelines.bind(this);
    }

    getTranslator() {
        if (this.props.translate) {
            return this.props.translate;
        }
        return (k,v) => v;
    }
    handleToggleGuidelines() {
        this.setState({
            showGuidelines: !this.state.showGuidelines
        })
    }

    renderGuidelinesText() {
        return {__html: DOMPurify.sanitize(this.props.activation.settings.content_guidelines)};
    }
    renderComplianceGuidelinesText() {
        return {__html: DOMPurify.sanitize(this.props.activation.settings.other_post_guidelines)};
    }

    renderDueDates() {
        const _t = this.getTranslator();
        const {activation} = this.props;
        let nextDueDate;

        const wdDraftsDue = _t('campaign.header.drafts_due', 'Drafts Due');
        const wdPostsDue = _t('campaign.header.posts_due', 'Posts Due');

        const draftDueDate = typeof activation.settings !== 'undefined' && activation.settings.draft_deadline_at !== null ? activation.settings.draft_deadline_at.date : '';
        const postDueDate = typeof activation.settings !== 'undefined' && activation.settings.post_deadline_at !== null ? activation.settings.post_deadline_at.date : '';

        if (typeof activation.settings !== 'undefined' && activation.settings.use_deadlines) {
            if (draftDueDate && !postDueDate) {
                nextDueDate = <div className="meta-item"><span className="v3 h7 bold meta-label">{wdDraftsDue}: </span> <span className="v3 h7 bold meta-value"> {moment(draftDueDate).format('MMM Do, YYYY')}</span></div>;
            } else if (postDueDate) {
                nextDueDate = <div className="meta-item"><span className="v3 h7 bold meta-label">{wdPostsDue}: </span><span className="v3 h7 bold meta-value"> {moment(postDueDate).format('MMM Do, YYYY')}</span></div>;
            } else {
                nextDueDate = null;
            }
        }

        return nextDueDate;
    }

    renderOffer() {
        const _t = this.getTranslator();
        const {activation} = this.props;

        if ((activation.settings || {}).use_payments && activation.payment_offered && activation.payment_offered > 0) {
            return (
                <div className="meta-item">
                    <span className="v3 h7 bold meta-label">{_t('campaign.header.offer', 'Offer')}: </span>
                    <span className="v3 h7 bold meta-value">{formatDollar(activation.payment_offered, 2)}</span>
                </div>
            );
        }
    }

    render() {

        const _t = this.getTranslator();
        const {activation, campaign, channel} = this.props;

        const campaignImageUrl = campaign.image ? campaign.image : '';
        const clientLogoUrl = channel.contribute_logo_url ? channel.contribute_logo_url : channel.manage_logo_url;

        const guidelinesBtnText = this.state.showGuidelines ? _t('campaign.header.hide_details', 'Hide Details') : _t('campaign.header.show_details', 'See Details');
        const campaignName = campaign.isFetching ? _t('words.loading_dots', 'Loading...') : campaign.name;
        const campaignDescription = campaign.isFetching ? _t('words.loading_dots', 'Loading...') : campaign.description;
        const hasComplianceGuidelines = ((activation.settings || {}).other_post_guidelines || '').length > 0;

        return (
            <div className="campaign-header">

                <div className="campaign-header-top">
                    <h3 className="v3 bold campaign-name" data-tooltip={"<strong>" + campaignName + "</strong>" + "<br><br>" + campaignDescription}>{campaignName}</h3>
                    <div className="campaign-header-images">
                        {clientLogoUrl &&
                        <div className="header-image client-logo"
                             style={{backgroundImage: 'url(' + clientLogoUrl + ')'}}>
                        </div>
                        }
                        {campaignImageUrl &&
                        <div className="header-image campaign-image"
                             style={{backgroundImage: 'url(' + campaignImageUrl + ')'}}>

                        </div>
                        }
                    </div>
                </div>

                <div className="campaign-header-bottom">

                    <div className="meta-wrapper">
                        {typeof activation !== 'undefined' &&
                            this.renderDueDates()
                        }
                        {this.renderOffer()}
                    </div>

                    {typeof activation.settings !== 'undefined' && activation.settings.content_guidelines &&
                    <div className="guidelines-btn">
                        <a role="button" className="v3 btn btn-primary bold small" onClick={() => this.handleToggleGuidelines()}>{guidelinesBtnText}</a>
                    </div>
                    }

                </div>

                {this.renderGuidelines()}

            </div>
        )
    }

    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }

    renderGuidelines() {
        const _t = this.getTranslator();
        const {activation, campaign, channel} = this.props;

        const hasComplianceGuidelines = ((activation.settings || {}).other_post_guidelines || '').length > 0;

        if (!this.state.showGuidelines) {
            return null
        }

        if (this.isBlockedByNDA()) {
            return (
                <div className="campaign-header-guidelines">
                    {this.renderNDAAlert()}
                </div>
            );
        }

        return (
            <div className="campaign-header-guidelines">
                <h3 className="v3 bold">{_t('campaign.header.guidelines', 'Guidelines')}</h3>
                {typeof activation.settings !== 'undefined' && activation.settings.content_guidelines &&
                    <div className="guidelines-text-wrapper">
                        <p className="v3 h6 light" dangerouslySetInnerHTML={this.renderGuidelinesText()}/>
                        {hasComplianceGuidelines && (
                            <>
                                <h4 className="v3 h4 topmargin">{_t('campaign.header.other_guidelines', 'Other Guidelines')}</h4>
                                <p className="v3 h6 light"
                                   dangerouslySetInnerHTML={this.renderComplianceGuidelinesText()}/>
                            </>
                        )}
                    </div>
                }
            </div>
        );
    }

}



const mapStateToProps = (state) => {

    return {
        channel: getCurrentChannel(state)
    };

};

const ConnectedInfluencerRequirementsCampaignHeader = connect(mapStateToProps)(InfluencerRequirementsCampaignHeader);

export default ConnectedInfluencerRequirementsCampaignHeader;