import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from "../Table/Table";
import DateCell from "../Table/Cell/Date";
import {makeCellKey, makeMomentFromDate} from "../../utilities";
import BasicCell from "../Table/Cell/Basic";
import {getCampaigns} from "../../selectors/campaigns";
import {connect} from 'react-redux';
import _find from 'lodash/find';
import LinkCell from "../Table/Cell/Link";

class InvitationsTable extends Component {

    static propTypes = {
        invitations: PropTypes.array,
        campaigns: PropTypes.array,
    };

    getColumns() {
        return [
            {
                key: 'created_at',
                title: 'Created',
                default: true,
                sortable: false,
                width: 150,
                cell: (row, column) => <DateCell column={column} row={row} key={makeCellKey(row, column)} value={row.item.created_at} />
            },
            {
                key: 'target',
                title: 'Invited To',
                default: true,
                sortable: false,
                width: 200,
                cell: (row, column) => {

                    let content;
                    if (row.item.target_type === 'Tidal\\Channel') {
                        content = 'Influencer Hub';
                    } else if (row.item.target_type === 'Tidal\\Campaign') {
                        const campaign = _find(this.props.campaigns, {id: row.item.target_id});
                        content = (campaign && campaign.name) ? campaign.name : 'Campaign';
                    }

                    return (
                        <BasicCell column={column} row={row} key={makeCellKey(row, column)}
                                   value={content} />
                    );
                }
            },
            {
                key: 'shortlink_url',
                title: 'Invitation Shortlink',
                default: true,
                sortable: false,
                width: 250,
                cell: (row, column) => {
                    return (
                        <LinkCell row={row} column={column} key={makeCellKey(row, column)}
                                  value={row.item.shortlink.shortlink}
                                  href={row.item.shortlink.shortlink}
                                  target={'_blank'}
                        />
                    );
                }
            },
            {
                key: 'shortlink_clicked',
                title: 'Invitation Clicked',
                default: true,
                sortable: false,
                width: 200,
                cell: (row, column) => {
                    let content = '';
                    if (row.item.shortlink.last_click_at) {
                        content = makeMomentFromDate(row.item.shortlink.last_click_at).format('MMM Do, YYYY');
                    }
                    return <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={content} />;
                }
            }
        ];
    }

    render() {
        return (
            <Table
                columns={this.getColumns()}
                items={this.props.invitations}
            />
        );

    }
}

const mapStateToProps = (state, props) => {
    return {
        campaigns: getCampaigns(state, props)
    };
};

export default connect(mapStateToProps)(InvitationsTable);