import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SimplePostCard from "../../../Post/SimplePostCard";
import {mapProfileContentToSimplePostCard} from "../../../../utilities/post";

class GenericProfileContentPane extends Component {
    static propTypes = {
        content: PropTypes.array.isRequired,
    };

    renderPost(post) {
        return (
            <SimplePostCard
                key={`post-card-${post.source_id}`}
                post={post}
                onClickPost={() => {
                    window.open(post.permalink, '_blank');
                }}
            />
        );
    }

    render() {
        return (
            <div className="generic-profile-pane-wrapper padding-top">
                {
                    mapProfileContentToSimplePostCard(this.props.content || [], this.props)
                        .map(post => this.renderPost(post))
                }
            </div>
        );
    }
}

export default GenericProfileContentPane;
