import React, {useState, useEffect} from 'react';
import Avatar from "../Common/Avatar";
import {findFirstValidImage} from "../../utilities";
import PortfolioReachWidget from "./PublicPortfolio/PortfolioReachWidget";
import PortfolioByline from "./PublicPortfolio/PortfolioByline";
import PortfolioContent from "./PublicPortfolio/PortfolioContent";
import PortfolioAudience from "./PublicPortfolio/PortfolioAudience";
import _get from "lodash/get";
import PortfolioInteractivity from "./PublicPortfolio/PortfolioInteractivity";
import he from 'he';
import ReactMarkdown from "react-markdown";


/**
 * This was broken out from PublicPortfolioCard separately because the Card component is used as
 * a renderer and that gives hook/useState errors. So we can't useState directly in PublicPortfolioCard.
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
function FallbackAvatar({item, printMode=false}) {

    const profile = item.profile;
    const overrideImage = _get(item, 'settings.custom_profile_image', null);
    const allImgs = [overrideImage, profile.picture, ...profile.images];
    const [profilePic, setProfilePic] = useState(null);
    useEffect(function() {
        findFirstValidImage(allImgs).then(url => setProfilePic(url));
    }, []);

    return <Avatar image={profilePic} useProxy={printMode} />
}

function PublicPortfolioCard({item, list, addNote, addRating, printMode=false, audienceChartLimit=3}) {

    const getSetting = (key, defaultValue) => _get(item, `settings.${key}`, _get(list, `settings.${key}`, defaultValue));
    const showAudience = getSetting('show_audience', true);
    const showAge = getSetting('show_age', true);
    const profile = item.profile;
    let bioText = _get(item, 'settings.custom_profile_text', profile.text || 'No information available.');
    if (!bioText || bioText.length === 0) {
        bioText = profile.text;
    }
    if (!bioText) {
        bioText = 'No information available.';
    }
    const customDescription = _get(item, 'settings.custom_description', null);
    let name = _get(item, 'settings.custom_name');
    if (!name) {
        name = profile.name;
    }

    return (
        <div className="list-portfolio-card-wrapper">
            <div className="gradient">
                <div className="profile-wrapper">
                    <PortfolioInteractivity addNote={addNote} addRating={addRating} item={item} />
                    <div className="avatar-wrapper">
                        <FallbackAvatar item={item} printMode={printMode} />
                    </div>
                    <div className="profile">
                        <h2 className="v3">{he.decode(name)}</h2>
                        <PortfolioByline item={item} showAge={showAge} />
                        <div className="bio"><ReactMarkdown children={'**About Me**: ' + he.decode(bioText)} /></div>
                        {customDescription && (
                            <div className="bio"><ReactMarkdown children={'**Justification**: ' +he.decode(customDescription)} /></div>
                        )}
                    </div>
                </div>


            </div>
            <div className="additional-info-wrapper">
                <div className="reach-wrapper">
                    <PortfolioReachWidget item={item} list={list} />
                </div>
                <div className="content-wrapper">
                    <PortfolioContent item={item} list={list} printMode={printMode} />
                </div>
                {showAudience && (
                    <div className="audience-wrapper">
                        <PortfolioAudience item={item} list={list} limit={audienceChartLimit} />
                    </div>
                )}
            </div>
        </div>
    );

}

export default PublicPortfolioCard;
