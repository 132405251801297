import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {preventDefaultIfPossible, formatNumber} from "../../utilities";
import * as URI from 'urijs';
import Checkbox from '../Common/Checkbox';
import {fetchAuthenticated} from "../../actions/auth";
import {fetchBadges, fetchLists} from "../../actions";

class EmailAddressBook extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            didInvalidateQuery: false,
            page: 1,
            query: {
                q: '',
                list: '',
                b: ''
            },
            users: [],
            showFilters: false,
        };

    }

    handleUpdateQueryField(e) {

        const {name, value} = e.target;
        const query = {...this.state.query, [name]: value};
        this.setState({didInvalidateQuery: true, query});

    }

    isUserSelected(userId) {
        return this.getSelectedUserIndex(userId) > -1;
    }

    getSelectedUserIndex(userId) {
        return this.props.selected
            .filter(u => !!u.mongo_id)
            .map(u => u.mongo_id)
            .indexOf(userId);
    }

    handleCommitSearch(e) {
        preventDefaultIfPossible(e);
        this.fetchCurrentQuery();

    }

    fetchNextPage() {

        return this.setState({
            page: this.state.page + 1
        }, () => this.fetchCurrentQuery());

    }

    fetchCurrentQuery() {

        if (this.state.didInvalidateQuery) {
            this.setState({
                isFetching: true,
                didInvalidateQuery: false,
                users: []
            });

        } else {
            this.setState({isFetching: true});
        }

        const payload = {
            page: this.state.page,
        };

        Object.entries(this.state.query).forEach((o) => {
            if (o[1] !== '') {
                payload[o[0]] = o[1];
            }
        });

        const url = URI(`/manage/do/widget/tools/get_users`)
            .query(payload)
            .toString();

        return this.props.fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => {
                const users = [].concat(
                    this.state.users,
                    json.map(item => {
                        return {
                            type: 'Tidal\\User',
                            mongo_id: item.id,
                            name: item.name
                        };
                    })
                );
                this.setState({users, isFetching: false});
            });

    }


    componentDidMount() {

        this.fetchCurrentQuery();
        this.props.fetchLists();
        this.props.fetchBadges();

    }

    getTableUsers() {
        const {users} = this.state;
        const {selected} = this.props;
        return [].concat(users);
    }

    renderTable() {
        const {isFetching} = this.state;
        return(

            <div className="fake-table">
                {
                    isFetching
                    ? (
                            <div className="fake-li padded">Loading...</div>
                        )
                    : this.getTableUsers().map(this.renderRow.bind(this))
                }
            </div>

        );
    }

    renderFiltersSection() {
        const {showFilters, query} = this.state;
        const {badges, lists} = this.props;
        if (!showFilters) return null;

        return (

            <div className="toolbox-section tidal-form">

                <div className="form-group">
                    <input
                        className="form-control"
                        type="text"
                        name="q"
                        placeholder="Name, email, or blog..."
                        value={query.q}
                        onChange={this.handleUpdateQueryField.bind(this)}
                    />
                </div>

                <div className="form-group">
                    <select
                        className="form-control"
                        name="b"
                        value={query.b}
                        onChange={this.handleUpdateQueryField.bind(this)}
                    >
                        <option value=''>- Filter by Badge -</option>
                        {badges.map(badge => <option value={badge.mongo_id}>Badge: {badge.name}</option>)}
                    </select>
                </div>

                <div className="form-group">
                    <select
                        className="form-control"
                        name="list"
                        value={query.list}
                        onChange={this.handleUpdateQueryField.bind(this)}
                    >
                        <option value=''>- Filter by List -</option>
                        {lists
                            .filter(list => !list.is_hidden)
                            .map(list => <option value={list.id}>List: {list.name}</option>)}
                    </select>
                </div>

                <button
                    onClick={this.handleCommitSearch.bind(this)}
                    className="btn tidal-btn btn-default fullwidth"><i className="fa fa-search" /> Search</button>
            </div>

        );

    }

    handleToggleUser(user) {

        const isSelected = this.isUserSelected(user.mongo_id);
        if (isSelected) {
            return this.unselectUser(user);
        } else {
            return this.selectUser(user);
        }

    }

    unselectUser(user) {

        const users = this.props.selected.slice(0);
        const i = this.getSelectedUserIndex(user.mongo_id);
        users.splice(i, 1);
        if (this.props.onChange) {
            this.props.onChange(users);
        }
    }

    selectUser(user) {

        const users = [].concat(this.props.selected, [user]);
        if (this.props.onChange) {
            this.props.onChange(users);
        }

    }

    renderRow(user) {
        const isSelected = this.isUserSelected(user.mongo_id);
        return (
            <div className="fake-li" style={{paddingLeft: 10}}>
                <Checkbox
                    key={user.mongo_id}
                    checked={isSelected}
                    label={ <span>{user.name} <small>{user.email}</small></span> }
                    onClick={this.handleToggleUser.bind(this, user)}
                    useBoldTitle={false}
                />
            </div>
        );
    }

    renderFiltersTitle() {

        const {showFilters} = this.state;
        const iconClass = showFilters ? 'fa fa-angle-down' : 'fa fa-angle-right';

        return (

            <div
                className="toolbox-section"
            >
                <h2>
                    <i className={iconClass} /> <a
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({showFilters: !showFilters})
                            }}
                        href="#">Search &amp; Filters</a>
                </h2>
            </div>

        );

    }

    handleToggleSelectAll() {
        const {selectAll} = this.state;
        const {onChange} = this.props;
        if (selectAll) {
            // Select none
            if (onChange) {
                onChange([]);
            }
        } else {
            // Select all
            if (onChange) {
                onChange(
                    [].concat(
                        this.props.selected.slice(0),
                        this.state.users.filter(u => !this.isUserSelected(u.id))
                    )
                );
            }
        }

        this.setState({selectAll: !selectAll});

    }

    renderUtils() {
        const {selectAll, isFetching, users} = this.state;
        if (isFetching) return null;
        const label = selectAll
            ? <span><strong>Select None</strong></span>
            : <span><strong>Select All {formatNumber(users.length)}</strong> <small>Max. 20,000</small></span>;

        return (
            <div className="fake-table">
                <div className="fake-li" style={{paddingLeft: 10}}>
                    <Checkbox
                        checked={selectAll}
                        label={label}
                        onClick={this.handleToggleSelectAll.bind(this)}
                    />
                </div>
            </div>
        )
    }

    render() {

        const {onClose} = this.props;

        return (
            <div className="toolbox email-address-book">
                <h2 className={'title'}>
                    <i className="fa fa-address-book" /> Address Book
                </h2>

                {onClose && <a href="#" className="close absolute" onClick={onClose}><i className="fa fa-times" /></a>}

                {this.renderUtils()}
                {this.renderTable()}
                {this.renderFiltersTitle()}
                {this.renderFiltersSection()}

            </div>

        );

    }
}

EmailAddressBook.defaultProps = {
    selected: [],
    onChange: () => {}
};

EmailAddressBook.propTypes = {
    selected: PropTypes.array,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    lists: PropTypes.array,
    badges: PropTypes.array,
    fetchAuthenticated: PropTypes.func,
    fetchLists: PropTypes.func,
    fetchBadges: PropTypes.func,
};

const mapStateToProps = ({listsById, badgesById}) => {
    return {
        lists: Object.values(listsById.lists || {}),
        badges: Object.values(badgesById.items || {}),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
        fetchLists: () => dispatch(fetchLists()),
        fetchBadges: () => dispatch(fetchBadges()),
    };
};

const ConnectedEmailAddressBook = connect(mapStateToProps, mapDispatchToProps)(EmailAddressBook);

export default ConnectedEmailAddressBook;

