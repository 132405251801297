import {makeFormData, fetchAuthenticated} from "../utilities";

export const REQUEST_CAMPAIGN_REQUIREMENTS = 'REQUEST_CAMPAIGN_REQUIREMENTS';
export const RECEIVE_CAMPAIGN_REQUIREMENTS = 'RECEIVE_CAMPAIGN_REQUIREMENTS';
export const UPDATE_CAMPAIGN_REQUIREMENT = 'UPDATE_CAMPAIGN_REQUIREMENT';
export const UPDATED_CAMPAIGN_REQUIREMENT = 'UPDATED_CAMPAIGN_REQUIREMENT';
export const DELETE_CAMPAIGN_REQUIREMENT = 'DELETE_CAMPAIGN_REQUIREMENT';
export const DELETED_CAMPAIGN_REQUIREMENT = 'DELETED_CAMPAIGN_REQUIREMENT';
export const CREATE_CAMPAIGN_REQUIREMENT = 'CREATE_CAMPAIGN_REQUIREMENT';
export const CREATED_CAMPAIGN_REQUIREMENT = 'CREATED_CAMPAIGN_REQUIREMENT';

export function createCampaignRequirement(campaignId, payload) {
    return function (dispatch) {
        dispatch({
            type: CREATE_CAMPAIGN_REQUIREMENT,
            campaignId,
            payload
        });


        return fetchAuthenticated(`/manage/api/campaign/${campaignId}/requirement`,
            {
                credentials: 'include',
                method: 'post',
                body: makeFormData({...payload, _method: 'post'})
            })
            .then(resp => resp.json())
            .then(json => dispatch({
                type: CREATED_CAMPAIGN_REQUIREMENT,
                campaignId,
                payload,
                data: json.data
            }))
            .then(() => dispatch(fetchCampaignRequirements(campaignId)));
    }

}

export function deleteCampaignRequirement(campaignId, requirementId, payload = {}) {

    return function (dispatch) {
        dispatch({
            type: DELETE_CAMPAIGN_REQUIREMENT,
            campaignId,
            requirementId,
            payload
        });


        return fetchAuthenticated(`/manage/api/campaign/${campaignId}/requirement/${requirementId}`,
            {
                credentials: 'include',
                method: 'post',
                body: makeFormData({...payload, _method: 'delete'})
            })
            .then(resp => resp.json())
            .then(json => dispatch({
                type: DELETED_CAMPAIGN_REQUIREMENT,
                campaignId,
                requirementId,
                payload,
                data: json.data
            }))
            .then(() => dispatch(fetchCampaignRequirements(campaignId)));
    }
}

export function updateCampaignRequirement(campaignId, requirementId, payload) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_CAMPAIGN_REQUIREMENT,
            campaignId,
            requirementId,
            payload
        });


        return fetchAuthenticated(`/manage/api/campaign/${campaignId}/requirement/${requirementId}`,
            {
                credentials: 'include',
                method: 'post',
                body: makeFormData({...payload, _method: 'patch'})
            })
            .then(resp => resp.json())
            .then(json => dispatch({
                type: UPDATED_CAMPAIGN_REQUIREMENT,
                campaignId,
                requirementId,
                payload,
                data: json.data
            }))
            .then(() => dispatch(fetchCampaignRequirements(campaignId)));
    }
}

export function fetchCampaignRequirements(campaignId) {
    return function (dispatch) {
        dispatch({
            type: REQUEST_CAMPAIGN_REQUIREMENTS,
            id: campaignId
        });

        return fetchAuthenticated(`/${window.Gator.getDashboardSlot()}/api/campaign/${campaignId}/requirement`, {credentials: 'include'})
            .then(response => response.json())
            .then(json => dispatch({type: RECEIVE_CAMPAIGN_REQUIREMENTS, id: campaignId, data: json.data}));
    }
}