import * as ActionTypes from './types';
import {fetchAuthenticated, makeFormData} from "../utilities";

const url = '/manage/api/channel/_/section';

export const fetchSections = () => {
    return function (dispatch) {
        dispatch({type: ActionTypes.REQUEST_SECTIONS});
        return fetchAuthenticated(url, {method: 'get'})
            .then(res => res.json())
            .then(json => dispatch({
                type: ActionTypes.RECEIVE_SECTIONS,
                data: json.data,
                meta: json.meta
            }))
            .catch(console.error)
    };
};

export const createSection = (params) => {
    return function (dispatch) {
        dispatch({type: ActionTypes.CREATE_SECTION});
        return fetchAuthenticated(url, {method: 'post', body: makeFormData({...params})})
            .then(res => res.json())
            .then(json => dispatch({
                type: ActionTypes.CREATED_SECTION,
                data: json.data,
                meta: json.meta
            }))
            .catch(console.error)
    };
};

export const updateSection = (id, params) => {
    return function (dispatch) {
        const postUrl = `${url}/${id}`;
        const payload = {...params, _method: 'put'};
        dispatch({type: ActionTypes.UPDATE_SECTION, id});
        return fetchAuthenticated(postUrl, {method: 'post', body: makeFormData(payload)})
            .then(res => res.json())
            .then(json => dispatch({
                type: ActionTypes.UPDATED_SECTION,
                data: json.data,
                meta: json.meta
            }))
            .catch(console.error)
    };
};