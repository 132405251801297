import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {c3ColorPattern, formatNumber, ucwords} from "../../../utilities";
import Tooltip from "../../Common/Tooltip";
import TinyThermometer from "../../Common/TinyThermometer";

const ICON_MAP = {
    titles: "book",
    organizations: "copyright",
    people: "users",
    events: "calendar",
    products: "shopping-cart",
    locations: "map-marker",
    hashtags: "hashtag"
};

class AudienceMentionExplorer extends Component {
    static propTypes = {
        titles: PropTypes.object,
        organizations: PropTypes.object,
        people: PropTypes.object,
        events: PropTypes.object,
        products: PropTypes.object,
        locations: PropTypes.object,
        hashtags: PropTypes.object,

        limit: PropTypes.number,
        minValue: PropTypes.number,
        upscale: PropTypes.number,
    };

    static defaultProps = {
        limit: 10,
        minValue: 0,
        upscale: 0.8,
    };

    state = {
        titles: true,
        organizations: true,
        people: true,
        events: true,
        products: true,
        locations: true,
        hashtags: true,
    };


    getData() {
        let out = [];
        for (let type in ICON_MAP) {
            if (!this.state[type]) {
                continue;
            }

            for (let key in this.props[type]) {
                out.push({
                    name: key,
                    value: this.props[type][key],
                    icon: ICON_MAP[type]
                });
            }
        }

        out = out.sort((a, b) => {
            return a.value > b.value ? -1 : 1;
        });

        out = out.filter(item => {
            return item.value > this.props.minValue;
        });

        out = out.slice(0, this.props.limit);

        return out;
    }

    renderBars() {
        return this.getData().map((item, index) => this.renderBar(item, index));
    }

    getScaleFactor() {
        if (!this.props.upscale) {
            return 1;
        }

        const max = this.getData()[0].value;
        if (!max) {
            return 1;
        }

        return this.props.upscale / max;

    }
    renderBar(item, index) {
        const color = c3ColorPattern.pattern[index];
        const scale = this.getScaleFactor();
        return (
            <div className="item" key={`category-index-${index}-${item.name}`}>
                <div className="item-name"><i className={`v3 icon ${item.icon}`} style={{marginRight: 5}}/> {item.name}</div>
                <div className="item-value">
                    <Tooltip title={`${item.name}: ${formatNumber(100 * item.value, 1)}%`}>
                        <TinyThermometer pct={100 * scale * item.value} color={color} />
                    </Tooltip>
                </div>
            </div>
        );
    }

    renderPills() {
        return (
            <div className="switches" style={{position: 'absolute', top: 0, right: 20}}>
                {Object.keys(ICON_MAP).map(type => {
                    let style = {marginLeft: 10, fontSize: 12, cursor: 'pointer'};
                    let tooltipTitle = ucwords(type);

                    if (this.state[type]) {
                        style.color = '#333';
                        tooltipTitle = 'Hide ' + tooltipTitle;
                    } else {
                        style.color = '#BBB';
                        tooltipTitle = 'Show ' + tooltipTitle;
                    }

                    const icon = (
                        <Tooltip
                            title={tooltipTitle}
                            key={`pill-icon-for-${type}`}
                        >
                            <i
                                onClick={() => this.setState({[type]: !this.state[type]})}
                                style={style}
                                className={"v3 icon " + ICON_MAP[type]}
                            />
                        </Tooltip>
                    );
                    return icon;
                })}
            </div>
        );
    }

    render() {
        return (
            <div className="chart-widget-wrapper">
                <div className="widget" style={this.props.style}>
                    <div className="title">
                        <div className="icon left">
                            <i className={`v3 icon comments`}/>
                        </div>
                        <h2>Top Mentions</h2>
                        {this.renderPills()}
                    </div>
                    <div className="content padded clearfix">
                        <div className="category-chart-wrapper">
                            {this.renderBars()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AudienceMentionExplorer;