import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../Common/Alert';
import Button from '../../Common/Form/Button';

class BulkInvitationForm extends Component {

    static defaultProps = {
        isUpdating: false,
    };

    static propTypes = {
        onInvite: PropTypes.func,
        activationIds: PropTypes.array.isRequired,
        isUpdating: PropTypes.bool,
    };

    renderAlert() {

        const count = this.props.activationIds.length;
        const influencers = count === 1 ? 'user' : 'users';
        return [
            <Alert
                classes={['info']}
                content={<span>Invite {count} {influencers} to this campaign. Doing this will remove the users from any waitlists and reactivate the user if their activation was canceled. <strong>Make sure you've set payment amounts for all users before inviting!</strong></span>}
            />
        ];


    }

    renderButtons() {
        const {isUpdating} = this.props;

        const style = {
            textAlign: 'center',
            padding: 20
        };

        const count = this.props.activationIds.length;
        const influencers = count === 1 ? 'User' : 'Users';

        return (
            <div style={style}>
                <Button
                    content={`Invite ${count} ${influencers}`}
                    classes={['v3', 'large', 'btn-primary', (isUpdating ? 'disabled' : '')]}
                    onClick={this.props.onInvite}
                />
            </div>
        );

    }

    render() {

        return (
            <div className="activation-status-change-form">
                {this.renderAlert()}
                {this.renderButtons()}
            </div>
        );

    }
}

export default BulkInvitationForm;
