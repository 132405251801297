import React, {useState} from 'react';
import Form from "../Common/Form/Form";
import _pick from 'lodash/pick';

const ALLOWED_FIELDS = [
    'slug',
    'name',
    'url',
    'followers_count',
    'friends_count',
    'avg_engagement',
    'avg_engagement_pct',
    'profile_text',
    'profile_website'
]

function EditSocialForm({social, onSave}) {
    const [form, setForm] = useState(_pick(social, ALLOWED_FIELDS));
    const handleSave = () => {

        const context = {
            ..._pick(form, ALLOWED_FIELDS),
            social_id: social.id
        };

        if (onSave) {
            onSave(context);
        }

    };
    return (

        <Form
            onFieldChange={(k, v) => setForm(prevForm => ({...prevForm, [k]: v}))}
            values={form}
            fields={[

                {
                    type: 'text',
                    name: 'slug',
                    title: 'Username'
                },
                {
                    type: 'text',
                    name: 'name',
                    title: 'Account Name'
                },
                {
                    type: 'text',
                    name: 'url',
                    title: 'Social URL'
                },
                {
                    type: 'number',
                    name: 'followers_count',
                    title: 'Followers Count'
                },
                {
                    type: 'number',
                    name: 'friends_count',
                    title: 'Friends/Following Count'
                },
                {
                    type: 'number',
                    name: 'avg_engagement',
                    title: 'Avg Engagements Per Post'
                },
                {
                    type: 'number',
                    name: 'avg_engagement_pct',
                    title: 'Avg Engagement Rate'
                },
                {
                    type: 'textarea',
                    name: 'profile_text',
                    title: 'Profile Text'
                },
                {
                    type: 'text',
                    name: 'profile_website',
                    title: 'Profile Website URL'
                },
                {
                    type: 'button',
                    title: 'Save',
                    options: {
                        hideTitle: true,
                        onClick: handleSave,
                        classes: ['v3 btn-primary']
                    }
                }
            ]}
        />



    );
}

export default EditSocialForm;