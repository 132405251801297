import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CatalogProductsTable from '../../components/Products/CatalogProductsTable';
import {fetchProductCatalogs, fetchCampaignProducts, updateCampaignProduct} from '../../actions/products';
import {createChannelInteraction} from '../../actions/channel';
import * as ProductSelectors from '../../selectors/products';

const mapStateToProps = (state, props) => {
    return {

        catalog: ProductSelectors.getCatalog(state, props),
        products: ProductSelectors.getCatalogProducts(state, props),

    };
};

const mapDispatchToProps = (dispatch, props) => {
    const campaignId = props.campaignId ? props.campaignId : props.campaign.id;
    return {

        fetchCatalogs: () => dispatch(fetchProductCatalogs()),
        fetchProducts: () => dispatch(fetchCampaignProducts(campaignId)),
        updateCampaignProduct: (cId, productId, params) => dispatch(updateCampaignProduct(cId, productId, params)),
        createInteraction: (type, ctx) => dispatch(createChannelInteraction(type, ctx)),

    };
};

const ConnectedCampaignProductsTable = connect(mapStateToProps, mapDispatchToProps)(CatalogProductsTable);

ConnectedCampaignProductsTable.propTypes = {
    campaignId: PropTypes.number.isRequired,
    catalogId: PropTypes.number.isRequired,
};

export default ConnectedCampaignProductsTable;