import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import {preventDefaultIfPossible, formatDollar} from "../../../utilities";
import {EMV_INDUSTRY_DEFAULTS, EMV_TIDAL_DEFAULTS, EMV_CREATORIQ_DEFAULTS} from '../../../utilities/emv';

const MIN_POST_VALUE = 0;
const MAX_POST_VALUE = 2000;
const MIN_ENGAGEMENT_VALUE = 0;
const MAX_ENGAGEMENT_VALUE = 10;
const MIN_IMPRESSION_VALUE = 0;
const MAX_IMPRESSION_VALUE = 2;

const STEP_CENT = 0.01;
const STEP_TENCENT = 0.1;
const STEP_DOLLAR = 1;
const STEP_TENDOLLAR = 10;

const SECTIONS = [
    {
        title: "Content Value",
        key: "showContentValue",
        fields: [

            {
                title: "Value of Instagram Post",
                key: 'emv_num_instagram_posts',
                step: STEP_TENDOLLAR,
                min: MIN_POST_VALUE,
                max: MAX_POST_VALUE
            },
            {
                title: "Value of Instastory",
                key: 'emv_num_instastory_posts',
                step: STEP_TENDOLLAR,
                min: MIN_POST_VALUE,
                max: MAX_POST_VALUE
            },
            {
                title: "Value of Facebook Post",
                key: 'emv_num_facebook_posts',
                step: STEP_TENDOLLAR,
                min: MIN_POST_VALUE,
                max: MAX_POST_VALUE
            },
            {
                title: "Value of Facebook Page Post",
                key: 'emv_num_facebook_page_posts',
                step: STEP_TENDOLLAR,
                min: MIN_POST_VALUE,
                max: MAX_POST_VALUE
            },
            {
                title: "Value of Twitter Post",
                key: 'emv_num_twitter_posts',
                step: STEP_TENDOLLAR,
                min: MIN_POST_VALUE,
                max: MAX_POST_VALUE
            },
            {
                title: "Value of Blog Post",
                key: 'emv_num_blog_posts',
                step: STEP_TENDOLLAR,
                min: MIN_POST_VALUE,
                max: MAX_POST_VALUE
            },
            {
                title: "Value of Pinterest Post",
                key: 'emv_num_pinterest_posts',
                step: STEP_TENDOLLAR,
                min: MIN_POST_VALUE,
                max: MAX_POST_VALUE
            },
            {
                title: "Value of YouTube Video",
                key: 'emv_num_youtube_posts',
                step: STEP_TENDOLLAR,
                min: MIN_POST_VALUE,
                max: MAX_POST_VALUE
            },
            {
                title: "Value of TikTok Video",
                key: 'emv_num_tiktok_posts',
                step: STEP_TENDOLLAR,
                min: MIN_POST_VALUE,
                max: MAX_POST_VALUE
            },

        ]
    },


    {
        title: "Instagram Engagement",
        key: "showInstagramEngagement",
        fields: [

            {
                title: "Value of Instagram Like",
                key: 'emv_instagram_like_count',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of Instagram Comment",
                key: 'emv_instagram_comment_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of Instagram Follower",
                key: 'emv_instagram_reach',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of Instastory Engagement",
                key: 'emv_instastory_engagement_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_CENT
            },
            {
                title: "Value of Instastory Impression",
                key: 'emv_instastory_impression_count',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of Instastory Reach",
                key: 'emv_instastory_reach',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },

        ]
    },

    {
        title: "Twitter Engagement",
        key: "showTwitterEngagement",
        fields: [

            {
                title: "Value of Tweet",
                key: 'emv_twitter_tweet_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of Retweet",
                key: 'emv_twitter_retweet_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of Twitter Favorite",
                key: 'emv_twitter_favorite_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of Twitter Follower",
                key: 'emv_twitter_reach',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },

        ]
    },

    {
        title: "Facebook Engagement",
        key: "showFacebookEngagement",
        fields: [

            {
                title: "Value of Facebook Reaction",
                key: 'emv_facebook_reaction_count',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of Facebook Share",
                key: 'emv_facebook_share_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_TENCENT,
            },
            {
                title: "Value of Facebook Comment",
                key: 'emv_facebook_comment_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_TENCENT,
            },
            {
                title: "Value of Facebook Follower",
                key: 'emv_facebook_reach',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },

            {
                title: "Value of Facebook Page Reaction",
                key: 'emv_facebook_page_reaction_count',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of Facebook Page Share",
                key: 'emv_facebook_page_share_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_TENCENT,
            },
            {
                title: "Value of Facebook Page Comment",
                key: 'emv_facebook_page_comment_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_TENCENT,
            },
            {
                title: "Value of Facebook Page Follower",
                key: 'emv_facebook_page_reach',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
        ]
    },

    {
        title: "Blog Engagement",
        key: "showBlogEngagement",
        fields: [

            {
                title: "Value of Blog Pageview",
                key: 'emv_blog_pageviews_count',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of Blog Comment",
                key: 'emv_blog_comment_count',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of Blog Subscriber",
                key: 'emv_blog_reach',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
        ]
    },

    {
        title: "Pinterest Engagement",
        key: "showPinterestEngagement",
        fields: [

            {
                title: "Value of Pinterest Save",
                key: 'emv_pinterest_save_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_TENCENT,
            },
            {
                title: "Value of Pinterest Comment",
                key: 'emv_pinterest_comment_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_TENCENT,
            },
            {
                title: "Value of Pinterest Follower",
                key: 'emv_pinterest_reach',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
        ]
    },

    {
        title: "YouTube Engagement",
        key: "showYoutubeEngagement",
        fields: [

            {
                title: "Value of Video View",
                key: 'emv_youtube_view_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of YouTube Like",
                key: 'emv_youtube_like_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of YouTube Dislike",
                key: 'emv_youtube_dislike_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of YouTube Comment",
                key: 'emv_youtube_comment_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_TENCENT,
            },
            {
                title: "Value of YouTube Subscriber",
                key: 'emv_youtube_reach',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
        ]
    },
    {
        title: "TikTok Engagement",
        key: "showTikTokEngagement",
        fields: [

            {
                title: "Value of TikTok Video View",
                key: 'emv_tiktok_view_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of TikTok Like",
                key: 'emv_tiktok_like_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of TikTok Share",
                key: 'emv_tiktok_share_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_CENT,
            },
            {
                title: "Value of TikTok Comment",
                key: 'emv_tiktok_comment_count',
                min: MIN_ENGAGEMENT_VALUE,
                max: MAX_ENGAGEMENT_VALUE,
                step: STEP_TENCENT,
            },
            {
                title: "Value of TikTok Subscriber",
                key: 'emv_tiktok_reach',
                min: MIN_IMPRESSION_VALUE,
                max: MAX_IMPRESSION_VALUE,
                step: STEP_CENT,
            },
        ]
    }
];

const SECTION_KEYS = SECTIONS.map(s => s.key);
const EMV_KEYS = SECTIONS.reduce((arr, section) => arr.concat(section.fields.map(f => f.key)), []);
const toDollar = x => formatDollar(x, 2);

export default class CampaignReportEMVSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...SECTION_KEYS.reduce((m, k) => ({...m, [k]: false}), {}),
            ...props.emvParams,
            showResetDefaults: false
        };

        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleToggleSection = this.handleToggleSection.bind(this);
        this.commitUpdates = this.commitUpdates.bind(this);
        this.handleResetDefaults = this.handleResetDefaults.bind(this);
    }

    handleResetDefaults(which, event) {
        preventDefaultIfPossible(event);

        let defaults;

        if (which === 'tidal') {
            defaults = {...EMV_TIDAL_DEFAULTS};
        } else if (which === 'industry') {
            defaults = {...EMV_INDUSTRY_DEFAULTS};
        } else if (which === 'creatoriq') {
            defaults = {...EMV_CREATORIQ_DEFAULTS};
        }

        this.setState(defaults, () => {
            this.commitUpdates();
        });

    }

    handleRangeChange(key, value) {
        this.setState({[key]: value});
    }

    handleToggleSection(key, event) {
        preventDefaultIfPossible(event);

        const newState = {
            ...SECTION_KEYS.concat(['showResetDefaults']).reduce((m, k) => ({...m, [k]: false}), {}),
            [key]: !this.state[key]
        };

        this.setState(newState);
    }

    commitUpdates() {
        const payload = EMV_KEYS.reduce((map, key) => ({...map, [key]: this.state[key]}), {});
        this.props.onChange(payload);
    }

    renderField(spec) {
        const currentVal = this.state[spec.key];
        return (
            <div className="form-group">
                <label><strong>{spec.title}</strong> {toDollar(currentVal)}</label>
                <InputRange
                    maxValue={spec.max}
                    minValue={spec.min}
                    step={spec.step}
                    value={currentVal}
                    onChange={value => this.handleRangeChange(spec.key, value)}
                    onChangeComplete={this.commitUpdates}
                    formatLabel={value => ''}
                />
            </div>
        );
    }

    renderSection(spec) {
        const visible = this.state[spec.key];
        return (
            <div className={'toolbox-section'}>
                <h2>
                    <a href="#" onClick={this.handleToggleSection.bind(null, spec.key)}>{spec.title} <i
                        className={'fa ' + (visible ? 'fa-angle-down' : 'fa-angle-right')}></i>
                    </a>
                </h2>

                { visible &&
                    <div className={'section-content'}>
                        {spec.fields.map(field => this.renderField(field)) }
                    </div>
                }
            </div>
        )
    }


    render() {
        return (
            <div className={'toolbox campaign-emv-settings-toolbox'} style={{right: 16, ...this.props.style}}>
                <h2 className={'title'}>Customize EMV</h2>
                <a className={'close absolute'} onClick={this.props.onClose}><i className={'fa fa-times'}></i></a>

                {SECTIONS.map(section => this.renderSection(section))}

                <div className={'toolbox-section'}>
                    <h2>
                        <a href="#" onClick={this.handleToggleSection.bind(null, 'showResetDefaults')}>Reset Defaults <i
                            className={'fa ' + (this.state.showResetDefaults ? 'fa-angle-down' : 'fa-angle-right')}></i>
                        </a>
                    </h2>

                    { this.state.showResetDefaults && (
                    <div className={'section-content'}>
                        <div className={'fake-li'}>
                            <a href="#" className="dark" onClick={this.handleResetDefaults.bind(this, 'tidal')}>Simple Defaults</a>
                        </div>
                        <div className={'fake-li'}>
                            <a href="#" className="dark" onClick={this.handleResetDefaults.bind(this, 'industry')}>Ayzenberg Industry Defaults</a>
                        </div>
                        <div className={'fake-li'}>
                            <a href="#" className="dark" onClick={this.handleResetDefaults.bind(this, 'creatoriq')}>CreatorIQ Defaults</a>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        )
    }

}

CampaignReportEMVSettings.defaultProps = {
    emvParams: EMV_TIDAL_DEFAULTS,
    style: {}
};

CampaignReportEMVSettings.propTypes = {
    emvParams: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    style: PropTypes.object,
};
