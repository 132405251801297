import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchActivationStats} from "../../actions/activations";
import * as ActivationSelectors from '../../selectors/activations';
import Alert from '../Common/Alert';
import SocialReachDonut from '../Campaign/Chart/SocialReachDonut';
import MediaValueDonut from '../Campaign/Chart/MediaValueDonut';
import PostTypesDonut from '../Campaign/Chart/PostTypesDonut';
import EngagementsOverTime from '../Campaign/Chart/EngagementsOverTime';

class ActivationExpandoStats extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        stats: PropTypes.array.isRequired,
        fetchStats: PropTypes.func,
    };

    componentDidMount() {
        this.props.fetchStats(this.props.activation.id);
    }

    renderAlert() {
        if (this.props.activation.isFetchingStats) {
            return <Alert classes={['info']} content={'Loading stats...'} />
        }
    }

    renderSocialReachDonut() {
        return <SocialReachDonut stats={this.props.stats} />
    }

    renderCharts() {
        const {activation, stats} = this.props;
        if (activation.didFetchStats && stats.length > 0) {

            return [
                // @TODO: Add Engagements per network donut, which needs a refactor
                <EngagementsOverTime stats={stats} style={{width: 750}}/>,
                <MediaValueDonut stats={stats} />,
                <SocialReachDonut stats={stats} />,
                <PostTypesDonut stats={stats} />,

            ];
        }
    }

    render() {

        return (
            <div className="activation-stats-expando">
                {this.renderAlert()}
                {this.renderCharts()}
            </div>
        );

    }
}

const mapStateToProps = (state, props) => {
    return {
        stats: ActivationSelectors.getActivationStats(state, {activationId: props.activation.id}),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchStats: (id) => dispatch(fetchActivationStats(id)),
    };

};

const ConnectedActivationExpandoStats = connect(mapStateToProps, mapDispatchToProps)(ActivationExpandoStats);
ConnectedActivationExpandoStats.propTypes = {
    activation: PropTypes.object.isRequired
};
export default ConnectedActivationExpandoStats;
