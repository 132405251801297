import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Form from '../Common/Form/Form';
import Alert from '../Common/Alert';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";
import { fetchAuthenticated, formatDollar as format } from '../../utilities';
import * as URI from 'urijs';

class UpdatePaymentOfferForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                payment_amount: (props.activation || {}).payment_amount
            },
            isSubmitting: false,
            didSubmit: false,
            sums: null
        };
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderAlert = this.renderAlert.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.renderBudgetDetails = this.renderBudgetDetails.bind(this);
    }

    static defaultProps = {
        usePadding: false,
        style: {},
    };

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onSaved: PropTypes.func,
        usePadding: PropTypes.bool,
        style: PropTypes.object,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
        campaign: PropTypes.object,
    };

    componentDidMount(){
        const urlRoot = `/manage/api/activation/_/total`;
        let payload = {
            filters: JSON.stringify({campaign_id: this.props.activation.campaign_id})
        };
        let url = URI(urlRoot)
            .query(payload)
            .toString();
        return fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => this.setState({sums: json.data.sums}));
    }

    resetForm() {
        this.setState({
            form: {
                payment_amount: (this.props.activation || {}).payment_amount
            },
        });
    }

    handleSubmit() {
        const { activation } = this.props;
        const { form } = this.state;
        this.setState({ isSubmitting: true });
        return this.props.createInteraction(activation.id, 'UpdatePaymentOffer', form)
            .then(resp => {
                this.setState({
                    isSubmitting: false,
                    didSubmit: true
                })
            })
            .then(() => {
                if (this.props.onSaved) {
                    this.props.onSaved(form);
                }
            })
            .then(() => this.props.fetchActivation(activation.id));
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    renderAlert(didSubmit, isSubmitting){
        if (didSubmit || isSubmitting) {
            return <Alert
                classes={['info']}
                content={isSubmitting ? 'Updating payment amount...' : 'Saved!'}
                style={{ marginBottom: 0 }}
            />
        }
    }

    renderForm(usePadding, form, isSubmitting){
        return <div className='left' style={{float: 'left', width: '40%', borderRight: '1px solid #eee'}}>
            <Form
                usePadding={usePadding}
                values={form}
                onFieldChange={this.handleFieldChange}
                fields={[
                    {
                        type: 'text',
                        name: 'payment_amount',
                        title: 'Payment Amount (USD)',
                        placeholder: 'eg: 123.20',
                        classes: ['v3', 'light'],
                        help: 'Payment amount, without dollar signs.'
                    },
                    {
                        type: 'button',
                        name: 'submit',
                        title: 'Update Payment Amount',
                        options: {
                            classes: ['v3 btn btn-primary medium bold fullwidth', (isSubmitting ? 'disabled' : '')],
                            hideTitle: true,
                            onClick: this.handleSubmit.bind(this),
                        }
                    }
                ]}
            />
        </div>
    }

    renderBudgetDetails(budget, remainingBalanceRange, payment_offered, payment_agreed, avg_price, price) {
        return <div className='right' style={{float: 'right', width: '60%'}}>
            {
                budget ?
                    <div className='fake-li padded'><strong>Campaign Budget: </strong><span className='pull-right'>{formatDollar(budget)}</span></div>
                    : null
            }
            {
                remainingBalanceRange

            }
            {
                !remainingBalanceRange && payment_offered ?
                    <div className='fake-li padded'><strong>Total Offered Payments: </strong><span className='pull-right'>{formatDollar(payment_offered)}</span></div>
                    : null
            }
            {
                !remainingBalanceRange && payment_agreed ?
                    <div className='fake-li padded'><strong>Total Accepted Payments: </strong><span className='pull-right'>{formatDollar(payment_agreed)}</span></div>
                    : null
            }
            {
                avg_price ?
                    <div className='fake-li padded' style={{marginBottom: '8px'}}><strong>{`Average Historical Payment: `}</strong><span className='pull-right'>{avg_price}</span></div>
                    : null
            }
            {
                !avg_price && price ?
                    <div className='fake-li padded'><strong>{`Recommended Payment: `}</strong><span className='pull-right'>{price}</span></div>
                    : null
            }
        </div>
    }

    render() {
        const { activation, campaign, usePadding } = this.props;
        const { isSubmitting, didSubmit, form, sums } = this.state;
        const { budget } = ((campaign || {}).settings || {});
        const { payment_offered } = ((sums || {}) || {});
        const { payment_agreed } = ((sums || {}) || {});
        const remainingBalanceRange = getRemainingBalanceRange(budget, payment_offered, payment_agreed);
        const { avg_price } = ((activation || {}).user || {});
        const { price } = ((activation || {}).user || {});

        return <div>
            { this.renderAlert(didSubmit, isSubmitting) }
            { this.renderForm(usePadding, form, isSubmitting) }
            { this.renderBudgetDetails(budget, remainingBalanceRange, payment_offered, payment_agreed, avg_price, price) }
        </div>
    }
}

function formatDollar(amount) {
    if (!amount) return null;
    const dollarStr = format(amount, 2);
    const cents = dollarStr.slice(-3, dollarStr.length);
    if (cents === '.00') {
        //if there's no change (e.g. '$35.00'), just return '$35'
        return format(amount);
    }
    return dollarStr;
}

function getRemainingBalanceRange(budget, offered, agreed){
    const actualRemaining = (budget && agreed) ? budget - agreed : null;
    const potentialRemaining = (budget && offered) ? budget - offered : null; //if all offers are accepted
    const range = ((actualRemaining && potentialRemaining) && (actualRemaining > potentialRemaining))
        ? `${formatDollar(potentialRemaining)} - ${formatDollar(actualRemaining)}`
        : null;

    if (potentialRemaining < 0) {
        //you offered more than your budget
        const overPromised = formatDollar(Math.abs(potentialRemaining));
        const helpText = `<p>${formatDollar(budget)} budget minus ${formatDollar(offered)} total offered payments.</p>`;
        return <div className='fake-li padded' data-tooltip={helpText}>
            <strong>{`You offered ${overPromised} more than your budget! `}</strong>
            <i className={'fa fa-question-circle'}/>
            </div>
    } else if (actualRemaining < 0) {
        //you agreed to pay more than your budget
        const overSpent = formatDollar(Math.abs(actualRemaining));
        const helpText = `<p>${formatDollar(budget)} budget minus ${formatDollar(agreed)} total accepted payments.</p>`;
        return <div className='fake-li padded' data-tooltip={helpText}>
            <strong>{`You agreed to pay ${overSpent} more than your budget! `}</strong>
            <i className={'fa fa-question-circle'}/>
            </div>
    } else if (range) {
        const helpText = `<p><strong>Total Offered Payments: </strong>${formatDollar(offered)}</p>
<p><strong>Total Accepted Payments: </strong>${formatDollar(agreed)}</p>
<p>If all of your open offers are accepted, your remaining campaign budget will be ${formatDollar(potentialRemaining)}.</p>
<p>If none of your open offers are accepted, your remaining campaign budget will be ${formatDollar(actualRemaining)}.</p>`;
        return <div className='fake-li padded' data-tooltip={helpText}>
            <strong>Budget Remaining: </strong><span className='pull-right'><i className={'fa fa-question-circle'}/> {range}</span>
            </div>
    }
    return null;
}

const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id))
    };
};

export default connect(null, mapDispatchToProps)(UpdatePaymentOfferForm);