import {RECEIVE_ACTIVATION_INTERACTIONS, REQUEST_ACTIVATION_INTERACTIONS} from "../actions/activations";
import {receiveItems, modifyItem, receiveItem} from "../utilities/reducer";
import {combineReducers} from 'redux';
import {makeQueriesReducerFor} from './queries';
import * as ActionTypes from '../actions/types';
import {
    RECEIVE_ACTIVATION, RECEIVE_ACTIVATION_REQUIREMENTS, REQUEST_ACTIVATION,
    REQUEST_ACTIVATION_REQUIREMENTS
} from "../actions/campaigns";

export const DEFAULT_ACTIVATIONS_QUERY = {
    id: "default",
    sort: {by: "advanced_at", dir: "desc"},
    limit: 20,
    page: 1,
    filters: {
        allow_inactive_users: true
    },
    context: {},
    pages: {}
};

const DEFAULT_ACTIVATION_STATE = {
    isFetchingActivation: false,
    isFetching: false,
    didFetch: false,
    isUpdating: false,
    isUpdatingMetadata: false,
    isFetchingRequirements: false,
    lastUpdatedRequirements: null,
    isFetchingStats: false,
    didFetchStats: false,
    isFetchingInteractions: false,
    didFetchInteractions: false,

    metadata: {},
    requirements: []
};

const interactionsById = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_ACTIVATION_INTERACTIONS:
            return receiveItems(state, action);

        default: return state;
    }
};

const statsById = (state = {}, action) => {

    switch (action.type) {

        case ActionTypes.ACTIVATION_RECEIVE_STATS:
            return {...state, [action.id]: action.data};

        default: return state;
    }

};

const queries = makeQueriesReducerFor('ACTIVATIONS_');

// New version of reducer, not exported because old version still in use
const activationsById = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_ACTIVATION_INTERACTIONS:
            return modifyItem(state, action, {isFetchingInteractions: true});

        case RECEIVE_ACTIVATION_INTERACTIONS:
            return modifyItem(state, action, {isFetchingInteractions: false, didFetchInteractions: true});

        case ActionTypes.ACTIVATION_REQUEST_STATS:
            return modifyItem(state, action, {isFetchingStats: true});

        case ActionTypes.ACTIVATION_RECEIVE_STATS:
            return modifyItem(state, action, {isFetchingStats: false, didFetchStats: true});

        case REQUEST_ACTIVATION:
            return modifyItem(state, action, {isFetchingActivation: true, isFetching: true});

        case RECEIVE_ACTIVATION:
            return receiveItem(state, action, {isFetchingActivation: false, isFetching: false, didFetch: true});

        case ActionTypes.ACTIVATIONS_RECEIVE_QUERY:
            return receiveItems(state, action);

        case ActionTypes.ACTIVATION_UPDATE_METADATA:
            return modifyItem(state, action,
                {
                    isUpdating: true,
                    isUpdatingMetadata: true,
                    metadata: {
                        ...(state[action.id] || {}).metadata,
                        [action.key]: action.value
                    }
                }
            );

        case ActionTypes.ACTIVATION_UPDATED_METADATA:
            return modifyItem(state, action,
                {
                    isUpdating: false,
                    isUpdatingMetadata: false,
                    metadata: {
                        ...(state[action.id] || {}).metadata,
                        [action.key]: action.value
                    }
                }
            );

        case REQUEST_ACTIVATION_REQUIREMENTS:
            return {
                ...state,
                [action.activationId]: {
                    ...state[action.activationId],
                    isFetchingRequirements: true
                }
            };

        case RECEIVE_ACTIVATION_REQUIREMENTS:
            return {
                ...state,
                [action.activationId]: {
                    ...state[action.activationId],
                    isFetchingRequirements: false,
                    lastUpdatedRequirements: action.receivedAt,
                    requirements: action.requirements
                }
            };

        default:
            return state;
    }

};

export const activations = combineReducers({
    queries,
    items: activationsById,
    interactions: interactionsById,
    stats: statsById,
});
