import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Toolbox from '../../../Common/Toolbox';
import Alert from '../../../Common/Alert';
import Form from '../../../Common/Form/Form';
import {getContentRequirementsHtml} from "../../../../utilities/campaign";
import DOMPurify from 'dompurify';
import Twig from 'twig';
import {fetchActivation} from "../../../../actions";
import {fetchPresence} from "../../../../actions/presence";
import {fetchAuthenticated, makeFormData} from "../../../../utilities";

class ClaimShadowUserToolbox extends Component {

    static propTypes = {
        activation: PropTypes.object,
        campaign: PropTypes.object,
        channel: PropTypes.object,
        presence: PropTypes.object,
        user: PropTypes.object,
        fetchActivation: PropTypes.func,
        fetchPresence: PropTypes.func,
        onClose: PropTypes.func,
        style: PropTypes.object,
    };

    state = {
        form: {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            terms: false,
        },
        showDetails: false,
        isSubmitting: false,
        isValid: false,
        error: null,
        interaction: null,
        invalidFields: [],
    };

    makeTwigContext() {
        return {
            channel: this.props.channel,
            campaign: this.props.campaign,
            content_requirements: getContentRequirementsHtml(this.props.activation),
            payment_offered: this.props.activation.payment_offered,
        };
    }

    renderInvitationCopy() {

        const template = Twig.twig({
            data: `
            <p class="v3 h7">{{channel.name}} has invited you to an influencer campaign! As part of this campaign you'd be required to do the following:</p>
            {{content_requirements|raw}}
            
            {% if payment_offered %}
            <p class="v3 h7">Upon successful completion of this campaign you'll be paid <strong>\${{payment_offered}}</strong>.</p>
            {% endif %}
            
            <p class="v3 h7"><strong>Note:</strong> Filling out this form only creates an account so you can log in later. You will still need to review the campaign details afterwards and either accept or decline the offer.</p>
            `,
        });

        const rendered = template.render(this.makeTwigContext());

        return {__html: DOMPurify.sanitize(rendered)};
    }

    renderDetails() {
        if (!this.state.showDetails) {
            return null;
        }

        return (
            <div
                style={{
                    padding: 15,
                    background: '#F6F6F6',
                }}
                className="v3 h7"
                dangerouslySetInnerHTML={this.renderInvitationCopy()}
            />
        );


    }

    renderErrorAlert() {
        if (!this.state.error) {
            return null;
        }

        return (
            <Alert
                classes={['danger']}
                content={this.state.error}
            />
        );
    }

    renderTopAlert() {

        if (this.state.error) {
            return this.renderErrorAlert();
        }

        const showHideDetailsLink = (
            <a
                role="button"
                className="v3 bold"
                onClick={() => this.setState({showDetails: !this.state.showDetails})}
            >{this.state.showDetails ? 'Hide Campaign Details' : 'Show Campaign Details'}</a>
        );

        const content = <span>
                You've been invited to a campaign but need to set up your account details first! {showHideDetailsLink}.
        </span>;

        return <Alert
            classes={['info']}
            content={content}
        />;
    }

    renderContent() {
        return (
            <div
                style={{
                    maxHeight: 600,
                    overflow: 'auto',
                }}
            >
                {this.renderTopAlert()}
                {this.renderDetails()}
                {this.renderForm()}
            </div>
        );
    }

    handleFieldChange(key, value) {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            }
        });
    }

    handleValidation({isValid, invalidFields}) {
        this.setState({
            isValid,
            invalidFields
        });
    }

    handleSubmit() {

        const isValid = this.isFormValid();

        if (!isValid) {
            return;
        }

        this.setState({
            isSubmitting: true,
            error: null,
            interaction: null,
        });

        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/user/me/interaction`;
        const context = {
            ...this.state.form,
            activation_id: this.props.activation.id,
            campaign_id: this.props.campaign.id,
        };

        const payload = {
            type: 'UpgradeShadowUser',
            context: JSON.stringify(context)
        };

        fetchAuthenticated(url, {
            method: 'post',
            body: makeFormData(payload)
        })
            .then(resp => resp.json())
            .then(json => {

                if (json.meta.error) {
                    this.setState({
                        interaction: json.data,
                        error: json.meta.error,
                        isSubmitting: false,
                    });
                } else {
                    this.props.fetchActivation(this.props.activation.id)
                        .then(() => this.props.fetchPresence())
                        .then(() => this.setState({
                            interaction: json.data,
                            error: null,
                            isSubmitting: false,
                        }));

                }

            });
    }

    isFormValid() {
        if (!this.state.isValid) {
            return false;
        }

        if (!this.state.form.terms) {
            return false;
        }

        if (this.state.form.password.length < 6) {
            return false;
        }

        if (this.state.form.password !== this.state.form.password_confirmation) {
            return false;
        }

        return true;
    }

    renderForm() {

        const {isSubmitting} = this.state;
        const isValid = this.isFormValid();

        const presence = this.props.presence || {};
        const channel = presence.channel || {};
        const supportEmail = channel.support_email || null;

        return (
            <Form
                values={this.state.form}
                onFieldChange={this.handleFieldChange.bind(this)}
                onValidation={this.handleValidation.bind(this)}
                fields={[
                    {
                        type: 'text',
                        name: 'name',
                        title: 'Your Name',
                        placeholder: 'eg: Jane Doe',
                        required: true,
                    },
                    {
                        type: 'text',
                        name: 'email',
                        title: 'Email Address',
                        placeholder: 'eg: you@youremail.com',
                        required: true,
                    },
                    {
                        type: 'password',
                        name: 'password',
                        title: 'Create a Password',
                        required: true,
                    },
                    {
                        type: 'password',
                        name: 'password_confirmation',
                        title: 'Repeat the Password',
                        required: true,
                    },
                    {
                        type: 'checkbox',
                        name: 'terms',
                        title: <span className="terms-line">I accept the <a target="_blank" href="https://tid.al/l/terms">User Agreement</a> and <a href="https://tid.al/l/influenceragreement" target="_blank">Influencer Agreement</a></span>,
                        required: true,
                        options: {
                            hideTitle: true,
                        }
                    },
                    {
                        type: 'button',
                        name: 'submit',
                        title: (() => {
                            if (!isValid) {
                                return 'Please Complete All Fields';
                            }
                            if (isSubmitting) {
                                return 'Creating Your Account...';
                            }
                            return 'Create Your Account';
                        })(),
                        help: supportEmail
                            ? <span><strong>Need help? Have questions?</strong> <a href={`mailto:${supportEmail}`}>Email us</a> or reply to your invitation email.</span>
                            : undefined,
                        options: {
                            classes: [
                                'v3',
                                'fullwidth',
                                ((isSubmitting || !isValid) ? 'btn-secondary disabled' : 'btn-primary')
                            ],
                            hideTitle: true,
                            onClick: this.handleSubmit.bind(this),
                        }
                    }

                ]}

            />
        )
    }

    render() {

        return (
            <Toolbox
                style={this.props.style}
                title={"Set Up Your Account"}
                content={this.renderContent()}
                onClose={this.props.onClose}
            />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        presence: state.presence,
        channel: state.presence.channel,
    };

};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchActivation: (id) => dispatch(fetchActivation(id)),
        fetchPresence: () => dispatch(fetchPresence()),
    };
};

const ConnectedClaimShadowUserToolbox = connect(mapStateToProps, mapDispatchToProps)(ClaimShadowUserToolbox);
export default ConnectedClaimShadowUserToolbox;