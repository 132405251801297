import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {socialHandleToUrl, socialIconForType, socialNameForType} from "../../utilities/social";
import {formatNumberK} from "../../utilities";

class GenericProfileSocialsTable extends Component {

    static propTypes = {
        socials: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            slug: PropTypes.string,
            followers_count: PropTypes.number,
        })),
    };

    renderSocial(social) {
        const niceName = socialNameForType(social.type);
        const iconClass = socialIconForType(social.type);
        const icon = <i className={iconClass} />;
        const url = socialHandleToUrl(social.type, social.slug);
        const followers = social.followers_count;
        const formattedFollowers = (followers && followers > 0) ? '(' + formatNumberK(followers) + ')' : '';
        return (
            <div className="v3 h8 light fake-li condensed bottom-border">
                <span>{icon} {niceName}</span>
                <span className="pull-right">
                    <a href={url} target="_blank" rel="noreferrer">{social.slug}</a> {formattedFollowers}
                </span>
            </div>
        );
    }

    getSocials() {
        return (this.props.socials || [])
            .sort((a, b) => a.type.localeCompare(b.type))
    }

    render() {

        return (
            <div className="fake-table">
                {this.getSocials().map(social => this.renderSocial(social))}
            </div>
        );

    }
}

export default GenericProfileSocialsTable;