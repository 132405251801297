/**
 * Created by visgotti on 11/30/17.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import Autosuggest from 'react-autosuggest';
import * as URI from 'urijs';
import Checkbox from '../Common/Checkbox';
import {preventDefaultIfPossible, fetchAuthenticated} from "../../utilities";

class DropDownFilter extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { label, filterName, options, handleChange, filters, limit, disabled } = this.props;

        let value;
        if(filterName === limit) {
            value = limit;
        } else {
            value = filters[filterName]
        }

        return(
            <div className="form-group">
                <label><strong>{ label } </strong></label>
                <select
                    className="form-control"
                    name={filterName}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled ? "disabled" : false}
                >
                    {
                        options.map(option => {
                            return (
                                <option
                                    value={option.value}
                                >
                                    { option.label }
                                </option>
                            )
                        })
                    }
                </select>
            </div>
        )
    }
}

class PostFilters extends Component {
    constructor(props) {
        super(props);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleResetFilters = this.handleResetFilters.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleLimitChange = this.handleLimitChange.bind(this);
        this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
        this.handleWrittenChangePick = this.handleWrittenChangePick.bind(this);
        this.handleWrittenChangeCustom = this.handleWrittenChangeCustom.bind(this);

        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.handleShowHideSection = this.handleShowHideSection.bind(this);

        this._suggestionsTimer = null;

        this.state = {
            writtenMax: '',
            writtenMin: '',
            userSuggestions: [],
            user: '',
            showCampaignsSection: false,
            showAdvancedSection: false
        };

    }

    renderAdvancedFiltersSection() {

        return (
            <div className="filter-section tidal-form" style={{borderBottom: 'none'}}>
                <h2>
                    <a href="#" onClick={this.handleShowHideSection.bind(this, 'showAdvancedSection')}>
                        More Filters
                        {this.getAngleIcon('showAdvancedSection')}
                    </a>
                </h2>
                {this.state.showAdvancedSection &&
                <div className="filter-content ">

                    { this.renderStatusFilter() }
                    { this.renderTypeFilter() }
                    { this.renderContentRatingFilter() }
                    { this.renderBadgesFilter() }
                    { this.renderWrittenAfterFilter() }
                    { this.renderWrittenBeforeFilter() }
                    { this.renderLimitFilter() }

                </div>
                }
            </div>
        );

    }
    renderCampaignFiltersSection() {

        return (
            <div className="filter-section tidal-form">
                <h2>
                    <a href="#" onClick={this.handleShowHideSection.bind(this, 'showCampaignsSection')}>
                        Campaigns
                        {this.getAngleIcon('showCampaignsSection')}
                    </a>
                </h2>
                {this.state.showCampaignsSection &&
                <div className="filter-content ">

                    {this.renderCampaignFilter()}
                    {this.renderComplianceFilter()}

                </div>
                }
            </div>
        );

    }

    handleShowHideSection(section, event) {
        event.preventDefault();
        this.setState({[section]: !this.state[section]})
    }

    onSuggestionsFetchRequested({value}) {

        const TIMEOUT = 400;

        if (this._suggestionsTimer) {
            clearTimeout(this._suggestionsTimer);
            this._suggestionsTimer = null;
        }

        this._suggestionsTimer = setTimeout(() => {

            const url = URI('/manage/api/autocomplete/_/user')
                .query({term: value})
                .toString();

            console.log("Search users for value " + value + " at url " + url);

            fetchAuthenticated(url, {credentials: 'include'})
                .then(response => response.json())
                .then(json => {
                    this.setState({
                        userSuggestions: json.data
                    });
                })
                .catch(err => {
                    console.log("Error fetching interests")
                    console.log(err)
                });

        }, TIMEOUT);
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested() {
        this.setState({
            userSuggestions: []
        });
    };

    onSuggestionSelected(event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) {
        console.log("Selected user " + suggestionValue);
        this.setState({user: suggestionValue});
        this.props.changeFilter('user', suggestionValue, false);
    }

    renderUserFilter() {

        const getSuggestionValue = suggestion => suggestion;
        const renderSuggestion = suggestion => (
            <div>
                {suggestion}
            </div>
        );

        const inputProps = {
            placeholder: 'Search by User...',
            value: this.state.user ? this.state.user : '',
            onChange: (e) => {
                this.setState({user: e.target.value});
            },
            className: 'form-control',
            name: 'user'
        };

        return (
        <div className="form-group">
            <label><strong>User</strong></label>
            <Autosuggest
                suggestions={this.state.userSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />

        </div>
        );
    }

    handleResetFilters() {
        this.props.resetFilters();
        this.setState({
            writtenMax: '',
            writtenMin: '',
        });
    }

    handleWrittenChangePick(date, key) {
        if(!date){
            this.setState({
                [key]: '',
            })
        } else {
            const formattedDate = moment(date._d).format("YYYY-MM-DD");
            this.props.changeFilter(key, formattedDate, false);
            this.setState({
                [key]: date,
            })
        }

    }

    handleWrittenChangeCustom(date, key) {
        if(date == '') {
            this.props.removeFilter(key);
        } else {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            this.props.changeFilter(key, formattedDate, false)
        }

        this.setState({
            [key]: '',
        })
    }

    handleFilterChange(event) {
        const target = event.target;
        const key = target.name;
        const value = target.value;

        if(value === 'remove' || value === '') {
            this.props.removeFilter(key, false)
        } else {
            this.props.changeFilter(key, value, false)
        }
    }

    handleLimitChange(event) {
        const value = event.target.value;
        this.props.changeLimit(value, false);
    }

    handleCheckBoxClick(filterKey, isChecked) {
        this.props.changeFilter(filterKey, !isChecked, false)
    }

    renderCheckbox(filterKey) {
        const isChecked = this.props.filters[filterKey];

        return(
            <Checkbox checked={isChecked}
                      label="Featured Posts"
                      onClick={() => this.handleCheckBoxClick(filterKey, isChecked)}
            />
        );
    }

    renderTitle() {
        return(
            <h2>
                Find Posts by
                <a onClick={this.props.toggleShowFilters}
                    className="close">
                    <i className="fa fa-times"/>
                </a>
            </h2>
        )
    }

    renderSearchbar() {
        return(
            <div className="form-group">
                <label><strong>Search Posts</strong></label>
                <input
                    type="text"
                    className="form-control"
                    name="search"
                    value={this.props.filters.search || ""}
                    placeholder="Search by title, tags, or content"
                    onChange={this.handleFilterChange}
                />
            </div>
        )
    }

    renderTypeFilter() {
        const options = [
            { value: 'remove', label: 'Any Type'},
            { value: 'post', label: 'Regular Post'},
            { value: 'instagram', label: 'Instagram'},
            { value: 'twitter', label: 'Twitter'},
            { value: 'facebook', label: 'Facebook'},
            { value: 'pinterest', label: 'Pinterest'},
            { value: 'youtube', label: 'YouTube'},
        ];

        return(
            <DropDownFilter
                label={"Type or Source"}
                filterName={"type"}
                options={options}
                handleChange={this.handleFilterChange}
                filters={this.props.filters}
            />
        )
    }

    renderComplianceFilter() {
        const options = [
            { value: 'remove', label: 'All Posts'},
            { value: false, label: 'Potential Compliance Issue'},
            { value: true, label: 'Is Compliant'},
        ];

        return(
            <DropDownFilter
                label={"FTC Compliance Status"}
                filterName={"isCompliant"}
                options={options}
                handleChange={this.handleFilterChange}
                filters={this.props.filters}
            />
        )
    }

    renderCampaignFilter() {
        const options = [
            { value: 'remove', label: 'Select a Campaign'},
        ].concat(
            Object.values(this.props.campaigns)
                .filter(campaign => campaign.status !== 'building')
                .map(campaign => {
                    return { value: campaign.id, label: campaign.name };
                })
        );

        return(
            <DropDownFilter
                label={"Campaign"}
                filterName={"campaign"}
                options={options}
                handleChange={this.handleFilterChange}
                filters={this.props.filters}
                disabled={this.props.disableCampaignField}
            />
        )
    }

    renderContentRatingFilter() {
        const options = [
            { value: 'remove', label: 'All Posts'},
            { value: 1, label: '1 Star'},
            { value: 2, label: '2 Stars'},
            { value: 3, label: '3 Stars'},
            { value: 4, label: '4 Stars'},
            { value: 5, label: '5 Stars'},
        ];

        return(
            <DropDownFilter
                label={"Content Rating"}
                filterName={"rating"}
                options={options}
                handleChange={this.handleFilterChange}
                filters={this.props.filters}
            />
        )
    }

    renderStatusFilter() {
        const options = [
            { value: 'all', label: 'All Posts'},
            { value: 'published', label: 'Published'},
            { value: 'submitted', label: 'Submitted'},
            { value: 'draft', label: 'Draft'},
        ];

        return(
            <DropDownFilter
                label={"Post Status"}
                filterName={"status"}
                options={options}
                handleChange={this.handleFilterChange}
                filters={this.props.filters}
            />
        )
    }

    renderFeaturedFilter() {
        return(
           <div className="form-group">
               { this.renderCheckbox('featured') }
           </div>
        )
    }

    renderWrittenAfterFilter() {
        let placeholder = "eg: Janurary 20, 2014";

        return(
            <div className="form-group">
                <label><strong>Written After</strong></label>
                <DatePicker
                    className="form-control"
                    onChange={(date) => this.handleWrittenChangePick(date, 'writtenMin')}
                    fixedHeight
                    value={this.props.filters.writtenMin}
                    selected={this.state.writtenMin}
                    placeholderText={placeholder}
                    onChangeRaw={(event) =>
                        this.handleWrittenChangeCustom(event.target.value, 'writtenMin')}
                />
            </div>
        )
    }


    renderWrittenBeforeFilter() {
        let placeholder = "eg: January 20, 2014";

        return(
            <div className="form-group">
                <label><strong>Written Before</strong></label>
                <DatePicker
                    className="form-control"
                    onChange={(date) => this.handleWrittenChangePick(date, 'writtenMax')}
                    fixedHeight
                    value={this.props.filters.writtenMax}
                    selected={this.state.writtenMax}
                    placeholderText={placeholder}
                    onChangeRaw={(event) =>
                        this.handleWrittenChangeCustom(event.target.value, 'writtenMax')}
                />
            </div>
        )
    }


    renderBadgesFilter() {
        const badges = this.props.badges;
        let options = [
            ...[{ value: 'remove', label: 'No Badge'}],
            ...Object.keys(badges).map(badgeId => {
                return {
                    value: badges[badgeId].mongo_id,
                    label: badges[badgeId].name,
                }
            })
        ];

        return(
            <DropDownFilter
                label={"Badge"}
                options={options}
                filterName="badge"
                handleChange={this.handleFilterChange}
                filters={this.props.filters}
            />
        )
    }

    renderLimitFilter() {
        const options = [
            { value: 20, label: '20 Posts'},
            { value: 50, label: '50 Posts'},
            { value: 75, label: '75 Posts'},
            { value: 100, label: '100 Posts'},
        ];

        return(
            <DropDownFilter
                label={"Limit"}
                options={options}
                filterName="limit"
                handleChange={this.handleLimitChange}
                filters={this.props.filters}
            />
        )
    }

    getAngleIcon(section) {
        if (this.state[section]) {
            return <i className="fa fa-angle-down"></i>
        } else {
            return <i className="fa fa-angle-right"></i>
        }
    }

    renderPostFilters() {
        return(
            <div className="posts-filters-inner">
                <div className="filter-section tidal-form">
                    { this.renderTitle() }
                    <div className="filter-content">
                        { this.renderSearchbar() }
                        { this.renderUserFilter() }
                        { this.renderFeaturedFilter() }
                    </div>
                </div>

                {this.renderCampaignFiltersSection()}
                {this.renderAdvancedFiltersSection()}

                <div className="filter-section tidal-form">
                    { this.renderSearchButton() }
                </div>
            </div>
        )
    }

    handleSearchClick(e) {
        preventDefaultIfPossible(e);
        const {filters} = this.props;

        // If autocomplete hasn't found a user, we must add it as a filter
        // before committing the search
        if (this.state.user !== filters.user) {
            console.log("Forcing user filter");
            this.props.changeFilter('user', this.state.user);
        }

        setTimeout(() => this.props.fetchPosts(), 10);

    }

    renderSearchButton() {
        return(
            <a className="tidal-btn btn-primary btn" onClick={this.handleSearchClick}>
                Search
            </a>
        )
    }

    renderResetButton(){
        return (
            <a className="tidal-btn btn-primary btn" onClick={this.handleResetFilters}>
                Reset
            </a>
        )
    }

    render() {
        return (
            <div className="posts-filters">
                { this.renderPostFilters() }
            </div>
        )
    }
}

PostFilters.propTypes = {
    toggleShowFilters: PropTypes.func,
    changeFilter: PropTypes.func,
    changeLimit: PropTypes.func,
    resetFilters: PropTypes.func,
    fetchPosts: PropTypes.func,
    removeFilter: PropTypes.func,
    filters: PropTypes.object,
    limit: PropTypes.number,
    badges: PropTypes.object,
    campaigns: PropTypes.object,
    disableCampaignField: PropTypes.bool
};

PostFilters.defaultProps = {
    disableCampaignField: false
};

export default PostFilters;