import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from "../Common/Toolbox";
import CommentOnIssueForm from './CommentOnIssueForm';
import NavStacked from "../Common/NavStacked";
import {limitChars, makeMomentFromDate} from "../../utilities";
import _find from 'lodash/find';
import Button from '../Common/Form/Button';
import Comment from '../Common/Comment'

import {connect} from 'react-redux';
import {hasAclRole} from "../../selectors/presence";
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";

class IssueToolbox extends Component {

    static defaultProps = {
        issues: [],
        style: {},
        toolboxTitle: 'Manage Issues',
        showComments: false,
    };

    static propTypes = {
        onClose: PropTypes.func,
        onSave: PropTypes.func,
        onSaveComment: PropTypes.func,
        toolboxTitle: PropTypes.string,
        issues: PropTypes.arrayOf(PropTypes.object),
        issue: PropTypes.object,
        style: PropTypes.object,
        showComments: PropTypes.bool,
        activation: PropTypes.object,
        hasManageCampaignsRole: PropTypes.bool,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            issueId: (props.issue ? props.issue.source_id : ((props.issues || [])[0] || {}).source_id || null),
            isResolving: false,
        };

    }

    getCurrentIssue() {
        if (!this.state.issueId) {
            return null;
        }

        return _find(this.props.issues, {source_id: this.state.issueId});
    }

    renderCommentForm() {

        return <CommentOnIssueForm
            activation={this.props.activation}
            issueId={this.state.issueId}
            usePadding={true}
            onSaved={this.props.onSaveComment}

        />;

    }

    renderIssuePreview() {
        const issue = this.getCurrentIssue();

        if (!issue) {
            return null;
        }

        return <div className={'draft-preview'} style={{padding: 20}}>
            <h3 className={'v3 light'}>{issue.title}</h3>
            <p className={'v3 h7 light bottommargin'}>{makeMomentFromDate(issue.created_at).format('MMM Do, YYYY - h:mm a')}</p>
            <div
                className={'draft-preview-content'}
                dangerouslySetInnerHTML={{__html: issue.content}}>
            </div>
            {this.renderResolveButton()}
        </div>
    }

    handleMarkResolved() {
        const issue = this.getCurrentIssue();
        if (!issue) {
            return null;
        }
        if (issue.issue_status === 'resolved') {
            return null;
        }
        const issueId = issue.source_id;
        const activation = this.props.activation;
        const payload = {issue_id: issueId};
        this.setState({isResolving: true});
        return this.props.createInteraction(activation.id, 'ResolveIssue', payload)
            .then(() => this.props.fetchActivation(activation.id))
            .then(() => this.setState({isResolving: false}));
    }

    renderResolveButton() {
        if (!this.props.hasManageCampaignsRole) {
            return null;
        }

        const issue = this.getCurrentIssue();

        const style = {
            marginTop: 20,
            paddingTop: 20,
            borderTop: '1px solid #EEEEEE',
        };

        let btnText = 'Mark as Resolved';
        let btnIcon = null;
        let btnClasses = ['v3'];

        if (this.state.isResolving) {
            btnIcon = <i className="fa fa-spinner fa-spin" style={{marginRight: 5}} />;
            btnClasses.push('disabled');
        }

        if (issue.issue_status === 'resolved') {
            btnIcon = <i className='v3 icon check-circle' style={{marginRight: 5}}/>;
            btnText = 'Issue Resolved';
            btnClasses.push('disabled');
            btnClasses.push('btn-secondary');
        } else {
            btnClasses.push('btn-primary');
        }

        let btnContent = <span>{btnIcon} {btnText}</span>;

        let btn = <Button
            content={btnContent}
            onClick={this.handleMarkResolved.bind(this)}
            classes={btnClasses}
        />;



        return <div className={'action-buttons-wrapper'} style={style}>{btn}</div>
    }

    renderComments() {

        const issue = this.getCurrentIssue();
        const comments = [...((issue || {}).comments || [])].reverse();

        return <div className="draft-comments">
            {comments.map(this.renderComment.bind(this))}
        </div>


    }

    renderComment(comment) {
        return <Comment comment={comment}/>
    }

    renderCommentsSection() {

        return <div className="draft-comments-wrapper">
            {this.renderCommentForm()}
            {this.renderComments()}
        </div>

    }

    renderCommentsToolbox() {
        return <Toolbox
            embedded={true}
            title={'Comments'}
            addlClasses={'draft-comments-toolbox'}
            content={this.renderCommentsSection()}
            onClose={this.props.showComments ? this.props.onClose : null}
        />
    }

    isIssueSelected(issue) {
        if (!this.state.issueId) {
            return false;
        }
        return this.state.issueId === issue.source_id;
    }

    getIssueNavIcon(issue) {
        switch (issue.issue_status) {

            case 'open':
                return <i className={'v3 signal warning'}/>;

            case 'resolved':
                return <i style={{color: '#66cc00'}} className={'v3 icon success'}/>;

            default:
                return <i className={'v3 signal neutral'}/>;
        }
    }

    renderLeftToolbox() {

        return <Toolbox
            embedded={true}
            title={this.props.toolboxTitle}
            addlClasses={'draft-main'}
            onClose={this.props.showComments ? null : this.props.onClose}
            content={

                <div className={'draft-main-inner'}>

                    <NavStacked
                        items={
                            this.props.issues.map(issue => {
                                return {
                                    title: limitChars(issue.title, 15),
                                    onClick: () => this.setState({issueId: issue.source_id}),
                                    isActive: this.isIssueSelected(issue),
                                    rightIcon: this.getIssueNavIcon(issue),
                                }
                            })
                        }
                    />

                    <Toolbox
                        addlClasses={'draft-preview-edit-toolbox'}
                        embedded={true}
                        content={this.renderIssuePreview()}
                    />

                </div>
            }
        />
    }

    renderBody() {
        return [
            this.renderLeftToolbox(),
            this.props.showComments ? this.renderCommentsToolbox() : null
        ];
    }

    render() {
        return (

            <Toolbox
                addlClasses={'draft-editor'}
                style={this.props.style}
                content={this.renderBody()}
            />

        );
    }
}

const mapStateToProps = (state) => {
    return {
        hasManageCampaignsRole: hasAclRole('manage_campaigns', state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };

};

const ConnectedIssueToolbox = connect(mapStateToProps, mapDispatchToProps)(IssueToolbox);

ConnectedIssueToolbox.propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onSaveComment: PropTypes.func,
    toolboxTitle: PropTypes.string,
    issues: PropTypes.arrayOf(PropTypes.object),
    issue: PropTypes.object,
    style: PropTypes.object,
    showComments: PropTypes.bool,
    activation: PropTypes.object,
};

export default ConnectedIssueToolbox;