import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MiniProfile from "../Common/MiniProfile";
import {getCollectableTypeIcon, getNiceCollectableTypeName, formatNumberK} from "../../utilities";
import {entryHasStats, numEntryPosts} from '../../utilities/entry';
import ActivationsHoverView from "../../containers/User/Pieces/ActivationsHoverView";
import AuthStatusDotWithTooltip from "../User/Pieces/AuthStatusDotWithTooltip";
import _get from 'lodash/get';

class GenericProfileMiniProfile extends Component {

    static propTypes = {

        isPublic: PropTypes.func,
        onClickPrimary: PropTypes.func,
        row: PropTypes.object,

        onShowHoverView: PropTypes.func,

        onClickActivations: PropTypes.func,
        onClickQuickEdit: PropTypes.func,
        onClickNotes: PropTypes.func,
        onClickStats: PropTypes.func,
        onClickContent: PropTypes.func,
        onClickLists: PropTypes.func,
        onClickImages: PropTypes.func,

        showActivations: PropTypes.bool,
        showQuickEdit: PropTypes.bool,
        showNotes: PropTypes.bool,
        showStats: PropTypes.bool,
        showContent: PropTypes.bool,
        showLists: PropTypes.bool,
        showImages: PropTypes.bool,

    };

    static defaultProps = {
        showActivations: false,
        showQuickEdit: false,
        showNotes: false,
        isPublic: false,
    };

    getSwitches() {
        const {
            row,
            onClickActivations,
            onClickQuickEdit,
            onClickNotes,
            onClickStats,
            onClickContent,
            onClickLists,
            onClickImages,
            showActivations,
            showQuickEdit,
            showNotes,
            showStats,
            showContent,
            showLists,
            showImages,

        } = this.props;
        let switches = [];
        let style = {color: '#999'};
        const activationIds = _get(row.item, 'channel_stats.activation_ids', []);

        if ( onClickQuickEdit && row.item.collectable_type === 'Tidal\\User' ) {
            switches.push({
                name: 'Quick Edit',
                icon: (<i className="v3 icon pencil" />),
                tooltip: "Quick-edit this user.",
                isActive: showQuickEdit,
                onClick: onClickQuickEdit,
            })
        }

        if (row.item.collectable_type === 'Tidal\\User'
            && activationIds.length > 0
        ) {
            switches.push({
                name: "Campaigns",
                icon: <i className="fa fa-plus-square" style={style}/>,
                tooltip: "Show campaign participation for this user.",
                label: activationIds.length,
                isActive: showActivations,
                onClick: onClickActivations,
                style,
            });
        }

        if (onClickNotes) {
            const noteCount = (row.item.notes || []).length;

            noteCount > 0 ? switches.push({
                name: "Notes",
                icon: <i className="v3 icon comments" style={style}/>,
                tooltip: "Read and write notes on this user.",
                label: noteCount,
                isActive: showNotes,
                onClick: onClickNotes,
                style
            }) : null;
        }

        if (row.item.collectable_type === 'Tidal\\User' && onClickStats) {
            const hasStats = entryHasStats(row.item);
            let style = {color: hasStats ? '#999' : '#ccc'};

            switches.push({
                name: 'Stats',
                icon: <i className="v3 icon pie-chart" style={style}/>,
                tooltip: "Show stats for this user.",
                isActive: showStats,
                onClick: onClickStats
            });
        }

        if (row.item.collectable_type === 'Tidal\\User' && onClickContent) {
            const numPosts = numEntryPosts(row.item);

            numPosts > 0 ? switches.push({
                name: 'Content',
                icon: <i className='v3 icon post' style={style}/>,
                tooltip: 'Show content for this user.',
                label:formatNumberK(numPosts),
                isActive: showContent,
                onClick: onClickContent,
                style
            }) : null;
        }

        if (onClickLists) {
            const numLists = _get(row.item, 'channel_stats.num_lists', 0);

            if (numLists > 0) {
                switches.push({
                    name: 'Lists',
                    icon: <i className='fa fa-list-ul' style={style}/>,
                    tooltip: 'Show lists for this user.',
                    label: numLists,
                    isActive: showLists,
                    onClick: onClickLists,
                    style,
                });
            }
        }

        if (onClickImages) {
            const campaignImages = row.item.campaign_image_urls || [];
            const profileImages = (row.item.profile || {}).images || [];
            const numImages = campaignImages.length + profileImages.length;

            if (numImages > 0) {

                switches.push({
                    name: 'Images',
                    icon: <i className='fa fa-camera' style={style}/>,
                    tooltip: 'Show images for this user.',
                    label: numImages,
                    isActive: showImages,
                    onClick: onClickImages,
                    style,
                })
            }
        }

        if (row.item.profile && !!row.item.profile.reach) {
            switches.push({
                name: 'Reach',
                icon: <i className='v3 icon bullhorn' style={style}/>,
                tooltip: 'Reach',
                label: formatNumberK(row.item.profile.reach),
                style: {...style, cursor: 'unset'}
            })
        }

        return switches;
    }

    renderActivationsPopup() {
        if (!this.props.showActivations) {
            return null;
        }

        return (
            <ActivationsHoverView
                showClickForMore={false}
                userId={this.props.row.item.collectable_id}
                style={{top: 75, left: 0}}
            />
        );
    }

    render() {
        const {row, onClickPrimary, isPublic} = this.props;
        // const niceType = getNiceCollectableTypeName(row.item);
        // const typeIconClass = getCollectableTypeIcon(row.item);
        const authStatus = _get(row.item, 'profile.metadata.auth_status', {});
        const badge = <AuthStatusDotWithTooltip status={authStatus} />;

        const overrideName = _get(row.item, 'settings.custom_name', null);
        const actualName = _get(row.item, 'profile.name', 'Anonymous');
        const name = isPublic ? overrideName || actualName : actualName;

        const allImages = _get(row.item, 'profile.images', []);
        const overrideImage = _get(row.item, 'settings.custom_profile_image', null);
        let actualImage = _get(row.item, 'profile.picture', null);
        if (!actualImage && allImages && allImages.length > 0) {
            actualImage = allImages[0];
        }
        const profilePicture = isPublic ? overrideImage || actualImage : actualImage;

        const isAdmin = !isPublic && _get(row.item, 'channel_stats.has_login_role', false);

        return (

            <div style={{position: 'relative'}}>
                <MiniProfile
                    primary={<a
                        onClick={onClickPrimary}
                        href={`/manage/do/page/users/detail?slug=${row.item.profile.slug}`}
                        style={{ color: isAdmin ? "#4c9701" : undefined }}
                        role="button">{name}</a>}
                    // secondary={<span><i className={typeIconClass} /> {niceType}</span>}
                    secondary={this.props.row.item.profile.location || 'Location Unknown'}
                    imageUrl={profilePicture}
                    onClickAvatar={onClickPrimary}
                    switches={this.getSwitches()}
                    badge={badge}
                />
                {this.renderActivationsPopup()}
            </div>
        );
    }
}

export default GenericProfileMiniProfile;
