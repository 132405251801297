/**
 * Created by bkanber on 8/1/17.
 */

import React, {Component} from 'react';
import CampaignActivationStatsTableRow from './TableRow';
import CampaignActivationStatsTableRowSocialCell from './TableRowSocialCell';

const getEngagementFields = function(social) {
    let fields = [];

    switch (social) {
        case 'blog':
            fields = ['blog_comment_count', 'other_engagements', 'tidal_vote_count'];
            break;

        case 'twitter':
            fields = ['twitter_favorite_count', 'twitter_retweet_count', 'twitter_tweet_count'];
            break;

        case 'instagram':
            fields = ['instagram_comment_count', 'instagram_like_count'];
            break;

        case 'instastory':
            fields = ['instastory_engagement_count'];
            break;

        case 'pinterest':
            fields = ['pinterest_comment_count', 'pinterest_like_count', 'pinterest_repin_count'];
            break;

        case 'facebook':
            fields = ['facebook_comment_count', 'facebook_comment_plugin_count', 'facebook_reaction_count', 'facebook_share_count'];
            break;

        case 'facebook_page':
            fields = ['facebook_page_comment_count', 'facebook_page_reaction_count', 'facebook_page_share_count'];
            break;

        case 'youtube':
            fields = ['youtube_comment_count', 'youtube_like_count', 'youtube_dislike_count'];
            break;

        case 'tiktok':
            fields = ['tiktok_like_count', 'tiktok_share_count', 'tiktok_comment_count'];
            break;

        case 'total':
            fields = ['total_engagements'];
            break;
    }

    return fields;
}

export default class CampaignActivationStatsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sortBy: 'total',
            sortDir: 'desc'
        };

        this.shouldRenderColumn = this.shouldRenderColumn.bind(this);
        this.handleChangeSort = this.handleChangeSort.bind(this);
    }

    handleChangeSort(sortBy, sortDir, event) {
        if (typeof event !== 'undefined' && typeof event.preventDefault !== 'undefined') {
            event.preventDefault();
        }

        this.setState({
            sortBy,
            sortDir
        });
    }

    getSortedActivations() {

        // direction modifier
        const multiplier = this.state.sortDir === 'desc' ? -1 : 1;

        // Totally separate handling for name sort
        if (this.state.sortBy === 'name') {
            return this.props.activations.sort((a, b) => {
                const aVal = a.user.basics.name;
                const bVal = b.user.basics.name;
                return multiplier * (aVal > bVal ? 1 : -1);
            });
        }

        // Handling engagements sort
        const getEngagementsForActivation = (activation, social) => {
            let total = 0;
            const stats = activation.stats;
            if (!stats || stats.length === 0) return total;
            const stat = stats[0];
            const fields = getEngagementFields(social);
            for (const field of fields) {
                total+=parseInt(stat[field] || 0, 10);
            }
            return total;
        };

        return this.props.activations.sort((a, b) => {
            const aVal = getEngagementsForActivation(a, this.state.sortBy);
            const bVal = getEngagementsForActivation(b, this.state.sortBy);
            const retVal = aVal > bVal ? 1 : -1;
            return multiplier*retVal;
        });
    }

    getCampaignStat() {
        const stats = this.props.campaignStats || [];
        if (!stats || stats.length === 0) return null;
        return stats[stats.length - 1];
    }

    shouldRenderColumn(social) {
        const stat = this.getCampaignStat();
        if (!stat) return false;
        const key = 'num_' + social + '_posts';
        const val = stat[key];
        if (!val) return false;
        return true;
    }

    renderSorters(key) {
        const ascClass = "asc " + (this.state.sortBy === key && this.state.sortDir === 'asc' ? "active" : '');
        const descClass = "desc " + (this.state.sortBy === key && this.state.sortDir === 'desc' ? "active" : '');

        return (
        <div className="sort pull-right">
            <a href="#" className={ascClass} onClick={this.handleChangeSort.bind(this, key, 'asc')}>
                <i className="fa fa-sort-asc"></i>
            </a>
            <a href="#" className={descClass} onClick={this.handleChangeSort.bind(this, key, 'desc')}>
                <i className="fa fa-sort-desc"></i>
            </a>
        </div>
        );
    }

    renderSocialHeaderCell(key, name) {
        if (!this.shouldRenderColumn(key)) return null;


        return (
            <th>
                {name}
                {this.renderSorters(key)}
            </th>
        );
    }

    renderHeader() {

        return (
            <thead>
            <tr>

                <th style={{paddingLeft: 20}}>
                    Creator
                    {this.renderSorters('name')}
                </th>

                {this.renderSocialHeaderCell('blog', 'Blog')}
                {this.renderSocialHeaderCell('twitter', 'Twitter')}
                {this.renderSocialHeaderCell('instagram', 'Instagram')}
                {this.renderSocialHeaderCell('instastory', 'Instastory')}
                {this.renderSocialHeaderCell('pinterest', 'Pinterest')}
                {this.renderSocialHeaderCell('facebook', 'Facebook')}
                {this.renderSocialHeaderCell('facebook_page', 'FB Page')}
                {this.renderSocialHeaderCell('youtube', 'YouTube')}
                {this.renderSocialHeaderCell('tiktok', 'TikTok')}

                <th style={{paddingRight: 20}}>
                    Total
                    {this.renderSorters('total')}
                </th>

            </tr>
            </thead>
        )

    }

    renderTotalsRow() {
        const stat = this.getCampaignStat();
        if (!stat) return null;


        return (
            <tr style={{fontWeight: "bold"}}>

                <td style={{paddingLeft: 20}}>Totals</td>

                {
                    this.shouldRenderColumn('blog') &&
                    <CampaignActivationStatsTableRowSocialCell
                        social="blog_totals"
                        posts={stat.num_blog_posts}
                        reach={stat.blog_reach}
                        engagements={stat.blog_comment_count + stat.other_engagements + stat.tidal_vote_count}
                    />
                }

                {
                    this.shouldRenderColumn('twitter') &&
                    <CampaignActivationStatsTableRowSocialCell
                        social="twitter_totals"
                        posts={stat.num_twitter_posts}
                        reach={stat.twitter_reach}
                        engagements={stat.twitter_favorite_count + stat.twitter_retweet_count + stat.twitter_tweet_count}
                    />
                }

                {
                    this.shouldRenderColumn('instagram') &&
                    <CampaignActivationStatsTableRowSocialCell
                        social="instagram_totals"
                        posts={stat.num_instagram_posts}
                        reach={stat.instagram_reach}
                        engagements={stat.instagram_comment_count + stat.instagram_like_count}
                    />
                }

                {
                    this.shouldRenderColumn('instastory') &&
                    <CampaignActivationStatsTableRowSocialCell
                        social="instastory_totals"
                        posts={stat.num_instastory_posts}
                        reach={stat.instastory_reach}
                        engagements={stat.instastory_engagement_count}
                    />
                }

                {
                    this.shouldRenderColumn('pinterest') &&
                    <CampaignActivationStatsTableRowSocialCell
                        social="pinterest_totals"
                        posts={stat.num_pinterest_posts}
                        reach={stat.pinterest_reach}
                        engagements={stat.pinterest_save_count + stat.pinterest_comment_count}
                    />
                }

                {
                    this.shouldRenderColumn('facebook') &&
                    <CampaignActivationStatsTableRowSocialCell
                        social="facebook_totals"
                        posts={stat.num_facebook_posts}
                        reach={stat.facebook_reach}
                        engagements={stat.facebook_comment_count + stat.facebook_comment_plugin_count + stat.facebook_reaction_count + stat.facebook_share_count}
                    />
                }

                {
                    this.shouldRenderColumn('facebook_page') &&
                    <CampaignActivationStatsTableRowSocialCell
                        social="facebook_page_totals"
                        posts={stat.num_facebook_page_posts}
                        reach={stat.facebook_page_reach}
                        engagements={stat.facebook_page_comment_count + stat.facebook_page_reaction_count + stat.facebook_page_share_count}
                    />
                }

                {
                    this.shouldRenderColumn('youtube') &&
                    <CampaignActivationStatsTableRowSocialCell
                        social="youtube_totals"
                        posts={stat.num_youtube_posts}
                        reach={stat.youtube_reach}
                        engagements={stat.youtube_like_count + stat.youtube_dislike_count + stat.youtube_comment_count}
                    />
                }

                {
                    this.shouldRenderColumn('tiktok') &&
                    <CampaignActivationStatsTableRowSocialCell
                        social="tiktok_totals"
                        posts={stat.num_tiktok_posts}
                        reach={stat.tiktok_reach}
                        engagements={stat.tiktok_comment_count + stat.tiktok_like_count + stat.tiktok_share_count}
                    />
                }

                <CampaignActivationStatsTableRowSocialCell
                    social="totals"
                    posts={stat.total_posts}
                    reach={stat.total_reach}
                    engagements={stat.total_engagements}
                />
            </tr>
        )

    }

    renderRows() {

        const activations = this.getSortedActivations();

        const rows = activations.map(
            a => <CampaignActivationStatsTableRow
                getEngagementFields={getEngagementFields}
                activation={a}
                shouldRenderColumn={this.shouldRenderColumn}
                key={a.id}
            />
        );

        return (
            <tbody>
            {rows}
            {this.renderTotalsRow()}
            </tbody>
        );
    }

    renderTable() {
        return (
            <table className="table table-striped">
                {this.renderHeader()}
                {this.renderRows()}
            </table>
        )
    }

    render() {
        return this.renderTable();
    }

}
