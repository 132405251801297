import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Image from '../../../Common/Image';

import {fetchAuthenticated} from "../../../../utilities";
import Alert from "../../../Common/Alert";

class SubmitInstastoryImage extends Component {

    static propTypes = {
        isUploading: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        refreshActivation: PropTypes.func.isRequired
    };

    state = {
        images: []
    };

    /**
     * Render the body widget for uploading images
     *
     * @returns {XML}
     */
    renderBody() {

        return (
            <div>
                {this.renderUploader()}
                {this.renderImages()}
            </div>
        );
    }

    /**
     * Render image previews
     *
     * @returns {XML}
     */
    renderImages() {

        const images = this.state.images || [];
        return <div className="clearfix images-container">{images.map(this.renderImage.bind(this))}</div>
    }

    /**
     * Return a single image component used to render image previews
     *
     * @see renderImages()
     * @param image
     * @returns {XML}
     */
    renderImage(image) {
        //return <div></div>
        return (

            <div
                className="image-tile-wrapper"
                style={{
                    height: 200,
                    float: 'left',
                    background: '#EEE',
                    marginBottom: 20,
                    position: 'relative',
                    padding: 20,
                }}
            >

                <a className="v3 close modal bg-primary-dark white"
                   role="button"
                   onClick={this.handleRemove.bind(this, image)}
                   style={{
                       left: 'inherit',
                       zIndex: 199,
                       // @TODO.
                       display: 'none',
                   }}
                />

                <Image
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    imageStyle={{
                        maxHeight: '100%',
                        display: 'block',
                        margin: 'auto',
                    }}
                    image={image}
                    key={image.id}
                />
            </div>
        );
    }

    /**
     * Upload an image to the API
     *
     * Updates the status to show an in-progress indicator while uploading is happening
     *
     * Upon completion, we call the handleSubmit function which is passed into the Component and bound to the parent
     * and is also responsible for updating the parent's state with new IDs
     *
     * @see SubmitInstastoryImage->handleImageSubmit()
     * @param event
     */
    handleUpload(event) {

        const formData = new FormData();
        formData.append('image', event.target.files[0]);
        formData.append('user_id', this.props.activation.user_id);
        formData.append('campaign_id', this.props.activation.campaign_id);

        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/image`;

        const options = {
            method: 'POST',
            body: formData,
            enctype: 'multipart/form-data'
        };

        this.props.isUploading(true); //Call method to update parent state

        return fetchAuthenticated(url, options)
            .then(res => res.json())
            .then(res => {
                this.props.onSubmit(res);
                const {images} = this.state;
                const newImages = [...images, res.data];
                this.setState({images: newImages});
            });//Call submit method after completion to update parent state
    }

    handleRemove(image) {
        if (this.state.isRemoving) {
            return null;
        }

        this.setState({isRemoving: true});
        const {activation} = this.props;

        //@todo Need an interaction for removing an activation post image
        const payload = {image_id: image.id, action: 'remove'};
        return this.props.createInteraction(activation.id, 'UploadImage', payload)
            .then(() => this.props.refreshActivation(activation.id))
            .then(() => this.setState({isRemoving: false}));

    }

    renderUploader() {

        return (

            <div className="form-group">
                <label><strong>Choose an Image to Upload</strong></label>
                <input type='file'
                       className='form-control'
                       onChange={this.handleUpload.bind(this)}
                />
                <p className="help-block">Upload a JPEG or PNG image. Max filesize is 4MB.</p>
            </div>

        );
    }

    render() {
        const {activation} = this.props;
        return (
            <div className="">
                <h5 className="v3 bold action-pane-title">Add Screenshots</h5>
                <p className="v3 light h7 pane-info">
                    {`Upload ${activation.settings.num_instastory_images} screenshots of your Instastory. Each screenshot should correspond to a panel of the Instastory.`}
                </p>
                {this.renderBody()}
            </div>
        );
    }
}

export default SubmitInstastoryImage;