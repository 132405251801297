
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../Common/Alert';
import DraftsTable from '../../../Activation/DraftsTable';

/**
 */
class SubmitDraft extends Component {

    constructor(props) {
        super(props);
        this.state = { };

        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
    }

    async refresh() {
        await this.props.refreshActivation();
    }

    _t(k, v) {
        if (this.props.translate) {
            return this.props.translate(k, v);
        }
        return v;
    }

    renderBody() {
        return <DraftsTable
            activation={this.props.activation}
            drafts={this.props.activation.drafts}
            onSaveDraft={this.refresh.bind(this)}
            onCreateComment={this.refresh.bind(this)}
            createInteraction={this.props.createInteraction}
            translate={this.props.translate}
        />
    }

    renderAlert() {
        const {activation} = this.props;
        const {settings = {}, status = {}} = activation;

        const reqDraftsCount = status.required_drafts_count || 0;
        const draftOrDrafts = `draft${reqDraftsCount !== 1 ? 's' : ''}`;

        const helpLink = <a
            href="http://docs.tid.al/knowledge-base/how-to-create-and-submit-drafts/"
            target="_blank"
            style={{color: "#66cc00"}}
        >here</a>;

        if (activation.status.total_drafts_count < activation.status.required_drafts_count) {
            return <Alert
                content={<div>You're required to submit {reqDraftsCount} {draftOrDrafts} for approval. You can continue once all required drafts are approved. Questions about creating a draft? Click {helpLink}.</div>}
                classes={['info']}
            />
        } else if (activation.status.accepted_drafts_count < activation.status.required_drafts_count) {
            return <Alert
                content={`You have fulfilled your requirement of submitting ${reqDraftsCount} ${draftOrDrafts} but approval is still pending. You can continue once all required drafts are approved.`}
                classes={['info']}
            />
        } else if (activation.status.open_drafts_count === 0 && activation.status.accepted_drafts_count === activation.status.required_drafts_count) {
            return <Alert
                content={`You have fulfilled your requirement of submitting ${reqDraftsCount} ${draftOrDrafts}.`}
                classes={['success']}
            />
        } else {
            return null;
        }
    }

    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }

    render() {

        if (this.isBlockedByNDA()) {
            return this.renderNDAAlert();
        }

        return (
            <div className="influencer-page-template submit-social-posts">
                <div className="widget">
                    <div className="title">
                        <div className="icon"><i className="fa fa-star"></i></div>
                        <h2>{this._t('campaign.drafts.title', 'Submit and Manage Drafts')}</h2>
                    </div>
                    <div className="content padded">
                        <h4 className="v3 bold action-pane-title">{this._t('campaign.drafts.subtitle', 'Submit Drafts')}</h4>
                        {this.renderAlert()}
                        {this.renderBody()}
                    </div>
                </div>
            </div>
        )
    }

}

SubmitDraft.propTypes = {
    activation: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    refreshActivation: PropTypes.func,
    createInteraction: PropTypes.func,
    translate: PropTypes.func,
};

export default SubmitDraft;
