import React, {useState} from 'react';
import Form from "../../Common/Form/Form";
import Alert from "../../Common/Alert";
import {bindActionCreators} from "redux";
import {createUserInteraction} from "../../../actions";
import {connect} from 'react-redux';


function AddSocialHandleForm({userId, onComplete, createUserInteraction}) {

    const [form, setForm] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);

    const handleSubmit = async () => {

        setSubmitting(true);

        let payload = {
            type: form.type,
            handle: form.handle
        };
        if (form.followers_count) {
            payload.followers_count = form.followers_count;
        }

        const resp = await createUserInteraction(userId, 'SimpleAttachSocial', payload);

        setSubmitting(false);
        if (onComplete) {
            onComplete(payload);
        }
    };
    return (
        <Form
            fields={[
                {
                    type: 'generic',
                    content: (
                        <Alert
                            classes={['info']}
                            content={"Add a social handle to this user. We will try our best to process this social account."}
                        />
                    )
                },
                {
                    type: 'select',
                    name: 'type',
                    title: 'Social Account Type',
                    choices: [
                        {text: 'Instagram', value: 'instagram'},
                        {text: 'TikTok', value: 'tiktok'},
                        {text: 'Twitter', value: 'twitter'},
                        {text: 'YouTube', value: 'youtube'},
                        {text: 'Pinterest', value: 'pinterest'},
                        {text: 'Facebook Page', value: 'facebook-page'},
                    ]
                },
                {
                    type: 'text',
                    name: 'handle',
                    title: 'Social Handle',
                    help: 'For Youtube, use the link to the channel.',
                    placeholder: 'eg: bkanbertidal'
                },
                {
                    type: 'number',
                    name: 'followers_count',
                    title: 'Followers Count',
                    help: 'Optional. Include if it you know it.',
                },
                {
                    type: 'button',
                    title: isSubmitting ? 'Saving, please wait...' : 'Save',
                    options: {
                        classes: ['v3', 'btn-primary'],
                        hideTitle: true,
                        onClick: handleSubmit
                    }
                }
            ]}
            values={form}
            onFieldChange={(k, v) => setForm(prevForm => ({...prevForm, [k]: v}))}
        />
    );
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({createUserInteraction: createUserInteraction}, dispatch);

export default connect(undefined, mapDispatchToProps)(AddSocialHandleForm);