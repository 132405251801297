import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tooltip from "./Tooltip";

export default class Switch extends Component {

    static defaultProps = {
        style: {}
    };

    static propTypes = {
        icon: PropTypes.node,
        tooltip: PropTypes.string,
        isActive: PropTypes.bool,
        onClick: PropTypes.func,
        style: PropTypes.object,
        label: PropTypes.node,
    };

    getClasses() {
        let classes = ['v3', 'switch'];
        if (this.props.isActive) {
            classes.push('active');
        }
        return classes.join(' ');
    }

    renderIcon() {
        if (this.props.icon) {
            return this.props.icon;
        }
    }

    renderLabel() {
        if (this.props.label) {
            return (
                <span className="switch-label">{this.props.label}</span>
            );
        }
    }

    handleClick(event) {
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    renderInner() {
        return (
            <div
                className={this.getClasses()}
                style={this.props.style}
                onClick={this.handleClick.bind(this)}
            >
                {this.renderIcon()}
                {this.renderLabel()}
            </div>
        )
    }

    render() {
        const content = this.renderInner();

        if (!this.props.tooltip) {
            return content;
        }

        if (typeof this.props.tooltip === 'string') {
            return <Tooltip title={this.props.tooltip} arrow={false}>{content}</Tooltip>;
        } else {
            return <Tooltip html={this.props.tooltip} arrow={false}>{content}</Tooltip>;

        }
    }
}