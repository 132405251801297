/**
 * Created by bkanber on 6/29/17.
 */

import {makeFormData, fetchAuthenticated} from '../utilities';
import {receiveBlogs} from './blogs';
import * as ActionTypes from './types';

export const CREATED_TALENTSPIDER = 'CREATED_TALENTSPIDER';
export const CREATE_TALENTSPIDER = 'CREATE_TALENTSPIDER';

export const REQUEST_TALENTSPIDERS = 'REQUEST_TALENTSPIDERS';
export const RECEIVE_TALENTSPIDERS = 'RECEIVE_TALENTSPIDERS';

export const REQUEST_TALENTSPIDER = 'REQUEST_TALENTSPIDER';
export const RECEIVE_TALENTSPIDER = 'RECEIVE_TALENTSPIDER';

export const REQUEST_TALENTSPIDER_CANDIDATES = 'REQUEST_TALENTSPIDER_CANDIDATES';
export const RECEIVE_TALENTSPIDER_CANDIDATES = 'RECEIVE_TALENTSPIDER_CANDIDATES';
export const INVALIDATE_TALENTSPIDER_CANDIDATES = 'INVALIDATE_TALENTSPIDER_CANDIDATES';

export const UPDATE_TALENTSPIDER_CANDIDATE = 'UPDATE_TALENTSPIDER_CANDIDATE';
export const UPDATED_TALENTSPIDER_CANDIDATE = 'UPDATED_TALENTSPIDER_CANDIDATE';
export const DELETE_TALENTSPIDER_CANDIDATE = 'DELETE_TALENTSPIDER_CANDIDATE';
export const DELETED_TALENTSPIDER_CANDIDATE = 'DELETED_TALENTSPIDER_CANDIDATE';

export const UPDATE_TALENTSPIDER_SETTING = 'UPDATE_TALENTSPIDER_SETTING';
export const UPDATED_TALENTSPIDER_SETTING = 'UPDATED_TALENTSPIDER_SETTING';
export const REQUEST_TALENTSPIDER_SETTINGS = 'REQUEST_TALENTSPIDER_SETTINGS';
export const RECEIVE_TALENTSPIDER_SETTINGS = 'RECEIVE_TALENTSPIDER_SETTINGS';

export const REQUEST_TALENTSPIDER_PIPELINE = 'REQUEST_TALENTSPIDER_PIPELINE';
export const RECEIVE_TALENTSPIDER_PIPELINE = 'RECEIVE_TALENTSPIDER_PIPELINE';
export const INVALIDATE_TALENTSPIDER_PIPELINE = 'INVALIDATE_TALENTSPIDER_PIPELINE';

export const createTalentSpiderInteraction = (tsId, type, context) => {

    return (dispatch) => {

        const dashboard = window.Gator.getDashboardSlot();
        const uri = `/${dashboard}/api/talentspider/${tsId}/interaction`;

        dispatch({
            type: ActionTypes.TALENTSPIDER_CREATE_INTERACTION,
            id: tsId,
            interactionType: type,
            context
        });

        return fetchAuthenticated(uri, {
            credentials: 'include',
            method: 'POST',
            body: makeFormData({
                type,
                context: JSON.stringify(context)
            })
        })
            .then(resp => resp.json())
            .then(json => {
                dispatch({
                    type: ActionTypes.TALENTSPIDER_CREATED_INTERACTION,
                    id: tsId,
                    meta: json.meta,
                    data: json.data
                });
                return json
            });
    }
};

export function reprocessTalentspider(id) {
    return function (dispatch) {
        return fetchAuthenticated(`/manage/api/talentspider/${id}/process`, {credentials: 'include', method: 'post'})
            .then(resp => resp.json())
            .then(json => dispatch(receiveTalentspider(id, json.data)))
            .then(() => dispatch(invalidateTalentspiderCandidates(id)));
    }
}

export function invalidateTalentspiderPipeline(id) {
    return {
        type: INVALIDATE_TALENTSPIDER_PIPELINE,
        id
    };
}

export function invalidateTalentspiderCandidates(id) {
    return {
        type: INVALIDATE_TALENTSPIDER_CANDIDATES,
        id
    };
}

export function deleteTalentspiderCandidate(talentspiderId, candidateId) {
    return function (dispatch) {
        dispatch({
            type: DELETE_TALENTSPIDER_CANDIDATE,
            talentspiderId,
            candidateId
        });

        return fetchAuthenticated(
            `/manage/api/talentspider/${talentspiderId}/candidate/${candidateId}`,
            {
                credentials: 'include',
                method: 'post',
                body: makeFormData({
                    _method: 'delete'
                })
            }
        )
            .then(resp => dispatch({
                type: DELETED_TALENTSPIDER_CANDIDATE,
                talentspiderId,
                candidateId
            }))
    }
}

export function updatedTalentspiderCandidate(talentspiderId, id, name, value, data) {
    return {
        type: UPDATED_TALENTSPIDER_CANDIDATE,
        talentspiderId,
        id,
        name,
        value,
        data: data,
        receivedAt: new Date()
    };
}

export function updateTalentspiderCandidate(talentspiderId, id, name, value) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_TALENTSPIDER_CANDIDATE,
            talentspiderId,
            id,
            name,
            value
        });

        const payload = {
            _method: 'PATCH',
            [name]: value
        };

        return fetchAuthenticated(
            `/manage/api/talentspider/${talentspiderId}/candidate/${id}`,
            {
                credentials: 'include',
                method: 'POST',
                body: makeFormData(payload)
            }
        )
            .then(resp => resp.json())
            .then(json => {
                dispatch(updatedTalentspiderCandidate(talentspiderId, id, name, value, json.data));
                return dispatch(receiveTalentspiderCandidates(talentspiderId, [json.data]));
            });
    }
}

export function fetchTalentspiderSettings(id) {
    return function (dispatch) {
        dispatch({
            type: REQUEST_TALENTSPIDER_SETTINGS,
            id
        });
        const url = `/manage/api/talentspider/${id}/setting`;
        return fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => dispatch(receiveTalentspiderSettings(id, json.data)));
    }
}

export function updateTalentspiderSetting(id, name, value) {

    return function (dispatch) {

        dispatch({
            type: UPDATE_TALENTSPIDER_SETTING,
            id,
            name,
            value
        });

        const url = `/manage/api/talentspider/${id}/setting/${name}`;

        return fetchAuthenticated(url, {
            credentials: 'include',
            method: 'post',
            body: makeFormData({
                _method: 'put',
                value
            })
        })
            .then(resp => resp.json())
            .then(json => {
                dispatch({
                    type: UPDATED_TALENTSPIDER_SETTING,
                    id,
                    name,
                    value
                });
                return dispatch(receiveTalentspiderSettings(id, json.data));
            });
    }
}

export function receiveTalentspiderSettings(id, data) {
    return {
        type: RECEIVE_TALENTSPIDER_SETTINGS,
        id,
        data,
        receivedAt: new Date()
    };
}

export function requestTalentspiderSettings(id) {
    return {
        type: REQUEST_TALENTSPIDER_SETTINGS,
        id
    };
}

/**
 * Can handle RECEIVE_TALENTSPIDER_CANDIDATES or RECEIVE_TALENTSPIDER_PIPELINE
 * @param id
 * @param data
 * @param type
 * @returns {Function}
 */
export function receiveTalentspiderCandidates(id, data, type = RECEIVE_TALENTSPIDER_CANDIDATES) {
    return function (dispatch) {

        const blogs = data.map(candidate => candidate.blog);
        dispatch(receiveBlogs(blogs));

        const candidates = data.map(candidate => {
            let c = {...candidate};
            c.blog_id = candidate.blog.id;
            return c;
        });

        dispatch({
            type,
            id,
            data: candidates,
            receivedAt: new Date()
        });

    }

}

export function receiveTalentspiderPipeline(id, data) {
    return receiveTalentspiderCandidates(id, data, RECEIVE_TALENTSPIDER_PIPELINE);
}

export function fetchAllCandidates(id) {
    return function (dispatch) {
        dispatch({
            type: REQUEST_TALENTSPIDER_CANDIDATES,
            id
        });

        return fetchAuthenticated(`/manage/api/talentspider/${id}/candidate`)
            .then(resp => resp.json())
            .then(json => dispatch(receiveTalentspiderCandidates(id, json.data)));
    }
}

export function fetchTalentspiderCandidate(tsid, cid) {
    return function (dispatch) {
        dispatch({
            type: ActionTypes.TALENTSPIDER_REQUEST_CANDIDATE,
            tsid,
            cid,
        });

        return fetchAuthenticated(`/manage/api/talentspider/${tsid}/candidate?filters={"id":${cid}}`)
            .then(resp => resp.json())
            .then(json => dispatch(receiveTalentspiderCandidates(tsid, json.data)));
    }
}

export function fetchUnratedCandidates(id) {
    return function (dispatch) {
        dispatch({
            type: REQUEST_TALENTSPIDER_CANDIDATES,
            id
        });

        return fetchAuthenticated(`/manage/api/talentspider/${id}/blog`, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => dispatch(receiveTalentspiderCandidates(id, json.data)));
    }
}

export function fetchTalentspiderPipeline(id) {
    return function (dispatch) {
        dispatch({
            type: REQUEST_TALENTSPIDER_PIPELINE,
            id
        });

        return fetchAuthenticated(`/manage/api/talentspider/${id}/recruit`, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => dispatch(receiveTalentspiderPipeline(id, json.data)));
    }
}

export function receiveTalentspider(id, data) {
    return {
        type: RECEIVE_TALENTSPIDER,
        id,
        data,
        receivedAt: new Date()
    };
}

export function fetchTalentspider(id) {
    return function (dispatch) {
        dispatch({
            type: REQUEST_TALENTSPIDER,
            id
        });

        const url = `/manage/api/talentspider/${id}`;
        return fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => dispatch(receiveTalentspider(id, json.data)));
    }
}

export const receiveTalentspiders = resp => {
    return {
        type: RECEIVE_TALENTSPIDERS,
        meta: resp.meta,
        data: resp.data,
    }
};

export function fetchTalentspiders() {
    return function (dispatch) {
        dispatch({ type: REQUEST_TALENTSPIDERS });
        return fetchAuthenticated(`/manage/api/talentspider`, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => dispatch(receiveTalentspiders(json)));
    }
}

export const createTalentspider = (params) => {
    return (dispatch) => {
        dispatch({
            type: CREATE_TALENTSPIDER,
            params
        });

        return fetchAuthenticated(`/manage/api/talentspider`, {
            credentials: 'include',
            method: 'post',
            body: makeFormData(params)
        })
            .then(resp => resp.json())
            .then(json => dispatch({
                    type: CREATED_TALENTSPIDER,
                    data: json.data,
                    meta: json.meta
                })
            );
    }
}