import React, {useState, useEffect, useMemo} from 'react';
import MyCampaignLeaderboard from "../../../MAC/Creator/MyCampaignLeaderboard";
import MyPerformanceCharts from "../../../MAC/Creator/MyPerformanceCharts";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {fetchAuthenticated} from "../../../../actions/auth";
import {fetchCampaigns} from "../../../../actions";
import {getCampaigns} from "../../../../selectors/campaigns";
import BriefsTable from "../../../MAC/Creator/BriefsTable";
import {getCurrentChannel, getCurrentUser, getPresenceTeam} from "../../../../selectors/presence";
import { magicFilterBriefs } from "../../../../utilities/mac";
import {translate} from "../../../../actions/i18n";
import {Link} from "react-router-dom";

function MACCreatorBriefsPage({fetchAuthenticated, campaigns, user, team, channel, translate}) {
    const [activations, setActivations] = useState([]);
    const fetchActivations = async () => {
        const resp = await fetchAuthenticated('/contribute/api/user/me/activation?withStats=1');
        const json = await resp.json();
        setActivations(json.data);
    };
    useEffect(function() {
        Promise.all([
            fetchActivations(),
        ]).then(() => console.log("Done loading MACCreatorBriefsPage data"));
    }, []);

    const filteredCampaigns = useMemo(function () {
        let teamToUse = team;
        let filterByTeam = true;
        if (channel.filter_teams === false) {
            teamToUse = null;
            filterByTeam = false;
        }
        return magicFilterBriefs(campaigns, user, teamToUse, true, filterByTeam);
    }, [campaigns, user, team, channel]);

    const backWord = translate('creator.words.back', 'Back');
    return (
        <div className="mac-wrapper">
            <div className="dashboard">

                <div className="briefs-page-wrapper">

                    <div className="page-title-wrapper briefs-title-wrapper">
                        <h1 className="red-indent">Briefs
                            <Link to="/contribute/do/page/dash/home" className="seeall">{backWord}<i className="v3 icon fa-arrow-left" /> </Link>
                        </h1>
                    </div>

                    <div className="briefs-table-wrapper">
                        <BriefsTable campaigns={filteredCampaigns} activations={activations} translate={translate} user={user} />
                    </div>

                </div>

            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => ({
    campaigns: getCampaigns(state, props),
    user: getCurrentUser(state, props),
    team: getPresenceTeam(state, props),
    channel: getCurrentChannel(state, props),
});
const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    fetchAuthenticated: fetchAuthenticated,
    translate: translate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MACCreatorBriefsPage);