/**
 * Created by bkanber on 6/29/17.
 */

import {makeFormData, fetchAuthenticated} from '../utilities';
import * as ActionTypes from './types';

export const RECEIVE_BADGES = 'RECEIVE_BADGES';
export const REQUEST_BADGES = 'REQUEST_BADGES';

export const ADD_USERS_TO_BADGE = 'ADD_USERS_TO_BADGE';
export const ADDED_USER_TO_BADGE = 'ADDED_USER_TO_BADGE';
export const ADDED_USERS_TO_BADGE = 'ADDED_USERS_TO_BADGE';
export const REMOVE_USERS_FROM_BADGE = 'REMOVE_USERS_FROM_BADGE';
export const REMOVED_USERS_FROM_BADGE = 'REMOVED_USERS_FROM_BADGE';

export function removedUsersFromBadge(userIds, badgeId) {
    return {
        type: REMOVED_USERS_FROM_BADGE,
        userIds,
        badgeId
    }

}

export function removeUsersFromBadge(userIds, badgeId) {

    const payload = {ids: userIds.join(','), _method: "DELETE"};
    const url = `/manage/api/badge/${badgeId}/user`;

    return function (dispatch) {

        dispatch({type: REMOVE_USERS_FROM_BADGE, userIds, badgeId});

        return fetchAuthenticated(url,
            {
                credentials: 'include',
                method: 'POST',
                body: makeFormData(payload)
            } )
            .then(response => response.json())
            .then(json => {
                return dispatch(removedUsersFromBadge(userIds, badgeId))
            })
            .catch(err => {
                console.log("Error removing users from badge");
                console.log(err)
            });
    }

}

export function addedUsersToBadge(userIds, badgeId) {
    return {
        type: ADDED_USERS_TO_BADGE,
        badgeId,
        userIds,
        receivedAt: Date.now()
    };

}

export function addedUserToBadge(userId, badgeId) {
    return {
        type: ADDED_USER_TO_BADGE,
        badgeId,
        userId,
        receivedAt: Date.now()
    };

}

export function addUsersToBadge(userIds, badgeId) {
    const payload = {ids: userIds.join(',')};
    const url = `/manage/api/badge/${badgeId}/user`;

    return function (dispatch) {

        dispatch({type: ADD_USERS_TO_BADGE, userIds, badgeId});

        return fetchAuthenticated(url,
            {
                credentials: 'include',
                method: 'POST',
                body: makeFormData(payload)
            } )
            .then(response => response.json())
            .then(json => {
                for (let userId of userIds) {
                    dispatch(addedUserToBadge(userId, badgeId));
                }
                return dispatch(addedUsersToBadge(userIds, badgeId));
            })
            .catch(err => {
                console.log("Error Adding users to badge");
                console.log(err)
            });
    }

}

export function requestBadges() {
    return {
        type: REQUEST_BADGES,
    };
}

export function receiveBadges(json) {
    return {
        type: RECEIVE_BADGES,
        badges: json.data,
        receivedAt: Date.now()
    }
}

/**
 * @returns {Function}
 */
export function fetchBadges() {
    return function (dispatch) {
        dispatch(requestBadges())

        return fetchAuthenticated(`/manage/api/badge`, {credentials: 'include'})
            .then(response => response.json())
            .then(json => dispatch(receiveBadges(json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

/**
 * @param state
 * @returns {*}
 */
export function shouldFetchBadges(state) {
    if (state.badgesById.isFetching || state.badgesById.lastUpdated) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchBadgesIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchBadges(getState())) {
            return dispatch(fetchBadges())
        }
    }
}


export const createBadge = (params) => {
    return function (dispatch) {
        dispatch({type: ActionTypes.CREATE_BADGE});
        return fetchAuthenticated(`/manage/api/badge`, {credentials: 'include', method: 'post', body: makeFormData({...params})})
            .then(res => res.json())
            .then(json => dispatch({type: ActionTypes.CREATED_BADGE, data: json.data, meta: json.meta}))
            .catch(console.error)
    };
};
export const updateBadge = (id, params) => {
    return function (dispatch) {
        const url = `/manage/api/badge/${id}`;
        dispatch({type: ActionTypes.UPDATE_BADGE, id});
        return fetchAuthenticated(url, {credentials: 'include', method: 'post', body: makeFormData({...params, _method: 'put'})})
            .then(res => res.json())
            .then(json => dispatch({type: ActionTypes.UPDATED_BADGE, data: json.data, meta: json.meta}))
            .catch(console.error)
    };
};