import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import Row from './Row';
import DragDropRow from "./DragDropRow";
import Sort from './Header/Sort';
import Tabs from './Header/Tabs';
import FilterPopup from './Header/FilterPopup';

import {DndProvider} from "react-dnd";
import {HTML5Backend} from 'react-dnd-html5-backend';
import PaginatorDropTarget from "./PaginatorDropTarget";

class Table extends Component {

    constructor(props) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);
        this.handlePageScroll = this.handlePageScroll.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleShowFilter = this.handleShowFilter.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);

        this.state = {
            // For the filter popup
            activeFilter: null,
            filterDebouncer: null,
            filterUpdates: {}
        };

        /** Maintains a ref to the <Sort> object so that we can sync horizontal scrolling */
        this.sortBarRef = null;
        /** For the filter popup; know where sort tab is */
        this.activeFilterTabRef = null;
        this.filterPopupRef = null;

        /** Maintains refs to the expansions */
        this.expansionRefs = [];
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handlePageScroll);
    }

    getFilterPopupStyle() {

        if (!this.activeFilterTabRef) return;

        let boundingRect = this.activeFilterTabRef.getBoundingClientRect();

        let top = boundingRect.y + boundingRect.height;

        let style = {
            position: 'fixed',
            top: top+'px',
            left: boundingRect.left + 'px'
        };

        return style;


    }

    handleShowFilter(column, event) {

        if (column.key === this.state.activeFilter) {
            return this.setState({activeFilter: null});
        }

        this.activeFilterTabRef = ReactDOM.findDOMNode(event.currentTarget);

        return this.setState({
            activeFilter: column.key,
        });
    }

    handleSortChange(sort) {
        if (this.props.onSortChange) {
            this.props.onSortChange(sort);
        }
    }

    handlePageScroll(event) {
        if (this.filterPopupRef) {
            Object.assign(this.filterPopupRef.style, this.getFilterPopupStyle());
        }
    }

    handleScroll(event) {

        if (!event.target) return;

        /**
         * Sync scrolling with the <Sort> bar
         * But not if we're scrolling in the expansion
         * So we check all expansion refs to see if that's where we're scrolling
         */
        for (let expansion of this.expansionRefs) {
            if (!expansion) continue;
            if (expansion.contains(event.target)) {
                return;
            }
        }

        if (this.sortBarRef) {
            this.sortBarRef.scrollLeft = event.target.scrollLeft;
        }

        if (this.filterPopupRef) {
            Object.assign(this.filterPopupRef.style, this.getFilterPopupStyle());
        }

        /**
         * We also need to expand the expansions' width if we're scrolled
         * This avoids cutting off the right end of the expansion if the table itself is scrolled
         * We figure out the new width by measuring the parent's width (the row)
         * and then adding the scroll position to it
         */
        for (let expansion of this.expansionRefs) {
            if (!expansion) continue;
            let parent = expansion.parentNode;
            let parentDomNode = ReactDOM.findDOMNode(parent);
            if (!parentDomNode) continue;
            let parentWidth = parentDomNode.getBoundingClientRect().width;
            let newWidth = parentWidth + event.target.scrollLeft;
            // expansion.style.width = newWidth + 'px';
        }
    }

    getVisibleColumns() {

        const {visibleColumns, columns} = this.props;

        if (visibleColumns) {
            return visibleColumns;
        }

        return columns
            .filter(col => !!col && !!col.default)
            .map(col => col.key);
    }

    /**
     * @param key
     * @returns {null}
     */
    getColumnSpecByKey(key) {
        const {columns} = this.props;
        const filtered = columns.filter(col => ((col || {}).key || undefined) === key);
        if (filtered.length > 0) {
            return filtered[0];
        }
        return null;
    }

    handleFilterChange(key, value) {
        // we need to store the filter updates in a debouncer
        // so that we can send them all at once
        if (this.state.filterDebouncer) {
            console.log("Clearing previous debouncer");
            clearTimeout(this.state.filterDebouncer);
        }

        const filterUpdates = {
            ...this.state.filterUpdates,
            [key]: value
        };

        const filterDebouncer = setTimeout(() => {
            if (this.props.onFilterChange) {
                console.log("Debouncer sending updates to parent", key, value);
                this.props.onFilterChange(key, value);
            }
            this.setState({
                filterUpdates: {},
                filterDebouncer: null
            });
        }, 1000);

        this.setState({
            filterUpdates,
            filterDebouncer
        });
    }

    renderFilterPopup() {
        const {activeFilter} = this.state;
        if (!activeFilter) return null;
        const column = this.getColumnSpecByKey(activeFilter);
        if (!column || !column.search) return null;
        const style = this.getFilterPopupStyle();

        // filters object is comprised of initial filters and filter updates
        const filters = {
            ...this.props.filters,
            ...this.state.filterUpdates
        };

        return <FilterPopup
            ref={el => {this.filterPopupRef = ReactDOM.findDOMNode(el);}}
            style={style}
            fields={column.search}
            filters={filters}
            onFilterChange={this.handleFilterChange}
            onResetSearch={column.onResetSearch || null}
        />
    }

    handleRowVisibilityChange(row) {

        if (this.props.onRowVisibilityChange) {
            this.props.onRowVisibilityChange(row);
        }

    }

    render() {

        const {
            items, columns, stickySortTabs, isRowSelected,
            isRowExpanded, expander, sort, showLoadingAlert, blurry, tabs, showSort,
            useDragDrop, onRowDrop, page, pages
        } = this.props;

        let classes = ['godzilla-table-rows'];
        if (blurry) classes.push('blurry');
        const isUsingDragDrop = useDragDrop && !!onRowDrop;
        const RowOrDragDropRow = isUsingDragDrop ? DragDropRow : Row;

        return (
            <div className="godzilla-table" style={this.props.style}>
                <DndProvider backend={HTML5Backend}>

                {
                    tabs && <Tabs tabs={tabs} />
                }

                {showSort && <Sort
                    columns={columns}
                    visibleColumns={this.getVisibleColumns()}
                    sticky={stickySortTabs}
                    onRefReady={el => { this.sortBarRef = el; }}
                    onSortChange={this.handleSortChange}
                    sort={sort}
                    showTotals={this.props.showTotals}
                    showFilters={this.props.showFilters}
                    onShowFilter={this.handleShowFilter}
                    filters={this.props.filters}
                    activeFilter={this.state.activeFilter}
                /> }

                {this.renderFilterPopup()}

                {
                    showLoadingAlert && <div className="alert tidal-alert alert-grey" style={{margin: 0}}>Loading...</div>
                }

                    {(isUsingDragDrop && page && page > 1) ? <PaginatorDropTarget direction={-1} /> : null}
                    <div className={classes.join(' ')} onScroll={this.handleScroll}>
                        {items.map((item, index) =>
                                item && <RowOrDragDropRow
                                    key={`row-${item._key || item.id}-${index}`}
                                    index={index}
                                    item={item}
                                    columns={columns}
                                    visibleColumns={this.getVisibleColumns()}
                                    isRowSelected={isRowSelected}
                                    isRowExpanded={isRowExpanded}
                                    expander={expander}
                                    onExpanderRefReady={el => { this.expansionRefs.push(el); }}
                                    onVisibilityChange={this.handleRowVisibilityChange.bind(this)}
                                    onDrop={onRowDrop}
                                />
                        )}
                    </div>
                    {(isUsingDragDrop && pages && page && pages > page) ? <PaginatorDropTarget direction={1}/> : null}
                </DndProvider>
            </div>
        );
    }
}

Table.defaultProps = {
    stickySortTabs: false,
    showLoadingAlert: false,
    showTotals: false,
    showFilters: false,
    showSort: true,
    isRowSelected: () => false,
    isRowExpanded: () => false,
    filters: {},
    style: {},
};

Table.propTypes = {
    /** Array of items to render. */
    items: PropTypes.array.isRequired,

    /** Column spec. */
    columns: PropTypes.array.isRequired,

    /** Array of columns (by key) that should be made visible */
    visibleColumns: PropTypes.array,

    /** Whether sort tabs should stick to top of the page on scroll */
    stickySortTabs: PropTypes.bool,

    /**
     * Called to check whether the row should be selected. Return boolean.
     * @param {Object} row
     */
    isRowSelected: PropTypes.func,
    /**
     * Called to check whether the row should be expanded. Return boolean.
     * @param {Object} row
     */
    isRowExpanded: PropTypes.func,

    /**
     * Called in order to generate the row expansion
     * @param {Object} row
     */
    expander: PropTypes.func,

    /**
     * An object representing the current sort
     * @property {{by: string, dir: string}}
     */
    sort: PropTypes.object,

    /**
     * Called when the sort has changed.
     * @param {{by: string, dir: string}}
     */
    onSortChange: PropTypes.func,

    /**
     * Show a loading alert in between the sort and the table
     */
    showLoadingAlert: PropTypes.bool,

    /**
     * Show totals in sort tabs? Requires column.total
     */
    showTotals: PropTypes.bool,

    /**
     * Show searchable columns?
     */
    showFilters: PropTypes.bool,

    /**
     * Show sort tabs?
     */
    showSort: PropTypes.bool,

    /**
     * Make table blurry? Used for loading effects.
     */
    blurry: PropTypes.bool,

    /**
     * Managed by showFilters functionality
     */
    filters: PropTypes.object,

    /**
     * Handler for filters
     */
    onFilterChange: PropTypes.func,

    /**
     * Called when the visible state of a row has changed.
     */
    onRowVisibilityChange: PropTypes.func,

    /**
     * Tabs spec
     */
    tabs: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.node,
        isActive: PropTypes.bool,
        onClick: PropTypes.func,
        classes: PropTypes.array,
        style: PropTypes.object,
        badge: PropTypes.node,
    })),

    style: PropTypes.object,

    useDragDrop: PropTypes.bool,
    onRowDrop: PropTypes.func,

};

export default Table;
