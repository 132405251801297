import {makeFormData, fetchAuthenticated} from '../utilities';
import {receiveUser} from './index';

export const REQUEST_TEAM = 'REQUEST_TEAM';
export const RECEIVE_TEAM = 'RECEIVE_TEAM';
export const REQUEST_TEAMS = 'REQUEST_TEAMS';
export const RECEIVE_TEAMS = 'RECEIVE_TEAMS';
export const REQUEST_TEAM_USERS = 'REQUEST_TEAM_USERS';
export const RECEIVE_TEAM_USERS = 'RECEIVE_TEAM_USERS';
export const ADD_TEAM_USER = 'ADD_TEAM_USER';
export const ADDED_TEAM_USER = 'ADDED_TEAM_USER';
export const REMOVE_TEAM_USER = 'REMOVE_TEAM_USER';
export const REMOVED_TEAM_USER = 'REMOVED_TEAM_USER';
export const CREATE_TEAM = 'CREATE_TEAM';
export const CREATED_TEAM = 'CREATED_TEAM';
export const UPDATE_TEAM_SETTINGS = 'UPDATE_TEAM_SETTINGS';
export const UPDATED_TEAM_SETTINGS = 'UPDATED_TEAM_SETTINGS';

export function updateTeamSettings(teamId, settings) {
    return function(dispatch) {
        dispatch({type: UPDATE_TEAM_SETTINGS, teamId, settings});
        const url = `/manage/api/team/${teamId}`;
        const payload = {...settings, _method: 'PATCH'};
        return fetchAuthenticated(url, {
            credentials: 'include',
            method: 'POST',
            body: makeFormData(payload)
        })
            .then(resp => resp.json())
            .then(json => dispatch({
                type: UPDATED_TEAM_SETTINGS,
                receivedAt: new Date(),
                teamId,
                team: json.data,
                meta: json.meta
            }))
            .catch(err => {
                console.log("Error updating team settings");
                console.log(err);
                return err;
            });
    }
}

export function createTeam(name) {
    return function (dispatch) {
        dispatch({type: CREATE_TEAM, name});

        const url = `/manage/api/channel/_/team`;
        const payload = {name};

        return fetchAuthenticated(url, {
            credentials: 'include',
            method: 'POST',
            body: makeFormData(payload)
        })
            .then(resp => resp.json())
            .then(json => dispatch({
                type: CREATED_TEAM,
                receivedAt: new Date(),
                name,
                team: json.data
            }))
            .catch(err => {
                console.log("Error creating team");
                console.log(err);
                return err;
            });

    }

}

/**
 * @param state
 * @param teamId
 * @returns {*}
 */
export function shouldFetchTeam(state, teamId) {
    const team = state.teamsById.items[teamId];
    if (state.teamsById.isFetching) {
        return false;
    } else {

        if (typeof team === 'undefined') {
            return true;
        } else if (team.isFetching) {
            return false;
        } else {
            return team.didInvalidate;
        }

    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchTeamIfNeeded(teamId) {
    return (dispatch, getState) => {
        if (shouldFetchTeam(getState(), teamId)) {
            return dispatch(fetchTeam(teamId))
        }
    }
}

/**
 * @param state
 * @returns {*}
 */
export function shouldFetchTeams(state) {
    if (state.teamsById.isFetching) {
        return false;
    } else {
        return state.teamsById.didInvalidate;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchTeamsIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchTeams(getState())) {
            return dispatch(fetchTeams())
        }
    }
}

export function receiveTeam(teamId, team) {
    return {
        type: RECEIVE_TEAM,
        receivedAt: new Date(),
        teamId,
        team
    };
}

export function removeTeamUser(teamId, userId) {
    return function (dispatch) {
        dispatch({type: REMOVE_TEAM_USER, teamId, userId});

        const url = `/manage/api/team/${teamId}/user/${userId}`;
        return fetchAuthenticated(url, {
            credentials: 'include',
            method: 'POST',
            body: makeFormData({_method: 'DELETE'})
        })
            .then(resp => resp.json())
            .then(json => dispatch({
                type: REMOVED_TEAM_USER,
                receivedAt: new Date(),
                teamId,
                userId
            }))
            .catch(err => {
                console.log("Error deleting team users");
                console.log(err);
                return err
            });
    }
}

export function addTeamUser(teamId, userId) {
    return function (dispatch) {
        dispatch({type: ADD_TEAM_USER, teamId, userId});

        const url = `/manage/api/team/${teamId}/user/${userId}`;
        return fetchAuthenticated(url, {
                credentials: 'include',
                method: 'POST',
                body: makeFormData({_method: 'PUT'})
            })
            .then(resp => resp.json())
            .then(json => dispatch({
                type: ADDED_TEAM_USER,
                receivedAt: new Date(),
                teamId,
                userId
            }))
            .catch(err => {
                console.log("Error fetching team users");
                console.log(err);
                return err
            });
    }
}

export function fetchTeamUsers(teamId) {
    return function (dispatch) {
        dispatch({type: REQUEST_TEAM_USERS, teamId});

        const url = `/manage/api/team/${teamId}/user`;
        return fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => {
                json.data.forEach(user => dispatch(receiveUser(user.id, {data: user})));
                return dispatch({
                    type: RECEIVE_TEAM_USERS,
                    receivedAt: new Date(),
                    teamId,
                    users: json.data
                })
            })
            .catch(err => {
                console.log("Error fetching team users");
                console.log(err);
                return err
            });
    }
}

export function fetchTeams() {
    return function (dispatch) {
        dispatch({type: REQUEST_TEAMS});

        const url = '/manage/api/channel/_/team';
        return fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => {
                json.data.forEach(team => dispatch(receiveTeam(team.id, team)));
                return dispatch({
                    type: RECEIVE_TEAMS,
                    receivedAt: new Date(),
                    teams: json.data
                });
            })
            .catch(err => {
                console.log("Error fetching teams");
                console.log(err);
                return err
            });
    }
}

export function fetchTeam(teamId) {
    return function (dispatch) {
        dispatch({type: REQUEST_TEAM, teamId});

        const url = `/manage/api/team/${teamId}`;
        return fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => dispatch(receiveTeam(teamId, json.data)))
            .catch(err => {
                console.log("Error fetching team");
                console.log(err);
                return err
            });
    }
}
