import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "./Form/Button";
import Toolbox from "./Toolbox";

const DEFAULT_STYLE = {
    display: 'inline-block'
};

const DEFAULT_CLASSES = [
    'actions-btn', 'v3', 'tidal-btn', 'small', 'bold'
];

class GenericActionsButton extends Component {

    static propTypes = {

        actions: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            isValid: PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.bool,
            ]),
            onClick: PropTypes.func,
        })).isRequired,

        wrapperStyle: PropTypes.object,
        classes: PropTypes.array,
        content: PropTypes.node,
        onClickAction: PropTypes.func,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        wrapperStyle: {},
        classes: [],
        content: "Actions",
        filters: {},
        disabled: false,
    };

    state = {
        showDropdown: false,
    };


    handleClickAction(item) {
        this.setState({showDropdown: false});
        if (typeof item.onClick === 'function') {
            item.onClick();
        }
        if (this.props.onClickAction) {
            this.props.onClickAction(item);
        }
    }


    getItems() {
        return this.props.actions.filter(spec => {
            if (typeof spec.isValid === 'undefined' || spec.isValid === true) {
                return true;
            }
            return spec.isValid()
        });
    }

    renderDropdownContent() {
        return this.getItems().map(item => {


            return (

                <div className="fake-li condensed" key={`quick-filter-item-${item.name}`}>
                    <a
                        role="button"
                        onClick={this.handleClickAction.bind(this, item)}
                        className="v3 h7 light"
                        style={{fontSize: 14}}
                    >
                        {item.title}
                    </a>
                </div>

            );
        })
    }

    renderDropdown() {

        if (!this.state.showDropdown || this.props.disabled) {
            return null;
        }

        return (
            <Toolbox
                content={this.renderDropdownContent()}
                style={{
                    zIndex: 200,
                    width: 300,
                    minHeight: 'inherit'
                }}
            />
        )

    }

    handleClickButton() {
        if (this.props.disabled) {
            return;
        }
        this.setState({showDropdown: !this.state.showDropdown});
    }
    render() {

        return (
            <div className="action-btn-wrapper" style={{...DEFAULT_STYLE, ...this.props.wrapperStyle}}>
                <Button
                    content={this.props.content}
                    onClick={() => this.handleClickButton()}
                    classes={[...DEFAULT_CLASSES, ...this.props.classes]}
                    style={this.props.style}
                />
                {this.state.showDropdown ? this.renderDropdown() : null}
            </div>

        );
    }
}

export default GenericActionsButton;
