import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import InteractionsTimeline from '../../components/Campaign/Interactions/Timeline';

import {fetchCampaignsIfNeeded, fetchActivationIfNeeded} from "../../actions/campaigns";
import {fetchActivationInteractions} from "../../actions/activations";
import {DEFAULT_CAMPAIGN_STATE} from "../../reducers/campaigns";
import * as ActivationSelectors from '../../selectors/activations';

const mapStateToProps = (state, props) => {

    const activation = ActivationSelectors.getActivation(state, props);
    const campaign = state.campaignsById[activation.campaign_id] || DEFAULT_CAMPAIGN_STATE;
    const interactions = ActivationSelectors.getActivationInteractions(state, props);

    return {activation, campaign, interactions};

};

const mapDispatchToProps = (dispatch, {activationId}) => {

    return {

        onMount: () => {
            return Promise.all([
                dispatch(fetchCampaignsIfNeeded()),
                dispatch(fetchActivationIfNeeded(activationId)),
                dispatch(fetchActivationInteractions(activationId))
            ])
        }


    }

};

const ConnectedInteractionsTimeline = connect(mapStateToProps, mapDispatchToProps)(InteractionsTimeline);

ConnectedInteractionsTimeline.propTypes = {
    activationId: PropTypes.number.isRequired,
    types: PropTypes.array,
    style: PropTypes.object,
};

export default ConnectedInteractionsTimeline;
