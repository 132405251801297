
export const socialStatsTypeToNiceName = (name) => {
    const lcName = name.toLowerCase();

    switch (lcName) {
        case 'facebook-reaction_count':
            return 'Facebook Reactions';
        case 'facebook-comment_count':
        case 'facebook-comment_plugin_count':
            return 'Facebook Comments';
        case 'facebook-share_count':
            return 'Facebook Shares';

        case 'instagram-likes':
            return 'Instagram Likes';
        case 'instagram-comments':
            return 'Instagram Comments';
        case 'instagram-video-views':
            return 'Instagram Video Views';

        case 'pinterest-saves':
            return 'Pinterest Saves';
        case 'pinterest-pins':
            return 'Pinterest Pins';
        case 'pinterest-repins':
            return 'Pinterest Repins';
        case 'pinterest-likes':
            return 'Pinterest Likes';
        case 'pinterest-comments':
            return 'Pinterest Comments';

        case 'twitter-tweets':
            return 'Twitter Tweets';
        case 'twitter-retweets':
            return 'Twitter Retweets';
        case 'twitter-favorites':
            return 'Twitter Favorites';

        case 'youtube-viewCount':
            return 'YouTube Views';
        case 'youtube-likeCount':
            return 'YouTube Likes';
        case 'youtube-dislikeCount':
            return 'YouTube Dislikes';
        case 'youtube-commentCount':
            return 'YouTube Comments';

        case 'instastory-reach':
            return 'Instastory Reach';
        case 'instastory-impressions':
            return 'Instastory Impressions';
        case 'instastory-replies':
            return 'Instastory Replies';
        case 'instastory-exits':
            return 'Instastory Exits';
        case 'instastory-taps_forward':
            return 'Instastory Taps Forward';
        case 'instastory-taps_back':
            return 'Instastory Taps Back';

        default: return name;
    }

};

export const socialTypeFromNiceName = (name) => {

    const lcName = name.toLowerCase();
    switch (lcName) {
        case 'instagram':
            return 'Tidal\\Social\\Instagram';
        case 'twitter':
            return 'Tidal\\Social\\Twitter';
        case 'facebook':
            return 'Tidal\\Social\\Facebook';
        case 'facebook-page':
            return 'Tidal\\Social\\FacebookPage';
        case 'pinterest':
            return 'Tidal\\Social\\Pinterest';
        case 'youtube':
            return 'Tidal\\Social\\Youtube';
        case 'tiktok':
            return 'Tidal\\Social\\TikTok';
        case 'instagram-business':
        case 'instastory':
            return 'Tidal\\Social\\InstagramBusiness';
        default :
            return 'Tidal\\Social';
    }

};

export const socialHandleToUrl = (type, handle) => {
    let prefix = '';

    switch (type) {
        case 'youtube':
        case 'Tidal\\Social\\Post\\Youtube':
        case 'Tidal\\Social\\Youtube':
            prefix = 'https://youtube.com/channel/';
            break;

        case 'pinterest':
        case 'Tidal\\Social\\Post\\Pinterest':
        case 'Tidal\\Social\\Pinterest':
            prefix = 'https://pinterest.com/';
            break;

        case 'facebook':
        case 'facebook-page':
        case 'Tidal\\Social\\Post\\Facebook':
        case 'Tidal\\Social\\Facebook':
        case 'Tidal\\Social\\FacebookPage':
            prefix = 'https://facebook.com/';
            break;

        case 'instagram':
        case 'Tidal\\Social\\Post\\Instagram':
        case 'Tidal\\Social\\Instagram':
            prefix = 'https://instagram.com/';
            break;

        case 'twitter':
        case 'Tidal\\Social\\Post\\Twitter':
        case 'Tidal\\Social\\Twitter':
            prefix = 'https://twitter.com/';
            break;

        case 'snapchat':
        case 'Tidal\\Social\\Snapchat':
            prefix = 'https://snapchat.com/';
            break;

        case 'tiktok':
        case 'Tidal\\Social\\TikTok':
            prefix = 'https://vm.tiktok.com/';
            break;
    }

    return prefix + handle;
};

export const socialSlugForObject = social => {
    const {name, slug, url, type} = social;
    let nice = slug;

    if (type === 'Tidal\\Social\\FacebookPage') {
        nice = (url || '').replace(/https?:\/\//g, '')
            .replace(/www\./g, '')
            .replace(/facebook\.com/g, '')
            .replace(/\//g, '');
    }

    if (type === 'Tidal\\Social\\Facebook') {
        nice = name;
    }

    return nice;

};

export const socialIconForType = type => {

    switch (type) {

        case 'youtube':
        case 'Tidal\\Social\\Post\\Youtube':
        case 'Tidal\\Social\\Youtube':
            return 'fab fa-youtube';

        case 'pinterest':
        case 'Tidal\\Social\\Post\\Pinterest':
        case 'Tidal\\Social\\Pinterest':
            return 'fab fa-pinterest';

        case 'facebook':
        case 'facebook-page':
        case 'Tidal\\Social\\Post\\Facebook':
        case 'Tidal\\Social\\Facebook':
        case 'Tidal\\Social\\FacebookPage':
            return 'fab fa-facebook';

        case 'instagram':
        case 'instastory':
        case 'Tidal\\Social\\Post\\Instagram':
        case 'Tidal\\Social\\Post\\Instastory':
        case 'Tidal\\Social\\Post\\InstagramBusiness':
        case 'Tidal\\Social\\Instagram':
        case 'Tidal\\Social\\InstagramBusiness':
            return 'fab fa-instagram';

        case 'twitter':
        case 'Tidal\\Social\\Post\\Twitter':
        case 'Tidal\\Social\\Twitter':
            return 'fab fa-twitter';

        case 'snapchat':
        case 'Tidal\\Social\\Snapchat':
            return 'fab fa-snapchat';

        case 'tiktok':
        case 'Tidal\\Social\\TikTok':
        case 'Tidal\\Social\\Post\\TikTok':
            return 'fab fa-tiktok';

        case 'draft':
            return 'fas fa-pencil';

        case 'video':
            return 'fas fa-video';

        default:
            return 'fas fa-rss';
    }

};

export const socialNameForType = type => {

    switch (type) {

        case 'Tidal\\Social\\Post\\Youtube':
        case 'Tidal\\Social\\Youtube':
        case 'youtube':
            return 'Youtube';

        case 'Tidal\\Social\\Post\\Pinterest':
        case 'Tidal\\Social\\Pinterest':
        case 'pinterest':
            return 'Pinterest';

        case 'Tidal\\Social\\Post\\Facebook':
        case 'Tidal\\Social\\Facebook':
        case 'facebook':
            return 'Facebook';

        case 'Tidal\\Social\\FacebookPage':
        case 'facebook-page':
            return 'Facebook Page';

        case 'Tidal\\Social\\Post\\Instagram':
        case 'Tidal\\Social\\Post\\Instastory':
        case 'Tidal\\Social\\Post\\InstagramBusiness':
        case 'Tidal\\Social\\Instagram':
        case 'instagram':
        case 'instastory':
            return 'Instagram';

        case 'Tidal\\Social\\InstagramBusiness':
        case 'instagram-business':
            return 'Instagram Business';

        case 'Tidal\\Social\\Post\\Twitter':
        case 'Tidal\\Social\\Twitter':
        case 'twitter':
            return 'Twitter';

        case 'tiktok':
        case 'Tidal\\Social\\TikTok':
        case 'Tidal\\Social\\Post\\TikTok':
            return 'TikTok';

        case 'snapchat':
        case 'Tidal\\Social\\Snapchat':
            return 'Snapchat';

        default:
            return 'Post';
    }
};
