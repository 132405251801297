import React, {Component} from 'react';
import PropTypes from 'prop-types';
import IFrame from '../Common/IFrame';
import moment from 'moment';
import EmailCompose from './Compose';
import MarkEmails from './MarkEmails';
import {preventDefaultIfPossible, stripSlashes, ucwords} from "../../utilities";
import Toolbox from "../Common/Toolbox";
import Tooltip from "../Common/Tooltip";
import DOMPurify from 'dompurify';

const renderAddress = address => {
    if (!address) {
        return <span>-</span>;
    }
    let label = address.email;
    if (address.name) {
        label = address.name;
    } else {
        label = address.email.substr(0, address.email.indexOf('@'));
        if (label.indexOf('+') > -1) {
            label = label.substr(0, label.indexOf('+'));
        }
    }

    return <span>{label}</span>
};

const buildReply = email => {

    const subject = 'Re: ' + email.subject;
    // Need to do some mapping because these are email_recipient objects and won't work if directly passed
    const to = ((email.inbox ? email.from : email.to) || [])
        .map(addr => {
            let obj = {
                id: addr.user_id,
                mongo_id: addr.user_mongo_id,
                name: addr.name,
                email: addr.email,
            };
            if (obj.id || obj.mongo_id) {
                obj.type = 'Tidal\\User';
            } else {
                obj.type = 'email';
            }
            return obj;
        });
    const fromUser = email.from[0];
    const senderName = (fromUser.name ? fromUser.name : '') + ' &lt;' + fromUser.email + '&gt;';
    const date = moment(email.created_at.date);
    const replyIntro = '<p>On ' + date.format("dddd, MMMM Do YYYY, h:mm:ss a") + ' ' + senderName + ' wrote:</p><hr />';
    const purifiedHtml = DOMPurify.sanitize(email.html);
    const body = "\n\n" + replyIntro + purifiedHtml;

    return {
        subject,
        to,
        body
    };

};

class EmailRead extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showReply: false,
            showLabelsToolbox: false,
            showCampaignsToolbox: false,
            isUpdatingLabel: false,
            isUpdatingCampaign: false,
            isLoading: false,
            email: null,
            didAutoMarkRead: false
        };

        this.handleToggleState = this.handleToggleState.bind(this);
        this.markReadTimeout = null;
    }

    getEmail() {
        if (this.state.email) {
            return this.state.email;
        }
        return this.props.email;
    }

    resetMarkReadTimeout() {
        if (this.markReadTimeout) {
            console.log("Unsetting mark-read timer");
            clearTimeout(this.markReadTimeout);
            this.markReadTimeout = null;
        }
    }

    triggerMarkRead() {

        const {autoMarkReadTimeout} = this.props;
        const email = this.getEmail();

        if (!autoMarkReadTimeout) {
            return;
        }

        if (email.is_read === false && !this.state.didAutoMarkRead) {
            console.log("Setting timer to mark message read");
            this.markReadTimeout = setTimeout(async () => {
                this.setState({isUpdatingLabel: true, didAutoMarkRead: true});
                console.log("Marking email read...");
                await this.props.createInteraction(email.id, 'MarkEmailRead', {read: true});
                console.log("Refreshing email...");
                await this.fetchEmail();
                this.setState({isUpdatingLabel: false, didAutoMarkRead: true});
            }, autoMarkReadTimeout);
        }

    }

    async componentDidMount() {
        await this.fetchEmail();
    }

    componentWillUnmount() {
        this.resetMarkReadTimeout();
    }

    async fetchEmail() {
        this.setState({isLoading: true});
        const emailResp = await this.props.fetchEmail(this.props.email.id);
        console.log("Email fetched", emailResp);
        this.setState({email: emailResp.data, isLoading: false})
        await this.triggerMarkRead();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.email.id !== this.props.email.id) {
            this.setState({email: null});
            this.fetchEmail();
            this.resetMarkReadTimeout();
            this.triggerMarkRead();
            // this.props.fetchThread(this.props.email.id);
        }
    }

    handleToggleState(key, e) {
        preventDefaultIfPossible(e);
        this.setState({[key]: !this.state[key]});
    }

    renderDecodedAttachments() {

        const {email} = this.props;
        if (email.isFetching) return null;
        if (!email.decoded_attachments || email.decoded_attachments.length === 0) return null;
        const allowedTypes = ['image\\/jpeg', 'image\\/png', 'image\\/gif', 'image\\/bmp', 'image\\/tiff', 'image\\/x-icon', 'image\\/svg+xml'];


        return (
            <div className="toolbox-section email-decoded-attachments">
                {email.decoded_attachments.map((a, i) => {
                    if (allowedTypes.indexOf(a.type) === -1) {
                        return null;
                    }

                    // a.content is base64 encoded from the email mime part
                    const srcString = `data:${a.type};base64,${a.content}`

                    return (
                        <div key={`attachment-${i}`} className="attachment email-attachment" style={{float: 'none'}}>
                            <img src={srcString} alt={a.filename} style={{maxWidth: 400}}/>
                        </div>
                    );
                })}
            </div>
        );

    }

    getEmailHtml() {
        const email = this.getEmail();
        if (email.isFetching) return null;

        if (email.decoded_html) {
            return email.decoded_html;
        }

        if (email.html) {
            return email.html;
        }

        // decode the email body
        const attachments = email.decoded_attachments || [];
        let output = '';

        const htmlAttachments = attachments.filter(a => a.type === 'text\\/html');
        const textAttachments = attachments.filter(a => a.type === 'text\\/plain');

        if (htmlAttachments.length > 0) {
            htmlAttachments.forEach(a => {
                output += stripSlashes(atob(a.content));
            })
        }

        if (htmlAttachments.length === 0 && textAttachments.length > 0) {
            textAttachments.forEach(a => {
                output += atob(a.content);
            })
        }

        return output;


    }

    renderBody() {

        const email = this.getEmail();
        if (email.isFetching) return null;
        const emailHtml = this.getEmailHtml();

        const content = emailHtml + '<style>body {padding: 0; margin: 0; font-family: "Museo Sans", Helvetica, Arial, sans-serif; font-size: 13px; line-height: 20px; color: #333; background: #FFF; }</style>';

        return (
            <div className="toolbox-section">
                <IFrame
                    content={content}
                    autoresize={true}
                />
            </div>
        );

    }

    renderDate() {
        const {email} = this.props;
        if (email.isFetching) return null;
        const d = moment(email.created_at.date);
        return (
            <Tooltip html={this.renderDetailsToolbox()}>
                <div className="date pull-left" ><i className="fa fa-clock-o" /> {d.fromNow()}</div>
            </Tooltip>
        );

    }

    renderCampaign() {
        const {campaignsById} = this.props;
        const email = this.getEmail();
        if (email.isFetching) return null;

        let label = <em>No Campaign</em>

        if (email.campaign_id) {
            const campaign = campaignsById.items[email.campaign_id];
            label = campaign ? campaign.name : 'Loading...';
        }

        const iconClass = email.isUpdatingCampaign ? 'fa fa-spinner fa-spin' : 'fa fa-star';

        return <div className="campaign pull-right">
            <a
                className="dark"
                onClick={this.handleToggleState.bind(this, 'showCampaignsToolbox')}
                href="#">{label} <i className={iconClass} /></a>
        </div>
    }

    renderFromTo() {
        const {email} = this.props;
        if (email.isFetching) return null;

        return (
            <Tooltip html={this.renderDetailsToolbox()}>
                <div className="from-to pull-left">
                    <i className="fa fa-info-circle" /> From {renderAddress(email.from[0])} to {renderAddress(email.to[0])}
                </div>
            </Tooltip>
        );
    }

    renderLabel() {
        const email = this.getEmail();
        if (email.isFetching) return null;
        const iconClass = email.isUpdatingLabel ? 'fa fa-spinner fa-spin' : 'fa fa-tag';
        return <div className="label-wrapper pull-right">
            <a href="#"
               className="dark"
               onClick={this.handleToggleState.bind(this, 'showLabelsToolbox')}
            >{ucwords(email.label)} <i className={iconClass} /></a>
        </div>
    }

    renderByline() {
        const email = this.getEmail();
        if (email.isFetching) return null;

        return (
            <div className="toolbox-section byline clearfix">
                <div className="clearfix">
                    {this.renderFromTo()}
                    {this.renderLabel()}
                </div>
                <div className="clearfix">
                    {this.renderDate()}
                    {this.renderCampaign()}
                </div>

                {this.renderLabelsToolbox()}
                {this.renderCampaignsToolbox()}
            </div>
        );
    }


    renderReplyTitle() {
        const email = this.getEmail();
        const {showReply} = this.state;

        if (email.isFetching) return null;

        const iconClass = showReply ? 'fa fa-angle-down' : 'fa fa-angle-right';


        return (
            <div className="toolbox-section">
                <h2><i className={iconClass} /> <a href="#" onClick={this.handleToggleState.bind(this, 'showReply')}>Reply</a></h2>
            </div>
        );
    }

    renderReply() {
        const email = this.getEmail();
        const {showReply} = this.state;

        if (email.isFetching) return null;
        if (!showReply) return null;

        const reply = buildReply(email);

        return (
            <div className="reply-wrapper">
                <EmailCompose
                    key={`reply-${email.id}`}
                    showTitle={false}
                    sendBtnText={'Reply Now'}
                    {...reply}
                    onSent={() => {

                        console.log("Email sent, updating labels...");
                        const currentLabel = email.label;
                        // if the label is blank, 'unread', 'read', or some others, mark as replied
                        const allowedMarkAsReply = ['unread', 'read', 'replied', 'unresolved', 'needs attention'];
                        if (currentLabel === null || allowedMarkAsReply.indexOf(currentLabel) > -1) {
                            console.log("Marking email as replied...");
                            this.handleMarkEmail('replied')
                                .then(() => {
                                    console.log("Email marked as replied, refreshing email...");
                                    this.fetchEmail();
                                });
                        }


                    }}
                />
            </div>
        )

    }

    renderLabelsToolbox() {
        const email = this.getEmail();
        const {showLabelsToolbox} = this.state;

        if (email.isFetching) return null;
        if (!showLabelsToolbox) return null;

        const title = this.state.isUpdatingLabel ? 'Updating Label...' : 'Update Label';
        return <Toolbox
            title={title}
            style={{left: 'inherit', right: 0, top: 130}}
            onClose={() => this.setState({showLabelsToolbox: false})}
            content={
                <MarkEmails
                    onClickLabel={this.handleMarkEmail.bind(this)}
                />
            }
        />;

    }

    renderCampaignsToolbox() {
        const {email, campaignsById} = this.props;
        const {showCampaignsToolbox} = this.state;

        if (email.isFetching) return null;
        if (!showCampaignsToolbox) return null;

        const title = this.state.isUpdatingCampaign ? 'Updating Campaign...' : 'Attach Campaign';
        return (
            <Toolbox
                title={title}
                onClose={() => this.setState({showCampaignsToolbox: false})}
                style={{right: 0, top: 130}}
                content={
                    <div className="list-select">
                        {Object.values(campaignsById.items)
                            .filter(c => !c.archived)
                            .filter(c => c.status !== 'building')
                            .filter(c => c.name !== 'New Blank Campaign')
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(c => {
                                return (
                                    <div
                                        key={`mark-email-campaign-item-${c.id}`}
                                        className="v3 h7 fake-li condensed email-mark-dropdown-label"
                                    >
                                        <a
                                            role="button"
                                            className="dark"
                                            onClick={this.handleUpdateEmailCampaign.bind(this, c.id)}
                                        >{c.name}</a>
                                    </div>
                                );
                            })
                        }
                    </div>
                }
            />
        );

    }

    handleUpdateEmailCampaign(campaignId, e) {
        preventDefaultIfPossible(e);
        this.setState({isUpdatingCampaign: true});
        return this.props.createInteraction(this.props.email.id, 'AttachCampaign', {campaign_id: campaignId})
            .then(() => {
                this.fetchEmail().then(() => {
                    this.setState({showCampaignsToolbox: false, isUpdatingCampaign: false});
                })
            });
    }

    handleMarkEmail(label) {
        const {email} = this.props;
        this.setState({isUpdatingLabel: true});
        return this.props.createInteraction(email.id, 'MarkEmail', {label: label})
            .then(() => {
                this.fetchEmail().then(() => {
                    this.setState({
                        showLabelsToolbox: false,
                        isUpdatingLabel: false,
                    });
                });
            });
    }

    renderDetailsToolbox() {
        const {email} = this.props;

        if (email.isFetching) return <em>Loading...</em>;

        const date = moment(email.created_at.date);
        const folder = email.inbox ? 'Inbox' : 'Sent';

        return (
            <div style={{width: 350, textAlign: 'left'}}>

                <div className="fake-li padded">
                    <strong>Date</strong>
                    <span className="pull-right">{date.format('MMM Do, YYYY h:mm a')}</span>
                </div>

                <div className="fake-li padded">
                    <strong>Folder</strong>
                    <span className="pull-right">{folder}</span>
                </div>

                {email.from.map(a => <div key={`from-${a.email}`} className="fake-li padded">
                    <strong>From</strong>
                    <span className="pull-right">{a.email}</span>
                </div> )}
                {email.to.map(a => <div key={`to-${a.email}`} className="fake-li padded">
                    <strong>To</strong>
                    <span className="pull-right">{a.email}</span>
                </div> )}
                {(email.cc || []).map(a => <div key={`cc-${a.email}`} className="fake-li padded">
                    <strong>CC</strong>
                    <span className="pull-right">{a.email}</span>
                </div> )}
                {(email.bcc || []).map(a => <div key={`bcc-${a.email}`} className="fake-li padded">
                    <strong>BCC</strong>
                    <span className="pull-right">{a.email}</span>
                </div> )}

                {!email.inbox && <div className="fake-li padded">
                    <strong>Email Bounced</strong>
                    <span className="pull-right">{email.was_bounced ? 'Yes' : 'No'}</span>
                </div>}
                {!email.inbox && <div className="fake-li padded">
                    <strong>Email Opened</strong>
                    <span className="pull-right">{email.was_opened ? 'Yes' : 'No'}</span>
                </div>}
                {!email.inbox && <div className="fake-li padded">
                    <strong>Link Clicked</strong>
                    <span className="pull-right">{email.was_clicked ? 'Yes' : 'No'}</span>
                </div>}


            </div>
        )
    }

    renderLightboxControls() {

        const {showLightboxControls, handleCloseLightbox, openNextEmail, openPrevEmail} = this.props;
        if (!showLightboxControls) return null;

        return (
            <div className="header-action-icons pull-right">
                {openPrevEmail && <a role="button" onClick={openPrevEmail}><i className="fas fa-angle-left" /></a>}
                {handleCloseLightbox && <a role="button" onClick={handleCloseLightbox}><i className="fas fa-times" /></a>}
                {openNextEmail && <a role="button" onClick={openNextEmail}><i className="fas fa-angle-right" /></a>}
            </div>
        )

    }

    renderHeader() {

        const {showLightboxControls, email} = this.props;
        const subj = email.isFetching ? 'Loading...' : email.subject;
        const h2Style = {};

        if (showLightboxControls) {
            h2Style.width = 'calc(100% - 100px)';
        }

        return (

            <div className="toolbox-section clearfix">
                <h2 className="overflow-ellipsis pull-left" style={h2Style}>
                    <i className="fa fa-envelope" /> {subj}
                </h2>
                {this.renderLightboxControls()}
            </div>

        )
    }

    render() {
        const {style} = this.props;

        return (
            <div className="toolbox toolbox-embedded email-read-toolbox" style={style}>

                {this.renderHeader()}
                {this.renderByline()}
                {this.renderBody()}
                {this.renderDecodedAttachments()}
                {this.renderReplyTitle()}
                {this.renderReply()}
            </div>
        );
    }
}

EmailRead.defaultProps = {
    style: {},
    showLightboxControls: false,
    autoMarkReadTimeout: 2000,
};

EmailRead.propTypes = {
    style: PropTypes.object,
    email: PropTypes.object,
    campaignsById: PropTypes.object,

    fetchEmail: PropTypes.func,
    fetchThread: PropTypes.func,
    createInteraction: PropTypes.func,

    showLightboxControls: PropTypes.bool,
    handleCloseLightbox: PropTypes.func,
    openNextEmail: PropTypes.func,
    openPrevEmail: PropTypes.func,
    autoMarkReadTimeout: PropTypes.number,

};

export default EmailRead;
