import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Form from '../Common/Form/Form';
import Alert from '../Common/Alert';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";
import _find from 'lodash/find';
import {STATS_FORM_FIELDS_FOR_POST_TYPES} from "../../utilities/campaign";
import {socialNameForType} from "../../utilities/social";

const RESET_FORM = {
    post_record_id: null,
    engagements: null,
    impressions: null,
    pageviews: null,
    'blog-comments': null,
    'facebook-reaction_count': null,
    'facebook-share_count': null,
    'facebook-comment_count': null,
    'instagram-likes': null,
    'instagram-comments': null,
    'instagram-saves': null,
    'instagram-impressions': null,
    'instagram-video-views': null,
    'pinterest-likes': null,
    'pinterest-comments': null,
    'twitter-retweets': null,
    'twitter-favorites': null,
    'youtube-likeCount': null,
    'youtube-viewCount': null,
    'youtube-dislikeCount': null,
    'youtube-commentCount': null,
    'tiktok-likes': null,
    'tiktok-shares': null,
    'tiktok-comments': null,
    'tiktok-views': null,
    'tiktok-clicks': null,
    'instastory-reach': null,
    'instastory-impressions': null,
    'instastory-exits': null,
    'instastory-replies': null,
    'instastory-shares': null,
    'instastory-taps_forward': null,
    'instastory-taps_back': null,
    'instastory-link_clicks': null,
    'instastory-sticker_taps': null,

};

class AddStatsToPostForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {...RESET_FORM},
            isSubmitting: false,
            didSubmit: false,
            error: null,
        };
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static defaultProps = {
        usePadding: false,
        style: {
            maxHeight: 600,
            overflow: 'auto'
        },
    };

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onSaved: PropTypes.func,
        usePadding: PropTypes.bool,
        style: PropTypes.object,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
    };

    componentDidMount() {
        this.props.fetchActivation(this.props.activation.id);
    }

    resetForm() {
        this.setState({
            form: {...RESET_FORM}
        });
    }

    getSelectedPostRecord() {
        if (!this.state.form.post_record_id) {
            return null;
        }
        return _find(this.props.activation.postRecords, {id: this.state.form.post_record_id});
    }

    handleSubmit() {
        const {activation} = this.props;
        this.setState({isSubmitting: true, error: null});
        return this.props.createInteraction(activation.id, 'AddStatsToPost', this.state.form)
            .then(resp => {

                if (resp.meta.error) {
                    this.setState({
                        isSubmitting: false,
                        didSubmit: false,
                        error: resp.meta.error,
                    });

                } else {
                    this.setState({
                        isSubmitting: false,
                        didSubmit: true,
                        error: null,
                    });
                    if (this.props.onSaved) {
                        this.props.onSaved(this.state.form);
                    }
                    this.props.fetchActivation(activation.id);
                    this.resetForm();
                }
            });
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    getSocialStatsFields() {

        const postRecord = this.getSelectedPostRecord();

        const fields = STATS_FORM_FIELDS_FOR_POST_TYPES.filter(item => {
            if (!postRecord) {
                return false;
            }
            const prType = (postRecord.metadata || {}).type || 'post';
            return item.forTypes.indexOf(prType) > -1;
        })
            .map(field => {
                // If the field's 'help' parameter is a function, evaluate the function with the user object
                if (typeof field.help === 'function') {
                    field.help = field.help((this.props.activation || {}).user);
                }
                return field;
            });

        return fields;
    }

    render() {
        const {isSubmitting, didSubmit} = this.state;
        const {activation} = this.props;
        return <Form
            style={this.props.style}
            usePadding={this.props.usePadding}
            values={this.state.form}
            onFieldChange={this.handleFieldChange}
            fields={[
                {
                    type: 'select',
                    name: 'post_record_id',
                    title: 'Select the Post to Update',
                    choices: (activation.post_records || activation.postRecords || [])
                        .map(pr => {
                            return {
                                value: pr.id,
                                text: socialNameForType(pr.post.type) + ': ' + pr.post.title
                            }
                        })
                },
                ...this.getSocialStatsFields(),
                {
                    type: 'number',
                    name: 'engagements',
                    title: 'Other Engagements',
                    classes: ['v3', 'light']
                },
                {
                    type: 'number',
                    name: 'impressions',
                    title: 'Other Impressions',
                    classes: ['v3', 'light']
                },
                this.state.error ? {
                    type: 'generic',
                    content: <Alert
                        classes={['danger']}
                        content={this.state.error}
                        onClose={() => this.setState({error: null})}
                    />

                } : null,
                {
                    type: 'button',
                    name: 'submit',
                    title: 'Save Stats',
                    options: {
                        classes: ['v3 btn btn-primary medium bold', (isSubmitting ? 'disabled' : '')],
                        hideTitle: true,
                        onClick: this.handleSubmit.bind(this),
                    }
                },
            ]}
        />

    }
}

const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id))
    };
};

export default connect(null, mapDispatchToProps)(AddStatsToPostForm);
