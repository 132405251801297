/**
 * Created by bkanber on 7/12/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

class StarRating extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hovering: null
        };
        this.handleRatingChange = this.handleRatingChange.bind(this);
    }

    handleRatingChange(rating) {

        if (typeof this.props.onRatingChange === 'function') {
            this.props.onRatingChange(rating);
        }

    }


    getIconClasses() {

        const filledCount = this.state.hovering ? this.state.hovering : this.props.rating;
        const prefixClass = this.state.hovering ? 'dark ' : '';

        let classes = [];

        for (let i = 1; i <= 5; i++) {

            if (i <= filledCount) {
                classes.push(prefixClass + 'star fa fa-star');
            } else {
                classes.push(prefixClass + 'star fa fa-star-o');
            }

        }

        return classes;
    }

    renderIcons() {
        const classes = this.getIconClasses();
        const isValid = typeof this.props.onRatingChange === 'function';

        return classes.map((className, index) => {
            const thisRating = index+1;
            return <i
                key={`starRating icon ${thisRating}`}
                onMouseOver={isValid ? () => this.setState({hovering: thisRating}) : null}
                onMouseOut={isValid ? () => this.setState({hovering: null}) : null}
                onClick={isValid ? this.handleRatingChange.bind(this, thisRating) : null}
                className={className}
            ></i>
        });
    }

    render() {
        return (
            <span className="star-rating" data-tooltip={this.props.tooltip} style={this.props.wrapperStyle}>
                {this.renderIcons()}
            </span>

        );
    }
}

StarRating.propTypes = {
    rating: PropTypes.number,
    onRatingChange: PropTypes.func,
    tooltip: PropTypes.string,
    wrapperStyle: PropTypes.object
};

export default StarRating;
