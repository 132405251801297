import React, {useEffect, useState} from 'react';
import Button from "../../Common/Form/Button";
import Toolbox from "../../Common/Toolbox";
import Form from "../../Common/Form/Form";
import Alert from "../../Common/Alert";
import SocialAuthConnector from "../EditProfile/Templates/SocialAuthConnector";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {requestLogin, requestSignup} from "../../../actions/auth";
import {fetchPresence} from "../../../actions/presence";
import {getCurrentUser} from "../../../selectors/presence";
import {createUserInteraction} from "../../../actions";

function SingleAttachSocialForm({socialType, title, placeholder, user, createUserInteraction, onDidAttach, buttonText="Add"}) {

    const [handle, setHandle] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleAddSocial = async () => {
        if (!handle) {
            return null;
        }
        if (isSubmitting) {
            return null;
        }
        if (!user || !user.id) {
            return null;
        }

        setIsSubmitting(true);
        const context = {
            type: socialType,
            handle: handle,
        };
        const resp = await createUserInteraction(user.id, 'SimpleAttachSocial', context);
        console.log("Interaction created", resp);
        setHandle('');
        setIsSubmitting(false);
        if (onDidAttach) {
            onDidAttach(resp);
        }

    }

    return (
        <Form
            onFieldChange={(name, value) => {
                setHandle(value);
            }}
            values={{handle}}
            fields={[
            {
                type: 'text',
                name: 'handle',
                title: title,
                placeholder: placeholder
            },
            {
                type: 'button',
                title: isSubmitting ? 'Adding...' : buttonText,
                options: {
                    classes: ['v3', 'btn-primary', 'small'],
                    hideTitle: true,
                    onClick: handleAddSocial
                }
            }

        ]} />
    );

}

function AttachSocials({user, createUserInteraction, campaignId}) {
    const translate = (key, fallback) => fallback; // todo
    const [isFetching, setIsFetching] = useState(false);
    const [socials, setSocials] = useState([]);

    if (!user || !user.id) {
        return "Waiting for user...";
    }

    const handleJoinCampaign = () => {
        window.location.href = `/contribute/do/page/campaign/go?campaignId=${campaignId}`;
    }

    const refreshSocials = () => {
        if (!user || !user.id) {
            return;
        }

        setIsFetching(true);

        createUserInteraction(user.id, 'PersonalProfileRequest', {})
            .then((resp) =>{
                console.log("Interaction created", resp);
                if (resp.data && resp.data.results.socials) {
                    setSocials(resp.data.results.socials);
                }

                setIsFetching(false);
            })

    }

    useEffect(() => {
        refreshSocials();
    }, [user]);

    const renderConnectorOrConnected = (socials, niceName, connector) => {
        if (socials && socials.length > 0) {
            const url = socials[0].url;
            return (
                <div className="connected-social-wrapper">
                    <i className="v3 icon success" style={{marginRight: 8}} />
                    You have added your {niceName}. {(url && <a className="bold" href={url} target="_blank" rel="noopener noreferrer">View Profile <i className="v3 icon external-link" /></a>)}
                </div>
            );
        }
        return connector;
    };

    const wdConnectSocials = translate('creator.brief.connect_socials.title', 'Add Your Socials To Participate');
    const wdConnectInstagram = translate('creator.brief.connect_socials.connect_instagram', 'Add your Instagram');
    const wdConnectTiktok = translate('creator.brief.connect_socials.connect_tiktok', 'Add your TikTok');
    const wdConnectYoutube = translate('creator.brief.connect_socials.connect_youtube', 'Add your YouTube');

    const connectorProps = {
        inline: true,
        user: user,
        buttonClass: 'v3 btn tidal-btn btn-secondary medium',
        buttonStyle: {width: 300},
        toolboxAddlClasses: 'toolbox-fixed toolbox-sm'
    };

    const igs = (socials || []).filter(s => s.type === 'Tidal\\Social\\InstagramBusiness');
    const igBasics = (socials || []).filter(s => s.type === 'Tidal\\Social\\Instagram');
    const tts = (socials || []).filter(s => s.type === 'Tidal\\Social\\TikTok');
    const yts = (socials || []).filter(s => s.type === 'Tidal\\Social\\Youtube');

    const connectedIgs = igs.filter(s => !s.broken);
    const connectedIgBasics = igBasics.filter(s => !s.broken);
    const allConnectedIgs = [...connectedIgs, ...connectedIgBasics];
    const connectedTts = tts.filter(s => !s.broken);
    const connectedYts = yts.filter(s => !s.broken);
    console.log("Connected accounts", connectedIgs, connectedIgBasics, connectedTts, connectedYts);
    const hasAnyIg = allConnectedIgs.length > 0;

    const igIconClass = hasAnyIg ? 'v3 icon success' : 'v3 icon error';
    const ttIconClass = connectedTts.length > 0 ? 'v3 icon success' : 'v3 icon error';
    const ytIconClass = connectedYts.length > 0 ? 'v3 icon success' : 'v3 icon error';

    const atLeastOneConnected = hasAnyIg || connectedTts.length > 0 || connectedYts.length > 0;
    const onDidAttach = () => {
        refreshSocials();
    };


    return (
        <div className="attach-socials-litebox">
            <Alert
                content={translate('creator.brief.connect_socials.description', 'To participate in this campaign, you must add your social accounts.')}
                classes={['info']}/>

            <div className="connector-or-connected-wrapper">
                <div className="how-we-use-socials">
                    <strong>How we use this data:</strong> we read your public data like your bio, follower count, and
                    recent posts. We do not modify or post content to your account, nor do we ask for your password.
                </div>
            </div>

            <div className="connector-or-connected-wrapper">

                {renderConnectorOrConnected(allConnectedIgs, 'Instagram', (
                    <SingleAttachSocialForm title="Instagram Username" placeholder="eg: @yourhandle"
                                            buttonText={wdConnectInstagram}
                                            socialType="instagram"
                                            user={user}
                                            createUserInteraction={createUserInteraction}
                                            onDidAttach={onDidAttach}
                    />
                ))}
            </div>

            <div className="connector-or-connected-wrapper">

                {renderConnectorOrConnected(connectedTts, 'TikTok', (
                    <SingleAttachSocialForm title="TikTok Username" placeholder="eg: @yourhandle"
                                            buttonText={wdConnectTiktok}
                                            socialType="tiktok"
                                            user={user}
                                            createUserInteraction={createUserInteraction}
                                            onDidAttach={onDidAttach}
                    />
                ))}
            </div>

            <div className="connector-or-connected-wrapper">

                {renderConnectorOrConnected(connectedYts, 'YouTube', (
                    <SingleAttachSocialForm title="YouTube Channel" placeholder="eg: https://youtube.com/@yourchannel"
                                            buttonText={wdConnectYoutube}
                                            socialType="youtube"
                                            user={user}
                                            createUserInteraction={createUserInteraction}
                                            onDidAttach={onDidAttach}
                    />
                ))}
            </div>

            {
                atLeastOneConnected && (
                    <div className="connector-or-connected-wrapper">
                        <a role="button" className="continue-to-campaign-btn" onClick={handleJoinCampaign}>Continue to
                            Campaign...</a>
                    </div>
                )
            }

        </div>
    );

}

function LoginForm({requestLogin, onLoggedIn}) {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [validityError, setValidityError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [form, setForm] = useState({
        email: '',
        password: '',
    });

    // Add validation with useEffect
    useEffect(() => {

        // reset errors
        setEmailError(null);
        setPasswordError(null);

        // basic validation
        let _isValid = form.email &&
            form.password;

        // check for valid email
        const isEmailValid = (form.email || '').match(/.+@.+\..+/);
        if (!isEmailValid) {
            _isValid = false;
            // only set error if email is not empty
            if (form.email) {
                setEmailError('Please enter a valid email address.');
            }
        }

        // check for valid password
        let isPasswordValid = (form.password || '').length >= 8;
        if (!isPasswordValid) {
            _isValid = false;
            // only set error if password is not empty
            if (form.password) {
                setPasswordError('Password must be at least 8 characters, with a number.');
            }
        }

        setIsValid( _isValid );

        if (_isValid) {
            setValidityError(null);
        }

    }, [form]);

    const handleSubmit = async () => {

        const resp = await requestLogin(form.email, form.password, 'lite');
        console.log(resp);

        if (resp.meta && resp.meta.error) {
            setValidityError(resp.meta.error);
        } else {
            if (onLoggedIn) {
                onLoggedIn(resp);
            }
        }

    }

    return (
        <Form
            values={form}
            onFieldChange={(name, value) => {

                setForm(prevForm => {
                    return {
                        ...prevForm,
                        [name]: value
                    }
                });

            }}
            fields={[
                validityError ? {
                    type: 'generic',
                    content: (
                        <Alert content={validityError} classes={['danger']} />
                    )
                } : null,
                {
                    type: 'text',
                    name: 'email',
                    title: 'Email Address',
                    placeholder: 'eg: you@your.com',
                    required: true,
                    help: emailError ? emailError : undefined,
                },
                {
                    type: 'password',
                    name: 'password',
                    title: 'Password',
                    required: true,
                    placeholder: passwordError ? passwordError : undefined,
                    help: <div>
                        Forgot your password? <a className="password-reset-link" href="/contribute/user/resetpassword">Reset it here</a>.
                    </div>
                },
                {
                    type: 'button',
                    name: 'submit',
                    title: (() => {
                        if (!isValid) {
                            return 'Please Complete All Fields';
                        }
                        if (isSubmitting) {
                            return 'Logging In...';
                        }
                        return 'Log In';
                    })(),
                    options: {
                        classes: [
                            'v3',
                            'fullwidth',
                            ((isSubmitting || !isValid) ? 'btn-secondary disabled' : 'btn-primary')
                        ],
                        hideTitle: true,
                        onClick: handleSubmit,
                    }
                }
            ]}
        />
    );


}

function SignupForm({requestSignup, onDidSignup, onClickLogin}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [validityError, setValidityError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [supportEmail, setSupportEmail] = useState(false);
    const [form, setForm] = useState({
        email: '',
        password: '',
        name: '',
        terms: false,
    });

    const doValidation = (_form) => {

        // basic validation
        let _isValid = _form.name &&
            _form.email &&
            _form.password &&
            _form.terms;


        // check for valid email
        const isEmailValid = (_form.email || '').match(/.+@.+\..+/);
        if (!isEmailValid) {
            _isValid = false;
            // only set error if email is not empty
            if (_form.email) {
                setEmailError('Please enter a valid email address.');
            }
        }

        // check for valid password
        let isPasswordValid = (_form.password || '').length >= 8;
        if (!isPasswordValid) {
            _isValid = false;
            // only set error if password is not empty
            if (_form.password) {
                setPasswordError('Password must be at least 8 characters.');
            }
        }

        return _isValid;
    };

    const handleSubmit = async () => {
        const data = { ...form, 'password-confirm': form.password };
        setIsSubmitting(true);
        const responseData = await requestSignup(data, 'lite');
        setIsSubmitting(false);

        if (responseData.meta && responseData.meta.error) {
            setValidityError(responseData.meta.error|| 'An error occurred');
            return;
        }


        if (responseData.data && responseData.data.token) {
            if (onDidSignup) {
                onDidSignup(responseData);
            }
        } else {
            setValidityError(responseData.message || 'An error occurred');
        }

    };

    return (
        <Form
            values={form}
            onFieldChange={(name, value) => {

                setForm(prevForm => {

                    let newForm = {
                        ...prevForm,
                        [name]: value
                   };

                    console.log("New form", newForm);

                    setValidityError(null);
                    setEmailError(null);
                    setPasswordError(null);

                    let _isValid = doValidation(newForm);
                    setIsValid(_isValid);

                    return newForm;

                });

            }}
            fields={[
                validityError ? {
                    type: 'generic',
                    content: (
                        <Alert content={validityError} classes={['danger']} />
                    )
                } : null,
                {
                    type: 'text',
                    name: 'name',
                    title: 'Your Name',
                    placeholder: 'Your Name',
                    required: true,
                },
                {
                    type: 'text',
                    name: 'email',
                    title: 'Email Address',
                    placeholder: 'eg: you@youremail.com',
                    help: emailError ? emailError : undefined,
                    required: true,
                },
                {
                    type: 'password',
                    name: 'password',
                    title: 'Create a Password',
                    help: passwordError ? passwordError : undefined,
                    placeholder: "8 characters minimum, with a number",
                    required: true,
                },
                {
                    type: 'checkbox',
                    name: 'terms',
                    title: <span className="terms-line">I accept the <a target="_blank" href="https://tid.al/l/terms">User Agreement</a> and <a href="https://tid.al/l/influenceragreement" target="_blank">Influencer Agreement</a></span>,
                    required: true,
                    options: {
                        hideTitle: true,
                    }
                },
                {
                    type: 'button',
                    name: 'submit',
                    title: (() => {
                        if (!isValid) {
                            return 'Please Complete All Fields';
                        }
                        if (isSubmitting) {
                            return 'Creating Your Account...';
                        }
                        return 'Create Your Account';
                    })(),
                    help: supportEmail
                        ? <span><strong>Need help? Have questions?</strong> <a href={`mailto:${supportEmail}`}>Email us</a> or reply to your invitation email.</span>
                        : undefined,
                    options: {
                        classes: [
                            'v3',
                            'fullwidth',
                            ((isSubmitting || !isValid) ? 'btn-secondary disabled' : 'btn-primary')
                        ],
                        hideTitle: true,
                        onClick: handleSubmit,
                    }
                },
                {
                    type: 'generic',
                    name: 'login',
                    content: <span className="loginlink">Already have an account? <a href="#" onClick={onClickLogin}>Log in instead.</a></span>,
                    options: {
                        hideTitle: true,
                    }
                },

            ]}

        />
    )
}


function PublicCampaignJoiner({campaignId, requestLogin, requestSignup, user, fetchPresence, createUserInteraction}) {

    const [showSignup, setShowSignup] = useState(false);
    const [showSocials, setShowSocials] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        fetchPresence();
    }, []);

    // If user is logged in when they click login, show socials
    useEffect(() => {
        if (showSignup && user && user.id) {
            setShowSocials(true);
            setShowLogin(false);
            setShowSignup(false);
        }
    }, [showSignup]);

    if (showLogin) {
        return <Toolbox
            addlClasses={"join-campaign-toolbox toolbox-fixed toolbox-sm"}
            title={"Login"}
            onClose={() => setShowLogin(false)}
            content={
                <LoginForm
                    requestLogin={requestLogin}
                    onLoggedIn={async (data) => {
                        console.log("Logging in -- current user?", user);
                        console.log("Logged in -- user now", data, user);
                        // fetch presence
                        const presence = await fetchPresence();
                        console.log("Presence", presence);

                        setShowLogin(false);
                        setShowSocials(true);

                    }}
                />
            }
        />;
    }

    if (showSignup) {
        return <Toolbox
            addlClasses={"join-campaign-toolbox toolbox-fixed toolbox-sm"}
            title={"Join Campaign"}
            onClose={() => setShowSignup(false)}
            content={
                <SignupForm
                    requestSignup={requestSignup}
                    onClickLogin={() => {
                        setShowSignup(false);
                        setShowSocials(false);
                        setShowLogin(true);
                    }}
                    onDidSignup={async (data) => {
                        console.log("Signed up -- current user?", user);
                        // fetch presence
                        const presence = await fetchPresence();
                        console.log("Presence", presence);

                        setShowSignup(false);
                        setShowLogin(false);
                        setShowSocials(true);
                    }}
                />
            }
        />;
    }

    if (showSocials) {
        return <Toolbox
            addlClasses={"join-campaign-toolbox toolbox-fixed toolbox-sm"}
            title={"Add Social Accounts"}
            onClose={() => setShowSocials(false)}
            content={<AttachSocials
                user={user}
                createUserInteraction={createUserInteraction}
                campaignId={campaignId}
            />}
        />;
    }

    return (
        <Button
            content={"Apply to Campaign"}
            classes={["v3", "btn-primary", "medium"]}
            onClick={() => {
                setShowSignup(true);
            }}
                className={"join-campaign-button"} style={{width: "100%"}}
        />
    )

}

const mapStateToProps = (state) => {
    return {
        user: getCurrentUser(state),
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    requestLogin: requestLogin,
    requestSignup: requestSignup,
    fetchPresence: fetchPresence,
    createUserInteraction: createUserInteraction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PublicCampaignJoiner);