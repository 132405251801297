import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../Common/Checkbox';
import BasicCell from './Basic';

class CheckboxCell extends BasicCell {

    getValue() {
        const {value, row, icon, label, wrapperStyle} = this.props;
        let isChecked = false;

        if (typeof value === 'function') {
            isChecked = value(row);
        }

        if (typeof value === 'boolean') {
            isChecked = value;
        }

        return <Checkbox checked={isChecked} onClick={() => {}} icon={icon} label={label} wrapperStyle={wrapperStyle} />

    }

}

CheckboxCell.propTypes = {
    value: PropTypes.any,
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    icon: PropTypes.node,
    label: PropTypes.string,
    wrapperStyle: PropTypes.object,
};

export default CheckboxCell;