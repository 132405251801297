/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import GenericDonutChart from '../../Chart/GenericDonutChart';
import {formatNumber, formatNumberK} from '../../../utilities';
import d3 from 'd3';
import RechartsDonutChart from "../../Chart/RechartsDonutChart";
import ChartWrapper from "../../Chart/ChartWrapper";

export default class CampaignParticipantTierDonutChart extends Component {

    getTotal() {
        return this.props.users.length || null;
    }

    getData() {
        if (!this.props.users || this.props.users.length === 0) return null;

        let data = {};

        for (let user of this.props.users) {

            let tier = user.basics.tier.replace(/\s.*/, '');
            if (!tier) {
                continue;
            }

            if (typeof data[tier] === 'undefined') {
                data[tier] = 0;
            }

            data[tier]++;

        }

        let chartData = [];

        for (const tier in data) {
            chartData.push({
                name: tier,
                value: data[tier]
            })
        }

        return chartData;

    }

    renderChart() {
        const data = this.getData();
        if (!data) return null;

        return (
            <RechartsDonutChart
                data={data}
                title="Participant Tier"
            />
        )

    }

    render() {
        const total = this.getTotal();
        const titleBadge = total !== null ? `${formatNumber(total)} creators` : null;

        return (
            <ChartWrapper
                title="Participant Tier"
                titleBadge={titleBadge}
                icon="pie-chart"
                chart={this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
            />
        );
    }
}
