import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InteractionTimelineMessages from './TimelineMessages';
import TimelineWrapper from '../../Common/TimelineView/Wrapper';
import Alert from '../../Common/Alert';

export default class InteractionsTimeline extends Component {

    static defaultProps = {
        blacklist: [
            'Tidal\\Campaign\\Activation\\Interaction\\ViewActivationPage'
        ]
    };

    static propTypes = {
        interactions: PropTypes.array,
        activation: PropTypes.object,
        campaign: PropTypes.object,
        onMount: PropTypes.func,
        /** Filters for which types to show */
        types: PropTypes.array,
        style: PropTypes.object,
        blacklist: PropTypes.array,
    };

    state = {
        isFetching: false,
        didFetch: false,
    };

    refreshData() {
        if (this.props.onMount) {
            this.setState({isFetching: true});
            this.props.onMount()
                .then(() => this.setState({isFetching: false, didFetch: true}));
        }

    }
    componentDidMount() {
        this.refreshData();
    }

    getInteractions() {
        const {types, blacklist, interactions} = this.props;


        return interactions
            .filter(interaction => {
                return blacklist.indexOf(interaction.type) === -1;
            })
            .filter(interaction => {
                if (!types || types.length === 0) {
                    return true;
                }

                return types.indexOf(interaction.type) > -1;
            });

    }

    render() {
        const {isFetching} = this.state;
        return (
            <TimelineWrapper style={this.props.style}>

                {isFetching && <Alert classes={['info']} content={'Loading history...'} />}

                <InteractionTimelineMessages
                    activation={this.props.activation}
                    interactions={this.getInteractions()}
                    onRefresh={this.refreshData.bind(this)}
                />

            </TimelineWrapper>

        );
    }
}