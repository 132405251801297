import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";
import TinyThermometer from "../../../Common/TinyThermometer";
import {getCampaign} from "../../../../selectors/campaigns";
import {createCampaignInteraction} from "../../../../actions";
import {connect} from 'react-redux';
import Button from "../../../Common/Form/Button";

const formattingHelpUrl = 'https://support.tid.al/campaign-email-template-tags';
const makeHelpWithFormattingArticle = (text) => {
    const helpLink = <a target="_blank" href={formattingHelpUrl} rel="noopener noreferrer">Get formatting help.</a>;
    return (
        <div>{text} {helpLink}</div>
    );
};

const SPECS = [
    {
        key: 'description',
        title: 'Campaign Description',
        description: 'The campaign description should be short, pithy, and very briefly explain what this campaign means to influencers. It may be injected into the Invitation email, and it will be displayed to influencers in the influencer experience. Advanced formatting is not available in this field.',
        isVisible: () => true,
        fields: [
            {
                type: 'textarea',
                name: 'description',
                title: 'Campaign Description',
                help: 'A 1-3 sentence description of the campaign. Will be visible to influencers.',
                placeholder: 'eg: Join us in our summer fashion campaign! You\'ll get to sample our newest line and get paid to post your honest review to your blog and Instagram.'
            },
        ]
    },
    {
        key: 'content_guidelines',
        title: 'Content Guidelines',
        description: 'Content guidelines are one of the first things influencers see. Use this template to describe exactly what you\'re looking for in submitted posts. Talk about style, tone, and any necessary compliance guidelines.',
        isVisible: () => true,
        fields: [
            {
                type: 'wysiwyg',
                name: 'content_guidelines',
                title: 'Content Guidelines',
                help: makeHelpWithFormattingArticle("Describe the content you would like generated. Make sure to include anything that is required of influencers, any example posts, and any other instructions related to writing or posting content."),
            },
        ]
    },
    {
        key: 'invitation',
        title: 'Invitation Email',
        description: <span>This email is the first communication participants get from you, so make it good! Explain what the campaign is, what they'll get, and what they'll be asked to do. You can use <a href={formattingHelpUrl} className="v3 bold" target="_blank" rel="noopener">template tags</a> to automatically inject specific requirements and payment offers.</span>,
        isVisible: () => true,
        testEmailTemplate: 'Invitation',
        fields: [
            {
                type: 'text',
                name: 'invitation_subject',
                title: 'Invitation Subject',
            },
            {
                type: 'wysiwyg',
                name: 'invitation_copy',
                title: 'Invitation Text',
                help: makeHelpWithFormattingArticle("This text will be included in invitation emails and on the campaign offer page.")
            },
        ]
    },
    {
        key: 'invitation-reminder',
        title: 'Invitation Reminder',
        description: 'Participants will receive this email if they don\'t respond to your invitation after a couple of days. You should remind them about the campaign and what it involves, and point out any deadlines as well.',
        isVisible: () => true,
        testEmailTemplate: 'InvitationReminder',
        fields: [
             {
                type: 'text',
                name: 'invitation_reminder_subject',
                title: 'Invitation Reminder Subject',
                help: makeHelpWithFormattingArticle('The subject to use when sending a reminder email.')
            },
             {
                type: 'wysiwyg',
                name: 'invitation_reminder_copy',
                title: 'Invitation Reminder Text',
                help: makeHelpWithFormattingArticle("This text will be included in invitation reminder emails.")
            },
        ]
    },

    {
        key: 'productshippedemail',
        title: 'Product Shipment Email',
        description: "This email is sent to the participant when their selected product samples have been shipped. Let them know what to expect from their shipment and your preferred method to handle any shipping issues.",
        isVisible: (settings) => !!settings.use_products,
        testEmailTemplate: 'ProductShipped',
        fields: [
            {
                type: 'text',
                name: 'product_shipped_subject',
                title: 'Product Shipment Email Subject',
            },
            {
                type: 'wysiwyg',
                name: 'product_shipped_copy',
                title: 'Product Shipment Email Copy',
                help: "This text will be included in product shipment notification emails."
            },
        ]
    },

    {
        key: 'waitlistemail',
        title: 'Waitlist Email',
        description: "Participants will be automatically waitlisted after they accept your invitation. This allows you to double-review participants before allowing them in the campaign. Use this email to describe your waitlist process and any criteria you look at before allowing participants to continue.",
        isVisible: (settings) => !!settings.waitlist_by_default,
        testEmailTemplate: 'Waitlisted',
        fields: [
            {
                type: 'text',
                name: 'waitlist_subject',
                title: 'Waitlist Email Subject',
                help: makeHelpWithFormattingArticle('This email will be sent when an influencer is waitlisted.')
            },
            {
                type: 'wysiwyg',
                name: 'waitlist_copy',
                title: 'Waitlist Copy',
                help: makeHelpWithFormattingArticle("This text will be included in the waitlist notification email.")
            },
        ]
    },
    {
        key: 'waitlist-accept-email',
        title: 'Accepted After Waitlist Email',
        description: "This email is sent after a user is waitlisted and then approved for participation by you. Use this email to remind them of the campaign and any specific things they need to do while participating.",
        isVisible: (settings) => !!settings.waitlist_by_default,
        testEmailTemplate: 'Accepted',
        fields: [
            {
                type: 'text',
                name: 'accepted_subject',
                title: 'Accepted-After-Waitlist Email Subject',
                help: makeHelpWithFormattingArticle('This email will be sent when an influencer is accepted after being waitlisted.')
            },
            {
                type: 'wysiwyg',
                name: 'accepted_copy',
                title: 'Accepted-After-Waitlist Email Copy',
                help: makeHelpWithFormattingArticle("This text will be included in the acceptance notification email.")
            },
        ]
    },

];

class CampaignCommunicationWizard extends Component {
    static propTypes = {
        campaign: PropTypes.object,
        settings: PropTypes.object,
        onFieldChange: PropTypes.func,
        onSave: PropTypes.func,
        onFinished: PropTypes.func,
        sendTestEmail: PropTypes.func,
    };

    state = {
        view: 'description',
        isSavingSettings: false,
        isSendingTest: false,
        didSendTest: false,
    };

    getVisibleSpecs() {
        return SPECS.filter(spec => spec.isVisible(this.props.settings));
    }

    async handleSave() {
        let didSave = true;
        this.setState({isSavingSettings: true});
        if (this.props.onSave) {
            didSave = await this.props.onSave();
        }
        this.setState({isSavingSettings: false});
        return didSave;
    }

    async handleChangeView(view) {
        const didSave = await this.handleSave();
        if (didSave === true) {
            this.setState({view: view, didSendTest: false, isSendingTest: false});
        }
    }

    async handleFinish() {
        await this.handleSave();
        if (this.props.onFinished) {
            this.props.onFinished();
        }
    }

    getNextSpec() {
        const {view} = this.state;
        const specs = this.getVisibleSpecs();
        const currentIndex = _findIndex(specs, {'key': view});
        const nextIndex = parseInt(currentIndex, 10) + 1;

        if (typeof specs[nextIndex] !== 'undefined') {
            return specs[nextIndex];
        } else {
            return null;
        }

    }
    goToNextSpec() {
        const nextSpec = this.getNextSpec();

        if (nextSpec) {
            this.handleChangeView(nextSpec.key);
        } else {
            this.handleFinish();
        }
    }

    makeSaveAndContinueButton() {
        const {isSavingSettings} = this.state;
        const icon = isSavingSettings ? <i className="v3 icon spinner" style={{marginRight: 8}} /> : null;
        const titleText = !!this.getNextSpec() ? 'Save and Continue' : 'Save and Continue to Add Users';

        return (
            <Button
                content={<span>{icon}{titleText}</span>}
                classes={['v3', 'btn-primary', 'medium', 'bold', (isSavingSettings ? 'disabled' : '')]}
                onClick={ isSavingSettings ? undefined : () => {
                    this.goToNextSpec();
                }}
            />
        )
    }

    makeTestEmailButton(template) {
        const {isSendingTest, didSendTest} = this.state;
        const icon = isSendingTest ? <i className="v3 icon spinner" style={{marginRight: 8}} /> : null;
        let titleText = 'Send Test Email';
        if (isSendingTest) {
            titleText = 'Sending You a Copy...'
        }
        if (didSendTest) {
            titleText = 'Check Your Email!'
        }

        return <Button
            content={ <span>{icon}{titleText}</span>}
            classes={ ['v3', 'btn-secondary', 'medium', 'bold', ((isSendingTest || didSendTest) ? 'disabled' : '')]}
            onClick={ (isSendingTest || didSendTest) ? undefined : async () => {
                    this.setState({isSendingTest: true, didSendTest: false});
                    await this.props.sendTestEmail(template);
                    this.setState({isSendingTest: false, didSendTest: true});
                }
            }
        />
    }

    renderCurrentView() {
        const spec = _find(SPECS, {key: this.state.view});
        let buttons = [this.makeSaveAndContinueButton()];
        if (spec.testEmailTemplate) {
            buttons.push(this.makeTestEmailButton(spec.testEmailTemplate));
        }
        let fields = [
            ...spec.fields,
            {
                type: 'generic',
                content: buttons
            }
        ];
        return (
            <>
                {this.renderProgress()}
                <Alert
                    classes={['info']}
                    content={<div><strong>{spec.title}:</strong> {spec.description}</div>}
                />
                <Form
                    fields={fields}
                    values={this.props.settings}
                    onFieldChange={this.props.onFieldChange}
                />
            </>
        );
    }

    renderProgress() {
        const specs = this.getVisibleSpecs();
        const currentIndex = parseInt(_findIndex(specs, {key: this.state.view}), 10);
        const pct = 100 * (currentIndex / specs.length);
        const label = `Progress: ${currentIndex} of ${specs.length} templates complete.`;
        return (
            <div
                style={{padding: '15px 15px 10px 15px', background: '#fafafa', 'border-bottom': '1px solid #f1f1f1'}}
            >
                <TinyThermometer
                    style={{height: 20}}
                    pct={pct}
                    label={label}
                    addlClasses={['green']}
                />
            </div>
        );
    }
    render() {
        return (
            <div>
                {this.renderCurrentView()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch, props) => {
    const campaignId = (props.campaign || {}).id;
    return {
        sendTestEmail: (template) => dispatch(createCampaignInteraction(campaignId, 'TestEmailTemplate', {template}))
    };

};
export default connect(undefined, mapDispatchToProps)(CampaignCommunicationWizard);
