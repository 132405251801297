import {
    OPEN_LIGHTBOX, CLOSE_LIGHTBOX, SET_LIGHTBOX_STACK
} from '../actions/lightbox';

export function lightbox(
    state = {
        visible: false,
        view: null,
        context: null,
        stack: []
    },
    action
) {

    switch (action.type) {

        case SET_LIGHTBOX_STACK:
            return {
                ...state,
                stack: action.stack
            };

        case OPEN_LIGHTBOX:
            return {
                ...state,
                visible: true,
                view: action.view,
                context: action.context
            };

        case CLOSE_LIGHTBOX:
            return {
                ...state,
                visible: false,
                stack: (action.resetStack ? [] : state.stack)
            };

        default: return state;
    }

}