
export const getCatalogs = (state, props = {}) => Object.values(state.products.catalogs);

export const getCatalog = (state, props = {}) => {

    let catalogId;
    if (typeof props.catalog !== 'undefined') {
        catalogId = props.catalog.id;
    } else if (typeof props.catalogId !== 'undefined') {
        catalogId = props.catalogId;
    }
    if (!catalogId) {
        return {};
    }
    return state.products.catalogs[catalogId] || {id: catalogId};
};

export const getCatalogProducts = (state, props = {}) => {
    const catalog = getCatalog(state, props);
    return Object.values(state.products.items)
        .filter(product => parseInt(product.product_catalog_id, 10) === parseInt(catalog.id, 10));
};