/**
 * Created by admin on 8/29/18.
 */

import {DEFAULT_USER_STATE} from '../reducers/users';


/**
 * Expects userId in props
 * @param state
 * @param props
 * @returns {*}
 */
export const getUser = (state, props = {}) => {
   const userId = props.userId;

   const user = state.usersById[userId] || DEFAULT_USER_STATE;

   return user;

};

export const getUserSocials = (state, props) => {
   const userId = props.userId || (props.user || {}).id;
   const stateUser = state.usersById[userId] || {};
   const socialIds = stateUser.socialIds || [];
   const socials = socialIds.map(id => state.socialsById[id] || null).filter(s => !!s);
   return socials;
};
