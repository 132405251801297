import _get from 'lodash/get';
import * as ActionTypes from './types';
import {createChannelInteraction} from "./channel";
import {fetchAuthenticated} from "./auth";
import {makeFormData} from "../utilities";
import * as URI from 'urijs';

export function fetchI18n() {
    return function (dispatch) {
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/channel/_/i18n`;

        dispatch({type: ActionTypes.I18N_REQUEST});
        return dispatch(fetchAuthenticated(url))
            .then(resp => resp.json())
            .then(json => {
                return dispatch({type: ActionTypes.I18N_RECEIVE, payload: json.data});
            });

    }
}
export function updateLanguage(lang = 'en', withReload = false, withQuery = false) {
    return function (dispatch, getState) {
        window.localStorage.setItem('tidal-lang', lang);
        const slot = window.Gator.getDashboardSlot();
        dispatch({type: ActionTypes.I18N_UPDATE_LANGUAGE, lang});
        const promise = dispatch(fetchAuthenticated(`/${slot}/api/channel/_/lang/${lang}`, {method: 'POST', body: makeFormData({_method: 'PUT'})}));

        if (withQuery) {
            let uri = URI(window.location.href);
            uri.setSearch('lang', lang);
            promise.then(() => window.location.href = uri.toString());
        } else if (withReload) {
            promise.then(() => window.location.reload());
        }
    }
}

function ensureMissed() {
    return function (dispatch, getState) {
        const missing = getState().i18n.missed;
        console.log(missing);
        dispatch(createChannelInteraction('EnsureTranslations', {translations: Object.values(missing)}))
            .then(() => dispatch(fetchI18n()));
        return dispatch({ type: ActionTypes.I18N_ENSURE_MISSED });
    };
};

function missedTranslation(key, defaultValue, lang, teamId) {
    return function (dispatch, getState) {
        const state = getState();
        const existingTimerId = state.i18n.timerId;
        const fullKey = `${teamId}.${lang}.${key}`;
        const alreadyMissed = state.i18n.missed;

        if (state.i18n.isFetching || !state.i18n.lastUpdated) {
            return;
        }

        if (typeof alreadyMissed[fullKey] !== 'undefined') {
            return;
        }

        if (existingTimerId) {
            clearTimeout(existingTimerId);
        }

        const timerId = setTimeout(function () {
            dispatch(ensureMissed());
        }, 5000);

        return dispatch({
            type: ActionTypes.I18N_MISSED_TRANSLATION,
            payload: { key, value: defaultValue, lang, team_id: (teamId === 'default' ? null : teamId) },
            fullKey: fullKey,
            timerId: timerId
        });
    }
}
export function translate(key, defaultValue = '') {

    return function (dispatch, getState) {

        const state = getState();
        const isI18nEnabled = _get(state, 'presence.channel.enable_i18n', false);
        const lang = _get(state, 'i18n.lang', 'en');
        const teamId = _get(state, 'i18n.teamId', 'default');
        const translations = _get(state, 'i18n.translations', {});

        if (!isI18nEnabled) {
            return defaultValue;
        }

        if (teamId !== 'default') {
            const teamValue = _get(translations, `${teamId}.${lang}.${key}`, null);
            if (teamValue) {
                return teamValue;
            }

            // Don't log missed translations for team-specific translations
            // Do this to ensure that we don't make the i18n payload too big,
            // but more importantly because we want team-specific default language to
            // override the custom language
            // dispatch(missedTranslation(key, defaultValue, lang, teamId));
        }

        const channelValue = _get(translations, `default.${lang}.${key}`, null);
        if (channelValue) {
            return channelValue;
        }

        dispatch(missedTranslation(key, defaultValue, lang, 'default'));
        return defaultValue;

    }

}