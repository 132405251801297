/**
 * Created by visgotti on 12/3/17.
 */

import React, {Component} from 'react';
import {formatNumber, formatNumberK, capitalizeFirstLetter, limitChars} from '../../../utilities';
import { POST_TABLE_TABS } from './Table';
import PostRowExtendedView from './ExpandedView';
import PostActions from '../Actions';

import StarRating from '../../Common/StarRating';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

const smartFormatNumberK = val => {
    if (val && val !== '...' && val !== '-' && typeof val !== 'undefined') {
        return formatNumberK(val);
    }
    if (val === null || typeof val === 'undefined') return '-';
    return val;
};

const smartFormatNumber = val => {
    if (val && val !== '...' && val !== '-' && typeof val !== 'undefined') {
        return formatNumber(val);
    }
    if (val === null || typeof val === 'undefined') return '-';
    return val;
};


class PostTableRow extends Component {

    constructor(props) {
        super(props);
        this.handleToggleExpanded = this.handleToggleExpanded.bind(this);
        this.handleExpandAll = this.handleExpandAll.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleLoadPost = this.handleLoadPost.bind(this);
        this.handleRatingChange = this.handleRatingChange.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);

        this.state = {
            showPostActions: false,
            isExpanded: false,
            isFeaturing: false,
            isVisible: false,
            wasEverVisible: false
        };
    }

    handleVisibilityChange(isVisible) {

        if (isVisible === true) {
            this.setState({isVisible, wasEverVisible: true});
        } else {
            this.setState({isVisible});
        }
    }

    componentWillMount() {
        this.handleLoadPost();
    }

    handleRatingChange(rating) {
        const postId = this.props.postId;
        this.props.updatePostRating(postId, rating);
    }

    handleLoadPost() {
        this.props.fetchPostIfNeeded(this.props.postId);
        // Moved to componentDidUpdate with visibility sensor
        // this.props.fetchPostStatsIfNeeded(this.props.postId);
    }

    componentDidMount() {
        if(this.props.currentView == 'expanded-table') {
            this.handleExpandAll();
        } else if (this.props.currentView === 'table') {
            this.handleUnexpandAll();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.currentView == 'expanded-table' && this.props.currentView !== prevProps.currentView) {
            this.handleExpandAll();
        }
        if(this.props.currentView == 'table' && this.props.currentView !== prevProps.currentView) {
            this.handleUnexpandAll();
        }

        if (this.state.isVisible === true && prevState.isVisible === false && prevState.wasEverVisible === false) {
            // We don't actually need this right now. Maybe bring it back when we bring back expanded view.
            // this.props.fetchPostStatsIfNeeded(this.props.postId);
        }
    }

    handleToggleExpanded() {
        this.setState(prevState => {
            return {
                isExpanded: !prevState.isExpanded,
            }
        })
    }

    handleExpandAll() {
        this.setState({
            isExpanded: true,
        })
    }

    handleUnexpandAll() {
        this.setState({
            isExpanded: false,
        })
    }

    renderTd(content, columnDef) {
        const className = (columnDef.sortBy === this.props.sort.by) ? 'posts-table-row-td posts-table-row-td-active' : 'posts-table-row-td';
        const style = Object.assign({minWidth: '80px'}, columnDef.style || {});
        return <div className={className} style={style}>{content}</div>;
    }

    handleSelect() {
        const {post, queryId, isSelected} = this.props;

        if (isSelected) {
            this.props.unselectPost(post._id, queryId);
        } else {
            this.props.selectPost(post._id, queryId);
        }
    }

    renderColumn(columnDef) {
        switch (columnDef.name) {
            case 'Eng. Pct.':
                return this.renderEngPct(columnDef);
            case 'Location':
                return this.renderUserBasicsField(columnDef, 'human_location');
            case 'Avg. Eng. Pct.':
                return this.renderUserBasicsField(columnDef, 'overall_avg_engagement_pct');
            case 'Tier':
                return this.renderUserBasicsField(columnDef, 'tier');
            case 'Reach':
                return this.renderUserBasicsField(columnDef, 'verified_reach', n => formatNumber(parseInt(n || 0, 10)));
            case "Featured":
                return this.renderFeatured(columnDef);
            case "Select":
                return this.renderSelect(columnDef);
            case "Title":
                return this.renderTitle(columnDef);
            case "Posted By":
                return this.renderPostedBy(columnDef);
            case "Kind":
                return this.renderKind(columnDef);
            case "Rating":
                return this.renderRating(columnDef);
            case "Engagements":
                return this.renderEngagements(columnDef);
            case "Impressions":
                return this.renderImpressions(columnDef);
            case "Status":
                return this.renderStatus(columnDef);
            case "Action":
                return this.renderActions(columnDef);
            case "Expanded":
                return this.renderExpanded(columnDef);
            default:
                return null;
        }
    }

    renderSelect(columnDef) {
        const isSelected = this.props.isSelected;

        let checkbox = <div onClick={this.handleSelect} className="tidal-checkbox"></div>;

        if (isSelected) {
            checkbox =
                <div onClick={this.handleSelect} className="tidal-checkbox checked"><i className="fa fa-check"></i>
                </div>
        }

        return this.renderTd(
            checkbox, columnDef
        )
    }

    renderUserBasicsField(columnDef, fieldName, formatter) {
        const {user, post} = this.props;
        let val = null;
        if (this.state.wasEverVisible) this.props.fetchUserIfNeeded(post.user.id);

        if (!user || !user.basics || !user.basics[fieldName]) {
            return this.renderTd('-', columnDef);
        }

        val = user.basics[fieldName];

        if (typeof formatter !== 'undefined') {
            val = formatter(val);
        }

        return this.renderTd(val, columnDef);

    }

    renderEngPct(columnDef) {
        const {post, user} = this.props;

        if (this.state.wasEverVisible) this.props.fetchUserIfNeeded(post.user.id);
        if (!user || !user.basics || !user.basics.verified_reach) {
            return this.renderTd('-', columnDef);
        }

        const eng = parseInt(post.engagements,  10);
        const reach = parseInt(user.basics.verified_reach, 10);
        const engPct = formatNumber(100 * eng / reach, 2) + '%';
        return this.renderTd(engPct, columnDef);

    }

    renderTitle(columnDef) {
        const {post} = this.props;
        const title = limitChars(post.title, 50);
        const label = <a className="lightbox-launcher" href={"/manage/do/page/posts/view?slug=" + post.slug}>{title}</a>
        return this.renderTd(label, columnDef);
    }

    renderPostedBy(columnDef) {
        const {post} = this.props;
        let label;
        if (post.user) {
            label = <a
                onClick={e => {
                    e.preventDefault();
                    this.props.openUserLightbox(post.user.id);
                }}
                href={"/manage/do/page/users/detail?slug=" + post.user.slug}>{post.user.name}</a>
        } else {
            label = '';
        }

        return this.renderTd(label, columnDef);
    }

    renderKind(columnDef) {
        const {post} = this.props;

        let label;

        const types = ['twitter', 'instagram', 'facebook', 'pinterest', 'youtube'];
        if(types.indexOf(post.type) > -1) {
            label = <span><i className={`fa fa-${post.type}`}></i> {capitalizeFirstLetter(post.type)}</span>;
        } else{
            label = <span><i className={'fa fa-file-o'}></i> Post </span>
        }

        return this.renderTd(label, columnDef);
    }

    renderRating(columnDef) {
        const {post} = this.props;
        const rating = post.rating;
        const label = <StarRating rating={rating} onRatingChange={(rating) => this.handleRatingChange(rating)}/>
        return this.renderTd(label, columnDef)
    }

    renderEngagements(columnDef) {
        const {post} = this.props;
        const label = formatNumber(post.engagements);
        return this.renderTd(label, columnDef);
    }

    renderImpressions(columnDef) {
        const {post} = this.props;
        const label = formatNumber(post.views);
        return this.renderTd(label, columnDef);
    }

    renderFeatured(columnDef) {
        const {post} = this.props;

        const iconClass = this.state.isFeaturing
            ? 'fa fa-spinner fa-spin'
            : (post.is_featured ? 'fa fa-star' : 'fa fa-star-o');

        const shouldFeature = !post.is_featured;
        const tooltip = (shouldFeature ? 'Feature' : 'Un-feature') + ' this post.';

        const icon = (<a
            className="dark"
            href="#"
            onClick={e => {
                e.preventDefault();
                this.setState({isFeaturing: true});
                this.props.featurePost(post._id, shouldFeature)
                    .then(() => {
                        this.setState({isFeaturing: false});
                    });
            }}
            data-tooltip={tooltip}
        ><i className={iconClass} /></a>);

        return this.renderTd(icon, columnDef);
    }

    renderStatus(columnDef) {
        const {post} = this.props;
        const label = post.is_featured ? post.human_status + ' Featured' : post.human_status;
        return this.renderTd(label, columnDef);
    }

    renderActions(columnDef) {
        const label = <PostActions
            applyAction={this.props.applyAction}
            postId ={this.props.post._id}
            post={this.props.post}
            publishPost={this.props.publishPost}
            featurePost={this.props.featurePost}
            fetchPostActivationRecords={this.props.fetchPostActivationRecords}
        />
        return this.renderTd(label, columnDef);
    }

    renderExpanded(columnDef) {

        return this.renderTd(
            <div className="carat-wrapper">
                <a href={`/manage/do/page/posts/view?slug=${this.props.post.slug}`} className="lightbox-launcher dark">
                    <i className="fa fa-angle-right" />
                </a>
            </div>,
            columnDef
        );

        // Disabling expanded for now.
        const expandedClass = this.state.isExpanded ? 'post-expanded-icon fa fa-angle-down' : 'post-expanded-icon fa fa-angle-right';
        const label = <div className="carat-wrapper" onClick={this.handleToggleExpanded} > <i className={expandedClass}></i> </div>
        return this.renderTd(label, columnDef);
    }

    getSelectedColumnsDefs() {
        return POST_TABLE_TABS.filter(tabdef => {
            if (tabdef.alwaysShow) return true;
            if (this.props.selectedColumns.indexOf(tabdef.name) > -1) return true;
            return false;
        });
    }

    getTotalRequiredWidth() {
        return this.getSelectedColumnsDefs().reduce((sum, value) => {
            let style = value.style || {};
            let minWidth = style.minWidth || '80px';
            let val = parseInt(minWidth.replace('px', ''));
            return sum+val+1;
        }, 0);
    }

    renderColumns() {
        const columns = this.getSelectedColumnsDefs().map(td => this.renderColumn(td));
        return columns;
    }

    renderExpandedView() {
        if(this.state.isExpanded) {
            return(
                <PostRowExtendedView
                    stats={this.props.post.stats}
                    views={this.props.post.views}
                    images={this.props.post.images}
                    title={this.props.post.title}
                    type={this.props.post.type}
                    post={this.props.post}
                />
            )
        }
        return null;
    }

    render() {
        const style = {
            minWidth: this.getTotalRequiredWidth()
        };

        const className = this.props.isFetching ? 'post-row blurred' : 'post-row';
        return (
            <div className={className} style={style}>
                <VisibilitySensor
                    scrollCheck
                    onChange={this.handleVisibilityChange}
                    partialVisibility={true}
                >
                    <div className="primary">
                        {this.renderColumns()}
                    </div>
                </VisibilitySensor>
                { this.renderExpandedView() }
            </div>
        );
    }
}

export default PostTableRow;

PostTableRow.propTypes = {
    post: PropTypes.object,
    isSelected: PropTypes.bool,
    queryId: PropTypes.number,
    fetchPostIfNeeded: PropTypes.func,
    selectPost: PropTypes.func,
    unselectPost: PropTypes.func,
    currentView: PropTypes.sting,
    sort: PropTypes.string,
    applyAction: PropTypes.func,
    isFetching: PropTypes.bool,
    featurePost: PropTypes.func,
    publishPost: PropTypes.func,
    fetchPostActivationRecords: PropTypes.func,
    openUserLightbox: PropTypes.func,
    fetchUserIfNeeded: PropTypes.func,
}

PostTableRow.defaultProps = {
    currentView: 'None'
};
