import {connect} from 'react-redux';
import ActivationsPage from '../../components/Pages/Activations';
import * as QuerySelectors from '../../selectors/queries';
import * as CampaignSelectors from '../../selectors/campaigns';
import {DEFAULT_ACTIVATIONS_QUERY} from "../../reducers/activations";
import * as ActionTypes from '../../actions/types';
import * as Query from '../../actions/queries';
import {updateActivationMetadata} from "../../actions/activations";
import {openUserLightbox} from "../../actions/lightbox";
import {fetchActivation, fetchCampaign, fetchCampaignForms} from "../../actions";
import {fetchAuthenticated} from "../../actions/auth";
import {getCurrentChannel} from "../../selectors/presence";
import {getCampaignForms} from "../../selectors/campaigns";

const mapStateToProps = (state, props) => {

    const channel = getCurrentChannel(state, props);
    const defaultQuery = {...DEFAULT_ACTIVATIONS_QUERY};
    defaultQuery.filters.allow_inactive_users = !channel.campaign_hide_inactive;
    const query = QuerySelectors.getQuery(state.activations, props, defaultQuery);
    return {
        channel,
        activations: QuerySelectors.getPageItemsWithFallback(state.activations, props, defaultQuery),
        campaigns: CampaignSelectors.getCampaignsByDate(state, props),
        campaign: CampaignSelectors.getCampaign(state, props),
        forms: getCampaignForms(state, props),
        query
    }

};

const mapDispatchToProps = (dispatch, props) => {

    const url = `/manage/api/activation`;
    return {
        onRefresh: (query) => dispatch(Query.fetchQuery(url, query, ActionTypes.ACTIVATIONS_REQUEST_QUERY, ActionTypes.ACTIVATIONS_RECEIVE_QUERY)),
        onSortChange: (sort, query) => dispatch(Query.updateQuerySort(sort, query, ActionTypes.ACTIVATIONS_UPDATE_QUERY_SORT)),
        onPageChange: (page, query) => dispatch(Query.updateQueryPage(page, query, ActionTypes.ACTIVATIONS_UPDATE_QUERY_PAGE)),
        onFilterChange: (filters, query) => dispatch(Query.updateQueryFilters(filters, query, ActionTypes.ACTIVATIONS_UPDATE_QUERY_FILTERS)),
        updateActivationMetadata: (id, key, value) => dispatch(updateActivationMetadata(id, key, value)),
        openUserLightbox: (id, ctx, stack) => dispatch(openUserLightbox(id, ctx, stack)),
        fetchActivation: id => dispatch(fetchActivation(id)),
        fetchCampaign: (id) => dispatch(fetchCampaign(id)),
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
        fetchForms: (id) => dispatch(fetchCampaignForms(id)),
    }

};

const ConnectedActivationsPage = connect(mapStateToProps, mapDispatchToProps)(ActivationsPage);

export default ConnectedActivationsPage;
