import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../Common/Form/Form";
import {EMAIL_LABELS} from "../../utilities/email";
import Button from "../Common/Form/Button";

class EmailGodzillaFilters extends Component {

    static propTypes = {
        filters: PropTypes.object,
        onClickSubmit: PropTypes.func,
        onClickReset: PropTypes.func,
        box: PropTypes.oneOf(['inbox', 'outbox', 'all'])
    };

    constructor(props) {
        super(props);
        this.state = {
            filters: props.filters || {}
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleClickSubmit = this.handleClickSubmit.bind(this);
        this.handleClickReset = this.handleClickReset.bind(this);
    }

    handleClickReset() {
        if (this.props.onClickReset) {
            this.props.onClickReset();
        }
    }
    handleClickSubmit() {

        if (this.props.onClickSubmit) {
            this.props.onClickSubmit(this.state.filters);
        }
    }

    handleFilterChange(name, value) {
        this.setState(prevState => ({filters: {...prevState.filters, [name]: value}}));
    }

    getOutboxFields() {
        if (this.props.box !== 'outbox') {
            return [];
        }

        return [
            {
                type: 'select',
                title: 'Email Was Opened',
                name: 'was_opened',
                choices: [
                    {text: '- All Emails -', value: null},
                    {text: 'Email Opened', value: true},
                    {text: 'Email Not Opened', value: false},
                ]
            },
            {
                type: 'select',
                title: 'Email Link Was Clicked',
                name: 'was_clicked',
                choices: [
                    {text: '- All Emails -', value: null},
                    {text: 'Link Clicked', value: true},
                    {text: 'Link Not Clicked', value: false},
                ]
            },
            {
                type: 'select',
                title: 'Email Bounced',
                name: 'was_bounced',
                choices: [
                    {text: '- All Emails -', value: null},
                    {text: 'Email Bounced', value: true},
                    {text: 'Email Not Bounced', value: false},
                ]
            },

        ];
    }
    render() {
        return (
            <Form
                values={this.state.filters}
                onFieldChange={this.handleFilterChange}
                fields={[
                    {
                        type: 'text',
                        name: 'user',
                        title: 'User',
                        placeholder: 'Search by name or email...',
                    },
                    {
                        type: 'text',
                        name: 'content',
                        title: 'Content',
                        placeholder: 'Search by subject or content...',
                    },
                    {
                        type: 'select',
                        name: 'label',
                        title: "Label",
                        choices: EMAIL_LABELS.map(label => ({text: label, value: label.toLowerCase()}))
                    },
                    {
                        type: 'select',
                        name: 'is_read',
                        title: 'Read/Unread',
                        choices: [
                            {text: 'Show Both Read and Unread', value: null},
                            {text: 'Unread Only', value: false},
                            {text: 'Read Only', value: true}
                        ]
                    },
                    {
                        type: 'checkbox',
                        name: 'hide_archived',
                        title: "Hide Archived",
                        options: {hideTitle: true},
                    },
                    {
                        type: 'checkbox',
                        name: 'hide_spam',
                        title: "Hide Spam",
                        options: {hideTitle: true},
                    },
                    ...this.getOutboxFields(),
                    {
                        key: 'submitbtns',
                        type: 'generic',
                        content: (
                            <div className="clearfix">

                                <Button
                                    classes={['v3', 'btn-primary', 'pull-left']}
                                    content={'Search'}
                                    onClick={this.handleClickSubmit}
                                />

                                {
                                    this.props.onClickReset
                                    && <Button
                                        classes={['v3', 'btn-secondary', 'pull-right']}
                                        content={'Reset'}
                                        onClick={this.handleClickReset}
                                    />
                                }

                            </div>
                        )
                    }
                ]}
            />
        );
    }
}

export default EmailGodzillaFilters;