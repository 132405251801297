/**
 * Created by visgotti on 9/29/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class PostsHeaderPostType extends Component {

    constructor(props){
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();
        if (typeof this.props.changeFilter === 'function') {
            this.props.changeFilter('status', event.currentTarget.getAttribute('value'), true);
        }
    }

    render() {
        const submittedPostsClass = this.props.activeStatus === 'submitted' ? 'active': '';
        const publishedPostsClass = this.props.activeStatus === 'published' ? 'active' : '';
        const allPostsClass = this.props.activeStatus === 'all' ? 'active' : '';

        return(
            <span className="post-types pull-left">
                <span
                    className={submittedPostsClass}
                    value="submitted"
                    onClick={this.handleClick}
                >
                    Submitted
                </span>

                <span
                    className={publishedPostsClass}
                    value="published"
                    onClick={this.handleClick}
                >
                   Published Posts
                </span>

                <span
                    className={allPostsClass}
                    value="all"
                    onClick={this.handleClick}
                >
                    All Posts
                </span>
            </span>
        )
    }
}

PostsHeaderPostType.propTypes = {
    changeFilter: PropTypes.func,
    activeStatus: PropTypes.oneOf(['draft', 'submitted', 'published', 'all'])
}

PostsHeaderPostType.defaultProps = {
    changeFilter: null,
    activeStatus: 'all',
}
