import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TinyThermometer from '../../Common/TinyThermometer';
import {formatNumber, fetchAuthenticated} from "../../../utilities";
import GenericProfilePerformanceScoreTable from "../../Common/GenericProfilePerformanceScoreTable";

const SPECS = {
    "avg_roi": {
        name: "Average ROI",
        tooltipFn: data => `Average ROI across all campaigns is <strong>${formatNumber(data.value)}x</strong>.`
    },
    "avg_campaign_engagement_pct": {
        name: "Engagement % In Campaigns",
        tooltipFn: data => `Average social engagement percentage for campaign content is <strong>${formatNumber(data.value * 100, 2)}%</strong>.`
    },
    "avg_post_rating": {
        name: "Average Content Rating",
        tooltipFn: data => `Average content rating for all submitted posts is <strong>${formatNumber(data.value, 1)} / 5</strong>.`
    },
    "genuine_followers_ratio": {
        name: "Audience Authenticity %",
        tooltipFn: data => `This creator's instagram audience is <strong>${formatNumber(data.value * 100)}% genuine</strong>, and the rest are bots or low-quality followers.`
    },
    "activation_completion_rate": {
        name: "Campaign Completion Rate",
        tooltipFn: data => `Percentage of campaigns successfully completed: <strong>${formatNumber(data.value * 100)}%</strong>.`
    },
    "completed_activations_count": {
        name: "Total Completed Campaigns",
        tooltipFn: data => `Total number of completed campaigns: <strong>${formatNumber(data.value)} campaigns</strong>.`
    },
    "overall_avg_engagement_pct": {
        name: "Overall Social Engagement %",
        tooltipFn: data => `Overall social engagement, for all social content across all social networks: <strong>${formatNumber(data.value * 100, 2)}%</strong>.`
    },
    "frecency": {
        name: "Frecency",
        tooltipFn: data => `Frecency, or "recently frequent", is a measure of how often and how recently the creator has interacted with the platform.`
    },
    "response_probability": {
        name: "Invitation Response Probability",
        tooltipFn: data => `The odds of this creator responding to a campaign invitation are <strong>${formatNumber(data.value * 100)}%</strong>.`
    },
    "avg_list_rating": {
        name: "Average List Rating",
        tooltipFn: data => `Average rating across all lists: <strong>${formatNumber(data.value/2, 1)} / 5</strong>.`
    },
};

export default class UserDetailPerformancePane extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            didFetch: false,
            results: null,
            score: null
        };

    }

    fetchData() {
        const {user} = this.props;
        if (!this.state.isFetching && !this.state.didFetch) {

            const url = `/manage/api/user/${user.id}/performance`;
            this.setState({ isFetching: true });

            return fetchAuthenticated(url, {credentials: 'include'})
                .then(resp => resp.json())
                .then(json => {
                    this.setState({
                        isFetching: false,
                        didFetch: true,
                        results: json.data.results,
                        score: json.data.score
                    })
                })
                .catch(err => {
                    console.log("Error fetching user performance score");
                    console.log(err);
                    return err;
                });

        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.id !== this.props.user.id) {
            this.setState({
                isFetching: false,
                didFetch: false,
                results: null,
                score: null,
            }, () => {
                this.fetchData();
            })
        }
    }

    componentDidMount() {
        this.fetchData();
    }


    renderTable() {
        return (
            <GenericProfilePerformanceScoreTable
                score={this.state.score}
                results={this.state.results}
            />
        );

    }

    render() {

        return (

            <div className={'user-detail-body-performance'}>
                {
                    (this.state.isFetching || !this.state.didFetch)
                        ? <div className="alert tidal-alert alert-grey">Loading performance history...</div>
                        : this.renderTable()
                }
            </div>

        );
    }

}

UserDetailPerformancePane.propTypes = {
    user: PropTypes.object,
};
