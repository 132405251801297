import React, {useState} from 'react';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";
import {connect} from "react-redux";
import Form from "../Common/Form/Form";
import {STATS_FORM_FIELDS_FOR_POST_TYPES} from "../../utilities/campaign";
import Alert from "../Common/Alert";

function QuickAddPostForm({createInteraction, fetchActivation, activation, onComplete}) {
    const [form, setForm] = useState({});
    const [error, setError] = useState(null);


    const handleFieldChange = (key, value) => {
        let newForm = {...form};

        // Set type based on url
        if (key === 'url') {
            newForm.url = value;
            if (!form.type) {
                const lcUrl = value.toLowerCase();
                if (lcUrl.indexOf('facebook.com') > -1) {
                    newForm.type = 'facebook-page';
                } else if (lcUrl.indexOf('pinterest.com') > -1) {
                    newForm.type = 'pinterest';
                } else if (lcUrl.indexOf('twitter.com') > -1) {
                    newForm.type = 'twitter';
                } else if (lcUrl.indexOf('instagram.com/stories') > -1) {
                    newForm.type = 'instastory';
                } else if (lcUrl.indexOf('instagram.com') > -1) {
                    newForm.type = 'instagram';
                } else if (lcUrl.indexOf('tiktok.com') > -1) {
                    newForm.type = 'tiktok';
                } else {
                    newForm.type = 'blog';
                }
            }
        }

        newForm[key] = value;

        setForm(newForm);
    };

    const getStatsFields = () => {
        return STATS_FORM_FIELDS_FOR_POST_TYPES
            .filter(o => o.forTypes.indexOf(form.type) > -1)
            .map(o => ({...o, name: 'stats.' + o.name}))
            .map(field => {
                // If the field's 'help' parameter is a function, evaluate the function with the user object
                if (typeof field.help === 'function') {
                    field.help = field.help((activation || {}).user);
                }

                // if the field has a defaultValue function, evaluate it with the user object, and also setState if it's not already set
                if (typeof field.defaultValue === 'function') {
                    let val = field.defaultValue((activation || {}).user);
                    if (val) {
                        val = Math.round(val);
                    }
                    if (!form[field.name] && val) {
                        handleFieldChange(field.name, val);
                    }
                }
                return field;
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const resp = await createInteraction(activation.id, 'QuickAddPost', form);
            if (resp && resp.meta && resp.meta.error) {
                throw new Error(resp.meta.error);
            }
            await fetchActivation(activation.id);
            onComplete();
        } catch (err) {
            setError(err.message);
        }

    };

    return (
        <Form
            style={{
                maxHeight: 600,
                overflow: 'auto'
            }}
            onFieldChange={handleFieldChange}
            values={form}
            fields={[
                {
                    type: 'text',
                    name: 'url',
                    title: 'URL of Post'
                },
                {
                    type: 'select',
                    name: 'type',
                    title: 'Type of Post',
                    choices: [
                        {text: '- Select -', value: null},
                        {text: 'Blog Post', value: 'blog'},
                        {text: 'Twitter Post', value: 'twitter'},
                        {text: 'Facebook Page Post', value: 'facebook-page'},
                        {text: 'Pinterest Post', value: 'pinterest'},
                        {text: 'TikTok Post', value: 'tiktok'},
                        {text: 'Instagram Post', value: 'instagram'},
                        {text: 'Instastory', value: 'instastory'},
                        {text: 'YouTube Video', value: 'youtube'},
                    ]
                },
                ...getStatsFields(),
                {
                    type: 'button',
                    name: 'submit',
                    title: 'Add Post and Stats',
                    options: {
                        hideTitle: true,
                        classes: ['v3 btn-primary medium'],
                        onClick: handleSubmit
                    }
                },
                (error ? {
                    type: 'generic',
                    content: <Alert content={error} classes={['danger']} />
                } : null)

            ].filter(o => !!o)}
        />
    );

}

const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };
};

export default connect(null, mapDispatchToProps)(QuickAddPostForm);
