/**
 * Created by bkanber on 7/7/17.
 */

import {receiveUser} from './index';
import {hasPushState, fetchAuthenticated} from "../utilities";
import * as URI from 'urijs';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const QUERY_USERS = 'QUERY_USERS';
export const SET_USERS_LIST_CONTEXT = 'SET_USERS_LIST_CONTEXT';
export const SET_USERS_BADGE_CONTEXT = 'SET_USERS_BADGE_CONTEXT';
export const SET_USERS_CAMPAIGN_CONTEXT = 'SET_USERS_CAMPAIGN_CONTEXT';
export const INVALIDATE_USERS_QUERY = 'INVALIDATE_USERS_QUERY';
export const REQUEST_USERS_STATS = 'REQUEST_USERS_STATS';
export const RECEIVE_USERS_STATS = 'RECEIVE_USERS_STATS';

export function fetchUsersStats(queryId, filters) {

    return function(dispatch) {

        dispatch({
            type: REQUEST_USERS_STATS,
            queryId,
            filters
        });

        const url = `/manage/api/user/_/statistic?filters=${JSON.stringify(filters)}`;
        return fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => dispatch({
                type: RECEIVE_USERS_STATS,
                receivedAt: new Date(),
                filters,
                queryId,
                meta: json.meta,
                stats: json.data
            }))
            .catch(err => {
                console.log("Error fetching user search stats");
                console.log(err);
            });
    }
}

export function invalidateUsersQuery(queryId) {
    return {
        type: INVALIDATE_USERS_QUERY,
        queryId
    };
}

export function setUsersCampaignContext(queryId, campaignId) {
    return {
        type: SET_USERS_CAMPAIGN_CONTEXT,
        campaignId,
        queryId

    };
}

export function setUsersBadgeContext(queryId, badgeId) {
    return {
        type: SET_USERS_BADGE_CONTEXT,
        badgeId,
        queryId

    };
}

export function setUsersListContext(queryId, listId) {
    return {
        type: SET_USERS_LIST_CONTEXT,
        listId,
        queryId

    };
}

export function receiveUsers(queryId, json) {

    return function (dispatch) {

        const users = json.data;
        const meta = json.meta;

        // now dispatch individual user updates
        users.forEach(user => {
            dispatch(receiveUser(user.id, {data: user}));
        });

        // Dispatch the full receive_users action now that that's done
        return dispatch({type: RECEIVE_USERS, users, meta, queryId, receivedAt: new Date()});


    };

}

export function queryUsers(queryId, filters = {}, sort = {}, page=1, limit=20, listContext = null, campaignContext = null, options = {}) {

    const defaultOpts = {usePushState: true};
    options = Object.assign({}, defaultOpts, options);

    return function (dispatch) {

        const payload = {
                filters: JSON.stringify(filters),
                sort: JSON.stringify(sort),
                page,
                limit,
                listContext,
                campaignContext
            };

        let url = URI('/manage/api/user')
            .query(payload)
            .toString();

        dispatch({type: QUERY_USERS, queryId, filters, sort, page, limit, listContext, campaignContext});

        if (hasPushState && options.usePushState) {
            // have to get rid of nested json before urlifying
            const newUrl = '?q='+JSON.stringify({...payload, filters, sort});
            window.history.replaceState( payload, null, newUrl );
        }

        return fetchAuthenticated(url, {credentials: 'include'})
            .then(response => response.json())
            .then(json => dispatch(receiveUsers(queryId, json)))
            .catch(err => {
                console.log("Error fetching users")
                console.log(err)
            });

    }
}