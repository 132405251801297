import React, {useState, useEffect} from 'react';
import MyCampaignLeaderboard from "../../../MAC/Creator/MyCampaignLeaderboard";
import MyPerformanceCharts from "../../../MAC/Creator/MyPerformanceCharts";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {fetchAuthenticated} from "../../../../actions/auth";
import {fetchCampaigns} from "../../../../actions";
import {getCampaigns} from "../../../../selectors/campaigns";
import MyPostPerformance from "../../../MAC/Creator/MyPostPerformance";
import {translate} from "../../../../actions/i18n";

function MACCreatorAchievementsPage({fetchAuthenticated, fetchCampaigns, campaigns, translate}) {
    const [socials, setSocials] = useState([]);
    const [activations, setActivations] = useState([]);
    const [stats, setStats] = useState([]);
    const [audience, setAudience] = useState({});
    const fetchAudience = async () => {
        const resp = await fetchAuthenticated('/contribute/api/user/me/audience');
        const json = await resp.json();
        setAudience(json.data);
    };

    const fetchStats = async () => {
        const resp = await fetchAuthenticated('/contribute/api/user/me/stat');
        const json = await resp.json();
        setStats(json.data);
    };
    const fetchSocials = async () => {
        const resp = await fetchAuthenticated('/contribute/api/user/me/social');
        const json = await resp.json();
        setSocials(json.data);
    };
    const fetchActivations = async () => {
        const resp = await fetchAuthenticated('/contribute/api/user/me/activation?withStats=1');
        const json = await resp.json();
        setActivations(json.data);
    };
    useEffect(function() {
        Promise.all([
            fetchSocials(),
            fetchActivations(),
            fetchCampaigns(),
            fetchStats(),
            fetchAudience()
        ]).then(() => console.log("Done loading MACCreatorAchievementsPage data"));
    }, []);

    const wdAchievements = translate('creator.words.achievements', 'Achievements');

    return (
        <div className="mac-wrapper">
            <div className="dashboard">
                <div className="achievements-page-wrapper">
                    <div className="page-title-wrapper achievements-title-wrapper">
                        <h1>{wdAchievements}
                            <span className="large badge">
                            <i className="v3 red icon star" />
                        </span>
                        </h1>
                    </div>
                    <MyCampaignLeaderboard
                        activations={activations}
                        campaigns={campaigns}
                        stats={stats}
                        translate={translate}
                    />
                    <MyPerformanceCharts
                        socials={socials}
                        stats={stats}
                        audience={audience}
                        translate={translate}
                    />

                    <MyPostPerformance
                        fetchAuthenticated={fetchAuthenticated}
                        activations={activations}
                        stats={stats}
                        translate={translate}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => ({
    campaigns: getCampaigns(state, props),
});
const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    fetchAuthenticated: fetchAuthenticated,
    fetchCampaigns: fetchCampaigns,
    translate: translate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MACCreatorAchievementsPage);