import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Area, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {c3ColorPattern, capitalizeFirstLetter, formatNumber} from "../../utilities";
import moment from "moment";
import _get from "lodash/get";

export default class RechartsTimeseriesAreaChart extends Component {


    /**
     * for default seriesSettings, refer to defaultSettings object inside getSeriesSettings()
     */
    static defaultProps = {
        width: '100%',
        height: 320,
        data: [],
        showTooltips: true,
        showLegend: true,
        stacked: false,
        hideSeriesThatEndInZero: true,
    };

    static propTypes = {
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        height: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        data: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.number,
        })),
        showTooltips: PropTypes.bool,
        showLegend: PropTypes.bool,
        stacked: PropTypes.bool,
        hideSeriesThatEndInZero: PropTypes.bool,
        seriesSettings: PropTypes.arrayOf(PropTypes.shape({
            chartType: PropTypes.string,
            type: PropTypes.string,
            dataKey: PropTypes.string,
            name: PropTypes.string,
            yAxisId: PropTypes.string,
            stroke: PropTypes.string,
            strokeWidth: PropTypes.number,
            stackId: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            fill: PropTypes.string,
            dot: PropTypes.bool,
            activeDot: PropTypes.bool,
            formatter: PropTypes.func,
            isAnimationActive: PropTypes.bool
        })),
        axisSettings: PropTypes.arrayOf(PropTypes.shape({
            axisType: PropTypes.string,
            yAxisId: PropTypes.string,
            dataKey: PropTypes.string,
            stroke: PropTypes.string,
            interval: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            orientation: PropTypes.string,
            tickFormatter: PropTypes.func
        }))
    };

    renderTooltipRow(spec, index) {
        return (
            <tr key={`tooltip-row-${index}`}>
                <td className="name">
                    <span style={{backgroundColor: spec.color}}></span>{capitalizeFirstLetter(spec.name)}
                </td>
                <td className="value">
                    {spec.formatter(spec.value)}
                </td>
            </tr>
        );
    }

    renderTooltip = (props) => {
        const {payload} = props;
        if (!payload || !payload.length) {
            return null;
        }

        const date = ((payload[0] || {}).payload || {}).date || ((payload[0] || {}).payload || {}).created_at || 0;
        const dateObject = moment(date);
        const formattedDate = dateObject.isValid() ? dateObject.format('MMM Do, YYYY') : date;

        let rows = payload.map((item, index) => {
            return this.renderTooltipRow(item, index)
        });

        if (this.props.stacked) {
            rows = rows.reverse();
        }

        return (
            <table className="custom-tooltip">
                <tbody>
                    <tr>
                        <th colSpan="2">{formattedDate}</th>
                    </tr>
                    {rows}
                </tbody>
            </table>
        )
    };

    getLatestDataValue(key) {
        const item = (this.props.data[this.props.data.length - 1] || {});
        return _get(item, key, 0);
    }

    getSeriesSettings() {
        const defaultSettings = {
            type: 'monotone',
            yAxisId: 'left',
            dot: false,
            activeDot: false,
            isAnimationActive: false,
            formatter: (value) => formatNumber(value)
        };

        const settings = this.props.seriesSettings.map(setting => ({...defaultSettings, ...setting})).filter(i => {
            if (this.props.hideSeriesThatEndInZero) {
                return this.getLatestDataValue(i.dataKey) !== 0;
            }
            return true;
        });

        if (this.props.stacked) {
            settings.sort((a, b) => {
                return this.getLatestDataValue(a.dataKey) > this.getLatestDataValue(b.dataKey) ? 1 : -1;
            });
        }

        return settings;
    }

    renderSeriesFromSettings(settings, index) {
        const chartType = settings.chartType;

        if (chartType === 'area') {
            if (settings.fill === '' || typeof settings.fill === 'undefined') {
                settings.fill = c3ColorPattern.pattern[index];
            }

            if (settings.stroke === '' || typeof settings.stroke === 'undefined') {
                settings.stroke = c3ColorPattern.pattern[index];
            }
        }

        if (chartType === 'line') {
            if (settings.stroke === '' || typeof settings.stroke === 'undefined') {
                settings.stroke = c3ColorPattern.pattern[index];
            }
        }

        const _settings = {...settings};
        delete (_settings.chartType);

        if (chartType === 'line') {
            return <Line {..._settings} key={`series-${chartType}-${index}`}/>
        }

        if (chartType === 'area') {
            return <Area {..._settings} key={`series-${chartType}-${index}`} />
        }
    }

    renderAxisSettings(settings, index) {
        const axisType = settings.axisType;
        const _settings = {...settings};
        delete (_settings.axisType);

        if (axisType === 'x') {
            return <XAxis {..._settings} key={`axis-${axisType}-${index}`}/>
        }

        if (axisType === 'y') {
            return <YAxis {..._settings} key={`axis-${axisType}-${index}`}/>
        }
    }

    render() {
        const {showLegend, showTooltips} = this.props;
        return (
            <ResponsiveContainer width={this.props.width} height={this.props.height} className="recharts-time-series-area-chart">

                <ComposedChart data={this.props.data} >

                    {this.props.axisSettings.map((settings, index) => this.renderAxisSettings(settings, index))}

                    {this.getSeriesSettings().map((settings, index) => this.renderSeriesFromSettings(settings, index))}

                    {showTooltips &&
                        <Tooltip
                            content={this.renderTooltip}
                            contentStyle={{padding: 0}}
                            itemStyle={{padding: 0}}
                        />
                    }

                    {showLegend &&
                        <Legend
                            iconType="square"
                            iconSize={10}
                        />
                    }

                </ComposedChart>

            </ResponsiveContainer>
        )
    }

}