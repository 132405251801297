/**
 * Created by bkanber on 6/9/17.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {limitChars} from '../../../../utilities';
import {fetchUserProfileIfNeeded} from '../../../../actions';
import {connect} from 'react-redux';
import UserProfileTable from '../../Pieces/ProfileTable';
import UserProfileImages from '../../Pieces/ProfileImages';

class UserTableExtendedProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wrapperWidth: 702 // size of two .left sections + little fudge factor
        }
        this.incrementWrapperWidth = this.incrementWrapperWidth.bind(this);
        this.handleImageLoad = this.handleImageLoad.bind(this);
    }

    handleImageLoad(event) {

        const naturalWidth = event.target.naturalWidth;
        const naturalHeight = event.target.naturalHeight;
        const aspectRatio = naturalWidth / naturalHeight;

        const actualHeight = event.target.height;
        const actualWidth = aspectRatio * actualHeight;

        this.incrementWrapperWidth(actualWidth + 1);

    }

    incrementWrapperWidth(width) {
        this.setState({
            wrapperWidth: this.state.wrapperWidth + width + 1
        });
    }

    componentDidMount() {
        this.props.dispatch(fetchUserProfileIfNeeded(this.props.user.id));
    }

    renderProfileText() {

        const user = this.props.user;
        const {profile} = user;

        let profileText = (<em>Profile text coming soon...</em>);

        if (profile && profile.text) {
            profileText = profile.text;
            profileText = limitChars(profileText, 300);
        }

        return (

            <p className="user-card-profile-text">
                {profileText}
            </p>
        );
    }

    renderTable() {
        const {user} = this.props;
        return <UserProfileTable user={user} />
    }

    renderViewProfileBtn() {

        if (this.props.user && this.props.user.basics.slug) {

            if (this.props.onClickOpenLightbox) {

                const viewUrl = '/manage/do/page/users/detail?slug=' + this.props.user.basics.slug;
                return (
                    <a href={viewUrl}
                       className="view-user-details tidal-btn btn btn-primary"
                       onClick={ this.props.onClickOpenLightbox.bind(null, this.props.user) }
                    >Full Profile</a>
                );

            } else {
                const viewUrl = '/manage/do/page/users/view?slug=' + this.props.user.basics.slug;
                return (
                    <a href={viewUrl} className="view-user-details tidal-btn btn btn-primary lightbox-launcher">Full
                        Profile</a>
                );

            }
        } else {
            return null;
        }

    }

    renderContactBtn() {
        // /manage/do/page/email/write?r[0][id]=571984765bdeb49f1f8b4bae&r[0][name]=Joel+Cas

        if (this.props.user && this.props.user.basics.mongo_id) {
            const url = '/manage/do/page/email/write?r[0][id]=' + this.props.user.basics.mongo_id + '&r[0][name]=' + this.props.user.basics.name;
            return <a href={url} className="contact-user tidal-btn btn btn-primary lightbox-launcher">Contact</a>

        }

        return null;
    }


    renderButtons() {
        return (
            <div className="profile-buttons">
                {this.renderViewProfileBtn()}
                {this.renderContactBtn()}
            </div>
        )

    }

    renderProfileImages() {

        return <UserProfileImages
            user={this.props.user}
            onImageLoad={this.handleImageLoad}
        />




    }

    render() {
        const {user} = this.props;

        if (!user.profile) {
            return <div></div>
        }

        const wrapperStyle = {width: this.state.wrapperWidth + 'px'};

        return (

            <div className="profile">

                <div className="hscrollwrapper" style={wrapperStyle}>

                    <div className="left">
                        {this.renderProfileText()}
                    </div>

                    <div className="left">
                        {this.renderTable()}
                    </div>

                    <div className="right">
                        {this.renderProfileImages()}
                    </div>

                    {this.renderButtons()}

                </div>

            </div>

        );
    }
}

UserTableExtendedProfile.propTypes = {
    onClickOpenLightbox: PropTypes.func
};

const ConnectedUserTableExtendedProfile = connect()(UserTableExtendedProfile);

ConnectedUserTableExtendedProfile.propTypes = {
    onClickOpenLightbox: PropTypes.func
};

export default ConnectedUserTableExtendedProfile;
