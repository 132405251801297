import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../Common/Alert';
import Form from '../../Common/Form/Form';

class BulkUpdatePaymentOffer extends Component {

    static defaultProps = {
        isUpdating: false,
    };

    static propTypes = {
        onClickSubmit: PropTypes.func,
        activationIds: PropTypes.array.isRequired,
        isUpdating: PropTypes.bool,
    };

    state = {
        payment_amount: null
    };

    renderAlert() {
        const count = this.props.activationIds.length;
        const influencers = count === 1 ? 'user' : 'users';
        return [
            <Alert
                classes={['info']}
                content={<span>Set payment amount for {count} {influencers}. This tool sets all offers to the same amount.</span>}
            />
        ];
    }

    renderForm() {
        const {isUpdating} = this.props;
        const count = this.props.activationIds.length;
        const influencers = count === 1 ? 'User' : 'Users';

        return (
            <Form
                onFieldChange={(name, value) => this.setState({[name]: value})}
                values={{payment_amount: this.state.payment_amount}}
                fields={[
                    {
                        type: 'number',
                        name: 'payment_amount',
                        placeholder: 'eg: $100',
                        help: 'Enter the amount in US Dollars, without the dollar sign.',
                        title: 'Payment Offer'
                    },
                    {
                        type: 'button',
                        title: `Set Payment Offer for ${count} ${influencers}`,
                        options: {
                            classes: ['v3', 'large', 'btn-primary', (isUpdating ? 'disabled' : '')],
                            onClick: (isUpdating ? null : this.props.onClickSubmit.bind(null, this.state.payment_amount)),
                            hideTitle: true,
                        }
                    }
                ]}


            />

        );
    }

    render() {

        return (
            <div className="activation-status-change-form">
                {this.renderAlert()}
                {this.renderForm()}
            </div>
        );

    }
}

export default BulkUpdatePaymentOffer;
