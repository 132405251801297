import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toolbox from '../../Common/Toolbox';
import Checkbox from '../Checkbox';

class HamburgerMenu extends Component {

    static propTypes = {
        content: PropTypes.node.isRequired,
        onClose: PropTypes.func,
        title: PropTypes.string,
        onCheckboxClick: PropTypes.func,
        //this component can either receive a toggleMenu and expanded prop (managed by parent) or not (self-manage)
        toggleMenu: PropTypes.func,
        expanded: PropTypes.bool,
        icon: PropTypes.string,
    };

    static defaultProps = {
        onClose: () => { },
        title: 'Actions and Tools',
    };

    constructor(props) {
        super(props);
        this.state = {
            expanded: props.expanded || false,
            checked: false,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onClose = this.onClose.bind(this);
    };

    componentDidUpdate(prevProps) {
        if (this.props.expanded !== prevProps.expanded) {
            this.setState({
                expanded: this.props.expanded
            })
        }
    }

    toggleMenu() {
        if (this.props.toggleMenu) {
            return this.props.toggleMenu();
        } else {
            this.setState({
                expanded: !this.state.expanded,
            })
        }
    };

    onClick() {
        this.setState({
            checked: !this.state.checked,
        });
        this.props.onCheckboxClick();
    };

    onClose() {
        this.props.onClose();
        this.toggleMenu();
    };

    render() {
        const {title, content, onCheckboxClick} = this.props;
        const {expanded, checked} = this.state;

        return (
            <span className='detail-view hamburger-menu'>
            {
                onCheckboxClick && <Checkbox 
                    wrapperStyle={{marginRight: 15}}
                    checked={checked}
                    onClick={this.onClick}
                />
            }
                <a href='#' role='button' onClick={this.toggleMenu}>
                    <i className= {this.props.icon ? this.props.icon : (expanded ? 'v3 icon burger-menu active' : 'v3 icon burger-menu')}/>
                </a>
                {
                    expanded && <Toolbox
                        style={{ boxShadow: '0 10px 40px rgba(0,0,0,0.3)', width: 260 }}
                        onClose={this.onClose}
                        title={title}
                        content={content}
                    />
                }
            </span>
        )
    }
};

export default HamburgerMenu;