import React, {Component} from 'react';
import PropTypes from 'prop-types';

class GenericProfileSummaryBox extends Component {

    static propTypes = {
        profile: PropTypes.string,
        email: PropTypes.string,
        website: PropTypes.string,
        metadata: PropTypes.object,
    };

    getLiClassName(addl = '') {
        return "v3 h7 light fake-li condensed " + addl;
    }

    getProfileText() {
        return this.props.text || <em>This user does has not filled out their profile bio yet.</em>;
    }

    render() {
        return (
            <div className="fake-table generic-profile-summary-wrapper">
                <div className={this.getLiClassName('bold')}>About Me</div>
                <div className={this.getLiClassName()} style={{marginBottom: 5}}>{this.getProfileText()}</div>

                {
                    this.props.email && (
                        <div className={this.getLiClassName('bottom-border')}>
                            <span><strong>Email</strong></span>
                            <span className="pull-right">{this.props.email}</span>
                        </div>
                    )
                }

                {
                    (this.props.metadata && this.props.metadata.tier_name) && (
                        <div className={this.getLiClassName('bottom-border')}>
                            <span><strong>Tier</strong></span>
                            <span className="pull-right">{this.props.metadata.tier_name}</span>
                        </div>
                    )
                }

                {
                    this.props.website && (
                        <div className={this.getLiClassName('bottom-border')}>
                            <span><strong>Website</strong></span>
                            <span className="pull-right">
                                <a
                                    href={this.props.website}
                                    target="_blank"
                                    rel="noreferrer"
                                >{this.props.website}</a>
                            </span>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default GenericProfileSummaryBox;