/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatNumber} from '../../../utilities';
import ChartWrapper from "../../Chart/ChartWrapper";
import RechartsTimeseriesAreaChart from "../../Chart/RechartsTimeseriesAreaChart";

export default class CampaignROIOverTimeChart extends Component {

    getTotal() {
        const stats = this.props.stats || [];
        if (!stats || stats.length === 0) return null;
        return stats[stats.length - 1].roi;
    }

    renderChart() {
        const stats = this.props.stats || [];
        if (!stats || stats.length === 0) return null;
        const roiStrokeColor = this.props.colors ? this.props.colors[0] : '#206601';
        return (
            <RechartsTimeseriesAreaChart
                data={stats}
                seriesSettings={[
                    {
                        chartType: 'area',
                        dataKey: 'emv',
                        name: 'Value',
                        yAxisId: 'right',
                        stroke: '#DDDDDD',
                        fill: '#DDDDDD',
                        formatter: (value) => '$' + formatNumber(value)
                    },
                    {
                        chartType: 'area',
                        dataKey: 'cost',
                        name: 'Cost',
                        yAxisId: 'right',
                        stroke: '#BBBBBB',
                        fill: '#BBBBBB',
                        formatter: (value) => '$' + formatNumber(value)
                    },
                    {
                        chartType: 'line',
                        dataKey: 'roi',
                        name: 'Return',
                        stroke: roiStrokeColor,
                        strokeWidth: 2,
                        formatter: (value) => formatNumber(value, 1) + 'x'
                    }
                ]}
                axisSettings={[
                    {
                        axisType: 'x',
                        dataKey: 'date',
                        stroke: '#888',
                        interval: 0
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'left',
                        stroke: '#888'
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'right',
                        stroke: '#888',
                        orientation: 'right',
                        tickFormatter: (value) => '$' + formatNumber(value)
                    }
                ]}
            />
        )
    }


    render() {
        const total = this.getTotal();
        const titleBadge = total !== null ? `${formatNumber(total)}x return` : null;

        return (
            <ChartWrapper
                title="Cost and Return Over Time"
                titleBadge={titleBadge}
                icon="dollar-sign"
                chart={this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
            />
        )
    }

}

CampaignROIOverTimeChart.defaultProps = {
    stats: []
};

CampaignROIOverTimeChart.propTypes = {
    stats: PropTypes.array.isRequired,
};
