import {makeFormData} from "../utilities";
import {fetchAuthenticated} from "./auth";

import * as URI from 'urijs';

export const REQUEST_SOCIAL_LISTENER = 'REQUEST_SOCIAL_LISTENER';
export const RECEIVE_SOCIAL_LISTENER = 'RECEIVE_SOCIAL_LISTENER';

export const CREATE_SOCIAL_LISTENER = 'CREATE_SOCIAL_LISTENER';
export const CREATED_SOCIAL_LISTENER = 'CREATED_SOCIAL_LISTENER';

export const CREATE_SOCIAL_LISTENERS = 'CREATE_SOCIAL_LISTENERS';
export const CREATED_SOCIAL_LISTENERS = 'CREATED_SOCIAL_LISTENERS';

export const REQUEST_SOCIAL_LISTENERS = 'REQUEST_SOCIAL_LISTENERS';
export const RECEIVE_SOCIAL_LISTENERS = 'RECEIVE_SOCIAL_LISTENERS';

export const REQUEST_SOCIAL_LISTENER_POSTS = 'REQUEST_SOCIAL_LISTENER_POSTS';
export const RECEIVE_SOCIAL_LISTENER_POSTS = 'RECEIVE_SOCIAL_LISTENER_POSTS';

export const REQUEST_SOCIAL_LISTENER_SOCIALS = 'REQUEST_SOCIAL_LISTENER_SOCIALS';
export const RECEIVE_SOCIAL_LISTENER_SOCIALS = 'RECEIVE_SOCIAL_LISTENER_SOCIALS';

export const REQUEST_SOCIAL_LISTENER_REPORT = 'REQUEST_SOCIAL_LISTENER_REPORT';
export const RECEIVE_SOCIAL_LISTENER_REPORT = 'RECEIVE_SOCIAL_LISTENER_REPORT';

export const REQUEST_SOCIAL = 'REQUEST_SOCIAL';
export const RECEIVE_SOCIAL = 'RECEIVE_SOCIAL';

export const REQUEST_SOCIAL_POSTS = 'REQUEST_SOCIAL_POSTS';
export const RECEIVE_SOCIAL_POSTS = 'RECEIVE_SOCIAL_POSTS';

export const REQUEST_SOCIAL_REPORT = 'REQUEST_SOCIAL_REPORT';
export const RECEIVE_SOCIAL_REPORT = 'RECEIVE_SOCIAL_REPORT';

export const REQUEST_USER_SOCIALS = 'REQUEST_USER_SOCIALS';
export const RECEIVE_USER_SOCIALS = 'RECEIVE_USER_SOCIALS';

export const REFRESH_SOCIAL_LISTENER = 'REFRESH_SOCIAL_LISTENER';
export const REFRESHED_SOCIAL_LISTENER = 'REFRESHED_SOCIAL_LISTENER';

export function refreshSocialListener(listenerId) {
    return function (dispatch) {

        dispatch({
            type: REFRESH_SOCIAL_LISTENER,
            listenerId
        });

        const url = `/manage/api/sociallistener/${listenerId}/refresh`;

        return dispatch(fetchAuthenticated(url, {
            method: "POST"
        }))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: REFRESHED_SOCIAL_LISTENER,
                listenerId,
                listenerIds: json.data.updated
            }))
            .catch(err => {
                console.log("Error refreshing social listeners");
                console.log(err);
                return err;
            });

    }

}

export function shouldFetchSocialListenerReport(state, listenerId) {

    const listener = state.socialListenersById[listenerId];
    if (typeof listener === 'undefined') return true;

    if (listener.isFetchingReport || listener.lastUpdatedReport) {
        return false;
    } else {
        return true;
    }

}

export function fetchSocialListenerReportIfNeeded(listenerId) {
    return (dispatch, getState) => {
        if (shouldFetchSocialListenerReport(getState(), listenerId)) {
            return dispatch(fetchSocialListenerReport(listenerId));
        } else {
            return new Promise((resolve, reject) => resolve());
        }
    }
};

export function fetchSocialListenerReport(listenerId) {
    return function(dispatch) {
        dispatch({
            type: REQUEST_SOCIAL_LISTENER_REPORT,
            listenerId
        });

        const url = `/manage/api/sociallistener/${listenerId}/report`;
        return dispatch(fetchAuthenticated(url ))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: RECEIVE_SOCIAL_LISTENER_REPORT,
                listenerId,
                receivedAt: new Date(),
                report: json.data
            }))
            .catch(err => {
                console.log("Error fetching listener report");
                console.log(err);
                return err;
            });
    }

}

export function createSocialListeners(name, queries = []) {
    return function (dispatch) {

        let promises = [];
        const tag = 'Group: ' + name;

        dispatch({
            type: CREATE_SOCIAL_LISTENERS,
            name,
            queries
        });

        queries.forEach(query => {
            promises.push( dispatch(createSocialListener(query, tag, name)) )
        });

        return Promise.all(promises).then(() => dispatch({
            type: CREATED_SOCIAL_LISTENERS,
            name,
            tag,
            queries
        }));
    }
}

export function createSocialListener(query, tag = null, name=null) {

    return function (dispatch) {

        dispatch({
            type: CREATE_SOCIAL_LISTENER,
            query,
            tag,
            name
        });

        const url = '/manage/api/sociallistener';
        const payload = {query, tag, name};

        return dispatch(fetchAuthenticated(url, {
            method: 'POST',
            body: makeFormData(payload)
        }))
            .then(response => response.json())
            .then(json => dispatch({
                type: CREATED_SOCIAL_LISTENER,
                query,
                tag,
                receivedAt: new Date(),
                listener: json.data,
                listenerId: json.data.id
            }))
            .catch(err => {
                console.log("Error creating social listener");
                console.log(err);
            });

    }
}

export function fetchSocialPostsIfNeeded(socialId) {
    return (dispatch, getState) => {
        if (shouldFetchSocialPosts(getState(), socialId)) {
            return dispatch(fetchSocialPosts(socialId))
        }
    }
}

export function shouldFetchSocialPosts(state, socialId) {

    const social = state.socialsById[socialId];
    if (typeof social === 'undefined') return false;

    if (social.isFetchingPosts || social.lastUpdatedPosts) {
        return false;
    } else {
        return true;
    }

}

export function receiveSocialPosts(json, socialId) {
    return {
        type: RECEIVE_SOCIAL_POSTS,
        posts: json.data,
        receivedAt: new Date(),
        socialId: socialId
    };
};

export function fetchSocialPosts(socialId) {
    return function (dispatch) {

        dispatch({
            type: REQUEST_SOCIAL_POSTS,
            socialId
        });

        return dispatch(fetchAuthenticated(`/manage/api/social/${socialId}/post`))
            .then(response => response.json())
            .then(json => dispatch(receiveSocialPosts(json, socialId)))
            .catch(err => {
                console.log("Error fetching social posts");
                console.log(err);
            });

    }
};



export function receiveSocialReport(json, socialId) {
    return {
        type: RECEIVE_SOCIAL_REPORT,
        report: json.data,
        receivedAt: new Date(),
        socialId: socialId
    };
}

export function fetchSocialReport(socialId) {
    return function (dispatch) {

        dispatch({
            type: REQUEST_SOCIAL_REPORT,
            socialId
        });

        return dispatch(fetchAuthenticated(`/manage/api/social/${socialId}/report`))
            .then(response => response.json())
            .then(json => dispatch(receiveSocialReport(json, socialId)))
            .catch(err => {
                console.log("Error fetching social report");
                console.log(err);
            });

    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchSocialReportIfNeeded(socialId) {
    return (dispatch, getState) => {
        if (shouldFetchSocialReport(getState(), socialId)) {
            return dispatch(fetchSocialReport(socialId))
        }
    }
}

export function shouldFetchSocialReport(state, socialId) {

    const social = state.socialsById[socialId];
    if (typeof social === 'undefined') return false;

    if (social.isFetchingReport || social.lastUpdatedReport) {
        return false;
    } else {
        return true;
    }

}
export function receiveUserSocials(json, userId) {
    return {
        type: RECEIVE_USER_SOCIALS,
        socials: json.data,
        receivedAt: new Date(),
        userId
    };
}

export function fetchUserSocials(userId) {
    return function (dispatch) {

        dispatch({
            type: REQUEST_USER_SOCIALS,
            userId
        });

        const slot = window.Gator.getDashboardSlot();
        return dispatch(fetchAuthenticated(`/${slot}/api/user/${userId}/social`))
            .then(response => response.json())
            .then(json => dispatch(receiveUserSocials(json, userId)))
            .catch(err => {
                console.log("Error fetching user socials")
                console.log(err)
            });

    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchUserSocialsIfNeeded(socialId) {
    return (dispatch, getState) => {
        if (shouldFetchUserSocials(getState(), socialId)) {
            return dispatch(fetchUserSocials(socialId))
        }
    }
}

export function shouldFetchUserSocials(state, socialId) {

    const social = state.socialsById[socialId];
    if (typeof social === 'undefined') return true;

    if (social.isFetchingSocials || social.lastUpdatedSocials) {
        return false;
    } else {
        return true;
    }

}


export function fetchSocialListenerSocials(listenerId, page = 1, filters = {}, sort = {}, limit = 12){
    const payload = {
        filters: JSON.stringify(filters),
        sort: JSON.stringify(sort),
        page,
        limit
    };

    let url = URI(`/manage/api/sociallistener/${listenerId}/social`)
        .query(payload)
        .toString();

    return function (dispatch) {
        dispatch(requestSocialListenerSocials(listenerId, page, filters, sort, limit));

        return dispatch(fetchAuthenticated(url))
            .then(response => response.json())
            .then(json => {
                if (json.meta && json.meta.listener) {
                    dispatch(receiveSocialListener({data: json.meta.listener}, listenerId));
                }
                return dispatch(receiveSocialListenerSocials(json, listenerId, page, filters, sort, limit))
            })
            .catch(err => {
                console.log("Error fetching");
                console.log(err);
            });
    }
}


export function receiveSocialListenerSocials(json, listenerId, page = 1, filters = {}, sort = {}, limit = 12) {
    return {
        type: RECEIVE_SOCIAL_LISTENER_SOCIALS,
        socials: json.data,
        meta: json.meta,
        receivedAt: Date.now(),
        page,
        sort,
        filters,
        limit,
        listenerId
    }
}

export function requestSocialListenerSocials(listenerId, page = 1, filters = {}, sort = {}, limit = 12) {
    return {
        type: REQUEST_SOCIAL_LISTENER_SOCIALS,
        page,
        filters,
        sort,
        limit,
        listenerId
    }
}

export function fetchSocialListenerPosts(listenerId, page = 1, filters = {}, sort = {}, limit = 12) {

    const payload = {
        filters: JSON.stringify(filters),
        sort: JSON.stringify(sort),
        page,
        limit
    };

    let url = URI(`/manage/api/sociallistener/${listenerId}/post`)
        .query(payload)
        .toString();

    return function (dispatch) {
        dispatch(requestSocialListenerPosts(listenerId, page, filters, sort, limit));

        return dispatch(fetchAuthenticated(url))
            .then(response => response.json())
            .then(json => {
                if (json.meta && json.meta.listener) {
                    dispatch(receiveSocialListener({data: json.meta.listener}, listenerId));
                }
                return dispatch(receiveSocialListenerPosts(json, listenerId, page, filters, sort, limit))
            })
            .catch(err => {
                console.log("Error fetching");
                console.log(err);
            });

    }
}

export function receiveSocialListenerPosts(json, listenerId, page = 1, filters = {}, sort = {}, limit = 12) {
    return {
        type: RECEIVE_SOCIAL_LISTENER_POSTS,
        posts: json.data,
        meta: json.meta,
        receivedAt: Date.now(),
        page,
        sort,
        filters,
        limit,
        listenerId
    }
}

export function requestSocialListenerPosts(listenerId, page = 1, filters = {}, sort = {}, limit = 12) {
    return {
        type: REQUEST_SOCIAL_LISTENER_POSTS,
        page,
        filters,
        sort,
        limit,
        listenerId
    }
}

export function fetchSocialListeners() {
    return function (dispatch) {
        dispatch(requestSocialListeners());

        return dispatch(fetchAuthenticated(`/manage/api/sociallistener?report=1`))
            .then(response => response.json())
            .then(json => dispatch(receiveSocialListeners(json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

export function requestSocialListeners() {
    return {
        type: REQUEST_SOCIAL_LISTENERS,
    };
}

export function receiveSocialListeners(json) {
    return {
        type: RECEIVE_SOCIAL_LISTENERS,
        listeners: json.data,
        receivedAt: Date.now(),
    }
}

export function requestSocialListener(listenerId) {
    return {
        type: REQUEST_SOCIAL_LISTENER,
        listenerId
    };
}

export function receiveSocialListener(json, listenerId) {
    return {
        type: RECEIVE_SOCIAL_LISTENER,
        listener: json.data,
        receivedAt: Date.now(),
        listenerId
    }
}

/**
 * @returns {Function}
 */
export function fetchSocialListener(listenerId) {
    return function (dispatch) {
        dispatch(requestSocialListener(listenerId))

        return dispatch(fetchAuthenticated(`/manage/api/sociallistener/${listenerId}?report=1`))
            .then(response => response.json())
            .then(json => dispatch(receiveSocialListener(json, listenerId)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

/**
 * @param state
 * @returns {*}
 */
export function shouldFetchSocialListener(state, listenerId) {
    const listener = state.socialListenersById[listenerId];
    if (typeof listener === 'undefined') return true;

    if (listener.didInvalidate === true) {
        return true;
    } else if (listener.isFetching || listener.lastUpdated) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchSocialListenerIfNeeded(listenerId) {
    return (dispatch, getState) => {
        if (shouldFetchSocialListener(getState(), listenerId)) {
            return dispatch(fetchSocialListener(listenerId))
        }
    }
}

