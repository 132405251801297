import React from 'react';
import Table from "../Table/Table";
import CheckboxCell from "../Table/Cell/Checkbox";

function AudienceStatsSettingsTable({settings, onChange}) {
    const handleClick = (key) => {
        onChange(key, !getValueFor(key));
    };
    const getValueFor = (key) => {
        return !!settings[key];
    };
    return (
        <Table
            showSort={false}
            columns={[

                {
                    key: 'checkbox',
                    title: 'Enable Audience Chart',
                    width: 250,
                    default: true,
                    sortable: false,
                    cell: (row, column) => {

                        return <CheckboxCell
                            wrapperStyle={{padding: 0, fontWeight: 'normal !important', color: '#555'}}
                            row={row}
                            column={column}
                            onClick={handleClick.bind(this, row.item.key)}
                            value={getValueFor(row.item.key)}
                            label={row.item.name}
                        />

                    }
                },
            ]}
            items={[
                {key: 'show_audience_gender', name: 'Audience Gender'},
                {key: 'show_audience_age', name: 'Audience Age'},
                {key: 'show_audience_credibility', name: 'Audience Credibility'},
                {key: 'show_audience_countries', name: 'Audience Countries'},
                {key: 'show_audience_cities', name: 'Audience Cities'},
                {key: 'show_audience_states', name: 'Audience States'},
                {key: 'show_audience_interests', name: 'Audience Interests'},
                {key: 'show_audience_brands', name: 'Audience Brands'},
                {key: 'show_audience_ethnicity', name: 'Audience Ethnicity'},
                {key: 'show_audience_language', name: 'Audience Language'},
            ]}
        />
    );
}
export default AudienceStatsSettingsTable;