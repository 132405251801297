import Image from "../../Common/Image";
import React, {useState} from "react";
import _get from 'lodash/get';
import {formatNumberK, getImageUrl} from "../../../utilities";

function PortfolioContentPosts({posts, printMode=false}) {
    const [blockedImgs, setBlockedImgs] = useState([]);
    const openUrl = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div className="posts-wrapper">
            {posts.map((post, index) => {
                const imageUrl = post.image;
                if (blockedImgs.indexOf(imageUrl) > -1) {
                    return null;
                }
                return (
                    <div className="post" onClick={() => openUrl(post.link)}>
                        <Image
                            shouldLoad={printMode}
                            useProxy={printMode}
                            asBackgroundImg={true}
                            key={index}
                            image={imageUrl}
                            onError={() => setBlockedImgs([...blockedImgs, imageUrl])}
                        />
                        <div className="post-meta">
                            <div className="post-title">{post.text}</div>
                            <div className="post-stats">
                                <div className="post-stat">
                                    <strong>{formatNumberK(post.stat.likes, 0)}</strong> Likes
                                </div>
                                <div className="post-stat">
                                    <strong>{formatNumberK(post.stat.comments, 0)}</strong> Comments
                                </div>
                            </div>

                        </div>
                    </div>
                );
            }).filter(o => !!o)}

        </div>
    )

}
function PortfolioContentImages({images, printMode=false}) {
    const [blockedImgs, setBlockedImgs] = useState([]);

    return (
        <div className="images-wrapper">
            {images.map((imageUrl, index) => {
                if (blockedImgs.indexOf(imageUrl) > -1) {
                    return null;
                }
                return (
                    <Image
                        shouldLoad={printMode}
                        useProxy={printMode}
                        asBackgroundImg={true}
                        key={index}
                        image={imageUrl}
                        onError={() => setBlockedImgs([...blockedImgs, imageUrl])}
                    />
                );
            }).filter(o => !!o)}
        </div>
    )
}
function PortfolioContent({item, list, printMode=false}) {
    const getSetting = (key, defaultValue) => _get(item, `settings.${key}`, _get(list, `settings.${key}`, defaultValue));
    let imageUrls = (item.profile.images || []).slice(0, 5);
    const topPosts = _get(item, 'socialdata.user_profile.top_posts', [])
        .filter(post => imageUrls.indexOf(post.image) === -1)
        .slice(0, 5);
    const showPortfolioImages = getSetting('show_portfolio_images', true);
    const showTopPosts = getSetting('show_top_posts', false);
    const customImages = getSetting("custom_images", null);
    if (customImages) {
        imageUrls = customImages.map(function (img) {
            return getImageUrl(img);
        });
    }

    return (
        <div className="content-box">
            {showPortfolioImages && (
                <>
                    <h5 className="v3 bold">Portfolio Images</h5>
                    <PortfolioContentImages images={imageUrls} printMode={printMode} />
                </>
            )}

            {(showTopPosts && topPosts.length > 0) && (
                <>
                    <h5 className="v3 bold ">Top Posts</h5>
                    <PortfolioContentPosts posts={topPosts} printMode={printMode} />
                </>
            )}
        </div>
    );
}


export default PortfolioContent