import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {makeQuery, makeFormData, preventDefaultIfPossible} from "../../../utilities";
import connect from "react-redux/es/connect/connect";
import {fetchPostStatsIfNeeded, fetchPost, featurePost, publishPost} from "../../../actions";
import { fetchSections } from '../../../actions/sections';
import * as ActionTypes from "../../../actions/types";
import { openUserLightbox } from "../../../actions/lightbox";
import { updatePostRating } from "../../../actions";
import Toolbox from '../../Common/Toolbox';
import Video from "../../Common/Video";
import PostDetailSidebar from './PostDetailSidebar';
import PublishOnCampaign from './PublishOnCampaign';
import ManageSections from './ManageSections';
import SchedulePost from './SchedulePost';
import {fetchAuthenticated} from '../../../actions/auth';
import { withRouter } from 'react-router-dom';
import Alert from "../../Common/Alert";
import ImageSlider from "./ImageSlider";
import PostDetailHeader from "./Header";
import PostDetailContent from "./Content";

class PostDetail extends Component {

    static propTypes = {
        postId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        onClose: PropTypes.func,
        handleNavigation: PropTypes.func,
        showImages: PropTypes.bool,
        fetchAuthenticated: PropTypes.func,
    };

    static defaultProps = {
        showImages: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            isFetchingPost: false,
            isFetchingCampaigns: false,
            tab: 'overview',
            popup: {},
            showMenu: false,
            isRunningAction: false,
            error: null,
        };
        this.togglePopup = this.togglePopup.bind(this);
    }

    componentDidMount() {
        this.fetchPostInfo();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.postId !== this.props.postId) {
            this.fetchPostInfo();
        }
    }

    fetchPostInfo() {
        this.setState({ 
            isFetchingPost: true,
            isFetchingCampaigns: true,
            dead: {},
        });

        this.props.fetchPost(this.props.postId)
            .then(() => this.setState({isFetchingPost: false}))
            .then(() => this.getCampaignList())
            .then(() => this.setState({isFetchingCampaigns: false}))
            .then(() => this.props.fetchStats(this.props.postId))
            .then(() => this.props.fetchSections())
            .catch(err => console.error(err));
    };


    getCampaignList() {
        const {post, campaigns} = this.props || {};
        const {published_campaigns, submitted_campaigns, notpublished_campaigns, draft_campaigns} = post || [];
        const {isFetchingCampaigns} = this.state;

        if (isFetchingCampaigns) return null;

        return Object.keys(campaigns || {})
            .map(id => {
                id = Number(id);
                const {name, slug} = campaigns[id];
                let status = '-';

                if (published_campaigns && published_campaigns.indexOf(id) > -1) {
                    status = 'Published'
                } else if (submitted_campaigns && submitted_campaigns.indexOf(id) > -1) {
                    status = 'Submitted'
                } else if (notpublished_campaigns && notpublished_campaigns.indexOf(id) > -1) {
                    status = 'Not Published'
                } else if (draft_campaigns && draft_campaigns.indexOf(id) > -1) {
                    status = 'Drafted'
                }

                return {
                    campaignName: name,
                    campaignSlug: slug,
                    campaignId: id, 
                    status
                };
        });
    };

    handleRatingChange(rating) {
        this.props.ratePost(this.props.post.id, rating)
            .then(() => { })
            .catch((err) => console.err);
    };

    handlePublishCampaign = (campaign_slug, type) => {
        const params = {
            post_id: (this.props.post || {}).id,
            campaign_slug,
            status: type === 'add_post_to_campaign' ? 'Published' : null
        };
        const slot = window.Gator.getDashboardSlot();
        const query = makeQuery(params);
        const url = `/${slot}/do/widget/tools/${type}?${query}`;

        return this.props.fetchAuthenticated(url, {method: 'post'})
            .catch(err => console.error)
    };

    hasOverridePermission() {
        const {user, channel} = this.props;
        const {roles} = user || [];
        return !!(roles.indexOf('override') > -1 && !!channel.override);
    };

    async handlePublishChannel(shouldPublish) {
        try {
            const resp = await this.props.publishPost(this.props.post.id, shouldPublish);
        } catch (err) {
            this.setState({error: err});
        }
        await this.fetchPostInfo();
    }

    async handleFeature(shouldFeature) {
        try {
            const resp = await this.props.featurePost(this.props.postId, shouldFeature);
        } catch (error) {
            this.setState({error});
        }
        await this.fetchPostInfo();
    }

    async handleEditSection(sectionId, shouldAdd) {
        const {post, fetchAuthenticated} = this.props;
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/post/${post.id}/section/${sectionId}`;
        const opts = shouldAdd === true
            ? {method: 'POST', body: makeFormData({_method: 'PUT'})}
            : {method: 'POST', body: makeFormData({_method: 'DELETE'})};
    
        const resp = await fetchAuthenticated(url, opts);
        const json = await resp.json();

        if (json && json.meta && json.meta.error) {
            this.setState({error: json.meta.error});
        } else {
            await this.fetchPostInfo();
        }
    };

    handleRejectCampaign = (campaignSlug) => {
        //change campaign status from 'submitted' to 'notpublished'
        const {post, fetchAuthenticated, fetchPost} = this.props;
        const postSlug = post.slug;
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/do/widget/campaign/reject_post?campaign=${campaignSlug}&slug=${postSlug}`;
        const params = {
            method: 'POST',
            body: makeFormData({
                method: 'post',
                _method: 'post'
            })
        };

        if (!campaignSlug || !postSlug) return;

        fetchAuthenticated(url, params)
            .then(res => this.togglePopup({}))
            .then(() => fetchPost(this.props.postId))
            .catch(err => console.error)
    };

    togglePopup(spec) {

        if (!spec || !spec.name || spec.name === (this.state.popup || {}).name) {
            this.setState({popup: {}});
        } else {
            this.setState({
                popup: spec,
                showMenu: false
            });
        }
    };



    renderPopup() {
        const {title, name} = this.state.popup;
        const {post, sections, postId, fetchPost, fetchAuthenticated} = this.props;
        let content;

        if (!title || !name) return;

        switch (name) {
            case 'publishCampaign':
                content = <PublishOnCampaign
                    postId = {postId}
                    campaignList = {this.getCampaignList()}
                    onComplete = {() => fetchPost(postId)}
                    publish={this.handlePublishCampaign.bind(this)}
                />;
                break;
            case 'manageSections':
                content = <ManageSections
                    postSlug = {post.slug}
                    allSections = {sections}
                    postSections = {post.sections}
                    onComplete = {() => fetchPost(postId)}
                    fetchAuthenticated={fetchAuthenticated}
                    onEditSection={this.handleEditSection.bind(this)}
                />;
                break;
            case 'schedule':
                content = <SchedulePost 
                    postId = {postId}
                    allSections = {sections}
                    postSections= {post.sections}
                    status={post['is_scheduled']}
                    fetchAuthenticated={fetchAuthenticated}
                    onComplete={()=> {
                        fetchPost(postId)
                        return setTimeout(() => this.setState({
                            popup: {}
                        }), 1000)
                    }}
                />
                break;
        }

        if (!content) {
            return null;
        }

        return <Toolbox
            title={title}
            addlClasses={'detail-menu-popup'}
            onClose={() => this.togglePopup({})}
            content={content}
        />
    };

    async handleBump() {
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/do/widget/posts/api_put_ordinality`;
        const params = {
            method: 'POST',
            body: makeFormData({
                post_id: this.props.post.id,
                ordinality: 'top'
            })
        };

        const resp = await this.props.fetchAuthenticated(url, params);
        const json = await resp.json();
        await this.fetchPostInfo();

    }

    async handleMarkSpam () {
        const slot = window.Gator.getDashboardSlot();
        const postId = this.props.post.id;
        const url = `/${slot}/api/post/${postId}/spam`;
        const resp = await this.props.fetchAuthenticated(url, { method: 'POST' });
        const json = await resp.json();
        await this.fetchPostInfo();
    }

    handleOpenUser(event = undefined) {
        preventDefaultIfPossible(event);
        const {post} = this.props;
        const {user} = post || {};
        const {id} = user || '';

        this.props.openUserLightbox(id);
        this.props.onClose();
    };


    renderHeader() {
        return <PostDetailHeader
            post={this.props.post}
            hasOverridePermission={this.hasOverridePermission()}
            sections={this.props.sections}
            onToggleItem={this.togglePopup.bind(this)}
            onToggleMenu={() => this.setState({showMenu: !this.state.showMenu})}
            showMenu={this.state.showMenu}
            isRunningAction={this.state.isRunningAction}
            onActionStarted={() => this.setState({isRunningAction: true})}
            onActionFinished={() => this.setState({isRunningAction: false})}
            onOpenUser={this.handleOpenUser.bind(this)}

            onFeature={this.handleFeature.bind(this)}
            onPublishChannel={this.handlePublishChannel.bind(this)}
            onMarkSpam={this.handleMarkSpam.bind(this)}
            onBump={this.handleBump.bind(this)}

            onClose={this.props.onClose}
            handleNavigation={this.props.handleNavigation}
        />
    };


    renderImageSlider() {
        if (!this.props.showImages) {
            return;
        }

        const images = (this.props.post || {}).images || [];
        if (!images || images.length === 0) return;

        return <ImageSlider images={images} />;

    };

    renderPostContent() {
        return <PostDetailContent post={this.props.post} />
    };

    renderOverviewContent() {
        const {postId, post, fetchPost, sections} = this.props;

        return <section className='clearfix row post-detail-overview-content'>
            <aside className='col-md-4 col-md-push-8'>
                <PostDetailSidebar
                    onOpenUser={this.handleOpenUser.bind(this)}
                    post={post}
                    campaigns={this.getCampaignList()}
                    sections={sections}
                    onComplete = {() => fetchPost(postId)}
                    onPublishChannel={this.handlePublishChannel.bind(this)}
                    onPublishCampaign={this.handlePublishCampaign.bind(this)}
                    onFeature={this.handleFeature.bind(this)}
                    onEditSection={this.handleEditSection.bind(this)}
                    onRejectCampaign={this.handleRejectCampaign.bind(this)}
                    hasOverridePermission={this.hasOverridePermission()}
                    onBump={this.handleBump.bind(this)}
                />
            </aside>
            {this.renderPostContent()}
        </section>;
    }

    renderTabContent() {
        let content;

        switch (this.state.tab) {
            case 'overview':
                content = <>
                    {this.renderImageSlider()}
                    {this.renderOverviewContent()}
                </>;
                break;
            default: break;
        }

        return <div className='post-detail-content'>{content}</div>;
    };

    renderError() {
        if (!this.state.error) {
            return null;
        }
        return (
            <Alert classes={['danger']} content={this.state.error} onClose={() => this.setState({error: null})} />
        );
    }

    render() {
        const {post} = this.props;
        const slug = typeof post !== 'undefined' ? post.slug : '';

        return (
            this.props.post
                ? (
                    <div className='post-detail-wrapper'>
                        {this.renderError()}
                        {this.renderHeader()}


                        {this.renderTabContent()}
                        {this.renderPopup()}
                        <Alert
                            classes={['info']}
                            content={<span>Problems with this new interface? Use the <a className="bold" href={"/manage/do/page/posts/view?slug=" + slug}>old post detail view</a> instead.</span>}
                        />
                    </div>
                )
                : <div className='alert tidal-alert alert-grey'>Loading...</div>
        )
    };
}

const mapStateToProps = (state, ownProps) => {
    const { postId } = ownProps;

    return {
        post: state.postsById[postId],
        campaigns: state.campaignsById.items,
        sections: state.sections.items,
        channel: state.presence.channel,
        user: state.presence.user,
    }
};

const mapDispatchToProps = (dispatch) => {

    return {
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
        fetchStats: (postId) => dispatch(fetchPostStatsIfNeeded(postId)),
        fetchSections: () => dispatch(fetchSections()),
        fetchPost: (postId) => dispatch(fetchPost(postId)),
        ratePost: (postId, rating) => dispatch(updatePostRating(postId, rating, ActionTypes.UPDATE_POST_RATING)),
        openUserLightbox: (id, ctx, stack) => dispatch(openUserLightbox(id, ctx, stack)),
        featurePost: (id, featured) => dispatch(featurePost(id, featured)),
        publishPost: (id, publish) => dispatch(publishPost(id, publish)),
    }
};

const ConnectedPostDetail = connect(mapStateToProps, mapDispatchToProps)(PostDetail);

ConnectedPostDetail.propTypes = {
    postId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    handleNavigation: PropTypes.func,
    post: PropTypes.object,
    channel: PropTypes.object,
    user: PropTypes.object,
    campaigns: PropTypes.object,
    sections: PropTypes.object,
    fetchAuthenticated: PropTypes.func,
    fetchStats: PropTypes.func,
    fetchPost: PropTypes.func,
    ratePost: PropTypes.func,
    openUserLightbox: PropTypes.func,
};

export default withRouter(ConnectedPostDetail);