import React, {Component} from 'react';
import PropTypes from 'prop-types';
import VideoTile from '../Pieces/VideoTile';

import {fetchAuthenticated} from "../../../../utilities";
import Alert from "../../../Common/Alert";

class SubmitInstastoryVideo extends Component {

    static propTypes = {
        isUploading: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        refreshActivation: PropTypes.func.isRequired,
        videosRequired: PropTypes.number.isRequired,
        videoType: PropTypes.string,
    };

    state = {
        videos: [],
        error: null,
        videosRequired: 0,
        videoType: 'Instastory'
    };

    /**
     * Render the body widget for uploading videos
     *
     * @returns {XML}
     */
    renderBody() {

        return (
            <div>
                {this.renderUploader()}
                {this.renderVideos()}
            </div>
        );

    }

    /**
     * Render video previews
     *
     * @returns {XML}
     */
    renderVideos() {

        const videos = this.state.videos || [];
        return <div className="clearfix videos-container">{videos.map(this.renderVideo.bind(this))}</div>
    }

    /**
     * Return a single video component used to render video previews
     *
     *
     * @see renderVideos()
     * @param video
     * @returns {XML}
     */
    renderVideo(video) {
        return (
            <VideoTile
                video={video}
            />
        );
    }

    /**
     * Upload a video to the API
     *
     * Updates the status to show an in-progress indicator while uploading is happening
     *
     * Upon completion, we call the handleSubmit function which is passed into the Component and bound to the parent
     * and is also responsible for updating the parent's state with new IDs
     *
     * @see SubmitInstastoryImage->handleVideoSubmit()
     * @param event
     */
    handleUpload(event) {

        const formData = new FormData();
        formData.append('video', event.target.files[0]);
        formData.append('user_id', this.props.activation.user_id);

        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/video`;

        const options = {
            method: 'POST',
            body: formData,
            enctype: 'multipart/form-data'
        };

        this.props.isUploading(true); //Call method to update parent state

        // Reset any errors
        this.setState({error: null});

        return fetchAuthenticated(url, options)
            .then(res => res.json())
            .then(res => {
                if (res.meta && res.meta.error) {
                    this.setState({error: res.meta.error});
                } else {
                    this.props.onSubmit(res);
                    const {videos} = this.state;
                    const newVideos = [...videos, res.data];
                    this.setState({videos: newVideos});
                }
            });

    }

    handleRemove(video) {
        // @todo
        return;
        if (this.state.isRemoving) {
            return null;
        }

        this.setState({isRemoving: true});
        const {activation} = this.props;
        const payload = {video_id: video.id, action: 'remove'};
        return this.props.createInteraction(activation.id, 'UploadVideo', payload)
            .then(() => this.props.refreshActivation(activation.id))
            .then(() => this.setState({isRemoving: false}));

    }

    renderUploader() {

        return (

            <div className="form-group">
                <label><strong>Choose a Video to Upload</strong></label>
                <input type='file'
                       className='form-control'
                       onChange={this.handleUpload.bind(this)}
                />
                <p className="help-block">Upload an MP4 video.</p>
            </div>

        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return <Alert
            classes={['danger']}
            content={this.state.error}
            style={{marginTop: 15, marginBottom: 15}}
        />;
    }

    render() {
        return (
            <div className="">
                <h5 className="v3 bold action-pane-title">Add Videos</h5>
                <p className="v3 light h7 pane-info">
                    {`Upload ${this.props.videosRequired} videos of your ${this.props.videoType}. You can download the video version of your post from the ${this.props.videoType} app.`}
                </p>
                {this.renderError()}
                {this.renderBody()}
            </div>
        );
    }
}

export default SubmitInstastoryVideo;
