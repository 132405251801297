import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ActivationROITable from './Table';
import {average, formatDollar, formatNumber} from '../../../../utilities';
import {calculateCost, calculateEmv, calculateRoi, EMV_TIDAL_DEFAULTS} from "../../../../utilities/emv";


export default class CampaignActivationROITableWrapper extends Component {

    constructor(props) {
        super(props);

        // Init state to all activations
        this.state = {
            currentFilter: 'all'
        };

        this.handleChangeFilter = this.handleChangeFilter.bind(this);
    }

    getAvgRoi() {
        if (this.state.currentFilter === 'all' && this.props.roi) {
            return this.props.roi;
        }

        const activations = this.filterActivations(this.state.currentFilter);
        if (activations.length === 0) return 0;
        const rois = activations.map(a => parseFloat(a.stats[0].roi));
        return average(rois);
    };

    getAvgCpe() {
        const activations = this.filterActivations(this.state.currentFilter);
        if (activations.length === 0) return 0;

        let totalEngagements = 0;
        let totalCost = 0;

        for (let activation of activations) {
            totalEngagements += parseInt(activation.stats[0].total_engagements, 10);
            totalCost += parseFloat(activation.stats[0].cost);
        }

        const cpe = totalEngagements ? (totalCost / totalEngagements) : 0;
        return cpe;

    }

    filterActivations(filterType) {

        return this.props.activations
            // validity filter
            .filter(a => {
                return a.user && a.user.basics && a.user.basics.slug && a.stats.length > 0;
            })
            // Tier filter
            .filter((a) => {
                if (filterType === 'all') return true;
                const thisTier = a.user.basics.tier.replace(/\s.*/, '');
                return thisTier === filterType;
            })
            // Apply Custom EMV/ROI
            .map(a => {
                const stats = (a.stats || []).slice().map(stat => {
                    const engagements = stat.total_engagements;
                    const reach = stat.total_reach;
                    const cost = stat.cost;
                    const cpe = engagements ? (cost / engagements) : 0;
                    const cpmr = reach ? (1000 * (cost / reach)) : 0;
                    return {
                        ...stat,
                        cpe: stat.cpe || cpe,
                        cpmr: stat.cpmr || cpmr
                    };
                });
                return {...a, stats: stats};
            })
            .filter(a => {
                const engagements = ((a.stats || [])[0] || {}).total_engagements || 0;
                if (!engagements) {
                    return false;
                }
                return true;
            })
            // Sort by cpe
            .sort((a, b) => {
                return parseFloat(b.stats[0].cpe) > parseFloat(a.stats[0].cpe) ? -1 : 1;
            })
            ;

    }


    handleChangeFilter(filterType, event) {
        event.preventDefault();

        console.log("Changing filter to " + filterType);

        this.setState({
            currentFilter: filterType,
        });


        return false;
    }

    renderTierCount(filterType) {
        let tierCount = this.filterActivations(filterType).length;
        return (tierCount);
    }

    renderFilterRow() {

        const tagStyle = {
            fontSize: '12px',
            height: '40px',
            padding: '0 20px',
            lineHeight: '40px',
            background: '#f6f6f6',
            marginRight: 0
        };

        const avgCpe = this.getAvgCpe();
        return (
            <div style={{background: '#f6f6f6'}}>
                <div className="row">
                    <div className="col-xs-8">
                        <ul className="nav nav-pills tidal-nav-pills">

                            <li
                                role="presentation"
                                className={this.state.currentFilter === 'all' ? 'active' : ''}
                            >
                                <a
                                    href="#"
                                    onClick={(e) => this.handleChangeFilter('all', e)}
                                    style={{paddingLeft: 20}}
                                >All <span
                                    className="tier-count">({this.renderTierCount('all')})</span></a>
                            </li>

                            <li
                                role="presentation"
                                className={this.state.currentFilter === 'Up-and-Comer' ? 'active' : ''}
                            >
                                <a href="#" onClick={(e) => this.handleChangeFilter('Up-and-Comer', e)}>Up-and-Comers <span className="tier-count">({this.renderTierCount('Up-and-Comer')})</span></a>
                            </li>

                            <li
                                role="presentation"
                                className={this.state.currentFilter === 'Prosumer' ? 'active' : ''}
                            >
                                <a href="#" onClick={(e) => this.handleChangeFilter('Prosumer', e)}>Prosumers <span
                                    className="tier-count">({this.renderTierCount('Prosumer')})</span></a>
                            </li>

                            <li
                                role="presentation"
                                className={this.state.currentFilter === 'Maven' ? 'active' : ''}
                            >
                                <a href="#" onClick={(e) => this.handleChangeFilter('Maven', e)}>Mavens <span
                                    className="tier-count">({this.renderTierCount('Maven')})</span></a>
                            </li>

                            <li
                                role="presentation"
                                className={this.state.currentFilter === 'Celeb' ? 'active' : ''}
                            >
                                <a href="#" onClick={(e) => this.handleChangeFilter('Celeb', e)}>Celebs <span
                                    className="tier-count">({this.renderTierCount('Celeb')})</span></a>
                            </li>


                        </ul>
                    </div>
                    <div className="col-xs-4">
                        {
                            avgCpe ?
                                (
                                    <div
                                        className="pull-right tag"
                                        style={tagStyle}
                                    >
                                        <strong>Average CPE: {formatDollar(avgCpe, 2)}</strong>
                                        <i className="fa fa-question-circle"
                                           style={{marginLeft: 5, color: "#AAA"}}
                                           data-tooltip="The average cost per engagement across all creators."></i>
                                    </div>
                                ) : ''
                        }
                    </div>
                </div>
            </div>
        );

    }

    render() {

        return (
            <div className="widget v2">
                <div className="title nobottommargin">
                    <div className="left icon"><i className="fa fa-dollar"></i></div>
                    <h2>Cost per Engagement, by User</h2>
                </div>
                <div className="content nopadding">

                    {this.renderFilterRow()}

                    <ActivationROITable
                        activations={this.filterActivations(this.state.currentFilter)}
                    />
                </div>
            </div>
        );
    }
}

CampaignActivationROITableWrapper.defaultProps = {
    emvParams: EMV_TIDAL_DEFAULTS
}

CampaignActivationROITableWrapper.propTypes = {
    emvParams: PropTypes.object.isRequired
}
