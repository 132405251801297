import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Toolbox from '../Common/Toolbox';
import Button from '../Common/Form/Button';


export default class BulkActionsButton extends Component {


    static defaultProps = {
        classes: [],
        style: {},
        wrapperStyle: {},
    };

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        onClickInteraction: PropTypes.func,
        activationIds: PropTypes.array,
        classes: PropTypes.array,
        style: PropTypes.object,
        wrapperStyle: PropTypes.object,
    };

    state = {
        showDropdown: false,
    };

    componentDidUpdate(prevProps) {
        // The button can get stuck open if you unselect activations while it's open.
        // This prevents that
        if (this.state.showDropdown
            && (prevProps.activationIds || []).length > 0
            && (this.props.activationIds || []).length === 0
        ) {
            this.setState({showDropdown: false});
        }
    }

    handleClickInteraction(item) {
        this.setState({showDropdown: false});
        if (this.props.onClickInteraction) {
            this.props.onClickInteraction(item.key);
        }
    }

    getItems() {
        const isUsingPayments = !!((this.props.campaign || {}).settings || {}).use_payments && !(this.props.channel || {}).disable_payments;
        const isUsingProducts = !!((this.props.campaign || {}).settings || {}).use_products;

        return [
            isUsingPayments ? {
                key: 'UpdatePaymentOffer',
                title: 'Set Payment Amount for Selected'
            } : null,
            {
                key: 'Invite',
                title: 'Invite Selected',
            },
            {
                key: 'InviteReminder',
                title: 'Send Invitation Reminders',
            },
            {
                key: 'Waitlist',
                title: 'Waitlist Selected',
            },
            {
                key: 'AcceptAfterWaitlist',
                title: 'Accept Selected',
            },
            {
                key: 'SendEmail',
                title: 'Email Selected'
            },
            {
                key: 'Cancel',
                title: 'Cancel Selected'
            },
            {
                key: 'Delete',
                title: 'Permanently Remove Selected'
            },
            isUsingProducts ? {
                key: 'ShipProduct',
                title: 'Mark Selected as Shipped'
            } : null,
            {
                key: 'Reactivate',
                title: 'Add Selected to Another Campaign'
            },
            isUsingPayments ? {
                key: 'GenerateInvoice',
                title: 'Approve Payments for Selected'
            } : null,
            isUsingPayments ? {
                key: 'PaymentSent',
                title: 'Mark Selected as Paid'
            } : null
        ].filter(item => !!item);
    }

    renderDropdown() {

        return (
            <Toolbox
                content={this.renderDropdownContent()}
                style={{
                    zIndex: 200
                }}
            />
        )

    }

    renderDropdownContent() {
        return this.getItems().map(item => {

            return (

                <div className="fake-li condensed">
                    <a
                        role="button"
                        onClick={this.handleClickInteraction.bind(this, item)}
                        className="v3 h7 light"
                        style={{fontSize: 14}}
                    >
                        {item.title}
                    </a>
                </div>

            );
        })
    }

    getTitle() {

        if (this.isValid()) {
            return `Actions (${this.props.activationIds.length})`;
        } else {
            return 'Actions';
        }

    }

    isValid() {
        return this.props.activationIds.length > 0;
    }

    render() {

        const isValid = this.isValid();

        return (


            <div className="bulk-btn-wrapper" style={this.props.wrapperStyle}>
                <Button
                    content={this.getTitle()}
                    onClick={isValid ? () => this.setState({showDropdown: !this.state.showDropdown}) : null}
                    classes={this.props.classes}
                    style={this.props.style}
                />
                {this.state.showDropdown ? this.renderDropdown() : null}
            </div>

        );

    }
}