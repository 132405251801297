import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Alert from "../../../Common/Alert";
import Form from "../../../Common/Form/Form";
import {getCurrentChannel} from "../../../../selectors/presence";

class AllianzProfileFields extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
        refreshUser: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        channel: PropTypes.object,

    };

    constructor(props) {
        super(props);

        this.state = {
            form: {},
            isUpdating: false,
            didUpdate: false,
            hasError: false,
            error: null,
        };
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
            hasError: false,
            error: null,
        });
    }

    handleClickSave() {
        this.setState({isUpdating: true, didUpdate: false});
        const context = {...this.state.form, channel_id: this.props.channel.id};

        this.props.createInteraction(this.props.user.id, 'UpdateProfileFields', context)
            .then(resp => {
                if (resp.meta.error) {
                    this.setState({isUpdating: false, hasError: true, error: resp.meta.error})
                } else {
                    this.props.refreshUser(this.props.user.id);
                    this.setState({isUpdating: false, didUpdate: true});
                    setTimeout(() => this.setState({form: {}, didUpdate: false}), 2000);
                }
            });
    }

    getFieldValues() {
        const {user} = this.props || {};
        const formValues = this.state.form;
        let nativeValues = {};

        for (let profileField of (user.profile || [])) {
            nativeValues[profileField.key] = profileField.value;
        }

        return {...nativeValues, ...formValues};
    }

    renderSaveAlert() {

        let style = {
            position: 'relative',
            top: 0,
            zIndex: 200,
            clear: 'both',
        };

        if (this.state.isUpdating) {
            return <Alert
                classes={['info', 'clearfix']} content={"Saving your changes..."} style={style}
            />
        }

        if (this.state.didUpdate) {
            return <Alert
                classes={['success', 'clearfix']} content={"Changes saved!"} style={style}
            />
        }

        if (this.state.hasError) {
            return <Alert
                classes={['danger', 'clearfix']} content={this.state.error} style={style}
            />
        }

        if (Object.keys(this.state.form).length > 0) {
            return (
                <Alert
                    classes={['info', 'clearfix']}
                    content={<span>You have unsaved changes. <a className="v3 bold" onClick={this.handleClickSave.bind(this)} role="button">Save now.</a></span>}
                    style={style}
                />
            );
        }
    }
    render() {

        return (
            <div className="pane-inner-content clearfix">
                {this.renderSaveAlert()}
                <Form
                    values={this.getFieldValues()}
                    onFieldChange={this.handleFieldChange.bind(this)}
                    fields={[
                        {
                            type: 'generic',
                            content: (<p style={{marginTop: 20}}>
                                <strong>
                                    What you make:
                                </strong>
                            </p>)
                        },
                        {
                            type: 'checkbox',
                            name: 'makesVideo',
                            title: 'Video',
                            wrapperStyle: {float: 'left', width: '50%', position: 'relative', zIndex: 99},
                            required: false,
                            options: {
                                groupStyle: {float: 'left', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'makesPhoto',
                            title: 'Photo',
                            required: false,
                            options: {
                                groupStyle: {float: 'right', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'makesAnimation',
                            title: 'Animation',
                            wrapperStyle: {float: 'left', width: '50%', position: 'relative', zIndex: 99},
                            required: false,
                            options: {
                                groupStyle: {float: 'left', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'makesIllustration',
                            title: 'Illustration',
                            required: false,
                            options: {
                                groupStyle: {float: 'right', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'makesMusic',
                            title: 'Music',
                            wrapperStyle: {float: 'left', width: '50%', position: 'relative', zIndex: 99},
                            required: false,
                            options: {
                                groupStyle: {float: 'left', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'makesArticles',
                            title: 'Articles',
                            required: false,
                            options: {
                                groupStyle: {float: 'right', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'generic',
                            content: (<p style={{marginTop: 20}}>
                                <strong>
                                    What Topics are you Interested in?
                                </strong>
                            </p>),
                            options: {groupStyle: {clear: 'both'}}
                        },
                        {
                            type: 'checkbox',
                            name: 'mobility',
                            title: 'Mobility',
                            wrapperStyle: {float: 'left', width: '50%', position: 'relative', zIndex: 99},
                            required: false,
                            options: {
                                groupStyle: {float: 'left', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'health',
                            title: 'Health',
                            required: false,
                            options: {
                                groupStyle: {float: 'right', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'lifeHacks',
                            title: 'Life Hacks',
                            wrapperStyle: {float: 'left', width: '50%', position: 'relative', zIndex: 99},
                            required: false,
                            options: {
                                groupStyle: {float: 'left', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'art',
                            title: 'Art',
                            required: false,
                            options: {
                                groupStyle: {float: 'right', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'textarea',
                            name: 'other',
                            placeholder: 'Other Topics',
                        },
                        {
                            type: 'generic',
                            content: (<p style={{marginTop: 20}}>
                                <strong>
                                    Your favorite social channels:
                                </strong>
                            </p>),
                            options: {groupStyle: {clear: 'both'}}
                        },
                        {
                            type: 'checkbox',
                            name: 'likesFacebook',
                            title: 'Facebook',
                            wrapperStyle: {float: 'left', width: '50%', position: 'relative', zIndex: 99},
                            required: false,
                            options: {
                                groupStyle: {float: 'left', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'likesInstagram',
                            title: 'Instagram',
                            required: false,
                            options: {
                                groupStyle: {float: 'right', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'likesSnapchat',
                            title: 'Snapchat',
                            wrapperStyle: {float: 'left', width: '50%', position: 'relative', zIndex: 99},
                            required: false,
                            options: {
                                groupStyle: {float: 'left', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'likesYoutube',
                            title: 'YouTube',
                            required: false,
                            options: {
                                groupStyle: {float: 'right', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'likesPinterest',
                            title: 'Pinterest',
                            wrapperStyle: {float: 'left', width: '50%', position: 'relative', zIndex: 99},
                            required: false,
                            options: {
                                groupStyle: {float: 'left', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                        {
                            type: 'checkbox',
                            name: 'likesTiktok',
                            title: 'Tik-Tok',
                            required: false,
                            options: {
                                groupStyle: {float: 'right', width: 'calc(50% - 10px)', whiteSpace: 'nowrap'}
                            }
                        },
                    ]}
                />
                {this.renderSaveAlert()}
            </div>
        );

    }
}

const mapStateToProps = (state, props) => {
    return {
        channel: getCurrentChannel(state, props),
    };
};
export default connect(mapStateToProps)(AllianzProfileFields);
