import React, {useEffect, useState} from 'react';
import {getCurrentUser} from "../../../selectors/presence";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../../actions/auth";
import {connect} from "react-redux";
import TrendingPostTile from "./TrendingPostTile";
import {translate} from "../../../actions/i18n";

function CampaignTopContentSection({campaign, fetchAuthenticated, translate}) {

    const [report, setReport] = useState(null);

    const fetchReport = () => {
            return fetchAuthenticated(`/contribute/api/campaign/${campaign.id}/report`)
                .then(resp => resp.json())
                .then(json => setReport(json.data));
    };

    useEffect(function() {
        if (campaign && campaign.id) {
            fetchReport().then(() => { });
        }
    }, [campaign]);

    if (!report || !report.posts || report.posts.length === 0) {
        return null;
    }

    const Posts = (report.posts || [])
        .slice(0, 12)
        .map(pr => {
            const engs = pr.post.engagements || 0;
            const reach = pr.followers_count || 0;
            const engPct = reach ? (engs/reach) : null;
            return <TrendingPostTile key={pr.id} post={pr.post} engPct={engPct} />
        });

    return (

        <div className="brief-top-content-wrapper generic-greybg-wrapper">
            <div className="page-title-wrapper briefs-title-wrapper">
                <h1 className="blue-indent">{translate('creator.brief.top_content.title', 'Top Content')}</h1>
            </div>

            <div className="brief-top-content ic-posts">
                {Posts}
            </div>
        </div>

    );

}

const mapStateToProps = (state, props) => {
    return {
        user: getCurrentUser(state, props)
    };
}
const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    fetchAuthenticated: fetchAuthenticated,
    translate: translate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CampaignTopContentSection);
