/**
 * Created by visgotti on 9/29/17.
 */

import React, {Component} from 'react';
import {Pagination} from 'react-bootstrap';
import PropTypes from 'prop-types';

class PostsPagination extends Component {
    constructor(props) {
        super(props);
    }

    handlePageClick = (pageNumber) => {
        this.props.changePage(pageNumber);
    };

    renderPagination() {
        const {page, hits, limit} = this.props;
        let totalPages = Math.ceil(hits / limit);

        return (
            <div className="centeredtext">
                <Pagination
                    bsClass="tidal-pagination"
                    items={totalPages}
                    activePage={page}
                    maxButtons={10}
                    next={true}
                    prev={true}
                    boundaryLinks={true}
                    onSelect={this.handlePageClick}
                />
            </div>
        )
    }

    render() {
        return(
            <div style={{textAlign: 'center'}}>
                { this.renderPagination() }
            </div>
        )
    }
}

PostsPagination.propTypes = {
    page: PropTypes.number,
    hits: PropTypes.number,
    limit: PropTypes.number,
    changePage: PropTypes.func,
    fetchPosts: PropTypes.func,
};

export default PostsPagination;