import React from 'react';
import PropTypes from 'prop-types';

const NavigationButtons = (({onClose, handleClickForward, handleClickBackward}) => {
    return <nav className='detail-view pull-right'>
            {
                handleClickBackward &&  <a onClick={handleClickBackward} href="#"><i className="fa fa-chevron-left" /></a>
            }
            {
                handleClickForward &&  <a onClick={handleClickForward} href="#"><i className="fa fa-chevron-right" /></a>
            }

            {
                onClose && <a onClick={onClose} href='#'><i className='fa fa-times'/></a>
            }
    </nav>
});

NavigationButtons.propTypes = {
    onClose: PropTypes.func,
    handleClickForward: PropTypes.func,
    handleClickBackward: PropTypes.func,
};

export default NavigationButtons;