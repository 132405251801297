import React, {Component} from 'react';
import C3Chart from 'react-c3js';
import d3 from 'd3';
import {ucwords, c3ColorPattern} from '../../../utilities';

export default class PostTypesChart extends Component {

    renderPostTypesDonut() {

        let postTypeCounts = {};
        for (let post of this.props.posts) {
            let type = post.type;
            if (!type) type = 'Regular Post';
            type = ucwords(type);
            if (typeof postTypeCounts[type] === 'undefined') {
                postTypeCounts[type] = 0;
            }
            postTypeCounts[type]++;
        }

        const tooltip = {
            format: {
                value: function (value, ratio, id) {
                    let format = d3.format(',');
                    let formatPercent = d3.format('.1%');
                    return format(value) + "  (" + formatPercent(ratio) + ")";
                }
            }
        };

        const data = {
            json: [postTypeCounts],
            keys: {
                value: Object.keys(postTypeCounts)
            },
            type: 'donut'
        };

        return (
            <div className="widget" style={{width: '350px', height: '360px', marginTop: 0, flex: 'none'}}>
                <div className="title">
                    <div className="icon left"><i className="fa fa-pie-chart"></i></div>
                    <h2>
                        Post Types
                        <span className="badge badge-darkgrey">
                            {this.props.posts.length} posts
                        </span>
                    </h2>
                </div>
                <div className="content">

                    <C3Chart
                        data={data}
                        donut={{title: "Post Types"}}
                        size={{width: 300, height: 280}}
                        color={c3ColorPattern}
                        tooltip={tooltip}
                        transition={{duration: 0}}
                    />

                </div>
            </div>
        );

    }

    render() {

        return this.renderPostTypesDonut();

    }
}
