import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from '../../Common/Toolbox';
import CampaignSettingsForm from './SettingsForm';
import {connect} from 'react-redux';
import {updateCampaignSettings} from '../../../actions/campaigns';
import {isMobile} from "../../../utilities";
import {getCurrentChannel} from "../../../selectors/presence";
import ConnectedMACCampaignSettingsForm from "../../MAC/Manage/CampaignSettingsForm";
import * as URI from 'urijs';

class CampaignSettingsToolbox extends Component {

    static defaultProps = {
        style: {},
    };

    state = {
        isMobile: isMobile(),
    };

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        style: PropTypes.object,
        channel: PropTypes.object,
        onClose: PropTypes.func,
        updateSettings: PropTypes.func,
    };

    handleSave(settings) {
        return this.props.updateSettings(this.props.campaign.id, settings);
    }

    renderContent() {

        const {channel, campaign} = this.props;
        const formProps = {
            channel,
            campaign,
            settings: (campaign || {}).settings || {},
            isMobile: this.state.isMobile,
            onSave: this.handleSave.bind(this)
        };

        // Use current URL query string to see if we should use custom mac thing
        const urlParams = URI(window.location.href).search(true);
        const isMacChannel = channel && channel.slug === 'mac';
        const customSettingsForm = urlParams.settingsForm || null;

        if (customSettingsForm === 'default') {
            return (
                <CampaignSettingsForm {...formProps} />
            );
        }

        if (isMacChannel || customSettingsForm === 'mac') {
            return (
                <ConnectedMACCampaignSettingsForm {...formProps} />
            );
        }

        return (
            <CampaignSettingsForm {...formProps} />
        );
    }

    render() {

        return (
            <Toolbox
                supportMobile={true}
                onClose={this.props.onClose}
                style={this.props.style}
                title={'Campaign Settings'}
                content={this.renderContent()}
            />
        );



    }

}

const mapStateToProps = (state, props) => {
    return {
        channel: getCurrentChannel(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateSettings: (id, settings) => dispatch(updateCampaignSettings(id, settings)),
    };
};

const ConnectedCampaignSettingsToolbox = connect(mapStateToProps, mapDispatchToProps)(CampaignSettingsToolbox);
ConnectedCampaignSettingsToolbox.propTypes = {
    campaign: PropTypes.object.isRequired,
    style: PropTypes.object,
    onClose: PropTypes.func,
};
export default ConnectedCampaignSettingsToolbox;
