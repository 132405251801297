import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from './Table';
import Header from './Header/Header';
import Pagination from './Pagination';
import Cards from "../Cards/Cards";

export default class TableWrapper extends Component {

    static defaultProps = {
        stickySortTabs: false,
        isRowSelected: () => false,
        isRowExpanded: () => false,
        showHeader: true,
        showPagination: true,
        showLoadingAlert: false,
        showTotals: false,
        showFilters: false,
        showSort: true,
        blurry: false,
        filters: {},
        viewAsCards: false,
        innerClasses: [],
        wrapperClasses: [],
    };

    static propTypes = {

        innerClasses: PropTypes.array,
        cardRenderer: PropTypes.func,
        viewAsCards: PropTypes.bool,
        tabs: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.node,
            isActive: PropTypes.bool,
            onClick: PropTypes.func,
            classes: PropTypes.array,
            style: PropTypes.object,
            badge: PropTypes.node,
        })),
        filters: PropTypes.object,
        blurry: PropTypes.bool,
        showHeader: PropTypes.bool,
        showTotals: PropTypes.bool,
        showFilters: PropTypes.bool,
        showSort: PropTypes.bool,
        showLoadingAlert: PropTypes.bool,
        showPagination: PropTypes.bool,
        banner: PropTypes.node,
        popups: PropTypes.arrayOf(PropTypes.node),
        buttons: PropTypes.arrayOf(PropTypes.node),
        wrapperClasses: PropTypes.array,

        /** Specify action icons for the top-right */
        switches: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            icon: PropTypes.node,
            tooltip: PropTypes.string,
            isActive: PropTypes.bool,
            /**
             * Either 'left' or 'right', default 'right'. Should really only have one for 'left'
             */
            spot: PropTypes.string,
            /**
             * Called when user clicks a switch; we attempt to bind the callback
             * @param {{name: string, icon: string, tooltip: string, isActive: boolean}} spec
             */
            onClick: PropTypes.func,
        })),

        /** An icon for the top-left of the header */
        headerIcon: Header.propTypes.icon,

        /** Current page */
        page: PropTypes.number,

        /** Max pages available */
        pages: PropTypes.number,

        /**
         * Called when a filter is updated
         */
        onFilterChange: PropTypes.func,

        /**
         * Called when a new page number is clicked
         */
        onPageChange: PropTypes.func,

        /** String or function for the title */
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
        ]),

        /** Array of items to render. */
        items: PropTypes.array.isRequired,

        /** Column spec. */
        columns: PropTypes.array,

        /** Array of columns (by key) that should be made visible */
        visibleColumns: PropTypes.array,

        /** Whether sort tabs should stick to top of the page on scroll */
        stickySortTabs: PropTypes.bool,

        /**
         * Called to check whether the row should be selected. Return boolean.
         * @param {Object} row
         */
        isRowSelected: PropTypes.func,
        /**
         * Called to check whether the row should be expanded. Return boolean.
         * @param {Object} row
         */
        isRowExpanded: PropTypes.func,

        /**
         * Called in order to generate the row expansion
         * @param {Object} row
         */
        expander: PropTypes.func,

        /**
         * An object representing the current sort
         * @property {{by: string, dir: string}}
         */
        sort: PropTypes.object,

        /**
         * Called when the sort has changed.
         * @param {{by: string, dir: string}}
         */
        onSortChange: PropTypes.func,

        /**
         * Called when a row's visibility has changed
         * @param {{item: Object, index: Number, isVisible: Boolean, wasEverVisible: Boolean}}
         */
        onRowVisibilityChange: PropTypes.func,

        useDragDrop: PropTypes.bool,
        onRowDrop: PropTypes.func,

    };

    constructor(props) {
        super(props);
        this.state = { };
    }

    renderPopups() {
        if (this.props.popups) {
            return this.props.popups;
        }
    }

    renderBanner() {
        if (this.props.banner) {
            return (
                <div className="godzilla-banner">
                    {this.props.banner}
                </div>
            );
        }
    }

    renderContent() {
        if (this.props.viewAsCards) {
            return <Cards
                items={this.props.items}
                renderer={this.props.cardRenderer}
                classes={this.props.innerClasses}
                blurry={this.props.blurry}
            />;
        }

        return <Table
            items={this.props.items}
            columns={this.props.columns}
            visibleColumns={this.props.visibleColumns}
            stickySortTabs={this.props.stickySortTabs}
            isRowExpanded={this.props.isRowExpanded}
            isRowSelected={this.props.isRowSelected}
            expander={this.props.expander}
            sort={this.props.sort}
            onSortChange={this.props.onSortChange}
            showLoadingAlert={this.props.showLoadingAlert}
            showTotals={this.props.showTotals}
            showFilters={this.props.showFilters}
            showSort={this.props.showSort}
            blurry={this.props.blurry}
            filters={this.props.filters}
            onFilterChange={this.props.onFilterChange}
            tabs={this.props.tabs}
            onRowVisibilityChange={this.props.onRowVisibilityChange}
            useDragDrop={this.props.useDragDrop}
            onRowDrop={this.props.onRowDrop}

            // Page and pages are only supplied to table because of drag/drop pagination targets
            page={this.props.page}
            pages={this.props.pages}
        />;
    }

    render() {

        let classes = ['godzilla-wrapper', this.props.viewAsCards ? 'godzilla-cards-wrapper' : '', ...this.props.wrapperClasses];


        return (
            <div className={classes.join(' ')}>

                {this.props.showHeader &&
                    <Header
                        title={this.props.title}
                        icon={this.props.headerIcon}
                        switches={this.props.switches}
                        buttons={this.props.buttons}
                    />
                }

                {this.renderBanner()}
                {this.renderContent()}

                {this.props.showPagination &&
                    <Pagination
                        page={this.props.page}
                        pages={this.props.pages}
                        onPageChange={this.props.onPageChange}
                    />
                }

                {this.renderPopups()}

            </div>

        );
    }


}

