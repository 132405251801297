import React from 'react';
import Tooltip from "../../Common/Tooltip";

const INNER_RULES = {
    "Creator Account": ['has_valid_email', 'is_creator_account'],
    "Connected Blog": ['has_any_blog', 'has_valid_blog'],
    "Basic Instagram Scraping": ['has_any_instagram'],
    "Connected Instagram Business": ['has_any_instagram_business', 'has_valid_instagram_business'],
    "Connected TikTok": ['has_any_tiktok', 'has_valid_tiktok'],
    "Connected Facebook Page": ['has_any_facebook_page', 'has_valid_facebook_page'],
    "Connected YouTube": ['has_any_youtube', 'has_valid_youtube'],
};

function InnerTable({status}) {

    const items = [];
    const keys = Object.keys(INNER_RULES);
    for (const index in keys) {
        const title = keys[index];
        const rules = INNER_RULES[title];
        const score = rules.map(rule => (status || {})[rule] ? 1 : 0).reduce((prev, curr) => prev+curr, 0);
        let iconClass = '';
        if (score === rules.length) {
            iconClass = 'v3 signal success';
        } else if (score === 0) {
            iconClass = 'v3 signal danger';
        } else {
            iconClass = 'v3 signal warning';
        }
        const icon = <i className={iconClass} />;
        items.push(
            <div
                className={"fake-li condensed " + (index < keys.length - 1 ? 'bottom-border' : '')}
                key={title}
                style={{textAlign: 'left'}}
            >
                {icon} {title}
            </div>
        );

    }

    return (
        <div className="fake-table">
            {items}
        </div>
    );

}

function AuthStatusDotWithTooltip({status}) {

    let iconClass = '';
    let iconStyle = {};

    const bestClass = 'v3 icon verified';
    const dangerClass = 'v3 signal large danger';
    const warningClass = 'v3 signal large warning';
    const goodClass = 'v3 icon success';
    const loadingClass = 'v3 icon check-circle';


    if (!status) {
        iconClass = loadingClass;
        iconStyle = {color: '#666', background: 'white', borderRadius: '50%'};
        return <i className={iconClass} style={iconStyle} />;
    }

    if (status.has_valid_email && status.is_creator_account && status.has_any_instagram && status.has_valid_instagram_business) {
        iconClass = bestClass;
    } else if (status.has_valid_email && status.is_creator_account && status.has_any_instagram) {
        iconClass = goodClass;
    } else if (status.has_valid_email && status.is_creator_account && status.has_any_instagram_business && !status.has_valid_instagram_business) {
        iconClass = warningClass;
    } else if (!status.has_valid_email || !status.is_creator_account) {
        iconClass = dangerClass;
    } else {
        iconClass = loadingClass;
        iconStyle = {color: '#666', background: 'white', borderRadius: '50%'};
    }

    const icon = <i className={iconClass} style={iconStyle} />;
    const html = (<InnerTable status={status}/> );

    return (
        <Tooltip html={html}>
            {icon}
        </Tooltip>
    );
}

export default AuthStatusDotWithTooltip;
