import React, {useState} from 'react';
import {createUserInteraction} from "../../../../actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";

function AddInstagram({createUserInteraction, userId, onClose, onClickInstagramBusinessLink}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [handle, setHandle] = useState('');

    const renderAlert = () => null;
    const handleSubmit = async () => {
        if (!handle) {
            return null;
        }
        setIsSubmitting(true);
        const payload = {handle, type: 'instagram'};
        const resp = await createUserInteraction(userId, 'SimpleAttachSocial', payload);
        console.log("simple add social resp", resp);
        setIsSubmitting(false);
        setHandle('');
        if (onClose) {
            onClose();
        }

    };
    return (

        <div className="add-blog-toolbox-content">
            {renderAlert()}
            <p className="v3 light h7 pane-info">
                Please indicate your Instagram handle below and then click "Add Instagram". <br/>
                For advanced users: if you have an "Instagram Business" or "Instagram Creator" account attached to a Facebook Page, you can use the link below the form to connect your account.
            </p>
            <Form
                fields={[
                    {
                        type: 'text',
                        name: 'handle',
                        title: 'Instagram Username or URL',
                        placeholder: 'eg: https://www.instagram.com/yourusername'
                    },
                    {
                        type: 'button',
                        title: isSubmitting ? 'Adding Your Instagram...' : 'Add Instagram',
                        options: {
                            classes: ['v3', 'btn-primary', ((isSubmitting || !handle) ? 'disabled' : '')],
                            hideTitle: true,
                            onClick: isSubmitting ? undefined : handleSubmit
                        }
                    },
                    onClickInstagramBusinessLink ? {
                        type: 'generic',
                        content: (
                            <Alert
                                style={{marginTop: 40}}
                                classes={['info']}
                                content={<span>Have an Instagram Business or Creator account attached to a Facebook Page? <a href="#" rel="button" className="v3 bold" onClick={onClickInstagramBusinessLink}>Click to Link Your Account</a></span>}
                            />
                        )
                    } : null

                ]}
                values={{handle}}
                onFieldChange={(key, value)  => {
                    if (key === 'handle') { setHandle(value); }
                }}
                usePadding={false}
            />

        </div>


    );
}

const mapDispatchToProps = dispatch => bindActionCreators({createUserInteraction}, dispatch);
export default connect(undefined, mapDispatchToProps)(AddInstagram);