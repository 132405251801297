export default {
    "aa": [ "Afar", "Afaraf"],
    "af": [ "Afrikaans", "Afrikaans"],
    "ak": [ "Akan", "Akan"],
    "sq": [ "Albanian", "Shqip"],
    "am": [ "Amharic", "አማርኛ"],
    "ar": [ "Arabic", "العربية"],
    "an": [ "Aragonese", "aragonés"],
    "hy": [ "Armenian", "Հայերեն"],
    "as": [ "Assamese", "অসমীয়া"],
    "av": [ "Avaric", "авар мацӀ, магӀарул мацӀ"],
    "ae": [ "Avestan", "avesta"],
    "ay": [ "Aymara", "aymar aru"],
    "az": [ "Azerbaijani", "azərbaycan dili, تۆرکجه"],
    "bm": [ "Bambara", "bamanankan"],
    "ba": [ "Bashkir", "башҡорт теле"],
    "eu": [ "Basque", "euskara, euskera"],
    "be": [ "Belarusian", "беларуская мова"],
    "bn": [ "Bengali", "বাংলা"],
    "bi": [ "Bislama", "Bislama"],
    "bs": [ "Bosnian", "bosanski jezik"],
    "br": [ "Breton", "brezhoneg"],
    "bg": [ "Bulgarian", "български език"],
    "my": [ "Burmese", "ဗမာစာ"],
    "ca": [ "Catalan, Valencian", "català, valencià"],
    "ch": [ "Chamorro", "Chamoru"],
    "ce": [ "Chechen", "нохчийн мотт"],
    "ny": [ "Chichewa, Chewa, Nyanja", "chiCheŵa, chinyanja"],
    "zh": [ "Chinese", "中文 (Zhōngwén), 汉语, 漢語"],
    "cv": [ "Chuvash", "чӑваш чӗлхи"],
    "kw": [ "Cornish", "Kernewek"],
    "co": [ "Corsican", "corsu, lingua corsa"],
    "cr": [ "Cree", "ᓀᐦᐃᔭᐍᐏᐣ"],
    "hr": [ "Croatian", "hrvatski jezik"],
    "cs": [ "Czech", "čeština, český jazyk"],
    "da": [ "Danish", "dansk"],
    "dv": [ "Divehi, Dhivehi, Maldivian", "ދިވެހި"],
    "nl": [ "Dutch, Flemish", "Nederlands, Vlaams"],
    "dz": [ "Dzongkha", "རྫོང་ཁ"],
    "en": [ "English", "English"],
    "eo": [ "Esperanto", "Esperanto"],
    "et": [ "Estonian", "eesti, eesti keel"],
    "ee": [ "Ewe", "Eʋegbe"],
    "fo": [ "Faroese", "føroyskt"],
    "fj": [ "Fijian", "vosa Vakaviti"],
    "fi": [ "Finnish", "suomi, suomen kieli"],
    "fr": [ "French", "Français"],
    "ff": [ "Fulah", "Fulfulde, Pulaar, Pular"],
    "gl": [ "Galician", "Galego"],
    "ka": [ "Georgian", "ქართული"],
    "de": [ "German", "Deutsch"],
    "el": [ "Greek, Modern (1453–)", "ελληνικά"],
    "gn": [ "Guarani", "Avañe'ẽ"],
    "gu": [ "Gujarati", "ગુજરાતી"],
    "ht": [ "Haitian, Haitian Creole", "Kreyòl ayisyen"],
    "ha": [ "Hausa", "(Hausa) هَوُسَ"],
    "he": [ "Hebrew", "עברית"],
    "hz": [ "Herero", "Otjiherero"],
    "hi": [ "Hindi", "हिन्दी, हिंदी"],
    "ho": [ "Hiri Motu", "Hiri Motu"],
    "hu": [ "Hungarian", "magyar"],
    "ia": [ "Interlingua (International Auxiliary Language Association)", "Interlingua"],
    "id": [ "Indonesian", "Bahasa Indonesia"],
    "ie": [ "Interlingue, Occidental", "(originally:) Occidental, (after WWII:) Interlingue"],
    "ga": [ "Irish", "Gaeilge"],
    "ig": [ "Igbo", "Asụsụ Igbo"],
    "ik": [ "Inupiaq", "Iñupiaq, Iñupiatun"],
    "io": [ "Ido", "Ido"],
    "is": [ "Icelandic", "Íslenska"],
    "it": [ "Italian", "Italiano"],
    "iu": [ "Inuktitut", "ᐃᓄᒃᑎᑐᑦ"],
    "ja": [ "Japanese", "日本語 (にほんご)"],
    "jv": [ "Javanese", "ꦧꦱꦗꦮ, Basa Jawa"],
    "kl": [ "Kalaallisut, Greenlandic", "kalaallisut, kalaallit oqaasii"],
    "kn": [ "Kannada", "ಕನ್ನಡ"],
    "kr": [ "Kanuri", "Kanuri"],
    "ks": [ "Kashmiri", "कश्मीरी, كشميري‎"],
    "kk": [ "Kazakh", "қазақ тілі"],
    "km": [ "Central Khmer", "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ"],
    "ki": [ "Kikuyu, Gikuyu", "Gĩkũyũ"],
    "rw": [ "Kinyarwanda", "Ikinyarwanda"],
    "ky": [ "Kirghiz, Kyrgyz", "Кыргызча, Кыргыз тили"],
    "kv": [ "Komi", "коми кыв"],
    "kg": [ "Kongo", "Kikongo"],
    "ko": [ "Korean", "한국어"],
    "ku": [ "Kurdish", "Kurdî, کوردی‎"],
    "kj": [ "Kuanyama, Kwanyama", "Kuanyama"],
    "la": [ "Latin", "latine, lingua latina"],
    "lb": [ "Luxembourgish, Letzeburgesch", "Lëtzebuergesch"],
    "lg": [ "Ganda", "Luganda"],
    "li": [ "Limburgan, Limburger, Limburgish", "Limburgs"],
    "ln": [ "Lingala", "Lingála"],
    "lo": [ "Lao", "ພາສາລາວ"],
    "lt": [ "Lithuanian", "lietuvių kalba"],
    "lu": [ "Luba-Katanga", "Kiluba"],
    "lv": [ "Latvian", "latviešu valoda"],
    "gv": [ "Manx", "Gaelg, Gailck"],
    "mk": [ "Macedonian", "македонски јазик"],
    "mg": [ "Malagasy", "fiteny malagasy"],
    "ms": [ "Malay", "Bahasa Melayu, بهاس ملايو‎"],
    "ml": [ "Malayalam", "മലയാളം"],
    "mt": [ "Maltese", "Malti"],
    "mi": [ "Maori", "te reo Māori"],
    "mr": [ "Marathi", "मराठी"],
    "mh": [ "Marshallese", "Kajin M̧ajeļ"],
    "mn": [ "Mongolian", "Монгол хэл"],
    "na": [ "Nauru", "Dorerin Naoero"],
    "nv": [ "Navajo, Navaho", "Diné bizaad"],
    "nd": [ "North Ndebele", "isiNdebele"],
    "ne": [ "Nepali", "नेपाली"],
    "ng": [ "Ndonga", "Owambo"],
    "nb": [ "Norwegian Bokmål", "Norsk Bokmål"],
    "nn": [ "Norwegian Nynorsk", "Norsk Nynorsk"],
    "no": [ "Norwegian", "Norsk"],
    "ii": [ "Sichuan Yi, Nuosu", "ꆈꌠ꒿ Nuosuhxop"],
    "nr": [ "South Ndebele", "isiNdebele"],
    "oc": [ "Occitan", "occitan, lenga d'òc"],
    "oj": [ "Ojibwa", "ᐊᓂᔑᓈᐯᒧᐎᓐ"],
    "cu": [ "Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic", "ѩзыкъ словѣньскъ"],
    "om": [ "Oromo", "Afaan Oromoo"],
    "or": [ "Oriya", "ଓଡ଼ିଆ"],
    "os": [ "Ossetian, Ossetic", "ирон æвзаг"],
    "pa": [ "Punjabi, Panjabi", "ਪੰਜਾਬੀ, پنجابی‎"],
    "pi": [ "Pali", "पालि, पाळि"],
    "fa": [ "Persian", "فارسی"],
    "pl": [ "Polish", "język polski, polszczyzna"],
    "ps": [ "Pashto, Pushto", "پښتو"],
    "pt": [ "Portuguese", "Português"],
    "qu": [ "Quechua", "Runa Simi, Kichwa"],
    "rm": [ "Romansh", "Rumantsch Grischun"],
    "rn": [ "Rundi", "Ikirundi"],
    "ro": [ "Romanian, Moldavian, Moldovan", "Română, Moldovenească"],
    "ru": [ "Russian", "русский"],
    "sa": [ "Sanskrit", "संस्कृतम्, 𑌸𑌂𑌸𑍍𑌕𑍃𑌤𑌮𑍍"],
    "sc": [ "Sardinian", "sardu"],
    "sd": [ "Sindhi", "सिन्धी, سنڌي، سندھی‎"],
    "se": [ "Northern Sami", "Davvisámegiella"],
    "sm": [ "Samoan", "gagana fa'a Samoa"],
    "sg": [ "Sango", "yângâ tî sängö"],
    "sr": [ "Serbian", "српски језик"],
    "gd": [ "Gaelic, Scottish Gaelic", "Gàidhlig"],
    "sn": [ "Shona", "chiShona"],
    "si": [ "Sinhala, Sinhalese", "සිංහල"],
    "sk": [ "Slovak", "Slovenčina, Slovenský jazyk"],
    "sl": [ "Slovenian", "Slovenski jezik, Slovenščina"],
    "so": [ "Somali", "Soomaaliga, af Soomaali"],
    "st": [ "Southern Sotho", "Sesotho"],
    "es": [ "Spanish", "Español"],
    "su": [ "Sundanese", "Basa Sunda"],
    "sw": [ "Swahili", "Kiswahili"],
    "ss": [ "Swati", "SiSwati"],
    "sv": [ "Swedish", "Svenska"],
    "ta": [ "Tamil", "தமிழ்"],
    "te": [ "Telugu", "తెలుగు"],
    "tg": [ "Tajik", "тоҷикӣ, toçikī, تاجیکی‎"],
    "th": [ "Thai", "ไทย"],
    "ti": [ "Tigrinya", "ትግርኛ"],
    "bo": [ "Tibetan", "བོད་ཡིག"],
    "tk": [ "Turkmen", "Türkmen, Түркмен"],
    "tl": [ "Tagalog", "Wikang Tagalog"],
    "tn": [ "Tswana", "Setswana"],
    "to": [ "Tonga (Tonga Islands)", "Faka Tonga"],
    "tr": [ "Turkish", "Türkçe"],
    "ts": [ "Tsonga", "Xitsonga"],
    "tt": [ "Tatar", "татар теле, tatar tele"],
    "tw": [ "Twi", "Twi"],
    "ty": [ "Tahitian", "Reo Tahiti"],
    "ug": [ "Uighur, Uyghur", "ئۇيغۇرچە‎, Uyghurche"],
    "uk": [ "Ukrainian", "Українська"],
    "ur": [ "Urdu", "اردو"],
    "uz": [ "Uzbek", "Oʻzbek, Ўзбек, أۇزبېك‎"],
    "ve": [ "Venda", "Tshivenḓa"],
    "vi": [ "Vietnamese", "Tiếng Việt"],
    "vo": [ "Volapük", "Volapük"],
    "wa": [ "Walloon", "Walon"],
    "cy": [ "Welsh", "Cymraeg"],
    "wo": [ "Wolof", "Wollof"],
    "fy": [ "Western Frisian", "Frysk"],
    "xh": [ "Xhosa", "isiXhosa"],
    "yi": [ "Yiddish", "ייִדיש"],
    "yo": [ "Yoruba", "Yorùbá"],
    "za": [ "Zhuang, Chuang", "Saɯ cueŋƅ, Saw cuengh"],
    "zu": [ "Zulu", "isiZulu"]
};