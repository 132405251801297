export const calculateCost = (record, emvParams) => {
    return Math.abs(parseInt(record.cost, 10) || 0);
};

export const calculateRoi = (record, emvParams) => {
    if (record === null) return 0;
    const {total} = calculateEmv(record, emvParams);
    const cost = calculateCost(record, emvParams);
    if (cost == 0) return 0;
    if (total == 0) return 0;
    return ((total/cost) - 1);
};

export const calculateEmv = (record, emvParams) => {
    let out = {
        total: 0,
        post: 0,
        engagement: 0,
        impression: 0,
        other: 0
    };

    for (const emvKey in emvParams) {
        const statKey = emvKey.replace('emv_', '');
        const category = EMV_CATEGORY_TYPES[emvKey] || 'other';
        const valuePer = parseFloat(emvParams[emvKey] || 0);
        const count = parseFloat(record[statKey] || 0);
        const value = count * valuePer;

        out[category] += value;
        out.total += value;
    }

    return out;

}

export const calculateEmvByNetwork = (record, emvParams) => {
    const networks = ['instagram', 'instastory', 'facebook', 'facebook_page', 'twitter', 'blog', 'pinterest', 'youtube', 'tiktok'];
    const out = {};

    // foreach network, calculate emv by using only the emv params for that network
    for (const network of networks) {
        const networkEmvParams = {};
        for (const emvKey in emvParams) {
            if (emvKey.indexOf(network) > -1) {
                networkEmvParams[emvKey] = emvParams[emvKey];
            }
        }
        out[network] = calculateEmv(record, networkEmvParams);
    }

    return out;

}
export const EMV_CATEGORY_TYPES = {
    "emv_num_instagram_posts": 'post',
    "emv_num_instastory_posts": 'post',
    "emv_num_facebook_posts": 'post',
    "emv_num_facebook_page_posts": 'post',
    "emv_num_twitter_posts": 'post',
    "emv_num_blog_posts": 'post',
    "emv_num_pinterest_posts": 'post',
    "emv_num_youtube_posts": 'post',
    "emv_num_tiktok_posts": 'post',

    "emv_instagram_like_count": 'engagement',
    "emv_instagram_comment_count": 'engagement',
    "emv_instagram_reach": 'impression',

    "emv_instastory_engagement_count": 'engagement',
    "emv_instastory_impression_count": 'impression',

    "emv_twitter_tweet_count": 'engagement',
    "emv_twitter_retweet_count": 'engagement',
    "emv_twitter_favorite_count": 'engagement',
    "emv_twitter_reach": 'impression',

    "emv_facebook_reaction_count": 'engagement',
    "emv_facebook_share_count": 'engagement',
    "emv_facebook_comment_count": 'engagement',
    "emv_facebook_reach": 'impression',

    "emv_facebook_page_reaction_count": 'engagement',
    "emv_facebook_page_share_count": 'engagement',
    "emv_facebook_page_comment_count": 'engagement',
    "emv_facebook_page_reach": 'impression',

    "emv_blog_pageviews_count": 'impression',
    "emv_blog_comment_count": 'engagement',
    "emv_blog_reach": 'impression',

    "emv_pinterest_save_count": 'engagement',
    "emv_pinterest_comment_count": 'engagement',
    "emv_pinterest_reach": 'impression',

    "emv_youtube_view_count": 'impression',
    "emv_youtube_like_count": 'engagement',
    "emv_youtube_dislike_count": 'engagement',
    "emv_youtube_comment_count": 'engagement',
    "emv_youtube_reach": 'impression',

    "emv_tiktok_view_count": "impression",
    "emv_tiktok_share_count": "engagement",
    "emv_tiktok_like_count": "engagement",
    "emv_tiktok_comment_count": "engagement",
    "emv_tiktok_click_count": "engagement",
    "emv_tiktok_reach": "impression",
};

export const EMV_INDUSTRY_DEFAULTS = {
    "emv_num_instagram_posts": 100,
    "emv_num_instastory_posts": 100,
    "emv_num_facebook_posts": 100,
    "emv_num_facebook_page_posts": 100,
    "emv_num_twitter_posts": 50,
    "emv_num_blog_posts": 400,
    "emv_num_pinterest_posts": 50,
    "emv_num_youtube_posts": 500,
    "emv_num_tiktok_posts": 500,

    "emv_instagram_like_count": 0.25,
    "emv_instagram_comment_count": 4.5,
    "emv_instagram_reach": 0.05,

    "emv_instastory_engagement_count": 0.5,
    "emv_instastory_impression_count": 0.05,
    "emv_instastory_reach": 0.05,

    "emv_twitter_tweet_count": 0.76,
    "emv_twitter_retweet_count": 1.5,
    "emv_twitter_favorite_count": 0.23,
    "emv_twitter_reach": 0.06,

    "emv_facebook_reaction_count": 0.26,
    "emv_facebook_share_count": 2.0,
    "emv_facebook_comment_count": 3.25,
    "emv_facebook_reach": 0.04,

    "emv_facebook_page_reaction_count": 0.26,
    "emv_facebook_page_share_count": 2.0,
    "emv_facebook_page_comment_count": 3.25,
    "emv_facebook_page_reach": 0.04,

    "emv_blog_pageviews_count": 0.05,
    "emv_blog_comment_count": 0.09,
    "emv_blog_reach": 0.05,

    "emv_pinterest_save_count": 0.27,
    "emv_pinterest_comment_count": 0.12,
    "emv_pinterest_reach": 0.05,

    "emv_youtube_view_count": 0.17,
    "emv_youtube_like_count": 0.72,
    "emv_youtube_dislike_count": 0.72,
    "emv_youtube_comment_count": 4.24,
    "emv_youtube_reach": 0.05,

    "emv_tiktok_view_count": 0.10,
    "emv_tiktok_share_count": 1.0,
    "emv_tiktok_like_count": 0.8,
    "emv_tiktok_comment_count": 1.0,
    "emv_tiktok_click_count": 1.0,
    "emv_tiktok_reach": 0.05,
};

export const EMV_TIDAL_DEFAULTS = {
    "emv_num_instagram_posts": 200,
    "emv_num_instastory_posts": 200,
    "emv_num_facebook_posts": 200,
    "emv_num_facebook_page_posts": 200,
    "emv_num_twitter_posts": 200,
    "emv_num_blog_posts": 200,
    "emv_num_pinterest_posts": 200,
    "emv_num_youtube_posts": 200,
    "emv_num_tiktok_posts": 200,

    "emv_instagram_like_count": 1,
    "emv_instagram_comment_count": 1,
    "emv_instagram_reach": 0.05,

    "emv_instastory_engagement_count": 1,
    "emv_instastory_impression_count": 0.05,
    "emv_instastory_reach": 0.05,

    "emv_twitter_tweet_count": 1,
    "emv_twitter_retweet_count": 1,
    "emv_twitter_favorite_count": 1,
    "emv_twitter_reach": 0.05,

    "emv_facebook_reaction_count": 1,
    "emv_facebook_share_count": 1,
    "emv_facebook_comment_count": 1,
    "emv_facebook_reach": 0.05,

    "emv_facebook_page_reaction_count": 1,
    "emv_facebook_page_share_count": 1,
    "emv_facebook_page_comment_count": 1,
    "emv_facebook_page_reach": 0.05,

    "emv_blog_pageviews_count": 0.05,
    "emv_blog_comment_count": 1,
    "emv_blog_reach": 0.05,

    "emv_pinterest_save_count": 1,
    "emv_pinterest_comment_count": 1,
    "emv_pinterest_reach": 0.05,

    "emv_youtube_view_count": 1,
    "emv_youtube_like_count": 1,
    "emv_youtube_dislike_count": 1,
    "emv_youtube_comment_count": 1,
    "emv_youtube_reach": 0.05,

    "emv_tiktok_view_count": 0.10,
    "emv_tiktok_share_count": 1.0,
    "emv_tiktok_like_count": 0.8,
    "emv_tiktok_comment_count": 1.0,
    "emv_tiktok_click_count": 1.0,
    "emv_tiktok_reach": 0.05,
};

export const EMV_CREATORIQ_DEFAULTS = {
    "emv_num_instagram_posts": 500,
    "emv_num_instastory_posts": 500,
    "emv_num_facebook_posts": 500,
    "emv_num_facebook_page_posts": 500,
    "emv_num_twitter_posts": 200,
    "emv_num_blog_posts": 1000,
    "emv_num_pinterest_posts": 500,
    "emv_num_youtube_posts": 1500,
    "emv_num_tiktok_posts": 1000,

    "emv_instagram_like_count": 36.95,
    "emv_instagram_comment_count": 212.46,
    "emv_instagram_impression_count": 4.18,
    "emv_instagram_reach": 0,

    "emv_instastory_engagement_count": 738.20,
    "emv_instastory_impression_count": 21.73,
    "emv_instastory_reach": 0,

    "emv_twitter_tweet_count": 14.04,
    "emv_twitter_retweet_count": 767.13,
    "emv_twitter_favorite_count": 14.04,
    "emv_twitter_reach": 1.20,

    "emv_facebook_reaction_count": 31.60,
    "emv_facebook_share_count": 1726.05,
    "emv_facebook_comment_count": 181.69,
    "emv_facebook_reach": 1.80,

    "emv_facebook_page_reaction_count": 31.60,
    "emv_facebook_page_share_count": 1726.05,
    "emv_facebook_page_comment_count": 181.69,
    "emv_facebook_page_reach": 1.80,

    "emv_blog_pageviews_count": 1.50,
    "emv_blog_comment_count": 3,
    "emv_blog_reach": 0.05,

    "emv_pinterest_save_count": 154.66,
    "emv_pinterest_comment_count": 154.66,
    "emv_pinterest_reach": 4,

    "emv_youtube_view_count": 39.63,
    "emv_youtube_like_count": 25.08,
    "emv_youtube_dislike_count": 25.08,
    "emv_youtube_comment_count": 144.20,
    "emv_youtube_reach": 0,

    "emv_tiktok_view_count": 10,
    "emv_tiktok_share_count": 40,
    "emv_tiktok_like_count": 5,
    "emv_tiktok_comment_count": 20,
    "emv_tiktok_click_count": 20,
    "emv_tiktok_reach": 0.05,

};

