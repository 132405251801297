import * as ActionTypes from './types';
import fetch from "isomorphic-fetch";
import {getCsrfToken, hasLocalStorage, makeFormData} from "../utilities";
import moment from 'moment';
import _merge from 'lodash/merge';

export const setSharedPassword = (password) => ({
    type: ActionTypes.AUTH_SET_SHARED_PASSWORD,
    data: password
});

export const setAuthState = (payload) => {
    return {
        type: ActionTypes.AUTH_SET_STATE,
        data: {
            ...payload
        }
    };
};

export const logOut = () => {

    return (dispatch) => {
        dispatch({
            type: ActionTypes.AUTH_LOGOUT
        });

        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/user/me/token`;


        let promise = dispatch(fetchAuthenticated(url, {
            method: 'POST',
            body: makeFormData({
                _method: 'delete'
            })
        }));

        if (hasLocalStorage) {
            window.localStorage.removeItem('TIDAL_TOKEN');
        }

        return promise;
    }
};

export const refreshToken = () => {
    return (dispatch, getState) => {

        dispatch({
            type: ActionTypes.AUTH_REFRESH_TOKEN
        });

        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/user/me/refreshtoken`;
        const {token} = getState().auth;

        if (!token) {
            return Promise.reject("No token to refresh");
        }

        const payload = {
            token: token.accessToken,
            refresh_token: token.refreshToken,
        };

        return dispatch(fetchAuthenticated(url, {
            allowRefresh: false,
            method: 'post',
            body: makeFormData(payload)
        }))
            .then(resp => resp.json())
            .then(json => dispatch(setAuthState(json.data)));

    };
};

export const xhrAuthenticated = (method, url, formData, onLoad, onProgress) => {

    return (dispatch, getState) => {
        const {token, channelUrl} = getState().auth;

        // Get the fully qualified URL.
        const domain = channelUrl || window.location.hostname;
        const proto = window.location.protocol;
        const base = domain ? (domain.substr(0, 4) !== 'http' ? (proto + '//') : '') + domain : '';
        const fullUrl = (url.substring(0, 4) !== 'http') ? base + url : url;

        let isTokenValid = false;
        let hasToken = false;
        let expiringSoon = false;

        if (token && token.accessToken && token.expires_at) {
            const expiresAt = moment(token.expires_at);
            const now = moment();
            const refreshTtl = 15;
            const expiresInMinutes = Math.abs(expiresAt.diff(now, 'minutes'));
            hasToken = true;

            if (expiresAt.isAfter(now)) {
                isTokenValid = true;

                if (expiresInMinutes < refreshTtl) {
                    expiringSoon = true;
                }
            }

            console.log({expiresInMinutes});

        }

        const request = new XMLHttpRequest();
        request.responseType = 'json';

        if (onProgress) {
            request.onprogress = onProgress;
            request.upload.onprogress = onProgress;
        }

        if (onLoad) {
            request.onload = onLoad;
        }

        request.open(method, fullUrl, true);

        if (isTokenValid) {
            request.setRequestHeader('Authorization', 'Bearer ' + token.accessToken);
            request.setRequestHeader('Accept', 'application/json');
        } else {
            request.setRequestHeader('X-CSRF-TOKEN', getCsrfToken());
            request.setRequestHeader('Accept', 'application/json');
        }

        request.send(formData);

        return request;
    };

};

export const fetchAuthenticated = (url, params = {}) => {

    return (dispatch, getState) => {
        const {token, channelUrl, sharedPassword} = getState().auth;

        // Get the fully qualified URL.
        const devUrls = ['dev.gator.com', 'demo.stag.tid.al'];
        const domain = channelUrl || window.location.hostname;
        // const proto = (devUrls.indexOf(domain) > -1) ? 'http' : 'https';
        const proto = window.location.protocol;
        const base = domain ? (domain.substr(0, 4) !== 'http' ? (proto + '//') : '') + domain : '';
        const fullUrl = (url.substring(0, 4) !== 'http') ? base + url : url;

        let isTokenValid = false;
        let hasToken = false;
        let expiringSoon = false;
        let defaultParams = {};
        let allowRefresh = (typeof params.allowRefresh === 'undefined' ? true : params.allowRefresh);

        if (token && token.accessToken && token.expires_at) {
            const expiresAt = moment(token.expires_at);
            const now = moment();
            const refreshTtl = 15;
            const expiresInMinutes = Math.abs(expiresAt.diff(now, 'minutes'));
            hasToken = true;

            if (expiresAt.isAfter(now)) {
                isTokenValid = true;

                if (expiresInMinutes < refreshTtl) {
                    expiringSoon = true;
                }
            }

            console.log({expiresInMinutes});

        }

        // if (hasToken && !isTokenValid) {
        //     return Promise.reject('Session expired.');
        // }

        if (isTokenValid) {
            defaultParams = {
                headers: {
                    'Authorization': 'Bearer ' + token.accessToken,
                    'Accept': 'application/json',
                }
            };
        } else {
            defaultParams = {
                credentials: 'same-origin',
                headers: {
                    'X-CSRF-TOKEN': getCsrfToken(),
                    'Accept': 'application/json',
                }
            };
        }

        if (sharedPassword) {
            defaultParams.headers['X-SHARED-PASSWORD'] = sharedPassword;
        }

        const requestParams = _merge({}, defaultParams, params);
        return fetch(fullUrl, requestParams)
            .then(resp => {

                if (hasToken && isTokenValid && expiringSoon && allowRefresh) {
                    console.log("Trying to refresh token");
                    dispatch(refreshToken());
                }

                return resp;
            });
    };
};

export const requestPasswordReset = (email, slot = null) => {
    return (dispatch) => {
        let dashboardSlot = slot ? slot : window.Gator.getDashboardSlot();
        const url = '/' + dashboardSlot + '/user/resetpassword';

        dispatch({
            type: ActionTypes.AUTH_REQUEST_PASSWORD_RESET
        });

        return dispatch(fetchAuthenticated(url, {
            method: 'post',
            body: makeFormData({
                email
            })
        }))
            .then(resp => resp.json())
            .then(json => {

                if (json.data.ok) {
                    return dispatch({
                        type: ActionTypes.AUTH_PASSWORD_RESET_SUCCESS,
                        data: json.data,
                        meta: json.meta
                    });
                } else {
                    return dispatch({
                        type: ActionTypes.AUTH_PASSWORD_RESET_FAIL,
                        data: json.data,
                        meta: json.meta,
                        error: json.meta.error,
                    });
                }

            });
    };

};

export const requestSubscriptions = (token, slot = null) => {
    return (dispatch) => {
        let dashboardSlot = slot ? slot : window.Gator.getDashboardSlot();
        const url = '/' + dashboardSlot + '/api/user/me/subscription?token=' + token;

        dispatch({
            type: ActionTypes.AUTH_REQUEST_SUBSCRIPTIONS
        });

        return dispatch(fetchAuthenticated(url))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: ActionTypes.AUTH_RECEIVE_SUBSCRIPTIONS,
                meta: json.meta,
                data: json.data,
            }));
    };
};

export const updateSubscriptions = (token, data, slot = null) => {
    return (dispatch) => {
        let dashboardSlot = slot ? slot : window.Gator.getDashboardSlot();
        const url = '/' + dashboardSlot + '/api/user/me/subscription?token=' + token;

        dispatch({
            type: ActionTypes.AUTH_UPDATE_SUBSCRIPTIONS,
            data
        });

        return dispatch(fetchAuthenticated(url, {
            method: 'POST',
            body: makeFormData({
                '_method': 'PUT',
                ...data,
            })
        }))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: ActionTypes.AUTH_UPDATED_SUBSCRIPTIONS,
                meta: json.meta,
                data: json.data,
            }));
    };
};

export const requestLogin = (email, password, slot = null) => {
    return (dispatch) => {

        let dashboardSlot = slot ? slot : window.Gator.getDashboardSlot();
        const url = '/' + dashboardSlot + '/api/user/me/token';

        dispatch({
            type: ActionTypes.AUTH_REQUEST_LOGIN
        });

        return dispatch(fetchAuthenticated(url, {
            method: 'post',
            body: makeFormData({
                email,
                password
            })
        }))
            .then(resp => resp.json())
            .then(json => {

                if (json.data.ok) {
                    return dispatch({
                        type: ActionTypes.AUTH_LOGIN_SUCCESS,
                        data: json.data,
                        meta: json.meta
                    });
                } else {
                    return dispatch({
                        type: ActionTypes.AUTH_LOGIN_FAIL,
                        data: json.data,
                        meta: json.meta,
                        error: json.meta.error,
                    });
                }

            });

    }
};

export const requestSignup = (form, slot = null) => {
    return (dispatch) => {

        let dashboardSlot = slot ? slot : window.Gator.getDashboardSlot();
        const url = '/' + dashboardSlot + '/api/user/me/signup';

        dispatch({
            type: ActionTypes.AUTH_REQUEST_SIGNUP
        });

        return dispatch(fetchAuthenticated(url, {
            method: 'post',
            body: makeFormData({
                ...form
            })
        }))
            .then(resp => resp.json())
            .then(json => {

                if (json.data && json.data.ok) {
                    return dispatch({
                        type: ActionTypes.AUTH_SIGNUP_SUCCESS,
                        data: json.data,
                        meta: json.meta
                    });
                } else {
                    return dispatch({
                        type: ActionTypes.AUTH_SIGNUP_FAIL,
                        data: json.data,
                        meta: json.meta,
                        error: json.meta.error,
                    });
                }

            });

    }
};



