/**
 * Created by bkroger on 10/17/18.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import NavStacked from "../../Common/NavStacked";
import Basics from "./Templates/Basics";
import YourProfile from "./Templates/YourProfile";
import {createUserInteraction} from "../../../actions/users";
import Location from "./Templates/Location";
import AccountTools from "./Templates/AccountTools";
import Password from "./Templates/Password";
import SocialNetworks from "./Templates/SocialNetworks";
import CustomFieldEditor from './Templates/CustomFieldEditor';
import AllianzSubscriptions from "../../Pages/Embedded/Allianz/Subscriptions";
import AllianzProfileFields from "../../Pages/Embedded/Allianz/ProfileFields";
import _get from 'lodash/get';
import {formatNumber, formatNumberK} from "../../../utilities";
import {getTierName} from "../../../utilities/user";
import {getCurrentChannel, getPresenceTeam} from "../../../selectors/presence";
import Facets from "./Templates/Facets";
import MiniProfile from "../../Common/MiniProfile";
import Alert from '../../Common/Alert';
import {translate} from "../../../actions/i18n";
import ShippingAddress from "./Templates/ShippingAddress";

/**
 * @param key
 * @param _t
 * @returns {*}
 */
const profileFieldKeyToName = (key, _t) => {
    switch (key) {
        case 'name': return _t('contribute.profile.missingfield.name', 'Name');
        case 'gender': return _t('contribute.profile.missingfield.gender', 'Gender');
        case 'birthday_year': return _t('contribute.profile.missingfield.birthday_year', "Birthday Year");
        case 'birthday_month': return _t('contribute.profile.missingfield.birthday_month', "Birthday Month");
        case 'birthday_date': return _t('contribute.profile.missingfield.birthday_day', "Birthday Day");
        case 'country_code': return _t('contribute.profile.missingfield.country', "Country");
        case 'state_name': return _t('contribute.profile.missingfield.state', "State");
        case 'city_name': return _t('contribute.profile.missingfield.city', "City");
        case 'zipcode': return _t('contribute.profile.missingfield.zip', "Zip or Postal Code");
        case 'profile_bio': return _t('contribute.profile.missingfield.bio', "Short Profile Bio");
        case 'profile_picture': return _t('contribute.profile.missingfield.picture', "Profile Picture");
        case 'blogs': return _t('contribute.profile.missingfield.blog', "Blog");
        case 'socials': return _t('contribute.profile.missingfield.socials', "Social Accounts");
        case 'phone': return _t('contribute.profile.missingfield.phone', "Phone Number");
        case 'facets.Category': return _t('contribute.profile.missingfield.category', "Primary Content Category");
        case 'facets.Language': return _t('contribute.profile.missingfield.language', "Primary Language");
        case 'facets.Race': return _t('contribute.profile.missingfield.race', "Race/Ethnicity");
        case 'facets.Parenthood': return _t('contribute.profile.missingfield.parenthood', "Parenthood Status");
        case 'facets.Relationship': return _t('contribute.profile.missingfield.relationship', "Relationship Status");
        case 'verified_blog': return _t('contribute.profile.missingfield.verify_blog', "Verify your Blog");
        case 'Instagram': return _t('contribute.profile.missingfield.instagram', "Connect your Instagram");
        case 'Facebook': return _t('contribute.profile.missingfield.facebook', "Connect your Facebook");
        case 'FacebookPage': return _t('contribute.profile.missingfield.facebookpage', "Connect your Facebook Page");
        default: return key;
    }
};

/**
 * @param user
 * @return {Number} As a percentage (ie, points/max * 100)
 */
const calcProfileCompletion = (user, isFieldVisible) => {

    let _missing = [];
    let _found = [];

    let points = 0;

    /**
     * Fields that should exist.
     * @type {string[]}
     */
    // const exists = ['name', 'gender', 'birthday_year', 'birthday_month', 'birthday_day', 'country_code', 'state_name', 'city_name', 'zipcode', 'profile_bio', 'profile_picture', 'blogs', 'socials', 'phone', 'facets.Category', 'facets.Language', 'facets.Race', 'facets.Parenthood', 'facets.Relationship'];
    const exists = ['name', 'gender', 'birthday_year', 'birthday_month', 'birthday_day', 'country_code', 'state_name', 'city_name', 'profile_bio', 'profile_picture', 'socials'];

    /**
     * Social types to count
     * We have to do IG vs IG Business separately, see below.
     * @type {string[]}
     * Previously Twitter, FacebookPage
     */
    const socialTypes = ['TikTok'];

    /**
     * Custom rule: blog verified
     * -- no longer in use.
     */
    // const hasVerifiedBlog  = (user.blogs || []).filter(b => b.is_verified).length > 0;

    /**
     * Total points is # of exists + # of social types + # of custom rules (1)
     */
    const max = exists.length + socialTypes.length + 1;

    /**
     * Add up points for existing fields
     */
    exists.forEach(key => {

        // If the field isn't 'visible', count it positively
        if (!isFieldVisible(key)) {
            points++;
            _found.push(key);
            return;
        }

        const val = _get(user, key, null);

        if (val !== null && val !== '') {
            points++;
            _found.push(key);
        } else {
            _missing.push(key);
        }
    });

    /**
     * Add up points for socials
     */
    socialTypes.forEach(key => {

        if (!isFieldVisible(key)) {
            points++;
            _found.push(key);
            return;
        }

        let type = 'Tidal\\Social\\' + key;
        const hasSocial = (user.socials || []).filter(s => s.type === type);
        if (hasSocial.length > 0) {
            points++;
            _found.push(key);
        } else {
            _missing.push(key);
        }
    });

    /**
     * Handle instagram / instagram business uniquely
     */
    const hasIgPersonal = (user.socials || []).filter(s => s.type === 'Tidal\\Social\\Instagram');
    const hasIgBusiness = (user.socials || []).filter(s => s.type === 'Tidal\\Social\\InstagramBusiness');

    if (hasIgBusiness || hasIgPersonal) {
        points++;
        _found.push('Instagram');
    } else {
        _missing.push('Instagram');
    }

    /**
     * Points for custom rules
     */
    // if (hasVerifiedBlog || !isFieldVisible('verified_blog')) {
    //     points++;
    // } else {
    //     _missing.push('verified_blog');
    // }

    console.log({_found, _missing});
    const pct = formatNumber(100 * points / max);
    return {
        points,
        max,
        pct,
        found: _found,
        missing: _missing
    };

};

class EditProfile extends Component {

    static defaultProps = {
        isMobile: false,
        view: 'basics',
        padding: false,
        showPresence: true,
        showManageAlert: false,
        style: {}
    };

    static propTypes = {
        userId: PropTypes.number.isRequired,
        createInteraction: PropTypes.func.isRequired,
        isMobile: PropTypes.bool,
        view: PropTypes.string,
        channel: PropTypes.object,
        team: PropTypes.object,
        padding: PropTypes.boolean,
        showPresence: PropTypes.boolean,
        showManageAlert: PropTypes.boolean,
        style: PropTypes.object,
        translate: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            view: props.view || 'basics',
            user: {name: "Friend"},
            isFetching: false,
            didFetch: false,
        };

        this.refreshUser = this.refreshUser.bind(this);
        this.isProfileFieldVisible = this.isProfileFieldVisible.bind(this);
    }

    componentDidMount() {
        this.refreshUser(this.props.userId);
        this.checkHash();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId) {
            this.refreshUser(this.props.userId);
        }
    }

    checkHash() {
        const hash = window.location.hash.substr(1);
        const allowed = ['basics', 'your-profile', 'facets', 'social-networks'];
        if (hash && allowed.indexOf(hash) !== -1) {
            this.setState({view: hash})
        }
    }

    refreshUser(id) {
        if (!id || id === 'null') {
            console.log("Bad id");
            console.log(id);
            return;
        }

        this.setState({isFetching: true});

        this.props.createInteraction(id, 'PersonalProfileRequest', {})
            .then((resp) =>
                this.setState({
                    user: (resp.data || {}).results || {},
                    isFetching: false,
                    didFetch: true
                })
            )
            .then(() => setTimeout(() => this.setState({didFetch: false}), 2000));
    }

    handleChangeView(view) {
        this.setState({view});
    }

    isSectionActive(view) {
        return this.state.view === view;
    }

    isProfileFieldVisible(fieldName) {
        const channel = this.props.channel || {};
        const skipFields = channel.profile_skip_fields || [];

        return skipFields.indexOf(fieldName) === -1;
    }

    isSectionVisible(view, defaultVisibility = true) {
        const channel = this.props.channel || {};
        const settingSections = channel.profile_sections || [];

        if (view === 'advanced') {
            return this.props.showManageAlert;
        }

        // If no setting, default to true.
        if (settingSections.length === 0) {
            return defaultVisibility;
        }

        if (settingSections.indexOf(view) > -1) {
            return true;
        }

        return false;
    }

    renderPresence() {
        const {showPresence} = this.props;

        if (!showPresence) {
            return null;
        }

        const {user} = this.state;
        const profilePicture = (user || {}).profile_picture || {};

        const {pct:completePct, missing} = calcProfileCompletion(user || {}, this.isProfileFieldVisible.bind(this));
        const tierName = getTierName(user.tier || 0);
        const article = (tierName === 'Up-and-Comer' ? 'an' : 'a');
        const reach = formatNumber(user.verified_reach || 0);
        const reachK = formatNumberK(user.verified_reach || 0);
        const missingNames = missing.map(k => profileFieldKeyToName(k, this.props.translate));

        let completionTooltip;
        let label;
        if (missingNames.length > 0) {
            completionTooltip = <div>
                <p>Your profile is not yet complete.</p>
                <p>Complete the following fields:</p>
                <ul style={{textAlign: 'left'}}>
                    {missingNames.map(name => <li>{name}</li>)}
                </ul>
            </div>
            label = <i className="fa fa-exclamation-triangle" />;
        } else {
            completionTooltip = <span>Your profile is complete.</span>
            label = <i className="fa fa-check-circle" />;
        }

        return (

            <div className="edit-profile-presence-wrapper">
                <MiniProfile
                    imageUrl={profilePicture.original_url}
                    primary={user.name}
                    onClickAvatar={this.handleChangeView.bind(this, 'your-profile')}
                    switches={[
                        {
                            key: 'verified-reach',
                            label: reach,
                            icon: <i className="fa fas fa-bullhorn" />,
                            tooltip: "You have " + reach + " verified followers on social media.",
                            onClick: this.handleChangeView.bind(this, 'social-networks')
                        },
                        {
                            key: 'progress-completion',
                            label: label,
                            icon: <i className="fa fa-list" />,
                            tooltip: completionTooltip
                        }
                    ]}
                />
            </div>

        );
    }

    renderNav() {
        if (this.state.isFetching && !this.state.didFetch) {
            return null;
        }
        const _t = this.props.translate;

        const navItems = [
            {
                title: _t('contribute.profile.nav.basics', 'The Basics'),
                onClick: this.handleChangeView.bind(this, 'basics'),
                isActive: this.isSectionActive('basics'),
                isVisible: this.isSectionVisible('basics'),
            },
            {
                title: _t('contribute.profile.nav.profile', 'Your Profile'),
                onClick: this.handleChangeView.bind(this, 'your-profile'),
                isActive: this.isSectionActive('your-profile'),
                isVisible: this.isSectionVisible('profile'),
            },
            {
                title: _t('contribute.profile.nav.shipping_address', 'Shipping Address'),
                onClick: this.handleChangeView.bind(this, 'shipping-address'),
                isActive: this.isSectionActive('shipping-address'),
                isVisible: this.isSectionVisible('shipping-address'),
            },
            {
                title: _t('contribute.profile.nav.tags', 'Tags and Attributes'),
                onClick: this.handleChangeView.bind(this, 'facets'),
                isActive: this.isSectionActive('facets'),
                isVisible: this.isSectionVisible('attributes'),
            },
            {
                title: _t('contribute.profile.nav.social', 'Social Networks and Blogs'),
                onClick: this.handleChangeView.bind(this, 'social-networks'),
                isActive: this.isSectionActive('social-networks'),
                isVisible: this.isSectionVisible('social'),
            },
            {
                title: _t('contribute.profile.nav.custom', 'Custom Fields'),
                onClick: this.handleChangeView.bind(this, 'advanced'),
                isActive:  this.isSectionActive('advanced'),
                isVisible: this.isSectionVisible('advanced')
            },
            {
                title: _t('contribute.profile.nav.topics', "Topics"),
                onClick: this.handleChangeView.bind(this, 'allianz-profile'),
                isActive: this.isSectionActive('allianz-profile'),
                isVisible: this.isSectionVisible('allianz-profile', false),
            },
            {
                title: _t('contribute.profile.nav.subscriptions', "Subscriptions"),
                onClick: this.handleChangeView.bind(this, 'allianz-subscriptions'),
                isActive: this.isSectionActive('allianz-subscriptions'),
                isVisible: this.isSectionVisible('allianz-subscriptions', false),
            },
            {
                title: _t('contribute.profile.nav.password', 'Password'),
                onClick: this.handleChangeView.bind(this, 'password'),
                isActive: this.isSectionActive('password'),
                isVisible: this.isSectionVisible('password'),
            },
            {
                title: _t('contribute.profile.nav.account', 'Account Tools'),
                onClick: this.handleChangeView.bind(this, 'account-tools'),
                isActive: this.isSectionActive('account-tools'),
                isVisible: this.isSectionVisible('tools'),
            },
        ];

        const visibleNavItems = navItems.filter(i => i.isVisible);

        return (
            <div className="edit-profile-nav-pane col-xs-12 col-md-4">
                {this.renderPresence()}
                <NavStacked
                    isHorizontal={this.props.isMobile}
                    items={visibleNavItems}
                />
            </div>
        )
    }

    renderPane() {
        if (this.state.isFetching && !this.state.didFetch) {
            return null;
        }
        const {user} = this.state;
        const {createInteraction} = this.props;

        let content;
        switch (this.state.view) {
            case 'social-networks':
                content = <SocialNetworks
                    user={user}
                    refreshUser={this.refreshUser}
                    createInteraction={createInteraction}
                    translate={this.props.translate}
                />;
                break;
            case 'your-profile':
                content = <YourProfile
                    isProfileFieldVisible={this.isProfileFieldVisible}
                    user={user}
                    refreshUser={this.refreshUser}
                    createInteraction={createInteraction}
                    translate={this.props.translate}
                />;
                break;
            case 'location':
                content = <Location
                    isProfileFieldVisible={this.isProfileFieldVisible}
                    user={user} refreshUser={this.refreshUser} createInteraction={createInteraction}/>;
                break;
            case 'password':
                content = <Password
                    user={user}
                    refreshUser={this.refreshUser}
                    createInteraction={createInteraction}
                    translate={this.props.translate}
                />;
                break;
            case 'facets':
                content = <Facets
                    user={user}
                    refreshUser={this.refreshUser}
                    createInteraction={createInteraction}
                    isProfileFieldVisible={this.isProfileFieldVisible}
                    translate={this.props.translate}
                    channel={this.props.channel}
                    team={this.props.team}
                />;
                break;
            case 'account-tools':
                content = <AccountTools
                    privacyPolicyUrl={(this.props.channel || {}).privacy_policy_url || undefined}
                    user={user}
                    refreshUser={this.refreshUser}
                    createInteraction={createInteraction}
                    translate={this.props.translate}
                />;
                break;
            case 'allianz-subscriptions':
                content = <AllianzSubscriptions showTitle={false} />;
                break;
            case 'allianz-profile':
                content = <AllianzProfileFields
                    user={user}
                    refreshUser={this.refreshUser}
                    createInteraction={createInteraction}
                />;
                break;
            case 'advanced':
                content = <CustomFieldEditor
                    user={user}
                    refreshUser={this.refreshUser}
                    channel={this.props.channel}
                    createInteraction={this.props.createInteraction}
                />;
                break;
            case 'shipping-address':
                content = <ShippingAddress
                    user={user}
                    refreshUser={this.refreshUser}
                    createInteraction={createInteraction}
                    translate={this.props.translate}
                />;
                break;
            case 'basics':
            default:
                content = <Basics
                    isProfileFieldVisible={this.isProfileFieldVisible}
                    user={user}
                    refreshUser={this.refreshUser}
                    createInteraction={createInteraction}
                    translate={this.props.translate}
                />
        }

        return (
            <div className="edit-profile-view-pane col-xs-12 col-md-8">
                {content}
            </div>
        );
    }

    render() {
        const {isFetching, user} = this.state;
        const {showManageAlert} = this.props;
        const style = {...this.props.style, padding: this.props.padding ? 10 : 0};
        const passwordResetLink = <a href={`/manage/do/page/users/generateClaimToken?user=${user.id}`} className='v3 bold'>create a password reset link.</a>;

        return (
            <div className="edit-profile-wrapper" style={style}>
                {isFetching && <div className='alert tidal-alert alert-grey'><i className='v3 icon spinner'/>Loading...</div>}
                {!isFetching && showManageAlert && <Alert classes={['info']}
                                                          content={<span>{`You are editing ${user.name}'s profile. When finished, `}{passwordResetLink}</span>}
                                                          style={{borderTop: '1px solid #000'}}
                />}
                {this.renderNav()}
                {this.renderPane()}
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        channel: getCurrentChannel(state, props),
        team: getPresenceTeam(state, props),
        lang: state.i18n.lang
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (id, type, context) => dispatch(createUserInteraction(id, type, context)),
        translate: (key, defaultValue = '') => dispatch(translate(key, defaultValue))
    };
};

const ConnectedEditProfile = connect(mapStateToProps, mapDispatchToProps)(EditProfile);

export default ConnectedEditProfile;
