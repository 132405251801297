/**
 * Created by bkroger on 7/10/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class NavStacked extends Component {

    static defaultProps = {
        isHorizontal: false,
        showTags: true,
        showNumbers: false
    };

    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
            rightIcon: PropTypes.node,
            tag: PropTypes.node,
            style: PropTypes.object,
            content: PropTypes.node,
            classes: PropTypes.array,
            isActive: PropTypes.bool
        })).isRequired,
        isHorizontal: PropTypes.bool,
        showTags: PropTypes.bool,
        showNumbers: PropTypes.bool,
    };

    renderItemContent(spec, index) {

        const numbering = this.props.showNumbers ? <span className="list-item-number">{index + 1}.</span> : undefined;
        let titleWidth;
        if (this.props.isHorizontal === false) {
            titleWidth = spec.tag && typeof spec.tag !== 'undefined' ? 'calc(100% - 120px)' : 'calc(100% - 30px)';
        } else {
            titleWidth = 'auto';
        }

        if (spec.content) {
            return spec.content;
        } else {
            return (
                <a role="button" className="list-item" onClick={spec.onClick}>
                    {numbering}
                    <span style={{width: titleWidth, display: 'inline-block'}}>{spec.title}</span>
                    {(this.props.showTags && typeof spec.tag !== 'undefined') ? spec.tag : null}
                    {spec.rightIcon}
                </a>
            );
        }

    }

    renderItem(spec, index) {
        if (!spec) return;

        let style = spec.style ? spec.style : {};
        let classes = spec.classes || [];

        if (spec.isActive) {
            classes.push('active');
        }

        return (
            <li className={classes.join(' ')} style={style} key={index}>
                {this.renderItemContent(spec, index)}
            </li>
        )
    }

    renderItems() {
        return this.props.items.map((item, index) => this.renderItem(item, index));
    }

    render() {

        let navClass = this.props.isHorizontal ? 'nav-horizontal' : 'nav-stacked';

        return (
            <ul className={"v3 nav nav-tabs " + navClass + " tidal-nav-tabs light"}>
                {this.renderItems()}
            </ul>
        )
    }

}