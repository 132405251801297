/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import d3 from 'd3';
import C3Chart from 'react-c3js';
import {c3ColorPattern} from '../../../utilities';

export default class CampaignInvitationStatsChart extends Component {

    constructor(props) {
        super(props);
    }

    getData() {
        const {campaign, report} = this.props;
        if (!campaign.lastUpdatedReport || campaign.isFetchingReport) {
            return null;
        }

        const invitationStats = (report.invitation_stats || {});

        if (Object.keys(invitationStats).length < 3) {
            return null;
        }
        const campaignStats = invitationStats[this.props.campaign.id].probabilities;
        const channelStats = invitationStats['channel'].probabilities;
        const allStats = invitationStats['all'].probabilities;

        let out = {
            x: 'x',
            type: 'bar',
            columns: [
                ['x', 'Invites Accepted', 'Invites to Previously Activated User', 'Odds of Repeat User Accepting Invite', 'Odds of New User Accepting Invite', 'Odds an Accepted Invite Was From Previously Activated User'],
                [this.props.campaign.name, campaignStats.P_A, campaignStats.P_B, campaignStats.P_A_B, campaignStats.P_A_notB, campaignStats.P_B_A],
                ['Your Channel', channelStats.P_A, channelStats.P_B, channelStats.P_A_B, channelStats.P_A_notB, channelStats.P_B_A],
                ['Benchmark', allStats.P_A, allStats.P_B, allStats.P_A_B, allStats.P_A_notB, allStats.P_B_A],
            ]
        };

        return out;
    }

    renderChart() {
        const data = this.getData();
        if (!data) return null;

        const axis = {
            x: {type: 'category'},
            y: {show: false}
        };

        const tooltip = {
            format: {
                value: (value, ratio, id) => {
                    return d3.format('%')(value);
                }
            }
        };

        return <C3Chart
            data={data}
            color={c3ColorPattern}
            axis={axis}
            tooltip={tooltip}
        />


    }

    render() {

        return (

            <div className="widget">
                <div className="title">
                    <div className="icon left">
                        <i className="fa fa-line-chart"></i>
                    </div>
                    <h2>
                        Invitation Stats
                    </h2>
                </div>
                <div className="content padded clearfix">
                    {this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
                </div>
            </div>

        );
    }
}

CampaignInvitationStatsChart.propTypes = {
    campaign: PropTypes.object,
    report: PropTypes.object
};
