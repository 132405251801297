import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {limitChars} from "../../../utilities";
import Tooltip from "../../Common/Tooltip";
import Toolbox from "../../Common/Toolbox";
import DropdownButton from '../../Common/Form/DropdownButton';
import _find from 'lodash/find';

const makeRows = (items) => {
    return items.filter(spec => spec.isValid())
        .map(spec => (<div className='fake-li padded bottom-border' key={spec.label}>{spec.label}
            <span className='pull-right'>{spec.value}</span>
        </div>))
};

class PostDetailSidebarModeration extends Component {
    static propTypes = {
        post: PropTypes.object,
        campaigns: PropTypes.array,
        sections: PropTypes.object,

        onPublishChannel: PropTypes.func,
        onPublishCampaign: PropTypes.func,
        onRejectCampaign: PropTypes.func,
        onFeature: PropTypes.func,
        onComplete: PropTypes.func,
        onEditSection: PropTypes.func,

    };

    renderCampaignDropdown() {
        const {campaigns} = this.props;
        const unpublishedCampaigns = (campaigns || [])
            .filter(campaign => campaign.status !== 'Published')
            .map(campaign => {
                const {campaignName, campaignSlug} = campaign;
                const onClick = () => {
                    this.props.onPublishCampaign(campaignSlug, 'add_post_to_campaign');
                    this.props.onComplete();
                };
                return <div className='fake-li bottom-border condensed' key={`post-detail-sidebar-campaign=${campaignSlug}`}>
                    <a role='button' onClick={onClick}>{campaignName}</a>
                </div>;
            });

        if (unpublishedCampaigns.length === 0) return;

        return <DropdownButton
            content='Add to Campaign'
            dropdown= {<div className='dropdown-content-wrapper'>{unpublishedCampaigns}</div>}
            classes={['btn-secondary', 'v3', 'small', (unpublishedCampaigns.length === 0 ? 'disabled' : '')]}
            style={{padding: '0 10px', fontSize: 14}}
        />
    };

    renderCmsStatus() {
        const post = this.props.post || {};
        const {human_cms_status} = post;

        let title = 'Unpublish', iconClass = 'v3 icon cancel', type = 'unpublish', style={display: 'inline'};

        if (human_cms_status !== 'Published') {
            if (human_cms_status === 'Submitted' || this.props.hasOverridePermission) {
                title = 'Publish';
                iconClass = 'v3 icon success';
                type = 'publish';
            } else {
                title = '';
                iconClass = '';
                style = {display: 'none'};
            }
        }

        return <span>{human_cms_status}
                <i className={iconClass} onClick={async () => {
                    await this.props.onPublishChannel(this.props.post.id, false);
                    this.props.onComplete();
                }}/>
            </span>;

    }

    renderFeatured() {
        const is_featured = (this.props.post || {}).is_featured;
        const text = is_featured ? 'Featured' : 'Not Featured';
        const action = () => this.props.onFeature(!is_featured);
        const title = is_featured ? 'Un-Feature Post' : 'Feature Post';
        const icon = 'v3 icon star';
        const style = {color: (is_featured ? '#6C0' : undefined)};

        return (
            <><Tooltip title={title}><a role="button" onClick={action}><i className={icon} style={style}/> {text}</a></Tooltip></>
        );
    }

    render() {
        const {campaigns, onComplete, } = this.props;
        const { sections, human_cms_status} = this.props.post;

        const campaignList = (campaigns || [])
            .filter(campaign => campaign.status !== '-')
            .map(campaign => {
                const {campaignName, campaignSlug, status} = campaign;
                let title1 = 'Reject', iconClass1= '', onClick1 = undefined, style1 = {display: 'none'};
                let title2 = 'Accept', iconClass2= '', onClick2 = undefined, style2 = {display: 'none'};

                switch (status) {
                    case 'Published':
                        style1 = {display: 'inline'};
                        title1 = 'Unpublish';
                        iconClass1 = 'v3 icon cancel';
                        onClick1 = async () => {
                            this.props.onPublishCampaign(campaignSlug, 'remove_post_from_campaign');
                            onComplete();
                        };
                        break;
                    case 'Submitted':
                        style1 = {display: 'inline'};
                        title1 = 'Reject';
                        iconClass1 = 'v3 icon cancel';
                        onClick1 = () => {
                            // BK note -- we don't really use Reject status, and removing is way more useful.
                            // this.props.onRejectCampaign(campaignSlug);
                            this.props.onPublishCampaign(campaignSlug, 'remove_post_from_campaign');
                            onComplete();
                        };
                    //purposeful exclusion of a break statement - we want both tooltip1 & tooltip2 if status === submitted
                    default:
                        style2 = {display: 'inline'};
                        title2 = 'Publish';
                        iconClass2 = 'v3 icon success';
                        onClick2 = () => {
                            this.props.onPublishCampaign(campaignSlug, 'add_post_to_campaign');
                            onComplete();
                        };
                        break;
                }

                return {
                    label: `${limitChars(campaignName, 90)}:`,
                    value: <span>{status}
                        <Tooltip title={title1} style={style1}>
                            <i className={iconClass1} onClick={onClick1}/>
                        </Tooltip>
                        <Tooltip title={title2} style={style2}>
                            <i className={iconClass2} onClick={onClick2}/>
                        </Tooltip>
                    </span>,
                    isValid: () => true
                }
            });

        const sectionList = (sections || [])
            .map(mongoId => {
                const section = _find(this.props.sections || {}, {mongo_id: mongoId});
                if (!section) {
                    return null;
                }
                const name = section.name;

                return {
                    label: `${limitChars(name, 90)}:`,
                    value: (
                        <span>Published
                            <Tooltip title='Unpublish'>
                                <i className='v3 icon cancel' onClick= {() => {
                                    this.props.onEditSection(mongoId, false);
                                    onComplete();
                                }}/>
                            </Tooltip>
                        </span>
                    ),
                    isValid: () => true
                }
            })
            .filter(sectionItem => !!sectionItem);

        const publicationItems = [
            {
                label: 'Marked Spam:',
                value: (<Tooltip title="This post was marked as spam."><i className="v3 icon error" /> Spam</Tooltip>),
                isValid: () => !!((this.props.post || {}).metadata || {}).spam
            },
            ...campaignList,
            {
                label: 'CMS Status:',
                value: this.renderCmsStatus(),
                isValid: () => !!human_cms_status
            },
            {
                label: 'Featured:',
                isValid: () => true,
                value: this.renderFeatured(),

            },
            ...sectionList,
        ];

        const content = makeRows(publicationItems);

        return (
            (content && content.length)
                ? <Toolbox
                    embedded={true}
                    title={<div className='post-detail-overview-toolbox-title'>
                        <div>Moderation</div>
                        {this.renderCampaignDropdown()}
                    </div>}
                    addlClasses='post-detail-overview-toolbox'
                    content={<div className='post-detail-overview-toolbox-content'>{content}</div>}
                />
                : null
        )

    }
}

export default PostDetailSidebarModeration;