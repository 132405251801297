import React, {Component} from 'react';
import PropTypes from 'prop-types';
import HamburgerMenu from "../../Common/DetailView/HamburgerMenu";

class PostDetailActionsMenu extends Component {
    static propTypes = {
        post: PropTypes.object,
        sections: PropTypes.array,
        hasOverridePermission: PropTypes.bool,
        onActionStarted: PropTypes.func,
        onActionFinished: PropTypes.func,
        onToggleItem: PropTypes.func,
        onToggleMenu: PropTypes.func,
        showMenu: PropTypes.bool,
        isRunningAction: PropTypes.bool,

        onPublishChannel: PropTypes.func,
        onFeature: PropTypes.func,
        onBump: PropTypes.func,
        onMarkSpam: PropTypes.func,
    }

    makeMenuItems() {
        const {post, sections, hasOverridePermission} = this.props;
        return [
            {
                name: 'publishCampaign',
                title: 'Publish on Campaign',
                icon: 'fas fa-plus-circle',
                isValid: true,
            },
            {
                name: 'publishChannel',
                title: post.human_cms_status === 'Published' ? 'Un-publish on CMS' : 'Publish on CMS',
                icon: 'fas fa-plus-circle',
                isValid: (true || hasOverridePermission),
                onClick: () => this.props.onPublishChannel(!post.is_published)
            },
            {
                name: 'export',
                title: 'Export Post',
                icon: 'fas fa-file-export',
                href: `/manage/do/page/posts/export?slug=${post.slug}`,
                isValid: true,
                onClick: () => {}
            },
            {
                name: 'manageSections',
                title: 'Manage Sections',
                icon: 'fas fa-tags',
                isValid: !!(Object.keys(sections).length),
            },
            {
                name: 'edit',
                title: 'Edit',
                icon: 'fas fa-edit',
                href: `/manage/do/page/posts/editv3?slug=${post.slug}`,
                isValid: true,
                onClick: () => {}
            },
            {
                name: 'feature',
                title: post.is_featured ? 'Unfeature' : 'Feature',
                icon: 'v3 icon star',
                isValid: true,
                onClick: () => this.props.onFeature(!post.is_featured)
            },
            {
                name: 'bump',
                title: 'Bump',
                icon: 'fas fa-level-up-alt',
                isValid: true,
                onClick: () => this.props.onBump()
            },
            {
                name: 'schedule',
                title: !!post.is_scheduled ? 'Unschedule' : 'Schedule',
                icon: 'v3 icon calendar',
                isValid: false,
            },
            {
                name: 'add/remove flag',
                title: 'add/remove flag',
                icon: null,
                isValid: false,
            },
            {
                name: 'promote',
                title: 'promote',
                icon: null,
                isValid: false,
            },
            {
                name: 'spam',
                title: 'Mark Spam',
                icon: 'v3 icon ban',
                isValid: true,
                onClick: () => this.props.onMarkSpam()
            }
        ]
    }

    async handleRunAction(item) {
        if (this.props.onActionStarted) {
            this.props.onActionStarted(item);
        }

        if (item.onClick) {
            await item.onClick();
        }

        if (this.props.onActionFinished) {
            this.props.onActionFinished(item);
        }
    }

    render() {
        const menuItems = this.makeMenuItems();
        const content = menuItems.filter(item => !!item.isValid)
            .map(item => {
                return <div className='fake-li bottom-border' key={item.title}>
                    <a
                        role="button"
                        href={item.href}
                        onClick={item.onClick ? () => this.handleRunAction(item) : () => this.props.onToggleItem(item)}
                    >
                        <i className={item.icon || ''} style={{marginRight: 8}}/>
                        {item.title}
                    </a>
                </div>
            });

        return <HamburgerMenu
            toggleMenu={this.props.onToggleMenu}
            expanded={this.props.showMenu}
            content={<>{content}</>}
            icon={this.props.isRunningAction ? 'fa spinner spin' : undefined}
        />

    }
}

export default PostDetailActionsMenu;