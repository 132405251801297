import React, {Component} from 'react';
import FormSelect from "../../Common/Form/Select";
import {CHANNEL_STATUSES} from "../../../utilities";

function UserChannelStatusSelect({status, onChange, style, classes}) {

    const options = Object.entries(CHANNEL_STATUSES)
        .map(entry => ({value: parseInt(entry[0], 10), text: entry[1]}));
    return (
        <FormSelect
            addlClasses={classes}
            wrapperStyle={style}
            options={options}
            onChange={onChange}
            value={status}
        />
    )
}

export default UserChannelStatusSelect;
