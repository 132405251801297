/**
 * Created by bkanber on 8/7/17.
 */

import React, {Component} from 'react';
import {formatNumber, limitChars} from '../../../utilities';

export default class UserProfileTable extends Component {

    renderTable() {
        /**
         * x Interests
         * x Category
         * x Blog
         * x Blog Traffic
         * x Instagram
         * x Price
         * x Content Rating
         * x Total Reach
         */
        const {user} = this.props;
        const {profile, basics} = user;
        return (

            <div className="user-profile-table">

                {
                    (profile && profile.interests && profile.interests.length > 0) &&
                    <div className="fake-li bottom-border">
                        <span>Interests:</span>
                        <span className="pull-right">{profile.interests.slice(0, 2).join(', ').substr(0, 25)}...</span>
                    </div>
                }

                {
                    (profile && profile.facets && profile.facets.Category) &&
                    <div className="fake-li bottom-border">
                        <span>Category:</span>
                        <span className="pull-right">{profile.facets.Category}</span>
                    </div>
                }

                { profile && profile.blog_name &&
                <div className="fake-li bottom-border">
                    <span>Blog:</span>
                    <span className="pull-right">
                        <a href={profile.blog_url} target="_blank">{limitChars(profile.blog_name, 20)}</a>
                    </span>
                </div>
                }

                { (profile && profile.blog_traffic) ?
                    (<div className="fake-li bottom-border">
                        <span>Blog Traffic:</span>
                        <span className="pull-right">{formatNumber(profile.blog_traffic)} visitors / mo</span>
                    </div>) : ''
                }

                { (profile && profile.instagram_followers) ?
                    (<div className="fake-li bottom-border">
                        <span>Instagram:</span>
                        <span className="pull-right">
                            <a href={"https://instagram.com/" + profile.instagram_slug} target="_blank">
                            {formatNumber(profile.instagram_followers)} followers
                            </a>
                        </span>
                    </div>) : ''
                }

                { (basics && basics.price) ?
                    (<div className="fake-li bottom-border">
                        <span>Price:</span>
                        <span className="pull-right">{basics.price}</span>
                    </div>) : ''
                }

                { (profile && profile.avg_post_rating) &&
                <div className="fake-li bottom-border">
                    <span>Content Rating:</span>
                    <span className="pull-right">{profile.avg_post_rating}</span>
                </div>
                }

                { (basics && basics.verified_reach) ?
                    (<div className="fake-li bottom-border">
                        <span>Total Reach:</span>
                        <span className="pull-right">{formatNumber(basics.verified_reach)}</span>
                    </div>) : ''
                }

            </div>

        );
    }

    render() {
        return this.renderTable();
    }
}
