import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatNumber, average} from "../../../utilities";

class UserListTable extends Component {

    componentDidMount() {
        this.props.fetchUserProfile()
            .then(() => {
                this.props.fetchUserLists();
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.user.didInvalidateLists && !prevProps.user.didInvalidateLists) {
            this.props.fetchUserLists();
        }

        if (this.props.user.didInvalidateProfile && !prevProps.user.didInvalidateProfile) {
            this.props.fetchUserProfile();
        }
    }

    renderListRow(list) {

        const url = '/manage/do/page/lists/view?id=' + list.id;
        const entry = list.entry || {};
        const scores = entry.scores || [];
        const filteredScores = scores.filter(score => score.score !== null).map(score => score.score);
        const avgScore = filteredScores.length > 0 ? (average(filteredScores) / 2) : null;
        const filteredNotes = scores.filter(score => score.note !== null).map(score => score.note);

        return (
            <tr>
                <td>
                    {
                        list.type === 'badge' ?
                            <span><i className="fa fa-certificate"></i> Badge</span> :

                            (list.is_hidden ?
                                    <span><i className="fa fa-eye-slash"></i> Hidden</span> :

                                    (list.smart ?
                                        <span><i className="fa fa-magic"></i> Smart</span> :
                                        <span><i className="fa fa-list"></i> Manual</span>)
                            )
                    }
                </td>
                <td>
                    <a href={url}>
                        {list.type === 'badge' ? 'Badge: ' : ''}{list.name}
                    </a>
                </td>
                <td>
                    {avgScore ? formatNumber(avgScore) + ' / 5' : '-'}
                </td>
                <td>
                    {filteredNotes.length > 0 ? filteredNotes.join(" / ") : '-'}
                </td>
                <td>
                    {formatNumber(list.users_count)}
                </td>
            </tr>
        );
    }

    render() {
        const {user} = this.props;

        if (user.isFetchingLists) {
            return <div className="alert alert-grey tidal-alert">Loading lists...</div>;
        }

        let lists = this.props.lists.map(list => Object.assign({}, list, {type: 'list'}));
        let badges = this.props.badges.map(badge => Object.assign({}, badge, {type: 'badge'}));
        let listsAndBadges = lists.concat(badges);

        let sorted = listsAndBadges.sort((a, b) => {
            return parseInt(a.users_count, 10) > parseInt(b.users_count, 10) ? -1 : 1;
        });

        return (
            <div className="table-wrapper">
                <table className={this.props.tableClass}>

                    <thead>
                    <tr>
                        <th style={{minWidth: 120}}>Type</th>
                        <th>Name</th>
                        <th>Score</th>
                        <th>Notes</th>
                        <th style={{minWidth: 120}}>Total Users</th>
                    </tr>
                    </thead>
                    <tbody>

                    {sorted.map(list => this.renderListRow(list))}

                    </tbody>

                </table>
            </div>
        );
    }
}

UserListTable.defaultProps = {
    tableClass: 'table'
};

UserListTable.propTypes = {
    user: PropTypes.object.isRequired,
    badges: PropTypes.array.isRequired,
    lists: PropTypes.arrayOf(PropTypes.shape({
        entry: PropTypes.object.isRequired
    })).isRequired,
    tableClass: PropTypes.string,

    fetchUserLists: PropTypes.func.isRequired,
    fetchUserProfile: PropTypes.func.isRequired
};

export default UserListTable;