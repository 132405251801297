import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../Common/Form/Form";

export default class SimpleCreateUserForm extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        isSubmitting: PropTypes.bool,
    };

    static defaultProps = {
        isSubmitting: false,
    };

    state = {
        email: '',
        name: '',

        blog_url: '',
        blog_name: '',

        instagram_handle: '',
        twitter_handle: '',
        pinterest_handle: '',
        youtube_handle: '',
        facebook_page_handle: '',
        tiktok_handle: '',

        isValid: false,
        invalidFields: ['name', 'email'],
        showAdditionalFields: false,
    };

    makeUserData() {
        let data = {
            email: this.state.email,
            name: this.state.name,
            shadow: false,
            socials: [],
        };

        if (this.state.blog_name && this.state.blog_name.length > 0) {
            data.blog_name = this.state.blog_name;
        }

        if (this.state.blog_url && this.state.blog_url.length > 0) {
            data.blog_url = this.state.blog_url;
        }

        if (this.state.instagram_handle && this.state.instagram_handle.length > 0) {
            data.socials.push({type: 'Tidal\\Social\\Instagram', handle: this.state.instagram_handle});
        }

        if (this.state.tiktok_handle && this.state.tiktok_handle.length > 0) {
            data.socials.push({type: 'Tidal\\Social\\TikTok', handle: this.state.tiktok_handle});
        }

        if (this.state.twitter_handle && this.state.twitter_handle.length > 0) {
            data.socials.push({type: 'Tidal\\Social\\Twitter', handle: this.state.twitter_handle});
        }

        if (this.state.pinterest_handle && this.state.pinterest_handle.length > 0) {
            data.socials.push({type: 'Tidal\\Social\\Pinterest', handle: this.state.pinterest_handle});
        }

        if (this.state.youtube_handle && this.state.youtube_handle.length > 0) {
            data.socials.push({type: 'Tidal\\Social\\Youtube', handle: this.state.youtube_handle});
        }

        if (this.state.facebook_page_handle && this.state.facebook_page_handle.length > 0) {
            data.socials.push({type: 'Tidal\\Social\\FacebookPage', handle: this.state.facebook_page_handle});
        }

        return data;

    }

    handleSubmit() {
        if (this.props.isSubmitting) {
            return;
        }

        if (this.props.onSubmit) {
            this.props.onSubmit(this.makeUserData());
        }
    }

    getAdditionalFieldsSpec() {
        if (!this.state.showAdditionalFields) {
            return [];
        }

        return [

            {
                type: 'text',
                name: 'blog_url',
                title: 'Blog URL',
                placeholder: 'eg: http://burakkanber.com',
                required: false,
            },
            {
                type: 'text',
                name: 'blog_name',
                title: 'Blog Name',
                placeholder: 'eg: Blogging for Life',
                required: false,
            },
            {
                type: 'text',
                name: 'instagram_handle',
                title: 'Instagram Handle',
                placeholder: 'eg: bkanbertidal',
                required: false,
            },
            {
                type: 'text',
                name: 'tiktok_handle',
                title: 'TikTok Handle',
                placeholder: 'eg: bkbkbk1212',
                required: false,
            },
            {
                type: 'text',
                name: 'twitter_handle',
                title: 'Twitter Handle',
                placeholder: 'eg: bkanber',
                required: false,
            },
            {
                type: 'text',
                name: 'pinterest_handle',
                title: 'Pinterest Handle',
                placeholder: 'eg: bkanber',
                required: false,
            },
            {
                type: 'text',
                name: 'facebook_page_handle',
                title: 'Facebook Page URL',
                placeholder: 'eg: https://www.facebook.com/tidal.labs',
                required: false,
            },
            {
                type: 'text',
                name: 'youtube_handle',
                title: 'YouTube Channel URL',
                placeholder: 'eg: https://www.youtube.com/channel/UC6hOVYvNn79Sl1Fc1vx2mYA',
                required: false,
            },
        ];
    }

    render() {

        return (
            <Form
                onFieldChange={(key, value) => this.setState({[key] : value})}
                values={{...this.state}}
                onValidation={({isValid, invalidFields}) => this.setState({isValid, invalidFields})}
                invalidFields={this.state.invalidFields.map(f => f.name)}
                fields={[
                    {
                        type: 'text',
                        name: 'name',
                        title: 'Name',
                        placeholder: 'eg: John Smith',
                        required: true,
                    },
                    {
                        type: 'text',
                        name: 'email',
                        title: 'Email Address',
                        placeholder: 'eg: john@smith.com',
                        required: true,
                    },
                    {
                        type: 'generic',
                        content: (
                            <a
                                className="v3 h6 bold dark"
                                onClick={() => this.setState({showAdditionalFields: !this.state.showAdditionalFields})}
                                role="button"
                            >{this.state.showAdditionalFields ? 'Hide' : 'Show'} Additional Fields <i className={'v3 icon ' + (this.state.showAdditionalFields ? 'angle-down' : 'angle-right')} /></a>
                        )
                    },
                    ...this.getAdditionalFieldsSpec(),
                    {
                        type: 'button',
                        name: 'submit',
                        title: this.props.isSubmitting ? 'Creating User...' : 'Create User',
                        options: {
                            onClick: this.handleSubmit.bind(this),
                            hideTitle: true,
                            classes: ['v3', 'btn-primary', (this.props.isSubmitting ? 'disabled' : '')]
                        }
                    }
                ]}
            />
        );

    }
}