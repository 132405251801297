/**
 * Created by vosgotti on 12/3/17.
 * based on the Sort.js for the Users table
 */

import React, {Component} from 'react';
import { POST_TABLE_TABS } from './Table';

class PostTableSort extends Component {

    constructor(props) {
        super(props);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    renderSortIcon(tabDef) {
        if (typeof tabDef.sortBy === 'undefined')
            return null;


        let dir = "fa fa-angle-down";

        if (this.props.sort && this.props.sort.by && this.props.sort.by === tabDef.sortBy) {

            if (this.props.sort.dir === 'asc') {
                dir = "fa fa-angle-up";
            }

        }

        return <a className="sort" href="#"><i className={dir}></i></a>

    }


    renderTab(tabDef) {

        const currentSort = this.props.sort;
        const style = Object.assign({minWidth: '80px'}, tabDef.style || {});
        const title = (typeof tabDef.title !== 'undefined') ? tabDef.title : tabDef.name;

        let className = "posts-table-sort-tab";
        let sortDir = tabDef.sortDir ? tabDef.sortDir : 'asc';


        if (currentSort && currentSort.by && currentSort.by === tabDef.sortBy) {
            className += " active";
            if (currentSort.dir === 'asc') {
                sortDir = 'desc';
            } else {
                sortDir = 'asc';
            }
        }

        if (typeof tabDef.sortBy !== 'undefined') {
            className += ' sortable';
        }

        /**
         * Handle special case of cog icon
         */
        let clickHandler = this.handleSortChange.bind(this, tabDef.sortBy ? tabDef.sortBy : null, sortDir);
        if (tabDef.name === 'Expanded') {
            clickHandler = this.props.onToggleSettings;
        }

        return (
            <div
                className={className}
                style={style}
                onClick={clickHandler}
            >

                {title}

                {tabDef.sortBy &&
                this.renderSortIcon(tabDef)
                }
            </div>
        );

    }

    getSelectedColumnsDefs() {
        return POST_TABLE_TABS.filter(tabdef => {
            if (tabdef.alwaysShow) return true;
            if (this.props.selectedColumns.indexOf(tabdef.name) > -1) return true;
            return false;
        });
    }

    getTotalRequiredWidth() {
        return this.getSelectedColumnsDefs().reduce((sum, value) => {
            let style = value.style || {};
            let minWidth = style.minWidth || '80px';
            let val = parseInt(minWidth.replace('px', ''));
            return sum+val+1;
        }, 0);
    }

    renderTabs() {

        const tabs = this.getSelectedColumnsDefs().map(td => this.renderTab(td));
        const minWidth = this.getTotalRequiredWidth();
        const style = {minWidth};

        return (
            <div className="posts-table-sort-tabs" style={style}>
                {tabs}
            </div>
        )

    }

    handleSortChange(by, dir = 'asc', event) {
        if (typeof event !== 'undefined') {
            event.preventDefault();
        }

        if (typeof this.props.onSortChange === 'function') {
            let sort = {by, dir};
            this.props.onSortChange(sort, true);
        }
    }

    render() {
        return (
            <div className="posts-table-sort">
                {this.renderTabs()}
            </div>
        )

    }
}

export default PostTableSort;
