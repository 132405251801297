import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../../../actions/auth";
import {fetchPresence} from "../../../../actions/presence";
import {fetchBadges} from "../../../../actions";
import DashboardTrending from "../../../MAC/Creator/DashboardTrending";
import DashboardSpotlight from "../../../MAC/Creator/DashboardSpotlight";
import {translate} from "../../../../actions/i18n";

function MACCreatorTrendingPage({fetchAuthenticated, translate, isLoading}) {

    return (
        <div className="mac-wrapper">
            <div className="dashboard">

                {!isLoading && (
                    <div className="community-page-wrapper trending-page-wrapper">

                        <DashboardTrending fetchAuthenticated={fetchAuthenticated} postLimit={15} translate={translate} />
                        <DashboardSpotlight fetchAuthenticated={fetchAuthenticated} postLimit={10} translate={translate} />

                    </div>
                )}


            </div>
        </div>
    )
}

export default connect(
    function (state, props) {
        return {
            isLoading: !state.i18n.didFetch
        };
    },
    function (dispatch) {
        return bindActionCreators({
            fetchAuthenticated: fetchAuthenticated,
            fetchPresence: fetchPresence,
            fetchBadges: fetchBadges,
            translate: translate,
        }, dispatch);
    }
)(MACCreatorTrendingPage);