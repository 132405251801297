import {receiveItems, modifyItem, receiveItem} from "../utilities/reducer";
import {combineReducers} from 'redux';
import {makeQueriesReducerFor} from './queries';
import * as ActionTypes from '../actions/types';

export const DEFAULT_VIDEOS_QUERY = {
    id: "default",
    sort: {by: "created_at", dir: "desc"},
    limit: 12,
    page: 1,
    filters: {},
    context: {},
    pages: {}
};

const queries = makeQueriesReducerFor('VIDEOS_');

// New version of reducer, not exported because old version still in use
const videosById = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.VIDEOS_RECEIVE_QUERY:
            return receiveItems(state, action);
        default:
            return state;
    }
};

export const videos = combineReducers({
    queries,
    items: videosById,
});
