import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';

export default class FormRange extends Component {

    static defaultProps = {
        min: 0,
        max: 100,
        step: 1,
        formatLabel: (value) => '',
    };

    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.object,
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        formatLabel: PropTypes.func,
    };

    getCleanValue() {
        let cleanValue = this.props.value ? Object.assign({}, this.props.value) : null;
        if (!cleanValue) {
            cleanValue = {min: this.props.min, max: this.props.max};
        }
        // if either min or max is missing, set them to the default values
        if (!cleanValue.min) {
            cleanValue.min = this.props.min;
        }
        if (!cleanValue.max) {
            cleanValue.max = this.props.max;
        }
        return cleanValue;
    }
    render() {
        return (
            <InputRange
                step={this.props.step}
                maxValue={this.props.max}
                minValue={this.props.min}
                value={this.getCleanValue()}
                onChange={this.props.onChange}
                formatLabel={this.props.formatLabel}
            />
        );
    }
}
