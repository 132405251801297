export const parseContentIntoSections = (content) => {

    let str = content || '';
    let output = [];

    while (str.length > 0) {
        let pos = str.indexOf('{{refs:');
        let endPos = str.indexOf('}}');

        if (pos > -1) {
            output.push({type: 'content', value: str.substr(0, pos)});
            output.push({type: 'widget', value: str.substr(pos+7, (endPos-pos-7))});
            str = str.substr(endPos+2);
        } else {
            output.push({type: 'content', value: str});
            str = '';
        }
    }

    return output;
}