/**
 * Created by bkroger on 4/26/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReviewOfferTemplate from '../Templates/ReviewOffer';
import ProductPreferencesTemplate from '../Templates/ProductPreferences';
import AdditionalInformationTemplate from '../Templates/AdditionalInformation';
import UserDeclinedInvitation from '../Templates/UserDeclinedInvitation';
import PostsPerChannel from '../Templates/PostsPerChannel';
import PaymentDetailsTemplate from '../Templates/PaymentDetails';
import SubmitDraftTemplate from '../Templates/SubmitDraft';
import ShippingAddress from '../Templates/ShippingAddress';
import CouponCode from '../Templates/CouponCode';
import SelectProducts from '../Templates/SelectProducts';
import ReceiveProducts from '../Templates/ReceiveProducts';
import UploadImages from '../Templates/UploadImages';
import Waitlisted from '../Templates/Waitlisted';
import Canceled from "../Templates/Canceled";
import SubmitOtherPosts from "../Templates/SubmitOtherPosts";
import SubmitInstastory from "../Templates/SubmitInstastory";

import {connect} from 'react-redux';
import UploadVideos from "../Templates/UploadVideos";
import Fulfilled from "../Templates/Fulfilled";
import {getContentRequirementsHtml} from "../../../../utilities/campaign";
import Alert from "../../../Common/Alert";
import CompleteYourProfile from "../Templates/CompleteYourProfile";
import SubmitTiktok from "../Templates/SubmitTiktok";
import GenerateAffiliateLink from "../Templates/GenerateAffiliateLink";
import Ensure1099Policy from "../Templates/Ensure1099Policy";
import SignNDA from "../Templates/SignNDA";

class InfluencerRequirementsActionPane extends Component {

    static propTypes = {
        view: PropTypes.object,
        activation: PropTypes.object.isRequired,
        campaign: PropTypes.object.isRequired,
        presence: PropTypes.object.isRequired,
        channel: PropTypes.object.isRequired,
        refreshActivation: PropTypes.func,
        createInteraction: PropTypes.func,
        showRequirementCompleteAlert: PropTypes.bool,
        onCloseCompleteAlert: PropTypes.func,
        onClickNextRequirement: PropTypes.func,
        refreshUser: PropTypes.func,
        user: PropTypes.object,
    };

    makeTwigContext() {
        return {
            channel: this.props.channel,
            campaign: this.props.campaign,
            content_requirements: getContentRequirementsHtml(this.props.activation),
            payment_offered: this.props.activation.payment_offered,
        };
    }

    getDefaultPropBindings() {

        const {campaign, activation, refreshActivation, createInteraction, user, refreshUser, translate} = this.props;
        return {
            campaign,
            activation,
            user,
            refreshUser,
            refreshActivation,
            createInteraction,
            translate
        }
    }

    renderViewTemplate() {

        const {view} = this.props;

        if (typeof view !== 'undefined' && view && typeof view.key !== 'undefined') {

            let pane = null;


            switch (view.key) {

                case 'UserDeclinedInvitation':
                    pane = <UserDeclinedInvitation
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                case 'Canceled':
                    pane = <Canceled
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                case 'Fulfilled':
                    pane = <Fulfilled
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                case 'Waitlisted':
                    pane = <Waitlisted
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                case 'SubmitVideos':
                    pane = <UploadVideos
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                case 'SubmitImages':
                    pane = <UploadImages
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                case 'SubmitDrafts':
                    pane = <SubmitDraftTemplate
                        {...this.getDefaultPropBindings()}
                    />;
                    break;


                case 'SubmitInstagramPosts':
                case 'SubmitTwitterPosts':
                case 'SubmitPinterestPosts':
                case 'SubmitYoutubePosts':
                case 'SubmitFacebookPosts':
                case 'SubmitFacebookPagePosts':
                case 'SubmitBlogPosts':
                case 'SubmitTiktokPosts':
                case 'SubmitInstastory':
                    pane = <PostsPerChannel
                        key={view.key}
                        view={view}
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                case 'SubmitSocialPosts':
                    pane = <SubmitOtherPosts
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                case "SubmitPaymentDetails":

                    pane = <PaymentDetailsTemplate
                        {...this.getDefaultPropBindings()}
                    />;

                    break;


                case "SubmitAdditionalInformation":

                    pane = <AdditionalInformationTemplate
                        {...this.getDefaultPropBindings()}
                    />;

                    break;

                case "SubmitShippingAddress":

                    pane = <ShippingAddress
                        {...this.getDefaultPropBindings()}
                    />;

                    break;

                case "ClaimCouponCode":

                    pane = <CouponCode
                        twigContext={this.makeTwigContext()}
                        {...this.getDefaultPropBindings()}
                    />;

                    break;

                case "SelectProduct":

                    pane = <SelectProducts
                        twigContext={this.makeTwigContext()}
                        {...this.getDefaultPropBindings()}
                    />;

                    break;

                case 'ReceiveProduct':
                    pane = <ReceiveProducts
                        {...this.getDefaultPropBindings()}
                        />;
                    break;

                case 'ReviewOffer':
                    pane = <ReviewOfferTemplate
                        twigContext={this.makeTwigContext()}
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                case 'GenerateAffiliateLink':
                    pane = <GenerateAffiliateLink
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                case 'Ensure1099Policy':
                    pane = <Ensure1099Policy
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                // Old, fully manual instastory submission
                // case 'SubmitInstastory':
                //     pane = <SubmitInstastory
                //         {...this.getDefaultPropBindings()}
                //         />;
                //     break;

                // Manual tiktok submission just in case we need it in the future...
                // case 'SubmitTiktokPosts':
                //     pane = <SubmitTiktok
                //         {...this.getDefaultPropBindings()}
                //     />;
                //     break;

                case 'CompleteYourProfile':
                    pane = <CompleteYourProfile
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                case 'SignNDA':
                    pane = <SignNDA
                        {...this.getDefaultPropBindings()}
                    />;
                    break;

                default:
                    pane = this.renderDefaultLoadingPane();
            }

            return pane;

        } else {
            return this.renderDefaultLoadingPane();
        }


    }

    renderDefaultLoadingPane() {
        return (

            <div className="influencer-page-template loading-pane">
                <div className="widget">
                    <div className="content padded">

                        <h4 className="v3 bold action-pane-title">Loading, please wait...</h4>
                    </div>
                </div>
            </div>

        );
    }

    renderCompleteAlertIfNeeded() {
        if (!this.props.showRequirementCompleteAlert) {
            return null;
        }

        if ((this.props.view || {}).key === 'Fulfilled') {
            return null;
        }

        const linkStyle = {
            color: 'white',
            textDecoration: 'underline',
        };

        return <Alert
            classes={['success', 'action-pane-step-completed-alert']}
            content={<span>Congratulations, you've completed this step! <a style={linkStyle} onClick={this.props.onClickNextRequirement} role="button" className="v3 bold">Move on to the next.</a></span>}
            onClose={this.props.onCloseCompleteAlert}
        />
    }

    render() {

        return (
            <div className="action-pane-wrapper col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <div className="action-pane-inner">
                    <div className="action-content">
                        {this.renderCompleteAlertIfNeeded()}
                        {this.renderViewTemplate()}
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        presence: state.presence,
        channel: state.presence.channel,
    };

};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

const ConnectedInfluencerRequirementsActionPane = connect(mapStateToProps, mapDispatchToProps)(InfluencerRequirementsActionPane);
export default ConnectedInfluencerRequirementsActionPane;
