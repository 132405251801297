/**
 * Created by bkanber on 6/29/17.
 */

import {RECEIVE_BLOG_FACETS, RECEIVE_BLOGS} from '../actions/blogs';
import {indexArrayById} from '../utilities'

export function blogsById(state = {}, action) {

    switch (action.type) {

        case RECEIVE_BLOGS:
            return {
                ...state,
                ...indexArrayById(action.data.map(d => {return {...d, receivedAt: action.receivedAt}}))
            };

        case RECEIVE_BLOG_FACETS:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    facets: action.data
                }
            };

        default:
            return state;
    }

}
