import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from "./Card";

class Cards extends Component {
    static defaultProps = {
        classes: [],
        blurry: false,
    };

    static propTypes = {
        items: PropTypes.array.isRequired,
        renderer: PropTypes.func.isRequired,
        classes: PropTypes.array,
        blurry: PropTypes.bool,
    };

    getClassName() {
        let classes = ['godzilla-cards', ...this.props.classes];
        if (this.props.blurry) {
            classes.push('blurry');
        }

        return classes.join(' ');
    }

    render() {
        return (

            <div className={this.getClassName()}>
                {this.props.items.map(item => <Card key={`card-${item._key || item.id}`} item={item} renderer={this.props.renderer}/> )}
            </div>

        );
    }
}

export default Cards;