import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";
import {createUserInteraction} from "../../../../actions";


class AddABlog extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
        onClose: PropTypes.func
    };

    state = {
        form: {
            name: '',
            url: '',
        },
        isFormUpdating: false,
        didFormUpdate: false,
        hasError: false,
    };

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            },
            hasError: false,
            error: null,
        });
    }

    handleAddBlog() {
        const {name, url} = this.state.form;

        if (!name || !url) {
            return;
        }

        this.setState({isFormUpdating: true, didFormUpdate: false});

        this.props.createInteraction(this.props.user.id, 'CreateBlog', this.state.form)
            .then(resp => {
                if (resp.meta.error) {
                    this.setState({isFormUpdating: false, hasError: true, error: resp.meta.error})
                } else {
                    this.setState({isFormUpdating: false, didFormUpdate: true});
                    if (this.props.onClose) {
                        setTimeout(() => this.props.onClose(), 2000);
                    } else {
                        this.setState({form: {}, didFormUpdate: false})
                    }
                }
            });
    }

    renderAlert() {
        let style = {
            position: 'sticky',
            top: 0,
            zIndex: 200,
        };

        if (this.state.hasError) {
            return <Alert
                classes={['danger']} content={this.state.error} style={style}
            />
        }
    }

    render() {
        const {isFormUpdating, didFormUpdate} = this.state;
        const {name, url} = this.state.form;
        let btnText = 'Click to Add Blog';
        if (isFormUpdating) btnText = 'Adding your Blog...';
        if (didFormUpdate) btnText = 'Blog Added!';

        return (
            <div className="add-blog-toolbox-content">
                {this.renderAlert()}
                <p className="v3 light h7 pane-info">
                    Connecting your blog will add it to your creator profile for brands to see. We will also read your RSS feed to make submitting content from your blog easier.
                </p>
                <Form
                    usePadding={false}
                    values={this.state.form}
                    onFieldChange={this.handleFieldChange.bind(this)}
                    fields={[
                        {
                            type: 'text',
                            name: 'url',
                            title: 'Blog URL',
                            help: 'Your blog\'s homepage address',
                            required: true,
                        },
                        {
                            type: 'text',
                            name: 'name',
                            title: 'Blog Name',
                            help: 'The name or title of your blog',
                            required: true,
                        },
                        {
                            type: 'text',
                            name: 'feed_url',
                            title: 'Blog RSS Feed URL',
                            help: 'The location of your RSS or Atom feed. Leave blank if you don\'t know it.',
                        },
                        {
                            type: 'button',
                            title: btnText,
                            options: {
                                classes: ['v3', 'btn-primary', ((isFormUpdating || !name || !url) ? 'disabled' : '')],
                                hideTitle: true,
                                onClick: isFormUpdating ? undefined : this.handleAddBlog.bind(this)
                            }
                        },
                    ]}
                />
            </div>
        );
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (id, type, context) => dispatch(createUserInteraction(id, type, context))
    };
};

const ConnectedAddABlog = connect(null, mapDispatchToProps)(AddABlog);

export default ConnectedAddABlog;
