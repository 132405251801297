/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import {formatNumber} from '../../../utilities';
import ChartWrapper from "../../Chart/ChartWrapper";
import RechartsTimeseriesAreaChart from "../../Chart/RechartsTimeseriesAreaChart";

export default class CampaignContentOverTimeChart extends Component {

    getStats() {
        const stats = this.props.stats || [];
        return stats;
    }

    getTotal() {
        const stats = this.getStats();
        if (!stats || stats.length === 0) return null;
        return stats[stats.length - 1].total_posts;
    }

    renderChart() {
        const stats = this.getStats();
        if (!stats || stats.length === 0) return null;

         const lastStat = stats.slice(-1)[0];

        return (
            <RechartsTimeseriesAreaChart
                data={stats}
                seriesSettings={[
                    {
                        chartType: 'area',
                        dataKey: 'total_images',
                        name: `Images (total ${lastStat.total_images})`,
                        stroke: '#DDDDDD',
                        fill: '#DDDDDD'
                    },
                    {
                        chartType: 'line',
                        dataKey: 'total_posts',
                        name: `Posts (total ${lastStat.total_posts})`,
                        stroke: '#206601',
                        strokeWidth: 2
                    }
                ]}
                axisSettings={[
                    {
                        axisType: 'x',
                        dataKey: 'created_at',
                        stroke: '#888',
                        interval: 0
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'left',
                        stroke: '#888',
                        tickFormatter: (value) => formatNumber(value)
                    },
                ]}
            />
        )
    }

    render() {
        const total = this.getTotal();
        const titleBadge = total !== null ? `${formatNumber(total)} posts` : null;

        return (
            <ChartWrapper
                title="Content Over Time"
                titleBadge={titleBadge}
                icon="file"
                chart={this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
            />
        )
    }

}
