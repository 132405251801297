import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {fetchAuthenticated} from "../../../../actions/auth";
import {connect} from 'react-redux';
import {requestSubscriptions, updateSubscriptions} from "../../../../actions/auth";
import Form from "../../../Common/Form/Form";
import qs from 'qs';
import {makeFormData} from "../../../../utilities";
import Alert from "../../../Common/Alert";
import {getCurrentChannel} from "../../../../selectors/presence";

class AllianzSubscriptions extends Component {

    static propTypes = {
        requestSubscriptions: PropTypes.func.isRequired,
        updateSubscriptions: PropTypes.func.isRequired,
        fetchAuthenticated: PropTypes.func.isRequired,
        channel: PropTypes.object,
        auth: PropTypes.object,
        showTitle: PropTypes.bool,
    };

    static defaultProps = {
        showTitle: true,
    };

    state = {
        isFetching: false,
        didFetch: false,
        isUpdating: false,
        didUpdate: false,
        didUnsubscribeEmail: false,

        token: null,
        form: {
            weekly_newsletter: false,
            monthly_newsletter: false,
            other_newsletter: false,
            journalist_newsletter: false,
        },
    };

    componentDidMount() {
        this.discoverToken();

        if (this.props.auth.isLoggedIn) {
            this.refreshSubscriptions();
        }

        setTimeout(() => {
            this.unsubscribeEmailIfNeeded();
        }, 3000);
    }


    refreshSubscriptions() {
        if (!this.state.token && !this.props.auth.isLoggedIn) {
            return;
        }

        this.setState({isFetching: true});

        return this.props.requestSubscriptions(this.state.token)
            .then(resp => {
                if (resp && resp.data) {
                    this.setState({
                        form: {...resp.data}
                    });
                }
            })
            .then(() => this.setState({isFetching: false, didFetch: true}));

    }

    handleSubmit(e) {

        if (!this.state.token && !this.props.auth.isLoggedIn) {
            return;
        }

        this.setState({isSubmitting: true});
        const {token, form} = this.state;

        return this.props.updateSubscriptions(token, {...form, channel_id: this.props.channel.id})
            .then(resp => {
                if (resp && resp.data) {
                    this.setState({
                        form: {...resp.data}
                    });
                }
            })
            .then(() => this.setState({isSubmitting: false, didSubmit: true}));
    }

    unsubscribeEmailIfNeeded() {
        const query = qs.parse(window.location.search.substr(1));

        if (query.email) {
            const url = `/contribute/user/unsubemail`;
            this.props.fetchAuthenticated(url, {
                method: "POST",
                body: makeFormData({
                    email: query.email
                })
            })
                .then(() => this.setState({didUnsubscribeEmail: true}))
        }

    }

    discoverToken() {
        const query = qs.parse(window.location.search.substr(1));

        if (query.token) {
            this.setState({token: query.token}, () => this.refreshSubscriptions());
        }
    }

    renderForm() {
        return (
            <Form
                style={{marginTop: 20}}
                values={this.state.form}
                onFieldChange={(key, value) => this.setState({form: {...this.state.form, [key]: value}})}
                fields={[
                    {
                        type: 'checkbox',
                        name: 'other_newsletter',
                        title: 'Allianz Global Explorer Program Newsletter',
                        help: 'Get bi-monthly updates on upcoming events, blog updates, new challenges, contest winners, and other cool stuff happening in and around our global community.',
                        options: {hideTitle: true},
                    },
                    {
                        type: 'checkbox',
                        name: 'weekly_newsletter',
                        title: 'Allianz Creators Challenges Alerts / Updates',
                        help: 'Hear about new challenges the moment they launch and never miss an opportunity to get cash rewards and gain exposure.',
                        options: {hideTitle: true},
                    },
                    {
                        type: 'checkbox',
                        name: 'journalist_newsletter',
                        title: 'Allianz Journalist Newsletter',
                        help: 'Get alerts from the Allianz newsroom.',
                        options: {hideTitle: true},
                    },

                    {
                        type: 'button',
                        name: 'submit',
                        title: "Update Subscription Preferences",
                        options: {
                            classes: ['v3', 'btn', 'btn-primary', (this.state.isSubmitting ? 'disabled' : '')],
                            hideTitle: true,
                            onClick: this.handleSubmit.bind(this),
                        }
                    }

                ]}
            />
        );
    }

    renderEmailDidUnsubscribeAlert() {
        if (!this.state.didUnsubscribeEmail) {
            return null;
        }

        return (
            <Alert classes={['success']} content={'You have been unsubscribed.'} style={{marginBottom: 20}}/>
        );
    }


    render() {


        return (
            <div>

                {this.props.showTitle &&
                    <h3>Manage Your <span className={'light-text'}>Subscriptions</span></h3>
                }
                {this.renderEmailDidUnsubscribeAlert()}
                {this.renderForm()}

            </div>
        );

    }

}

const mapStateToProps = (state, props) => {
    return {
        channel: getCurrentChannel(state, props),
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        requestSubscriptions: (token) => dispatch(requestSubscriptions(token)),
        updateSubscriptions: (token, data) => dispatch(updateSubscriptions(token, data)),
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
    };
};

const ConnectedAllianzSubscriptions = connect(mapStateToProps, mapDispatchToProps)(AllianzSubscriptions);

export default ConnectedAllianzSubscriptions;