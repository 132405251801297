import React, {Component, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {fetchActivation} from "../../../../actions";
import PropTypes from 'prop-types';
import Alert from '../../../Common/Alert';
import Form from '../../../Common/Form/Form';
import Text from "../../../Common/Form/Text";
import _get from 'lodash/get';

import {CLINIQUE_PRODUCTS, CLINIQUE_LOCALES} from "../../../../utilities/clinique";
import IFrame from "../../../Common/IFrame";


class Ensure1099Policy extends Component {

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        fetchActivation: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        translate: PropTypes.func.isRequired,
    };

    state = {
        isLoading: false,
        results: null,
    };

    _t(k, v) {
        // TODO: translations disabled for this demo feature
        return v;
        if (this.props.translate) {
            return this.props.translate(k, v);
        }
        return v;
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.props.createInteraction(this.props.activation.id, 'GetTen99PolicySession', {})
            .then((resp) => {
                // debug
                const int = _get(resp, 'data.0');
                console.log('GetTen99PolicySession', int);
                this.setState({
                    results: int.results,
                    isLoading: false,
                });

            });
    }

    renderAlert() {

        return (
            <>
                <p className="v3 light h7 pane-info">
                    { this._t('campaign.1099policy.pane.info', 'As an IPG contractor you are required to have workers compensation insurance. Please provide the following information to ensure you are compliant with IPG policy.') }
                </p>
                {this.state.results && (
                    <p className="v3 light h7 pane-info">
                        Note: the following experience is powered by 1099Policy.com. <a href={this.state.results.session_url} target="_blank" rel="noopener noreferrer">Click here</a> if nothing appears below.
                    </p>
                )}
            </>
        );


    }

    renderContent() {
        const {results, isLoading} = this.state;

        if (isLoading) {
            return (
                <div className="loading">
                    <i className="v3 icon spinner"></i> Loading...
                </div>
            );
        }

        if (!results) {
            return null;
        }

        return (
            <iframe
                style={{width: '100%', height: '800px', border: '1px solid #ddd'}}
                src={results.session_url}
            />
        );
    }


    render() {

        return(
            <div className="influencer-page-template receive-products">
                <div className="widget">

                    <div className="content padded">

                        <h4 className="v3 bold action-pane-title">{this._t('campaign.1099policy.pane.title', 'Policy Requirements')}</h4>

                        {this.renderAlert()}
                        {this.renderContent()}

                    </div>

                </div>
            </div>
        )
    }

}


const mapDispatchToProps = dispatch => {
    return {
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };
};

export default connect(null, mapDispatchToProps)(Ensure1099Policy);
