import {makeFormData} from "../utilities";
import {fetchAuthenticated} from "./auth";
import * as ActionTypes from './types';


/**
 * @param campaignId
 * @param productId
 * @param params
 * @returns {function(*): *}
 */
export const updateCampaignProduct = (campaignId, productId, params) => {
    return (dispatch) => {

        dispatch({
            type: ActionTypes.UPDATE_CAMPAIGN_PRODUCT,
            campaignId,
            productId,
            id: productId,
            data: params
        });

        const url = `/manage/api/campaign/${campaignId}/product/${productId}`;
        const payload = {...params, _method: 'patch'};
        const options = {
            method: 'POST',
            body: makeFormData(payload)
        };

        return dispatch(fetchAuthenticated(url, options))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: ActionTypes.UPDATED_CAMPAIGN_PRODUCT,
                campaignId,
                productId,
                id: productId,
                data: json.data,
                meta: json.meta,
            }));

    };
};

export const deleteCatalogProduct = (id) => {
    return {
        type: ActionTypes.PRODUCTS_DELETE_CATALOG_PRODUCT,
        id,
    };
};

/**
 * @param {Object} json
 */
export const receiveProductCatalogs = (json) => {
    return {
        type: ActionTypes.PRODUCTS_RECEIVE_CATALOGS,
        meta: json.meta,
        data: json.data,
    };
};

/**
 * @returns {function(*)}
 */
export const fetchProductCatalogs = () => {
    return dispatch => {

        dispatch({type: ActionTypes.PRODUCTS_REQUEST_CATALOGS});
        const slot = window.Gator.getDashboardSlot();
        return dispatch(fetchAuthenticated(`/${slot}/api/productcatalog`))
            .then(resp => resp.json())
            .then(json => dispatch(receiveProductCatalogs(json)));
    };
};

/**
 * @param campaignId
 * @param json
 * @returns {{type: *, data, meta, catalogId: *}}
 */
export const receiveCampaignProducts = (campaignId, json) => {
    return {
        type: ActionTypes.PRODUCTS_RECEIVE_CAMPAIGN_PRODUCTS,
        data: json.data,
        meta: json.meta,
        id: campaignId,
        campaignId: campaignId,
    };
};

/**
 * @param catalogId
 * @param json
 * @returns {{type: *, data, meta, catalogId: *}}
 */
export const receiveCatalogProducts = (catalogId, json) => {
    return {
        type: ActionTypes.PRODUCTS_RECEIVE_CATALOG_PRODUCTS,
        data: json.data,
        meta: json.meta,
        id: catalogId,
        catalogId: catalogId,
    };
};

/**
 * @param id
 * @returns {function(*)}
 */
export const fetchCatalogProducts = (id) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.PRODUCTS_REQUEST_CATALOG_PRODUCTS,
            id: id,
            catalogId: id,
        });

        const slot = window.Gator.getDashboardSlot();
        return dispatch(fetchAuthenticated(`/${slot}/api/productcatalog/${id}/product`))
            .then(resp => resp.json())
            .then(json => dispatch(receiveCatalogProducts(id, json)));

    };
};

/**
 * @param id
 * @returns {function(*)}
 */
export const fetchCampaignProducts = (campaignId) => {
    return dispatch => {
        dispatch({
            type: ActionTypes.PRODUCTS_REQUEST_CAMPAIGN_PRODUCTS,
            id: campaignId,
            campaignId: campaignId,
        });

        const slot = window.Gator.getDashboardSlot();
        return dispatch(fetchAuthenticated(`/${slot}/api/campaign/${campaignId}/product`))
            .then(resp => resp.json())
            .then(json => dispatch(receiveCampaignProducts(campaignId, json)));

    };
};
