import React, {useState} from 'react';
import {createUserInteraction} from "../../../../actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Form from "../../../Common/Form/Form";

function AddTikTok({createUserInteraction, userId, onClose}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [handle, setHandle] = useState('');

    const renderAlert = () => null;
    const handleSubmit = async () => {
        if (!handle) {
            return null;
        }
        setIsSubmitting(true);
        const payload = {handle};
        const resp = await createUserInteraction(userId, 'AddTikTok', payload);
        setIsSubmitting(false);
        setHandle('');
        if (onClose) {
            onClose();
        }

    };
    return (

        <div className="add-blog-toolbox-content">
            {renderAlert()}
            <p className="v3 light h7 pane-info">
                Please indicate your TikTok handle below.
            </p>
            <Form
                fields={[
                    {
                        type: 'text',
                        name: 'handle',
                        title: 'TikTok Username or URL',
                        placeholder: 'eg: https://www.tiktok.com/@bkbkbk1212'
                    },
                    {
                        type: 'button',
                        title: isSubmitting ? 'Adding Your TikTok...' : 'Add TikTok',
                        options: {
                            classes: ['v3', 'btn-primary', ((isSubmitting || !handle) ? 'disabled' : '')],
                            hideTitle: true,
                            onClick: isSubmitting ? undefined : handleSubmit
                        }
                    }
                ]}
                values={{handle}}
                onFieldChange={(key, value)  => {
                    if (key === 'handle') { setHandle(value); }
                }}
                usePadding={false}
            />

        </div>


    );
}

const mapDispatchToProps = dispatch => bindActionCreators({createUserInteraction}, dispatch);
export default connect(undefined, mapDispatchToProps)(AddTikTok);