import React, {useState} from "react";
import Form from "../Common/Form/Form";
import Toolbox from "../Common/Toolbox";
import {bindActionCreators} from "redux";
import {createChannelInteraction} from "../../actions/channel";
import {connect} from "react-redux";

function SingleElementEditorToolbox({onClose, onFieldChange, onRemovePage, spec, createInteraction, onMovePage}) {
    const [imgGeneratePrompt, setImgGeneratePrompt] = useState('');
    const [showImgGeneratePrompt, setShowImgGeneratePrompt] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    const makeImageUploadOrGenerate = ({name, title}) => {
        const generateImg = async () => {
            setIsGenerating(true);
            const resp = await createInteraction('GenerateDalleImages', {prompts: [imgGeneratePrompt]});
            if (resp && resp.data && resp.data.results && resp.data.results.length) {
                console.log("resp", resp);
                const img = resp.data.results[0].urls[0];
                console.log("img", img);
                onFieldChange(name, img);
                setShowImgGeneratePrompt(false);
                setIsGenerating(false);
            }

        };

        return showImgGeneratePrompt ?
            {
                type: 'generic',
                content: (
                    <Form
                        usePadding={false}
                        fields={[
                            {
                                type: 'textarea',
                                name: 'prompt',
                                title: 'Prompt',
                            },
                            {
                                type: 'button',
                                title: isGenerating ? 'Generating, please wait...' : 'Generate Image',
                                options: {
                                    classes: ['v3 btn-primary small', isGenerating ? 'disabled' : ''],
                                    hideTitle: true,
                                    onClick: isGenerating ? undefined : generateImg
                                }
                            }
                        ]}
                        onFieldChange={(field, value) => setImgGeneratePrompt(value)}
                        values={{prompt: imgGeneratePrompt}}
                    />
                )
            }
            : {
                type: 'image',
                name: name,
                title: title,
                help: <span><a role="button" className="v3 dark bold" onClick={() => setShowImgGeneratePrompt(true)}>Or generate an image with AI.</a></span>
            }

    }
    const FORMDEFS = {
        image: [
            makeImageUploadOrGenerate({name: 'properties.src', title: 'Upload Image'}),
        ],
        heading: [
            {
                type: 'text',
                title: 'Heading Text',
                name: 'properties.text'
            },
            {
                type: 'color',
                title: 'Color',
                name: 'style.color'
            }
        ],
        page: [
            makeImageUploadOrGenerate({name: 'properties.backgroundImage', title: 'Background Image'}),
            {
                type: 'generic',
                options: {useBorderTop: true},
                content: (
                    <div>
                        <a role="button" onClick={onMovePage.bind(null, 1)} className="v3 btn tidal-btn btn-secondary small">Move Page Down</a>
                        <a role="button" onClick={onMovePage.bind(null, -1)} className="v3 btn tidal-btn btn-secondary small">Move Page Up</a>
                    </div>
                )
            },
            {
                type: 'generic',
                options: {useBorderTop: true},
                content: (
                    <div>
                        <a role="button" onClick={() => {
                            if (onRemovePage) {
                                onRemovePage();
                            }
                            if (onClose) {
                                onClose();
                            }
                        }} className="btn v3 tidal-btn btn-danger small">Remove Page</a>
                    </div>
                )
            }
        ],
        textbox: [
            {
                type: 'wysiwyg',
                name: 'properties.content',
                title: 'Content'
            }
        ]
    };

    return (
        <Toolbox
            style={{width: spec.type === 'textbox' ? 600 : undefined}}
            title={"Edit Element"}
            addlClasses={"toolbox-sm"}
            onClose={onClose}
            content={
                <Form
                    onFieldChange={onFieldChange}
                    fields={FORMDEFS[spec.type] || []}
                    values={spec}
                />
            }
        />
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
    createInteraction: createChannelInteraction
}, dispatch);

export default connect(null, mapDispatchToProps)(SingleElementEditorToolbox);