import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {findFirstValidImage, getIconAndTypeForPost, getImageUrl} from "../../utilities";
import BasicCell from '../Table/Cell/Basic';
import he from 'he';
import MiniProfile from "../Common/MiniProfile";

class PostTitleCell extends Component {

    static propTypes = {
        row: PropTypes.object.isRequired,
        column: PropTypes.object.isRequired,
        onClickUser: PropTypes.func.isRequired,
        onClickPost: PropTypes.func.isRequired,
    };

    state = {
        imageUrl: null,
        didCheckImages: false,
    };

    componentDidMount() {
        this.checkImages();
    }

    async checkImages() {
        if (this.state.didCheckImages) {
            return;
        }

        const imageUrls = this.getAllImageUrls();
        const imageUrl = await findFirstValidImage(imageUrls);

        this.setState({
            imageUrl,
            didCheckImages: true,
        });
    }

    getAllImageUrls() {
        const post = this.props.row.item;
        const postImages = post.images || [];
        const userImage = ((post.user || {}).profile || {}).picture || null;
        const allImageObjects = [...postImages, userImage].filter(o => !!o);
        const imageUrls = allImageObjects.map(getImageUrl);
        return imageUrls;
    }

    renderPrimary() {
        const {row, onClickPost} = this.props;
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/do/page/posts/detail?slug=${row.item.slug}`;

        // Add back to the link when we restore new detail view:

        const title = (
            <a
                href={url}
                className='v3 h7 bold dark'
                onClick={(e) => {
                    e.preventDefault();
                    onClickPost(row.item.id, row.index);
                }}
            >
                {he.decode(row.item.title || 'Untitled Content')}
            </a>
        );

        return title;
    }

    renderSecondary() {
        const {row, column} = this.props;
        const {icon, type} = getIconAndTypeForPost(row.item.type);
        const iconEl = <i className={icon} />;
        const userLink = (
            <a className="dark" role="button"
               onClick={() => this.props.onClickUser(row.item.user.id)}
            >{row.item.user.name}</a>
        );

        return (
            <span>{iconEl} {type} by {userLink}</span>
        );

    }

    render() {
        const {row, column} = this.props;

        const url = `/manage/do/page/posts/view?slug=${row.item.slug}`;

        return <BasicCell
            key={`post-title-cell-${this.props.row.item.id}`}
            row={row}
            column={column}
            classes={['posts-table-title-cell']}
            value={<MiniProfile
                primary={this.renderPrimary()}
                secondary={this.renderSecondary()}
                imageUrl={this.state.imageUrl}
                onClickAvatar={() => {
                    if (window.Gator.Lightbox) {
                        window.Gator.Lightbox.load(url);
                    }
                }}
                square={true}
            />}
        />

    }

}

export default PostTitleCell;