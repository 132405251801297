/**
 * Created by bkanber on 8/7/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {proxyImageUrl} from "../../../utilities";

export default class UserProfileImages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            wrapperWidth: 2
        };

        this.handleImageLoad = this.handleImageLoad.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
    }

    componentDidMount() {
    }

    handleImageError(event) {
        if (typeof this.props.onImageError === 'function') {
            this.props.onImageError(event);
        }
        event.target.style.display = 'none'
    }

    handleImageLoad(event) {

        if (typeof this.props.onImageLoad === 'function') {
            this.props.onImageLoad(event);
        }

        const naturalWidth = event.target.naturalWidth;
        const naturalHeight = event.target.naturalHeight;
        const aspectRatio = naturalWidth / naturalHeight;

        const actualHeight = event.target.height;
        const actualWidth = aspectRatio * actualHeight;

        const wrapperWidth = parseInt(this.state.wrapperWidth, 10) + 2 + actualWidth;
        this.setState({ wrapperWidth });
    }

    getImages() {

        const profile = this.props.user.profile;
        const basics = this.props.user.basics;

        const repImages = basics.representativeImages || [];
        const instagramImages = profile.instagramImages || [];

        const images = repImages.concat(instagramImages);
        return images;

    }

    renderProfileImages() {

        const images = this.getImages();
        const imagesStyle = {
            width: this.state.wrapperWidth + 'px'
        };

        const limit = this.props.limit;

        if (!images) return null;


        const renderImage = (image) => {

            const imageUrl = this.props.useProxy ? proxyImageUrl(image) : image;
            return (
                <img
                    key={image}
                    className="profile-image"
                    src={imageUrl}
                    onLoad={ this.handleImageLoad }
                    onError={this.handleImageError}
                />
            )
        };

        const imageTags = images
            .slice(0, limit)
            .map((image) => renderImage(image));

        let classes = ['profile-images-wrapper'];
        if (this.props.onClickEditImages) {
            classes.push('editable');
        }

        return (
            <div className={classes.join(' ')}>
                <div className="profile-images-row" style={this.props.applyWrapperWidth ? imagesStyle : {}}>{imageTags}</div>
                { this.props.onClickEditImages &&
                    <a onClick={this.props.onClickEditImages} role="button" className="edit-profile-link images">Edit Images</a>
                }
            </div>
        );

    }

    render() {
        return this.renderProfileImages();
    }
}

UserProfileImages.defaultProps = {
    limit: 8,
    applyWrapperWidth: true,
    useProxy: false
};

UserProfileImages.propTypes = {
    onClickEditImages: PropTypes.func,
    useProxy: PropTypes.bool,
};
