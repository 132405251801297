/**
 * Created by bkanber on 7/5/17.
 */

import {makeFormData, fetchAuthenticated} from '../utilities';
import {fetchAuthenticated as fetchAuthenticatedAction} from "./auth";

export const REQUEST_USER_LISTS = 'REQUEST_USER_LISTS';
export const RECEIVE_USER_LISTS = 'RECEIVE_USER_LISTS';

export const REQUEST_USER_NOTES = 'REQUEST_USER_NOTES';
export const RECEIVE_USER_NOTES = 'RECEIVE_USER_NOTES';

export const CREATE_USER_NOTE = 'CREATE_USER_NOTE';
export const CREATED_USER_NOTE = 'CREATED_USER_NOTE';

export const SELECT_USER = 'SELECT_USER';
export const UNSELECT_USER = 'UNSELECT_USER';

export const POST_USER_RATING = 'POST_USER_RATING';
export const POSTED_USER_RATING = 'POSTED_USER_RATING';

export const UPDATE_USER_FACET = 'UPDATE_USER_FACET';
export const UPDATED_USER_FACET = 'UPDATED_USER_FACET';

export const UPDATE_USER_TAGS = 'UPDATE_USER_TAGS';
export const UPDATED_USER_TAGS = 'UPDATED_USER_TAGS';

export const BLOCK_USER = 'BLOCK_USER';
export const BLOCKED_USER = 'BLOCKED_USER';

export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
export const UPDATED_USER_EMAIL = 'UPDATED_USER_EMAIL';

export const REQUEST_USER_INTERESTS = 'REQUEST_USER_INTERESTS';
export const RECEIVE_USER_INTERESTS = 'RECEIVE_USER_INTERESTS';

export const USER_CREATE_INTERACTION = 'USER_CREATE_INTERACTION';
export const USER_CREATED_INTERACTION = 'USER_CREATED_INTERACTION';
export const USER_CREATE_INTERACTION_FAILED = 'USER_CREATE_INTERACTION_FAILED';

export const REQUEST_USER_INTERACTIONS = 'REQUEST_USER_INTERACTIONS';
export const RECEIVE_USER_INTERACTIONS = 'RECEIVE_USER_INTERACTIONS';

/**
 * @param userId
 * @returns {function(*, *)}
 */
export function fetchUserInterestsIfNeeded(userId) {
    return (dispatch, getState) => {
        if (shouldFetchUserInterests(getState(), userId)) {
            return dispatch(fetchUserInterests(userId))
        } else {
            return new Promise((resolve, reject) => resolve());
        }
    }
}

export function fetchUserInterests(userId) {
    return function (dispatch) {

        dispatch({
            type: REQUEST_USER_INTERESTS,
            userId
        });

        const url = `/manage/api/user/${userId}/interest`;
        return fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => dispatch({
                type: RECEIVE_USER_INTERESTS,
                userId,
                receivedAt: new Date(),
                interests: json.data
            }))
            .catch(err => {
                console.log("Error requesting interests");
                console.log(err);
                return err;
            });
    }
}

/**
 * @param state
 * @param userId
 * @returns {*}
 */
export function shouldFetchUserInterests(state, userId) {

    const user = state.usersById[userId];

    if (user.isFetchingInterests || user.lastUpdatedInterests) {
        return false;
    } else {
        return true;
    }

}

export function updateUserEmail(userId, email) {

    return function (dispatch) {

        dispatch({
            type: UPDATE_USER_EMAIL,
            userId,
            email
        });

        const url = `/manage/api/user/${userId}/email`;
        const payload = {email, _method: 'patch'};

        return fetchAuthenticated(url, {
            credentials: 'include',
            method: 'POST',
            body: makeFormData(payload)
        })
            .then(resp => resp.json())
            .then(json => dispatch({
                ...json.data,
                userId,
                type: UPDATED_USER_EMAIL,
                receivedAt: new Date()
            }))
            .catch(err => {
                console.log("Error updating user email");
                console.log(err);
            });

    }
}

export function blockUser(userId, block) {
    return function (dispatch) {

        dispatch({
            type: BLOCK_USER,
            userId,
            block
        });

        const url = `/manage/api/user/${userId}/block/${block ? 1 : 0}`;

        return fetchAuthenticated(url, {
            credentials: 'include',
            method: 'POST',
            body: makeFormData({_method: 'PUT'})
        })
            .then(resp => resp.json())
            .then(json => dispatch({
                type: BLOCKED_USER,
                userId,
                block
            }))
            .catch(err => {
                console.log("Error blocking user");
                console.log(err);
            });
    }
}

export function updateUserTags(userId, tags) {
    return function (dispatch) {

        dispatch({
            type: UPDATE_USER_TAGS,
            userId,
            tags
        });

        const url = `/manage/api/user/${userId}/tag`;
        const payload = {tags: JSON.stringify(tags)};

        return fetchAuthenticated(url, {
            credentials: 'include',
            method: 'POST',
            body: makeFormData(payload)
        })
            .then(resp => resp.json())
            .then(json => dispatch({
                type: UPDATED_USER_TAGS,
                userId,
                tags: json.data
            }))
            .catch(err => {
                console.log("Error updating user tags");
                console.log(err);
            });
    }
}

export function updateUserFacet(userId, facet, value) {
    return function (dispatch) {

        dispatch({
            type: UPDATE_USER_FACET,
            userId,
            facet,
            value
        });

        const url = `/manage/api/user/${userId}/facet/${facet}`;
        const payload = {value, _method: 'put'};

        return fetchAuthenticated(url, {
            credentials: 'include',
            method: 'POST',
            body: makeFormData(payload)
        })
            .then(resp => resp.json())
            .then(json => dispatch({
                type: UPDATED_USER_FACET,
                userId,
                facet,
                value,
                data: json.data
            }))
            .catch(err => {
                console.log("Error updating user facet");
                console.log(err);
            });
    }
}

export function createUserNote(userId, note) {
    return function (dispatch) {
        dispatch({
            type: CREATE_USER_NOTE,
            note,
            userId
        });

        const url = `/manage/api/user/${userId}/note`;

        return fetchAuthenticated(url, {
                credentials: 'include',
                method: 'POST',
                body: makeFormData({note})
            })
            .then(resp => resp.json())
            .then(json => dispatch({
                type: CREATED_USER_NOTE,
                userId,
                notes: json.data,
                receivedAt: new Date()
            }))
            .catch(err => {
                console.log("Error creating note");
                console.log(err)
            });
    }

}


/**
 * @param state
 * @param userId
 * @returns {*}
 */
export function shouldFetchUserNotes(state, userId) {
    const user = state.usersById[userId]
    if (user.isFetchingNotes || user.lastUpdatedNotes) {
        return false;
    } else {
        return true;
    }
}


/**
 * @param userId
 * @returns {function(*, *)}
 */
export function fetchUserNotesIfNeeded(userId) {
    return (dispatch, getState) => {
        if (shouldFetchUserNotes(getState(), userId)) {
            return dispatch(fetchUserNotes(userId))
        }
    }
}

export function fetchUserNotes(userId) {

    return function (dispatch) {
        dispatch({
            type: REQUEST_USER_NOTES,
            userId
        });

        const url = `/manage/api/user/${userId}/note`;

        return fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => dispatch({
                type: RECEIVE_USER_NOTES,
                userId,
                notes: json.data,
                receivedAt: new Date()
            }))
            .catch(err => {
                console.log("Error fetching notes");
                console.log(err)
            });
    }
}

export function postedUserRating(userId, score, listId) {
    return {
        type: POSTED_USER_RATING,
        receivedAt: new Date(),
        userId,
        listId
    };
}

export function rateUser(userId, score, listId) {

    const payload = {id: userId, score};
    const url = `/manage/api/userlist/${listId}/score`;

    console.log("Dispatching rateUser with " + userId + " rating " + score + " on list " + listId);

    return function (dispatch) {
        dispatch({type: POST_USER_RATING, userId, listId});

        return fetchAuthenticated(url,
            {
                credentials: 'include',
                method: 'POST',
                body: makeFormData(payload)
            })
            .then(response => response.json())
            .then(json => {
                dispatch(postedUserRating(userId, score, listId));
            })
            .catch(err => {
                console.log("Error scoring user");
                console.log(err)
            });

    }

}

export function selectUser(userId) {
    return {
        type: SELECT_USER,
        userId
    }
}

export function unselectUser(userId) {
    return {
        type: UNSELECT_USER,
        userId
    }
}

/**
 * @param userId
 * @returns {{type: string, userId: *}}
 */
export function requestUserLists(userId) {
    return {
        type: REQUEST_USER_LISTS,
        userId
    };
}

/**
 * @param userId
 * @param json
 * @returns {{type: string, userId: *, posts, receivedAt: number}}
 */
export function receiveUserLists(userId, json) {
    return {
        type: RECEIVE_USER_LISTS,
        userId,
        listEntries: json.data,
        receivedAt: Date.now()
    }
}

/**
 * @param userId
 * @returns {Function}
 */
export function fetchUserLists(userId) {
    return function (dispatch) {
        dispatch(requestUserLists(userId))

        return fetchAuthenticated(`/manage/api/user/${userId}/list`, {credentials: 'include'})
            .then(response => response.json())
            .then(json => dispatch(receiveUserLists(userId, json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

/**
 * @param state
 * @param userId
 * @returns {*}
 */
export function shouldFetchUserLists(state, userId) {
    const user = state.usersById[userId]
    if (user.didInvalidateLists) {
        return true;
    } else if (user.isFetchingLists || user.lastUpdatedLists) {
        console.log("Return false 2");
        return false;
    } else {
        console.log("Return true");
        return true;
    }
}

/**
 * @param userId
 * @returns {function(*, *)}
 */
export function fetchUserListsIfNeeded(userId) {
    return (dispatch, getState) => {
        if (shouldFetchUserLists(getState(), userId)) {
            return dispatch(fetchUserLists(userId))
        }
    }
}

export const createUserInteraction = (userId, type, context) => {

    return (dispatch) => {

        const dashboard = window.Gator.getDashboardSlot();
        const uri = `/${dashboard}/api/user/${userId}/interaction`;

        dispatch({
            type: USER_CREATE_INTERACTION,
            id: userId,
            interactionType: type,
            context
        });

        return dispatch(fetchAuthenticatedAction(uri, {
            method: 'POST',
            body: makeFormData({
                type,
                context: JSON.stringify(context)
            })
        }))
            .then(resp => resp.json())
            .then(json => dispatch({
                    type: USER_CREATED_INTERACTION,
                    id: userId,
                    meta: json.meta,
                    data: json.data
                })
            )
            //.then(json => dispatch(receiveUserInteractions(userId, json)));
    }
};

export const receiveUserInteractions = (id, resp) => {
    return {
        type: RECEIVE_USER_INTERACTIONS,
        id,
        data: resp.data,
        meta: resp.meta,
    }
};

export const fetchUserInteractions = id => {
    return (dispatch) => {
        dispatch({type: REQUEST_USER_INTERACTIONS, id});
        const url = `/manage/api/user/${id}/interaction`;
        return fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => dispatch(receiveUserInteractions(id, json)));
    };
}