import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../actions/activations";
import PropTypes from 'prop-types';
import Form from '../Common/Form/Form';
import {fetchAuthenticated} from "../../utilities";

class SendEmailForm extends Component {

    state = {
        form: {subject: '', html: '', attachments: []},
        isSubmitting: false,
        didSubmit: false,
    };

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onSaved: PropTypes.func,
        createInteraction: PropTypes.func,
    };

    resetForm() {
        this.setState({
            form: {
                subject: '',
                html: '',
                attachments: [],
            }
        });
    }

    handleSubmit() {
        const {activation} = this.props;
        this.setState({isSubmitting: true});
        return this.props.createInteraction(activation.id, 'SendEmail', this.state.form)
            .then(resp => {
                this.setState({
                    isSubmitting: false,
                    didSubmit: true
                })
            })
            .then(() => this.resetForm())
            .then(() => {
                if (this.props.onSaved) {
                    this.props.onSaved();
                }
            })
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    handleUploadAttachment(event) {

        const slot = window.Gator.getDashboardSlot();
        const data = new FormData();
        data.append('attachment', event.target.files[0]);

        const url = `/${slot}/api/email/-1/attachment`;

        return fetchAuthenticated(url, {
            method: 'post',
            body: data,
        })
            .then(resp => resp.json())
            .then(json => [...this.state.form.attachments, json.data]);

    }

    render() {
        const {isSubmitting, didSubmit} = this.state;
        const buttonTitleText = (isSubmitting, didSubmit) => {
            let text = 'Send Message';
            if (isSubmitting) text = 'Sending Message...';
            if (didSubmit) text = 'Message Sent!';
            return text;
        };
        return <Form
            values={this.state.form}
            onFieldChange={this.handleFieldChange.bind(this)}
            fields={[
                {
                    type: 'text',
                    title: 'Subject',
                    placeholder: 'Subject',
                    name: 'subject'
                },
                {
                    type: 'html',
                    title: 'Message',
                    name: 'html'
                },
                {

                    type: 'file',
                    title: "Attachments",
                    name: 'attachments',
                    options: {
                        onChange: this.handleUploadAttachment.bind(this),
                        previewFormatter: (values, updater) => {
                            return values.map(value => <div style={{
                                background: '#f6f6f6',
                                padding: '10px',
                                margin: '10px 0',
                            }}>
                                <a
                                    style={{marginRight: 15}}
                                    className={'v3 icon ban'}
                                    onClick={() => {
                                        updater(this.state.form.attachments.filter(att => att.original_url !== value.original_url));
                                    }}
                                    role={'button'} />

                                <a className={'dark'}
                                   href={value.original_url} target={'_blank'}>{value.name}</a>
                            </div>)
                        }
                    }

                },
                {
                    type: 'button',
                    title: buttonTitleText(isSubmitting, didSubmit),
                    options: {
                        hideTitle: true,
                        onClick: this.handleSubmit.bind(this),
                        classes: ['btn-primary', ((isSubmitting || didSubmit) ? 'disabled' : '')]
                    }
                },
            ]} />
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context))
    };
};

export default connect(null, mapDispatchToProps)(SendEmailForm);
