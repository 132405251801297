import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createUserInteraction} from "../../actions";
import {getCurrentUser} from "../../selectors/presence";
import Select from '../Common/Form/Select';
import Button from '../Common/Form/Button';
import Toolbox from "../Common/Toolbox";
import * as URI from 'urijs';
import TableWrapper from '../Table/Wrapper';
import {makeCellKey, makeMomentFromDate, c3ColorPattern} from "../../utilities";
import {fetchAuthenticated} from "../../actions/auth";
import LinkCell from "../Table/Cell/Link";
import NumberCell from "../Table/Cell/Number";
import DateCell from "../Table/Cell/Date";
import _get from 'lodash/get';
import _find from 'lodash/find';
import BasicCell from "../Table/Cell/Basic";
import MiniProfile from "../Common/MiniProfile";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    LineChart, Line, AreaChart, Area,
    ResponsiveContainer,
} from 'recharts';
import InstagramBusinessPost from "./InstagramBusinessPost";

class AnalyzeInstagramBusiness extends Component {

    static propTypes = {
        user: PropTypes.object,
        createInteraction: PropTypes.func,
    };

    state = {
        showSelectSocial: false,
        showTable: false,

        user: null,
        isFetching: false,
        didFetch: false,

        socials: null,
        authLinks: null,
        isFetchingAuthLinks: false,
        didFetchAuthLinks: false,

        hasError: false,
        error: null,

        isAttachingSocial: false,
        didAttachSocial: false,
        socialId: null,

        posts: null,
        isFetchingPosts: false,
        didFetchPosts: false,

        artifacts: null,
        isFetchingArtifacts: false,
        didFetchArtifacts: false,

        insights: null,
        isFetchingInsights: false,
        didFetchInsights: false,

        postId: null,
    };

    authwindow = null;

    componentDidMount() {

        if (this.props.user && this.props.user.id) {
            this.refreshUser();
            this.fetchAuthLinks();
            this.fetchSocials();
        }

        window.addEventListener('message', this.handleAuthWindowMessage.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.socialId !== this.state.socialId) {
            this.fetchSocialPosts();
            this.fetchSocialArtifacts();
            this.fetchSocialInsights();
        }

        if (this.props.user && this.props.user.id && (!prevProps.user || !prevProps.user.id)) {
            this.refreshUser();
            this.fetchAuthLinks();
            this.fetchSocials();
        }
    }

    launchAuthWindow(authLink) {

        let url = new URI(authLink);
        url.search({...url.search(true), insights: 1, useTestApp: 1});

        if (this.authWindow) {
            this.authWindow.close();
        }

        this.authWindow = window.open(url.toString(), '_blank', 'height=600,width=800');

    }

    fetchSocialInsights() {
        if (!this.state.socialId) {
            return Promise.reject("Select a social first");
        }

        const url = `/${window.Gator.getDashboardSlot()}/api/social/${this.state.socialId}/insight`;
        this.setState({isFetchingInsights: true});
        return this.props.fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => this.setState({
                insights: json.data,
                isFetchingInsights: false,
                didFetchInsights: true,
            }));
    }

    fetchSocialArtifacts() {
        if (!this.state.socialId) {
            return Promise.reject("Select a social first");
        }

        const url = `/${window.Gator.getDashboardSlot()}/api/social/${this.state.socialId}/artifact`;
        this.setState({isFetchingArtifacts: true});
        return this.props.fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => this.setState({
                artifacts: json.data,
                isFetchingArtifacts: false,
                didFetchArtifacts: true,
            }));
    }

    fetchSocials() {
        const url = `/${window.Gator.getDashboardSlot()}/api/social`;
        return this.props.fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => {
                this.setState({
                    socials: json.data
                });
            });
    }
    fetchSocialPosts() {
        if (!this.state.socialId) {
            return Promise.reject("Select a social first");
        }

        const url = `/${window.Gator.getDashboardSlot()}/api/social/${this.state.socialId}/livepost`;
        this.setState({isFetchingPosts: true});
        return this.props.fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => this.setState({
                posts: json.data,
                isFetchingPosts: false,
                didFetchPosts: true,
            }));
    }

    fetchAuthLinks() {
        this.setState({isFetchingAuthLinks: true});

        this.props.createInteraction(this.props.user.id, 'SocialAuthRequest')
            .then((resp) => {
                if (resp.meta.error) {
                    this.setState({isFetchingAuthLinks: false, hasError: true, error: resp.meta.error})
                } else {
                    this.setState({
                        authLinks: resp.data.results, isFetchingAuthLinks: false, didFetchAuthLinks: true
                    })
                }
            });

    }

    handleAuthWindowMessage(msg) {
        const {data} = msg;
        const type = data.type;

        if (this.authWindow) {
            this.authWindow.close();
        }

        if (data.from !== 'AttachSocial') {
            return;
        }

        this.setState({isAttachingSocial: true});

        this.props.createInteraction(this.props.user.id, 'AttachSocial', {'token': data.t, 'type': type})
            .then((resp) => {
                if (resp.meta.error) {
                    this.setState({isAttachingSocial: false, hasError: true, error: resp.meta.error})
                } else if (resp.data && resp.data.results) {
                    this.setState({
                        isAttachingSocial: false,
                        didAttachSocial: true,
                        showSelectSocial:false,
                        showTable: true,
                        socialId: resp.data.results.id
                    });
                }
            })
            .then(() => {
                this.refreshUser();
                this.fetchSocials();
            });

    }

    refreshUser() {
        this.setState({isFetching: true, didFetch: false});

        this.props.createInteraction(this.props.user.id, 'PersonalProfileRequest', {})
            .then((resp) =>
                this.setState({
                    user: (resp.data || {}).results || {},
                    isFetching: false,
                    didFetch: true
                })
            );
    }

    renderPreviousSocials() {
        const socials = (this.state.socials || []).filter(social => social.type === 'Tidal\\Social\\InstagramBusiness');
        if (!socials || socials.length === 0) {
            return null;
        }

        return (
            <>
                <br/><br/>
                <p>Or use a previously-connected Instagram account:</p>
                <ul>
                    {socials
                        .map(social => <li key={social.id}>
                            <a role="button" onClick={() => this.setState({
                                socialId: social.id,
                                showSelectSocial: false,
                                showTable: true
                            })}>{social.name || social.slug}</a>
                        </li>)}
                </ul>
            </>
        );
    }

    renderSelectSocialScreen() {

        const authUrl = (this.state.authLinks || {})['instagram-business'] || null;
        return (
            <div
                style={{padding: 20, paddingBottom: 50}}
            >
                <p style={{}}>Please connect an Instagram Business account to analyze:</p>

                {!!authUrl
                    ?
                    <Button
                        content={'Connect an Instagram Business Account'}
                        classes={['btn-primary', 'v3']}
                        onClick={() => this.launchAuthWindow(authUrl)}
                    />
                    : <p><em>Please wait, loading...</em></p>
                }

                {this.renderPreviousSocials()}
            </div>
        )
    }

    renderCombinedInsightsChart(graphable) {
        const insights = this.state.insights || [];
        const data = {};
        const seriesTitleMap = {};

        graphable.forEach(seriesKey => {
            const series = _find(insights, {name: seriesKey});
            if (series) {
                const seriesNiceName = series.title;
                seriesTitleMap[seriesKey] = seriesNiceName;

                series.values.forEach(entry => {
                    const {value, end_time} = entry;
                    const date = makeMomentFromDate(end_time);
                    const niceDate = date.format('MMM D');

                    if (typeof data[niceDate] === 'undefined') {
                        data[niceDate] = {
                            date: niceDate
                        };
                    }

                    data[niceDate][seriesNiceName] = value;

                });
            }
        });

        return (
            <ResponsiveContainer width={'100%'} height={300} margin={{right: 20}} >
                <LineChart data={Object.values(data)} >
                    <XAxis dataKey="date"/>
                    <YAxis/>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Tooltip/>
                    <Legend />
                    {(graphable).map((seriesKey,index) => {

                        const colorIndex = index*3;
                        const seriesName = seriesTitleMap[seriesKey];
                        const stroke = c3ColorPattern.pattern[colorIndex];
                        const fill = c3ColorPattern.pattern[colorIndex+1];

                        return <Line type="monotone" dataKey={seriesName} stroke={stroke} fill={fill} dot={false} activeDot={{r: 8}} />
                    })}
                </LineChart>
            </ResponsiveContainer>
        );
    }

    renderChart(title, chart) {
        return (
            <div style={{
                width: 600,
                display: 'inline-block',
                padding: '20px 40px 20px 0',
                borderRight: '1px solid #EEEEEE'
            }}>
                <h3 style={{marginBottom: 20, marginLeft: 60}}>{title}</h3>
                {chart}
            </div>
        );
    }

    renderAudienceGenderChart() {
        const raw = ((this.state.artifacts || {}).ig_demographics || {}).audience_gender_age || {};
        let data = {};

        Object.keys(raw).forEach(key => {
            const gender = key.substr(0,1);
            let niceGender = 'Unknown';
            if (gender === 'F') niceGender = 'Female';
            if (gender === 'M') niceGender = 'Male';
            const ageRange = key.substr(2);

            if (typeof data[ageRange] === 'undefined') {
                data[ageRange] = {
                    name: ageRange
                };
            }

            data[ageRange][niceGender] = raw[key];
        });

        return (
            <ResponsiveContainer width={'100%'} height={300}>
                <BarChart data={Object.values(data)} margin={{right: 20}} >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend />
                    <Bar dataKey="Male" fill="#8884d8" />
                    <Bar dataKey="Female" fill="#82ca9d" />
                    <Bar dataKey="Unknown" fill="#000" />
                </BarChart>
            </ResponsiveContainer>
        );
    }

    renderPostsTableBanner() {
        if (!this.state.didFetchArtifacts) {
            return null;
        }
        if (!this.state.didFetchInsights) {
            return null;
        }

        return (
            <div style={{whiteSpace: 'nowrap', overflow: 'auto'}}>
                {this.renderChart('Audience Age and Gender', this.renderAudienceGenderChart())}
                {this.renderChart('Views and Followers per Day', this.renderCombinedInsightsChart(['follower_count', 'profile_views']))}
                {this.renderChart('Reach and Impressions per Day', this.renderCombinedInsightsChart(['reach', 'impressions']))}
                {this.renderChart('Clicks per Day', this.renderCombinedInsightsChart(['website_clicks', 'email_contacts']))}
            </div>
        );
    }

    renderPostPopup() {
        if (!this.state.postId) {
            return null;
        }

        const post = _find(this.state.posts, {source_id: this.state.postId});
        const social = _find(this.state.user.socials, {id: this.state.socialId});

        return (
            <InstagramBusinessPost
                key={this.state.postId}
                post={post}
                social={social}
                onClose={() => this.setState({postId: null})}
            />
        );
    }

    getPopups() {
        return [
            this.renderPostPopup(),
        ];
    }

    renderPostsTable() {

        if (!this.state.didFetchPosts) {
            return <p style={{padding: 15}}><em>Analyzing your timeline...</em></p>
        }

        return (
            <TableWrapper
                showPagination={false}
                showFilters={false}
                showTotals={false}
                showHeader={false}
                stickySortTabs={true}

                popups={this.getPopups()}
                banner={this.renderPostsTableBanner()}
                items={this.state.posts || []}
                columns={[
                    {
                        key: 'title',
                        title: 'Title',
                        default: true,
                        sortable: false,
                        width: 500,
                        cell: (row, column) => {
                            const onClick = () => this.setState({postId: row.item.source_id});
                            return <BasicCell row={row} column={column} key={makeCellKey(row, column)}
                                value={<MiniProfile
                                    style={{width: 470}}
                                    primary={<a role="button" onClick={onClick}>{row.item.title}</a>}
                                    imageUrl={(row.item.image_urls || [])[0]}
                                    onClickAvatar={onClick}
                                />}

                            />
                        },
                    },
                    {
                        key: 'source_created_at',
                        title: 'Date',
                        default: true,
                        sortable: false,
                        width: 200,
                        cell: (row, column) => <DateCell row={row} column={column} key={makeCellKey(row, column)}
                                                         value={row.item.source_created_at}
                        />,

                    },
                    {
                        key: 'total_engagements',
                        title: 'Engagements',
                        default: true,
                        sortable: false,
                        width: 200,
                        cell: (row, column) => <NumberCell row={row} column={column} key={makeCellKey(row, column)}
                                                         value={row.item.total_engagements}
                        />,
                    },
                    {
                        key: 'engagements.instagram-likes',
                        title: 'Likes',
                        default: true,
                        sortable: false,
                        width: 200,
                        cell: (row, column) => <NumberCell row={row} column={column} key={makeCellKey(row, column)}
                                                           value={_get(row.item, column.key, 0)}
                        />,
                    },
                    {
                        key: 'engagements.instagram-comments',
                        title: 'Comments',
                        default: true,
                        sortable: false,
                        width: 500,
                        cell: (row, column) => <NumberCell row={row} column={column} key={makeCellKey(row, column)}
                                                           value={_get(row.item, column.key, 0)}
                        />,
                    },

                ]}
            />
        );
    }

    renderSelectSocialToolbox() {

        if (!this.state.showSelectSocial) {
            return null;
        }

        return (
            <Toolbox
                addlClasses={'toolbox-fixed toolbox-sm'}
                onClose={() => this.setState({showSelectSocial: false})}
                title={"Instagram Business Analyzer"}
                content={this.renderSelectSocialScreen()}
            />
        );

    }

    renderPostsToolbox() {
        if (!this.state.showTable) {
            return null;
        }

        return (
            <Toolbox
                title={'Instagram Analysis'}
                onClose={() => this.setState({socialId: null, showTable: false})}

                content={this.renderPostsTable()}
                style={{
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    overflow: 'auto',
                }}
            />
        );
    }

    render() {
        return (
            <div className="homepage striped">

                {this.renderSelectSocialToolbox()}
                {this.renderPostsToolbox()}

                <div className="container">
                    <div className="row">


                        <div className="col-xs-12 col-md-4">
                            <p className="homepage-section-title thin">
                                Instagram Business Analyzer
                            </p>
                        </div>

                        <div className="col-xs-12 col-md-8">
                            <div className="right-wrap">
                                <div className="infobox">
                                    <p className="lead"><a role="button" onClick={() => this.setState({showSelectSocial: true, showTable: false})}>Analyze your Instagram Business Account</a> to view insights on your audience, content, and comments.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        user: getCurrentUser(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (id, type, context) => dispatch(createUserInteraction(id, type, context)),
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params))
    };
};

const ConnectedAnalyzeInstagramBusiness = connect(mapStateToProps, mapDispatchToProps)(AnalyzeInstagramBusiness);

export default ConnectedAnalyzeInstagramBusiness;
