import {receiveItem, receiveItems, requestItem} from "../utilities/reducer";
import {combineReducers} from 'redux';
import {makeQueriesReducerFor} from './queries';
import * as ActionTypes from '../actions/types';

export const DEFAULT_LISTENTRIES_QUERY = {
    id: "default",
    sort: {by: "id", dir: "desc"},
    limit: 15,
    page: 1,
    filters: {},
    context: {},
    pages: {}
};

const queries = makeQueriesReducerFor('LISTENTRIES_');

// New version of reducer, not exported because old version still in use
const listEntriesById = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.LIST_ENTRY_REMOVE_NOTES:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    notes: []
                }
            };

        case ActionTypes.LISTENTRIES_RECEIVE_QUERY:
            return receiveItems(state, action);

        case ActionTypes.LIST_RECEIVE_ENTRY:
            return receiveItem(state, action);

        case ActionTypes.LIST_REQUEST_ENTRY:
            return requestItem(state, action);

        default:
            return state;
    }

};

export const listEntries = combineReducers({
    queries,
    items: listEntriesById,
});
