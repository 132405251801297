import React, {useMemo} from 'react';
import Table from "../../Table/Table";
import TrendingPostTile from "./TrendingPostTile";

function MyPostPerformance({activations, postLimit=12, translate}) {

    const postRecords = useMemo(() => {

        let out = [];
        const _activations = activations || [];
        for (const activation of _activations) {
            const _prs = activation.postRecords || [];
            for (const pr of _prs) {
                const activationClone = JSON.parse(JSON.stringify(activation));
                delete (activationClone.postRecords);
                out.push({
                    ...pr,
                    activation: activationClone
                });
            }
        }

        return out.slice(0, postLimit);

    }, [activations]);

    const wdYourContent = translate('creator.achievements.performance.your_content', 'Your Content');

    return (

        <div className="posts-wrapper">
            <h2 className="red-indent">{wdYourContent}</h2>
            <div className="godzilla-wrapper mac-transparent-table">
                <div className="godzilla-cards ic-posts">
                    {postRecords.map(pr => {
                        const activation = pr.activation;
                        const stats = activation.latest_stats || {};
                        let postType = pr.post.type;
                        if (postType === 'instastory') postType = 'instagram';
                        const reachKey = postType + '_reach';
                        const reachThisSocial = stats[reachKey] ? stats[reachKey] : (stats.total_reach ? stats.total_reach : stats.verified_reach);
                        // console.log("Looking for reach with key " + reachKey + ": " + reachThisSocial, stats);
                        const engagements = parseInt(pr.post.engagements || '0', 10);
                        const engPct = reachThisSocial ? (engagements/reachThisSocial) : null;
                        // console.log("Calculated eng pct from engagements", engagements, engPct);

                        return <TrendingPostTile
                            key={pr.id}
                            post={pr.post}
                            engPct={engPct}
                        />
                    })}
                </div>
            </div>
        </div>
    );
}

export default MyPostPerformance;