import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions/campaigns";
import PropTypes from 'prop-types';
import Alert from '../Common/Alert';
import Button from '../Common/Form/Button';
import {makeMomentFromDate, ucwords} from "../../utilities";

class StatusChangeForm extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
        onComplete: PropTypes.func,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
    };

    state = {
        isUpdating: false,
        didUpdate: false,
        error: false,
    };

    handleButtonClick() {

        const {activation, type, onComplete, fetchActivation, createInteraction} = this.props;

        this.setState({isUpdating: true});
        
        let context = {};

        if (type === 'PostDeadlineReminder') {
            context.force = true;
        }

        return createInteraction(activation.id, type, context)
            .catch(err => this.setState({isUpdating: false, didUpdate: false, error: true}))
            .then(() => fetchActivation(activation.id))
            .then(() => this.setState({isUpdating: false, didUpdate: true}))
            .then(() => {
                if (onComplete) {
                    onComplete();
                }
            });

    }

    getActionPresentTense() {
        switch (this.props.type) {
            case 'Invite':
                return 'inviting';
            case 'AcceptAfterWaitlist':
                return 'accepting';
            case 'Waitlist':
                return 'waitlisting';
            case 'Cancel':
                return 'canceling';
            case 'InviteReminder':
                return 'reminding';
            case 'PostDeadlineReminder':
                return 'reminding';
            default:
                return 'updating';
        }

    }

    getActionPastTense() {

        switch (this.props.type) {
            case 'AcceptAfterWaitlist':
                return 'accepted';
            case 'Invite':
                return 'invited';
            case 'Waitlist':
                return 'waitlisted';
            case 'Cancel':
                return 'canceled';
            case 'InviteReminder':
                return 'reminded';
            case 'PostDeadlineReminder':
                return 'reminded';
            default:
                return 'updated';
        }
    }

    renderAlert() {

        const {activation, type} = this.props;
        const {didUpdate, isUpdating, error} = this.state;
        const pastTense = this.getActionPastTense();
        const presentTense = this.getActionPresentTense();

        if (didUpdate) {
            return <Alert classes={['success']} content={`${activation.user.name} was successfully ${pastTense}!`} />
        }

        if (isUpdating) {
            return <Alert classes={['info']} content={`${ucwords(presentTense)} ${activation.user.name}...`} />
        }

        if (error) {
            return <Alert classes={['danger']} content={`There was a problem ${presentTense} ${activation.user.name}.`} />
        }

        switch (this.props.type) {
            case 'PostDeadlineReminder':
                return <Alert classes={['info']} content={`Send a reminder to ${activation.user.name} about their post due date. You can only send this reminder once.`} /> ;
                
                
            case 'AcceptAfterWaitlist':
                return <Alert classes={['info']} content={`Accept ${activation.user.name} in this campaign, removing them from the Waitlist and sending them the Accepted email.`} /> ;

            case 'Invite':

                return <Alert classes={['info']} content={`Invite ${activation.user.name} to this campaign. Doing this will remove the user from any waitlists and reactivate the user if their activation was canceled.`} /> ;

            case 'Waitlist':
                return <Alert classes={['info']} content={`Waitlist ${activation.user.name} in this campaign. Doing this will prevent the user from taking further action until they are re-invited. ${activation.user.name} will be notified via email.`} /> ;
            case 'Cancel':
                return <Alert classes={['info']} content={`Cancel ${activation.user.name}'s activation in this campaign. Doing this will prevent the user from taking further action.`} /> ;

            case 'InviteReminder':
                const invitedTs = makeMomentFromDate(activation.invited_at);
                return <Alert classes={['info']} content={`${activation.user.name} was invited ${invitedTs.fromNow()} on ${invitedTs.format('MMM Do, YYYY')} and has not responded yet. This will send them an email reminder about their invitation.`} /> ;


        }

    }

    renderButtons() {
        const {isUpdating} = this.state;

        const style = {
            textAlign: 'center',
            padding: 20
        };

        let niceText = this.props.type;

        if (niceText === 'InviteReminder') {
            niceText = 'Send Invitation Reminder';
        } else if (niceText === 'AcceptAfterWaitlist') {
            niceText = 'Accept';
        } else if (niceText === 'PostDeadlineReminder') {
            niceText = 'Send Post Reminder';
        }

        return (
            <div style={style}>
                <Button
                    content={niceText}
                    classes={['v3', 'large', 'btn-primary', (isUpdating ? 'disabled' : '')]}
                    onClick={this.handleButtonClick.bind(this)}
                />
            </div>
        );

    }

    render() {

        return (
            <div className="activation-status-change-form">
                {this.renderAlert()}
                {this.renderButtons()}
            </div>
        );

    }
}


const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };
};

export default connect(null, mapDispatchToProps)(StatusChangeForm);
