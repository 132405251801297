import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GenericQuickFiltersButton from "../../Common/GenericQuickFiltersButton";

class QuickFiltersButton extends Component {
    static propTypes = {
        onClickFilter: PropTypes.func,
    };

    render() {

        return (
            <GenericQuickFiltersButton
                key={'users-quick-filters-btn'}
                checks={[
                    {
                        name: "Recent Emails",
                        filters: {"recentEmails": 1},
                    },
                    {
                        name: "Recent Campaign Posts",
                        filters: {"recentCampaignPosts": 1},
                    },
                    {
                        name: "Recent Campaign Activity",
                        filters: {"recentCampaignActivity": 1},
                    },
                    {
                        name: "Open Campaign Issues",
                        filters: {"hasOpenIssues": 1}
                    },
                    {
                        name: "Open Campaign Drafts",
                        filters: {"hasOpenDrafts": 1}
                    },
                    {
                        name: "Recent Profile Updates",
                        filters: {"recentlyUpdatedProfile": 1}
                    },
                    {
                        name: 'Activated Creators',
                        filters: {"group": "activated"}
                    },
                    {
                        name: 'Unactivated Creators',
                        filters: {"group": "unactivated"}
                    },
                    {
                        name: 'Completed Campaign',
                        filters: {"group": "activationCompleted"}
                    },
                    {
                        name: 'Owned Creators',
                        filters: {"group": "owned"}
                    },
                    {
                        name: 'Reset Filters',
                        filters: {},
                    },
                ]}
                classes={['btn-primary']}
                content={'Quick Filters'}
                apiUrl={`/manage/api/user`}
                wrapperStyle={{marginRight: 10}}
                onClickFilter={this.props.onClickFilter}
            />
        );
    }
}

export default QuickFiltersButton;
