import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../actions/activations";
import PropTypes from 'prop-types';
import Form from '../Common/Form/Form';
import SubmitVideo from '../Common/SubmitVideo'
import Alert from '../Common/Alert';

class EditDraftForm extends Component {

    constructor(props) {
        super(props);
        const draft = props.draft || {};
        this.state = {
            form: {
                title: draft.title || '',
                content: draft.content || '',
                source_id: draft.source_id || null,
                'video_ids': []
            },
            editorMode: null,
            isSubmitting: false,
            didSubmit: false,
            keybase: 0,
            isUploading: false,
            isRemoving: false,
            titleError: false,
            contentError: false
        };
    }

    static defaultProps = {
        usePadding: true,
    };

    static propTypes = {
        activation: PropTypes.object.isRequired,
        draft: PropTypes.object,
        onSaved: PropTypes.func,
        style: PropTypes.object,
        usePadding: PropTypes.bool,
        createInteraction: PropTypes.func,
    };

    componentDidMount() {
        if (typeof window.Gator.setupOneRedactor !== 'undefined') {
            this.setState({editorMode: 'html'});
        } else {
            this.setState({editorMode: 'wysiwyg'});
        }
    }

    componentDidUpdate(prevProps) {
        const {draft} = this.props;
        const prevDraft = prevProps.draft;

        // Doesn't matter if there's no draft.
        if (!draft) {
            return;
        }

        if (draft.source_id !== prevDraft.source_id || draft.content !== prevDraft.content) {
            this.setState({
                form: {
                    ...this.state.form,
                    title: draft.title,
                    content: draft.content,
                    source_id: draft.source_id || null
                }
            })
        }
    }

    handleSubmit() {
        const {activation} = this.props;
        const context = {...this.state.form};
        const {title, content} = this.state.form;

        if (!title || !content) {
            this.setValidationErrors();
            return;
        }

        this.setState({isSubmitting: true});

        return this.props.createInteraction(activation.id, 'SubmitDraft', context)
            .then(resp => {
                this.setState({
                    isSubmitting: false,
                    didSubmit: true
                })
            })
            .then(() => {
                if (this.props.onSaved) {
                    this.props.onSaved(context);
                }
            })
            .then(() => {
                setTimeout(() => this.setState({didSubmit: false}), 3000);
            });
    }

    setValidationErrors(){
        const {title, content} = this.state.form;
        const {isSubmitting, didSubmit} = this.state;

        const titleError = (didSubmit || isSubmitting) ? false : !title;
        const contentError = (didSubmit || isSubmitting) ? false : !content;

        this.setState({
            titleError ,
            contentError
        })
    }

    handleFieldChange(name, value) {
        let {titleError, contentError} = this.state;

        if (name === 'title') {
            titleError = !value;
        }

        if (name === 'content') {
            contentError = !value
        }

        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
            titleError,
            contentError
        });
    }

    isUploading(val) {
        this.setState({isUploading: val});
    }

    /**
     * Updates our state array for tracking video ids
     * @param json
     * @return json
     */
    handleVideoSubmit(json) {
        //Collect video_ids and push them into the interactionPayload.video_ids
        let payload = {...this.state.form};
        payload.video_ids.push(json.data.id);

        this.setState({
            isUploading: false,
        });

        return json;
    }

    render() {
        const {isSubmitting, didSubmit, titleError, contentError, editorMode} = this.state;
        return (
            <Form
            usePadding={this.props.usePadding}
            style={this.props.style}
            values={this.state.form}
            onFieldChange={this.handleFieldChange.bind(this)}
            fields={[
                {
                    type: 'text',
                    name: 'title',
                    title: "Title",
                    placeholder: 'Title of the post or draft',
                    required: true
                },
                {
                  type: 'generic',
                  content: titleError && <Alert
                      classes={['danger']}
                      content='This field is required.'
                  />
                },
                (!!editorMode ? {
                    type: editorMode,
                    name: 'content',
                    title: "Content",
                    required: true
                } : null),
                {
                    type: 'generic',
                    content: contentError && <Alert
                        classes={['danger']}
                        content='This field is required.'
                    />
                },
                {
                    type: 'generic',
                    content: <SubmitVideo
                        key={`vid-submit-${this.state.keybase}`}
                        isUploading={this.isUploading.bind(this)}
                        onSubmit={this.handleVideoSubmit.bind(this)}
                        campaign={this.props.campaign}
                        activation={this.props.activation}
                        refreshActivation={this.props.refreshActivation} />
                },
                {
                    type: 'button',
                    title: isSubmitting ? 'Saving Draft...' : didSubmit ? 'Draft Saved!' : "Save Draft",
                    options: {
                        hideTitle: true,
                        classes: ['btn-primary', (isSubmitting ? 'disabled' : '')],
                        onClick: this.handleSubmit.bind(this)
                    },
                },
            ]}
        />)
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        createInteraction: (
            props.createInteraction
            ||
            ((id, type, context) => dispatch(createActivationInteraction(id, type, context)))
        )
    };
};

export default connect(null, mapDispatchToProps)(EditDraftForm);
