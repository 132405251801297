import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";
import {createUserInteraction} from "../../../../actions/users";
import {fetchUser} from "../../../../actions/index";
import {getCurrentChannel} from "../../../../selectors/presence";

class SendMessageForm extends Component {

    static propTypes = {
        userId: PropTypes.number.isRequired,
        onClose: PropTypes.func,
        createInteraction: PropTypes.func,
        channel: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {subject: '', body: ''},
            invalidFields: [],
            isValid: null,
            isSubmitting: false,
            didSubmit: false,
        }
    }

    resetForm() {
        this.setState({
            form: {subject: '', body: ''}
        });
    }

    handleFieldChange(name, value) {
        if (name === 'subject' && value === 'Custom Subject Line') {
            return this.setState({
                form: {...this.state.form, subject: ''},
            })
        }

        return this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    getFormFields(){
        const {isSubmitting, didSubmit} = this.state;
        let fields = [];

        if (didSubmit || isSubmitting) {
            fields.push({
                type: 'generic',
                content: <Alert
                    classes={['info']}
                    content={isSubmitting ? 'Sending message...' : 'Message sent!'}
                    style={{marginBottom: 0}}
                />
            })
        }

        fields.push({
            type: 'text',
            title: 'Subject',
            placeholder: 'eg: How do I update my address?',
            name: 'subject',
            required: true,
        });

        fields.push({
            type: 'textarea',
            title: 'Message',
            placeholder: 'Type your message here',
            name: 'body'
        });

        fields.push ({
            type: 'button',
            title: `Submit`,
            options: {
                hideTitle: true,
                onClick: this.handleSubmit.bind(this),
                classes: ['v3 btn btn-primary', ((isSubmitting || !this.state.isValid) ? 'disabled' : '')]
            }
        });

        return fields;
    }

    handleSubmit() {
        const {userId} = this.props;
        if (!this.state.isValid) return;

        this.setState({isSubmitting: true});
        return this.props.createInteraction(userId, 'SendEmailToChannel', {...this.state.form, channel_id: this.props.channel.id})
            .then(() => this.props.fetchUser(userId))
            .then(resp => {
                this.setState({
                    isSubmitting: false,
                    didSubmit: true
                })
            })
            .then(() => this.resetForm())
            .then(() => {
                if (this.props.onClose) {
                    setTimeout(() => this.props.onClose(), 2000);
                }
            })
    }

    handleValidation({isValid, invalidFields}) {
        let invalidFieldNames = invalidFields.map(field => field.name);

        this.setState({
            isValid,
            invalidFields: invalidFieldNames
        });
    }

    render() {
        return (
            <Form
                values={this.state.form}
                onFieldChange={this.handleFieldChange.bind(this)}
                fields={this.getFormFields()}
                invalidFields={this.state.invalidFields}
                onValidation={this.handleValidation.bind(this)}
            />
        )
    }

}

const mapStateToProps = (state, props) => {
    return {
        channel: getCurrentChannel(state, props)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createUserInteraction(id, type, context)),
        fetchUser: (id) => dispatch(fetchUser(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMessageForm);
