import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SortTab from './SortTab.js';
import FilterTab from './FilterTab';

class Sort extends Component {

    constructor(props) {
        super(props);
        this.handleRef = this.handleRef.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.state = {
            rowWidth: 1440
        }
    }

    componentDidMount() {
        this.calculateRowWidth();
    }

    componentDidUpdate(prevProps) {
        const prevVisible = (prevProps.visibleColumns || []).join(' ');
        const nowVisible = (this.props.visibleColumns || []).join(' ');

        if (nowVisible !== prevVisible) {
            this.calculateRowWidth();
        }

    }

    calculateRowWidth() {
        let width = 0;
        this.getVisibleColumns().forEach(col => {
            const _w = col.width || 80;
            width = width + _w;
        });
        this.setState({rowWidth: width});

    }

    handleSortChange(sort) {
        if (this.props.onSortChange) {
            this.props.onSortChange(sort);
        }
    }

    handleRef(el) {
        if (this.props.onRefReady) {
            this.props.onRefReady(el);
        }
    }

    isColumnVisible(key) {
        const visible = this.getVisibleColumnKeys();
        return visible.indexOf(key) > -1;
    }

    getVisibleColumns() {
        return this.props.columns.filter(col => !!col && this.isColumnVisible(col.key));
    }

    getVisibleColumnKeys() {

        const {visibleColumns, columns} = this.props;

        if (visibleColumns) {
            return visibleColumns;
        }

        return columns.filter(col => !!col && !!col.default).map(col => col.key);
    }


    /**
     * @deprecated
     * @param key
     * @returns {null}
     */
    getColumnSpecByKey(key) {
        const {columns} = this.props;
        const filtered = columns.filter(col => !!col && col.key === key);
        if (filtered.length > 0) {
            return filtered[0];
        }
        return null;
    }

    /**
     * @deprecated
     * @param key
     * @returns {*}
     */
    renderColumnByKey(key) {
        const spec = this.getColumnSpecByKey(key);
        return this.renderColumn(spec);
    }

    renderFilterColumn(spec) {
        const {sort, onShowFilter, filters, activeFilter} = this.props;
        let isActive = false;
        let value = null;
        let filterKey = spec.key;

        if (spec && spec.filterKey) {
            filterKey = spec.filterKey;
        }

        if (sort && sort.by && sort.by === spec.key) {
            isActive = true;
        }

        if (spec && spec.sortKey && sort && sort.by && sort.by === spec.sortKey) {
            isActive = true;
        }

        if (filterKey === activeFilter) {
            isActive = true;
        }



        if (filters && filters.hasOwnProperty(filterKey)) {
            value = filters[filterKey];
        }

        return <FilterTab
            key={`filter-tab-${spec.key}`}
            column={spec}
            isActive={isActive}
            onClick={onShowFilter}
            value={value}
        />

    }

    renderSortColumn(spec) {
        const {sort} = this.props;
        let isActive = false;
        let direction = null;

        if (sort && sort.by && sort.by === spec.key) {
            isActive = true;
        }

        if (spec && spec.sortKey && sort && sort.by && sort.by === spec.sortKey) {
            isActive = true;
        }

        if (isActive) {
            direction = (sort || {}).dir;
        }

        return <SortTab
            key={`sort-tab-${spec.key}`}
            column={spec}
            isActive={isActive}
            direction={direction}
            onClick={this.handleSortChange}
            showTotals={this.props.showTotals}
        />

    }

    renderFiltersRow() {
        const {showFilters} = this.props;
        if (!showFilters) return null;

        return (
            <div className="godzilla-table-filters-row">
                {this.getVisibleColumns()
                    .map(col => this.renderFilterColumn(col))}
            </div>
        );
    }

    renderSortRow() {
        return (
        <div className="godzilla-table-sort-row" style={this.getSortRowStyle()}>
            {this.getVisibleColumns()
                .map(col => this.renderSortColumn(col))}
        </div>
        );
    }

    getClassName() {
        const {sticky} = this.props;
        let classes = ['godzilla-table-sort'];
        if (sticky) {
            classes.push('stick-to-top');
        }
        return classes.join(" ");
    }

    getSortRowStyle() {
        return {
            width: this.state.rowWidth
        };
    }

    render() {
        return (
            <div
                className={this.getClassName()}
                ref={this.handleRef}
            >
                {this.renderSortRow()}
                {this.renderFiltersRow()}
            </div>
        );
    }
}

Sort.defaultProps = {
    sticky: false,
    showTotals: false,
    showFilters: false,
    filters: {}
};

Sort.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    visibleColumns: PropTypes.array,
    sticky: PropTypes.bool,
    showTotals: PropTypes.bool,
    onRefReady: PropTypes.func,
    onSortChange: PropTypes.func,
    sort: PropTypes.object,
    showFilters: PropTypes.bool,
    onShowFilter: PropTypes.func,
    filters: PropTypes.object,
    /** Which filter to highlight */
    activeFilter: PropTypes.string,
};

export default Sort;