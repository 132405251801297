import ConnectedImageUpload from "../Common/Form/ImageUpload";
import ContributeDashProfileImages from "../Influencer/EditProfile/ContributeDashProfileImages";
import React, {useState} from "react";
import _get from 'lodash/get';

function EntryProfileImagesField({entry, onChange}) {
    const customImages = _get(entry, 'settings.custom_images', []) || [];
    const getAllImages = () => [...customImages];
    const handleUpload = (imageId, image, removedId) => {
        if (onChange) {
            onChange([...(_get(entry, 'settings.custom_images', []) || []), image]);
        }
    };
    const handleRemove = (id) => {
        const newImages = (_get(entry, 'settings.custom_images', []) || [])
            .filter(img => img.id !== id);
        if (onChange) {
            onChange(newImages);
        }
    }

    const allImageIds = getAllImages().map(i => i.id);

    return (
        <div className="profile-images">
            <div className="hscrollwrapper">

                <div className="left">
                    <label htmlFor="rep-image-upload-btn" className="rep-image-upload-label">
                        <span><i className="v3 icon plus"></i> Add image</span>
                    </label>
                    <ConnectedImageUpload onChange={handleUpload} id="rep-image-upload-btn" />
                </div>

                <div className="right">
                    <ContributeDashProfileImages
                        images={getAllImages()}
                        imageIds={allImageIds}
                        onChangeImage={() => {}}
                        onRemoveImage={handleRemove}
                    />
                </div>

            </div>
        </div>
    )
}

export default EntryProfileImagesField;