/**
 * Created by bkanber on 7/12/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import StarRating from '../../components/Common/StarRating';
import {rateUser} from '../../actions';

class UserStarRating extends Component {

    constructor(props) {
        super(props);
        this.state = {rating: null};
        this.handleRatingChange = this.handleRatingChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {

        if (this.props.user.id !== nextProps.user.id) {
            this.setState({rating: null});
        }

    }

    handleRatingChange(rating) {
        const {user, dispatch, list} = this.props;
        const listId = list ? list.id : 'default';

        rating = rating * 2; // user ratings are 1-10 but star tool is 1-5
        dispatch(rateUser(user.id, rating, listId));

        this.setState({rating});
    }

    render() {

        // divide by 2 because of ratings ranges
        const rating = (this.state.rating ? this.state.rating : this.props.user.basics.rating) / 2;
        const tooltip = this.props.list ? `Rating on list "${this.props.list.name}"` : null;

        return (
            <StarRating
                onRatingChange={this.handleRatingChange}
                rating={rating}
                tooltip={tooltip}
            />
        );

    }

}

const ConnectedUserStarRating = connect()(UserStarRating);

ConnectedUserStarRating.propTypes = {
    user: PropTypes.object.isRequired,
    list: PropTypes.object
};

export default ConnectedUserStarRating;


