import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {openUserLightbox} from "../../../actions/lightbox";
import {
    preventDefaultIfPossible,
    formatNumber,
    fetchAuthenticated,
    base64_png_1pxSolidEEE,
    formatPound,
    makeCellKey
} from "../../../utilities";
import TableWrapper from '../../Table/Wrapper';
import NumberCell from '../../Table/Cell/Number';
import BasicCell from '../../Table/Cell/Basic';
import EMVSettings from '../../Campaign/Report/EMVSettings';

import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _sumBy from 'lodash/sumBy';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _omit from 'lodash/omit';

import {EMV_INDUSTRY_DEFAULTS} from "../../../utilities/emv";
import MiniProfile from "../../Common/MiniProfile";
import Toolbox from "../../Common/Toolbox";
import HBLeaderboardExpando from "../../Test/HBLeaderboardExpando";
import Button from "../../Common/Form/Button";

const LIMIT = 25;

class SocialChannelEngagementLeaderboard extends Component {

    static defaultProps = {
        mode: 'manager',
    };

    static propTypes = {
        listenerId: PropTypes.any,
        openLightbox: PropTypes.func,
        mode: PropTypes.oneOf(['manager', 'employee']).isRequired,
    };

    constructor(props) {

        super(props);

        this.state = {
            page: 1,
            isFetching: false,
            didFetch: false,
            rawData: null,
            leaderboard: null,
            meta: null,
            sort: {by: 'rank', dir: 'asc'},
            totals: {},
            showEmvConfig: false,
            showPrizeInfo: false,
            showFilters: false,
            showSocialExpando: false,
            emvParams: null,
            tab: null,
            filters: {},
            period: 'month',

            // Used for filters and select drops
            allAreas: [],
            allStores: [],
            allRegions: [],
        };

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleOpenLightbox = this.handleOpenLightbox.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.period !== this.state.period) {
            this.fetchData();
        }
    }

    collectStoresAndAreas() {
        const stores = (this.state.rawData || [])
            .map(row => (row.user || {}).store_info || null)
            .filter(store => !!store);

        let areaMap = {};
        let regionMap = {};
        let storeMap = {};

        stores.forEach(store => {

            if (!store || !store.value) {
                return;
            }

            if (typeof storeMap[store.value] === 'undefined') {
                storeMap[store.value] = {text: store.text || '', value: store.value};
            }

            if (typeof areaMap[store.Area] === 'undefined') {
                areaMap[store.Area] = {text: 'Area ' + store.Area + ': ' + store['Area Manager'], value: store.Area};
            }

            if (typeof regionMap[store.Region] === 'undefined') {
                regionMap[store.Region] = {text: 'Region ' + store.Region + ': ' + store['Regional Manager'], value: store.Region};
            }
        });

        const sortFn = (a, b) => a.text.localeCompare(b.text);

        this.setState({
            allAreas: Object.values(areaMap).sort(sortFn),
            allStores: Object.values(storeMap).sort(sortFn),
            allRegions: Object.values(regionMap).sort(sortFn),
        });

    }

    isManagerMode() {
        return this.props.mode === 'manager';
    }

    isEmployeeMode() {
        return this.props.mode === 'employee';
    }

    getEngPct(row) {
        const item = (row.item ? row.item : row);
        const total = parseInt(item.total_engagements, 10);
        const reach = parseInt(item.total_reach, 10);

        if (!total || !reach) {
            return null;
        }

        return 100 * (total / reach);
    }

    handlePageChange(page) {
        this.setState({page});
    }

    handleOpenLightbox(userId, event) {
        preventDefaultIfPossible(event);
        const userIds = (this.state.leaderboard || []).map(l => l.id);
        this.props.openLightbox(userId, {}, userIds);
    }

    getLeaders() {
        const {page} = this.state;
        return (this.state.leaderboard || [])
            .slice((page - 1) * LIMIT, page * LIMIT);
    }

    fetchData() {

        const id = this.props.listenerId;
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/sociallistener/_/hbleader?period=${this.state.period}`;

        this.setState({
            isFetching: true,
            rawData: null,
            leaderboard: null,
            meta: null,
            totals: null,
        });

        return fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => {


                const leaders = json.data.map(row => {
                    return {
                        ...row,
                        engPct: this.getEngPct(row),
                    };
                });

                const totals = this.calculateTotals(leaders);
                this.setState({
                    rawData: leaders,
                    leaderboard: leaders,
                    meta: json.meta,
                    emvParams: json.meta.emvParams,
                    isFetching: false,
                    didFetch: true,
                    totals,
                }, () => {
                    this.postProcessData();
                    // Set tab to area, if employee context
                    if (this.state.tab === null) {
                        if (this.isEmployeeMode()) {
                            this.setActiveTab('area');
                        } else {
                            this.setActiveTab('all');
                        }
                    }
                });

                return json;
            })
            .catch(err => {
                console.log("Error fetching leaderboard data");
                console.log(err);
                return err;
            });

    }

    calculateTotals(data) {

        const fields = [
            'total_engagements',
            'total_emv',
            'total_reach',
            'engagements.facebook_comment_count',
            'engagements.facebook_reaction_count',
            'engagements.facebook_share_count',

            'engagements.instagram_like_count',
            'engagements.instagram_comment_count',
            'engagements.twitter_retweet_count',
            'engagements.twitter_favorite_count',
        ];

        let totals = {
            name: data.length,
        };

        fields.forEach(field => {
            totals[field] = _sumBy(data, field);
        });

        return totals;

    }

    getTotalFor(field, text) {
        const val = this.state.totals[field] || 0;
        return formatNumber(val) + ' ' + text;
    }

    componentDidMount() {
        this.fetchData();
    }


    applyFilters(data) {

        let filtered = [...data];

        Object.keys(this.state.filters)
            .forEach(filterKey => {
                const val = this.state.filters[filterKey];
                filtered = _filter(filtered, [filterKey, val]);
            });

        return filtered;
    }

    applySort(data) {
        const sort = this.state.sort;

        let filtered = (data || [])
            .filter(i => _get(i, sort.by, null) !== null);

        let sorted = _sortBy(filtered, [sort.by]);

        if (sort.dir === 'desc') {
            _reverse(sorted);
        }

        return sorted;

    }

    /**
     * Used for all filters at once.
     * @param filters
     */
    handleFilterChange(filters) {
        this.setState({filters}, () => this.postProcessData());
    }

    /**
     * Used for table
     * @param key
     * @param value
     */
    onFilterChange(key, value) {
        const filters = {...this.state.filters};
        filters[key] = value;
        return this.handleFilterChange(filters);
    }

    handleSortChange(sort) {
        this.setState({sort}, () => this.postProcessData());
    }

    getSwitches() {
        return [

            this.isManagerMode() ? {
                name: 'showFilters',
                icon: <i className="fa fa-search" />,
                tooltip: "Show/Hide Filters",
                isActive: this.state.showFilters,
                onClick: () => this.setState({showFilters: !this.state.showFilters}),
            } : null,

            this.isManagerMode() ? {
                name: 'expando',
                icon: <i className="fa fa-address-card"/>,
                tooltip: "Show/Hide Social Breakdowns",
                isActive: this.state.showSocialExpando,
                onClick: () => this.setState({showSocialExpando: !this.state.showSocialExpando}),
            } : null,

            this.isManagerMode() ? {
                name: 'emv',
                icon: <i className="fa fa-cog"/>,
                tooltip: "Configure EMV parameters.",
                isActive: this.state.showEmvConfig,
                onClick: () => this.setState({showEmvConfig: !this.state.showEmvConfig}),
            } : null,

            this.isManagerMode() ? {
                name: 'download',
                icon: <i className="fa fa-download"/>,
                tooltip: "Download Excel Leaderboard",
                isActive: false,
                onClick: () => {
                    window.location.href = '/manage/do/widget/tools/exportHBLeaderboard';
                },
            } : null,

        ];
    }

    renderPrizeInfoContent() {
        return (
            <div style={{padding: 15}}>

                <h3 className="v3 light bottommargin ">Yearly Business Wide Reward:</h3>

                <p className="v3">This will be awarded to the person in the business with the highest score, 1 x calendar year from when we launch The Social Circle.</p>

                <p className="v3"><strong>Reward:</strong> Holiday Voucher to the value of £2000</p>



                <h4 className="v3 light bottommargin topmargin">Quarterly Area Reward:</h4>

                <p>A Quarterly prize, per Area, worth £250 will be given the colleague with the highest score at the end of each business Quarter.</p>

                <p><strong>Reward:</strong></p>


                <ul>
                    <li>Love2Shop Voucher to the value of £250</li>
                </ul>



                <h4 className="v3 light bottommargin topmargin">Gold & Silver Monthly Rewards:</h4>

                <p>Gold & Silver prizes to the value of £100 & £50 respectively, will be given to colleagues who achieve the top two scores in their Area each month.</p>

                <p><strong>Reward:</strong></p>


                <ul>
                    <li>Love2Shop Voucher to the value of £100 (Gold) or £50 (Silver)</li>
                </ul>
            </div>

        );
    }
    getPopups() {
        return [
            (this.state.showEmvConfig && this.isManagerMode()) ? (

                <EMVSettings
                    style={{top: 60}}
                    emvParams={EMV_INDUSTRY_DEFAULTS}
                    onClose={() => this.setState({showEmvConfig: false})}
                />

            ) : null,

            (this.state.showPrizeInfo) ? (

                <Toolbox
                    style={{top: 60, width: 650}}
                    onClose={() => this.setState({showPrizeInfo: false})}
                    title={"About the Prizes"}
                    content={this.renderPrizeInfoContent()}
                />

            ) : null,
        ];
    }

    isTabActive(tab) {
        return this.state.tab === tab;
    }

    setActiveTab(tab) {


        let filters = {};
        const currentUserStore = (this.state.meta || {}).storeInfo || {};

        switch (tab) {
            case 'store':
                filters = {'user.store_info.value': currentUserStore.value};
                break;
            case 'area':
                filters = {'user.store_info.Area': currentUserStore.Area};
                break;
            case 'region':
                filters = {'user.store_info.Region': currentUserStore.Region};
                break;
            case 'all':
            default:
                filters = {};

        }


        this.setState({
            tab,
            filters
        }, () => this.postProcessData());

    }

    postProcessData() {
        // Filters
        const filtered = this.applyFilters(this.state.rawData);

        // Sort
        const sorted = this.applySort(filtered);

        // Totals
        const totals = this.calculateTotals(sorted);

        this.setState({
            leaderboard: sorted,
            totals,
        }, () => this.collectStoresAndAreas());

    }

    getEmployeeTabs() {
        const currentUserStore = this.state.meta.storeInfo;
        const hasStore = !!currentUserStore;
        return [
            hasStore ? {
                title: 'Your Area',
                onClick: () => this.setActiveTab('area'),
                isActive: this.isTabActive('area'),
            } : null,
            hasStore ? {
                title: 'Your Store',
                onClick: () => this.setActiveTab('store'),
                isActive: this.isTabActive('store'),
            } : null,
            {
                title: 'All Regions',
                onClick: () => this.setActiveTab('all'),
                isActive: this.isTabActive('all'),
            },
            // hasStore ? {
            //     title: 'Your Region',
            //     onClick: () => this.setActiveTab('region'),
            //     isActive: this.isTabActive('region'),
            // } : null,


        ];
    }

    getManagerTabs() {
        return [
            {
                title: 'Current Month',
                onClick: () => this.setState({period: 'month'}),
                isActive: this.state.period === 'month',
            },
            {
                title: 'Current Quarter',
                onClick: () => this.setState({period: 'quarter'}),
                isActive: this.state.period === 'quarter',
            },
            {
                title: 'Current Year',
                onClick: () => this.setState({period: 'year'}),
                isActive: this.state.period === 'year',
            },

        ];
    }

    getTabs() {
        if (this.isManagerMode()) {
            return this.getManagerTabs();
        }

        if (this.isEmployeeMode()) {
            return this.getEmployeeTabs();
        }
    }

    getManagerColumns() {

        if (!this.isManagerMode()) {
            return [];
        }

        return [
            {
                key: 'total_reach',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: 'Followers',
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   key={makeCellKey(row, column)}
                                                   value={row.item.total_reach}
                                                   formatter={formatNumber}
                />,
                total: this.getTotalFor('total_reach', 'Reach'),
            },
            {
                key: 'engPct',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: 'Engagement %',
                width: 180,
                cell: (row, column) => <BasicCell row={row} column={column}
                                                  key={makeCellKey(row, column)}
                                                  value={row.item.engPct
                                                      ? (formatNumber(row.item.engPct, 2) + '%')
                                                      : '-'
                                                  }
                />,
            },
            {
                key: 'engagements.facebook_comment_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Facebook Comments",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   key={makeCellKey(row, column)}
                                                   value={row.item.engagements.facebook_comment_count}/>,
                total: this.getTotalFor('engagements.facebook_comment_count', 'Comments'),
            },
            {
                key: 'engagements.facebook_reaction_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Facebook Reactions",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   key={makeCellKey(row, column)}
                                                   value={row.item.engagements.facebook_reaction_count}/>,
                total: this.getTotalFor('engagements.facebook_reaction_count', 'Reactions'),
            },
            {
                key: 'engagements.facebook_share_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Facebook Shares",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   key={makeCellKey(row, column)}
                                                   value={row.item.engagements.facebook_share_count}/>,
                total: this.getTotalFor('engagements.facebook_share_count', 'Shares'),
            },

            {
                key: 'engagements.instagram_comment_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Instagram Comments",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   key={makeCellKey(row, column)}
                                                   value={row.item.engagements.instagram_comment_count}/>,
                total: this.getTotalFor('engagements.instagram_comment_count', 'Comments'),
            },
            {
                key: 'engagements.twitter_retweet_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Retweets",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   key={makeCellKey(row, column)}
                                                   value={row.item.engagements.twitter_retweet_count}/>,
                total: this.getTotalFor('engagements.twitter_retweet_count', 'Retweets'),
            },
            {
                key: 'engagements.twitter_favorite_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Twitter Favorites",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   key={makeCellKey(row, column)}
                                                   value={row.item.engagements.twitter_favorite_count}/>,
                total: this.getTotalFor('engagements.twitter_favorite_count', 'Favorites'),
            },
        ];
    }

    getColumns() {
        return [
            {
                key: 'rank',
                default: true,
                sortable: true,
                title: "#",
                width: 60,
                cell: (row, column) => <BasicCell
                    key={makeCellKey(row, column)}
                    row={row} column={column} value={"#" + row.item.rank}/>,
            },
            {
                key: 'user.name',
                default: true,
                sortable: true,
                title: "Name",
                width: 300,
                cell: (row, column) => {
                    const user = row.item.user;
                    const name = user ? user.name : row.item.name;

                    const pic = (user && user.profile_picture_url)
                        ? user.profile_picture_url
                        : 'data:image/png;base64,' + base64_png_1pxSolidEEE;

                    const onClick = (user && this.isManagerMode())
                        ? this.props.openLightbox.bind(this, user.id)
                        : null;

                    const store = (user && user.store_info) ? user.store_info : null;
                    const secondary = store
                        ? <span>Store: {store.text}</span>
                        : null;

                    return <BasicCell row={row} column={column}
                                      key={makeCellKey(row, column)}
                                      value={
                                          <MiniProfile
                                              primary={<a role="button" onClick={onClick}>{name}</a>}
                                              imageUrl={pic}
                                              onClickAvatar={onClick}
                                              secondary={secondary}
                                          />
                                      }/>
                },
                total: this.getTotalFor('name', 'Users'),
                search: [
                    {
                        type: 'select',
                        name: 'user.store_info.value',
                        title: 'Store',
                        choices: [...(this.state.allStores || [])]
                    },
                    {
                        type: 'select',
                        name: 'user.store_info.Area',
                        title: 'Area',
                        choices: [...(this.state.allAreas || [])]
                    },
                    {
                        type: 'select',
                        name: 'user.store_info.Region',
                        title: 'Region',
                        choices: [...(this.state.allRegions || [])]
                    },
                ],
                onResetSearch: () => {
                    this.handleFilterChange(_omit(this.state.filters, [
                        'user.store_info.value',
                        'user.store_info.Area',
                        'user.store_info.Region',
                    ]));
                }
            },
            {
                key: 'total_emv',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: this.isManagerMode() ? 'EMV' : 'Total Points',
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   value={row.item.total_emv}
                                                   key={makeCellKey(row, column)}
                                                   formatter={this.isManagerMode() ? formatPound : formatNumber}
                />,
                total: formatPound(this.state.totals['total_emv'] || 0) + ' Total',
            },
            {
                key: 'total_engagements',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Engagements",
                width: 170,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   key={makeCellKey(row, column)}
                                                   value={row.item.total_engagements}
                                                   formatter={formatNumber}
                />,
                total: this.getTotalFor('total_engagements', 'Total'),
            },

            ...this.getManagerColumns()
        ];
    }

    renderExpando(row) {

        if (!this.isManagerMode()) {
            return null;
        }

        return (
            <div className="hb-table-expando-wrapper">
                <HBLeaderboardExpando
                    user={row.item}
                />
            </div>

        );

    }

    getButtons() {
        return [

            this.isEmployeeMode() ?
                <Button
                    key={'hb-prize-info-btn'}
                    content={'Prize Info'}
                    onClick={() => this.setState({showPrizeInfo: !this.state.showPrizeInfo})}
                    classes={['v3', 'small', 'btn-secondary']}
                />
                : null,
        ];

    }

    renderTable() {
        if (this.state.isFetching) return null;
        if (!this.state.leaderboard) return null;
        const leaders = this.getLeaders();

        return <TableWrapper
            title={'Social Leaders'}
            items={leaders}
            stickySortTabs={true}
            showTotals={this.isManagerMode()}

            showPagination={true}
            onPageChange={this.handlePageChange.bind(this)}
            pages={Math.ceil((this.state.leaderboard || []).length / LIMIT)}
            page={this.state.page}

            onSortChange={this.handleSortChange.bind(this)}
            sort={this.state.sort}

            switches={this.getSwitches()}
            popups={this.getPopups()}
            tabs={this.getTabs()}
            buttons={this.getButtons()}

            columns={this.getColumns()}

            isRowExpanded={row => this.state.showSocialExpando}
            expander={row => this.renderExpando(row)}

            filters={this.state.filters}
            showFilters={this.state.showFilters}
            onFilterChange={this.onFilterChange}
        />
    }


    render() {

        if (this.state.isFetching) {
            return <div className={'alert tidal-alert alert-grey'}>Loading leaderboard...</div>
        }

        return this.renderTable();
    }
}

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        openLightbox: (userId, ctx, users) => dispatch(openUserLightbox(userId, ctx, users))
    }
};

const ConnectedSocialChannelEngagementLeaderboard = connect(mapStateToProps, mapDispatchToProps)(SocialChannelEngagementLeaderboard);

ConnectedSocialChannelEngagementLeaderboard.propTypes = {
    listenerId: PropTypes.any,
    mode: PropTypes.string,
};

export default ConnectedSocialChannelEngagementLeaderboard;


