import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Video from "../../Common/Video";
import {parseContentIntoSections} from "../../../utilities/widget_parser";
import PostDetailWidgetSection from "./WidgetSection";

class PostDetailContent extends Component {
    static propTypes = {
        post: PropTypes.object,
    };

    renderVideoPost() {
        return  <article className='col-md-8 col-md-pull-4'>
            <div className='post-detail-video'>
                <Video
                    src={(this.props.post || {}).permalink}
                    provider='file'
                    style={{ maxHeight: 'unset' }}
                />
            </div>
        </article>

    }

    renderNormalPost() {
        const post = this.props.post || {};
        const sections = parseContentIntoSections(post.content_clean);
        console.log({sections});
        let output = [];

        for (const sectionIndex in sections) {
            if (!sections.hasOwnProperty(sectionIndex)) {
                continue;
            }
            const section = sections[sectionIndex];

            if (section.type === 'content') {
                output.push(
                    <div style={{ maxWidth: 600 }} dangerouslySetInnerHTML={{ __html: section.value }} key={`post-section-${sectionIndex}`} />
                );
            } else if (section.type === 'widget') {
                output.push(<PostDetailWidgetSection referenceIds={section.value.split(',')} references={post.references || []} />);
            }

        }
        return (
            <article className='col-md-8 col-md-pull-4'>
                {output}
            </article>
        );
    }

    render() {
        const post = this.props.post || {};

        if (post.content_clean) {
            return this.renderNormalPost();
        } else if (post.type === 'video' && !!post.permalink) {
            return this.renderVideoPost();
        } else {
            return null;
        }

    }
}

export default PostDetailContent;