import React, {useState} from 'react';
import _merge from "lodash/merge";
import Toolbox from "../Common/Toolbox";
import Form from "../Common/Form/Form";
import DOMPurify from "dompurify";
import SingleElementEditorToolbox from "./SingleElementEditorToolbox";

function SingleElementEditControls({onClickEdit}) {
    return (
        <div className="controls" onClick={onClickEdit} >
            <i className="v3 icon fa-pencil" />
        </div>
    )
}


function SingleElement({spec, isEditable, path, onFieldChange, onRemovePage, onMovePage}) {
    const [isHovering, setIsHovering] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    if (!spec) {
        return null;
    }

    const children = spec.children || [];
    const specStyle = spec.style || {};
    const specProps = spec.properties || {};
    const className = `pdfbuilder-element pdfbuilder-element-${spec.type} ${specProps.className || ''}`;
    let content = null;
    let defaultStyle = {};

    switch (spec.type) {
        case 'heading':
            content = (spec.properties || {}).text;
            defaultStyle = { color: specStyle.color || undefined };
            break;
        case 'image':
            defaultStyle = { backgroundImage: `url(${spec.properties.src})`, };
            break;
        case 'page':
            defaultStyle = { backgroundImage: specProps.backgroundImage ? `url(${specProps.backgroundImage})` : undefined };
            break;
        case 'textbox':
            content = DOMPurify.sanitize((spec.properties || {}).content || '', {ADD_ATTR: ['target']});
            content = { __html: content };
            content = <div dangerouslySetInnerHTML={content} />;
            break;
        default:
            break;
    }

    const style = _merge({}, defaultStyle, specStyle);
    return (
        <div
            className={className}
            style={style}
            onMouseOver={(e) => {
                setIsHovering(true);
                e.stopPropagation();
            }}
            onMouseLeave={(e) => {
                setIsHovering(false)
            }}
            id={'el-' + path}
        >
            {(isHovering && isEditable) && <SingleElementEditControls onClickEdit={() => setIsEditing(true)} />}
            {isEditing && <SingleElementEditorToolbox
                onRemovePage={onRemovePage}
                onMovePage={(direction) => {
                    if (onMovePage) {
                        // page index is already bound
                        onMovePage(direction);
                    }
                    setIsEditing(false);
                }}
                onFieldChange={onFieldChange.bind(null, path)}
                onClose={() => setIsEditing(false)}
                spec={spec}
            />}
            {content}
            {children.map((child, index) => <SingleElement onFieldChange={onFieldChange} path={`${path}.children.${index}`} key={index} spec={child} isEditable={isEditable} /> )}
        </div>
    )

}

export default function PDFBuilderEditor({ docSpec, isEditable=true, onFieldChange, onRemovePage, onMovePage }) {
    const content = (docSpec || {}).content || [];
    // Make sure the inner elements always have an onFieldChange handler
    const handleFieldChange = (path, field, value, obj) => {
        if (onFieldChange) {
            onFieldChange(path + '.' + field, value, obj);
        }
    };

    const handleRemovePage = (index) => {
        if (onRemovePage) {
            onRemovePage(index);
        }
    }

    const handleMovePage = (index, direction) => {
        if (onMovePage) {
            onMovePage(index, direction);
        }
    }

    return (

        <div className={"pdfbuilder-editor " + (isEditable ? 'editable' : '')}>
            {content.map((spec, pageIndex) => <SingleElement
                onRemovePage={handleRemovePage.bind(null, pageIndex)}
                onMovePage={handleMovePage.bind(null, pageIndex)}
                onFieldChange={handleFieldChange}
                path={`content.${pageIndex}`}
                key={pageIndex}
                spec={spec}
                isEditable={isEditable}
            />)}
        </div>

    )
}