import React, {Component} from 'react';
import PropTypes from 'prop-types';
import UserTableExtendedProfile from '../Table/Extended/Profile';
import UserBlogWidget from '../Pieces/BlogWidget';
import UserAttributesTable from '../Pieces/UserAttributesTable';
import UserSocialTable from '../Pieces/UserSocialTable';
import UserProfileImages from '../Pieces/ProfileImages';
import {limitChars} from "../../../utilities";
import Alert from "../../Common/Alert";

export default class UserDetailProfilePane extends Component {

    renderProfileText() {

        const user = this.props.user;
        const {basics, profile} = user;
        const showPayments = this.props.showPayments;

        let profileText = (<em>Profile text coming soon...</em>);

        if (profile && profile.text) {
            profileText = profile.text;
            profileText = limitChars(profileText, 400);
        }

        return (

            <div className="user-detail-profile-text-wrapper">

                <div className="fake-li">
                    <strong>About Me</strong>
                </div>

                <div className="fake-li">
                    {limitChars(profileText, 310)}
                </div>

                {
                    basics.human_location &&
                        <div className="fake-li bottom-border profile-location">
                            <strong>Location:</strong>
                            <span className="pull-right">{limitChars(basics.human_location, 26)}</span>
                        </div>
                }

                {
                    basics.email &&
                    <div className="fake-li bottom-border profile-location">
                        <strong>Email:</strong>
                        <a href={`mailto:${basics.email}`} onClick={this.props.onClickEmail} className="pull-right">{limitChars(basics.email, 26)}</a>
                    </div>
                }

                {
                    basics.tier &&
                    <div className="fake-li bottom-border">
                        <strong>Tier:</strong>
                        <span className="pull-right">{basics.tier}</span>
                    </div>
                }

                {
                    (basics.price && showPayments) ? (
                    <div className="fake-li bottom-border">
                        <strong>Sugg. Price:</strong>
                        <span className="pull-right">{basics.price}</span>
                    </div>) : null
                }

                { this.props.onClickEditBio &&
                    <a onClick={this.props.onClickEditBio} role="button" className="edit-profile-link bio">Edit</a>
                }

            </div>
        );
    }

    renderInterests() {
        const user = this.props.user;
        const {profile} = user;

        let limit = 8;

        const interests = (profile.interests || []).slice(0, limit);

        return (
            <div className="user-interests-table">
                {interests.map((interest, i) => {
                    return <div className="fake-li bottom-border">
                        <span>Interest #{i+1}:</span>
                        <span className="pull-right">{interest}</span>
                    </div>
                })}
            </div>
        )

    }

    render() {

        const {user, showBlogPreview, showInterests, showFrecency, showResponsiveness, onClickEditFacets, onClickEditImages} = this.props;

        let classes = ['col-xs-12'];

        if (showInterests) {
            classes.push('col-sm-3')
        } else {
            classes.push('col-sm-4')
        }

        return (

            <div className={'user-detail-body-profile'}>

                <UserProfileImages
                    user={user}
                    applyWrapperWidth={false}
                    onClickEditImages={onClickEditImages}
                    useProxy={this.props.printMode}
                />

                <div className="container-fluid">
                    <div className="user-detail-profile-attributes-row row">

                        <div className={classes.join(' ')}>
                            {this.renderProfileText()}
                        </div>

                        <div className={classes.join(' ')}>
                            <UserAttributesTable
                                user={user}
                                channel={this.props.channel}
                                showFrecency={showFrecency}
                                showResponsiveness={showResponsiveness}
                                onClickEditFacets={onClickEditFacets}
                            />
                        </div>

                        <div className={classes.join(' ')}>
                            <UserSocialTable user={user} />
                        </div>

                        { showInterests &&
                            <div className={classes.join(' ')}>
                                {this.renderInterests()}
                            </div>
                        }

                    </div>
                </div>

                { showBlogPreview &&
                    <UserBlogWidget user={user} />
                }

            </div>

        );
    }

}

UserDetailProfilePane.defaultProps = {
    showBlogPreview: true,
    showInterests: true,
    showFrecency: true,
    showResponsiveness: true,
    showPayments: true,
    printMode: false
};

UserDetailProfilePane.propTypes = {
    user: PropTypes.object,
    showBlogPreview: PropTypes.bool,
    showInterests: PropTypes.bool,
    showFrecency: PropTypes.bool,
    showResponsiveness: PropTypes.bool,
    showPayments: PropTypes.bool,
    onClickEditBio: PropTypes.func,
    onClickEditFacets: PropTypes.func,
    onClickEditImages: PropTypes.func,
    onClickEmail: PropTypes.func,
    channel: PropTypes.object,
    printMode: PropTypes.bool,
};
