/**
 * Created by visgotti on 11/1/17.
 */
import {connect} from 'react-redux';
import { fetchPosts, applyAction } from '../../actions/postsById';
import { fetchBadgesIfNeeded } from '../../actions/badges';
import { changeFilter, removeFilter, changeSort, resetFilters, selectAllPosts, unselectAllPosts, changeLimit, changePage, } from '../../actions/postQueries';
import {DEFAULT_POST_QUERY} from "../../reducers/postQueries";
import {DEFAULT_POST_STATE} from "../../reducers/postsById";
import Posts from '../../components/Post/Posts';


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchPosts: (filters, sort, page, limit, queryId) => {
            dispatch(fetchPosts(filters, sort, page, limit, queryId))
        },
        applyAction: (postIds, action) => {
            dispatch(applyAction(postIds, action))
        },
        selectAllPosts: (postIds, queryId) => {
            dispatch(selectAllPosts(postIds, queryId))
        },
        unselectAllPosts: (postIds, queryId) => {
            dispatch(unselectAllPosts(postIds, queryId))
        },
        changeFilter: (key, value, fetchPosts, queryId) => {
            dispatch(changeFilter(key, value, fetchPosts, queryId))
        },
        removeFilter: (key, fetchPosts, queryId) => {
            dispatch(removeFilter(key, fetchPosts, queryId))
        },
        changeSort: (sort, fetchPosts, queryId) => {
            dispatch(changeSort(sort, fetchPosts, queryId))
        },
        resetFilters:(queryId) => {
            dispatch(resetFilters(queryId))
        },
        changeLimit: (limit, fetchPosts, queryId) => {
            dispatch(changeLimit(limit, fetchPosts, queryId))
        },
        changePage: (page, queryId) => {
            dispatch(changePage(page, queryId))
        },
        fetchBadgesIfNeeded: () => {
            dispatch(fetchBadgesIfNeeded())
        },
    };
};

function mapStateToProps(state, ownProps) {
    const queryId = ownProps.queryId || 1;
    const postQuery = state.postQueries.queries[queryId] || DEFAULT_POST_QUERY;
    const posts = (postQuery.postIds || []).map(id => state.postsById[id] || DEFAULT_POST_STATE);
    return {
        postsLoaded: state.postQueries.isLoaded,
        badges: state.badgesById.items,
        campaigns: state.campaignsById.items,
        queryId,
        postQuery,
        posts
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Posts);
