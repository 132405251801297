import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tooltip from "../../Common/Tooltip";

export default class Header extends Component {

    static propTypes = {

        /** Buttons to include to the right of the title */
        buttons: PropTypes.arrayOf(PropTypes.node),

        /** Specify action icons for the top-right */
        switches: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            icon: PropTypes.element,
            tooltip: PropTypes.string,
            isActive: PropTypes.bool,
            onClick: PropTypes.func,
            spot: PropTypes.string,
        })),

        /** A string or function for the title */
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func
        ]),

        /** An icon for the top-left */
        icon: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.func
        ]),

    };

    renderTitle() {
        const {title} = this.props;
        if (!title) return null;
        return (
            <h2 className="title">{typeof title === 'function' ? title() : title}</h2>
        );

    }

    renderIcon() {
        const {icon} = this.props;
        if (!icon) return null;
        return <span className="icon">{typeof icon === 'function' ? icon() : icon}</span>
    }

    getSwitches(spot = 'right') {
        const {switches} = this.props;
        if (!switches) return null;

        return switches.filter(item => {
            if (!item) {
                return false;
            }
            const thisSpot = item.spot ? item.spot : 'right';
            return thisSpot === spot;
        });

    }

    renderSwitches(spot = 'right') {
        const switches = this.getSwitches(spot);

        if (!switches || switches.length === 0) {
            return null;
        }

        return (
            <div className={`switches ${spot}`}>
                {(switches || []).map(this.renderSwitch.bind(this))}
            </div>
        );
    }

    renderButtons() {
        if (this.props.buttons) {
            return (
                <div className="buttons">
                    {this.props.buttons}
                </div>
            )
        }
    }

    renderSwitch(spec) {

        if (!spec) {
            return null;
        }

        let classes = ['switch', 'switch-'+(spec.name || spec.key)];

        if (spec.isActive) {
            classes.push('active');
        }

        let icon = spec.icon;
        if (typeof spec.tooltip === 'string') {
            icon = <Tooltip title={spec.tooltip}>{spec.icon}</Tooltip>
        }

        return (
            <a
                key={spec.key || spec.name}
                role="button"
                className={classes.join(' ')}
                onClick={spec.onClick ? spec.onClick.bind(this) : null}
            >{icon}</a>
        );

    }

    render() {
        return (
            <div className="godzilla-header">
                {this.renderSwitches('left')}
                <div className="godzilla-header-icon-title-wrapper">
                    {this.renderIcon()}
                    {this.renderTitle()}
                </div>
                {this.renderButtons()}
                {this.renderSwitches('right')}
            </div>
        )
    }
}