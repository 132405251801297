import React, {Component} from 'react';
import Table from '../Table/Table';
import Toolbox from '../Common/Toolbox';
import Button from '../Common/Form/Button';
import BasicCell from '../Table/Cell/Basic';
import LinkCell from '../Table/Cell/Link';
import ImageCell from '../Table/Cell/Image';
import CheckboxCell from '../Table/Cell/Checkbox';
import _find from 'lodash/find';
import {base64_png_1pxSolidEEE, makeFormData, fetchAuthenticated} from "../../utilities";
import RepImagesProfilePreview from './RepImagesProfilePreview';

class RepImagesProfileTool extends Component {

    state = {
        posts: null,
        isFetchingPosts: false,
        didFetchPosts: false,

        liveposts: null,
        isFetchingLivePosts: false,
        didFetchLivePosts: false,

        social: null,
        socials: null,
        isFetchingSocials: false,
        didFetchSocials: false,

        showSocialSelect: false,
        showPostSelect: false,
        showPreview: false,

        updatingPosts: {},
    };

    componentDidMount() {
        this.fetchSocials();
        this.fetchPosts();
    }

    fetchLivePosts() {
        this.setState({isFetchingLivePosts: true});
        return fetchAuthenticated(`/contribute/api/social/${this.state.social.id}/livepost`, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => this.setState({liveposts: json.data, isFetchingLivePosts: false, didFetchLivePosts: true}))

    }

    fetchSocials() {
        this.setState({isFetchingSocials: true});
        return fetchAuthenticated(`/contribute/api/social`, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => this.setState({socials: json.data, isFetchingSocials: false, didFetchSocials: true}))
    }

    fetchPosts() {
        this.setState({isFetchingPosts: true});
        return fetchAuthenticated(`/contribute/api/user/me/profilepost`, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => this.setState({posts: json.data, isFetchingPosts: false, didFetchPosts: true}))
    }

    getNiceSocialType(social) {
        switch (social.type) {
            case 'Tidal\\Social\\Facebook': return 'Facebook';
            case 'Tidal\\Social\\FacebookPage': return 'Facebook Page';
            case 'Tidal\\Social\\Instagram': return 'Instagram';
            case 'Tidal\\Social\\Twitter': return 'Twitter';
        }
    }
    renderSocialSelectToolbox() {
        if (!this.state.showSocialSelect) return null;
        return <Toolbox
            title={'Select an Account'}
            content={
                [
                    <div className="alert tidal-alert alert-grey"
                         style={{marginBottom: 0, borderRadius: 0, textShadow: 'none'}}
                    >
                        Visit <a href="/profile#social" data-widget="social">Social Networks</a> to connect more accounts.
                    </div>,
                    ...(this.state.socials || [])
                        .filter(social => {
                            return [
                                'Tidal\\Social\\Facebook',
                                'Tidal\\Social\\FacebookPage',
                                'Tidal\\Social\\Instagram',
                            ].indexOf(social.type) > -1
                        })
                        .map(social => <div className="fake-li">
                            <a role="button" onClick={() => this.handleSelectSocial(social)}>{this.getSourceLabel(social)}</a>
                        </div>)
                ]
            }
            onClose={() => this.setState({showSocialSelect: false})}

        />
    }

    getSourceLabel(social) {
        return `${this.getNiceSocialType(social)}: ${social.name}`
    }

    handleSelectSocial(social) {
        this.setState({
            social,
            showSocialSelect: false,
            showPostSelect: true,
            liveposts: null
        }, () => this.fetchLivePosts());

    }

    isPostSelected(post) {
        const searchId = post.source_id;
        const allIds = (this.state.posts || []).map(post => post.source_id);
        return allIds.indexOf(searchId) > -1;
    }

    handleSelectPost(post) {
        const isSelected = this.isPostSelected(post);
        const sourceId = post.source_id;
        if (this.isPostUpdating(post)) return;

        this.setState({updatingPosts: {
                ...this.state.updatingPosts,
                [sourceId]: true
        }});
        let promise;

        if (isSelected) {
            // Delete
            promise =fetchAuthenticated(`/contribute/api/user/me/profilepost`, {
                credentials: 'include',
                method: 'POST',
                body: makeFormData({
                    source_id: sourceId,
                    _method: 'DELETE'
                })
            })
        } else {
            // Create
            promise =fetchAuthenticated(`/contribute/api/user/me/profilepost`, {
                credentials: 'include',
                method: 'POST',
                body: makeFormData({
                    post: JSON.stringify(post),
                })
            })

        }

        promise
            .then(resp => resp.json())
            .then(json => this.setState({posts: json.data, isFetchingPosts: false, didFetchPosts: true}))
            .then(() => this.setState({
                updatingPosts: {
                    ...this.state.updatingPosts,
                    [sourceId]: false
                }
            }))

    }

    isPostUpdating(post) {
        const sourceId = post.source_id;
        return this.state.updatingPosts[sourceId] || false;
    }


    renderPostsTable(items) {
        const defaultImage = 'data:image/png;base64,' + base64_png_1pxSolidEEE;

        return <Table items={items || []}
               columns={[
                   {
                       key: 'select',
                       default: true,
                       sortable: false,
                       width: 70,
                       cell: (row, column) => <CheckboxCell
                           row={row} column={column}
                           value={this.isPostSelected(row.item)}
                           onClick={() => this.handleSelectPost(row.item)}
                           icon={this.isPostUpdating(row.item) ? <i className="fa fa-spinner fa-spin" /> : null}
                       />
                   },
                   {
                       key: 'image',
                       default: true,
                       sortable: false,
                       title: 'Image',
                       width: 80,
                       cell: (row, column) => <ImageCell
                           row={row} column={column}
                           url={(row.item.image_urls || [])[0] || defaultImage}
                       />
                   },
                   {
                       key: 'source',
                       default: true,
                       sortable: false,
                       width: 180,
                       title: 'Source',
                       cell: (row, column) => <BasicCell
                           row={row} column={column}
                           value={this.getSourceLabel(row.item.user)}
                       />
                   },
                   {
                       key: 'title',
                       title: 'Post Title',
                       default: true,
                       sortable: false,
                       width: 470,
                       cell: (row, column) => <BasicCell row={row} column={column}
                                                         value={row.item.title}

                       />
                   }

               ]}
        />

    }

    renderPostSelectToolbox() {

        if (!this.state.showPostSelect) return null;
        return <Toolbox
            style={{width: 730}}
            title="Select Posts"
            onClose={() => this.setState({showPostSelect: false})}
            content={
                this.state.isFetchingLivePosts
                    ? <div className="alert tidal-alert alert-grey">Finding posts...</div>
                    : this.renderPostsTable(this.state.liveposts || [])
            }

        />
    }

    renderProfilePostsTable() {

        if (this.state.isFetchingPosts) {
            return <div className="alert tidal-alert alert-grey">Loading profile posts...</div>
        }

        if (this.state.posts !== null && this.state.posts.length === 0 && this.state.didFetchPosts) {
            return <div className="alert tidal-alert alert-grey">You have no profile posts. Add one with the button above.</div>
        }

        return this.renderPostsTable(this.state.posts || []);
    }

    renderProfilePreview() {
        if (!this.state.showPreview) {
            return null;
        }
        return <RepImagesProfilePreview
            onClose={() => this.setState({showPreview: false})}
            posts={this.state.posts}
        />
    }

    render() {

        const previewLink = <a onClick={() => this.setState({showPreview: true})} role={"button"}>Preview your profile.</a>
        const hasPosts = this.state.posts !== null && this.state.posts.length > 0 && this.state.didFetchPosts;

        return (
            <div className="form-group" style={{marginTop: 30, position: 'relative'}}>
                <label>
                    <strong>Profile Posts</strong>
                    <a
                        style={{marginLeft: 10}}
                        role="button"
                       onClick={() => this.setState({showSocialSelect: true, showPostSelect: false})}
                    ><i className="fa fa-plus-circle" /> Add a Post</a>
                </label>

                <p className="help-block">Select a few posts from your social accounts to use in your public profile. The posts should be representative of your original work. Unselect a post to remove it from your profile. {hasPosts ? previewLink : null}</p>

                {this.renderSocialSelectToolbox()}
                {this.renderPostSelectToolbox()}
                {this.renderProfilePostsTable()}
                {this.renderProfilePreview()}
            </div>
        )

    }
}

export default RepImagesProfileTool;
