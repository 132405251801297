export const CLINIQUE_LOCALES = [
    {"name": "Australia"},
    {"name": "Brazil"},
    {"name": "Chile"},
    {"name": "Japan"},
    {"name": "Mexico"},
    {"name": "New Zealand"},
    {"name": "Taiwan"},
    {"name": "Thailand"}
];

export const CLINIQUE_PRODUCTS = [
    {
        "name": "Lash Power Mascara Long-Wearing Formula",
        "campaigns": [221],
        "image": "https://www.clinique.com/media/export/cms/products/1200x1500/cl_sku_KGMR01_1200x1500_0.png",
        "locales": {
            "Japan": {"url": "https://www.clinique.jp/product/1606/59450/--/lash-power-mascara-long-wearing-formula"},
            "Thailand": {"url": "https://www.clinique.co.th/product/1606/7079/lash-power-curling-mascara/long-wearing-formula?shade=01_%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF_%E3%82%AA%E3%83%8B%E3%82%AD%E3%82%B9&size=6_ml."},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/29219/7079/2019md/2021lastchance/lash-power-curling-mascara?shade=01&size=6ml"}
        }
    },
    {
        "name": "24 Days of Clinique",
        "campaigns": [222],
        "image": "https://www.clinique.com.au/media/export/cms/products/1200x1500/cl_sku_V60MY2_1200x1500_0.png",
        "locales": {
            "Australia": {"url": "https://www.clinique.com.au/product/13566/109494/holiday/all-gifts/24-days-of-clinique"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/13566/109494/holiday/all-gifts/24-days-of-clinique"}
        }
    },
    {
        "name": "Moisture Surge Set",
        "campaigns": [222],
        "image": "https://www.clinique.co.th/media/export/cms/products/1200x1500/cl_sku_V65CY2_1200x1500_0.png",
        "locales": {
            "Japan": {"url": "https://www.clinique.jp/product/16422/109303/23663c-moisture-surge-set"},
            "Thailand": {"url": "https://www.clinique.co.th/product/1685/109303/skincare/gifts/moisture-surgetm-50ml/1950-2650"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/33590/112812/2022md/2022/1111-80160/80160"}
        }
    },
    {
        "name": "Smart Clinical Repair Set",
        "campaigns": [222],
        "image": "https://www.clinique.co.th/media/export/cms/products/1200x1500/cl_sku_V647Y2_1200x1500_0.png",
        "locales": {
            "Japan": {"url": "https://www.clinique.jp/product/16422/110371/23663c-smart-clinical-set"},
            "Thailand": {"url": "https://www.clinique.co.th/product/1685/110371/skincare/gifts/smart-clinical-repairtm-50ml/4150--6300"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/33590/109899/2022md/2022"}
        }
    },
    {
        "name": "Even Better Set",
        "campaigns": [222],
        "image": "https://www.clinique.co.th/media/export/cms/products/1200x1500/cl_sku_V634Y2_1200x1500_0.png",
        "locales": {
            "Japan": {"url": "https://www.clinique.jp/product/16422/109952/23663c-even-better-set"},
            "Thailand": {"url": "https://www.clinique.co.th/product/1685/109952/skincare/gifts/even-better-clinicaltm-50ml/4100-5600"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/33590/109900/2022md/2022/no1"}
        }
    },
    {
        "name": "Even Better Clinical Foundation",
        "image": "https://www.clinique.com.mx/media/export/cms/products/1200x1500/cl_sku_KY1951_1200x1500_0.png",
        "campaigns": [226, 221],
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/1599/85169/maquillaje/bases-de-maquillaje/even-bettertm-clinical-serum-foundation-spf-20?shade=WN_54_Honey_Wheat"},
            "Chile": {"url": "https://www.clinique.cl/product/1599/85169/makeup/foundations/even-better-clinical-serum-foundation-spf-20/base-de-maquillaje?shade=CN_52_Neutral_(MF)"},
            "Brazil": {"url": "https://www.clinique.com.br/product/1599/85169/makeup/base/base-serum-even-better-clinicaltm-fps-20?shade=WN_114_Golden_(D)"},
            "Japan": {"url": "https://www.clinique.jp/product/1599/82933/even-better-clinical-serum-foundation-spf-20?shade=61_Ivory_%E3%82%A2%E3%82%A4%E3%83%9C%E3%83%AA%E3%83%BC"},
            "Thailand": {"url": "https://www.clinique.co.th/product/1599/82933/foundations/even-better-clinical-serum-foundation-spf-20-pa?shade=61_Ivory"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/1599/82933/even-better-clinical-serum-foundation-spf-20-pa/spf20pa?shade=65_Neutral"},
            "Australia": {"url": "https://www.clinique.com.au/product/1599/85169/makeup/foundations/even-better-clinicaltm-serum-foundation-spf-20?shade=CN_08_Linen_(VF)"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/4323/85169/just-arrived/even-better-clinicaltm-serum-foundation-spf-20?shade=CN_08_Linen_(VF)"}
        }
    },
    {
        "name": "Even Better All Over Concealer",
        "image": "https://www.clinique.com.mx/media/export/cms/products/1200x1500/cl_sku_KF5451_1200x1500_0.png",
        "campaigns": [226, 221],
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/1585/76702/centro-de-regalos/even-bettertm-all-over-concealer-eraser?shade=WN_54_Honey_Wheat"},
            "Chile": {"url": "https://www.clinique.cl/product/1585/76702/gift-center/even-better-concealer?shade=WN_54_Honey_Wheat"},
            "Brazil": {"url": "https://www.clinique.com.br/product/1596/76702/makeup/corretivo/corretivo-even-bettertm?shade=WN_100_Deep_Honey"},
            "Japan": {"url": "https://www.clinique.jp/product/1596/76702/-/even-better-concealer?shade=65_Neutral"},
            "Thailand": {"url": "https://www.clinique.co.th/product/1596/76702/even-better-concealer?shade=Neutral"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/1599/76702/even-better-concealer?shade=Rose_Beige"},
            "Australia": {"url": "https://www.clinique.com.au/product/1596/76702/makeup/concealers/even-bettertm-all-over-concealer-eraser?shade=WN_01_Flax"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/1596/76702/makeup/concealers/even-bettertm-all-over-concealer-eraser?shade=WN_01_Flax"}
        }
    },
    {
        "name": "Black Honey Almost Lipstick",
        "image": "https://www.clinique.com.mx/media/export/cms/products/1200x1500/cl_sku_635906_1200x1500_0.png",
        "campaigns": [226, 221],
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/1603/4772/maquillaje/brillos-labiales/almost-lipstick--black-honey"},
            "Chile": {"url": "https://www.clinique.cl/product/1603/4772/makeup/lip-glosses/almost-lipstick-black-honey?shade=Black_Honey&size=.07_oz."},
            "Brazil": {"url": "https://www.clinique.com.br/product/1605/4772/makeup/batons/batom-almost-lipstick-black-honey?shade=Black_Honey"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/1592/4772/almost-lipstick?shade=Black_Honey"},
            "Australia": {"url": "https://www.clinique.com.au/product/1603/4772/makeup/lip-glosses/almost-lipstick?shade=Black_Honey"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/1603/4772/makeup/lip-glosses/almost-lipstick?shade=Black_Honey"}
        }
    },
    {
        "name": "Clinique Pop",
        "image": "https://www.clinique.com.mx/media/export/cms/products/1200x1500/cl_sku_KTJM02_1200x1500_0.png",
        "campaigns": [226, 221],
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/1605/86691/maquillaje/labiales/clinique-poptm-reds?shade=Red-Handed"},
            "Chile": {"url": "https://www.clinique.cl/product/1605/35082/makeup/lipsticks/lip-pop?shade=Plum_Pop"},
            "Brazil": {"url": "https://www.clinique.com.br/product/1605/35082/makeup/batons/batom-com-primer-clinique-poptm-colour-prime?shade=Plum_Pop"},
            "Japan": {"url": "https://www.clinique.jp/product/1605/35082/clinique-pop-lip-colour-primer?shade=14_%E3%83%97%E3%83%A9%E3%83%A0_%E3%83%9D%E3%83%83%E3%83%97"},
            "Thailand": {"url": "https://www.clinique.co.th/product/1605/35082/clinique-pop-lip-colour-and-primer?shade=Melon_Pop"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/1605/35082/clinique-pop-lip-colour-and-primer?shade=Cherry_Pop"},
            "Australia": {"url": "https://www.clinique.com.au/product/1605/86691/makeup/lipsticks/clinique-poptm-reds?shade=Red_Hot"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/1605/86691/makeup/lipsticks/clinique-poptm-reds?shade=Red_Hot"}
        }
    },
    {
        "name": "High Impact Mascara",
        "image": "https://www.clinique.com.mx/media/export/cms/products/1200x1500/cl_sku_68A001_1200x1500_0.png",
        "campaigns": [226, 221],
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/1606/5416/maquillaje/mascara/high-impact-mascara/clinique?shade=Black"},
            "Chile": {"url": "https://www.clinique.cl/product/1606/5416/makeup/mascara/high-impact-mascara?shade=Black"},
            "Brazil": {"url": "https://www.clinique.com.br/product/1606/5416/makeup/mascaras-de-cilios/mascara-de-cilios-high-impacttm?shade=Black"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/29219/7079/2019md/2021lastchance/lash-power-curling-mascara?shade=01&size=6ml"},
            "Australia": {"url": "https://www.clinique.com.au/product/1606/5416/makeup/mascara/high-impacttm-mascara?shade=Black"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/1606/5416/makeup/mascara/high-impact-mascara?shade=Black"}
        }
    },

    {
        "name": "Happy Fragrance",
        "image": "https://www.clinique.cl/media/export/cms/products/1200x1500/cl_sku_635M01_1200x1500_0.png",
        "campaigns": [193, 189],
        "locales": {
            "Chile": {"url": "https://www.clinique.cl/product/1577/5188/fragrance/clinique-happy-perfume-spray"},
            "Brazil": {"url": "https://www.clinique.com.br/product/1579/5188/perfumes/clinique-happy/perfume-clinique-happytm?size=50_ml"},
            "Japan": {"url": "https://www.clinique.jp/product/1579/5188/clinique-happy?size=50mL"},
            "Thailand": {"url": "https://www.clinique.co.th/product/1579/5188/clinique-happy/clinique-happy?size=50_ml"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/1577/5188/clinique-happy-perfume-spray/clinique-happy?size=100ML"},
            "Australia": {"url": "https://www.clinique.com.au/product/1579/5188/fragrance/clinique-happy/clinique-happy-perfume-spray"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/1579/5188/Fragrance/Clinique-Happy/Clinique-Happy-Perfume-Spray"}
        }
    },
    {
        "name": "Happy for Men",
        "image": "https://www.clinique.com.mx/media/export/cms/products/1200x1500/cl_sku_62YM01_1200x1500_0.png",
        "campaigns": [193, 189],
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/1617/5190/hombres/fragancia/clinique-happy-for-men/clinique-happy-for-men"},
            "Chile": {"url": "https://www.clinique.cl/product/1609/5190/mens/clinique-happy-for-men"},
            "Thailand": {"url": "https://www.clinique.co.th/product/1617/5190/clinique-happy-for-men/clinique-happy-for-men?size=%5B30%25_OFF%5D_50_ml."},
            "Taiwan": {"url": "https://liff.line.me/1429440178-lwY6XWYD/shipping/321743720?utm_source=copy&utm_medium=share"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/1617/5190/mens/fragrance/clinique-happy-for-men/clinique-happy-for-men-cologne-spray"}
        }
    },
    {
        "name": "Set Favoritos Clinique",
        "image": "https://www.clinique.cl/media/export/cms/products/1200x1500/cl_sku_V60YY2_1200x1500_0.png",
        "campaigns": [195, 227],
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/"},
            "Chile": {"url": "https://www.clinique.cl/product/13562/109498/navidad/makeup/set-favoritos-de-clinique-68-off"}
        }
    },
    {
        "name": "Set Favoritos Hidratación",
        "image": "https://www.clinique.com.mx/media/export/cms/products/1200x1500/cl_sku_V63YY2_1200x1500_0.png",
        "campaigns": [195, 227],
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/1685/109320/cuidado-de-la-piel/regalos/sets-de-cuidado-de-la-piel/moisture-surge-megastars-a-trio-of-hydration-heroes"},
            "Chile": {"url": "https://www.clinique.cl/product/1685/109320/skin-care/gifts/gifts-sets/set-favoritos-de-hidratacion42-off"},
            "Brazil": {"url": "https://www.clinique.com.br/product/9423/104215/holiday-2020/skincare/kit-hidratacao-moisture-surge-tm"}
        }
    },
    {
        "name": "Smart & Smooth: An Innovative De-Aging Trio",
        "image": "https://www.clinique.com.mx/media/export/cms/products/1200x1500/cl_sku_V64GY2_1200x1500_0.png",
        "campaigns": [195, 227],
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/1575/109475/best-sellers/smart-smooth-an-innovative-de-aging-trio"},
            "Chile": {"url": "https://www.clinique.cl/product/16422/109475/gift-center/gifts/set-rutina-antiedad-clinique-smart-smooth-48-off"},
            "Brazil": {"url": "https://www.clinique.com.br/product/16422/109427/presentes/kits/kit-especialista-em-anti-idade-smarttm"}
        }
    },
    {
        "name": "Set Esenciales Para Tus Ojos",
        "image": "https://www.clinique.com.mx/media/export/cms/products/1200x1500/cl_sku_V60PY2_1200x1500_0.png",
        "campaigns": [195, 227],
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/1685/110437/cuidado-de-la-piel/regalos/sets-de-cuidado-de-la-piel/eyes-on-the-fly-a-travel-friendly-trio-of-eye-essentials"},
            "Chile": {"url": "https://www.clinique.cl/product/1585/110437/gift-center/set-esenciales-para-tus-ojos-45-off"},
            "Brazil": {"url": "https://www.clinique.com.br/product/1592/109434/makeup/kit-olhos-ousados-high-impacttm"}
        }
    },
    {
        "name": "Get Smart: Set Rutina Smart Clinical Repair",
        "image": "https://www.clinique.com.mx/media/export/cms/products/1200x1500/cl_sku_V65FY2_1200x1500_0.png",
        "campaigns": [195, 227],
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/16422/110458/centro-de-regalos/gifts/get-smart-innovative-de-agers-plus-a-mascara"},
            "Chile": {"url": "https://www.clinique.cl/product/25434/110458/skincare/get-smart-set-rutina-smart-clinical-repair-57-off"},
            "Brazil": {"url": "https://www.clinique.com.br/product/1667/104216/skincare/kit-smart-clinical-repair-tm"}
        }
    },
    {
        "campaigns": [ 158, 134, 157, 113, 111, 133, 108, 156, 171, 170, 168, 173, 185, 140 ],
        "name": "Take the Day Off Cleansing Balm",
        "image": "https://www.clinique.com/media/export/cms/products/1200x1500/cl_sku_6CY401_1200x1500_0.png",
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/1673/6424/cuidado-de-la-piel/limpiadores-y-desmaquillantes/take-the-day-off-cleansing-balm/balsamo-desmaquillante-para-rostro-y-ojos?size=125_ml"},
            "Chile": {"url": "https://www.clinique.cl/product/1673/6424/skin-care/cleansers-makeup-removers/take-the-day-off--balsamo-desmaquillante-para-rostro-y-ojos?size=125_ml"},
            "Brazil": {"url": "https://www.clinique.com.br/product/1673/6424/skincare/limpeza/balsamo-demaquilante-take-the-day-offtm?size=125_ml"},
            "Japan": {"url": "https://www.clinique.jp/product/1673/6424/take-the-day-off?size=125mL"},
            "Thailand": {"url": "https://www.clinique.co.th/product/10589/6424/offers-lp/take-the-day-off-cleansing-balm?size=125_ml"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/1673/6424/take-the-day-off-cleansing-balm?size=125ml"},
            "Australia": {"url": "https://www.clinique.com.au/product/1673/6424/skin-care/cleansers-makeup-removers/take-the-day-off-cleansing-balm?size=125ml"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/1673/6424/skin-care/cleansers-makeup-removers/take-the-day-off-cleansing-balm?size=3.8_oz.%2F125ml"}
        }
    },
    {
        "campaigns": [ 158, 134, 157, 113, 111, 133, 108, 156, 171, 170, 168, 173, 185, 140 ],
        "name": "Smart Clinical Repair Wrinkle Correcting Serum",
        "image": "https://www.clinique.com/media/export/cms/products/1200x1500/cl_sku_V17E01_1200x1500_0.png",
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/4034/87057/cuidado-de-la-piel/sueros/clinique-smarttm-clinical-repair-wrinkle-correcting-serum?size=50_ml"},
            "Chile": {"url": "https://www.clinique.cl/product/4034/87057/skin-care/serums/clinique-smart-clinical-repair-suero-corrector-de-arrugas?size=50_ml"},
            "Brazil": {"url": "https://www.clinique.com.br/product/4034/87057/skin-care/seruns/serum-anti-idade-smarttm"},
            "Japan": {"url": "https://www.clinique.jp/product/13303/87057"},
            "Thailand": {"url": "https://www.clinique.co.th/product/4034/87057/skincare/clinique-smart-clinical-repair-wrinkle-correcting-serum"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/4034/87057/clinique-smart-clinical-repair-wrinkle-correcting-serum/a"},
            "Australia": {"url": "https://www.clinique.com.au/product/4034/87057/skin-care/serums/clinique-smart-clinical-repairtm-wrinkle-correcting-serum"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/4034/87057/skin-care/serums/clinique-smart-clinical-repairtm-wrinkle-correcting-serum"}
        }
    },
    {
        "campaigns": [ 158, 134, 157, 113, 111, 133, 108, 156, 171, 170, 168, 173, 185, 140 ],
        "name": "Smart Clinical Repair Wrinkle Correcting Cream",
        "image": "https://www.clinique.com/media/export/cms/products/1200x1500/cl_sku_V46N01_1200x1500_0.png",
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/16320/104214/cuidado-de-la-piel/clinique-smart/nuevo-clinique-smart-clinical-repairtm-wrinkle-correcting-cream/crema-antiedad"},
            "Chile": {"url": "https://www.clinique.cl/product/16320/104214/skin-care/clinique-smart/nueva-crema-anti-arrugas-smart-clinical-repair/todo-tipo-de-piel?size=1.7_oz._%2F_50ml_All_Skin_Types"},
            "Brazil": {"url": "https://www.clinique.com.br/product/16320/104214/skincare/clinique-smart/creme-anti-idade-clinique-smart-clinical-repairtm-wrinkle-correcting-cream?size=50_ml_Todos_os_Tipos_de_Pele"},
            "Japan": {},
            "Thailand": {},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/1687/104214/new-clinique-smart-clinical-repairtm-wrinkle-correcting-cream"},
            "Australia": {"url": "https://www.clinique.com.au/product/16320/104214/skin-care/clinique-smart/new-clinique-smart-clinical-repairtm-wrinkle-correcting-cream"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/16320/104214/skin-care/clinique-smart/new-clinique-smart-clinical-repairtm-wrinkle-correcting-cream"}
        }
    },
    {
        "campaigns": [ 158, 134, 157, 113, 111, 133, 108, 156, 171, 170, 168, 173, 185, 140 ],
        "name": "Smart Clinical Repair Wrinkle Correcting Eye Cream",
        "image": "https://www.clinique.com/media/export/cms/products/1200x1500/cl_sku_V1AX01_1200x1500_0.png",
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/9424/94055/lo-mas-hot/verano/clinique-smart-clinical-repair-wrinkle-correcting-eye-cream?size=15ml"},
            "Chile": {"url": "https://www.clinique.cl/product/9424/94055/hotlists/discovery-box/clinique-smart-clinical-repair-contorno-de-ojos-antiedad?size=15ml"},
            "Brazil": {"url": "https://www.clinique.com.br/product/16320/94055/skin-care/clinique-smart/creme-antirrugas-para-area-dos-olhos-smart-tm"},
            "Japan": {"url": "https://www.clinique.jp/product/1683/94055/-/clinique-smart-clinical-repair-wrinkle-correcting-eye-cream"},
            "Thailand": {"url": "https://www.clinique.co.th/product/9424/94055/discovery-box/clinique-smart-clinical-repair-wrinkle-correcting-eye-cream"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/1683/94055/clinique-smart-clinical-repair-wrinkle-correcting-eye-cream"},
            "Australia": {"url": "https://www.clinique.com.au/product/1683/94055/skin-care/eye-lip-care/clinique-smart-clinical-repair-wrinkle-correcting-eye-cream"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/1683/94055/skin-care/eye-lip-care/clinique-smart-clinical-repair-wrinkle-correcting-eye-cream"}
        }
    },
    {
        "campaigns": [158, 134, 157, 113, 111, 133, 108, 156, 171, 170, 168, 173, 185, 140, 173, 190, 194, 228, 223],
        "name": "Moisture Surge™ 100H Auto-Replenishing Hydrator",
        "image": "https://www.clinique.com/media/export/cms/products/1200x1500/cl_sku_KWW301_1200x1500_0.png",
        "locales": {
            "Mexico": {"url": "https://www.clinique.com.mx/product/1687/83690/cuidado-de-la-piel/humectantes/moisture-surgetm-100h-auto-replenishing-hydrator/moisture-surgetm-100h-auto-replenishing-hydrator"},
            "Chile": {"url": "https://www.clinique.cl/product/1687/83690/skin-care/moisturizers/moisture-surge-100h-auto-replenishing-hydrator"},
            "Brazil": {"url": "https://www.clinique.com.br/product/1687/83690/skin-care/hidratantes/hidratante-moisture-surge-tm-100h?size=15_ml"},
            "Japan": {"url": "https://www.clinique.jp/product/13301/83690/-100h/moisture-surge-100h-auto-replenishing-hydrator"},
            "Thailand": {"url": "https://www.clinique.co.th/product/1687/83690/skincare/moisturizers/moisture-surgetm-extended-replenishing-hydrator-100h"},
            "Taiwan": {"url": "https://www.clinique.com.tw/product/31269/83690/ms_family/moisture-surgetm-100h-auto-replenishing-hydrator/100h"},
            "Australia": {"url": "https://www.clinique.com.au/product/1687/83690/skin-care/moisturisers/moisture-surgetm-100h-auto-replenishing-hydrator"},
            "New Zealand": {"url": "https://www.clinique.co.nz/product/1687/83690/skin-care/moisturisers/moisture-surgetm-100h-auto-replenishing-hydrator"}
        }
    }
];