import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from '../../Common/Form/Form';
import Alert from '../../Common/Alert';

import {connect} from 'react-redux';
import {createCampaignCoupon} from "../../../actions";

class CreateCouponForm extends Component {

    static defaultProps = {
        style: {},
        usePadding: true,
        btnText: 'Save Coupon',
    };

    static propTypes = {
        campaign: PropTypes.object,
        onCreated: PropTypes.func,
        style: PropTypes.object,
        createCoupon: PropTypes.func,
        usePadding: PropTypes.bool,
        btnText: PropTypes.string,
    };

    state = {
        isSubmitting: false,
        didSubmit: false,
        form: {},
    };

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    handleSaveCoupon() {
        const {campaign, createCoupon, onCreated} = this.props;
        this.setState({isSubmitting: true});
        return createCoupon(campaign.id, this.state.form)
            .then((dispatched) => {
                if (onCreated) {
                    onCreated(dispatched);
                }
            })
            .then(() => this.setState({isSubmitting: false, didSubmit: true, form: {}}));
    }

    render() {

        const {isSubmitting, didSubmit} = this.state;

        return <Form
            fields={[
                {
                    type: 'text',
                    name: 'code',
                    title: 'Coupon Code',
                    help: 'The coupon code to add.',
                    placeholder: 'eg: 4XPROMO'
                },
                {
                    type: 'number',
                    name: 'monetary_value',
                    title: 'Monetary Value',
                    help: 'Enter the value of the coupon in dollars, without the dollar sign.',
                    placeholder: 'eg: 150',
                    options: {min: 0, max: 10000, step: 1},
                },
                {
                    type: 'text',
                    name: 'link_url',
                    title: 'Link URL',
                    help: '(Optional) A link or URL where the coupon can be claimed or used.',
                    placeholder: 'eg: http://myonlinestore.com/coupons/4XPROMO'
                },
                {
                    type: 'text',
                    name: 'terms_url',
                    title: 'Terms URL',
                    help: '(Optional) A link or URL for the Terms and Conditions of the coupon code.'
                },
                {
                    type: 'button',
                    title: isSubmitting ? 'Saving Coupon...' : this.props.btnText,
                    options: {
                        classes: ['v3', 'btn-primary', (isSubmitting ? 'disabled' : '')],
                        hideTitle: true,
                        onClick: this.handleSaveCoupon.bind(this),
                    }
                },
                (didSubmit || isSubmitting) ? {
                    type: 'generic',
                    content: <Alert
                        classes={['info']}
                        content={isSubmitting ? 'Saving coupon...' : 'Coupon Saved!'}
                        style={{marginBottom: 0}}
                    />
                } : null,
            ]}
            onFieldChange={this.handleFieldChange.bind(this)}
            usePadding={this.props.usePadding}
            values={this.state.form}
        />

    }

}

const mapStateToProps = (state, props) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createCoupon: (campaignId, payload) => dispatch(createCampaignCoupon(campaignId, payload)),
    };
};

const ConnectedCreateCouponForm = connect(mapStateToProps, mapDispatchToProps)(CreateCouponForm);

ConnectedCreateCouponForm.propTypes = {
    campaign: PropTypes.object.isRequired,
    style: PropTypes.object,
    onCreated: PropTypes.func,
    usePadding: PropTypes.bool,
    btnText: PropTypes.string,
};

export default ConnectedCreateCouponForm;