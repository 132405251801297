import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from "../Common/Toolbox";
import EditDraftForm from './EditDraftForm';

class CreateDraftToolbox extends Component {

    static defaultProps = {
        style: {},
        toolboxTitle: 'Create Draft',
    };

    static propTypes = {
        onClose: PropTypes.func,
        onSave: PropTypes.func,
        createInteraction: PropTypes.func,
        toolboxTitle: PropTypes.string,
        style: PropTypes.object,
        activation: PropTypes.object,
        addlClasses: PropTypes.string,
    };

    renderBody() {
        return <EditDraftForm
            activation={this.props.activation}
            usePadding={true}
            onSaved={this.props.onSave}
            createInteraction={this.props.createInteraction}
        />
    }

    render() {
        return (

            <Toolbox
                onClose={this.props.onClose}
                title={this.props.toolboxTitle}
                addlClasses={'draft-editor ' + (this.props.addlClasses ? this.props.addlClasses : '')}
                style={this.props.style}
                content={this.renderBody()}
            />

        );
    }
}

export default CreateDraftToolbox;
