/**
 * Created by bkroger on 10/22/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";
import {countries} from "../../../../utilities";

class Location extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
        refreshUser: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        isProfileFieldVisible: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {},
            isUpdating: false,
            didUpdate: false,
            hasError: false,
            error: null,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.getFieldValues();
        }
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
            hasError: false,
            error: null,
        });
    }

    handleClickSave() {
        this.setState({isUpdating: true, didUpdate: false});

        this.props.createInteraction(this.props.user.id, 'SetLocation', this.state.form)
            .then(resp => {
                if (resp.meta.error) {
                    this.setState({isUpdating: false, hasError: true, error: resp.meta.error})
                } else {
                    this.props.refreshUser(this.props.user.id);
                    this.setState({isUpdating: false, didUpdate: true});
                    setTimeout(() => this.setState({form: {}, didUpdate: false}), 2000);
                }
            });
    }

    handleRemoveLocation() {
        this.setState({isUpdating: true, didUpdate: false});

        this.props.createInteraction(this.props.user.id, 'RemoveLocation', this.state.form)
            .then(() => this.props.refreshUser(this.props.user.id))
            .then(() => this.setState({
                form: {
                    city_name: '',
                    state_name: '',
                    zipcode: ''
                },
                isUpdating: false,
                didUpdate: true
            }))
            .then(() => setTimeout(() => this.setState({form: {}, didUpdate: false}), 2000));
    }

    getFieldValues() {
        const nativeValues = this.props.user || {};
        const formValues = this.state.form;

        return {...nativeValues, ...formValues};
    }

    renderSaveAlert() {

        let style = {
            position: 'sticky',
            top: 0,
            zIndex: 200,
        };

        if (this.state.isUpdating) {
            return <Alert
                classes={['info']} content={"Saving your changes..."} style={style}
            />
        }

        if (this.state.didUpdate) {
            return <Alert
                classes={['success']} content={"Changes saved!"} style={style}
            />
        }

        if (this.state.hasError) {
            return <Alert
                classes={['danger']} content={this.state.error} style={style}
            />
        }

        if (Object.keys(this.state.form).length > 0) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>You have unsaved changes. <a className="v3 bold" onClick={this.handleClickSave.bind(this)} role="button">Save now.</a></span>}
                    style={style}
                />
            );
        }
    }

    render() {
        const isVisible = this.props.isProfileFieldVisible;
        return (
            <div className="pane-inner-content">
                {this.renderSaveAlert()}
                <p className={'v3 light h7 pane-info'}>
                    Adding your location to your profile will help brands find you easier. We do not show your zip code to brands. If you would like to have your location removed from our database, click the following link: <a role="button" onClick={this.handleRemoveLocation.bind(this)}>Remove Location</a>
                </p>
                <Form
                    values={this.getFieldValues()}
                    onFieldChange={this.handleFieldChange.bind(this)}
                    fields={[
                        isVisible('country_code') ? {
                            type: 'select',
                            name: 'country_code',
                            title: 'Country',
                            choices: Object.keys(countries).map(code => ({text: countries[code], value: code})),
                            options: {
                                defaultValue: "- Select your Country -",
                            },
                        } : null,
                        isVisible('city_name') ? {
                            type: 'text',
                            name: 'city_name',
                            title: 'City',
                        } : null,
                        isVisible('state_name') ? {
                            type: 'text',
                            name: 'state_name',
                            title: 'State',
                        } : null,
                        isVisible('zipcode') ? {
                            type: 'text',
                            name: 'zipcode',
                            title: 'Zip Code',
                        } : null,
                    ]}
                />
            </div>
        );
    }

}

export default Location;