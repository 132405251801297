import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from '../Common/Toolbox';
import BulkInvitationForm from './Bulk/BulkInvitationForm';
import {createActivationInteraction} from "../../actions/activations";
import Alert from "../Common/Alert";
import {connect} from 'react-redux';
import BulkSendEmailForm from "./Bulk/BulkSendEmailForm";
import BulkInvitationReminderForm from "./Bulk/BulkInvitationReminderForm";
import BulkWaitlistForm from "./Bulk/BulkWaitlistForm";
import BulkCancel from './Bulk/BulkCancel';
import BulkShipProduct from './Bulk/BulkShipProduct';
import BulkReactivate from './Bulk/BulkReactivate';
import BulkUpdatePaymentOffer from './Bulk/BulkUpdatePaymentOffer';
import BulkAcceptForm from "./Bulk/BulkAcceptForm";
import BulkApprovePayment from './Bulk/BulkApprovePayment';
import BulkPaymentSent from './Bulk/BulkPaymentSent';
import BulkDelete from "./Bulk/BulkDelete";

class BulkActivationInteractionToolbox extends Component {

    static propTypes = {
        activationIds: PropTypes.array.isRequired,
        campaign: PropTypes.object,
        type: PropTypes.string.isRequired,
        onComplete: PropTypes.func,
        onClose: PropTypes.func,
        refresh: PropTypes.func,
        style: PropTypes.object,
        createInteraction: PropTypes.func,
    };

    state = {
        numCompleted: 0,
        pendingIds: [],
    };

    getStyle() {
        let style = {
            top: '60px',
            left: '50%',
            marginLeft: '-300px',
            width: '600px',
            position: 'fixed',
            maxHeight: '600px',
            overflow: 'auto'
        };

        const largeToolboxes = [
        ];

        if (largeToolboxes.indexOf(this.props.type) > -1) {
            style.width = 1200;
            style.marginLeft = -600;
            style.height = 600;
            style.overflow = 'auto';
        }

        return {...style, ...this.props.style};
    }

    createNextInteraction(id, type, ctx = {}) {

        return this.props.createInteraction(id, type, ctx)
            .then(() => this.setState((state) => {
                return {
                    numCompleted: state.numCompleted + 1
                };
            }));

    }

    async createInteractions(type, ctx = {}) {

        let pendingIds = [...this.props.activationIds];
        this.setState({pendingIds});
        let id;

        while (id = pendingIds.pop()) {
            await this.setState({pendingIds});
            await this.createNextInteraction(id, type, ctx);
        }

        if (this.props.refresh) {
            this.props.refresh();
        }

        if (this.props.onComplete) {
            this.props.onComplete();
        }



    }

    renderInnerContent() {

        const {activationIds} = this.props;
        const isWorking = this.state.pendingIds.length > 0;

        switch (this.props.type) {
            case 'AcceptAfterWaitlist':
                return <BulkAcceptForm
                    activationIds={activationIds}
                    isUpdating={isWorking}
                    onClickSubmit={!isWorking ? this.createInteractions.bind(this, 'AcceptAfterWaitlist', {}) : null}
                />;
                break;

            case 'UpdatePaymentOffer':

                return <BulkUpdatePaymentOffer
                    activationIds={activationIds}
                    isUpdating={isWorking}
                    onClickSubmit={!isWorking ? (payment_amount) => this.createInteractions('UpdatePaymentOffer', {payment_amount}) : null}
                />;

                break;

            case 'Invite':

                return <BulkInvitationForm
                    activationIds={activationIds}
                    onInvite={!isWorking ? this.createInteractions.bind(this, 'Invite', {dont_resend: true}) : null}
                    isUpdating={isWorking}
                />;

                break;

            case 'InviteReminder':
                return <BulkInvitationReminderForm
                    activationIds={activationIds}
                    onInvite={!isWorking ? this.createInteractions.bind(this, 'InviteReminder', {}) : null}
                    isUpdating={isWorking}
                />;
                break;

            case 'Waitlist':

                return <BulkWaitlistForm
                    activationIds={activationIds}
                    onInvite={!isWorking ? this.createInteractions.bind(this, 'Waitlist', {}) : null}
                    isUpdating={isWorking}
                />;

                break;

            case 'SendEmail':

                return <BulkSendEmailForm
                    activationIds={activationIds}
                    onSubmit={!isWorking ? this.createInteractions.bind(this, 'SendEmail') : null}
                    isSubmitting={isWorking}
                />;

                break;
            case 'Cancel':
                return <BulkCancel
                    activationIds={activationIds}
                    onCancel={!isWorking ? this.createInteractions.bind(this, 'Cancel', {}) : null}
                    isUpdating={isWorking}
                />;
            break;
            case 'Delete':
                return <BulkDelete
                    activationIds={activationIds}
                    onDelete={!isWorking ? this.createInteractions.bind(this, 'DeleteActivation', {}) : null}
                    isUpdating={isWorking}
                />;
                break;
            case 'ShipProduct':
                return <BulkShipProduct
                    activationIds={activationIds}
                    onShipped={!isWorking ? this.createInteractions.bind(this, 'ShipProduct', {}) : null}
                    isUpdating={isWorking}
                />;
            break;
            case 'Reactivate':
                return <BulkReactivate
                    activationIds={activationIds}
                    onReactivate={(campaign_id) => !isWorking ? this.createInteractions.bind(this, 'Reactivate', {force: true, campaign_id}) : null}
                    isUpdating={isWorking}
                />;
            case 'GenerateInvoice':
                return <BulkApprovePayment
                    activationIds={activationIds}
                    isUpdating={isWorking}
                    onSubmit={!isWorking ? this.createInteractions.bind(this, 'GenerateInvoice', {}) : null}
                />;
            break;
            case 'PaymentSent':
                return <BulkPaymentSent
                    activationIds={activationIds}
                    onSubmit={!isWorking ? this.createInteractions.bind(this, 'PaymentSent', {}) : null}
                    isUpdating={isWorking}
                />;
            break;
        }

    }

    getTitle() {
        const {type, activationIds} = this.props;
        const numInfluencers = activationIds.length + ' User' + (activationIds.length !== 1 ? 's' : '');

        switch (type) {

            case 'UpdatePaymentOffer':
                return `Set Payment Amount for ${numInfluencers}`;
                break;

            case 'Invite':
                return `Invite ${numInfluencers}`;
                break;

            case 'InviteReminder':
                return `Send Invitation Reminders to ${numInfluencers}`;
                break;

            case 'AcceptAfterWaitlist':
                return `Accept ${numInfluencers}`;
                break;

            case 'Waitlist':
                return `Waitlist ${numInfluencers}`;
                break;

            case 'SendEmail':
                return `Message ${numInfluencers}`;
                break;
            case 'Cancel':
                return `Cancel ${numInfluencers}`;
                break;
            case 'ShipProduct':
                return `Mark ${numInfluencers} as Shipped`;
                break;
            case 'Reactivate':
                return `Add ${numInfluencers} to Another Campaign`;
                break;
            case 'GenerateInvoice':
                return `Approve Payments for ${numInfluencers}.`;
                break;
            case 'PaymentSent':
                return `Send Payment Confirmations for ${numInfluencers}.`;
                break;
            default:
                return `${numInfluencers}`
        }

    }

    renderProgress() {
        const {pendingIds} = this.state;
        const {activationIds} = this.props;
        const totalCount = activationIds.length;
        const pendingCount = pendingIds.length;
        const completedCount = totalCount - pendingCount;

        if (!pendingCount) {
            return;
        }

        return <Alert
            classes={['info']}
            content={`Finished ${completedCount} of ${totalCount}...`}
        />
    }

    renderContent() {
        return (
            <div>
                {this.renderProgress()}
                {this.renderInnerContent()}
            </div>
        );

    }

    render() {
        const {onClose} = this.props;
        const style = this.getStyle();
        const title = this.getTitle();

        return <Toolbox
            supportMobile={true}
            onClose={onClose}
            style={style}
            title={title}
            content={this.renderContent()}
        />;

    }
}

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (id, type, ctx) => dispatch(createActivationInteraction(id, type, ctx)),
    };
};

const ConnectedBulkActivationInteractionToolbox = connect(mapStateToProps, mapDispatchToProps)(BulkActivationInteractionToolbox);
export default ConnectedBulkActivationInteractionToolbox;
