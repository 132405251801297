import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MyCreatorsList from "../../../containers/Pages/Manage/Users/MyCreatorsList";
import Toolbox from "../../Common/Toolbox";

class AddUsersToCampaignToolbox extends Component {
    static propTypes = {
        onClose: PropTypes.func,
        style: PropTypes.object,
        campaign: PropTypes.object,
        onActivatedUsers: PropTypes.func,
    };

    static defaultProps = {
        style: {
            top: 60,
            left: 60,
            width: 'calc(100% - 120px)',
            height: 'calc(100% - 120px)',
            position: 'fixed',
            overflow: 'auto'
        }
    };

    render() {
        return (
            <Toolbox
                style={this.props.style}
                content={
                    <MyCreatorsList
                        useActivateButton={(this.props.campaign || {}).id}
                        onClose={this.props.onClose}
                        onActivatedUsers={this.props.onActivatedUsers}
                    />
                }
            />
        );
    }
}


export default AddUsersToCampaignToolbox;
