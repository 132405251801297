import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GenericQuickFiltersButton from "../Common/GenericQuickFiltersButton";

class QuickFiltersButton extends Component {
    static propTypes = {
        onClickFilter: PropTypes.func,
        listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    };

    render() {

        return (
            <GenericQuickFiltersButton
                key={'list-quick-filters-btn'}
                checks={[
                    {
                        name: "Unrated Items",
                        filters: {"unrated": true},
                    },
                    {
                        name: "Missing Email Address",
                        filters: {"missing_email": true},
                    },
                    {
                        name: "Recent Emails",
                        filters: {"recentEmails": 1},
                    },
                    {
                        name: "Recent Campaign Posts",
                        filters: {"recentCampaignPosts": 1},
                    },
                    {
                        name: "Recent Campaign Activity",
                        filters: {"recentCampaignActivity": 1},
                    },
                    {
                        name: "Open Campaign Issues",
                        filters: {"hasOpenIssues": 1}
                    },
                    {
                        name: "Open Campaign Drafts",
                        filters: {"hasOpenDrafts": 1}
                    },
                    {
                        name: "Recent Profile Updates",
                        filters: {"recentlyUpdatedProfile": 1}
                    },
                    {
                        name: 'Reset Filters',
                        filters: {},
                    },
                ]}
                classes={['btn-primary']}
                content={'Quick Filters'}
                apiUrl={`/manage/api/userlist/${this.props.listId}/entry`}
                wrapperStyle={{marginRight: 10}}
                onClickFilter={this.props.onClickFilter}
            />
        );
    }
}

export default QuickFiltersButton;
