import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {fetchAuthenticated, formatNumber, makeCellKey, makeMomentFromDate} from "../../utilities";
import {connect} from 'react-redux';
import {getCurrentUser} from "../../selectors/presence";
import TableWrapper from '../Table/Wrapper';
import {createUserInteraction} from "../../actions";
import BasicCell from "../Table/Cell/Basic";
import DateCell from "../Table/Cell/Date";
import NumberCell from "../Table/Cell/Number";
import MiniProfile from "../Common/MiniProfile";
import {socialNameForType} from "../../utilities/social";
import Image from "../Common/Image";
import _get from 'lodash/get';
import _find from 'lodash/find';


const INITIAL_STATE = {
    insights: null,
    isFetchingInsights: false,
    didFetchInsights: false,

    comments: null,
    isFetchingComments: false,
    didFetchComments: false,
};

class InstagramBusinessPost extends Component {

    static defaultProps = {
        style: {
            position: 'fixed',
            top: 20,
            left: 100,
            width: 'calc(100% - 200px)',
            height: 'calc(100% - 40px)',
            background: '#FFF',
            boxShadow: '0 0 40px -5px rgba(0,0,0,0.5)',
            overflow: 'auto',
            zIndex: 101,
        }
    };

    static propTypes = {
        post: PropTypes.object.isRequired,
        social: PropTypes.object.isRequired,
        user: PropTypes.object,
        createInteraction: PropTypes.func,
        onClose: PropTypes.func,
        style: PropTypes.object,
    };

    state = {...INITIAL_STATE};


    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.post.source_id !== this.props.post.source_id) {
            // Resets state then refreshes.
            this.setState({...INITIAL_STATE}, () => this.refresh());
        }

    }

    refresh() {
        this.fetchInsights();
        this.fetchComments();
    }

    fetchComments() {
        const url = `/${window.Gator.getDashboardSlot()}/api/social/${this.props.social.id}/post_comment/${this.props.post.source_id}`;
        this.setState({isFetchingComments: true});
        return fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => this.setState({
                comments: json.data,
                isFetchingComments: false,
                didFetchComments: true,
            }));
    }

    fetchInsights() {
        const url = `/${window.Gator.getDashboardSlot()}/api/social/${this.props.social.id}/post_insight/${this.props.post.source_id}`;
        this.setState({isFetchingInsights: true});
        return fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => this.setState({
                insights: json.data,
                isFetchingInsights: false,
                didFetchInsights: true,
            }));
    }

    renderHeader() {
        return (
            <div
                style={{
                    padding: '10px 20px',
                    borderBottom: '1px solid #EEE',
                    background: '#FAFAFA',
                }}
            >
                <MiniProfile
                    primary={<a href={this.props.social.url} target="_blank">{this.props.social.name}</a>}
                    secondary={socialNameForType(this.props.social.type)}
                    imageUrl={this.props.social.profile_picture_url}
                />

                { this.props.onClose
                    &&
                    <a
                        style={{top: 25}}
                        role={'button'}
                        className={'v3 close absolute'}
                        onClick={this.props.onClose} />
                }

            </div>
        );
    }

    renderPostContent() {

        return (
            <div
                style={{
                    float: 'left',
                    width: 450,
                    marginRight: 60,
                    paddingTop: 20,
                }}
            >

                <div
                    style={{
                        maxWidth: 600,
                        marginBottom: 20,
                    }}
                >
                    <p><a href={this.props.post.permalink} target="_blank" className="v3 dark bold">View on Instagram</a></p>
                    {this.props.post.content}
                </div>

                <div
                    style={{maxWidth: 600}}
                >
                    <Image image={{original_url: this.props.post.image_urls[0]}} />
                </div>

            </div>
        )
    }

    renderContent() {
        return (
            <div
                style={{
                    padding: 20
                }}
                className={'clearfix'}
            >
                <h3
                    className={"v3 bold"}
                    style={{marginBottom: 20}}

                >{this.props.post.title}</h3>

                <div
                    className={"v3 h6 light quote"}
                    style={{marginBottom: 20}}
                >{makeMomentFromDate(this.props.post.source_created_at).format('MMM Do YYYY')}</div>

                {this.renderPostContent()}
                {this.renderInsights()}
            </div>
        );
    }

    getInsightValue(key) {

        if (!this.state.didFetchInsights) {
            return 0;
        }

        const item = _find(this.state.insights, {name: key});

        if (!item) {
            return 0;
        }

        return item.values[0].value;

    }

    renderInsights() {
        if (!this.state.didFetchInsights) {
            return null;
        }

        const engagements = this.getInsightValue('engagement');
        const comments = this.props.post.engagements['instagram-comments'];
        const likes = this.props.post.engagements['instagram-likes'];
        const impressions = this.getInsightValue('impressions');
        const reach = this.getInsightValue('reach');
        const saved = this.getInsightValue('saved');
        const followers = this.props.social.followers_count;

        const engPctReach = 100 * (engagements / reach);
        const engPctFollowers = 100 * (engagements / followers);

        const renderItem = (title, value) => {
            return (
                <div
                    className={'clearfix'}
                    style={{
                        padding: '10px 0',
                        margin: '10px 0',
                        borderBottom: '1px solid #eee',
                    }}
                >
                    {title}
                    <span
                        className={'v3 bold pull-right'}
                        style={{}}
                        >{value}</span>
                </div>
            )
        };

        return (
            <div
                style={{
                    float: 'left',
                    width: 300,
                    position: 'sticky',
                    top: 0,
                }}
            >
                {renderItem('Engagements', formatNumber(engagements))}
                {renderItem('Likes', formatNumber(likes))}
                {renderItem('Comments', formatNumber(comments))}
                {renderItem('Followers', formatNumber(followers))}
                {renderItem('Impressions', formatNumber(impressions))}
                {renderItem('Saves', formatNumber(saved))}
                {renderItem('Eng. Pct. (Reach)', formatNumber(engPctReach, 1) + '%')}
                {renderItem('Eng. Pct. (Followers)', formatNumber(engPctFollowers, 1) + '%')}
            </div>
        );
    }

    renderComments() {
        if (!this.state.didFetchComments) {
            return null;
        }

        let comments = [...this.state.comments];
        comments.reverse();

        return (
            <div
                style={{paddingTop: 40, background: '#FAFAFA'}}
            >
            <TableWrapper
                showPagination={false}

                title={'Comments'}
                headerIcon={<span className="badge large">{comments.length}</span>}
                items={comments}
                columns={[
                    {
                        key: 'username',
                        title: 'By',
                        default: true,
                        sortable: false,
                        width: 200,
                        cell: (row, column) => <BasicCell row={row} column={column} key={makeCellKey(row, column)}
                                                          value={row.item.username}
                                               />
                    },
                    {
                        key: 'timestamp',
                        title: 'Date',
                        default: true,
                        sortable: false,
                        width: 160,
                        cell: (row, column) => <DateCell row={row} column={column} key={makeCellKey(row, column)}
                                                          value={row.item.timestamp}
                        />
                    },
                    {
                        key: 'like_count',
                        title: 'Likes',
                        default: true,
                        sortable: false,
                        width: 100,
                        cell: (row, column) => <NumberCell row={row} column={column} key={makeCellKey(row, column)}
                                                         value={row.item.like_count}
                        />
                    },
                    {
                        key: 'text',
                        title: 'Comment',
                        default: true,
                        sortable: false,
                        width: 800,
                        cell: (row, column) => <BasicCell row={row} column={column} key={makeCellKey(row, column)}
                                                          value={row.item.text}
                        />
                    },

                ]}
            />
            </div>
        );
    }

    render() {

        return (
            <div
                style={this.props.style}
                className={'instagram-business-post-wrapper'}
            >

                {this.renderHeader()}
                {this.renderContent()}
                {this.renderComments()}

            </div>
        );

    }
}

const mapStateToProps = (state, props) => {
    return {
        user: getCurrentUser(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (id, type, context) => dispatch(createUserInteraction(id, type, context)),
    };
};

const ConnectedInstagramBusinessPost = connect(mapStateToProps, mapDispatchToProps)(InstagramBusinessPost);

export default ConnectedInstagramBusinessPost;
