import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '../../Common/Form/Button';

class PublishOnCampaign extends Component {
    
    static propTypes = {
        campaignList: PropTypes.arrayOf(
            PropTypes.shape({
                campaignName: PropTypes.string,
                campaignSlug: PropTypes.string,
                campaignId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                status: PropTypes.string,
            })
        ),
        postId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onComplete: PropTypes.func,
        publish: PropTypes.func,
    };
        
    static defaultProps = {
       onComplete: () => {},
    };

    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            didSubmit: false,
        };
    };

    makeRow = (row) => {
        const slug = row.campaignSlug;
        const {isSubmitting, didSubmit} = this.state;
        const classes = ['v3', 'small', (!!isSubmitting[slug] || !!didSubmit[slug]) ? 'btn-secondary' : 'btn-primary'];
        const btnStyle = {height: '100%', padding: 10, lineHeight: 'normal'};

        return <div className='fake-li padded bottom-border' key={row.campaignId}>
            <div className="campaign-name"><span>{row.campaignName}</span></div>
        <span className='pull-right'>
            {
                row.status === 'Published'
                ? <Button
                    content={this.getBtnText(slug, 'unpublish')}
                    classes={classes}
                    onClick={() => this.publish(slug, 'remove_post_from_campaign')}
                    style={btnStyle}
                />
                : <Button 
                    content={this.getBtnText(slug, 'publish')}
                    classes={classes}
                    onClick={() => this.publish(slug, 'add_post_to_campaign')}
                    style={btnStyle}
                />
            }
            </span>
        </div>
    };

    getBtnText = (slug, type) => {
        const {isSubmitting, didSubmit} = this.state;
        let btnText = 'Publish on Campaign';

        switch(type) {
            case 'publish':
                if (!!isSubmitting[slug]) btnText = 'Publishing...';
                else if (!!didSubmit[slug]) btnText = 'Published!';
                else btnText = 'Publish on Campaign';
                break;
            case 'unpublish':
                if (!!isSubmitting[slug]) btnText = 'Removing...';
                else if (!!didSubmit[slug]) btnText = 'Removed!';
                else btnText = 'Remove';
                break;
        }
        return btnText;
    };

    publish(campaign_slug, type) {
        this.setState({
            isSubmitting: {[campaign_slug]: true}
        });

        return this.props.publish(campaign_slug, type)
            .then(() => {
                this.setState({
                    isSubmitting: {[campaign_slug]: false},
                    didSubmit: {[campaign_slug]: true},
                })
            })
            .then(() => this.props.onComplete())
            .then(() => this.setState({
                didSubmit: {[campaign_slug]: false}
            }))
            .catch(console.error)
    }

    render() {
        const {campaignList} = this.props;

        return <>
        {
            campaignList.map(campaign => {
                return this.makeRow(campaign);
            })
        }
        </>
    }
};

export default PublishOnCampaign;