import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TinyThermometer from "../../Common/TinyThermometer";
import {c3ColorPattern, formatNumber} from "../../../utilities";
import Tooltip from "../../Common/Tooltip";

class AudienceCategoryChart extends Component {
    static propTypes = {
        data: PropTypes.object,
        minValue: PropTypes.number,
        limit: PropTypes.number,
        upscale: PropTypes.number,
        autoSort: PropTypes.bool,
        colors: PropTypes.array,
        thermometerStyle: PropTypes.object,
        showAbsoluteValue: PropTypes.bool,
        absoluteValueMaxCount: PropTypes.bool,

    };

    static defaultProps = {
        minValue: 0,
        limit: 100,
        upscale: 0.75,
        autoSort: true,
        colors: c3ColorPattern.pattern,
        showAbsoluteValue: false,
        absoluteValueMaxCount: 0
    };

    getData() {
        let out = [];
        for (let key in this.props.data) {
            out.push({name: key, value: this.props.data[key]});
        }

        if (this.props.autoSort) {
            out = out.sort((a, b) => {
                return a.value > b.value ? -1 : 1;
            });
        }

        out = out.filter(item => {
            return item.value > this.props.minValue;
        });

        out = out.slice(0, this.props.limit);

        return out;
    }

    renderBars() {
        return this.getData().map((item, index) => this.renderBar(item, index));
    }

    getScaleFactor() {
        if (!this.props.upscale) {
            return 1;
        }

        const max = this.getData().sort((a, b) => a.value > b.value ? -1 : 1)[0].value;
        if (!max) {
            return 1;
        }

        return this.props.upscale / max;

    }
    renderBar(item, index) {
        const color = this.props.colors[index];
        const scale = this.getScaleFactor();
        const formattedPct = formatNumber(100 * item.value, 1) + '%';
        const formattedValue = formatNumber(item.value * this.props.absoluteValueMaxCount, 0);
        const fullValueLabel = this.props.showAbsoluteValue ? `${formattedValue} (${formattedPct})` : formattedPct;
        return (
            <div className="item" key={`category-index-${index}-${item.name}`}>
                <div className="item-name">{item.name}</div>
                <div className="item-value">
                    <Tooltip title={`${item.name}: ${fullValueLabel}`}>
                        <TinyThermometer style={this.props.thermometerStyle} label={fullValueLabel} pct={100 * scale * item.value} color={color} />
                    </Tooltip>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="category-chart-wrapper">
                {this.renderBars()}
            </div>
        );
    }
}

export default AudienceCategoryChart;