import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ConnectedManageProduct from './ManageProduct';
import Toolbox from "../Common/Toolbox";

const makeForm = (item, catalogId) => {
    let form = {
        name: item.name || '',
        description: item.description || '',
        sku: item.sku || '',
        gender: item.gender || '',
        size: item.size || '',
        color: item.color || '',
        category: item.category || '',
        subcategory: item.subcategory || '',
        product_url: item.product_url || '',
        monetary_value: item.monetary_value || 0,
        monetary_locale: 'en-US',
        brand_name: item.brand_name || '',
        image_ids: [],
        source_id: item.source_id || '',
        product_catalog_id: catalogId,
        product_id: item.id,
        image: null,
        locales: item.locales || {}
    };

    const images = item.images;
    // convert images api object to be an array for the form and future interactions
    if (images.length) {
        form.image = Number(form.image = images[0].id);
        images.forEach(image => form.image_ids.push(image.id));
    }

    return form;
};

class EditProduct extends Component {

    static propTypes = {
        onClose: PropTypes.func,
        item: PropTypes.object,
        catalogId: PropTypes.number,
        refresh: PropTypes.func,
        embedded: PropTypes.bool,
    };

    static defaultProps = {
        embedded: false,
    };

    getStyle() {
        let style = {
            top: 60,
            left: '50%',
            width: 800,
            marginLeft: -400,
            backgroundColor: '#EEE',
        };

        if (this.props.embedded) {
            delete style.marginLeft;
            delete style.top;
            delete style.left;
            style.width = '100%';
            style.position = 'relative';
        }

        return style;
    }

    render() {
        const {item, catalogId} = this.props;
        const form = makeForm(item, catalogId);

        return <Toolbox
            style={this.getStyle()}
            title='Edit a Product'
            onClose={this.props.onClose}
            content={<ConnectedManageProduct
                form={form}
                onClose={this.props.onClose}
                interactionType={'UpdateProduct'}
                catalogId={this.props.catalogId}
                refresh={this.props.refresh}
                showDeleteBtn={true}
            />}
        />
    }
}

export default EditProduct;