import React, {Component} from 'react';
import ConnectedEmails from "../../Pages/Manage/Users/Emails";
import PropTypes from  'prop-types';
import Toolbox from "../../Common/Toolbox";
import ConnectedEmailCompose from "../../Email/Compose";

class EmailPaneManageDash extends Component {

    state = {
        showComposeToUser: false
    };

    renderComposePopup() {
        if (!this.state.showComposeToUser) {
            return null;
        }

        const {user} = this.props;
        return (
            <Toolbox
                key={'user-detail-email-popup'}
                title={<><i className="fa fa-send"/> Compose</>}
                addlClasses={['toolbox-fixed toolbox-sm']}
                onClose={() => this.setState({showComposeToUser: false})}
                content={<ConnectedEmailCompose
                    showTitle={false}
                    to={[
                        {
                            name: user.basics.name,
                            email: user.basics.email,
                            type: 'Tidal\\User',
                            id: user.id
                        }
                    ]}
                    onSent={() => this.setState({showComposeToUser: false})}
                />}
            />
        );
    }

    render() {

        const {user} = this.props;

        return (
            <>
                {this.renderComposePopup()}
                <ConnectedEmails
                    key={`user-detail-emails-${user.id}`}
                    query={{
                        id: `user-detail-emails-${user.id}`,
                        filters: {
                            'recipients.user_id': user.id
                        }
                    }}
                    onClickComposeToUser={() => {
                        this.setState({showComposeToUser: true})
                    }}
                />
            </>
        )
    }
}

export default EmailPaneManageDash;

EmailPaneManageDash.propTypes = {
    user: PropTypes.object.isRequired,
};