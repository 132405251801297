/**
 * Created by bkroger on 6/19/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {base64_png_1pxSolidEEE} from "../../../utilities";

export default class Avatar extends Component {
    static defaultProps = {
        image: null,
        layout: 'left',
    };

    static propTypes = {
        layout: PropTypes.string,
        image: PropTypes.string,
    };

    render() {

        const {layout, image} = this.props;

        const float = layout === 'left' ? 'right' : 'left';

        const style = {
            float: float,
            backgroundImage: 'url(' + (image || 'data:image/png;base64,' + base64_png_1pxSolidEEE) + ')',
        };

        return (
            <div className="timeline-message-avatar" style={style}>
            </div>
        )
    }

}