import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SocialProfile from "../../../Social/Profile";

class GenericProfileSocialsPane extends Component {
    static propTypes = {
        socials: PropTypes.array.isRequired,
    };

    renderSocial(social) {
        return (
            <SocialProfile key={`social-id-${social.id}`} social={social} />
        );
    }

    render() {
        return (
            <div className="generic-profile-pane-wrapper">
                {this.props.socials.map(social => this.renderSocial(social))}
            </div>
        );

    }
}

export default GenericProfileSocialsPane;
