import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tooltip from "../../../Common/Tooltip";

class LiteTableRow extends Component {

    renderRowContent() {
        const {content} = this.props;
        return Object.keys(content).map(function(key, index) {
            return content[key];
        });
    }

    renderSwitch(spec) {

        if (!spec) {
            return null;
        }

        let icon = spec.icon;
        if (typeof spec.tooltip === 'string') {
            icon = <Tooltip title={spec.tooltip}>{spec.icon}</Tooltip>
        }

        return (
            <a
                key={spec.key || spec.name}
                role="button"
                className="switch"
                onClick={spec.onClick ? spec.onClick.bind(this) : null}
            >{icon}</a>
        );

    }

    renderSwitches() {
        const {rowSwitches} = this.props;

        if (!rowSwitches || rowSwitches.length === 0) {
            return null;
        }

        return (
            <div className={'switches'}>
                {(rowSwitches || []).map(this.renderSwitch.bind(this))}
            </div>
        );
    }

    render() {
        return (
            <div className="lite-table-row clearfix">
                <div className="content">
                    {this.renderRowContent()}
                </div>
                {this.props.rowSwitches && this.renderSwitches()}
            </div>
        )
    }

}

LiteTableRow.propTypes = {
    content: PropTypes.any.isRequired,
    rowSwitches: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.node,
        tooltip: PropTypes.string,
        onClick: PropTypes.func,
    })),
};

export default LiteTableRow;