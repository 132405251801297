/**
 * Created by visgotti on 7/13/17.
 */

import {REQUEST_NOTIFICATIONS, RECEIVE_NOTIFICATIONS,
    MARKED_NOTIFICATION_READ, MARK_NOTIFICATION_READ,
    MARKED_NOTIFICATION_HIDDEN, MARK_NOTIFICATION_HIDDEN,
    MARKED_ALL_NOTIFICATIONS_READ, MARK_ALL_NOTIFICATIONS_READ
} from '../actions';
import {indexArrayById} from '../utilities';

const DEFAULT_NOTIFICATIONS_STATE = {
    items: {},
    isFetching: false,
    lastUpdated: null,
    isMarkingAllRead: false,
};

export function notificationsById(state = DEFAULT_NOTIFICATIONS_STATE, action) {
    switch (action.type) {

        case MARK_ALL_NOTIFICATIONS_READ: {
            return {...state, isMarkingAllRead: true};
        }

        case MARKED_ALL_NOTIFICATIONS_READ: {
            let items = {...state.items};
            for (let id in items) {
                items[id].is_read = true;
            }
            return {...state, items, isMarkingAllRead: false};
        }

        case MARK_NOTIFICATION_HIDDEN: {
            let items = {...state.items, [action.notificationId]: {...state.items[action.notificationId], isMarkingHidden: true}};
            return {...state, items};
        }

        case MARKED_NOTIFICATION_HIDDEN: {
            let items = {...state.items, [action.notificationId]: {...state.items[action.notificationId], isMarkingHidden: false, is_hidden: action.hidden}};
            return {...state, items};
        }

        case MARK_NOTIFICATION_READ: {
            let items = {...state.items, [action.notificationId]: {...state.items[action.notificationId], isMarkingRead: true}};
            return {...state, items};
        }

        case MARKED_NOTIFICATION_READ: {
            let items = {...state.items, [action.notificationId]: {...state.items[action.notificationId], isMarkingRead: false, is_read: action.read}};
            return {...state, items};
        }

        case REQUEST_NOTIFICATIONS:
            return Object.assign({}, state, {isFetching: true});

        case RECEIVE_NOTIFICATIONS: {

            let subnotifications = [];

            // Extract resolved by ids
            let newItems = action.notifications.map(n => {
                let resolvers = n.resolved_by;
                let resolverIds = resolvers.map(r => r.id);
                subnotifications = subnotifications.concat(resolvers);
                n = Object.assign({}, n, {resolved_by_ids: resolverIds});
                delete n.resolved_by;
                return n;
            });

            let combined = newItems.concat(subnotifications);

            let items = {...state.items, ...indexArrayById(combined)};
            return {...state, isFetching: false, lastUpdated: action.receivedAt, items};
        }

        default:
            return state;
    }
}
