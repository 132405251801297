import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Form from '../Common/Form/Form';
import Alert from '../Common/Alert';
import {countries} from "../../utilities";
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";
import {translate} from "../../actions/i18n";

class ShippingAddressForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {...((props.activation || {}).shipping_address || {}) },
            isSubmitting: false,
            didSubmit: false,
            error: null,
        };
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static defaultProps = {
        usePadding: false,
        style: {},
    };

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onSaved: PropTypes.func,
        usePadding: PropTypes.bool,
        style: PropTypes.object,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
        translate: PropTypes.func,
    };

    componentDidMount() {
        this.props.fetchActivation(this.props.activation.id)
            .then(() => this.resetForm());
    }

    _t(k, v) {
        const key = `campaign.address.form.${k}`;
        if (this.props.translate) {
            return this.props.translate(key, v);
        }
        return v;
    }
    resetForm() {
        this.setState({
            form: {...((this.props.activation || {}).shipping_address || {}) }
        });
    }

    handleSubmit() {
        const {activation} = this.props;
        this.setState({isSubmitting: true, error: null});
        return this.props.createInteraction(activation.id, 'SubmitShippingAddress', this.state.form)
            .then(resp => {

                if (resp.meta.error) {

                    this.setState({
                        isSubmitting: false,
                        didSubmit: false,
                        error: resp.meta.error,
                    });

                } else {

                    this.setState({
                        isSubmitting: false,
                        didSubmit: true
                    });

                    this.props.fetchActivation(activation.id);

                    if (this.props.onSaved) {
                        this.props.onSaved(this.state.form);
                    }

                    this.resetForm();

                }
            });
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    render() {
        const {isSubmitting, didSubmit} = this.state;
        return <Form
            style={this.props.style}
            usePadding={this.props.usePadding}
            values={this.state.form}
            onFieldChange={this.handleFieldChange}
            fields={[
                {
                    type: 'text',
                    name: 'name',
                    title: this._t('name.title', 'Your Name'),
                    placeholder: 'eg: Burak Kanber',
                    help: this._t('name.help', "Your first and last name"),
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'address1',
                    title: this._t('address1.title', 'Address Line 1'),
                    placeholder: 'eg: 123 First Avenue',
                    help: this._t('address1.help', "The address where your shipments should be sent"),
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'address2',
                    title: this._t('address2.title', 'Address Line 2'),
                    placeholder: 'eg: Apt 6',
                    help: this._t('address2.help', "Optional. Additional address info such as apartment or suite number"),
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'city',
                    title: this._t('city.title', 'City'),
                    placeholder: 'eg: Los Angeles',
                    help: this._t('city.help', "Enter the city for your shipping address"),
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'region',
                    title: this._t('state.title', 'State'),
                    placeholder: 'eg: CA',
                    help: this._t('state.help', "Enter the state for your shipping address"),
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'postal',
                    title: this._t('postal.title', 'Zip Code'),
                    placeholder: 'eg: 90210',
                    help: this._t('postal.help', "Enter the zip code for your shipping address"),
                    classes: ['v3', 'light']
                },
                {
                    type: 'select',
                    name: 'country',
                    title: this._t('country.title', 'Country'),
                    choices: Object.keys(countries).map(code => ({text: countries[code], value: code})),
                    options: {
                        defaultValue: this._t('country.defaultValue', "Select a Country"),
                    },
                    addlClasses: 'v3 light',
                    help: this._t('country.help', "Enter the country for your shipping address"),
                },
                {
                    type: 'text',
                    name: 'email',
                    title: this._t('email.title', 'Email Address'),
                    placeholder: 'eg: burak@tid.al',
                    help: this._t('email.help', "Your email address"),
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'phone',
                    title: this._t('phone.title', 'Phone Number'),
                    placeholder: 'eg: 917-555-5555',
                    help: this._t('phone.help', "Your phone number"),
                    classes: ['v3', 'light']
                },
                {
                    type: 'textarea',
                    name: 'notes',
                    title: this._t('notes.title', 'Additional Notes'),
                    placeholder: this._t('notes.placeholder', 'eg: "Use side door" or "Leave with doorman"'),
                    help: this._t('notes.help', 'Optional. Use this area to specify any additional information regarding your shipping address.'),
                    classes: ['v3', 'light']
                },
                this.state.error ? {
                    type: 'generic',
                    content: <Alert
                        classes={['danger']}
                        content={this.state.error}
                        onClose={() => this.setState({error: null})}
                    />

                } : null,
                {
                    type: 'button',
                    name: 'submit',
                    title: this._t('submit.title', 'Save Shipping Address'),
                    options: {
                        classes: ['v3 btn btn-primary medium bold', (isSubmitting ? 'disabled' : '')],
                        hideTitle: true,
                        onClick: this.handleSubmit.bind(this),
                    }
                },
                (didSubmit || isSubmitting) ? {
                    type: 'generic',
                    content: <Alert
                        classes={['info']}
                        content={isSubmitting ? this._t('alert.saving', 'Saving address...') : this._t('alert.saved', 'Address Saved!')}
                        style={{marginBottom: 0}}
                    />
                } : null,
            ]}
        />

    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        createInteraction: (
            props.createInteraction
            ||
            ((id, type, context) => dispatch(createActivationInteraction(id, type, context)))
        ),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
        translate: (key, defaultValue) => dispatch(translate(key, defaultValue))
    };
};

export default connect(null, mapDispatchToProps)(ShippingAddressForm);