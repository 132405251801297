/**
 * Created by bkroger on 6/22/18.
 */

import React, {Component} from 'react';

export default class ActionItem extends Component {

    render() {

        return(

            <div className="timeline-history-action-item">
                <div className="text-container">
                    <p className="v3 light">Campaign Name: Invitation has been Sent: $345</p>
                </div>

                <div className="btn-container">
                    <a href="" className="v3 btn btn-primary medium bold">Accept</a>
                    <a href="" className="v3 btn btn-secondary medium bold">Decline</a>
                </div>
            </div>

        )

    }

}