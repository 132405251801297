/**
 * Created by visgotti on 9/12/17.
 */

import React, {Component} from 'react';
import PostTableSort from './Sort';
import PostTableSettings from './Settings';
import { ConnectedPostRow } from '../../../containers/Post/ConnectedPostCard'
import {preventDefaultIfPossible} from "../../../utilities";

const DEFAULT_POST_TABLE_TABS = [
    'Featured', 'Title', 'Posted By', 'Kind', 'Rating', 'Engagements', 'Status', 'Action', 'Expanded'
];

export const POST_TABLE_TABS = [
    // {
    //     name: "Select",
    //     title: <i className="fa fa-check"></i>,
    //     style: {minWidth: '60px', maxWidth: '60px', width: '60px', textAlign: 'center'},
    //     alwaysShow: true
    // },

    {
        name: "Featured",
        title: <i className="fa fa-star-o"></i>,
        style: {minWidth: '60px', maxWidth: '60px', width: '60px', textAlign: 'center'},
    },
    {name: "Title", style: {minWidth: '380px', maxWidth: '380px'}, sortBy: 'title', sortDir: 'desc', alwaysShow: true},
    {name: "Posted By", sortBy: 'user.name', style: {minWidth: '230px', maxWidth: '230px'}, sortDir: 'desc'},
    {name: "Kind", sortBy: 'type', style: {minWidth: '140px', maxWidth: '140px'}, sortDir: 'desc'},
    {name: "Rating", sortBy: 'rating', style: {minWidth: '140px', maxWidth: '140px'}, sortDir: 'desc'},
    {name: "Engagements", sortBy: 'engagements', style: {minWidth: '140px', maxWidth: '140px'}, sortDir: 'desc'},
    {name: "Impressions", sortBy: 'views', style: {minWidth: '140px', maxWidth: '140px'}, sortDir: 'desc'},
    {name: "Status", style: {minWidth: '140px', maxWidth: '140px'}},
    {
        name: 'Action',
        title: 'Actions',
        style: {minWidth: '80px', maxWidth: '80px'},
    },
    {
        name: 'Eng. Pct.',
        style: {minWidth: '100px', maxWidth: '100px'},
    },
    {
        name: 'Reach',
        style: {minWidth: '100px', maxWidth: '100px'},
    },
    {
        name: 'Tier',
        style: {minWidth: '140px', maxWidth: '140px'},
    },
    {
        name: 'Avg. Eng. Pct.',
        style: {minWidth: '120px', maxWidth: '120px'},
    },
    {
        name: 'Location',
        style: {minWidth: '200px', maxWidth: '200px'},
    },
    {
        name: 'Expanded',
        title: <i className="fa fa-gear" style={{cursor: 'pointer'}} data-tooltip="Show/hide columns."></i>,
        style: {minWidth: '80px', maxWidth: '80px'},
        alwaysShow: true,
    },
];

const LOCALSTORAGE_COLS_KEY = 'posts-table-selectedColumns';
const hasLocalStorage = (() => {
    var test = 'test';
    try {
        window.localStorage.setItem(test, test);
        window.localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
})();

export default class PostTable extends Component {

    constructor(props) {
        super(props);

        this.handleChangeColumns = this.handleChangeColumns.bind(this);
        this.handleToggleSettings = this.handleToggleSettings.bind(this);

        this.state = {
            showSettings: false,
            selectedColumns: DEFAULT_POST_TABLE_TABS
        };
    }

    componentDidMount() {

        // Try to recover selectedColumns from localstorage
        if (hasLocalStorage) {
            const selectedColsJson = window.localStorage.getItem(LOCALSTORAGE_COLS_KEY);
            if (selectedColsJson) {
                try {
                    const selectedColumns = JSON.parse(selectedColsJson);
                    if (selectedColumns) {
                        this.setState({selectedColumns});
                    }
                } catch (e) {
                    // No-op
                }
            }
        }
    }

    handleToggleSettings(event) {
        preventDefaultIfPossible(event);
        this.setState({
            showSettings: !this.state.showSettings,
        });
    }
    handleChangeColumns(selectedColumns) {
        this.setState({selectedColumns});

        if (hasLocalStorage) {
            window.localStorage.setItem(LOCALSTORAGE_COLS_KEY, JSON.stringify(selectedColumns));
        }
    }

    renderTable() {
        return(
            <div className={"posts-table"}>
                <PostTableSort
                    onSortChange={this.props.changeSort}
                    onToggleSettings={this.handleToggleSettings}
                    sort={this.props.sort}
                    selectedColumns={this.state.selectedColumns}
                />

                {
                    this.state.showSettings &&
                    <PostTableSettings
                        selectedColumns={this.state.selectedColumns}
                        onChange={this.handleChangeColumns}
                        onClose={this.handleToggleSettings}
                    />
                }

                {
                    this.props.postIds.map(postId => {
                        return (
                            <ConnectedPostRow
                                postId={postId}
                                queryId={this.props.queryId}
                                currentView={this.props.currentView}
                                sort={this.props.sort}
                                isFetching={this.props.isFetching}
                                selectedColumns={this.state.selectedColumns}
                            />
                        )
                    })
                }

            </div>
        )
    }

    render() {
        return this.renderTable()
    }
}


