import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ContributorToDoRow extends Component {

    render() {

        const {title, desc, icon} = this.props;

        return (
            <div className="todo-row">

                <div className="todo-row-icon-wrapper">
                    {icon}
                </div>

                <div className="todo-row-text">
                    <div className="todo-text-top">
                        {title}
                    </div>
                    <div className="todo-text-bottom">
                        {desc}
                    </div>
                </div>

            </div>
        )
    }
}

export default ContributorToDoRow;

ContributorToDoRow.propTypes = {
    title: PropTypes.node,
    desc: PropTypes.node,
    icon: PropTypes.node
};