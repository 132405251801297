import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createUserInteraction} from "../../actions";
import {getCurrentUser} from "../../selectors/presence";
import Select from '../Common/Form/Select';
import Button from '../Common/Form/Button';
import Toolbox from "../Common/Toolbox";
import * as URI from 'urijs';
import TableWrapper from '../Table/Wrapper';
import {makeCellKey, fetchAuthenticated, makeMomentFromDate, c3ColorPattern} from "../../utilities";
import LinkCell from "../Table/Cell/Link";
import NumberCell from "../Table/Cell/Number";
import DateCell from "../Table/Cell/Date";
import _get from 'lodash/get';
import _find from 'lodash/find';
import BasicCell from "../Table/Cell/Basic";
import MiniProfile from "../Common/MiniProfile";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    LineChart, Line, AreaChart, Area,
    ResponsiveContainer,
} from 'recharts';
import Form from "../Common/Form/Form";

class InstagramBusinessHashtagSearch extends Component {

    static propTypes = {
        user: PropTypes.object,
        createInteraction: PropTypes.func,
    };

    state = {
        showSelectSocial: false,
        showSearch: false,
        hashtag: null,

        user: null,
        isFetching: false,
        didFetch: false,

        authLinks: null,
        isFetchingAuthLinks: false,
        didFetchAuthLinks: false,

        hasError: false,
        error: null,

        isAttachingSocial: false,
        didAttachSocial: false,
        socialId: null,

        posts: null,
        isFetchingPosts: false,
        didFetchPosts: false,

    };

    componentDidMount() {

        if (this.props.user && this.props.user.id) {
            this.refreshUser();
            this.fetchAuthLinks();
        }

        window.addEventListener('message', this.handleAuthWindowMessage.bind(this));
    }

    handleStartSearch() {
        this.setState({
            showSearch: true,
            showSelectSocial: false,
        });
        this.fetchSearchResults();
    }

    fetchSearchResults() {
        const url = `/${window.Gator.getDashboardSlot()}/api/social/${this.state.socialId}/hashtag_search/${this.state.hashtag}`;
        this.setState({
            isFetchingPosts: true,
        });
        return fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => {
                this.setState({
                    posts: json.data,
                    isFetchingPosts: false,
                    didFetchPosts: true
                });
            });

    }

    refreshUser() {
        this.setState({isFetching: true, didFetch: false});

        this.props.createInteraction(this.props.user.id, 'PersonalProfileRequest', {})
            .then((resp) =>
                this.setState({
                    user: (resp.data || {}).results || {},
                    isFetching: false,
                    didFetch: true
                })
            );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.socialId !== this.state.socialId) {
        }

        if (this.props.user && this.props.user.id && (!prevProps.user || !prevProps.user.id)) {
            this.refreshUser();
            this.fetchAuthLinks();
        }
    }

    fetchAuthLinks() {
        this.setState({isFetchingAuthLinks: true});

        this.props.createInteraction(this.props.user.id, 'SocialAuthRequest')
            .then((resp) => {
                if (resp.meta.error) {
                    this.setState({isFetchingAuthLinks: false, hasError: true, error: resp.meta.error})
                } else {
                    this.setState({
                        authLinks: resp.data.results, isFetchingAuthLinks: false, didFetchAuthLinks: true
                    })
                }
            });

    }

    handleAuthWindowMessage(msg) {
        const {data} = msg;
        const type = data.type;

        if (this.authWindow) {
            this.authWindow.close();
        }

        if (data.from !== 'AttachSocial') {
            return;
        }

        this.setState({isAttachingSocial: true});

        this.props.createInteraction(this.props.user.id, 'AttachSocial', {'token': data.t, 'type': type})
            .then((resp) => {
                if (resp.meta.error) {
                    this.setState({isAttachingSocial: false, hasError: true, error: resp.meta.error})
                } else {
                    this.setState({isAttachingSocial: false, didAttachSocial: true})
                }
            })
            .then(() => this.refreshUser());

    }

    renderSelectSocialToolbox() {

        if (!this.state.showSelectSocial) {
            return null;
        }

        return (
            <Toolbox
                style={{
                    position: 'absolute',
                    minHeight: 400,
                    left: '50%',
                    width: 400,
                    marginLeft: -200,
                }}
                onClose={() => this.setState({showSelectSocial: false})}
                title={"Select an Instagram Business Account"}
                content={this.renderSelectSocialScreen()}
            />
        );

    }

    renderSelectSocialScreen() {

        const socials = ((this.state.user || {}).socials || [])
            .filter(social => social.type === "Tidal\\Social\\InstagramBusiness");

        const authUrl = (this.state.authLinks || {})['instagram-business'] || null;
        return (
            <div
                style={{padding: 20, paddingBottom: 50}}
            >

                <Form
                    usePadding={false}
                    values={{
                        socialId: this.state.socialId,
                        hashtag: this.state.hashtag
                    }}
                    onFieldChange={(key, value) => this.setState({[key]: value})}
                    fields={[
                        {
                            type: 'select',
                            name: 'socialId',
                            title: 'Instagram Account',
                            choices: [...socials.map(social => ({text: social.name, value: social.id}))],
                            help: authUrl && <span>Or <a role="button" onClick={() => this.launchAuthWindow(authUrl)}>Connect to Instagram</a>.</span>
                        },
                        {
                            type: 'text',
                            name: 'hashtag',
                            title: 'Hashtag',
                            placeholder: 'eg: fashion'
                        },
                        {
                            type: 'button',
                            title: 'Search Now',
                            options: {
                                hideTitle: true,
                                onClick: () => {
                                    this.handleStartSearch();
                                },
                                classes: ['v3', 'btn-primary']
                            }
                        }
                    ]}
                />
            </div>
        )
    }

    launchAuthWindow(authLink) {

        let url = new URI(authLink);

        if (this.authWindow) {
            this.authWindow.close();
        }

        this.authWindow = window.open(url.toString(), '_blank', 'height=600,width=800');

    }

    renderSearchResults() {
        return (
            <Toolbox
                title={'Search Results'}
                onClose={() => this.setState({showSearch: false})}
                content={'search results.'}
                style={{
                    width: 1000,
                    left: '50%',
                    marginLeft: -500,
                    overflow: 'auto',
                    height: 'calc(100% - 40px)',
                    top: 20,
                    position: 'fixed',
                }}

            />
        );
    }

    render() {
        return (
            <div className="homepage striped">

                {this.renderSelectSocialToolbox()}
                {this.renderSearchResults()}

                <div className="container">
                    <div className="row">


                        <div className="col-xs-12 col-md-4">
                            <p className="homepage-section-title thin">
                                Instagram Hashtag Search
                            </p>
                        </div>

                        <div className="col-xs-12 col-md-8">
                            <div className="right-wrap">
                                <div className="infobox">
                                    <p className="lead"><a role="button" onClick={() => this.setState({showSelectSocial: true, showTable: false})}>Search Instagram for Hashtags</a> to discover competitors and new influencers.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        user: getCurrentUser(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (id, type, context) => dispatch(createUserInteraction(id, type, context)),
    };
};

const ConnectedInstagramBusinessHashtagSearch = connect(mapStateToProps, mapDispatchToProps)(InstagramBusinessHashtagSearch);

export default ConnectedInstagramBusinessHashtagSearch;
