/**
 * Created by bkroger on 7/16/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ShippingAddressForm from '../../../Activation/ShippingAddressForm';
import Alert from "../../../Common/Alert";

class ShippingAddress extends Component {

    constructor(props) {
        super(props);
        this.handleSaved = this.handleSaved.bind(this);
    }

    async handleSaved() {

        if (this.props.refreshActivation) {
            await this.props.refreshActivation();
        }

    }

    _t(k, v) {
        if (this.props.translate) {
            return this.props.translate(k, v);
        }
        return v;
    }

    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }

    render() {

        if (this.isBlockedByNDA()) {
            return this.renderNDAAlert();
        }

        return(
            <div className="influencer-page-template shipping_address">
                <div className="widget">

                    <div className="content padded">
                        <h4 className="v3 bold action-pane-title">{this._t('campaign.address.pane.title', 'Update Shipping Address')}</h4>
                        <p className="v3 light h7 pane-info">{this._t('campaign.address.pane.info', 'This information is required to receive product shipments for any projects you do with brands. All fields required unless otherwise indicated.')} </p>

                        <ShippingAddressForm
                            activation={this.props.activation}
                            onSaved={this.handleSaved.bind(this)}
                            style={{marginTop: 20}}
                            usePadding={false}
                            createInteraction={this.props.createInteraction}
                            translate={this.props.translate}
                        />

                    </div>
                </div>
            </div>
        );

    }

}

ShippingAddress.propTypes = {
    campaign: PropTypes.object.isRequired,
    activation: PropTypes.object.isRequired,
    refreshActivation: PropTypes.func.isRequired,
    createInteraction: PropTypes.func.isRequired,
    translate: PropTypes.func,
};

export default ShippingAddress;