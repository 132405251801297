import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Importer from '../Importer/Importer';
import {validateUrl, slugify} from '../../utilities';
import Alert from "../Common/Alert";

class Import extends Component {

    static propTypes = {
        onImport: PropTypes.func.isRequired,
        isImporting: PropTypes.bool,
        catalogId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    };
    
    constructor(props) {
        super(props);
        this.handleImport = this.handleImport.bind(this);
    }

    handleImport(data) {
        let processed = [];

        for (const row of data) {
            row['monetary_locale'] = 'en-US';
            row['product_catalog_id'] = this.props.catalogId;
            if (row.sku) {
                row.sku = slugify(row.sku);
                row['source_id'] = row.sku;
            }
            if (row.gender) {
                row.gender = row.gender.toLowerCase();
            }
            processed.push(row);
        }

        if (this.props.onImport) {
            this.props.onImport(processed);
        }
    }

    render() {
        return (
            <Importer
                banner={this.props.isImporting && <Alert classes={['info']} content={"Importing items..."} />}
                onImport={this.handleImport.bind(this)}
                columns={[
                    {
                        key: 'name',
                        title: 'Name',
                        required: true,
                        notes: 'This field is required.'
                    },
                    {
                        key: 'sku',
                        title: 'SKU',
                        required: true,
                        notes: 'This field is required and must be unique.'
                    },
                    {
                        key: 'description',
                        title: 'Product Description',
                        required: false,
                    },
                    {
                        key: 'brand_name',
                        title: 'Brand',
                        required: false,
                    },
                    {
                        key: 'monetary_value',
                        title: 'Value',
                        required: false,
                        notes: 'Numeric item price without dollar signs'
                    },
                    {
                        key: 'product_url',
                        title: 'Product URL',
                        required: false,
                        notes: 'Optional: must be a valid URL e.g. "http://www...".'
                    },
                    {
                        key: 'size',
                        title: 'Size',
                        required: false,
                    },
                    {
                        key: 'color',
                        title: 'Color',
                        required: false,
                    },
                    {
                        key: 'gender',
                        title: 'Gender',
                        required: false,
                        notes: 'Optional: one of these values: "male", "female", "unisex".'
                    },
                    {
                        key: 'category',
                        title: 'Category',
                        required: false,
                    },
                    {
                        key: 'subcategory',
                        title: 'SubCategory',
                        required: false,
                    },
                    {
                        key: 'image_url',
                        title: 'Image URL',
                        required: false,
                        notes: 'Optional: must be a valid URL e.g. "http://www...".'
                    }
                ]}
            />

        );
    }
}

export default Import;