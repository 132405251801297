import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from "../Common/Toolbox";
import DropdownButton from "../Common/Form/DropdownButton";

const ACTIONS = [
    {
        title: "Invite",
        key: 'Invite',
        isValid: (status) => !status.was_invited,
    },
    {
        title: "Invite Reminder",
        key: 'InviteReminder',
        isValid: (status) => status.is_invitation_pending
            && !status.is_waitlisted
            && !status.is_canceled
            && !status.invitation_reminder_sent_at,
    },
    {
        title: "Post Reminder",
        key: 'PostDeadlineReminder',
        isValid: (status, activation) => status.is_active
            && !activation.metadata.post_reminder_sent_at
            && !!activation.settings.use_deadlines
            && !!activation.settings.post_deadline_at,
    },
    {
        title: 'Accept',
        key: 'AcceptAfterWaitlist',
        isValid: (status) => !!status.is_waitlisted && !status.is_canceled,
    },
    {
        title: "Waitlist",
        key: 'Waitlist',
        isValid: (status) => !status.is_fulfilled && !status.is_waitlisted,
    },
    {
        title: "Cancel",
        key: 'Cancel',
        isValid: (status) => !status.is_fulfilled && !status.is_canceled,
    },
];

class ActivationInvitationToolsButton extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onClickAction: PropTypes.func,
    };

    getAvailableActions() {
        const status = this.props.activation.status || {};
        return ACTIONS.filter(action => action.isValid(status, this.props.activation));
    }

    renderDropdownOptions() {
        const actions = this.getAvailableActions();

        if (actions.length === 0) {
            return <div><div className="fake-li condensed"><a role="button"><em>No Actions Available</em></a></div></div>
        }

        return (<div>
            {actions.map(action => <div className="fake-li condensed"><a role="button" onClick={this.handleClickAction.bind(this, action)}>{action.title}</a></div>)}
        </div>);
    }

    renderDropdown() {
        return <Toolbox
            style={{minHeight: 0}}
            content={this.renderDropdownOptions()}
        />
    }

    handleClickAction(action) {
        if (!action) return;
        if (this.props.onClickAction) {
            this.props.onClickAction(action.key);
        }

    }

    render() {
        const actions = this.getAvailableActions();
        const primaryAction = actions.length > 0 ? actions[0] : null;

        if (actions.length === 0) {
            return null;
        }

        return <DropdownButton
            content={primaryAction ? primaryAction.title : <em>N/A</em>}
            onClickPrimary={this.handleClickAction.bind(this, primaryAction)}
            classes={['btn-secondary', 'v3', 'small', (actions.length === 0 ? 'disabled' : '')]}
            dropdown={this.renderDropdown()}
        />
    }
}

export default ActivationInvitationToolsButton;