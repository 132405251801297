import React, {Component} from 'react';
import PropTypes from  'prop-types';
import * as QuerySelectors from "../../../selectors/queries";
import {DEFAULT_EMAILS_QUERY} from "../../../reducers/emails";
import * as ActionTypes from "../../../actions/types";
import * as Query from "../../../actions/queries";
import {createEmailInteraction, fetchEmail} from "../../../actions/emailById";
import connect from "react-redux/es/connect/connect";
import LiteTableWrapper from "../../Influencer/Dashboard/Email/LiteTableWrapper";
import moment from 'moment';
import Button from "../../Common/Form/Button";
import SendMessageForm from "../../Influencer/Dashboard/Partials/SendMessageForm";
import Toolbox from "../../Common/Toolbox";
import EmailRead from "../../Email/Read";
import * as CampaignSelectors from "../../../selectors/campaigns";
import {fetchCampaignsIfNeeded} from "../../../actions/campaigns";
import Alert from "../../Common/Alert";

class ContributeDashEmails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showSort: false,
            showSearch: false,
            showSendMessagePopup: false,
            showEmailReadView: false,
            email: null,
        }
    }

    componentDidMount() {
        this.handleRefresh();
        this.props.fetchCampaigns();
    }

    handleRefresh() {
        return this.props.onRefresh(this.props.query);
    }

    getSignal(spec) {
        if (spec.is_read === false) {
            return <i className="v3 signal success"></i>;
        }
        return <i className="v3 signal neutral" key={`email-id-${spec.id}`}></i>;
    }

    getEmailContent() {
        const {emails} = this.props;
        return emails.map(email => {
            const statusClass = email.is_read ? '' : 'unread';

            return {
                signal: this.getSignal(email),
                subject: <a className={`subject ${statusClass}`} onClick={() => this.setState({showEmailReadView: !this.state.showEmailReadView, email: email})}>{email.subject}</a>,
                date: <span className={`date ${statusClass}`}>{moment(email.created_at.date).format('MMMM Do, YYYY')}</span>,
                sender: <span className={`sender ${statusClass}`}>{email.sender.name}</span>,
            }
        })
    }

    getHeaderSwitches() {
        return [
            {
                name: 'sort',
                icon: <i className="v3 icon sort" />,
                tooltip: "Sort options",
                isActive: this.state.showSort,
                onClick: () => this.setState({showSort: !this.state.showSort}),
            },
            {
                name: 'search',
                icon: <i className="v3 icon search" />,
                tooltip: "Search options",
                isActive: this.state.showSearch,
                onClick: () => this.setState({showSearch: !this.state.showSearch}),
            }
        ];
    }

    getRowSwitches() {
        return [
            {
                name: 'reply',
                icon: <i className="v3 icon reply" />,
                tooltip: "Reply to this email",
            },
            {
                name: 'archive',
                icon: <i className="v3 icon inbox" />,
                tooltip: "Archive this email",
            },
            {
                name: 'label',
                icon: <i className="v3 icon label" />,
                tooltip: "Label this email",
            },
            // {
            //     name: 'delete',
            //     icon: <i className="v3 icon trash" />,
            //     tooltip: "Delete this email",
            // }
        ];
    }

    getButtons() {
        return [
            <Button
                key={'newemailbutton'}
                content={'New Email'}
                classes={['v3', 'btn-primary', (this.state.showSendMessagePopup ? 'active' : '')]}
                onClick={() => this.setState({showSendMessagePopup: !this.state.showSendMessagePopup})}
            />

        ];
    }

    getLoadingAlert() {
        return {
            class: 'info',
            text: 'Loading Emails...',
        }
    }

    renderEmailReadPopup() {
        if (!this.state.showEmailReadView) return null;

        return <Toolbox
            title={""}
            onClose={() => this.setState({showEmailReadView: false})}
            content={
                <EmailRead
                    email={this.state.email}
                    fetchEmail={this.props.fetchEmail}
                    campaignsById={this.props.campaignsById}
                />
            }
            pushout={false}
            supportMobile={true}
            addlClasses={"contribute-email-read-view"}
        />
    }

    renderSortPopup() {
        if (!this.state.showSort) return null;

        return <Toolbox
            title={"Sort Emails"}
            onClose={() => this.setState({showSort: false})}
            content={'[sort options go here]'}
            pushout={true}
            supportMobile={true}
            style={{
                top: 60,
                right: 0,
                width: 330,
            }}
        />;
    }

    renderSearchPopup() {
        if (!this.state.showSearch) return null;

        return <Toolbox
            title={"Search Emails"}
            onClose={() => this.setState({showSearch: false})}
            content={'[search options go here]'}
            pushout={true}
            supportMobile={true}
            style={{
                top: 60,
                right: 0,
                width: 330,
            }}
        />;
    }

    renderSendMessagePopup() {
        if (!this.state.showSendMessagePopup) return null;

        return <Toolbox
            title={"Send a Message"}
            content={
                <SendMessageForm
                    userId={601}
                />
            }
            onClose={() => this.setState({showSendMessagePopup: false})}
            pushout={false}
            supportMobile={true}
            style={{
                top: 60,
                right: 0,
                width: 500,
            }}
        />;
    }

    renderPopups() {
        return [
            this.renderSortPopup(),
            this.renderSearchPopup(),
            this.renderSendMessagePopup(),
            this.renderEmailReadPopup()
        ];
    }

    render() {

        const {query} = this.props;
        const alert = <Alert classes={['info']} content={<span>Loading emails...</span>} style={{marginTop: 10}}/>;

        return (
            <>
            {query.isFetching ? alert : null}
            {!query.isFetching &&
                <LiteTableWrapper
                    headerSwitches={this.getHeaderSwitches()}
                    rowSwitches={this.getRowSwitches()}
                    buttons={this.getButtons()}
                    popups={this.renderPopups()}
                    items={this.getEmailContent()}
                    addlClasses={['contribute-email-table-wrapper']}
                />
            }
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    const {campaignsById} = state;
    return {
        emails: QuerySelectors.getPageItemsWithFallback(state.emails, props, DEFAULT_EMAILS_QUERY),
        query: QuerySelectors.getQuery(state.emails, props, DEFAULT_EMAILS_QUERY),
        campaignsById
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const slot = window.Gator.getDashboardSlot();
    const url = `/${slot}/api/user/${props.user.id}/email`;
    return {
        onRefresh: (query) => dispatch(Query.fetchQuery(url, query, ActionTypes.EMAILS_REQUEST_QUERY, ActionTypes.EMAILS_RECEIVE_QUERY)),
        onSortChange: (sort, query) => dispatch(Query.updateQuerySort(sort, query, ActionTypes.EMAILS_UPDATE_QUERY_SORT)),
        onPageChange: (page, query) => dispatch(Query.updateQueryPage(page, query, ActionTypes.EMAILS_UPDATE_QUERY_PAGE)),
        onFilterChange: (filters, query) => dispatch(Query.updateQueryFilters(filters, query, ActionTypes.EMAILS_UPDATE_QUERY_FILTERS)),
        createInteraction: (id, type, ctx) => dispatch(createEmailInteraction(id, type, ctx)),
        fetchEmail: (id) => dispatch(fetchEmail(id)),
        fetchCampaigns: () => dispatch(fetchCampaignsIfNeeded()),

    };
};

const EmailPaneContributeDash = connect(mapStateToProps, mapDispatchToProps)(ContributeDashEmails);

EmailPaneContributeDash.propTypes = {
    user: PropTypes.object.isRequired,
};

export default EmailPaneContributeDash;

