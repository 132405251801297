import React, {Component} from 'react';
import {fetchAuthenticated, makeFormData} from "../../utilities";

export default class HBInfluencerWelcome extends Component {


    state = {
        pane: 0,
        isHidden: false,
    };

    renderHow() {

        return (
            <div>

                <h2 className="v3 bold bottommargin">How it Works</h2>

                <p className="v3 h6 light"><strong>STEP 1:</strong> You’ve already done it! The first step is creating a H&B Social Circle Account</p>



                <p className="v3 h6 light"><strong>STEP 2:</strong> Following this walk-through, you’ll be asked to <a href="/contribute/do/page/user/profile">link your own Facebook, Twitter and Instagram</a> to the platform. This enables us to track your interactions with H&B Social Media content.</p>



                <p className="v3 h6 light"><strong>STEP 3:</strong> Start interacting with the posts you see on H&B Business pages – and log in to H&B Social Circle to watch your score increase. The following interactions affect your score:</p>

                <ul className="v3 light">
                    <li>Like, Love, Comment on, or Share <a target="_blank" href="https://facebook.com/hollandandbarrett">H&B Facebook Posts</a>.</li>
                    <li>Retweet or Favourite H&B <a target="_blank" href="https://twitter.com/holland_barrett">tweets on Twitter</a>.</li>
                    <li>Comment on <a target="_blank" href="https://instagram.com/hollandandbarrett">H&B Instagram posts</a>.</li>

                </ul>
            </div>
        )
    }

    renderWhat() {
        return (
            <div>

                <h2 className="v3 bold bottommargin">H&B Social Circle: What is it?</h2>


                <p className="v3 h6 light">Born from your enthusiasm towards Holland & Barrett Brand Campaigns on Social Media, we developed H&B Social Circle, a platform to gamify your experience with our H&B Social Media channels.</p>



                <p className="v3 h6 light">The more you interact with our page content, the higher you climb in your Area Monthly League Table.</p>



                <p className="v3 h6 light">Gold & Silver rewards will be awarded to the top two colleagues with the highest scores per area – every month.</p>



                <p className="v3 h6 light">Rewards to the value of £240+ will be awarded to top colleague with the highest score per area – every quarter.</p>



                <p className="v3 h6 light">The person who achieves the highest score across the business as a whole at the end of this financial year will be rewarded with £2000 Holiday Vouchers.</p>



            </div>
        )
    }


    renderIncentives() {
        return (
            <div>
                <h2 className="v3 bold bottommargin">Social Circle Prizes</h2>
                <img src="https://cdn.tid.al/hollandandbarrett/hb-incentives-feb-2019.png" />
            </div>
        )
    }

    renderHiddenMessage() {
        return (
            <div>Thank you for participating in the Social Circle! We won't show you this welcome message again.</div>
        )
    }

    renderCurrentPane() {
        if (this.state.isHidden) {
            return this.renderHiddenMessage();
        }
        switch (this.state.pane) {
            case 0:
                return this.renderWhat();
            case 1:
                return this.renderHow();
            case 2:
                return this.renderIncentives();
        }
    }

    handleClose() {

        this.setState({isHidden: true});
        fetchAuthenticated(`/contribute/api/user/_/profile`, {
            method: 'post',
            body: makeFormData({
                key: 'hb_did_welcome',
                value: 1
            })
        });

    }

    renderPagination() {
        const {pane, isHidden} = this.state;
        if (isHidden) {
            return;
        }
        return (
            <div
                style={{
                    position: 'absolute',
                    right: 20,
                    top: 25,
                }}
            >
                <a
                    style={{
                        float: 'none',
                        verticalAlign: 'top',
                    }}
                    onClick={this.handleClose.bind(this)}
                    data-tooltip="Hide this forever."
                    role="button"
                    className="v3 close primary bg-primary-dark"></a>

                {
                    (pane > 0) ? <a
                        role="button"
                        style={{marginLeft: 10}}
                        onClick={() => this.setState({pane: pane-1})}
                        className="prev v3 caret bg-accent small round"></a> : null
                }
                {
                    (pane < 2) ?
                    <a
                        style={{marginLeft: 10}}
                        onClick={() => this.setState({pane: pane+1})}
                        role="button"
                        className="next v3 caret bg-accent small round"></a>
                        : null
                }

            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderPagination()}
                {this.renderCurrentPane()}
            </div>
        );
    }
}