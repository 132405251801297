import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {fetchAuthenticated} from "../../../actions/auth";
import {connect} from 'react-redux';

class ImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            isUploading: false,
            didUpload: false,
            isFetching: false,
            isDraggingOver: false
        };
        this.handleImageUpload = this.handleImageUpload.bind(this);
        this.deleteImageUpload = this.deleteImageUpload.bind(this);
    }

    static defaultProps = {
        classes: [],
        style: {}
    };

    static propTypes = {
        classes: PropTypes.array,
        name: PropTypes.string,
        id: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        style: PropTypes.object,
        fetchAuthenticated: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.getImage();
    }

    componentDidUpdate(prevProps){
        if (this.props.value && !this.state.image) {
            this.getImage();
        }

        if (prevProps.value !== this.props.value) {
            this.getImage();
        }
    }

    getImage() {

        if (this.state.isFetching) {
            return;
        }

        //this.props.value can either be an id or a URL.  the if blocks handle each potential situation
        const value = this.props.value || null;
        const url = value && typeof(value) === 'string' ? value.match(/[:/.]/g) : null; //return truthy array if value contains url characters

        if (value && !url) {
            //if value is an id
            const slot = window.Gator.getDashboardSlot();
            const api = `/${slot}/api/image/${value}`;
            const options = { method: 'GET' };

            this.setState({isFetching: true});
            return this.props.fetchAuthenticated(api, options)
                .then(res => res.json())
                .then(json => {
                    if (json.data) {
                        this.setState({
                            image: json.data,
                            didUpload: true,
                            isFetching: false,
                        });
                    }
                });

        } else if (url) {
            //if value is a url
            this.setState({
                image: {
                    original_url: value
                },
                didUpload: true
            });
        }
    }

    handleImageUpload(event) {
        const file = event.target.files[0];
        event.target.value = "";
        this.doImageUpload(file);
    }

    doImageUpload(file) {
        const formData = new FormData();
        formData.append('image', file);

        const slot = window.Gator.getDashboardSlot();
        const api = `/${slot}/api/image`;
        const options = {
            method: 'POST',
            body: formData,
            enctype: 'multipart/form-data'
        };

        this.setState({
            isUploading: true
        });

        return this.props.fetchAuthenticated(api, options)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isUploading: false,
                    didUpload: true,
                    image: json.data,
                });
                return json.data;
            })
            .then(image => {
                if(this.props.onChange) {
                    this.props.onChange(image.id, image);
                }
            });
    }

    deleteImageUpload() {
        const removeId = this.state.image.id;
        this.setState({ image: null, didUpload: false });
        this.props.onChange(null, null, removeId);
    }

    renderPreview(){
        if (this.state.image) {
            return (
                <div className='react-image-uploader-preview-wrapper'>
                    <img className='react-image-uploader-preview-img' src={this.state.image.original_url}/>
                    <a role='button'
                       className='v3 close primary bg-white'
                       onClick={this.deleteImageUpload}
                       style={{display: 'block', lineHeight: 1.2}}
                    />
                </div>
            )
        }
    }

    handleDragOver(event) {
        this.setState({isDraggingOver: true});
        event.preventDefault();
    }
    handleDragLeave(event) {
        event.preventDefault();
        this.setState({isDraggingOver: false});
    }
    handleDrop(event) {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        this.setState({isDraggingOver: false});
        this.doImageUpload(file);
    }

    render() {
        return (
            <div
                className={'react-image-uploader ' + (this.state.isDraggingOver ? "is-hover" : "")}
                onDragOver={this.handleDragOver.bind(this)}
                onDragLeave={this.handleDragLeave.bind(this)}
                onDrop={this.handleDrop.bind(this)}
            >

                {this.state.isDraggingOver && <p>Drop your image here to upload.</p>}

                <input type='file'
                       className='react-image-file-input'
                       onChange={this.handleImageUpload}
                       style={{display: this.state.didUpload ? 'none' : 'block'}}
                       id={this.props.id ? this.props.id : null}
                />

                {this.state.isUploading && <div className='alert tidal-alert alert-grey'>Uploading Image...</div>}
                {this.state.image ? this.renderPreview() : null}

            </div>
        );
    }
}

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
    };
};

const ConnectedImageUpload = connect(mapStateToProps, mapDispatchToProps)(ImageUpload);

export default ConnectedImageUpload;
