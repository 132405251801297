import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from '../Common/Toolbox';
import {fetchAuthenticated, limitChars} from "../../utilities";
import UserAttributesTable from '../User/Pieces/UserAttributesTable';
import UserSocialTable from '../User/Pieces/UserSocialTable';
import UserBlogWidget from '../User/Pieces/BlogWidget';

export default class RepImagesProfilePreview extends Component {

    static propTypes = {
        posts: PropTypes.array,
        onClose: PropTypes.func,
    };

    state = {
        isFetchingUser: false,
        didFetchUser: false,
        user: null,
    };

    fetchUser() {

        this.setState({isFetchingUser: true});
        return fetchAuthenticated('/contribute/api/user/me')
            .then(resp => resp.json())
            .then(json => this.setState({isFetchingUser: false, didFetchUser: true, user: json.data}));

    }

    makeUserObject() {
        const user = {
            basics: {...this.state.user},
            profile: {...this.state.user},
        };
        return user;
    }


    componentDidMount() {
        this.fetchUser();
    }

    renderProfileText() {

        const user = this.state.user;

        let profileText = (<em>Profile text coming soon...</em>);

        if (user && user.text) {
            profileText = user.text;
            profileText = limitChars(profileText, 400);
        }

        return (

            <div className="user-detail-profile-text-wrapper">

                <div className="fake-li">
                    <strong>About Me</strong>
                </div>

                <div className="fake-li">
                    {limitChars(profileText, 310)}
                </div>

                {
                    user.human_location &&
                    <div className="fake-li bottom-border">
                        <strong>Location:</strong>
                        <span className="pull-right">{limitChars(user.human_location, 26)}</span>
                    </div>
                }

                {
                    user.tier &&
                    <div className="fake-li bottom-border">
                        <strong>Tier:</strong>
                        <span className="pull-right">{user.tier}</span>
                    </div>
                }

                {
                    user.price &&
                    <div className="fake-li bottom-border">
                        <strong>Sugg. Price:</strong>
                        <span className="pull-right">{user.price}</span>
                    </div>
                }

            </div>
        );
    }

    getSourceLabel(social) {
        return `${this.getNiceSocialType(social)}: ${social.name}`
    }

    getNiceSocialType(social) {
        switch (social.type) {
            case 'Tidal\\Social\\Facebook': return 'Facebook';
            case 'Tidal\\Social\\FacebookPage': return 'Facebook Page';
            case 'Tidal\\Social\\Instagram': return 'Instagram';
            case 'Tidal\\Social\\Twitter': return 'Twitter';
        }
    }

    renderPost(post) {

        const imageUrl = (post.image_urls || [])[0] || null;
        if (!imageUrl) {
            return null;
        }

        return (
            <div
                className={'profile-post'}
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: 300,
                    width: 300,
                    position: 'relative',
                    display: 'inline-block',
                    verticalAlign: 'top',
                    marginRight: 1
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        whiteSpace: 'normal',
                        fontSize: '14px',
                        lineHeight: '18px',
                        padding: '10px',
                        background: 'rgba(255,255,255,0.8)',
                        bottom: 0,
                        height: 80,
                        width: '100%'
                    }}
                >
                    <h4
                        style={{
                            fontSize: '14px',
                            lineHeight: '18px',
                        }}
                    >{limitChars(post.title, 75)}</h4>
                    <p>From {this.getSourceLabel(post.user)}</p>
                </div>
            </div>
        )

    }

    renderProfilePosts() {
        return (
            <div
                className={'profile-images-row-test'}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'auto',
                }}
            >
                {(this.props.posts || []).map(post => this.renderPost(post))}
            </div>

        );
    }

    renderContent() {
        if (this.state.isFetchingUser || !this.state.user) {
            return <div className={"alert tidal-alert alert-info"}>Loading profile...</div>
        }

        const {user} = this.state;

        return (

            <div className={'user-detail-body-profile'} style={{paddingTop: 0}}>

                {this.renderProfilePosts()}


                <div className="container-fluid">
                    <div className="user-detail-profile-attributes-row row">

                        <div className="col-xs-4">
                            {this.renderProfileText()}
                        </div>

                        <div className="col-xs-4">
                            <UserAttributesTable user={this.makeUserObject()} />
                        </div>

                        <div className="col-xs-4">
                            <UserSocialTable user={this.makeUserObject()} />
                        </div>

                    </div>
                </div>

                <UserBlogWidget user={this.makeUserObject()} />

            </div>

        );
    }

    render() {
        return (

            <Toolbox
                style={{
                    width: 1000,
                    left: '50%',
                    marginLeft: -500,
                    top: 70,
                }}
                onClose={this.props.onClose}
                title={this.state.isFetchingUser ? 'Preview Profile...' : 'Your Profile'}
                content={this.renderContent()}
            />

        );
    }
}