/**
 * Created by bkroger on 5/2/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import {connect} from 'react-redux';
import Alert from '../../../Common/Alert';
import Checkbox from '../../../Common/Checkbox';
import Text from '../../../Common/Form/Text';
import Button from '../../../Common/Form/Button';
import {createActivationInteraction} from "../../../../actions/activations";
import Twig from 'twig';
import {translate} from "../../../../actions/i18n";
import {makeMomentFromDate} from "../../../../utilities";
import moment from "moment";
import _get from "lodash/get";

const INFLUENCER_AGREEMENT_URL = 'https://tid.al/l/influenceragreement';
class ReviewOfferTemplate extends Component {

    static propTypes = {
        createInteraction: PropTypes.func,
        translate: PropTypes.func,
        twigContext: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            formData: {
                /**
                 * Not sure if we need to record this
                 * Signature will act as the user's consent
                  */

                age_verified: false,
                agreed: false,
                signature: '',
                contract: '',

            },
            buttonClicked: null,
            isSubmitting: false,
            didSubmit: false,
        };

        this.handleClickAccept = this.handleClickAccept.bind(this);
        this.handleClickDecline = this.handleClickDecline.bind(this);
    }

    componentDidMount() {
        DOMPurify.addHook('beforeSanitizeAttributes', function(node) {
            if (node.target && node.target === '_blank') {
                node['rel'] = 'noopener noreferrer';
            }
        });
    }

    toggleFormField(name) {

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: !this.state.formData[name]
            }
        });
    }

    handleFieldChange(event) {
        const {name, value} = event.target;
        let formData = this.state.formData;
        formData[name] = value;
        this.setState({'formData': formData})
    }

    handleClickAccept() {
        this.setState({isSubmitting: true, buttonClicked: 'accept'});
        if (typeof this.props.activation !== 'undefined' && typeof this.props.activation.id !== 'undefined') {
            const {activation} = this.props;

            const contractUrl = (activation.settings.use_custom_contract === true && typeof activation.settings.custom_contract_url !== 'undefined')
                ? activation.settings.custom_contract_url
                : INFLUENCER_AGREEMENT_URL;

            const context = {...this.state.formData, response: 'accept', contract: contractUrl};

            return this.props.createInteraction(this.props.activation.id, 'RespondToInvitation', context)
                .then(res => {
                    this.setState({
                        isSubmitting: false,
                        didSubmit: true})
                    })
                .then(this.props.refreshActivation)
                .then(this.props.refreshRequirements)
        }
    }

    handleClickDecline() {
        this.setState({isSubmitting: true, buttonClicked: 'decline'});
        if (typeof this.props.activation !== 'undefined' && typeof this.props.activation.id !== 'undefined') {

            const {activation} = this.props;
            const contractUrl = (activation.settings.use_custom_contract === true && typeof activation.settings.custom_contract_url !== 'undefined')
                ? activation.settings.custom_contract_url
                : INFLUENCER_AGREEMENT_URL;

            const context = {...this.state.formData, response: 'decline', contract: contractUrl};

            return this.props.createInteraction(this.props.activation.id, 'RespondToInvitation', context)
                .then(res => {
                    this.setState({
                        isSubmitting: false,
                        didSubmit: true})
                })
                .then(this.props.refreshActivation)
                .then(this.props.refreshRequirements)
        }
    }

    renderInvitationCopy() {
        const {activation} = this.props;

        const invitationCopy = (activation.settings || {}).invitation_copy || '';
        const template = Twig.twig({
            data: invitationCopy,
        });
        const rendered = template.render(this.props.twigContext);

        return {__html: DOMPurify.sanitize(rendered, { ADD_ATTR: ['target', 'rel'] })};
    }

    renderContentGuidelines() {
        const {activation} = this.props;

        const guidelinesCopy = (activation.settings || {}).content_guidelines || '';
        const template = Twig.twig({
            data: guidelinesCopy,
        });
        const rendered = template.render(this.props.twigContext);

        return {__html: DOMPurify.sanitize(rendered, { ADD_ATTR: ['target', 'rel'] })};
    }

    renderComplianceGuidelines() {
        const {activation} = this.props;
        const _t = this.props.translate || ((k, v) => v);

        const complianceGuidelines = (activation.settings || {}).other_post_guidelines || '';

        if (complianceGuidelines.length === 0) {
            return null;
        }

        const template = Twig.twig({
            data: complianceGuidelines,
        });

        const rendered = template.render(this.props.twigContext);

        const innerHTML = {__html: DOMPurify.sanitize(rendered, { ADD_ATTR: ['target', 'rel'] })};

        return (

            <div className="review-offer-content-guidelines-wrapper">
                <div className="divider-line" />
                <div className="review-offer-content-guidelines">
                    <h5 className="v3 bold action-pane-title">{_t('campaign.offer.compliance_guidelines', 'Compliance Guidelines')}</h5>
                    <div className="v3 h7 light pane-info" dangerouslySetInnerHTML={innerHTML} />
                </div>
            </div>
        );
    }

    renderProductHighlights() {
        const {activation} = this.props;
        const _t = this.props.translate || ((k, v) => v);

        const highlights = (activation.settings || {}).product_details_copy || '';

        // min length of 10 prevents empty <p></p>\n from being rendered
        if (highlights && highlights.length <= 10) {
            return null;
        }

        const template = Twig.twig({ data: highlights, });
        const rendered = template.render(this.props.twigContext);
        const innerHTML = {__html: DOMPurify.sanitize(rendered, { ADD_ATTR: ['target', 'rel'] })};

        return (

            <div className="review-offer-product-highlights-wrapper">
                <div className="divider-line" />
                <div className="review-offer-content-guidelines">
                    <h5 className="v3 bold action-pane-title">{_t('campaign.offer.product_highlights', 'Product Highlights')}</h5>
                    <div className="v3 h7 light pane-info" dangerouslySetInnerHTML={innerHTML} />
                </div>
            </div>
        );
    }

    renderResponseAlert() {
        const _t = this.props.translate || ((k, v) => v);
        const {activation} = this.props;
        const {status} = activation;

        if (status.is_canceled) {
            return <Alert
                classes={['danger']}
                content={_t('campaign.offer.alert_canceled', "This invitation has expired or been revoked.")}
            />
        }

        if (status.did_user_accept_invitation) {
            return <Alert
                classes={['success']}
                content={_t('campaign.offer.alert_accepted', "You've accepted the brief! Use the to-do list to start working on this campaign.")}
            />
        }

        if (status.did_user_decline_invitation) {
            return <Alert
                classes={['info']}
                content={_t('campaign.offer.alert_declined', "You've declined this brief.")}
            />

        }

        if (this.isCampaignEnded()) {
            return <Alert
                classes={['danger']}
                content={_t('campaign.offer.alert_campaign_ended', "This campaign has ended.")}
            />
        }

        if (this.isInvitationDeadlinePast()) {
            return <Alert
                classes={['danger']}
                content={_t('campaign.offer.alert_invitation_expired', "This invitation has expired.")}
            />
        }

        if (status.is_invitation_pending) {
            return null;
        }
    }

    getAcceptButtonContent(type, isSubmitting, didSubmit) {
        const _t = this.props.translate || ((k, v) => v);
        let text = _t('campaign.offer.btn.accept', 'Accept Brief');
        if (type === 'accept') {
            if (isSubmitting) text = _t('campaign.offer.btn.accepting', 'Accepting Brief...');
            if (didSubmit) text = _t('campaign.offer.btn.accepted', 'Brief Accepted!');
        }
        return text;
    }

    getDeclineButtonContent(type, isSubmitting, didSubmit) {
        const _t = this.props.translate || ((k, v) => v);
        let text = _t('campaign.offer.btn.decline', 'Decline Brief');
        if (type === 'decline') {
            if (isSubmitting) text = _t('campaign.offer.btn.declining', 'Declining Brief...');
            if (didSubmit) text = _t('campaign.offer.btn.declined', 'Brief Declined!');
        }
        return text;
    }

    isCampaignEnded() {
        const {campaign} = this.props;
        const endDate = makeMomentFromDate(campaign.end || null);
        if (!endDate) {
            return false;
        }
        const now = moment();
        return endDate.isBefore(now);
    }

    isInvitationDeadlinePast() {
        const {campaign} = this.props;
        const deadline = makeMomentFromDate(_get(campaign, 'settings.invitation_deadline_at', null));
        if (!deadline) {
            return false;
        }
        const now = moment();
        return deadline.isBefore(now);
    }


    renderResponseForm() {
        const _t = this.props.translate || ((k, v) => v);
        const {campaign, activation} = this.props;
        const {status} = activation;
        const { isSubmitting, didSubmit, buttonClicked } = this.state;
        const {agreed, age_verified, signature} = this.state.formData;

        // Couple of things to check before moving on.
        if (status.was_invited && !status.is_invitation_pending) {
            return this.renderResponseAlert();
        }

        if (status.did_user_accept_invitation || status.did_user_decline_invitation) {
            return this.renderResponseAlert();
        }

        if (status.is_canceled) {
            return this.renderResponseAlert();
        }

        if (this.isCampaignEnded() || this.isInvitationDeadlinePast()) {
            return this.renderResponseAlert();
        }

        const contractUrl = activation.settings.use_custom_contract === true && typeof activation.settings.custom_contract_url !== 'undefined' ? activation.settings.custom_contract_url : INFLUENCER_AGREEMENT_URL;


        const disableContract = (activation.settings || {}).disable_contract || false;

        let isFormValid = agreed && age_verified && signature && signature.length > 3;
        let titleText = _t('campaign.offer.response.title_accept_agreement', 'Accept the Contributor Agreement');
        let agreementBlock = (
            <p className="v3 h7 light help-block">{_t('campaign.offer.agreement_link_preamble', 'You must read and accept this')} <a href={contractUrl} target="_blank">{_t('campaign.offer.response.agreement_link_title', 'Creator Services Agreement')}</a> {_t('campaign.offer.agreement_link_after', 'in order to participate. Please type your name as an indication that you\'ve read and agree to the Creator Services Agreement.')} </p>
        );

        // Change some copy if the contract is disabled
        if (disableContract) {
            titleText = _t('campaign.offer.response.title_accept_invitation', 'Accept or Decline the Invitation');
            agreementBlock = (
                <p className="v3 h7 light help-block">{_t('campaign.offer.response.signature_text', 'Please type your name as an indication that you\'ve read and understood the information above.')}</p>
            );
            isFormValid = signature && signature.length > 3;
        }

        return (
            <form>

                <div className="form-group">
                    <label className="v3 h6 bold"><strong>{titleText}</strong></label>
                    {agreementBlock}

                    <Text
                        name="signature"
                        classes={['v3', 'form-control', 'light']}
                        placeholder="Your Name"
                        value={signature}
                        onChange={this.handleFieldChange.bind(this)}
                        style={{marginBottom: 15}}
                    />

                    {
                        (agreed && age_verified && !isFormValid)
                            ? <p className='help-block'><i className='v3 icon error'/> {_t('campaign.offer.alert_needs_signature', 'Please sign your full name to continue.')}</p>
                            : null
                    }

                    {

                        (disableContract === false) ?
                        <Checkbox
                            checked={age_verified}
                            label={_t('campaign.offer.18_label', "I am 18 years or older")}
                            onClick={this.toggleFormField.bind(this, 'age_verified')}
                            wrapperStyle={{fontSize: 14}}
                        /> : null
                    }

                    {
                        (disableContract === false) ?
                        <Checkbox
                            checked={agreed}
                            label={[_t('campaign.offer.terms_pre', "I Agree to the "),
                                <a onClick={e => e.stopPropagation()} href={contractUrl} target="_blank" className="review-offer-terms-link">{_t('campaign.offer.terms_link', 'Terms and Conditions')}</a>]}
                            onClick={this.toggleFormField.bind(this, 'agreed')}
                            wrapperStyle={{marginBottom: 15, display: 'block', fontSize: 14}}
                        /> : null
                    }

                    <Button
                        classes={['v3', 'btn', 'medium', 'bold', (isFormValid ? 'btn-primary' : 'btn-secondary disabled')]}
                        onClick={isFormValid ? this.handleClickAccept.bind(this) : undefined}
                        content={this.getAcceptButtonContent(buttonClicked, isSubmitting, didSubmit)}
                        style={{float: 'left'}}
                    />

                    <Button
                        classes={['v3', 'btn', 'btn-secondary', 'medium', 'bold']}
                        onClick={() => this.handleClickDecline()}
                        content={this.getDeclineButtonContent(buttonClicked, isSubmitting, didSubmit)}
                        style={{float: 'right'}}
                    />

                </div>

            </form>
        );

    }
    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }
    render() {

        if (this.isBlockedByNDA()) {
            return this.renderNDAAlert();
        }

        const {campaign, activation} = this.props;
        const _t = this.props.translate || ((k, v) => v);

        const contractUrl = activation.settings.use_custom_contract === true && typeof activation.settings.custom_contract_url !== 'undefined' ? activation.settings.custom_contract_url : INFLUENCER_AGREEMENT_URL;

        return(
            <div className="influencer-page-template review-offer">
                <div className="widget">
                    <div className="content padded">

                        <h4 className="v3 bold action-pane-title">{_t('campaign.offer.title', 'Review & Respond to Brief')}</h4>

                        <p className="v3 light h7 pane-info" style={{marginBottom: 20}}>{_t('campaign.offer.info', 'Please review the information below and let us know if you\'re willing and able to participate.')}</p>

                        <div className="review-offer-invitation-wrapper">
                            <div className="divider-line"></div>

                            <div className="template-var">
                                <h5 className="v3 bold action-pane-title">{_t('campaign.offer.invitation_to', 'Invitation to')} {campaign.name}</h5>
                                <div className="v3 h7 light pane-info" dangerouslySetInnerHTML={this.renderInvitationCopy()}></div>
                            </div>
                        </div>

                        <div className="review-offer-content-guidelines-wrapper">
                            <div className="divider-line"></div>

                            <div className="review-offer-content-guidelines">
                                <h5 className="v3 bold action-pane-title">{_t('campaign.offer.content_guidelines', 'Content Guidelines')}</h5>
                                <div className="v3 h7 light pane-info" dangerouslySetInnerHTML={this.renderContentGuidelines()}></div>
                            </div>
                        </div>

                        {this.renderComplianceGuidelines()}
                        {this.renderProductHighlights()}

                        <div className="divider-line"></div>

                        {this.renderResponseForm()}
                    </div>
                </div>
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        translate: (key, defaultValue) => dispatch(translate(key, defaultValue))
    };
};

export default connect(null, mapDispatchToProps)(ReviewOfferTemplate);
