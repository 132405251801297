/**
 * Created by bkanber on 8/7/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatNumber} from '../../../utilities';

export default class UserSocialTable extends Component {

    renderTable() {
        /**
         * x Interests
         * x Category
         * x Blog
         * x Blog Traffic
         * x Instagram
         * x Price
         * x Content Rating
         * x Total Reach
         */
        const {user} = this.props;
        const {profile, basics} = user;

        return (

            <div className="user-profile-table">

                { (basics && basics.verified_reach && parseInt(basics.verified_reach, 10) > 0) ?
                    (<div className="fake-li bottom-border">
                        <span>Verified Reach:</span>
                        <span className="pull-right">{formatNumber(basics.verified_reach)}</span>
                    </div>) : ''
                }

                { (profile && profile.blog_traffic && parseInt(profile.blog_traffic, 10) > 0) ?
                    (<div className="fake-li bottom-border">
                        <span>Blog Traffic:</span>
                        <span className="pull-right">
                            <a href={profile.blog_url} target="_blank">
                                {formatNumber(profile.blog_traffic)} visitors / mo
                            </a>
                        </span>
                    </div>) : ''
                }

                { (profile && profile.instagram_followers && parseInt(profile.instagram_followers, 10) > 0) ?
                    (<div className="fake-li bottom-border">
                        <span>Instagram:</span>
                        <span className="pull-right">
                            <a href={"https://www.instagram.com/" + profile.instagram_slug + '/'} target="_blank">
                            {formatNumber(profile.instagram_followers)} followers
                            </a>
                        </span>
                    </div>) : ''
                }

                { (profile && profile.tiktok_followers && parseInt(profile.tiktok_followers, 10) > 0) ?
                    (<div className="fake-li bottom-border">
                        <span>TikTok:</span>
                        <span className="pull-right">
                            <a href={"https://www.tiktok.com/@" + profile.tiktok_slug + '/'} target="_blank">
                            {formatNumber(profile.tiktok_followers)} followers
                            </a>
                        </span>
                    </div>) : ''
                }


                { (profile && profile.twitter_reach && profile.twitter_slug && parseInt(profile.twitter_reach, 10) > 0) ?
                    (<div className="fake-li bottom-border">
                        <span>Twitter:</span>
                        <span className="pull-right">
                            <a href={"https://twitter.com/" + profile.twitter_slug} target="_blank">
                            {formatNumber(profile.twitter_reach)} followers
                            </a>
                        </span>
                    </div>) : ''
                }

                { (profile && profile.pinterest_followers && parseInt(profile.pinterest_followers, 10) > 0) ?
                    (<div className="fake-li bottom-border">
                        <span>Pinterest:</span>
                        <span className="pull-right">
                            {formatNumber(profile.pinterest_followers)} followers
                        </span>
                    </div>) : ''
                }

                { (profile && profile.facebook_followers && parseInt(profile.facebook_followers, 10) > 0) ?
                    (<div className="fake-li bottom-border">
                        <span>Facebook:</span>
                        <span className="pull-right">
                            {formatNumber(profile.facebook_followers)} followers
                        </span>
                    </div>) : ''
                }
                { (profile && profile.facebook_page_followers && parseInt(profile.facebook_page_followers, 10) > 0) ?
                    (<div className='fake-li bottom-border'>
                        <span>Facebook Page:</span>
                        <span className='pull-right'>
                            {formatNumber(profile.facebook_page_followers)} followers
                        </span>
                    </div>) : ''
                }
                { (profile && profile.youtube_subscribers && parseInt(profile.youtube_subscribers, 10) > 0) ?
                    (<div className="fake-li bottom-border">
                        <span>YouTube:</span>
                        <span className="pull-right">
                            {formatNumber(profile.youtube_subscribers)} subscribers
                        </span>
                    </div>) : ''
                }

                { (profile && profile.overall_avg_engagement_pct) ?
                    (<div className="fake-li bottom-border">
                        <span>Engagement Pct:</span>
                        <span className="pull-right">
                            {formatNumber(profile.overall_avg_engagement_pct * 100, 1) + '%'}

                        </span>
                    </div>) : ''
                }
            </div>

        );
    }

    render() {
        return this.renderTable();
    }
}

UserSocialTable.propTypes = {
    user: PropTypes.object,
};
