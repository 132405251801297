/**
 * Created by bkanber on 6/21/17.
 */

import {makeFormData} from '../utilities';
import {fetchAuthenticated} from "./auth";

export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';

export const MARK_NOTIFICATION_READ = 'MARK_NOTIFICATION_READ';
export const MARKED_NOTIFICATION_READ = 'MARKED_NOTIFICATION_READ';

export const MARK_NOTIFICATION_HIDDEN = 'MARK_NOTIFICATION_HIDDEN';
export const MARKED_NOTIFICATION_HIDDEN = 'MARKED_NOTIFICATION_HIDDEN';

export const MARK_ALL_NOTIFICATIONS_READ = 'MARK_ALL_NOTIFICATIONS_READ';
export const MARKED_ALL_NOTIFICATIONS_READ = 'MARKED_ALL_NOTIFICATIONS_READ';

export function markAllNotificationsRead(read = true) {
    return function (dispatch) {

        dispatch({
            type: MARK_ALL_NOTIFICATIONS_READ
        });

        return dispatch(fetchAuthenticated(`/manage/api/notification`, {
            method: 'post',
            body: makeFormData({
                _method: 'delete'
            })
        }))
            .then(resp => resp.json())
            .then(json => {
                dispatch({ type: MARKED_ALL_NOTIFICATIONS_READ });
                return json;
            });

    }
}

/**
 * @returns {Function}
 */
export function markNotificationHidden(notificationId, hidden = true) {
    return function (dispatch) {

        dispatch({
            type: MARK_NOTIFICATION_HIDDEN,
            notificationId,
            hidden
        });

        const payload = {_method: 'put'};

        return dispatch(fetchAuthenticated(`/manage/api/notification/${notificationId}/hidden/${hidden}`,
            {
                method: 'POST',
                body: makeFormData(payload)
            }
        ))
            .then(response => response.json())
            .then(json => dispatch({
                type: MARKED_NOTIFICATION_HIDDEN,
                notificationId,
                hidden
            }))
            .catch(err => {
                console.log("Error marking notification as hidden");
                console.log(err);
            });

    }
}

/**
 * @returns {Function}
 */
export function markNotificationRead(notificationId, read = true) {
    return function (dispatch) {

        dispatch({
            type: MARK_NOTIFICATION_READ,
            notificationId,
            read
        });

        const payload = {_method: 'put'};

        return dispatch(fetchAuthenticated(`/manage/api/notification/${notificationId}/read/${read}`,
                {
                    method: 'POST',
                    body: makeFormData(payload)
                }
            ))
            .then(response => response.json())
            .then(json => dispatch({
                type: MARKED_NOTIFICATION_READ,
                notificationId,
                read
            }))
            .catch(err => {
                console.log("Error marking notification as read");
                console.log(err);
            });

    }
}

/**
 * @returns {{type: string}}
 */
export function requestNotifications() {
    return {
        type: REQUEST_NOTIFICATIONS
    };
}

/**
 * @param json
 * @returns {{type: string, lists, receivedAt: number}}
 */
export function receiveNotifications(json) {
    return {
        type: RECEIVE_NOTIFICATIONS,
        notifications: json.data,
        receivedAt: Date.now()
    }
}

/**
 * @returns {Function}
 */
export function fetchNotifications() {
    return function (dispatch) {

        dispatch(requestNotifications());

        return dispatch(fetchAuthenticated(`/manage/api/notification` ))
            .then(response => response.json())
            .then(json => dispatch(receiveNotifications(json)))
            .catch(err => {
                console.log("Error fetching notifications");
                console.log(err);
            });

    }
}

/**
 * @param state
 * @returns {*}
 */
export function shouldFetchNotifications(state) {
    if (state.notificationsById.isFetching) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchNotificationsIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchNotifications(getState())) {
            return dispatch(fetchNotifications())
        }
    }
}
