/**
 * Created by bkanber on 6/29/17.
 */

import {makeFormData, fetchAuthenticated} from '../utilities';

export const UPDATE_BLOG_FACET = 'UPDATE_BLOG_FACET';
export const UPDATED_BLOG_FACET = 'UPDATED_BLOG_FACET';
export const RECEIVE_BLOG_FACETS = 'RECEIVE_BLOG_FACETS';
export const RECEIVE_BLOGS = 'RECEIVE_BLOGS';

export function receiveBlogs(data) {
    return {
        type: RECEIVE_BLOGS,
        data,
        receivedAt: new Date()
    };
}

export function receiveBlogFacets(id, data) {
    return {
        type: RECEIVE_BLOG_FACETS,
        id,
        data,
        receivedAt: new Date()
    };
}

export function updateBlogFacet(id, name, value) {
    return function (dispatch) {

        dispatch({
            type: UPDATE_BLOG_FACET,
            id,
            name,
            value
        });

        const uri = `/manage/api/blog/${id}/facet/${encodeURIComponent(name)}`;

        return fetchAuthenticated(uri, {
            credentials: 'include',
            method: 'post',
            body: makeFormData({
                _method: 'put',
                value
            })
        })
            .then(resp => resp.json())
            .then(json => dispatch(receiveBlogFacets(id, json.data)));

    }
}

