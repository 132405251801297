import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ViewListTable from '../../../../components/List/ViewListTable';
import * as QuerySelectors from '../../../../selectors/queries';
import {connect} from 'react-redux';
import {DEFAULT_LISTENTRIES_QUERY} from "../../../../reducers/listEntries";
import * as Query from "../../../../actions/queries";
import * as ActionTypes from "../../../../actions/types";
import {
    createListInteraction,
    createUserInteraction,
    fetchBadges,
    fetchLists,
    fetchNetworks,
    fetchTeams
} from "../../../../actions";
import * as URI from 'urijs';
import Filters from '../../../../components/User/Table/Filters';
import {getCampaigns} from "../../../../selectors/campaigns";
import {getLists} from "../../../../selectors/lists";
import {getBadges} from "../../../../selectors/badges";
import {getTeams} from "../../../../selectors/teams";
import {getCurrentChannel} from "../../../../selectors/presence";
import QuickFiltersButton from "../../../../components/User/Pieces/QuickFiltersButton";
import {fetchAuthenticated} from "../../../../actions/auth";
import _get from 'lodash/get';
import {createChannelInteraction} from "../../../../actions/channel";
import {bindActionCreators} from "redux";

const ConnectedFilters = connect(
    (state, props) => ({
        campaigns: getCampaigns(state, props),
        lists: getLists(state, props),
        badges: getBadges(state, props),
        networks: Object.values(state.networksById.items),
        teams: getTeams(state, props),
        presence: state.presence,
    }),
    (dispatch) => bindActionCreators({fetchAuthenticated}, dispatch)
)(Filters);

const SORT_KEY_MAPPING = {
    'profile.name.raw': 'name.raw',
    'profile.performance.score': 'performance_score',
    'latest_score': 'rating',
    'profile.reach': 'verified_reach',
    'profile.engagement_pct': 'overall_avg_engagement_pct',
    'profile.tier' : 'tier',
    'profile.website': 'blog_name',
    'profile.location': 'human_location',
    'profile.stats.mozrank': false,
    'profile.stats.domain_authority': false,
    'profile.metadata.fastpass': 'fastpass',
};

class MyCreatorsList extends Component {
    static propTypes = {
        createUserInteraction: PropTypes.func,
        channel: PropTypes.object,
        teams: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.handleAddNote = this.handleAddNote.bind(this);
        this.handleRemoveNote = this.handleRemoveNote.bind(this);
        this.handleAddRating = this.handleAddRating.bind(this);
    }

    /**
     * This has to be in the Component because it relies on channel prop, which we can't get in mapDispatchToProps
     * @param userId
     * @param note
     * @returns {*}
     */
    handleAddNote(userId, note) {
        return this.props.createUserInteraction(
            userId,
            'AddNote',
            { channel_id: this.props.channel.id, note: note }
        );
    }

    handleRemoveNote(userId, noteId) {
        return this.props.createUserInteraction(
            userId,
            'RemoveNote',
            { channel_id: this.props.channel.id, note_id: noteId }
        );
    }

    /**
     * This has to be in the Component because it relies on channel prop, which we can't get in mapDispatchToProps
     * @param userId
     * @param score
     * @returns {*}
     */
    handleAddRating(userId, score) {
        return this.props.createUserInteraction(
            userId,
            'AddStarRating',
            { channel_id: this.props.channel.id, score}
        );
    }

    render() {
        return (
            <ViewListTable
                {...this.props}
                sortKeyMapping={SORT_KEY_MAPPING}
                addNote={this.handleAddNote}
                removeNote={this.handleRemoveNote}
                addRating={this.handleAddRating}
                renderFilters={(addlProps) => <ConnectedFilters {...addlProps} />}
                renderQuickFilters={(addlProps) => <QuickFiltersButton {...addlProps} />}
                showTeamTabs={true}
            />
        );
    }
}

const DEFAULT_MYCREATORS_QUERY = {
    ...DEFAULT_LISTENTRIES_QUERY,
    id: 'mycreators-default',
    filters: {group: 'brand'},
    sort: {by: 'performance_score', dir: 'desc'},
    // function to determine if we use a custom limit from the url:
    limit: (() => {
        const url = URI(window.location.href);
        const limit = url.query(true).limit;
        return limit ? parseInt(limit) : 15;
    })(),
};

const determineTitle = (_filters, state, props) => {
    let title = 'My Creators';
    const filters = _filters || props.filters;
    const filtersGroup = filters.group;

    switch (filtersGroup) {
        case 'activated':
            title = 'Activated Creators';
            break;
        case 'unactivated':
            title = 'Unactivated Creators';
            break;
        case 'activationCompleted':
            title = 'Creators with Completed Campaigns';
            break;
        case 'admins':
            title = 'Admins and Managers';
            break;
        case 'brand':
            title = 'Your Creators';
            break;
    }

    if (filtersGroup.substr(0, 5) === 'list:') {
        const listId = filtersGroup.substr(5);
        const list = state.listsById.lists[listId];
        if (list) {
            title = 'List: ' + list.name;
        }
    }

    if (filtersGroup.substr(0, 6) === 'badge:') {
        const badgeId = filtersGroup.substr(6);
        const badge = state.badgesById.items[badgeId];
        if (badge) {
            title = 'Badge: ' + badge.name;
        }
    }

    if (filtersGroup.substr(0, 8) === 'network:') {
        const networkId = filtersGroup.substr(8);
        const network = state.networksById.items[networkId];
        if (network) {
            title = 'Network: ' + network.name.replace('Tidal ', '');
        }
    }

    return title;
};

const mapStateToProps = (state, props) => {

    const query = QuerySelectors.getQuery(state.listEntries, props, DEFAULT_MYCREATORS_QUERY);
    const title = determineTitle(query.filters, state, props);

    return {
        presence: state.presence,
        channel: getCurrentChannel(state, props),
        entries: QuerySelectors.getPageItemsWithFallback(state.listEntries, props, DEFAULT_MYCREATORS_QUERY),
        teams: getTeams(state, props),
        query,
        title
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const url = `/manage/api/user`;

    // Fake list entries have id of `user:$userid`, so they need to be cleaned
    const getUserId = (id) => id.replace('user:', '');
    const _createChannelInteraction = (type, context) => dispatch(createChannelInteraction(type, context));
    const _createListInteraction = (listId, type, context) => dispatch(createListInteraction(listId, type, context));
    const _createUserInteraction = (userId, type, context) => dispatch(createUserInteraction(getUserId(userId), type, context));
    const addToOtherList = (otherListId, objects) => _createListInteraction(otherListId, 'AddToList', {objects});
    const blockUser = (userId, block) => _createChannelInteraction('BlockUser', {user_id: userId, block});
    const setUserChannelStatus = (userId, status) => _createChannelInteraction('SetUserChannelStatus', {user_id: userId, status});

    const fetchTotals = (filters) => {
        const urlRoot = `/manage/api/user/_/total`;
        let payload = { filters: JSON.stringify(filters) };
        let url = URI(urlRoot).query(payload).toString();
        return dispatch(fetchAuthenticated(url))
            .then(resp => resp.json());
    };

    const fetchEntry = (userId) => dispatch(
        Query.fetchQuery(
            url,
            {
                ...DEFAULT_MYCREATORS_QUERY,
                id: `one-off-user-id-query-${userId}`,
                presentAsList: 1,
                summary: false,
                filters: {ids: [getUserId(userId)]}
            },
            ActionTypes.LISTENTRIES_REQUEST_QUERY,
            ActionTypes.LISTENTRIES_RECEIVE_QUERY
        )
    );

    const getTotalForField = (field, totals) => {
        const mapping = {
            'sums.profile.reach': 'sums.verified_reach',
            'exists.profile.website': 'exists.blog_name',
            'sums.youtube_followers': 'sums.youtube_subscribers',
            'sums.facebook-page_followers': 'sums.facebook_page_followers',
            'avgs.profile.engagement_pct': 'avgs.overall_avg_engagement_pct',
        };

        let lookupKey = field;

        if (typeof mapping[field] !== 'undefined') {
            lookupKey = mapping[field];
        }

        return _get(totals, lookupKey);
    };

    const fetchAllIds = async (query) => {
        const resp = await dispatch(Query.fetchQuery(url, {...query, presentAsList: 0, metaOnly: true, limit: 5000}, 'REQUEST_USER_IDS', 'RECEIVE_USER_IDS'));
        return resp.data.map((item) => ({id: "user:" + item.id, collectable_id: item.id, collectable_type: "Tidal\\User", profile: {name: item.name, email: item.email}}));
    }

    return {
        onMount: () => {
            dispatch(fetchLists());
            dispatch(fetchBadges());
            dispatch(fetchNetworks());
            dispatch(fetchTeams());
        },
        onRefresh: (query) => dispatch(Query.fetchQuery(url, {...query, presentAsList: 1, summary: true}, ActionTypes.LISTENTRIES_REQUEST_QUERY, ActionTypes.LISTENTRIES_RECEIVE_QUERY)),
        onSortChange: (sort, query) => dispatch(Query.updateQuerySort(sort, query, ActionTypes.LISTENTRIES_UPDATE_QUERY_SORT)),
        onPageChange: (page, query) => dispatch(Query.updateQueryPage(page, query, ActionTypes.LISTENTRIES_UPDATE_QUERY_PAGE)),
        onFilterChange: (filters, query) => dispatch(Query.updateQueryFilters(filters, query, ActionTypes.LISTENTRIES_UPDATE_QUERY_FILTERS)),

        addToOtherList,
        fetchEntry,
        fetchTotals,
        getTotalForField,
        blockUser,
        setUserChannelStatus,

        createUserInteraction: _createUserInteraction,
        fetchAllIds
    };


};

export default connect(mapStateToProps, mapDispatchToProps)(MyCreatorsList);
