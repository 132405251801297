import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../Common/Alert';
import SubmitInstastoryImage from './SubmitInstastoryImage';
import SubmitInstastoryVideo from './SubmitInstastoryVideo';
import Button from '../../../Common/Form/Button';

import {connect} from 'react-redux';
import {createActivationInteraction} from "../../../../actions/activations";
import Form from "../../../Common/Form/Form";

class SubmitInstastory extends Component {

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        refreshActivation: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
    };

    state = {
        keybase: 0,
        isUploading: false,
        isRemoving: false,
        didUploadSuccessfully: false,
        interactionPayload: {
            'video_ids': [],
            'image_ids': [],
            'impressions': '',
            'engagements': '',
        },
        error: null
    };

    isUploading(val) {
        this.setState({isUploading: val});
    }

    renderAlert() {

        if (this.state.error) {

            return <Alert
                classes={['danger']}
                content={"Error uploading Instastory: " + this.state.error}
                style={{marginBottom: 20}}
            />;

        }

        const requiredCount = (this.props.activation.settings || {}).num_instastories;
        const uploadedCount = (this.props.activation.status || {}).num_instastories_uploaded;
        const {isUploading, isRemoving} = this.state;
        const isFulfilled = uploadedCount >= requiredCount;

        let content = `You are required to post ${requiredCount} Instastories. So far you have uploaded ${uploadedCount}. Please upload related MP4 videos and screenshots below.`;

        if (isUploading) {
            content = 'Uploading your content...';
        }

        if (isRemoving) {
            content = 'Removing content...';
        }

        return <Alert
            classes={[isFulfilled ? 'success' : 'info']}
            content={content}
            style={{marginBottom: 20}}
        />;



    }

    handleRemoveImage(image) {
        if (this.state.isRemoving) {
            return null;
        }

        this.setState({isRemoving: true});
        const {activation} = this.props;
        const payload = {image_id: image.id, action: 'remove'};
        return this.props.createInteraction(activation.id, 'UploadImage', payload)
            .then(() => this.props.refreshActivation(activation.id))
            .then(() => this.setState({isRemoving: false}));

    }

    /**
     * Updates our state array for tracking video ids
     * @param json
     * @return json
     */
    handleVideoSubmit(json) {
        //Collect video_ids and push them into the interactionPayload.video_ids
        let payload = {...this.state.interactionPayload};

        payload.video_ids.push(json.data.id);

        this.setState({
            // Resets the overall flag so validation errors come back for 2nd upload
            didUploadSuccessfully: false,
            isUploading: false,
            interactionPayload: payload
        });

        return json;
    }

    /**
     * Updates our state array for tracking image ids
     * @param json
     * @return json
     */
    handleImageSubmit(json) {

        // Collect image_ids and push it into the interactionPayload.image_ids
        let payload = {...this.state.interactionPayload};

        payload.image_ids.push(json.data.id);

        this.setState({
            // Resets the overall flag so validation errors come back for 2nd upload
            didUploadSuccessfully: false,
            isUploading: false,
            interactionPayload: payload
        });

        return json;
    }

    /**
     * Responsible for calling the backend interaction for submitting all Instastory data
     *
     * @param json
     */
    handleSubmit(event) {
        const {activation} = this.props;
        const {interactionPayload} = this.state;

        this.setState({
            isUploading: true,
        });

        return this.props.createInteraction(activation.id, 'SubmitInstastory', interactionPayload)
            .then((resp) => {

                if (resp.meta.error) {
                    this.setState({
                        error: resp.meta.error,
                        isUploading: false,
                    });
                    window.scrollTo(0, 0);
                } else {
                    this.props.refreshActivation(activation.id);

                    this.setState({
                        isUploading: false,
                        isRemoving: false,
                        didUploadSuccessfully: true,
                        error: null,
                        interactionPayload: {
                            'video_ids': [],
                            'image_ids': [],
                            'engagements': '',
                            'impressions': ''
                        },
                        keybase: this.state.keybase + 1
                    });
                }
            });
    }

    getInvalidFields() {
        let invalid = [];

        const videosPer = (this.props.activation.settings || {}).num_instastory_videos || 0;
        const imagesPer = (this.props.activation.settings || {}).num_instastory_images || 0;

        const {impressions, engagements, image_ids, video_ids} = this.state.interactionPayload;

        // If you just finished uploading don't show invalid fields
        if (this.state.didUploadSuccessfully) {
            return invalid;
        }

        if (!impressions || impressions === '0' || impressions === 0 || impressions === '') {
            invalid.push('impressions');
        }

        if (!engagements || engagements === '0' || engagements === 0 || engagements === '') {
            invalid.push('engagements');
        }

        if (video_ids.length < videosPer) {
            invalid.push('video_ids');
        }

        if (image_ids.length < imagesPer) {
            invalid.push('image_ids');
        }

        return invalid;
    }

    isFormValid() {
        return this.getInvalidFields().length === 0;
    }

    /**
     * Render our widget for uploading videos and images related to instastories
     *
     * @returns {XML}
     */
    render() {
        const requiredCount = (this.props.activation.settings || {}).num_instastories || 1;
        const videosPer = (this.props.activation.settings || {}).num_instastory_videos || 1;
        const imagesPer = (this.props.activation.settings || {}).num_instastory_images || 1;
        const isValid = this.isFormValid();

        let submitContent = (
            <span>
            {this.state.isUploading ? <i className="fa fa-spinner fa-spin" /> : ''} Submit Instastory
            </span>
        );

        if (this.state.didUploadSuccessfully) {
            submitContent = 'Instastory Submitted!';
        }

        return (
            <div className="influencer-page-template submit-instastory">
                <div className="widget">
                    <div className="title">
                        <h2>Upload Instastory</h2>
                    </div>
                    <div className="content padded">
                        {this.renderAlert()}

                        <h4 className="v3 bold action-pane-title">Submit Instastory</h4>

                        <p className="v3 light h7 pane-info"
                           style={{marginBottom: 20}}
                        >
                            <strong><i className="v3 icon info" /> How to Submit: </strong>
                            You are required to create and post <strong>{requiredCount} Instastor{requiredCount === 1 ? 'y' : 'ies'}</strong>. First, upload and post the story to Instagram. Then return here, and <em>for each</em> Instastory, upload {videosPer} video{videosPer === 1 ? '' : 's'} and {imagesPer} screenshot{imagesPer === 1 ? '' : 's'} for that Instastory. <strong>One of your screenshots should be the Insights page, and must include stats.</strong></p>
                        <p className="v3 light h7 pane-info"
                           style={{marginBottom: 40}}
                        >
                            Once you've uploaded the videos and screenshots, use the "Submit Instastory" button at the bottom. Need help? Use the "Have an Issue?" button or <a href="http://docs.tid.al/" target="_blank"><strong>visit the help center</strong></a>.

                        </p>

                        <SubmitInstastoryVideo
                            videosRequired={videosPer}
                            videoType={'Instastory'}
                            key={`vid-submit-${this.state.keybase}`}
                            isUploading={this.isUploading.bind(this)}
                            onSubmit={this.handleVideoSubmit.bind(this)}
                            campaign={this.props.campaign}
                            activation={this.props.activation}
                            refreshActivation={this.props.refreshActivation} />

                        <SubmitInstastoryImage
                            key={`img-submit-${this.state.keybase}`}
                            isUploading={this.isUploading.bind(this)}
                            onSubmit={this.handleImageSubmit.bind(this)}
                            campaign={this.props.campaign}
                            activation={this.props.activation}
                            refreshActivation={this.props.refreshActivation} />

                        <Form

                            invalidFields={this.getInvalidFields()}
                            usePadding={false}
                            onFieldChange={(key, value) => {
                                this.setState(prevState => {
                                    return {
                                        // Reset this flag so validation errors come back for 2nd upload
                                        didUploadSuccessfully: false,
                                        interactionPayload: {
                                            ...prevState.interactionPayload,
                                            [key]: value
                                        }
                                    };
                                });
                            }}
                            values={{
                                impressions: this.state.interactionPayload.impressions,
                                engagements: this.state.interactionPayload.engagements,
                            }}
                            fields={[
                                {
                                    type: 'number',
                                    name: 'impressions',
                                    title: 'Number of Impressions',
                                    help: "Report the number of views of your Instastory."
                                },
                                {
                                    type: 'number',
                                    name: 'engagements',
                                    title: 'Number of Engagements',
                                    help: "Report the total number of likes, clicks, and interactions on your Instastory."
                                }
                            ]}
                        />

                        <Button
                            content={submitContent}
                            classes={['v3', 'btn-primary', 'large', (!isValid ? 'disabled' : '')]}
                            onClick={isValid ? this.handleSubmit.bind(this) : undefined}
                        />

                        {(!isValid
                            ? <Alert
                                classes={['danger']}
                                content={"Please fill out all fields."}
                                style={{marginTop: 20}}
                            />
                            : null)}
                    </div>
                </div>
            </div>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(null, mapDispatchToProps)(SubmitInstastory);
