import React, {Component} from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

class Card extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        renderer: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            wasEverVisible: false
        };
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    }

    handleVisibilityChange(isVisible) {

        let payload = {isVisible};

        if (isVisible === true) {
            payload.wasEverVisible = true;
        }

        this.setState({...payload}, () => {
            if (this.props.onVisibilityChange) {
                this.props.onVisibilityChange(this.makeRowData());
            }
        });

    }
    /**
     * @returns {{
     *   item: Object,
     *   index: number,
     *   isVisible: boolean,
     *   wasEverVisible: boolean,
     *   isSelected: boolean,
     *   isExpanded: boolean
     * }}
     */
    makeRowData() {
        const {item} = this.props;
        const {isVisible, wasEverVisible} = this.state;

        let row = { item, isVisible, wasEverVisible };
        return row;
    }


    render() {

        const renderer = this.props.renderer ? this.props.renderer : () => null;
        return (
            <VisibilitySensor
                scrollCheck
                onChange={this.handleVisibilityChange}
                partialVisibility={true}
            >

                {renderer(this.makeRowData())}

            </VisibilitySensor>
        );
    }
}

export default Card;