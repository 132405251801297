import * as URI from 'urijs';
import * as ActionTypes from '../actions/types';
import {fetchAuthenticated} from "./auth";


/**
 * @TODO: update query page
 * @TODO: update query filter(s)?
 * @TODO: update query sort
 * @TODO: mark query dirty/clean
 * @TODO: mark query fetching
 * @TODO: receive query page results
 */

export const updateQueryPage = (page, query, type = ActionTypes.UPDATE_QUERY_PAGE) => ({
    type,
    page,
    query
});

export const updateQueryFilters = (filters, query, type = ActionTypes.UPDATE_QUERY_FILTERS) => ({
    type,
    filters,
    query
});

export const updateQuerySort = (sort, query, type = ActionTypes.UPDATE_QUERY_SORT) => ({
    type,
    sort,
    query
});

export const requestQuery = (url, query, type = ActionTypes.REQUEST_QUERY) => ({
    url,
    query,
    type
});

export const receiveQuery = (url, query, response, type = ActionTypes.RECEIVE_QUERY) => ({
    url,
    query,
    meta: response.meta,
    data: response.data,
    type
});

export const fetchQuery = (
    urlRoot,
    query,
    requestType = ActionTypes.REQUEST_QUERY,
    responseType = ActionTypes.RECEIVE_QUERY
) => {
    return (dispatch) => {


        let payload = { };
        const blacklisted = ['id', 'pages', 'isFetching', 'isDirty', 'last_page', 'last_sort', 'meta', 'queryId'];

        Object.keys(query).forEach(key => {
            if (blacklisted.indexOf(key) > -1) {
                return;
            }
            if (query.hasOwnProperty(key)) {

                if (typeof query[key] === 'object') {
                    payload[key] = JSON.stringify(query[key]);
                } else {
                    payload[key] = query[key];
                }
            }
        });

        const requestOptions = {
            credentials: 'include',
        };

        let url = URI(urlRoot)
            .query(payload)
            .toString();

        dispatch(requestQuery(url, query, requestType));

        return dispatch(fetchAuthenticated(url, requestOptions))
            .then(resp => resp.json())
            .then(json => dispatch(receiveQuery(url, query, json, responseType)))

    }
};


