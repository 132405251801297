import React, {useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {limitChars} from "../../utilities";
import Tooltip from "../Common/Tooltip";
import TagsInput from "react-tagsinput";
import {createUserInteraction} from "../../actions";

function EditableTagCell({tags, userId, createUserInteraction, onSaved, editable=true}) {

    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const isEditable = editable && !isSaving && !!userId && !!createUserInteraction;

    // This is for the popup
    const tagsHtml = (
        <div
            className="fake-table"
            style={{
                maxHeight: 250,
                overflow: 'auto'
            }}
        >
            {tags.map((tag, tagIndex) => <div className={"fake-li condensed " + (tagIndex < tags.length ? 'bottom-border' : '')}>{tag}</div>)}
        </div>
    );

    // when we're not editing
    const tagsPreview = tags.length > 0
        ? limitChars(tags.join(', '), 25)
        : 'N/A';

    const editLinkIcon = isEditable ? (
        <i className={"fa fa-pencil " + (isEditing ? 'active' : '')}
           onClick={() => setIsEditing(true)}
           style={{cursor: "pointer"}}
        />
    ) : null;

    let content;

    if (isSaving) {
        content = <>
            <i className="v3 icon spinner" /> Saving...
        </>;

    } else if (isEditing) {
        content = <TagsInput
            onChange={(newVal) => {
                setIsSaving(true);
                setIsEditing(false);
                createUserInteraction(userId, 'SetTags', {tags: newVal})
                    .then(resp => {
                        if (onSaved) {
                            onSaved(newVal);
                        }
                        setIsSaving(false);
                    })
            }}
            value={tags || []}
        />;

    } else if (tags.length > 0) {
        content = <Tooltip html={tagsHtml}>{editLinkIcon} {tagsPreview}</Tooltip>;
    } else {
        content = <>{editLinkIcon}</>;
    }

    return content;
}

const mapStateToProps = undefined;
const mapDispatchToProps = (dispatch) => bindActionCreators({
    createUserInteraction: createUserInteraction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditableTagCell);