/**
 * Created by bkanber on 6/8/17.
 */
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from './reducers'

/**
 * Set up middleware.
 */

let middlewares = [
    thunk
];

if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger({collapsed: true}));
}

export default function configureStore(preloadedState) {

    return createStore(
        rootReducer,
        preloadedState,
        applyMiddleware(...middlewares)
    );

}
