import React, {Component} from 'react';
import PropTypes from 'prop-types';
import C3Chart from 'react-c3js';
import d3 from 'd3';
import {c3ColorPattern} from '../../../utilities';

const renderLabelForSocial = (social, showHandle) => {
    let type = social.type.replace('Tidal\\Social\\', '').replace('FacebookPage', 'Facebook Pages');
    let out = '@' + social.slug + ' on ' + type;

    if (showHandle === false) {
        out = type;
    }

    return out;
};

export default class SocialEngagementPctComparisonChart extends Component {

    getFilteredSocials() {
        return (this.props.socials || []).filter(social => {
            return social.avg_engagement_pct !== null;
        });
    }

    getData() {
        const socials = this.getFilteredSocials();
        if (socials.length === 0) {
            return null;
        }

        const showHandle = this.props.showHandle;

        let columns = socials.map(social => {
            return [
                renderLabelForSocial(social, showHandle),
                social.avg_engagement_pct
            ];
        });

        columns.push(
            ['x', 'Avg Engagement Percent']
        );


        let out = {
            x: 'x',
            type: 'bar',
            columns
        };

        return out;
    }

    renderChart() {
        const data = this.getData();
        if (!data) return null;

        const axis = {
            x: {type: 'category'},
            y: {
                tick: {
                    format: d3.format('%')
                }
            }
        };

        const tooltip = {
            format: {
                value: (value, ratio, id) => {
                    return d3.format('%')(value);
                }
            }
        };

        return <C3Chart
            transition={{duration: 0}}
            data={data}
            color={c3ColorPattern}
            axis={axis}
            tooltip={tooltip}
            size={this.props.size}
        />


    }

    render() {

        const style = this.props.style;
        return (

            <div className="widget" style={style}>
                <div className="title">
                    <div className="icon left">
                        <i className="fa fa-line-chart"></i>
                    </div>
                    <h2>
                        Social Engagement Pcts
                    </h2>
                </div>
                <div className="content padded clearfix">
                    {this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
                </div>
            </div>

        );
    }


}

SocialEngagementPctComparisonChart.defaultProps = {
    socials: [],
    style: {width: '580px', height: '350px', marginTop: 0, flex: 'none'},
    size: {height: 270},
    showHandle: true
};

SocialEngagementPctComparisonChart.propTypes = {
    socials: PropTypes.array,
    style: PropTypes.object,
    size: PropTypes.object,
    showHandle: PropTypes.bool
};
