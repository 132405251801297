/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatNumber} from '../../../utilities';
import RechartsTimeseriesAreaChart from "../../Chart/RechartsTimeseriesAreaChart";
import ChartWrapper from "../../Chart/ChartWrapper";

export default class CampaignEngagamentPctOverTimeChart extends Component {

    static defaultProps = {
        widgetClass: "widget return-over-time-chart"
    };
    static propTypes = {
        widgetClass: PropTypes.object,
        widgetStyle: PropTypes.object,
        colors: PropTypes.array,
    };

    getStats() {
        const stats = this.props.stats || [];
        return stats.map(stat => {
            const reach = (stat.total_reach || 0);
            stat.total_reach_impressions = reach;

            if (reach) {
                stat.engagement_pct = 100 * stat.total_engagements / reach;
            } else {
                stat.engagement_pct = 0;
            }

            return stat
        });
    }

    getTotal() {
        const stats = this.getStats();
        if (!stats || stats.length === 0) return null;
        return stats[stats.length - 1].engagement_pct;
    }

    renderChart() {
        const stats = this.getStats();
        if (!stats || stats.length === 0) return null;
        const engPctStrokeColor = this.props.colors ? this.props.colors[0] : '#206601';

        return (
            <RechartsTimeseriesAreaChart
                data={stats}
                seriesSettings={[
                    {
                        chartType: 'area',
                        dataKey: 'total_reach_impressions',
                        name: 'Reach',
                        yAxisId: 'right',
                        stroke: '#DDDDDD',
                        fill: '#DDDDDD'
                    },
                    {
                        chartType: 'area',
                        dataKey: 'total_engagements',
                        name: 'Engagements',
                        yAxisId: 'right',
                        stroke: '#AAAAAA',
                        fill: '#AAAAAA'
                    },
                    {
                        chartType: 'line',
                        dataKey: 'engagement_pct',
                        name: 'Engagement Percent',
                        stroke: engPctStrokeColor,
                        strokeWidth: 2,
                        formatter: (value) => formatNumber(value, 2) + '%'
                    }
                ]}
                axisSettings={[
                    {
                        axisType: 'x',
                        dataKey: 'created_at',
                        stroke: '#888',
                        interval: 0
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'left',
                        stroke: '#888',
                        tickFormatter: (value) => formatNumber(value, 2) + '%'
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'right',
                        stroke: '#888',
                        orientation: 'right',
                        tickFormatter: (value) => formatNumber(value)
                    }
                ]}
            />
        )
    }

    render() {
        const total = this.getTotal();
        const titleBadge = total !== null ? `${formatNumber(total, 2)}% engagement` : null;

        return (
            <ChartWrapper
                title="Captured Audience Over Time"
                titleBadge={titleBadge}
                icon="bullhorn"
                chart={this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
            />
        )
    }

}
