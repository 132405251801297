import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ContributeDashProfileImages from "./ContributeDashProfileImages";
import ConnectedImageUpload from "../../Common/Form/ImageUpload";

class RepresentativeImagesEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            image_ids: [],
            uploadedImages: [],
            isUploading: false,
            didUpload: false,
            hasError: false,
            error: null
        };
    }

    componentDidMount() {
        this.setState({image_ids: this.props.user.representative_images.map(i => i.id)})
    }

    handleUpload(id, image) {
        this.setState({
            isUploading: true,
            didUpload: false,
            image_ids: [...this.state.image_ids, id],
            uploadedImages: [...this.state.uploadedImages, image]
        });
        this.props.onChangeImage(this.state.image_ids);
    }

    render() {
        return (
            <div className="profile-images">
                <div className="hscrollwrapper">

                    <div className="left">
                        <label htmlFor="rep-image-upload-btn" className="rep-image-upload-label">
                            <span><i className="v3 icon plus"></i> Add image</span>
                        </label>
                        <ConnectedImageUpload onChange={this.handleUpload.bind(this)} id="rep-image-upload-btn" />
                    </div>

                    <div className="right">
                        <ContributeDashProfileImages
                            images={[...this.props.user.representative_images, ...this.state.uploadedImages]}
                            imageIds={this.state.image_ids}
                            onChangeImage={this.props.onChangeImage}
                        />
                    </div>

                </div>
            </div>
        )
    }

}

export default RepresentativeImagesEditor;

RepresentativeImagesEditor.propTypes = {
    user: PropTypes.object.isRequired,
    onChangeImage: PropTypes.func,
};