/**
 * Requires activationId in props.
 * @param state
 * @param props
 * @returns {*|Array}
 */
export const getActivationStats = (state, props = {}) => {
    const {activationId} = props;
    return state.activations.stats[activationId] || [];
};

/**
 * Requires activationId in props
 * @param state
 * @param props
 * @returns {Array.<*>}
 */
export const getActivationInteractions = (state, props = {}) => {
    const {activationId} = props;
    return Object.values(state.activations.interactions || {})
        .filter(interaction => {
            return interaction.target_id === activationId
                && interaction.target_type === 'Tidal\\Campaign\\Activation';
        })
        .reverse();
};

/**
 * Requires activationId in props
 * @param state
 * @param props
 * @returns {*|{}}
 */
export const getActivation = (state, props = {}) => {
    const {activationId} = props;
    return state.activations.items[activationId] || {};
};