import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../../actions/activations";
import PropTypes from 'prop-types';
import EventDivider from '../../Common/TimelineView/EventDivider';
import MessageRow from '../../Common/TimelineView/MessageRow';
import {makeMomentFromDate, limitChars } from "../../../utilities";
import _find from 'lodash/find';
import DraftToolbox from '../../Activation/DraftToolbox';

class DraftInteractionMessage extends Component {

    static propTypes = {
        interaction: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        onRefresh: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            showToolbox: false,
            isUpdatingStatus: false,
        };
        this.handleChangeDraftStatus = this.handleChangeDraftStatus.bind(this);
    }

    handleChangeDraftStatus(status) {
        const {interaction, activation} = this.props;
        const draftId = interaction.context.source_id;

        const context = { status, source_id: draftId };
        this.setState({isUpdatingStatus: true});
        return this.props.createInteraction(activation.id, 'UpdateDraftStatus', context)
            .then(() => this.setState({isUpdatingStatus: false}))
            .then(() => {
                if (this.props.onRefresh) {
                    this.props.onRefresh();
                }
            });

    }


    getDraft() {
        const {interaction, activation} = this.props;
        const draftId = interaction.context.source_id;
        const draft = _find(activation.drafts, {source_id: draftId});
        return draft;
    }

    renderMessageDetails() {
        const {interaction} = this.props;
        switch (interaction.type) {

            case "Tidal\\Campaign\\Activation\\Interaction\\UpdateDraftStatus":
                return this.renderStatusUpdateDetails();
            case "Tidal\\Campaign\\Activation\\Interaction\\CommentOnDraft":
                return this.renderCommentDetails();
            case "Tidal\\Campaign\\Activation\\Interaction\\SubmitDraft":
                return this.renderDraftUpdateDetails();
        }
    }

    getNiceDraftStatus() {
        const draft = this.getDraft();
        if (!draft) return 'Unknown Status';

        if (draft.draft_status === 'accepted') {
            return 'Approved';
        }
        if (draft.draft_status === 'drafting') {
            return 'In Progress';
        }
        if (draft.draft_status === 'request-revision') {
            return 'Needs Revision';
        }

    }

    renderStatusUpdateDetails() {
        const {interaction} = this.props;
        const draft = this.getDraft();
        if (!draft) return;

        return <div>
            <h4 className="v3 light">{draft.title} <small>{this.getNiceDraftStatus()}</small></h4>
            <h6 className="v3 light">{interaction.actor.name} marked the draft as <strong>{this.getNiceDraftStatus()}</strong>.</h6>
        </div>
    }

    renderDraftUpdateDetails() {
        const {interaction} = this.props;

        return <div>
            <h4 className="v3 light">{interaction.context.title} <small>{this.getNiceDraftStatus()}</small></h4>
            <h6 className="v3 light">{interaction.actor.name} updated the draft:</h6>
            <div className={'v3 quote draft'}
                 dangerouslySetInnerHTML={{__html: interaction.context.content}}
            ></div>
        </div>
    }

    renderCommentDetails() {
        const {interaction} = this.props;
        const draft = this.getDraft();
        if (!draft) return;
        return <div>
            <h4 className="v3 light">{draft.title} <small>{this.getNiceDraftStatus()}</small></h4>
            <h6 className="v3 light">New comment by {interaction.actor.name}:</h6>
            <div className={'v3 quote comment'} dangerouslySetInnerHTML={{__html: interaction.context.comment}}></div>
        </div>
    }

    renderMessage() {

        return (
            <div className={'interaction-group'}>
                {this.renderMessageDetails()}

            </div>
        );

    }


    getEventDividerMessage() {
        const {interaction, activation} = this.props;

        switch (interaction.type) {

            case "Tidal\\Campaign\\Activation\\Interaction\\UpdateDraftStatus":
                return `${interaction.actor.name} updated a draft's status.`;
            case "Tidal\\Campaign\\Activation\\Interaction\\CommentOnDraft":
                return `${interaction.actor.name} commented on a draft.`;
            case "Tidal\\Campaign\\Activation\\Interaction\\SubmitDraft":
                return `${interaction.actor.name} updated a draft.`;
        }

    }

    renderLinks() {
        const {showToolbox} = this.state;

        return <a role="button"
                  className="v3 light"
                  onClick={() => this.setState({showToolbox: !showToolbox})}
                  >{showToolbox ? 'Hide Draft Editor' : 'Show Draft Editor'}</a>






        const draft = this.getDraft();
        if (!draft) return;

        if (draft.draft_status === 'drafting') {
            return <a className="v3 light" role="button" onClick={this.handleChangeDraftStatus.bind(this, 'accepted')}>Approve Draft</a>
        }

        if (draft.draft_status === 'accepted') {
            return <a className="v3 light" role="button" onClick={this.handleChangeDraftStatus.bind(this, 'request-revision')}>Request Revisions</a>
        }
    }

    handleDelayedRefresh() {
        setTimeout(() => {
            this.setState({showToolbox: false});
            if (this.props.onRefresh) {
                this.props.onRefresh();
            }
        }, 3000);
    }

    renderToolbox() {
        const {interaction, activation} = this.props;
        const {showToolbox} = this.state;
        if (!showToolbox) return;

        return <DraftToolbox
            activation={activation}
            draft={this.getDraft()}
            toolboxTitle={'Edit Draft'}
            showComments={true}
            onClose={() => this.setState({showToolbox: false})}
            onSaveComment={this.handleDelayedRefresh.bind(this)}
            onSave={this.handleDelayedRefresh.bind(this)}
            style={{
                width: 1000,
                left: '50%',
                marginLeft: -500,
                top: 0,
                maxHeight: '100%',
            }}

        />

    }

    render() {

        const {interaction, activation} = this.props;
        const {showToolbox} = this.state;
        const timestamp = makeMomentFromDate(interaction.created_at).format('MMM Do, YYYY');
        const layout = activation.user_id === interaction.actor.id ? 'right' : 'left';

        return (
            <div className={'timeline-group draft-interaction'}>

                {this.renderToolbox()}
                <EventDivider message={this.getEventDividerMessage()} timestamp={timestamp} />

                <MessageRow
                    message={this.renderMessage()}
                    image={interaction.actor.picture}
                    timestamp={timestamp}
                    layout={layout}
                    link={this.renderLinks()}
                />
            </div>

        );

    }
}


const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context))
    };
};

export default connect(null, mapDispatchToProps)(DraftInteractionMessage);
