import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from '../Common/Toolbox';
import RaiseIssueForm from './RaiseIssueForm';
import ShippingAddressForm from './ShippingAddressForm';
import PaymentDetailsForm from './PaymentDetailsForm';
import SendEmailForm from './SendEmailForm';
import StatusChangeForm from './StatusChangeForm';
import ShipProductForm from './ShipProductForm';
import UpdatePaymentOfferForm from './UpdatePaymentOfferForm';
import GenerateInvoiceForm from './GenerateInvoiceForm';
import ConnectedInfluencerRequirements from '../../containers/Influencer/InfluencerRequirements';
import DraftToolbox from './DraftToolbox';
import IssueToolbox from './IssueToolbox';
import AssignCouponCodeToolbox from './AssignCouponCodeToolbox';
import ActivationRequirementsTable from './ActivationRequirementsTable';
import AssignProductToolbox from './AssignProductToolbox';
import ActivationSettingsToolbox from './SettingsToolbox';
import Alert from '../Common/Alert';
import Form from "../Common/Form/Form";
import ReactivateForm from './ReactivateForm';
import InvitationForm from './InvitationForm';
import AddStatsToPostForm from './AddStatsToPostForm';
import Tooltip from "../Common/Tooltip";
import DeleteActivationForm from "./DeleteActivationForm";
import QuickAddPostForm from "./QuickAddPostForm";
import ViewPostDrivers from "./ViewPostDrivers";
import AddPaidAdStatsToPostForm from "./AddPaidAdStatsToPostForm";


export default class ActivationInteractionToolbox extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        campaign: PropTypes.object,
        type: PropTypes.string.isRequired,
        onClose: PropTypes.func,
        style: PropTypes.object,
        context: PropTypes.object,
    };

    getStyle() {
        let style = {
            top: '50%',
            left: '50%',
            width: '600px',
            position: 'fixed',
            height: 'auto',
            overflow: 'initial',
            transform: 'translate(-50%, -50%)',
        };

        const largeToolboxes = [
            'ManageDrafts',
            'ManageIssues',
            'ViewActivationRequirements',
            'SelectProduct',
            'LaunchInfluencerUI',
            'UpdateSettings',
            'UpdatePaymentOffer',
        ];

        if (largeToolboxes.indexOf(this.props.type) > -1) {
            style.width = '100%';
            style.height = '100%';
            style.overflow = 'auto';
        }

        const greyToolboxes = ['UpdateSettings'];
        if (greyToolboxes.indexOf(this.props.type) > -1) {
            style.background = '#eee';
        }

        return {...style, ...this.props.style};
    }

    render() {
        const {type, activation, onClose, campaign, context} = this.props;
        const cleanType = type.replace('Tidal\\Campaign\\Activation\\Interaction\\', '');
        const userName = activation.user.name;
        const style = this.getStyle();

        switch (cleanType) {

            case 'ViewPostDrivers':

                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`Post Stats Explorer`}
                                content={<ViewPostDrivers activation={activation} />}
                />;
                break;
            case 'QuickAddPost':
                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`Add a Post by ${userName} to Campaign`}
                                content={<QuickAddPostForm
                                    activation={activation}
                                    onComplete={() => {
                                        setTimeout(() => {
                                            if (onClose) {
                                                onClose();
                                            }
                                        }, 2000);
                                    }}
                                />}
                />;
                break;

            case 'DeleteActivation':
                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`Remove ${userName} from Campaign`}
                                content={<DeleteActivationForm
                                    activation={activation}
                                    onComplete={() => {
                                        setTimeout(() => {
                                            if (onClose) {
                                                onClose();
                                            }
                                        }, 2000);
                                    }}
                                />}
                        />;
                break;

            case 'AddPaidAdStatsToPost':
                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`Add Paid Performance for ${userName}`}
                                content={<AddPaidAdStatsToPostForm
                                    usePadding={true}
                                    activation={activation}
                                    onSaved={() => {
                                        setTimeout(() => {
                                            if (onClose) {
                                                onClose();
                                            }
                                        }, 2000);
                                    }}
                                />}
                />;
                break;
            case 'AddStatsToPost':
                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`Update Stats for ${userName}`}
                                content={<AddStatsToPostForm
                                    usePadding={true}
                                    activation={activation}
                                    onSaved={() => {
                                        setTimeout(() => {
                                            if (onClose) {
                                                onClose();
                                            }
                                        }, 2000);
                                    }}
                                />}
                        />;
                break;

            case 'Reactivate':
                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`Add ${userName} to Another Campaign`}
                                content={<ReactivateForm
                                    activation={activation}
                                />}
                />;
                break;

            case 'GetInfluencerLink':
                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`Activation Link for ${userName}`}
                                content={
                                    <Form
                                        values={{
                                            auth_link: 'https://' + window.location.hostname + '/contribute/do/page/campaign/activation?hash=' + activation.auth_hash
                                        }}
                                        fields={[
                                            {
                                                type: 'generic',
                                                content: <Alert
                                                    classes={['info']}
                                                    content={`Find a link to the user interface for ${userName} below.`}
                                                />
                                            },
                                            {
                                                type: 'text',
                                                readonly: true,
                                                name: 'auth_link',
                                                title: 'Influencer Link',
                                                help: 'The link above can be given to the user so they can access their dashboard.'
                                            }
                                        ]}
                                    />
                                }
                />;
                break;

            case 'UpdateSettings':
                return (
                    <ActivationSettingsToolbox
                        activation={activation}
                        campaign={campaign}
                        onClose={onClose}
                        style={style}
                        title={`Override ${userName}'s Settings`}
                    />

                );
                break;

            case 'SelectProduct':
                return (
                    <AssignProductToolbox
                        title={`Assign Product to ${userName}`}
                        onClose={onClose}
                        style={style}
                        campaignId={activation.campaign_id}
                        activation={activation}
                        onSaved={() => {
                            setTimeout(() => {
                                if (onClose) {
                                    onClose();
                                }
                            }, 2000);
                        }}
                    />
                );
                break;

            case 'ViewActivationRequirements':
                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`${userName}'s Progress`}
                                content={
                                    <ActivationRequirementsTable
                                        activation={activation}
                                    />
                                }
                />;

            case 'AssignCouponCode':
                return <AssignCouponCodeToolbox
                    supportMobile={true}
                    onClose={onClose}
                    activation={activation}
                    style={{...style, maxHeight: 600, overflow: 'auto'}}
                    onSaved={() => {
                        setTimeout(() => {
                            if (onClose) {
                                onClose();
                            }
                        }, 2000);
                    }}
                />;

            case 'ManageIssues':
                return <IssueToolbox
                    onClose={onClose}
                    activation={activation}
                    style={style}
                    showComments={true}
                    issues={activation.issues}
                />;

            case 'ManageDrafts':
                return <DraftToolbox
                    onClose={onClose}
                    activation={activation}
                    style={style}
                    showComments={true}
                    drafts={activation.drafts}
                    showApprovalTool={true}
                    draftId={context.source_id}
                />;

            case 'ShipProduct':
                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`Ship Product Samples to ${userName}`}
                                content={
                                    <ShipProductForm
                                        usePadding={true}
                                        activation={activation}
                                        onComplete={() => {
                                            setTimeout(() => {
                                                if (onClose) {
                                                    onClose();
                                                }
                                            }, 2000);
                                        }}
                                    />
                                }
                />;

            case 'GenerateInvoice':
                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`Approve Payment for ${userName}`}
                                content={
                                    <GenerateInvoiceForm
                                        usePadding={true}
                                        activation={activation}
                                        onComplete={() => {
                                            setTimeout(() => {
                                                if (onClose) {
                                                    onClose();
                                                }
                                            }, 2000);
                                        }}
                                    />
                                }
                />;

            case 'UpdatePaymentOffer':
                return <Toolbox onClose={onClose}
                                supportMobile={true}
                                style={{...style, height: 'auto', width: '750px'}}
                                title={`Update Payment for ${userName}`}
                                content={
                                    <UpdatePaymentOfferForm
                                        usePadding={true}
                                        activation={activation}
                                        onSaved={() => {
                                            setTimeout(() => {
                                                if (onClose) {
                                                    onClose();
                                                }
                                            }, 2000);
                                        }}
                                        campaign={campaign}
                                    />
                                }
                />;

            case 'Invite':
                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`Invite ${userName}`}
                                content={
                                    <InvitationForm
                                        activation={activation}
                                        onComplete={() => {
                                            setTimeout(() => {
                                                if (onClose) {
                                                    onClose();
                                                }
                                            }, 2000);
                                        }}
                                    />
                                }
                />;
                break;

            case 'Waitlist':
            case 'AcceptAfterWaitlist':
            case 'Cancel':
            case 'InviteReminder':
            case 'PostDeadlineReminder':

                let niceStatusTitle = `${cleanType} ${userName}`;
                if (cleanType === 'InviteReminder') {
                    niceStatusTitle = `Send ${userName} an Invitation Reminder`;
                } else if (cleanType === 'AcceptAfterWaitlist') {
                    niceStatusTitle = `Accept ${userName}`;
                } else if (cleanType === 'PostDeadlineReminder') {
                    niceStatusTitle = `Send ${userName} a Post Reminder`;
                }

                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={niceStatusTitle}
                                content={
                                    <StatusChangeForm
                                        activation={activation}
                                        type={cleanType}
                                        onComplete={() => {
                                            setTimeout(() => {
                                                if (onClose) {
                                                    onClose();
                                                }
                                            }, 2000);
                                        }}
                                    />
                                }
                />;

            case 'LaunchInfluencerUI':
                return <Toolbox onClose={onClose} style={style}
                                supportMobile={true}
                                title={`User Campaign Interface for ${userName}`}
                                content={
                                    <ConnectedInfluencerRequirements
                                        activationId={activation.id}
                                        campaignId={activation.campaign_id}
                                        modalInterface={true}
                                    />
                                }
                                addlClasses="influencer-ui-toolbox"
                />;

            case 'SendEmail':
                return <Toolbox onClose={onClose}
                                style={{top: '50%', left: '50%', width: 600, position: 'fixed', transform: 'translate(-50%,-50%)', overflow: 'auto'}}
                                supportMobile={true}
                                title={`Send a Message to ${userName}`}
                                addlClasses={"send-email-toolbox"}
                                content={<SendEmailForm
                                    activation={activation}
                                    onSaved={() => {
                                        setTimeout(() => {
                                            if (onClose) {
                                                onClose();
                                            }
                                        }, 2000)
                                    }}

                                />}
                />;

            case 'RaiseIssue':
                return <Toolbox
                    supportMobile={true}
                    content={<RaiseIssueForm
                        onClose={onClose}
                        activation={activation}
                        mode='manager'
                    />}
                    title={`Raise an Issue - ${userName}`}
                    onClose={onClose}
                    style={style}
                />;

            case 'SubmitShippingAddress':
                return <Toolbox
                    supportMobile={true}
                    content={<ShippingAddressForm
                        usePadding={true}
                        onSaved={() => {
                            setTimeout(() => {
                                if (onClose) {
                                    onClose();
                                }
                            }, 2000)
                        }}
                        activation={activation}
                        style={{
                            maxHeight: 500,
                            overflow: 'auto'
                        }}
                    />}
                    title={`Update Shipping Address - ${userName}`}
                    onClose={onClose}
                    style={style}
                />;

            case 'SubmitPaymentProfile':
                return <Toolbox
                    supportMobile={true}
                    content={<PaymentDetailsForm
                        usePadding={true}
                        onSaved={() => {
                            setTimeout(() => {
                                if (onClose) {
                                    onClose();
                                }
                            }, 2000)
                        }}
                        activation={activation}
                        style={{
                            maxHeight: 500,
                            overflow: 'auto'
                        }}
                    />}
                    title={`Update Payment Profile - ${userName}`}
                    onClose={onClose}
                    style={style}
                />;



            default: return null;
        }

    }
}
