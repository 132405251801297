import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RechartsBarChart from "../RechartsBarChart";
import {formatNumber} from "../../../utilities";

class AudienceBarChart extends Component {
    static propTypes = {
        data: PropTypes.object,
        dataKeys: PropTypes.array,
        colors: PropTypes.array,
        title: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number
    };

    getDataKeys() {
        if (this.props.dataKeys) {
            return this.props.dataKeys;
        }
        return Object.keys(this.props.data || {});
    }

    getData() {
        let out = [];
        for (let key of this.getDataKeys()) {
            out.push({name: key, value: this.props.data[key]});
        }
        return out;
    }
    render() {
        if (!this.props.data) {
            return null;
        }
        return (
            <RechartsBarChart
                data={this.getData()}
                valueFormatter={value => formatNumber(100 * value, 1) + '%'}
                tickFormatter={value => formatNumber(100 * value, 1) + '%'}
                seriesName={this.props.title}
                height={this.props.height}
                width={this.props.width}
                colors={this.props.colors}
            />
        );
    }
}

export default AudienceBarChart;