import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";

class SubmitSocialUrlFallback extends Component {

    static propTypes = {
        activation: PropTypes.object,
        campaign: PropTypes.object,
        createInteraction: PropTypes.func,
        refreshActivation: PropTypes.func,
        onSubmitted: PropTypes.func,
        expanderText: PropTypes.string,
        onClickExpanderOverride: PropTypes.func
    };

    static defaultProps = {
        expanderText: 'Can\'t find your post? Click here to submit manually.'
    };

    state = {
        expanded: false,
        isSubmitting: false,
        didSubmit: false,
        error: null,
        url: '',
    };

    handleSubmit() {

        this.setState({isSubmitting: true, didSubmit: false});

        const ctx = {
            social_type: this.props.type,
            url: this.state.url,
        };

        this.props.createInteraction(this.props.activation.id, 'SubmitSocialPostUrl', ctx)
            .then(() => {
                this.setState({
                    isSubmitting: false,
                    didSubmit: true,
                    url: '',
                    expanded: false,
                });
                this.props.refreshActivation();
                if (this.props.onSubmitted) {
                    this.props.onSubmitted();
                }
            });

    }

    renderExpanderLink() {
        const onClick = !!this.props.onClickExpanderOverride
            ? this.props.onClickExpanderOverride
            : () => this.setState({expanded: !this.state.expanded});
        const carat = this.state.expanded ? <i className="v3 icon angle-down" /> : <i className="v3 icon angle-right" />;
        return (
            <div style={{marginTop: 15, marginBottom: 15}}><label><a
                onClick={onClick}
                className="v3 dark bold"
                role="button">{carat} {this.props.expanderText}</a></label></div>
        );

    }

    renderForm() {

        if (!this.state.expanded) {
            return null;
        }

        return (
            <Form
                usePadding={false}
                values={{url: this.state.url}}
                onFieldChange={(k, v) => this.setState({[k]: v})}
                fields={[
                    {
                        type: 'text',
                        name: 'url',
                        title: 'URL of Post',
                        placeholder: 'https://www...',
                        help: "Enter the full URL of the post you want to submit."
                    },
                    {
                        type: 'button',
                        name: 'submit',
                        title: this.state.isSubmitting ? 'Submitting, please wait...' : 'Submit Post',
                        options: {
                            hideTitle: true,
                            classes: ['v3 medium btn-primary', this.state.isSubmitting ? 'disabled' : ''],
                            onClick: this.handleSubmit.bind(this)
                        }
                    }

                ]}
            />
        );
    }

    renderAlert() {
        if (this.state.didSubmit) {
            return (<Alert classes={['success']} content={"Your post was submitted successfully!"}/>);
        }
    }

    render() {
        return (

            <>
                {this.renderExpanderLink()}
                {this.renderForm()}
                {this.renderAlert()}
            </>

        );

    }
}

export default SubmitSocialUrlFallback;