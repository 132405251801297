import {formatDollar, formatNumber, formatNumberK} from "../../utilities";
import _get from "lodash/get";
import {useMemo} from "react";

export const addDerivedStats = (stats) => {

    // Start with zeroes
    stats.instastory_link_clicks_rate = 0;
    stats.instastory_share_rate = 0;
    stats.instagram_save_rate = 0;

    // instagram_save_rate = instagram_save_count / instagram_impression_count
    if (stats.instagram_impression_count > 0) {
        stats.instagram_save_rate = stats.instagram_save_count / stats.instagram_impression_count;
    }

    // instastory_link_clicks_rate = instastory_link_clicks / instastory_impression_count
    // instastory_share_rate = instastory_shares_count / instastory_impression_count
    if (stats.instastory_impression_count > 0) {
        stats.instastory_link_clicks_rate = stats.instastory_link_clicks / stats.instastory_impression_count;
        stats.instastory_share_rate = stats.instastory_shares_count / stats.instastory_impression_count;
    }

    return stats;
};

export const ICON_OPTIONS = [
    {text: 'Dollar Sign', value: 'dollar-sign'},
    {text: 'Users', value: 'users'},
    {text: 'User', value: 'user'},
    {text: 'Gender', value: 'gender'},
    {text: 'Pie Chart', value: 'pie-chart'},
    {text: 'Line Chart', value: 'line-chart'},
    {text: 'Bullhorn', value: 'bullhorn'},
    {text: 'Address Card', value: 'address-card'},
    {text: 'Search', value: 'search'},
    {text: 'Download', value: 'download'},
    {text: 'Inbox', value: 'inbox'},
    {text: 'Label', value: 'label'},
    {text: 'External Link', value: 'external-link'},
    {text: 'File', value: 'file'},
    {text: 'Star', value: 'star'},
    {text: 'Bell', value: 'bell'},
    {text: 'Home', value: 'home'},
    {text: 'Link', value: 'link'},
    {text: 'Mail', value: 'mail'},
    {text: 'Image', value: 'image'},
    {text: 'Pencil', value: 'pencil'},
    {text: 'Grid', value: 'grid'},
    {text: 'Calendar', value: 'calendar'},
    {text: 'Video', value: 'video'},
    {text: 'Fire', value: 'fire'},
    {text: 'Comments', value: 'comments'},
    {text: 'Post', value: 'post'},
    {text: 'Child', value: 'child'},
    {text: 'Hashtag', value: 'hashtag'},
    {text: 'Map Marker', value: 'map-marker'},
    {text: 'Shopping Cart', value: 'shopping-cart'},
    {text: 'Heart', value: 'heart'},
    {text: 'Book', value: 'book'},
    {text: 'Copyright', value: 'copyright'},
];

export const FORMATTER_OPTIONS = [
    {text: 'None (eg 12345)', value: 'none'},
    {text: 'Number (eg 12,345)', value: 'number'},
    {text: 'Decimal (eg 12,345.00)', value: 'numberWithDecimals'},
    {text: 'Dollar (eg $12,345)', value: 'dollar'},
    {text: 'Dollar and Cents (eg $12,345.00)', value: 'dollarAndCents'},
    {text: 'Dollar, Thousands (eg $12k)', value: 'dollarThousands'},
    {text: 'Thousands (eg 12k)', value: 'thousands'},
    {text: 'Percent (eg 12.3%)', value: 'percent'},
    {text: 'Multiple (eg 12.3x)', value: 'multiple'},

];

export const ACTIVATION_STATS_OPTIONS = [
    {text: 'Total Engagements', value: 'total_engagements'},
    {text: 'Total Reach', value: 'total_reach'},
    {text: 'Earned Media Value', value: 'emv'},
    {text: 'Return on Investment', value: 'roi'},
    {text: 'Cost per Engagement', value: 'cpe'},
    {text: 'Cost per Reach (CPM-R)', value: 'cpmr'},
    {text: 'Engagement Percent', value: 'overall_engagement_pct'},
    {text: 'Social Engagement Percent', value: 'social_engagement_pct'},
    {text: 'Cost', value: 'cost'},
    {text: 'Engagements Media Value', value: 'emv_per_engagement'},
    {text: 'Impressions Media Value', value: 'emv_per_impression'},
    {text: 'Content Media Value', value: 'emv_per_post'},
    {value: 'blog_reach', text: 'Blog Reach'},
    {value: 'facebook_reach', text: 'Facebook Reach'},
    {value: 'facebook_page_reach', text: 'FB Page Reach'},
    {value: 'instagram_reach', text: 'Instagram Reach'},
    {value: 'instastory_reach', text: 'Instastory Reach'},
    {value: 'pinterest_reach', text: 'Pinterest Reach'},
    {value: 'twitter_reach', text: 'Twitter Reach'},
    {value: 'youtube_reach', text: 'YouTube Reach'},
    {value: 'blog_comment_count', text: 'Blog Comments'},
    {value: 'blog_pageviews_count', text: 'Blog Pageviews'},
    {value: 'facebook_comment_count', text: 'Facebook Comments'},
    {value: 'facebook_reaction_count', text: 'Facebook Reactions'},
    {value: 'facebook_share_count', text: 'Facebook Shares'},
    {value: 'facebook_page_comment_count', text: 'Facebook Page Comments'},
    {value: 'facebook_page_reaction_count', text: 'Facebook Page Reactions'},
    {value: 'facebook_comment_plugin_count', text: 'Embedded Facebook Comments'},
    {value: 'instagram_comment_count', text: 'Instagram Comments'},
    {value: 'instagram_like_count', text: 'Instagram Likes'},
    {value: 'instastory_engagement_count', text: 'Instastory Engagements'},
    {value: 'instastory_impression_count', text: 'Instastory Impressions'},
    {value: 'pinterest_comment_count', text: 'Pinterest Comments'},
    {value: 'pinterest_save_count', text: 'Pinterest Saves'},
    {value: 'twitter_favorite_count', text: 'Twitter Favorites'},
    {value: 'twitter_retweet_count', text: 'Twitter Retweets'},
    {value: 'twitter_tweet_count', text: 'Twitter Shares'},
    {value: 'youtube_comment_count', text: 'YouTube Comments'},
    {value: 'youtube_dislike_count', text: 'YouTube Dislikes'},
    {value: 'youtube_like_count', text: 'YouTube Likes'},
    {value: 'youtube_view_count', text: 'YouTube Views'},
    {value: 'tiktok_view_count', text: 'TikTok Views'},
    {value: 'tiktok_like_count', text: 'TikTok Likes'},
    {value: 'tiktok_comment_count', text: 'TikTok Comments'},
    {value: 'tiktok_share_count', text: 'TikTok Shares'},
    {value: 'num_accepted_activations', text: 'Accepted Invitations'},
    {value: 'num_declined_activations', text: 'Declined Invitations'},
    {value: 'num_complete_activations', text: 'Completed Activations'},
    {value: 'num_fulfilled_activations', text: 'Fulfilled Activations'},
    {value: 'num_accepted_users', text: 'Accepted Participants'},
    {value: 'num_blog_posts', text: 'Blog Posts'},
    {value: 'num_draft_posts', text: 'Drafts'},
    {value: 'num_facebook_page_posts', text: 'Facebook Page Posts'},
    {value: 'num_facebook_posts', text: 'Facebook Posts'},
    {value: 'num_instagram_posts', text: 'Instagram Posts'},
    {value: 'num_instastory_posts', text: 'Instastory Posts'},
    {value: 'num_pinterest_posts', text: 'Pinterest Posts'},
    {value: 'num_twitter_posts', text: 'Twitter Posts'},
    {value: 'num_youtube_posts', text: 'YouTube Posts'},
    {value: 'num_tiktok_posts', text: 'TikTok Posts'},
    {value: 'other_engagements', text: 'Other Engagements'},
    {value: 'other_impressions', text: 'Other Impressions'},
    {value: 'total_activations', text: 'Total Activations'},
    {value: 'total_images', text: 'Total Images'},
    {value: 'total_impressions', text: 'Total Impressions'},
    {value: 'total_posts', text: 'Total Posts'},
    {value: 'total_social_posts', text: 'Total Social Posts'},
    {value: 'total_social_reach', text: 'Total Social Reach'},
    {value: 'total_users', text: 'Total Users'},
    {value: 'total_videos', text: 'Total Videos'},
    {value: 'verified_reach', text: 'Audience Size'},
];

export const FORMATTERS = {
    none: (value) => value,
    number: (value) => formatNumber(value, 0),
    numberWithDecimals: (value) => formatNumber(value, 2),
    dollar: (value) => formatDollar(value, 0),
    dollarAndCents: (value) => formatDollar(value, 2),
    dollarThousands: (value) => '$' + formatNumberK(value, 0),
    thousands: (value) => formatNumberK(value, 0),
    percent: (value) => formatNumber(value * 100, 1) + '%',
    multiple: (value) => formatNumber(value, 1) + 'x'
};

export const makeHistogram = (spec, source) => {
    let output = [];
    let counts = {};

    for (let item of spec) {
        for (let point of source) {

            for (let field of item.fields) {
                const value = (point[field] || item.defaultValue || 'N/A');
                const title = item.title.replace(':value:', value);
                if (typeof counts[title] === 'undefined') {
                    counts[title] = 0;
                }
                counts[title]++;
            }
        }
    }

    for (let key in counts) {
        const value = counts[key];
        output.push({
            name: key,
            value: value
        });
    }

    output = output.sort((a, b) => a.name.localeCompare(b.name));
    return output;

};

export const objectToChartData = (spec, source = {}) => {

    let output = [];

    if (spec.length === 0) {
        spec = Object.keys(source).map(key => ({
            title: key,
            fields: [key]
        }));
    }

    for (let item of spec) {
        let total = 0;

        if (item.fields) {
            for (let field of item.fields) {
                const value = parseFloat(source[field] || 0);
                total += value;
            }
        } else if (item.multiply) {
            total = 1;
            for (let field of item.multiply) {
                const value = parseFloat(source[field] || 0);
                total *= value;
            }
        }

        output.push({
            name: item.title,
            value: total
        });

    }
    return output;
};

export const latestTimeseriesEntry = (source) => {
    const stats = source || [];
    if (stats.length === 0) return null;
    return stats[source.length - 1];
};

export const axisConverter = (spec) => {
    let output = [];
    for (let item of (spec || [])) {
        let thisItem = {...item};
        if (typeof thisItem.tickFormatter === 'string') {
            thisItem.tickFormatter = FORMATTERS[thisItem.tickFormatter];
        }
        output.push(thisItem);
    }
    return output;
};

export const timeseriesSettingsConverter = (spec, source) => {
    let settings = [];

    const defaults = {
        chartType: 'area',
        type: 'linear',
    };

    const latestNative = latestTimeseriesEntry(source) || {};
    const latestConverted = latestTimeseriesEntry(timeseriesDataConverter(spec, source)) || {};

    for (let item of spec) {

        let thisSetting = {
            ...defaults,
            ...item,
            dataKey: item.key,
        };

        if (typeof item.title === 'string') {
            item.title = {format: item.title};
        }

        if (typeof item.title === 'object') {
            const format = item.title.format || ':value:';
            const formatter = FORMATTERS[item.title.formatter || 'number'];
            const value = latestNative[item.title.field || item.key] || latestConverted[item.title.field || item.key];
            const formattedValue = formatter(value);
            thisSetting.name = format.replace(':value:', formattedValue);
        }

        if (typeof item.formatter === 'string') {
            thisSetting.formatter = FORMATTERS[item.formatter];
        }

        delete thisSetting['key'];
        delete thisSetting['fields'];
        delete thisSetting['title'];


        settings.push(thisSetting);

    }

    return settings;

};

export const timeseriesDataConverter = (spec, source) => {
    let series = [];

    if (!source) {
        return series;
    }

    for (let point of source) {
        let newpoint = {
            created_at: point.created_at,
            updated_at: point.updated_at,
        };

        for (let item of spec) {

            let total = 0;

            if (item.fields) {
                for (let field of item.fields) {
                    const value = parseFloat(point[field] || 0);
                    total += value;
                }
            } else if (item.multiply) {
                total = 1;
                for (let field of item.multiply) {
                    const value = parseFloat(point[field] || 0);
                    total *= value;
                }

            }

            newpoint[item.key] = total;
        }

        series.push(newpoint);

    }

    return series;
};

export const timeseriesToDonutData = (spec, source) => {
    const data = latestTimeseriesEntry(source);

    if (!data) {
        return null;
    }

    let output = [];

    for (let item of spec) {
        let total = 0;

        if (item.fields) {
            for (let field of item.fields) {
                const value = parseFloat(data[field] || 0);
                total += value;
            }
        } else if (item.multiply) {
            total = 1;
            for (let field of item.multiply) {
                const value = parseFloat(data[field] || 0);
                total *= value;
            }

        }

        if (total > 0) {
            output.push({
                name: item.title,
                value: total
            });
        }
    }

    return output;

};

export const CAMPAIGN_CHART_SPECS = {
    'audience-categories': {
        meta: {title: "Audience Content Category", icon: "users", group: "Audience"},
        type: 'audience-category',
        title: 'Content Category',
        data: {
            source: 'audience.distribution.category',
            type: 'object',
        }
    },
    'audience-interests': {
        meta: {title: "Audience Interests", icon: "users", group: "Audience"},
        type: 'audience-category',
        title: 'Top Interests',
        data: {
            source: 'audience.distribution.top_interests',
            type: 'object',
        }
    },
    'audience-mentions': {
        meta: {title: "Audience Mentions", icon: "users", group: "Audience"},
        type: 'audience-mentions',
        title: "Mentions Explorer",
        data: {
            source: 'audience.distribution',
            type: 'object'
        }
    },
    'tier-donut': {
        meta: {title: "Participant Tier", icon: "pie-chart", group: "Creators"},
        type: 'chart',
        title: 'Participant Tier',
        icon: 'pie-chart',
        data: {
            source: 'participating_users',
            type: 'histogram',
        },
        chart: {
            type: 'donut',
            title: "Participant Tier",
            labelPercent: true,
            definition: [
                {title: ":value:", fields: ['tier'], defaultValue: 'Unspecified'}
            ]
        }
    },
    'gender-donut': {
        meta: {title: "Participant Gender", icon: "pie-chart", group: "Creators"},
        type: 'chart',
        title: 'Participant Gender',
        icon: 'gender',
        data: {
            source: 'participating_users',
            type: 'histogram',
        },
        chart: {
            type: 'donut',
            title: "Participant Gender",
            labelPercent: true,
            definition: [
                {title: ":value:", fields: ['gender'], defaultValue: 'Unspecified'}
            ]
        }
    },
    'posts-per-creator': {
        meta: {title: "Posts per Participant", icon: "pie-chart", group: "Creators"},
        type: 'chart',
        title: 'Posts per Creator',
        icon: 'pie-chart',
        data: {
            source: 'activation_stats',
            type: 'histogram',
        },
        chart: {
            type: 'donut',
            title: "Posts per Creator",
            labelPercent: true,
            definition: [
                {title: ":value: Posts", fields: ['total_posts']}
            ]
        }
    },
    'content-timeseries': {
        meta: {title: "Content over Time", icon: "line-chart", group: "Content"},
        type: 'chart',
        title: "Content Over Time",
        icon: 'post',
        data: {
            source: 'timeseries',
            type: 'timeseries',
        },
        badge: {
            field: 'total_posts',
            format: ":value: posts",
            formatter: 'number',
        },
        chart: {
            type: 'timeseries',
            title: 'Content Over Time',
            axis: [
                {
                    axisType: 'x',
                    dataKey: 'created_at',
                    stroke: '#888',
                    interval: 0
                },
                {
                    axisType: 'y',
                    yAxisId: 'left',
                    stroke: '#888',
                    tickFormatter: "number"
                },
            ],
            definition: [
                {
                    title: 'Posts',
                    key: 'total_posts',
                    fields: ['total_posts'],
                    chartType: 'line',
                    stroke: '#206601',
                    strokeWidth: 2,
                },
                {
                    title: 'Images',
                    key: 'total_images',
                    fields: ['total_images'],
                    chartType: 'area',
                    fill: '#DDDDDD',
                    stroke: '#DDDDDD'
                }

            ]
        }
    },
    'avg-payout-by-tier': {
        meta: {title: "Avg Payouts", icon: "line-chart", group: "ROI"},
        type: 'chart',
        title: "Avg Payouts by Tier",
        icon: 'dollar-sign',
        data: {
            source: "by_tier.avg_payment",
            type: "object"
        },
        chart: {
            title: "Avg Payouts By Tier",
            type: "bar",
            valueFormatter: "dollar",
            tickFormatter: "dollar",
            definition: [
                {title: "Up-and-Comer", fields: ['Up-and-Comer']},
                {title: "Prosumer", fields: ['Prosumer']},
                {title: "Maven", fields: ['Maven']},
                {title: "Celeb", fields: ['Celeb']},
            ]
        }
    },
    'total-payout-by-tier': {
        meta: {title: "Total Payouts", icon: "line-chart", group: "ROI"},
        type: 'chart',
        title: "Total Payouts by Tier",
        icon: 'dollar-sign',
        data: {
            source: "by_tier.total_payment",
            type: "object"
        },
        chart: {
            title: "Total Payouts By Tier",
            type: "bar",
            valueFormatter: "dollar",
            tickFormatter: "dollar",
            definition: [
                {title: "Up-and-Comer", fields: ['Up-and-Comer']},
                {title: "Prosumer", fields: ['Prosumer']},
                {title: "Maven", fields: ['Maven']},
                {title: "Celeb", fields: ['Celeb']},
            ]
        }
    },
    'eng-pct-by-tier': {
        meta: {title: "Engagement Pct by Tier", icon: "line-chart", group: "ROI"},
        type: 'chart',
        title: "Engagement % By Tier",
        icon: 'line-chart',
        data: {
            source: "by_tier.avg_engagement_pct",
            type: "object"
        },
        chart: {
            title: "Engagement % By Tier",
            type: "bar",
            valueFormatter: "percent",
            tickFormatter: "percent",
            definition: [
                {title: "Up-and-Comer", fields: ['Up-and-Comer']},
                {title: "Prosumer", fields: ['Prosumer']},
                {title: "Maven", fields: ['Maven']},
                {title: "Celeb", fields: ['Celeb']},
            ]
        }
    },
    'eng-by-tier': {
        meta: {title: "Engagements by Tier", icon: "line-chart", group: "ROI"},
        type: 'chart',
        title: "Engagements By Tier",
        icon: 'line-chart',
        data: {
            source: "by_tier.total_engagements",
            type: "object"
        },
        chart: {
            title: "Engagements By Tier",
            type: "bar",
            valueFormatter: "number",
            tickFormatter: "number",
            definition: [
                {title: "Up-and-Comer", fields: ['Up-and-Comer']},
                {title: "Prosumer", fields: ['Prosumer']},
                {title: "Maven", fields: ['Maven']},
                {title: "Celeb", fields: ['Celeb']},
            ]
        }
    },
    'avg-eng-by-tier': {
        meta: {title: "Avg Engagements by Tier", icon: "line-chart", group: "ROI"},
        type: 'chart',
        title: "Avg Engagements By Tier",
        icon: 'line-chart',
        data: {
            source: "by_tier.avg_engagements",
            type: "object"
        },
        chart: {
            title: "Avg Engagements By Tier",
            type: "bar",
            valueFormatter: "number",
            tickFormatter: "number",
            definition: [
                {title: "Up-and-Comer", fields: ['Up-and-Comer']},
                {title: "Prosumer", fields: ['Prosumer']},
                {title: "Maven", fields: ['Maven']},
                {title: "Celeb", fields: ['Celeb']},
            ]
        }
    },
    'roi-timeseries': {
        meta: {title: "Roi Over Time", icon: "line-chart", group: "ROI"},
        type: "chart",
        title: "Cost and Return over Time",
        icon: "dollar-sign",
        data: {
            source: "timeseries",
            type: "timeseries",
        },
        badge: {
            field: "roi",
            formatter: "multiple",
            format: ":value: return"
        },
        chart: {
            type: "timeseries",
            axis: [
                {
                    axisType: 'x',
                    dataKey: 'created_at',
                    stroke: '#888',
                    interval: 0
                },
                {
                    axisType: 'y',
                    yAxisId: 'left',
                    stroke: '#888',
                    tickFormatter: "multiple"
                },
                {
                    axisType: 'y',
                    yAxisId: 'right',
                    orientation: 'right',
                    stroke: '#888',
                    tickFormatter: "dollarThousands"
                }
            ],

            definition: [
                {
                    key: 'emv',
                    fields: ['emv'],
                    title: "Value",
                    fillOpacity: 0.8,
                    yAxisId: 'right',
                    stroke: '#DDDDDD',
                    fill: '#DDDDDD',
                    formatter: "dollarThousands",
                    chartType: "area"
                },
                {
                    key: 'cost',
                    fields: ['cost'],
                    title: "Cost",
                    fillOpacity: 0.8,
                    yAxisId: 'right',
                    stroke: '#BBBBBB',
                    fill: '#BBBBBB',
                    formatter: "dollarThousands",
                    chartType: "area"
                },
                {
                    key: 'roi',
                    fields: ['roi'],
                    title: "Return",
                    stroke: '#206601',
                    strokeWidth: 2,
                    formatter: "multiple",
                    chartType: "line"
                },

            ]
        }
    },
    'emv-donut' : {
        meta: {title: "Earned Media Value", icon: "pie-chart", group: "ROI"},
        type: "chart",
        title: "Media Value",
        icon: "dollar-sign",
        data: {
            source: "timeseries",
            type: "timeseries",
        },
        badge: {
            field: "emv",
            formatter: "dollar",
            format: ":value:"
        },
        chart: {
            type: "donut",
            title: "Earned Media Value",
            labelFormatter: 'dollar',
            tooltipFormatter: 'dollar',
            definition: [
                {title: "Engagements", fields: ['emv_per_engagement']},
                {title: "Impressions", fields: ['emv_per_impression']},
                {title: "Content Value", fields: ['emv_per_post']},
            ]
        }
    },
    'post-types-donut': {
        meta: {title: "Post Types", icon: "pie-chart", group: "Content"},
        type: 'chart',
        title: "Post Types",
        icon: "pie-chart",
        data: {
            source: "timeseries",
            type: "timeseries",
        },
        badge: {
            field: "total_posts",
            formatter: "number",
            format: ":value: posts"
        },
        chart: {
            type: "donut",
            title: "Post Types",
            labelPercent: true,
            definition: [
                {title: "Blog", fields: ['num_blog_posts']},
                {title: "Instagram", fields: ['num_instagram_posts']},
                {title: "Instastory", fields: ['num_instastory_posts']},
                {title: "Twitter", fields: ['num_twitter_posts']},
                {title: "Pinterest", fields: ['num_pinterest_posts']},
                {title: "Facebook", fields: ['num_facebook_posts']},
                {title: "Facebook Page", fields: ['num_facebook_page_posts']},
                {title: "YouTube", fields: ['num_youtube_posts']},
                {title: "TikTok", fields: ['num_tiktok_posts']},
            ]
        }
    },
    'reach-donut': {
        meta: {title: "Social Reach", icon: "pie-chart", group: "Audience"},
        type: 'chart',
        title: "Social Reach",
        icon: "bullhorn",
        data: {
            source: "timeseries",
            type: "timeseries",
        },
        badge: {
            field: "total_reach",
            formatter: "thousands",
            format: ":value: Reach"
        },
        chart: {
            type: "donut",
            title: "Social Reach",
            labelFormatter: 'thousands',
            definition: [
                {fields: ['blog_reach'], title: 'Blog Uniques'},
                {fields: ['facebook_reach'], title: 'Facebook'},
                {fields: ['facebook_page_reach'], title: 'FB Page'},
                {fields: ['instagram_reach'], title: 'Instagram'},
                {fields: ['instastory_reach'], title: 'Instastory'},
                {fields: ['pinterest_reach'], title: 'Pinterest'},
                {fields: ['twitter_reach'], title: 'Twitter'},
                {fields: ['youtube_reach'], title: 'YouTube'},
                {fields: ['tiktok_reach'], title: 'TikTok'},
            ]
        }
    },
    'eng-by-network-donut': {
        meta: {title: "Engagements by Channel", icon: "pie-chart", group: "Engagements"},
        type: 'chart',
        title: "Engs by Network",
        icon: "bullhorn",
        data: {
            source: "timeseries",
            type: "timeseries",
        },
        badge: {
            field: "total_engagements",
            formatter: "thousands",
            format: ":value: Engagements"
        },
        chart: {
            type: "donut",
            title: "Engs by Network",
            labelFormatter: 'thousands',
            definition: [
                {title: "Instagram", fields: ['instagram_comment_count', 'instagram_like_count']},
                {title: "Instastory", fields: ['instastory_engagement_count']},
                {title: "Twitter", fields: ['twitter_tweet_count', 'twitter_retweet_count', 'twitter_favorite_count']},
                {title: "Facebook", fields: ['facebook_reaction_count', 'facebook_share_count', 'facebook_comment_count', 'facebook_comment_plugin_count']},
                {title: "FB Page", fields: ['facebook_page_reaction_count', 'facebook_page_share_count', 'facebook_page_comment_count']},
                {title: "Pinterest", fields: ['pinterest_comment_count', 'pinterest_save_count']},
                {title: "Blog", fields: ['blog_pageviews_count', 'other_engagements']},
                {title: "YouTube", fields: ['youtube_like_count', 'youtube_dislike_count', 'youtube_comment_count', 'youtube_view_count']},
                {title: "TikTok", fields: ['tiktok_like_count', 'tiktok_comment_count', 'tiktok_share_count']},
            ]
        }
    },
    'engagements-timeseries': {
        meta: {title: "Engagements Over Time", icon: "line-chart", group: "Engagements"},
        type: 'chart',
        title: "Engagements Over Time",
        icon: "bullhorn",
        data: {
            source: "timeseries",
            type: "timeseries",
        },
        badge: {
            field: "total_engagements",
            formatter: "thousands"
        },
        chart: {
            type: "timeseries",
            title: "Engagements over Time",
            stacked: true,
            axis: [
                {
                    axisType: 'x',
                    dataKey: 'created_at',
                    stroke: '#888',
                    interval: 0
                },
                {
                    axisType: 'y',
                    yAxisId: 'left',
                    stroke: '#888',
                    tickFormatter: "thousands"
                }
            ],
            definition: [
                {
                    key: 'facebook_engagements',
                    fields: ['facebook_comment_count', 'facebook_comment_plugin_count', 'facebook_reaction_count', 'facebook_share_count'],
                    title: "Facebook (total :value:)",
                    stroke: '#B9ED91',
                    fill: '#B9ED91',
                    fillOpacity: 0.8,
                    stackId: 1
                },
                {
                    key: 'facebook_page_engagements',
                    fields: ['facebook_page_comment_count', 'facebook_page_share_count', 'facebook_page_reaction_count'],
                    title: "Facebook Page (total :value:)",
                    stroke: '#B9ED91',
                    fill: '#B9ED91',
                    fillOpacity: 0.8,
                    stackId: 1
                },
                {
                    key: 'blog_engagements',
                    fields: ['blog_comment_count', 'other_engagements', 'tidal_vote_count'],
                    title: {format: "Blog (:value:)", formatter: "thousands"},
                    stroke: '#206601',
                    fill: '#206601',
                    fillOpacity: 0.8,
                    stackId: 1

                },
                {
                    key: 'instagram_engagements',
                    fields: ['instagram_comment_count', 'instagram_like_count'],
                    title: "Instagram (:value:)",
                    stroke: '#257900',
                    fill: '#257900',
                    fillOpacity: 0.8,
                    stackId: 1
                },
                {
                    key: 'instastory_engagements',
                    fields: ['instastory_engagement_count'],
                    title: "Instastory (:value:)",
                    stroke: '#257900',
                    fill: '#257900',
                    fillOpacity: 0.8,
                    stackId: 1
                },
                {
                    key: 'pinterest_engagements',
                    fields: ['pinterest_comment_count', 'pinterest_like_count', 'pinterest_repin_count'],
                    title: "Pinterest (:value:)",
                    stroke: '#A6E874',
                    fill: '#A6E874',
                    fillOpacity: 0.8,
                    stackId: 1
                },
                {
                    key: 'twitter_engagements',
                    fields: ['twitter_favorite_count', 'twitter_retweet_count', 'twitter_tweet_count'],
                    title: "Twitter (:value:)",
                    stroke: '#469A01',
                    fill: '#469A01',
                    fillOpacity: 0.8,
                    stackId: 1

                },
                {
                    key: 'youtube_engagements',
                    fields: ['youtube_comment_count', 'youtube_dislike_count', 'youtube_like_count'],
                    title: "YouTube (:value:)",
                    stroke: '#95E15B',
                    fill: '#95E15B',
                    fillOpacity: 0.8,
                    stackId: 1

                },
                {
                    key: 'tiktok_engagements',
                    fields: ['tiktok_comment_count', 'tiktok_share_count', 'tiktok_like_count'],
                    title: "TikTok (:value:)",
                    stroke: '#81ce46',
                    fill: '#81ce46',
                    fillOpacity: 0.8,
                    stackId: 1

                },
                {
                    key: 'total_other_engagements' ,
                    fields: ['tidal_vote_count'],
                    title: "Other (:value:)",
                    stroke: '#58B305',
                    fill: '#58B305',
                    fillOpacity: 0.8,
                    stackId: 1

                },
            ]
        }
    },
    'eng-pct-timeseries': {
        meta: {title: "Eng Pct Over Time", icon: "line-chart", group: "Engagements"},
        type: 'chart',
        title: "Captured Audience Over Time",
        icon: "bullhorn",
        style: {width: '100%'},
        data: {
            source: "timeseries",
            type: "timeseries",
        },
        badge: {
            field: "overall_engagement_pct",
            formatter: "percent",
            format: ":value: Engagement"
        },
        chart: {
            type: "timeseries",
            title: "Engagements over Time",
            stacked: false,
            axis: [
                {
                    axisType: 'x',
                    dataKey: 'created_at',
                    stroke: '#888',
                    interval: 0
                },
                {
                    axisType: 'y',
                    yAxisId: 'left',
                    stroke: '#888',
                    tickFormatter: "percent"
                },
                {
                    axisType: 'y',
                    yAxisId: 'right',
                    stroke: '#888',
                    tickFormatter: 'thousands',
                    orientation: 'right',
                }
            ],
            definition: [
                {
                    key: 'total_reach',
                    fields: ['total_reach'],
                    title: "Reach",
                    stroke: '#DDDDDD',
                    fill: '#DDDDDD',
                    fillOpacity: 0.8,
                    yAxisId: 'right',
                    chartType: 'area',
                },
                {
                    key: 'total_engagements',
                    fields: ['total_engagements'],
                    title: "Engagements",
                    stroke: '#AAAAAA',
                    fill: '#AAAAAA',
                    fillOpacity: 0.8,
                    yAxisId: 'right',
                    chartType: 'area',
                },
                {
                    key: 'overall_engagement_pct',
                    fields: ['overall_engagement_pct'],
                    title: "Engagement Percent",
                    stroke: '#206601',
                    strokeWidth: 2,
                    yAxisId: 'left',
                    chartType: 'line',
                    formatter: 'percent'
                },
            ]
        }

    },
    'audience-gender': {
        meta: {title: "Audience Gender", icon: "pie-chart", group: "Audience"},
        type: 'chart',
        title: 'Audience Gender',
        icon: 'gender',
        data: {source: 'audience.distribution.gender', type: 'object'},
        chart: {
            type: 'donut',
            title: 'Audience Gender',
            labelPercent: true,
        }
    },
    'audience-relationship': {
        meta: {title: "Audience Relationship Status", icon: "pie-chart", group: "Audience"},
        type: 'chart',
        title: 'Relationship Status',
        icon: 'heart',
        data: {source: 'audience.distribution.relationship', type: 'object'},
        chart: {
            type: 'donut',
            title: 'Relationship Status',
            labelPercent: true,
        }
    },
    'audience-parenthood': {
        meta: {title: "Audience Parenthood Status", icon: "pie-chart", group: "Audience"},
        type: 'chart',
        title: 'Parenthood Status',
        icon: 'child',
        data: {source: 'audience.distribution.parenthood', type: 'object'},
        chart: {
            type: 'donut',
            title: 'Parenthood Status',
            labelPercent: true,
        }
    },
    'audience-race': {
        meta: {title: "Audience Race", icon: "pie-chart", group: "Audience"},
        type: 'chart',
        title: 'Audience Race',
        icon: 'user',
        data: {source: 'audience.distribution.race', type: 'object'},
        chart: {
            type: 'donut',
            title: 'Audience Race',
            labelPercent: true,
        }
    },
    'audience-hhi': {
        meta: {title: "Audience Household Income", icon: "line-chart", group: "Audience"},
        type: 'chart',
        title: 'Household Income',
        icon: 'user',
        data: {source: 'audience.distribution.hhi', type: 'object'},
        chart: {
            type: 'bar',
            title: 'Household Income',
            tickFormatter: 'percent',
            valueFormatter: 'percent',
            definition: [
                {title: '$0 - $25k', fields: ['$0 - $25k']},
                {title: '$25k - $50k', fields: ['$25k - $50k']},
                {title: '$50k - $75k', fields: ['$50k - $75k']},
                {title: '$75k - $100k', fields: ['$75k - $100k']},
                {title: '$100k - $150k', fields: ['$100k - $150k']},
                {title: '$150k - $200k', fields: ['$150k - $200k']},
                {title: '$200k+', fields: ['$200k+']},
            ]
        }
    },
    'audience-age': {
        meta: {title: "Audience Age", icon: "pie-chart", group: "Audience"},
        type: 'chart',
        title: 'Audience Age',
        icon: 'users',
        data: {source: 'audience.distribution.age', type: 'object'},
        chart: {
            type: 'bar',
            title: 'Audience Age',
            tickFormatter: 'percent',
            valueFormatter: 'percent',
            definition: [
                {title: '13-17', fields: ['13-17']},
                {title: '18-21', fields: ['18-21']},
                {title: '22-25', fields: ['22-25']},
                {title: '26-29', fields: ['26-29']},
                {title: '30-39', fields: ['30-39']},
                {title: '40-60', fields: ['40-60']},
                {title: '60+', fields: ['60+']},
            ]
        }
    },
    'audience-authenticity': {
        meta: {title: "Audience Authenticity", icon: "pie-chart", group: "Audience"},
        type: 'chart',
        title: 'Audience Authenticity',
        icon: 'users',
        data: {source: 'audience.distribution.is_authentic', type: 'object'},
        chart: {
            type: 'donut',
            title: 'Audience Authenticity',
            labelPercent: true,
            definition: [
                {title: 'Authentic', fields: ['Authentic']},
                {title: 'Not Authentic', fields: ['Not Authentic']},
            ]
        }
    },
    'heading': {
        meta: {title: "Heading", icon: "pencil", group: "General"},
        type: "heading",
        title: "Section Heading",
    },
    'posts-board': {
        meta: {title: 'Content Board', icon: 'post', group: 'Content'},
        type: 'posts',
        data: {source: 'posts', type: 'array'},
        style: {width: '100%'},
        sort: {by: 'post.engagements', dir: 'desc'},
        limit: 10
    },
    'creator-engagements-ranking': {
        meta: {title: 'Engagements per Creator', icon: 'users', group: 'Creators'},
        type: 'activation-stat-bars',
        title: "Creator Engagements",
        icon: "dollar-sign",
        data: {
            source: 'activation_stats',
            type: 'array',
        },
        value: {
            field: 'total_engagements',
            formatter: 'number'
        }
    },
    'creator-roi-ranking': {
        meta: {title: 'ROI per Creator', icon: 'users', group: 'Creators'},
        type: 'activation-stat-bars',
        title: "Creator ROI",
        icon: "dollar-sign",
        data: {
            source: 'activation_stats',
            type: 'array',
        },
        value: {
            field: 'roi',
            formatter: 'multiple'
        }
    },
    'creator-emv-ranking': {
        meta: {title: 'EMV per Creator', icon: 'users', group: 'Creators'},
        type: 'activation-stat-bars',
        title: "Creator EMV",
        icon: "dollar-sign",
        data: {
            source: 'activation_stats',
            type: 'array',
        },
        value: {
            field: 'emv',
            formatter: 'dollar'
        }
    },
    'creator-eng-pct-ranking': {
        meta: {title: 'Engagement % per Creator', icon: 'users', group: 'Creators'},
        type: 'activation-stat-bars',
        title: "Creator Engagement %",
        icon: "bullhorn",
        data: {
            source: 'activation_stats',
            type: 'array',
        },
        value: {
            field: 'overall_engagement_pct',
            formatter: 'percent'
        }
    },

    'campaign-overview': {
        meta: {title: 'Campaign Overview', icon: 'star', group: 'General'},
        type: 'campaign-overview',
        definition: [
            {field: 'total_engagements', label: 'Engagements'},
            {field: 'total_reach', label: 'Reach'},
            {field: 'total_posts', label: 'Posts'},
            {field: 'cost', label: 'Total Cost', formatter: 'dollar'},
            {field: 'emv', label: 'Earned Media Value', formatter: 'dollar'},
            {field: 'cpe', label: 'CPE', formatter: 'dollarAndCents'},
            {field: 'total_images', label: 'Images', formatter: 'number'},
            {field: 'num_accepted_users', label: 'Participants', formatter: 'number'},
            {field: 'roi', label: 'ROI', formatter: 'multiple'},
            {field: 'overall_engagement_pct', label: 'Engagement Percent', formatter: 'percent'},
            {field: 'instagram_save_rate', label: 'Instagram Save Rate', formatter: 'percent', enabledBySetting: 'share_report_vars.show_save_rate'},
            {field: 'instastory_link_clicks_rate', label: 'Instastory CTR', formatter: 'percent', enabledBySetting: 'share_report_vars.show_ctr'},
            {field: 'instastory_share_rate', label: 'Instastory Share Rate', formatter: 'percent', enabledBySetting: 'share_report_vars.show_share_rate'},
        ]
    },
    'activation-cards': {
        meta: {title: 'Participating Users Board', icon: 'users', group: 'Creators'},
        type: 'activation-cards',
        data: {source: 'participating_users', type: 'object'},
        style: {width: '100%'},
        limit: 10,
        sort: {by: 'stats.total_engagements', dir: 'desc'}
    },
    'activation-stats-table': {
        meta: {title: 'Activation Stats Table', icon: 'grid', group: 'Creators'},
        type: 'activation-stats-table',
        title: 'Engagement Stats by Participant',
        style: {width: '100%'}
    },
    'executive-summary': {
        meta: {title: 'Executive Summary', icon: 'grid', group: 'ITB Widgets'},
        type: 'executive-summary',
        title: 'Executive Summary',
        style: {width: '100%'}
    },
    'performance-overview': {
        meta: {title: 'Performance Overview', icon: 'grid', group: 'ITB Widgets'},
        type: 'graphical-overview',
        title: 'Performance Overview',
        style: {width: '100%'}
    },
    'performance-kpis': {
        meta: {title: 'Performance KPIs', icon: 'grid', group: 'ITB Widgets'},
        type: 'performance-kpis',
        title: 'Performance KPIs',
        style: {width: '100%'}
    },
    'graphical-header': {
        meta: {title: 'Graphical Header', icon: 'grid', group: 'ITB Widgets'},
        type: 'graphical-header',
        title: 'Graphical Header',
        style: {width: '100%'}
    },
    'sortable-posts': {
        meta: {title: 'Top (Sortable) Posts', icon: 'grid', group: 'ITB Widgets'},
        type: 'sortable-posts',
        title: 'Top Posts',
        style: {width: '100%'},
        data: {source: 'posts', type: 'array'},
        sort: {by: 'post.engagements', dir: 'desc'},
    },
    'sortable-users': {
        meta: {title: 'Top (Sortable) Users', icon: 'grid', group: 'ITB Widgets'},
        type: 'sortable-users',
        title: 'Top Users',
        style: {width: '100%'},
        data: {source: 'participating_users', type: 'object'},
        sort: {by: 'stats.total_engagements', dir: 'desc'}
    },
    'audience-breakdown': {
        meta: {title: 'Audience Breakdown', icon: 'users', group: 'ITB Widgets'},
        type: 'audience-breakdown',
        title: 'Audience Breakdown',
        style: {width: '100%'},
        data: {source: 'audience', type: 'object'},
    },
    'roi-breakdown': {
        meta: {title: 'ROI Breakdown', icon: 'dollar-sign', group: 'ITB Widgets'},
        type: 'roi-breakdown',
        title: 'ROI Breakdown',
        style: {width: '100%'},
    },
};

export const LISTENER_CHART_SPECS = {

        'audience-categories': {
            meta: {title: "Audience Content Category", icon: "users", group: "Audience"},
            type: 'audience-category',
            title: 'Content Category',
            data: {
                source: 'audience.distribution.category',
                type: 'object',
            }
        },
        'audience-interests': {
            meta: {title: "Audience Interests", icon: "users", group: "Audience"},
            type: 'audience-category',
            title: 'Top Interests',
            data: {
                source: 'audience.distribution.iqdata_interests',
                type: 'object',
            }
        },
        'audience-mentions': {
            meta: {title: "Audience Mentions", icon: "users", group: "Audience"},
            type: 'audience-mentions',
            title: "Mentions Explorer",
            data: {
                source: 'audience.distribution',
                type: 'object'
            }
        },
        'gender-donut': {
            meta: {title: "Participant Gender", icon: "pie-chart", group: "Creators"},
            type: 'chart',
            title: 'Participant Gender',
            icon: 'gender',
            data: {
                source: 'participating_users',
                type: 'histogram',
            },
            chart: {
                type: 'donut',
                title: "Participant Gender",
                labelPercent: true,
                definition: [
                    {title: ":value:", fields: ['gender'], defaultValue: 'Unspecified'}
                ]
            }
        },
        'content-timeseries': {
            meta: {title: "Content over Time", icon: "line-chart", group: "Content"},
            type: 'chart',
            title: "Content Over Time",
            icon: 'post',
            data: {
                source: 'timeseries',
                type: 'timeseries',
            },
            badge: {
                field: 'total_posts',
                format: ":value: posts",
                formatter: 'number',
            },
            style: {width: '50%'},
            chart: {
                type: 'timeseries',
                title: 'Content Over Time',
                axis: [
                    {
                        axisType: 'x',
                        dataKey: 'created_at',
                        stroke: '#888',
                        interval: 0
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'left',
                        stroke: '#888',
                        tickFormatter: "number"
                    },
                ],
                definition: [
                    {
                        title: 'Posts',
                        key: 'total_posts',
                        fields: ['total_posts'],
                        chartType: 'line',
                        stroke: '#206601',
                        strokeWidth: 2,
                    },
                    {
                        title: 'Images',
                        key: 'total_images',
                        fields: ['total_images'],
                        chartType: 'area',
                        fill: '#DDDDDD',
                        stroke: '#DDDDDD'
                    }

                ]
            }
        },
        'emv-donut' : {
            meta: {title: "Earned Media Value", icon: "pie-chart", group: "ROI"},
            type: "chart",
            title: "Media Value",
            icon: "dollar-sign",
            data: {
                source: "timeseries",
                type: "timeseries",
            },
            badge: {
                field: "emv",
                formatter: "dollar",
                format: ":value:"
            },
            chart: {
                type: "donut",
                title: "Earned Media Value",
                labelFormatter: 'dollar',
                tooltipFormatter: 'dollar',
                definition: [
                    {title: "Engagements", fields: ['emv_per_engagement']},
                    {title: "Impressions", fields: ['emv_per_impression']},
                    {title: "Content Value", fields: ['emv_per_post']},
                ]
            }
        },
        'post-types-donut': {
            meta: {title: "Post Types", icon: "pie-chart", group: "Content"},
            type: 'chart',
            title: "Post Types",
            icon: "pie-chart",
            data: {
                source: "current",
                type: "object",
            },
            badge: {
                field: "total_posts",
                formatter: "number",
                format: ":value: posts"
            },
            chart: {
                type: "donut",
                title: "Post Types",
                labelPercent: true,
                definition: [
                    {title: "Instagram", fields: ['num_instagram_posts']},
                    {title: "TikTok", fields: ['num_tiktok_posts']},
                ]
            }
        },
        'reach-donut': {
            meta: {title: "Social Reach", icon: "pie-chart", group: "Audience"},
            type: 'chart',
            title: "Social Reach",
            icon: "bullhorn",
            data: {
                source: "current",
                type: "object",
            },
            badge: {
                field: "total_reach",
                formatter: "thousands",
                format: ":value: Reach"
            },
            chart: {
                type: "donut",
                title: "Social Reach",
                labelFormatter: 'thousands',
                definition: [
                    {fields: ['instagram_reach'], title: 'Instagram'},
                    {fields: ['tiktok_reach'], title: 'TikTok'},
                ]
            }
        },
        'eng-by-network-donut': {
            meta: {title: "Engagements by Channel", icon: "pie-chart", group: "Engagements"},
            type: 'chart',
            title: "Engs by Network",
            icon: "bullhorn",
            data: {
                source: "current",
                type: "object",
            },
            badge: {
                field: "total_engagements",
                formatter: "thousands",
                format: ":value: Engagements"
            },
            chart: {
                type: "donut",
                title: "Engs by Network",
                labelFormatter: 'thousands',
                definition: [
                    {title: "Instagram", fields: ['instagram_comment_count', 'instagram_like_count']},
                    // {title: "Twitter", fields: ['twitter_tweet_count', 'twitter_retweet_count', 'twitter_favorite_count']},
                    {title: "TikTok", fields: ['tiktok_like_count', 'tiktok_comment_count', 'tiktok_share_count']},
                ]
            }
        },
        'engagements-timeseries': {
            meta: {title: "Engagements Over Time", icon: "line-chart", group: "Engagements"},
            type: 'chart',
            title: "Engagements Over Time",
            icon: "bullhorn",
            style: {width: '50%'},
            data: {
                source: "timeseries",
                type: "timeseries",
            },
            badge: {
                field: "total_engagements",
                formatter: "thousands"
            },
            chart: {
                type: "timeseries",
                title: "Engagements over Time",
                stacked: true,
                axis: [
                    {
                        axisType: 'x',
                        dataKey: 'created_at',
                        stroke: '#888',
                        interval: 0
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'left',
                        stroke: '#888',
                        tickFormatter: "thousands"
                    }
                ],
                definition: [
                    {
                        key: 'instagram_engagements',
                        fields: ['instagram_comment_count', 'instagram_like_count'],
                        title: "Instagram (:value:)",
                        stroke: '#257900',
                        fill: '#257900',
                        fillOpacity: 0.8,
                        stackId: 1
                    },
                    // {
                    //     key: 'twitter_engagements',
                    //     fields: ['twitter_favorite_count', 'twitter_retweet_count', 'twitter_tweet_count'],
                    //     title: "Twitter (:value:)",
                    //     stroke: '#469A01',
                    //     fill: '#469A01',
                    //     fillOpacity: 0.8,
                    //     stackId: 1
                    //
                    // },
                    {
                        key: 'tiktok_engagements',
                        fields: ['tiktok_comment_count', 'tiktok_share_count', 'tiktok_like_count'],
                        title: "TikTok (:value:)",
                        stroke: '#81ce46',
                        fill: '#81ce46',
                        fillOpacity: 0.8,
                        stackId: 1

                    },
                ]
            }
        },
        'eng-pct-timeseries': {
            meta: {title: "Eng Pct Over Time", icon: "line-chart", group: "Engagements"},
            type: 'chart',
            title: "Captured Audience Over Time",
            icon: "bullhorn",
            style: {width: '100%'},
            data: {
                source: "timeseries",
                type: "timeseries",
            },
            badge: {
                field: "overall_engagement_pct",
                formatter: "percent",
                format: ":value: Engagement"
            },
            chart: {
                type: "timeseries",
                title: "Engagements over Time",
                stacked: false,
                axis: [
                    {
                        axisType: 'x',
                        dataKey: 'created_at',
                        stroke: '#888',
                        interval: 0
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'left',
                        stroke: '#888',
                        tickFormatter: "percent"
                    },
                    {
                        axisType: 'y',
                        yAxisId: 'right',
                        stroke: '#888',
                        tickFormatter: 'thousands',
                        orientation: 'right',
                    }
                ],
                definition: [
                    {
                        key: 'total_reach',
                        fields: ['total_reach'],
                        title: "Reach",
                        stroke: '#DDDDDD',
                        fill: '#DDDDDD',
                        fillOpacity: 0.8,
                        yAxisId: 'right',
                        chartType: 'area',
                    },
                    {
                        key: 'total_engagements',
                        fields: ['total_engagements'],
                        title: "Engagements",
                        stroke: '#AAAAAA',
                        fill: '#AAAAAA',
                        fillOpacity: 0.8,
                        yAxisId: 'right',
                        chartType: 'area',
                    },
                    {
                        key: 'overall_engagement_pct',
                        fields: ['overall_engagement_pct'],
                        title: "Engagement Percent",
                        stroke: '#206601',
                        strokeWidth: 2,
                        yAxisId: 'left',
                        chartType: 'line',
                        formatter: 'percent'
                    },
                ]
            }

        },
        'audience-gender': {
            meta: {title: "Audience Gender", icon: "pie-chart", group: "Audience"},
            type: 'chart',
            title: 'Audience Gender',
            icon: 'gender',
            data: {source: 'audience.distribution.gender', type: 'object'},
            chart: {
                type: 'donut',
                title: 'Audience Gender',
                labelPercent: true,
            }
        },
        'audience-relationship': {
            meta: {title: "Audience Relationship Status", icon: "pie-chart", group: "Audience"},
            type: 'chart',
            title: 'Relationship Status',
            icon: 'heart',
            data: {source: 'audience.distribution.relationship', type: 'object'},
            chart: {
                type: 'donut',
                title: 'Relationship Status',
                labelPercent: true,
            }
        },
        'audience-parenthood': {
            meta: {title: "Audience Parenthood Status", icon: "pie-chart", group: "Audience"},
            type: 'chart',
            title: 'Parenthood Status',
            icon: 'child',
            data: {source: 'audience.distribution.parenthood', type: 'object'},
            chart: {
                type: 'donut',
                title: 'Parenthood Status',
                labelPercent: true,
            }
        },
        'audience-race': {
            meta: {title: "Audience Race", icon: "pie-chart", group: "Audience"},
            type: 'chart',
            title: 'Audience Race',
            icon: 'user',
            data: {source: 'audience.distribution.race', type: 'object'},
            chart: {
                type: 'donut',
                title: 'Audience Race',
                labelPercent: true,
            }
        },
        'audience-hhi': {
            meta: {title: "Audience Household Income", icon: "line-chart", group: "Audience"},
            type: 'chart',
            title: 'Household Income',
            icon: 'user',
            data: {source: 'audience.distribution.hhi', type: 'object'},
            chart: {
                type: 'bar',
                title: 'Household Income',
                tickFormatter: 'percent',
                valueFormatter: 'percent',
                definition: [
                    {title: '$0 - $25k', fields: ['$0 - $25k']},
                    {title: '$25k - $50k', fields: ['$25k - $50k']},
                    {title: '$50k - $75k', fields: ['$50k - $75k']},
                    {title: '$75k - $100k', fields: ['$75k - $100k']},
                    {title: '$100k - $150k', fields: ['$100k - $150k']},
                    {title: '$150k - $200k', fields: ['$150k - $200k']},
                    {title: '$200k+', fields: ['$200k+']},
                ]
            }
        },
        'audience-age': {
            meta: {title: "Audience Age", icon: "pie-chart", group: "Audience"},
            type: 'chart',
            title: 'Audience Age',
            icon: 'users',
            data: {source: 'audience.distribution.age', type: 'object'},
            chart: {
                type: 'bar',
                title: 'Audience Age',
                tickFormatter: 'percent',
                valueFormatter: 'percent',
                definition: [
                    {title: '13-17', fields: ['13-17']},
                    {title: '18-24', fields: ['18-24']},
                    {title: '25-34', fields: ['25-34']},
                    {title: '35-44', fields: ['35-44']},
                    {title: '45-64', fields: ['45-64']},
                    {title: '65-', fields: ['65-']},
                ]
            }
        },
        'audience-authenticity': {
            meta: {title: "Audience Authenticity", icon: "pie-chart", group: "Audience"},
            type: 'chart',
            title: 'Audience Authenticity',
            icon: 'users',
            data: {source: 'audience.distribution.is_authentic', type: 'object'},
            chart: {
                type: 'donut',
                title: 'Audience Authenticity',
                labelPercent: true,
                definition: [
                    {title: 'Authentic', fields: ['Authentic']},
                    {title: 'Not Authentic', fields: ['Not Authentic']},
                ]
            }
        },
        'heading': {
            meta: {title: "Heading", icon: "pencil", group: "General"},
            type: "heading",
            title: "Section Heading",
        },

        'listener-overview': {
            meta: {title: 'Campaign Overview', icon: 'star', group: 'General'},
            type: 'listener-overview',
            definition: [
                {field: 'total_engagements', label: 'Engagements'},
                {field: 'total_reach', label: 'Reach'},
                {field: 'total_posts', label: 'Posts'},
                {field: 'total_videos', label: 'Videos'},
                {field: 'overall_engagement_pct', label: 'Engagement Percent', formatter: 'percent'},
            ]
        },
};

export const ELEMENT_OPTIONS_PIECES = {
    title: {
        type: 'text',
        name: 'title',
        title: 'Title'
    },

    titleIcon: {
        type: 'select',
        name: 'icon',
        title: 'Title Icon',
        choices: ICON_OPTIONS
    },

    width: {
        type: 'select',
        name: 'style.width',
        title: 'Width',
        choices: [
            {text: '100%', value: '100%'},
            {text: '66%', value: '66.6667%'},
            {text: '50%', value: '50%'},
            {text: '33%', value: '33.3333%'},
            {text: '25%', value: '25%'},
        ]
    },

    badgeField: {
        type: 'select',
        name: 'badge.field',
        title: 'Badge Field',
        choices: ACTIVATION_STATS_OPTIONS,
    },

    badgeFormatter: {
        type: 'select',
        name: 'badge.formatter',
        title: 'Badge Formatter',
        choices: FORMATTER_OPTIONS,
    },

    badgeFormat: {
        type: 'text',
        name: 'badge.format',
        title: 'Badge Format',
    },

    chartTitle: {
        type: 'text',
        name: 'chart.title',
        title: 'Chart Title',
    },

    chartValueFormatter: {
        type: 'select',
        name: 'chart.valueFormatter',
        title: 'Chart Value Formatter',
        choices: FORMATTER_OPTIONS,
    },

    chartTickFormatter: {
        type: 'select',
        name: 'chart.tickFormatter',
        title: 'Chart Tick Formatter',
        choices: FORMATTER_OPTIONS,
    },

};

export const DEFAULT_REPORT_SPEC = [
    CAMPAIGN_CHART_SPECS['campaign-overview'],
    {type: 'heading', title: 'Most Engaging Participants'},
    {...CAMPAIGN_CHART_SPECS['activation-cards'], style: {width: '100%'}},

    {
        type: 'heading',
        title: 'Stats Overview'
    },
    CAMPAIGN_CHART_SPECS['post-types-donut'],
    CAMPAIGN_CHART_SPECS['reach-donut'],
    CAMPAIGN_CHART_SPECS['tier-donut'],
    CAMPAIGN_CHART_SPECS['eng-pct-timeseries'],

    {
        type: 'heading',
        title: 'Most Engaging Content'
    },
    CAMPAIGN_CHART_SPECS['posts-board'],

    {
        type: 'heading',
        title: 'Return on Investment',
    },
    CAMPAIGN_CHART_SPECS['emv-donut'],
    {...CAMPAIGN_CHART_SPECS['roi-timeseries'], style: {width: '66.6667%'}},
    CAMPAIGN_CHART_SPECS['total-payout-by-tier'],
    CAMPAIGN_CHART_SPECS['avg-payout-by-tier'],
    CAMPAIGN_CHART_SPECS['creator-roi-ranking'],

    {
        type: 'heading',
        title: 'Content and Engagements Stats',
    },
    CAMPAIGN_CHART_SPECS['post-types-donut'],
    {...CAMPAIGN_CHART_SPECS['content-timeseries'], style: {width: '33.3333%'}},
    CAMPAIGN_CHART_SPECS['eng-by-network-donut'],
    {...CAMPAIGN_CHART_SPECS['engagements-timeseries'], style: {width: '66.6667%'}},
    CAMPAIGN_CHART_SPECS['eng-by-tier'],
    CAMPAIGN_CHART_SPECS['avg-eng-by-tier'],
    CAMPAIGN_CHART_SPECS['eng-pct-by-tier'],
    CAMPAIGN_CHART_SPECS['posts-per-creator'],

    {
        type: 'heading',
        title: 'Creators'
    },
    CAMPAIGN_CHART_SPECS['reach-donut'],
    CAMPAIGN_CHART_SPECS['gender-donut'],
    CAMPAIGN_CHART_SPECS['tier-donut'],
    CAMPAIGN_CHART_SPECS['creator-engagements-ranking'],
    CAMPAIGN_CHART_SPECS['creator-eng-pct-ranking'],
    CAMPAIGN_CHART_SPECS['creator-emv-ranking'],
    CAMPAIGN_CHART_SPECS['activation-stats-table'],


    {
        type: 'heading',
        title: 'Audience'
    },
    CAMPAIGN_CHART_SPECS['audience-categories'],
    CAMPAIGN_CHART_SPECS['audience-gender'],
    CAMPAIGN_CHART_SPECS['audience-relationship'],
    {...CAMPAIGN_CHART_SPECS['audience-hhi'], style: {width: "66.6667%"}},
    CAMPAIGN_CHART_SPECS['audience-interests'],
    CAMPAIGN_CHART_SPECS['audience-authenticity'],
    {...CAMPAIGN_CHART_SPECS['audience-age'], style: {width: "66.6667%"}},
    CAMPAIGN_CHART_SPECS['audience-parenthood'],
    CAMPAIGN_CHART_SPECS['audience-race'],
    CAMPAIGN_CHART_SPECS['audience-mentions'],
];

export const MEDIA_KPI_DEFINITIONS = {

    instagram: {
        posts: ['num_instagram_posts'],
        reach: ['instagram_reach'],
        impressions: ['instagram_impression_count'],
        engagements: ['instagram_comment_count', 'instagram_like_count', 'instagram_save_count'],
        followers: ['instagram_followers'],
        title: "Instagram Posts",
        iconClass: "fab fa-instagram",
    },
    instastory: {
        posts: ['num_instastory_posts'],
        reach: ['instastory_reach'],
        impressions: ['instastory_impression_count'],
        engagements: ['instastory_engagement_count'],
        followers: ['instagram_followers'],
        title: "Instagram Stories",
        iconClass: "fab fa-instagram-square",
    },
    tiktok: {
        posts: ['num_tiktok_posts'],
        reach: ['tiktok_reach'],
        impressions: ['tiktok_view_count'],
        engagements: ['tiktok_comment_count', 'tiktok_like_count', 'tiktok_share_count'],
        followers: ['tiktok_followers'],
        title: 'TikTok Videos',
        iconClass: "fab fa-tiktok"
    },
    twitter: {
        posts: ['num_twitter_posts'],
        reach: ['twitter_reach'],
        impressions: [],
        engagements: ['twitter_favorite_count', 'twitter_retweet_count', 'twitter_tweet_count'],
        followers: ['twitter_followers'],
        title: 'Twitter Tweets',
        iconClass: 'fab fa-twitter'
    },
    youtube: {
        posts: ['num_youtube_posts'],
        reach: ['youtube_reach'],
        impressions: ['youtube_view_count'],
        engagements: ['youtube_comment_count', 'youtube_dislike_count', 'youtube_like_count'],
        followers: ['youtube_followers'],
        title: 'YouTube Videos',
        iconClass: 'fab fa-youtube'
    },
    facebook: {
        posts: ['num_facebook_posts', 'num_facebook_page_posts'],
        reach: ['facebook_page_reach'],
        impressions: [],
        engagements: [],
        followers: ['facebook_page_followers'],
        title: 'Facebook Posts',
        iconClass: 'fab fa-facebook'
    },
    pinterest: {
        posts: ['num_pinterest_posts'],
        reach: ['pinterest_reach'],
        impressions: [],
        engagements: ['pinterest_save_count', 'pinterest_comment_count'],
        followers: ['pinterest_followers'],
        title: 'Pinterest Posts',
        iconClass: 'fab fa-pinterest'
    },

};
const calculateKpi = (sourceData, socialType, key) => {
    const spec = MEDIA_KPI_DEFINITIONS[socialType];
    const fields = _get(spec, key, []);
    let sum = 0;

    for (const fieldName of fields) {
        let dataVal = _get(sourceData, fieldName, null);
        if (dataVal !== null) {
            sum += parseInt(dataVal, 10);
        }
    }

    return sum;
};

export const calulateKpis = (sourceData) => {

    let out = {};
    for (const socialKey in MEDIA_KPI_DEFINITIONS) {
        const spec = MEDIA_KPI_DEFINITIONS[socialKey];
        out[socialKey] = {};
        for (const statKey in spec) {
            if (typeof spec[statKey] === 'object') {
                out[socialKey][statKey] = calculateKpi(sourceData, socialKey, statKey);
            }
        }
    }
    return out;

};

/**
 * Total followers relevant to the campaign, ie, for networks that have posts
 * @param sourceData
 */
export const calculateTotalActiveFollowers = (sourceData) => {
    // do instagram separately so you don't double count
    const map = {
        'num_twitter_posts': 'twitter_followers',
        'num_facebook_page_posts': 'facebook_followers',
        'num_pinterest_posts': 'pinterest_followers',
        'num_tiktok_posts': 'tiktok_followers',
        'num_youtube_posts': 'youtube_followers',
    };

    let total = 0;

    for (const postCountKey in map) {
        const followersCountKey = map[postCountKey];
        const postCountValue = parseInt(sourceData[postCountKey] || 0, 10);
        const followersCountValue = parseInt(sourceData[followersCountKey] || 0, 10);

        if (postCountValue > 0) {
            total += followersCountValue;
        }
    }

    // Now handle instagram and instastory specially, to not-double count the audience members
    if (sourceData.num_instagram_posts || sourceData.num_instastory_posts) {
        total += parseInt(sourceData.instagram_followers || 0, 10);
    }

    return total;
}