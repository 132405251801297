import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchAuthenticated} from "../../actions/auth";
import Button from "./Form/Button";
import Toolbox from "./Toolbox";
import {formatNumber} from "../../utilities";
import * as URI from 'urijs';

const DEFAULT_STYLE = {
    display: 'inline-block'
};

const DEFAULT_CLASSES = [
    'quickfilter-btn', 'v3', 'tidal-btn', 'small', 'bold'
];

class GenericQuickFiltersButton extends Component {

    static propTypes = {

        checks: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            filters: PropTypes.object.isRequired,
            /** Set skipCheck to skip checking and displaying results -- useful for 'reset' link */
            skipCheck: PropTypes.bool,
        })).isRequired,
        apiUrl: PropTypes.string.isRequired,

        wrapperStyle: PropTypes.object,
        classes: PropTypes.array,
        content: PropTypes.string,
        /** Filters that get applied to all queries against apiUrl */
        filters: PropTypes.object,
        onClickFilter: PropTypes.func,
        /** This object is passed to each check's isValid callback */
        validatorObject: PropTypes.object,

        fetchAuthenticated: PropTypes.func,
    };

    static defaultProps = {
        wrapperStyle: {},
        classes: [],
        content: "Quick Filters",
        filters: {},
    };

    state = {
        showDropdown: false,
        didRunChecks: false,
        fetching: {},
        hits: {},
    };


    componentDidUpdate(prevProps, prevState) {
        if (this.state.showDropdown && !this.state.didRunChecks) {
            this.runChecks();
        }
    }

    runChecks() {
        if (this.state.didRunChecks) {
            return;
        }

        this.setState({didRunChecks: true});
        this.getItems().forEach(check => this.fetchCheckStats(check));
    }

    handleClickFilter(item) {
        this.setState({showDropdown: false});
        if (this.props.onClickFilter) {
            this.props.onClickFilter(item);
        }
    }

    fetchCheckStats(item) {
        if (item.skipCheck) {
            return;
        }

        this.markItemFetching(item, true);

        const filters = {
            ...this.props.filters,
            ...item.filters
        };

        const payload = {
            filters: JSON.stringify(filters),
            metaOnly: true
        };

        let url = URI(this.props.apiUrl)
            .query(payload)
            .toString();

        return this.props.fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => this.setItemHits(item, json.meta.hits))
            .then(() => this.markItemFetching(item, false));
    }

    setItemHits(item, hits) {
        this.setState((prevState) => {
            return {
                hits: {
                    ...prevState.hits,
                    [item.name]: hits
                }
            }
        });

    }

    markItemFetching(item, value) {
        this.setState((prevState) => {
            return {
                fetching: {
                    ...prevState.fetching,
                    [item.name]: value
                }
            }
        });
    }


    getItems() {
        return this.props.checks.filter(spec => {
            if (typeof spec.isValid === 'undefined' || spec.isValid === true) {
                return true;
            }
            return spec.isValid(this.props.validatorObject)
        });
    }

    isItemFetching(item) {
        return this.state.fetching[item.name] || false;
    }

    getItemHits(item) {
        return this.state.hits[item.name] || 0;
    }

    renderDropdownContent() {
        return this.getItems().map(item => {

            const tagValue = this.isItemFetching(item)
                ? 'Checking...'
                : formatNumber(this.getItemHits(item)) + ' Found';

            const showCheck = !item.skipCheck;

            return (

                <div className="fake-li condensed" key={`quick-filter-item-${item.name}`}>
                    <a
                        role="button"
                        onClick={this.handleClickFilter.bind(this, item)}
                        className="v3 h7 light"
                        style={{fontSize: 14}}
                    >
                        {item.name}
                        {
                            showCheck && <span className="v3 tidal-tag light bg-transparent pull-right">{tagValue}</span>
                        }
                    </a>
                </div>

            );
        })
    }

    renderDropdown() {

        if (!this.state.showDropdown) {
            return null;
        }

        return (
            <Toolbox
                content={this.renderDropdownContent()}
                style={{
                    zIndex: 200,
                    width: 300,
                    minHeight: 'inherit'
                }}
            />
        )

    }

    render() {

        return (
            <div className="quickfilter-btn-wrapper" style={{...DEFAULT_STYLE, ...this.props.wrapperStyle}}>
                <Button
                    content={this.props.content}
                    onClick={() => this.setState({showDropdown: !this.state.showDropdown, didRunChecks: false})}
                    classes={[...DEFAULT_CLASSES, ...this.props.classes]}
                    style={this.props.style}
                />
                {this.state.showDropdown ? this.renderDropdown() : null}
            </div>

        );
    }
}

const mapStateToProps = undefined;
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
    };
};


const QuickFiltersButton = connect(mapStateToProps, mapDispatchToProps)(GenericQuickFiltersButton);
QuickFiltersButton.propTypes = {

    checks: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        filters: PropTypes.object.isRequired,
    })).isRequired,
    apiUrl: PropTypes.string.isRequired,

    wrapperStyle: PropTypes.object,
    classes: PropTypes.array,
    content: PropTypes.string,
    /** Filters that get applied to all queries against apiUrl */
    filters: PropTypes.object,
    onClickFilter: PropTypes.func,
};

export default QuickFiltersButton;
