import React, {useEffect, useMemo, useState} from 'react';
import DashboardBriefs from "../../../MAC/Creator/DashboardBriefs";
import DashboardCommunity from "../../../MAC/Creator/DashboardCommunity";
import ProfileStripe from "../../../MAC/Creator/ProfileStripe";
import {connect} from "react-redux";
import {getBadges} from "../../../../selectors/badges";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../../../actions/auth";
import {fetchPresence} from "../../../../actions/presence";
import {fetchBadges} from "../../../../actions";
import DOMPurify from "dompurify";
import ThreadAttachments from "../../../MAC/Creator/ThreadAttachments";
import ThreadDiscussion from "../../../MAC/Creator/ThreadDiscussion";
import {makeMomentFromDate} from "../../../../utilities";

function MACCreatorThreadPage({threadId, fetchAuthenticated}) {
    const [post, setPost] = useState({});
    const [didLoad, setDidLoad] = useState(false);

    const fetchPost = async () => {
        const resp = await fetchAuthenticated(`/contribute/api/innercircle/_/post/${threadId}`);
        const json = await resp.json();
        setPost(json.data);
        setDidLoad(true);
    }

    useEffect(function( ) {
        fetchPost().then(() => console.log("Done loading Thread page data"))
    }, [threadId]);

    const cleanContent = useMemo(() => {
        const clean = DOMPurify.sanitize(post.content || '', {ADD_TAGS: ['iframe', 'source'], ADD_ATTR: ['controls']});
        return {__html: clean};
    }, [post]);

    const user = (post.user || {});
    const topic = (post.topic || {});
    const createdAtMoment = makeMomentFromDate(post.created_at);
    const createdAt = createdAtMoment ? createdAtMoment.format('MMMM Do YYYY') : '';

    return (
        <div className="mac-wrapper">
            <div className="dashboard">

                <div className="thread-page-wrapper">
                    <h1 className="red-indent">{post.title}</h1>
                    <div className="thread-byline">By {user.name} in #{topic.name} on {createdAt}</div>

                    <div className="thread-content" dangerouslySetInnerHTML={cleanContent} />
                    {didLoad && <ThreadAttachments attachments={post.attachments} post={post} />}
                    {didLoad && <ThreadDiscussion postId={post.id} post={post} />}
                </div>


            </div>
        </div>
    )
}

export default connect(
    function (state, props) {
        return { };
    },
    function (dispatch) {
        return bindActionCreators({
            fetchAuthenticated: fetchAuthenticated,
        }, dispatch);
    }
)(MACCreatorThreadPage);