/**
 * Created by bkanber on 7/28/17.
 */

import {connect} from 'react-redux';
import {
    fetchCampaignsIfNeeded,
    fetchCampaignStatsIfNeeded,
    fetchCampaignActivationsIfNeeded,
    fetchCampaignUsersIfNeeded,
    fetchCampaignAudienceIfNeeded,
    fetchCampaignActivationStatsIfNeeded,
    fetchCampaignAffinityIfNeeded,
    fetchCampaignReportIfNeeded,
    updateCampaignEMVParams, fetchCampaign
} from '../../actions';
import CampaignReport from '../../components/Campaign/Report'
import {fetchCampaignGeographyIfNeeded} from "../../actions/campaigns";


const mapStateToProps = (state, ownProps) => {

    const campaignId = ownProps.campaignId;

    const DEFAULT_CAMPAIGN = {
        id: campaignId,
        activationIds: [],
        userIds: [],
        audience: [],
        affinity: {}
    };

    const campaign = state.campaignsById.items[campaignId] || DEFAULT_CAMPAIGN;

    let activations = campaign.activationIds
        .map(aid => state.activationsById[aid])
        .filter(a => typeof a !== 'undefined')
        .map(a => {
            a.stats = state.activationStatsById[a.id] || [];
            a.user = state.usersById[a.user_id] || null;
            return a;
        });

    return {

        campaign,
        activations,

        stats: state.campaignStatsById[campaignId] || {items: []},
        users: campaign.userIds.map(uid => state.usersById[uid]).filter(u => typeof u !== 'undefined'),
        audience: campaign.audience,
        affinity: campaign.affinity,
        report: campaign.report || {}

    };

};

const mapDispatchToProps = (dispatch, ownProps) => {

    const campaignId = ownProps.campaignId;

    return {

        fetchAffinity: () => dispatch(fetchCampaignAffinityIfNeeded(campaignId)),

        updateEMVParams: (emvParams) => dispatch(updateCampaignEMVParams(campaignId, emvParams)),

        fetchCampaign: (id) => {
            dispatch(fetchCampaign(id));
        },

        fetchReport: () => dispatch(fetchCampaignReportIfNeeded(campaignId)),

        fetchStats: () => {
            dispatch(fetchCampaignStatsIfNeeded(campaignId));
        },

        fetchActivations: () => {
            dispatch(fetchCampaignActivationsIfNeeded(campaignId));
        },

        fetchActivationStats: () => {
            dispatch(fetchCampaignActivationStatsIfNeeded(campaignId));
        },

        fetchUsers: () => {
            dispatch(fetchCampaignUsersIfNeeded(campaignId));
        },

        fetchAudience: () => {
            dispatch(fetchCampaignAudienceIfNeeded(campaignId));
        },

        fetchGeography: () => dispatch(fetchCampaignGeographyIfNeeded(campaignId))
    };

};

const ConnectedCampaignReport = connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignReport);

export default ConnectedCampaignReport;
