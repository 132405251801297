import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ContributorTodosPane from "../../Dashboard/Partials/ContributorToDosPane";

class CompleteYourProfile extends Component {
    static propTypes = {
        // activation: PropTypes.object,
        // campaign: PropTypes.object,
        // createInteraction: PropTypes.func,
        // refreshActivation: PropTypes.func,
        user: PropTypes.object,
        refreshUser: PropTypes.func,
        translate: PropTypes.func,
    };

    static defaultProps = {
        missingFields: []
    };

    state = {isReloading: false};

    refreshInterval = null;

    componentDidMount() {
        this.refreshInterval = setInterval(this.props.refreshUser, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    async handleReload() {
        this.setState({isReloading: true});
        await this.props.refreshUser();
        this.setState({isReloading: false});
    }

    _t(key, defaultValue) {
        if (this.props.translate) {
            return this.props.translate(key, defaultValue);
        }
        return defaultValue;
    }

    render() {
        return (
            <div className="influencer-page-template shipping_address">
                <div className="widget">
                    <div className="content padded">
                        <h4 className="v3 bold action-pane-title">{this._t('campaign.profile.title', 'Complete Your Profile')}</h4>
                        <p className="v3 light h7 pane-info">{this._t('campaign.profile.subtitle', 'Your personal profile is incomplete. Please address the items below.')}
                            <br/>
                            <a role="button" onClick={this.handleReload.bind(this)} className="v3 bold"
                                >{this.state.isReloading ? <i className="v3 icon spinner" /> : null} {this._t('campaign.profile.reload', 'Reload your profile')}</a> {this._t('campaign.profile.reload_after', 'to update this page\'s status.')}
                        </p>

                        <ContributorTodosPane
                            translate={this.props.translate}
                            style={{padding: '10px 0'}}
                            missingFields={(this.props.user || {}).missingFields || []}
                            onClickTodo={item => {
                                window.open(`/contribute/do/page/user/profile#${item.hash}`, '_blank');
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default CompleteYourProfile;
