import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Form from '../Common/Form/Form';
import Alert from '../Common/Alert';
import {countries} from "../../utilities";
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";
import {translate} from "../../actions/i18n";

class PaymentDetailsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {...((props.activation || {}).payment_details || {}) },
            isSubmitting: false,
            didSubmit: false,
            error: null,
        };
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static defaultProps = {
        usePadding: false,
        style: {},
        showConsentMessage: true    //can be toggled to show/hide GDPR notification for managers / influencers
    };

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onSaved: PropTypes.func,
        usePadding: PropTypes.bool,
        style: PropTypes.object,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
        translate: PropTypes.func,
    };

    componentDidMount() {
        this.props.fetchActivation(this.props.activation.id)
            .then(() => this.resetForm());
    }

    _t(k, v) {
        if (this.props.translate) {
            return this.props.translate(`campaign.payment.form.${k}`, v);
        }
        return v;
    }

    resetForm() {
        this.setState({
            form: {...((this.props.activation || {}).payment_details || {}) }
        });
    }

    handleSubmit() {
        const {activation} = this.props;
        this.setState({isSubmitting: true, error: null});
        return this.props.createInteraction(activation.id, 'SubmitPaymentProfile', this.state.form)
            .then(resp => {

                if (resp.meta.error) {
                    this.setState({
                        isSubmitting: false,
                        didSubmit: false,
                        error: resp.meta.error,
                    });

                } else {
                    this.setState({
                        isSubmitting: false,
                        didSubmit: true,
                        error: null,
                    });
                    if (this.props.onSaved) {
                        this.props.onSaved(this.state.form);
                    }
                    this.props.fetchActivation(activation.id);
                    this.resetForm();
                }
            });
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    render() {
        const {isSubmitting, didSubmit} = this.state;
        return <Form
            style={this.props.style}
            usePadding={this.props.usePadding}
            values={this.state.form}
            onFieldChange={this.handleFieldChange}
            fields={[
                {
                    type: 'text',
                    name: 'vendor_name',
                    title: this._t('name.title', 'Your Name'),
                    placeholder: 'eg: Burak Kanber',
                    help: this._t('name.help', "Required. The first and last name of the payment recipient."),
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'vendor_address1',
                    title: this._t('address1.title', 'Address Line 1'),
                    placeholder: 'eg: 123 First Avenue',
                    help: <>
                    <div>{this._t('address1.help1', 'Required. The address where your check should be sent')}</div>
                    <p className='help-block-alert'>{this._t('address1.help2', '*If you are represented, make sure to speak with your agency to provide the correct payment address.')}</p>
                    </>,
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'vendor_address2',
                    title: this._t('address2.title', 'Address Line 2'),
                    placeholder: 'eg: Apt 6',
                    help: this._t('address2.help', "Optional. Additional address info such as apartment or suite number."),
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'vendor_city',
                    title: this._t('city.title', 'City'),
                    placeholder: 'eg: Los Angeles',
                    help: this._t('city.help', "Required. Enter the city for your billing address."),
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'vendor_state',
                    title: this._t('state.title', 'State'),
                    placeholder: 'eg: CA',
                    help: this._t('state.help', "Required. Enter the state for your billing address."),
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'vendor_postal',
                    title: this._t('postal.title', 'Zip Code'),
                    placeholder: 'eg: 90210',
                    help: this._t('postal.help', "Required. Enter the zip code for your billing address."),
                    classes: ['v3', 'light']
                },
                {
                    type: 'select',
                    name: 'vendor_country',
                    title: this._t('country.title', 'Country'),
                    choices: Object.keys(countries).map(code => ({text: countries[code], value: code})),
                    options: {
                        defaultValue: this._t('country.defaultValue', "Select a Country"),
                    },
                    addlClasses: 'v3 light',
                    help: this._t('country.help', "Required. Enter the country for your billing address."),
                },
                {
                    type: 'text',
                    name: 'vendor_email',
                    title: this._t('email.title', 'Email Address'),
                    placeholder: 'eg: burak@tid.al',
                    help: <>
                    <div>{this._t('email.help1', 'Required. Your email address.')}</div>
                    <p className='help-block-alert'>{this._t('email.help2', '*If you are not in the United States, please provide an email address that is connected to a Paypal account.')}</p>
                    </>,
                    classes: ['v3', 'light']
                },
                {
                    type: 'text',
                    name: 'vendor_phone',
                    title: this._t('phone.title', 'Phone Number'),
                    placeholder: 'eg: 917-555-5555',
                    help: this._t('phone.help', "Required. Your phone number."),
                    classes: ['v3', 'light']
                },
                this.state.error ? {
                    type: 'generic',
                    content: <Alert
                        classes={['danger']}
                        content={this.state.error}
                        onClose={() => this.setState({error: null})}
                    />

                } : null,
                {
                    type: 'button',
                    name: 'submit',
                    title: this._t('submit.title', 'Save Payment Profile'),
                    options: {
                        classes: ['v3 btn btn-primary medium bold', (isSubmitting ? 'disabled' : '')],
                        hideTitle: true,
                        onClick: this.handleSubmit.bind(this),
                    }
                },
                (this.props.showConsentMessage) ? {
                    type: 'generic',
                    content: <>
                        <p className="v3 light h7 pane-info">{this._t('consent.line1', 'We will securely transfer your payment details to Bill.com for the purpose of issuing payment to you.')}</p>
                        <p className='help-block-notice'>{this._t('consent.line2', '*If you would like to set up e-payments, connect with us on Bill.com with Payment Network ID: 0160155256758263')}</p>
                        <p className='help-block-notice'>{this._t('consent.line3', '**Payments are issued in net 45 days from when campaign requirements are completed. You will receive an email notification upon requirement completion.')}</p>
                    </>
                } : null,
                (didSubmit || isSubmitting) ? {
                    type: 'generic',
                    content: <Alert
                        classes={['info']}
                        content={isSubmitting ? this._t('alert.saving', 'Saving payment profile...') : this._t('alert.saved', 'Saved!')}
                        style={{marginBottom: 0}}
                    />
                } : null,
            ]}
        />

    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        createInteraction: (
            props.createInteraction
            ||
            ((id, type, context) => dispatch(createActivationInteraction(id, type, context)))
        ),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
        translate: (key, defaultValue) => dispatch(translate(key, defaultValue))
    };
};

export default connect(null, mapDispatchToProps)(PaymentDetailsForm);