import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {preventDefaultIfPossible, limitChars} from "../../../utilities";
import _ from 'lodash';
import moment from 'moment';

class FormSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            search: '',
        };

        this.wrapperRef = null;
        this.handleToggleExpanded = this.handleToggleExpanded.bind(this);
        this.handleSelectOption = this.handleSelectOption.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        if (this.props.searchable) {
            document.addEventListener('keydown', this.handleKeyPress);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        if (this.props.searchable) {
            document.removeEventListener('keydown', this.handleKeyPress);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if we just closed the select, clear the search
        if (prevState.expanded && !this.state.expanded) {
            this.setState({search: ''});
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({expanded: false});
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleKeyPress(event) {
        // if searchable and expanded
        if (this.props.searchable && this.state.expanded) {
            // if control is pressed, ignore
            if (event.ctrlKey) return;

            // if backspace
            if (event.keyCode === 8) {
                this.setState({search: this.state.search.slice(0, -1)});
            }
            // if letter
            else if (event.keyCode >= 65 && event.keyCode <= 90) {
                this.setState({search: this.state.search + event.key.toLowerCase()});
            }
            // if space or number
            else if (event.keyCode === 32 || (event.keyCode >= 48 && event.keyCode <= 57)) {
                this.setState({search: this.state.search + event.key});
                // if space, prevent default
                if (event.keyCode === 32) {
                    event.preventDefault();
                }
            }
            // if escape or delete
            else if (event.keyCode === 27 || event.keyCode === 46) {
                this.setState({search: ''});
            }
        }

    }

    handleSelectOption(option, event) {
        preventDefaultIfPossible(event);

        if (this.props.onChange) {
            this.props.onChange(option);
        }

        this.setState({expanded: false});
        return false;
    }

    handleToggleExpanded(event) {
        preventDefaultIfPossible(event);
        this.setState({expanded: !this.state.expanded});
    }

    getOptions() {
        const {options} = this.props;
        const {search} = this.state;
        if (!search) return options;

        return options.filter((option) => {
            return (
                (option.text || '').toLowerCase().indexOf(search.toLowerCase()) !== -1
                || (option.value || '').toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
        });
    }

    renderCurrentSearch() {
        if (!this.props.searchable) return null;
        if (!this.state.expanded) return null;
        // if there's no search, show "Type to search..."
        // if there is a search, show the search
        const {search} = this.state;
        const text = search ? ('Filter: ' + search): 'Type to filter...';
        // need to prevent bubbling up to the document click handler
        const onClick = (event) => {
            event.preventDefault();
            event.stopPropagation();
            return false;
        };
        return (
            <div className="current-search" onClick={onClick}>
                {text}
            </div>
        );

    }

    renderOptions() {
        if (!this.state.expanded) return null;
        const options = this.getOptions();
        return (
            <div className="options">
                {this.renderCurrentSearch()}
                <ul className="options-list">
                    {options.map((option, index) => {
                        let text = option.text ? option.text : option.value;
                        text = this.props.limitChars ? limitChars(text, 30)  : text;

                        let classes = ['option'];
                        if (option.isActive) {
                            classes.push('active');
                        }

                        return (
                            <li
                                key={`select-option-${option.value || option.text}-${index}`}
                                onClick={this.handleSelectOption.bind(this, option)}
                                className={classes.join(' ')}>{text}
                                {
                                    option.iconClass &&
                                    <i className={"v3 icon " + option.iconClass}></i>
                                }
                                {
                                    option.date &&
                                    <span className="start-date">{moment(option.date).format('MMM YYYY')}</span>
                                }
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }

    renderCurrentValue() {

        const {value, options, defaultValue} = this.props;
        let display = defaultValue;

        if (typeof value !== 'undefined') {
            let currentOption = _.find(options, {value});
            if (typeof currentOption !== 'undefined') {
                display = currentOption.text || currentOption.value;
            }
        }


        return (
            <div
                className="current-selection">
                {display}
            </div>
        );
    }

    render() {

        let classes = ['react-form-select', this.props.addlClasses];
        if (this.props.air) {
            classes.push('air');
        }
        if (this.props.slim) {
            classes.push('slim');
        }
        if (this.props.searchable) {
            classes.push('searchable');
        }
        if (this.state.expanded) {
            classes.push('expanded');
        }

        return (
            <div
                ref={this.setWrapperRef}
                className={classes.join(' ')}
                onClick={this.handleToggleExpanded}
                style={this.props.wrapperStyle}
            >
                <div
                    className="dropdown-icon">
                    <i className="fa fa-angle-down"/>
                </div>
                {this.renderCurrentValue()}
                {this.renderOptions()}
            </div>
        );

    }
}

FormSelect.defaultProps = {
    searchable: true,
    air: false,
    slim: false,
    addlClasses: '',
    defaultValue: '- Select One -',
    limitChars: false,
    wrapperStyle: {},
};

FormSelect.propTypes = {
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    options: PropTypes.array.isRequired,
    searchable: PropTypes.bool,
    onChange: PropTypes.func,
    air: PropTypes.bool,
    slim: PropTypes.bool,
    addlClasses: PropTypes.string,
    wrapperStyle: PropTypes.object,
    limitChars: PropTypes.bool,
};

export default FormSelect;
