import * as ActionTypes from './types';
import {makeFormData} from "../utilities";
import {fetchAuthenticated} from "./auth";

export const REQUEST_ACTIVATION_INTERACTIONS = 'REQUEST_ACTIVATION_INTERACTIONS';
export const RECEIVE_ACTIVATION_INTERACTIONS = 'RECEIVE_ACTIVATION_INTERACTIONS';

export const receiveActivationStats = (id, resp) => {
    return {
        type: ActionTypes.ACTIVATION_RECEIVE_STATS,
        id,
        data: resp.data,
        meta: resp.meta,
    }
};

export const fetchActivationStats = (id) => {

    return (dispatch) => {
        const dashboard = window.Gator.getDashboardSlot();
        const uri = `/${dashboard}/api/activation/${id}/stat`;

        dispatch({
            type: ActionTypes.ACTIVATION_REQUEST_STATS,
            id
        });

        return dispatch(fetchAuthenticated(uri))
            .catch(error => dispatch({
                type: ActionTypes.ACTIVATION_REQUEST_STATS_FAILED,
                id,
                error
            }))
            .then(resp => resp.json())
            .then(json => dispatch(receiveActivationStats(id, json)));
    };

};

export const createActivationInteraction = (activationId, type, context) => {

    return (dispatch) => {

        const dashboard = window.Gator.getDashboardSlot();
        const uri = `/${dashboard}/api/activation/${activationId}/interaction`;

        dispatch({
            type: ActionTypes.ACTIVATION_CREATE_INTERACTION,
            id: activationId,
            interactionType: type,
            context
        });

        return dispatch(fetchAuthenticated(uri, {
            method: 'POST',
            body: makeFormData({
                type,
                context: JSON.stringify(context)
            })
        }))
            .then(resp => resp.json())
            .then(json => {
                dispatch({
                    type: ActionTypes.ACTIVATION_CREATED_INTERACTION,
                    id: activationId,
                    meta: json.meta,
                    data: json.data
                });
                return json
            })
            .then(json => dispatch(receiveActivationInteractions(activationId, json)));
    }
};

export const updateActivationMetadata = (id, key, value) => {
    return (dispatch) => {
        dispatch({ type: ActionTypes.ACTIVATION_UPDATE_METADATA, id, key, value });
        return dispatch(fetchAuthenticated(`/manage/api/activation/${id}/metadata`, {
            method: 'post',
            body: makeFormData({key, value})
        }))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: ActionTypes.ACTIVATION_UPDATED_METADATA,
                id,
                key,
                value,
                data: json.data,
                meta: json.meta
            }))
            .catch(error => dispatch({
                type: ActionTypes.ACTIVATION_UPDATE_METADATA_FAILED,
                id, key, value, error
            }))
    }
};

export const receiveActivationInteractions = (id, resp) => {
    return {
        type: RECEIVE_ACTIVATION_INTERACTIONS,
        id,
        data: resp.data,
        meta: resp.meta,
    }
};

export const fetchActivationInteractions = id => {
    return (dispatch) => {
        dispatch({type: REQUEST_ACTIVATION_INTERACTIONS, id});
        const url = `/manage/api/activation/${id}/interaction`;
        return dispatch(fetchAuthenticated(url))
            .then(resp => resp.json())
            .then(json => dispatch(receiveActivationInteractions(id, json)));
    };
}
