import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getCampaignsByDate} from "../../selectors/campaigns";
import {createActivationInteraction} from "../../actions/activations";
import Alert from "../Common/Alert";
import Form from "../Common/Form/Form";


class ReactivateForm extends Component {
    static propTypes = {
        campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
        activation: PropTypes.object.isRequired,
        createInteraction: PropTypes.func,
        onComplete: PropTypes.func,
    };

    state = {
        campaign_id: null,
        isUpdating: false,
        didUpdate: false,
    };

    handleFieldChange(name, value) {
        this.setState({[name]: value});
    }

    handleSubmit() {


        const {activation} = this.props;
        this.setState({isUpdating: true});
        const payload = {force: true, campaign_id: this.state.campaign_id};

        return this.props.createInteraction(activation.id, 'Reactivate', payload)
            .then(() => this.setState({isUpdating: false, didUpdate: true, campaign_id: null}))
            .then(() => {
                if (this.props.onComplete) {
                    this.props.onComplete();
                }
            });


    }

    renderAlert() {
        const {didUpdate} = this.state;
        return <Alert
            classes={[didUpdate ? 'success' : 'info']}
            content={didUpdate ? 'Added to campaign!' : 'Use this tool to add the user to another campaign.'}
        />
    }

    render() {
        const {isUpdating, didUpdate, campaign_id} = this.state;
        let isDisabled = false;
        let btnClasses = ['v3', 'btn', 'tidal-btn'];

        if (!campaign_id || isUpdating) {
            btnClasses = [...btnClasses, 'btn-secondary', 'disabled'];
        } else {
            btnClasses = [...btnClasses, 'btn-primary'];
        }

        return (
            <Form
                values={{campaign_id: this.state.campaign_id}}
                onFieldChange={this.handleFieldChange.bind(this)}
                fields={[
                    {
                        type: 'generic',
                        content: this.renderAlert()
                    },
                    {
                        type: 'select',
                        name: 'campaign_id',
                        title: "Select a Campaign",
                        choices: [
                            {text: '- Select a Campaign -', value: null},
                            ...this.props.campaigns.map(c => ({text: c.name, value: c.id}))
                        ],
                    },
                    {
                        type: 'button',
                        title: "Add to Campaign",
                        options: {
                            hideTitle: true,
                            onClick: isDisabled ? null : this.handleSubmit.bind(this),
                            classes: btnClasses,
                        }
                    }

                ]}
            />


        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        campaigns: getCampaignsByDate(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (id, type, ctx) => dispatch(createActivationInteraction(id, type, ctx)),
    };
};

const ConnectedReactivateForm = connect(mapStateToProps, mapDispatchToProps)(ReactivateForm);
export default ConnectedReactivateForm;
