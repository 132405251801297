import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ScrollingImageRow from "./ScrollingImageRow";
import {formatNumber, limitChars, makeMomentFromDate} from "../../utilities";
import Tooltip from "./Tooltip";
import GenericProfileFacetsTable from "./GenericProfileFacetsTable";
import GenericProfileSummaryBox from "./GenericProfileSummaryBox";

export default class GenericProfileExpando extends Component {

    static propTypes = {
        text: PropTypes.string,
        facets: PropTypes.object,
        tags: PropTypes.array,
        stats: PropTypes.object,
        metadata: PropTypes.object,
        images: PropTypes.array,

        editorial_notes: PropTypes.string,
    };

    renderOverview() {
        if (!this.props.editorial_notes) {
            return null;
        }

        return (
            <div className="expando-section">
                <div className="fake-table generic-profile-summary-wrapper">
                    <div className={"v3 h7 light fake-li condensed bold"}>Editorial Notes</div>
                    <div className={"v3 h7 light fake-li condensed"} style={{marginBottom: 5}}>{this.props.editorial_notes}</div>
                </div>

            </div>
        );
    }

    renderProfile() {

        const profile = this.props.text ? this.props.text : "This user has no profile yet.";

        return (
            <div className="expando-section">
                <GenericProfileSummaryBox {...this.props} />
            </div>
        );
    }

    renderFacets() {


        return (
            <div className="expando-section">
                <GenericProfileFacetsTable {...this.props} />
            </div>
        );
    }

    renderImages() {

        return (
            <ScrollingImageRow
                urls={this.props.images || []}
            />
        );
    }

    render() {

        return (
            <div className="generic-profile-table-expando">
                <div className="hscrollwrapper">
                    {this.renderOverview()}
                    {this.renderProfile()}
                    {this.renderFacets()}
                    {this.renderImages()}
                </div>
            </div>
        );
    }
}