import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Alert from "../Alert";

export default class File extends Component {

    static defaultProps = {
        classes: [],
        style: {}
    };

    static propTypes = {
        classes: PropTypes.array,
        name: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        onForceChange: PropTypes.func,
        style: PropTypes.object,
        previewFormatter: PropTypes.func,
        accept: PropTypes.string,
        isUploading: PropTypes.bool
    };

    renderPreview(){
        if (this.props.previewFormatter && this.props.value) {
            return this.props.previewFormatter(this.props.value, (newval = null) => this.props.onForceChange(newval));
        }
    }

    render() {
        return (
            <div className='react-image-uploader'>

                <input type='file'
                       className='react-image-file-input'
                       onChange={this.props.onChange}
                       accept={this.props.accept}
                />

                {this.props.isUploading && <Alert classes={['info']} content={'Uploading, please wait. Large files may take a long time.'} />}

                {this.renderPreview()}

            </div>
        );
    }
}
