/**
 * Created by visgotti on 7/29/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types'

import PostFilters from '../Filters';

export default class PostsHeaderDisplayControls extends Component {

    constructor(props){
        super(props);
        this.handleToggleShowFilters = this.handleToggleShowFilters.bind(this);
        this.state = {
            showFilters: false,
        }
    }
    handleToggleShowFilters() {
        this.setState(prevState => {
            return {
                showFilters: !prevState.showFilters
            }
        })
    }
    renderFilters() {
        if (this.state.showFilters) {
            return(
                <PostFilters
                    campaigns={this.props.campaigns}
                    toggleShowFilters={this.handleToggleShowFilters}
                    changeFilter={this.props.changeFilter}
                    changeLimit={this.props.changeLimit}
                    resetFilters={this.props.resetFilters}
                    fetchPosts={this.props.fetchPosts}
                    removeFilter={this.props.removeFilter}
                    filters={this.props.filters}
                    limit={this.props.limit}
                    badges={this.props.badges}
                    disableCampaignField={this.props.disableCampaignField}
                />
            )
        }
    }

    render() {
        const tableClassName = this.props.currentView === 'table' ? 'active display-icon': 'display-icon';
        const expandedTableClassName = this.props.currentView === 'expanded-table' ? 'active display-icon': 'display-icon';
        const smallCardClassName = this.props.currentView === 'small' ? 'active display-icon': 'display-icon';
        const mediumCardClassName = this.props.currentView === 'medium' ? 'active display-icon': 'display-icon';
        const searchClassName = this.state.showFilters ? 'active display-icon': 'display-icon';

        return (

            <div className="pull-right display-icons">
                {/*<a*/}
                    {/*onClick={() => {this.props.changeView('expanded-table')}}*/}
                    {/*data-tooltip="Display posts as expanded table."*/}
                    {/*className={expandedTableClassName}*/}
                {/*>*/}
                    {/*<i className="fa fa-address-card"></i>*/}
                {/*</a>*/}

                <a
                    onClick={() => {this.props.changeView('table')}}
                    data-tooltip="Display posts as table."
                    className={tableClassName}
                >
                    <i className="fa fa-th-list"></i>
                </a>

                <a
                    onClick={() => {this.props.changeView('medium')}}
                    data-tooltip="Display posts as graphical cards."
                    className={mediumCardClassName}
                >
                    <i className="fa fa-th-large"></i>
                </a>

                <a
                    onClick={this.handleToggleShowFilters}
                    data-tooltip="Search for a post."
                    className={searchClassName}
                >
                    <i className="fa fa-search"></i>
                </a>

                { this.renderFilters() }
            </div>
        )
    }
}

PostsHeaderDisplayControls.propTypes = {
    changeFilter: PropTypes.func,
    changeLimit: PropTypes.func,
    fetchPosts: PropTypes.func,
    removeFilter: PropTypes.func,
    resetFilters: PropTypes.func,
    filters: PropTypes.object,
    limit: PropTypes.number,
    badges: PropTypes.object,
    campaigns: PropTypes.object,
    currentView: PropTypes.oneOf['small', 'medium', 'large', 'table', 'expanded-table'],
    isSearching: PropTypes.bool,
    disableCampaignField: PropTypes.bool,
    changeView: PropTypes.func,
};

PostsHeaderDisplayControls.defaultProps = {
    currentView: "small",
    isSearching: false,
    changeView: () => {},
    disableCampaignField: false,
};
