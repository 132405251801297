import React, {Component} from 'react';
import PropTypes from 'prop-types';
import UserDetailSocialPaneRow from './SocialPaneRow';
import SocialAuthConnector from "../../Influencer/EditProfile/Templates/SocialAuthConnector";
import Alert from "../../Common/Alert";
import Toolbox from "../../Common/Toolbox";
import EditSocialForm from "../../Social/EditSocialForm";

export default class UserDetailSocialPane extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAttachingSocial: false,
            isLoading: true,
            didAttachSuccessfully: false,
            error: null,
            editSocial: null,
        }
    }

    componentDidMount() {
        if (this.props.fetchSocials) {
            this.props.fetchSocials();
        }

        if (this.props.socials.length) {
            this.setState({isLoading: false})
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.id !== this.props.user.id && this.props.fetchSocials) {
            this.props.fetchSocials();
        }
    }

    getSocials() {
        const whitelistTypes = [
            'Tidal\\Social\\Pinterest',
            'Tidal\\Social\\Facebook',
            'Tidal\\Social\\FacebookPage',
            'Tidal\\Social\\Youtube',
            'Tidal\\Social\\Instagram',
            'Tidal\\Social\\InstagramBusiness',
            'Tidal\\Social\\Twitter',
            'Tidal\\Social\\TikTok',
            'Tidal\\Social\\Snapchat',
        ];

        return (this.props.socials || [])
            .filter(social => {
                return whitelistTypes.indexOf(social.type) > -1;
            })
            .sort((a, b) => {

                const aCount = (a.posts || []).length;
                const bCount = (b.posts || []).length;

                // always return either +1 or -1
                // +1 means the first item (a) should get pushed forward -- ie, to the end
                // -1 means the first item (a) should get pulled backwards -- ie, to the beginning

                return aCount > bCount ? -1 : 1;
            });

    }

    renderAlert() {
        if (!this.state.didAttachSuccessfully && this.state.error) {
            return (
                <Alert
                    classes={['danger']}
                    content={<span>Failed to attach social account.</span>}
                    onClose={() => this.setState({error: null})}
                    style={{marginBottom: 10}}
                />
            )
        } else if (this.state.didAttachSuccessfully) {
            return (
                <Alert
                    classes={['success']}
                    content={<span>Social account successfully attached!</span>}
                    style={{marginBottom: 10}}
                />
            )
        } else if (this.state.isLoading) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>Loading socials...</span>}
                    style={{marginBottom: 10}}
                />
            )
        }
    }

    renderEditPopup() {
        if (!this.state.editSocial) {
            return null;
        }

        return (
            <Toolbox
                addlClasses="toolbox-fixed toolbox-sm"
                title="Edit Social Details"
                onClose={() => this.setState({editSocial: null})}
                content={<EditSocialForm
                    social={this.state.editSocial}
                    onSave={(payload) => {
                        this.props.createUserInteraction(this.props.user.id, 'EditSocial', payload)
                            .then(() => {
                                this.setState({editSocial: null});
                                return this.props.fetchSocials();
                            })
                    }}
                />}
            />
        );
    }

    render() {

        const {user, showSocialConnectorCard} = this.props;
        const socials = this.getSocials();
        const Rows = socials
            .map(social =>
                <UserDetailSocialPaneRow
                    key={social.id}
                    user={user}
                    social={social}
                    onClickRemove={() => {
                        this.props.createUserInteraction(user.id, 'RemoveSocial', {social_id: social.id})
                            .then(() => this.props.fetchSocials());
                    }}
                    onClickEdit={() => {
                        this.setState({editSocial: social});
                    }}
                />
            );

        return (

            <div className={'user-detail-body-social'}>

                {this.renderAlert()}
                {this.renderEditPopup()}

                { showSocialConnectorCard &&
                <div className="user-detail-body-social-row social-connector-card">
                    <SocialAuthConnector
                        user={this.props.user}
                        onDidAttach={() => {
                            this.props.onDidAttachSocial();
                            this.setState({isAttachingSocial: false, didAttachSuccessfully: true});
                        }}
                        onFail={(error) => {
                            this.setState({isAttachingSocial: false, didAttachSuccessfully: false, error: error});
                        }}
                    />
                </div>
                }

                {Rows}

            </div>

        );
    }

}

UserDetailSocialPane.defaultProps = {
    showSocialConnectorCard: false,

};

UserDetailSocialPane.propTypes = {
    user: PropTypes.object.isRequired,
    socials: PropTypes.array.isRequired,
    fetchSocials: PropTypes.func,
    showSocialConnectorCard: PropTypes.bool,
    onDidAttachSocial: PropTypes.func,
    createUserInteraction: PropTypes.func,
};
