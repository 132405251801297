import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from './Toolbox';
import Checkbox from './Form/Checkbox';

export default class ColumnSelectToolbox extends Component {
    static propTypes = {
        onClose: PropTypes.func,
        onChange: PropTypes.func,
        style: PropTypes.object,
        columns: PropTypes.array,
        visibleColumns: PropTypes.array,
        innerScrollHeight: PropTypes.number,
    };

    getVisibleColumns() {

        const {visibleColumns, columns} = this.props;

        if (visibleColumns && visibleColumns.length > 0) {
            return visibleColumns;
        }

        return columns.filter(col => !!col.default).map(col => col.key);
    }

    isColumnVisible(key) {
        const visible = this.getVisibleColumns();
        return visible.indexOf(key) > -1;
    }

    renderContent() {
        const style = {overflow: 'auto'};
        if (this.props.innerScrollHeight) {
            style.maxHeight = this.props.innerScrollHeight;
        }

        return (
            <div style={style}>
                {this.props.columns.map(column => this.renderColumn(column))}
            </div>
        );

    }

    renderColumn(column) {
        const isVisible = this.isColumnVisible(column.key);
        return <div className="fake-li padded slim">
            <Checkbox
                checked={isVisible}
                label={column.title}
                onClick={this.handleCheckboxClick.bind(this, column.key)}
                useBoldTitle={false}
            />
        </div>

    }

    handleCheckboxClick(key) {
        const wasVisible = this.isColumnVisible(key);
        let newColumns;

        if (wasVisible) {
            // Remove
            newColumns = this.getVisibleColumns().filter(colKey => colKey !== key);
        } else {
            // add
            newColumns = this.getVisibleColumns().concat([key]);
        }

        if (this.props.onChange) {
            this.props.onChange(newColumns);
        }

    }

    render() {
        return (
            <Toolbox
                onClose={this.props.onClose}
                title={'Customize Columns'}
                style={this.props.style}
                content={this.renderContent()}
            />

        );
    }
}