import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableWrapper from '../../../Table/Wrapper';
import CheckboxCell from '../../../Table/Cell/Checkbox';
import ImageCell from '../../../Table/Cell/Image';
import BasicCell from '../../../Table/Cell/Basic';
import LinkCell from '../../../Table/Cell/Link';
import DateCell from '../../../Table/Cell/Date';
import {formatNumber, makeCellKey} from "../../../../utilities";
import SocialPostPreview from './SocialPostPreview';
import Toolbox from "../../../Common/Toolbox";

export default class SelectableSocialPostsTable extends Component {

    static defaultProps = {
        selectedPostIds: [],
        submittingPostIds: [],
    };

    static propTypes = {
        posts: PropTypes.array,
        onSelect: PropTypes.func,
        onUnselect: PropTypes.func,
        selectedPostIds: PropTypes.array,
        submittingPostIds: PropTypes.array,
        usePagination: PropTypes.bool,
        page: PropTypes.number,
        pages: PropTypes.number,
        onPageChange: PropTypes.func,
        isInstastory: PropTypes.bool,
    };

    state = {
        preview: null
    };

    handleClickSelectPost(post) {
        if (this.isPostSelected(post)) {
            if (this.props.onUnselect) {
                this.props.onUnselect(post);
            }
        } else {
            if (this.props.onSelect) {
                this.props.onSelect(post);
            }
        }
    }

    getColumns() {
        return [

            {
                key: 'select',
                sortable: false,
                title: '',
                width: 60,
                default: true,
                cell: (row, column) => <CheckboxCell
                    row={row}
                    column={column}
                    key={makeCellKey(row, column)}
                    onClick={this.handleClickSelectPost.bind(this, row.item)}
                    icon={this.isPostSubmitting(row.item) ? <i className="fa fa-spinner fa-spin"/> : null}
                    value={row.isSelected}
                />
            },
            {
                key: 'image',
                sortable: false,
                title: 'Image',
                width: 80,
                default: true,
                cell: (row, column) => <ImageCell
                    row={row} column={column} url={(row.item.image_urls || [])[0] || null} key={makeCellKey(row, column)}
                    style={{height: this.props.isInstastory ? 150 : 60}}
                />
            },
            {
                key: 'title',
                sortable: false,
                title: 'Title',
                width: 400,
                default: true,
                cell: (row, column) => <LinkCell
                    row={row} column={column} value={row.item.title} key={makeCellKey(row, column)}
                    onClick={() => {
                        this.setState({preview: {...row.item}})
                    }}
                    // target={'_blank'}
                    // href={row.item.permalink}
                />
            },
            {
                key: 'source_created_at',
                sortable: false,
                title: 'Date',
                width: 140,
                default: true,
                cell: (row, column) => <DateCell
                    row={row} column={column} key={makeCellKey(row, column)}
                    value={row.item.source_created_at}
                />
            },
            {
                key: 'total_engagements',
                sortable: false,
                title: 'Engagements',
                width: 120,
                default: true,
                cell: (row, column) => <BasicCell row={row} column={column}
                                                  key={makeCellKey(row, column)}
                                                  value={formatNumber(row.item.total_engagements || 0)}
                />

            },
            {
                key: 'disclosure',
                sortable: false,
                title: 'Disclosure',
                width: 130,
                default: false,
                cell: (row, column) => <BasicCell row={row} column={column} key={makeCellKey(row, column)}
                                                  value={row.item.has_sponsored_tag ? 'Compliant' : 'Not Compliant'}
                />
            },


        ];
    }

    isPostSelected(post) {
        return this.props.selectedPostIds.indexOf(post.source_id) > -1;
    }

    isPostSubmitting(post) {
        return this.props.submittingPostIds.indexOf(post.source_id) > -1;
    }

    renderPopups() {
        if (!this.state.preview) {
            return [];
        }

        return [
            this.renderPreviewPopup()
        ];
    }

    renderPreviewPopup() {

        return <Toolbox
            onClose={() => this.setState({preview: null})}
            title={`Post Preview`}
            content={<SocialPostPreview post={this.state.preview} scrollToTopOnLoad={true} />}
            style={{
                top: 0,
                width: '100%'
            }}
        />

    }

    render() {
        return (
            <TableWrapper
                showHeader={false}
                items={this.props.posts}
                columns={this.getColumns()}
                isRowSelected={row => this.isPostSelected(row.item)}

                page={this.props.page}
                pages={this.props.pages}
                showPagination={this.props.usePagination}
                onPageChange={this.props.usePagination ? this.props.onPageChange : undefined}

                popups={this.renderPopups()}
            />

        );
    }
}