/**
 * Created by visgotti on 11/29/17.
 */

export const CHANGE_POST_FILTER = 'CHANGE_POST_FILTER';
export const CHANGE_POST_FILTER_FAIL = 'CHANGE_POST_FILTER_FAILED';
export const REMOVE_POST_FILTER = 'REMOVE_POST_FILTER';
export const RESET_POST_FILTERS = 'RESET_POST_FILTERS';

export const CHANGE_POST_SORT = 'CHANGE_POST_SORT';
export const CHANGE_POST_SORT_FAIL = 'CHANGE_POST_SORT_FAILED';

export const CHANGE_POST_LIMIT = 'CHANGE_POST_LIMIT';
export const CHANGE_POST_PAGE = 'CHANGE_POST_PAGE';

export const SELECT_POST = 'SELECT_POST';
export const UNSELECT_POST = 'UNSELECT_POST';
export const SELECT_ALL_POSTS = 'SELECT_ALL_POSTS';
export const UNSELECT_ALL_POSTS = 'UNSELECT_ALL_POSTS';


export function resetFilters(queryId) {
    return {
        type: RESET_POST_FILTERS,
        queryId,
    }
}

export function changeFilter(key, value, fetchPosts, queryId) {
    try {
        return {
            type: CHANGE_POST_FILTER,
            filter: {
                key,
                value,
            },
            queryId,
            needsFetching: fetchPosts,
        };
    } catch(e) {
        return {
            type: CHANGE_POST_FILTER_FAIL,
            error: e,
        }
    }
}

export function removeFilter(key, fetchPosts, queryId) {
    return {
        type: REMOVE_POST_FILTER,
        key,
        queryId,
        needsFetching: fetchPosts
    }
}

export function changeSort(values, fetchPosts, queryId) {
    try {
        return {
            type: CHANGE_POST_SORT,
            by: values.by,
            dir: values.dir,
            queryId,
            needsFetching: fetchPosts
        };
    } catch(e) {
        return {
            type: CHANGE_POST_SORT_FAIL,
            error: e,
        }
    }
}

export function changePage(page, queryId) {
    return {
        type: CHANGE_POST_PAGE,
        page,
        queryId,
    }
}
export function changeLimit(limit, fetchPosts, queryId) {
    return {
        type: CHANGE_POST_LIMIT,
        limit,
        queryId,
        needsFetching: fetchPosts,
    }
}

export function selectAllPosts (postIds, queryId) {
    return function (dispatch) {
        postIds.forEach(postId => {
            dispatch(selectPost(postId, queryId));
        });
        dispatch({type: SELECT_ALL_POSTS, queryId})
    };
}

export function unselectAllPosts (postIds, queryId) {
    return function (dispatch) {
        postIds.forEach(postId => {
            dispatch(unselectPost(postId, queryId));
        });
        dispatch({type: UNSELECT_ALL_POSTS, queryId})
    };
}

export function selectPost (postId, queryId) {
    return {
        type: SELECT_POST,
        postId,
        queryId
    };
}

export function unselectPost (postId, queryId) {
    return {
        type: UNSELECT_POST,
        postId,
        queryId
    };
}
