/**
 * Created by bkanber on 6/21/17.
 */

import {makeFormData} from '../utilities';
import {fetchAuthenticated} from "./auth";
import * as ActionTypes from "./types";
import * as URI from "urijs";

export const REQUEST_LIST = 'REQUEST_LIST';
export const REQUEST_LISTS = 'REQUEST_LISTS';
export const RECEIVE_LISTS = 'RECEIVE_LISTS';

export const RECEIVE_LIST = 'RECEIVE_LIST';
export const EDIT_LIST = 'EDIT_LIST';
export const CREATE_LIST = 'CREATE_LIST';
export const CREATED_LIST = 'CREATED_LIST';

export const ADD_USERS_TO_LIST = 'ADD_USERS_TO_LIST';
export const ADDED_USER_TO_LIST = 'ADDED_USER_TO_LIST';
export const ADDED_USERS_TO_LIST = 'ADDED_USERS_TO_LIST';

export const REMOVE_USERS_FROM_LIST = 'REMOVE_USERS_FROM_LIST';
export const REMOVED_USERS_FROM_LIST = 'REMOVED_USERS_FROM_LIST';

export const ADD_LIST_TEAM = 'ADD_LIST_TEAM';
export const ADDED_LIST_TEAM = 'ADDED_LIST_TEAM';
export const REMOVE_LIST_TEAM = 'REMOVE_LIST_TEAM';
export const REMOVED_LIST_TEAM = 'REMOVED_LIST_TEAM';



export function removeListTeam(listId, teamId) {
    return function(dispatch) {
        dispatch({type: REMOVE_LIST_TEAM, listId, teamId});
        return dispatch(fetchAuthenticated(`/manage/api/userlist/${listId}/team/${teamId}`, {
            method: 'POST',
            body: makeFormData({_method: 'DELETE'})
        }))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: REMOVED_LIST_TEAM,
                listId,
                teamId,
                list: json.data
            }))
            .catch(err => {
                console.log("Error removing team from list");
                console.log(err);
                return err;
            })
    }
}

export function addListTeam(listId, teamId) {
    return function(dispatch) {
        dispatch({type: ADD_LIST_TEAM, listId, teamId});
        return dispatch(fetchAuthenticated(`/manage/api/userlist/${listId}/team/${teamId}`, {
            method: 'POST',
            body: makeFormData({_method: 'PUT'})
        }))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: ADDED_LIST_TEAM,
                listId,
                teamId,
                list: json.data
            }))
            .catch(err => {
                console.log("Error adding team to list");
                console.log(err);
                return err;
            })
    }
}




export function removedUsersFromList(userIds, listId) {
    return {
        type: REMOVED_USERS_FROM_LIST,
        userIds,
        listId
    }

}

export function removeUsersFromList(userIds, listId) {

    const payload = {ids: userIds.join(','), _method: "DELETE"};
    const url = `/manage/api/userlist/${listId}/user`;

    return function (dispatch) {

        dispatch({type: REMOVE_USERS_FROM_LIST, userIds, listId});

        return dispatch(fetchAuthenticated(url,
            {
                method: 'POST',
                body: makeFormData(payload)
            } ))
            .then(response => response.json())
            .then(json => {
                return dispatch(removedUsersFromList(userIds, listId))
            })
            .catch(err => {
                console.log("Error removing users from list");
                console.log(err)
            });
    }

}

export function addedUserToList(userId, listId) {
    return {
        type: ADDED_USER_TO_LIST,
        listId,
        userId,
        receivedAt: Date.now()
    };

}

export function addedUsersToList(userIds, listId) {
    return {
        type: ADDED_USERS_TO_LIST,
        listId,
        userIds,
        receivedAt: Date.now()
    };

}

export function addUsersToList(userIds, listId) {
    const payload = {ids: userIds.join(',')};
    const url = `/manage/api/userlist/${listId}/user`;

    return function (dispatch) {

        dispatch({type: ADD_USERS_TO_LIST, userIds, listId});

        return dispatch(fetchAuthenticated(url,
            {
                method: 'POST',
                body: makeFormData(payload)
            } ))
            .then(response => response.json())
            .then(json => {
                for (let userId of userIds) {
                    dispatch(addedUserToList(userId, listId));
                }
                return dispatch(addedUsersToList(userIds, listId));
            })
            .catch(err => {
                console.log("Error Adding users to list");
                console.log(err)
            });
    }

}

export function receiveList(listId, json) {
    return {
        type: RECEIVE_LIST,
        listId,
        list: json.data,
        receivedAt: Date.now()
    };
}

export function createdList(listId, json) {
    return {
        type: CREATED_LIST,
        listId,
        list: json.data,
        receivedAt: Date.now()
    };
}

export function createList(payload) {
    return function (dispatch) {

        dispatch({type: CREATE_LIST, payload});

        return dispatch(fetchAuthenticated( `/manage/api/userlist/`,
            {
                method: 'POST',
                body: makeFormData(payload)
            } ))
            .then(response => response.json())
            .then(json => dispatch(createdList(json.data.id, json)))
            .catch(err => {
                console.log("Error creating list");
                console.log(err)
            });

    }

}

export function editList(listId, payload) {
    return function (dispatch) {

        dispatch({type: EDIT_LIST, listId, payload});

        return dispatch(fetchAuthenticated(
            `/manage/api/userlist/${listId}`,
            {
                method: 'POST',
                body: makeFormData(Object.assign({_method: "PATCH"}, payload))
            }
        ))
            .then(response => response.json())
            .then(json => dispatch(receiveList(listId, json)))
            .catch(err => {
                console.log("Error editing list");
                console.log(err)
            });

    }
}

/**
 * @returns {{type: string}}
 */
export function requestLists() {
    return {
        type: REQUEST_LISTS
    };
}

/**
 * @param json
 * @returns {{type: string, lists, receivedAt: number}}
 */
export function receiveLists(json) {
    return {
        type: RECEIVE_LISTS,
        lists: json.data,
        receivedAt: Date.now()
    }
}

/**
 * @returns {Function}
 */
export function fetchLists(params = {}) {
    return function (dispatch) {

        const slot = window.Gator.getDashboardSlot();
        const limit = params.limit || 50;
        const page = params.page || 1;
        const summary = params.summary || false;

        if (page === 1) {
            dispatch(requestLists());
        }

        const url = URI(`/${slot}/api/userlist`)
            .query({page, limit, summary: summary ? 'true' : 'false'})
            .toString();

        return dispatch(fetchAuthenticated(url))
            .then(response => response.json())
            .then(json => {
                const res = dispatch(receiveLists(json))
                if (json.data && json.data.length === limit) {
                    // Trigger another page.
                    setTimeout(() => {
                        dispatch(fetchLists({page: page+1, limit, summary}));
                    }, 1000);
                }
                return res;
            })
            .catch(err => {
                console.log("Error fetching");
                console.log(err);
            });

    }
}

/**
 * @returns {Function}
 */
export function fetchList(listId) {
    return function (dispatch) {
        const dashboard = window.Gator.getDashboardSlot();
        const uri = `/${dashboard}/api/userlist/${listId}`;

        dispatch({
            type: REQUEST_LIST,
            listId,
        });

        return dispatch(fetchAuthenticated(uri, {
            method: 'GET',
        }))
            .then(resp => resp.json())
            .then(json => dispatch(receiveList(listId, json)))
            .catch(err => {
                console.log("Error fetching list");
                console.log(err)
            });
    }
}

/**
 * @param state
 * @returns {*}
 */
export function shouldFetchLists(state) {
    if (state.listsById.didInvalidate) {
        return true;
    } else if (state.listsById.isFetching || state.listsById.lastUpdated) {
        return false;
    } else {
        // TODO Need to record if ever fetched
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchListsIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchLists(getState())) {
            return dispatch(fetchLists())
        }
    }
}

export const createListInteraction = (listId, type, context) => {

    return (dispatch) => {

        const dashboard = window.Gator.getDashboardSlot();
        const uri = `/${dashboard}/api/userlist/${listId}/interaction`;

        dispatch({
            type: ActionTypes.LIST_CREATE_INTERACTION,
            id: listId,
            interactionType: type,
            context
        });

        return dispatch(fetchAuthenticated(uri, {
            method: 'POST',
            body: makeFormData({
                type,
                context: JSON.stringify(context)
            })
        }))
            .then(resp => resp.json())
            .then(json => {
                dispatch({
                    type: ActionTypes.LIST_CREATED_INTERACTION,
                    id: listId,
                    meta: json.meta,
                    data: json.data
                });
                return json
            });
    }
};

export const fetchListEntry = (listId, entryId) => {
    return (dispatch) => {
        const dashboard = window.Gator.getDashboardSlot();
        const uri = `/${dashboard}/api/userlist/${listId}/entry/${entryId}`;

        dispatch({
            type: ActionTypes.LIST_REQUEST_ENTRY,
            id: entryId,
            listId,
            entryId
        });

        return dispatch(fetchAuthenticated(uri, {
            method: 'GET',
        }))
            .then(resp => resp.json())
            .then(json => {
                dispatch({
                    type: ActionTypes.LIST_RECEIVE_ENTRY,
                    id: entryId,
                    listId,
                    entryId,
                    meta: json.meta,
                    data: json.data
                });
                return json
            });
    }
};

export const fetchPublicListEntry = (listId, entryId) => {
    return (dispatch) => {
        const dashboard = window.Gator.getDashboardSlot();
        const uri = `/${dashboard}/api/userlist/${listId}/publicdata/${entryId}`;

        dispatch({
            type: ActionTypes.LIST_REQUEST_ENTRY,
            id: entryId,
            listId,
            entryId
        });

        return dispatch(fetchAuthenticated(uri, {
            method: 'GET',
        }))
            .then(resp => resp.json())
            .then(json => {
                dispatch({
                    type: ActionTypes.LIST_RECEIVE_ENTRY,
                    id: entryId,
                    listId,
                    entryId,
                    meta: json.meta,
                    data: json.data
                });
                return json
            });
    }
};
