import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Table from '../Table/Table';
import BasicCell from "../Table/Cell/Basic";
import MiniProfile from "../Common/MiniProfile";
import NumberCell from "../Table/Cell/Number";


import {
    preventDefaultIfPossible,
    formatNumber,
    fetchAuthenticated,
    base64_png_1pxSolidEEE,
    formatPound, makeCellKey
} from "../../utilities";

import {socialNameForType, socialIconForType} from "../../utilities/social";
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _sumBy from 'lodash/sumBy';
import _get from 'lodash/get';
import _filter from 'lodash/filter';

import Toolbox from "../Common/Toolbox";

class HBLeaderboardExpando extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
    };

    getColumns() {
        return [
            {
                key: 'rank',
                default: true,
                sortable: false,
                title: "",
                width: 60,
                cell: (row, column) => <BasicCell row={row} column={column} value={' '}/>,
            },
            {
                key: 'social.name',
                default: true,
                sortable: false,
                title: "Name",
                width: 300,
                cell: (row, column) => {
                    const social = row.item.social;
                    const name = social.name;

                    const pic = (social && social.profile_picture_url)
                        ? social.profile_picture_url
                        : 'data:image/png;base64,' + base64_png_1pxSolidEEE;

                    const socialType = socialNameForType(social.type);
                    const iconClassName = socialIconForType(social.type);
                    const icon = <i className={iconClassName} />;

                    const secondary = <span>{icon} {socialType}</span>;

                    return <BasicCell row={row} column={column} value={
                        <MiniProfile
                            primary={<a target="_blank" href={social.url}>{name}</a>}
                            imageUrl={pic}
                            secondary={secondary}
                        />
                    }/>
                },
            },
            {
                key: 'total_emv',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: 'EMV',
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   value={row.item.total_emv}
                                                   formatter={formatPound}
                />,
            },
            {
                key: 'total_engagements',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Engagements",
                width: 170,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   value={row.item.total_engagements}
                                                   formatter={formatNumber}
                />,
            },

            {
                key: 'total_reach',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: 'Followers',
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   value={row.item.social.followers_count}
                                                   formatter={formatNumber}
                />,
            },
            {
                key: 'engPct',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: 'Engagement %',
                width: 180,
                cell: (row, column) => {
                    const eng = parseInt(row.item.total_engagements, 10);
                    const reach = parseInt(row.item.social.followers_count, 10);
                    let pct = null;

                    if (eng && reach) {
                        pct = 100 * eng / reach;
                    }

                    return <BasicCell row={row} column={column}
                                      value={pct ? (formatNumber(pct, 2) + '%') : '-' }
                    />
                },
            },
            {
                key: 'counts.totals.facebook_comment_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Facebook Comments",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column} value={row.item.counts.totals.facebook_comment_count}/>,
            },
            {
                key: 'counts.totals.facebook_reaction_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Facebook Reactions",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column} value={row.item.counts.totals.facebook_reaction_count}/>,
            },
            {
                key: 'counts.totals.facebook_share_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Facebook Shares",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column} value={row.item.counts.totals.facebook_share_count}/>,
            },
            {
                key: 'counts.totals.instagram_comment_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Instagram Comments",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   key={makeCellKey(row, column)}
                                                   value={row.item.counts.totals.instagram_comment_count}/>,
            },
            {
                key: 'counts.totals.twitter_retweet_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Retweets",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   key={makeCellKey(row, column)}
                                                   value={row.item.counts.totals.twitter_retweet_count}/>,
            },
            {
                key: 'counts.totals.twitter_favorite_count',
                default: true,
                sortable: true,
                sortDir: 'desc',
                title: "Twitter Favorites",
                width: 180,
                cell: (row, column) => <NumberCell row={row} column={column}
                                                   key={makeCellKey(row, column)}
                                                   value={row.item.counts.totals.twitter_favorite_count}/>,
            },
        ];
    }

    render() {
        const {user} = this.props;
        const socials = (user || {}).socials || [];

        return (

            <Table
                items={socials}
                columns={this.getColumns()}
                showSort={false}
                showLoadingAlert={false}
                showTotals={false}
                showFilters={false}
            />

        );
    }

}


export default HBLeaderboardExpando;