import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {PieChart, Pie, Cell} from 'recharts';


export default class TinyProgressPie extends Component {

    static defaultProps = {
        size: 20,
        progress: 0.0,
        style: {},
        colors: [
            '#66CC00',
            '#DDDDDD',
        ],
        donut: false,
    };

    static propTypes = {
        progress: PropTypes.number,
        size: PropTypes.number,
        style: PropTypes.object,
        colors: PropTypes.array,
        donut: PropTypes.bool,
    };

    render() {

        const {colors} = this.props;

        const data = [
            {name: 'Complete', value: this.props.progress},
            {name: 'Incomplete', value: (1 - this.props.progress)},
        ];

        const startAngle = 90;
        const endAngle = startAngle + 360;
        const defaultStyle = {
            display: 'inline-block'
        };
        const style = {...defaultStyle, ...this.props.style};

        let classNames = ['tiny-progress-pie'];
        if (this.props.donut) {
            classNames.push('donut');
        }

        return (
            <div style={style} className={classNames.join(' ')}>
                <PieChart width={this.props.size} height={this.props.size}>
                    <Pie
                        isAnimationActive={false}
                        data={data}
                        dataKey={'value'}
                        innerRadius={this.props.donut ? (this.props.size / 4) : 0}
                        outerRadius={(this.props.size / 2)}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        legendType={'none'}
                    >
                        {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index]} />
                            ))
                        }
                    </Pie>
                </PieChart>
            </div>

        );
    }
}