import React, {Component} from 'react';
import PropTypes from 'prop-types';
import NavigationButtons from "../../Common/DetailView/NavigationButtons";
import {findFirstValidImage, getIconAndTypeForPost, getImageUrl} from "../../../utilities";
import {getTierName} from "../../../utilities/user";
import SocialHeader from "../../Common/DetailView/SocialHeader";
import MiniProfile from "../../Common/MiniProfile";
import he from 'he';
import PostDetailActionsMenu from "./ActionsMenu";
import Tooltip from "../../Common/Tooltip";

class PostDetailHeader extends Component {
    static propTypes = {
        post: PropTypes.object,
        showMenu: PropTypes.bool,
        handleNavigation: PropTypes.func,
        onClose: PropTypes.func,
        hasOverridePermission: PropTypes.bool,
        isRunningAction: PropTypes.bool,
        sections: PropTypes.array,
        onToggleMenu: PropTypes.func,
        onToggleItem: PropTypes.func,
        onPublishChannel: PropTypes.func,
        onFeature: PropTypes.func,
        onBump: PropTypes.func,
        onMarkSpam: PropTypes.func,
        onActionStarted: PropTypes.func,
        onActionFinished: PropTypes.func,
        onOpenUser: PropTypes.func,
    };

    state = {
        avatarUrl: undefined,
        didCheckImages: false,
    };

    componentDidMount() {
        this.checkImages().then(() => {});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (((prevProps.post || {}).images || []).length !== ((this.props.post || {}).images || []).length) {
            this.setState({didCheckImages: false}, () => this.checkImages());
        }
    }

    async checkImages() {
        if (this.state.didCheckImages) return;

        const { images, user } = this.props.post;
        const imageArr = (images || []).map(image => {
            return {
                url: getImageUrl(image),
                id: image.mongo_id,
            }
        });
        const userImage = ((user || {}).profile || {}).picture || {};
        const userImageUrl = userImage.url;
        const imageUrls = imageArr.map(imageObj => imageObj.url);
        const avatarUrl = (await findFirstValidImage(imageUrls)) || userImageUrl;

        this.setState({
            avatarUrl,
            didCheckImages: true,
        });
    };

    renderMidHeader() {
        const { post } = this.props || {};
        const { engagements, views } = post || '';
        const type = post.type || 'blog';
        const { stats, user } = post || {};
        const { tier, verified_reach, twitter_reach, instagram_followers, youtube_subscribers, facebook_followers, pinterest_followers, blog_traffic } = user || '';
        const getPostReach = (type) => {
            switch(type) {
                case 'twitter':
                    if (twitter_reach) return twitter_reach;
                case 'instagram':
                    if (instagram_followers) return instagram_followers;
                case 'youtube':
                    if (youtube_subscribers) return youtube_subscribers;
                case 'facebook':
                    if (facebook_followers) return facebook_followers;
                case 'pinterest':
                    if (pinterest_followers) return pinterest_followers;
                case 'blog':
                    if (blog_traffic) return blog_traffic;
                case 'post':
                case null:
                    if (post.permalink && blog_traffic) return blog_traffic;
                default:
                    return verified_reach;
            }
        };
        const primaryTitle = engagements ? 'Engagements' : 'Reach';
        const primaryValue = engagements || getPostReach(post.type);
        const { icon } = getIconAndTypeForPost(type);
        const tierName = tier ? getTierName(tier) : null;
        const getValue = (type) => stats[type] || [].length ? (stats[type].slice(-1)[0] || {}).value : null;
        const comments = {
            blog: getValue('Blog Comments'),
            facebook: getValue('Facebook Comments'),
            instagram: getValue('Instagram Comments'),
            pinterest: getValue('Pinterest Comments'),
        };
        const likes = {
            twitter: getValue('Twitter Favorites'),
            facebook: getValue('Facebook Reactions'),
            instagram: getValue('Instagram Likes'),
            pinterest: getValue('Pinterest Likes'),
        };
        const shares = {
            facebook: getValue('Facebook Shares'),
            twitter: getValue('Twitter Retweets'),
            pinterest: getValue('Pinterest Re-Pins'),
        };
        const totalShares = (Number(shares.facebook) || 0)
            + (Number(shares.twitter) || 0)
            + (Number(shares.pinterest) || 0);

        return <SocialHeader
            primary={{
                title: primaryTitle,
                value: primaryValue
            }}
            secondary={[
                {
                    title: 'impressions',
                    value: views,
                    icon,
                },
                {
                    title: 'comments',
                    value: comments[type],
                },
                {
                    title: 'likes',
                    value: likes[type],
                },
                {
                    title: 'shares',
                    value: totalShares,
                },
                {
                    title: 'tier',
                    value: tierName,
                },
                primaryValue === verified_reach
                    ? null
                    : {
                        title: 'user reach',
                        value: verified_reach,
                    },
            ]}
        />
    };
    renderNavigation() {
        const {onClose, handleNavigation} = this.props;
        if (!onClose && !handleNavigation) {
            return null;
        }
        return <NavigationButtons
            onClose={onClose}
            handleClickForward={handleNavigation ? handleNavigation.bind(null, 1) : undefined}
            handleClickBackward={handleNavigation ? handleNavigation.bind(null, -1) : undefined}
        />;
    };

    renderMiniProfile() {
        const { isFetchingPost } = this.state;
        const { post } = this.props;
        const { title } = post || '';
        const cleanTitle = isFetchingPost ? <i className='v3 icon spinner'/> : (he.decode(title || '') || 'Untitled Content');
        const { user } = post || {};
        const { name, id } = user || '';
        const cleanName = isFetchingPost ? <i className='v3 icon spinner'/> : name;
        const { icon, type } = getIconAndTypeForPost(post.type);
        const userLink = <a className="dark" role="button" onClick={this.props.onOpenUser}>{cleanName}</a>;
        const primary = <span className='v3 h7 bold dark'><Tooltip title={title}>{cleanTitle}</Tooltip></span>;
        const secondary = <>
            <div
                style={{maxWidth: '100%',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}>
                <i className={icon} />{type} by {userLink}
            </div>
            {/*<StarRating rating={post.rating} onRatingChange={this.handleRatingChange.bind(this)} />*/}
            {/*hide star rating as per matt*/}
        </>;

        return <MiniProfile
            primary={primary}
            secondary={secondary}
            imageUrl={this.state.avatarUrl}
        />
    };

    renderMenu() {
        return <PostDetailActionsMenu
            post={this.props.post}
            sections={this.props.sections}
            hasOverridePermission={this.props.hasOverridePermission}
            onToggleMenu={this.props.onToggleMenu}
            showMenu={this.props.showMenu}
            onToggleItem={this.props.onToggleItem}
            onPublishChannel={this.props.onPublishChannel}
            onFeature={this.props.onFeature}
            onBump={this.props.onBump}
            onMarkSpam={this.props.onMarkSpam}
            isRunningAction={this.props.isRunningAction}
        />;
    };


    render() {
        let classes = ['post-detail-actions'];

        if (this.props.showMenu) {
            classes.push('cover')
        }

        return (
            <header className="post-detail-header">
                <div className={classes.join(' ')}>
                    {this.renderMenu()}
                    {this.renderNavigation()}
                </div>
                {this.renderMiniProfile()}
                {this.renderMidHeader()}
            </header>
        );

    }
}

export default PostDetailHeader;