import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class HTML extends Component {

    static defaultProps = {
        classes: [],
        style: {},
    };

    static propTypes = {
        classes: PropTypes.array,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        style: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
        this.textarea = null;
        this.redactor = null;
    }

    componentDidMount() {
        if (this.textarea) {
            const $self = this;
            this.redactor = window.Gator.setupOneRedactor(this.textarea);
            this.redactor.on('change.callback.redactor', function() {
                const value = this.code.get();
                $self.setState({value}, () => {
                    if ($self.props.onChange) {
                        $self.props.onChange(value);
                    }
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.redactor || !this.redactor.redactor) return;

        if (
            prevProps.value !== this.props.value
            && this.props.value !== this.state.value
        ) {
            this.setState({value: this.props.value}, () => {
                this.redactor.redactor('code.set', this.state.value);
            });
        }
    }

    render() {
        let classes = ['form-control', ...this.props.classes];
        return (
            <textarea
                className={classes.join(' ')}
                placeholder={this.props.placeholder}
                name={this.props.name}
                value={this.props.value}
                style={this.props.style}
                ref={(el) => this.textarea = el}
            />
        );
    }
}