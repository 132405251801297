import React, {useState, useEffect} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchAuthenticated} from "../../actions/auth";
import PublicPortfolioCard from "../List/PublicPortfolioCard";
import {scrollToBottomThenTop, getImageDimensions} from "../../utilities";
import {jsPDF} from "jspdf";
import * as htmlToImage from "html-to-image";
import Toolbox from "../Common/Toolbox";
import TinyThermometer from "../Common/TinyThermometer";

const DEFAULT_SETTINGS = {
    show_top_posts: true,
    show_portfolio_images: true,
    show_audience: true,

    show_audience_gender: true,
    show_audience_age: true,
    show_audience_credibility: true,

    show_audience_brands: false,
    show_audience_language: false,
    show_audience_ethnicity: false,
    show_audience_interests: false,
    show_audience_countries: false,
    show_audience_cities: false,
    show_audience_states: false,
};

function PDFExporter({userIds, fetchAuthenticated, onComplete, settings = {}}) {
    const [isPrinting, setIsPrinting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [didLoad, setDidLoad] = useState(false);
    const [printProgressMax, setPrintProgressMax] = useState((userIds || []).length * 2);
    const [printProgress, setPrintProgress] = useState(0);
    const [users, setUsers] = useState([]);

    const fetchAllUsers = () => {
        setIsLoading(true);
        const url = '/manage/api/user/_/public?ids=' + encodeURIComponent(userIds.join(','));
        return fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => {
                setUsers(json.data);
                setPrintProgress(json.data.length);
                setIsLoading(false);
                setDidLoad(true);
            });
    };


    const renderUsers = () => (users || []).map(user => {
        const profile = user;

        if (!profile) {
            return null;
        }

        profile.settings = {
            ...DEFAULT_SETTINGS,
            ...settings
        };

        return <PublicPortfolioCard key={user.id} printMode={true} item={profile} audienceChartLimit={20} />
    }).filter(p => !!p);

    const printToPdf = async () => {

        if (isPrinting) {
            return;
        }
        setIsPrinting(true);

        // scroll down and back up to trigger all image loads
        await scrollToBottomThenTop();
        const els = document.getElementsByClassName('list-portfolio-card-wrapper');

        // set progress flag
        // setPrintProgressMax(prevMax => prevMax + els.length);
        // setPrintProgress(0);

        let fontEmbedCss = undefined;
        const pdf = new jsPDF({unit: "px"});
        pdf.deletePage(1);


        for (const el of els) {

            if (!fontEmbedCss) {
                try {
                    fontEmbedCss = await htmlToImage.getFontEmbedCSS(el);
                } catch (e) { }
            }

            try {
                const imgData = await htmlToImage.toJpeg(el, {
                    width: 1400,
                    canvasWidth: 1400,
                    backgroundColor: '#FFFFFF',
                    fontEmbedCSS: fontEmbedCss
                });
                const [imgW, imgH] = await getImageDimensions(imgData);
                // window.open(imgData, '_blank');
                pdf.addPage([imgW, imgH], imgW > imgH ? 'landscape' : 'portrait');
                pdf.addImage(imgData, 'JPEG', 0, 0, imgW, imgH);
            } catch (err) {
                // No op
            }
            setPrintProgress(prevProgress => prevProgress + 1);

        }

        pdf.save();
        setPrintProgress(0);

        if (onComplete) {
            onComplete();
        }

    };

    useEffect(function() {
        if (!isLoading && !didLoad) {
            fetchAllUsers().then(() => printToPdf());
        }
    }, []);

    return (
        <div className="user-pdf-exporter">
            <div className="user-pdf-exporter-blackout" style={{}}>
                <Toolbox
                    title="Saving PDF"
                    content={<div style={{padding: 20}}>
                        <div style={{marginBottom: 20}}>
                            Generating your PDF. Please wait, this could take a while.
                        </div>
                        <div>
                            <TinyThermometer pct={100 * (printProgress / printProgressMax)} />
                        </div>
                    </div>}
                    addlClasses={"toolbox-fixed toolbox-sm"}
                />
            </div>
            <div id="public-list-wrapper" className="printable">
                {renderUsers()}
            </div>
        </div>
    )
}

const mapStateToProps = undefined;
const mapDispatchToProps = (dispatch) => bindActionCreators({fetchAuthenticated}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PDFExporter);