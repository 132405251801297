import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../actions/auth";
import moment from "moment";
import {formatNumber, ucwords} from "../../utilities";
import he from 'he';
import Tooltip from "../Common/Tooltip";
import Form from "../Common/Form/Form";
import Toolbox from "../Common/Toolbox";
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";

const cleanLabel = (label) => ucwords(label.replace(/[-_]/g, ' '));
const niceNameForDriverType = (driverType) => {
    switch (driverType) {
        case 'Tidal\\Post\\Driver\\ManualStatsDriver': return 'Manually Entered Stats';
        case 'Tidal\\Post\\Driver\\InstastoryStatsDriver': return 'Instastory API';
        case 'Tidal\\Post\\Driver\\InstagramStatsDriver': return 'Instagram API';
        case 'Tidal\\Post\\Driver\\TwitterStatsDriver': return 'Twitter API';
        case 'Tidal\\Post\\Driver\\GoogleAnalyticsPageviewsDriver': return 'Google Analytics API';
        case 'Tidal\\Post\\Driver\\PinterestStatsDriver': return 'Pinterest API';
        case 'Tidal\\Post\\Driver\\YoutubeStatsDriver': return 'YouTube API';
        case 'Tidal\\Post\\Driver\\TikTokStatsDriver': return 'TikTok';
        case 'Tidal\\Post\\Driver\\UrlSharesDriver': return 'URL Share Scanner';
        case 'Tidal\\Post\\Driver\\FacebookStatsDriver': return 'Facebook API';
        case 'Tidal\\Post\\Driver\\TidalVotesDriver': return 'Tidal CMS Votes Tracker';
        case 'Tidal\\Post\\Driver\\TidalHitsDriver': return 'Tidal CMS Engagements Tracker';
        default: return driverType;
    }
};

function SingleStat({icon, label, history, onClick, isOverridden=false}) {
    const historyLength = (history || []).length;
    const latestHistory = historyLength > 0 ? history[historyLength-1] : null;

    if (!latestHistory) {
        return null;
    }

    const latestTs = moment(latestHistory.ts);
    const latestValue = parseFloat(latestHistory.value);
    const renderedHistory = (
        <div className="fake-table" style={{width: 300, maxHeight: 200, overflow: "auto"}}>

            {history.map((item, index) => (
                <div className={"fake-li " + (index < historyLength-1 ? "bottom-border" : "")} key={item.ts} style={{textAlign: "left"}}>
                    <span>{moment(item.ts).format('MMM D YYYY, hh:mm a')}</span>
                    <strong className="pull-right">{formatNumber(item.value)}</strong>
                </div>
            ))}

        </div>
    );

    return (
        <div className={"driver-stat " + (isOverridden ? 'muted' : '')} onClick={onClick}>
            <Tooltip html={renderedHistory}>
                <div className="stat-icon">{icon}</div>
                <div className="stat-label">{label}</div>
                <div className="stat-value">{formatNumber(latestValue)}</div>
                <div className="stat-time">{latestTs.format('MMM D, h:mm a')}</div>
            </Tooltip>
        </div>
    );
}
function SingleDriver({driver, onClickStat, manualDriverStats}) {

    const statsKeys = Object.keys(driver.stats || {}).filter(key => {
        return typeof driver.stats[key] === 'object' && driver.stats[key].length > 0;
    });

    if (statsKeys.length === 0) {
        return null;
    }

    const isManualDriver =  driver.type === 'Tidal\\Post\\Driver\\ManualStatsDriver';

    return (
        <div className="driver">
            <div className="driver-title">Data Source: {niceNameForDriverType(driver.type)}</div>
            <div className="driver-meta">
                {driver.last_update_at && <span>Last Update: {moment(driver.last_update_at).fromNow()}</span>}
            </div>
            <div className="driver-stats">
                {statsKeys.map(statsKey => {
                    const thisSeries = driver.stats[statsKey];
                    const seriesLength = thisSeries.length;
                    const latestValue = seriesLength > 0 ? thisSeries[seriesLength-1].value : 0;
                    const isOverridden = !isManualDriver && manualDriverStats.indexOf(statsKey) > -1;
                    // Don't show 0s on manual drivers
                    if (isManualDriver && parseInt(latestValue, 10) === 0) {
                        return null;
                    }
                    return <SingleStat
                            label={cleanLabel(statsKey)}
                            history={driver.stats[statsKey]}
                            key={statsKey}
                            isOverridden={isOverridden}
                            onClick={(e) => {
                                e.preventDefault();
                                onClickStat({name: statsKey, value: latestValue});
                            }}
                        />
                    }
                )}
            </div>
        </div>
    );
}

function SinglePostRecord({record, drivers, onUpdateStat}) {

    const [editStat, setEditStat] = useState(null);
    const [editStatValue, setEditStatValue] = useState(0);
    const [isSubmitting, setSubmitting] = useState(false);

    const createdMoment = moment(record.created_at);
    const post = record.post || {};
    const manualDrivers = (drivers || []).filter(driver => driver.type === 'Tidal\\Post\\Driver\\ManualStatsDriver');
    const manualDriver = manualDrivers.length > 0 ? manualDrivers[0] : null;
    const manualDriverStats = manualDriver ? Object.keys(manualDriver.stats).filter(statKey => {
        // if there are no stats, return false
        const stats = manualDriver.stats;
        if (!stats[statKey] || stats[statKey].length === 0) {
            return false;
        }

        // Now need to actually check latest stats value to see if it's 0 or not
        const latestStat = parseInt(stats[statKey][stats[statKey].length-1].value, 10);
        return !!latestStat;
    }) : [];

    return (
        <div className="single-post">
            <h3 className="v3 h3 post-title">{he.decode(post.title)}</h3>
            <div className="post-meta">
                <span>{createdMoment.fromNow()}</span>
                <span>Status: {record.status}</span>
            </div>

            <div className="post-drivers">
                {(drivers || []).map(driver => <SingleDriver
                        key={driver.id}
                        driver={driver}
                        manualDriverStats={manualDriverStats}
                        onClickStat={({name, value}) => {
                            setEditStat(name);
                            setEditStatValue(value);
                        }}
                    />
                )}
            </div>

            {editStat && (
                <Toolbox
                    style={{width: 400, marginLeft: -200, left: '50%'}}
                    addlClasses={"toolbox-fixed"}
                    title={`Replace Stats`}
                    onClose={() => {
                        setEditStat(null);
                        setEditStatValue(0);
                    }}
                    content={<Form
                        onFieldChange={(k, v) => setEditStatValue(v)}
                        values={{value: editStatValue}}
                        fields={[
                            {
                                type: 'number',
                                name: 'value',
                                title: `Override "${cleanLabel(editStat)}"`,
                                help: 'Set to 0 to remove this override.'
                            },
                            {
                                type: 'button',
                                name: 'submit',
                                title: isSubmitting ? 'Saving...' : 'Save Stat',
                                options: {
                                    hideTitle: true,
                                    classes: ['v3 btn-primary medium'],
                                    onClick: () => {
                                        setSubmitting(true);
                                        onUpdateStat(editStat, editStatValue)
                                            .then(() => {
                                                setEditStat(null);
                                                setEditStatValue(0);
                                                setSubmitting(false);
                                            })
                                    }
                                }
                            }

                        ]}
                    />}
                />
            )}
        </div>
    );
}

function ViewPostDrivers({activation, fetchAuthenticated, fetchActivation, createActivationInteraction}) {

    const [drivers, setDrivers] = useState(null);
    const postRecords = (activation || {}).postRecords || [];

    const fetchDrivers = async () => {
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/activation/${activation.id}/postdriver`;
        const resp = await fetchAuthenticated(url);
        const json = await resp.json();
        setDrivers(json.data);
    }

    const handleUpdateStat = async (postRecordId, key, value) => {

        const payload = {
            post_record_id: postRecordId,
            [key]: value
        };
        const resp = await createActivationInteraction(activation.id, 'AddStatsToPost', payload);
        await fetchActivation(activation.id);
        return fetchDrivers();
    };

    useEffect(() => {
        fetchDrivers().then(() => {
            // init.
        })
    }, []);

    return (
        <div className="post-drivers-viewer">
            {postRecords.sort((a, b) => b.id - a.id).map(pr => {
                const theseDrivers = (drivers || []).filter(driver => driver.post_mongo_id === pr.post_id);
                return (
                    <SinglePostRecord
                        record={pr}
                        drivers={theseDrivers}
                        key={pr.id}
                        onUpdateStat={(key, value) => {
                            return handleUpdateStat(pr.id, key, value);
                        }}
                    />
                );
            })}
        </div>
    )
}


export default connect(undefined, function (dispatch) {
    return bindActionCreators({
        fetchAuthenticated,
        createActivationInteraction,
        fetchActivation
    }, dispatch);
})(ViewPostDrivers);