import {formatNumber, getIconAndTypeForPost, getImageUrl} from "../../../utilities";
import React from "react";
import _get from 'lodash/get';

function TrendingPostTile({post, engPct}) {
    const image = (post.images || [])[0];
    const imageUrl = getImageUrl(image);
    const formattedEngPct = engPct ? formatNumber(engPct*100, 2) + '%' : null;
    // Instagram engagements are counted twice, so we gotta divide by 2 in that case
    const isIg = (post.type === 'instagram' || post.type === 'instagram-business');
    const adjustedEngagements = isIg ? Math.floor(post.engagements / 2) : post.engagements;
    const formattedEngs = post.engagements ? formatNumber(adjustedEngagements) + ' Engagements' : null;
    const tileStyle = { backgroundImage: `url(${imageUrl})` };
    const handleClick = () => {
        window.open(post.permalink, '_blank');
    };
    const {icon:iconClasses, type:socialNiceName} = getIconAndTypeForPost(post.type)
    const icon = <i className={iconClasses} />;

    let views;

    const driverStatsImpressionKey = post.type + '-impressions';
    const driverStatsReachKey = post.type + '-reach';
    const driverStatsImpressions = _get(post, ['driver_stats', driverStatsImpressionKey]);
    const driverStatsReach = _get(post, ['driver_stats', driverStatsReachKey]);
    const postViews = post.views;

    if (driverStatsImpressions) {
        views = formatNumber(driverStatsImpressions) + ' Views';
    } else if (driverStatsReach) {
        views = formatNumber(driverStatsReach) + ' Views';
    } else if (postViews) {
        views = formatNumber(postViews) + ' Views';
    }

    const shouldShowViews = views && !engPct;

    return (
        <div className="ic-post" style={tileStyle} onClick={handleClick}>
            <div className="ic-post-content">
                <div className="meta-top">
                    <h4>{post.title}</h4>
                    <p className="byline">By {post.user.name}</p>
                </div>
                <div className="meta-bottom">
                    <div>{icon} {formattedEngs}</div>
                    <div>{formattedEngPct}</div>
                    {shouldShowViews && <div>{views}</div>}
                </div>
            </div>
        </div>
    );

}

export default TrendingPostTile;