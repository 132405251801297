/**
 * Created by visgotti on 12/3/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PostTimeSeriesChart from '../Chart/PostTimeSeriesChart';
import {getImageUrl, limitChars} from '../../../utilities';
import Image from '../../Common/Image';

const POST_TABLE_MIN_IMAGE_HEIGHT = 220;
class PostExpandedView extends Component {

    constructor(props) {
        super(props);
        this.handleImageError = this.handleImageError.bind(this);
        this.handleImageLoad = this.handleImageLoad.bind(this);

        // Will be a ref passed to the smart Image loader
        this.imagesViewport = null;
    }


    handleImageLoad(e) {

        if (!e.target) {
            return;
        }

        const height = e.target.naturalHeight;

        if (height < POST_TABLE_MIN_IMAGE_HEIGHT) {
            // targets the <li>
            e.target.parentNode.parentNode.parentNode.style.display = 'none';
        }

    }

    handleImageError(event) {
        try {
            event.target.parentNode.parentNode.parentNode.style.display = 'none';
        } catch(e) {
            console.log(e);
        }
    }

    renderSocialMediaIcon(type) {
        const types = ['twitter', 'instagram', 'facebook', 'pinterest', 'youtube'];

        if (types.indexOf(type) > -1) return <i className={`fa fa-${type}`}></i>

        else return <i className={'fa fa-file-o'}></i>

    }

    renderStats() {
        return (
            <PostTimeSeriesChart
                stats={this.props.stats}
                views={this.props.post.engagements}
                size={"large"}
                showTitle={true}
                height={220}
            />
        )
    }

    renderPhotos() {
        const { images, type, title } = this.props;
        if (images === undefined || images === null || images.length === 0) {
            return (
                <div className="post-content" >
                    <div className="post-content-inner">
                        {this.renderSocialMediaIcon(type)}
                        {limitChars(title)}
                    </div>
                </div>
            )
        } return (
            <div
                className="post-photos"
                ref={(e) => { this.imagesViewport = e; }}
            >
                <ul>
                    {
                        images.map(image => {
                            return(
                                <li>
                                    <div className="post-photo">
                                        <Image
                                            image={image}
                                            onError={this.handleImageError}
                                            onLoad={this.handleImageLoad}
                                            containment={this.imagesViewport}
                                        />
                                    </div>
                                </li>
                            )
                        })
                    }

                    <li>
                        {this.renderStats()}
                    </li>

                    <li>
                        <div className="additional-post-content">
                            <div className="additional-post-content-inner">
                                { this.renderSocialMediaIcon(type) }
                                {limitChars(title)}
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        )
    }

    render() {
        return (
            <div className="expanded-view">
                <div className="photos">
                    { this.renderPhotos() }
                </div>

                <div className="stats">
                    { this.renderStats() }
                </div>
            </div>
        )
    }
}

PostExpandedView.propTypes = {
    // array of image urls to display
    images: PropTypes.array,
    title: PropTypes.string,
    type: PropTypes.string,
    views: PropTypes.number,
    stats: PropTypes.object,
    post: PropTypes.object.isRequired
}

export default PostExpandedView;