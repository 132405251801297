import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from '../Common/Toolbox';

const TOOLS_SPEC = [
    {
        title: "Launch User Campaign Interface",
        key: 'LaunchInfluencerUI',
    },
    {
        title: "Get User Campaign Link",
        key: 'GetInfluencerLink',
    },
    {
        title: 'Quick Add Post',
        key: 'QuickAddPost'
    },
    // {
    //     title: "Add to Another Campaign",
    //     key: 'Reactivate'
    // },
    // {
    //     key: 'ViewHistory',
    //     title: "View all History"
    // },
];

const INTERACTIONS_SPEC = [
    {
        title: "Generate Invoice",
        key: 'GenerateInvoice',
        isValid: (activation) => !!activation.status.is_payment_owed,
    },
    {
        title: "Send Invitation",
        key: 'Invite',
        isValid: (activation) => !activation.status.was_invited,
    },
    {
        title: "Send an Email",
        key: 'SendEmail',
        isValid: (activation) => true,
    },
    {
        title: "Raise a New Issue",
        key: 'RaiseIssue',
        isValid: (activation) => true,
    },
    {
        title: "Add Organic Stats to Post",
        key: 'AddStatsToPost',
        isValid: (activation) => activation.status.content_total_count > 0,
    },
    {
        title: "Add Paid Performance to Post",
        key: 'AddPaidAdStatsToPost',
        isValid: (activation) => activation.status.content_total_count > 0,
    },
    {
        title: "Post Stats Explorer",
        key: 'ViewPostDrivers',
        isValid: (activation) => activation.status.content_total_count > 0,
    },
    {
        title: "Assign Coupon Code",
        key: 'AssignCouponCode',
        isValid: (activation) => !!activation.status.is_coupon_code_required,
    },
    // {
    //     title: "Mark Coupon Code as Accepted",
    //     key: 'ClaimCouponCode',
    //     isValid: (activation) => !!activation.status.is_coupon_code_assigned && !activation.status.is_coupon_code_claimed,
    // },
    // {
    //     title: "Set Shipping Address",
    //     key: 'SubmitShippingAddress',
    //     isValid: (activation) => !activation.status.has_shipping_address,
    // },
    // {
    //     title: "Set Payment Profile",
    //     key: 'SubmitPaymentProfile',
    //     isValid: (activation) => !activation.status.has_payment_profile,
    // },
    {
        title: "Remove from Campaign",
        key: 'DeleteActivation',
        isValid: (activation) => !activation.status.is_payment_sent,
    },
    {
        key: 'UpdateSettings',
        title: 'Customize Settings',
        isValid: (activation) => true,
    },
];

export default class ActivationActionsToolbox extends Component {

    static defaultProps = {
        style: {},
        addlClasses: [],
    };

    static propTypes = {
        activation: PropTypes.object.isRequired,
        style: PropTypes.object,
        onClose: PropTypes.func,
        onClickItem: PropTypes.func,
        addlClasses: PropTypes.array,
    };

    state = {
    };

    getValidItems() {
        return [
            ...TOOLS_SPEC,
            ...INTERACTIONS_SPEC.filter(spec => {
                if (!spec.isValid) {
                    return true;
                }
                return spec.isValid(this.props.activation)
            })
        ];
    }

    handleClickItem(name, event) {
        if (this.props.onClickItem) {
            this.props.onClickItem(name, event);
        }
    }

    renderContent() {
        const items = this.getValidItems();
        return <div style={{overflowY: "auto", maxHeight: 360}}>
            {items.map(spec => {
                return (
                    <div className="fake-li condensed">
                        <a onClick={this.handleClickItem.bind(this, spec.key)} role="button">{spec.title}</a>
                    </div>
                );
            })}
        </div>
    }
    render() {
        const title = this.props.activation.user_name || 'Actions and Tools';

        return (
            <Toolbox
                style={this.props.style}
                content={this.renderContent()}
                title={title}
                onClose={this.props.onClose}
                addlClasses={this.props.addlClasses}
            />
        )

    }
}

