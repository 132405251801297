/**
 * Created by bkroger on 4/26/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InfluencerRequirementsLeftPane from './Pieces/LeftPane';
import InfluencerRequirementsActionPane from './Pieces/ActionPane';
import _find from 'lodash/find';
import _difference from 'lodash/difference';
import RaiseIssueForm from '../../Activation/RaiseIssueForm';
import IssueToolbox from '../../Activation/IssueToolbox';
import Toolbox from "../../Common/Toolbox";
import ClaimShadowUserToolbox from './Pieces/ClaimShadowUserToolbox';
import {isMobile} from '../../../utilities';

import {getVisibleInfluencerRequirementTypes, getCompletedInfluencerRequirementTypes} from "../../../utilities/campaign";
import {calcProfileCompletion} from "../../../utilities/user";

class InfluencerRequirements extends Component {

    static defaultProps = {
        useMobileNav: false,
        useAutoScroll: true,
    };

    static propTypes = {
        activationId: PropTypes.number,
        campaignId: PropTypes.any,
        activation: PropTypes.object,
        campaign: PropTypes.object,
        interactions: PropTypes.array,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
        modalInterface: PropTypes.bool,
        useMobileNav: PropTypes.bool,
        useAutoScroll: PropTypes.bool,
        channel: PropTypes.object,
        fetchPresence: PropTypes.func,
        userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        fetchUserPersonalProfile: PropTypes.func,
        translate: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.wrapperRef = null;

        this.state = {
            currentView: null,

            // Cached requirement key names so that we can compare later in componentDidUpdate
            incompleteRequirementNames: [],
            completeRequirementNames: [],

            isFetchingActivation: false,
            didFetchActivation: false,

            didJustCompleteRequirement: false,
            nextIncompleteRequirement: null,

            createIssue: false,
            showIssues: false,

            isMobile: isMobile(),

            showClaimUserToolbox: false,
            isLoading: true,
            userPersonalProfile: null,
            userProfileMissingFields: [],
        };

        this.handleChangeView = this.handleChangeView.bind(this);
        this.handleInteraction = this.handleInteraction.bind(this);
        this.refreshActivation = this.refreshActivation.bind(this);
        this.handleMobileState = this.handleMobileState.bind(this);
        this.protectedHandleInteraction = this.protectedHandleInteraction.bind(this);
        this.refreshUser = this.refreshUser.bind(this);

    }

    componentDidMount() {
        window.addEventListener('resize', this.handleMobileState);
        window.addEventListener('orientationchange', this.handleMobileState);

        this.setState({isLoading: true});
        Promise.all([
            this.props.fetchPresence(),
            this.props.fetchCampaign(this.props.campaignId),
            this.refreshActivation()
        ])
            .then(() => {
                this.handleInteraction(this.props.activationId, 'ViewActivationPage', {});
            })
            .then(() => this.refreshUser())
            .then(() => this.determineInitialState())
            .then(() => this.setState({isLoading: false}));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleMobileState);
        window.removeEventListener('orientationchange', this.handleMobileState);
    }

    getTranslator() {
        if (this.props.translate) {
            return this.props.translate;
        }
        return (k, v) => v;
    }

    isProfileFieldVisible(fieldName) {
        const channel = this.props.channel || {};
        const skipFields = channel.profile_skip_fields || [];
        return skipFields.indexOf(fieldName) === -1;
    }

    refreshUser() {
        return this.props.fetchUserPersonalProfile(this.props.userId)
            .then(resp => {
                const user = resp.data.results;
                const {pct:completePct, missing:missingFields} = calcProfileCompletion(user || {}, this.isProfileFieldVisible.bind(this));
                this.setState({
                    userPersonalProfile: user,
                    userProfileMissingFields: missingFields
                })
            });
    }

    handleMobileState() {
        return this.setState({
            isMobile: isMobile()
        });
    }

    getToolboxStyle() {
        let style;
        if (this.state.isMobile) {
            style = {
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1001,
            }
        } else {
            style = {
                position: 'fixed',
                top: 60,
                left: '50%',
                width: 500,
                maxWidth: 'calc(100vw - 30px)',
                height: 'auto',
                zIndex: 1001,
                WebkitTransform: 'translateX(-50%)',
                msTransform: 'translateX(-50%)',
                transform: 'translateX(-50%)',
            }
        }
        return style;
    }

    scrollToTop() {
        const wrapper = this.wrapperRef;

        if (wrapper) {
            if (this.props.modalInterface) {
                wrapper.scrollTop = 0;
            } else {
                window.scrollTo(0, wrapper.offsetTop);
            }
        }

    }

    changeViewByKey(key) {

        const user = this.getUserWithMissingFields();
        const views = getVisibleInfluencerRequirementTypes(this.props.activation, user);
        const filtered = views.filter(view => view && view.key === key);

        if (filtered.length > 0) {
            this.handleChangeView(filtered[0]);
        }

    }

    handleChangeView(view) {
        this.setState({
            currentView: view,
            didJustCompleteRequirement: false,
            nextIncompleteRequirement: null,
        });

        if (this.isShadowUser()) {
            this.setState({
                showClaimUserToolbox: true
            });
        }

        if (this.props.useAutoScroll) {
            this.scrollToTop();
        }
    }

    handleInteraction(id, type, context) {
        return this.props.createInteraction(id, type, context)
            .then(resp => {
                this.refreshActivation();
                return resp;
            })
    }

    protectedHandleInteraction(id, type, context) {
        if (this.isShadowUser()) {
            this.setState({showClaimUserToolbox: true});
            return Promise.reject({
                meta: {
                    error: 'Complete your account first!'
                }
            });

        } else {
            return this.handleInteraction(id, type, context);
        }
    }

    refreshActivation() {
        this.setState({isFetchingActivation: true});
        return this.props.fetchActivation(this.props.activationId)
            .then(() => this.setState({isFetchingActivation: false, didFetchActivation: true}));
    }

    isShadowUser() {
        const activation = this.props.activation;
        const user = activation.user || null;

        if (!user) {
            return false;
        }

        return !!user.shadow;

    }

    /**
     * Used in bootstrapping; decides whether the page should jump to a specified view or
     * to load the first incomplete requirement
     */
    determineInitialState() {

        if (window.location.hash.substr(0, 6) === '#view=') {
            const view = window.location.hash.substr(6);
            switch (view) {
                case 'issues':
                    this.setState({showIssues: true});
                    // goto first requirement as well.
                    this.advanceToFirstIncompleteRequirement();

                    break;
                case 'drafts':
                    this.changeViewByKey('SubmitDrafts');
                    break;
                case 'track_shipment':
                    this.changeViewByKey('ReceiveProduct');
                    break;
                default:
                    this.advanceToFirstIncompleteRequirement();
            }

        } else {
            this.advanceToFirstIncompleteRequirement();
        }

        if (this.isShadowUser()) {
            this.setState({showClaimUserToolbox: true});
        }

    }

    getCompleteRequirementKeys(activation, shouldSetState = false) {

        const user = this.getUserWithMissingFields();
        const reqs = getCompletedInfluencerRequirementTypes(activation, user)
            .map(req => req.key);

        if (shouldSetState) {
            this.setState({completeRequirementNames: reqs});
        }
        return reqs;

    }

    getIncompleteRequirementKeys(activation, shouldSetState = false) {

        const user = this.getUserWithMissingFields();
        const reqs = getVisibleInfluencerRequirementTypes(activation, user)
            .filter(req => {
                if (typeof req.isComplete === 'undefined') {
                    return true;
                }
                return !req.isComplete(activation, user);
            })
            .map(req => req.key);

        if (shouldSetState) {
            this.setState({incompleteRequirementNames: reqs});
        }

        return reqs;

    }

    advanceToFirstIncompleteRequirement(force = false) {

        // Not using this here, but it initializes state for us
        this.getCompleteRequirementKeys(this.props.activation, true);
        const user = this.getUserWithMissingFields();

        if (!this.state.currentView || force) {
            const keys = this.getIncompleteRequirementKeys(this.props.activation, true);

            if (typeof keys[0] !== 'undefined') {
                const req = _find(getVisibleInfluencerRequirementTypes(this.props.activation, user), {key: keys[0]});
                this.handleChangeView(req);
            } else {
                const firstReq = this.getCompleteRequirementKeys(this.props.activation, true)[0];
                const view = _find(getCompletedInfluencerRequirementTypes(this.props.activation, user), {key: firstReq});
                this.setState({currentView: view})
            }

        }

    }


    /**
     * Regardless of completion status.
     */
    getNextStep() {

        const user = this.getUserWithMissingFields();
        const reqs = getVisibleInfluencerRequirementTypes(this.props.activation, user);
        const keys = reqs.map(req => req.key);

        if (!this.state.currentView) {
            return reqs[0];
        }

        const currentIndex = keys.indexOf(this.state.currentView.key);

        if (currentIndex === -1) {
            return reqs[0];
        }

        const nextIndex = currentIndex + 1;

        if (typeof reqs[nextIndex] !== 'undefined') {
            return reqs[nextIndex];
        }

        return reqs[reqs.length - 1];



    }

    handleClickNextRequirement(allowBeforeCurrent = false) {
        const {activation} = this.props;
        const {currentView} = this.state;
        const user = this.getUserWithMissingFields();
        const reqs = getVisibleInfluencerRequirementTypes(activation, user);

        let isAfterCurrent = false;
        let foundSomething = false;

        for (let i in reqs) {
            const req = reqs[i];
            const isComplete = (req && req.isComplete) ? req.isComplete(activation, user) : false;

            if ((isAfterCurrent || allowBeforeCurrent) && !isComplete) {
                this.handleChangeView(req);
                foundSomething = true;
                break;
            }

            if (!isAfterCurrent && currentView && req.key === currentView.key) {
                isAfterCurrent = true;
            }
        }

        // We didn't find anything, so go to last.
        if (!foundSomething) {
            if (!allowBeforeCurrent) {
                this.handleClickNextRequirement(true);
            } else {
                this.handleChangeView(this.getNextStep());
            }
        }

    }


    renderIssuesPopups() {

        if (this.state.createIssue) {
            return this.renderCreateIssue();
        }

        if (this.state.showIssues) {
            return this.renderIssuesToolbox();
        }

    }

    renderCreateIssue() {

        return <Toolbox
            onClose={() => this.setState({createIssue: false})}
            style={this.getToolboxStyle()}
            title={this.getTranslator()('campaign.create_issue.toolbox.title', 'Create an Issue')}
            content={
                <RaiseIssueForm
                    activation={this.props.activation}
                    onClose={() => this.setState({createIssue: false})}
                />
            }
        />
    }

    renderIssuesToolbox() {
        return <IssueToolbox
            onClose={() => this.setState({showIssues: false})}
            style={{
                position: 'fixed',
                top: 60,
                width: 1200,
                left: '50%',
                marginLeft: -600,
                height: 600,
                zIndex: 300,
            }}
            activation={this.props.activation}
            showComments={true}
            toolboxTitle={this.getTranslator()('campaign.issues.toolbox.title', 'All Issues')}
            issues={this.props.activation.issues || []}
        />

    }

    renderClaimUserInterstitial() {

        const {activation = {}, campaign = {}} = this.props;
        const {user} = activation;

        if (!this.state.showClaimUserToolbox) {
            return null;
        }

        if (!user || !user.shadow) {
            return null;
        }

        return (
            <div
                className="blackout"
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0,0,0,0.6)',
                    zIndex: 1000
                }}
            >
                <ClaimShadowUserToolbox
                    activation={activation}
                    campaign={campaign}
                    user={user}
                    onClose={() => this.setState({showClaimUserToolbox: false})}
                    style={this.getToolboxStyle()}
                />
            </div>
        );

    }

    getUserWithMissingFields() {
        return {
            ...this.state.userPersonalProfile,
            missingFields: this.state.userProfileMissingFields,
        };
    }

    render() {

        const {campaign, activation, useMobileNav} = this.props;
        const user = this.getUserWithMissingFields();

        // const modalClass = typeof this.props.modalInterface !== 'undefined'
        //     && this.props.modalInterface === true ? 'modal-interface' : '';

        // const modalInterface = typeof this.props.modalInterface !== 'undefined'
        //     && this.props.modalInterface === true;

        let classes = ['influencer-requirements-wrapper'];

        if (typeof this.props.modalInterface !== 'undefined' && this.props.modalInterface === true) {
            classes.push('modal-interface');
        }

        if (this.state.isMobile) {
            classes.push('mobile');
        }

        const {currentView} = this.state;
        const isCurrentComplete = (currentView && currentView.isComplete) ? currentView.isComplete(activation, user) : false;

        return(
            <div className={classes.join(' ')} ref={el => this.wrapperRef = el}>
                {this.renderClaimUserInterstitial()}
                <div className="row clearfix">

                    <InfluencerRequirementsLeftPane
                        campaign={campaign}
                        activation={activation}
                        onViewChange={this.handleChangeView}
                        currentView={this.state.currentView}
                        refreshActivation={this.refreshActivation.bind(this)}
                        onClickShowIssues={() => this.setState({showIssues: !this.state.showIssues})}
                        onClickCreateIssue={() => this.setState({createIssue: !this.state.createIssue})}
                        createInteraction={this.protectedHandleInteraction}
                        useMobileNav={useMobileNav}
                        refreshUser={this.refreshUser}
                        user={user}
                        translate={this.getTranslator()}
                    />


                    <InfluencerRequirementsActionPane
                        campaign={campaign}
                        activation={activation}
                        refreshActivation={this.refreshActivation.bind(this)}
                        view={this.state.currentView}
                        showRequirementCompleteAlert={isCurrentComplete}
                        onClickNextRequirement={() => this.handleClickNextRequirement()}
                        createInteraction={this.protectedHandleInteraction}
                        refreshUser={this.refreshUser}
                        user={user}
                        translate={this.getTranslator()}
                    />

                    {this.renderIssuesPopups()}

                </div>
            </div>
        )
    }

}
export default InfluencerRequirements;
