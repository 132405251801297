import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {makeMomentFromDate} from "../../utilities";
import Form from "./Form/Form";

class NotesView extends Component {
    static propTypes = {
        notes: PropTypes.arrayOf(PropTypes.shape({
            note: PropTypes.string,
            created_at: PropTypes.any,
            created_by: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string
            })
        })),

        isSubmitting: PropTypes.bool,
        onSaveNote: PropTypes.func,
        onRemoveNote: PropTypes.func,
    };

    state = {
        note: ''
    };

    renderNote(note) {
        const onRemoveNote = this.props.onRemoveNote;
        const date = makeMomentFromDate(note.created_at);

        return (
            <div className="fake-li padded bottom-border" style={{position: "relative"}} key={note.id}>
                <strong>{note.created_by.name}</strong> <em>{date.format('MMM Do, YYYY')}</em>
                <br />
                {note.note}
                {onRemoveNote && (
                    <a
                        role="button"
                        className=""
                        onClick={(e) => {
                            e.preventDefault();
                            onRemoveNote(note);
                        }}
                        style={{
                            position: "absolute",
                            right: 0,
                            top: 18,
                        }}
                    >
                        <i className="v3 icon close" />
                    </a>
                )}
            </div>
        );

    }

    renderNotes() {

        return (
            <div className="notes-list">

                {
                    this.props.notes.length > 0
                    ? this.props.notes.map(note => this.renderNote(note))
                    : <div className="fake-li padded bottom-border"><em>No notes yet... try adding one!</em></div>
                }

            </div>
        );

    }

    renderForm() {
        if (!this.props.onSaveNote) {
            return null;
        }

        return (
            <Form
                onFieldChange={(key, value) => this.setState({[key]: value})}
                values={{note: this.state.note}}
                fields={[
                    {
                        type: 'textarea',
                        name: 'note',
                        placeholder: 'Add your note here...'
                    },
                    {
                        type: 'button',
                        name: 'submit',
                        title: 'Save Note',
                        options: {
                            classes: ['v3', 'btn-primary', 'medium', (this.props.isSubmitting ? 'disabled' : '')],
                            hideTitle: true,
                            onClick: this.handleSubmit.bind(this)
                        }
                    }
                ]}
            />
        );
    }

    handleSubmit() {
        if (this.props.isSubmitting) {
            return;
        }

        if (this.props.onSaveNote) {
            this.props.onSaveNote(this.state.note);
        }
    }

    render() {

        return (
            <div className="notes-view">
                {this.renderNotes()}
                {this.renderForm()}
            </div>
        );

    }
}

export default NotesView;
