/**
 * Created by bkanber on 8/1/17.
 */

import React, {Component} from 'react';
import {formatNumber} from '../../../../utilities';

export default class CampaignActivationStatsTableRowSocialCell extends Component {

    render() {
        const {posts, reach, social, engagements} = this.props;

        const style = {textAlign: 'left', padding: 0};
        const engagementPct = (engagements && reach) ? (100 * engagements / reach) : null;

        return (
            <td style={style} className={social + " activations-stats-table-td"}>
                <table className="table table-condensed table-striped" style={{margin: 0}}>
                    <tbody>
                    <tr>
                        <td>{posts ? formatNumber(posts) : 0} <small>Posts</small> </td>
                    </tr>
                    <tr>
                        <td>{reach ? formatNumber(reach) : 'N/A'} <small>Reach</small> </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{engagements ? formatNumber(engagements) : 'N/A'}</strong> <small>Engagements {engagementPct ? `(${formatNumber(engagementPct, 1)}%)` : ''}</small>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        )

    }
}

