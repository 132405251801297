import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TinyThermometer from "./TinyThermometer";
import Tooltip from "./Tooltip";
import _find from 'lodash/find';

class GenericProfilePerformanceThermometer extends Component {
    static propTypes = {
        score: PropTypes.numeric,
        results: PropTypes.arrayOf(PropTypes.shape({
            weighted: PropTypes.number,
            normalized: PropTypes.number,
            value: PropTypes.number,
            name: PropTypes.string,
            description: PropTypes.string,
            field: PropTypes.string,
            min: PropTypes.number,
            max: PropTypes.number,
            weight: PropTypes.number,
        })).isRequired,
        isVerified: PropTypes.bool,
    };

    static defaultProps = {
        score: 0,
        isVerified: false,
    };

    renderResult(result, withBorder = true) {
        let value = 'Average';
        if (result.normalized > 0.75) {
            value = 'High';
        }
        if (result.normalized < 0.25) {
            value = 'Low';
        }
        return (
            <div className={"fake-li condensed " + (withBorder ? 'bottom-border' : '')}>
                <span style={{marginRight: 20}}>{result.name}</span>
                <span className="pull-right">
                    {/*<strong>{Math.round(100 * result.normalized)}%</strong>*/}
                    <strong>{value}</strong>
                </span>
            </div>
        );
    }
    renderTooltipContent() {

        if (!this.props.results) {
            return;
        }

        return (
            <div className="fake-table" style={{textAlign: 'left'}}>
                { this.props.results.map((result, index) => this.renderResult(result, index < this.props.results.length - 1)) }
            </div>
        );

    }

    getThermometerClasses() {
        let classes = [];
        const completedCampaignsScore = _find(this.props.results, {field: 'completed_activations_count'});

        if (this.props.isVerified) {
            classes = ['green-secondary'];
        }

        if (completedCampaignsScore && completedCampaignsScore.normalized > 0) {
            classes = ['green'];
        }

        return classes;
    }

    render() {

        const pct = Math.round(this.props.score * 100);

        return (
            <Tooltip
                html={this.renderTooltipContent()}
            >
                <TinyThermometer
                    pct={pct}
                    addlClasses={this.getThermometerClasses()}
                />
            </Tooltip>
        );
    }
}

export default GenericProfilePerformanceThermometer;
