import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatNumber, formatNumberK, limitChars, makeMomentFromDate, preventDefaultIfPossible} from "../../utilities";
import {socialIconForType, socialNameForType, socialSlugForObject} from "../../utilities/social";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../actions/auth";
import {connect} from "react-redux";
import GenericTimeseriesAreaChart from "../Chart/GenericTimeseriesAreaChart";
import RechartsTimeseriesAreaChart from "../Chart/RechartsTimeseriesAreaChart";
import {linearInterpolateSeriesToTargetCount} from "../../utilities/stats";
import Tooltip from "../Common/Tooltip";

class SocialProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showToolbox: false,
            stats: null,
            isLoadingStats: false,
        };
        this.handleToggleToolbox = this.handleToggleToolbox.bind(this);
    }

    componentDidMount() {
        this.fetchStats();

    }
    fetchStats() {
        const {social} = this.props;
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/social/${social.id}/stat`;

        this.setState({isLoadingStats: true});

        this.props.fetchAuthenticated(url)
            .then(response => response.json())
            .then(json => {
                this.setState({
                    stats: linearInterpolateSeriesToTargetCount(json.data, 5),
                    isLoadingStats: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error,
                    isLoadingStats: false,
                });
            });
    }
    handleToggleToolbox(e) {
        preventDefaultIfPossible(e);
        this.setState({showToolbox: !this.state.showToolbox});
    }

    renderProfileMeta() {

        const {social} = this.props;
        const niceSlug = socialSlugForObject(social);
        const shortSlug = limitChars(niceSlug, 14);

        return (
            <div className="social-user-meta">
                <div className="user-line">
                    <a className="dark social-user-link" href={social.url}>{shortSlug}</a>
                </div>

                <div className="stats-line">
                    {
                        social.followers_count &&
                        <span>{formatNumber(social.followers_count, 0)} followers</span>
                    }
                </div>
            </div>
        );

    }

    renderStatusLi() {
        const {social} = this.props;
        const {broken, crawled_at} = social;

        const crawledAtMoment = crawled_at ? makeMomentFromDate(crawled_at) : null;
        const crawledAtFromNow = crawledAtMoment ? crawledAtMoment.fromNow() : 'Never';
        let tooltipHtml = [<p>Last stats update: {crawledAtMoment ? crawledAtMoment.format('lll') : 'Never'}</p>];
        let valueHtml = [<>{crawledAtFromNow}</>];
        if (broken) {
            tooltipHtml.push(<p>This account is no longer connected, either due to expiry, password change, or permission revocation.</p>);
            valueHtml.push(<i className="v3 icon error" style={{marginLeft: 8}} />);
        }
        return (
            <div className="fake-li bottom-border">
                <span>Last Refresh</span>

                <span className="pull-right">
                    <Tooltip html={tooltipHtml}>{valueHtml}</Tooltip>

                </span>
            </div>
        )
    }
    renderStats() {
        const {social} = this.props;
        const {followers_count, friends_count, posts_count, avg_engagement_pct, location, type, url} = social;
        const socialName = socialNameForType(type);
        const niceSlug = socialSlugForObject(social);

        return (
            <div className="social-stats fake-li-table">

                <div className="fake-li bottom-border">
                    <span>{socialName}</span>
                    <span className="pull-right">
                        <a href={url} target="_blank">@{niceSlug}</a>
                    </span>
                </div>

                {this.renderStatusLi()}

                {
                    !!followers_count ? (
                    <div className="fake-li bottom-border">
                        <span>Followers</span>
                        <span className="pull-right">{formatNumber(followers_count)}</span>
                    </div>
                    ) : null
                }

                {
                    !!friends_count ? (
                    <div className="fake-li bottom-border">
                        <span>Following</span>
                        <span className="pull-right">{formatNumber(friends_count)}</span>
                    </div>
                    ) : null
                }
                {
                    !!posts_count ? (
                    <div className="fake-li bottom-border">
                        <span>Posts</span>
                        <span className="pull-right">{formatNumber(posts_count)}</span>
                    </div>
                    ) : null
                }

                {
                    !!avg_engagement_pct ? (
                    <div className="fake-li bottom-border">
                        <span>Engagement Percent</span>
                        <span className="pull-right">{formatNumber(100*avg_engagement_pct, 2)}%</span>
                    </div>
                    ) : null
                }

                {
                    !!location ? (
                    <div className="fake-li bottom-border">
                        <span>Location</span>
                        <span className="pull-right">{limitChars(location, 24)}</span>
                    </div>
                    ) : null
                }
            </div>
        )

    }

    renderMeta() {

        const {social, showCheckbox, showMenu} = this.props;

        const profilePicture = social.profile_picture_url;
        const iconClass = socialIconForType(social.type);
        const permalink = social.url;

        return (

            <div className="meta">

                {
                    showCheckbox &&
                    <div className="tidal-checkbox"><i className="fa"></i></div>
                }

                <div className="social-user-avatar" style={{backgroundImage: 'url('+profilePicture+')'}}></div>

                <div className="action-icons">
                    <a target="_blank" href={permalink} className={`${iconClass} social-post-permalink`}></a>
                    {
                        showMenu && <a
                            className="social-post-menu"
                            href="#"
                            onClick={this.handleToggleToolbox}><i className="fa fa-bars"></i></a>
                    }
                </div>

                {this.state.showToolbox && this.renderToolbox()}

                {this.renderProfileMeta()}

            </div>
        )
    }

    renderToolbox() {

        const {social} = this.props;
        const {type, url} = social;
        const iconClass = socialIconForType(type);
        const socialName = socialNameForType(type);

        return (
            <div className="social-post-action-toolbox toolbox">

                <div className="fake-li">
                    <a href={url} target="_blank"><i className={iconClass}></i> View User on {socialName}</a>
                </div>

                {this.props.onClickEdit && (
                    <div className="fake-li">
                        <a role="button" onClick={this.props.onClickEdit}><i className="v3 icon pencil" /> Edit Details</a>
                    </div>
                )}

                {this.props.onClickRemove && (
                    <div className="fake-li">
                        <a role="button" onClick={this.props.onClickRemove}><i className="v3 icon ban" /> Remove Social</a>
                    </div>
                )}
            </div>


        );
    }

    renderProfile() {
        const social = this.props.social;
        const profile = social.profile_text;
        if (profile && profile.length > 0) {
            return <div className="content">{profile}</div>
        }
        return null;
    }

    renderCharts() {
        if (!this.state.stats || this.state.isLoadingStats || this.state.stats.length === 0) {
            return null;
        }
        const lastStat = this.state.stats[this.state.stats.length - 1];
        return (
            <div className="charts">
                <RechartsTimeseriesAreaChart
                    height={180}
                    data={this.state.stats}
                    seriesSettings={[
                        {
                            chartType: 'area',
                            type: 'linear',
                            dataKey: 'followers_count',
                            name: `Followers (total ${formatNumberK(lastStat.followers_count)})`,
                            stroke: '#206601',
                            fill: '#206601',
                            fillOpacity: 0.8,
                            stackId: 1
                        },
                    ]}
                    axisSettings={[
                        {
                            axisType: 'x',
                            dataKey: 'created_at',
                            stroke: '#888',
                            interval: 0,
                            tickFormatter: (value) => makeMomentFromDate(value).format('M/D')
                        },
                        {
                            axisType: 'y',
                            yAxisId: 'left',
                            stroke: '#888',
                            tickFormatter: (value) => formatNumberK(value)
                        }
                    ]}
                />
            </div>

        );
    }
    render() {
        return (
            <div className="social-profile-card">

                {this.renderMeta()}
                {this.renderStats()}
                {this.renderProfile()}
                {this.renderCharts()}



            </div>
        )
    }
}

SocialProfile.defaultProps = {
    showCheckbox: false,
    showMenu: true,
};

SocialProfile.propTypes = {
    social: PropTypes.object.isRequired,
    showCheckbox: PropTypes.bool,
    showMenu: PropTypes.bool,
    onClickRemove: PropTypes.func,
    onClickEdit: PropTypes.func,
    fetchAuthenticated: PropTypes.func.isRequired,
};


const mapDispatchToProps = (dispatch) => bindActionCreators({fetchAuthenticated: fetchAuthenticated}, dispatch);
export default connect(null, mapDispatchToProps)(SocialProfile);