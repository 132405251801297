/**
 * Created by visgotti on 11/19/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {c3ColorPattern, formatNumber} from '../../../utilities';
import C3Chart from 'react-c3js';
import moment from 'moment';
import d3 from 'd3';

export default class PostTimeSeriesChart extends Component {

    getStats() {
        const stats = [];
        Object.keys(this.props.stats).map(key => {
            for(var i = 0; i < this.props.stats[key].length; i++) {
                if(!stats[i]) stats[i] = {
                    'date': moment(this.props.stats[key][i].ts).format('YYYY-MM-DD'),
                    'created_at': this.props.stats[key][i].ts,
                };
                stats[i][key] = this.props.stats[key][i].value;
            }
        });
        // so we can get a nice horizontal line instead of just a dot, we duplicate the stat
        // and add a day to the duplicate.
        if(stats.length === 1) {
            const extraStat = { ...stats[0]};
            extraStat['date'] = (moment(extraStat.created_at ).add('1','days')).format('YYYY-MM-DD'),
            stats.push({...extraStat});
        }
        return stats;
    }

    renderSmallChart() {
        const stats = this.getStats();
        if (!stats || stats.length === 0) return null;

        const axis = {
            x: {
                type: 'timeseries',
                show: false
            },
            y: {
                show: false
            }
        };

        const legend = {
            hide: true
        };

        const data = {
            json: stats,
            keys: {
                x: 'date',
                value: Object.keys(this.props.stats),
            },
            type: 'area',
        };

        return <C3Chart
            data={data}
            axis={axis}
            color={c3ColorPattern}
            size={{
                height: this.props.height || 125,
            }}
            point={{show: false}}
            legend={legend}
        />
    }

    renderLargeChart() {
        const stats = this.getStats();
        if (!stats || stats.length === 0) return null;

        const padding = {
            right: 20
        };

        const axis = {
            x: {
                type: 'timeseries',
                show: false
            },
            y: {
                show: true
            }
        };

        const data = {
            json: stats,
            keys: {
                x: 'date',
                value: Object.keys(this.props.stats),
            },
            type: 'area',
        };

        const legend = {
            hide: true
        };

        return <C3Chart
            data={data}
            axis={axis}
            color={c3ColorPattern}
            size={{
                height: this.props.height || 190,
            }}
            legend={legend}
            padding={padding}
            point={{show: false}}
        />
    }

    renderTitleToChart() {
        return  (
            <div className="time-chart-title">
                <span className="badge badge-darkgrey">{ formatNumber(this.props.views) }</span>
                &nbsp;
                <label>Engagements Over Time</label>
            </div>
        )
    }

    renderChart() {
        switch(this.props.size) {
            case 'small':
                return this.renderSmallChart();
            case 'large':
                return this.renderLargeChart();
            default:
                return this.renderLargeChart();
        }
    }

    render() {

        return(
            <div className="time-chart">
                { this.props.showTitle ? this.renderTitleToChart() : null }
                { this.props.stats ? this.renderChart() : null }
            </div>
        )
    }
}

PostTimeSeriesChart.propTypes = {
    views: PropTypes.number,
    stats: PropTypes.object,
    size: PropTypes.oneOf(['small', 'large']),
    height: PropTypes.number,
    showTitle: PropTypes.bool,
}
