/**
 * Created by bkroger on 5/18/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../Common/Alert';

class Fulfilled extends Component {

    static propTypes = {
        activation: PropTypes.object,
        translate: PropTypes.func,
    };

    renderSubmittedPosts() {
        const activation = this.props.activation || {};
        const postRecords = activation.postRecords || activation.post_records || [];
        if (postRecords.length === 0) {
            return null;
        }

        return (
            <>
                <h4 style={{marginTop: 40, marginBottom: 20}}>Your Submitted Posts</h4>
                <ul>
                    {postRecords.map(pr => <li>{pr.post.title}</li>)}
                </ul>
            </>
        );
    }

    render() {

        const _t = this.props.translate || ((k, v) => v);
        const description = _t('campaign.fulfilled.description', "Congratulations! You've finished all the work required of you for this campaign. If payment is owed to you, your payment and invoice will be approved by a brand manager shortly. Please keep an eye out for any emails you receive with regards to this campaign, as your brand manager may find issues with your content and ask you to resolve them before your progress can be marked complete.");
        const alertText = _t('campaign.fulfilled.alert_text', "You have completed all your requirements for this campaign!");
        const titleText = _t('campaign.fulfilled.title', "Campaign Fulfilled");


        return (
            <div className="influencer-page-template campaign-fulfilled">
                <div className="widget">
                    <div className="content padded">

                        <h4 className="v3 bold action-pane-title">{titleText}</h4>

                        <Alert
                            classes={['success']}
                            content={alertText}
                            style={{marginBottom: 20}}
                        />

                        <p className={'v3 light h7 pane-info'}>
                            {description}
                        </p>

                        {this.renderSubmittedPosts()}


                    </div>
                </div>
            </div>
        )
    }
}

export default Fulfilled;
