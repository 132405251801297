/**
 * Created by bkanber on 7/6/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Common/Checkbox';

import {connect} from 'react-redux';
import {
    addUsersToList,
    addUsersToBadge,
    removeUsersFromList,
    removeUsersFromBadge,

    fetchUserLists,
    fetchUserProfile,
    fetchListsIfNeeded,
    fetchBadgesIfNeeded,

} from '../../actions';

import {formatNumber} from '../../utilities';
import moment from 'moment';

class AddUserToList extends Component {

    constructor(props) {
        super(props);

        this.state = { };

        this.handleChangeBadge = this.handleChangeBadge.bind(this);
        this.handleChangeList = this.handleChangeList.bind(this);

    }

    handleChangeList(list) {

        const listId = list.id;
        let promise;

        if (this.userHasList(listId)) {
            promise = this.props.removeFromList(listId);
        } else {
            promise = this.props.addToList(listId);
        }

        promise.then(() => {
            this.props.fetchUserLists();
        });
    }

    handleChangeBadge(badge) {
        const badgeId = badge.id;
        let promise;

        if (this.userHasBadge(badgeId)) {
            promise = this.props.removeFromBadge(badgeId);
        } else {
            promise = this.props.addToBadge(badgeId);
        }

        promise.then(() => {
            this.props.fetchProfile();
        });

    }

    isLoading() {

        const {user} = this.props;
        if (user.isFetchingLists || user.isFetchingProfile) return true;
        return false;
    }

    componentDidMount() {
        this.props.fetchProfile();
        this.props.fetchUserLists();
    }

    userHasBadge(badgeId) {
        const {user} = this.props;
        if ((user.profile.badgeIds || []).indexOf(badgeId) !== -1) return true;
        return false;

    }

    userHasList(listId) {
        const {user} = this.props;
        const listEntries = user.listEntries || [];

        for (const entry of listEntries) {
            if (listId === entry.list_id) {
                return true;
            }
        }

        return false;
    }

    renderBadge(badge) {

        const isSelected = this.userHasBadge(badge.id);
        const isUpdating = typeof badge._isUpdating !== 'undefined' ? badge._isUpdating : false;
        const icon = isUpdating ? <i className="fa fa-spinner fa-spin" /> : null;

        return (
            <div className="fake-li">
                <Checkbox
                    icon={icon}
                    checked={isSelected}
                    label={'Badge: ' + badge.name}
                    onClick={this.handleChangeBadge.bind(this, badge)}
                />
            </div>
        );
    }

    renderList(list) {

        const isSelected = this.userHasList(list.id);
        const isUpdating = typeof list._isUpdating !== 'undefined' ? list._isUpdating : false;
        const icon = isUpdating ? <i className="fa fa-spinner fa-spin" /> : null;

        return (
            <div className="fake-li">
                <Checkbox
                    icon={icon}
                    checked={isSelected}
                    label={list.name}
                    onClick={this.handleChangeList.bind(this, list)}
                />
            </div>
        );
    }

    renderCloseBtn() {
        if (this.props.handleClose) {
            return <a href="#" className="close" onClick={this.props.handleClose}><i className="fa fa-times"></i></a>;
        }

        return null;
    }

    render() {

        const listLinks = Object.values(this.props.listsById.lists)
            .filter(list => !list.smart)
            .filter(list => !list.is_hidden)
            .sort((a, b) => moment(a.created_at.date).isBefore(b.created_at.date) ? 1 : -1)
            .map(list => this.renderList(list));

        const badgeLinks = Object.values(this.props.badgesById.items)
            .map(badge => this.renderBadge(badge));

        const listSelectStyle = {
            marginBottom: '20px',
            maxHeight: this.props.listSelectMaxHeight,
            overflow: 'auto'
        };

        let title = 'Add to List';

        return (

            <div className="toolbox" style={this.props.style}>
                {this.renderCloseBtn()}
                <div className="toolbox-form">
                    <h2>{this.isLoading() ? <i style={{marginRight: 5}} className="fa fa-spinner fa-spin" /> : null}{title}</h2>

                    <div className="list-select" style={listSelectStyle}>
                        {listLinks}
                        {badgeLinks}
                    </div>

                </div>
            </div>

        );
    }
}

AddUserToList.defaultProps = {
    listSelectMaxHeight: 350
};

AddUserToList.propTypes = {
    handleClose: PropTypes.func,
    user: PropTypes.object,
    onUpdate: PropTypes.func,
    fetchProfile: PropTypes.func,
    fetchUserLists: PropTypes.func,
    listsById: PropTypes.object,
    badgesById: PropTypes.object,
    addToList: PropTypes.func,
    removeFromList: PropTypes.func,
    addToBadge: PropTypes.func,
    removeFromBadge: PropTypes.func,
    style: PropTypes.object,
    listSelectMaxHeight: PropTypes.number
};

const mapStateToProps = ({listsById, badgesById}) => {
    return {
        listsById,
        badgesById
    };
}

const mapDispatchToProps = (dispatch, {user}) => {
    const userIds = [user.id];
    return {
        addToList: (listId) => dispatch(addUsersToList(userIds, listId)),
        removeFromList: (listId) => dispatch(removeUsersFromList(userIds, listId)),
        addToBadge: (badgeId) => dispatch(addUsersToBadge(userIds, badgeId)),
        removeFromBadge: (badgeId) => dispatch(removeUsersFromBadge(userIds, badgeId)),

        fetchProfile: () => dispatch(fetchUserProfile(user.id)),
        fetchUserLists: () => dispatch(fetchUserLists(user.id)),
    };
}

const ConnectedAddUserToList = connect(mapStateToProps, mapDispatchToProps)(AddUserToList);

ConnectedAddUserToList.propTypes = {
    user: PropTypes.object.isRequired,
    handleClose: PropTypes.func,
    onUpdate: PropTypes.func,
    style: PropTypes.object,
};

export default ConnectedAddUserToList;
