import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getActivationStatusIcon} from "../../../utilities/campaign";

class ActivationsHoverView extends Component {

    static propTypes = {
        activations: PropTypes.array,
        isLoading: PropTypes.bool,
        showClickForMore: PropTypes.bool,
        fetchActivations: PropTypes.func,
        style: PropTypes.object,
        classes: PropTypes.array,
    };

    static defaultProps = {
        showClickForMore: true,
        style: {},
        classes: [],
    };

    componentDidMount() {
        if (this.props.fetchActivations) {
            this.props.fetchActivations();
        }
    }

    getClassName() {
        const classes = [
            'user-mini-profile-hoverview user-mini-profile-hoverview-activations',
            ...this.props.classes
        ];
        return classes.join(' ');
    }

    render() {

        const activations = this.props.activations
            .sort((a, b) => { //sort archived campaigns last
                if ((a.campaign || {}).archived) return 1;
                else return -1;
            });


        let rendered = activations.map(activation => {
            return (
                <div className="fake-li">
                    <span>{getActivationStatusIcon(activation)} {activation.campaign.name}</span>
                </div>
            );
        });

        if (this.props.isLoading) {
            rendered.push((
                <div className="fake-li">
                    <span><em>Loading...</em></span>
                </div>
            ));
        }

        return (
            <div className={this.getClassName()} style={this.props.style}>
                <h2>Campaigns
                    {this.props.showClickForMore && <small>&nbsp;Click icon for more.</small>}
                </h2>
                {rendered}
            </div>
        );


    }
}

export default ActivationsHoverView;
