/**
 * Created by bkroger on 5/18/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SubmitSocialPost from './SubmitSocialPost';
import SubmitBlogPosts from '../Templates/SubmitBlogPosts';
import _ from 'lodash';
import {preventDefaultIfPossible} from "../../../../utilities";
import SubmitInstastory from "./SubmitInstastory";
import Alert from "../../../Common/Alert";

const translateSocialType = input => {
    switch (input) {

        case 'tiktok':
            return 'Tidal\\Social\\TikTok';
        case 'instagram':
            return 'Tidal\\Social\\Instagram';
        case 'twitter':
            return 'Tidal\\Social\\Twitter';
        case 'pinterest':
            return 'Tidal\\Social\\Pinterest';
        case 'facebook':
            return 'Tidal\\Social\\Facebook';
        case 'facebook-page':
            return 'Tidal\\Social\\FacebookPage';
        case 'youtube':
            return 'Tidal\\Social\\Youtube';
    }
};
class PostsPerChannel extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        campaign: PropTypes.object.isRequired,
        view: PropTypes.object,
        refreshActivation: PropTypes.func,
        createInteraction: PropTypes.func,
        translate: PropTypes.func,
    };


    // This is really just for instastory mode switching.
    state = {manualSubmission: false};
    onClickSubmitManually(e) {
        preventDefaultIfPossible(e);
        this.setState({manualSubmission: true});
    }

    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }
    render() {
        if (this.isBlockedByNDA()) {
            return this.renderNDAAlert();
        }

        const {activation, campaign, refreshActivation, view, createInteraction, translate} = this.props;

        // Instastory won't use the URL submission, it'll switch to manual instastory pane
        const isInstastory = view.metadata && view.metadata.isInstastory;
        const handleClickSubmitManually = isInstastory ? this.onClickSubmitManually.bind(this) : undefined;

        // If we're in instastory manual mode, return that
        if (view.key === 'SubmitInstastory' && isInstastory && this.state.manualSubmission) {
            return <SubmitInstastory {...this.props} />
        }

        if (view.key === 'SubmitBlogPosts') {
            return <SubmitBlogPosts
                    campaign={campaign}
                    activation={activation}
                    createInteraction={createInteraction}
                    translate={translate}
                />;
        }

        let pane = null;

        switch (view.metadata.type) {

            case 'instagram':
            case 'twitter':
            case 'pinterest':
            case 'facebook':
            case 'facebook-page':
            case 'youtube':
            case 'tiktok':
                pane = <SubmitSocialPost
                    campaign={campaign}
                    activation={activation}
                    type={translateSocialType(view.metadata.type)}
                    isInstastory={isInstastory}
                    refreshActivation={refreshActivation}
                    createInteraction={createInteraction}
                    facebookLinkMode={false}
                    translate={translate}
                    onClickSubmitManually={handleClickSubmitManually}
                />
                break;

            default:
                pane = <div>Nothing to do here...</div>
        }

        return pane;

    }

}

export default PostsPerChannel;
