/**
 * Created by bkroger on 10/22/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";

class Password extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
        refreshUser: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {},
            isUpdating: false,
            didUpdate: false,
            hasError: false,
            error: null,
        };
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
            hasError: false,
            error: null,
        });
    }

    handleClickSave() {
        this.setState({isUpdating: true, didUpdate: false});

        this.props.createInteraction(this.props.user.id, 'UpdatePassword', this.state.form)
            .then(resp => {
                if (resp.meta.error) {
                    this.setState({isUpdating: false, hasError: true, error: resp.meta.error})
                } else {
                    this.props.refreshUser(this.props.user.id);
                    this.setState({isUpdating: false, didUpdate: true});
                    setTimeout(() => this.setState({form: {}, didUpdate: false}), 2000);
                }
            });
    }

    getFieldValues() {
        const nativeValues = this.props.user || {};
        const formValues = this.state.form;

        return {...nativeValues, ...formValues};
    }

    renderSaveAlert() {
        const _t = this.props.translate;

        let style = {
            position: 'sticky',
            top: 0,
            zIndex: 200,
        };

        if (this.state.isUpdating) {
            return <Alert
                classes={['info']} content={_t('contribute.profile.saving', "Saving your changes...")} style={style}
            />
        }

        if (this.state.didUpdate) {
            return <Alert
                classes={['success']} content={_t('contribute.profile.saved', "Changes saved!")} style={style}
            />
        }

        if (this.state.hasError) {
            return <Alert
                classes={['danger']} content={this.state.error} style={style}
            />
        }

        if (Object.keys(this.state.form).length > 0) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>{_t('contribute.profile.unsavedchanges', 'You have unsaved changes.')} <a className="v3 bold" onClick={this.handleClickSave.bind(this)} role="button">{_t('contribute.profile.savenow', 'Save now.')}</a></span>}
                    style={style}
                />
            );
        }
    }

    render() {
        const _t = this.props.translate;
        return (
            <div className="pane-inner-content">
                {this.renderSaveAlert()}
                <p className={'v3 light h7 pane-info'}>
                    {_t('contribute.password.encryption', 'Your password is encrypted to the greatest degree possible and can not be decrypted or viewed by anyone.')}
                </p>
                <Form
                    values={this.getFieldValues()}
                    onFieldChange={this.handleFieldChange.bind(this)}
                    fields={[
                        {
                            type: 'password',
                            name: 'old_password',
                            title: _t('contribute.password.current_title', 'Your current password'),
                        },
                        {
                            type: 'password',
                            name: 'password',
                            title: _t('contribute.password.new_title', 'Choose a new password'),
                        },
                        {
                            type: 'password',
                            name: 'password_confirmation',
                            title: _t('contribute.password.confirm_title', 'Type the new password again'),
                        },
                    ]}
                />
            </div>
        );
    }

}

export default Password;