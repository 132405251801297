/**
 * Created by bkroger on 5/2/18.
 */

import React, {Component} from 'react';
import {capitalizeFirstLetter} from '../../../../utilities'

class ProductPreferencesTemplate extends Component {

    renderExistingSelections() {

        const {activation} = this.props;
        const pageResponse = typeof activation !== 'undefined' && typeof activation.page_responses !== 'undefined' && typeof activation.page_responses["Complete Your Profile"] !== 'undefined' ? activation.page_responses["Complete Your Profile"]["Complete Your Profile"] : '';

        let existingSelection = {}, totalSelections = [];

        for (let i=1; i<=3; i++) {
            if (typeof pageResponse['product_option_' + i] !== 'undefined') {
                existingSelection.name = pageResponse['product_option_' + i];
                existingSelection.id = pageResponse['product_option_' + i + '_id'];
                if (typeof pageResponse['product_option_' + i + '_variation_1'] !== 'undefined') {
                    existingSelection.color = pageResponse['product_option_' + i + '_variation_1'];
                }
                if (typeof pageResponse['product_option_' + i + '_variation_2'] !== 'undefined') {
                    existingSelection.size = pageResponse['product_option_' + i + '_variation_2'];
                }

                if (totalSelections.includes(existingSelection) === false) {
                    totalSelections.push(existingSelection);
                }
            }
        }

        if (totalSelections.length > 0) {
            console.log(totalSelections);
        }

        const existingSelections = totalSelections.map(function(selection) {
           return (
               <li><span className="selection-name">{selection.name} </span><span className="selection-color">({capitalizeFirstLetter(selection.color)})</span></li>
           )
        });

        return (
            totalSelections.length > 0 &&

            (<div className="form-group">
                <label><strong>Previous Selections:</strong></label>
                <ul>
                    {existingSelections}
                </ul>
            </div>)
        )

    }


    renderColorOptions(colorOptions) {
        const colors = colorOptions.split(',');

        return colors.map(function(color) {
            return <option value={color}>{capitalizeFirstLetter(color)}</option>
        })
    }


    renderSizeOptions(sizeOptions) {
        const sizes = sizeOptions.split(',');

        return sizes.map(function(size) {
            return <option value={size}>{size}</option>
        })
    }


    renderProducts() {
        const lineItemSheet = typeof this.props.campaign !== 'undefined' && typeof this.props.campaign.settings !== 'undefined' ? this.props.campaign.settings.line_item_sheet : '';

        function parseCSV(str) {
            let arr = [];
            let quote = false;  // true means we're inside a quoted field
            let col, char;

            // iterate over each character, keep track of current row and column (of the returned array)
            for (let row = col = char = 0; char < str.length; char++) {
                let currentChar = str[char], nextChar = str[char+1];  // current character, next character
                arr[row] = arr[row] || [];             // create a new row if necessary
                arr[row][col] = arr[row][col] || '';   // create a new column (start with empty string) if necessary

                // If the current character is a quotation mark, and we're inside a
                // quoted field, and the next character is also a quotation mark,
                // add a quotation mark to the current column and skip the next character
                if (currentChar === '"' && quote && nextChar === '"') { arr[row][col] += currentChar; ++char; continue; }

                // If it's just one quotation mark, begin/end quoted field
                if (currentChar === '"') { quote = !quote; continue; }

                // If it's a comma and we're not in a quoted field, move on to the next column
                if (currentChar === ',' && !quote) { ++col; continue; }

                // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
                // and move on to the next row and move to column 0 of that new row
                if (currentChar === '\r' && nextChar === '\n' && !quote) { ++row; col = 0; ++char; continue; }

                // If it's a newline (LF or CR) and we're not in a quoted field,
                // move on to the next row and move to column 0 of that new row
                if (currentChar === '\n' && !quote) { ++row; col = 0; continue; }
                if (currentChar === '\r' && !quote) { ++row; col = 0; continue; }

                // Otherwise, append the current character to the current column
                arr[row][col] += currentChar;
            }

            return arr;
        }

        const parsedLineItems = parseCSV(lineItemSheet);

        console.log(parsedLineItems);

        return parsedLineItems.map(lineItem => {

            const [
                startDate,
                endDate,
                category,
                name,
                colorOptions,
                gender,
                stock,
                productUrl,
                imageUrl,
                productId,
                sizeOptions
            ] = lineItem;

            const productImageUrl = typeof this.props.campaign.settings.hide_imageless_products !== 'undefined' && this.props.campaign.settings.hide_imageless_products !== 1 && imageUrl === '' ? '//cdn.tid.al/img/Product-Image-Coming-Soon.jpg' : imageUrl;

            const bgImage = {
                backgroundImage: 'url("' + productImageUrl + '")'
            };

            return (
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="product-box" data-product-id={productId} data-product-name={name} style={bgImage}>
                        <div className="caption-area">
                            <input type="checkbox" className="product-select" name="" data-product-id={productId} />
                            &nbsp;{name}
                        </div>
                        <div className="option-area">
                            {
                                colorOptions &&
                                <select className="product-variation-select form-control" data-variation-index="1">
                                    <option value="">Choose a color</option>
                                    {this.renderColorOptions(colorOptions)}
                                </select>
                            }

                            {
                                sizeOptions &&
                                <select class="product-variation-select form-control" data-variation-index="2">
                                    <option value="">Choose a size</option>
                                    {this.renderSizeOptions(sizeOptions)}
                                </select>

                            }

                            {/*
                                If we have a more variations choices, we can continue to add dropdowns and the JS will pick it up
                                <select class="product-variation-select form-control">
                                    <option value="test">test</option>
                                    <option value="test2">test2</option>
                                </select>
                             */}
                            <br />
                            <a className="product-learn-more" href={productUrl} target="_blank"><i className="fa fa-external-link"></i>&nbsp;Learn more</a>
                        </div>
                    </div>
                </div>
            )

        })
    }

    render() {
        return (
            <div className="influencer-page-template product-preferences">
                <div className="widget">

                    <div className="content padded">

                        <h4 className="v3 bold action-pane-title">Product Preferences</h4>

                        <p className="v3 light h7 pane-info">We're thrilled that you'd like to work with us! Before moving on, however,
                            we'll need you to confirm your product preferences below.</p>

                        {this.renderExistingSelections()}

                        <form method="post" action="" id="profile-info-form">

                            <div className="form-group">
                                <label><strong>Choose a Product *</strong><small>(Scroll to see more)</small></label>
                                <div className="product-selection">
                                    {this.renderProducts()}
                                </div>
                            </div>

                            <div className="form-group">
                                <label><strong>Notes</strong></label>
                                <textarea
                                    name="notes"
                                    className="form-control "
                                    placeholder="Write any additional notes here (sizing, color, etc).">
                                </textarea>
                            </div>

                            <button type="submit" className="v3 btn btn-primary medium bold" value="Submit Information">
                                Submit Information
                            </button>

                        </form>
                    </div>

                </div>
            </div>
        )
    }

}

export default ProductPreferencesTemplate;