import * as ActionTypes from './types';
import { makeFormData } from '../utilities';
import {fetchAuthenticated} from '../actions/auth';

export const createChannelInteraction = (type, context) => {
    return (dispatch) => {
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/channel/_/interaction`;

        dispatch({
            type: ActionTypes.CHANNEL_CREATE_INTERACTION,
            interactionType: type,
            context
        });

        return dispatch(fetchAuthenticated(url, {
            credentials: 'include',
            method: 'POST',
            body: makeFormData({
                type,
                context: JSON.stringify(context)
            })
        }))
            .then(resp => resp.json())
            .then(json => {
                dispatch({
                    type: ActionTypes.CHANNEL_CREATED_INTERACTION,
                    meta: json.meta,
                    data: json.data
                });
                return json
            })
            .then(json => dispatch(receiveChannelInteractions(json)));
    }
};

export const receiveChannelInteractions = (resp) => {
    return {
        type: ActionTypes.RECEIVE_CHANNEL_INTERACTIONS,
        data: resp.data,
        meta: resp.meta,
    }
};

// export const fetchChannelSettings = () => {
//     return dispatch => {
//
//         dispatch({
//             type: ActionTypes.CHANNEL_REQUEST_SETTINGS,
//         });
//         const url = '/manage/api/channel/_/setting';
//         return fetchAuthenticated(url)
//             .then(resp => resp.json())
//             .then(json => dispatch(receiveChannelSettings(json)));
//     };
// };
//
// export const receiveChannelSettings = (json) => {
//     return {
//         type: ActionTypes.CHANNEL_RECEIVE_SETTINGS,
//         data: json.data,
//         meta: json.meta,
//     };
// };
