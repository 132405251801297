import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../Common/Form/Form";
import {createChannelInteraction} from "../../actions/channel";
import connect from "react-redux/es/connect/connect";
import {validateUrl} from "../../utilities";
import Alert from "../Common/Alert";

class ManageCatalog extends Component {

    static propTypes = {
        onClose: PropTypes.func,
        refresh: PropTypes.func,
        form: PropTypes.object,
        interactionType: PropTypes.string,
        createInteraction: PropTypes.func,
        catalogId: PropTypes.number,
        showProducts: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            formIsUpdated: false,
            formIsSaving: false,
            formHasSaved: false,
            valid: {},
            form: {
                name: '',
                description: '',
                link_url: '',
                // teams: [],
                // team_id: null,
                product_catalog_id: this.props.catalogId || null
            },
        };
        this.handleEditProductsClick = this.handleEditProductsClick.bind(this);
    };

    componentDidMount(){
        //if this component receives a form prop, then the form will be pre-populated
        const {form} = this.props;
        if(form) {
            this.setState({form})
        }
    }

    setValidationErrors(name, value){
        const {formIsSaving, formHasSaved} = this.state;
        const validUrl = ((!formHasSaved || !formIsSaving) && value.length) ? validateUrl(value) : null;

        this.setState({
            valid: {
                ...this.state.valid,
                [name]: validUrl
            }
        })
    }

    handleFieldChange(name, value) {

        if (name === 'link_url') {
            this.setValidationErrors(name, value);
        };

        this.setState({
            formIsUpdated: true,
            formIsSaving: false,
            formHasSaved: false,
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    }

    handleSave() {
        const { form } = this.state;
        const { interactionType } = this.props;

        this.setState({formIsSaving: true});
        return this.props.createInteraction(interactionType, form)
            .then(() => {
                this.setState({
                    formIsSaving: false,
                    formIsUpdated: false,
                    formHasSaved: true,
                })
            })
            .then(() => {
                this.props.refresh();
                setTimeout(this.props.onClose, 1000)
            })
            .catch(err => console.err);
    }

    handleEditProductsClick(){
        this.props.onClose();
        this.props.showProducts();
    }

    render() {
        const {formHasSaved, formIsSaving, valid} = this.state;
        const invalid = Object.values(valid).indexOf(false) > -1;
        const classes = (formIsSaving || invalid) ? 'v3 btn btn-primary medium bold disabled' : 'v3 btn btn-primary medium bold';
        let title = 'Save';
        if (formIsSaving) title = 'Saving...';
        if (formHasSaved) title = 'Saved!';

        return <Form
            style={{background: '#fff'}}
            usePadding={true}
            onFieldChange={this.handleFieldChange.bind(this)}
            values={this.state.form}
            fields={[
                {
                    type: 'text',
                    name: 'name',
                    title: 'Catalog Name',
                },
                {
                    type: 'textarea',
                    name: 'description',
                    title: 'Catalog Description',
                },
                {
                    type: 'text',
                    name: 'link_url',
                    title: 'Link',
                    help: 'Optional link to a catalog or store page.'
                },
                {
                    type: 'generic',
                    content: (valid['link_url'] === false) && <Alert
                        classes={['danger']}
                        content='Please enter a valid URL.'
                    />
                },
                {
                    type: 'button',
                    name: 'submit',
                    title: title,
                    options: {
                        classes: [classes],
                        hideTitle: true,
                        onClick: invalid ? undefined: this.handleSave.bind(this),
                    }
                },
                this.props.showProducts && {
                    type: 'button',
                    name: 'editProducts',
                    title: 'Edit Products',
                    options: {
                        classes: ['v3', 'btn', 'btn-secondary', 'medium'],
                        hideTitle: true,
                        onClick: this.handleEditProductsClick
                    }
                },
            ]}
        />
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (type, ctx) => dispatch(createChannelInteraction(type, ctx)),
    };
};

const ConnectedManageCatalog = connect(null, mapDispatchToProps)(ManageCatalog);

export default ConnectedManageCatalog;