import React, {useEffect, useMemo, useState} from "react";
import {makeMomentFromDate} from "../../../utilities";
import TrendingPostTile from "./TrendingPostTile";
import {filterBriefsForTeam} from "../../../utilities/mac";

function DashboardTrending({fetchAuthenticated, postLimit=5, campaignLimit=4, team, translate}) {
    const _t = (k, v) => translate ? translate(k, v) : v;
    const [page, setPage] = useState(1);
    const [posts, setPosts] = useState([]);

    const dedupedPosts = useMemo(function( ){
        let idMap = {}; // use hashmap for O(1) lookups
        // they're called posts but they're actually postRecords
        return (posts || [])
            .filter(pr => {
                if (idMap[pr.post_id]) {
                    return false;
                }
                idMap[pr.post_id] = true;
                return true;
            });
    }, [posts]);

    const sortedPosts = useMemo(function () {
        return (dedupedPosts || [])
            .map(post => {
                const reach = post.followers_count || 0;
                const engs = post.post.engagements || 0;
                let engPct = 0;
                if (reach > 0) {
                    engPct = engs / reach;
                }
                return {
                    ...post,
                    engPct
                };
            })
            .sort((a, b) => {
                const aEng = a.engPct || 0;
                const bEng = b.engPct || 0;
                return aEng > bEng ? -1 : 1;
            });
    }, [dedupedPosts]);

    const RenderedPosts = (sortedPosts || [])
            .slice((page-1)*postLimit, page*postLimit)
            .map(postRecord => {
                return <TrendingPostTile post={postRecord.post} key={postRecord.id} engPct={postRecord.engPct} />
            });

    const fetchCampaignReport = async (id) => {
        const resp = await fetchAuthenticated(`/contribute/api/campaign/${id}/report`);
        const json = await resp.json();
        const data = json.data;
        const posts = data.posts || [];
        setPosts(prevPosts => [...prevPosts, ...posts]);
    };

    const fetchData = async () => {
        const campaignsResp = await fetchAuthenticated(`/contribute/api/campaign`);
        const campaignsJson = await campaignsResp.json();
        const filteredCampaigns = filterBriefsForTeam(campaignsJson.data || [], team)
            .filter(c => !!c.has_stats)
            .filter(c => c.total_activations > 5)
            .sort((a, b) => {
                const aDate = makeMomentFromDate(a.start || a.created_at);
                const bDate = makeMomentFromDate(b.start || b.created_at);
                return aDate.isAfter(bDate) ? -1 : 1;
            })
            .slice(0, campaignLimit);

        for (const camp of filteredCampaigns) {
            fetchCampaignReport(camp.id).then(() => console.log(`Fetched for campaign ${camp.id}`));
        }
    };

    useEffect(function() {
        fetchData().then(() => console.log("Finished fetching campaign reports"));
    }, []);

    const totalPosts = dedupedPosts.length;
    const hasNextPage = totalPosts > (page*postLimit);

    if (!posts || posts.length === 0) {
        return null;
    }

    const trendingWord = _t('creator.words.trending', 'Trending');
    const previousWord = _t('creator.words.previous', 'Prev');
    const nextWord = _t('creator.words.next', 'Next');
    const descTrending = _t('creator.sections.trending.description', 'The most engaging content from recent briefs.');

    return (
        <div className="dash-section">
            <div className="section-header">
                <h1 className="red-indent">#{trendingWord}
                    {page > 1 ? (
                        <a className="seeall" onClick={() => setPage(prevPage => prevPage-1)}><i className="v3 icon fa-arrow-left" style={{marginRight: 8}}/>{previousWord}</a>
                    ) : null}

                    {hasNextPage ? (
                        <a className="seeall" onClick={() => setPage(prevPage => prevPage + 1)}>{nextWord}<i
                            className="v3 icon fa-arrow-right"/></a>) : null }
                </h1>
                <p className="section-subtitle">{descTrending}</p>
            </div>
            <div className="section-content">

                <div className="ic-posts">
                    {RenderedPosts}
                </div>

            </div>

        </div>
    );
}

export default DashboardTrending;