import _find from "lodash/find";
import {base64_png_1pxSolidEEE} from "../utilities";

const DEFAULT_BG_IMG = 'data:image/png;base64,' + base64_png_1pxSolidEEE;

export const getThumbnailFromPost = post => {
    const attachment = _find(post.attachments || [], {type: 'thumbnail'});
    if (!attachment) {
        return null;
    }
    return attachment.image_url;
};

export const getBestImageForPost = post => {
    const thumb = getThumbnailFromPost(post);
    if (thumb) {
        return thumb;
    }

    return DEFAULT_BG_IMG;
}
