import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableWrapper from '../Table/Wrapper';
import BasicCell from "../Table/Cell/Basic";
import NumberCell from "../Table/Cell/Number";
import EditableTextCell from '../Table/Cell/EditableText';
import LinkCell from '../Table/Cell/Link';
import Button from '../Common/Form/Button';
import Checkbox from '../Common/Form/Checkbox';
import Alert from '../Common/Alert';
import {base64_png_1pxSolidEEE, fetchAuthenticated, formatDollar, makeFormData} from "../../utilities";
import Toolbox from "../Common/Toolbox";
import Form from "../Common/Form/Form";
import AddProduct from './AddProduct';
import EditProduct from './EditProduct';
import Import from './Import';

class CatalogProductsTable extends Component {

    static defaultProps = {
        showStockAvailable: false,
        embedded: false,
    };

    static propTypes = {
        fetchCatalogs: PropTypes.func,
        fetchProducts: PropTypes.func,
        createInteraction: PropTypes.func,
        catalog: PropTypes.object,
        products: PropTypes.array,
        showStockAvailable: PropTypes.bool,
        onClickAssign: PropTypes.func,
        assignedProductIds: PropTypes.array,
        updateCampaignProduct: PropTypes.func,
        campaignId: PropTypes.number,
        catalogId: PropTypes.number,
        view: PropTypes.string,
        embedded: PropTypes.bool,
    };

    state = {
        showBulkUpdateStock: false,
        showProductImporter: false,
        isUpdatingAllStock: false,
        didUpdateAllStock: false,
        allProductsStockTotal: null,
        showAddProduct: false,
        showEditProduct: false,
        productToEdit: null,
        isFetching: false,
        isRunningInteraction: false,
        importError: false,
    };

    async refresh() {
        this.setState({
            isFetching: true,
        });
        await this.props.fetchCatalogs();
        await this.props.fetchProducts()
            .then(() => {
                this.setState({
                    isFetching: false,
                })
            })
    }

    componentDidMount() {
        this.refresh();
    }

    handleUpdateStock(product, value) {
        const campaignId = this.props.campaignId ? this.props.campaignId : (this.props.campaign || {}).id;
        this.props.updateCampaignProduct(campaignId, product.id, {stock_total: value});
    }

    getProductImage(imageArray) {
        const imageObj = (imageArray || [])[0];
        const bgImage = (imageObj || {}).url || 'data:image/png;base64,' + base64_png_1pxSolidEEE;
        const style = {
            backgroundImage: `url(${bgImage})`,
        };
        return <div className='product-image' style={style}/>;
    }

    getColumns() {
        const fullView = this.props.view === 'full';

        return [
            (this.props.showStockAvailable && this.props.updateCampaignProduct) ? {
                key: 'stock_available',
                title: 'Stock Available',
                width: 180,
                default: true,
                sortable: false,
                cell: (row, column) => {
                    const consumables = row.item.consumables || [];
                    const totalCount = consumables.length;
                    const availableCount = consumables.filter(c => c.is_consumed === false).length;

                    let value = 'No Stock Info';
                    if (totalCount > 0) {
                        value = `${availableCount} available of ${totalCount}`;
                    }

                    return <EditableTextCell
                        value={totalCount}
                        row={row}
                        column={column}
                        displayValue={value}
                        onSave={value => this.handleUpdateStock(row.item, value)}
                        isSaving={row.item.isUpdating}
                        placeholder={"Total available, eg: 12"}
                    />

                }
            } : null,
            {
                key: 'image',
                title: 'Image',
                width: 100,
                default: true,
                sortable: false,
                cell: (row, column) => {
                    const imageDiv = this.getProductImage(row.item.images);
                    return <BasicCell row={row} column={column} value={imageDiv}/>
                }
            },
            {
                key: 'name',
                title: 'Product Name',
                width: 200,
                default: true,
                sortable: false,
                cell: (row, column) => <BasicCell row={row} column={column} value={row.item.name} />,
            },

            this.props.onClickAssign ? {
                key: 'AssignBtn',
                sortable: false,
                default: true,
                width: 180,
                title: 'Assign Product',
                cell: (row, column) => {
                    const consumables = row.item.consumables || [];
                    const totalCount = consumables.length;
                    const availableCount = consumables.filter(c => c.is_consumed === false).length;
                    const isAvailable = availableCount > 0;
                    const isConsumed = !isAvailable;
                    const isAssigned = (this.props.assignedProductIds || []).indexOf(row.item.id) > -1;
                    const shouldShowCheckbox = (isAvailable || isAssigned);

                    let value = 'None Left';

                    if (shouldShowCheckbox) {
                        value = <Checkbox
                            wrapperStyle={{fontSize: 14}}
                            checked={isAssigned}
                            label={'Select Product'}
                            onClick={!shouldShowCheckbox ? null : () => this.props.onClickAssign(row.item, !isAssigned)}
                        />
                    }

                    return <BasicCell row={row} column={column} value={value} />
                },

            } : null,
            fullView && {
                key: 'description',
                title: 'Description',
                width: 400,
                default: true,
                sortable: false,
                cell: (row, column) => <BasicCell row={row} column={column} value={row.item.description} />,
            },
            {
                key: 'sku',
                title: 'SKU',
                width: 200,
                default: true,
                sortable: false,
                cell: (row, column) => <NumberCell row={row} column={column} value={row.item.sku} />,
            },
            {
                key: 'monetary_value',
                title: 'Value',
                width: 85,
                default: true,
                sortable: false,
                cell: (row, column) => <NumberCell row={row} column={column} value={row.item.monetary_value} formatter={formatDollar} />,
            },

            fullView && {
                key: 'id',
                title: 'Product ID',
                width: 85,
                default: true,
                sortable: false,
                cell: (row, column) => <NumberCell row={row} column={column} value={row.item.id} />,
            },
            fullView && {
                key: 'color',
                title: 'Color',
                width: 150,
                default: true,
                sortable: false,
                cell: (row, column) => <BasicCell row={row} column={column} value={row.item.color} />,
            },
            fullView && {
                key: 'category',
                title: 'Category',
                width: 150,
                default: true,
                sortable: false,
                cell: (row, column) => <BasicCell row={row} column={column} value={row.item.category} />,
            },
            {
                key: 'edit_product',
                title: 'Edit',
                width: 60,
                default: true,
                sortable: false,
                cell: (row, column) => <LinkCell
                    row={row}
                    column={column}
                    value={<i className='v3 icon pencil'/>}
                    onClick={this.toggleEditProduct.bind(this, row.item)}
                />,
            },
        ];
    }

    renderButtons() {
        const btnClasses = ['v3', 'small', 'bold', 'btn-secondary'];

        return [

            (this.props.showStockAvailable && this.props.updateCampaignProduct)
            ? <Button
                    content={'Update All Stock'}
                    classes={btnClasses}
                    onClick={() => this.setState({showBulkUpdateStock: !this.state.showBulkUpdateStock})}
                />
            : null,

             <Button
                    content='Add a Product'
                    classes={btnClasses}
                    onClick={this.toggleAddProduct.bind(this)}
                />,
            <Button
                    content='Import Product List'
                    classes={btnClasses}
                    onClick={() => this.setState({showProductImporter: !this.state.showProductImporter})}
                />,
        ];

    }

    toggleAddProduct() {
        this.setState({showAddProduct: !this.state.showAddProduct})
    }

    toggleProductImporter() {
        this.setState({showProductImporter: !this.state.showProductImporter})
    }

    toggleEditProduct(item){
        this.setState({
            showEditProduct: !this.state.showEditProduct,
            productToEdit: item
        })
    }

    renderAddProduct() {
        if (this.state.showAddProduct) {
            return <AddProduct
                onClose={this.toggleAddProduct.bind(this)}
                catalogId={this.props.catalogId}
                refresh={this.refresh.bind(this)}
                embedded={this.props.embedded}
            />
        }
    }

    renderEditProduct() {
        if (this.state.showEditProduct) {
            return <EditProduct
                onClose={this.toggleEditProduct.bind(this)}
                catalogId={this.props.catalogId}
                item={this.state.productToEdit}
                refresh={this.refresh.bind(this)}
                embedded={this.props.embedded}
            />
        }
    }

    renderPopups() {
        return [
            this.state.showBulkUpdateStock ? this.renderBulkUpdateStockPopup() : null,
            this.state.showAddProduct ? this.renderAddProduct() : null,
            this.state.showProductImporter ? this.renderProductImporter() : null,
            this.state.showEditProduct ? this.renderEditProduct() : null,
        ];
    }

    handleUpdateAllStock() {
        const campaignId = this.props.campaignId ? this.props.campaignId : (this.props.campaign || {}).id;

        const stock_total = this.state.allProductsStockTotal;
        const url = `/manage/api/campaign/${campaignId}/product`;
        const params = {
            method: 'POST',
            body: makeFormData({
                stock_total,
                _method: 'patch'
            })
        };

        this.setState({isUpdatingAllStock: true});

        return fetchAuthenticated(url, params)
            .then(() => this.refresh())
            .then(() => this.setState({isUpdatingAllStock: false, didUpdateAllStock: true, allProductsStockTotal: null}))
            .then(() => setTimeout(() => {
                this.setState({
                    didUpdateAllStock: false,
                    showBulkUpdateStock: false,
                });
            }, 1500));


    }

    renderBulkUpdateStockPopup() {

        const isUpdating = this.state.isUpdatingAllStock;
        const didUpdate = this.state.didUpdateAllStock;
        const isValid = !!this.state.allProductsStockTotal;
        const {embedded} = this.props;

        let title = 'Update Stock For All Products';
        let classes = ['v3', 'btn-primary', 'medium', 'bold'];

        if (!isValid) {
            classes.push('disabled');
        }

        if (isUpdating) {
            title = 'Updating Stock Availability...';
            classes.push('disabled');
        }

        if (didUpdate) {
            title = 'Updated Stock Successfully!';
            classes.push('disabled');
        }

        return <Toolbox
            title={'Update All Stock Availability'}
            style={{
                top: '60px',
                width: '500px',
                left: '50%',
                marginLeft: '-250px',
                position: embedded ? 'relative' : null,
            }}
            onClose={() => this.setState({showBulkUpdateStock: false})}
            content={
                <Form
                    onFieldChange={(name, value) => this.setState({[name]: value})}
                    values={{allProductsStockTotal: this.state.allProductsStockTotal}}
                    fields={[

                        {
                            type: 'number',
                            name: 'allProductsStockTotal',
                            title: 'Total Stock Available',
                            help: 'Enter the total stock available for each product. Note that this tool updates stock availability for all products at once.',
                            placeholder: 'eg: 5'
                        },
                        {
                            type: 'button',
                            title: title,
                            options: {
                                classes: classes,
                                hideTitle: true,
                                onClick: (isValid && !isUpdating) ? this.handleUpdateAllStock.bind(this) : null,
                            }

                        }


                    ]}
                />
            }
        />;

    }

    handleProductInteraction(type, context) {
        if (!this.props.createInteraction) {
            return;
        }

        this.setState({isRunningInteraction: true, importError: false});
        return this.props.createInteraction(type, context)
            .then((res) => {
                if (res.meta && res.meta.error) {
                    this.setState({
                        importError: res.meta.error,
                        isRunningInteraction: false
                    })
                } else {
                    this.setState({
                        isRunningInteraction: false
                    })
                }
            });
    }

    renderProductImporter() {
        return this.state.showProductImporter 
        ? <Toolbox
            onClose={this.toggleProductImporter.bind(this)}
            title={'Import Product List from Excel'}
            style={{
                position: 'fixed',
                top: 40,
                width: 1100,
                left: '50%',
                marginLeft: -550,
                height: 'calc(100% - 80px)',
                overflow: 'auto'
            }}
            content={<Import
                onImport={async products => {
                    await this.handleProductInteraction('ImportProducts', {products});
                    return setTimeout(() => {
                        this.refresh()
                            .then(() => this.toggleProductImporter());
                    }, 1000)
                }}
                isImporting={this.state.isRunningInteraction}
                catalogId={this.props.catalogId}
            />}
        />
    : null;
    }

    renderSwitches() {
        const fullView = this.props.view === 'full';
        return fullView && !this.props.embedded
            ? [
                {
                    name: 'close',
                    icon: <i className='v3 close primary' />,
                    onClick: this.props.onClose
                },
            ]
            : []
    }
    
    renderAlert() {
        const {isFetching, importError} = this.state;
        const {products} = this.props;
        const alerts = [];

        if (isFetching && !products.length) {
            alerts.push(<Alert classes={['info']} content='Loading...'/>);
        }

        if (importError) {
            alerts.push(<Alert classes={['danger']} content={importError}/>);
        }

        return <>{alerts}</>;
    }

    renderTable() {
        return <TableWrapper
            title='Products'
            banner={this.renderAlert()}
            switches={this.renderSwitches()}
            items={this.props.products}
            columns={this.getColumns()}
            showPagination={false}
            stickySortTabs={true}
            buttons={this.renderButtons()}
            popups={this.renderPopups()}
        />;
    }

    renderContent() {
        return this.renderTable();
        // huh ?
        const {showEditProduct, showAddProduct} = this.state;
        const {embedded} = this.props;

        if (embedded) {
            //only return one
            if (showEditProduct) {
                return this.renderEditProduct();
            }
            else if (showAddProduct) {
                return this.renderAddProduct();
            }
            else {
                return this.renderTable();
            }

        } else {
            //return all as popups
            return (<>
                {this.renderEditProduct()}
                {this.renderAddProduct()}
                {this.renderTable()}
            </>)
        }
    }
    
    render() {
        const className = this.props.embedded ? null : 'catalog-wrapper';

        return (
            <div className={className}>
                {this.renderContent()}
            </div>
        );
    }
}

export default CatalogProductsTable;