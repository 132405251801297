import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ScrollingImageRow from "../../ScrollingImageRow";
import GenericProfileSummaryBox from "../../GenericProfileSummaryBox";
import GenericProfileFacetsTable from "../../GenericProfileFacetsTable";
import GenericProfileSocialsTable from "../../GenericProfileSocialsTable";

class GenericProfileBasicsPane extends Component {

    static propTypes = {
        name: PropTypes.string,
        picture: PropTypes.string,
        reach: PropTypes.number,
        images: PropTypes.arrayOf(PropTypes.string),
        socials: PropTypes.arrayOf(PropTypes.object),
        facets: PropTypes.object,
        tags: PropTypes.array,
        stats: PropTypes.object,
        metadata: PropTypes.object,
        website: PropTypes.string,
        collectable_type: PropTypes.string,
        performance: PropTypes.shape({
            score: PropTypes.number,
            results: PropTypes.array
        }),
        tier_name: PropTypes.string,

    };

    renderImages() {

        if (!this.props.images || this.props.images.length === 0) {
            return null;
        }

        return (
            <div className="generic-profile-images-wrapper">
                <ScrollingImageRow urls={this.props.images} />
            </div>
        );
    }

    renderAttributes() {

        return (
            <div className="generic-profile-attributes-wrapper">
                <div className="generic-profile-attributes-section">
                    <GenericProfileSummaryBox {...this.props} />
                </div>
                <div className="generic-profile-attributes-section">
                    <GenericProfileFacetsTable {...this.props} />
                </div>
                <div className="generic-profile-attributes-section">
                    <GenericProfileSocialsTable socials={this.props.socials} />
                </div>
            </div>
        );

    }

    renderWebsite() {

        const {metadata, website} = this.props;
        let content;

        if (metadata.screenshot_image_url) {
            content = (
                <a href={website} target="_blank" rel="noopener">
                    <img src={metadata.screenshot_image_url} />
                </a>
            );

        } else if (website) {
            content = (
                <iframe src={website}></iframe>
            );
        }

        if (content) {
            return (
                <div className="blog-preview-frame" style={{borderTop: '30px solid #EEE'}}>
                    {content}
                </div>
            );
        }

        return null;

    }

    render() {

        return (
            <div className="generic-profile-pane-wrapper">
                {this.renderImages()}
                {this.renderAttributes()}
                {this.renderWebsite()}
            </div>
        );

    }
}

export default GenericProfileBasicsPane;
