import React, {Component} from 'react';
import ConnectedManagePosts from "../../Pages/Manage/Content/Posts";
import PropTypes from  'prop-types';

class ContentPaneContributeDash extends Component {

    render() {

        const {user} = this.props;

        return (
            <ConnectedManagePosts
                title={`${user.basics.name}'s Posts`}
                key={`user-posts-${user.id}`}
                query={{
                    id: `user-posts-${user.id}`,
                    filters: {
                        'user.id': user.id,
                        hideInstastory: true,
                    }
                }}
                cols={[
                    'title',
                    'written',
                    'human_status',
                    'campaignStatus',
                    'tags',
                    'sections',
                    'type',
                ]}
                showContributeDashButtons={true}
                showSwitches={false}
                showTitle={false}
                showIcon={false}
            />
        )
    }

}

export default ContentPaneContributeDash;

ContentPaneContributeDash.propTypes = {
    user: PropTypes.object.isRequired,
};