import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from "../../../Common/Toolbox";
import MyCreatorsList from "../../../../containers/Pages/Manage/Users/MyCreatorsList";
import Form from "../../../Common/Form/Form";
import Checkbox from "../../../Common/Form/Checkbox";
import {getCurrentChannel, getCurrentUser} from "../../../../selectors/presence";
import {connect} from 'react-redux';
import _merge from 'lodash/merge';
import Alert from "../../../Common/Alert";
import Button from "../../../Common/Form/Button";
import {bindActionCreators} from "redux";
import {createCampaignInteraction, fetchCampaign, updateCampaignSettings} from "../../../../actions";
import CampaignCommunicationWizard from "./CampaignCommunicationWizard";

class CampaignSetupWizard extends Component {

    static propTypes = {
        style: PropTypes.object,
        onClose: PropTypes.func,
        campaign: PropTypes.object,
        settings: PropTypes.object,
        onClickReviewSettings: PropTypes.func,
        createChannelInteraction: PropTypes.func,
        updateCampaignSettings: PropTypes.func,
        user: PropTypes.object,
        channel: PropTypes.object,
        fetchCampaign: PropTypes.func,
    };

    static defaultProps = {
        style: {
            position: 'fixed',
            top: 60,
            left: '50%',
            height: 'calc(100% - 120px)',
            width: 1200,
            marginLeft: -600,
            overflow: 'auto'
        },
    };

    state = {
        view: 'basics',
        preset: 'custom',
        areSettingsDirty: false,
        isSavingSettings: false,
        settings: {},
        error: null,
    };

    componentDidMount() {
        this.props.fetchCampaign();
    }

    getCurrentSettings() {
        const campaign = this.props.campaign || {};
        return {
            name: campaign.name,
            description: campaign.description,
            ...this.props.settings,
            ...this.state.settings,
            did_launch_wizard: true,
        };
    }

    async handleSave() {
        if (this.state.areSettingsDirty) {
            this.setState({isSavingSettings: true, error: null});
            const resp = await this.props.updateCampaignSettings(this.getCurrentSettings());
            await this.props.fetchCampaign();

            if (resp && resp.meta && resp.meta.error) {
                this.setState({
                    error: resp.meta.error,
                    isSavingSettings: false,
                    areSettingsDirty: true,
                });

                return false;
            }

            this.setState({isSavingSettings: false, areSettingsDirty: false, settings: {}});
            return true;
        }

        return true;
    }

    async handleChangeView(view) {
        await this.handleSave();
        this.setState({view});
    }

    renderNav() {
        const {view} = this.state;
        return (
            <div className="wizard-breadcrumbs">
                <a role="button"
                   onClick={() => this.handleChangeView('basics')}
                   className={view === 'basics' ? 'active' : ''}
                >Basics</a>
                <i className="fas fa-chevron-double-right" />
                <a role="button"
                   onClick={() => this.handleChangeView('tweaks')}
                   className={view === 'tweaks' ? 'active' : ''}
                >Tweaks</a>
                <i className="fas fa-chevron-double-right" />
                <a role="button"
                   onClick={() => this.handleChangeView('communication')}
                   className={view === 'communication' ? 'active' : ''}
                >Communication</a>
                <i className="fas fa-chevron-double-right" />
                <a role="button"
                   onClick={() => this.handleChangeView('users')}
                   className={view === 'users' ? 'active' : ''}
                >Add Users</a>
                <i className="fas fa-chevron-double-right" />
                <a role="button"
                   onClick={() => this.handleChangeView('finish')}
                   className={view === 'finish' ? 'active' : ''}
                >Next Steps</a>

                <a className="v3 close absolute" onClick={this.props.onClose} role="button" />
            </div>
        );
    }

    renderContent() {
        return (
            <div className="wizard-content">
                {this.renderNav()}
                {this.renderError()}
                {this.renderCurrentPane()}
            </div>
        );
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <Alert
                classes={['danger']}
                content={this.state.error}
                style={{position: 'sticky', top: 0}}
            />
        );
    }

    renderCurrentPane() {
        if (!this.props.campaign || !this.props.campaign.id) {
            return <div style={{padding: 20}}><em>Loading...</em></div>
        }

        switch (this.state.view) {
            case 'basics': return this.renderBasics();
            case 'tweaks': return this.renderTweaks();
            case 'communication': return this.renderCommunication();
            case 'users': return this.renderAddUsers();
            case 'finish': return this.renderFinish();
        }

    }

    renderCommunication() {

        return (
            <CampaignCommunicationWizard
                campaign={this.props.campaign}
                settings={this.getCurrentSettings()}
                onFieldChange={this.handleFieldChange.bind(this)}
                onSave={async () => {
                    return await this.handleSave();
                }}
                onFinished={() => this.handleChangeView('users')}
            />
        );

    }

    renderFinish() {
        const settings = this.getCurrentSettings();
        const styles = {
            div: {padding: 20},
            ol: {marginTop: 20, marginBottom: 20, paddingLeft: 20},
            li: {margin: '20px 0', borderBottom: '1px solid #eee', paddingLeft: 10, paddingBottom: 20},
        };
        return (
            <div style={styles.div}>
                {/*<Alert*/}
                {/*    classes={['success']}*/}
                {/*    content={'Congratulations! The basic setup for your campaign is complete. Please review next steps below.'}*/}
                {/*/>*/}

                <h3 className="v3 h3 bold notopmargin">What's Next?</h3>

                <ol style={styles.ol}>
                    <li style={styles.li}>
                        First, <strong>invite yourself</strong> to the campaign to test the workflow and template content, then make any necessary adjustments.
                    </li>

                    {settings.use_payments && (
                        <li style={styles.li}>
                            You have <strong>payments enabled</strong>, so be sure to set individual participant payment amounts <strong>before sending invites.</strong>.
                        </li>
                    )}

                    {settings.waitlist_by_default && (
                        <li style={styles.li}>
                            You have <strong>waitlisting enabled</strong>. Make sure your waitlist email template clearly explains your process to participants. <br/>
                            Be sure to <strong>check back daily</strong> and process waitlisted participants.
                        </li>
                    )}

                    <li style={{...styles.li, borderBottom: 'none'}}>
                        Review the full settings for this campaign to make sure everything looks ok. Don't skip this step! You only have one chance to make a first impression.
                    </li>


                </ol>

                <Button
                    content={'Review Settings and Continue'}
                    classes={['btn-primary', 'medium', 'bold']}
                    onClick={this.props.onClickReviewSettings}
                />
            </div>
        );
    }

    renderTweaks() {
        const settings = this.getCurrentSettings();
        const preset = this.state.preset;
        const showBlogPosts = ['product-sampling-campaign', 'campaign-with-drafts', 'custom'].indexOf(preset) > -1;
        const showIgPosts = ['paid-social', 'product-sampling-campaign', 'campaign-with-drafts', 'custom'].indexOf(preset) > -1;
        const showTwPosts = ['paid-social', 'product-sampling-campaign', 'campaign-with-drafts', 'custom'].indexOf(preset) > -1;
        return (
            <Form
                values={this.getCurrentSettings()}
                onFieldChange={this.handleFieldChange.bind(this)}
                fields={[
                    {
                        type: 'checkbox',
                        name: 'waitlist_by_default',
                        title: 'Waitlist By Default',
                        help: 'Automatically waitlists users that accept campaign invitations. If enabled, you will have to approve influencers before they can start.',
                    },
                    {
                        type: 'number',
                        name: 'target_activations',
                        title: 'Target Number of Participants',
                        options: {min: 0, max: 500, step: 1},
                        help: 'How many participants are you targeting for this campaign?'
                    },
                    showBlogPosts ? {
                        type: 'number',
                        name: 'num_blog_posts',
                        title: 'Blog Posts Required',
                        options: {min: 0, max: 5, step: 1},
                    } : null,
                    showIgPosts ? {
                        type: 'number',
                        name: 'num_instagram_posts',
                        title: 'Instagram Posts Required',
                        options: {min: 0, max: 10, step: 1},
                    } : null,
                    showTwPosts ? {
                        type: 'number',
                        name: 'num_twitter_posts',
                        title: 'Twitter Posts Required',
                        options: {min: 0, max: 10, step: 1},
                    } : null,
                    preset === 'direct-content' ? {
                        type: 'number',
                        name: 'num_images',
                        title: 'Direct-Upload Images Required',
                        options: {min: 0, max: 50, step: 1},
                        help: 'How many images the creator is required to upload to the dashboard for use as brand assets.'
                    } : null,
                    preset === 'direct-content' ? {
                        type: 'number',
                        name: 'num_videos',
                        title: 'Video Uploads Required',
                        options: {min: 0, max: 5, step: 1},
                        help: 'How many videos the creator is required to upload to the dashboard for use as brand assets.'
                    } : null,
                    preset === 'custom' ? {
                        type: 'checkbox',
                        name: 'use_payments',
                        title: 'Use Cash Payments',
                        help: 'Will require users to submit payment profiles.',
                    } : null,
                    settings.use_payments ? {
                        type: 'number',
                        name: 'budget',
                        title: 'Total Budget',
                        help: 'Total budget for cash payments, useful for tracking spending (but doesn\'t prevent over-spend).',
                        options: {min: 0, max: 1000000, step: 1},
                    } : null,
                    settings.use_deadlines ? {
                        type: 'date',
                        name: 'post_deadline_at',
                        title: 'Post Submission Deadline',
                        help: 'The deadline for post submissions. Users receive an email reminder as the deadline is upcoming.',
                        options: {},
                    } : null,
                    this.makeSaveAndContinueButtonSpec('communication'),
                    {
                        type: 'generic',
                        content: (
                            <Alert
                                classes={['info']}
                                content={"There are many more settings available. Be sure to review them before inviting your users!"}
                            />
                        )
                    }
                ].filter(Boolean)}
            />
        )
    }

    renderBasics() {
        const channel = this.props.channel || {};
        return (
            <Form
                values={this.getCurrentSettings()}
                onFieldChange={this.handleFieldChange.bind(this)}
                fields={[
                    {
                        type: 'text',
                        name: "name",
                        title: 'Campaign Name',
                        placeholder: `eg: ${channel.name ? channel.name : 'Brand'} Summer Fashion Campaign`,
                        help: 'Name your campaign. Both users and managers will see this, so make it descriptive.'
                    },
                    {type: 'generic', content: this.renderCampaignTemplateSelect()},
                    this.makeSaveAndContinueButtonSpec('tweaks')

                ]}
            />
        );
    }

    handleFieldChange(key, value) {

        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [key]: value
            },
            areSettingsDirty: true,
        }));

    }

    handleSelectPreset(preset) {

        let settings = _merge(
            {},
            this.getCurrentSettings(),
            preset.settings
        );

        this.setState({
            preset: preset.key,
            areSettingsDirty: true,
            settings: settings,
        });

    }

    renderCampaignTemplateSelect() {
        const channel = this.props.channel || {};
        const planType = channel.plan_type;
        const showDrafts = ['advanced', 'enterprise'].indexOf(planType) > -1;
        const PRESETS = [
            {
                key: 'paid-social',
                title: 'Paid Social Campaign',
                description: (
                    <span>Asks users to post content to their social channels in exchange for a payment through the platform.<br/>
                        <em>Enables payments, social submissions, invitations, deadlines, and reminders.</em>
                    </span>
                ),
                settings: {
                    use_payments: true,
                    use_products: false,
                    use_deadlines: true,
                    cancel_after_deadline: false,
                    num_blog_posts: 0,
                    num_instagram_posts: 2,
                    num_twitter_posts: 1,
                    num_images: 0,
                    num_videos: 0,
                    use_blog_drafts: false,
                    use_social_drafts: false,
                    force_complete_profile: true,
                    auto_approve_content: true,
                }
            },
            {
                key: 'product-sampling-campaign',
                title: 'Product Sampling Campaign',
                description: (
                    <span>Choose this preset if you want to send a product to users for review on their blog and social channels.<br/>
                        <em>Enables payments, product sampling, blog and social submissions, and reminders.</em>
                    </span>
                ),
                settings: {
                    use_payments: true,
                    use_products: true,
                    use_deadlines: false,
                    cancel_after_deadline: false,
                    num_blog_posts: 1,
                    num_instagram_posts: 2,
                    num_twitter_posts: 1,
                    num_images: 0,
                    num_videos: 0,
                    use_blog_drafts: false,
                    use_social_drafts: false,
                    force_complete_profile: true,
                    auto_approve_content: true,
                }
            },
            {
                key: 'direct-content',
                title: 'Direct Content Campaign',
                description: (
                    <span>Does not require users to post content publicly. Instead, allows them to upload media directly into your dashboard for use as brand assets.<br/>
                        <em>Enables payments, invitations, and direct content uploads.</em>
                    </span>
                ),
                settings: {
                    use_payments: true,
                    use_products: false,
                    use_deadlines: false,
                    cancel_after_deadline: false,
                    num_blog_posts: 0,
                    num_instagram_posts: 0,
                    num_twitter_posts: 0,
                    num_images: 2,
                    num_videos: 1,
                    use_blog_drafts: false,
                    use_social_drafts: false,
                    force_complete_profile: true,
                    auto_approve_content: true,
                }

            },
            showDrafts ? {
                key: 'campaign-with-drafts',
                title: 'Campaign With Drafts',
                description: (
                    <span>Need to review content before users post it to their blog and social? This preset uses collaborative draft mode for content review.<br/>
                        <em>Enables payments, draft review, blog and social submissions, and reminders.</em>
                    </span>
                ),
                settings: {
                    use_payments: true,
                    use_products: false,
                    use_deadlines: false,
                    cancel_after_deadline: false,
                    num_blog_posts: 1,
                    num_instagram_posts: 2,
                    num_twitter_posts: 1,
                    num_images: 0,
                    num_videos: 0,
                    use_blog_drafts: true,
                    use_social_drafts: true,
                    force_complete_profile: true,
                    auto_approve_content: true,
                }
            } : null,
            {
                key: 'custom',
                title: 'Custom Campaign',
                description: (
                    <span>Creates a totally custom campaign; you'll have to review and update all settings and templates directly.<br/>
                        <em>Default features enabled; use settings to customize.</em>
                    </span>
                ),
                settings: {
                    // use_payments: false,
                    // use_products: false,
                    // use_deadlines: false,
                    // cancel_after_deadline: false,
                    // num_blog_posts: 1,
                    // num_instagram_posts: 0,
                    // num_twitter_posts: 0,
                    // num_images: 0,
                    // num_videos: 0,
                    // use_blog_drafts: false,
                    // use_social_drafts: false,
                    // force_complete_profile: true,
                    // auto_approve_content: true,
                }
            }
        ].filter(Boolean);

        return (
            <>
                <label><strong>Select a Campaign Preset</strong></label>
                <div className="plan-selector">
                    {
                        PRESETS.map(preset => (
                            <div className="plan" key={`preset-${preset.key}`}>
                                <div className="plan-checkbox-wrapper">
                                    <Checkbox
                                        checked={this.state.preset === preset.key}
                                        onClick={() => this.handleSelectPreset(preset)}
                                    />
                                </div>
                                <div
                                    className="plan-content-wrapper"
                                    onClick={() => this.handleSelectPreset(preset)}
                                    style={{width: 'calc(100% - 60px)'}}
                                >
                                    <div className="plan-title v3 h5 bold" style={{margin: 0}}>{preset.title}</div>
                                    <div className="plan-description v3 h7 light" style={{margin: 0}}>{preset.description}</div>
                                </div>
                            </div>

                        ))
                    }
                </div>
            </>
        );
    }

    makeSaveAndContinueButtonSpec(nextStep) {
        const {isSavingSettings} = this.state;
        const icon = isSavingSettings ? <i className="v3 icon spinner" style={{marginRight: 8}} /> : null;
        return {
            type: 'button',
            title: <span>{icon}Save and Continue</span>,
            options: {
                hideTitle: true,
                classes: ['v3', 'btn-primary', 'medium', 'bold', (isSavingSettings ? 'disabled' : '')],
                onClick: isSavingSettings ? undefined : () => {
                    this.handleChangeView(nextStep)
                }
            }
        }
    }

    renderAddUsers() {
        return <MyCreatorsList
            useActivateButton={(this.props.campaign || {}).id}
            onClose={this.props.onClose}
            onActivatedUsers={this.props.onActivatedUsers}
        />
    }

    render() {
        return (
            <Toolbox
                style={this.props.style}
                onClose={this.props.onClose}
                content={this.renderContent()}
            />
        );
    }
}


const mapStateToProps = (state, props) => {
    return {
        channel: getCurrentChannel(state, props),
        user: getCurrentUser(state, props),
    };
};

const mapDispatchToProps = (dispatch, props) => {

    const campaignId = props.campaign ? props.campaign.id : undefined;
    const _createCampaignInteraction = (type, ctx) => dispatch(createCampaignInteraction(campaignId, type, ctx));
    const _updateCampaignSettings = (settings) => dispatch(updateCampaignSettings(campaignId, settings));

    return {
        updateCampaignSettings: _updateCampaignSettings,
        createCampaignInteraction: _createCampaignInteraction,
        fetchCampaign: () => dispatch(fetchCampaign(campaignId)),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSetupWizard);
