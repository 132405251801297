import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getCurrentChannel} from "../../../selectors/presence";
import {fetchPresenceIfNeeded} from "../../../actions/presence";
import {createUserInteraction} from "../../../actions/users";
import ConnectedUserDetail from "../../../containers/User/Detail";

class ContributeDashHomepage extends Component {

    static propTypes = {
        userId: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            user: {name: "Friend"},
            isFetching: false,
            didFetch: false,
        };

        this.refreshUser = this.refreshUser.bind(this);
    }

    componentDidMount() {
        this.props.fetchPresence();
        this.refreshUser(this.props.userId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId) {
            this.refreshUser(this.props.userId);
        }
    }

    refreshUser(id) {
        if (!id || id === 'null') {
            console.log("Bad id");
            console.log(id);
            return;
        }

        this.setState({isFetching: true});

        this.props.createInteraction(id, 'PersonalProfileRequest', {})
            .then((resp) =>
                this.setState({
                    user: (resp.data || {}).results || {},
                    isFetching: false,
                    didFetch: true
                })
            )
            .then(() => setTimeout(() => this.setState({didFetch: false}), 2000));
    }

    render() {
        return (
            <ConnectedUserDetail
                userId={this.props.userId}
                channel={this.props.channel}
                showMenuIcon={false}
                showListsIcons={false}
                showListsTab={false}
                showStarRating={false}
                showBlogPreview={false}
                showInterests={false}
                showFrecency={false}
                showResponsiveness={false}
                showProfileCompletionPct={true}
                showSecondaryStats={false}
                showActions={false}
                showTodosTab={true}
                showPerformanceTab={false}
                showContributeContentTab={true}
                showManageContentTab={false}
                showContributeCampaignsTab={true}
                showManageCampaignsTab={false}
                showContributeEmailTab={true}
                showManageEmailTab={false}
                showContributeStatsTab={false}
                showManageStatsTab={false}
                showSocialConnectorCard={true}
                onClickEditBasics={() => window.location.href = '/contribute/do/page/user/profile#basics'}
                onClickEditBio={() => window.location.href = '/contribute/do/page/user/profile#your-profile'}
                onClickEditFacets={() => window.location.href = '/contribute/do/page/user/profile#facets'}
                onClickEditImages={() => window.location.href = '/contribute/do/page/user/profile#your-profile'}
                showContributeDashTitle={true}
            />
        )
    }

}

const mapStateToProps = (state, props) => {
    return {
        channel: getCurrentChannel(state, props),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    const userId = props.userId;
    return {
        fetchPresence: () => dispatch(fetchPresenceIfNeeded(userId)),
        createInteraction: (id, type, context) => dispatch(createUserInteraction(id, type, context)),
    };
};

const ConnectedContributeDashHomepage = connect(mapStateToProps, mapDispatchToProps)(ContributeDashHomepage);

export default ConnectedContributeDashHomepage;