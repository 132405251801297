import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {base64_png_1pxSolidEEE} from "../../utilities";
import Image from './Image';

export default class ScrollingImageRow extends Component {
    static defaultProps = {
        classes: [],
        style: {},
        minImageArea: 40000,
    };

    static propTypes = {
        classes: PropTypes.arrayOf(PropTypes.string),
        urls: PropTypes.arrayOf(PropTypes.string),
        images: PropTypes.arrayOf(PropTypes.object),
        style: PropTypes.object,
        /** Minimum area, in pixels, of the image */
        minImageArea: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.state = {
            showRecruitingPopup: false,
            invalidImages: []
        };

        this.handleImageLoad = this.handleImageLoad.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
        this.invalidateImage = this.invalidateImage.bind(this);
    }

    invalidateImage(url) {
        let invalidImages = [...this.state.invalidImages];
        invalidImages.push(url);
        this.setState({invalidImages});
    }

    handleImageLoad(url, event) {
        const {naturalHeight, naturalWidth, src} = event.target;
        const area = naturalHeight * naturalWidth;
        if (area < this.props.minImageArea) {
            this.invalidateImage(url);
        }

    }

    handleImageError(url, event) {
        this.invalidateImage(url);
    }

    getImages() {

        let images;
        const {invalidImages} = this.state;

        if (this.props.images) {
            images = this.props.images;
        } else {
            images = this.props.urls.map(url => {
                return {
                    original_url: url,
                    type: 'image/png',
                    preview: base64_png_1pxSolidEEE
                }
            });
        }

        const validImages = images.filter(image => invalidImages.indexOf(image.original_url) === -1);
        return validImages;
    }

    renderImages() {
        return this.getImages().map(image =>
                <div className="scrolling-image-item">
                    <Image
                        image={image}
                        onLoad={this.handleImageLoad.bind(this, image.original_url)}
                        onError={this.handleImageError.bind(this, image.original_url)}
                    />
                </div>

        );
    }

    render() {
        let classes = ['scrolling-image-row', ...this.props.classes];

        return (
            <div className={classes.join(' ')} style={this.props.style}>
                {this.renderImages()}
            </div>
        )
    }

}