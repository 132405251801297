import React, {Component} from 'react';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import StarRating from '../Common/StarRating';
import {createActivationInteraction} from "../../actions/activations";

class ActivationStarRating extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string,]).isRequired,
        onRatingChange: PropTypes.func,
        onComplete: PropTypes.func,
    };

    handleRatingChange(rating) {
        const {activation_id} = this.props;
        const context = {rating};

        this.props.createInteraction(activation_id, 'AddStarRating', context)
            .then(() => {
                if (typeof this.props.onComplete === 'function') {
                    this.props.onComplete();
                }
            });

    }

    render() {
        return (
            <StarRating
                onRatingChange={this.handleRatingChange.bind(this)}
                rating={this.props.rating}
            />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
    };
};

const ConnectedActivationStarRating = connect(null, mapDispatchToProps)(ActivationStarRating);

ConnectedActivationStarRating.propTypes = {
    createInteraction: PropTypes.func,
};

export default ConnectedActivationStarRating;