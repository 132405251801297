import React, {useState, useEffect} from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import Form from "../Common/Form/Form";
import {createChannelInteraction} from "../../actions/channel";
import {fetchAuthenticated} from "../../actions/auth";
import {countries} from "../../utilities";
import moment from "moment";
import Alert from "../Common/Alert";

const FORM_FIELDS = ['name', 'email', 'city_name', 'state_name', 'country_code', 'age'];

function QuickEditUserForm({userId, createChannelInteraction, fetchAuthenticated, onSave}) {
    const [user, setUser] = useState({});
    const [form, setForm] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);

    const handleFieldChange = (key, value) => {

        if (key === 'age') {
            const newBday = moment().subtract(value, 'years').startOf('year').format('YYYY-MM-DD');
            setForm(prev => ({...prev, birthday: newBday, age: value}))
        } else {
            setForm(prev => ({...prev, [key]: value}));
        }

    };

    const handleSubmit = async () => {
        setSubmitting(true);

        const resp = await createChannelInteraction('QuickEditUser', {
            user_id: userId,
            ...form
        });

        setSubmitting(false);

        if (resp && resp.meta && resp.meta.error) {
            setError(resp.meta.error);
            return;
        }

        if (onSave) {
            onSave();
        }

        setSubmitting(false);
    };

    const init = async () => {
        const toSetAsForm = {};
        // Doing this requests manually because redux version messes with loading state
        const userResp = await fetchAuthenticated(`/manage/api/user/${userId}`);
        const userData = await userResp.json();
        const profileResp = await fetchAuthenticated(`/manage/api/user/${userId}/profile`);
        const profileData = await profileResp.json();

        const user = {...userData.data, ...profileData.data};
        setUser(user);

        for (const key of FORM_FIELDS) {
            toSetAsForm[key] = user[key];
        }
        setForm(toSetAsForm);
    };

    useEffect(function() {
        init().then(() => setLoading(false));
    }, [userId]);

    if (isLoading) {
        return (<Alert classes={['info']} content={"Loading, please wait..."} /> );
    }

    return (
        <Form
            values={form}
            onFieldChange={handleFieldChange}
            fields={[
                error ? {
                    type: 'generic',
                    content: <Alert classes={['danger']} content={error} />
                } : null,
                {
                    type: 'text',
                    name: 'name',
                    title: 'Name'
                },
                {
                    type: 'text',
                    name: 'email',
                    title: 'Email'
                },
                {
                    type: 'text',
                    name: 'city_name',
                    title: 'City'
                },
                {
                    type: 'text',
                    name: 'state_name',
                    title: 'State / Region'
                },
                {
                    type: 'select',
                    name: 'country_code',
                    title: 'Country',
                    choices: [
                        {text: '- Select Country -', value: null},
                        ...Object.keys(countries).map(countryCode => ({
                            text: countries[countryCode],
                            value: countryCode
                        }))
                    ]
                },
                {
                    type: 'number',
                    name: 'age',
                    title: 'Age',
                    help: (form.birthday ? `Will set birthday as ${form.birthday}.` : null)
                },
                {
                    type: 'button',
                    name: 'submit',
                    title: 'Save',
                    options: {
                        hideTitle: true,
                        classes: ['v3 btn tidal-btn btn-primary medium'],
                        onClick: handleSubmit
                    }
                }
            ].filter(f => !!f)}
        />

    );
}

const mapStateToProps = undefined;
const mapDispatchToProps = (dispatch) => bindActionCreators({
    createChannelInteraction: createChannelInteraction,
    fetchAuthenticated: fetchAuthenticated,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuickEditUserForm);