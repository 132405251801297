import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';

const cleanOutput = arr => arr.map(a => ({...a, name: a._name || a.name}));
const mapEmail = a => ({
    ...a,
    _name: a.name,
    name: a.name + (
        a.email ? (' (' + a.email + ')') : ''
    )
});

class MultiEmailField extends Component {

    constructor (props) {
        super(props)

        this.state = {
            isFetching: false,
            suggestions: [ ]
        }
    }

    getValues() {
        const emails = this.props.emails.map(mapEmail);
        if (emails.length > 10) {
            return [{
                id:'multi',
                name: emails.length + ' Recipients',
                emails
            }];
        } else {
            return emails;
        }
    }

    getSuggestions() {
        return this.state.suggestions.map(mapEmail);
    }

    handleFetchSuggestions(query) {
        if (this.state.isFetching) return;

        const url = `/manage/do/widget/tools/get_users?q=${query}`;
        this.setState({isFetching: true});
        fetch(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(suggestions => this.setState({suggestions, isFetching: false}));
    }

    handleDelete (i) {

        const emails = this.getValues().slice(0);
        emails.splice(i, 1);

        if (this.props.onChange) {
            this.props.onChange(cleanOutput(emails));
        }

    }

    handleAddition (value) {
        console.log({addition: value});
        // handleAddition prop populates "name" key, bur really it's an email
        let addedItem;

        if (typeof value.id !== 'undefined' && value.id.indexOf('@') === -1) {
            // we have an autocomplete
            console.log("Adding autocomplete");
            addedItem = {...value, type: 'Tidal\\User'};
        } else {
            console.log("Adding email");
            addedItem = {type: 'email', email: value.name, name: value.name};
            const validEmailRegex = RegExp('.*@.*\..*', 'ig');
            if (!validEmailRegex.test(addedItem.email)) {
                addedItem = null;
            }
        }

        if (!addedItem) {
            console.log("Not adding item");
            return;
        }

        console.log({addedItem});

        // Need them all mapped because they're all going to get cleaned
        const emails = [].concat(this.props.emails.map(mapEmail), addedItem);
        console.log({emails});

        if (this.props.onChange) {
            this.props.onChange(cleanOutput(emails));
        }
    }

    render () {
        return (
            <ReactTags
                placeholder={this.props.placeholder}
                tags={this.getValues()}
                suggestions={this.getSuggestions()}
                handleDelete={this.handleDelete.bind(this)}
                handleAddition={this.handleAddition.bind(this)}
                handleInputChange={this.handleFetchSuggestions.bind(this)}
                allowNew={true}
                addOnBlur={true}
                autofocus={false}
            />
        )
    }
}

MultiEmailField.defaultProps = {
    placeholder: 'To',
    emails: []
};

MultiEmailField.propTypes = {
    placeholder: PropTypes.string,
    emails: PropTypes.array,
    onChange: PropTypes.func
};

export default MultiEmailField;
