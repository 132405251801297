import {PRESENCE_TOGGLE_TEAMSWITCHER, PRESENCE_UPDATE_FILTER, PRESENCE_SHOW_FAVORITES} from "./types";
import {fetchAuthenticated, getApiRoot} from "../utilities";
import * as ActionTypes from './types';
import {fetchAuthenticated as fetchAuthenticatedAction} from "./auth";

export const REQUEST_PRESENCE = 'REQUEST_PRESENCE';
export const RECEIVE_PRESENCE = 'RECEIVE_PRESENCE';
export const INVALIDATE_PRESENCE = 'INVALIDATE_PRESENCE';

export function showHelp() { return {type: ActionTypes.PRESENCE_SHOW_HELP}; }
export function hideHelp() { return {type: ActionTypes.PRESENCE_HIDE_HELP}; }

export function toggleHelpTicket() {
    return {type: ActionTypes.PRESENCE_SHOW_HELP_TICKET};
}

export function toggleSearch() {
    return {type: ActionTypes.PRESENCE_SHOW_SEARCH};
}

export function updatePresenceChannelUrl(url) {
    return {
        type: ActionTypes.PRESENCE_SET_CHANNEL_URL,
        url
    };
}

export function updatePresenceFilter(key, value) {
    return {
        type: PRESENCE_UPDATE_FILTER,
        key,
        value
    };
}

export function toggleFavorites() {
    return {
        type: PRESENCE_SHOW_FAVORITES
    };
}

export function toggleTeamSwitcher() {
    return {
        type: PRESENCE_TOGGLE_TEAMSWITCHER
    };
}

export function fetchPresenceIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchPresence(getState())) {
            return dispatch(fetchPresence());
        } else {
            return new Promise((resolve) => resolve());
        }
    }
}

export function shouldFetchPresence(state) {
    const {presence} = state;
    if (state.isFetching) {
        return false;
    } else {
        return presence.didInvalidate;
    }
}

export function fetchPresence() {
    return function (dispatch) {

        dispatch({type: REQUEST_PRESENCE});

        const slot = window.Gator.getDashboardSlot();

        return dispatch(fetchAuthenticatedAction(`/${slot}/api/channel/_/presence`))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: RECEIVE_PRESENCE,
                receivedAt: new Date(),
                presence: json.data
            }))
            .catch(err => {
                console.log("Error fetching presence");
                console.log(err);
                return err;
            });
    }
}
