import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BasicCell from './Basic';
import {getImageUrl} from "../../../utilities";

export default class ImageCell extends Component {

    static defaultProps = {
        style: {},
    };

    static propTypes = {
        url: PropTypes.string,
        image: PropTypes.object,
        row: PropTypes.object.isRequired,
        column: PropTypes.object.isRequired,
        onClick: PropTypes.func,
        style: PropTypes.object,
    };

    getImageUrl() {
        if (this.props.url) {
            return this.props.url;
        }
        return getImageUrl(this.props.image);
    }

    getStyle() {

        const style = {
            backgroundSize: this.props.style.backgroundSize || 'cover',
            backgroundImage: 'url(' + this.getImageUrl() + ')',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: this.props.style.backgroundPosition || 'initial',
            padding: 0,
            height: this.props.style.height || 40,
        };

        return style;

    }

    render() {

        return <BasicCell
            row={this.props.row}
            column={this.props.column}
            value={''}
            style={this.getStyle()}
            onClick={this.props.onClick}
        />

    }


}

