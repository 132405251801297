import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MiniProfile from '../Common/MiniProfile';
import Switch from '../Common/Switch';
import _get from 'lodash/get';
import {getCompletedInfluencerRequirementTypes, getVisibleInfluencerRequirementTypes} from "../../utilities/campaign";
import TinyProgressPie from "../Chart/TinyProgressPie";
import Tooltip from "../Common/Tooltip";
import {formatNumber} from "../../utilities";
import AuthStatusDotWithTooltip from "../User/Pieces/AuthStatusDotWithTooltip";

export default class ActivationMiniProfile extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onClickSwitch: PropTypes.func,
        onClickUser: PropTypes.func,
        onClickProgress: PropTypes.func,
    };

    handleSwitchClick(name) {

        if (this.props.onClickSwitch) {
            this.props.onClickSwitch(name);
        }

    }

    getIssuesSwitch() {

        const {activation} = this.props;
        const openCount = _get(activation, 'status.open_issues_count', 0);
        const closedCount = _get(activation, 'status.resolved_issues_count', 0);
        const totalCount = _get(activation, 'status.total_issues_count', 0);

        let content = [];
        let icon = <i className="fa fa-check-circle" /> ;
        if (openCount > 0) {
            icon = <i className="fa fa-exclamation-circle " style={{color: '#FF0000'}} />;
            content.push(`${openCount} Open Issue${openCount !== 1 ? 's' : ''}. Click to view.`);
        }
        if (closedCount > 0) {
            content.push(`${closedCount} Resolved Issue${closedCount !== 1 ? 's' : ''}. Click to view.`);
        }
        if (!totalCount) {
            content.push('No Issues. Click to raise an issue.');
        }

        const whichInteraction = totalCount > 0 ? 'ManageIssues' : 'RaiseIssue';

        return <Switch
            icon={icon}
            onClick={this.handleSwitchClick.bind(this, whichInteraction)}
            tooltip={content.join(' ')}
        />;
    }

    getSwitches() {

        const {activation} = this.props;
        const postCount = (activation.status || {}).content_total_count || 0;

        return [
            this.getIssuesSwitch(),

            <Switch
                icon={<i className="fa fa-history" /> }
                onClick={this.handleSwitchClick.bind(this, 'ViewHistory')}
                tooltip={"Show all history for this activation."}
            />,

            postCount > 0 ? <Switch
                icon={<i className="fa fa-file" /> }
                onClick={this.handleSwitchClick.bind(this, 'ViewPosts')}
                label={postCount}
                tooltip={`Click to show ${postCount} submitted post${postCount !== 1 ? 's' : ''}.`}
            /> : null,

            activation.stats !== null ? <Switch
                icon={<i className="fa fa-pie-chart" />}
                onClick={this.handleSwitchClick.bind(this, 'ViewStats')}
                tooltip={`Click to show performance stats for this activation.`}
            /> : null,

        ];
    }

    renderProgressPie() {
        const {activation} = this.props;

        const totalCount = getVisibleInfluencerRequirementTypes(activation).length;
        const completeCount = getCompletedInfluencerRequirementTypes(activation).length;
        const percent = (totalCount ? (completeCount / totalCount) : 0);

        return <TinyProgressPie
            progress={percent}
            style={{verticalAlign: 'middle', cursor: 'pointer', marginBottom: 6}}
            size={12}
        />;

    }

    renderSecondary() {

        const {activation} = this.props;

        const totalCount = getVisibleInfluencerRequirementTypes(activation).length;
        const completeCount = getCompletedInfluencerRequirementTypes(activation).length;
        const percent = (totalCount ? (completeCount / totalCount) : 0);

        const tooltip = `${completeCount} of ${totalCount} (${formatNumber(100*percent)}%) steps completed. Click to see details.`;
        return (
            <Tooltip title={tooltip}>
                <a
                    onClick={this.props.onClickProgress}
                    role="button"
                    style={{color: '#888'}}
                    className="v3 bold h9"
                >{this.renderProgressPie()} {(activation.status || {}).human_status || ''}</a>
            </Tooltip>
        );

    }

    render() {
        const {activation} = this.props;
        const {user = {}} = activation;
        const loadingIcon = activation.isFetching ? <i className="fas fa-spinner fa-spin" style={{marginRight: 5}} /> : null;
        const shadowIcon = user.shadow
            ? (
                <Tooltip title="This user has not yet claimed their account.">
                    <i className="v3 icon info" style={{marginRight: 5}} />
                </Tooltip>
            )
            : null;
        const status = (activation.status || {}).human_status || '';

        let badge = undefined;
        if (user && user.is_unsubscribed) {
            badge = (
                <Tooltip title="User has unsubscribed from campaign offers and will not receive invitation or reminder emails.">
                    <i
                        className="v3 signal danger large"
                        style={{ position: 'relative', top: 0, right: 0, verticalAlign: 'top', margin: 0 }}
                    />
                </Tooltip>
            );
        }
        if (!badge && user.auth_status) {
            badge = <AuthStatusDotWithTooltip status={user.auth_status || {}} />;
        }

        return (

            <MiniProfile
                imageUrl={user.picture}
                primary={<a role="button" onClick={this.props.onClickUser}>{loadingIcon}{shadowIcon}{user.name}</a>}
                secondary={this.renderSecondary()}
                switches={this.getSwitches()}
                onClickAvatar={this.props.onClickUser}
                badge={badge}
            />

        );
    }
}
