import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import TableWrapper from "../../../Table/Wrapper";
import * as QuerySelectors from "../../../../selectors/queries";
import BasicCell from "../../../Table/Cell/Basic";
import DateCell from '../../../Table/Cell/Date';
import NumberCell from '../../../Table/Cell/Number';
import LinkCell from '../../../Table/Cell/Link';
import * as Query from "../../../../actions/queries";
import * as ActionTypes from "../../../../actions/types";
import {DEFAULT_POSTS_QUERY} from "../../../../reducers/posts";
import {getAvailableMetadata, getAvailableMetadata as getPageMetadata} from "../../../../selectors/queries";
import {openUserLightbox} from "../../../../actions/lightbox";
import {formatNumber, formatNumberK, getIconAndTypeForPost, limitChars, makeCellKey} from "../../../../utilities";
import _get from "lodash/get";
import _find from "lodash/find";
import _omit from "lodash/omit";
import { featurePost, updatePostRating } from '../../../../actions/postsById';
import PostTitleCell from "../../../Post/TitleCell";
import GodzillaPostsFiltersToolbox from "../../../Post/GodzillaPostsFiltersToolbox";
import GenericQuickFiltersButton from "../../../Common/GenericQuickFiltersButton";
import {getBadges} from "../../../../selectors/badges";
import {fetchBadgesIfNeeded} from "../../../../actions";
import Tooltip from "../../../Common/Tooltip";
import Alert from "../../../Common/Alert";
import {fetchSections} from "../../../../actions/sections";
import {getSections} from "../../../../selectors/sections";
import PostDetail from '../../../Post/PostDetail';
import Toolbox from '../../../Common/Toolbox';
import GenericActionsButton from "../../../Common/GenericActionsButton";
import CheckboxCell from "../../../Table/Cell/Checkbox";
import {fetchAuthenticated} from "../../../../actions/auth";
import * as URI from "urijs";
import {getTeams} from "../../../../selectors/teams";

class Posts extends Component {

    static propTypes = {
        title: PropTypes.string,
        posts: PropTypes.array,
        query: PropTypes.object,
        onSortChange: PropTypes.func,
        onPageChange: PropTypes.func,
        fetchPosts: PropTypes.func,
        fetchTotals: PropTypes.func,
        openUserLightbox: PropTypes.func,
        onFilterChange: PropTypes.func,
        ratePost: PropTypes.func,
        featurePost: PropTypes.func,
        onRefresh: PropTypes.func,
        badges: PropTypes.array,
        fetchBadgesIfNeeded: PropTypes.func,
        fetchSections: PropTypes.func,
        cols: PropTypes.array,
        showContributeDashButtons: PropTypes.bool,
        showSwitches: PropTypes.bool,
        showTitle: PropTypes.bool,
        showIcon: PropTypes.bool,
        showTeamTabs: PropTypes.bool,
        teams: PropTypes.array,
    };

    static defaultProps = {
        title: "Posts",
        cols: [
            'checkbox',
            'title',
            'is_featured',
            'written',
            'human_status',
            'engagements',
            'views',
            'total_emv',
            'campaignStatus',
            'tags',
            'sections',
            'type',
            'user.name',
            'user.human_location',
        ],
        showContributeDashButtons: false,
        showSwitches: true,
        showTitle: true,
        showIcon: true,
        showTeamTabs: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            initialFilters: ((props.query) || {}).filters || {},
            isUpdating: false,
            showFilters: false,
            selectedPost: {},
            delayedSelectedPost: null,
            selectedIds: {},

            isFetchingTotals: false,
            didFetchTotals: false,
            totals: null,
        };

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.handleRatingChange = this.handleRatingChange.bind(this);
        this.handleFeaturedChange = this.handleFeaturedChange.bind(this);

        this.debounceTimer = null;
    }

    componentDidMount() {
        this.props.fetchBadgesIfNeeded();
        this.props.fetchSections();
        this.handleRefresh();

        document.title = 'Posts | ContentMetric Dashboard';
    }

    async handleBulkAction(urlFn, params) {
        const postIds = this.getSelectedPostIds();
        this.setState({isUpdating: true});
        for (const postId of postIds) {
            const url = urlFn(postId);
            await this.props.fetchAuthenticated(url, params);
        }
        await this.handleRefresh();
        this.setState({isUpdating: false, selectedIds: {}});
    }

    async handleBulkMarkSpam() {
        const urlFn = postId => `/manage/api/post/${postId}/spam`;
        const params = {method: 'POST'};
        await this.handleBulkAction(urlFn, params);
    }

    async handleBulkApprove() {
        const urlFn = postId => `/manage/api/post/${postId}/approve`;
        const params = {method: 'POST'};
        await this.handleBulkAction(urlFn, params);
    }
    async handleBulkReject() {
        const urlFn = postId => `/manage/api/post/${postId}/reject`;
        const params = {method: 'POST'};
        await this.handleBulkAction(urlFn, params);
    }

    handleRefresh() {
        return this.props.onRefresh(this.props.query)
            .then(() => this.fetchTotals());
    }

    isSelected(postId) {
        return !!this.state.selectedIds[postId];
    }

    toggleSelected(postId) {
        const selected = !this.isSelected(postId);
        this.setState(prevState => ({...prevState, selectedIds: {...prevState.selectedIds, [postId]: selected}}));
    }

    getSelectedPostIds() {
        let out = [];
        for (const id in this.state.selectedIds) {
            if (this.isSelected(id)) {
                out.push(id);
            }
        }
        return out;
    }

    componentDidUpdate(prevProps){
        const {query, onRefresh, posts} = this.props;
        const {isFetching, isDirty} = query;
        const wasFetching = prevProps.query ? prevProps.query.isFetching : null;
        const {delayedSelectedPost} = this.state;

        if (isDirty && !prevProps.query.isDirty && !isFetching) {
            return onRefresh(query);
        }

        if (delayedSelectedPost && wasFetching === true && isFetching === false) {
            //aka we navigated to a new page, and fetching is complete
            const index = delayedSelectedPost === 1 ? 0 : posts.length-1;
            const nextPost = posts[index];

            this.setState({
                delayedSelectedPost: null,
                selectedPost: {
                    id: nextPost.id,
                    index
                }
            })
        }
    }

    shouldShowLoadingAlert() {
        const { campaigns, query, posts } = this.props;
        return (query.isFetching && !posts.length && !campaigns.length)
    }

    shouldBlur() {
        const { query, posts } = this.props;
        if (query.isFetching && posts.length) {
            return true;
        } else if (this.state.isUpdating) {
            return true;
        } else {
            return false;
        }
    }

    handlePageChange(page) {
        return this.props.onPageChange(page, this.props.query);
    }

    handleSortChange(sort) {
        this.handlePageChange(1);
        return this.props.onSortChange(sort, this.props.query);
    }

    renderHeaderIcon() {
        const hits = this.getTotalHits();
        if (hits) {
            return <span className='large badge'>{hits}</span>
        }
    }

    getMaxPages() {
        //return hits/(query.limit or 10)
        const hits = this.getTotalHits();
        if (hits) {
            return Math.ceil(hits / (this.props.query.limit || 10));
        } else {
            return 1;
        }
    }

    fetchTotals() {
        if (!this.props.fetchTotals) {
            return;
        }

        this.setState({isFetchingTotals: true});
        this.props.fetchTotals(this.props.query.filters)
            .then(json => this.setState({totals: json.data, isFetchingTotals: false, didFetchTotals: true}));
    }

    getCurrentPageMeta() {
        const {query} = this.props;
        return getAvailableMetadata(query);
    }

    getTotalHits() {
        const meta = this.getCurrentPageMeta();
        if (!meta || !meta.hits) return 0;
        return parseInt(meta.hits, 10);
    }

    makeCellKey(row, col) {
        return `posts-cell-id-${row.item.id}-col-${col.key}`;
    }

    renderSwitches() {
        const {showFilters} = this.state;
        return [
            {
                name: 'filter',
                icon: <i className='v3 icon search'></i>,
                tooltip: "Show search and filter options.",
                isActive: showFilters,
                onClick: () => {
                    this.setState({showFilters: !showFilters})
                }
            },
        ];
    }

    handleFilterChange(name, value) {
        let filters = {...this.state.filters};

        if (value === false) {
            //remove the filter for an unchecked box
            filters = _omit(filters, [name]);
        } else {
            const cleanValue = (value === '') ? null : value;
            filters[name] = cleanValue;
        }

        this.setState({filters});
    }

    async resetFilters(additional = {}, title = null) {
        const filters = {...this.state.initialFilters, ...additional};
        this.setState({title});
        const results = await this.props.onFilterChange(filters, this.props.query);
        await this.fetchTotals();
        return results;
    }

    handleRatingChange(post, rating) {
        this.setState({isUpdating: true});
        this.props.ratePost(post.id, rating)
            .then(()=>{this.setState({isUpdating: false})})
            .catch((err) => console.err);
    }

    handleFeaturedChange(post) {
        this.setState({isUpdating: true});
        this.props.featurePost(post.id, !post.is_featured)
            .then(()=>{this.setState({isUpdating:false})})
            .catch((err)=>console.err);
    }

    handlePostNavigation(dir) {
        dir = Number(dir);
        const {posts, query} = this.props;
        const {selectedPost} = this.state;
        const {index} = selectedPost;

        if (index === undefined) return;

        const nextIndex = Number(index) + dir;
        const nextPost = posts[nextIndex];
        const currentPage = Number(query.page);
        const maxPages = this.getMaxPages();
        const morePages = maxPages >= currentPage + dir;
        const nextPage = (nextPost === undefined && !!morePages) ? Number(currentPage + dir) : null;

        if (nextPost && nextPost.id) {
            return this.setState({
                selectedPost: {
                    id: nextPost.id,
                    index: nextIndex
                }
            });
        };

        if (!!nextPage) {
            this.handlePageChange(nextPage);
            return this.setState({
                delayedSelectedPost: dir
            });
        };
    }

    getTabs() {

        // Render tabs for each team
        // Don't show if props.teams is empty
        // don't show if props.showTeamTabs is false
        // don't show if props.list is true
        // don't show if props.isPublic is true
        if (!this.props.teams || !this.props.teams.length || !this.props.showTeamTabs) {
            return null;
        }

        return this.props.teams.map(team => {

            const filterTeamId = parseInt(this.props.query.filters.team, 10);
            const thisTeamId = parseInt(team.id, 10);
            return {
                title: team.name,
                isActive: filterTeamId === thisTeamId,
                onClick: () => {
                    // If the query already has the team filter, unset it
                    if (filterTeamId === thisTeamId) {
                        return this.resetFilters({team: null});
                    } else {
                        // otherwise, set the team filter
                        return this.resetFilters({team: thisTeamId});
                    }
                },
                key: team.name
            };

        });
    }

    getColumns() {
        const { campaigns } = this.props;
        return [
            {
                key: 'checkbox',
                sortable: false,
                default: true,
                title: '',
                width: 50,
                cell: (row, column) => {
                    return <CheckboxCell
                        key={makeCellKey(row, column)}
                        row={row}
                        column={column}
                        value={this.isSelected(row.item.id)}
                        onClick={() => this.toggleSelected(row.item.id)}
                    />
                }
            },
            {
                key: 'title',
                sortable: true,
                sortKey: 'slug',
                default: true,
                title: 'Title',
                width: 400,
                sticky: true,
                cell: (row, column) => {
                    return <PostTitleCell
                        row={row} column={column} key={makeCellKey(row, column)}
                        onClickUser={this.props.openUserLightbox}
                        onClickPost={(id, index) => this.setState({
                            selectedPost: {id, index}
                        })}
                    />
                },
                total: _get(this.state.totals, 'uniques.slug', 0) + ' Posts',
            },
            {
                key: 'is_featured',
                sortable: false,
                default: true,
                title: 'Featured',
                width: 90,
                cell: (row, column) => <LinkCell
                    key={this.makeCellKey(row, column)}
                    row={row}
                    column={column}
                    style={{textAlign: 'center'}}
                    value={<i className='v3 icon star'
                              style={row.item.is_featured ? {color:'#66CC00'} : {color :'#8B8B8B'}}
                              onClick={this.handleFeaturedChange.bind(this, row.item)}>
                    </i>}
                />,
                filterDisplayValue: <span>Filters</span>,
                total: _get(this.state.totals, 'exists.featured', 0) + ' Posts',
            },
            // hide as per matt - 'written' column is enough
            // {
            //     key: 'created',
            //     sortable: true,
            //     default: true,
            //     title: 'Created',
            //     width: 150,
            //     cell: (row, column) => <DateCell
            //         key={this.makeCellKey(row, column)}
            //         row={row}
            //         column={column}
            //         value={_get(row.item.created_at, 'date')}
            //     />
            // },
            {
                key: 'written',
                sortable: true,
                default: true,
                title: 'Written',
                width: 150,
                cell: (row, column) => <DateCell
                    key={this.makeCellKey(row, column)}
                    row={row}
                    column={column}
                    value={_get(row.item.written_at, 'date')}
                />
            },
            // hide as per matt
            // {
            //     key: 'rating',
            //     sortDir: 'desc',
            //     sortable: true,
            //     default: true,
            //     title: 'Rating',
            //     width: 110,
            //     cell: (row, column) => {
            //         if (row.item.type === 'draft') {
            //             return <BasicCell row={row} column={column} key={makeCellKey(row, column)} value={'N/A'} />;
            //         }
            //         return <BasicCell
            //             row={row}
            //             column={column}
            //             key={makeCellKey(row, column)}
            //             value={<StarRating
            //                 rating={row.item.rating}
            //                 onRatingChange={this.handleRatingChange.bind(this, row.item)}
            //             />}
            //         />;
            //     }
            // },
            {
                key: 'human_status',
                sortable: false,
                default: true,
                title: 'Status',
                width: 150,
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row}
                    column={column}
                    value={row.item.human_status}
                />,
                search: [{
                    type: 'select',
                    name: 'human_status',
                    title: 'Status',
                    choices: [
                        {text: 'All Statuses', value: null},
                        {text: 'Submitted', value: true},
                        {text: 'Published', value: false},
                    ]
                }],
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['human_status']))}
            },
            {
                key: 'engagements',
                sortable: true,
                sortDir: 'desc',
                default: true,
                title: 'Engagements',
                width: 150,
                cell: (row, column) => {
                    const engs = _get(row.item, 'engagements', 0);
                    const formattedEngs = formatNumber(engs);
                    const views = _get(row.item, 'views', 0);
                    let engPct;
                    if (views > 0) {
                        engPct = <small>({formatNumber(engs / views * 100, 1)}%)</small>;
                    }
                    const content = <>{formattedEngs} {engPct}</>;
                    return <BasicCell
                        key={this.makeCellKey(row, column)}
                        row={row}
                        column={column}
                        value={content}
                    />
                },
                total: (() => { // iife for extra formatting
                    const sum = _get(this.state.totals, 'sums.engagements', 0);
                    const avg = _get(this.state.totals, 'avgs.engagements', 0);

                    const tooltipContent = (
                        <div className="fake-table">
                            <div className="fake-li bottom-border condensed">
                                Total: {formatNumber(sum)}
                            </div>
                            <div className="fake-li condensed">
                                Average: {formatNumber(avg)} per post
                            </div>
                        </div>

                    );

                    return <Tooltip
                        position="right"
                        animateFill={false}
                        arrow={false}
                        html={tooltipContent}>{formatNumberK(sum)} Total</Tooltip>
                })(),
            },
            {
                key: 'views',
                sortable: true,
                sortDir: 'desc',
                default: true,
                title: 'Impressions',
                width: 150,
                cell: (row, column) => <NumberCell
                    key={this.makeCellKey(row, column)}
                    row={row}
                    column={column}
                    value={_get(row.item, 'views')}
                    formatter={formatNumber}
                />,
                total: (() => { // iife for extra formatting
                    const sum = _get(this.state.totals, 'sums.views', 0);
                    const avg = _get(this.state.totals, 'avgs.views', 0);

                    const tooltipContent = (
                        <div className="fake-table">
                            <div className="fake-li bottom-border condensed">
                                Total: {formatNumber(sum)}
                            </div>
                            <div className="fake-li condensed">
                                Average: {formatNumber(avg)} per post
                            </div>
                        </div>

                    );

                    return <Tooltip
                        position="right"
                        animateFill={false}
                        arrow={false}
                        html={tooltipContent}>{formatNumberK(sum)} Total</Tooltip>
                })(),
            },
            {
                key: 'total_emv',
                sortable: true,
                sortDir: 'desc',
                default: true,
                title: 'EMV',
                width: 150,
                cell: (row, column) => {
                    const totalEmv = _get(row.item, 'emv.total', 0);
                    const formattedEmv = '$' + formatNumber(totalEmv);
                    const postEmv = _get(row.item, 'emv.post', 0);
                    const engagementEmv = _get(row.item, 'emv.engagement', 0);
                    const impressionEmv = _get(row.item, 'emv.impression', 0);

                    const tooltipContent = (
                        <div className="fake-table">
                            <div className="fake-li bottom-border condensed">
                                <strong>Total: ${formatNumber(totalEmv)}</strong>
                            </div>
                            <div className="fake-li bottom-border condensed">
                                Post Value: ${formatNumber(postEmv)}
                            </div>
                            <div className="fake-li bottom-border condensed">
                                Engagement Value: ${formatNumber(engagementEmv)}
                            </div>
                            <div className="fake-li condensed">
                                Impression Value: ${formatNumber(impressionEmv)}
                            </div>
                        </div>
                    );

                    return <BasicCell
                        key={this.makeCellKey(row, column)}
                        row={row}
                        column={column}
                        value={<Tooltip position="right" html={tooltipContent}>{formattedEmv}</Tooltip>}
                    />
                },
                total: (() => { // iife for extra formatting
                    const sum = _get(this.state.totals, 'sums.total_emv', 0);
                    const avg = _get(this.state.totals, 'avgs.total_emv', 0);

                    const tooltipContent = (
                        <div className="fake-table">
                            <div className="fake-li bottom-border condensed">
                                Total: ${formatNumber(sum)}
                            </div>
                            <div className="fake-li condensed">
                                Average: ${formatNumber(avg)} per post
                            </div>
                        </div>

                    );

                    return <Tooltip
                        position="right"
                        animateFill={false}
                        arrow={false}
                        html={tooltipContent}>${formatNumberK(sum)} Total</Tooltip>
                })(),
            },
            {
                key: 'campaignStatus',
                sortable: false,
                default: true,
                title: 'Campaign Status',
                width: 300,
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row}
                    column={column}
                    value={()=>{
                        const status = row.item.human_status;
                        const submittedId = (row.item.submitted_campaigns || [])[0];
                        const publishedId = (row.item.published_campaigns || [])[0];
                        const submittedCampaign = (campaigns || {})[submittedId];
                        const publishedCampaign = (campaigns || {})[publishedId];
                        const submittedName = (submittedCampaign || {}).name;
                        const publishedName = (publishedCampaign || {}).name;
                        if (status === 'Submitted' && submittedName) {
                            return `Submitted to ${submittedName}`;
                        } else if (status === 'Published' && publishedName) {
                            return `Published on ${publishedName}`;
                        } else {
                            return 'Not submitted'
                        }
                    }}
                />
            },
            {
                key: 'type',
                default: true,
                sortable: false,
                width: 130,
                title: 'Type',
                cell: (row, column) => {
                    const {icon, type} = getIconAndTypeForPost(row.item.type);
                    const iconEl = <i className={icon} />;
                    let content = <span>{iconEl} {type}</span>;
                    return <BasicCell row={row} column={column} key={makeCellKey(row, column)} value={content} />
                },
                total: (() => { // iife for extra formatting
                    const tooltipRows = [];
                    let postTypes = _get(this.state.totals, 'types', {});
                    // add instagram to instagram-business
                    if (postTypes['instagram-business'] && postTypes.instagram) {
                        postTypes['instagram-business'] += postTypes.instagram;
                        // delete instagram after adding:
                        delete postTypes.instagram;
                    }

                    for (const postType in postTypes) {
                        const count = postTypes[postType];
                        const {icon, type} = getIconAndTypeForPost(postType);
                        const iconEl = <i className={icon} />;
                        tooltipRows.push(
                            <div className="fake-li bottom-border condensed" key={`post-type-${postType}`}>
                                {iconEl} {type}: {count}
                            </div>
                        );
                    }

                    const tooltipContent = (
                        <div className="fake-table">
                            <div className="fake-li bottom-border condensed">
                                Post Type Breakdown
                            </div>
                            {tooltipRows}
                        </div>

                    );

                    return <Tooltip
                        position="bottom"
                        animateFill={false}
                        arrow={false}
                        html={tooltipContent}>Show details</Tooltip>
                })(),
            },
            {
                key: 'tags',
                sortable: false,
                default: true,
                title: 'Tags',
                width: 200,
                cell: (row, column) => {
                    const tags = row.item.tags || [];
                    const tagsCount = tags.length;
                    const preview = limitChars(tags.join(', '), 15);
                    const popupContent = (
                        <div>
                            {tags.map((tag, index) => {
                                return (
                                    <div className={`fake-li slim ${(index < tagsCount-1) ? 'bottom-border' : ''}`}
                                         key={`tag-item-${tag}-${index}`}
                                         style={{paddingTop: 3, paddingBottom: 3}}
                                    >{tag}</div>
                                );
                            })}
                        </div>
                    );
                    let content;
                    if (tagsCount > 0) {
                        content = <Tooltip html={popupContent}>{preview} ({tagsCount} Tags)</Tooltip>
                    } else {
                        content = '-';
                    }

                    return (
                        <BasicCell row={row} column={column} key={makeCellKey(row, column)}
                                   value={content}
                        />
                    );

                }
            },
            {
                key: 'sections',
                sortable: false,
                default: true,
                title: 'Sections',
                width: 150,
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row}
                    column={column}
                    value={()=>{
                        if (!row.item.sections || !row.item.sections.length) {
                            return '-';
                        }
                        const sectionNames = row.item.sections.map(sectionId => {
                            let s = _find(this.props.sections || [], {mongo_id: sectionId});
                            if (s) {
                                return s.name;
                            }
                            return null;
                        }).filter(n => !!n);
                        return sectionNames.join(', ');
                    }}
                />
            },
            {
                key: 'user.name',
                sortable: false,
                default: true,
                title: 'User',
                width: 250,
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row}
                    column={column}
                    value={
                        <a className="dark" role="button"
                           onClick={() => this.props.openUserLightbox(row.item.user.id)}
                        >{row.item.user.name}</a>
                    }
                />,
                search: [
                    {
                        type: 'text',
                        name: 'name',
                        placeholder: 'Search by User Name',
                        options: {hideTitle: true}
                    }
                ],
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['name']))},
                total: _get(this.state.totals, 'uniques.user.id', 0) + ' Users',
            },
            {
                key: 'user.human_location',
                sortable: false,
                default: true,
                title: 'User Location',
                width: 300,
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row}
                    column={column}
                    value={
                        <a className="dark" role="button"
                           onClick={() => this.props.openUserLightbox(row.item.user.id)}
                        >{row.item.user.human_location}</a>
                    }
                />,
                search: [
                    {
                        type: 'text',
                        name: 'user.human_location',
                        placeholder: 'Search by User Location',
                        options: {hideTitle: true}
                    }
                ],
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.human_location']))}
            },
            // {
            //     key: 'avgImpPerPost',
            //     sortable: true,
            //     default: true,
            //     title: 'Average Impressions per Post',
            //     width: 150,
            //     cell: (row, column) => <NumberCell
            //         key={this.makeCellKey(row, column)}
            //         row={row}
            //         column={column}
            //         value={'avg. imp/post'}
            //     />
            // },
            // {
            //     key: 'avgEngPerPost',
            //     sortable: true,
            //     default: true,
            //     title: 'Average Engagements per Post',
            //     width: 150,
            //     cell: (row, column) => <NumberCell
            //         key={this.makeCellKey(row, column)}
            //         row={row}
            //         column={column}
            //         value={'avg. eng/post'}
            //     />
            // },
        ]
    }

    renderFiltersPopup() {
        if (!this.state.showFilters) {
            return null;
        }

        let cleanFilters = {...this.props.query.filters};

        if (cleanFilters.written && cleanFilters.written.max) {
            cleanFilters.written_before = cleanFilters.written.max;
        }

        if (cleanFilters.written && cleanFilters.written.min) {
            cleanFilters.written_after = cleanFilters.written.min;
        }

        cleanFilters = _omit(cleanFilters, ['written']);

        return (
            <GodzillaPostsFiltersToolbox
                key={'posts-filters-toolbox'}
                filters={cleanFilters}
                onClose={() => this.setState({showFilters: false})}
                onSave={(filters) => this.resetFilters(filters, null)}
                onReset={() => {
                    this.resetFilters({}, null);
                    this.setState({showFilters: false});
                }}
            />
        );

    }

    renderPopups() {
        return [
            this.renderFiltersPopup(),
            this.renderPostDetail(),
        ];
    }

    renderPostDetail() {
        const {selectedPost} = this.state;

        if (!selectedPost.id) return null;

        return <Toolbox
        content={<PostDetail
            postId={selectedPost.id}
            onClose={() => this.setState({selectedPost: {}})}
            handleNavigation={this.handlePostNavigation.bind(this)}
            />}
        addlClasses={'post-detail-toolbox'}
    />
    }

    renderButtons() {
        if (this.props.showContributeDashButtons) {
            return null;
        }

        const selectedPostIds = this.getSelectedPostIds();
        const selectedCount = selectedPostIds.length;
        const selectedCountLabel = selectedCount > 0 ? ` (${selectedCount})` : '';

        return [

            <GenericQuickFiltersButton
                classes={['btn-secondary']}
                key={'posts-quick-filters-button'}
                apiUrl={'/manage/api/post'}
                onClickFilter={(item) => this.resetFilters(item.filters, item.name)}
                filters={this.state.initialFilters}
                checks={[
                    {
                        name: 'Featured Posts',
                        filters: {featured: true}
                    },
                    {
                        name: 'Published Posts',
                        filters: {'status': 'published'},
                    },
                    {
                        name: 'Submitted Posts',
                        filters: {'status': 'submitted'},
                    },
                    {
                        name: 'Draft Posts',
                        filters: {'status': 'draft'},
                    },
                    {
                        name: 'Not-Published Posts',
                        filters: {'status': 'notpublished'},
                    },
                ]}

            />,

            <GenericActionsButton
                wrapperStyle={{marginLeft: 10}}
                classes={['btn-secondary']}
                key={'posts-actions-button'}
                actions={[
                    {
                        name: 'approveall',
                        title: 'Approve All' + selectedCountLabel,
                        isValid: () => selectedCount > 0,
                        onClick: () => this.handleBulkApprove()
                    },
                    {
                        name: 'rejectall',
                        title: 'Reject All' + selectedCountLabel,
                        isValid: () => selectedCount > 0,
                        onClick: () => this.handleBulkReject()
                    },
                    {
                        name: 'markspam',
                        title: 'Mark Spam' + selectedCountLabel,
                        isValid: () => selectedCount > 0,
                        onClick: () => this.handleBulkMarkSpam()
                    },
                ]}
            />

        ];
    }

    render() {
        return (
            <div className='react-manage-posts-wrapper'>
                <TableWrapper
                    title={this.props.showTitle ? this.props.title : null}
                    headerIcon={this.props.showIcon ? this.renderHeaderIcon() : null}
                    showLoadingAlert={this.shouldShowLoadingAlert()}
                    blurry={this.shouldBlur()}
                    items={this.props.posts}
                    columns={this.getColumns()}
                    showPagination={true}
                    page={this.props.query.page}
                    pages={this.getMaxPages()}
                    onPageChange={this.handlePageChange}
                    sort={this.props.query.sort}
                    onSortChange={this.handleSortChange}
                    switches={this.props.showSwitches ? this.renderSwitches() : null}
                    filters={this.props.query.filters || {}}
                    showFilters={false}
                    onFilterChange={this.handleFilterChange}
                    stickySortTabs={true}
                    popups={this.renderPopups()}
                    buttons={this.renderButtons()}
                    visibleColumns={this.props.cols}
                    showTotals={this.props.fetchTotals && this.state.didFetchTotals}
                    tabs={this.getTabs()}
                />

                <Alert
                    classes={['info']}
                    style={{marginTop: 50}}
                    content={<span>Problems with this new interface? Use the <a className="bold" href="/manage/do/page/posts/allv1">old posts interface</a> instead.</span>}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        posts: QuerySelectors.getPageItemsWithFallback(state.posts, props, DEFAULT_POSTS_QUERY),
        query: QuerySelectors.getQuery(state.posts, props, DEFAULT_POSTS_QUERY),
        campaigns: state.campaignsById.items,
        badges: getBadges(state, props),
        sections: getSections(state, props),
        teams: getTeams(state, props),
    }
};

const mapDispatchToProps = (dispatch, props) => {
    const url = props.queryUrl || '/manage/api/post';
    const totalsUrl = props.totalsUrl || '/manage/api/post/_/total';
    return {
        onSortChange: (sort, query) => dispatch(Query.updateQuerySort(sort, query, ActionTypes.POSTS_UPDATE_QUERY_SORT)),
        onPageChange: (page, query) => dispatch(Query.updateQueryPage(page, query, ActionTypes.POSTS_UPDATE_QUERY_PAGE)),
        fetchPosts: (query) => dispatch(Query.fetchQuery(url, query, ActionTypes.POSTS_REQUEST_QUERY, ActionTypes.POSTS_RECEIVE_QUERY)),
        openUserLightbox: (id, ctx, stack) => dispatch(openUserLightbox(id, ctx, stack)),
        onFilterChange: (filters, query) => dispatch(Query.updateQueryFilters(filters, query, ActionTypes.POSTS_UPDATE_QUERY_FILTERS)),
        ratePost: (postId, rating) => dispatch(updatePostRating(postId, rating, ActionTypes.UPDATE_POST_RATING)),
        featurePost: (postId, feature) => dispatch(featurePost(postId, feature, ActionTypes.FEATURE_POST)),
        onRefresh: (query) => dispatch(Query.fetchQuery(url, query, ActionTypes.POSTS_REQUEST_QUERY, ActionTypes.POSTS_RECEIVE_QUERY)),
        fetchBadgesIfNeeded: () => dispatch(fetchBadgesIfNeeded()),
        fetchSections: () => dispatch(fetchSections()),
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
        fetchTotals: (filters) => {
            let payload = { filters: JSON.stringify(filters) };
            let url = URI(totalsUrl).query(payload).toString();
            return dispatch(fetchAuthenticated(url))
                .then(resp => resp.json());
        }
    }
};

const ConnectedManagePosts = connect(mapStateToProps, mapDispatchToProps)(Posts);

export default ConnectedManagePosts;
