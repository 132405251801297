import {makeMomentFromDate} from "../utilities";
import moment from "moment";

export function getCampaignEndOrDueDate(campaign) {
    if (campaign.end) {
        return makeMomentFromDate(campaign.end);
    }
    return null;
}

export function filterBriefsByDate(briefs, useStartDate=true) {
    const weekAgo = moment().subtract(1, 'week');
    console.log("Filter briefs by date: ", briefs);
    return (briefs || [])
        .filter(campaign => {
            console.log("Filtering campaign: ", campaign);
            const endDate = getCampaignEndOrDueDate(campaign);
            if (!endDate) {
                console.log("No end date, returning false");
                return true;
            }
            if (endDate.isBefore(weekAgo)) {
                console.log("End date is before week ago, returning false");
                return false;
            }
            console.log("End date is after week ago, returning true");
            return true;
        })
        .filter(campaign => {
            if (!useStartDate) {
                return true;
            }

            const startDate = makeMomentFromDate(campaign.start);
            if (!startDate) {
                return false;
            }

            if (startDate.isAfter(moment())) {
                return false;
            }

            return true;

        });
}

export function filterBriefsForTeam(briefs, team, showIfNoTeam=true) {
    if (!team) {
        return briefs || [];
    }

    return (briefs || [])
        .filter(brief => {
            if (brief.billable_team_id) {
                return brief.billable_team_id === team.id;
            }
            return showIfNoTeam;
        });
}

export function filterBriefsForUser(briefs, user, filterByTeam = true) {

    console.log("Filter briefs for user: ", briefs, user);
    return (briefs || [])
        .filter(brief => {

            console.log("Filtering brief: ", brief);

            if (!!user.blocked) {
                console.log("User is blocked, returning false");
                return false;
            }

            if (!!brief.invite_only) {
                // In the future, this should check if the user is invited to the brief
                console.log("Brief is invite only, checking user IDs...");

                if (!user || !user.id) {
                    console.log("User is not logged in, returning false");
                    return false;
                }

                if ((brief.invite_only_users || []).indexOf(user.id) > -1) {
                    console.log("User is invited, returning true");
                    return true;
                }

                return false;
            }

            if (filterByTeam && brief.billable_team_id) {
                const userHasTeam = ((user || {}).teams || []).indexOf(brief.billable_team_id) > -1;
                if (userHasTeam) {
                    console.log("User has team, returning true");
                    return true;
                } else {
                    console.log("User does not have team, returning false");
                    return false;
                }
            }


            console.log("Brief has no billable team, returning true");
            return true;
        });
}

export function magicFilterBriefs(briefs, user, team, showIfNoTeam=true, filterByTeam=true) {
    return filterBriefsByDate(
        filterBriefsForTeam(
            filterBriefsForUser(briefs, user, filterByTeam),
            team,
            showIfNoTeam
        )
    ).filter(brief => !brief.archived);
}
