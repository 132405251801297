import React, {Component} from 'react';
import PropTypes from 'prop-types';

import TableWrapper from '../Table/Wrapper';
import BasicCell from '../Table/Cell/Basic';
import NumberCell from '../Table/Cell/Number';
import DateCell from '../Table/Cell/Date';
import LinkCell from '../Table/Cell/Link';
import EditableTextCell from '../Table/Cell/EditableText';
import Checkbox from '../Common/Form/Checkbox';
import Toolbox from '../Common/Toolbox';
import ColumnSelectToolbox from "../Common/ColumnSelectToolbox";
import ActivationActionsToolbox from '../Activation/ActionsToolbox';
import ActivationInteractionToolbox from '../Activation/InteractionToolbox';
import BulkActivationInteractionToolbox from '../Activation/BulkInteractionToolbox';
import ConnectedInteractionsTimeline from '../../containers/Campaign/InteractionsTimeline';
import ActivationMiniProfile from '../Activation/ActivationMiniProfile';
import ActivationInvitationToolsButton from '../Activation/InvitationToolsButton';
import ActivationPaymentToolsButton from '../Activation/PaymentToolsButton';
import ActivationExpandoPosts from '../Activation/ExpandoPosts';
import CampaignSettingsToolbox from '../Campaign/Setup/SettingsToolbox';
import HealthCheckButton from '../Campaign/HealthCheckButton';
import Tooltip from '../Common/Tooltip';
import TinyProgressPie from '../Chart/TinyProgressPie';
import ConnectedCampaignReport from '../../containers/Campaign/Report';
import ConnectedActivationExpandoStats from '../Activation/ExpandoStats';
import {Redirect} from 'react-router-dom';

import {
    formatNumber, makeDateSortFn, makeMomentFromDate, makeNumberSortFn, makeStringSortFn, getLocalStorage,
    setLocalStorage, formatDollar, formatNumberK, formatRangeValue, fetchAuthenticated, hasPushState
} from "../../utilities";
import {getAvailableMetadata} from "../../selectors/queries";
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _find from 'lodash/find';
import _mean from 'lodash/mean';
import _debounce from 'lodash/debounce';
import * as URI from 'urijs';
import {
    getVisibleInfluencerRequirementTypes,
    getCompletedInfluencerRequirementTypes,
    getContentRequirementsList, getContentRequirementsMap
} from "../../utilities/campaign";
import {socialIconForType} from "../../utilities/social";
import StarRating from "../Common/StarRating";
import moment from 'moment';
import BulkActionsButton from "../Campaign/BulkActionsButton";
import ActivationStarRating from '../Activation/StarRating';
import {toggleRowSelectedWithMultiSelect} from "../../utilities/table";
import Alert from "../Common/Alert";
import AddUsersToCampaignToolbox from "../Campaign/Setup/AddUsersToCampaignToolbox";
import CampaignSetupWizard from "../Campaign/Setup/Wizard/CampaignSetupWizard";
import ConnectedPostDetail from "../Post/PostDetail";
import ExportToolsToolbox from "../Campaign/Setup/ExportToolsToolbox";
import ImportTrackingNumbersToolbox from "../Campaign/Setup/ImportTrackingNumbersToolbox";
import ConnectedEmails from "./Manage/Users/Emails";

const LOCALSTORAGE_COLS_KEY = 'activations-table-visibleColumns';

const makeMenuSwitchKey = row => `activation-menu-${row.item.id}`;

const ISSUES_TIMELINE_FILTERS = [
    'Tidal\\Campaign\\Activation\\Interaction\\RaiseIssue',
    'Tidal\\Campaign\\Activation\\Interaction\\ResolveIssue',
    'Tidal\\Campaign\\Activation\\Interaction\\CommentOnIssue',
];
const DRAFTS_TIMELINE_FILTERS = [
    'Tidal\\Campaign\\Activation\\Interaction\\SubmitDraft',
    'Tidal\\Campaign\\Activation\\Interaction\\UpdateDraftStatus',
    'Tidal\\Campaign\\Activation\\Interaction\\CommentOnDraft',
];
const STATUS_FILTERS = [
    {value: 'canceled', text: 'Canceled'},
    {value: 'waitlisted', text: 'Waitlisted'},
    {value: 'not-invited', text: 'Not Invited'},
    {value: 'invited', text: 'Invited, Pending Response'},
    {value: 'declined', text: 'Declined Invitation'},
    {value: 'payment-owed', text: 'Awaiting Payment'},
    {value: 'fulfilled', text: 'Influencer Complete'},
    {value: 'completed', text: 'Activation Complete'},
    {value: 'drafting', text: 'Drafting Content'},
    {value: 'submitted-posts', text: 'Content Needs Approval'},
    {value: 'posting', text: 'Submitting Content'},
    {value: 'accepted', text: 'Accepted Invitation'},
    {value: 'shipping', text: 'Shipping Samples'},
    {value: 'unknown', text: 'Unknown'},
];

const TAB_COLUMNS = {
    overview: [
        'status.status',
        'campaign.name',
        // 'status.open_issues_count',
        'advanced_at',
        'progress',
        'payment_offered',
        'content_requirements',
        'form_responses',
        'user.verified_reach',
        'user.tier',
        'status.draft_updated_at',
        'settings',
    ],
    form: [],
    users: [
        'user.verified_reach',
        'user.tier',
        'user.gender',
        'user.instagram_followers',
        'user.blog_traffic',
        'user.facebook_followers',
        'user.facebook_page_followers',
        'user.pinterest_followers',
        'user.youtube_subscribers',
        'user.twitter_reach',
        'user.blog_name.raw',
        'user.human_location.raw',
        'user.overall_avg_engagement_pct',
        'user.avg_payment_amount',
        'user.avg_post_rating',
        'rating',
    ],
    invitation: [
        'invitation_tools',
        'invited_at',
        'status.invitation_reminder_sent_at',
        'nda_signed_at',
        'accepted_at',
        'metadata.shipping_address_reminder_sent_at',
        'metadata.post_reminder_sent_at',
        'declined_at',
        'waitlisted_at',
        'canceled_at',
    ],
    gifting: [
        'gifting_tools',
        'payment_offered',
        'product',
        'shipping_address',
        'metadata.tracking_number',
        'status.is_product_shipped',
        'status.is_product_received',
        'payment_profile',
        'invoiced_at',
        'metadata.payment_due_date',
        'coupon_code',
    ],
    stats: [
        // 'user.tier',
        // 'user.verified_reach',
        // 'stats.total_posts',
        // 'stats.total_engagements',
        // 'stats.overall_engagement_pct',
        'stats.emv',
        'stats.roi',
        'blog_stats',
        'instagram_stats',
        'instastory_stats',
        'twitter_stats',
        'tiktok_stats',
        'facebook_page_stats',
        'pinterest_stats',
        'youtube_stats',
        'total_stats',
        'stats.total_images',
        'stats.total_videos',
    ],
    content: [
        'posts-rollup-num-posts',
        'posts-rollup-types',
        'posts-rollup-rating',
        'posts-rollup-created',
        'posts-rollup-updated',
        'posts-rollup-engagements',
        'posts-rollup-status',
        // 'status.open_drafts_count',
        // 'status.content_submitted_count',
        // 'status.content_accepted_count',
        // 'status.num_youtube_posts_uploaded',
        // 'status.num_facebook_posts_uploaded',
        // 'status.num_facebook_page_posts_uploaded',
        // 'status.num_pinterest_posts_uploaded',
        // 'status.num_instagram_posts_uploaded',
        // 'status.num_blog_posts_uploaded',
        // 'status.num_twitter_posts_uploaded',
    ]
};

export default class ActivationsPage extends Component {

    static defaultProps = {
        parseQueryString: true,
        usePushState: true,
        singleUserMode: false,
        allowWizard: false,
        isPublic: false,
    };

    static propTypes = {
        query: PropTypes.object,
        onSortChange: PropTypes.func,
        onPageChange: PropTypes.func,
        onFilterChange: PropTypes.func,
        onRefresh: PropTypes.func,
        campaigns: PropTypes.array,
        activations: PropTypes.array,
        updateActivationMetadata: PropTypes.func,
        openUserLightbox: PropTypes.func,
        fetchAuthenticated: PropTypes.func,
        fetchActivation: PropTypes.func,
        fetchCampaign: PropTypes.func,
        parseQueryString: PropTypes.bool,
        usePushState: PropTypes.bool,
        campaignId: PropTypes.number,
        campaign: PropTypes.object,
        singleUserMode: PropTypes.bool,
        title: PropTypes.string,
        allowWizard: PropTypes.bool,
        isPublic: PropTypes.bool,
        forms: PropTypes.array,
        fetchForms: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            switches: {},
            expandos: {},
            visibleColumns: null,
            selectedIds: {},
            lastSelectedIndex: null,
            selectAll: false,
            selectEverything: false, // Select all objects across all pages
            isFetchingAllIds: false,
            currentInteraction: null,
            currentBulkInteraction: null,
            currentTimeline: null,
            tab: null,
            initialFilters: props.query.filters,
            totals: null,
            isFetchingTotals: false,
            didFetchTotals: false,
            title: this.props.title ? this.props.title : "Activations",
            postId: null,
            filterDebouncer: null,
        };

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.toggleSwitchActive = this.toggleSwitchActive.bind(this);
        this.setSwitchState = this.setSwitchState.bind(this);
        this.setActiveTab = this.setActiveTab.bind(this);
        this.isSwitchActive = this.isSwitchActive.bind(this);
        this.isTabActive = this.isTabActive.bind(this);
        this.isRowSelected = this.isRowSelected.bind(this);
        this.toggleRowSelected = this.toggleRowSelected.bind(this);
        this.handleColumnVisibilityChange = this.handleColumnVisibilityChange.bind(this);
        this.handleShowTimeline = this.handleShowTimeline.bind(this);
        this.handleClickInteraction = this.handleClickInteraction.bind(this);
        this.setExpandoType = this.setExpandoType.bind(this);
        this.getExpandoType = this.getExpandoType.bind(this);
        this.isRowExpanded = this.isRowExpanded.bind(this);
        this.renderExpander = this.renderExpander.bind(this);
        this.getNumberOfActiveFilters = this.getNumberOfActiveFilters.bind(this);
        this.getFilterTitle = this.getFilterTitle.bind(this);
        this.getFilterOnClick = this.getFilterOnClick.bind(this);
        this.getFilterTag = this.getFilterTag.bind(this);
        this.handleEscapeKey = this.handleEscapeKey.bind(this);

        this.debouncedHandleRefresh = _debounce(this.handleRefresh, 500);
        this.fetchTotalsPromise = null;

    }

    componentDidMount() {
        const {fetchCampaign, campaignId} = this.props;

        if (campaignId) {
            fetchCampaign(campaignId)
                .then(() => {
                    this.campaign = null;
                    // if the campaign uses a custom form, fetch those
                    const useCustom = _get(this.props.campaign, 'settings.use_custom_form', false);
                    if (!!useCustom) {
                        this.props.fetchForms(this.props.campaign.id)
                            .then(() => {
                                // need to refresh the current tab, in case we're on the form tab and need to add the columns
                                if (this.state.tab === 'form') {
                                    this.setActiveTab(this.state.tab);
                                }
                            });
                    }
                });
        }

        this.initFromURL();
        document.addEventListener("keydown", this.handleEscapeKey, false);

    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleEscapeKey, false);
        this.resetFilters({}, null);
    }

    handleEscapeKey(event){
        if (event.keyCode === 27) {
            this.setState({
                currentInteraction: null,
                currentBulkInteraction: null,
                currentTimeline: null,
            });
            this.setSwitchState('settings', false);
        }
    }

    updateQueryString() {
        if (!this.props.usePushState || !hasPushState) {
            return;
        }

        const initialFilterKeys = Object.keys(this.state.initialFilters || {});
        const filtersToUse = _omit(this.props.query.filters, initialFilterKeys);
        const uri = new URI(window.location.search);

        let params = {
            tab: this.state.tab,
        };

        if (Object.keys(filtersToUse).length > 0) {
            params.filters = btoa(JSON.stringify(filtersToUse));
        } else {
            params.filters = undefined;
        }

        uri.search({...uri.search(true), ...params});

        window.history.pushState(params, this.state.title, uri.search());

    }

    initFromURL() {

        if (!this.props.parseQueryString) {
            this.initColumnPreferences();
            this.handleRefresh();
            return;
        }

        // Check for filters and tab in initial request
        const uri = new URI(window.location.href);
        const query = uri.search(true);
        let shouldDelay = false;

        // Load active tab
        if (typeof query.tab !== 'undefined' && Object.keys(TAB_COLUMNS).indexOf(query.tab) > -1) {
            this.setActiveTab(query.tab);
        } else {
            this.initColumnPreferences();
        }

        if (typeof query.limit !== 'undefined') {
            this.handleLimitChange(query.limit);
            shouldDelay = true;
        }

        // Load query filters.
        if (typeof query.filters !== 'undefined' && Object.keys(query.filters || {}).length > 0) {
            shouldDelay = true;
            try {
                const filters = JSON.parse(atob(query.filters));
                const whitelisted = _omit(filters, ['allow_inactive_users']);
                if (filters) {
                    if (Object.keys(whitelisted).length > 0) {
                        this.setSwitchState('filter', true);
                    }
                    setTimeout(() => this.resetFilters(filters), 100);
                }
            } catch (e) {
            }
        }

        let refreshPromise;
        if (shouldDelay) {
            refreshPromise = new Promise((resolve) => {
                setTimeout(() => {
                    this.handleRefresh()
                        .then(() => resolve());
                }, 500);
            });
        } else {
            refreshPromise = this.handleRefresh();
        }

        if (refreshPromise) {
            refreshPromise.then(() => {
                this.launchWizardIfNeeded();
            });
        }

    }

    launchWizardIfNeeded() {
        const {campaign, allowWizard} = this.props;
        const didLaunchWizard = !!((campaign || {}).settings || {}).did_launch_wizard;
        let total = this.getTotalHits();
        if (!total) total = 0;

        if (allowWizard && total <= 1 && !didLaunchWizard) {
            // this.toggleSwitchActive('wizard');
        }

    }

    initColumnPreferences() {
        const savedVisibleColumns = getLocalStorage(LOCALSTORAGE_COLS_KEY);
        if (savedVisibleColumns) {
            this.handleColumnVisibilityChange(savedVisibleColumns);
        } else {
            this.setActiveTab('overview');
        }
    }

    async fetchAllIds() {
        this.setState({isFetchingAllIds: true});

        const filters = {
            campaign_id: this.props.campaign.id,
            ...this.props.query.filters
        };

        const payload = {
            filters: JSON.stringify(filters),
            metaOnly: true,
            limit: 1000
        };

        let url = URI(`/manage/api/activation`)
            .query(payload)
            .toString();

        const resp = await this.props.fetchAuthenticated(url);
        const json = await resp.json();

        this.setState({isFetchingAllIds: false});
        return json.data;
    }

    setExpandoType(row, type = null) {

        let newType = type;

        if (type === this.getExpandoType(row)) {
            newType = null;
        }

        this.setState({
            expandos: {
                ...this.state.expandos,
                [row.item.id]: newType
            }
        })
    }

    isRowExpanded(row) {
        return this.getExpandoType(row) !== null;
    }

    getExpandoType(row) {
        // If on content view, expand automatically
        if (this.state.tab === 'content') {
            return 'ViewPosts';
        }
        return this.state.expandos[row.item.id] || null;
    }

    getSelectedIds() {
        return Object.keys(this.state.selectedIds)
            .filter(id => !!this.state.selectedIds[id]);
    }

    isRowSelected(row) {
        return !!this.state.selectedIds[row.item.id];
    }

    toggleRowSelected(row, event = null) {
        const newState = toggleRowSelectedWithMultiSelect(this.state, this.props.activations, row, event);
        this.setState({...newState, selectAll: false, selectEverything: false});
    }

    handleShowTimeline(row, types = null, title = null) {
        this.setState({
            currentTimeline: {
                activationId: row.item.id,
                types,
                title
            }
        })
    }

    handleClickBulkInteraction(interactionName) {

        this.setState({
            currentBulkInteraction: interactionName
        });

    }

    handleClickInteraction(row, interactionName, context = {}) {

        this.setSwitchState(makeMenuSwitchKey(row), false);

        if (interactionName === 'ViewHistory') {
            return this.handleShowTimeline(row);
        }

        // If the context is an event (because of binding), null it out
        const actualContext = typeof context.preventDefault === 'undefined' ? context : null;

        this.setState({
            currentInteraction: {
                type: interactionName,
                activationId: row.item.id,
                context: actualContext,
            }
        });

    }

    handleColumnVisibilityChange(visibleColumns) {
        this.setState({visibleColumns, tab: null});
        setLocalStorage(LOCALSTORAGE_COLS_KEY, visibleColumns);
    }

    isSwitchActive(name) {
        return !!this.state.switches[name];
    }

    setSwitchState(name, value) {
        this.setState({
            switches: {
                ...this.state.switches,
                [name]: value
            }
        });
    }

    toggleSwitchActive(name) {

        this.setState({
            switches: {
                ...this.state.switches,
                [name]: !this.isSwitchActive(name)
            }
        });
    }

    toggleMenuActive(id) {
        const activeMenu = this.state.switches.activationMenu;
        const value = activeMenu === id ? null : id;
        this.setState({
           switches: {
               ...this.state.switches,
               activationMenu: value
           }
        });
    }

    isMenuActive(id) {
        return id === this.state.switches.activationMenu;
    }

    componentDidUpdate(prevProps) {
        this.detectUpdateRequiringRefresh(prevProps);
        this.updateQueryString();
    }

    detectUpdateRequiringRefresh(prevProps) {
        if (this.props.query.isDirty && !prevProps.query.isDirty && !this.props.query.isFetching) {
            this.debouncedHandleRefresh();
        }
    }

    fetchTotals() {
        const slot = window.Gator.getDashboardSlot() || 'manage';
        const urlRoot = `/${slot}/api/activation/_/total`;
        let payload = {
            filters: JSON.stringify(this.props.query.filters)
        };
        let url = URI(urlRoot)
            .query(payload)
            .toString();
        this.setState({isFetchingTotals: true});
        this.fetchTotalsPromise = this.props.fetchAuthenticated(url);
        this.fetchTotalsPromise
            .then(resp => resp.json())
            .then(json => this.setState({totals: json.data, isFetchingTotals: false, didFetchTotals: true}));
        return this.fetchTotalsPromise;
    }

    handleRefresh() {
        return this.props.onRefresh(this.props.query)
            .then(() => this.fetchTotals());
    }

    handleSortChange(sort) {
        this.handlePageChange(1);
        return this.props.onSortChange(sort, this.props.query);
    }

    handlePageChange(page) {
        return this.props.onPageChange(page, this.props.query);
    }

    handleFilterChange(name, value) {
        let cleanValue = value;
        if (cleanValue === '') cleanValue = null;

        //resets filters to null for checkboxes (instead of false)
        if ((name === 'status.did_user_accept_invitation'
            || name === 'status.did_user_decline_invitation'
            || name === 'status.is_invitation_pending') && !value) {
            cleanValue = null
        }

        const filters = {...this.props.query.filters, [name]: cleanValue};
        return this.props.onFilterChange(filters, this.props.query);
    }

    handleLimitChange(limit) {
        return this.props.onFilterChange(this.props.query.filters, {...this.props.query, limit});
    }

    resetFilters(additional = {}, title = null) {
        const filters = {...this.state.initialFilters, ...additional};
        this.setState({title});
        return this.props.onFilterChange(filters, this.props.query);
    }

    getFilterValue(name) {
        return (this.props.query.filters || {})[name];
    }

    getBoolFilterText(name, ifTrue, ifFalse, ifUnset = null) {
        const value = this.getFilterValue(name);
        if (value === true) {
            return ifTrue;
        }
        if (value === false) {
            return ifFalse;
        }
        return ifUnset;
    }

    getCurrentPageMeta() {
        const {query} = this.props;
        return getAvailableMetadata(query);
    }

    getTotalHits() {
        const meta = this.getCurrentPageMeta();
        if (!meta || !meta.hits) return 0;
        return parseInt(meta.hits, 10);
    }

    getTotalActivationsInCampaign() {
        const meta = this.getCurrentPageMeta();
        const totalKey = this.isAllowingInactiveUsers() ? 'campaign_total' : 'campaign_active';
        if (!meta || !meta[totalKey]) return 0;
        return parseInt(meta[totalKey], 10);
    }

    getMaxPages() {
        const hits = this.getTotalHits();
        if (!hits) return 1;
        return Math.ceil(hits / (this.props.query.limit || 10));
    }

    renderCurrentTimelinePopup() {
        const {currentTimeline} = this.state;
        if (!currentTimeline) return null;
        const activation = _find(this.props.activations, {id: currentTimeline.activationId});

        return <Toolbox
            supportMobile={true}
            content={
                <ConnectedInteractionsTimeline
                    activationId={currentTimeline.activationId}
                    types={currentTimeline.types}
                    style={{
                        maxHeight: 600,
                        overflow: 'auto'
                    }}
                />
            }
            onClose={() => {
                this.setState({currentTimeline: null});
            }}
            title={(currentTimeline.title || 'Activation History') + ' - ' + activation.user.name}
            style={{
                top: 60,
                width: 800,
                left: '50%',
                marginLeft: -400,
                position: 'fixed'
            }}
        />

    }

    getCampaign() {
        // NOTE: This might not work out too great if the table switches campaigns without reloading.
        // So revisit that in the future if it comes to pass.
        // Otherwise, the purpose of this is to 'cache' this.campaign so that we're not calling _find a billion times.
        if (this.campaign) {
            return this.campaign;
        }

        this.campaign = _find(this.props.campaigns, {id: parseInt(this.props.query.filters.campaign_id, 10)});
        return this.campaign;
    }

    renderCurrentBulkInteractionPopup() {
        const {currentBulkInteraction} = this.state;
        if (!currentBulkInteraction) return null;
        const campaign = this.getCampaign();

        return <BulkActivationInteractionToolbox
            activationIds={this.getSelectedIds()}
            campaign={campaign}
            type={currentBulkInteraction}
            onClose={() => this.setState({currentBulkInteraction: null})}
            onComplete={() => setTimeout(() => this.setState({currentBulkInteraction: null, selectedIds: {}, selectAll: false}), 1000)}
            refresh={this.handleRefresh.bind(this)}
        />

    }

    renderCurrentInteractionPopup() {
        const {currentInteraction} = this.state;
        if (!currentInteraction) return null;
        const activation = _find(this.props.activations, {id: currentInteraction.activationId});
        const campaign = this.getCampaign();

        return <ActivationInteractionToolbox
            activation={activation}
            campaign={campaign}
            type={currentInteraction.type}
            context={currentInteraction.context}
            onClose={() => this.setState({currentInteraction: null})}
        />

    }

    renderPopups() {
        const campaign = _find(this.props.campaigns, {id: parseInt(this.props.query.filters.campaign_id, 10)});
        const campaignId = parseInt(this.props.query.filters.campaign_id, 10);

        return [
            this.isSwitchActive('wizard')
                ? <CampaignSetupWizard
                    campaign={campaign}
                    settings={(campaign || {}).settings || {}}
                    onClose={this.toggleSwitchActive.bind(this, 'wizard')}
                    onActivatedUsers={() => {
                        setTimeout(this.handleRefresh.bind(this), 3000);
                    }}
                    onClickReviewSettings={() => {
                        this.setState({
                            switches: {
                                wizard: false,
                                settings: true
                            }
                        });
                        this.handleRefresh();
                    }}
                />
                : null,
            this.isSwitchActive('activate-users')
                ? <AddUsersToCampaignToolbox
                    onClose={this.toggleSwitchActive.bind(this, 'activate-users')}
                    campaign={campaign}
                    onActivatedUsers={() => {
                        setTimeout(async () => {
                            await this.handleRefresh();
                            this.toggleSwitchActive('activate-users');
                        }, 3000);
                    }}
                />
                : null,
            this.isSwitchActive('columns')
                ? <ColumnSelectToolbox
                    visibleColumns={this.state.visibleColumns}
                    columns={this.getColumns()}
                    onClose={this.toggleSwitchActive.bind(this, 'columns')}
                    onChange={this.handleColumnVisibilityChange}
                    style={{top: 60, right: 0}}
                    innerScrollHeight={400}
                />: null,

            this.isSwitchActive('settings')
                ? <CampaignSettingsToolbox
                    onClose={() => this.toggleSwitchActive('settings')}
                    campaign={campaign}
                    style={{
                        top: 60,
                        left: '50%',
                        width: 1000,
                        marginLeft: -500,
                        backgroundColor: '#EEE',
                    }}
                />
                : null,

            this.isSwitchActive('export')
                ? <ExportToolsToolbox
                    campaignId={campaignId}
                    style={{ top: 60, right: 0 }}
                    onClose={() => this.toggleSwitchActive('export')}
                    onClickUploadMetadata={() => {
                        // use switch state for this even though it's not technically a switch
                        this.setSwitchState('import-tracking-numbers', true);
                    }}
                />
                : null,

            this.isSwitchActive('import-tracking-numbers')
                ? <ImportTrackingNumbersToolbox
                    onClose={() => this.setSwitchState('import-tracking-numbers', false)}
                />
                : null,

            this.isSwitchActive('inbox')
                ? <Toolbox
                    onClose={this.toggleSwitchActive.bind(this, 'inbox')}
                    title={`${campaign.name} Email Inbox`}
                    addlClasses="toolbox-fixed toolbox-lg"
                    content={
                        <ConnectedEmails
                            showTeamTabs={false}
                            key={`campaign-emails-${campaign.id}`}
                            query={{
                                id: `campaign-emails-${campaign.id}`,
                                filters: {
                                    'campaign_id': campaign.id,
                                    "inbox":true,
                                    "hide_spam":true,
                                    "hide_archived":true
                                }
                            }}
                        />
                    }
                />
                : null,

            !!this.state.postId
                ? (
                    <Toolbox
                        addlClasses={'toolbox-fixed toolbox-lg'}
                        content={
                            <ConnectedPostDetail postId={this.state.postId} onClose={() => this.setState({postId: null})} />
                        }
                    />
                )
                : null,

            this.renderCurrentInteractionPopup(),
            this.renderCurrentBulkInteractionPopup(),
            this.renderCurrentTimelinePopup(),

            // this.isSwitchActive('filter')
            //     ? <ActivationFiltersToolbox
            //         onClose={this.toggleSwitchActive.bind(this, 'filter')}
            //         filters={this.props.query.filters}
            //         onFieldChange={this.handleFilterChange}
            //         campaigns={this.props.campaigns}
            //     />
            //     : null,
        ];
    }

    renderSwitches() {

        const {campaign, allowWizard, isPublic} = this.props;
        if (!campaign || campaign.isFetching || this.props.singleUserMode) {
            return [];
        }

        return [
            (allowWizard && !isPublic) ? {
                name: 'wizard',
                icon: <i className="fas fa-magic" />,
                tooltip: 'Launch the setup wizard.',
                isActive: this.isSwitchActive('wizard'),
                onClick: this.toggleSwitchActive.bind(this, 'wizard')
            } : null,
            !isPublic ? {
                name: 'activate-users',
                icon: <i className="fas fa-user-plus" />,
                tooltip: 'Activate users into this campaign.',
                isActive: this.isSwitchActive('activate-users'),
                onClick: this.toggleSwitchActive.bind(this, 'activate-users'),
            } : null,
            !isPublic ? {
                name: 'settings',
                icon: <i className="fa fa-cog" />,
                tooltip: "Settings for this campaign.",
                isActive: this.isSwitchActive('settings'),
                onClick: this.toggleSwitchActive.bind(this, 'settings'),
            } : null,
            // {
            //     name: 'stats',
            //     icon: <i className="fa fa-pie-chart" />,
            //     tooltip: "Show aggregated stats for this campaign.",
            //     isActive: this.isSwitchActive('stats'),
            //     onClick: this.toggleSwitchActive.bind(this, 'stats'),
            // },
            // {
            //     name: 'columns',
            //     icon: <i className="fa fa-cog" />,
            //     tooltip: "Customize the columns displayed in this table.",
            //     isActive: this.isSwitchActive('columns'),
            //     onClick: this.toggleSwitchActive.bind(this, 'columns'),
            // },
            !isPublic ? {
                name: 'export',
                icon: <i className='fa fa-download'/>,
                tooltip: 'Export Tools.',
                isActive: this.isSwitchActive('export'),
                onClick: this.toggleSwitchActive.bind(this, 'export')
            } : null,
            !isPublic ? {
                name: 'inbox',
                icon: <i className="fa fa-envelope" />,
                tooltip: 'Show emails for this campaign',
                isActive: this.isSwitchActive('inbox'),
                onClick: this.toggleSwitchActive.bind(this, 'inbox')
            } : null,
            {
                name: 'filter',
                icon: <i className="fa fa-search" />,
                tooltip: "Show search and filter options.",
                isActive: this.isSwitchActive('filter'),
                onClick: this.toggleSwitchActive.bind(this, 'filter'),
            },
        ].filter(Boolean);
    }

    handleQuickFilterClick(spec) {
        let filters = {};

        filters = spec.filters;
        this.setActiveTab(spec.tab);
        this.setSwitchState('filter', true);
        this.resetFilters(filters, spec.name);
    }

    renderButtons() {

        const selectedIds = this.getSelectedIds();
        const campaign = this.getCampaign() || {};
        const isPublic = this.props.isPublic;

        return [
            (campaign && campaign.id) ? <HealthCheckButton
                channel={this.props.channel}
                campaign={campaign}
                onClickFilter={this.handleQuickFilterClick.bind(this)}
                classes={['v3', 'tidal-btn', 'btn-primary', 'small', 'bold']}
                wrapperStyle={{display: 'inline-block'}}
            /> : null,

            (campaign && campaign.id && !isPublic) ?
                <BulkActionsButton
                    channel={this.props.channel}
                    campaign={campaign}
                    onClickInteraction={this.handleClickBulkInteraction.bind(this)}
                    wrapperStyle={{display: 'inline-block', marginLeft: 10}}
                    activationIds={selectedIds}
                    classes={['v3', 'tidal-btn', 'btn-secondary', 'small', 'bold']}
                />
                : null,


        ];
    }

    makeDateCell(key, title, params = {}) {
        return {
            key,
            title,
            sortable: true,
            default: true,
            width: 180,
            cell: (row, column) =>
                <DateCell
                    row={row}
                    column={column}
                    value={_get(row.item, key)}
                    key={`activation-date-cell-id-${row.item.id}-key-${key}`}
                />,
            ...params
        }
    }

    makeCellKey(row, col) {
        return `activations-cell-id-${row.item.id}-col-${col.key}`;
    }

    makeCompoundStatsCell(key, title, countKey, reachKey, engKeys, impressionKeys, settingKey, params = {}) {
        const totalPosts = formatNumberK(_get(this.state.totals, `sums.${countKey}`, 0)) + ' Posts';
        let totalEngs = 0;
        for (const engKey of engKeys) {
            totalEngs += parseInt(_get(this.state.totals, `sums.${engKey}`, 0), 10);
        }
        totalEngs = formatNumberK(totalEngs, 0) + ' Engs';

        return {
            key,
            title,
            sortable: false,
            default: true,
            width: 180,
            total: [totalPosts, totalEngs].join(', '),
            cell: (row, column) => {

                let value;
                const numRequired = _get(row.item, settingKey, 0);
                const count = _get(row.item, countKey, 0);
                const reach = _get(row.item, reachKey, 0);
                let engs = 0, views = 0;
                for (const engKey of engKeys) {
                    engs += parseInt(_get(row.item, engKey, 0), 10);
                }
                for (const impKey of impressionKeys) {
                    views += parseInt(_get(row.item, impKey, 0), 10);
                }
                const engPct = reach > 0 ? (100 * (engs / reach)) : null;

                let innerClasses = ['compound-stats-cell-inner'];
                let postsClasses = [];
                let reachClasses = [];
                let engClasses = [];

                if (numRequired > 0 && !!row.item.accepted_at) {

                    if (count === 0) {
                        postsClasses.push('danger');
                    } else if (count < numRequired) {
                        postsClasses.push('warning');
                    }
                    if (reach < 10) {
                        reachClasses.push('warning');
                    }
                    if (engs < 10) {
                        engClasses.push('warning');
                    }
                }


                if ((numRequired === 0 && count === 0) || !row.item.accepted_at) {
                    value = (<div className="compound-stats-cell-inner empty">N/A</div>);
                } else {
                    const impressionsSpan = views > 0 ? <span>/ {formatNumberK(views)} Imps.</span> : null;
                    value = (
                        <div className={innerClasses.join(' ')}>
                            <div className={postsClasses.join(' ')}>{formatNumber(count)} Posts</div>
                            <div className={reachClasses.join(' ')}>{formatNumberK(reach )} Reach {impressionsSpan}</div>
                            <div className={engClasses.join(' ')}><strong>{formatNumber(engs)}</strong> Engs {engPct ? `(${formatNumber(engPct, 1)}%)` : ''}</div>
                        </div>
                    );
                }

                return (
                    <BasicCell
                        style={{cursor: 'pointer'}}
                        onClick={() => this.handleClickInteraction(row, 'ViewPostDrivers', {})}
                        row={row} column={column} key={`compound-stats-cell-id-${row.item.id}-key-${key}`}
                        classes={['compound-stats-cell']}
                        value={value}
                    />
                );
            },
            ...params
        };

    }

    makeStatsCell(key, title, params = {}) {
        return {
            key,
            title,
            sortable: true,
            default: true,
            width: 180,
            cell: (row, column) =>
                <NumberCell
                    row={row}
                    column={column}
                    value={_get(row.item, key)}
                    key={`activation-stats-cell-id-${row.item.id}-key-${key}`}
                    formatter={params.formatter || formatNumber}
                />,
            ...params
        }

    }

    makePostsCountCell(key, title, params = {}) {
        const totalNumber = formatNumber(_get(this.state.totals, `sums.${key}_uploaded`, 0));
        const totalText = totalNumber === '1' ? `${totalNumber} Post Submitted` : `${totalNumber} Posts Submitted`;
        return {
            key: `${key}_uploaded`,
            title,
            width: 180,
            sortable: true,
            default: true,
            total: totalText,
            cell: (row, column) => {
                const submittedNumber = _get(row.item, `${key}_uploaded`);
                const acceptedNumber = _get(row.item, `${key}_accepted`);
                const submittedText = submittedNumber ? submittedNumber + ' Submitted' : '-';
                const acceptedText = acceptedNumber ? acceptedNumber + ' Accepted' : '-';
                let cellValue;
                if(submittedText === '-' && acceptedText === '-') {
                    cellValue = <span>-</span>;
                    } else {
                    cellValue = <span>{submittedText}, {acceptedText}</span>;
                }
                return <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row}
                    column={column}
                    value={cellValue}
                />
            },
            ...params
        }
    }

    toggleSelectAll() {
        const selectAll = !this.state.selectAll;
        let selectedIds = {};

        if (selectAll) {
            this.props.activations.forEach(a => selectedIds[a.id] = true);
        }

        return this.setState({selectAll, selectedIds, selectEverything: false, lastSelectedIndex: null});
    }

    async toggleSelectEverything() {
        const selectEverything = !this.state.selectEverything;
        let selectedIds = [];
        let selectAll = false;

        if (selectEverything) {
            selectAll = true;
            const allIds = await this.fetchAllIds();
            allIds.forEach(id => selectedIds[id] = true);
        }

        this.setState({selectEverything, selectAll, selectedIds});
    }

    getFormResponseColumns() {
        const {forms} = this.props;
        let columns = [];
        if (!forms) return columns;


        // forms is a 2d array. each form object has a property named "json"
        forms.forEach((form, formIndex) => {
            const formJson = JSON.parse(form.json);
            if (!formJson.fields) {
                return;
            }

            formJson.fields.forEach((field) => {
                // add a column for this field
                // the field label needs html stripped
                const cleanFieldLabel = field.label.replace(/<[^>]+>/g, '');
                columns.push({
                    key: `form_responses.${form.id}.${field.name}`,
                    title: cleanFieldLabel,
                    cell: (row, column) => {
                        const response = _get(row.item, `form_responses.${form.id}.${field.name}`);
                        return <BasicCell
                            key={this.makeCellKey(row, column)}
                            row={row}
                            column={column}
                            value={response}
                        />
                    },
                    width: 300,
                    sortable: false,
                    default: true,
                    search: [
                        {
                            type: 'text',
                            name: `form_responses.${form.id}.${field.name}`,
                            placeholder: `Search by ${cleanFieldLabel}`,
                            options: {hideTitle: true}
                        }
                    ],
                    onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, [`form_responses.${form.id}.${field.name}`]))}
                });
            });
        });

        return columns;
    }

    getColumns() {
        const {activations, campaign, channel, singleUserMode} = this.props;
        const settings = campaign.settings || {};
        /*
         * Can probably do this better with boolean logic, but this reads easier:
         */
        let isUsingPayments = !!settings.use_payments;
        let isUsingProducts = !!settings.use_products;
        let isUsingCoupons = !!settings.use_coupon_codes;
        let isUsingCustomForm = !!settings.use_custom_form;
        let isUsingNda = !!settings.use_nda;

        // If we're on single user profile, show all
        if (singleUserMode) {
            isUsingPayments = true;
            isUsingProducts = true;
            isUsingCoupons = true;
            isUsingCustomForm = true;
        }

        // If the channel disables payments, hide these even if on single user mode
        if (!!channel.disable_payments) {
            isUsingPayments = false;
            isUsingCoupons = false;
        }

        return [

            !this.props.singleUserMode &&
            {
                key: 'menu',
                sortable: false,
                default: true,
                title: <Checkbox
                    checked={this.state.selectAll}
                    label={null}
                    onClick={this.toggleSelectAll.bind(this)} />,
                width: 80,
                cell: (row, column) => {
                    const isMenuActive = this.isMenuActive(row.item.id);

                    let menuBtnClasses = ['godzilla-table-cell-switch-wrapper'];
                    if (isMenuActive) {
                        menuBtnClasses.push('active');
                    }

                    let toolboxClass = '';
                    if (row.index <= 2) {
                        toolboxClass = 'upper-row';
                    } else if (row.index >= (activations.length - 3)) {
                        toolboxClass = 'lower-row';
                    }

                    return <BasicCell
                               key={this.makeCellKey(row, column)}
                               row={row}
                               column={column}
                               style={{position: 'relative'}}
                               value={
                                   <span className="godzilla-table-cell-checkbox-menu-wrapper">
                                      <Checkbox
                                          checked={row.isSelected}
                                          label={null}
                                          onClick={(e) => this.toggleRowSelected(row, e)}
                                      />
                                      <a role="button"
                                         className={menuBtnClasses.join(' ')}
                                         onClick={() => this.toggleMenuActive(row.item.id)}
                                      >
                                          <i className="v3 icon burger-menu"/>
                                      </a>

                                       {isMenuActive
                                       && <ActivationActionsToolbox
                                           activation={row.item}
                                           onClose={() => this.toggleMenuActive(row.item.id)}
                                           onClickItem={(interactionName) => this.handleClickInteraction(row, interactionName)}
                                           addlClasses={[toolboxClass]}
                                       />}
                                  </span>
                               }
                    />
                },
                filterDisplayValue: <span>Filters</span>,
                search: [
                    {
                        type: 'generic',
                        content: <div className="fake-li"><a className="nopadding" onClick={this.resetFilters.bind(this, {}, null)} role="button">Reset Filters <i className="fa fa-refresh pull-right" /></a></div>
                    }
                ]

            },
            {
                key: 'miniprofile',
                sticky: this.state.tab !== 'content',
                sortable: false,
                default: true,
                title: 'User',
                width: 300,
                cell: (row, column) => {
                    const {query, listContext, campaign} = this.props;
                    const context = {userQuery: query};
                    if (!query.queryId) context.userQuery.queryId = campaign.id;
                    if (!query.meta || !query.meta.hits) context.userQuery.meta = this.getCurrentPageMeta();
                    if (listContext) context.listContext = listContext;

                    const stack = this.props.activations.map(activation => activation['user_id']);

                    return <BasicCell
                        key={this.makeCellKey(row, column)}
                        row={row}
                        column={column}
                        value={<ActivationMiniProfile
                            activation={row.item}
                            onClickUser={() => this.props.openUserLightbox(row.item.user_id, context, stack)}
                            onClickProgress={this.handleClickInteraction.bind(this, row, 'ViewActivationRequirements')}
                            onClickSwitch={name => {
                                if (name === 'ViewHistory') {
                                    this.handleShowTimeline(row);
                                }
                                if (name === 'ViewPosts' || name === 'ViewStats') {
                                    this.setExpandoType(row, name);
                                }
                                if (name === 'ManageIssues' || name === 'RaiseIssue') {
                                    this.handleClickInteraction(row, name);
                                }
                            }}
                        />}
                    />
                },
                search: [
                    {
                        type: 'text',
                        name: 'user.name',
                        placeholder: 'Search by User Name',
                        options: {hideTitle: true}
                    },
                    {
                        type: 'text',
                        name: 'user.blog_name',
                        placeholder: "Search by Blog Name",
                        options: {hideTitle: true}
                    }
                ],
                total: _get(this.state.totals, 'uniques.user_id', 0) + ' Users',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.blog_name', 'user.name']))}
            },

            this.props.singleUserMode &&
            {
                key: 'campaign.name',
                sortable: false,
                default: true,
                title: 'Campaign',
                width: 230,
                cell: (row, column) => {
                    const campaignId = row.item.campaign_id;
                    const campaign = _find(this.props.campaigns, {id: campaignId});
                    return <BasicCell
                        key={this.makeCellKey(row, column)}
                        row={row} column={column} value={campaign.name} />;
                },
                search: [
                    {
                        type: 'text',
                        name: 'campaign.name',
                        placeholder: "Search by Campaign Name",
                        options: {hideTitle: true}
                    }
                ]
            },

            {
                key: 'status.status',
                sortable: true,
                default: true,
                title: 'Status',
                width: 230,
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row} column={column} value={_get(row.item, 'status.human_status')} />,
                search: [
                    {
                        type: 'select',
                        name: 'status.status',
                        title: 'Status',
                        choices: STATUS_FILTERS,
                    },

                ],
                filterDisplayValue: (() => {
                    const val = this.getFilterValue('status.status');
                    if (!val) return null;
                    const obj = _find(STATUS_FILTERS, {value: val});
                    if (obj) {
                        return obj.text;
                    }
                    return null;
                })(),
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['status.status']))}
            },
            {
                key: 'invitation_tools',
                sortable: false,
                default: true,
                title: 'Invitation Tools',
                width: 200,
                cell: (row, column) => <BasicCell row={row} column={column}
                                                  key={this.makeCellKey(row, column)}
                                                  value={<ActivationInvitationToolsButton
                                                      onClickAction={this.handleClickInteraction.bind(this, row)}
                                                      activation={row.item} />}
                />
            },
            (isUsingPayments || isUsingProducts || isUsingCoupons) ? {
                key: 'gifting_tools',
                sortable: false,
                default: true,
                title: 'Payment Settings',
                width: 230,
                cell: (row, column) => <BasicCell row={row} column={column}
                                                  key={this.makeCellKey(row, column)}
                                                  value={<ActivationPaymentToolsButton
                                                      onClickAction={this.handleClickInteraction.bind(this, row)}
                                                      activation={row.item}
                                                  />}
                />
            } : null,
            this.makeDateCell('advanced_at', 'Last Activity', {sortDir: 'desc'}),
            {
                key: 'status.open_issues_count',
                sortable: true,
                default: true,
                width: 200,
                title: 'Issues',
                cell: (row, column) => {
                    const openCount = _get(row.item, 'status.open_issues_count', 0);
                    const closedCount = _get(row.item, 'status.resolved_issues_count', 0);
                    const totalCount = _get(row.item, 'status.total_issues_count', 0);

                    let content = [];
                    let icon = null;
                    if (openCount > 0) {
                        icon = <i className="v3 signal danger" />
                        content.push(`${openCount} Open Issue${openCount !== 1 ? 's' : ''}`);
                    }
                    if (closedCount > 0) {
                        content.push(`${closedCount} Resolved`);
                    }
                    if (!totalCount) {
                        content.push('No Issues');
                    }

                    const whichInteraction = totalCount > 0 ? 'ManageIssues' : 'RaiseIssue';

                    return <BasicCell
                        row={row}
                        key={this.makeCellKey(row, column)}
                        column={column}
                        value={<a role="button" className="dark" onClick={this.handleClickInteraction.bind(this, row, whichInteraction)}>{icon} {content.join('; ')}</a>}
                    />
                },
                search: [
                    {
                        type: 'checkbox',
                        name: 'status.has_open_issues',
                        title: 'Has Open Issues'
                    }
                ],
                filterDisplayValue: this.getBoolFilterText('status.has_open_issues', 'Open Issues', null),
                total: _get(this.state.totals, 'sums.status.open_issues_count') + ' Open Issues',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['status.has_open_issues']))}
            },
            {
                key: 'progress',
                sortable: false,
                default: true,
                width: 200,
                title: 'Progress',
                cell: (row, column) => {

                    const totalCount = getVisibleInfluencerRequirementTypes(row.item).length;
                    const completeCount = getCompletedInfluencerRequirementTypes(row.item).length;

                    const percent = (totalCount ? (completeCount / totalCount) : 0);

                    const icon = <TinyProgressPie
                        progress={percent}
                        style={{verticalAlign: 'middle', cursor: 'pointer', marginBottom: 2}}
                        size={15}
                    />;

                    const cellValue = (
                        <a role="button"
                           className={'dark'}
                           onClick={this.handleClickInteraction.bind(this, row, 'ViewActivationRequirements')}
                           style={{display: 'block'}}
                        >{icon} {completeCount} of {totalCount}</a>
                    );

                    return <BasicCell
                        key={this.makeCellKey(row, column)} row={row} column={column}
                        value={cellValue}
                    />
                }
            },
            this.makeDateCell('created_at', 'Created'),
            this.makeDateCell('invited_at', 'Invited On', {
                search: [
                    {
                        type: 'select',
                        name: 'status.was_invited',
                        title: 'Invitation Status',
                        choices: [
                            {text: 'All Statuses', value: null},
                            {text: 'Only Invited', value: true},
                            {text: 'Not Invited', value: false},
                        ]
                    }
                ],
                filterDisplayValue: this.getBoolFilterText('status.was_invited', 'Invited', 'Not Invited'),
                total: _get(this.state.totals, 'exists.invited_at', 0) + ' Invited',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['status.was_invited']))}
            }),
            this.makeDateCell('status.invitation_reminder_sent_at', 'Invite Reminder', {
                total: _get(this.state.totals, 'exists.status.invitation_reminder_sent_at', 0) + ' Reminded'
            }),
            isUsingNda ? {
                key: 'nda_signed_at',
                sortable: false,
                default: true,
                width: 200,
                title: 'NDA Signed',
                cell: (row, column) => {
                    const isSigned = _get(row.item, 'status.is_nda_signed', false);
                    const signedDate = _get(row.item, 'metadata.nda_signed_at');
                    let value = 'No';
                    if (isSigned) {
                        value = signedDate ? makeMomentFromDate(signedDate).format('MMM Do YYYY') : 'Yes';
                    }

                    return <BasicCell
                        row={row}
                        column={column}
                        value={value}
                        key={this.makeCellKey(row, column)}
                    />
                },

            } : null,
            this.makeDateCell('accepted_at', 'Accepted Invitation', {
                search: [
                    {
                      type: 'generic',
                      content: <div style={{fontWeight: 'bold'}}>Accepted Status</div>
                    },
                    {
                        type: 'checkbox',
                        name: 'status.did_user_accept_invitation',
                        title: 'Accepted Invitation',
                        options: {useBoldTitle: false},
                    },
                    {
                        type: 'checkbox',
                        name: 'status.is_invitation_pending',
                        title: 'Did Not Respond',
                        options: {useBoldTitle: false},
                    },
                ],
                filterDisplayValue: (() => {
                    const accepted = this.getFilterValue('status.did_user_accept_invitation');
                    const didNotRespond = this.getFilterValue('status.is_invitation_pending');
                    let val;

                    if (accepted) {
                        val = 'Accepted Only'
                    }
                    if (didNotRespond) {
                        val = 'Did Not Respond'
                    }
                    if (accepted && didNotRespond) {
                        val = null
                    }
                    return val;
                })(),
                total: _get(this.state.totals, 'exists.accepted_at', 0) + ' Accepted',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['status.did_user_accept_invitation', 'status.is_invitation_pending']))}
            }),
            this.makeDateCell('metadata.shipping_address_reminder_sent_at', 'Address Reminder', {
                total: _get(this.state.totals, 'exists.status.shipping_address_reminder_sent_at', 0) + ' Reminded',
                sortable: false,
            }),
            this.makeDateCell('metadata.post_reminder_sent_at', 'Post Reminder', {
                total: _get(this.state.totals, 'exists.status.post_reminder_sent_at', 0) + ' Reminded',
                sortable: false,
            }),
            this.makeDateCell('declined_at', 'Declined Invitation', {
                search: [
                    {
                        type: 'generic',
                        content: <div style={{fontWeight: 'bold'}}>Declined Status</div>
                    },
                    {
                        type: 'checkbox',
                        name: 'status.did_user_decline_invitation',
                        title: 'Declined Invitation',
                        options: {useBoldTitle: false},
                    },
                    {
                        type: 'checkbox',
                        name: 'status.is_invitation_pending',
                        title: 'Did Not Respond',
                        options: {useBoldTitle: false},
                    },
                ],
                filterDisplayValue: (() => {
                    const declined = this.getFilterValue('status.did_user_decline_invitation');
                    const didNotRespond = this.getFilterValue('status.is_invitation_pending');
                    let val;

                    if (declined) {
                        val = 'Declined Only'
                    }
                    if (didNotRespond) {
                        val = 'Did Not Respond'
                    }
                    if (declined && didNotRespond) {
                        val = null
                    }
                    return val;
                })(),
                total: _get(this.state.totals, 'exists.declined_at', 0) + ' Declined',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['status.did_user_decline_invitation', 'status.is_invitation_pending']))}
            }),
            this.makeDateCell('assigned_at', 'Assigned On', {
                total: _get(this.state.totals, 'exists.assigned_at', 0) + ' Assigned',
            }),
            this.makeDateCell('fulfilled_at', 'Fulfilled On', {
                total: _get(this.state.totals, 'exists.fulfilled_at', 0) + ' Fulfilled',
            }),
            this.makeDateCell('completed_at', 'Completed On', {
                total: _get(this.state.totals, 'exists.completed_at', 0) + ' Complete',
            }),
            this.makeDateCell('canceled_at', 'Canceled On', {
                total: _get(this.state.totals, 'exists.canceled_at', 0) + ' Canceled',
            }),
            this.makeDateCell('waitlisted_at', 'Waitlisted On', {
                search: [
                    {
                        type: 'select',
                        name: 'status.is_waitlisted',
                        title: 'Waitlist Status',
                        choices: [
                            {text: 'All Statuses', value: null},
                            {text: 'Waitlisted', value: true},
                            {text: 'Not Waitlisted', value: false},
                        ]
                    }
                ],
                filterDisplayValue: this.getBoolFilterText('status.is_waitlisted', 'Waitlisted Only', 'Not Waitlisted'),
                total: _get(this.state.totals, 'exists.waitlisted_at', 0) + ' Waitlisted',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['status.is_waitlisted']))}
            }),
            isUsingPayments ? {
                key: 'payment_offered',
                sortable: true,
                default: true,
                width: 200,
                title: "Payment Amount",
                cell: (row, column) => {
                    let amount = _get(row.item, 'payment_amount');
                    let value = amount ? formatDollar(amount, 2) : 'Click to Update';
                    return (
                        <LinkCell
                            row={row}
                            column={column}
                            value={value}
                            key={this.makeCellKey(row, column)}
                            onClick={this.handleClickInteraction.bind(this, row, 'UpdatePaymentOffer')}
                        />
                    );
                },
                total: '$' + formatNumber(_get(this.state.totals, 'sums.payment_offered', 0)) + ' Total',
            }: null,
            {
                key: 'content_requirements',
                sortable: false,
                default: true,
                width: 260,
                title: 'Requirements',
                cell: (row, column) => {
                    const mapping = getContentRequirementsMap(row.item);
                    const items = Object.values(mapping).filter(i => ['use_blog_drafts', 'use_social_drafts'].indexOf(i.key) === -1);
                    const shortItems = items.length > 3 ? items.slice(0, 2) : items;
                    const hasMore = items.length > 3 ? `... and ${items.length-2} more.` : null;
                    const itemRenderer = (item, index) => {
                        const progress = item.value ? Math.min(1, (item.uploadedValue || 0) / item.value) : 0;
                        return (
                            <div key={index} style={{textAlign: 'left'}}>
                                <TinyProgressPie size={12} progress={progress} style={{verticalAlign: 'sub'}}/> <span>{item.text}</span>
                            </div>
                        );
                    };
                    const tooltipContent = (
                        <div>
                            {items.map(itemRenderer)}
                        </div>
                    );
                    const innerContent = (
                        <>
                            {shortItems.map(itemRenderer)}
                            {hasMore ? <div style={{paddingLeft: 16}}>{hasMore}</div> : null}
                        </>
                    );
                    const content = hasMore ? <Tooltip html={tooltipContent}>{innerContent}</Tooltip> : innerContent;

                    return <BasicCell column={column} row={row} key={this.makeCellKey(row, column)}
                                      value={content} />;
                }
            },
            isUsingCustomForm ? {
                key: 'form_responses',
                sortable: false,
                default: true,
                title: 'Form Responses',
                width: 300,
                cell: (row, column) => {
                    let label = 'N/A';
                    let value;
                    const formResponses = _get(row.item, 'form_responses', {});
                    const responses = {};

                    // flatten responses
                    if (formResponses && Object.keys(formResponses).length > 0) {
                        for (const key of Object.keys(formResponses)) {
                            for (const key2 of Object.keys(formResponses[key])) {
                                if (key2 === 'form_id') continue;
                                if (formResponses[key][key2] !== null) {
                                    responses[key2] = formResponses[key][key2];
                                }
                            }
                        }
                    }

                    const tooltipContent = (
                        <div>
                            {Object.keys(responses).map(key => (
                                <div key={key} className="fake-li" style={{textAlign: 'left'}}>
                                    <strong>{key}</strong>: {responses[key]}
                                </div>
                            ))}
                        </div>
                    );
                    // show number of responses in label, content of responses in tooltip
                    const numResponses = Object.keys(responses).length;
                    if (numResponses) {
                        label = `${numResponses} Response${numResponses !== 1 ? 's' : ''}`;
                        value = <Tooltip html={tooltipContent}>{label}</Tooltip>;
                    } else {
                        value = label;
                    }


                    return (
                        <BasicCell row={row} column={column} key={this.makeCellKey(row, column)}
                                  value={value}
                        />
                    );

                }
            } : null,
            isUsingProducts ? {
                key: 'product',
                sortable: false,
                default: true,
                title: 'Product Sampling',
                width: 200,
                cell: (row, column) => {
                    let productNames = (row.item.products || []).map(p => p.name);
                    let value = 'Click to Select Product';

                    if (productNames.length > 0) {
                        value = productNames.join('; ');
                    }

                    return (
                        <LinkCell row={row} column={column} key={this.makeCellKey(row, column)}
                                  value={value}
                                  onClick={this.handleClickInteraction.bind(this, row, 'SelectProduct')}
                        />
                    );
                },

            } : null,
            isUsingProducts ? {
                key: 'shipping_address',
                sortable: false,
                default: true,
                title: "Shipping Address",
                width: 200,
                cell: (row, column) => {
                    const hasAddress = _get(row.item, 'status.has_shipping_address');
                    let value = (<span><i className="v3 icon address-card"/> Not Submitted</span>);

                    if (hasAddress) {
                        value = (<span><i className="v3 icon check-circle success"/> Submitted</span>);
                    }

                    const address = row.item.shipping_address;
                    if (hasAddress && address && Object.keys(address).length > 3) {
                        const tooltipHtml = (
                            <div className="v3 h8 light" style={{textAlign: 'left'}}>
                                    {address.name}<br />
                                    {address.address1} <br />
                                    {address.address2}<br />
                                    {address.city} {address.region} {address.postal} <br/>
                                    {address.country}
                            </div>
                        );
                        value = <Tooltip
                            html={tooltipHtml}
                        >{value}</Tooltip>
                    }


                    return <LinkCell
                        row={row} column={column}
                        value={value}
                        onClick={this.handleClickInteraction.bind(this, row, 'SubmitShippingAddress')}
                        key={this.makeCellKey(row, column)}
                    />;
                },
                search: [
                    {
                        type: 'select',
                        name: 'status.has_shipping_address',
                        title: 'Shipping Address Status',
                        choices: [
                            {text: 'All Statuses', value: null},
                            {text: 'Has Address', value: true},
                            {text: 'Missing Address', value: false},
                        ]
                    }
                ],
                filterDisplayValue: this.getBoolFilterText('status.has_shipping_address', 'Has Address', 'Missing Address'),
                total: _get(this.state.totals, 'exists.status.has_shipping_address') + ' Submitted',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['status.has_shipping_address']))}
            } : null,
            isUsingProducts ? {
                key: 'metadata.tracking_number',
                sortable: false,
                default: true,
                title: "Shipping Tracking #",
                width: 350,
                cell: (row, column) => <EditableTextCell
                    key={this.makeCellKey(row, column)}
                    placeholder={"Shipping Tracking Number"}
                    row={row} column={column} value={_get(row.item, 'metadata.tracking_number')}
                    onSave={value => this.props.updateActivationMetadata(row.item.id, 'tracking_number', value)}
                    isSaving={row.item.isUpdatingMetadata}
                />
            } : null,
            isUsingProducts ? {
                key: 'status.is_product_shipped',
                sortable: false,
                default: true,
                title: 'Product Shipped',
                width: 160,
                cell: (row, column) => <BasicCell
                    row={row}
                    column={column}
                    key={this.makeCellKey(row, column)}
                    value={_get(row.item, 'status.is_product_shipped') ? 'YES' : 'NO'}
                />,
                total: _get(this.state.totals, 'exists.status.is_product_shipped', 0) + ' Shipped',
                search: [
                    {
                        type: 'select',
                        name: 'status.is_product_shipped',
                        title: 'Shipment Status',
                        choices: [
                            {text: 'All Statuses', value: null},
                            {text: 'Shipped', value: true},
                            {text: 'Not Shipped', value: false},
                        ]
                    }
                ],
                filterDisplayValue: this.getBoolFilterText('status.is_product_shipped', 'Shipped: YES', 'Shipped: NO'),
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['status.is_product_shipped']))}
            } : null,
            isUsingProducts ? {
                key: 'status.is_product_received',
                sortable: false,
                default: true,
                title: 'Product Received',
                width: 160,
                cell: (row, column) => <BasicCell row={row} column={column} key={this.makeCellKey(row, column)}
                                                  value={_get(row.item, 'status.is_product_received') ? 'YES' : 'NO'}
                />,
                total: _get(this.state.totals, 'exists.status.is_product_received', 0) + ' Received',
            } : null,
            isUsingPayments ? {
                key: 'payment_profile',
                sortable: false,
                default: true,
                title: "Payment Profile",
                width: 200,
                cell: (row, column) => <LinkCell row={row} column={column}
                                                 value={
                                                     _get(row.item, 'status.has_payment_profile')
                                                         ? <span><i className="v3 icon check-circle"/> Submitted</span>
                                                         : <span><i className="v3 icon address-card"/> Not Submitted</span>
                                                 }
                                                 onClick={this.handleClickInteraction.bind(this, row, 'SubmitPaymentProfile')}
                                                 key={this.makeCellKey(row, column)}
                />,
                search: [
                    {
                        type: 'select',
                        name: 'status.has_payment_profile',
                        title: 'Payment Profile Status',
                        choices: [
                            {text: 'All Statuses', value: null},
                            {text: 'Has Payment Details', value: true},
                            {text: 'Missing Payment Details', value: false},
                        ]
                    }
                ],
                filterDisplayValue: this.getBoolFilterText('status.has_payment_profile', 'Has Profile', 'Missing Details'),
                total: _get(this.state.totals, 'exists.status.has_payment_profile') + ' Submitted',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['status.has_payment_profile']))}
            } : null,
            isUsingPayments ? this.makeDateCell('invoiced_at', 'Invoiced', {
                total: _get(this.state.totals, 'exists.invoiced_at', 0) + ' Invoiced',
            }) : null,
            isUsingPayments ? this.makeDateCell('metadata.payment_due_date', 'Payment Due Date', {
               // total: _get(this.state.totals, 'exists.payment_due_date', 0) + ' Due',
            }) : null,
            isUsingCoupons ? {
                key: 'coupon_code',
                sortable: false,
                default: true,
                title: 'Coupon Code',
                width: 200,
                cell: (row, column) => {

                    let value = 'No Coupon';
                    if (row.item.coupon_codes && row.item.coupon_codes.length > 0) {
                        value = (row.item.coupon_codes[0] || {}).code;
                    }

                    const assigned = _get(row.item, 'status.is_coupon_code_assigned');
                    const claimed = _get(row.item, 'status.is_coupon_code_claimed');

                    let icon = null;
                    if (assigned) {
                        icon = <i className="v3 signal neutral" />
                    }
                    if (claimed) {
                        icon = <i className="v3 signal success" />
                    }

                    const cellValue = <span>{icon} {value}</span>;

                    return <LinkCell row={row} column={column} key={this.makeCellKey(row, column)}
                                     value={cellValue}
                                     onClick={this.handleClickInteraction.bind(this, row, 'AssignCouponCode')}
                    />
                },
                search: [
                    {
                        type: 'select',
                        name: 'status.is_coupon_code_assigned',
                        title: 'Coupon Code Status',
                        choices: [
                            {text: 'All Statuses', value: null},
                            {text: 'Has Coupon Code', value: true},
                            {text: 'Missing Coupon Code', value: false},
                        ]
                    }
                ],
                filterDisplayValue: this.getBoolFilterText('status.is_coupon_code_assigned', 'Has Coupon', 'Missing Coupon'),
                total: _get(this.state.totals, 'exists.status.is_coupon_code_assigned', 0) + ' Assigned',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['status.is_coupon_code_assigned']))}
            } : null,
            {
                key: 'status.open_drafts_count',
                sortable: true,
                default: true,
                width: 200,
                title: 'Drafts',
                cell: (row, column) => {
                    const openCount = _get(row.item, 'status.open_drafts_count', 0);
                    const closedCount = _get(row.item, 'status.accepted_drafts_count', 0);
                    const totalCount = _get(row.item, 'status.total_drafts_count', 0);

                    let content = [];
                    if (openCount > 0) {
                        content.push(`${openCount} Open`);
                    }
                    if (closedCount > 0) {
                        content.push(`${closedCount} Accepted`);
                    }
                    if (!totalCount) {
                        content.push('No Drafts');
                    }


                    return <BasicCell
                        key={this.makeCellKey(row, column)}
                        row={row}
                        column={column}
                        value={<a role="button" className="dark" onClick={this.handleClickInteraction.bind(this, row, 'ManageDrafts')}>{content.join(" and ")}</a>}
                    />
                },
                search: [
                    {
                        type: 'checkbox',
                        name: 'status.has_open_drafts',
                        title: 'Has Open Drafts'
                    }
                ],
                filterDisplayValue: this.getBoolFilterText('status.has_open_drafts', 'Open Drafts', null),
                total: _get(this.state.totals, 'sums.status.open_drafts_count') + ' Open Drafts',
            },
            {
                key: 'user.name.raw',
                sortable: true,
                default: true,
                width: 400,
                title: 'Name',
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row} column={column} value={_get(row.item, 'user.name')} />,
                search: [
                    {
                        type: 'text',
                        name: 'user.name',
                        placeholder: 'Search by User Name',
                        options: {hideTitle: true}
                    }
                ]
            },
            {
                key: 'user.blog_name.raw',
                sortable: true,
                default: true,
                width: 250,
                title: 'Blog',
                cell: (row, column) => <LinkCell row={row}
                                                 column={column}
                                                 value={_get(row.item, 'user.blog_name')}
                                                 href={_get(row.item, 'user.blog_url')}
                                                 target={'_blank'}
                                                 key={this.makeCellKey(row, column)}
                                                 style={{wordBreak: 'break-all'}}
                                        />,
                search: [
                    {
                        type: 'text',
                        name: 'user.blog_name',
                        placeholder: "Search by Blog Name",
                        options: {hideTitle: true}
                    }
                ],
                total: _get(this.state.totals, 'exists.user.blog_name') + ' Blogs',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.blog_name']))}
            },
            {
                key: 'user.human_location.raw',
                sortable: true,
                default: true,
                width: 400,
                title: 'Location',
                cell: (row, column) => <BasicCell row={row}
                                                 column={column}
                                                 value={_get(row.item, 'user.human_location')}
                                                  key={this.makeCellKey(row, column)}
                                    />,
                search: [
                    {
                        type: 'text',
                        name: 'user.human_location',
                        placeholder: "Search by Location",
                        options: {hideTitle: true}
                    }
                ],
                total: _get(this.state.totals, 'uniques.user.country_name', 0) + ' Countries',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.human_location']))}
            },
            {
                key: 'user.email',
                sortable: true,
                default: true,
                width: 300,
                title: 'Email',
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row} column={column} value={_get(row.item, 'user.email')} />,
                search: [
                    {
                        type: 'text',
                        name: 'user.email',
                        placeholder: "Search by Email",
                        options: {hideTitle: true}
                    }
                ]
            },
            {
                key: 'user.tier',
                sortable: true,
                default: true,
                width: 200,
                title: "Tier",
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row} column={column} value={_get(row.item, 'user.tier')} />,
                search: [{
                    type: "select",
                    options: {hideTitle: true},
                    name: 'user.tier',
                    choices: [
                        {text: "All Tiers", value: null},
                        {text: "Up-and-Comers", value: "0"},
                        {text: "Prosumers", value: "1,2,3"},
                        {text: "Mavens", value: "4,5,6"},
                        {text: "Celebs", value: "7,8,9,10,11,12"},
                    ]
                }],
                filterDisplayValue: (() => {
                    const val = this.getFilterValue('user.tier');
                    switch (val) {
                        case "0": return "Up-and-Comers";
                        case "1,2,3": return "Prosumers";
                        case "4,5,6": return "Mavens";
                        case "7,8,9,10,11,12": return "Celebs";
                        default: return null;
                    }
                })(),
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.tier']))}
            },
            {
                key: 'user.verified_reach',
                sortable: true,
                default: true,
                width: 200,
                title: "Verified Reach",
                cell: (row, column) => <NumberCell
                    key={this.makeCellKey(row, column)}
                    row={row} column={column} value={_get(row.item, 'user.verified_reach')} formatter={formatNumber} />,
                search: [{
                    type: 'range',
                    name: 'user.verified_reach',
                    title: "Verified Reach",
                    options: {
                        min: 0,
                        max: 100000,
                        step: 1000,
                        valueFormatter: formatNumberK,
                    }
                }],
                filterDisplayValue: formatRangeValue(this.getFilterValue('user.verified_reach'), formatNumberK),
                total: formatNumberK(_get(this.state.totals, 'sums.user.verified_reach', 0)) + ' Reach',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.verified_reach']))}
            },
            this.makeStatsCell('user.instagram_followers', 'Instagram Reach', {
                search: [
                    {
                        type: 'range',
                        name: 'user.instagram_followers',
                        title: 'Instagram Reach',
                        options: {
                            min: 0,
                            max: 100000,
                            step: 1000,
                            valueFormatter: formatNumberK,
                        }
                    }
                ],
                filterDisplayValue: formatRangeValue(this.getFilterValue('user.instagram_followers'), formatNumberK),
                total: formatNumberK(_get(this.state.totals, 'sums.user.instagram_followers', 0)) + ' Reach',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.instagram_followers']))}
            }),
            this.makeStatsCell('user.blog_traffic', 'Blog Reach', {
                search: [
                    {
                        type: 'range',
                        name: 'user.blog_traffic',
                        title: 'Blog Reach',
                        options: {
                            min: 0,
                            max: 100000,
                            step: 1000,
                            valueFormatter: formatNumberK,
                        }
                    }
                ],
                filterDisplayValue: formatRangeValue(this.getFilterValue('user.blog_traffic'), formatNumberK),
                total: formatNumberK(_get(this.state.totals, 'sums.user.blog_traffic', 0)) + ' Reach',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.blog_traffic']))}
            }),
            this.makeStatsCell('user.twitter_reach', 'Twitter Reach', {
                sortKey: 'user.twitter_followers',
                search: [
                    {
                        type: 'range',
                        name: 'user.twitter_followers',
                        title: 'Twitter Reach',
                        options: {
                            min: 0,
                            max: 100000,
                            step: 1000,
                            valueFormatter: formatNumberK,
                        }
                    }
                ],
                filterDisplayValue: formatRangeValue(this.getFilterValue('user.twitter_followers'), formatNumberK),
                total: formatNumberK(_get(this.state.totals, 'sums.user.twitter_followers', 0)) + ' Reach',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.twitter_reach']))}
            }),
            this.makeStatsCell('user.youtube_subscribers', 'YouTube Reach', {
                search: [
                    {
                        type: 'range',
                        name: 'user.youtube_subscribers',
                        title: 'YouTube Reach',
                        options: {
                            min: 0,
                            max: 100000,
                            step: 1000,
                            valueFormatter: formatNumberK,
                        }
                    }
                ],
                filterDisplayValue: formatRangeValue(this.getFilterValue('user.youtube_subscribers'), formatNumberK),
                total: formatNumberK(_get(this.state.totals, 'sums.user.youtube_subscribers', 0)) + ' Reach',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.youtube_subscribers']))}
            }),
            this.makeStatsCell('user.facebook_followers', 'Facebook Reach', {
                search: [
                    {
                        type: 'range',
                        name: 'user.facebook_followers',
                        title: 'Facebook Reach',
                        options: {
                            min: 0,
                            max: 100000,
                            step: 1000,
                            valueFormatter: formatNumberK,
                        }
                    }
                ],
                filterDisplayValue: formatRangeValue(this.getFilterValue('user.facebook_followers'), formatNumberK),
                total: formatNumberK(_get(this.state.totals, 'sums.user.facebook_followers', 0)) + ' Reach',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.facebook_followers']))}
            }),
            this.makeStatsCell('user.facebook_page_followers', 'Facebook Page Reach', {
                search: [
                    {
                        type: 'range',
                        name: 'user.facebook_page_followers',
                        title: 'Facebook Page Reach',
                        options: {
                            min: 0,
                            max: 100000,
                            step: 1000,
                            valueFormatter: formatNumberK,
                        }
                    }
                ],
                filterDisplayValue: formatRangeValue(this.getFilterValue('user.facebook_page_followers'), formatNumberK),
                total: formatNumberK(_get(this.state.totals, 'sums.user.facebook_page_followers', 0)) + ' Reach',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.facebook_page_followers']))}
            }),
            this.makeStatsCell('user.pinterest_followers', 'Pinterest Reach', {
                search: [
                    {
                        type: 'range',
                        name: 'user.pinterest_followers',
                        title: 'Pinterest Reach',
                        options: {
                            min: 0,
                            max: 100000,
                            step: 1000,
                            valueFormatter: formatNumberK,
                        }
                    }
                ],
                filterDisplayValue: formatRangeValue(this.getFilterValue('user.pinterest_followers'), formatNumberK),
                total: formatNumberK(_get(this.state.totals, 'sums.user.pinterest_followers', 0)) + ' Reach',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.pinterest_followers']))}
            }),
            this.makeDateCell('status.draft_updated_at', 'Drafts Updated', { sortDir: 'desc' }),
            {
                key: 'user.overall_avg_engagement_pct',
                sortable: true,
                default: false,
                width: 180,
                title: 'Avg Engagement Pct',
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row} column={column} value={_get(row.item, 'user.overall_avg_engagement_pct')} />,
                total: formatNumber(_get(this.state.totals, 'avgs.user.overall_avg_engagement_pct') * 100, 2) + '%',
            },
            {
                key: 'user.avg_payment_amount',
                sortable: true,
                default: false,
                width: 200,
                title: 'Avg Historical Price',
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row} column={column} value={_get(row.item, 'user.avg_price')} />,
                total: formatDollar(_get(this.state.totals, 'avgs.user.avg_payment_amount')) + ' Avg',
            },
            {
                key: 'user.gender',
                sortable: true,
                default: false,
                width: 150,
                title: 'Gender',
                cell: (row, column) => <BasicCell
                    key={this.makeCellKey(row, column)}
                    row={row} column={column} value={_get(row.item, 'user.gender')} />
            },

            this.makeStatsCell('user.avg_post_rating', 'Avg Content Rating', {
                search: [
                    {
                        type: 'range',
                        name: 'user.avg_post_rating',
                        title: 'Avg Content Rating',
                        options: {
                            min: 0,
                            max: 5,
                            step: 0.1,
                            valueFormatter: formatNumber,
                        }
                    }
                ],
                filterDisplayValue: formatRangeValue(this.getFilterValue('user.avg_post_rating'), formatNumber),
                total: formatNumber(_get(this.state.totals, 'avgs.user.avg_post_rating'), 1) + ' / 5',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['user.avg_post_rating']))}
            }),

            {
                key: 'status.content_submitted_count',
                title: 'Submitted Posts',
                width: 180,
                sortable: true,
                default: true,
                cell: (row, column) => {
                    const postsCount = _get(row.item, 'status.content_submitted_count');
                    const icon = (postsCount && postsCount > 0) ? <i className="fa fa-file" /> : null;
                    const cellValue = <span>{icon} {postsCount ? postsCount : '-'}</span>

                    return <LinkCell row={row} column={column} key={this.makeCellKey(row, column)}
                                     value={cellValue}
                                     onClick={() => this.setExpandoType(row, 'ViewPosts')}
                    />
                },
                total: _get(this.state.totals, 'sums.status.content_submitted_count') + ' Pending',
            },

            {
                key: 'status.content_accepted_count',
                title: 'Approved Posts',
                width: 180,
                sortable: true,
                default: true,
                cell: (row, column) => {
                    const postsCount = _get(row.item, 'status.content_accepted_count');
                    const icon = (postsCount && postsCount > 0) ? <i className="fa fa-file" /> : null;
                    const cellValue = <span>{icon} {postsCount ? postsCount : '-'}</span>;

                    return <LinkCell row={row} column={column} key={this.makeCellKey(row, column)}
                                     value={cellValue}
                    />
                },
                total: _get(this.state.totals, 'sums.status.content_accepted_count') + ' Approved',
            },
            this.makePostsCountCell('status.num_youtube_posts', 'Youtube Posts'),
            this.makePostsCountCell('status.num_facebook_posts', 'Facebook Posts'),
            this.makePostsCountCell('status.num_facebook_page_posts', 'Facebook Page Posts'),
            this.makePostsCountCell('status.num_pinterest_posts', 'Pinterest Posts'),
            this.makePostsCountCell('status.num_instagram_posts', 'Instagram Posts'),
            this.makePostsCountCell('status.num_blog_posts', 'Blog Posts'),
            this.makePostsCountCell('status.num_twitter_posts', 'Twitter Posts'),

            this.makeStatsCell('stats.total_engagements', 'Total Engagements', {
                search: [
                    {
                        type: 'range',
                        name: 'stats.total_engagements',
                        title: 'Total Engagements',
                        options: {
                            min: 0,
                            max: 100000,
                            step: 10,
                            valueFormatter: formatNumber,
                        }
                    }
                ],
                filterDisplayValue: formatRangeValue(this.getFilterValue('stats.total_engagements'), formatNumber),
                total: formatNumberK(_get(this.state.totals, 'sums.stats.total_engagements', 0)) + ' Engagements',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['stats.total_engagements']))}

            }),
            this.makeStatsCell('stats.instagram_like_count', 'Instagram Likes', {
                search: [
                    {
                        type: 'range',
                        name: 'stats.instagram_like_count',
                        title: 'Instagram Likes',
                        options: {
                            min: 0,
                            max: 10000,
                            step: 10,
                            valueFormatter: formatNumber,
                        }
                    }
                ],
                filterDisplayValue: formatRangeValue(this.getFilterValue('stats.instagram_like_count'), formatNumber),
                total: formatNumberK(_get(this.state.totals, 'sums.stats.instagram_like_count', 0)) + ' Likes',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['stats.instagram_like_count']))}
            }),
            this.makeStatsCell('stats.instagram_comment_count', 'Instagram Comments', {
                width: 200,
                search: [
                    {
                        type: 'range',
                        name: 'stats.instagram_comment_count',
                        title: 'Instagram Comments',
                        options: {
                            min: 0,
                            max: 1000,
                            step: 10,
                            valueFormatter: formatNumber,
                        }
                    }
                ],
                filterDisplayValue: formatRangeValue(this.getFilterValue('stats.instagram_comment_count'), formatNumber),
                total: formatNumberK(_get(this.state.totals, 'sums.stats.instagram_comment_count', 0)) + ' Comments',
                onResetSearch: () => {this.resetFilters(_omit(this.props.query.filters, ['stats.instagram_comment_count']))}
            }),
            this.makeStatsCell('stats.overall_engagement_pct', 'Eng Pct', {
                width: 120,
                formatter: (value) => formatNumber(100*value, 1) + '%'
            }),
            {

                key: 'settings',
                sortable: false,
                default: true,
                title: 'Custom Settings',
                width: 220,
                cell: (row, column) => {

                    const campaignSettings = (this.getCampaign() || {}).settings || {};
                    const activationSettings = row.item.settings || {};
                    let isCustom = false;

                    Object.keys(campaignSettings).forEach(key => {

                        /** Serialize first so we can compare objects (like dates) */
                        const thisCampaignSetting = JSON.stringify(campaignSettings[key]);
                        const thisActivationSetting = JSON.stringify(activationSettings[key]);

                        if (thisCampaignSetting !== thisActivationSetting) {
                            isCustom = true;
                            // Might need these for debugging later...
                            // console.log(`Activation with ID ${row.item.id} has custom setting on ${key}:`);
                            // console.log('Campaign Setting:');
                            // console.log(thisCampaignSetting);
                            // console.log('Custom Setting:');
                            // console.log(thisActivationSetting);
                        }
                    });

                    const cellText = isCustom ? 'View Custom Settings' : 'Customize';

                    return <LinkCell row={row} column={column} key={this.makeCellKey(row, column)}
                                     value={<span><i className="v3 icon cog" /> {cellText}</span>}
                                     onClick={this.handleClickInteraction.bind(this, row, 'UpdateSettings')}
                    />
                },

            },
            ...this.makeContentCells(),
            {
                key: 'rating',
                sortable: true,
                width: 200,
                title: 'Rating',
                cell: (row, column) => {
                    return <BasicCell
                        row={row} column={column} key={this.makeCellKey(row, column)}
                        value={<ActivationStarRating
                            activation_id={row.item.id}
                            rating={row.item.rating || 0}
                            onComplete={()=>this.props.fetchActivation(row.item.id)}
                        />}
                    />;
                }
            },


            this.makeCompoundStatsCell(
                'instagram_stats',
                'Instagram',
                'stats.num_instagram_posts',
                'stats.instagram_reach',
                ['stats.instagram_comment_count', 'stats.instagram_like_count'],
                ['stats.instagram_impression_count'],
                'settings.num_instagram_posts',
            ),

            this.makeCompoundStatsCell(
                'instastory_stats',
                'Instastory',
                'stats.num_instastory_posts',
                'stats.instastory_reach',
                ['stats.instastory_engagement_count'],
                ['stats.instastory_impression_count'],
                'settings.num_instastories',
            ),

            this.makeCompoundStatsCell(
                'tiktok_stats',
                'TikTok',
                'stats.num_tiktok_posts',
                'stats.tiktok_reach',
                ['stats.tiktok_comment_count', 'stats.tiktok_like_count', 'stats.tiktok_share_count', 'stats.tiktok_click_count'],
                ['stats.tiktok_view_count'],
                'settings.num_tiktok_posts',
            ),

            this.makeCompoundStatsCell(
                'youtube_stats',
                'YouTube',
                'stats.num_youtube_posts',
                'stats.youtube_reach',
                ['stats.youtube_comment_count', 'stats.youtube_like_count', 'stats.youtube_dislike_count'],
                ['stats.youtube_view_count'],
                'settings.num_youtube_posts'
            ),

            this.makeCompoundStatsCell(
                'total_stats',
                'Totals',
                'stats.total_posts',
                'stats.total_reach',
                ['stats.total_engagements'],
                ['stats.total_impressions'],
                'status.content_required_count'
            ),

            this.makeCompoundStatsCell(
                'twitter_stats',
                'Twitter',
                'stats.num_twitter_posts',
                'stats.twitter_reach',
                ['stats.twitter_favorite_count', 'stats.twitter_retweet_count', 'stats.twitter_tweet_count'],
                [],
                'settings.num_twitter_posts',
            ),

            this.makeCompoundStatsCell(
                'facebook_page_stats',
                'Facebook Page',
                'stats.num_facebook_page_posts',
                'stats.facebook_page_reach',
                ['stats.facebook_page_comment_count', 'stats.facebook_page_reaction_count', 'stats.facebook_page_share_count'],
                [],
                'settings.num_facebook_page_posts',
            ),

            this.makeCompoundStatsCell(
                'blog_stats',
                'Blog',
                'stats.num_blog_posts',
                'stats.blog_reach',
                ['stats.blog_comment_count', 'stats.other_engagements', 'stats.tidal_vote_count'],
                ['stats.blog_pageviews_count'],
                'settings.num_blog_posts'
            ),

            this.makeCompoundStatsCell(
                'pinterest_stats',
                'Pinterest',
                'stats.num_pinterest_posts',
                'stats.pinterest_reach',
                ['stats.pinterest_comment_count', 'stats.pinterest_like_count', 'stats.pinterest_repin_count'],
                [],
                'settings.num_pinterest_posts',
            ),


            this.makeStatsCell('stats.total_images', 'Submitted Images', {
                total: formatNumber(_get(this.state.totals, 'sums.stats.total_images', 0)) + ' Images',
            }),

            this.makeStatsCell('stats.total_videos', 'Submitted Videos', {
                total: formatNumber(_get(this.state.totals, 'sums.stats.total_videos', 0)) + ' Videos',
            }),
            this.makeStatsCell('stats.emv', 'EMV', {
                width: 120,
                formatter: (value) => formatDollar(value)
            }),
            this.makeStatsCell('stats.roi', 'ROI', {
                width: 120,
                formatter: (value) => formatNumber(value) + 'x'
            }),

            // add custom form responses
            ...this.getFormResponseColumns()
        ];
    }

    makeContentCells() {
        let cellStyle = {
            //fontWeight: 'bold',
            color: '#666'
        };

        return [
            {
                key: 'posts-rollup-num-posts',
                default: true,
                sortable: false,
                width: 400,
                title: 'Number of Posts',
                cell: (row, column) => {
                    const postsCount = _get(row.item, 'status.content_total_count');
                    const draftsCount = _get(row.item, 'status.total_drafts_count');
                    let content = [`${postsCount} Post${postsCount !== 1 ? 's' : ''}`];
                    if (draftsCount > 0) {
                        content.push(`${draftsCount} Draft${draftsCount !== 1 ? 's' : ''}`);
                    }
                    let value = <strong>{content.join(' and ')}</strong>
                    return <BasicCell row={row} column={column} value={value} style={{...cellStyle, cursor: 'pointer'}} onClick={() => this.setExpandoType(row, 'ViewPosts')} />

                },
                total: (() => {
                    const totalPosts = _get(this.state.totals, 'sums.status.content_total_count', 0);
                    const totalDrafts = _get(this.state.totals, 'sums.status.total_drafts_count', 0);

                    return `${totalPosts} Posts` + (totalDrafts > 0 ? ` and ${totalDrafts} Drafts` : '');
                })(),
            },
            {
                key: 'posts-rollup-types',
                default: true,
                sortable: false,
                width: 130,
                title: 'Type',
                cell: (row, column) => {

                    let iconClasses = [];
                    if ((row.item.drafts || []).length > 0) {
                        iconClasses.push('v3 icon pencil');
                    }
                    (row.item.post_records || row.item.postRecords || []).forEach(pr => {
                        const post = pr.post;
                        let thisIcon = socialIconForType(post.type);
                        if (iconClasses.indexOf(thisIcon) === -1) {
                            iconClasses.push(thisIcon);
                        }
                    });

                    if (iconClasses.length === 0) {
                        return <BasicCell row={row} column={column} value={'-'} />
                    }

                    const icons = iconClasses.map(className => <i style={{marginRight: 8, color: '#666'}} className={className} /> );
                    const iconWrapper = <span>{icons}</span>
                    return <BasicCell row={row} column={column} value={iconWrapper} style={cellStyle} />
                }
            },
            {
                key: 'posts-rollup-rating',
                default: true,
                sortable: false,
                width: 110,
                title: 'Rating',
                cell: (row, column) => {

                    if ((row.item.post_records || row.item.postRecords || []).length === 0) {
                        return <BasicCell row={row} column={column} value={'-'} />
                    }

                    const ratings = (row.item.post_records || row.item.postRecords || [])
                        .map(pr => pr.post.rating)
                        .filter(rating => typeof rating !== 'undefined' && rating !== null);

                    const mean = ratings.length > 0 ? _mean(ratings) : 0;
                    const RatingTool = <StarRating onRatingChange={null} rating={mean} />;

                    return <BasicCell row={row} column={column} value={RatingTool} style={cellStyle} />
                }
            },
            {
                key: 'posts-rollup-created',
                default: true,
                sortable: false,
                width: 150,
                title: 'Created',
                cell: (row, column) => {

                    let dates = [
                        ...(row.item.drafts || []).map(draft => makeMomentFromDate(draft.created_at)),
                        ...(row.item.post_records || row.item.postRecords || []).map(pr => moment(pr.post.created)),
                    ];

                    if (dates.length === 0) {
                        return <BasicCell row={row} column={column} value={'-'} />
                    }

                    let sorted = dates.sort((a, b) => {
                        return a.isAfter(b) ? 1 : -1;
                    });

                    let value = sorted.length > 0 ? sorted[0].format('MMM Do, YYYY') : '-';

                    return <BasicCell row={row} column={column} value={value} style={cellStyle} />
                }
            },
            {
                key: 'posts-rollup-updated',
                default: true,
                sortable: false,
                width: 150,
                title: 'Updated',
                cell: (row, column) => {

                    let dates = [
                        ...(row.item.drafts || []).map(draft => makeMomentFromDate(draft.updated_at)),
                        ...(row.item.post_records || row.item.postRecords || []).map(pr => moment(pr.post.updated)),
                    ];

                    if (dates.length === 0) {
                        return <BasicCell row={row} column={column} value={'-'} />
                    }

                    let sorted = dates.sort((a, b) => {
                        return a.isAfter(b) ? -1 : 1;
                    });

                    let value = sorted.length > 0 ? sorted[0].format('MMM Do, YYYY') : '-';

                    return <BasicCell row={row} column={column} value={value} style={cellStyle} />
                }
            },
            {
                key: 'posts-rollup-engagements',
                default: true,
                sortable: false,
                width: 150,
                title: 'Engagements',
                cell: (row, column) => {

                    if ((row.item.post_records || row.item.postRecords || []).length === 0) {
                        return <BasicCell row={row} column={column} value={'-'} />
                    }

                    let value = (row.item.stats || {}).total_engagements || null;
                    if (value) {
                        value = formatNumber(value);
                    } else {
                        value = '-';
                    }
                    return <BasicCell row={row} column={column} value={value} style={cellStyle} />
                },
                total: formatNumberK(_get(this.state.totals, 'sums.stats.total_engagements', 0)) + ' Engagements',
            },
            {
                key: 'posts-rollup-impressions',
                default: true,
                sortable: false,
                width: 150,
                title: 'Impressions',
                cell: (row, column) => {

                    return <BasicCell row={row} column={column} value={'234,567'} style={cellStyle} />
                }
            },
            {
                key: 'posts-rollup-status',
                default: true,
                sortable: false,
                width: 200,
                title: 'Status',
                cell: (row, column) => {

                    const totalPosts = _get(row.item, 'status.content_total_count');
                    const acceptedPosts = _get(row.item, 'status.content_accepted_count');
                    const totalDrafts = _get(row.item, 'status.total_drafts_count');
                    const acceptedDrafts = _get(row.item, 'status.accepted_drafts_count');

                    const total = totalPosts + totalDrafts;
                    const accepted = acceptedDrafts + acceptedPosts;

                    return <BasicCell row={row} column={column} value={`${accepted} / ${total} Approved`} style={cellStyle} />
                },
                total: (() => {
                    const totalPosts = _get(this.state.totals, 'sums.status.content_total_count', 0);
                    const acceptedPosts = _get(this.state.totals, 'sums.status.content_accepted_count', 0);
                    const totalDrafts = _get(this.state.totals, 'sums.status.total_drafts_count', 0);
                    const acceptedDrafts = _get(this.state.totals, 'sums.status.accepted_drafts_count', 0);

                    const total = totalPosts + totalDrafts;
                    const accepted = acceptedPosts + acceptedDrafts;

                    return `${accepted} / ${total} Approved`;
                })(),
            },

        ];
    }

    shouldShowLoadingAlert() {
        const {activations, query} = this.props;
        if (query.isFetching && !activations.length) {
            return true;
        }
        return false;

    }

    shouldBlur() {
        const {activations, query} = this.props;
        if (query.isFetching && activations.length > 0) {
            return true;
        }
        return false;
    }

    setActiveTab(tab) {
        // special logic for form responses, because the fields are dynamic
        if (tab === 'form') {
            const formColumnNames = this.getFormResponseColumns().map(col => col.key);
            this.setState({
                tab,
                visibleColumns: ['menu', 'miniprofile', ...formColumnNames]
            });

        } else {
            const columns = ['menu', 'miniprofile', ...TAB_COLUMNS[tab]];
            this.setState({
                tab,
                visibleColumns: columns
            });
        }
    }

    isTabActive(tab) {
        return this.state.tab === tab;
    }

    getNumberOfActiveFilters(){
        const filters = (this.props.query || {}).filters || {};
        const filterKeys = Object.keys(filters);
        const blacklist = ['campaign_id', 'allow_inactive_users'];
        const activeFilters = filterKeys.filter(key =>
            (filters.hasOwnProperty(key)
                && filters[key] !== null
                && blacklist.indexOf(key) === -1
                && key !== 'campaign_id'
            )
        );
        return activeFilters.length;
    }

    getFilterTag(){
        const filters = this.getNumberOfActiveFilters();
        if (filters === 1) {
            return '1 Active Filter';
        } else if (filters > 1) {
            return `${filters} Active Filters`;
        }
        return null;
    }

    getFilterTitle() {
        const filters = this.getNumberOfActiveFilters();
        if (this.isSwitchActive('filter')) {
            if (filters) {
                return (
                    <div className="reset-filters-tab">
                        <i className="v3 icon reload" style={{marginRight: '5px', color: '#333'}}></i>
                        Reset Filters
                    </div>
                );
            }
            return 'Hide Filters';
        }
        return 'Show Filters';
    }

    getFilterOnClick() {
        const filters = this.getNumberOfActiveFilters();
        return filters ? this.resetFilters.bind(this, {}, null) : this.toggleSwitchActive.bind(this, 'filter');
    }

    getTabs() {
        const {campaign, channel, singleUserMode} = this.props;
        const settings = campaign.settings || {};

        // Same logic as in getColumns
        let isUsingPayments = !!settings.use_payments;
        let isUsingProducts = !!settings.use_products;
        let isUsingCoupons = !!settings.use_coupon_codes;
        let isUsingForm = !!settings.use_custom_form && !!settings.custom_form_id;

        if (singleUserMode) {
            isUsingPayments = true;
            isUsingProducts = true;
            isUsingCoupons = true;
        }

        if (!!channel.disable_payments) {
            isUsingPayments = false;
            isUsingCoupons = false;
        }

        return [
            {
                title: 'Overview',
                isActive: this.isTabActive('overview'),
                onClick: this.setActiveTab.bind(this, 'overview')
            },
            {
                title: 'Users',
                isActive: this.isTabActive('users'),
                onClick: this.setActiveTab.bind(this, 'users')
            },
            {
                title: 'Invitations',
                isActive: this.isTabActive('invitation'),
                onClick: this.setActiveTab.bind(this, 'invitation')
            },
            (isUsingForm) ? {
                title: 'Form Responses',
                isActive: this.isTabActive('form'),
                onClick: this.setActiveTab.bind(this, 'form')
            } : null,
            (!channel.disable_payments && (isUsingPayments || isUsingProducts || isUsingCoupons)) ? {
                title: 'Gifting & Payment',
                isActive: this.isTabActive('gifting'),
                onClick: this.setActiveTab.bind(this, 'gifting')
            } : null,
            {
                title: 'Content',
                isActive: this.isTabActive('content'),
                onClick: this.setActiveTab.bind(this, 'content')
            },
            {
                title: 'Stats',
                isActive: this.isTabActive('stats'),
                onClick: this.setActiveTab.bind(this, 'stats')
            },

            !this.props.singleUserMode &&
            {
                title: this.getFilterTitle(),
                isActive: this.isSwitchActive('filter'),
                onClick: this.getFilterOnClick(),
                style: {float: 'right'}
            },

            !this.props.singleUserMode &&
            this.getNumberOfActiveFilters() > 0 ? {
                title: this.getFilterTag(),
                style: {float: 'right', color: '#66CC00'}
            } : null,

            !this.props.singleUserMode &&
            {
                title: this.isAllowingInactiveUsers() ? 'Hide Inactive' : 'Show Inactive',
                style: {float: 'right'},
                isActive: this.isAllowingInactiveUsers(),
                onClick: () => this.handleToggleAllowInactiveUsers(),
            },
        ];
    }

    isAllowingInactiveUsers() {
        const filters = (this.props.query || {}).filters || {};

        if (typeof filters['allow_inactive_users'] !== 'undefined'
            && filters['allow_inactive_users'] === true) {
            return true;
        }

        return false;
    }

    handleToggleAllowInactiveUsers() {
        if (this.isAllowingInactiveUsers()) {
            this.handleFilterChange('allow_inactive_users', false);
        } else {
            this.handleFilterChange('allow_inactive_users', true);
        }
    }

    renderExpander(row) {
        const type = this.getExpandoType(row);

        if (!type) {
            return null;
        }

        if (type === 'ViewPosts') {
            return <ActivationExpandoPosts
                activation={row.item}
                onClickDraft={this.handleClickInteraction.bind(this, row, 'ManageDrafts')}
                onClickPost={post => this.setState({postId: post.id})}
                campaign={this.props.campaign}
                refresh={this.handleRefresh.bind(this)}
            />
        }

        if (type === 'ViewStats') {
            return <ConnectedActivationExpandoStats activation={row.item} />
        }
    }


    renderSelectAllBanner() {
        const currentSelected = this.getSelectedIds();
        const totalHits = this.getTotalHits();
        const isFetchingAllIds = this.state.isFetchingAllIds;
        const hasMultiplePages = this.getMaxPages() > 1;

        if (this.state.selectAll && !this.state.selectEverything && hasMultiplePages) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>Currently selecting {currentSelected.length} items on this page. <a className="v3 bold" onClick={this.toggleSelectEverything.bind(this)} role="button">Select all {totalHits} instead.</a> {isFetchingAllIds && <i className="v3 icon spinner" />}</span>}
                />
            );
        }

        if (this.state.selectEverything) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>Selected {currentSelected.length} items from all pages.</span>}
                />
            );

        }

        return null;
    }

    renderBanner() {

        if (this.state.selectAll || this.state.selectEverything) {
            return this.renderSelectAllBanner();
        }

        if (!this.isSwitchActive('stats')) {
            return null;
        }

        const campaignId = parseInt(this.props.query.filters.campaign_id, 10);

        return <ConnectedCampaignReport
            campaignId={campaignId}
            showHeader={false}
            showROITable={false}
            showInfluencersTable={false}
            showTopPosts={false}
            style={{
                padding: 0,
                marginBottom: 20
            }}
        />
    }

    renderHeaderIcon() {
        const hits = this.getTotalHits();
        const inCampaign = this.getTotalActivationsInCampaign();

        if (!inCampaign && hits || hits === inCampaign) {
            return <span className="large badge">{hits}</span>;
        }

        if (hits !== inCampaign) {
            return <span className="large badge">{hits} of {inCampaign}</span>;
        }

        return <span className="large badge">{hits}</span>;
    }

    handleRowVisibilityChange(row) {

        const activation = row.item;

        if ((row.isVisible || row.wasEverVisible) && !activation.didFetch && !activation.isFetching) {

            if (this.state.isFetchingTotals && this.fetchTotalsPromise !== null) {
                this.fetchTotalsPromise.then(() => this.props.fetchActivation(activation.id));
            } else {
                this.props.fetchActivation(activation.id);
            }
        }

    }

    render() {

        if (typeof (this.props.campaign || {}).workflow_id !== 'undefined' && this.props.campaign.workflow_id !== null) {
            return <Redirect to={`/manage/do/page/campaign/activationsv1?id=${this.props.campaign.id}`}/>;
        }

        return (

            <TableWrapper
                items={this.props.activations}
                columns={this.getColumns()}
                tabs={this.getTabs()}
                title={this.state.title ? this.state.title : 'Activations'}
                banner={this.renderBanner()}
                popups={this.renderPopups()}
                switches={this.renderSwitches()}
                onSortChange={this.handleSortChange}
                sort={this.props.query.sort}
                showTotals={this.state.didFetchTotals && !!this.state.totals}
                showPagination={true}
                onPageChange={this.handlePageChange}
                page={this.props.query.page}
                buttons={this.renderButtons()}
                pages={this.getMaxPages()}
                headerIcon={this.renderHeaderIcon()}
                visibleColumns={this.state.visibleColumns}
                stickySortTabs={true}
                showLoadingAlert={this.shouldShowLoadingAlert()}
                blurry={this.shouldBlur()}
                isRowSelected={row => this.isRowSelected(row)}
                showFilters={this.isSwitchActive('filter')}
                filters={this.props.query.filters || {}}
                onFilterChange={this.handleFilterChange}
                isRowExpanded={row => this.isRowExpanded(row)}
                expander={row => this.renderExpander(row)}
                onRowVisibilityChange={this.handleRowVisibilityChange.bind(this)}
                wrapperClasses={['activations-godzilla-table']}
            />

        );
    }
}
