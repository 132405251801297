import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ConnectedManageCatalog from './ManageCatalog';
import Toolbox from "../Common/Toolbox";

class AddCatalog extends Component {

    static propTypes = {
        onClose: PropTypes.func,
        refresh: PropTypes.func,
        embedded: PropTypes.boolean,
    };

    static defaultProps = {
        embedded: false,
    };

    getStyle() {
        const style = {
            top: 60,
            left: '50%',
            width: 500,
            marginLeft: -250,                
            backgroundColor: '#EEE',
        };
        
        if (this.props.embedded) {
            delete style.top;
        }

        return style;
    }

    render() {
        return <Toolbox
            style={this.getStyle()}
            title='Create a Catalog'
            onClose={this.props.onClose}
            content={<ConnectedManageCatalog
                onClose={this.props.onClose}
                interactionType={'AddProductCatalog'}
                refresh={this.props.refresh}
            />}
        />
    }
}

export default AddCatalog;