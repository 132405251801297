/**
 * Created by bkanber on 6/27/17.
 */

import {combineReducers} from 'redux';
import {
    REQUEST_CAMPAIGN, RECEIVE_CAMPAIGN,
    REQUEST_CAMPAIGN_IMAGES, RECEIVE_CAMPAIGN_IMAGES,
    REQUEST_CAMPAIGNS, RECEIVE_CAMPAIGNS,
    REQUEST_CAMPAIGN_STATS, RECEIVE_CAMPAIGN_STATS,
    REQUEST_CAMPAIGN_ACTIVATIONS, RECEIVE_CAMPAIGN_ACTIVATIONS,
    REQUEST_CAMPAIGN_ACTIVATION_STATS, RECEIVE_CAMPAIGN_ACTIVATION_STATS,
    REQUEST_CAMPAIGN_USERS, RECEIVE_CAMPAIGN_USERS,
    REQUEST_CAMPAIGN_AUDIENCE, RECEIVE_CAMPAIGN_AUDIENCE,
    REQUEST_CAMPAIGN_GEOGRAPHY, RECEIVE_CAMPAIGN_GEOGRAPHY,
    REQUEST_CAMPAIGN_AFFINITY, RECEIVE_CAMPAIGN_AFFINITY,
    REQUEST_CAMPAIGN_REPORT, RECEIVE_CAMPAIGN_REPORT,

    RECEIVE_USER_ACTIVATION_STATS, REQUEST_USER_ACTIVATION_STATS, RECEIVE_USER_ACTIVATIONS,
    UPDATE_CAMPAIGN_EMV_PARAMS, UPDATED_CAMPAIGN_EMV_PARAMS,

    REQUEST_ACTIVATION, RECEIVE_ACTIVATION,
    REQUEST_ACTIVATION_REQUIREMENTS, RECEIVE_ACTIVATION_REQUIREMENTS

} from '../actions';
import {ADDED_CAMPAIGN_TEAM, UPDATE_CAMPAIGN_SETTING, UPDATED_CAMPAIGN_SETTING, REMOVED_CAMPAIGN_TEAM, ACTIVATED_USERS, UPDATE_CAMPAIGN_SETTINGS, UPDATED_CAMPAIGN_SETTINGS} from "../actions/campaigns";
import {REQUEST_CAMPAIGN_PAGE_TEMPLATES, RECEIVE_CAMPAIGN_PAGE_TEMPLATES} from "../actions/templates";
import * as ActionTypes from '../actions/types';
import {receiveItems} from "../utilities/reducer";

export function activationStatsById(state = {}, action) {
    switch (action.type) {

        case RECEIVE_CAMPAIGN_ACTIVATION_STATS:
        case RECEIVE_USER_ACTIVATION_STATS:
            let newState = Object.assign({}, state);
            for (let stat of action.stats) {
                let existing = newState[stat.activation_id];
                if (typeof newState[stat.activation_id] === 'undefined') {
                    newState[stat.activation_id] = [];
                }
                newState[stat.activation_id].push(stat);
            }
            return newState;
            break;

        case REQUEST_USER_ACTIVATION_STATS:
        case REQUEST_CAMPAIGN_ACTIVATION_STATS:
        default:
            return state;
    }

}

export function activationsById(state = {}, action) {
    switch (action.type) {
        case RECEIVE_USER_ACTIVATIONS:
        case RECEIVE_CAMPAIGN_ACTIVATIONS:
        case ACTIVATED_USERS:
            let newState = Object.assign({}, state);
            for (let activation of action.activations) {
                newState[activation.id] = activation;
            }
            return newState;

        case REQUEST_ACTIVATION:
            return {
                ...state,
                [action.activationId]: {
                    ...state[action.activationId],
                    isFetchingActivation: true
                }
            };

        case RECEIVE_ACTIVATION:
            return {
                ...state,
                [action.activationId]: {
                    ...state[action.activationId],
                    ...action.activation,
                    isFetchingActivation: false,
                    lastUpdatedActivation: action.receivedAt,

                }
            };

        case REQUEST_ACTIVATION_REQUIREMENTS:
            return {
                ...state,
                [action.activationId]: {
                    ...state[action.activationId],
                    isFetchingRequirements: true
                }
            };

        case RECEIVE_ACTIVATION_REQUIREMENTS:
            return {
                ...state,
                [action.activationId]: {
                    ...state[action.activationId],
                    isFetchingRequirements: false,
                    lastUpdatedRequirements: action.receivedAt,
                    requirements: action.requirements
                }
            };

        default:
            return state;
    }

}
export const DEFAULT_CAMPAIGN_STATE = {
        id: null,

        isFetching: true,
        lastUpdated: null,

        isFetchingReport: false,
        lastUpdatedReport: null,

        isFetchingImages: false,
        lastUpdatedImages: null,

        isFetchingActivations: false,
        lastUpdatedActivations: null,

        isFetchingUsers: false,
        lastUpdatedUsers: null,

        isFetchingAudience: false,
        lastUpdatedAudience: null,

        isFetchingGeography: false,
        lastUpdatedGeography: null,

        isFetchingActivationStats: false,
        lastUpdatedActivationStats: null,

        isUpdatingEMVParams: false,
        lastUpdatedEMVParams: null,

        isUpdatingSetting: false,
        lastUpdatedSetting: null,

        isFetchingAffinity: false,
        lastUpdatedAffinity: null,

        isFetchingPageTemplates: false,
        lastUpdatedPageTemplates: null,

        isFetchingCoupons: false,
        lastUpdatedCoupons: null,

        imageIds: [],
        activationIds: [],
        userIds: [],
        audience: [],
        geography: {},
        affinity: {},
        report: {}
    };

function campaign(
    state = DEFAULT_CAMPAIGN_STATE,
    action
)
{
    switch (action.type) {

        case ActionTypes.CAMPAIGN_RECEIVE_COUPONS:
            return {...state, isFetchingCoupons: false, lastUpdatedCoupons: new Date()};
            break;

        case ActionTypes.CAMPAIGN_REQUEST_COUPONS:
            return {...state, isFetchingCoupons: true};
            break;

        case ACTIVATED_USERS: {

            const aIds = [...state.activationIds];

            action.activations.forEach(a => {
                if (aIds.indexOf(a.id) === -1) {
                    aIds.push(a.id);
                }
            });

            return {
                ...state,
                activationIds: aIds
            };
        }

        case ADDED_CAMPAIGN_TEAM:
        case REMOVED_CAMPAIGN_TEAM:
            return {
                ...state,
                teams: action.campaign.teams
            };

        case RECEIVE_CAMPAIGN_REPORT:
            return {
                ...state,
                isFetchingReport: false,
                lastUpdatedReport: action.receivedAt,
                report: action.report
            };

        case REQUEST_CAMPAIGN_REPORT:
            return {
                ...state,
                isFetchingReport: true
            };

        case RECEIVE_CAMPAIGN_PAGE_TEMPLATES:
            return {
                ...state,
                isFetchingPageTemplates: false,
                lastUpdatedPageTemplates: action.receivedAt
            };

        case REQUEST_CAMPAIGN_PAGE_TEMPLATES:
            return {
                ...state,
                isFetchingPageTemplates: true
            };

        case RECEIVE_CAMPAIGN_AFFINITY:
            return {
                ...state,
                isFetchingAffinity: false,
                lastUpdatedAffinity: action.receivedAt,
                affinity: action.affinity
            };
            break;

        case REQUEST_CAMPAIGN_AFFINITY:
            return {...state, isFetchingAffinity: true};
            break;

        case REQUEST_CAMPAIGN_GEOGRAPHY:
            return Object.assign({}, state, {isFetchingGeography: true});
            break;

        case RECEIVE_CAMPAIGN_GEOGRAPHY:
            return Object.assign({}, state, {
                isFetchingGeography: false,
                lastUpdatedGeography: action.receivedAt,
                geography: action.geography
            });
            break;

        case UPDATED_CAMPAIGN_SETTING:
            return {...state, ...action.campaign, isUpdatingSetting: false, lastUpdatedSetting: action.receivedAt};

        case UPDATE_CAMPAIGN_SETTING:
            return {...state, isUpdatingSetting: true};

        case UPDATED_CAMPAIGN_SETTINGS:
            return {...state, ...action.data, isUpdatingSetting: false, lastUpdatedSetting: action.receivedAt};

        case UPDATE_CAMPAIGN_SETTINGS:
            return {...state, isUpdatingSetting: true};

        case UPDATE_CAMPAIGN_EMV_PARAMS:
            return {...state, isUpdatingEMVParams: true};

        case UPDATED_CAMPAIGN_EMV_PARAMS:
            return {...state,
                isUpdatingEMVParams: false,
                lastUpdatedEMVParams: action.receivedAt,
                emv_params: action.emvParams
            };

        case REQUEST_CAMPAIGN_ACTIVATION_STATS:
            return Object.assign({}, state, {isFetchingActivationStats: true});
            break;

        case RECEIVE_CAMPAIGN_ACTIVATION_STATS:
            return Object.assign({}, state, {
                isFetchingActivationStats: false,
                lastUpdatedActivationStats: action.receivedAt
            });
            break;

        case REQUEST_CAMPAIGN_AUDIENCE:
            return Object.assign({}, state, {isFetchingAudience: true});
            break;

        case RECEIVE_CAMPAIGN_AUDIENCE:
            return Object.assign({}, state, {
                isFetchingAudience: false,
                lastUpdatedAudience: action.receivedAt,
                audience: action.audience
            });
            break;

        case RECEIVE_CAMPAIGN:
            return Object.assign({}, state, action.campaign, {isFetching: false, lastUpdated: action.receivedAt});
            break;

        case RECEIVE_CAMPAIGN_IMAGES:
            return Object.assign({}, state, {
                imageIds: action.images.map(i => i.id),
                isFetchingImages: false,
                lastUpdatedImages: action.receivedAt
            });
            break;

        case RECEIVE_CAMPAIGN_USERS:
            return Object.assign({}, state, {
                userIds: action.users.map(i => i.id),
                isFetchingUsers: false,
                lastUpdatedUsers: action.receivedAt
            });
            break;

        case RECEIVE_CAMPAIGN_ACTIVATIONS:
            return Object.assign({}, state, {
                activationIds: action.activations.map(i => i.id),
                isFetchingActivations: false,
                lastUpdatedActivations: action.receivedAt
            });
            break;

        case REQUEST_CAMPAIGN_USERS:
            return Object.assign({}, state, {isFetchingUsers: true});
            break;

        case REQUEST_CAMPAIGN_ACTIVATIONS:
            return Object.assign({}, state, {isFetchingActivations: true});
            break;

        case REQUEST_CAMPAIGN_IMAGES:
            return Object.assign({}, state, {isFetchingImages: true});
            break;

        case REQUEST_CAMPAIGN:
            return {...state, isFetching: true};

        default:
            return state;
    }

}

function campaignStats(
    state = {isFetching: false, lastUpdated: null, items: []},
    action
) {

    switch (action.type) {
        case REQUEST_CAMPAIGN_STATS:
            return Object.assign({}, state, {isFetching: true});

        case RECEIVE_CAMPAIGN_STATS:
            return Object.assign({}, state, {
                isFetching: false,
                lastUpdated: action.receivedAt,
                items: action.stats
            });

        default:
            return state;
    }

}

export function campaignStatsById(
    state = {},
    action
) {

    switch (action.type) {

        case REQUEST_CAMPAIGN_STATS:
        case RECEIVE_CAMPAIGN_STATS:
            return Object.assign({}, state, {
                [action.campaignId]: campaignStats(state[action.campaignId], action)
            });

        default:
            return state;

    }
}

export function campaignsById(state = {
    isFetching: false,
    lastUpdated: null,
    items: {}
}, action) {
    switch (action.type) {
        case ACTIVATED_USERS:
        case REQUEST_CAMPAIGN_REPORT:
        case RECEIVE_CAMPAIGN_REPORT:
        case REQUEST_CAMPAIGN_AFFINITY:
        case RECEIVE_CAMPAIGN_AFFINITY:
        case UPDATE_CAMPAIGN_SETTING:
        case UPDATED_CAMPAIGN_SETTING:
        case UPDATE_CAMPAIGN_EMV_PARAMS:
        case UPDATED_CAMPAIGN_EMV_PARAMS:
        case REQUEST_CAMPAIGN_ACTIVATION_STATS:
        case RECEIVE_CAMPAIGN_ACTIVATION_STATS:
        case REQUEST_CAMPAIGN_GEOGRAPHY:
        case RECEIVE_CAMPAIGN_GEOGRAPHY:
        case REQUEST_CAMPAIGN_AUDIENCE:
        case RECEIVE_CAMPAIGN_AUDIENCE:
        case REQUEST_CAMPAIGN_USERS:
        case RECEIVE_CAMPAIGN_USERS:
        case REQUEST_CAMPAIGN_ACTIVATIONS:
        case RECEIVE_CAMPAIGN_ACTIVATIONS:
        case RECEIVE_CAMPAIGN:
        case RECEIVE_CAMPAIGN_IMAGES:
        case ADDED_CAMPAIGN_TEAM:
        case REMOVED_CAMPAIGN_TEAM:
        case UPDATED_CAMPAIGN_SETTINGS:
        case UPDATE_CAMPAIGN_SETTINGS:
        case ActionTypes.CAMPAIGN_RECEIVE_COUPONS:
        case ActionTypes.CAMPAIGN_REQUEST_COUPONS:
            let newItems = Object.assign({}, state.items, {
                [action.campaignId]: campaign(state.items[action.campaignId], action)
            });

            return Object.assign({}, state, {items: newItems});
            break;

        case RECEIVE_CAMPAIGNS: {
            let items = action.campaigns.reduce((o, c) =>
                ({...o,
                    [c.id]: campaign(state.items[c.id],
                        {type: RECEIVE_CAMPAIGN, campaign: c, receivedAt: action.receivedAt})
                }), state.items);

            return Object.assign({}, state, {
                isFetching: false,
                lastUpdated: action.receivedAt,
                items
            });

            break;
        }

        case REQUEST_CAMPAIGNS:
            return Object.assign({}, state, {
                isFetching: true,
            });
            break;


        default:
            return state;
    }
}

export function couponsById(state = {}, action) {
    switch (action.type) {

        case ActionTypes.CAMPAIGN_RECEIVE_COUPONS:
            return receiveItems(state, action);
            break;

        default:
            return state;
    }
}

const formsById = (state = {}, action) => {

    switch (action.type) {
        case ActionTypes.CAMPAIGN_RECEIVE_FORMS:
            return receiveItems(state, action);
            break;

        default:
            return state;
    }

};

export const campaigns = combineReducers({
    byId: campaignsById,
    coupons: couponsById,
    stats: campaignStatsById,
    forms: formsById,
});
