import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formatNumber, makeMomentFromDate, proxyImageUrl} from "../../../../utilities";
import {socialStatsTypeToNiceName} from "../../../../utilities/social";

export default class SocialPostPreview extends Component {

    static propTypes = {
        post: PropTypes.object.isRequired,
        scrollToTopOnLoad: PropTypes.bool,
        useImageProxy: PropTypes.bool,
    };

    static defaultProps = {
        scrollToTopOnLoad: false,
        useImageProxy: false,
    };

    componentDidMount() {
        if (this.props.scrollToTopOnLoad) {
            window.scrollTo(0, 60);
        }
    }
    renderEngagementsList() {

        const {engagements} = this.props.post;

        return Object.entries(engagements || {})
            .map(([key, value]) => {
                return (
                    <div className="fake-li bottom-border slim padded">
                        <strong>{socialStatsTypeToNiceName(key)}: </strong>
                        <span>{formatNumber(value)}</span>
                    </div>
                );
            });
    }

    renderAttributesTable() {

        const {title, permalink, source_created_at} = this.props.post;
        const date = makeMomentFromDate(source_created_at);

        return (
            <div>

                <div className="fake-li bottom-border slim padded">
                    <strong>Title: </strong>
                    <span>
                        {title}
                    </span>
                </div>

                <div className="fake-li bottom-border slim padded">
                    <strong>Date: </strong>
                    <span>
                        {date.format('MMM Do, YYYY')}
                    </span>
                </div>

                {this.renderEngagementsList()}

                <div className="fake-li bottom-border slim padded">
                    <strong>Original: </strong>
                    <span>
                        <a
                            href={permalink}
                            className="inline nopadding"
                            target="_blank"
                            rel="noreferrer"
                            style={{padding: 0}}
                        >View Original</a></span>
                </div>


            </div>

        );
    }

    renderImages() {
        const {image_urls = []} = this.props.post;

        if (image_urls.length === 0) {
            return null;
        }

        return (
            <div
                style={{borderBottom: "1px solid #e5e5e5"}}
            >
                <label
                    style={{paddingTop: 7, paddingLeft: 15}}
                ><strong>
                    Images:
                </strong></label>

                <div
                    style={{
                        margin: 15,
                        padding: 15,
                        background: '#FAFAFA'
                    }}
                >

                    {image_urls.map(url => {
                        const urlToUse = this.props.useImageProxy ? proxyImageUrl(url) : url;
                        return (
                            <img key={urlToUse} src={urlToUse} style={{height: 200, marginRight: 1}}/>
                        );
                    })}

                </div>

            </div>

        );

    }

    renderContent() {

        const {content} = this.props.post;

        return (
            <div
                style={{borderBottom: "1px solid #e5e5e5"}}
            >
                <label
                    style={{paddingTop: 7, paddingLeft: 15}}
                ><strong>
                    Content:
                </strong></label>
                <div
                    style={{
                        margin: 15,
                        padding: 15,
                        background: '#FAFAFA'
                    }}
                    dangerouslySetInnerHTML={{__html: content}}></div>

            </div>

        );
    }

    render() {
        return (
            <div
                className={'social-post-preview-wrapper'}
            >

                {this.renderAttributesTable()}
                {this.renderContent()}
                {this.renderImages()}
            </div>

        );
    }
}
