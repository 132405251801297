import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {fetchActivation} from "../../actions";
import Form from '../Common/Form/Form';
import Alert from "../Common/Alert";
import {makeFormData} from "../../utilities";
import {fetchAuthenticated} from "../../actions/auth";
import _find from 'lodash/find';
import * as URI from 'urijs';
import AddABlog from '../Influencer/EditProfile/Templates/AddABlog';
import Toolbox from "../Common/Toolbox";
import {translate} from "../../actions/i18n";

class SubmitBlogPostForm extends Component {

    static defaultProps = {
        usePadding: true,
        style: {},
    };

    static propTypes = {
        activation: PropTypes.object.isRequired,
        fetchActivation: PropTypes.func,
        fetchAuthenticated: PropTypes.func,
        createInteraction: PropTypes.func,
        translate: PropTypes.func,
        onComplete: PropTypes.func,
        usePadding: PropTypes.bool,
        style: PropTypes.object,
    };

    state = {
        url: null,
        blog_id: null,
        auth_url: null,

        showAddBlog: false,
        isSubmitting: false,
        didSubmit: false,
        blogs: null,
        isFetchingBlogs: false,
        didFetchBlogs: false,
    };

    toggleAddBlog(){
        this.setState({showAddBlog: !this.state.showAddBlog})
    }

    fetchBlogs() {
        const userId = this.props.activation.user_id;
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/user/${userId}/blog?withAuthUrl=1`;

        this.setState({isFetchingBlogs: true});
        return this.props.fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => this.setState({
                blogs: json.data,
                auth_url: json.meta.auth_url,
                isFetchingBlogs: false,
                didFetchBlogs: true
            }));
    }

    makeAuthUrlForBlog(blogId) {

        let uri = new URI(this.state.auth_url);
        uri.search({
            ...uri.search(true),
            blogId: blogId,
            after: '__pm'
        });
        return uri.toString();

    }

    componentDidMount() {
        this.fetchBlogs();
    }

    handleFieldChange(name, value) {
        this.setState({[name]: value, didSubmit: false});
    }

    handleSubmit() {
        const {activation, onComplete, fetchActivation, createInteraction} = this.props;
        const {isSubmitting, url, blog_id} = this.state;


        const isValid = !isSubmitting && url && url.length > 4;
        if (!isValid) {
            return;
        }

        this.setState({isSubmitting: true});
        const context = {url, blog_id};

        return createInteraction(activation.id, 'SubmitBlogPost', context)
            .then(() => this.setState({isSubmitting: false, didSubmit: true, url: ''}))
            .then(() => fetchActivation(activation.id))
            .then(() => {
                if (onComplete) {
                    onComplete(context);
                }
            })

    }

    handleAuthWindowMessage(msg) {
        const {data} = msg;

        if (this.authWindow) {
            this.authWindow.close();
        }

        if (data.from !== 'VerifyBlog') {
            return;
        }

        const {id} = data;

        this.fetchBlogs()
            .then(() => {
                // Need to check if the ID we got from the auth window is now in the response.
                const blog = _find(this.state.blogs, {id});

                if (!blog) {
                    this.handleVerifyBlogFromToken(data);
                }

            });
    }

    handleVerifyBlogFromToken(data) {

        const slot = window.Gator.getDashboardSlot();
        const activation = this.props.activation;
        const url = `/${slot}/api/user/${activation.user_id}/verifyblog/${this.state.blog_id}`;
        const payload = {
            token: data.t,
            viewId: data.viewId,
        };

        const options = {
            method: 'POST',
            body: makeFormData(payload)
        };

        return this.props.fetchAuthenticated(url, options)
            .then(() => this.fetchBlogs());
    }

    launchAuthWindow() {
        const url = this.makeAuthUrlForBlog(this.state.blog_id);

        if (this.authWindow) {
            this.authWindow.close();
        }

        this.authWindow = window.open(url.toString(), '_blank', 'height=600,width=800');

        window.addEventListener('message', this.handleAuthWindowMessage.bind(this));

    }

    render() {
        const _t = this.props.translate;
        const {isSubmitting, didSubmit, url, blog_id, blogs} = this.state;
        const isValid = !isSubmitting && url && url.length > 4 && blog_id;
        const blog = _find(this.state.blogs, {id: blog_id});
        const doesBlogNeedVerify = blog && !blog.is_verified;
        const isBlogAnInstagramPage = blog && blog.hostname === 'www.instagram.com';
        const blogChoices = (blogs || []).map(blog => ({text: blog.name, value: blog.id}));
        const addBlogLink = <a href='#' onClick={this.toggleAddBlog.bind(this)}>{_t('campaign.blog.form.add_blog_link', 'Add a Blog.')}</a>;
        const helpText = blogChoices.length
            ? <span>{_t('campaign.blog.form.add_blog_help', 'Indicate the attached blog that the post appears on, or')} {addBlogLink}</span>
            : addBlogLink;

        let btnText = _t('campaign.blog.form.btn.submit', 'Submit Blog Post');
        if (isSubmitting) {
            btnText = _t('campaign.blog.form.btn.submitting', 'Submitting Post, Please Wait...');
        }
        if (didSubmit) {
            btnText = _t('campaign.blog.form.btn.submitted', 'Submitted Post!');
        }

        return (
            <Form
                name={'SubmitBlogPostForm'}
                style={this.props.style}
                usePadding={this.props.usePadding}
                onFieldChange={this.handleFieldChange.bind(this)}
                values={this.state}
                fields={[
                    {
                        type: 'select',
                        name: 'blog_id',
                        title: _t('campaign.blog.form.blog', "Select Blog"),
                        help: helpText,
                        choices: [
                            {text: "- " + _t('campaign.blog.form.blog', 'Select Blog') + " -", value: null},
                            ...blogChoices
                        ]
                    },
                    this.state.showAddBlog ? {
                        type: 'generic',
                        content: <Toolbox
                            title={_t('campaign.blog.form.add.title', 'Add a Blog')}
                            content={<AddABlog
                                user={this.props.activation.user}
                                onClose={()=>{
                                    this.toggleAddBlog();
                                    this.fetchBlogs();
                                }}
                            />}
                            onClose={this.toggleAddBlog.bind(this)}
                            style={{
                                width: 500,
                                left: '50%',
                                marginLeft: -250,
                                maxHeight: 600,
                                top: '30%'
                            }}
                        />
                    } : null,
                    doesBlogNeedVerify && !isBlogAnInstagramPage ? {
                        type: 'generic',
                        content: <Alert
                            classes={['danger']}
                            content={<div>{_t('campaign.blog.form.alert_not_verified', 'The selected blog is not verified with Google Analytics. Your requirements will not be considered complete until your blog is verified.')} <a className="v3 bold" style={{color: '#F2F2F2'}} role="button" onClick={this.launchAuthWindow.bind(this)}>{_t('campaign.blog.form.alert_not_verified_link', 'Click to verify with Google Analytics.')}</a></div>}
                        />

                    } : null,
                    {
                        type: 'text',
                        name: 'url',
                        title: _t('campaign.blog.form.url.title', 'URL of Your Post'),
                        placeholder: 'eg: https://myblog.com/some-post',
                        help: _t('campaign.blog.form.url.help', 'Submit the URL or link to your blog post.')
                    },
                    {
                        type: 'button',
                        name: 'submit',
                        title: btnText,
                        options: {
                            classes: ['v3 btn btn-primary medium bold', (!isValid ? 'disabled' : '')],
                            hideTitle: true,
                            onClick: !isValid ? null : this.handleSubmit.bind(this),
                        }
                    },
                ]}
            />

        );
    }

}

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => {
    return {
        fetchActivation: (id) => dispatch(fetchActivation(id)),
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
        translate: (key, defaultValue) => dispatch(translate(key, defaultValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitBlogPostForm);
