import {receiveItems, modifyItem, receiveItem} from "../utilities/reducer";
import {combineReducers} from 'redux';
import {makeQueriesReducerFor} from './queries';
import * as ActionTypes from '../actions/types';

export const DEFAULT_IMAGES_QUERY = {
    id: "default",
    sort: {by: "created_at", dir: "desc"},
    limit: 36,
    page: 1,
    filters: {},
    context: {},
    pages: {}
};

const queries = makeQueriesReducerFor('IMAGES_');

// New version of reducer, not exported because old version still in use
const imagesById = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.IMAGES_RECEIVE_QUERY: {
            const mappedAction = {
                ...action,
                data: action.data.map(item => ({...item, id: item._key || item.id, _id: item.id}))
            };
            return receiveItems(state, mappedAction);
        }

        default:
            return state;
    }

};

export const images = combineReducers({
    queries,
    items: imagesById,
});
