/**
 * Created by bkanber on 6/29/17.
 */

import {REQUEST_TALENTSPIDER, RECEIVE_TALENTSPIDER, RECEIVE_TALENTSPIDER_CANDIDATES, REQUEST_TALENTSPIDER_CANDIDATES, REQUEST_TALENTSPIDER_SETTINGS, RECEIVE_TALENTSPIDER_SETTINGS, UPDATE_TALENTSPIDER_CANDIDATE, UPDATED_TALENTSPIDER_CANDIDATE, INVALIDATE_TALENTSPIDER_CANDIDATES, REQUEST_TALENTSPIDER_PIPELINE, RECEIVE_TALENTSPIDER_PIPELINE, INVALIDATE_TALENTSPIDER_PIPELINE, DELETED_TALENTSPIDER_CANDIDATE, DELETE_TALENTSPIDER_CANDIDATE, REQUEST_TALENTSPIDERS, RECEIVE_TALENTSPIDERS, CREATED_TALENTSPIDER} from '../actions/talentspiders';
import {indexArrayById} from '../utilities';
import {receiveItem, requestItem, modifyItem, receiveItems, deleteKey} from "../utilities/reducer";
import {makeQueriesReducerFor} from './queries';
import {combineReducers} from 'redux';
import * as ActionTypes from '../actions/types';

export const DEFAULT_CANDIDATES_QUERY = {
    id: "default",
    sort: {by: "completeness_score", dir: "desc"},
    limit: 20,
    page: 1,
    filters: {},
    context: {},
    pages: {}
};

export const DEFAULT_TALENTSPIDER_STATE = {

    isFetching: false,
    lastUpdated: null,

    isFetchingSettings: false,
    lastUpdatedSettings: null,

    isFetchingCandidates: false,
    lastUpdatedCandidates: null,
    didInvalidateCandidates: true,

    isFetchingPipeline: false,
    lastUpdatedPipeline: null,
    didInvalidatePipeline: true,
};

export function talentspiderCandidatesById(state = {}, action) {
    switch (action.type) {

        case DELETED_TALENTSPIDER_CANDIDATE:
            return deleteKey(state, action.candidateId);


        case UPDATED_TALENTSPIDER_CANDIDATE:
            return modifyItem(state, action, {[action.name]: action.value, isUpdating: false});

        case UPDATE_TALENTSPIDER_CANDIDATE:
            return modifyItem(state, action, {isUpdating: true});

        case RECEIVE_TALENTSPIDER_CANDIDATES:
        case RECEIVE_TALENTSPIDER_PIPELINE:
        case ActionTypes.CANDIDATES_RECEIVE_QUERY:
            return receiveItems(state, action);

        default:
            return state;
    }
}

export function talentspidersById(state = {}, action) {

    switch (action.type) {

        case INVALIDATE_TALENTSPIDER_PIPELINE:
            return modifyItem(state, action, {didInvalidatePipeline: true}, DEFAULT_TALENTSPIDER_STATE);

        case INVALIDATE_TALENTSPIDER_CANDIDATES:
            return modifyItem(state, action, {didInvalidateCandidates: true}, DEFAULT_TALENTSPIDER_STATE);

        case RECEIVE_TALENTSPIDER_SETTINGS:
            return modifyItem(state, action,
                {
                    isFetchingSettings: false,
                    lastUpdatedSettings: action.receivedAt,
                    settings: action.data
                }, DEFAULT_TALENTSPIDER_STATE);

        case REQUEST_TALENTSPIDER_SETTINGS:
            return modifyItem(state, action, {isFetchingSettings: true}, DEFAULT_TALENTSPIDER_STATE);

        case RECEIVE_TALENTSPIDER_CANDIDATES:
            return modifyItem(state, action,
                { isFetchingCandidates: false, didInvalidateCandidates: false, lastUpdatedCandidates: new Date() },
                DEFAULT_TALENTSPIDER_STATE);

        case REQUEST_TALENTSPIDER_CANDIDATES:
            return modifyItem(state, action,
                { isFetchingCandidates: true, didInvalidateCandidates: false },
                DEFAULT_TALENTSPIDER_STATE);

        case RECEIVE_TALENTSPIDER_PIPELINE:
            return modifyItem(state, action,
                {isFetchingPipeline: false, lastUpdatedPipeline: new Date(), didInvalidatePipeline: false},
                DEFAULT_TALENTSPIDER_STATE);

        case REQUEST_TALENTSPIDER_PIPELINE:
            return modifyItem(state, action,
                {isFetchingPipeline: true, didInvalidatePipeline: false},
                DEFAULT_TALENTSPIDER_STATE);

        case REQUEST_TALENTSPIDER:
            return requestItem(state, action, {}, DEFAULT_TALENTSPIDER_STATE);

        case RECEIVE_TALENTSPIDER:
        case CREATED_TALENTSPIDER:
            return receiveItem(state, action, {lastUpdated: action.receivedAt}, DEFAULT_TALENTSPIDER_STATE);

        case RECEIVE_TALENTSPIDERS:
            return receiveItems(state, action, {}, DEFAULT_TALENTSPIDER_STATE);

        default:
            return state;
    }

}

const queries = makeQueriesReducerFor('CANDIDATES_');

export const candidates = combineReducers({
    queries,
    items: talentspiderCandidatesById,
});
