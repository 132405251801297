import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Alert extends Component {

    static defaultProps = {
        classes: [],
        style: {},
        visible: true,

    };

    static propTypes = {
        classes: PropTypes.array,
        style: PropTypes.object,
        content: PropTypes.node,
        icon: PropTypes.node,
        onClose: PropTypes.func,
        visible: PropTypes.bool,
    };

    render() {
        const classes = ['v3', 'tidal-alert', 'light', ...this.props.classes];
        if (this.props.visible === false) {
            classes.push('hidden');
        }

        return (

            <div
                className={classes.join(' ')}
                style={this.props.style}>
                {this.props.content}
                {this.props.onClose && <span className="v3 close" onClick={this.props.onClose}></span>}
            </div>

        );
    }
}