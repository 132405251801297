import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {c3ColorPattern} from "../../utilities";

export default class TinyThermometer extends Component {

    render() {
        const {pct, tooltip, style, color} = this.props;
        const innerStyle = {width: pct + '%'};
        if (color) {
            innerStyle.background = color;
        }
        return (
            <span data-tooltip={tooltip} style={style} className={["tiny-thermometer", ...this.props.addlClasses].join(' ')}>
                <span style={innerStyle}>{this.props.label}</span>
            </span>
        );
    }
}

TinyThermometer.defaultProps = {
    style: {},
    addlClasses: [],
};

TinyThermometer.propTypes = {
    pct: PropTypes.number.isRequired,
    tooltip: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.string,
    addlClasses: PropTypes.array,
    label: PropTypes.node,
};
