import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Bar, BarChart, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {c3ColorPattern, formatNumber} from "../../utilities";

export default class RechartsBarChart extends Component {

    static defaultProps = {
        width: '100%',
        height: 320,
        data: [],
        showTooltips: true,
        showLegend: true,
        seriesName: '',
        formatPct: false,
        formatCurrency: false,
        colors: c3ColorPattern.pattern,
        useSingleColor: true,
        tickFormatter: (decimal, fixed = 0) => formatNumber(decimal),
        valueFormatter: (value) => formatNumber(value)
    };

    static propTypes = {
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        height: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        data: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.number,
        })),
        showTooltips: PropTypes.bool,
        showLegend: PropTypes.bool,
        seriesName: PropTypes.string,
        formatPct: PropTypes.bool,
        formatCurrency: PropTypes.bool,
        colors: PropTypes.array,
        useSingleColor: PropTypes.bool,
        tickFormatter: PropTypes.func,
        valueFormatter: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.renderTooltip = this.renderTooltip.bind(this);
    }

    tickFormatter = (decimal, fixed = 0) => {
        if (this.props.formatPct) {
            return `${(decimal * 100).toFixed(fixed)}%`;
        } else if (this.props.formatCurrency) {
            return `$${formatNumber(decimal)}`
        } else {
            return formatNumber(decimal);
        }
    };

    renderTooltip = (props) => {
        const {payload} = props;
        const {valueFormatter, colors} = this.props;

        if (!payload) {
            return null;
        }

        const name = ((payload[0] || {}).payload || {}).name || '';
        const value = ((payload[0] || {}).payload || {}).value || 0;
        const color = ((payload[0] || {}).payload || {}).color || colors[0];
        const tooltipVal = valueFormatter(value);

        // if (formatPct) {
        //     tooltipVal = formatNumber((value*100), 2)+'%';
        // } else if (formatCurrency) {
        //     tooltipVal = `$${formatNumber(value)}`;
        // } else {
        //     tooltipVal = formatNumber(value);
        // }

        return (
            <div className="custom-tooltip">
                <div className="tooltip-top">
                    <span className="cell stat-name">{name}</span>
                </div>
                <div className="tooltip-left">
                    <div className="cell color-box" style={{backgroundColor: color}}></div>
                    <span className="cell stat-name">{this.props.seriesName}</span>
                </div>
                <div className="tooltip-right">
                    <span className="cell stat-value">{tooltipVal}</span>
                </div>
            </div>
        )
    };


    render() {
        const {colors, seriesName, showLegend, showTooltips, useSingleColor} = this.props;

        const payload = useSingleColor
            ? [{
                id: seriesName,
                type: "square",
                value: seriesName,
                color: colors[0]
            }]
            : this.props.data.map(
                (item, index) => ({
                    id: item.name,
                    type: "square",
                    value: item.name,
                    color: colors[index],
                })
            );

        const bar = useSingleColor
            ? <Bar dataKey="value" fill={colors[0]} />
            : <Bar dataKey="value">
                {
                    this.props.data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                    ))
                }
            </Bar>;

        return (
            <ResponsiveContainer width={this.props.width} height={this.props.height}>

                <BarChart data={this.props.data} barCategoryGap="20%" >
                    <XAxis dataKey="name" interval={0} />
                    <YAxis dataKey="value" tickFormatter={this.props.tickFormatter} />

                    {bar}

                    {showLegend &&
                        <Legend
                            payload={payload}
                            iconSize={10}
                        />
                    }

                    {showTooltips &&
                        <Tooltip
                            content={this.renderTooltip}
                            contentStyle={{padding: 0}}
                            itemStyle={{padding: 0}}
                            cursor={{fill: 'rgba(255,255,255,0.35)'}}
                        />
                    }

                </BarChart>

            </ResponsiveContainer>
        )
    }

}

