import React, {useState} from 'react';
import {connect} from 'react-redux';
import Form from '../Common/Form/Form';
import Alert from '../Common/Alert';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";
import _find from 'lodash/find';
import {socialNameForType} from "../../utilities/social";

function AddPaidAdStatsToPostForm({activation, usePadding=true, createInteraction, fetchActivation, onSaved}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form, setForm] = useState({
        'paid-cost': 0,
        'paid-impressions': 0,
        'paid-clicks': 0,
        'paid-conversions': 0,
        'paid-revenue': 0
    });
    const handleSubmit = () => {
        setIsSubmitting(true);
        createInteraction(activation.id, 'AddPaidAdStatsToPost', form)
            .then(() => {
                fetchActivation(activation.id);
                setIsSubmitting(false);
                onSaved();
            })
            .catch(e => {
                setIsSubmitting(false);
                console.error(e);
            });
    }
    return (
        <Form
            onFieldChange={(name, value) => {
                setForm({...form, [name]: value});
            }}
            values={form}
            fields={[
                {
                    type: 'select',
                    name: 'post_record_id',
                    title: 'Select the Post to Update',
                    choices: (activation.post_records || activation.postRecords || [])
                        .map(pr => {
                            return {
                                value: pr.id,
                                text: socialNameForType(pr.post.type) + ': ' + pr.post.title,
                            }
                        })
                },
                {
                    type: 'number',
                    name: 'paid-cost',
                    title: 'Cost of Ad Spend',
                },
                {
                    type: 'number',
                    name: 'paid-impressions',
                    title: 'Impressions',
                },
                {
                    type: 'number',
                    name: 'paid-clicks',
                    title: 'Clicks',
                },
                {
                    type: 'number',
                    name: 'paid-conversions',
                    title: '# of Conversions',
                },
                {
                    type: 'number',
                    name: 'paid-revenue',
                    title: 'Amount of Revenue',
                },
                {
                    type: 'button',
                    name: 'submit',
                    title: isSubmitting ? 'Saving...' : 'Save Stats',
                    options: {
                        classes: ['v3 btn btn-primary medium bold', (isSubmitting ? 'disabled' : '')],
                        hideTitle: true,
                        onClick: handleSubmit
                    }
                },
            ]}
        />
    )
}


const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id))
    };
};

export default connect(null, mapDispatchToProps)(AddPaidAdStatsToPostForm);
