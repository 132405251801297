import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../Common/Form/Form";
import connect from "react-redux/es/connect/connect";
import {createChannelInteraction} from "../../actions/channel";
import {deleteCatalogProduct} from '../../actions/products'
import Alert from "../Common/Alert";
import {validateUrl, slugify} from "../../utilities";
import GenericSettingsForm from "../Common/GenericSettingsForm";
import _unique from 'lodash/uniq';
import _set from 'lodash/set';
import _merge from 'lodash/merge';

class ManageProduct extends Component {

    static propTypes = {
        title: PropTypes.string,
        interactionType: PropTypes.string,
        onClose: PropTypes.func,
        form: PropTypes.object,
        createInteraction: PropTypes.func,
        catalogId: PropTypes.number,
        refresh: PropTypes.func,
        showDeleteBtn: PropTypes.bool,
        deleteCampaignProduct: PropTypes.func,
    };

    static defaultProps = {
        refresh: () => {},
        onClose: () => {},
    };

    constructor(props) {
        super(props);
        this.state = {
            formIsUpdated: false,
            formIsSaving: false,
            formHasSaved: false,
            isDeleting: false,
            hasDeleted: false,
            touchedSku: false,
            valid: {},
            error: null,

            locales: [], // List of newly added locales to merge into via getValues()
            _add_locale: '', // The locale that is currently being typed/added
        }
    };


    handleSave(data) {

        delete(data._add_locale);
        console.log("data", data);
        // Convert data (with dot notation keys) to nested object
        const nestedData = {};
        Object.keys(data).forEach(key => {
            _set(nestedData, key, data[key]);
        });
        // if We have updated locales, merge them in -- otherwise only the update will be sent
        if (nestedData.locales) {
            nestedData.locales = _merge({}, this.getValues().locales, nestedData.locales);
        }
        // add the catalog id
        nestedData.product_catalog_id = this.props.catalogId;
        // also add product id if it exists
        if (this.props.form && this.props.form.product_id) {
            nestedData.product_id = this.props.form.product_id;
        }
        console.log("nestedData", nestedData);

        const { interactionType } = this.props;

        this.setState({formIsSaving: true});

        return this.props.createInteraction(interactionType, nestedData)
            .then(() => {
                this.setState({
                    formIsSaving: false,
                    formIsUpdated: false,
                    formHasSaved: true,
                })
            })
            .then(() => {
                this.props.refresh();
                setTimeout(this.props.onClose, 1000)
            })
            .catch(err => console.err);
    }

    getLocaleFields() {
        let fields = [];
        const locales = this.getValues().locales || {};
        // also merge in newly added locales from state
        const allLocales = [...Object.keys(locales), ...this.state.locales];
        const uniqueLocales = _unique(allLocales);

        // for each locale, add a set of fields:
        // product url at minimum, and optionally image, and 'add a field' field
        uniqueLocales.forEach(locale => {
            const localeFields = [
                {
                    type: 'text',
                    name: `locales.${locale}.product_url`,
                    title: `${locale} Product URL`,
                    options: { useBorderBottom: true }
                },
            ];
            fields = fields.concat(localeFields);
        });

        // Add an 'Add Locale' field
        fields.push({
            type: 'text',
            name: '_add_locale',
            title: 'Add Locale',
        });

        // Add the 'Add Locale' button
        fields.push({
            type: 'button',
            name: 'do_add_locale',
            title: 'Add Locale',
            options: {
                hideTitle: true,
                classes: ['v3', 'btn-secondary', 'small'],
                onClick: () => {
                    this.setState({
                        locales: [ ...this.state.locales, this.state._add_locale ],
                        _add_locale: '',
                    });
                }
            }
        });

        return fields;
    }

    getAdvancedFields() {
        return [
            {
                type: 'text',
                name: 'brand_name',
                title: 'Brand',
            },
            {
                type: 'text',
                name: 'monetary_value',
                title: 'Value',
                placeholder: 'eg: 123.20',
                help: 'Item price without dollar signs.'
            },
            {
                type: 'text',
                name: 'size',
                title: 'Size',
            },
            {
                type: 'text',
                name: 'color',
                title: 'Color',
            },
            {
                type: 'select',
                name: 'gender',
                title: 'Gender',
                choices: [
                    {text: 'Female', value: 'female'},
                    {text: 'Male', value: 'male'},
                    {text: 'Unisex', value: 'unisex'}
                ]
            },
            {
                type: 'text',
                name: 'category',
                title: 'Category',
            },
            {
                type: 'text',
                name: 'subcategory',
                title: 'Sub-category',
            },
        ];

    }
    getBasicFields() {
        return [
            {
                type: 'text',
                name: 'name',
                title: 'Product Name*',
                help: 'This field is required.'
            },
            {
                type: 'textarea',
                name: 'description',
                title: 'Product Description',
            },
            {
                type: 'text',
                name: 'sku',
                title: 'SKU*',
            },
            {
                type: 'text',
                name: 'product_url',
                title: 'Product URL',
            },
            {
                type: 'image',
                name: 'image',
                title: 'Image'
            },
         ];
    }

    getValues() {
        const form = this.props.form || {};
        form._add_locale = this.state._add_locale;
        return form;
    }

    render() {
        return <>
            <GenericSettingsForm
                spec={[
                    {
                        title: 'Basics',
                        key: 'basics',
                        fields: this.getBasicFields()
                    },
                    {
                        title: 'Advanced',
                        key: 'advanced',
                        fields: this.getAdvancedFields()
                    },
                    {
                        title: 'Locales',
                        key: 'locales',
                        fields: this.getLocaleFields()
                    },

                ]}
                onSave={this.handleSave.bind(this)}
                values={this.getValues()}
                onFieldChange={(key, value) => {
                    // Only if the key is _add_locale, update the state
                    if (key === '_add_locale') {
                        this.setState({[key]: value});
                    }
                }}
            />
        </>
    }
}

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (type, ctx) => dispatch(createChannelInteraction(type, ctx)),
    };
};

const ConnectedManageProduct = connect(mapStateToProps, mapDispatchToProps)(ManageProduct);
export default ConnectedManageProduct;