import React, {useEffect} from 'react';
import DashboardBriefs from "../../../MAC/Creator/DashboardBriefs";
import DashboardCommunity from "../../../MAC/Creator/DashboardCommunity";
import ProfileStripe from "../../../MAC/Creator/ProfileStripe";
import {connect} from "react-redux";
import {getBadges} from "../../../../selectors/badges";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../../../actions/auth";
import {fetchPresence} from "../../../../actions/presence";
import {fetchBadges} from "../../../../actions";

function MACCreatorCommunityPage() {

    return (
        <div className="mac-wrapper">
            <div className="dashboard">

                <div className="community-page-wrapper">

                    <div className="community-header">
                    </div>

                    <div className="leaderboard-wrapper">
                    </div>

                    <DashboardCommunity />
                </div>


            </div>
        </div>
    )
}

export default connect(
    function (state, props) {
        return { };
    },
    function (dispatch) {
        return bindActionCreators({
            fetchAuthenticated: fetchAuthenticated,
            fetchPresence: fetchPresence,
            fetchBadges: fetchBadges,
        }, dispatch);
    }
)(MACCreatorCommunityPage);