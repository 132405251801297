import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchUserActivationsIfNeeded} from "../../../../actions/index";
import {DEFAULT_USER_STATE} from "../../../../reducers/users";
import ContributorToDoRow from "./ContributorToDoRow";
import {fetchCampaign, fetchCampaignsIfNeeded} from "../../../../actions/campaigns";
import _find from 'lodash/find';
import Alert from "../../../Common/Alert";

class ParticipationPane extends Component {

    componentDidMount() {
        this.props.fetchActivations();
        this.props.fetchCampaigns();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId) {
            this.props.fetchActivations();
        }
    }

    renderRows() {
        const {activations, campaigns} = this.props;

        return activations.map(a => {
            const campaign = _find(campaigns, {id: a.campaign_id} || {});
            const campaignImage = (campaign || {}).image;
            const campaignName = (campaign || {}).name || <i className="v3 icon spinner"></i>;
            const authLink = 'https://' + window.location.hostname + '/contribute/do/page/campaign/activation?hash=' + (a || {}).auth_hash;
            const campaignLink = <><span>Campaign: </span><a style={{fontWeight: 700}} href={authLink}>{campaignName}</a></>;

            return <ContributorToDoRow
                title={"Status: " + (a.status.human_status || 'Loading...')}
                desc={campaignLink}
                icon={<div className="v3 avatar square" style={{backgroundImage: `url(${campaignImage})`}} />}
            />
        })
    }

    render() {
        const alert = <Alert classes={['info']} content={<span>Loading campaigns...</span>} style={{marginTop: 10}}/>;
        return (
            <>
                {!this.props.activations.length ? alert : null}
                <div className="contributor-participation-pane">
                    {this.renderRows()}
                </div>
            </>
        )
    }

}

const mapStateToProps = (state, props) => {
    const {userId} = props;
    const {activationsById, campaignsById} = state;
    const user = state.usersById[userId] || DEFAULT_USER_STATE;
    const activations = user.activationIds.map(aid => activationsById[aid]);

    return {
        activations,
        campaigns: campaignsById.items,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    const {userId} = props;

    return {
        fetchActivations: () => dispatch(fetchUserActivationsIfNeeded(userId)),
        fetchCampaigns: () => dispatch(fetchCampaignsIfNeeded()),
    }
};

const ConnectedParticipationPane = connect(mapStateToProps, mapDispatchToProps)(ParticipationPane);

export default ConnectedParticipationPane;
