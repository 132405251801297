/**
 * Created by bkroger on 5/18/18.
 */

import React, {Component} from 'react';
import Alert from '../../../Common/Alert';

class Canceled extends Component {

    render() {

        return (
            <div className="influencer-page-template activation-canceled">
                <div className="widget">
                    <div className="content padded">

                        <h4 className="v3 bold action-pane-title">Activation Canceled</h4>

                        <Alert
                            classes={['info']}
                            content={"Your activation for this campaign has been canceled."}
                            style={{marginBottom: 20}}
                        />

                        <p className={'v3 light h7 pane-info'}>
                            Your activation has been canceled and you will not be able to continue completing requirements for this campaign. Please check your email for details on the cancellation. If you think this occurred in error, please use the 'Have an Issue?' link at the top of the page.
                        </p>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default Canceled;