import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableWrapper from '../Table/Wrapper';
import Button from '../Common/Form/Button';
import DraftToolbox from '../Activation/DraftToolbox';
import LinkCell from '../Table/Cell/Link';
import BasicCell from '../Table/Cell/Basic';
import _find from 'lodash/find';
import CreateDraftToolbox from './CreateDraftToolbox';

const getDraftStatus = draft => {
    switch (draft.draft_status) {
        case 'accepted':
            return 'Accepted';
            break;
        case 'request-revision':
            return 'Revisions Required';
            break;
        case 'drafting':
        default:
            return 'Open';
    }
};

export default class DraftsTable extends Component {

    static defaultProps = {
        drafts: [],
    };

    static propTypes = {
        drafts: PropTypes.array,
        activation: PropTypes.object.isRequired,
        onSaveDraft: PropTypes.func,
        onCreateComment: PropTypes.func,
        createInteraction: PropTypes.func,
        translate: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            showCreate: false,
            showDraft: null,
        };
        this.toggleState = this.toggleState.bind(this);
    };

    _t(k, v) {
        if (this.props.translate) {
            return this.props.translate(k, v);
        }
        return v;
    }

    toggleState(key) {
        this.setState({
            [key]: !this.state[key]
        })
    }

    getDraftById(id) {
        return _find(this.props.drafts, {source_id: id});
    }


    getColumns() {
        return [
            {
                key: 'title',
                title: this._t('campaign.drafts.table.title', 'Title'),
                width: 400,
                default: true,
                sortable: false,
                cell: (row, column) => <LinkCell
                    row={row}
                    column={column}
                    value={row.item.title}
                    onClick={() => this.setState({showDraft: row.item.source_id})}
                />
            },
            {
                key: 'status',
                title: this._t('campaign.drafts.table.status', 'Status'),
                width: 180,
                default: true,
                sortable: false,
                cell: (row, column) => <BasicCell
                    row={row}
                    column={column}
                    value={getDraftStatus(row.item)}
                />
            },
            {
                key: 'comments',
                title: this._t('campaign.drafts.table.comments', 'Comments'),
                width: 180,
                default: true,
                sortable: false,
                cell: (row, column) => <LinkCell
                    row={row}
                    column={column}
                    value={`${row.item.comments.length} ${this._t('campaign.drafts.table.comments', 'Comments')}`}
                    onClick={() => this.setState({showDraft: row.item.source_id})}
                />
            },

        ];
    }

    getButtons() {
        return [
            <Button
                classes={['btn-primary', (this.state.showCreate ? 'active' : '')]}
                content={'Create Draft'}
                onClick={this.toggleState.bind(this, 'showCreate')}
            />
        ]

    }

    getPopups() {
        return [

            this.state.showCreate
                ? <CreateDraftToolbox

                    addlClasses={"toolbox-fixed toolbox-lg"}
                    onClose={this.toggleState.bind(this, 'showCreate')}
                    onSave={() => {
                        if (this.props.onSaveDraft) {
                            this.props.onSaveDraft();
                        }
                        setTimeout(() => this.setState({showCreate: false}), 1000);
                    }}
                    toolboxTitle={this._t('campaign.drafts.create.title', 'Create a New Draft')}
                    activation={this.props.activation}
                    createInteraction={this.props.createInteraction}
                    translate={this.props.translate}

                />
                : null,

            this.state.showDraft
                ? <DraftToolbox
                    addlClasses={"toolbox-fixed toolbox-lg"}
                    onClose={() => this.setState({showDraft: null})}
                    onSave={this.props.onSaveDraft}
                    onSaveComment={this.props.onCreateComment}
                    draftId={this.state.showDraft}
                    drafts={this.props.drafts}
                    showComments={true}
                    toolboxTitle={this._t('campaign.drafts.edit.title', 'Edit Draft')}
                    activation={this.props.activation}
                    createInteraction={this.props.createInteraction}
                    showApprovalTool={false}
                    translate={this.props.translate}
                />
                : null
        ];

    }

    render() {
        return (
            <TableWrapper
                title={this._t('campaign.drafts.table.your_drafts', 'Your Drafts')}
                items={this.props.drafts}
                columns={this.getColumns()}
                buttons={this.getButtons()}
                popups={this.getPopups()}
                showPagination={false}
            />

        );
    }
}