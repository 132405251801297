/**
 * Created by visgotti on 9/13/17.
 */

import {
   QUERY_EMAILS,
    RECEIVE_EMAILS,
    CHANGE_EMAIL_FILTER,
    REMOVE_EMAIL_FILTER,
    SELECT_EMAIL,
    UNSELECT_EMAIL,
    SELECT_ALL_EMAILS,
    UNSELECT_ALL_EMAILS,
    RESET_EMAIL_QUERY
} from '../actions';

export const DEFAULT_EMAIL_FILTERS = {
    query: null,
    inbox: true,
    outbox: 0,
    campaign: '',
    label: '',
    my_teams_only: true,
    owned_users_only: false,
    include_archived: false,
    include_automated: false,
    page: 1,
    limit: 25,
    outbox: 0,
};

export const DEFAULT_EMAIL_QUERY = {
    isFetching: false,
    meta: {},
    selectedEmailIds: [],
    allEmailsSelected: false,
    queryId: 1,
    didInvalidate: false,
    lastUpdated: null,
    filters: {
        my_teams_only: true,
        outbox: 0,
        page: 1,
        limit: 25
    },
    emailIds: [],
};

export const DEFAULT_EMAIL_QUERY_STATE = {
    currentQueryId: 1,
    queries: {
        1: DEFAULT_EMAIL_QUERY
    }
};

export function emailQueries( state = DEFAULT_EMAIL_QUERY_STATE, action ) {

    const prevQueryState = (state.queries[action.queryId] || {...DEFAULT_EMAIL_QUERY, queryId: action.queryId});

    switch (action.type) {

        case RESET_EMAIL_QUERY:
            return {
                ...state,
                queries: {
                    ...state.queries,
                    [action.queryId]: {...DEFAULT_EMAIL_QUERY, queryId: action.queryId}
                }
            };

        case QUERY_EMAILS:
            state = {
                ...state,
                queries: {
                    ...state.queries,
                }
            };
            state.queries[action.queryId] = {
                ...prevQueryState,
                isFetching: true
            };
            return state;
            break;

        case RECEIVE_EMAILS:
            state = {
                ...state,
                queries: {
                    ...state.queries,
                }
            };

            state.queries[action.queryId] = {
                ...prevQueryState,
                isFetching: false,
                emailIds: action.emails.map(email => email.id),
                meta: action.meta,
                lastUpdated: action.receivedAt,
            };
            return state;
            break;

        case REMOVE_EMAIL_FILTER:
            state = { ...state };
            delete state.queries[action.queryId].filters[action.filter.key];
            return state;

        case CHANGE_EMAIL_FILTER:
            state = {
                ...state,
                queries: {
                    ...state.queries
                }
            };
            state.queries[action.queryId] = {
                ...prevQueryState,
                filters: {
                    ...prevQueryState.filters
                }
            };

            state.queries[action.queryId].filters[action.filter.key] = action.filter.value;

            return state;
            break;

        case SELECT_EMAIL:
            let alreadySelected = state.queries[action.queryId].selectedEmailIds.indexOf(action.emailId) > -1;
            state = {
                ...state,
                queries: {
                    ...state.queries
                }
            };
            state.queries[action.queryId] = {
                ...prevQueryState,
                selectedEmailIds: [...prevQueryState.selectedEmailIds]
            };

            !alreadySelected && state.queries[action.queryId].selectedEmailIds.push(action.emailId);

            return state;

        case UNSELECT_EMAIL:
            state = {
                ...state,
                queries: {
                    ...state.queries
                }
            };
            state.queries[action.queryId] = {
                ...prevQueryState,
                selectedEmailIds: prevQueryState.selectedEmailIds.filter((emailId) => emailId !== action.emailId)
            };

            return state

        case SELECT_ALL_EMAILS:
            state = {
                ...state,
                queries: {
                    ...state.queries
                }
            };
            state.queries[action.queryId] = {
                ...prevQueryState,
                allEmailsSelected: true
            };

            return state;

        case UNSELECT_ALL_EMAILS:
            state = {
                ...state,
                queries: {
                    ...state.queries
                }
            };
            state.queries[action.queryId] = {
                ...prevQueryState,
                allEmailsSelected: false
            };

            return state;

        default: return state;
    }
}
