/**
 * Created by bkanber on 8/8/17.
 */

import React, {Component} from 'react';

export default class UserBlogWidget extends Component {

    renderPreview() {
        const profile = this.props.user.profile;

        if (!profile || !profile.blog_url) {
            return null;
        }

        const url = profile.blog_url;

        if (profile.blog_screenshot) {
            return (
                <a href={url} target="_blank">
                    <img src={profile.blog_screenshot} />
                </a>
            )
        } else {
            return (
                <iframe src={url}></iframe>
            )
        }
    }

    render() {

        const profile = this.props.user.profile;
        if (!profile || !profile.blog_url) {
            return null;
        }
        const url = profile.blog_url;

        return (
            <div className="user-blog-widget widget v2 notopmargin">

                <div className="title">
                    <div className="left icon"><i className="fa fa-rss"></i></div>
                    <h2>
                        Blog: {profile.blog_name}&nbsp;<small><a className="dark" href={url} target="_blank">Visit Blog</a></small>
                    </h2>
                </div>

                <div className="content padded">
                    <div className="blog-preview-frame">
                        {this.renderPreview()}
                    </div>
                </div>

            </div>
        )
    }
}
