import * as ActionTypes from './types';
import {fetchAuthenticated} from "../utilities";

export const REQUEST_LEDGER = 'REQUEST_LEDGER';
export const RECEIVE_LEDGER = 'RECEIVE_LEDGER';
export const INVALIDATE_LEDGER = 'INVALIDATE_LEDGER';


/** @TODO Abstract this */
export const fetchBills = () => {
    return (dispatch) => {
        dispatch({type: ActionTypes.REQUEST_BILLS});
        return fetchAuthenticated(`/manage/api/bill`, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => dispatch({type: ActionTypes.RECEIVE_BILLS, data: json.data, meta: json.meta}));
    }
};


export function fetchLedgerIfNeeded() {
    return function (dispatch, getState) {
        if (shouldFetchLedger(getState())) {
            return dispatch(fetchLedger());
        } else {
            return null;
        }
    }
}

export function shouldFetchLedger(state) {
    if (state.ledger.isFetching) {
        return false;
    } else if (state.ledger.didInvalidate) {
        return true;
    } else if (state.ledger.lastUpdated) {
        return false;
    }
    return true;
}

export function receiveLedger(json) {
    return {
        type: RECEIVE_LEDGER,
        transactions: json.data,
        available: json.meta.available,
        balance: json.meta.balance,
        businessUnits: json.meta.businessUnits,
        businessUnitBalances: json.meta.businessUnitBalances,
        campaignBalances: json.meta.campaignBalances,
        receivedAt: new Date()
    };
}

export function fetchLedger() {
    return function (dispatch) {
        dispatch({type: REQUEST_LEDGER});
        const url = '/manage/api/credit';

        return fetchAuthenticated(url, {credentials: 'include',})
            .then(response => response.json())
            .then(json => {
                dispatch(receiveLedger(json));
            })
            .catch(err => {
                console.log("Error fetching credits ledger");
                console.log(err)
            });
    }
}
