import React from 'react';
import Table from "../../Table/Table";
import BasicCell from "../../Table/Cell/Basic";
import {formatNumber, makeCellKey} from "../../../utilities";
import NumberCell from "../../Table/Cell/Number";
import InfoIcon from "../../Common/InfoIcon";
import _find from 'lodash/find';
import _get from 'lodash/get';

function MyCampaignLeaderboard({activations, campaigns, translate}) {
    const getColumns = () => ([
        {
            key: 'name',
            title: translate('creator.achievements.leaderboard.campaign.title', 'Campaign'),
            width: 400,
            default: true,
            sortable: false,
            cell: (row, column) => {
                const campaign = _find(campaigns, {id: row.item.campaign_id}) || {};
                return <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={campaign.name || '???'} />
            }
        },
        {
            key: 'total_engagements',
            title: (<>
                {translate('creator.achievements.leaderboard.engagements.title', 'Engagements')}
                <InfoIcon title={translate('creator.achievements.leaderboard.engagements.info', "Total of likes, comments, shares.")} style={{marginLeft: 10}} />
            </>),
            width: 200,
            default: true,
            sortable: false,
            cell: (row, column) => {
                const value = _get(row.item, 'latest_stats.total_engagements', null);
                return <NumberCell row={row} column={column} key={makeCellKey(row, column)} value={value} formatter={formatNumber}/>
            }
        },
        {
            key: 'total_impressions',
            title: ( <>
                {translate('creator.achievements.leaderboard.impressions.title', 'Impressions')}
                <InfoIcon title={translate('creator.achievements.leaderboard.impressions.info', "Approximately how many people saw your content.")} style={{marginLeft: 10}} />
            </> ),
            width: 200,
            default: true,
            sortable: false,
            cell: (row, column) => {
                const value = _get(row.item, 'latest_stats.total_impressions', null);
                return <NumberCell row={row} column={column} key={makeCellKey(row, column)} value={value} formatter={formatNumber} />
            }
        },

    ])

    const getItems = () => activations || [];
    return (
        <div className="achievements-leaderboard-wrapper">
            <h2 className="red-indent">{translate('creator.achievements.leaderboard.title', 'Campaign Results')}</h2>
            <div className="godzilla-wrapper mac-transparent-table">
                <Table columns={getColumns()} items={getItems()} />
            </div>
        </div>
    );
}

export default MyCampaignLeaderboard;