/**
 * Created by bkanber on 6/8/17.
 */
import {combineReducers} from 'redux';

import {activationsById, activationStatsById, campaigns, campaignsById, campaignStatsById} from './campaigns';
import {badgesById} from './badges';
import {usersById} from './users';
import {workflowsById} from './workflows';
import {userQueries} from './userQueries';
import {emailQueries} from './emailQueries';
import {emailsById} from './emailsById';
import {networksById} from './networks';
import {activationPostRecordsById, postsById} from './postsById';
import {postQueries} from './postQueries';
import {socialListenersById, socialPostsById, socialsById} from './socials';
import {notificationsById} from "./notificationsById";
import {ledger, ledgerv3} from "./ledger";
import {emailTemplatesByKey, pageTemplatesById} from "./templates";
import {lightbox} from "./lightbox";
import {teamsById} from "./teams";
import {presence} from "./presence";
import {candidates, talentspiderCandidatesById, talentspidersById} from "./talentspiders";
import {blogsById} from "./blogs";
import {campaignRequirementsById} from "./requirements";
import {activations} from "./activations";
import {products} from "./products";
import {images} from "./images";
import {videos} from './videos';
import {sections} from './sections';
import {auth} from "./auth";
import {posts} from './posts';
import {emails} from './emails';
import {listEntries} from "./listEntries";
import {i18n} from "./i18n";
import _omit from 'lodash/omit';

import {
    SELECT_USER, UNSELECT_USER,
    REQUEST_USER, RECEIVE_USER, INVALIDATE_USER,
    REQUEST_USER_PROFILE, RECEIVE_USER_PROFILE,
    REQUEST_USER_IMAGES, RECEIVE_USER_IMAGES, RECEIVE_POST_IMAGES,
    REQUEST_USER_ACTIVATIONS, RECEIVE_USER_ACTIVATIONS,
    REQUEST_USER_LISTS, RECEIVE_USER_LISTS,
    REQUEST_USER_ACTIVATION_STATS, RECEIVE_USER_ACTIVATION_STATS,
    REQUEST_LISTS, RECEIVE_LISTS, EDIT_LIST, RECEIVE_LIST, CREATE_LIST, CREATED_LIST,
    ADD_USERS_TO_LIST, ADDED_USERS_TO_LIST, REMOVE_USERS_FROM_LIST, REMOVED_USERS_FROM_LIST,
    RECEIVE_CAMPAIGN_IMAGES, RECEIVE_CAMPAIGN_ACTIVATIONS,
    PRESENCE_TOGGLE_NOTIFICATIONS,
    ADDED_LIST_TEAM, REMOVED_LIST_TEAM
} from '../actions';
import { CHANNEL_CREATED_INTERACTION } from '../actions/types';

function updateObject(oldObject, newValues) {
    // Encapsulate the idea of passing a new object as the first parameter
    // to Object.assign to ensure we correctly copy data instead of mutating
    return Object.assign({}, oldObject, newValues);
}

function selectedUsers(state = [], action) {
    let newState = state.slice(0);
    switch (action.type) {

        case SELECT_USER:
            if (newState.indexOf(action.userId) === -1) {
                newState.push(action.userId);
            }
            return newState;
            break;


        case UNSELECT_USER:
            let index = newState.indexOf(action.userId);
            if (index > -1) {
                newState.splice(index, 1);
            }
            return newState;

        default:
            return state;
    }
}

function imagesById(state = {}, action) {
    switch (action.type) {
        case RECEIVE_POST_IMAGES: {
            console.log('te image from action was', action.images);
            let newState = Object.assign({}, state);
            for (let image of action.images) {
                newState[image.id] = image;
            }
            return newState;
            break;
        }
        case RECEIVE_USER_IMAGES:
        case RECEIVE_CAMPAIGN_IMAGES: {
            let newState = Object.assign({}, state);
            for (let image of action.images) {
                newState[image.id] = image;
            }
            return newState;
            break;
        }
        default:
            return state;
    }
}

function listsById(state = {
    isFetching: false,
    isCreating: false,
    didInvalidate: false,
    lastUpdated: null,
    lists: {}
}, action) {

    switch (action.type) {

        case ADDED_LIST_TEAM:
        case REMOVED_LIST_TEAM:
        {
            let newState = updateObject(state, {});
            newState.lists[action.listId] = updateObject(newState.lists[action.listId], {teams: action.list.teams});
            return newState;
        }

        case ADDED_USERS_TO_LIST:
        case REMOVED_USERS_FROM_LIST:
        {
            let newState = updateObject(state, {});
            newState.lists[action.listId] = updateObject(newState.lists[action.listId], {_isUpdating: false});
            return newState;
        }

        case ADD_USERS_TO_LIST:
        case REMOVE_USERS_FROM_LIST:
        {
            let newState = updateObject(state, {});
            newState.lists[action.listId] = updateObject(newState.lists[action.listId], {_isUpdating: true});
            return newState;
        }

        case CREATED_LIST: {
            let newState = updateObject(state, {isCreating: false});
            newState.lists[action.listId] = updateObject(action.list, {_isUpdating: false});
            return newState;
        }

        case CREATE_LIST: {
            return updateObject(state, {isCreating: true});
        }

        case EDIT_LIST: {
            let newState = updateObject(state, {});
            newState.lists[action.listId] = updateObject(newState.lists[action.listId], {_isUpdating: true});
            return newState;
        }

        case RECEIVE_LIST: {
            let newState = updateObject(state, {});
            newState.lists[action.listId] = updateObject(action.list, {_isUpdating: false});
            return newState;
        }
        case RECEIVE_LISTS:
            let newState = {
                isFetching: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt,
                lists: {
                    ...state.lists
                }
            };

            for (let list of action.lists) {
                newState.lists[list.id] = list;
            }

            return newState;

        case REQUEST_LISTS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
            break;


        default:
            return state;
    }
}

const rootReducer = combineReducers({
    usersById,
    imagesById,
    postsById,
    postQueries,
    campaignsById,
    campaignStatsById,
    activationsById,
    activationStatsById,
    listsById,
    selectedUsers,
    badgesById,
    userQueries,
    emailQueries,
    emailsById,
    socialsById,
    socialPostsById,
    socialListenersById,
    networksById,
    notificationsById,
    workflowsById,
    ledger,
    emailTemplatesByKey,
    pageTemplatesById,
    activationPostRecordsById,
    lightbox,
    teamsById,
    presence,
    talentspidersById,
    talentspiderCandidatesById,
    blogsById,
    campaignRequirementsById,
    ledgerv3,
    activations,
    campaigns,
    products,
    images,
    posts,
    videos,
    sections,
    candidates,
    auth,
    emails,
    listEntries,
    i18n
});

export default rootReducer;