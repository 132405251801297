import React from "react";

function PortfolioByline({item, showAge=true}) {
    const profile = item.profile;
    const facets = item.profile.facets || {};
    let byline = [];

    if (profile.location) {
        byline.push(profile.location);
    }

    if (facets.Category) {
        byline.push(`${facets.Category}`);
    }

    if (showAge) {
        if (profile.metadata && profile.metadata.actual_age) {
            byline.push(`Age: ${profile.metadata.actual_age}`);
        } else if (facets.Age) {
            byline.push(`Age: ${facets.Age}`);
        }
    }

    return (
        <div className="byline">
            {byline.map((text, index) => <div key={index} className="byline-item">{text}</div>)}
        </div>
    );
}


export default PortfolioByline;