/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import C3Chart from 'react-c3js';
import d3 from 'd3';
import {c3ColorPattern} from '../../utilities';
import PropTypes from 'prop-types';


export default class GenericCategoryBarChart extends Component {

    makeCategoryNames() {
        return Object.keys(this.props.data);
    }

    renderChart() {

        const total = Object.values(this.props.data).reduce((sum, val) => sum+parseInt(val, 10), 0);

        const data = {
            x: 'categories',
            columns: [
                [this.props.seriesName].concat( Object.values(this.props.data) ),
                ['categories'].concat(this.makeCategoryNames())
            ],
            type: 'bar',
            unload: true
        };

        let axis = {
            x: {
                type: 'category',
            }
        };

        if (this.props.yAxisTickFormatter) {
            axis.y = {
                tick: {
                    format: this.props.yAxisTickFormatter
                }
            };
        }

        return (

                    <C3Chart
                        data={data}
                        axis={axis}
                        size={this.props.size}
                        legend={this.props.legend}
                        color={c3ColorPattern}
                        tooltip={this.props.tooltip}
                        unloadBeforeLoad={true}
                    />

        );

    }

    render() {

        return this.renderChart();

    }
}

GenericCategoryBarChart.defaultProps = {
    seriesName: null,
    data: {},
    size: null,
    legend: {show: true},
    tooltip: {
        format: {
            value: (value, ratio, id) => {
                const format = d3.format(',');
                return format(value);
            }
        }
    },
    yAxisTickFormatter: d3.format(',')
};

GenericCategoryBarChart.propTypes = {
    data: PropTypes.object.isRequired,
    seriesName: PropTypes.string.isRequired,
    size: PropTypes.object,
    legend: PropTypes.object,
    tooltip: PropTypes.object,
    yAxisTickFormatter: PropTypes.func
};