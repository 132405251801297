/**
 * Created by bkroger on 5/18/18.
 */

import React, {Component} from 'react';
import Alert from '../../../Common/Alert';

class UserDeclinedInvitation extends Component {

    render() {

        return (
            <div className="widget">
                <div className="content padded">

                    <h4 className="v3 bold action-pane-title">Invitation Declined</h4>

                    <Alert
                        classes={['info']}
                        style={{marginTop: 20, marginBottom: 20}}
                        content={"We're sorry you won't be joining us for this program, but we appreciate your consideration. Keep up the great work!"}
                    />


                    <div style={{background: '#F6F6F6', padding: 20}} className="v3 light">
                        If you declined your invitation accidentally, or would like us to remove you from future consideration, please use the 'Have an Issue?' button at the top of the page.
                    </div>

                </div>
            </div>
        )
    }
}

export default UserDeclinedInvitation;