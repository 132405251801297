import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {PieChart, Pie, Cell, text, ResponsiveContainer, Legend, Tooltip, Sector, Label} from 'recharts';
import {c3ColorPattern, formatNumber} from "../../utilities";
import {rechartsDonutChartLabels} from "../../utilities/chart.js";

const renderActiveShape = (props) => {
    const {cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill} = props;

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius - 1}
                outerRadius={outerRadius + 6}
                fill={fill}
            />
        </g>
    );
};

function CustomLabel({viewBox, value1, value2}){
    const {cx, cy} = viewBox;
    const pos = value2 ? '-0.25em' : '0.25em';
    return (
        <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle">
            { value1 &&
                <tspan x={cx} dy={pos} className="donut-chart-title">{value1}</tspan>
            }
            { value2 &&
                <tspan x={cx} dy="1.5em" className="donut-chart-subtitle">{value2}</tspan>
            }
        </text>
    )
}

export default class RechartsDonutChart extends Component {

    static defaultProps = {
        size: 100,
        width: '100%',
        height: 320,
        outerRadius: '95%',
        innerRadius: '55%',
        style: {},
        colors: c3ColorPattern.pattern,
        labelLine: false,
        showTooltips: true,
        showLegend: true,
        labelFormatter: (value) => `${(value * 100).toFixed(0)}%`,
        labelPercent: true,
        tooltipFormatter: (value) => formatNumber(value)
    };

    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.number,
        })),
        size: PropTypes.number,
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        height: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        outerRadius: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        innerRadius: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        style: PropTypes.object,
        colors: PropTypes.array,
        label: PropTypes.any,
        labelLine: PropTypes.any,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        showTooltips: PropTypes.bool,
        showLegend: PropTypes.bool,
        labelFormatter: PropTypes.func,
        labelPercent: PropTypes.bool,
        tooltipFormatter: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: null,
            activeData: {}
        };

        this.renderTooltip = this.renderTooltip.bind(this);
        this.onPieLeave = this.onPieLeave.bind(this);
        this.onLegendEnter = this.onLegendEnter.bind(this);
        this.onLegendLeave = this.onLegendLeave.bind(this);
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
            activeData: data.payload
        });
    };

    onPieLeave() {
        this.setState({
            activeIndex: null,
            activeData: {}
        });
    }

    onLegendEnter = (data, index) => {
        const newData = {
            fill: data.color,
            name: data.id,
            value: data.val,
        };
        this.setState({
            activeIndex: index,
            activeData: newData,
        });
    };

    onLegendLeave() {
        this.setState({
            activeIndex: null,
            activeData: {}
        });
    }

    getTotal() {
        let total = 0;
        this.props.data.map(item => {
            total += item.value;
        });
        return total;
    }

    renderTooltip() {
        const {fill, name, value} = this.state.activeData;
        const tooltipVal = this.props.tooltipFormatter(value);
        const total = this.getTotal();
        return (
            <div className="custom-tooltip">
                <div className="tooltip-left">
                    <div className="cell color-box" style={{backgroundColor: fill}}></div>
                    <span className="cell stat-name">{name}</span>
                </div>
                <div className="tooltip-right">
                    <span className="cell stat-value">{`${tooltipVal} (${((value/total) * 100).toFixed(1)}%)`}</span>
                </div>
            </div>
        )
    }

    getCellOpacity(index) {
        if (index !== this.state.activeIndex && this.state.activeIndex !== null) {
            return 0.35;
        } else {
            return 1.0;
        }
    }

    render() {

        const {colors, labelFormatter, labelPercent} = this.props;
        const startAngle = 90;
        const endAngle = startAngle + 360;
        const label = rechartsDonutChartLabels(labelFormatter, labelPercent);

        return (

            <ResponsiveContainer width={this.props.width} height={this.props.height}>

                <PieChart>

                    <Pie
                        activeIndex={this.state.activeIndex}
                        activeShape={renderActiveShape}
                        isAnimationActive={false}
                        data={this.props.data}
                        dataKey={'value'}
                        outerRadius={this.props.outerRadius}
                        innerRadius={this.props.innerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        label={label}
                        labelLine={this.props.labelLine}
                        onMouseEnter={this.onPieEnter}
                        onMouseLeave={this.onPieLeave}
                    >
                        {
                            this.props.data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index]} opacity={this.getCellOpacity(index)}/>
                            ))
                        }
                        <Label
                            position="center"
                            content={<CustomLabel value1={this.props.title} value2={this.props.subtitle}/>}
                        />
                    </Pie>

                    {this.props.showLegend &&
                        <Legend
                            payload={
                                this.props.data.map(
                                    (item, index) => ({
                                        id: item.name,
                                        type: "square",
                                        value: item.name,
                                        color: colors[index],
                                        val: item.value,
                                    })
                                )
                            }
                            onMouseEnter={this.onLegendEnter}
                            onMouseLeave={this.onLegendLeave}
                            iconSize={10}
                        />
                    }

                    {this.props.showTooltips &&
                        <Tooltip
                            content={this.renderTooltip}
                            contentStyle={{padding: 0}}
                            itemStyle={{padding: 0}}
                        />
                    }

                </PieChart>

            </ResponsiveContainer>

        );
    }
}
