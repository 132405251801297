import React from 'react';
import {DropTarget} from "react-dnd";

function PaginatorDropTarget({connectDropTarget, isOver, canDrop, direction}) {
    const style = (canDrop)
        ? {
            border: '2px dashed #aaa',
            height: 80,
            margin: 10,
            padding: 30,
            textAlign: 'center'
        }
        : {};
    if (isOver) {
        style.fontWeight = 'bold';
    }
    const text = direction === 1 ? "Move to the next page." : "Move to the previous page.";
    return connectDropTarget(
        <div className="paginator-drop-target" style={style}>
            {canDrop ? text : null}
        </div>
    );
}

const dropSpec = {
    hover(props, monitor, component) {
    },
    drop(props, monitor, component) {
        return {
            itemId: monitor.getItem().id,
            startIndex: monitor.getItem().index,
            targetPaginate: props.direction
        };
    }
};

function dropCollect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    };
}

export default DropTarget('table-row', dropSpec, dropCollect)(PaginatorDropTarget);
