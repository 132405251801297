import React, {Component} from 'react';
import C3Chart from 'react-c3js';
import {c3ColorPattern} from '../../../utilities';

export default class PostRatingsChart extends Component {

    renderPostRatingsChart() {

        let postRatingCounts = {
            '5 Stars': 0,
            '4 Stars': 0,
            '3 Stars': 0,
            '2 Stars': 0,
            '1 Stars': 0,
            'Unrated': 0
        };

        for (let post of this.props.posts) {
            let rating = post.rating;
            if (!rating) rating = 'Unrated';
            else rating = rating + ' Stars';

            if (typeof postRatingCounts[rating] === 'undefined') {
                postRatingCounts[rating] = 0;
            }
            postRatingCounts[rating]++;
        }

        const data = {
            json: [postRatingCounts],
            keys: {
                value: Object.keys(postRatingCounts)
            },
            type: 'bar'
        };

        return (
            <div className="widget" style={{width: '350px', height: '350px', marginTop: 0, flex: 'none'}}>
                <div className="title">
                    <div className="icon left"><i className="fa fa-bar-chart-o"></i></div>
                    <h2>
                        Post Ratings
                        <span className="badge badge-darkgrey">
                        {this.props.posts.length} posts
                        </span>
                    </h2>
                </div>
                <div className="content">

                    <C3Chart
                        data={data}
                        size={{width: 300, height: 280}}
                        color={c3ColorPattern}
                        axis={{x: {show: false}}}
                        transition={{duration: 0}}
                    />

                </div>
            </div>
        );

    }

    render() {

        return this.renderPostRatingsChart();

    }
}
