export const mapProfileContentToSimplePostCard = (content, profile) => {
    return (content || []).map(postInterface => {
        return {
            title: postInterface.title,
            slug: postInterface.title,
            written_at: postInterface.source_created_at,
            images: postInterface.image_urls,
            engagements: null,
            user: {
                name: profile.name,
                verified_reach: profile.reach,
            },
            type: 'post',
            permalink: postInterface.permalink
        };

    });
};
