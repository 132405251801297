export const getQuery = (state, props, defaultQuery = {}) => {
    const propsQuery = props.query || {};
    const combinedDefault = {...defaultQuery, ...propsQuery};
    let queryId = 'default';
    if (combinedDefault.id) {
        queryId = combinedDefault.id;
    }
    return state.queries[queryId] || combinedDefault;
};

export const getCurrentPageItems = (state, props, defaultQuery = {}) => {
    const query = getQuery(state, props, defaultQuery);
    const pageNumber = query.page;
    const pages = query.pages || {};
    const currentPage = pages[pageNumber] || {};
    const ids = currentPage.ids || [];
    const items = ids.map(id => state.items[id]);
    const filtered = items.filter(item => !!item);
    return filtered;
}

/**
 * If no items yet for current page, return last page's items
 * @param state
 * @param props
 * @param defaultQuery
 * @returns {Array.<*>}
 */
export const getPageItemsWithFallback = (state, props, defaultQuery = {}) => {
    const query = getQuery(state, props, defaultQuery);
    const pages = query.pages || {};

    let pageNumber = query.page;
    let currentPage = pages[pageNumber] || {};

    if (query.last_page && (typeof currentPage === 'undefined' || typeof currentPage.ids === 'undefined')) {
        pageNumber = query.last_page;
        currentPage = pages[pageNumber] || {};
    }

    const ids = currentPage.ids || [];
    const items = ids.map(id => state.items[id]);
    const filtered = items.filter(item => !!item);
    return filtered;
}

export const getAvailableMetadata = (query) => {
    const pages = query.pages || {};
    let page = query.page || 1;
    let meta = (pages[page] || {}).meta;

    if (query.last_page || typeof meta === 'undefined') {
        page = query.last_page;
        meta = (pages[page] || {}).meta;
    }

    return meta;
};