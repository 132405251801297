import React from 'react';
import Toolbox from "../Common/Toolbox";
import {getTeams} from "../../selectors/teams";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {createChannelInteraction} from "../../actions/channel";
import Checkbox from "../Common/Form/Checkbox";
import Button from "../Common/Form/Button";

function AddUsersToTeamToolbox({action, userIds, onClose, onComplete, createChannelInteraction, teams}) {
    const [isWorking, setIsWorking] = React.useState(false);
    const [didSubmit, setDidSubmit] = React.useState(false);
    const [selected, setSelected] = React.useState({});
    const title = action === 'add' ? 'Add Users to Team' : 'Remove Users from Team';
    const usersCount = userIds.length;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsWorking(true);
        const teamIds = getTeamIds();
        const resp = await createChannelInteraction('AddUsersToTeam', {
            user_ids: userIds,
            team_ids: teamIds,
            action: action
        });
        setIsWorking(false);
        setDidSubmit(true);
        if (onComplete) {
            onComplete();
        }
    };

    const renderTeam = team => (
        <div className="fake-li" key={team.id} style={{padding: '10px 15px'}}>
            <Checkbox
                onClick={() => setSelected({...selected, [team.id]: !selected[team.id]})}
                checked={!!selected[team.id]}
                label={team.name}
            />
        </div>
    );

    const getTeamIds = () => {
        let out = [];
        for (const tid in selected) {
            if (selected[tid] === true) {
                out.push(tid);
            }
        }
        return out;
    };

    const renderButton = () => {


        const selectedTeamIds = getTeamIds();
        const isEnabled = !isWorking && !didSubmit && selectedTeamIds.length > 0;
        let btnTitle = action === 'add'
            ? `Add ${usersCount} Users to Teams`
            : `Remove ${usersCount} from Teams`;
        if (isWorking) {
            btnTitle = 'Please wait...';
        }
        if (didSubmit) {
            btnTitle = action === 'add' ? 'Users Added!' : 'Users Removed!'
        }

        return (
            <Button
                content={btnTitle}
                classes={[isEnabled ? 'btn-primary' : 'disabled', 'fullwidth', 'v3 medium']}
                onClick={isEnabled ? handleSubmit : undefined}
            />
        );
    };

    return (

        <Toolbox
            addlClasses={'toolbox-fixed toolbox-sm'}
            title={title}
            onClose={onClose}
            content={(
                <div>
                    <div className="fake-table" style={{overflow: 'auto', maxHeight: 400}}>
                        {teams.map(renderTeam)}
                    </div>
                    <div className="padded" style={{padding: 15}}>
                        {renderButton()}
                    </div>
                </div>

            )}
        />

    );
}

const mapStateToProps = (state, props) => ({
    teams: getTeams(state, props)
});

const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    createChannelInteraction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddUsersToTeamToolbox);