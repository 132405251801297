import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class BasicCell extends PureComponent {

    static defaultProps = {
        style: {},
        classes: [],
    };

    static propTypes = {
        value: PropTypes.any,
        row: PropTypes.object.isRequired,
        column: PropTypes.object.isRequired,
        style: PropTypes.object,
        onClick: PropTypes.func,
        classes: PropTypes.array,
    };

    getValue() {
        const {value, row} = this.props;
        if (typeof value === 'function') {
            return value(row);
        }
        return value;
    }

    getStyle() {

        const {column} = this.props;
        const style = {...this.props.style};

        if (column.width) {
            style.width = column.width;
            style.minWidth = column.width;
            style.maxWidth = column.width;
        }

        if (column.sticky) {
            if (column.stickyLeft) {
                style.left = column.stickyLeft;
            } else {
                style.left = 0;
            }
        }

        return style;

    }

    getClassName() {
        const {column} = this.props;
        let classes = ['godzilla-table-cell', ...this.props.classes];

        if (column.sticky) {
            classes.push('stick-to-left');
        }

        return classes.join(' ');
    }

    render() {
        return (
            <div
                className={this.getClassName()}
                style={this.getStyle()}
                onClick={this.props.onClick}
            >
                {this.getValue()}
            </div>
        );
    }
}


