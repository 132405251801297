import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SectionalToolbox from "../Common/SectionalToolbox";
import Toolbox from "../Common/Toolbox";
import CommentOnDraftForm from './CommentOnDraftForm';
import EditDraftForm from './EditDraftForm';
import NavStacked from "../Common/NavStacked";
import {limitChars} from "../../utilities";
import _find from 'lodash/find';
import Button from '../Common/Form/Button';
import Comment from '../Common/Comment';
import {connect} from 'react-redux';
import {hasAclRole} from "../../selectors/presence";
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";

class DraftToolbox extends Component {

    static defaultProps = {
        drafts: [],
        style: {},
        toolboxTitle: 'Edit Draft',
        initialView: 'edit',
        showComments: false,
        showApprovalTool: false,
    };

    static propTypes = {
        onClose: PropTypes.func,
        onSave: PropTypes.func,
        onSaveComment: PropTypes.func,
        toolboxTitle: PropTypes.string,
        drafts: PropTypes.arrayOf(PropTypes.object),
        draftId: PropTypes.string,
        style: PropTypes.object,
        initialView: PropTypes.oneOf(['edit', 'preview']),
        showComments: PropTypes.bool,
        activation: PropTypes.object,
        hasManageCampaignsRole: PropTypes.bool,
        showApprovalTool: PropTypes.bool,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
        addlClasses: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            draftId: (props.draft ? props.draft.source_id : ((props.drafts || [])[0] || {}).source_id || null),
            isAccepting: false,
            isDeleting: false,
            showDeleteAlert: false,
        };

        if (props.draftId) {
            this.state.draftId = props.draftId;
        }

        this.handleShowDeleteAlert = this.handleShowDeleteAlert.bind(this);
    }

    getCurrentDraft() {
        if (!this.state.draftId) {
            return null;
        }

        return _find(this.props.drafts, {source_id: this.state.draftId});
    }

    renderCommentForm() {

        return <CommentOnDraftForm
            activation={this.props.activation}
            draftId={this.state.draftId}
            usePadding={true}
            onSaved={this.props.onSaveComment}
            createInteraction={this.props.createInteraction}

        />;

    }

    renderDraftEditorContent() {

        return <EditDraftForm
            activation={this.props.activation}
            draft={this.getCurrentDraft()}
            usePadding={!this.props.showComments}
            onSaved={this.props.onSave}
            createInteraction={this.props.createInteraction}
        />
    }

    renderDraftPreview() {
        const draft = this.getCurrentDraft();
        return <div className={'draft-preview'}>
            <h3 className={'v3 light bottommargin'}>{draft.title}</h3>
            <div
                className={'draft-preview-content'}
                dangerouslySetInnerHTML={{__html: draft.content}}>
            </div>
        </div>
    }

    renderVotes() {
        if (!this.props.hasManageCampaignsRole || !this.props.showApprovalTool) {
            return null;
        }

        const draft = this.getCurrentDraft();
        const votes = [...((draft || {}).votes || [])].reverse();
        return votes.map((vote, voteIndex) => {
            const author = {
                ...vote.source_author,
                name: vote.source_author.name ? vote.source_author.name : 'Anonymous',
            };
            const actionDesc = vote.approve ? `<p>${author.name} voted to approve this draft.</p>` : `<p>${author.name} requested revisions:</p>`
            const moreInfo = vote.content ? vote.content : '';
            const content = `
                <div>
                    ${actionDesc}
                    ${moreInfo}
                </div>
            `;

            return (
                <Comment
                    key={vote.source_created_at}
                    comment={{
                        ...vote,
                        source_author: author,
                        content: content
                    }}
                />
            );
        });
    }

    renderComments() {

        const draft = this.getCurrentDraft();
        const comments = [...((draft || {}).comments || [])].reverse();

        return <div className="draft-comments">
            {comments.map(this.renderComment.bind(this))}
            {this.renderVotes()}
        </div>


    }

    renderComment(comment) {
        return <Comment comment={comment}/>
    }

    renderCommentsSection() {

        return <div className="draft-comments-wrapper">
            {this.renderCommentForm()}
            {this.renderComments()}
        </div>

    }

    renderCommentsToolbox() {
        return <Toolbox
            embedded={true}
            title={'Comments'}
            addlClasses={'draft-comments-toolbox'}
            content={this.renderCommentsSection()}
            onClose={this.props.showComments ? this.props.onClose : null}
        />
    }

    handleChangeDraftStatus(status) {
        const draft = this.getCurrentDraft();
        if (!draft) {
            return null;
        }
        const source_id = draft.source_id;
        const activation = this.props.activation;
        const payload = {source_id, status};
        this.setState({isAccepting: true});
        return this.props.createInteraction(activation.id, 'UpdateDraftStatus', payload)
            .then(() => this.props.fetchActivation(activation.id))
            .then(() => this.setState({isAccepting: false}));

    }
    handleMarkAccepted() {
        const draft = this.getCurrentDraft();
        if (!draft) {
            return null;
        }
        if (draft.draft_status === 'accepted') {
            return null;
        }
        return this.handleChangeDraftStatus('accepted');
    }

    handleDeleteDraft() {
        const draft = this.getCurrentDraft();
        if (!draft) {
            return null;
        }
        if (draft.draft_status === 'accepted') {
            return null;
        }
        const payload = {draft_id: draft.source_id};
        const activation = this.props.activation;
        this.setState({isDeleting: true});
        return this.props.createInteraction(activation.id, 'DeleteDraft', payload)
            .then(() => setTimeout(() => this.setState({isDeleted: true})))
            .then(() => this.props.onClose())
            .then(() => this.props.fetchActivation(activation.id))
            .then(() => this.setState({isDeleting: false, showDeleteAlert: false, isDeleted: false}));
    }

    handleShowDeleteAlert() {
        this.setState({
            showDeleteAlert: !this.state.showDeleteAlert
        })
    }

    renderDeleteButton() {
        let btnContent = '';

        if (this.state.showDeleteAlert && !this.state.isDeleting) btnContent =
            <div>
                Are you Sure?&nbsp;
                <a role="button" className="dark" onClick={this.handleDeleteDraft.bind(this)}>Yes</a>
                &nbsp;/&nbsp;
                <a role="button" className="dark" onClick={this.handleShowDeleteAlert}>No</a>
            </div>;

        else if (this.state.isDeleting) btnContent = 'Deleting Draft...';

        else btnContent = <a role="button" className="dark delete-draft-link" onClick={this.handleShowDeleteAlert}><i className="v3 icon trash"></i> Delete Draft</a>;

        return (
            <div style={{float: 'right', position: 'relative', top: 10}}>
                {btnContent}
            </div>
        )
    }

    renderApproveButton() {
        if (!this.props.hasManageCampaignsRole || !this.props.showApprovalTool) {
            return null;
        }

        const draft = this.getCurrentDraft();

        const style = {
        };

        let btnText = 'Accept Draft';
        let btnIcon = null;
        let btnClasses = ['v3'];

        if (this.state.isAccepting) {
            btnIcon = <i className="fa fa-spinner fa-spin" style={{marginRight: 5}} />;
            btnClasses.push('disabled');
        }

        if (draft.draft_status === 'accepted') {
            btnText = 'Draft Accepted';
            btnClasses.push('disabled');
            btnClasses.push('btn-secondary');
        } else {
            btnClasses.push('btn-primary');
        }

        let btnContent = <span>{btnIcon} {btnText}</span>;

        let btn = <Button
            content={btnContent}
            onClick={this.handleMarkAccepted.bind(this)}
            classes={btnClasses}
        />;


        return (
            <div className={'action-buttons-wrapper'} style={style}>
                <p className="v3 small help-block">Approve this draft. To request changes, use the comment form to the right.</p>
                {draft.draft_status === 'accepted' && (
                    <div style={{float: 'right', position: 'relative', top: 10}}>
                        <a role="button" className="dark delete-draft-link" onClick={() => {
                            this.handleChangeDraftStatus('drafting');
                        }}>Undo Approval</a>
                    </div>
                )}

                {btn}
                {draft.draft_status !== 'accepted' &&
                    this.renderDeleteButton()
                }
            </div>
        );
    }

    getSections() {
        return [
            (this.props.hasManageCampaignsRole && this.props.showApprovalTool) ? {
                key: 'approve',
                title: 'Approve',
                isExpanded: this.props.initialView === 'approve',
                content: this.renderApproveButton()
            } : null,
            {
                key: 'edit',
                title: 'Edit',
                isExpanded: this.props.initialView === 'edit',
                content: this.renderDraftEditorContent()
            },
            {
                key: 'preview',
                title: 'Preview',
                isExpanded: this.props.initialView === 'preview',
                content: this.renderDraftPreview()
            },
        ];
    }

    isDraftSelected(draft) {
        if (!this.state.draftId) {
            return false;
        }
        return this.state.draftId === draft.source_id;
    }

    getDraftNavIcon(draft) {
        switch (draft.draft_status) {

            case 'request-revision':
                return <i className={'v3 signal warning'}/>;

            case 'accepted':
                return <i className={'v3 signal success'}/>;

            case 'drafting':
            default:
                return <i className={'v3 signal neutral'}/>;
        }
    }

    renderLeftToolbox() {

        return <Toolbox
            embedded={true}
            title={this.props.toolboxTitle}
            addlClasses={'draft-main'}
            onClose={this.props.showComments ? null : this.props.onClose}
            content={

                <div className={'draft-main-inner'}>

                    <NavStacked
                        items={
                            this.props.drafts.map(draft => {
                                return {
                                    title: limitChars(draft.title, 15),
                                    onClick: () => this.setState({draftId: draft.source_id}),
                                    isActive: this.isDraftSelected(draft),
                                    rightIcon: this.getDraftNavIcon(draft),
                                }
                            })
                        }
                    />

                    <SectionalToolbox
                        addlClasses={'draft-preview-edit-toolbox'}
                        embedded={true}
                        sections={this.getSections()}
                    />

                </div>
            }
        />
    }

    renderBody() {
        return [
            this.renderLeftToolbox(),
            this.props.showComments ? this.renderCommentsToolbox() : null
        ];
    }

    render() {
        return (

            <Toolbox
                addlClasses={'draft-editor ' + (this.props.addlClasses || '')}
                style={this.props.style}
                content={this.renderBody()}
            />

        );
    }
}

const mapStateToProps = (state) => {
    return {
        hasManageCampaignsRole: hasAclRole('manage_campaigns', state),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        createInteraction: (
            props.createInteraction
            ||
            ((id, type, context) => dispatch(createActivationInteraction(id, type, context)))
        ),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };

};

const ConnectedDraftToolbox = connect(mapStateToProps, mapDispatchToProps)(DraftToolbox);

ConnectedDraftToolbox.propTypes = {
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    onSaveComment: PropTypes.func,
    toolboxTitle: PropTypes.string,
    drafts: PropTypes.arrayOf(PropTypes.object),
    draftId: PropTypes.string,
    style: PropTypes.object,
    initialView: PropTypes.oneOf(['edit', 'preview']),
    showComments: PropTypes.bool,
    activation: PropTypes.object,
    showApprovalTool: PropTypes.bool,
    createInteraction: PropTypes.func,
};

export default ConnectedDraftToolbox;
