import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../Common/Alert';
import Button from '../../Common/Form/Button';

class BulkAcceptForm extends Component {

    static defaultProps = {
        isUpdating: false,
    };

    static propTypes = {
        onClickSubmit: PropTypes.func,
        activationIds: PropTypes.array.isRequired,
        isUpdating: PropTypes.bool,
    };

    renderAlert() {

        const count = this.props.activationIds.length;
        const influencers = count === 1 ? 'user' : 'users';
        return [
            <Alert
                classes={['info']}
                content={<span>Accept {count} {influencers}. They will be removed from the waitlist and notified.</span>}
            />
        ];


    }

    renderButtons() {
        const {isUpdating} = this.props;

        const style = {
            textAlign: 'center',
            padding: 20
        };

        const count = this.props.activationIds.length;
        const influencers = count === 1 ? 'User' : 'Users';

        return (
            <div style={style}>
                <Button
                    content={`Accept ${count} ${influencers}`}
                    classes={['v3', 'large', 'btn-primary', (isUpdating ? 'disabled' : '')]}
                    onClick={isUpdating ? null : this.props.onClickSubmit}
                />
            </div>
        );

    }

    render() {

        return (
            <div className="activation-status-change-form">
                {this.renderAlert()}
                {this.renderButtons()}
            </div>
        );

    }
}

export default BulkAcceptForm;
