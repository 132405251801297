/**
 * Created by bkanber on 6/27/17.
 */

import {makeFormData} from "../utilities";
import {fetchAuthenticated} from "./auth";

import * as ActionTypes from './types';
import * as URI from 'urijs';

import {receiveActivationInteractions} from "./activations";

export const REQUEST_CAMPAIGN = 'REQUEST_CAMPAIGN';
export const RECEIVE_CAMPAIGN = 'RECEIVE_CAMPAIGN';

export const REQUEST_CAMPAIGNS = 'REQUEST_CAMPAIGNS';
export const RECEIVE_CAMPAIGNS = 'RECEIVE_CAMPAIGNS';

export const REQUEST_CAMPAIGN_REPORT = 'REQUEST_CAMPAIGN_REPORT';
export const RECEIVE_CAMPAIGN_REPORT = 'RECEIVE_CAMPAIGN_REPORT';

export const REQUEST_CAMPAIGN_STATS = 'REQUEST_CAMPAIGN_STATS';
export const RECEIVE_CAMPAIGN_STATS = 'RECEIVE_CAMPAIGN_STATS';

export const REQUEST_CAMPAIGN_IMAGES = 'REQUEST_CAMPAIGN_IMAGES';
export const RECEIVE_CAMPAIGN_IMAGES = 'RECEIVE_CAMPAIGN_IMAGES';

export const REQUEST_CAMPAIGN_ACTIVATIONS = 'REQUEST_CAMPAIGN_ACTIVATIONS';
export const RECEIVE_CAMPAIGN_ACTIVATIONS = 'RECEIVE_CAMPAIGN_ACTIVATIONS';

export const REQUEST_CAMPAIGN_USERS = 'REQUEST_CAMPAIGN_USERS';
export const RECEIVE_CAMPAIGN_USERS = 'RECEIVE_CAMPAIGN_USERS';
export const RECEIVE_CAMPAIGN_USER = 'RECEIVE_CAMPAIGN_USER';

export const REQUEST_CAMPAIGN_AUDIENCE = 'REQUEST_CAMPAIGN_AUDIENCE';
export const RECEIVE_CAMPAIGN_AUDIENCE = 'RECEIVE_CAMPAIGN_AUDIENCE';

export const REQUEST_CAMPAIGN_GEOGRAPHY = 'REQUEST_CAMPAIGN_GEOGRAPHY';
export const RECEIVE_CAMPAIGN_GEOGRAPHY = 'RECEIVE_CAMPAIGN_GEOGRAPHY';

export const REQUEST_CAMPAIGN_ACTIVATION_STATS = 'REQUEST_CAMPAIGN_ACTIVATION_STATS';
export const RECEIVE_CAMPAIGN_ACTIVATION_STATS = 'RECEIVE_CAMPAIGN_ACTIVATION_STATS';

export const UPDATE_CAMPAIGN_EMV_PARAMS = 'UPDATE_CAMPAIGN_EMV_PARAMS';
export const UPDATED_CAMPAIGN_EMV_PARAMS = 'UPDATED_CAMPAIGN_EMV_PARAMS';

export const ACTIVATE_USERS = 'ACTIVATE_USERS';
export const ACTIVATED_USERS = 'ACTIVATED_USERS';

export const UPDATE_CAMPAIGN_SETTING = 'UPDATE_CAMPAIGN_SETTING';
export const UPDATED_CAMPAIGN_SETTING = 'UPDATED_CAMPAIGN_SETTING';

export const REQUEST_CAMPAIGN_AFFINITY = 'REQUEST_CAMPAIGN_AFFINITY';
export const RECEIVE_CAMPAIGN_AFFINITY = 'RECEIVE_CAMPAIGN_AFFINITY';

export const ADD_CAMPAIGN_TEAM = 'ADD_CAMPAIGN_TEAM';
export const ADDED_CAMPAIGN_TEAM = 'ADDED_CAMPAIGN_TEAM';
export const REMOVE_CAMPAIGN_TEAM = 'REMOVE_CAMPAIGN_TEAM';
export const REMOVED_CAMPAIGN_TEAM = 'REMOVED_CAMPAIGN_TEAM';

export const DUPLICATE_CAMPAIGN = 'DUPLICATE_CAMPAIGN';
export const DUPLICATED_CAMPAIGN = 'DUPLICATED_CAMPAIGN';

export const REQUEST_ACTIVATION = 'REQUEST_ACTIVATION';
export const RECEIVE_ACTIVATION = 'RECEIVE_ACTIVATION';

export const REQUEST_ACTIVATION_REQUIREMENTS = 'REQUEST_ACTIVATION_REQUIREMENTS';
export const RECEIVE_ACTIVATION_REQUIREMENTS = 'RECEIVE_ACTIVATION_REQUIREMENTS';

export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_FAILED = 'CREATE_CAMPAIGN_FAILED';
export const CREATED_CAMPAIGN = 'CREATED_CAMPAIGN';

export const UPDATE_CAMPAIGN_SETTINGS = 'UPDATE_CAMPAIGN_SETTINGS';
export const UPDATED_CAMPAIGN_SETTINGS = 'UPDATED_CAMPAIGN_SETTINGS';

/**
 * @param campaignId
 * @returns {function(*)}
 */
export const fetchCampaignForms = (campaignId) => {

    return (dispatch) => {

        dispatch({
            type: ActionTypes.CAMPAIGN_REQUEST_FORMS,
            id: campaignId
        });

        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/campaign/${campaignId}/form`;
        return dispatch(fetchAuthenticated(url))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: ActionTypes.CAMPAIGN_RECEIVE_FORMS,
                data: json.data,
                meta: json.meta,
                id: campaignId
            }));

    };

};

/**
 * @param campaignId
 * @param payload
 * @returns {function(*): *}
 */
export const createCampaignCoupon = (campaignId, payload) => {
    return (dispatch) => {

        dispatch({
            type: ActionTypes.CAMPAIGN_CREATE_COUPON,
            id: campaignId,
            campaignId,
            data: payload,
            payload
        });

        const url = `/manage/api/campaign/${campaignId}/coupon`;

        return dispatch(fetchAuthenticated(url, {
            method: 'post',
            body: makeFormData(payload),
        }))
            .then(resp => resp.json())
            .then(json => {
                dispatch({
                    type: ActionTypes.CAMPAIGN_CREATED_COUPON,
                    id: campaignId,
                    campaignId,
                    data: json.data,
                    meta: json.meta,
                });
                return json;
            })
            .then(json => dispatch(receiveCampaignCoupons(campaignId, json)));

    };
};

/**
 *
 * @param campaignId
 * @param json
 * @returns {{type: string, data: *, meta: *, id: *}}
 */
export const receiveCampaignCoupons = (campaignId, json) => {
    return {
        type: ActionTypes.CAMPAIGN_RECEIVE_COUPONS,
        data: json.data,
        meta: json.meta,
        id: campaignId,
        campaignId
    };
};

/**
 * @param campaignId
 * @returns {function(*): *}
 */
export const fetchCampaignCoupons = (campaignId) => {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CAMPAIGN_REQUEST_COUPONS,
            id: campaignId,
            campaignId
        });

        const url = `/manage/api/campaign/${campaignId}/coupon`;

        return dispatch(fetchAuthenticated(url))
            .then(resp => resp.json())
            .then(json => dispatch(receiveCampaignCoupons(campaignId, json)));

    };
};

/**
 * @returns {function}
 */
export function fetchActivation(activationId) {
    return function(dispatch) {
        dispatch({
            type: REQUEST_ACTIVATION,
            activationId,
            id: activationId
        });

        const slot = window.Gator.getDashboardSlot();
        return dispatch(fetchAuthenticated(`/${slot}/api/activation/${activationId}`, {}))
            .then(response => response.json())
            .then(json => {
                return dispatch({
                    type: RECEIVE_ACTIVATION,
                    activation: json.data,
                    data: json.data,
                    meta: json.meta,
                    id: activationId,
                    activationId,
                    receivedAt: new Date()
                })
            })
            .catch(err => {
                console.log("Error fetching");
                console.log(err);
            });
    }
}

/**
 * @param state
 * @param activationId
 * @returns {*}
 */
export function shouldFetchActivation(state, activationId) {
    const activation = state.activationsById[activationId];
    if (typeof activation === 'undefined') {
        return true;
    } else if (activation.isFetchingActivation || activation.lastUpdatedActivation) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchActivationIfNeeded(activationId) {
    return (dispatch, getState) => {
        if (shouldFetchActivation(getState(), activationId)) {
            return dispatch(fetchActivation(activationId))
        }
    }
}

/**
 * @returns {Function}
 */
export function fetchActivationRequirements(activationId) {
    return function(dispatch) {
        dispatch({type: REQUEST_ACTIVATION_REQUIREMENTS, activationId});

        const slot = window.Gator.getDashboardSlot();
        return dispatch(fetchAuthenticated(`/${slot}/api/activation/${activationId}/requirement`))
            .then(response => response.json())
            .then(json => {
                return dispatch({
                    type: RECEIVE_ACTIVATION_REQUIREMENTS,
                    id: activationId,
                    data: json.data,
                    meta: json.meta,
                    requirements: json.data,
                    activationId,
                    receivedAt: new Date()
                })
            })
            .catch(err => {
                console.log("Error fetching");
                console.log(err);
            });
    }
}

/**
 * @param state
 * @param activationId
 * @returns {*}
 */
export function shouldFetchActivationRequirements(state, activationId) {
    const activation = state.activationsById[activationId];
    if (typeof activation === 'undefined') {
        return true;
    } else if (activation.isFetchingRequirements || activation.lastUpdatedRequirements) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchActivationRequirementsIfNeeded(activationId) {
    return (dispatch, getState) => {
        if (shouldFetchActivationRequirements(getState(), activationId)) {
            return dispatch(fetchActivationRequirements(activationId))
        }
    }
}

export const createCampaign = (name = 'New Blank Campaign', teamId = null) => {
    return (dispatch) => {

        dispatch({ type: CREATE_CAMPAIGN, name });

        return dispatch(fetchAuthenticated(`/manage/api/campaign`, {
            method: 'POST',
            body: makeFormData({name, team_id: teamId})
        }))
            .then(resp => resp.json())
            .then(json => dispatch(receiveCampaign(json.data.id, json.data)) );

    };
};

export function duplicateCampaign(campaignId, newName, teamId = null, asSubcampaign = false) {
    return function (dispatch) {

        dispatch({type: DUPLICATE_CAMPAIGN, campaignId, newName});

        return dispatch(fetchAuthenticated(`/manage/api/campaign/${campaignId}/duplicate`, {
            method: 'POST',
            body: makeFormData({
                name: newName,
                team_id: teamId,
                parent_id: asSubcampaign ? campaignId : null
            })
        }))
            .then(resp => resp.json())
            .then(json => {
                // Dispatch receiveCampaign so that we don't need a separate reducer
                // handler for duplicated_campaign
                dispatch(receiveCampaign(json.data.id, json.data));
                return dispatch({type: DUPLICATED_CAMPAIGN, campaignId, newName, campaign: json.data});
            })
            .catch(err => {
                console.log("Error duplicating campaign");
                console.log(err);
                return err;
            });
    }
}


export function removeCampaignTeam(campaignId, teamId) {
    return function(dispatch) {
        dispatch({type: REMOVE_CAMPAIGN_TEAM, campaignId, teamId});
        return dispatch(fetchAuthenticated(`/manage/api/campaign/${campaignId}/team/${teamId}`, {
            method: 'POST',
            body: makeFormData({_method: 'DELETE'})
        }))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: REMOVED_CAMPAIGN_TEAM,
                campaignId,
                teamId,
                campaign: json.data
            }))
            .catch(err => {
                console.log("Error removing team from campaign");
                console.log(err);
                return err;
            })
    }
}

export function addCampaignTeam(campaignId, teamId) {
    return function(dispatch) {
        dispatch({type: ADD_CAMPAIGN_TEAM, campaignId, teamId});
        return dispatch(fetchAuthenticated(`/manage/api/campaign/${campaignId}/team/${teamId}`, {
            method: 'POST',
            body: makeFormData({_method: 'PUT'})
        }))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: ADDED_CAMPAIGN_TEAM,
                campaignId,
                teamId,
                campaign: json.data
            }))
            .catch(err => {
                console.log("Error adding team to campaign");
                console.log(err);
                return err;
            })
    }
}

/**
 *
 * @param state
 * @param campaignId
 * @returns {boolean}
 */
export function shouldFetchCampaignReport(state, campaignId) {
    const campaign = state.campaignsById.items[campaignId];
    if (typeof campaign === 'undefined') {
        return true;
    } else if (campaign.isFetchingReport || campaign.lastUpdatedReport) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchCampaignReportIfNeeded(campaignId) {
    return (dispatch, getState) => {
        if (shouldFetchCampaignReport(getState(), campaignId)) {
            return dispatch(fetchCampaignReport(campaignId))
        }
    }
}

export function fetchCampaignReport(campaignId, allowRebuild=false) {
    return function (dispatch) {

        dispatch({
            type: REQUEST_CAMPAIGN_REPORT,
            campaignId
        });

        const slot = window.Gator.getDashboardSlot();
        const queryStringParams = {allowRebuild};
        const queryString = Object.keys(queryStringParams).map(key => key + '=' + queryStringParams[key]).join('&');

        return dispatch(fetchAuthenticated(`/${slot}/api/campaign/${campaignId}/report?${queryString}`, {}))
            .then(resp => resp.json())
            .then(json => {
                dispatch(receiveCampaignActivationStats(campaignId, {data: json.data.activation_stats}));
                return dispatch({
                    type: RECEIVE_CAMPAIGN_REPORT,
                    campaignId,
                    receivedAt: new Date(),
                    report: json.data,
                });
            })
            .catch(err => {
                console.log("Error encountered fetching campaign report data");
                console.log(err);
            });
    }
}

/**
 *
 * @param state
 * @param campaignId
 * @returns {boolean}
 */
export function shouldFetchCampaignAffinity(state, campaignId) {
    const campaign = state.campaignsById.items[campaignId];
    if (typeof campaign === 'undefined') {
        return true;
    } else if (campaign.isFetchingAffinity || campaign.lastUpdatedAffinity) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchCampaignAffinityIfNeeded(campaignId) {
    return (dispatch, getState) => {
        if (shouldFetchCampaignAffinity(getState(), campaignId)) {
            return dispatch(fetchCampaignAffinity(campaignId))
        }
    }
}

export function fetchCampaignAffinity(campaignId) {
    return function (dispatch) {

        dispatch({
            type: REQUEST_CAMPAIGN_AFFINITY,
            campaignId
        });

        return dispatch(fetchAuthenticated(`/manage/api/campaign/${campaignId}/affinity`, {}))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: RECEIVE_CAMPAIGN_AFFINITY,
                campaignId,
                receivedAt: new Date(),
                affinity: json.data,
                affinityMeta: json.meta
            }))
            .catch(err => {
                console.log("Error encountered fetching campaign affinity data");
                console.log(err);
            });
    }
}

/**
 * @returns {Function}
 */
export function fetchCampaignGeography(campaignId) {
    return function (dispatch) {

        dispatch({
            type: REQUEST_CAMPAIGN_GEOGRAPHY,
            campaignId
        });

        return dispatch(fetchAuthenticated(`/manage/api/campaign/${campaignId}/geography`, {}))
            .then(response => response.json())
            .then(json => dispatch({
                type: RECEIVE_CAMPAIGN_GEOGRAPHY,
                campaignId,
                receivedAt: new Date(),
                geography: json.data
            }))
            .catch(err => {
                console.log("Error fetching campaign geography");
                console.log(err);
            });

    }
}

/**
 * @param state
 * @param campaignId
 * @returns {*}
 */
export function shouldFetchCampaignGeography(state, campaignId) {
    const campaign = state.campaignsById.items[campaignId];
    if (typeof campaign === 'undefined') {
        return true;
    } else if (campaign.isFetchingGeography || campaign.lastUpdatedGeography) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchCampaignGeographyIfNeeded(campaignId) {
    return (dispatch, getState) => {
        if (shouldFetchCampaignGeography(getState(), campaignId)) {
            return dispatch(fetchCampaignGeography(campaignId))
        }
    }
}

export function updateCampaignSettings(campaignId, settings) {

    return function (dispatch) {
        const url = `/manage/api/campaign/${campaignId}/setting`;
        const payload = {...settings};

        dispatch({
            type: UPDATE_CAMPAIGN_SETTINGS,
            id: campaignId,
            campaignId,
            data: settings,
            settings
        });

        return dispatch(fetchAuthenticated(url, {
            method: 'post',
            body: makeFormData(payload)
        }))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: UPDATED_CAMPAIGN_SETTINGS,
                id: campaignId,
                campaignId,
                data: json.data,
                meta: json.meta
            }));

    }

};

export function updateCampaignSetting(campaignId, key, value) {

    const url = `/manage/api/campaign/${campaignId}/setting/${key}`;
    const payload = {[key]: value, _method: 'PUT'};

    return function (dispatch) {
        dispatch({
            type: UPDATE_CAMPAIGN_SETTING,
            campaignId,
            key,
            value
        });

        return dispatch(fetchAuthenticated(url,
            {
                method: 'POST',
                body: makeFormData(payload)
            } ))
            .then(response => response.json())
            .then(json => {

                dispatch({
                    type: UPDATED_CAMPAIGN_SETTING,
                    campaignId,
                    key,
                    value,
                    receivedAt: new Date(),
                    campaign: json.data
                });

            })
            .catch(err => {
                console.log("Error updating campaign setting");
                console.log(err)
            });
    }

}

export function activateUsers(campaignId, userIds, params) {

    const defaultParams = {
        allow_duplicates: false,
    };

    params = Object.assign({}, defaultParams, params);

    const url = `/manage/api/campaign/${campaignId}/activation`;

    return function (dispatch) {

        let payload = {...params, ids: userIds};

        dispatch({
            type: ACTIVATE_USERS,
            campaignId,
            userIds,
            params
        });

        return dispatch(fetchAuthenticated(url,
            {
                method: 'POST',
                body: makeFormData(payload)
            } ))
            .then(response => response.json())
            .then(json => {

                dispatch({
                    type: ACTIVATED_USERS,
                    activations: json.data,
                    campaignId,
                    userIds,
                    params
                });

            })
            .catch(err => {
                console.log("Error activating users");
                console.log(err)
            });



    }

}

export function updateCampaignEMVParams(campaignId, emvParams) {
    return function (dispatch) {

        dispatch({
            type: UPDATE_CAMPAIGN_EMV_PARAMS,
            campaignId,
            emvParams
        });

        const payload = {
            emvParams: JSON.stringify(emvParams),
            _method: 'PUT'
        };

        return dispatch(fetchAuthenticated(`/manage/api/campaign/${campaignId}/emvparam`, {
                method: 'POST',
                body: makeFormData(payload)
            }))
            .then(response => response.json())
            .then(json => dispatch({
                type: UPDATED_CAMPAIGN_EMV_PARAMS,
                emvParams: json.data,
                campaignId,
                receivedAt: new Date()
            }))
            .catch(err => {
                console.log("Error updating emv params");
                console.log(err);
            });

    }
}

export function requestCampaignActivationStats(campaignId) {
    return {
        type: REQUEST_CAMPAIGN_ACTIVATION_STATS,
        campaignId
    };
}

export function receiveCampaignActivationStats(campaignId, json) {
    return {
        type: RECEIVE_CAMPAIGN_ACTIVATION_STATS,
        stats: json.data,
        receivedAt: Date.now(),
        campaignId
    };
}

/**
 * @returns {Function}
 */
export function fetchCampaignActivationStats(campaignId) {
    return function (dispatch) {
        dispatch(requestCampaignActivationStats(campaignId));

        return dispatch(fetchAuthenticated(`/manage/api/campaign/${campaignId}/activationstat`, {}))
            .then(response => response.json())
            .then(json => dispatch(receiveCampaignActivationStats(campaignId, json)))
            .catch(err => {
                console.log("Error fetching");
                console.log(err);
            });

    }
}

/**
 * @param state
 * @param campaignId
 * @returns {*}
 */
export function shouldFetchCampaignActivationStats(state, campaignId) {
    const campaign = state.campaignsById.items[campaignId];
    if (typeof campaign === 'undefined') {
        return true;
    } else if (campaign.isFetchingActivationStats || campaign.lastUpdatedActivationStats) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchCampaignActivationStatsIfNeeded(campaignId) {
    return (dispatch, getState) => {
        if (shouldFetchCampaignActivationStats(getState(), campaignId)) {
            return dispatch(fetchCampaignActivationStats(campaignId))
        }
    }
}

export function requestCampaignAudience(campaignId) {
    return {
        type: REQUEST_CAMPAIGN_AUDIENCE,
        campaignId
    };
}

export function receiveCampaignAudience(campaignId, json) {
    return {
        type: RECEIVE_CAMPAIGN_AUDIENCE,
        audience: json.data,
        receivedAt: Date.now(),
        campaignId
    };
}

/**
 * @returns {Function}
 */
export function fetchCampaignAudience(campaignId) {
    return function (dispatch) {
        dispatch(requestCampaignAudience(campaignId));

        return dispatch(fetchAuthenticated(`/manage/api/campaign/${campaignId}/audience`, {}))
            .then(response => response.json())
            .then(json => dispatch(receiveCampaignAudience(campaignId, json)))
            .catch(err => {
                console.log("Error fetching");
                console.log(err);
            });

    }
}

/**
 * @param state
 * @param campaignId
 * @returns {*}
 */
export function shouldFetchCampaignAudience(state, campaignId) {
    const campaign = state.campaignsById.items[campaignId];
    if (typeof campaign === 'undefined') {
        return true;
    } else if (campaign.isFetchingAudience || campaign.lastUpdatedAudience) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchCampaignAudienceIfNeeded(campaignId) {
    return (dispatch, getState) => {
        if (shouldFetchCampaignAudience(getState(), campaignId)) {
            return dispatch(fetchCampaignAudience(campaignId))
        }
    }
}

export function requestCampaignUsers(campaignId) {
    return {
        type: REQUEST_CAMPAIGN_USERS,
        campaignId
    };
}

export function receiveCampaignUsers(campaignId, json) {
    return {
        type: RECEIVE_CAMPAIGN_USERS,
        users: json.data,
        receivedAt: Date.now(),
        campaignId
    };
}

/**
 * @returns {Function}
 */
export function fetchCampaignUsers(campaignId, includeNotAccepted = false) {
    return function (dispatch) {
        dispatch(requestCampaignUsers(campaignId));

        const url = `/manage/api/campaign/${campaignId}/user` + (includeNotAccepted ? '?includeNotAccepted=1' : '');

        return dispatch(fetchAuthenticated(url, {}))
            .then(response => response.json())
            .then(json => dispatch(receiveCampaignUsers(campaignId, json)))
            .catch(err => {
                console.log("Error fetching");
                console.log(err);
            });

    }
}

/**
 * @param state
 * @param campaignId
 * @returns {*}
 */
export function shouldFetchCampaignUsers(state, campaignId) {
    const campaign = state.campaignsById.items[campaignId];
    if (typeof campaign === 'undefined') {
        return true;
    } else if (campaign.isFetchingUsers || campaign.lastUpdatedUsers) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchCampaignUsersIfNeeded(campaignId, includeNotAccepted = false) {
    return (dispatch, getState) => {
        if (shouldFetchCampaignUsers(getState(), campaignId)) {
            return dispatch(fetchCampaignUsers(campaignId, includeNotAccepted))
        }
    }
}

export function requestCampaignActivations(campaignId) {
    return {
        type: REQUEST_CAMPAIGN_ACTIVATIONS,
        campaignId
    };
}

export function receiveCampaignActivations(campaignId, json) {
    return {
        type: RECEIVE_CAMPAIGN_ACTIVATIONS,
        activations: json.data,
        receivedAt: Date.now(),
        campaignId
    }
}

/**
 * @returns {Function}
 */
export function fetchCampaignActivations(campaignId) {
    return function (dispatch) {
        dispatch(requestCampaignActivations(campaignId));

        return dispatch(fetchAuthenticated(`/manage/api/campaign/${campaignId}/activation`, {}))
            .then(response => response.json())
            .then(json => dispatch(receiveCampaignActivations(campaignId, json)))
            .catch(err => {
                console.log("Error fetching");
                console.log(err);
            });

    }
}

/**
 * @param state
 * @param campaignId
 * @returns {*}
 */
export function shouldFetchCampaignActivations(state, campaignId) {
    const campaign = state.campaignsById.items[campaignId];
    if (typeof campaign === 'undefined') {
        return true;
    } else if (campaign.isFetchingActivations || campaign.lastUpdatedActivations) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchCampaignActivationsIfNeeded(campaignId) {
    return (dispatch, getState) => {
        if (shouldFetchCampaignActivations(getState(), campaignId)) {
            return dispatch(fetchCampaignActivations(campaignId))
        }
    }
}

export function requestCampaignImages(campaignId) {
    return {
        type: REQUEST_CAMPAIGN_IMAGES,
        campaignId
    };
}

export function receiveCampaignImages(campaignId, json) {
    return {
        type: RECEIVE_CAMPAIGN_IMAGES,
        images: json.data,
        receivedAt: Date.now(),
        campaignId
    }
}

/**
 * @returns {Function}
 */
export function fetchCampaignImages(campaignId) {
    return function (dispatch) {
        dispatch(requestCampaignImages(campaignId));

        return dispatch(fetchAuthenticated(`/manage/api/campaign/${campaignId}/image`, {}))
            .then(response => response.json())
            .then(json => dispatch(receiveCampaignImages(campaignId, json)))
            .catch(err => {
                console.log("Error fetching");
                console.log(err);
            });

    }
}

/**
 * @param state
 * @param campaignId
 * @returns {*}
 */
export function shouldFetchCampaignImages(state, campaignId) {
    const campaign = state.campaignsById.items[campaignId];
    if (typeof campaign === 'undefined') {
        return true;
    } else if (campaign.isFetchingImages || campaign.lastUpdatedImages) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchCampaignImagesIfNeeded(campaignId) {
    return (dispatch, getState) => {
        if (shouldFetchCampaignImages(getState(), campaignId)) {
            return dispatch(fetchCampaignImages(campaignId))
        }
    }
}


export function requestCampaignStats(campaignId) {
    return {
        type: REQUEST_CAMPAIGN_STATS,
        campaignId
    };
}

export function receiveCampaignStats(campaignId, json) {
    return {
        type: RECEIVE_CAMPAIGN_STATS,
        stats: json.data,
        receivedAt: Date.now(),
        campaignId
    }
}

/**
 * @returns {Function}
 */
export function fetchCampaignStats(campaignId) {
    return function (dispatch) {
        dispatch(requestCampaignStats(campaignId))

        return dispatch(fetchAuthenticated(`/manage/api/campaign/${campaignId}/stat`, {}))
            .then(response => response.json())
            .then(json => dispatch(receiveCampaignStats(campaignId, json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

/**
 * @param state
 * @returns {*}
 */
export function shouldFetchCampaignStats(state, campaignId) {
    const theseStats = state.campaignStatsById[campaignId];
    if (typeof theseStats === 'undefined') {
        return true;
    } else if (theseStats.isFetching || theseStats.lastUpdated) {
        return false;
    } else {
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchCampaignStatsIfNeeded(campaignId) {
    return (dispatch, getState) => {
        if (shouldFetchCampaignStats(getState(), campaignId)) {
            return dispatch(fetchCampaignStats(campaignId))
        }
    }
}


/**
 * @returns {{type: string}}
 */
export function requestCampaigns() {
    return {
        type: REQUEST_CAMPAIGNS
    };
}

/**
 */
export function receiveCampaigns(json) {

    return {
        type: RECEIVE_CAMPAIGNS,
        campaigns: json.data,
        receivedAt: Date.now()
    };

}

export function receiveCampaign(campaignId, json) {
    return {
        type: RECEIVE_CAMPAIGN,
        campaign: json,
        campaignId,
        receivedAt: Date.now()
    };
}

/**
 * @returns {Function}
 */
export function fetchCampaigns(params={}) {
    return function (dispatch) {
        const slot = window.Gator.getDashboardSlot();
        const limit = params.limit || 100;
        const page = params.page || 1;

        // Only request campaigns on page one, to avoid weird UI glitches everywhere
        if (page === 1) {
            dispatch(requestCampaigns());
        }

        const url = URI(`/${slot}/api/campaign`)
            .query({page, limit})
            .toString();

        return dispatch(fetchAuthenticated(url))
            .then(response => response.json())
            .then(json => {
                const res = dispatch(receiveCampaigns(json));
                if (json.data && json.data.length === limit) {
                    // Trigger another page.
                    setTimeout(() => {
                        dispatch(fetchCampaigns({page: page+1, limit}));
                    }, 1000);
                }
                return res;
            })
            .catch(err => {
                console.log("Error fetching");
                console.log(err);
            });

    }
}

export function fetchCampaign(id) {
    return function (dispatch) {
        const slot = window.Gator.getDashboardSlot();
        dispatch({
            type: REQUEST_CAMPAIGN,
            id,
        });

        return dispatch(fetchAuthenticated(`/${slot}/api/campaign/${id}`))
            .then(resp => resp.json())
            .then(json => dispatch({
                type: RECEIVE_CAMPAIGN,
                id,
                meta: json.meta,
                data: json.data,
                campaign: json.data,
                campaignId: id,
            }));
    }
}

/**
 * @param state
 * @returns {*}
 */
export function shouldFetchCampaigns(state) {
    if (state.campaignsById.isFetching || state.campaignsById.lastUpdated) {
        return false;
    } else {
        // TODO Need to record if ever fetched
        return true;
    }
}

/**
 * @returns {function(*, *)}
 */
export function fetchCampaignsIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchCampaigns(getState())) {
            return dispatch(fetchCampaigns())
        }
    }
}

export const createCampaignInteraction = (campaignId, type, context) => {

    return (dispatch) => {

        const dashboard = window.Gator.getDashboardSlot();
        const uri = `/${dashboard}/api/campaign/${campaignId}/interaction`;

        dispatch({
            type: ActionTypes.CAMPAIGN_CREATE_INTERACTION,
            id: campaignId,
            interactionType: type,
            context
        });

        return dispatch(fetchAuthenticated(uri, {
            method: 'POST',
            body: makeFormData({
                type,
                context: JSON.stringify(context)
            })
        }))
            .then(resp => resp.json())
            .then(json => {
                dispatch({
                    type: ActionTypes.CAMPAIGN_CREATED_INTERACTION,
                    id: campaignId,
                    meta: json.meta,
                    data: json.data
                });
                return json
            });
    }
};
