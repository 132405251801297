import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../Common/Alert';
import Image from '../../../Common/Image';

import {connect} from 'react-redux';
import {createActivationInteraction} from "../../../../actions/activations";
import {fetchAuthenticated} from "../../../../actions/auth";
import _get from "lodash/get";

class UploadImages extends Component {

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        refreshActivation: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fetchAuthenticated: PropTypes.func.isRequired,
        onComplete: PropTypes.func,
    };

    static defaultProps = {
        disabled: false,
    };

    state = {
        isUploading: false,
        isRemoving: false,
    };

    renderAlert() {

        if (!this.isAllowedToUpload()) {
            let msg = 'You must accept the brief invitation and terms before you can upload images.';
            if (_get(this.props, 'activation.status.is_waitlisted')) {
                msg = 'You are currently waitlisted. Please wait until you are approved before uploading images.';
            }

            return <Alert
                classes={['info']}
                content={msg}
                style={{marginBottom: 20}}
            />
        }

        const requiredCount = (this.props.activation.settings || {}).num_images;
        const uploadedCount = (this.props.activation.image_uploads || []).length;
        const {isUploading, isRemoving} = this.state;
        const isFulfilled = uploadedCount >= requiredCount;

        let content = `You are required to upload ${requiredCount} high resolution images. Please upload images that are at least 1000 pixels wide.`;

        if (isUploading) {
            content = 'Uploading your image...';
        }

        if (isRemoving) {
            content = 'Removing image...';
        }

        return <Alert
            classes={[isFulfilled ? 'success' : 'info']}
            content={content}
            style={{marginBottom: 20}}
        />;



    }

    renderBody() {

        return (
            <div>
                {this.renderUploader()}
                {this.renderImages()}
            </div>
        );

    }

    renderImages() {

        const images = this.props.activation.image_uploads || [];
        return <div className="clearfix images-container">{images.map(this.renderImage.bind(this))}</div>
    }

    renderImage(image) {
        return (

            <div
                className="image-tile-wrapper"
                style={{
                    height: 200,
                    float: 'left',
                    background: '#EEE',
                    marginBottom: 20,
                    position: 'relative',
                    padding: 20,
                }}
            >

                <a className="v3 close modal bg-primary-dark white"
                   role="button"
                   onClick={this.handleRemoveImage.bind(this, image)}
                   style={{
                       left: 'inherit',
                       zIndex: 199,
                   }}
                />

                <Image
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    imageStyle={{
                        maxHeight: '100%',
                        display: 'block',
                        margin: 'auto',
                    }}
                    image={image}
                    key={image.id}
                />
            </div>
        );
    }

    handleUpload(event) {

        const formData = new FormData();
        formData.append('image', event.target.files[0]);
        formData.append('user_id', this.props.activation.user_id);
        formData.append('campaign_id', this.props.activation.campaign_id);

        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/image`;

        const options = {
            method: 'POST',
            body: formData,
            enctype: 'multipart/form-data'
        };

        this.setState({ isUploading: true });

        return this.props.fetchAuthenticated(url, options)
            .then(res => res.json())
            .then(this.handleSubmit.bind(this))

    }

    handleRemoveImage(image) {
        if (this.state.isRemoving) {
            return null;
        }

        this.setState({isRemoving: true});
        const {activation} = this.props;
        const payload = {image_id: image.id, action: 'remove'};
        return this.props.createInteraction(activation.id, 'UploadImage', payload)
            .then(() => this.props.refreshActivation(activation.id))
            .then(() => this.setState({isRemoving: false}));

    }

    handleSubmit(json) {
        const {activation} = this.props;
        const payload = {image_id: json.data.id, image: json.data, action: 'add'};
        return this.props.createInteraction(activation.id, 'UploadImage', payload)
            .then(() => this.props.refreshActivation(activation.id))
            .then(() => this.setState({isUploading: false}))
            .then(() => {
                if (this.props.onComplete) {
                    this.props.onComplete();
                }
            });
    }

    isAllowedToUpload() {
        const {activation} = this.props;
        const {status} = activation;
        return status && status.did_user_accept_invitation && !status.is_waitlisted;
    }

    renderUploader() {

        if (!this.isAllowedToUpload()) {
            return null;
        }

        return (

            <div className="form-group">
                <label><strong>Choose an Image to Upload</strong></label>
                <input type='file'
                       className='form-control'
                       onChange={this.handleUpload.bind(this)}
                       disabled={this.props.disabled}
                />
                <p className="help-block">Upload a JPEG or PNG image. Max filesize is 4MB.</p>
            </div>

        );
    }

    render() {
        return (
            <div className="influencer-page-template submit-images">
                <div className="widget">
                    <div className="title">
                        <h2>Upload Images</h2>
                    </div>
                    <div className="content padded">
                        <h4 className="v3 bold action-pane-title">Upload Images</h4>
                        {this.renderAlert()}
                        {this.renderBody()}
                    </div>
                </div>
            </div>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params)),
    };
};

export default connect(null, mapDispatchToProps)(UploadImages);