import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from "../../../Common/Form/Form";
import Alert from "../../../Common/Alert";

class CustomFieldEditor extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
        refreshUser: PropTypes.func.isRequired,
        channel: PropTypes.object.isRequired,
        createInteraction: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {},
            isUpdating: false,
            didUpdate: false,
            hasError: false,
            error: null,
            showFieldAdder: false,
            newKey: '',
            newValue: '',
        };
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
            hasError: false,
            error: null,
        });
    }

    handleNewFieldChange(name, value) {
        this.setState({
            [name]: value,
            hasError: false,
            error: null,
        });
    }

    handleClickSave() {
        const {channel, user} = this.props;
        const {newKey, newValue} = this.state;
        const context = {...this.state.form, channel_id: channel.id};

        if (newKey && newValue) {
            context[newKey] = newValue;
        }

        this.setState({isUpdating: true, didUpdate: false});


        this.props.createInteraction(user.id, 'UpdateProfileFields', context)
            .then(resp => {
                if (resp.meta.error) {
                    this.setState({isUpdating: false, hasError: true, error: resp.meta.error})
                } else {
                    this.props.refreshUser(user.id);
                    this.setState({isUpdating: false, didUpdate: true});
                    setTimeout(() => this.setState({form: {}, didUpdate: false}), 2000);
                }
            });
    }

    getFields() {
        const fields = Object.keys(this.getFieldValues())
            .map(key => {
                return {
                    type: 'text',
                    name: key,
                    title: key
                }
            });

        const noFields = {
            type: 'generic',
            content: <span style={{fontStyle: 'italic'}}>This user has no custom field data.</span>
        };

        const addField = {
            type: 'generic',
            content: this.renderFieldAdder(),
        };

        return [
            ...(fields.length ? fields : [noFields]),
            addField
        ];
    }

    getFieldValues() {
        const {user, channel} = this.props;
        const {profile} = user || {};
        const formValues = this.state.form;
        let nativeValues = {};

        (profile || [])
            .filter(field => field.channel_id === channel.mongo_id)
            .forEach(customField => nativeValues[customField.key] = customField.value)

        return {...nativeValues, ...formValues};
    }

    renderSaveAlert() {
        let style = {
            position: 'sticky',
            top: 0,
            zIndex: 200,
        };

        if (this.state.isUpdating) {
            return <Alert
                classes={['info']} content={"Saving your changes..."} style={style}
            />
        }

        if (this.state.didUpdate) {
            return <Alert
                classes={['success']} content={"Changes saved!"} style={style}
            />
        }

        if (this.state.hasError) {
            return <Alert
                classes={['danger']} content={this.state.error} style={style}
            />
        }

        if (Object.keys(this.state.form).length > 0 || this.state.newKey || this.state.newValue) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>You have unsaved changes. <a className="v3 bold" onClick={this.handleClickSave.bind(this)} role="button">Save now.</a></span>}
                    style={style}
                />
            );
        }
    }

    renderFieldAdder() {
        return (
            <>
                <a onClick={() => this.setState({showFieldAdder: !this.state.showFieldAdder})} role='button' 
                    className='v3 bold btn btn-secondary medium' style={{marginBottom: 15}}>Add a Custom Field</a>
                {
                    this.state.showFieldAdder && 
                    <Form
                        usePadding={false}
                        values={this.state}
                        onFieldChange={this.handleNewFieldChange.bind(this)}
                        fields={[
                            {
                                type: 'text',
                                name: 'newKey',
                                title: 'New Field Name'
                            }, 
                            {
                                type: 'text',
                                name: 'newValue',
                                title: 'New Field Value'
                            }
                        ]}
                    />
                }
            </>
        )
    }

    render() {
        return (
            <div className="pane-inner-content">
                {this.renderSaveAlert()}
                <p className='v3 light h7 pane-info'>Custom fields are only visible to brand managers, and can be used to track metadata like internal IDs, rate card information, and other internal metrics or attributes.</p>
                <Form
                    values={this.getFieldValues()}
                    onFieldChange={this.handleFieldChange.bind(this)}
                    fields={this.getFields()}
                />
            </div>
        );
    }

}

export default CustomFieldEditor;