import * as ActionTypes from '../actions/types';
import { receiveItems, receiveItem, modifyItem } from "../utilities/reducer";
import { combineReducers } from 'redux';


const sectionsById = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.RECEIVE_SECTIONS:
            return receiveItems(state, action);
        case ActionTypes.CREATED_SECTION:
            return receiveItem(state, action, {isCreatingSection: false});
        case ActionTypes.UPDATE_SECTION:
            return modifyItem(state, action, {isUpdatingSection: true});
        case ActionTypes.UPDATED_SECTION:
            return modifyItem(state, action, {isUpdatingSection: false});
        default:
            return state;
    }
};

export const sections = combineReducers({
    items: sectionsById
});