import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ActivationPostRecordCard from './PostRecordCard';
import ActivationPostsTable from './ActivationPostsTable';
import {connect} from 'react-redux';
import {fetchActivation} from "../../actions";
import {createActivationInteraction} from "../../actions/activations";
import {fetchAuthenticated} from "../../actions/auth";
import {makeFormData} from "../../utilities";

class ActivationExpandoPosts extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        campaign: PropTypes.object,
        onClickDraft: PropTypes.func,
        onClickPost: PropTypes.func,
        fetchActivation: PropTypes.func,
        createInteraction: PropTypes.func,
        fetchAuthenticated: PropTypes.func,
    };

    handleAcceptPost(record) {

        const activationId = this.props.activation.id;
        const payload = {post_record_id: record.id};

        return this.props.createInteraction(activationId, 'AcceptPost', payload)
            .then(() => this.props.fetchActivation(activationId));
    }

    handleRatePost(record, rating) {
        const activationId = this.props.activation.id;
        const payload = {activation_post_id: record.id, rating};

        return this.props.createInteraction(activationId, 'RatePost', payload)
            .then(() => this.props.fetchActivation(activationId));

    }

    handleRemovePost(record) {

        if (!this.props.campaign) {
            return Promise.reject();
        }

        const activationId = this.props.activation.id;
        const postId = record.post_id;
        const campaignSlug = this.props.campaign.slug;
        const slot = window.Gator.getDashboardSlot();
        const apiUrl = `/${slot}/do/widget/tools/remove_post_from_campaign`;
        const payload = {campaign_slug: campaignSlug, post_id: postId};

        return this.props.fetchAuthenticated(apiUrl, {method: 'post', body: makeFormData(payload)})
            .then(() => this.props.fetchActivation(activationId));
    }

    render() {

        const activation = this.props.activation;
        const numPosts = (activation.postRecords || activation.post_records || []).length;
        const numDrafts = (activation.drafts || []).length;

        if (!numPosts && !numDrafts) {
            return null;
        }

        return (
            <div className="activation-posts-expando">
                <ActivationPostsTable
                    activation={this.props.activation}
                    onClickDraft={this.props.onClickDraft}
                    onClickPost={this.props.onClickPost}
                    onAcceptPost={this.handleAcceptPost.bind(this)}
                    onRatePost={this.handleRatePost.bind(this)}
                    onRemovePost={this.handleRemovePost.bind(this)}
                />
            </div>
        );

    }
}

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => {
    return {
        fetchActivation: (id) => dispatch(fetchActivation(id)),
        createInteraction: (id, type, ctx) => dispatch(createActivationInteraction(id, type, ctx)),
        fetchAuthenticated: (url, params) => dispatch(fetchAuthenticated(url, params))
    };
};
const ConnectedActivationExpandoPosts = connect(mapStateToProps, mapDispatchToProps)(ActivationExpandoPosts);
export default ConnectedActivationExpandoPosts;
