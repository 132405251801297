/**
 * Created by bkanber on 8/7/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import UserDetailProfilePane from './ProfilePane';
import UserDetailSocialPane from './SocialPane';
import UserDetailStatsPaneManage from './StatsPaneManageDash';
import UserDetailStatsPaneContribute from "./StatsPaneContributeDash";
import UserDetailListsPane from './ListsPane';
import UserDetailPerformancePane from './PerformancePane';
import UserDetailHeader from './Header';
import UserDetailMenu from './Menu';
import AddUserToList from '../../List/AddUserToList';
import {preventDefaultIfPossible, ucwords, getImageDimensions} from "../../../utilities";
import UserDetailInterestsPane from "./InterestsPane";
import ConnectedActivationsPage from '../../../containers/Pages/Activations';
import Alert from "../../Common/Alert";
import {calcProfileCompletion} from '../../../utilities/user';
import ContributorTodosPane from "../../Influencer/Dashboard/Partials/ContributorToDosPane";
import ConnectedParticipationPane from "../../Influencer/Dashboard/Partials/ParticipationPane";
import EmailPaneContributeDash from "./EmailPaneContributeDash";
import EmailPaneManageDash from "./EmailPaneManageDash";
import ContentPaneContributeDash from "./ContentPaneContributeDash";
import ContentPaneManageDash from "./ContentPaneManageDash";
import TabBar from "../../Common/DetailView/TabBar";
import Toolbox from "../../Common/Toolbox";
import ConnectedEmailCompose from "../../Email/Compose";
import ManageUserTeams from "../../Team/ManageUserTeams";
import AddSocialHandleForm from "../Pieces/AddSocialHandleForm";
import {jsPDF} from "jspdf";
import * as htmlToImage from "html-to-image";
import PDFExporter from "../PDFExporter";
import QuickEditUserForm from "../QuickEditUserForm";

const determineInitialView = view => {
    switch (view) {
        case 'Campaigns': return 'campaigns-manage';
        case 'Posts': return 'content-manage';
        case 'Images': return 'content';
        case 'Stats': return 'stats-manage';
        case 'Lists': return 'lists';
        case 'Performance': return 'performance';
        case 'Social': return 'social';
        case 'Profile':
        case 'Notes':
        case 'Interests':
        default:
            return 'profile';
    }
};

export default class UserDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentView: determineInitialView(props.currentView),
            showMenu: false,
            // these snake_case ones come from the menu dropdown
            show_addToList: false,
            show_addToTeam: false,
            show_sendEmail: false,
            show_addSocialHandle: false,
            show_quickEditUser: false,

            profileCompletionPct: 0,
            missingFields: [],
            didEverChangePane: false,
            printMode: false
        };

        this.handleChangeView = this.handleChangeView.bind(this);
        this.handleToggleMenuItem = this.handleToggleMenuItem.bind(this);
        this.keyCapture = this.keyCapture.bind(this);
        this.isProfileFieldVisible = this.isProfileFieldVisible.bind(this);
        this.refreshProfileCompletionPct = this.refreshProfileCompletionPct.bind(this);
        this.printToPdf = this.printToPdf.bind(this);
    }

    async printToPdf(event) {
        preventDefaultIfPossible(event);
        this.setState({printMode: true, showMenu: false});
    }

    keyCapture(event) {
        // 69 === "e" key code
        if (event.keyCode === 69
            && event.ctrlKey === true
            && !this.state.show_attributes
            && !this.state.show_addToList
            && !this.state.showMenu
        ) {
            this.setState({show_attributes: true});
        }
    }

    fetchData() {
        this.props.fetchBadgesIfNeeded();
        this.props.fetchListsIfNeeded();

        let promise = this.props.fetchUser();

        if (!promise) {
            promise = new Promise(resolve => resolve(true));
        }

        promise.then(() => {
            this.props.fetchProfile();
            this.props.fetchUserLists();
        });

    }

    componentDidMount() {
        this.fetchData();
        document.addEventListener("keydown", this.keyCapture, false);

        if (this.props.showProfileCompletionPct) {
            this.refreshProfileCompletionPct(this.props.userId);
        }

        this.setTitle();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId) {

            this.fetchData();

            this.setState({
                showMenu: false,
                show_addToList: false
            });

            if (this.props.showProfileCompletionPct) {
                this.refreshProfileCompletionPct(this.props.userId);
            }

        }

        this.setTitle();
    }

    setTitle() {
        if (this.props.showContributeDashTitle) {
            switch (this.state.currentView) {
                case 'todos':
                    document.title = 'To-Dos | Tidal Labs CreatorExchange';
                    break;
                case 'campaigns-contribute':
                    document.title = 'Campaigns | Tidal Labs CreatorExchange';
                    break;
                case 'email-contribute':
                    document.title = 'Email | Tidal Labs CreatorExchange';
                    break;
                case 'stats-contribute':
                    document.title = 'Stats | Tidal Labs CreatorExchange';
                    break;
                case 'content-contribute':
                    document.title = 'Content | Tidal Labs CreatorExchange';
                    break;
                default:
                    document.title = `${ucwords(this.state.currentView)} | Tidal Labs CreatorExchange`;
            }
            return document.title;
        }

        return document.title = 'Users | ContentMetric Dashboard';
    }

    handleOnDidAttachSocial() {
        this.props.fetchUser();
        this.props.fetchSocials();
        this.refreshProfileCompletionPct(this.props.userId);
    }

    refreshProfileCompletionPct(id) {
        this.props.createInteraction(id, 'PersonalProfileRequest', {})
            .then((resp) => {
                const user = (resp.data || {}).results || {};

                const {pct:completePct, missing:missingFields} = calcProfileCompletion(user || {}, this.isProfileFieldVisible.bind(this));

                const shouldShowTodosPane = !!(this.props.showTodosTab && missingFields.length > 0);

                const newState = {
                    profileCompletionPct: completePct,
                    missingFields: missingFields,
                };

                if (shouldShowTodosPane && this.state.didEverChangePane === false) {
                    newState.currentView = 'todos';
                    newState.didEverChangePane = true;
                }

                this.setState(newState);
            })
    }

    isProfileFieldVisible(fieldName) {
        const channel = this.props.channel || {};
        const skipFields = channel.profile_skip_fields || [];
        return skipFields.indexOf(fieldName) === -1;
    }

    renderHeader() {
        const {user, listsById, badgesById, listContext} = this.props;
        const profileCompletionPct = this.state.profileCompletionPct;

        return <UserDetailHeader
            printMode={this.state.printMode}
            user={user}
            onMenuToggle={showMenu => this.setState({showMenu})}
            showMenu={this.state.showMenu}
            menuContent={<UserDetailMenu
                onToggleItem={this.handleToggleMenuItem}
                user={this.props.user}
                blockUser={this.props.blockUser}
                showTeamsLink={(this.props.teams || []).length > 0}
                onPrint={this.printToPdf.bind(this)}
            />}

            handleCloseLightbox={this.props.handleCloseLightbox}
            openNextUser={this.props.openNextUser}
            openPrevUser={this.props.openPrevUser}
            onClickEditBasics={this.props.onClickEditBasics}

            listsById={listsById}
            badgesById={badgesById}
            onClickLists={this.handleToggleMenuItem.bind(this, 'addToList')}
            listContext={listContext}

            showMenuIcon={this.props.showMenuIcon}
            showListsIcons={this.props.showListsIcons}
            showStarRating={this.props.showStarRating}
            showProfileCompletionPct={this.props.showProfileCompletionPct}
            showSecondaryStats={this.props.showSecondaryStats}
            showActions={this.props.showActions}

            profileCompletionPct={profileCompletionPct}
        />
    }

    handleChangeView(view) {
        this.setState({currentView: view, didEverChangePane: true});
    }

    renderTabs() {
        const {user, showListsTab, showInterests, showTodosTab, showPerformanceTab, showContributeContentTab, showManageContentTab, showContributeCampaignsTab, showManageCampaignsTab, showContributeEmailTab, showManageEmailTab, showContributeStatsTab, showManageStatsTab} = this.props;
        const {currentView} = this.state;
        const hasInterests = (user.profile.interests || []).length > 0;
        const toDos = this.state.missingFields.length;

        return (
            <div className="row">
                <div className="col-xs-12 user-detail-nav-tabs-wrapper">
                    <TabBar
                        tabs={[
                            {
                                key: 'todos',
                                title: <span>To-Dos <span className="v3 number-badge xs muted">{toDos}</span></span>,
                                isVisible: toDos > 0 && showTodosTab
                            },
                            {
                                key: 'profile',
                                title: 'Profile',
                            },
                            {
                                key: 'content-contribute',
                                title: 'Content',
                                isVisible: showContributeContentTab
                            },
                            {
                                key: 'content-manage',
                                title: 'Content',
                                isVisible: showManageContentTab
                            },
                            {
                                key: 'social',
                                title: 'Social',
                            },
                            {
                                key: 'campaigns-contribute',
                                title: 'Campaigns',
                                isVisible: showContributeCampaignsTab
                            },
                            {
                                key: 'campaigns-manage',
                                title: 'Campaigns',
                                isVisible: showManageCampaignsTab
                            },
                            {
                                key: 'performance',
                                title: 'Performance',
                                isVisible: showPerformanceTab
                            },
                            {
                                key: 'email-contribute',
                                title: 'Email',
                                isVisible: showContributeEmailTab
                            },
                            {
                                key: 'email-manage',
                                title: 'Email',
                                isVisible: showManageEmailTab
                            },
                            {
                                key: 'interests',
                                title: 'Interests',
                                isVisible: hasInterests && showInterests
                            },
                            {
                                key: 'lists',
                                title: 'Lists',
                                isVisible: showListsTab
                            },
                            {
                                key: 'stats-contribute',
                                title: 'Stats',
                                isVisible: showContributeStatsTab
                            },
                            {
                                key: 'stats-manage',
                                title: 'Stats',
                                isVisible: showManageStatsTab
                            },
                        ]}
                        activeTab={currentView}
                        onClickTab={(tab) => this.handleChangeView(tab)}
                    />
                </div>
            </div>
        );

    }

    renderSubView() {
        let subview = null;

        const {
            campaignsById,
            user, activations, socials, posts, activationStats,
            fetchActivations, fetchSocials, fetchPosts,
            fetchActivationStats, fetchSocialReport, fetchInterests, fetchAudience, fetchUserStats,
            showBlogPreview, showInterests, showFrecency, showResponsiveness, showSocialConnectorCard,
            onClickEditBio, onClickEditFacets, onClickEditImages,
            channel
        } = this.props;

        const showPaymentStuff = !channel.disable_payments;

        switch (this.state.currentView) {

            case 'performance':
                subview = <UserDetailPerformancePane user={user} />
                break;

            case 'lists':
                subview = <UserDetailListsPane
                    user={user}
                    onClickAddToList={this.handleToggleMenuItem.bind(this, 'addToList')}
                />
                break;

            case 'interests':
                subview = <UserDetailInterestsPane fetchInterests={fetchInterests} user={user} />
                break;

            case 'content-contribute':
                subview = <ContentPaneContributeDash user={user} />
                break;

            case 'content-manage':
                subview = <ContentPaneManageDash user={user} />
                break;

            case 'campaigns-manage':
                subview = <ConnectedActivationsPage
                    key={`user-detail-activation-${user.id}`}
                    activations={activations}
                    query={{
                        id: `user-detail-activation-${user.id}`,
                        filters: {'user.id': user.id},
                    }}
                    singleUserMode={true}
                    title={"Campaigns"}
                />
                break;

            case 'campaigns-contribute':
                subview = <ConnectedParticipationPane userId={user.id} />
                break;

            case 'social':
                const socialsCount = user.socialIds.length;
                subview = <UserDetailSocialPane
                    key={`socials-count-${socialsCount}`}
                    user={user}
                    socials={socials}
                    fetchSocials={fetchSocials}
                    showSocialConnectorCard={showSocialConnectorCard}
                    onDidAttachSocial={this.handleOnDidAttachSocial.bind(this)}
                    createUserInteraction={this.props.createInteraction}
                />
                break;

            case 'email-manage':
                subview = <EmailPaneManageDash user={user} />
                break;

            case 'email-contribute':
                subview = <EmailPaneContributeDash user={user} />
                break;

            case 'todos':
                subview = <ContributorTodosPane
                    missingFields={this.state.missingFields}
                />
                break;

            case 'stats-manage':
                subview = <UserDetailStatsPaneManage
                    socials={socials}
                    posts={posts}
                    user={user}
                    activations={activations}
                    activationStats={activationStats}
                    fetchPosts={fetchPosts}
                    fetchSocials={fetchSocials}
                    fetchActivationStats={fetchActivationStats}
                    fetchActivations={fetchActivations}
                    fetchSocialReport={fetchSocialReport}
                    fetchAudience={fetchAudience}
                    fetchUserStats={fetchUserStats}
                    campaignsById={campaignsById}
                    printMode={this.state.printMode}
                />
                break;

            case 'stats-contribute':
                subview = <UserDetailStatsPaneContribute
                    socials={socials}
                    posts={posts}
                    user={user}
                    activations={activations}
                    activationStats={activationStats}
                    fetchPosts={fetchPosts}
                    fetchSocials={fetchSocials}
                    fetchActivationStats={fetchActivationStats}
                    fetchActivations={fetchActivations}
                    fetchSocialReport={fetchSocialReport}
                    fetchAudience={fetchAudience}
                    fetchUserStats={fetchUserStats}
                    campaignsById={campaignsById}
                />
                break;

            case 'profile':
            default:
                subview = <UserDetailProfilePane
                    printMode={this.state.printMode}
                    user={user}
                    showBlogPreview={showBlogPreview}
                    showInterests={showInterests}
                    showFrecency={showFrecency}
                    showResponsiveness={showResponsiveness}
                    showPayments={showPaymentStuff}
                    onClickEditBio={onClickEditBio}
                    onClickEditFacets={onClickEditFacets}
                    onClickEditImages={onClickEditImages}
                    onClickEmail={this.handleToggleMenuItem.bind(this, 'sendEmail')}
                    channel={this.props.channel}
                />

        }

        return (
            <div className="row">
                <div className="col-xs-12 user-detail-body-wrapper">
                    <div className="user-detail-body">
                        {subview}
                    </div>
                </div>
            </div>
        );
    }

    handleToggleMenuItem(item, event) {
        preventDefaultIfPossible(event);
        const itemKey = 'show_' + item;
        const newVal = !this.state[itemKey];
        let newState = {[itemKey]: newVal};
        if (newVal) {
            newState.showMenu = false;
        }
        this.setState(newState);
    }

    renderAddToList() {
        if (this.state.show_addToList === false) {
            return null;
        }

        return <AddUserToList
            style={{top: 95, right: 15}}
            user={this.props.user}
            handleClose={(e) => {
                preventDefaultIfPossible(e);
                this.setState({show_addToList: false});
            }}
            onAddToListOrBadge={() => this.setState({show_addToList: false})}
        />

    }

    renderQuickEditUserPopup() {
        if (!this.state.show_quickEditUser) {
            return null;
        }

        return (
            <Toolbox
                title={'Quick Edit User'}
                onClose={() => this.setState({show_quickEditUser: false})}
                content={<QuickEditUserForm
                    userId={this.props.user.id}
                    onSave={() => {
                        this.setState({show_quickEditUser: false});
                        this.fetchData();
                    }}
                />}
                addlClasses={'toolbox-fixed toolbox-sm'}
            />
        );
    }


    renderTeamsPopup() {
        if (!this.state.show_addToTeam) {
            return null;
        }
        const user = this.props.user;
        const profile = user.profile || {};
        const teamIds = profile.teamIds || [];

        return (
            <Toolbox
                key={'ManageUserTeamsPopup'}
                onClose={() => this.setState({show_addToTeam: false})}
                title={'Update Teams for ' + user.basics.name}
                addlClasses="toolbox-fixed toolbox-sm"
                content={
                    <ManageUserTeams
                        userId={user.id}
                        teamIds={teamIds}
                        onUpdated={() => {
                            this.props.fetchProfile();
                        }}
                    />
                }
            />
        );

    }

    renderAddSocialPopup() {
        if (!this.state.show_addSocialHandle) {
            return null;
        }

        return (
            <Toolbox
                title="Add Social Handle"
                onClose={() => this.setState({show_addSocialHandle: false})}
                content={
                    <AddSocialHandleForm
                        userId={this.props.user.id}
                        onComplete={() => {
                            this.fetchData();
                            this.setState(({show_addSocialHandle: false}));
                        }}
                    />
                }
                addlClasses={"toolbox-fixed toolbox-sm"}
            />
        )
    }

    renderSendEmailPopup() {
        if (!this.state.show_sendEmail) {
            return null;
        }

        const recipients = [
            {
                type: 'Tidal\\User',
                id: this.props.user.id,
                email: this.props.user.basics.email,
                name: this.props.user.basics.name
            }
        ];
        const style = {width: 600, top: 65, right: '50%', transform: 'translateX(50%)'};
        return (
            <Toolbox
                key={'user-detail-send-email-popup'}
                title={<><i className="fa fa-send"/> Compose</>}
                addlClasses={'list-send-email-popup toolbox-fixed'}
                style={style}
                onClose={() => this.setState({show_sendEmail: false})}
                content={<ConnectedEmailCompose
                    showTitle={false}
                    to={recipients}
                    onSent={() => this.setState({show_sendEmail: null})}
                />}

            />

        );

    }

    renderBody() {
        const user = this.props.user;
        const slug = typeof user !== 'undefined' ? user.basics.slug : '';

        if (!user || user.isFetching || !user.id) {
            return (
                <div className="tidal-alert alert alert-grey">Loading user...</div>
            )
        }

        return (
            <div className="user-detail container-fluid">
                {this.renderHeader()}

                <div className="row downgrade-alert">
                    <Alert
                        classes={['info']}
                        content={<span>Problems with this new view? Use the <a className="bold" href={"/manage/do/page/users/detail?slug=" + slug}>old user detail view</a> instead.</span>}
                        visible={false}
                    />
                </div>

                {this.renderTabs()}
                {this.renderAddToList()}
                {this.renderTeamsPopup()}
                {this.renderSendEmailPopup()}
                {this.renderAddSocialPopup()}
                {this.renderQuickEditUserPopup()}
                {this.renderSubView()}
            </div>
        )

    }

    renderPrintModePopup() {
        if (!this.state.printMode) {
            return null;
        }

        return (
            <PDFExporter
                userIds={[this.props.userId]}
                onComplete={() => this.setState({printMode: false})}
                settings={{
                    show_top_posts: true,
                    show_portfolio_images: true,
                    show_audience: true,

                    show_audience_gender: true,
                    show_audience_age: true,
                    show_audience_credibility: true,

                    show_audience_brands: true,
                    show_audience_language: true,
                    show_audience_ethnicity: true,
                    show_audience_interests: true,
                    show_audience_countries: true,
                    show_audience_cities: true,
                    show_audience_states: true,
                }}
            />
        )
    }

    render() {
        return (
            <div className={"user-detail-wrapper"}>
                {this.renderBody()}
                {this.renderPrintModePopup()}
            </div>
        )
    }

}

UserDetail.defaultProps = {
    currentView: 'profile',
    showMenuIcon: true,
    showListsIcons: true,
    showListsTab: true,
    showStarRating: true,
    showBlogPreview: true,
    showInterests: true,
    showFrecency: true,
    showResponsiveness: true,
    showProfileCompletionPct: false,
    showSecondaryStats: true,
    showActions: true,
    showTodosTab: false,
    showPerformanceTab: true,
    showContributeContentTab: false,
    showManageContentTab: true,
    showContributeCampaignsTab: false,
    showManageCampaignsTab: true,
    showContributeEmailTab: false,
    showManageEmailTab: true,
    showContributeStatsTab: false,
    showManageStatsTab: true,
    showSocialConnectorCard: false,
    showContributeDashTitle: false,
};

UserDetail.propTypes = {
    campaignsById: PropTypes.object,
    listsById: PropTypes.object,
    badgesById: PropTypes.object,

    user: PropTypes.object,
    posts: PropTypes.array,
    activations: PropTypes.array,
    activationStats: PropTypes.array,
    socials: PropTypes.array,
    listContext: PropTypes.object,
    channel: PropTypes.object,
    teams: PropTypes.array,

    fetchUser: PropTypes.func,
    fetchProfile: PropTypes.func,
    fetchActivations: PropTypes.func,
    fetchSocials: PropTypes.func,
    fetchSocialPosts: PropTypes.func,
    fetchPosts: PropTypes.func,
    fetchActivationStats: PropTypes.func,
    fetchSocialReport: PropTypes.func,
    fetchUserLists: PropTypes.func,
    fetchInterests: PropTypes.func,
    fetchAudience: PropTypes.func,
    fetchBadgesIfNeeded: PropTypes.func,
    fetchListsIfNeeded: PropTypes.func,
    fetchUserStats: PropTypes.func,

    blockUser: PropTypes.func,
    handleCloseLightbox: PropTypes.func,
    currentView: PropTypes.string,
    openNextUser: PropTypes.func,
    openPrevUser: PropTypes.func,
    onClickEditBasics: PropTypes.func,
    onClickEditBio: PropTypes.func,
    onClickEditFacets: PropTypes.func,
    onClickEditImages: PropTypes.func,

    showMenuIcon: PropTypes.bool,
    showListsIcons: PropTypes.bool,
    showListsTab: PropTypes.bool,
    showStarRating: PropTypes.bool,
    showBlogPreview: PropTypes.bool,
    showInterests: PropTypes.bool,
    showFrecency: PropTypes.bool,
    showResponsiveness: PropTypes.bool,
    showProfileCompletionPct: PropTypes.bool,
    showSecondaryStats: PropTypes.bool,
    showActions: PropTypes.bool,
    showTodosTab: PropTypes.bool,
    showPerformanceTab: PropTypes.bool,
    showContributeContentTab: PropTypes.bool,
    showManageContentTab: PropTypes.bool,
    showContributeCampaignsTab: PropTypes.bool,
    showManageCampaignsTab: PropTypes.bool,
    showContributeEmailTab: PropTypes.bool,
    showManageEmailTab: PropTypes.bool,
    showContributeStatsTab: PropTypes.bool,
    showManageStatsTab: PropTypes.bool,

    showSocialConnectorCard: PropTypes.bool,
    showContributeDashTitle: PropTypes.bool,
    createInteraction: PropTypes.func, // User interaction
};
