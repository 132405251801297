import React, {useEffect, useState} from 'react';
import Form from "../../../Common/Form/Form";
import {countries} from "../../../../utilities";
import Alert from "../../../Common/Alert";

/** countries is a hash of country codes to country names, like: {US: 'United States', ...} */
const countryOptions = Object.keys(countries).map(code => ({value: code, text: countries[code]}));

function ShippingAddress({user, refreshUser, createInteraction, translate}) {

    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [formData, setFormData] = useState({ });
    const [formChanged, setFormChanged] = useState(false);

    // load user data into form
    useEffect(() => {
        if (user && user.shipping_address) {
            setFormData(user.shipping_address);
        }
    }, []);

    // translation safety function
    const _t = (k, v) => translate ? translate('contribute.shipping_address.pane.' + k, v) : v;

    const handleSave = async () => {

        if (!user || !user.id) {
            return;
        }

        setIsSaving(true);
        setSaveError(null);
        setSaveSuccess(false);
        setFormChanged(false);

        try {
            const resp = await createInteraction(user.id, 'SetShippingAddress', formData);
            if (resp && resp.meta && resp.meta.error) {
                throw new Error(resp.meta.error);
            } else {
                setSaveSuccess(true);
                await refreshUser(user.id);
                setIsSaving(false);
            }
        } catch (e) {
            setSaveError(e.message);
        }

    };

    const renderSaveAlert = () => {

            let style = {
                position: 'sticky',
                top: 0,
                zIndex: 200,
            };

            if (isSaving) {
                return <Alert
                    classes={['info']} content={_t('alert.saving', "Saving your address...")} style={style}
                />
            }

            if (saveSuccess) {
                return <Alert
                    classes={['success']} content={_t('alert.saved', "Address saved!")} style={style}
                />
            }

            if (saveError) {
                return <Alert
                    classes={['danger']} content={saveError} style={style}
                />
            }

            if (formChanged) {
                // check if country is missing
                if (!formData.country) {
                    return (
                        <Alert
                            classes={['info']}
                            content={_t('alert.missing_country', 'Please select a country.')} style={style}
                        />
                    );
                }

                return (
                    <Alert
                        classes={['info']}
                        content={<span>{_t('contribute.profile.unsavedchanges', 'You have unsaved changes.')} <a className="v3 bold" onClick={handleSave} role="button">{_t('contribute.profile.savenow', 'Save now.')}</a></span>}
                        style={style}
                    />
                );
            }

    };

    const handleRemove = async () => {
        if (!user || !user.id) {
            return;
        }

        setIsSaving(true);
        setSaveError(null);
        setSaveSuccess(false);
        setFormChanged(false);

        try {
            const resp = await createInteraction(user.id, 'SetShippingAddress', {remove: true});
            if (resp && resp.meta && resp.meta.error) {
                throw new Error(resp.meta.error);
            } else {
                setFormData({});
                setSaveSuccess(true);
                await refreshUser(user.id);
                setIsSaving(false);
            }

        } catch (e) {
            setSaveError(e.message);
        }
    }


    return (
        <div className="pane-inner-content">

            {renderSaveAlert()}

            <p className={'v3 light h7 pane-info'}>
                {_t('description', 'Please keep your shipping address up to date.')}&nbsp;
                <a href="#" role={'button'} onClick={handleRemove} className={'v3 bold'}>{_t('remove_btn.remove', 'Or click here to remove your Shipping Address')}</a>
            </p>

            <Form
                values={formData}
                onFieldChange={(k, v) => {
                    setFormData({...formData, [k]: v});
                    setFormChanged(true);
                }}
                fields={[
                    {
                        type: 'text',
                        name: 'name',
                        title: _t('form.name.title', 'Name'),
                        help: _t('form.name.help', 'The name that should appear on any shipping labels.'),
                    },
                    {
                        type: 'text',
                        name: 'address1',
                        title: _t('form.address1.title', 'Address Line 1'),
                        help: _t('form.address1.help', 'Usually your street address, like 123 Main Street.'),
                    },
                    {
                        type: 'text',
                        name: 'address2',
                        title: _t('form.address2.title', 'Address Line 2'),
                        help: _t('form.address2.help', 'Apartment, suite, unit, building, floor, etc.'),
                    },
                    {
                        type: 'select',
                        name: 'country',
                        title: _t('form.country.title', 'Country'),
                        help: _t('form.country.help', 'The country for your shipping address.'),
                        choices: countryOptions,
                    },
                    {
                        type: 'text',
                        name: 'city',
                        title: _t('form.city.title', 'City'),
                        help: _t('form.city.help', 'The city or town where you live.'),
                    },
                    {
                        type: 'text',
                        name: 'region',
                        title: _t('form.region.title', 'State / Region'),
                        help: _t('form.region.help', 'Type the state, if in the US, or your postal region if outside the US.'),
                    },
                    {
                        type: 'text',
                        name: 'postal',
                        title: _t('form.postal.title', 'ZIP Code / Postal Code'),
                        help: _t('form.postal.help', 'The ZIP code or postal code for your area.'),
                    },
                    {
                        type: 'text',
                        name: 'phone',
                        title: _t('form.phone.title', 'Phone Number'),
                        help: _t('form.phone.help', 'A phone number that can be used to contact you about your shipping address.'),
                    },
                    {
                        type: 'text',
                        name: 'email',
                        title: _t('form.email.title', 'Email Address'),
                        help: _t('form.email.help', 'An email address that can be used to contact you about your shipping address.'),
                    },
                    {
                        type: 'textarea',
                        name: 'notes',
                        title: _t('form.notes.title', 'Additional Notes (Optional)'),
                        help: _t('form.notes.help', 'Any extra information you want to provide about your shipping address (eg: side door).'),
                    },

                ]}
            />
        </div>

    );

}

export default ShippingAddress;