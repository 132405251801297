import {
    REQUEST_EMAIL_TEMPLATE, RECEIVE_EMAIL_TEMPLATE,
    UPDATE_EMAIL_TEMPLATE, UPDATED_EMAIL_TEMPLATE,
    REQUEST_EMAIL_TEMPLATE_PREVIEW, RECEIVE_EMAIL_TEMPLATE_PREVIEW,
    REQUEST_CAMPAIGN_PAGE_TEMPLATES, RECEIVE_CAMPAIGN_PAGE_TEMPLATES,
    REQUEST_PAGE_TEMPLATE, RECEIVE_PAGE_TEMPLATE,
    REQUEST_PAGE_TEMPLATE_PREVIEW, RECEIVE_PAGE_TEMPLATE_PREVIEW,
    UPDATED_PAGE_TEMPLATE, UPDATE_PAGE_TEMPLATE
} from "../actions/templates";
import {indexArrayById} from "../utilities";

export const DEFAULT_EMAIL_TEMPLATE = {
    isFetching: false,
    isUpdating: false,
    lastUpdated: null,

    isFetchingPreview: false,
    lastUpdatedPreview: null,

    subject: null,
    html: null,
    preview: null
};

export const DEFAULT_PAGE_TEMPLATE = {
    isFetching: false,
    isUpdating: false,
    lastUpdated: null,
    isFetchingPreview: false,
    lastUpdatedPreview: null,
    preview: null
};

export function pageTemplatesById(state = {}, action) {
    switch (action.type) {


        case UPDATED_PAGE_TEMPLATE:
            return {...state, [action.id]: {
                ...state[action.id],
                ...action.template,
                isUpdating: false,
                lastUpdated: action.receivedAt,
                preview: null
            }};

        case UPDATE_PAGE_TEMPLATE:
            return {...state, [action.id]: {
                ...state[action.id],
                isUpdating: true
            }};

        case RECEIVE_PAGE_TEMPLATE_PREVIEW:
            return {...state, [action.id]: {
                ...state[action.id],
                isFetchingPreview: false,
                lastUpdatedPreview: action.receivedAt,
                preview: action.preview
            }};

        case REQUEST_PAGE_TEMPLATE_PREVIEW:
            return {...state, [action.id]: {
                ...state[action.id],
                isFetchingPreview: true
            }};

        case RECEIVE_CAMPAIGN_PAGE_TEMPLATES:
            return {
                ...state,
                ...indexArrayById(action.templates)
            };

        case RECEIVE_PAGE_TEMPLATE:
            return {
                ...state,
                [action.id]: {
                    ...action.template,
                    isFetching: false,
                    lastUpdated: action.receivedAt
                }
            };

        case REQUEST_PAGE_TEMPLATE:
            return {
                ...state,
                [action.id]: {
                    ...DEFAULT_PAGE_TEMPLATE,
                    ...state[action.id],
                    isFetching: true
                }
            };

        default: return state;
    }
}

export function emailTemplatesByKey(state = {}, action) {

    switch (action.type) {

        case UPDATED_EMAIL_TEMPLATE:
            return {...state, [action.key]: {
                ...state[action.key],
                ...action.template,
                isUpdating: false,
                lastUpdated: action.receivedAt,
                preview: null
            }};

        case UPDATE_EMAIL_TEMPLATE:
            return {...state, [action.key]: {
                ...state[action.key],
                isUpdating: true
            }};

        case RECEIVE_EMAIL_TEMPLATE_PREVIEW:
            return {...state, [action.key]: {
                ...state[action.key],
                isFetchingPreview: false,
                lastUpdatedPreview: action.receivedAt,
                preview: action.preview
            }};

        case REQUEST_EMAIL_TEMPLATE_PREVIEW:
            return {...state, [action.key]: {
                ...state[action.key],
                isFetchingPreview: true
            }};

        case RECEIVE_EMAIL_TEMPLATE:
            return {...state, [action.key]: {
                ...state[action.key],
                ...action.template,
                lastUpdated: action.receivedAt,
                isFetching: false
            }};

        case REQUEST_EMAIL_TEMPLATE:
            return {...state, [action.key]: {...DEFAULT_EMAIL_TEMPLATE, isFetching: true}};

        default: return state;
    }
}
