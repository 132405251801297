import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import UserListTable from '../../components/User/Pieces/ListTable';
import {fetchUserLists} from "../../actions/users";
import {fetchUserProfile} from "../../actions/index";

const mapStateToProps = (state, ownProps) => {
    const user = ownProps.user;
    const lists = (user.listEntries || []).map(le => Object.assign({}, state.listsById.lists[le.list_id], {entry: le}));
    const badges = (user.profile.badgeIds || []).map(badgeId => state.badgesById.items[badgeId]);

    return {lists, badges};
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const user = ownProps.user;
    return {
        fetchUserLists: () => dispatch(fetchUserLists(user.id)),
        fetchUserProfile: () => dispatch(fetchUserProfile(user.id))
    }
};

const ConnectedUserListTable = connect(mapStateToProps, mapDispatchToProps)(UserListTable);

ConnectedUserListTable.propTypes = {
    user: PropTypes.object.isRequired,
    tableClass: PropTypes.string,
};

export default ConnectedUserListTable;