/**
 * Created by bkanber on 7/10/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import {formatNumberK, countries, fetchAuthenticated} from '../../../utilities';
import {DEFAULT_USER_FILTERS} from '../../../reducers/userQueries';
import Autosuggest from 'react-autosuggest';
import * as URI from 'urijs';
import moment from 'moment';
import {responsivenessLabel, frecencyLabel} from "../../../utilities/user";
import DateRange from "../../Common/Form/DateRange";
import UserChannelStatusSelect from "../Pieces/UserChannelStatusSelect";
import ReactTagAutocomplete from 'react-tag-autocomplete';
import Form from "../../Common/Form/Form";

const AGE_MIN = 16;
const AGE_MAX = 80;
const FRECENCY_MAX = 10;

const REACH_FIELDS = ['reach', 'instagramReach', 'blogReach', 'youtubeReach', 'twitterReach', 'pinterestReach', 'facebookReach'];
const REACH_MAX = 250000;

const PARENTHOOD_FACET_OPTIONS = [
    'No Children',
    'Has Children',
    'Pregnant',
    'Twins',
    'Triplets',
];

const LANGUAGE_FACET_OPTIONS = [
    'English',
    'Spanish',
    'Portugese',
    'French',
    'Russian',
    'German',
    'Polish',
    'Chinese',
    'Korean',
    'Japanese',
    'Hindi',
    'Arabic',
    'Turkish',
    'Italian',
    'Dutch',
    'Swedish',
    'Greek',
    'Hebrew',
    'Other',
];

const RACE_FACET_OPTIONS = [
    'White',
    {'text': 'Black or African American', 'value': 'Black'},
    'Asian',
    'Indian',
    {'text': 'Latino or Hispanic', 'value': 'Hispanic'},
    'Other',
];

const CATEGORY_FACET_OPTIONS = [
    'Arts',
    'Automotive',
    'Beauty',
    'Business',
    'Corporate/Brand',
    'DIY',
    'Entertainment',
    'Fashion',
    'Finance',
    'Food',
    'Gadgets',
    'Health and Fitness',
    'House and Home',
    'Journalism',
    'Literature',
    'Lifestyle',
    'Liquor',
    'News',
    'Parenting',
    'Sports',
    'Shopping',
    'Tabloid',
    'Technology',
    'Travel',
    'Other',

];

const RELATIONSHIP_FACET_OPTIONS = [
    'Single',
    'Married',
    'Engaged',
    'Long-Term',
    'Dating'
];


class UserFilters extends Component {

    constructor(props) {
        super(props);

        let combinedFilters = Object.assign({}, DEFAULT_USER_FILTERS, props.filters);

        this.state = Object.assign({}, combinedFilters,
            {
                showBasics: true,
                showDemographics: this.hasAnyFiltersForSection('showDemographics', combinedFilters),
                showSocial: this.hasAnyFiltersForSection('showSocial', combinedFilters),
                showUser: this.hasAnyFiltersForSection('showUser', combinedFilters),
                showCampaign: this.hasAnyFiltersForSection('showCampaign', combinedFilters),
                showAudience: this.hasAnyFiltersForSection('showAudience', combinedFilters),
                interestsSuggestions: [], // native interests field
                responsiveness: {min: 'Low', max: 'Very High'},
                activity: {min: 'Very Low', max: 'Very High'},
                // These are specifically for socialdata fields
                geo_suggestions: [],
                interests_suggestions: [],
                brands_suggestions: [],
            }
        );

        this.handleShowHideSection = this.handleShowHideSection.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);

        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);

        this.handleResponseProbabilityChange = this.handleResponseProbabilityChange.bind(this);
        this.handleFrecencyChange = this.handleFrecencyChange.bind(this);

        this._suggestionsTimer = null;
    }

    componentDidMount() {
        this.fetchInterestsSuggestions();
    }

    getValidatorFn(field) {
        switch (field) {
            case 'reach':
            case 'instagramReach':
            case 'blogReach':
            case 'twitterReach':
            case 'youtubeReach':
            case 'facebookReach':
            case 'pinterestReach':
                return (state) => state[field].min !== 0 || state[field].max !== REACH_MAX;
            case 'age':
                return (state) => state[field].min !== AGE_MIN || state[field].max !== AGE_MAX;
            case 'response_probability':
                return (state) => state[field].min !== 0 || state[field].max !== 1;
            case 'frecency':
                return (state) => state[field].min !== 0 || state[field].max !== FRECENCY_MAX;
            case 'created_at':
                return (state) => state[field] && (state[field].min || state[field].max);
            case 'channelStatus':
                return (state) => typeof state[field] !== 'undefined' && state[field] !== null;

            default:
                return (state) => state[field];
        }

    }

    async fetchInterestsSuggestions () {
        const slot = window.Gator.getDashboardSlot();
        const url = `/${slot}/api/socialdata/_/interest`;
        const resp = await this.props.fetchAuthenticated(url);
        const json = await resp.json();

        this.setState({
            interests_suggestions: json.data.data.interests,
            brands_suggestions: json.data.data.brands,
        });
    }

    async fetchGeoSuggestions(input) {

        const slot = window.Gator.getDashboardSlot();
        const uri = new URI(`/${slot}/api/socialdata/_/geo`);
        uri.search({q: input});
        const resp = await this.props.fetchAuthenticated(uri.toString());
        const json = await resp.json();
        this.setState({
            geo_suggestions: json.data
        });

    }

    hasValidValue(field, state) {
        return this.getValidatorFn(field)(state);
    }

    hasAnyFiltersForSection(section, filters) {

        const defs = {
            showBasics: ['tier', 'group', 'interests'],
            showDemographics: ['age', 'gender', 'human_location', 'category', 'language', 'race', 'parenthood', 'relationship', 'response_probability', 'frecency', 'content', 'country_name'],
            showUser: ['name', 'email', 'blog_name', 'created_at', 'channelStatus'],
            showSocial: ['instagram'].concat(REACH_FIELDS),
            showCampaign: ['campaign', 'notCampaign'],
            showAudience: ['audience.gender', 'audience.age']
        };

        const fields = defs[section];
        for (let field of fields) {
            if (this.hasValidValue(field, filters)) return true;
        }

        return false;
    }


    getFiltersPayload() {

        let filters = {};
        let keys = Object.keys(DEFAULT_USER_FILTERS);

        for (let key of keys) {

            let val = this.state[key];
            let valid = this.hasValidValue(key, this.state);

            if (valid) {
                filters[key] = val;
            }

        }

        // add custom search fields
        if (this.state.custom_search) {
            filters.custom_search = this.state.custom_search;
        }

        return filters;
    }

    deliverFiltersPayload() {
        if (typeof this.props.onFilterChange === 'function') {
            this.props.onFilterChange(this.getFiltersPayload());
        }
    }

    handleFrecencyChange(key, value) {

        const max = frecencyLabel(value.max);
        const min = frecencyLabel(value.min);
        this.setState({
            activity: {min, max}
        });
        this.handleRangeChange(key, value);

    }

    handleResponseProbabilityChange(key, value) {

        const max = responsivenessLabel(value.max);
        const min = responsivenessLabel(value.min);
        this.setState({
            responsiveness: {min, max}
        });
        this.handleRangeChange(key, value);

    }

    handleRangeChange(key, value) {
        this.setState({[key]: value}, this.deliverFiltersPayload);
    }

    handleFilterChange(event) {
        const target = event.target;
        const key = target.name;
        const value = target.value;

        let newState = {[key]: value};
        this.setState(newState, this.deliverFiltersPayload);
    }

    handleShowHideSection(section, event) {
        event.preventDefault();
        this.setState({[section]: !this.state[section]})
    }

    getAngleIcon(section) {
        if (this.state[section]) {
            return <i className="fa fa-angle-down"></i>
        } else {
            return <i className="fa fa-angle-right"></i>
        }
    }

    renderCountryNameSelectOptions() {
        return [
            <option value="">Select a Country</option>
        ].concat(
            Object.values(countries)
                .map(country => {
                    return <option value={country.toLowerCase()}>{country}</option>
                })
        );

    }

    renderCampaignSelectOptions() {

        return [
            <option value="">Select a Campaign</option>
        ].concat(
            this.props.campaigns
                .filter(campaign => campaign.status !== 'building')
                .sort((a, b) => {
                    const astart = a.start ? moment(a.start.date) : moment('2010-01-01');
                    const bstart = b.start ? moment(b.start.date) : moment('2010-01-01');
                    return astart.isAfter(bstart) ? -1 : 1;
                })
                .map(campaign => {
                    return <option value={campaign.id}>{campaign.name}</option>
                })
        );

    }

    renderTeamSelect() {

        const teamOptions = this.props.teams.map(team => {
            return <option value={team.id}>{team.name}</option>
        });

        if (teamOptions.length === 0) {
            return null;
        }

        return (
            <div className="form-group">
                <label><strong>Team</strong></label>
                <select
                    className="form-control"
                    name="team"
                    value={this.state.team}
                    onChange={this.handleFilterChange}
                >
                    <option value="">All Teams</option>
                    <option value="none">No Teams</option>
                    {teamOptions}
                </select>
            </div>
        );
    }

    renderGroupSelect() {

        const {presence} = this.props;
        const allowedTeamIds = (((presence || {}).user || {}).teams || []);

        // Filter lists by user teams
        const listOptions = this.props.lists
            .filter(list => {
                if (allowedTeamIds.length === 0) return true;
                if ((list.teams || []).length === 0) return true;
                for (let t of list.teams) {
                    if (allowedTeamIds.indexOf(t.id) > -1) {
                        return true;
                    }
                }
                return false;
            })
            .map(list => {
                const value = "list:" + list.id;
                return <option value={value}>List: {list.name}</option>
            });

        const badgeOptions = this.props.badges.map(badge => {
            const value = "badge:" + badge.id;
            return <option value={value}>Badge: {badge.name}</option>
        });

        const networkOptions = this.props.networks.map(network => {
            const value = "network:" + network.id;
            const name = network.name.replace('Tidal ', '');
            return <option value={value}>Network: {name}</option>
        });

        return (
            <select
                className="form-control"
                name="group"
                value={this.state.group}
                onChange={this.handleFilterChange}
            >
                <option value="brand">All Brand Creators</option>
                <option value="activated">Activated Creators Only</option>
                <option value='unactivated'>Unactivated Creators Only</option>
                <option value="activationCompleted">Completed Activations Only</option>
                <option value="owned">Owned Creators Only</option>
                <option value="admins">Admins &amp; Managers</option>
                <option value="blocked">Blocked Users</option>
                {listOptions}
                {badgeOptions}
                {networkOptions}
            </select>
        );
    }

    onSuggestionsFetchRequested({value}) {

        const TIMEOUT = 400;

        if (this._suggestionsTimer) {
            clearTimeout(this._suggestionsTimer);
            this._suggestionsTimer = null;
        }

        this._suggestionsTimer = setTimeout(() => {

            const url = URI('/manage/api/autocomplete/_/allinterest')
                .query({search: value})
                .toString();

            console.log("Search interests for value " + value + " at url " + url);

            fetchAuthenticated(url, {credentials: 'include'})
                .then(response => response.json())
                .then(json => {
                    this.setState({
                        interestsSuggestions: json.data
                    });
                })
                .catch(err => {
                    console.log("Error fetching interests")
                    console.log(err)
                });

        }, TIMEOUT);
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested() {
        this.setState({
            interestsSuggestions: []
        });
    };

    onSuggestionSelected(event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) {
        this.setState({interests: suggestionValue}, this.deliverFiltersPayload);
    }


    renderBasicFilters() {

        const interestsInputProps = {
            placeholder: 'Search by Interest...',
            value: this.state.interests ? this.state.interests : '',
            onChange: this.handleFilterChange,
            className: 'form-control',
            name: 'interests'
        };

        const getInterestsSuggestionValue = suggestion => suggestion.name;
        const renderInterestsSuggestion = suggestion => (
            <div>
                {suggestion.name}
            </div>
        );

        return (
            <div className="filter-section tidal-form">
                <h2>
                    <a href="#" onClick={this.handleShowHideSection.bind(this, 'showBasics')}>
                        Filter by
                        {this.getAngleIcon('showBasics')}
                    </a>
                </h2>
                {this.state.showBasics &&
                <div className="filter-content ">

                    <div className="form-group">
                        <label><strong>Tier</strong></label>
                        <select
                            className="form-control"
                            name="tier"
                            onChange={this.handleFilterChange}
                            value={this.state.tier}
                        >
                            <option value="">Select a Tier</option>
                            <option value="0">Up-And-Comer</option>
                            <option value="1,2,3">Prosumer</option>
                            <option value="4,5,6">Maven</option>
                            <option value="7,8,9,10,11,12">Celeb</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label><strong>List</strong></label>
                        {this.renderGroupSelect()}
                    </div>

                    {this.renderTeamSelect()}

                    <div className="form-group">
                        <label><strong>Interests</strong></label>
                        <Autosuggest
                            suggestions={this.state.interestsSuggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            onSuggestionSelected={this.onSuggestionSelected}
                            getSuggestionValue={getInterestsSuggestionValue}
                            renderSuggestion={renderInterestsSuggestion}
                            inputProps={interestsInputProps}
                        />

                    </div>

                </div>
                }
            </div>
        );

    }

    renderCustomFilters() {

        const presence = this.props.presence;
        const channel = (presence || {}).channel || {};
        const fields = (channel.additional_user_search_fields || []);

        if (!fields.length) {
            return null;
        }

        return (
            <div className="filter-section tidal-form">
                <h2>
                    <a href="#" onClick={this.handleShowHideSection.bind(this, 'showCustom')}>
                        Custom Fields
                        {this.getAngleIcon('showCustom')}
                    </a>
                </h2>
                {this.state.showCustom &&
                    <div className="filter-content ">
                        <Form
                            usePadding={false}
                            values={this.state.custom_search || {}}
                            fields={fields}
                            onFieldChange={(key, value) => {
                                const cleanKey = key.replace('custom_search.', '');
                                const prevCustomSearch = this.state.custom_search || {};
                                const customSearch = Object.assign({}, prevCustomSearch, {[cleanKey]: value});
                                this.setState({custom_search: customSearch}, this.deliverFiltersPayload);
                            }}
                        />
                    </div>
                }
            </div>
        );

    }

    renderUserFilters() {

        return (
            <div className="filter-section tidal-form">
                <h2>
                    <a href="#" onClick={this.handleShowHideSection.bind(this, 'showUser')}>
                        Find a User
                        {this.getAngleIcon('showUser')}
                    </a>
                </h2>
                {this.state.showUser &&
                <div className="filter-content ">

                    <div className="form-group">
                        <label><strong>Status</strong></label>
                        <UserChannelStatusSelect
                            onChange={({value}) => this.handleFilterChange({target: {name: 'channelStatus', value}})}
                            status={this.state.channelStatus}
                        />

                    </div>
                    <div className="form-group">
                        <label><strong>Name</strong></label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            onChange={this.handleFilterChange}
                            value={this.state.name}
                            placeholder="Search by name..."
                        />
                    </div>

                    <div className="form-group">
                        <label><strong>Email</strong></label>
                        <input
                            className="form-control"
                            name="email"
                            onChange={this.handleFilterChange}
                            value={this.state.email}
                            placeholder="Search by email..."
                        />
                    </div>

                    <div className="form-group">
                        <label><strong>Instagram Handle</strong></label>
                        <input
                            name="instagram"
                            value={this.state.instagram}
                            onChange={this.handleFilterChange}
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                        />
                    </div>

                    <div className="form-group">
                        <label><strong>Blog Name</strong></label>
                        <input
                            className="form-control"
                            name="blog_name"
                            onChange={this.handleFilterChange}
                            value={this.state.blog_name}
                            placeholder="Search by blog..."
                        />
                    </div>

                    <div className="form-group">
                        <label><strong>Joined Between</strong></label>
                        <DateRange
                            onChange={this.handleRangeChange.bind(this, 'created_at')}
                            value={this.state.created_at}
                            placeholder="Search by date..."
                        />
                    </div>

                </div>
                }
            </div>
        );

    }

    renderFacetGroup(name, title, values) {

        const renderOption = (option) => {
            const text = typeof option === 'string' ? option : option.text;
            const value = typeof option === 'string' ? option : option.value;
            return <option value={value}>{text}</option>
        }

        const options = values.map(value => renderOption(value));

        return (

            <div className="form-group">
                <label><strong>{title}</strong></label>
                <select
                    name={name}
                    onChange={this.handleFilterChange}
                    value={this.state[name]}
                    className="form-control"
                >
                    <option value="">Any</option>
                    {options}
                </select>
            </div>
        )

    }

    renderDemographicFilters() {
        return (

            <div className="filter-section tidal-form">
                <h2>
                    <a href="#" onClick={this.handleShowHideSection.bind(this, 'showDemographics')}>
                        Demographics &amp; Content
                        {this.getAngleIcon('showDemographics')}
                    </a>
                </h2>
                {this.state.showDemographics &&
                <div className="filter-content">

                    <div className="form-group">
                        <label><strong>Age Range:</strong> {this.state.age.min}-{this.state.age.max}</label>
                        <InputRange
                            maxValue={AGE_MAX}
                            minValue={AGE_MIN}
                            value={this.state.age}
                            onChange={value => this.handleRangeChange('age', value)}
                            formatLabel={value => ''}

                        />
                    </div>

                    <div className="form-group">
                        <label><strong>Responsiveness:</strong> {this.state.responsiveness.min}-{this.state.responsiveness.max}</label>
                        <InputRange
                            maxValue={1}
                            minValue={0}
                            value={this.state.response_probability}
                            onChange={value => this.handleResponseProbabilityChange('response_probability', value)}
                            formatLabel={value => ''}
                            step={0.1}

                        />
                    </div>

                    <div className="form-group">
                        <label><strong>Activity:</strong> {this.state.activity.min}-{this.state.activity.max}</label>
                        <InputRange
                            maxValue={FRECENCY_MAX}
                            minValue={0}
                            value={this.state.frecency}
                            onChange={value => this.handleFrecencyChange('frecency', value)}
                            formatLabel={value => ''}
                            step={1}
                        />
                    </div>

                    <div className="form-group">
                        <label><strong>Gender</strong></label>
                        <select
                            name="gender"
                            onChange={this.handleFilterChange}
                            value={this.state.gender}
                            className="form-control"
                        >
                            <option value="">Any</option>
                            <option value="female">Female</option>
                            <option value="male">Male</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label><strong>City, State, or Region</strong></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Location..."
                            name="human_location"
                            value={this.state.human_location}
                            onChange={this.handleFilterChange}
                        />

                    </div>

                    <div className="form-group">
                        <label><strong>Country</strong></label>
                        <select
                            className="form-control"
                            name="country_name"
                            value={this.state.country_name}
                            onChange={this.handleFilterChange}
                        >
                            {this.renderCountryNameSelectOptions()}
                        </select>

                    </div>

                    <div className="form-group">
                        <label><strong>Content Keywords</strong></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Content Keyword..."
                            name="content"
                            value={this.state.content}
                            onChange={this.handleFilterChange}
                        />

                    </div>

                    {this.renderFacetGroup("category", "Category", CATEGORY_FACET_OPTIONS)}
                    {this.renderFacetGroup("race", "Race", RACE_FACET_OPTIONS)}
                    {this.renderFacetGroup("language", "Language", LANGUAGE_FACET_OPTIONS)}
                    {this.renderFacetGroup("relationship", "Relationship", RELATIONSHIP_FACET_OPTIONS)}
                    {this.renderFacetGroup("parenthood", "Parenthood", PARENTHOOD_FACET_OPTIONS)}

                </div>
                }
            </div>

        )
    }

    renderCampaignFilters() {

        if (!this.props.showCampaignFilters) {
            return null;
        }

        return (

            <div className="filter-section tidal-form">
                <h2>
                    <a href="#" onClick={this.handleShowHideSection.bind(this, 'showCampaign')}>
                        Campaigns
                        {this.getAngleIcon('showCampaign')}
                    </a>
                </h2>

                {this.state.showCampaign &&
                <div className="filter-content">
                    <div className="form-group">
                        <label><strong>In Campaign:</strong></label>
                        <select
                            className="form-control"
                            name="campaign"
                            value={this.state.campaign}
                            onChange={this.handleFilterChange}
                        >
                            {this.renderCampaignSelectOptions()}
                        </select>
                    </div>

                    <div className="form-group">
                        <label><strong>Not In Campaign:</strong></label>
                        <select
                            className="form-control"
                            name="notCampaign"
                            value={this.state.notCampaign}
                            onChange={this.handleFilterChange}
                        >
                            {this.renderCampaignSelectOptions()}
                        </select>
                    </div>

                </div>
                }
            </div>


        )

    }

    renderAudienceFilters() {
        return (
            <div className="filter-section tidal-form">
                <h2>
                    <a href="#" onClick={this.handleShowHideSection.bind(this, 'showAudience')}>
                        Audience
                        {this.getAngleIcon('showAudience')}
                    </a>
                </h2>

                {this.state.showAudience && (
                    <div className="filter-content">

                        <div className="form-group">
                            <label><strong>Audience Gender</strong></label>
                            <select
                                className="form-control"
                                name="audience.gender"
                                value={this.state['audience.gender']}
                                onChange={this.handleFilterChange}
                            >
                                <option>- Any Gender -</option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label><strong>Audience Age</strong></label>
                            <select
                                className="form-control"
                                name="audience.age"
                                value={this.state['audience.age']}
                                onChange={this.handleFilterChange}
                            >
                                <option>- Any Age -</option>
                                <option value="13-17">13-17</option>
                                <option value="18-24">18-24</option>
                                <option value="25-34">25-34</option>
                                <option value="35-44">35-44</option>
                                <option value="45-64">45-64</option>
                                <option value="65-">65-</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label><strong>Audience Ethnicity</strong></label>
                            <select
                                className="form-control"
                                name="audience.ethnicity"
                                value={this.state['audience.ethnicity']}
                                onChange={this.handleFilterChange}
                            >
                                <option>- Any Ethnicity -</option>
                                <option value="white">White / Caucasian</option>
                                <option value="asian">Asian</option>
                                <option value="african_american">African American</option>
                                <option value="hispanic">Hispanic</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label><strong>Audience Brand Affinity</strong></label>
                            <ReactTagAutocomplete
                                allowNew={false}
                                tags={this.state['audience.brands'] ? [ {name: this.state['audience.brands']} ] : []}
                                placeholder={'Type a brand...'}
                                suggestions={this.state.brands_suggestions || []}
                                autofocus={false}
                                addOnBlur={true}
                                handleAddition={item => {
                                    console.log(item)
                                    this.setState({'audience.brands': item.name}, this.deliverFiltersPayload);
                                }}
                                handleDelete={index => {
                                    this.setState({'audience.brands': null}, this.deliverFiltersPayload);
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label><strong>Audience Interests</strong></label>
                            <ReactTagAutocomplete
                                allowNew={false}
                                tags={this.state['audience.interests'] ? [ {name: this.state['audience.interests']} ] : []}
                                placeholder={'Type a topic...'}
                                suggestions={this.state.interests_suggestions || []}
                                autofocus={false}
                                addOnBlur={true}
                                handleAddition={item => {
                                    this.setState({'audience.interests': item.name}, this.deliverFiltersPayload);
                                }}
                                handleDelete={index => {
                                    this.setState({'audience.interests': null}, this.deliverFiltersPayload);
                                }}
                            />
                        </div>

                        <div className="form-group">
                            <label><strong>Audience Location</strong></label>
                            <ReactTagAutocomplete
                                allowNew={false}
                                tags={this.state['audienceGeo'] ? [ {name: this.state['audienceGeo']} ] : []}
                                placeholder={'Type a location...'}
                                suggestions={this.state.geo_suggestions || []}
                                autofocus={false}
                                addOnBlur={true}
                                handleAddition={item => {
                                    this.setState({'audienceGeo': item.name}, this.deliverFiltersPayload);
                                }}
                                handleDelete={index => {
                                    this.setState({'audienceGeo': null}, this.deliverFiltersPayload);
                                }}
                                handleInputChange={input => {
                                    if (input.length > 2) {
                                        this.fetchGeoSuggestions(input);
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>

        );
    }

    renderSocialFilters() {

        return (

            <div className="filter-section tidal-form">
                <h2>
                    <a href="#" onClick={this.handleShowHideSection.bind(this, 'showSocial')}>
                        Social
                        {this.getAngleIcon('showSocial')}
                    </a>
                </h2>

                {this.state.showSocial &&
                    <div className="filter-content">
                        <div className="form-group">
                            <label><strong>Instagram Handle</strong></label>
                            <input
                                name="instagram"
                                value={this.state.instagram}
                                onChange={this.handleFilterChange}
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                            />
                        </div>
                        <div className="form-group">
                            <label><strong>TikTok Handle</strong></label>
                            <input
                                name="tiktok"
                                value={this.state.tiktok}
                                onChange={this.handleFilterChange}
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                            />
                        </div>

                        <div className="form-group">
                            <label><strong>Verified
                                Reach:</strong>
                                {formatNumberK(this.state.reach.min)}-{formatNumberK(this.state.reach.max)}{this.state.reach.max === REACH_MAX ? '+' : ''}
                            </label>

                            <InputRange
                                maxValue={REACH_MAX}
                                minValue={0}
                                step={1000}
                                value={this.state.reach}
                                onChange={value => this.handleRangeChange('reach', value)}
                                formatLabel={value => ''}

                            />
                        </div>

                        <div className="form-group">
                            <label><strong>Instagram
                                Reach:</strong> {formatNumberK(this.state.instagramReach.min)}-{formatNumberK(this.state.instagramReach.max)}{this.state.instagramReach.max === REACH_MAX ? '+' : ''}
                            </label>

                            <InputRange
                                maxValue={REACH_MAX}
                                minValue={0}
                                step={1000}
                                value={this.state.instagramReach}
                                onChange={value => this.handleRangeChange('instagramReach', value)}
                                formatLabel={value => ''}

                            />
                        </div>

                        <div className="form-group">
                            <label><strong>Blog
                                Uniques:</strong> {formatNumberK(this.state.blogReach.min)}-{formatNumberK(this.state.blogReach.max)}{this.state.blogReach.max === REACH_MAX ? '+' : ''}
                            </label>

                            <InputRange
                                maxValue={REACH_MAX}
                                minValue={0}
                                step={1000}
                                value={this.state.blogReach}
                                onChange={value => this.handleRangeChange('blogReach', value)}
                                formatLabel={value => ''}

                            />
                        </div>

                        <div className="form-group">
                            <label><strong>Youtube
                                Subscribers:</strong> {formatNumberK(this.state.youtubeReach.min)}-{formatNumberK(this.state.youtubeReach.max)}{this.state.youtubeReach.max === REACH_MAX ? '+' : ''}
                            </label>

                            <InputRange
                                maxValue={REACH_MAX}
                                minValue={0}
                                step={1000}
                                value={this.state.youtubeReach}
                                onChange={value => this.handleRangeChange('youtubeReach', value)}
                                formatLabel={value => ''}

                            />
                        </div>

                        <div className="form-group">
                            <label><strong>Pinterest
                                Following:</strong> {formatNumberK(this.state.pinterestReach.min)}-{formatNumberK(this.state.pinterestReach.max)}{this.state.pinterestReach.max === REACH_MAX ? '+' : ''}
                            </label>

                            <InputRange
                                maxValue={REACH_MAX}
                                minValue={0}
                                step={1000}
                                value={this.state.pinterestReach}
                                onChange={value => this.handleRangeChange('pinterestReach', value)}
                                formatLabel={value => ''}

                            />
                        </div>

                        <div className="form-group">
                            <label><strong>Twitter
                                Following:</strong> {formatNumberK(this.state.twitterReach.min)}-{formatNumberK(this.state.twitterReach.max)}{this.state.twitterReach.max === REACH_MAX ? '+' : ''}
                            </label>

                            <InputRange
                                maxValue={REACH_MAX}
                                minValue={0}
                                step={1000}
                                value={this.state.twitterReach}
                                onChange={value => this.handleRangeChange('twitterReach', value)}
                                formatLabel={value => ''}

                            />
                        </div>

                        <div className="form-group">
                            <label><strong>Facebook
                                Reach:</strong> {formatNumberK(this.state.facebookReach.min)}-{formatNumberK(this.state.facebookReach.max)}{this.state.facebookReach.max === REACH_MAX ? '+' : ''}
                            </label>

                            <InputRange
                                maxValue={REACH_MAX}
                                minValue={0}
                                step={1000}
                                value={this.state.facebookReach}
                                onChange={value => this.handleRangeChange('facebookReach', value)}
                                formatLabel={value => ''}

                            />
                        </div>

                    </div>
                }
            </div>


        )

    }

    render() {
        return (
            <div className="user-filters">

                {this.renderBasicFilters()}
                {this.renderUserFilters()}
                {this.renderCustomFilters()}
                {this.renderCampaignFilters()}
                {this.renderDemographicFilters()}
                {this.renderSocialFilters()}
                {this.renderAudienceFilters()}


            </div>
        )
    }

}

UserFilters.defaultProps = {
    showCampaignFilters: false,
    campaigns: []
};

UserFilters.propTypes = {
    onFilterChange: PropTypes.func,
    showCampaignFilters: PropTypes.bool,
    campaigns: PropTypes.array,
    lists: PropTypes.array,
    badges: PropTypes.array,
    networks: PropTypes.array,
    teams: PropTypes.array,
    presence: PropTypes.object,
};

export default UserFilters;
