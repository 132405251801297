import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getLists} from "../../selectors/lists";
import Alert from "../Common/Alert";
import Checkbox from "../Common/Form/Checkbox";
import Button from "../Common/Form/Button";

class CopyEntriesToOtherLists extends Component {
    static propTypes = {

        lists: PropTypes.array,
        onClickSubmit: PropTypes.func,
        isSubmitting: PropTypes.bool,

    };

    state = {
        isSubmitting: false,
        selected: {}
    };

    getSelectedIds() {
        return Object.keys(this.state.selected)
            .filter(id => !!this.state.selected[id]);
    }

    isSelected(id) {
        return !!this.state.selected[id];
    }

    toggleSelected(id) {
        this.setState({
            selected: {...this.state.selected, [id]: !this.isSelected(id)}
        });
    }

    handleSubmit() {
        if (!this.props.onClickSubmit) {
            return;
        }

        return this.props.onClickSubmit(this.getSelectedIds());
    }

    renderAlert() {

        return <Alert classes={['info']} content={'Copy selected items to other lists, preserving settings.'} />

    }

    renderSelector() {

        return (
            <div
                className="fake-table"
                style={{
                    overflow: 'auto',
                    maxHeight: 300
                }}
            >
                {this.props.lists.map(list => {
                    return (
                        <div
                            className="fake-li bottom-border"
                            style={{paddingLeft: 15}}
                            key={'list-item-wrapper-'+list.id}
                        >
                            <Checkbox
                                onClick={() => this.toggleSelected(list.id)}
                                checked={this.isSelected(list.id)}
                                label={list.name}
                                useBoldTitle={false}
                            />
                        </div>

                    );
                })}
            </div>
        );

    }

    renderSubmit() {
        const {isSubmitting} = this.props;
        const hasSelections = this.getSelectedIds().length > 0;

        return (
            <div style={{padding: 15}}>

                <Button
                    content={isSubmitting ? 'Copying Items to Lists...' : 'Copy Items to Lists'}
                    classes={['v3', 'btn-primary', 'medium', 'fullwidth', (isSubmitting || !hasSelections) ? 'disabled' : '']}
                    onClick={this.handleSubmit.bind(this)}
                />
            </div>
        );
    }


    render() {
        return (

            <div>
                {this.renderAlert()}
                {this.renderSelector()}
                {this.renderSubmit()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        lists: getLists(state, props)
    };
};

export default connect(mapStateToProps)(CopyEntriesToOtherLists);
