import React, {useEffect, useState} from "react";
import TrendingPostTile from "./TrendingPostTile";
import * as URI from "urijs";

function DashboardSpotlight({fetchAuthenticated, team, postLimit=5, translate}) {
    const _t = (k, v) => translate ? translate(k, v) : v;
    const [page, setPage] = useState(1);
    const [posts, setPosts] = useState([]);
    const [totalPosts, setTotalPosts] = useState(0);

    const RenderedPosts = (posts || [])
            .slice(0, postLimit)
            .map(post => {
                return <TrendingPostTile post={post} key={post.id} />
            });

    const fetchData = async (page) => {
        let uri = new URI('/contribute/api/post/_/featured');
        uri.addQuery('page', page);
        uri.addQuery('limit', postLimit);
        if (team && team.id) {
            uri.addQuery('team', team.id);
        }
        const url = uri.toString();
        return fetchAuthenticated(url)
            .then(resp => resp.json())
            .then(json => {
                setTotalPosts(json.meta.hits);
                setPosts(json.data);
            });
    };
    useEffect(function() {
        fetchData(page).then(() => console.log("Finished fetching page " + page));
    }, [page, team, postLimit]);

    const hasNextPage = totalPosts > (page*postLimit);

    if (!posts || posts.length === 0) {
        return null;
    }

    const wdPrev = _t('creator.words.previous', 'Prev');
    const wdNext = _t('creator.words.next', 'Next');
    const wdSpotlight = _t('creator.words.spotlight', 'Spotlight');
    const descSpotlight = _t('creator.sections.spotlight.description', 'Content loved and featured by your local and regional leads.');

    return (
        <div className="dash-section">
            <div className="section-header">
                <h1 className="red-indent">#{wdSpotlight}
                    {page > 1 ? (
                        <a className="seeall" onClick={() => setPage(prevPage => prevPage-1)}><i className="v3 icon fa-arrow-left" style={{marginRight: 8}}/>{wdPrev}</a>
                    ) : null}

                    {hasNextPage ? (
                        <a className="seeall" onClick={() => setPage(prevPage => prevPage + 1)}>{wdNext}<i
                            className="v3 icon fa-arrow-right"/></a>) : null }
                </h1>
                <p className="section-subtitle">{descSpotlight}</p>
            </div>
            <div className="section-content">

                <div className="ic-posts">
                    {RenderedPosts}
                </div>

            </div>

        </div>
    );
}

export default DashboardSpotlight;