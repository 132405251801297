import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Table from '../../Table/Table';
import CheckboxCell from '../../Table/Cell/Checkbox';
import BasicCell from "../../Table/Cell/Basic";

class EmailSettingsTable extends Component {

    static propTypes = {
        settings: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    getItems() {


        const items = [
            {
                key: 'send_issue_emails',
                name: 'Issue Updates',
                description: 'Sends the user emails about new comments or updates on their raised issues.'
            },
            {
                key: 'send_invitation_email',
                name: 'Invitation Email',
                description: 'Sends invitation emails. Recommended setting = on.'
            },
            {
                key: 'send_invitation_reminder_email',
                name: 'Invitation Reminders',
                description: 'Invitation reminders will automatically be sent 3 days after the invitation.'
            },
            {
                key: 'send_waitlist_email',
                name: 'Waitlist Notification',
                description: 'Sends the user a message when they are waitlisted in the campaign.'
            },
            {
                key: 'send_accepted_email',
                name: 'Accepted after Waitlist',
                description: 'Sends the user a message when they are released from the waitlist.'
            },
            {
                key: 'send_shipping_address_reminder_email',
                name: 'Confirm Address',
                description: 'Reminds the user to confirm their shipping address.',
                isVisible: (settings) => !!settings.use_products,
            },
            {
                key: 'send_shipping_email',
                name: 'Product Shipped',
                description: 'Sends the user a message when their product sample has been shipped.',
                isVisible: (settings) => !!settings.use_products,
            },
            {
                key: 'send_draft_deadline_reminder',
                name: 'Draft Deadline Reminder',
                description: 'Sends the user a message when the deadline for draft submissions is 3 days away.',
                isVisible: (settings) => !!settings.use_deadlines,
            },
            {
                key: 'send_draft_emails',
                name: 'Draft Updates',
                description: 'Sends the user emails about new comments or status updates on their drafts.',
                isVisible: (settings) => !!settings.use_blog_drafts || !!settings.use_social_drafts,
            },
            {
                key: 'send_post_deadline_reminder',
                name: 'Post Deadline Reminder',
                description: 'Sends the user a message when the deadline for post submissions is 3 days away.',
                isVisible: (settings) => !!settings.use_deadlines,
            },
            {
                key: 'send_invoice_email',
                name: 'Invoice Notification',
                description: 'Sends the user a message when their invoice has been approved.',
                isVisible: (settings) => !!settings.use_payments,
            },
            {
                key:'send_payment_sent_email',
                name: 'Payment Sent',
                description: 'Sends the user a message that payment has been issued.',
            },
            {
                key: 'send_completed_email',
                name: 'Completed Notification',
                description: 'Sends the user a message when their activation is complete.',
            },
            {
                key: 'send_canceled_email',
                name: 'Canceled Notification',
                description: 'Sends the user a message when their activation is canceled.',
            },

        ];

        return items.filter(item => {
            if (typeof item.isVisible === 'undefined') {
                return true;
            }

            return item.isVisible(this.props.settings);
        });

    }

    getValueFor(key) {
        return !!this.props.settings[key];
    }

    handleClick(key) {
        const newValue = !this.getValueFor(key);
        this.props.onChange(key, newValue);
    }

    getColumns() {

        return [

            {
                key: 'checkbox',
                title: 'Enable Email',
                width: 250,
                default: true,
                sortable: false,
                cell: (row, column) => {

                    return <CheckboxCell
                        wrapperStyle={{padding: 0}}
                        row={row}
                        column={column}
                        onClick={this.handleClick.bind(this, row.item.key)}
                        value={this.getValueFor(row.item.key)}
                        label={row.item.name}
                    />

                }
            },
            {
                key: 'description',
                title: 'Description',
                width: 600,
                default: true,
                sortable: false,
                cell: (row, column) => <BasicCell row={row} column={column} value={row.item.description} />,
            }

        ];
    }

    render() {
        return (

            <Table
                items={this.getItems()}
                columns={this.getColumns()}
                showSort={false}
            />

        );
    }
}

export default EmailSettingsTable;
