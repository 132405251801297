import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';

class PostDetailWidgetSection extends Component {
    static propTypes = {
        references: PropTypes.array,
        referenceIds: PropTypes.array,
    }

    renderReference(refId) {
        const ref = _find(this.props.references, {_id: refId});
        if (!ref) {
            return null;
        }

        let niceType = ref.model;
        switch (niceType) {
            case 'post': case 'mangopost': niceType = 'Post'; break;
            case 'image': case 'mangoimage': case 'Tidal\\Image': niceType = 'Image'; break;
            case 'product': case 'mangoproduct': niceType = 'Product'; break;
        }

        return (
            <div className="tidal-widget-item" key={refId}>
                <p className="widget-type">{niceType} Item</p>
                <h3 className="widget-title">{ref.title || ref.name}</h3>
                <p className="widget-description">{ref.description}</p>
            </div>
        );

    }

    render() {
        return (
            <div className="tidal-widget-section">
                <p className="v3 h6">Widget Section:</p>
                {this.props.referenceIds.map(refId => this.renderReference(refId))}
            </div>
        );
    }
}

export default PostDetailWidgetSection;