import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from '../Common/Toolbox';
import SelectProductTable from './SelectProductTable';

class AssignProductToolbox extends Component {
    static defaultProps = {
        style: {},
        title: 'Assign Product',
    };

    static propTypes = {
        style: PropTypes.object,
        activation: PropTypes.object,
        campaignId: PropTypes.number,
        onClose: PropTypes.func,
        onSaved: PropTypes.func,
        title: PropTypes.string,
    };

    renderTable() {
        return <SelectProductTable
            activation={this.props.activation}
            campaignId={this.props.campaignId}
            showStockAvailable={true}
            onSaved={this.props.onSaved}
        />

    }

    render() {

        return <Toolbox
            supportMobile={true}
            title={this.props.title}
            style={this.props.style}
            onClose={this.props.onClose}
            content={this.renderTable()}
        />

    }
}

export default AssignProductToolbox;

