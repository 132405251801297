import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from "../Common/Toolbox";
import DropdownButton from "../Common/Form/DropdownButton";

const ACTIONS = [
    {
        title: "Approve Payment",
        key: 'GenerateInvoice',
        isValid: (status) => status.is_payment_owed && status.has_payment_profile,
    },
    {
        title: "Set Payment Amount",
        key: 'UpdatePaymentOffer',
        isValid: (status, activation) => activation.settings.use_payments && !activation.payment_offered,
    },
    {
        title: 'Assign Coupon Code',
        key: 'AssignCouponCode',
        isValid: (status, activation) => status.is_coupon_code_required && !status.is_coupon_code_assigned,
    },
    {
        title: "Set Payment Details",
        key: 'SubmitPaymentProfile',
        isValid: (status) => status.is_payment_required && !status.has_payment_profile,
    },
    {
        title: "Set Shipping Details",
        key: 'SubmitShippingAddress',
        isValid: (status) => status.is_product_required && !status.has_shipping_address,
    },
    {
        title: "Ship Product Sample",
        key: 'ShipProduct',
        isValid: (status) => status.is_product_required && !status.is_product_shipped,
    },
];

class ActivationPaymentToolsButton extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onClickAction: PropTypes.func,
    }

    getAvailableActions() {
        const status = this.props.activation.status;

        // Don't allow any actions if waitlisted or canceled
        if (status.is_waitlisted || status.is_canceled) {
            return [];
        }

        return ACTIONS.filter(action => action.isValid(status, this.props.activation));
    }

    renderDropdownOptions() {
        const actions = this.getAvailableActions();

        if (actions.length === 0) {
            return <div><div className="fake-li condensed"><a role="button"><em>No Actions Available</em></a></div></div>
        }

        return (<div>
            {actions.map(action => <div className="fake-li condensed"><a role="button" onClick={this.handleClickAction.bind(this, action)}>{action.title}</a></div>)}
        </div>);
    }

    renderDropdown() {
        return <Toolbox
            style={{minHeight: 0}}
            content={this.renderDropdownOptions()}
        />
    }

    handleClickAction(action) {
        if (!action) return;
        if (this.props.onClickAction) {
            this.props.onClickAction(action.key);
        }

    }

    render() {
        const actions = this.getAvailableActions();
        const primaryAction = actions.length > 0 ? actions[0] : null;

        if (actions.length === 0) {
            return <span>{(((this.props.activation || {}).status || {}).human_payment_status || '')}</span>;
        }

        return <DropdownButton
            content={primaryAction ? primaryAction.title : <em>N/A</em>}
            onClickPrimary={this.handleClickAction.bind(this, primaryAction)}
            classes={['btn-secondary', 'v3', 'small', (actions.length === 0 ? 'disabled' : '')]}
            dropdown={this.renderDropdown()}
        />
    }
}

export default ActivationPaymentToolsButton;