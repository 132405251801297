import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../actions/activations";
import PropTypes from 'prop-types';
import Form from '../Common/Form/Form';
import Alert from '../Common/Alert';
import {fetchActivation} from "../../actions";

class RaiseIssueForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {title: '', content: ''},
            invalidFields: [],
            isValid: null,
            isSubmitting: false,
            didSubmit: false,
            missingSocials: (!!this.props.activation.status.is_active),
            missingPaymentDetails: (!!this.props.activation.status.is_active && !this.props.activation.status.has_payment_profile),
            missingShippingAddress: (!!this.props.activation.status.is_active && !this.props.activation.status.has_shipping_address),
            missingProductSelection: (!!this.props.activation.status.is_active && !!this.props.activation.settings.use_products && !!this.props.activation.settings.product_catalog_id && !this.props.activation.status.is_product_selected),
        };
        this.makeSubjectField = this.makeSubjectField.bind(this);
    }

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onClose: PropTypes.func,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
        mode: PropTypes.string, //e.g. 'mode: manager' gets pre-populated suggested issues based on activation status
    };

    resetForm() {
        this.setState({
            form: {title: '', content: ''}
        });
    }

    handleSubmit() {
        const {activation} = this.props;
        if (!this.state.isValid) return;

        this.setState({isSubmitting: true});
        return this.props.createInteraction(activation.id, 'RaiseIssue', this.state.form)
            .then(() => this.props.fetchActivation(activation.id))
            .then(resp => {
                this.setState({
                    isSubmitting: false,
                    didSubmit: true
                })
            })
            .then(() => this.resetForm())
            .then(() => {
                if (this.props.onClose) {
                    setTimeout(() => this.props.onClose(), 2000);
                }
            })
    }

    handleFieldChange(name, value) {
        let content = '';

        if (name === 'title' && value === 'Please Submit Your Payment Details') {
            content = `We are missing your Payment Details.  Please login to submit.`;
        } else if (name === 'title' && value === 'Please Reconnect Your Social Accounts') {
            content = `One or more of your social network accounts needs to be reconnected in order for you to participate in this campaign. Please login to reconnect.`;
        } else if (name === 'title' && value === 'Please Submit Your Shipping Address') {
            content = `We are missing your shipping address.  Please login to submit.`;
        } else if (name === 'title' && value === 'Please Select a Product') {
            content = `We are missing your product selection(s).  Please login to submit.`;
        } else if (name === 'title' && value === 'Custom Subject Line') {
            return this.setState({
                missingPaymentDetails: false,
                missingShippingAddress: false,
                missingProductSelection: false,
                missingSocials: false,
                form: {...this.state.form, title: ''},
            })
        }

        if (content.length) {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value,
                    content
                }
            })
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: value
                }
            })
        }
    }

    makeSubjectField(){
        const {missingPaymentDetails, missingShippingAddress, missingProductSelection, missingSocials} = this.state;
        const {mode} = this.props;

        let choices = [];

        if (missingSocials) {
            choices.push({text: 'Please Reconnect Your Social Accounts', value: 'Please Reconnect Your Social Accounts'});
        }
        if (missingPaymentDetails) {
            choices.push({text: 'Please Submit Your Payment Details', value: 'Please Submit Your Payment Details'});
        }
        if (missingShippingAddress) {
            choices.push({text: 'Please Submit Your Shipping Address', value: 'Please Submit Your Shipping Address'});
        }
        if (missingProductSelection) {
            choices.push({text: 'Please Select a Product', value: 'Please Select a Product'});
        }
        if (choices.length) {
            choices.push({text: 'Custom Subject Line', value: 'Custom Subject Line'})
        }

        return (choices.length && mode === 'manager')
            ? ({
                type: 'select',
                name: 'title',
                title: 'Subject',
                choices,
                options: {defaultValue: 'Select a Subject Line'},
                required: true,
            })
            : ({
                type: 'text',
                title: 'Subject',
                placeholder: 'eg: Please update your shipping address',
                name: 'title',
                required: true,
            });
    }

    handleValidation({isValid, invalidFields}) {
        let invalidFieldNames = invalidFields.map(field => field.name);

        this.setState({
            isValid,
            invalidFields: invalidFieldNames
        });
    }

    getFormFields(){
        const {isSubmitting, didSubmit} = this.state;
        const subjectField = this.makeSubjectField();
        let fields = [];

        if (didSubmit || isSubmitting) {
            fields.push({
                type: 'generic',
                content: <Alert
                    classes={['info']}
                    content={isSubmitting ? 'Creating issue...' : 'Issue created!'}
                    style={{marginBottom: 0}}
                />
            })
        }

        fields.push(subjectField);

        fields.push({
            type: 'textarea',
            title: 'Message',
            placeholder: 'Describe the issue here',
            name: 'content'
        });

        fields.push ({
            type: 'button',
            title: `Raise New Issue`,
            options: {
                hideTitle: true,
                onClick: this.handleSubmit.bind(this),
                classes: ['v3 btn btn-primary', ((isSubmitting || !this.state.isValid) ? 'disabled' : '')]
            }
        });

        return fields;
    }

    render() {
        return <Form
            values={this.state.form}
            onFieldChange={this.handleFieldChange.bind(this)}
            fields={this.getFormFields()}
            invalidFields={this.state.invalidFields}
            onValidation={this.handleValidation.bind(this)}
        />
    }
}


const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };
};

export default connect(null, mapDispatchToProps)(RaiseIssueForm);
