/**
 * Created by bkanber on 7/26/17.
 */

import React, {Component} from 'react';
import C3Chart from 'react-c3js';
import d3 from 'd3';
import {c3ColorPattern, formatNumberK} from '../../../utilities';

const REACH_NAME_MAP = {
    "blog_traffic": "Blog Uniques",
    "twitter_reach": "Twitter",
    "instagram_followers": "Instagram",
    "youtube_subscribers": "YouTube",
    "facebook_followers": "Facebook",
    "pinterest_followers": "Pinterest"

};

export default class UserReachChart extends Component {

    renderChart() {

        const user = this.props.user;
        const profile = user.profile;
        const totalReach = user.basics.verified_reach;

        if (typeof profile === 'undefined') return null;

        let reach = {};

        for (let key in REACH_NAME_MAP) {
            let niceName = REACH_NAME_MAP[key];
            let val = profile[key];
            if (typeof val === 'undefined' || val === null) continue;

            reach[niceName] = val;
        }

        const data = {
            json: [reach],
            keys: {
                value: Object.keys(reach)
            },
            type: 'donut'
        };

        const donut = {
            title: "Social Reach",
            label: {
                format: function (value, ratio, id) {
                    return formatNumberK(value);
                }
            }
        };

        const tooltip = {
            format: {
                value: function (value, ratio, id) {
                    let format = d3.format(',');
                    let formatPercent = d3.format('.1%');
                    return format(value) + "  (" + formatPercent(ratio) + ")";
                }
            }
        };

        return (
            <div className="widget" style={{width: '350px', height: '350px', marginTop: 0, flex: "none"}}>
                <div className="title">
                    <div className="icon left"><i className="fa fa-bar-chart-o"></i></div>
                    <h2>
                        Social Reach
                        <span className="badge badge-darkgrey">
                        {formatNumberK(totalReach)}
                        </span>
                    </h2>
                </div>
                <div className="content">

                    <C3Chart
                        data={data}
                        donut={donut}
                        size={{width: 300, height: 280}}
                        color={c3ColorPattern}
                        tooltip={tooltip}
                        transition={{duration: 0}}
                    />

                </div>
            </div>
        );

    }

    render() {
        const totalReach = ((this.props.user || {}).basics || {}).verified_reach;

        if (totalReach) {
            return this.renderChart();
        }

        return null;
    }
}
