import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar';
import Switch from './Switch';

export default class MiniProfile extends Component {

    static defaultProps = {
        style: {},
        switches: [],
        classes: [],
        square: false,
        avatarSize: 60
    };

    static propTypes = {
        style: PropTypes.object,
        switches: PropTypes.array,
        classes: PropTypes.array,
        primary: PropTypes.node,
        secondary: PropTypes.node,
        image: PropTypes.any,
        imageUrl: PropTypes.string,
        onClickAvatar: PropTypes.func,
        badge: PropTypes.node,
        square: PropTypes.bool,
        avatarSize: PropTypes.number
    };

    getClasses() {
        return ['v3', 'mini-profile', ...this.props.classes].join(' ');
    }

    renderAvatar() {
        const avatarStyle = this.props.avatarSize ? {width: this.props.avatarSize, height: this.props.avatarSize} : undefined;
        return (
            <div className="avatar-wrapper">
                {this.props.badge ? <div className='avatar-badge'>{this.props.badge}</div> : null}
                <Avatar
                    image={this.props.image}
                    url={this.props.imageUrl || undefined}
                    onClick={this.props.onClickAvatar}
                    classes={this.props.square ? ['square'] : ''}
                    style={avatarStyle}
                />
            </div>
        );
    }

    renderPrimary() {
        if (this.props.primary) {
            return (
                <div className="primary content-row v3 bold">
                    {this.props.primary}
                </div>
            );
        }
    }

    renderSecondary() {
        if (this.props.secondary) {
            return (
                <div className="muted content-row v3 light h9">
                    {this.props.secondary}
                </div>
            );
        }
    }

    renderSwitches() {
        if (this.props.switches) {
            return (
                <div className="switches content-row">
                    {this.props.switches.map(s => this.renderSwitch(s))}
                </div>
            );
        }
    }

    renderSwitch(spec) {

        if (!spec) return null;
        let node = spec;

        if (typeof spec.icon !== 'undefined') {
            // We have a spec
            node = <Switch {...spec} />
        }

        return node;
    }

    renderContent() {
        const style = {
            width: `calc(100% - ${((this.props.avatarSize || 0)+10)}px)`
        }
        return (
            <div className="content" style={style}>
                {this.renderPrimary()}
                {this.renderSecondary()}
                {this.renderSwitches()}
            </div>
        );
    }

    render() {
        return (
            <div className={this.getClasses()} style={this.props.style}>
                {this.renderAvatar()}
                {this.renderContent()}
            </div>

        );
    }

}