import React from 'react';
import ResponsiveEditProfile from "../../../Influencer/EditProfile/ResponsiveEditProfile";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {getCurrentUser} from "../../../../selectors/presence";

function MACCreatorProfilePage({user}) {
    if (!user) {
        return null;
    }

    return (
        <div className="mac-wrapper">
            <ResponsiveEditProfile userId={user.id} />
        </div>
    );
}
const mapStateToProps = (state, props) => ({
    user: getCurrentUser(state, props)
});
const mapDispatchToProps = (dispatch, props) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MACCreatorProfilePage);