import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Toolbox from '../Common/Toolbox';
import {formatNumber, fetchAuthenticated} from "../../utilities";
import * as URI from 'urijs';
import { CAMPAIGN_HEALTH_CHECKS } from "../../utilities/campaign";
import Button from '../Common/Form/Button';


export default class HealthCheckButton extends Component {


    static defaultProps = {
        classes: [],
        style: {},
        wrapperStyle: {},
        content: 'Quick Filters',
    };

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        onClickFilter: PropTypes.func,
        content: PropTypes.node,
        classes: PropTypes.array,
        style: PropTypes.object,
        wrapperStyle: PropTypes.object,
    };

    state = {
        fetching: {},
        hits: {},
        showDropdown: false,
        didRunChecks: false,
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.showDropdown && !this.state.didRunChecks) {
            this.runChecks();
        }
    }

    runChecks() {
        if (this.state.didRunChecks) {
            return;
        }

        this.setState({didRunChecks: true});
        this.getItems().forEach(check => this.fetchCheckStats(check));
    }

    handleClickFilter(item) {
        this.setState({showDropdown: false});
        if (this.props.onClickFilter) {
            this.props.onClickFilter(item);
        }
    }

    fetchCheckStats(item) {
        this.markItemFetching(item, true);

        const filters = {
            campaign_id: this.props.campaign.id,
            ...item.filters
        };

        const payload = {
            filters: JSON.stringify(filters),
            metaOnly: true
        };

        let url = URI(`/manage/api/activation`)
            .query(payload)
            .toString();

        return fetchAuthenticated(url, {credentials: 'include'})
            .then(resp => resp.json())
            .then(json => this.setItemHits(item, json.meta.hits))
            .then(() => this.markItemFetching(item, false));
    }

    setItemHits(item, hits) {
        this.setState((prevState) => {
            return {
                hits: {
                    ...prevState.hits,
                    [item.name]: hits
                }
            }
        });

    }
    markItemFetching(item, value) {
        this.setState((prevState) => {
            return {
                fetching: {
                    ...prevState.fetching,
                    [item.name]: value
                }
            }
        });
    }

    getItems() {
        return CAMPAIGN_HEALTH_CHECKS.filter(spec => spec.isValid(this.props.campaign));
    }

    isItemFetching(item) {
        return this.state.fetching[item.name] || false;
    }

    getItemHits(item) {
        return this.state.hits[item.name] || 0;
    }

    renderDropdown() {

        return (
            <Toolbox
                content={this.renderDropdownContent()}
                style={{
                    zIndex: 200,
                    width: 300
                }}
            />
        )

    }

    renderDropdownContent() {
        return this.getItems().map(item => {

            const tagValue = this.isItemFetching(item)
                ? 'Checking...'
                : formatNumber(this.getItemHits(item)) + ' Found';

            return (

                <div className="fake-li condensed">
                    <a
                        role="button"
                        onClick={this.handleClickFilter.bind(this, item)}
                        className="v3 h7 light"
                        style={{fontSize: 14}}
                    >
                        {item.name}
                        <span className="v3 tidal-tag light bg-transparent pull-right">{tagValue}</span>
                    </a>
                </div>

            );
        })
    }

    render() {

        return (

            <div className="dropdown-btn-wrapper" style={this.props.wrapperStyle}>
                <Button
                    content={this.props.content}
                    onClick={() => this.setState({showDropdown: !this.state.showDropdown, didRunChecks: false})}
                    classes={this.props.classes}
                    style={this.props.style}
                />
                {this.state.showDropdown ? this.renderDropdown() : null}
            </div>

        );

    }
}