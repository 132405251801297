import React from 'react';
import Tooltip from "./Tooltip";

function InfoIcon(props) {
    const iconStyle = Object.assign({}, {cursor: "pointer", position: "relative", top: "auto", left: "auto", right: "auto"}, props.iconStyle);
    const wrapperStyle = Object.assign({}, {width: 20, height: 20, display: "inline-block", position: "relative", top: "auto", left: "auto", right: "auto"}, props.style);
    return (
        <Tooltip
            title={props.title}
            position={props.position}
            trigger={props.trigger}
            arrow={props.arrow}
            className={props.className}
            style={wrapperStyle}
            html={props.html}
        >
            <i className="v3 icon info" style={iconStyle} />
        </Tooltip>
    );
}

export default InfoIcon;

