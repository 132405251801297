import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {base64_png_1pxSolidEEE, getImageUrl, proxyImageUrl} from "../../utilities";
import VisibilitySensor from 'react-visibility-sensor';

export default class Image extends Component {

    constructor(props) {
        super(props);

        this.img = null;

        this.state = {
            shouldLoad: props.shouldLoad,
            isVisible: false,
            wasEverVisible: false,
            isLoading: false,
            didLoad: false,
            didError: false
        };

        this.handleLoad = this.handleLoad.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleRef = this.handleRef.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    }

    handleVisibilityChange(isVisible) {
        let newState = {isVisible};

        if (isVisible) {
            newState.wasEverVisible = true;
            newState.shouldLoad = true;
        }

        this.setState(newState);
    }


    handleError(e) {
        this.setState({didLoad: false, didError: true});
        if (this.props.onError) {
            this.props.onError(e);
        }
    }

    handleLoad(e) {
        /**
         * Need to "persist" react's synthetic event when passing to setTimeout,
         * to avoid react's synthetic event pooling.  (otherwise e.target will be null)
         * @see https://github.com/facebook/react/issues/1717
         * @see https://reactjs.org/docs/events.html
         * - BK
         **/
        e.persist();

        setTimeout(() => {

            this.setState({didLoad: true, isLoading: false});

            if (this.props.onLoad) {
                this.props.onLoad(e);
            }

        }, 100);
    }

    handleRef(e) {
        this.img = e;
        if (this.props.imageRef) {
            this.props.imageRef(e);
        }
    }

    renderPreview() {
        const image = this.props.image || {};
        let {preview, type} = image;

        if (!preview) {
            preview = base64_png_1pxSolidEEE;
            type = 'image/png';
        }

        if (this.state.didLoad === false && preview) {
            const previewSrc = 'data:' + type + ';base64,' + preview;
            return (
                <img src={previewSrc} className={'tidal-img-preview'} />
            );
        }

        return null;
    }

    renderImage() {

        if (this.state.shouldLoad === false) {
            return null;
        }

        const image = this.props.image;
        let url = getImageUrl(image);

        if (this.props.useProxy) {
            url = proxyImageUrl(url);
        }

        let style = {...this.props.imageStyle};

        if (!this.state.didLoad || this.props.asBackgroundImg) {
            style['display'] = 'none';
        }

        return (
            <>
                {
                    this.props.asBackgroundImg && (
                        <div
                            className="tidal-img-bg"
                            style={{backgroundImage: `url('${url}')`}}
                        />
                    )
                }
                <img src={url} style={style}
                     ref={this.handleRef}
                     onLoad={this.handleLoad}
                     onError={this.handleError}
                     onClick={this.props.onClick}
                />
            </>
        );
    }

    getStyle() {
        const given = this.props.style;
        let applied = {};
        return Object.assign({}, given, applied);
    }

    render() {
        return (
            <VisibilitySensor
                scrollCheck={true}
                intervalCheck={true}
                containment={this.props.containment}
                onChange={this.handleVisibilityChange}
                partialVisibility={true}
                delayedCall={true}
            >
                <div
                    className={this.props.className}
                    style={this.getStyle()}
                >
                    {this.renderPreview()}
                    {this.renderImage()}
                </div>
            </VisibilitySensor>
        )
    }
}

Image.propTypes = {

    style: PropTypes.object,
    imageStyle: PropTypes.object,
    className: PropTypes.string,

    image: PropTypes.object,
    sizes: PropTypes.arrayOf(PropTypes.object),

    onClick: PropTypes.func,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    imageRef: PropTypes.func,

    containment: PropTypes.any,
    asBackgroundImg: PropTypes.bool,
    useProxy: PropTypes.bool,
    shouldLoad: PropTypes.bool,

};

Image.defaultProps = {
    className: 'tidal-img',
    style: {},
    imageStyle: {},
    asBackgroundImg: false,
    useProxy: false,
    shouldLoad: false
};