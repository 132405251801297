import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Toolbox extends Component {

    static defaultProps = {
        style: {},
        embedded: false,
        supportMobile: false,
    };

    static propTypes = {
        style: PropTypes.object,
        onClose: PropTypes.func,
        onMouseLeave: PropTypes.func,
        title: PropTypes.node,
        content: PropTypes.node,
        embedded: PropTypes.bool,
        pushout: PropTypes.bool,
        addlClasses: PropTypes.string,
        supportMobile: PropTypes.bool,
    };

    getClassName() {
        let classes = ['toolbox', this.props.addlClasses];
        if (this.props.embedded) {
            classes.push('toolbox-embedded');
        }
        if(this.props.pushout) {
            classes.push('pushout');
        }
        if(this.props.supportMobile && !this.props.embedded) {
            classes.push('mobile');
        }
        return classes.join(' ');
    }

    renderTitle() {
        if (this.props.title) {
            return <h4 className="v3 title bold">{this.props.title}</h4>
        }
    }

    renderCloseBtn() {
        if (this.props.onClose) {
            return <a role="button" className="v3 close absolute" onClick={this.props.onClose}></a>
        }
    }

    renderContent() {
        if (this.props.content) {
            return this.props.content;
        }
    }

    render() {
        const {style} = this.props;
        return (

            <div className={"v3 " + this.getClassName()} style={style} onMouseLeave={this.props.onMouseLeave}>
                {this.renderTitle()}
                {this.renderCloseBtn()}
                {this.renderContent()}
            </div>

        );
    }
}
