import React, {Component, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {fetchActivation} from "../../../../actions";
import PropTypes from 'prop-types';
import Form from '../../../Common/Form/Form';
import _get from 'lodash/get';

import {CLINIQUE_LOCALES, CLINIQUE_PRODUCTS} from "../../../../utilities/clinique";
import {fetchCatalogProducts} from "../../../../actions/products";
import {getImageUrl} from "../../../../utilities";
import Button from "../../../Common/Form/Button";

function AffiliateLinkTile({url, image, name, translate, isLocked, onSelect}) {
    const [isSelecting, setIsSelecting] = useState(false);
    const _t = (k, v) => {
        if (translate) { return translate(k, v); }
        return v;
    }
    const imageUrl = getImageUrl(image);
    const styles = {
        wrapper: {
            flex: "1 1 300px",
            minWidth: 250,
            maxWidth: 600,
            padding: 20,
            background: 'rgba(255, 255, 255, 0.08)'
        },
        image: {
            width: '100%',
            height: '250px',
            backgroundSize: 'contain',
            backgroundImage: `url(${imageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        }
    };

    const clickToSelectText = _t('campaign.affiliate.link_tile.click_to_select', 'Click to Select');
    const selectingText = _t('campaign.affiliate.link_tile.selecting', 'Selecting...');

    const handleClickSelect = () => {
        setIsSelecting(true);
        onSelect().then(() => {
            setIsSelecting(false);
        });
    }

    return (

        <div style={styles.wrapper}>
            <h4>{name}</h4>
            <div style={styles.image} />
            <Form
                usePadding={false}
                values={{url}}
                fields={[
                    isLocked ?
                        {
                            type: 'generic',
                            name: 'clicktounlock',
                            content: (
                                <div style={{textAlign: 'center', marginTop: 20}}>
                                    <Button
                                        content={isSelecting ? selectingText : clickToSelectText}
                                        classes={["v3 btn-primary small"]}
                                        onClick={handleClickSelect}
                                    />
                                </div>
                            )

                        }
                        :
                        {
                            type: 'text',
                            readOnly: true,
                            title: _t('campaign.affiliate.link_tile.your_link', 'Your Affiliate Link'),
                            name: 'url'
                        }
                ]}
            />

        </div>

    );
}
function GetCliniqueAffiliateLinks({locale, createInteraction, activation, fetchActivation, fetchCatalogProducts, campaign, translate, user}) {
    const _t = (k, v) => {
        if (translate) { return translate(k, v); }
        return v;
    }

    const hardcodedProducts = useMemo(() => {
        return CLINIQUE_PRODUCTS
            .filter(product => {
                // return false if doesn't include locale
                if (typeof product.locales[locale] === 'undefined') { return false; }
                // return true if campaigns includes '*'
                if (product.campaigns.indexOf('*') > -1) { return true; }
                // return true if campaigns includes current campaign id
                if (product.campaigns.indexOf(campaign.id) > -1) { return true; }
                // return false otherwise
                return false;
            });
    }, [locale, campaign])

    const [products, setProducts] = useState([]);

    useEffect(() => {
        // fetch products
        // if we have a campaign.settings.affiliate_product_catalog_id, fetch products via fetchCatalogProducts
        // if we don't, fetch products from the CLINIQUE_PRODUCTS array

        if (campaign.settings.affiliate_product_catalog_id) {
            fetchCatalogProducts(campaign.settings.affiliate_product_catalog_id)
                .then((res) => {
                    setProducts(res.data);
                    console.log('res', res);
                })
        } else {
            setProducts(hardcodedProducts);
        }

    }, [locale, campaign, hardcodedProducts]);

    useEffect(() => {
        // generateLinks().then(() => console.log("Finished 'GenerateLinks'"));
    }, [locale, campaign, products])

    const getImageForProduct = product => {
        if (product.images && product.images.length > 0) {
            return product.images[0];
        }

        if (product.image) {
            return product.image;
        }

        return null;
    };

    const getOriginalUrlForProduct = product => {
        const thisLocale = product.locales[locale] || {};
        const localeProductUrl = thisLocale.url || thisLocale.product_url;
        const nativeProductUrl = product.url || product.product_url;
        return localeProductUrl || nativeProductUrl;
    };

    const hasProductUrlBeenUsed = (originalUrl) => {
        const linkMap = _get(activation, 'metadata.affiliate_link_map', {});
        if (typeof linkMap[originalUrl] !== 'undefined') {
            return !!linkMap[originalUrl].shortlink_url;
        }
        return false;
    }

    const getUrlForProduct = product => {
        const originalUrl = getOriginalUrlForProduct(product);
        const linkMap = _get(activation, 'metadata.affiliate_link_map', {});
        if (typeof linkMap[originalUrl] !== 'undefined') {
            // fall back to affiliate_url if shortlink_url has three /// in a row
            let outputUrl = linkMap[originalUrl].shortlink_url || linkMap[originalUrl].affiliate_url;
            if (outputUrl.indexOf('///') > -1) {
                outputUrl = linkMap[originalUrl].affiliate_url;
            }
            return outputUrl;
        }
        return originalUrl;
    };

    const generateLinks = () => {
        const urls = (products || []).map(product => getUrlForProduct(product));
        const payload = {locale, urls};
        return createInteraction(activation.id, 'GenerateAffiliateLink', payload)
            .then(() => fetchActivation(activation.id))
    };

    const generateOneLink = (product) => {
        const url = getUrlForProduct(product);
        const payload = {locale, urls: [url]};
        return createInteraction(activation.id, 'GenerateAffiliateLink', payload)
            .then(() => fetchActivation(activation.id))
    }

    const flexStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 40,
        gap: '40px'
    };
    return (
        <div className="v3 form-group" style={{marginTop: 40}}>
            <label><strong>{_t('campaign.affiliate.locale_links.preamble', 'Your Affiliate Links for')} <span style={{textDecoration: 'underline'}}>{locale}</span></strong></label>

            <div style={flexStyle}>
                {products.length === 0 && (
                    <p><em>There are no products available for this campaign.</em></p>
                )}
                {products
                    .filter(product => !!getUrlForProduct(product) )
                    .map(product => {
                        const url = getUrlForProduct(product);
                        const image = getImageForProduct(product);
                        return <AffiliateLinkTile
                            translate={translate}
                            name={product.name}
                            image={image}
                            url={url}
                            key={url}
                            isLocked={!hasProductUrlBeenUsed(getOriginalUrlForProduct(product))}
                            onSelect={() => {
                                generateOneLink(product);
                            }}
                        />;
                    })
                }

            </div>
        </div>
    );
}

class GenerateAffiliateLink extends Component {

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        fetchActivation: PropTypes.func.isRequired,
        fetchCatalogProducts: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        translate: PropTypes.func.isRequired,
    };

    state = {
        isConfirming: false,
        original_url: '',
        shortlink: null,
        locale: null,
        showLocaleSelect: true
    };

    componentDidMount() {
        const user = this.props.user
        if (user && user.country_name) {
            // if the user's country is one of CLINIQUE's locales, set the locale to that
            const locale = CLINIQUE_LOCALES.find(l => l.name === user.country_name);
            if (locale) {
                console.log("Setting locale to", locale);
                this.setState({locale: locale.name, showLocaleSelect: false});
            } else {
                console.log("No locale found for", user.country_name);
            }
        }
    }

    _t(k, v) {
        if (this.props.translate) {
            return this.props.translate(k, v);
        }
        return v;
    }

    handleGenerateLink() {
        const payload = { original_url: this.state.original_url };
        this.setState({isGenerating: true, shortlink: null});
        this.props.createInteraction(this.props.activation.id, 'GenerateAffiliateLink', payload)
            .then(() => this.props.fetchActivation(this.props.activation.id))
            .then(() => this.setState({isGenerating: false, shortlink: null}));
    }

    renderAlert() {

        return (
            <p className="v3 light h7 pane-info">
                { this._t('campaign.affiliate.pane.info', 'Use this page to convert a product link (SPP) to a trackable affiliate link that gives you credit for each click and purchase. '
                    + 'Paste the URL to the product you want to convert to an affiliate link below, and then you can use our shortlink in your posts, Stories, and profile bios.') }
            </p>
        );

    }


    renderLinkOptions() {

        const affiliateLinkMap = _get(this.props.activation, 'metadata.affiliate_link_map', {});
        const {isGenerating, original_url} = this.state;
        const isUsingCustomUrl = !!original_url;
        const shortlink = original_url ? affiliateLinkMap[original_url] : null;
        const hasShortlink = !!shortlink;

        let shortlink_url = shortlink ? shortlink.shortlink_url : '';
        // if shortlink url has three /// in a row, fall back to shortlink.affiliate_url
        if (shortlink_url.indexOf('///') > -1) {
            shortlink_url = shortlink.affiliate_url;
        }

        return (
            <div>
                <Form
                    style={{marginTop: 40}}
                    usePadding={false}
                    values={{
                        locale: this.state.locale,
                        original_url: this.state.original_url,
                        shortlink_url: shortlink_url,
                        shoppable_url: 'https://' + window.location.hostname + '/u/' + this.props.user.slug,
                    }}
                    fields={[
                        {
                            type: 'text',
                            readOnly: true,
                            name: 'shoppable_url',
                            title: 'Your Personal Shoppable Page',
                            help: 'This is your personal shoppable page, which lets you share all your affiliate links in one place. Add this to your linktree, bio, or anywhere else you want to share your shoppable page.',
                        },
                        this.state.showLocaleSelect ? {
                            type: 'select',
                            name: 'locale',
                            title: this._t('campaign.affiliate.form.locale.title', 'Confirm Country to Receive Clinique Affiliate Links'),
                            choices: [
                                {text: 'Choose Country', value: ''},
                                ...CLINIQUE_LOCALES.map(locale => (
                                    {
                                        text: locale.name,
                                        value: locale.name
                                    }
                                ))
                            ]
                        } : null,
                        this.state.locale ? {
                            type: 'generic',
                            content:<GetCliniqueAffiliateLinks
                                {...this.props}
                                locale={this.state.locale}
                            />
                        } : null,
                        {
                            type: 'text',
                            name: 'original_url',
                            title: this._t('campaign.affiliate.form.original_url.title', 'Or Convert a Custom Link to an Affiliate Link'),
                            options: {
                                groupStyle: {
                                    marginTop: 40,
                                    paddingTop: 40,
                                    borderTop: '1px dashed #555'
                                }
                            }
                        },
                        !hasShortlink ? {
                            type: 'button',
                            name: 'submit',
                            title: isGenerating ? this._t('campaign.affiliate.status.generating', 'Generating Affiliate Link...') : this._t('campaign.affiliate.status.generate', 'Generate Affiliate Link'),
                            options: {
                                hideTitle: true,
                                classes: ['v3', 'medium', isUsingCustomUrl ? 'btn-primary' : 'btn-secondary'],
                                onClick: this.handleGenerateLink.bind(this)
                            }
                        } : null,
                        hasShortlink ? {
                            type: 'text',
                            title: <><i className="v3 icon success" style={{marginRight: 8}} /> {this._t('campaign.affiliate.form.shortlink_url.title', 'Copy Affiliate Link')}</>,
                            name: 'shortlink_url',
                            readOnly: true,
                            help: this._t('campaign.affiliate.form.shortlink_url.help', 'This is your personalized custom affiliate link. Copy it and use it in your posts and profile!')
                        } : null
                    ].filter(f => !!f)}
                    onFieldChange={(k, v) => {
                        if (k === 'locale' || k === 'original_url') {
                            this.setState({[k]: v});
                        }
                    }}
                />
            </div>

        );
    }

    render() {

        return(
            <div className="influencer-page-template receive-products">
                <div className="widget">

                    <div className="content padded">
                        <h4 className="v3 bold action-pane-title">{this._t('campaign.affiliate.pane.title', 'Get Affiliate Links')}</h4>
                        {this.renderAlert()}

                        {this.renderLinkOptions()}


                    </div>

                </div>

            </div>
        )
    }

}


const mapDispatchToProps = dispatch => {
    return {
        fetchActivation: (id) => dispatch(fetchActivation(id)),
        fetchCatalogProducts: (id) => dispatch(fetchCatalogProducts(id)),
    };
};

export default connect(null, mapDispatchToProps)(GenerateAffiliateLink);
