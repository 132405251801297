import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {preventDefaultIfPossible} from "../../../utilities";

export default class UserDetailMenu extends Component {

    handleToggleItem(name, event) {
        preventDefaultIfPossible(event);
        this.props.onToggleItem(name);
    }

    handleBlockUser(block, event) {
        preventDefaultIfPossible(event);
        const userId = this.props.user.id;
        this.props.blockUser(userId, block);
    }

    renderBlockUser() {
        if (!this.props.blockUser) {
            return null;
        }

        const isBlocked = (this.props.user.profile || {}).is_blocked || false;

        if (isBlocked) {
            return <div className="fake-li">
                <a href="#" onClick={this.handleBlockUser.bind(this, false)}><i className="fa fa-ban" /> Un-Block User</a>
            </div>

        } else {
            return <div className="fake-li">
                <a href="#" onClick={this.handleBlockUser.bind(this, true)}><i className="fa fa-ban" /> Block User</a>
            </div>

        }
    }

    render() {
        const profileImagesUrl = `/manage/do/page/users/edit_images?user=${this.props.user.id}`;
        const paymentUrl = `/manage/do/page/payment/vendor?user=${this.props.user.basics.mongo_id}`;
        const advancedEditorUrl = `/manage/do/page/users/edit?id=${this.props.user.id}`;

        return (
            <>
                <div className='fake-li bottom-border'>
                    <a href="#" onClick={this.handleToggleItem.bind(this, 'quickEditUser')}><i className="v3 icon pencil" /> Quick Edit User</a>
                </div>


                <div className='fake-li bottom-border'>
                    <a href="#" onClick={this.handleToggleItem.bind(this, 'addToList')}><i className="fa fa-list" /> Add to Badge or List</a>
                </div>

                {this.props.showTeamsLink && (
                    <div className='fake-li bottom-border'>
                        <a href="#" onClick={this.handleToggleItem.bind(this, 'addToTeam')}><i className="fa fa-users" /> Edit Teams</a>
                    </div>
                )}

                {this.renderBlockUser()}

                <div className='fake-li bottom-border'>
                    <a href="#" onClick={this.handleToggleItem.bind(this, 'addSocialHandle')}><i className="fab fa-twitter" /> Add Social Handle</a>
                </div>

                <div className='fake-li bottom-border'>
                    <a href={paymentUrl}><i className="fa fa-usd" /> Payment Profile</a>
                </div>

                <div className='fake-li bottom-border'>
                    <a href={profileImagesUrl}><i className="fa fa-picture-o" /> Edit Profile Images</a>
                </div>

                <div className='fake-li bottom-border'>
                    <a href={advancedEditorUrl} target="_blank"><i className="fa fa-pencil-square-o" /> Edit As User (Advanced)</a>
                </div>

                <div className='fake-li bottom-border'>
                    <a href={"#"} role="button" onClick={this.handleToggleItem.bind(this, 'sendEmail')}><i className="v3 icon mail" /> Email User</a>
                </div>

                {this.props.onPrint && (
                    <div className='fake-li bottom-border'>
                        <a href={"#"} role="button" onClick={this.props.onPrint}><i className="v3 icon download" /> Save as PDF</a>
                    </div>
                )}
            </>
        );
    }
}

UserDetailMenu.propTypes = {
    onToggleItem: PropTypes.func,
    blockUser: PropTypes.func,
    user: PropTypes.object.isRequired,
    showTeamsLink: PropTypes.bool
};