import React, {useEffect, useMemo, useState} from "react";
import * as URI from "urijs";
import ICPostTile from "./ICPostTile";

function ICTopicSection({topic, fetchAuthenticated, allowPagination=true, limit=5, translate}) {
    const _t = (k, v) => translate ? translate(k, v) : v;
    const [page, setPage] = useState(1);
    const [posts, setPosts] = useState(null);
    const [totalPosts, setTotalPosts] = useState(0);
    const fetchPosts = async (pageNum=1) => {
        const url = new URI('/contribute/api/innercircle/_/post');
        url.search({
            pinned: 1,
            topic: topic.id,
            limit: limit,
            page: pageNum,
            useVisibilityFilters: 1
        });
        const resp = await fetchAuthenticated(url.toString());
        const json = await resp.json();
        setPosts(json.data);
        setTotalPosts(parseInt(json.meta.total || '0', 10));
    };

    const RenderedPosts = useMemo(function () {
        return (posts || []) .map(post => <ICPostTile post={post} key={post.id} /> );
    }, [topic, posts]);

    useEffect(function() {
        fetchPosts(page).then(() => console.log("Finished fetching page " + page));
    }, [topic, page]);

    const hasNextPage = totalPosts > (page*limit);

    if (!posts || posts.length === 0) {
        return null;
    }

    const wdPrev = _t('creator.words.previous', 'Prev');
    const wdNext = _t('creator.words.next', 'Next');
    const descTransKey = `creator.innercircle.${topic.id}.description`;
    const desc = _t(descTransKey, topic.description);

    return (

        <div className="dash-section">
            <div className="section-header">
                <h1 className="red-indent">#{topic.name}
                    {page > 1 ? (
                        <a className="seeall" onClick={() => setPage(prevPage => prevPage-1)}><i className="v3 icon fa-arrow-left" style={{marginRight: 8}}/>{wdPrev}</a>
                    ) : null}

                    {hasNextPage ? (
                        <a className="seeall" onClick={() => setPage(prevPage => prevPage + 1)}>{wdNext}<i
                            className="v3 icon fa-arrow-right"/></a>) : null }
                </h1>
                <p className="section-subtitle">{desc}</p>
            </div>
            <div className="section-content">

                <div className="ic-posts">
                    {RenderedPosts}
                </div>

            </div>

        </div>
    );
}

export default ICTopicSection;