/**
 * Created by visgotti on 9/13/17.
 */

import {fetchAuthenticated} from "../utilities";
import * as ActionTypes from "./types";

export const RECEIVE_EMAILS = 'RECEIVE_EMAILS';
export const RECEIVE_EMAIL = 'RECEIVE_EMAIL';
export const QUERY_EMAILS = 'QUERY_EMAILS';
export const CHANGE_EMAIL_FILTER = 'CHANGE_EMAIL_FILTER';
export const REMOVE_EMAIL_FILTER = 'REMOVE_EMAIL_FILTER';
export const SELECT_EMAIL = 'SELECT_EMAIL';
export const UNSELECT_EMAIL = 'UNSELECT_EMAIL';
export const SELECT_ALL_EMAILS = 'SELECT_ALL_EMAILs';
export const UNSELECT_ALL_EMAILS = 'UNSELECT_ALL_EMAILS';
export const RESET_EMAIL_QUERY = 'RESET_EMAIL_QUERY';

export function emailToggleCompose(showCompose = false, campaignId = null) {
    return {
        type: ActionTypes.EMAIL_TOGGLE_COMPOSE,
        showCompose,
        campaignId
    };
}

export function resetEmailQuery(queryId) {
    return {
        type: RESET_EMAIL_QUERY,
        queryId
    }
};

export function changeEmailFilter(queryId, key, value) {
    return {
        type: CHANGE_EMAIL_FILTER,
        filter: {
            key,
            value,
        },
        queryId
    };
}

export function removeEmailFilter(queryId, key) {
    return {
        type: REMOVE_EMAIL_FILTER,
        filter: {
            key,
        },
        queryId
    };
}

export function queryEmails(queryId, filters = {}) {
    return function (dispatch) {
        let url = jsonToQueryString(filters);
        dispatch({type: QUERY_EMAILS, queryId});

        return fetchAuthenticated(url, {credentials: 'include'})
            .then(response => response.json())
            .then(json => dispatch(receiveEmails(queryId, json)))
            .catch(err => {
                console.log("Error fetching emails");
                console.log(err)
            });
    }
}

export function receiveEmails(queryId, json) {

    return function (dispatch) {
        const emails = json.data;
        const meta = json.meta;

        emails.forEach(email => {
            dispatch(receiveEmail(email.id, {data: email}));
        });

        dispatch({type: RECEIVE_EMAILS, emails, meta, queryId, receivedAt: new Date()});
    };
}

export function receiveEmail(emailId, json) {
    console.log(json);

    return {
        type: RECEIVE_EMAIL,
        emailId,
        id: emailId,
        meta: json.meta,
        data: json.data,
        email: json.data,
        receivedAt: Date.now()
    }
}

export function selectAllEmails (emailIds, queryId) {
    return function (dispatch) {
        emailIds.forEach(emailId => {
            dispatch(selectEmail(emailId, queryId));
        });
        dispatch({type: SELECT_ALL_EMAILS, queryId})
    };
}

export function unselectAllEmails (emailIds, queryId) {
    return function (dispatch) {
        emailIds.forEach(emailId => {
            dispatch(unselectEmail(emailId, queryId));
        });
        dispatch({type: UNSELECT_ALL_EMAILS, queryId})
    };
}

export function selectEmail (emailId, queryId) {
    return {
        type: SELECT_EMAIL,
        emailId,
        queryId
    };
}

export function unselectEmail (emailId, queryId) {
    return {
        type: UNSELECT_EMAIL,
        emailId,
        queryId
    };
}


// Helper function to conver the filter json into the appropriate query string.
function jsonToQueryString(json) {
    return '/manage/api/email?' +
        Object.keys(json).map(function(key) {
            return encodeURIComponent(key) + '=' + (json[key]);
        }).join('&');
}