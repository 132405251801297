/**
 * Created by bkanber on 6/8/17.
 */

import {fetchAuthenticated} from "./auth";
import {receivePostImages} from "./postsById";

export * from './lists';
export * from './campaigns';
export * from './badges';
export * from './users';
export * from './userQueries';
export * from './emailQueries';
export * from './socials';
export * from './networks';
export * from './notifications';
export * from './workflows';
export * from './postsById';
export * from './postQueries';
export * from './teams';

export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const INVALIDATE_USER = 'INVALIDATE_USER';

export const REQUEST_USER_PROFILE = 'REQUEST_USER_PROFILE';
export const RECEIVE_USER_PROFILE = 'RECEIVE_USER_PROFILE';

export const REQUEST_USER_POSTS = 'REQUEST_USER_POSTS';
export const RECEIVE_USER_POSTS = 'RECEIVE_USER_POSTS';

export const REQUEST_USER_IMAGES = 'REQUEST_USER_IMAGES';
export const RECEIVE_USER_IMAGES = 'RECEIVE_USER_IMAGES';

export const REQUEST_USER_ACTIVATIONS = 'REQUEST_USER_ACTIVATIONS';
export const RECEIVE_USER_ACTIVATIONS = 'RECEIVE_USER_ACTIVATIONS';

export const REQUEST_USER_ACTIVATION_STATS = 'REQUEST_USER_ACTIVATION_STATS';
export const RECEIVE_USER_ACTIVATION_STATS = 'RECEIVE_USER_ACTIVATION_STATS';
export const RECEIVE_ACTIVATION_STATS = 'RECEIVE_ACTIVATION_STATS';

export const PRESENCE_TOGGLE_NOTIFICATIONS = 'PRESENCE_TOGGLE_NOTIFICATIONS';

export function toggleNotifications() {
    return {type: PRESENCE_TOGGLE_NOTIFICATIONS};
};

/**
 * @param userId
 * @returns {{type: string, userId: *}}
 */
export function requestUserActivationStats(userId) {
    return {
        type: REQUEST_USER_ACTIVATION_STATS,
        userId
    };
}

/**
 * @param json
 * @returns {{type: string, userId: *, posts, receivedAt: number}}
 */
export function receiveActivationStats(json) {
    return {
        type: RECEIVE_ACTIVATION_STATS,
        stats: json.data,
        receivedAt: Date.now()
    }
}

/**
 * @param userId
 * @param json
 * @returns {{type: string, userId: *, posts, receivedAt: number}}
 */
export function receiveUserActivationStats(userId, json) {
    return {
        type: RECEIVE_USER_ACTIVATION_STATS,
        userId,
        stats: json.data,
        receivedAt: Date.now()
    }
}
/**
 * @param userId
 * @returns {Function}
 */
export function fetchUserActivationStats(userId) {
    return function (dispatch) {
        dispatch(requestUserActivationStats(userId))

        return dispatch(fetchAuthenticated(`/manage/api/user/${userId}/activationstat`))
            .then(response => response.json())
            .then(json => dispatch(receiveUserActivationStats(userId, json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

/**
 * @param state
 * @param userId
 * @returns {*}
 */
export function shouldFetchUserActivationStats(state, userId) {
    const user = state.usersById[userId]
    if (user.isFetchingActivationStats || user.lastUpdatedActivationStats) {
        return false;
    } else {
        return true;
    }
}

/**
 * @param userId
 * @returns {function(*, *)}
 */
export function fetchUserActivationStatsIfNeeded(userId) {
    return (dispatch, getState) => {
        if (shouldFetchUserActivationStats(getState(), userId)) {
            return dispatch(fetchUserActivationStats(userId))
        }
    }
}

/**
 * @param userId
 * @returns {{type: string, userId: *}}
 */
export function requestUserImages(userId) {
    return {
        type: REQUEST_USER_IMAGES,
        userId
    };
}

/**
 * @param userId
 * @param json
 * @returns {{type: string, userId: *, posts, receivedAt: number}}
 */
export function receiveUserImages(userId, json) {
    return {
        type: RECEIVE_USER_IMAGES,
        userId,
        images: json.data,
        receivedAt: Date.now()
    }
}

/**
 * @param userId
 * @returns {Function}
 */
export function fetchUserImages(userId) {
    return function (dispatch) {
        dispatch(requestUserImages(userId))

        return dispatch(fetchAuthenticated(`/manage/api/user/${userId}/image`))
            .then(response => response.json())
            .then(json => dispatch(receiveUserImages(userId, json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

/**
 * @param state
 * @param userId
 * @returns {*}
 */
export function shouldFetchUserImages(state, userId) {
    const user = state.usersById[userId]
    // todo replace user.imageIds check with lastUpdatedImages check
    if (user.isFetchingImages || user.imageIds.length > 0) {
        return false;
    } else if (user.imageIds.length === 0) {
        return true;
    } else {
        return user.didInvalidate;
    }
}

/**
 * @param userId
 * @returns {function(*, *)}
 */
export function fetchUserImagesIfNeeded(userId) {
    return (dispatch, getState) => {
        if (shouldFetchUserImages(getState(), userId)) {
            return dispatch(fetchUserImages(userId))
        }
    }
}

/**
 * @param userId
 * @returns {{type: string, userId: *}}
 */
export function requestUserActivations(userId) {
    return {
        type: REQUEST_USER_ACTIVATIONS,
        userId
    };
}

/**
 * @param userId
 * @param json
 * @returns {{type: string, userId: *, posts, receivedAt: number}}
 */
export function receiveUserActivations(userId, json) {
    return {
        type: RECEIVE_USER_ACTIVATIONS,
        userId,
        activations: json.data,
        receivedAt: Date.now()
    }
}

/**
 * @param userId
 * @returns {Function}
 */
export function fetchUserActivations(userId) {
    return function (dispatch) {
        dispatch(requestUserActivations(userId))

        const slot = window.Gator.getDashboardSlot();

        return dispatch(fetchAuthenticated(`/${slot}/api/user/${userId}/activation`))
            .then(response => response.json())
            .then(json => dispatch(receiveUserActivations(userId, json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

/**
 * @param state
 * @param userId
 * @returns {*}
 */
export function shouldFetchUserActivations(state, userId) {
    const user = state.usersById[userId] || {};
    if (user.isFetchingActivations || user.lastUpdatedActivations) {
        return false;
    } else if ((user.activationIds || []).length === 0) {
        return true;
    } else {
        return user.didInvalidate;
    }
}

/**
 * @param userId
 * @returns {function(*, *)}
 */
export function fetchUserActivationsIfNeeded(userId) {
    return (dispatch, getState) => {
        if (shouldFetchUserActivations(getState(), userId)) {
            return dispatch(fetchUserActivations(userId))
        }
    }
}
/**
 * @param userId
 * @returns {{type: string, userId: *}}
 */
export function requestUserPosts(userId) {
    return {
        type: REQUEST_USER_POSTS,
        userId
    };
}

/**
 * @param userId
 * @param json
 * @returns {{type: string, userId: *, posts, receivedAt: number}}
 */
export function receiveUserPosts(userId, json) {
    return function (dispatch) {
        const posts = json.data || [];

        posts.forEach(post => dispatch(receivePostImages(post.images)));

        return dispatch({
            type: RECEIVE_USER_POSTS,
            userId,
            posts,
            receivedAt: Date.now()
        });

    }
}

/**
 * @param userId
 * @returns {Function}
 */
export function fetchUserPosts(userId) {
    return function (dispatch) {
        dispatch(requestUserPosts(userId))
        const slot = window.Gator.getDashboardSlot();
        return dispatch(fetchAuthenticated(`/${slot}/api/user/${userId}/post`))
            .then(response => response.json())
            .then(json => dispatch(receiveUserPosts(userId, json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

/**
 * @param state
 * @param userId
 * @returns {*}
 */
export function shouldFetchUserPosts(state, userId) {
    const user = state.usersById[userId]
    if (user.isFetchingPosts || user.postIds.length > 0) {
        return false;
    } else if (user.postIds.length === 0) {
        return true;
    } else {
        return user.didInvalidate;
    }
}

/**
 * @param userId
 * @returns {function(*, *)}
 */
export function fetchUserPostsIfNeeded(userId) {
    return (dispatch, getState) => {
        if (shouldFetchUserPosts(getState(), userId)) {
            return dispatch(fetchUserPosts(userId))
        }
    }
}

export function requestUserProfile(userId) {
    return {
        type: REQUEST_USER_PROFILE,
        userId
    };
}

export function receiveUserProfile(userId, json) {
    return {
        type: RECEIVE_USER_PROFILE,
        userId,
        profile: json.data,
        receivedAt: Date.now()
    }
}

export function fetchUserProfile(userId) {
    return function (dispatch) {
        dispatch(requestUserProfile(userId))

        return dispatch(fetchAuthenticated(`/manage/api/user/${userId}/profile`))
            .then(response => response.json())
            .then(json => dispatch(receiveUserProfile(userId, json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

export function shouldFetchUserProfile(state, userId) {
    const user = state.usersById[userId]
    if (user.didInvalidateProfile) {
        return true;
    } else if (user.isFetchingProfile || user.lastUpdatedProfile) {
        return false;
    } else {
        return true;
    }
}

export function fetchUserProfileIfNeeded(userId) {
    return (dispatch, getState) => {
        if (shouldFetchUserProfile(getState(), userId)) {
            return dispatch(fetchUserProfile(userId))
        }
    }
}

export function invalidateUser(userId) {
    return {
        type: INVALIDATE_USER,
        userId
    }
}

export function requestUser(userId) {
    return {
        type: REQUEST_USER,
        userId
    }
}

export function receiveUser(userId, json) {
    console.log("RECEIVE_USER " + userId);
    console.log(json);

    return {
        type: RECEIVE_USER,
        userId,
        user: json.data,
        receivedAt: Date.now()
    }
}

export function fetchUserStats (userId) {
    return function (dispatch) {

        const slot = window.Gator.getDashboardSlot();
        return dispatch(fetchAuthenticated(`/${slot}/api/user/${userId}/stat`))
            .then(response => response.json())
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}
export function fetchUser(userId) {
    console.log("Calling fetchUser on " + userId);
    return function (dispatch) {
        dispatch(requestUser(userId))
        console.log("Just dispatched requestUser, now returning promise");

        return dispatch(fetchAuthenticated(`/manage/api/user/${userId}`))
            .then(response => response.json())
            .then(json => dispatch(receiveUser(userId, json)))
            .catch(err => {
                console.log("Error fetching")
                console.log(err)
            });

    }
}

export function shouldFetchUser(state, userId) {

    const user = state.usersById[userId];
    if (!user || !user.id) {
        return true;
    } else if (user.isFetching) {
        return false;
    } else {
        return user.didInvalidate;
    }
}

export function fetchUserIfNeeded(userId) {
    return (dispatch, getState) => {
        if (shouldFetchUser(getState(), userId)) {
            return dispatch(fetchUser(userId))
        }
    }
}