import {receiveItems, modifyItem, receiveItem, deleteKey} from "../utilities/reducer";
import {combineReducers} from 'redux';
import * as ActionTypes from '../actions/types';

const DEFAULT_CATALOG_STATE = {
    isFetchingProducts: false,
    didFetchProducts: false,
};

const DEFAULT_PRODUCT_STATE = {
    isUpdating: false,
    didUpdate: false,
};

const catalogsById = (state = {}, action) => {
    switch (action.type) {

        case ActionTypes.PRODUCTS_REQUEST_CATALOG_PRODUCTS:
            return modifyItem(state, action, {isFetchingProducts: true}, DEFAULT_CATALOG_STATE);

        case ActionTypes.PRODUCTS_RECEIVE_CATALOG_PRODUCTS:
            return modifyItem(
                state,
                action,
                {isFetchingProducts: false, didFetchProducts: true},
                DEFAULT_CATALOG_STATE
            );

        case ActionTypes.PRODUCTS_RECEIVE_CATALOGS:
            return receiveItems(state, action, {}, DEFAULT_CATALOG_STATE);

        default:
            return state;
    }

};

const productsById = (state = {}, action) => {

    switch (action.type) {

        case ActionTypes.UPDATE_CAMPAIGN_PRODUCT:
            return modifyItem(state, action, {isUpdating: true}, DEFAULT_PRODUCT_STATE);

        case ActionTypes.UPDATED_CAMPAIGN_PRODUCT:
            return receiveItem(state, action, {isUpdating: false, didUpdate: true}, DEFAULT_PRODUCT_STATE);

        case ActionTypes.PRODUCTS_RECEIVE_CATALOG_PRODUCTS:
        case ActionTypes.PRODUCTS_RECEIVE_CAMPAIGN_PRODUCTS:
            return receiveItems(state, action, {}, DEFAULT_PRODUCT_STATE);
        case ActionTypes.PRODUCTS_DELETE_CATALOG_PRODUCT:
            return deleteKey(state, action.id);

        default:
            return state;
    }

};

export const products = combineReducers({
    catalogs: catalogsById,
    items: productsById,
});
