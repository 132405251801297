import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LiteTableRow from "./LiteTableRow";

class LiteTable extends Component {

    renderRows() {
        return this.props.items.map(item => {
            return (
                <LiteTableRow
                    content={item}
                    rowSwitches={this.props.rowSwitches}
                />
            )
        })
    }

    render() {

        return (
            <div className="lite-table">
                {this.renderRows()}
            </div>
        )
    }

}


LiteTable.propTypes = {
    items: PropTypes.array.isRequired,
    rowSwitches: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.node,
        tooltip: PropTypes.string,
        onClick: PropTypes.func,
    })),
};

export default LiteTable;