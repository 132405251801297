/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import GenericDonutChart from '../../Chart/GenericDonutChart';
import {limitChars} from '../../../utilities';
import d3 from 'd3';

export default class CampaignAudienceAffinityDonutChart extends Component {

    getData() {
        if (!this.props.audience || this.props.audience.length === 0) return null;

        let totals = { };
        let data = { };
        let sortable = [];
        let total = 0;

        for (let row of this.props.audience) {
            let affinity = row[2];
            let value = parseInt(row[3], 10);
            if (typeof totals[affinity] === 'undefined') {
                totals[affinity] = 0;
            }
            totals[affinity] = totals[affinity] + value;
            total += value;
        }

        if (total === 0) return null;

        for (let affinity in totals) {
            sortable.push({
                affinity,
                value: totals[affinity]
            });
        }

        sortable = sortable.sort((a, b) => b.value > a.value ? 1 : -1);
        for (let row of sortable.slice(0, 10)) {
            data[row.affinity] = 100 * row.value / total;
        }

        return data;

    }

    renderChart() {
        const data = this.getData();
        if (!data) return null;

        return <GenericDonutChart
            data={data}
            title="Audience Affinity"
            legend={{show: false}}
            labelFormatter={
                (value, ratio, id) => limitChars(id, 8)
            }
            tooltipValueFormatter={
                (value, ratio, id) => d3.format('.1f')(value) + '% of audience'
            }
        />


    }

    render() {

        return (

            <div className="widget">
                <div className="title">
                    <div className="icon left">
                        <i className="fa fa-pie-chart"></i>
                    </div>
                    <h2>
                        Audience Affinity
                    </h2>
                </div>
                <div className="content padded clearfix">
                    {this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
                </div>
            </div>

        );
    }
}
