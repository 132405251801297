import React, {useEffect, useState} from 'react';
import Button from "../../Common/Form/Button";
import Toolbox from "../../Common/Toolbox";
import Form from "../../Common/Form/Form";
import Alert from "../../Common/Alert";
import SocialAuthConnector from "../EditProfile/Templates/SocialAuthConnector";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {requestLogin, requestSignup} from "../../../actions/auth";
import {fetchPresence} from "../../../actions/presence";
import {getCurrentUser} from "../../../selectors/presence";
import {createUserInteraction} from "../../../actions";

function ListSurveyForm({requestLogin, requestSignup, fetchPresence, createUserInteraction, onSubmitted}) {

    // bobbi brown's custom profile spec
    const SPEC = [
        {
            "type": "select",
            "name": "content_focus",
            "title": "Content Focus",
            "choices": [
                {
                    "text": "- Select One -",
                    "value": null
                },
                {
                    "text": "Beauty",
                    "value": "Beauty"
                },
                {
                    "text": "Lifestyle",
                    "value": "Lifestyle"
                },
                {
                    "text": "Fitness",
                    "value": "Fitness"
                },
                {
                    "text": "MUA",
                    "value": "MUA"
                },
                {
                    "text": "Parenting/Mom",
                    "value": "Moms"
                },
                {
                    "text": "Skincare",
                    "value": "Skincare"
                }
            ]
        },
        {
            "type": "select",
            "name": "skin_type",
            "title": "Skin Type",
            "choices": [
                {
                    "text": "- Select One -",
                    "value": null
                },
                {
                    "text": "Dry",
                    "value": "dry"
                },
                {
                    "text": "Oily",
                    "value": "oily"
                },
                {
                    "text": "Combination",
                    "value": "combination"
                },
                {
                    "text": "Normal",
                    "value": "normal"
                },
                {
                    "text": "Sensitive",
                    "value": "sensitive"
                }
            ]
        },
        {
            "type": "select",
            "name": "primary_skin_concern",
            "title": "Primary Skin Concern",
            "choices": [
                {
                    "text": "- Select One -",
                    "value": null
                },
                {
                    "text": "Acne",
                    "value": "acne"
                },
                {
                    "text": "Anti-Aging",
                    "value": "anti-aging"
                },
                {
                    "text": "Blemishes",
                    "value": "blemishes"
                },
                {
                    "text": "Dark Spots",
                    "value": "dark-spots"
                },
                {
                    "text": "Dryness",
                    "value": "dryness"
                },
                {
                    "text": "Dullness",
                    "value": "dullness"
                },
                {
                    "text": "Redness",
                    "value": "redness"
                },
                {
                    "text": "Fine Lines and Wrinkles",
                    "value": "wrinkles"
                },
                {
                    "text": "Undereye Darkness",
                    "value": "undereye-darkness"
                },
                {
                    "text": "Uneven Tone and Texture",
                    "value": "tone"
                }
            ]
        },
        {
            "type": "textarea",
            "name": "other_skin_concerns",
            "title": "Other Skin Concerns",
            "placeholder": "List anything else you think is important for us to know about your skin."
        },
        {
            "type": "select",
            "name": "makeup_preference",
            "title": "What Make-up Finish do you Prefer?",
            "choices": [
                {
                    "text": "- Select One -",
                    "value": null
                },
                {
                    "text": "Matte",
                    "value": "Matte"
                },
                {
                    "text": "Dewy",
                    "value": "Dewy"
                },
                {
                    "text": "Radiant",
                    "value": "Radiant"
                }
            ]
        },
        {
            "type": "select",
            "name": "preferred_coverage",
            "title": "What Coverage do you Like?",
            "choices": [
                {
                    "text": "- Select One -",
                    "value": null
                },
                {
                    "text": "Light",
                    "value": "Light"
                },
                {
                    "text": "Medium",
                    "value": "Medium"
                },
                {
                    "text": "Full",
                    "value": "Full"
                }
            ]
        },
        {
            "type": "select",
            "name": "undereye_undertones",
            "title": "What are your Undereye Undertones?",
            "choices": [
                {
                    "text": "- Select One -",
                    "value": null
                },
                {
                    "text": "Blueish/Purple",
                    "value": "Blueish/Purple"
                },
                {
                    "text": "Greenish/Brown",
                    "value": "Greenish/Brown"
                },
                {
                    "text": "Hmmm, I'm not sure",
                    "value": "Hmmm, I'm not sure"
                }
            ]
        }
    ];
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({});

    const handleSubmit = () => {

        if (onSubmitted) {
            onSubmitted();
        }
    };

    return (
        <Form
            values={formValues}
            onFieldChange={(name, value) => {
                console.log("field change", name, value);
                setFormValues({...formValues, [name]: value});
            }}
            fields={[
                ...SPEC,
                error ? {
                    name: "error",
                    type: "generic",
                    content: (
                        <Alert
                            content={error}
                            classes={["danger"]}
                        />
                    )
                } : null,
                {
                    name: "submit",
                    type: "button",
                    title: "Submit",
                    options: {
                        hideTitle: true,
                        classes: ["v3", "btn-primary", "medium"],
                        onClick: handleSubmit
                    }
                },
                {
                    name: "terms",
                    type: "generic",
                    content: (
                        <div>
                            <p className="help">By applying, you agree to our terms and privacy policy.</p>
                        </div>
                    )
                }

            ]}
        />
    );

}


function ListSignupForm({requestLogin, requestSignup, fetchPresence, createUserInteraction, onSubmitted}) {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        email: "",
        name: "",
        instagram: "",
        tiktok: "",
        country: "",
    });

    const isFormValid = () => {
        // simple email regex, just check for any characters, @, and .
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // instagram regex is just username longer than 4 characters with optional @ sign
        const instagramRegex = /^@?[a-zA-Z0-9_]{4,30}$/;
        // tiktok regex is just username longer than 4 characters with optional @ sign
        const tiktokRegex = /^@?[a-zA-Z0-9_]{4,30}$/;
        // name regex is any characters, then one or more space characters, then any characters
        const nameRegex = /^\S+(\s+\S+)+$/;
        // country must be "US" or "CA"
        const countryRegex = /^(US|CA)$/;

        // go one at a time
        if (!emailRegex.test(formValues.email)) {
            return "Enter a valid email address.";
        }
        if (!instagramRegex.test(formValues.instagram)) {
            return "Enter a valid Instagram username.";
        }
        if (!tiktokRegex.test(formValues.tiktok)) {
            // Not requiring TT right now.
            // return "Enter a valid TikTok username.";
        }
        if (!nameRegex.test(formValues.name)) {
            return "Your full name must be at least two words.";
        }
        if (!countryRegex.test(formValues.country)) {
            return "You must be located in the US or Canada to apply.";
        }
        return true;
    }

    const handleSignup = () => {
        const formValid = isFormValid();
        if (formValid !== true) {
            setError(formValid);
            return;
        }
        setError(null);
        // demo mode
        if (onSubmitted) {
            onSubmitted();
        }
    }

    return (
        <Form
            values={formValues}
            onFieldChange={(name, value) => {
                console.log("field change", name, value);
                setFormValues({...formValues, [name]: value});
            }}
            fields={[
                {
                    name: "email",
                    title: "Email *",
                    type: "text",
                    placeholder: "you@example.com",
                    required: true,
                },
                {
                    name: "name",
                    title: "Full Name *",
                    type: "text",
                    placeholder: "",
                    required: true,
                },
                {
                    name: "instagram",
                    title: "Instagram Username *",
                    type: "text",
                    placeholder: "@",
                    required: true,
                },
                {
                    name: "tiktok",
                    title: "TikTok Username",
                    type: "text",
                    placeholder: "@",
                    required: true,
                },
                {
                    name: "country",
                    title: "Country *",
                    type: "select",
                    required: true,
                    choices: [
                        {value: "US", text: "United States"},
                        {value: "CA", text: "Canada"},
                        {value: "other", text: "Other"},
                    ]
                },
                error ? {
                    name: "error",
                    type: "generic",
                    content: (
                        <Alert
                            content={error}
                            classes={["danger"]}
                        />
                    )
                } : null,
                {
                    name: "submit",
                    type: "button",
                    title: "Submit",
                    options: {
                        hideTitle: true,
                        classes: ["v3", "btn-primary", "medium"],
                        onClick: handleSignup
                    }
                },
                {
                    name: "terms",
                    type: "generic",
                    content: (
                        <div>
                            <p className="help">By applying, you agree to our terms and privacy policy.</p>
                        </div>
                    )
                }
            ]}
        />
    )
}






function PublicListJoiner({listId, requestLogin, requestSignup, user, fetchPresence, createUserInteraction}) {

    const [showForm, setShowForm] = useState(false);
    const [showSurvey, setShowSurvey] = useState(false);
    const [showComplete, setShowComplete] = useState(false);

    return (
        <>
            {showComplete && (
                <Toolbox
                    title={"Application Submitted"}
                    onClose={() => setShowComplete(false)}
                    content={ <div className="application-submitted">
                        <p>Thank you for applying to the Bobbi Brown Cosmetics Creator Community! Please hang tight while we review your application. This process can take up to 2 weeks. We’ll reach out to you directly via e-mail if you have been selected. Xx. </p>
                    </div>}
                    addlClasses={"toolbox-fixed toolbox-sm"}
                    supportMobile={true}
                />

            )}
            {showSurvey && (
                <Toolbox
                    title={"Apply"}
                    onClose={() => setShowSurvey(false)}
                    content={ <ListSurveyForm
                        onSubmitted={() => {
                            setShowSurvey(false);
                            setShowComplete(true);
                        }}
                    />}
                    addlClasses={"toolbox-fixed toolbox-sm"}
                    supportMobile={true}
                />
            )}
            {showForm && (
                <Toolbox
                    title={"Apply"}
                    onClose={() => setShowForm(false)}
                    content={ <ListSignupForm
                        onSubmitted={() => {
                            setShowForm(false);
                            setShowSurvey(true);

                        }}
                    />}
                    addlClasses={"toolbox-fixed toolbox-sm"}
                    supportMobile={true}
                />
            )}

            <Button
                content={"Apply"}
                classes={["v3", "btn-primary", "medium"]}
                onClick={() => {
                    setShowForm(true);
                }}
                className={"join-list-button"} style={{width: "100%"}}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: getCurrentUser(state),
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    requestLogin: requestLogin,
    requestSignup: requestSignup,
    fetchPresence: fetchPresence,
    createUserInteraction: createUserInteraction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PublicListJoiner);