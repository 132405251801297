import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ChannelEngagementLeaderboard from '../Social/Chart/ChannelEngagementLeaderboard';
import Toolbox from '../Common/Toolbox';

class HBInfluencerLeaderboard extends Component {

    static propTypes = {
        listenerId: PropTypes.any,
        openLightbox: PropTypes.func,
    };

    state = {
        showWarning: false,
    };

    renderPauseNotice() {
        return (
            <Toolbox
                title={<span><i className="v3 icon exclamation-triangle" style={{marginRight: 11}}/> Important Notice</span>}
                content={
                    <div style={{padding: 15}}>
                        <p className="v3 h6 light">
                            Social Circle has officially been live for one Quarter! We’d like to thank you all for all of the Likes, Loves, Comments and Shares that have been sending our results soaring on our H&B Social pages! You guys truly are our best ambassadors – and we’re really pleased with the level of participation we’re seeing currently.
                        </p>
                        <p className="v3 h6 light">
                            As with all new initiatives, we’ve also experienced some teething problems, that some of you will have observed. As such, we need to hit pause on initiative, temporarily, to work through these as a team – so for the month of March, league tables will remain at zero while we look to address a couple of technical challenges. We’ve had an overwhelmingly positive response to the platform, so don’t worry – we will be back! However, if you have any questions around this, please do feel free to email <a href="mailto:socialsupport@hollandandbarrett.com">socialsupport@hollandandbarrett.com</a> – and I’ll endeavour to answer any queries you may have. All February Rewards and Quarterly Rewards will be honoured for those who may be wondering <i className="fa fa-smile" />.
                        </p>
                        <p className="v3 h6 light">
                            Thanks again for your support on Social Media so far – and watch this space for Social Circle’s imminent return! <br />The Social Media Team ♥
                        </p>
                    </div>
                }
                style={{
                    zIndex: 200,
                    top: 0,
                    left: '50%',
                    marginLeft: -250,
                    width: 500
                }}

            />

        );
    }
    renderWarning() {
        if (!this.state.showWarning) {
            return null;
        }

        return (
            <Toolbox
                title={<span><i className="v3 icon exclamation-triangle" style={{marginRight: 10}}/> Important Notice</span>}
                onClose={() => this.setState({showWarning: false})}
                content={
                    <div style={{padding: 15}}>
                        <p className="v3 h6 light">
                            We've received reports of Facebook users being blocked for liking too many posts at once.
                        </p>
                        <p className="v3 h6 light">
                            As a result, we will only count interactions made on recent H&B posts. <strong>Please do not</strong> like dozens of posts at once, as that puts your account at risk of being blocked, and you will not receive points for liking posts older than a month.
                        </p>
                        <p className="v3 h6 light">
                            Thank you for your enthusiastic cooperation!
                        </p>
                    </div>
                }
                style={{
                    zIndex: 200,
                    top: 0,
                    left: '50%',
                    marginLeft: -200,
                    width: 400
                }}

            />

        );

    }

    render() {

        return (
            <div>
                {this.renderPauseNotice()}
                <ChannelEngagementLeaderboard
                    listenerId={this.props.listenerId}
                    mode={'employee'}
                />
            </div>
        );

    }
}

export default HBInfluencerLeaderboard;

