/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import {formatNumber} from '../../../utilities';
import ChartWrapper from "../../Chart/ChartWrapper";
import RechartsDonutChart from "../../Chart/RechartsDonutChart";

export default class CampaignParticipantGenderDonutChart extends Component {

    getTotal() {
        return this.props.users.length || null;
    }

    getData() {
        if (!this.props.users || this.props.users.length === 0) return null;

        let data = {
            Male: 0,
            Female: 0,
            Unknown: 0
        };

        for (let user of this.props.users) {
            let gender = user.basics.gender;
            if (gender && typeof data[gender] !== 'undefined') {
                data[gender] ++;
            } else {
                data.Unknown++;
            }

        }

        let chartData = [];
        for (let entry in data) {
            chartData.push({
                name: entry,
                value: data[entry]
            })
        }

        return chartData;

    }

    renderChart() {
        const data = this.getData();
        if (!data) return null;

        return <RechartsDonutChart
            data={data}
            title="Participant Gender"
        />
    }

    render() {
        const total = this.getTotal();
        const titleBadge = total !== null ? `${formatNumber(total)} creators` : null;

        return (

            <ChartWrapper
                title="Participant Gender"
                titleBadge={titleBadge}
                icon=""
                chart={this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div>}
            />

        );
    }
}
