import React, {Component} from 'react';
import PropTypes from 'prop-types';

class FilterTab extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {

        const {onClick, column} = this.props;

        if (!column.search) {
            return null;
        }

        if (!onClick) {
            return null;
        }

        onClick(this.props.column, event);
    }

    getStyle() {

        const {column} = this.props;
        const style = {};

        if (column.width) {
            style.width = column.width;
            style.minWidth = column.width;
        }

        if (column.sticky) {
            if (column.stickyLeft) {
                style.left = column.stickyLeft;
            } else {
                style.left = 0;
            }
        }

        return style;

    }

    getValue() {
        const {value, column} = this.props;

        let displayValue = column.filterDisplayValue ? column.filterDisplayValue : value;
        let style = { };

        if (!displayValue && column.search) {
            style.color = '#DDD';
            displayValue = 'Filter';
        }

        if (typeof displayValue === 'string') {
            style.width = (column.width || 100) - 50;
        }

        return <span
            style={style}
            className="filter">{displayValue}</span>

    }

    getIcon() {
        const {column} = this.props;
        if (!column.search) return null;
        if (column.filterDisplayValue && typeof column.filterDisplayValue !== 'string') {
            // Hide the icon if filterDisplayValue is an element
            return null;
        }

        return <span className="icon">
            <i className="fa fa-ellipsis-v" />
        </span>

    }

    getClassName() {

        const {isActive, column} = this.props;
        let classes = ["godzilla-table-filter-cell"];

        if (isActive) {
            classes.push('active');
        }

        if (column.search) {
            classes.push('filterable');
        }

        if (column.sticky) {
            classes.push('stick-to-left');
        }

        return classes.join(" ");
    }

    render() {
        return (
            <div className={this.getClassName()}
                 style={this.getStyle()}
                 onClick={this.handleClick}
            >
                {this.getValue()}
                {this.getIcon()}
            </div>
        );
    }
}

FilterTab.defaultProps = {
};

FilterTab.propTypes = {
    column: PropTypes.array.isRequired,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.any,
};

export default FilterTab;