/**
 * Created by bkroger on 4/26/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getVisibleInfluencerRequirementTypes, getCompletedInfluencerRequirementTypes} from "../../../../utilities/campaign";
import NavStacked from "../../../Common/NavStacked";
import _get from 'lodash/get';


class InfluencerRequirementsList extends Component {

    static defaultProps = {
        useMobileNav: false,
    };

    static propTypes = {
        activation: PropTypes.object.isRequired,
        useMobileNav: PropTypes.bool,
        onClickCreateIssue: PropTypes.func,
        onClickShowIssues: PropTypes.func,
        refreshUser: PropTypes.func,
        user: PropTypes.object,
        translate: PropTypes.func,
    };

    // constructor(props) {
    //     super(props);
    //
    //     this.state = {
    //         isMobile: window.innerWidth < 992,
    //     };
    // }
    //
    // componentDidMount() {
    //     window.addEventListener('load', () => this.handleMobileState(), false);
    //     window.addEventListener('resize', () => this.handleMobileState(), false);
    //     window.addEventListener('orientationchange', () => this.handleMobileState(), false);
    // }
    //
    // handleMobileState() {
    //     return this.setState({
    //         isMobile: window.innerWidth < 992
    //     });
    // }

    renderRightIcon(spec) {
        const {activation, user} = this.props;
        let isComplete = false;
        let isBlocked = false;

        if (typeof spec.isComplete === 'function') {
            isComplete = spec.isComplete(activation, user);
        }

        if (typeof spec.isBlocked === 'function') {
            isBlocked = spec.isBlocked(activation, user);
        }

        if (isComplete) {
            return <i className="v3 icon check-circle"></i>;
        }

        if (isBlocked) {
            return <i className="v3 icon ban" />;
        }

        return null;
    }

    renderTag(spec) {
        const {activation, user} = this.props;
        let isComplete = false;
        let isBlocked = false;

        if (typeof spec.isComplete === 'function') {
            isComplete = spec.isComplete(activation, user);
        }

        if (typeof spec.isBlocked === 'function') {
            isBlocked = spec.isBlocked(activation, user);
        }

        if (typeof spec.tag === 'function') {
            let tag = spec.tag(activation, user, this.props.translate);

            const rightPos = isComplete || isBlocked ? 50 : 20;
            const style = {
                right: rightPos
            };

            if (!tag) {
                return null;
            } else {
                return <div className="v3 tidal-tag light bg-transparent" style={style}>{tag}</div>;
            }
        }

        return null;
    }

    renderMenu() {

        const {currentView, onViewChange, activation, useMobileNav, user} = this.props;
        const _t = this.props.translate || ((k,v) => v);

        if (!activation || !activation.id || !activation.settings) {
            return null;
        }

        const items = getVisibleInfluencerRequirementTypes(activation, user)
            .map(spec => {
                const tKey = `campaign.reqs.${spec.key}`;
                const tTitle = _t(tKey + '.title', spec.title);
                const tShortTitle = _t(tKey + '.shorttitle', spec.shortTitle);
                return {
                    title: ((useMobileNav && spec.shortTitle) ? tShortTitle : tTitle),
                    onClick: () => onViewChange(spec),
                    rightIcon: this.renderRightIcon(spec),
                    tag: this.renderTag(spec),
                    isActive: (currentView && currentView.key === spec.key),
                };
            });

        return (
            <NavStacked
                items={items}
                isHorizontal={useMobileNav}
                showTags={!useMobileNav}
                showNumbers={true}
            />
        )

    }

    renderProgressBadge() {

        const {activation, user} = this.props;
        const _t = this.props.translate;

        if (!activation || !activation.id || !activation.settings) {
            return null;
        }

        const totalRequirements = getVisibleInfluencerRequirementTypes(activation, user).length;
        const completeRequirements = getCompletedInfluencerRequirementTypes(activation, user).length;

        return (
            <div>
                <span className="v3 number-badge small accent">{completeRequirements} {_t('campaign.reqs.of', 'of')} {totalRequirements} {_t('campaign.reqs.done', 'Done')}</span>
            </div>
        )
    }

    renderIssuesLinks() {

        const _t = this.props.translate || ((k, v) => v);
        const activation = this.props.activation;
        const status = activation.status;
        const openIssuesCount = _get(status, 'open_issues_count', 0);
        const totalIssuesCount = _get(status, 'total_issues_count', 0);

        const showIssues = totalIssuesCount > 0;
        let issuesIcon = openIssuesCount > 0 ? <i className="v3 icon error" /> : <i className="v3 icon exclamation-triangle" />;
        let issuesText = openIssuesCount > 0 ? `${openIssuesCount} ${_t('campaign.open_issues', 'Open Issues')}` : _t('campaign.view_issues', 'View Issues');
        const showIssuesClass = openIssuesCount > 0 ? 'has-open-issues' : '';

        return (
            <div className="influencer-requirements-switches-wrapper">

                {
                    showIssues &&
                    <a className={"v3 bold h7 show-issues " + showIssuesClass} role="button" onClick={this.props.onClickShowIssues}>
                        {issuesIcon} {issuesText}
                    </a>
                }

                <a className="v3 bold h7 create-issue" role="button" onClick={this.props.onClickCreateIssue}>
                    <i className="v3 icon info" /> {_t('campaign.have_an_issue', 'Have an Issue?')}
                </a>


            </div>
        );

    }

    render() {

        const _t = this.props.translate;
        //const mobileClass = this.state.isMobile ? 'mobile' : '';

        return (
            <div className="list-wrapper">
                <div className="list-header">
                    <h4 className="v3 bold list-header-title">{_t('campaign.todos', 'To-Dos')}</h4>
                    {this.renderProgressBadge()}
                </div>
                {this.renderIssuesLinks()}
                {this.renderMenu()}
            </div>
        )
    }

}

export default InfluencerRequirementsList;
