/**
 * Created by bkroger on 10/10/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

class VideoTile extends Component {

    static propTypes = {
        video: PropTypes.node.isRequired,
        onRemoveVideo: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            error: false,
        }
    }

    handleError(e) {
        this.setState({
            error: true
        });

        setTimeout(() => {
            this.setState({
                error: false
            });
        }, 10000);
    }

    renderVideo() {
        return (
            <div className="video-tile-inner" style={{
                top: '50%',
                transform: 'translateY(-50%)',
                position: 'relative',
                maxHeight: '100%',
                textAlign:'center'
            }}>
                <video
                    onError={this.handleError.bind(this)}
                    src={this.props.video.source}
                    controls
                    style={{
                        maxWidth: '100%',
                        maxHeight: '160px',
                    }}
                />
            </div>
        );
    }

    renderErrorMessage() {
        return (
            <h5 className="v3 light" style={{top: '50%', transform: 'translateY(-50%)', position: 'relative', textAlign: 'center'}}>Processing Video... <br /> <small>(You may continue)</small></h5>
        );
    }

    renderContent() {
        if (this.state.error) {
            return this.renderErrorMessage();
        }

        return this.renderVideo();
    }

    render() {

        return(
            <div
                className="video-tile-wrapper"
                style={{
                    height: 200,
                    float: 'left',
                    background: '#EEE',
                    marginBottom: 20,
                    position: 'relative',
                    padding: 20,
                }}

            >
                {
                    this.props.onRemoveVideo &&
                    <a className="v3 close modal bg-primary-dark white"
                       role="button"
                       onClick={this.props.onRemoveVideo}
                       style={{
                           left: 'inherit',
                           zIndex: 199,
                       }}
                    />
                }


                {this.renderContent()}

            </div>
        )

    }

}

export default VideoTile;