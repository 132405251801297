import React, {Component} from 'react';
import PropTypes from 'prop-types';
import EventDivider from '../../Common/TimelineView/EventDivider';
import moment from 'moment';
import RaisedIssueInteractionMessage from './RaisedIssueInteractionMessage';
import CommentedOnIssueInteractionMessage from './CommentedOnIssueInteractionMessage';
import SentEmailInteractionMessage from './SentEmailInteractionMessage';
import DraftInteractionMessage from './DraftInteractionMessage';

const getMessageForInteraction = interaction => {
    switch (interaction.type) {
        case "Tidal\\Campaign\\Activation\\Interaction\\AcceptAfterWaitlist":
            return `${interaction.actor.name} accepted the influencer.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\AcceptPost":
            return `${interaction.actor.name} approved a post.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\Fulfill":
            return `All influencer requirements are fulfilled!`;
        case "Tidal\\Campaign\\Activation\\Interaction\\Complete":
            return `The activation has been completed!`;
        case "Tidal\\Campaign\\Activation\\Interaction\\UploadVideo":
            return `${interaction.actor.name} uploaded a video.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\UploadImage":
            return `${interaction.actor.name} uploaded an image.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\SelectProduct":
            return `${interaction.actor.name} selected a product.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\ReceiveProduct":
            return `${interaction.actor.name} has marked their product shipment as received.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\UpdateSettings":
            return `${interaction.actor.name} customized activation settings.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\SubmitBlogPost":
            return `${interaction.actor.name} submitted a blog post.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\AssignCouponCode":
            return `${interaction.actor.name} assigned a coupon code.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\ShipProduct":
            return `${interaction.actor.name} marked product samples as Shipped.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\InviteReminder":
            return `${interaction.actor.name} sent an invitation reminder message.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\UpdatePaymentOffer":
            return `${interaction.actor.name} updated the payment amount to $${interaction.context.payment_amount || 0}.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\GenerateInvoice":
            return `${interaction.actor.name} approved a payment for $${interaction.results.amount || 0}.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\Cancel":
            return `${interaction.actor.name} canceled the activation.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\Waitlist":
            return `${interaction.actor.name} waitlisted the activation.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\Invite":
            return `${interaction.actor.name} sent an invitation to the campaign.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\UpdateMetadata":
            return `${interaction.actor.name} updated metadata for "${interaction.context.key}".`;
        case "Tidal\\Campaign\\Activation\\Interaction\\RespondToInvitation":
            return `${interaction.actor.name} ${interaction.context.response}ed the invitation.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\UpdateDraftStatus":
            return `${interaction.actor.name} updated a draft's status.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\CommentOnDraft":
            return `${interaction.actor.name} commented on a draft.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\SubmitDraft":
            return `${interaction.actor.name} updated a draft.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\SubmitSocialPost":
            return `${interaction.actor.name} submitted a social post.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\SendEmailTemplate":
            return `${interaction.actor.name} sent an email.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\SendEmail":
            return `${interaction.actor.name} sent a message.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\ViewActivationPage":
            return `${interaction.actor.name} viewed their dashboard.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\SubmitFormResponse":
            return `${interaction.actor.name} submitted a form.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\ResolveIssue":
            return `${interaction.actor.name} resolved an issue.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\RaiseIssue":
            return `${interaction.actor.name} raised an issue.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\CommentOnIssue":
            return `${interaction.actor.name} commented on an issue.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\SubmitShippingAddress":
            return `${interaction.actor.name} updated the shipping address.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\SubmitPaymentProfile":
            return `${interaction.actor.name} updated the payment profile.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\Reactivate":
            return `${interaction.actor.name} reactivated the influencer.`;
        case "Tidal\\Campaign\\Activation\\Interaction\\ClaimCouponCode":
            return `${interaction.actor.name} claimed a coupon code.`;
        default:
            return 'An event occurred.'
    }
}

const getTimestampForInteraction = interaction => {
    const ts = moment(interaction.created_at.date).parseZone(interaction.created_at.timezone);
    return ts.format('MMM Do, YYYY');
}

export default class InteractionTimelineMessages extends Component {

    static defaultProps = {
        interactions: [],
    };

    static propTypes = {
        interactions: PropTypes.array,
        activation: PropTypes.object,
        onRefresh: PropTypes.func,
    };

    handleRefresh() {
        if (this.props.onRefresh) {
            this.props.onRefresh();
        }
    }

    renderInteractions() {
        return this.props.interactions.map(interaction => this.renderInteraction(interaction));
    }

    renderInteraction(interaction) {

        const {activation} = this.props;

        switch (interaction.type) {

            case 'Tidal\\Campaign\\Activation\\Interaction\\CommentOnDraft':
            case 'Tidal\\Campaign\\Activation\\Interaction\\SubmitDraft':
            case 'Tidal\\Campaign\\Activation\\Interaction\\UpdateDraftStatus':
                return <DraftInteractionMessage
                    interaction={interaction}
                    activation={activation}
                    onRefresh={this.handleRefresh.bind(this)}
                />;
                break;

            case 'Tidal\\Campaign\\Activation\\Interaction\\SendEmail':
                return <SentEmailInteractionMessage
                    interaction={interaction}
                    activation={activation}
                />
                break;

            case 'Tidal\\Campaign\\Activation\\Interaction\\RaiseIssue':
                return <RaisedIssueInteractionMessage
                    interaction={interaction}
                    activation={activation}
                    onCommentSaved={this.handleRefresh.bind(this)}
                />;
                break;

            case 'Tidal\\Campaign\\Activation\\Interaction\\CommentOnIssue':
                return <CommentedOnIssueInteractionMessage
                    interaction={interaction}
                    activation={activation}
                    onCommentSaved={this.handleRefresh.bind(this)}
                />;
                break;

            default:
                return <EventDivider
                    message={getMessageForInteraction(interaction)}
                    timestamp={getTimestampForInteraction(interaction)}
                />


        }


    }

    render() {
        return (
            <div className="container-fluid timeline-history-messages-wrapper">
                {this.renderInteractions()}
            </div>
        );
    }
}