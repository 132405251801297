import React, {useMemo} from 'react';
import TableWrapper from "../../Table/Wrapper";
import BasicCell from "../../Table/Cell/Basic";
import {makeCellKey, makeMomentFromDate} from "../../../utilities";
import MiniProfile from "../../Common/MiniProfile";
import _find from 'lodash/find';
import {
    getCompletedInfluencerRequirementTypes,
    getVisibleInfluencerRequirementTypes
} from "../../../utilities/campaign";
import TinyProgressPie from "../../Chart/TinyProgressPie";
import {Link} from "react-router-dom";
import {filterBriefsForUser, getCampaignEndOrDueDate, filterBriefsByDate} from "../../../utilities/mac";
import moment from "moment";

function BriefsTable({campaigns, activations, user, translate}) {
    const _t = (k, v) => translate ? translate(k, v) : v;

    const filteredCampaigns = useMemo(function () {
        return filterBriefsByDate(filterBriefsForUser(campaigns, user))
            .filter(campaign => !campaign.archived);
    }, [campaigns, user]);


    const getColumns = () => ([
        {
            key: 'name',
            width: 500,
            title: _t('creator.briefs.table.name.title', 'Name'),
            default: true,
            sortable: false,
            cell: (row, column) => {
                const imageUrl = row.item.image || row.item.fallback_image_url;
                return (
                    <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={
                        <MiniProfile
                            classes={['brief-miniprofile']}
                            imageUrl={imageUrl}
                            primary={<Link to={`/contribute/do/page/dash/brief?id=${row.item.id}`} className="v3 bold" role="button">{row.item.name}</Link>}
                        />
                    } />
                );
            }
        },
        {
            key: 'type',
            width: 250,
            title: _t('creator.briefs.table.type.title', 'Type'),
            default: false,
            sortable: false,
            cell: (row, column) => {
                return (
                    <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={'Some type'} />
                );
            }
        },
        {
            key: 'status',
            width: 400,
            title: _t('creator.briefs.table.status.title', 'Status'),
            default: true,
            sortable: false,
            cell: (row, column) => {
                const activation = _find(activations, {campaign_id: row.item.id});
                let status = 'Starting Soon';
                if (activation) {
                    status = activation.status.human_status;
                }
                if (status === 'Not Invited') {
                    status = 'You\'re Invited!';
                }
                status = _t(`creator.briefs.table.status.${status}`, status);
                const totalCount = getVisibleInfluencerRequirementTypes(activation).length;
                const completeCount = getCompletedInfluencerRequirementTypes(activation).length;

                const percent = (totalCount ? (completeCount / totalCount) : 0);

                const icon = <TinyProgressPie
                    progress={percent}
                    style={{verticalAlign: 'middle', cursor: 'pointer', marginBottom: 2, marginRight: 10}}
                    size={20}
                />;
                return (
                    <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={<div>{icon} {status}</div>} />
                );
            }
        },
        {
            key: 'date',
            width: 300,
            title: _t('creator.briefs.table.date.title', 'Date'),
            default: true,
            sortable: false,
            cell: (row, column) => {
                const renderDueDate = () => {
                    const campaign = row.item;
                    const endDate = getCampaignEndOrDueDate(campaign);
                    if (!endDate) {
                        return _t('creator.words.open_ended', 'Open-Ended');
                    }
                    const dueText = _t('creator.words.due', 'Due');
                    return dueText + ': ' + endDate.format('MMM Do');
                };
                return (
                    <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={renderDueDate()} />
                );
            }
        },
    ]);
    return (
        <TableWrapper
            items={filteredCampaigns}
            columns={getColumns()}
            showHeader={false}
            showPagination={false}
            page={1}
            pages={5}
            wrapperClasses={['mac-striped-table']}
        />
    );

}

export default BriefsTable;