import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Pagination as BSPagination} from 'react-bootstrap';

export default class Pagination extends Component {
    static defaultProps = {
        maxButtons: 10,
        next: true,
        prev: true,
        boundaryLinks: true,
    };

    static propTypes = {
        page: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        maxButtons: PropTypes.number,
        next: PropTypes.bool,
        prev: PropTypes.bool,
        boundaryLinks: PropTypes.bool,
    };

    render() {
        return (
            <div className="godzilla-pagination">
                <BSPagination
                    bsClass="v3 tidal-pagination"
                    items={this.props.pages}
                    activePage={this.props.page}
                    maxButtons={this.props.maxButtons}
                    next={this.props.next}
                    prev={this.props.prev}
                    boundaryLinks={this.props.boundaryLinks}
                    onSelect={this.props.onPageChange}
                />
            </div>

        );
    }
}