import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from '../../Common/Form/Form';
import Alert from '../../Common/Alert';
import {fetchAuthenticated} from "../../../utilities";

class BulkSendEmailForm extends Component {

    state = {
        form: {subject: '', html: '', attachments: []},
        isSubmitting: false,
    };

    static propTypes = {
        activationIds: PropTypes.array.isRequired,
        onSubmit: PropTypes.func,
        isSubmitting: PropTypes.bool,
    };

    resetForm() {
        this.setState({
            form: {
                subject: '',
                html: '',
                attachments: [],
            }
        });
    }

    handleSubmit() {

        if (this.props.onSubmit) {
            this.props.onSubmit(this.state.form);
        }
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        })
    }

    handleUploadAttachment(event) {

        const slot = window.Gator.getDashboardSlot();
        const data = new FormData();
        data.append('attachment', event.target.files[0]);

        const url = `/${slot}/api/email/-1/attachment`;

        return fetchAuthenticated(url, {
            method: 'post',
            body: data,
        })
            .then(resp => resp.json())
            .then(json => [...this.state.form.attachments, json.data]);

    }

    render() {
        const {isSubmitting} = this.props;
        return <Form
            values={this.state.form}
            onFieldChange={this.handleFieldChange.bind(this)}
            fields={[
                {
                    type: 'generic',
                    content: <Alert classes={['info']} content={<span>You'll be sending this message to <strong>{this.props.activationIds.length} users</strong>.</span>}/>
                },
                {
                    type: 'text',
                    title: 'Subject',
                    placeholder: 'Subject',
                    name: 'subject'
                },
                {
                    type: 'html',
                    title: 'Message',
                    name: 'html'
                },
                {

                    type: 'file',
                    title: "Attachments",
                    name: 'attachments',
                    options: {
                        onChange: this.handleUploadAttachment.bind(this),
                        previewFormatter: (values, updater) => {
                            return values.map(value => <div style={{
                                background: '#f6f6f6',
                                padding: '10px',
                                margin: '10px 0',
                            }}>
                                <a
                                    style={{marginRight: 15}}
                                    className={'v3 icon ban'}
                                    onClick={() => {
                                        updater(this.state.form.attachments.filter(att => att.original_url !== value.original_url));
                                    }}
                                    role={'button'} />

                                <a className={'dark'}
                                   href={value.original_url} target={'_blank'}>{value.name}</a>
                            </div>)
                        }
                    }

                },
                {
                    type: 'button',
                    title: isSubmitting ? "Sending..." : `Send Message`,
                    options: {
                        hideTitle: true,
                        onClick: isSubmitting ? undefined : this.handleSubmit.bind(this),
                        classes: ['btn-primary', (isSubmitting ? 'disabled' : '')]
                    }
                },

            ]} />
    }
}

export default BulkSendEmailForm;
