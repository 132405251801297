import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Importer from "../Importer/Importer";
import {socialTypeFromNiceName} from "../../utilities/social";
import Alert from "../Common/Alert";
import TinyThermometer from "../Common/TinyThermometer";

const transformDataRow = (input) => {

    let output = {...input};

    // Force type to be user, for now
    output.type = 'user';

    if (output.type.toLowerCase() === 'user') {
        output.type = 'Tidal\\User';
    }
    if (output.type.toLowerCase() === 'blog') {
        output.type = 'Tidal\\Blog';
    }
    if (output.type.toLowerCase() === 'social') {
        output.type = 'Tidal\\Social';
    }

    const socialCols = ['instagram', 'twitter', 'pinterest', 'youtube', 'facebook-page', 'tiktok'];
    let socials = [];

    for (let socialType of socialCols) {
        if (output[socialType] && output[socialType].length > 0) {
            socials.push({
                type: socialTypeFromNiceName(socialType),
                handle: input[socialType]
            });
        }
        delete output[socialType];
    }

    if (output.type === 'Tidal\\Blog') {
        output.url = output.blog_url;
        delete output.blog_url;
    }

    output.socials = socials;

    return output;
};

class Import extends Component {

    static propTypes = {
        onImportBatch: PropTypes.func.isRequired,
        onImported: PropTypes.func,
        batchSize: PropTypes.number
    };

    static defaultProps = {
        batchSize: 10,
    };

    constructor(props) {
        super(props);
        this.state = {
            isImporting: false,
            importProgress: 0,
            importTotal: 0,
            importErrors: [],
        };
    }

    splitIntoBatches(data) {
        const batchSize = this.props.batchSize;
        let batches = [];
        for (let i = 0; i < data.length; i += batchSize) {
            batches.push(data.slice(i, i + batchSize));
        }
        return batches;
    }


    async handleImport(data) {
        const preprocessed = data.map(transformDataRow);

        /**
         * Split socials up into separate records
         */
        let processed = [];
        for (const row of preprocessed) {
            if (row.type === 'Tidal\\Social') {
                let theseSocials = row.socials;
                if (row.email && row.email.length > 0) {
                    theseSocials = theseSocials.map(s => {
                        return {...s, email: row.email};
                    });
                }
                processed = processed.concat(theseSocials);
            } else {
                processed.push(row);
            }
        }

        // split into batches
        const batches = this.splitIntoBatches(processed);

        // process each batch, updating progress in state
        this.setState({
            isImporting: true,
            importProgress: 0,
            importTotal: processed.length,
            importErrors: [],
        });

        for (let batch of batches) {
            try {
                await this.props.onImportBatch(batch);
            } catch (e) {
                this.setState(prevState => ({
                    importErrors: prevState.importErrors.concat(e),
                }));
            }

            this.setState(prevState => ({
                importProgress: prevState.importProgress + batch.length,
            }));
        }

        this.setState({
            isImporting: false,
        });

        if (this.props.onImported) {
            this.props.onImported();
        }

    }

    renderImportBanner() {
        if (!this.state.isImporting) {
            return null;
        }

        return (
            <div style={{padding: 20}}>
                <TinyThermometer
                    // pct is out of 100
                    pct={(this.state.importProgress / this.state.importTotal) * 100}
                    addlClasses={['green']}
                    label={`${this.state.importProgress} / ${this.state.importTotal} imported. Do not close this window.`}
                />
            </div>
        );
    }

    render() {
        return (
            <Importer
                banner={this.renderImportBanner()}
                onImport={this.handleImport.bind(this)}
                columns={[
                    {
                        key: 'name',
                        title: 'Name',
                        required: true,
                    },
                    {
                        key: 'email',
                        title: 'Email',
                        required: true,
                    },
                    {
                        key: 'gender',
                        title: 'Gender',
                        required: false,
                    },
                    {
                        key: "birthday",
                        title: "Birthday",
                        required: false,
                    },
                    {
                        key: "bio",
                        title: "Biography",
                        required: false,
                    },
                    {
                        key: 'blog_url',
                        title: 'Blog URL',
                        required: false,
                    },
                    {
                        key: 'instagram',
                        title: 'Instagram',
                        required: false,
                    },
                    {
                        key: 'tiktok',
                        title: 'TikTok',
                        required: false,
                    },
                    {
                        key: 'twitter',
                        title: 'Twitter',
                        required: false,
                    },
                    {
                        key: 'youtube',
                        title: 'YouTube',
                        required: false,
                    },
                    {
                        key: 'facebook-page',
                        title: 'Facebook Page',
                        required: false,
                    },
                    {
                        key: "country_name",
                        title: "Country (Name)",
                        required: false,
                    },
                    {
                        key: "country_code",
                        title: "Country (2-Letter Code)",
                        required: false,
                    },
                    {
                        key: "state_name",
                        title: "State / Region Name",
                        required: false,
                    },
                    {
                        key: "city_name",
                        title: "City",
                        required: false,
                    },

                ]}
            />

        );
    }
}

export default Import;
