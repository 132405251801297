import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from '../../Common/Toolbox';
import CreateCouponForm from './CreateCouponForm';

class CreateCouponToolbox extends Component {

    static defaultProps = {
        style: {},
    };

    static propTypes = {
        campaign: PropTypes.object,
        onClose: PropTypes.func,
        onCreated: PropTypes.func,
        style: PropTypes.object,
        createCoupon: PropTypes.func,
    };

    renderCreateForm() {

        return <CreateCouponForm
            campaign={this.props.campaign}
            onCreated={this.props.onCreated}
        />
    }

    render() {

        return <Toolbox
            style={this.props.style}
            title={'Create Coupon'}
            content={this.renderCreateForm()}
            onClose={this.props.onClose}
        />

    }
}

export default CreateCouponToolbox;