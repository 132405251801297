import React from 'react';
import PropTypes from 'prop-types';
import { formatNumberK, getIconAndTypeForPost, capitalizeFirstLetter } from '../../../utilities';
import Tooltip from '../Tooltip';
import TinyProgressPie from "../../Chart/TinyProgressPie";

const getIconForSocialHeader = (type) => {
    type = type.toLowerCase();

    switch (type) {
        case 'comments':
        case 'engagement percent':
            return 'v3 icon comments';
        case 'likes':
            return 'fa fas fa-heart';
        case 'shares':
            return 'v3 icon users';
        case 'tier':
            return 'fas fa-fire';
        case 'reach':
        case 'user reach':
            return 'v3 icon bullhorn';
        case 'blog':
            return 'v3 icon rss';
        default:
            return getIconAndTypeForPost(type);
    }
};

const SocialHeader = ({primary, secondary, showProfileCompletionPct, showSecondaryStats}) => {

    let primaryClasses = ['header-primary-stats'];
    if (showSecondaryStats === false) primaryClasses.push('align-right');

    return (
        <React.Fragment>

            <div className={primaryClasses.join(' ')}>
                {
                    primary && primary.length && primary.map(spec => {

                        if (spec === null) return;

                        let {key, title, value} = spec;

                        if (!value) return;

                        if (showProfileCompletionPct && key === 'profile_completion_pct') {
                            return (
                                <div className="header-primary-stat profile-completion-pct">
                                    <TinyProgressPie
                                        progress={value/100}
                                        size={26}
                                        donut={true}
                                    />
                                    <figcaption>{formatNumberK(value) + title}</figcaption>
                                </div>
                            )
                        }

                        if (title === 'Total Reach') {
                            return (
                                <div className="header-primary-stat total-reach">
                                    <span>{formatNumberK(value)}</span>
                                    <figcaption>{title}</figcaption>
                                </div>
                            )
                        }

                    })
                }
            </div>

            { showSecondaryStats &&
                <div className='header-secondary-stats'>
                    {
                        secondary && secondary.length && secondary.map(spec => {
                            if (spec === null) return;

                            let {title, value, icon} = spec;

                            if (!value) return;

                            if (title === 'age') {
                                return <span key={`social-header-age-${value}`}>Age: {value}</span>;
                            }

                            if (typeof(value) === 'number') {
                                value = formatNumberK(value);
                            }

                            if (!icon) {
                                icon = getIconForSocialHeader(title);
                            }

                            const content = <span key={`social-header-content-${value}`}><i className={icon}/>{value}</span>;

                            return title
                                ? <Tooltip key={title} title={capitalizeFirstLetter(title)}>{content}</Tooltip>
                                : content;
                        })
                    }
                </div>
            }

        </React.Fragment>
    )
};

SocialHeader.propTypes = {
    primary: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.number,
    })),
    secondary: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.oneOf([
            'impressions', 'comments', 'likes', 'shares', 'tier', 'reach', 'user reach', 'instagram', 'twitter', 'facebook', 'pinterest', 'youtube', 'blog', 'age', 'engagement percent'
        ]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        icon: PropTypes.node,
    })),
};

export default SocialHeader;
