import React, {Component} from 'react';
import PropTypes from 'prop-types';
import EventDivider from '../../Common/TimelineView/EventDivider';
import MessageRow from '../../Common/TimelineView/MessageRow';
import {makeMomentFromDate, limitChars} from "../../../utilities";
import CommentOnIssueForm from '../../Activation/CommentOnIssueForm';
import IFrame from '../../Common/IFrame';

export default class SentEmailInteractionMessage extends Component {

    static propTypes = {
        interaction: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
    };

    state = {
        showDetails: false
    };

    renderMessage() {
        const {interaction, activation} = this.props;
        const {showDetails} = this.state;
        const preview = limitChars(interaction.related.text, 200);

        return (
            <div className={'interaction-group'}>
                <h3 className="v3 light" style={{marginBottom: 20}}>{interaction.related.subject}</h3>

                {
                    showDetails
                        ? <IFrame
                            content={interaction.related.html}
                            style={{border: 'none', width: '100%'}}
                            stylesheets={['/manage/css/style.css']}
                        />
                        : <div>{preview}</div>
                }
            </div>
        );

    }


    toggleDetails() {
        this.setState({ showDetails: !this.state.showDetails });
    }

    render() {

        const {interaction, activation} = this.props;
        const timestamp = makeMomentFromDate(interaction.created_at).format('MMM Do, YYYY');
        const layout = activation.user_id === interaction.actor.id ? 'right' : 'left';

        return (
            <div className={'timeline-group email-sent'}>

                <EventDivider
                    message={`${interaction.actor.name} Sent an Email`}
                    timestamp={timestamp}
                />

                <MessageRow
                    message={this.renderMessage()}
                    image={interaction.actor.picture}
                    timestamp={timestamp}
                    layout={layout}
                    link={<a role="button" className="v3 light" onClick={this.toggleDetails.bind(this)}>Show More</a>}
                />
            </div>

        );

    }
}