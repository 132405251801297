/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component} from 'react';
import C3Chart from 'react-c3js';
import d3 from 'd3';
import {c3ColorPattern, formatNumber} from '../../utilities';
import PropTypes from 'prop-types';


export default class GenericDonutChart extends Component {

    renderChart() {

        const data = {
            json: [this.props.data],
            keys: {
                value: Object.keys(this.props.data)
            },
            type: 'donut',
            unload: true
        };

        const donut = {
            title: this.props.title,
            label: {
                format: this.props.labelFormatter
            }
        };

        const tooltip = {
            format: {
                value: this.props.tooltipValueFormatter
            }
        };

        return (

                    <C3Chart
                        data={data}
                        donut={donut}
                        size={this.props.size}
                        legend={this.props.legend}
                        color={c3ColorPattern}
                        tooltip={tooltip}
                        unloadBeforeLoad={true}
                    />

        );

    }

    render() {

        return this.renderChart();

    }
}

GenericDonutChart.defaultProps = {
    title: null,
    data: {},
    size: null,
    legend: {show: true},

    labelFormatter: function (value, ratio, id) {
        return formatNumber(value);
    },

    tooltipValueFormatter: function (value, ratio, id) {
        let format = d3.format(',');
        let formatPercent = d3.format('.1%');
        return format(value) + "  (" + formatPercent(ratio) + ")";
    }
};

GenericDonutChart.propTypes = {
    data: PropTypes.object.isRequired,
    title: PropTypes.string,
    size: PropTypes.object,
    legend: PropTypes.object,
    labelFormatter: PropTypes.func,
    tooltipValueFormatter: PropTypes.func
};