import React, {useState} from 'react';
import {base64_png_1pxSolidEEE, formatNumber} from "../../../utilities";

function AttachmentTile({attachment}) {
    let bgImg = 'data:image/png;base64,' + base64_png_1pxSolidEEE;
    if (attachment.image_url) {
        bgImg = attachment.image_url;
    }
    let text = null, type = null, size = null;

    const style = {
        backgroundImage: `url(${bgImg})`,
        cursor: 'pointer'
    };

    if (attachment.type === 'file') {
        text = (attachment.file || {}).name || 'untitled';
        type = (attachment.file || {}).type;
        size = formatNumber(((attachment.file || {}).size || 0) / (1024*1024)) + ' MB';
    }

    const handleDownload = () => {
        if (attachment.type === 'file') {
            const url = (attachment.file || {}).original_url || null;
            if (url) {
                window.open(url, 'Download');
            }
        }

    };

    return (
        <div className="attachment-tile" style={style} onClick={handleDownload}>
            {text && <div>
                <div><h4><i className="v3 icon download"/> {text}</h4></div>
                <div><em>{type}</em></div>
                <div><em>{size}</em></div>
            </div>}
        </div>
    );
}

function ThreadAttachments({attachments, post}) {
    const [showAttachments, setShowAttachments] = useState(false);
    const nonThumbnailAttachments = (attachments || []).filter(a => a.type !== 'thumbnail');
    const attachmentsCount = (nonThumbnailAttachments || []).length === 0 ? 'No' : nonThumbnailAttachments.length;
    const plural = attachmentsCount !== 1 ? 's' : '';

    if (nonThumbnailAttachments.length === 0) {
        return null;
    }


    return (

        <div className="thread-attachments">
            <h2
                onClick={() => setShowAttachments(!showAttachments)}
                className="red-indent"
                style={{cursor: 'pointer'}}
            >{attachmentsCount} Attachment{plural} <i className={"v3 icon " + (showAttachments ? 'angle-down' : 'angle-right')} /> </h2>

            {showAttachments && (
                <div className="attachments">
                    {nonThumbnailAttachments.map((attachment, index) => <AttachmentTile attachment={attachment} key={index} /> )}
                </div>
            )}

        </div>

    );

}

export default ThreadAttachments;