/**
 * Created by visgotti on 9/12/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {EMAIL_LABELS} from "../../utilities/email";

class MarkEmails extends Component {

    static propTypes = {
        onClickLabel: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.handleMarkSelectedClick = this.handleMarkSelectedClick.bind(this);
    }

    handleMarkSelectedClick(label) {
        if (this.props.onClickLabel) {
            this.props.onClickLabel(label);
        }
    }

    renderListItems() {

        return EMAIL_LABELS.map(label => {
            return (
                <div
                    key={`email-label-${label}`}
                    className="v3 h7 fake-li condensed"
                >
                    <a
                        role="button"
                        className="dark"
                        onClick={() => this.handleMarkSelectedClick(label)}
                    >{label}</a>
                </div>
            )
        });
    }

    renderDropdown() {
        return(
            <div className="email-mark-label-wrapper">
                <div className="list-select">
                    { this.renderListItems() }
                </div>
            </div>
        )
    }

    render() {
        return (
            this.renderDropdown()
        )
    }
}

export default MarkEmails;