import {makeMomentFromDate} from "../utilities";
import moment from 'moment';
import _mapKeys from 'lodash/mapKeys';

export const renameSeriesKeys = (series, keyMap) => {
    return series.map(item => {
        return _mapKeys(item, (value, key) => {
            if (typeof keyMap[key] !== 'undefined') {
                return keyMap[key];
            }
            return [key];
        });

    });
};

export const getLastObject = (series) => {
    return series.length === 0 ? null : series[series.length - 1];
};

export const getLatestStatValue = (series, key) => {
    const o = getLastObject(series);
    if (!o) {
        return null;
    }
    return o[key] || null;
};

export const interpolateSeries = (series, duration) => {

    if (series.length < 1) {
        return series;
    }

    let normalized = normalizeTimestamps(series)
        .sort((a, b) => a.date.localeCompare(b.date));

    let date = makeMomentFromDate(normalized[0].date);
    const now = moment();

    let output = [];
    let candidate = normalized.shift();
    let candidateDate = makeMomentFromDate(candidate.date);

    while (date.isSameOrBefore(now)) {

        let nextCandidate = (normalized.length > 0) ? normalized[0] : null;
        let nextCandidateDate = nextCandidate ? moment(nextCandidate.date) : null;

        if (nextCandidateDate && nextCandidateDate.isSameOrBefore(date)) {
            // New candidate
            candidate = normalized.shift();
            candidateDate = makeMomentFromDate(candidate.date);
        }

        output.push({...candidate, date: date.format('YYYY-MM-DD')});

        date.add(duration);
    }

    return output;

};

/**
 * Finds fields named 'ts' or 'created_at' in a timeseries and replaces with a 'date' timestamp in YYYY-MM-DD format
 * @param series
 */
export const normalizeTimestamps = (series) => {

    return series.map(item => {
        let date;
        let original;

        if (item.ts) {
            original = item.ts;
        } else if (item.created_at) {
            original = item.created_at;
        } else if (item.date) {
            original = item.date;
        }

        if (original) {
            date = makeMomentFromDate(original).format('YYYY-MM-DD');
        } else {
            date = moment().format('YYYY-MM-DD');
        }

        let newItem = {...item};
        delete(newItem.ts);
        delete(newItem.created_at);
        newItem.date = date;
        return newItem;

    });

};

export const getTimestampMoment = (item) => {
    if (item.ts) {
        return makeMomentFromDate(item.ts);
    } else if (item.created_at) {
        return makeMomentFromDate(item.created_at);
    } else if (item.date) {
        return makeMomentFromDate(item.date);
    }
    return null;
}

export const simpleLinearInterpolation = (x, x0, x1, y0, y1) => {
    return parseFloat(y0) + (parseFloat(x) - parseFloat(x0)) * (parseFloat(y1) - parseFloat(y0)) / (parseFloat(x1) - parseFloat(x0));
}

export const IGNORE_INTERPOLATE_FIELDS = ['created_at', 'id', 'updated_at', 'campaign_id', 'activation_id', 'owner_id', 'owner_type', '_ts', 'user_id', 'ts', 'date'];
/**
 * Interpolate a timeseries, specifying the duration between each point
 * @param series
 * @param duration
 * @param ignoreKeys
 * @param timestampField
 * @returns {*[]}
 */
export const linearInterpolateSeries = (series, duration = {days: 1}, ignoreKeys = [], timestampField = 'created_at') => {
    if (series.length < 1) {
        return [];
    }

    // set up some default options
    const allIgnoreKeys = [...IGNORE_INTERPOLATE_FIELDS].concat(ignoreKeys);

    // First, normalize the timestamps
    const normalized = series.map(item => ({...item, _ts: getTimestampMoment(item)}));

    // Then, sort by date
    const sorted = normalized.sort((a, b) => a._ts.isBefore(b._ts) ? -1 : 1);

    // use the first and last timestamp of the series
    const first = sorted[0];
    const last = sorted[sorted.length - 1];

    // Then, iterate through the series, interpolating values for each date
    let date = first._ts;
    const until = last._ts;
    const output = [];

    let numIterations = 0;
    const maxIterations = 10000;
    while (date.isSameOrBefore(until) && numIterations < maxIterations) {

        // Find the two values that are closest to the current date
        const seriesBefore = sorted.filter(item => item._ts.isSameOrBefore(date));
        const seriesAfter = sorted.filter(item => item._ts.isAfter(date));
        const firstItem = seriesBefore.length > 0 ? seriesBefore[seriesBefore.length - 1] : null;
        const lastItem = seriesAfter.length > 0 ? seriesAfter[0] : null;

        // If we have both values, interpolate
        if (firstItem && lastItem) {
            const firstItemDate = firstItem._ts;
            const lastItemDate = lastItem._ts;
            // loop through item keys, interpolating each
            const newItem = {};
            Object.keys(firstItem).forEach(key => {
                if (allIgnoreKeys.indexOf(key) === -1) {
                    newItem[key] = simpleLinearInterpolation(date.unix(), firstItemDate.unix(), lastItemDate.unix(), firstItem[key], lastItem[key]);
                }
            });
            newItem[timestampField] = date.toISOString();
            output.push(newItem);
        }

        date = date.add(duration);
        numIterations++;
    }

    // Then, return the new series
    return output;
}

/**
 * Interpolate a timeseries to a target number of points
 * @param series
 * @param targetCount
 * @param ignoreKeys
 * @param timestampField
 * @returns {*|*[]}
 */
export const linearInterpolateSeriesToTargetCount = (series, targetCount, ignoreKeys = [], timestampField = 'created_at') => {
    if (series.length < 2) {
        return series;
    }
    // get the difference in days between the first and last dates
    const first = series[0];
    const last = series[series.length - 1];
    const firstDate = getTimestampMoment(first);
    const lastDate = getTimestampMoment(last);
    const days = lastDate.diff(firstDate, 'days');
    // calculate the duration between each point
    const targetTimeGap = Math.max(1, Math.round(days / targetCount));
    const duration = {days: targetTimeGap};
    // interpolate the series
    return linearInterpolateSeries(series, duration, ignoreKeys, timestampField);
}

