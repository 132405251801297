import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BasicCell from './Basic';

class NumberCell extends BasicCell {

    getValue() {
        const {value, row, formatter} = this.props;
        let number;

        if (typeof value === 'function') {
            number = value(row);
        } else {
            number = value;
        }

        if (typeof number === 'undefined' || number === null) {
            return '-';
        }

        if (typeof formatter === 'function') {
            number = formatter(number);
        }

        return number;

    }

}

NumberCell.propTypes = {
    value: PropTypes.any,
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    formatter: PropTypes.func,
};

export default NumberCell;