import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';

import {frecencyTooltip, frecencyLabel, responsivenessLabel} from "../../../utilities/user";
import {formatNumber, limitChars} from "../../../utilities";

export default class UserAttributesTable extends Component {

    renderItem(name, value, tooltip = null) {

        return (
            <div className="fake-li bottom-border">
                <span>{name}:</span>
                <span className="pull-right" data-tooltip={tooltip}>{value}</span>
            </div>
        );
    }

    renderFacet(name) {
        const {user} = this.props;
        const {profile} = user;

        if (!profile || !profile.facets) {
            return null;
        }

        const facetValue = profile.facets[name];
        if (!facetValue) {
            return null;
        }

        return this.renderItem(name, facetValue);

    }

    renderContentRating() {
        const {user} = this.props;
        const profile = user.profile;

        if (user.isFetchingProfile || user.lastUpdatedProfile === null) {
            return null;
        }

        const val = profile.avg_post_rating || null;
        if (val === null) return null;

        const label = profile.avg_post_rating + ' / ' + 5;

        return this.renderItem('Content Rating', label);

    }

    renderFrecency() {
        const {user} = this.props;
        const profile = user.profile;

        if (user.isFetchingProfile || user.lastUpdatedProfile === null) {
            return null;
        }

        const val = profile.frecency || 0;
        const label = frecencyLabel(val);
        const tooltip = frecencyTooltip(val);

        return this.renderItem('Activity', label, tooltip);

    }

    renderResponsiveness() {
        const {user} = this.props;
        const profile = user.profile;

        let val = null;

        if (user.isFetchingProfile || user.lastUpdatedProfile === null) {
            return null;
        }

        val = profile.response_probability || 0.1;
        const output = responsivenessLabel(val);

        const tooltip = 'We estimate a ' + formatNumber(val*100) + '% chance this creator will accept an invitation.';
        return this.renderItem("Responsiveness", output, tooltip);

    }

    renderTags() {
        const user = this.props.user;
        const {profile} = user;

        const tags = profile.tags || [];
        const tagsSummary = limitChars(tags.join(', '), 24);
        const tagsPopup = tags.length > 0 ? tags.join(', ') : null;

        const sponsoredTags = profile.sponsored_tags || {mentions: {}, tags: {}};
        const sponsoredTagsHandles = Object.keys(sponsoredTags.mentions || {}).map(t => '@' + t);
        const sponsoredTagsSummary = limitChars(sponsoredTagsHandles.join(', '), 24);
        const sponsoredTagsPopup = sponsoredTagsHandles.length > 0 ? sponsoredTagsHandles.join(', ') : null;

        return (
            <>

                {
                    (tags.length > 0) ?
                        <div className="fake-li bottom-border" data-tooltip={tagsPopup}>
                            <span>Tags:</span>
                            <span className="pull-right">{tagsSummary}</span>
                        </div>
                        : null
                }

                {
                    (sponsoredTagsHandles.length > 0) ?
                        <div className="fake-li bottom-border" data-tooltip={sponsoredTagsPopup}>
                            <span>Sponsors:</span>
                            <span className="pull-right">{sponsoredTagsSummary}</span>
                        </div>
                        : null
                }

            </>
        )
    }

    renderCustomFields() {
        const {user, channel} = this.props;
        const rawCustomFieldsSpec = channel.custom_profile_fields || null;
        const userCustomProfileFields = (user.profile || {}).custom_fields || [];
        let customFieldSpec;
        if (!rawCustomFieldsSpec) {
            return null;
        }
        if (!userCustomProfileFields || userCustomProfileFields.length === 0) {
            return null;
        }
        try {
            customFieldSpec = JSON.parse(rawCustomFieldsSpec);
        } catch (err) {
            return null;
        }

        if (!customFieldSpec) {
            return null;
        }

        // Catch legacy custom field specs
        if (typeof customFieldSpec['meta'] !== 'undefined') {
            return null;
        }

        let output = [];

        for (const customFieldSpecItem of customFieldSpec) {
            const fixedKey = `custom.${customFieldSpecItem.name}`;
            const profileField = _find(userCustomProfileFields, {channel_id: channel.mongo_id, key: fixedKey});
            if (profileField) {

                let readableValue = profileField.value;
                if (customFieldSpecItem.type === 'select') {
                    const selectOption = _find(customFieldSpecItem.choices, {value: profileField.value});
                    if (selectOption) {
                        readableValue = selectOption.text;
                    }
                }
                output.push(this.renderItem(customFieldSpecItem.title, readableValue));
            }
        }

        return output;
    }

    render() {
        const {user, showFrecency, showResponsiveness, onClickEditFacets} = this.props;
        const {profile, basics} = user;
        const {facets} = profile;

        return (

            <div className="user-profile-table">

                { this.renderCustomFields() }
                { this.renderFacet('Category') }
                { this.renderFacet('Language') }
                { this.renderFacet('Parenthood') }
                { this.renderFacet('Race') }
                { this.renderFacet('Relationship') }
                { this.renderTags() }

                { this.renderContentRating() }
                { showFrecency &&
                    this.renderFrecency()
                }
                { showResponsiveness &&
                    this.renderResponsiveness()
                }

                { onClickEditFacets &&
                    <a onClick={onClickEditFacets} role="button" className="edit-profile-link facets">Edit</a>
                }

            </div>

        );
    }
}

UserAttributesTable.defaultProps = {
    showFrecency: true,
    showResponsiveness: true
};

UserAttributesTable.propTypes = {
    user: PropTypes.object,
    showFrecency: PropTypes.bool,
    showResponsiveness: PropTypes.bool,
    onClickEditFacets: PropTypes.func,
    channel: PropTypes.object
};