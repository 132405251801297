import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Text extends Component {

    static defaultProps = {
        classes: [],
        style: {},
        readOnly: false

    };

    static propTypes = {
        classes: PropTypes.array,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        style: PropTypes.object,
        readOnly: PropTypes.bool
    };

    render() {
        let classes = ['form-control', ...this.props.classes];

        return (
            <input
                type="text"
                className={classes.join(' ')}
                placeholder={this.props.placeholder}
                name={this.props.name}
                value={this.props.value || ''}
                onChange={this.props.onChange}
                style={this.props.style}
                readOnly={this.props.readOnly}
            />
        );
    }
}