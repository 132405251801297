/**
 * Created by bkroger on 6/21/18.
 */

import React, {Component} from 'react';
import FormSelect from '../../Common/Form/Select';


export default class TimelineHeader extends Component {

    render() {

        const options = [
            {text:'Your Life Styled', value:'campaign1'},
            {text:'What Moves Us 2', value:'campaign2'},
            {text:'Come Together', value:'campaign3'},
            {text:'Spring is Here', value:'campaign4'},
            {text:'Summer Styles 2018', value:'campaign5'}
        ];

        return (
            <div className="timeline-history-header">
                <FormSelect
                    addlClasses="v3 medium"
                    options={options}
                    defaultValue={'- Select Campaign -'}
                />
                <div className="header-badge">
                    <span className="v3 badge-number small">+3</span>
                </div>
            </div>
        )

    }

}