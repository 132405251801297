import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbox from '../Common/Toolbox';
import CampaignSettingsForm from '../Campaign/Setup/SettingsForm';
import Alert from '../Common/Alert';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions";
import {isMobile} from "../../utilities";
import {getCurrentChannel} from "../../selectors/presence";
import * as URI from "urijs";

class ActivationSettingsToolbox extends Component {

    static defaultProps = {
        style: {},
        title: 'Override Activation Settings',
    };

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        activation: PropTypes.object.isRequired,
        channel: PropTypes.object,
        style: PropTypes.object,
        onClose: PropTypes.func,
        createInteraction: PropTypes.func,
    };

    state = {
        isMobile: isMobile(),
    };

    handleSave(settings) {
        return this.props.createInteraction(this.props.activation.id, 'UpdateSettings', settings)
            .then(() => this.props.fetchActivation(this.props.activation.id));
    }

    renderForm() {
        const {channel} = this.props;
        // Use current URL query string to see if we should use custom mac thing
        const urlParams = URI(window.location.href).search(true);
        const isMacChannel = channel && channel.slug === 'mac';
        const customSettingsForm = urlParams.settingsForm || null;

        if (isMacChannel || customSettingsForm === 'mac') {
            return (
                <div style={{padding: 20}}><p><em>There are no settings to customize here.</em></p></div>
            );
        }

        return (
            <CampaignSettingsForm
                settings={(this.props.activation || {}).settings || {}}
                campaign={this.props.campaign}
                onSave={this.handleSave.bind(this)}
                activationFieldsOnly={true}
                isMobile={this.state.isMobile}
            />
        );
    }

    renderContent() {
        return (
            <div>
                <Alert
                    classes={['info']}
                    content={`You are updating ${this.props.activation.user.name}'s individual settings.`}
                />
                {this.renderForm()}
            </div>
        );
    }

    render() {

        return (
            <Toolbox
                supportMobile={true}
                onClose={this.props.onClose}
                style={this.props.style}
                title={this.props.title}
                content={this.renderContent()}
            />
        );



    }

}

const mapStateToProps = (state, props) => {
    return {
        channel: getCurrentChannel(state, props)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };
};

const ConnectedActivationSettingsToolbox = connect(mapStateToProps, mapDispatchToProps)(ActivationSettingsToolbox);
ConnectedActivationSettingsToolbox.propTypes = {
    activation: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    style: PropTypes.object,
    onClose: PropTypes.func,
    title: PropTypes.string,
};
export default ConnectedActivationSettingsToolbox;