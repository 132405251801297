import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import TableWrapper from '../../../Table/Wrapper';
import BasicCell from '../../../Table/Cell/Basic';
import {limitChars, makeCellKey, preventDefaultIfPossible, ucwords} from "../../../../utilities";
import Button from "../../../Common/Form/Button";
import * as Query from '../../../../actions/queries';
import * as ActionTypes from '../../../../actions/types';
import * as QuerySelectors from '../../../../selectors/queries';
import * as CampaignSelectors from '../../../../selectors/campaigns';
import {DEFAULT_EMAILS_QUERY} from "../../../../reducers/emails";
import {getAvailableMetadata} from "../../../../selectors/queries";
import Checkbox from "../../../Common/Form/Checkbox";
import DateCell from "../../../Table/Cell/Date";
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _pull from 'lodash/pull';
import EmailRead from '../../../../containers/Email/Read';
import Toolbox from "../../../Common/Toolbox";
import EmailCompose from '../../../Email/Compose';
import MarkEmails from "../../../Email/MarkEmails";
import {createEmailInteraction, fetchEmail} from "../../../../actions/emailById";
import EmailGodzillaFilters from "../../../Email/GodzillaFilters";
import MiniProfile from "../../../Common/MiniProfile";
import {openUserLightbox} from "../../../../actions/lightbox";
import {createListInteraction, emailToggleCompose} from "../../../../actions";
import GenericQuickFiltersButton from "../../../Common/GenericQuickFiltersButton";
import GenericActionsButton from "../../../Common/GenericActionsButton";
import {toggleRowSelectedWithMultiSelect} from "../../../../utilities/table";
import SendMessageForm from "../../../Influencer/Dashboard/Partials/SendMessageForm";
import {getTeams} from "../../../../selectors/teams";
import _get from "lodash/get";
import AddEntriesToList from "../../../List/AddEntriesToList";
import {getLists} from "../../../../selectors/lists";

class Emails extends Component {

    static propTypes = {
        teams: PropTypes.array,
        emails: PropTypes.array,
        query: PropTypes.object,
        campaigns: PropTypes.array,
        onRefresh: PropTypes.func,
        onSortChange: PropTypes.func,
        onFilterChange: PropTypes.func,
        onPageChange: PropTypes.func,
        createInteraction: PropTypes.func,
        createListInteraction: PropTypes.func,
        openUserLightbox: PropTypes.func,
        title: PropTypes.string,
        box: PropTypes.oneOf(['inbox', 'outbox', 'all']),
        showCompose: PropTypes.bool,
        composeCampaignId: PropTypes.any,
        toggleCompose: PropTypes.func,
        fetchEmail: PropTypes.func,
        cols: PropTypes.array,
        showSwitches: PropTypes.bool,
        showContributeDashButtons: PropTypes.bool,
        showIcon: PropTypes.bool,
        showTitle: PropTypes.bool,
        showSort: PropTypes.bool,
        showTeamTabs: PropTypes.bool,
        wrapperClasses: PropTypes.array,
        useNewReadView: PropTypes.bool,
    };

    static defaultProps = {
        title: "Emails",
        box: 'all',
        showCompose: false,
        showSwitches: true,
        showContributeDashButtons: false,
        showIcon: true,
        showTitle: true,
        showSort: true,
        showTeamTabs: true,
        useNewReadView: true,
        wrapperClasses: [],
        cols: [
            'select',
            'from',
            'to',
            'subject',
            'label',
            'campaign',
            'created_at',
            'teams',
            'was_opened',
            'was_clicked',
            'was_bounced',
        ],
    };

    constructor(props) {
        super(props);
        this.state = {
            initialFilters: {...props.query.filters},
            selectedIds: {},
            lastSelectedIndex: null,
            selectAll: false,
            email: null,
            // showCompose: false,
            showMarkEmails: false,
            showFilters: false,
            markedEmailIds: [],
            isRunningBulkAction: false,
            showSendMessagePopup: false,
            showAddToListPopup: false,
        };

        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.toggleRowSelected = this.toggleRowSelected.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
        this.handleLaunchEmail = this.handleLaunchEmail.bind(this);
    }

    setTitle() {
        if (this.props.box === 'inbox') {
            document.title = 'Email Inbox | ContentMetric Dashboard';
        } else if (this.props.box === 'outbox') {
            document.title = 'Email Outbox | ContentMetric Dashboard';
        } else if (this.props.showContributeDashButtons) {
            document.title = 'Email | Tidal Labs CreatorExchange';
        } else {
            document.title = 'Email | ContentMetric Dashboard';
        }
    }

    componentDidMount() {
        this.handleRefresh();
        this.setTitle();
    }

    componentDidUpdate(prevProps) {
        this.detectUpdateRequiringRefresh(prevProps);
        this.setTitle();
    }

    detectUpdateRequiringRefresh(prevProps) {
        if (this.props.query.isDirty && !prevProps.query.isDirty && !this.props.query.isFetching) {
            this.handleRefresh();
        }
    }

    handleLaunchEmail(email, event) {
        preventDefaultIfPossible(event);
        this.setState({email});
        return false;
    }

    toggleSelectAll() {
        const selectAll = !this.state.selectAll;
        let selectedIds = {};

        if (selectAll) {
            this.props.emails.forEach(a => selectedIds[a.id] = true);
        }

        return this.setState({selectAll, selectedIds, lastSelectedIndex: null});
    }

    getSelectedIds() {
        return Object.keys(this.state.selectedIds)
            .filter(id => !!this.state.selectedIds[id]);
    }

    getSelectedUserEntries() {
        // for each selected id, look up the email, then collect the sender and recipient user ids
        // deduplicating
        return Object.keys(
            this.getSelectedIds().reduce((acc, id) => {
                let idMap = {};
                const email = _find(this.props.emails, {id: parseInt(id, 10)});
                if (!email) {
                    console.log("Couldn't find email by id");
                    return acc;
                }
                (email.from || []).forEach(a => idMap[a.user_id] = true);
                (email.to || []).forEach(a => idMap[a.user_id] = true);
                return {...acc, ...idMap};
            }, {})
        )
            .map(id => parseInt(id, 10))
            .filter(id => !!id)
            .map(userId => ({type: 'Tidal\\User', id: userId}))
            ;
    }

    isRowSelected(row) {
        return !!this.state.selectedIds[row.item.id];
    }

    toggleRowSelected(row, event = null) {
        const newState = toggleRowSelectedWithMultiSelect(this.state, this.props.emails, row, event);
        this.setState(newState);
    }

    handleRefresh() {
        return this.props.onRefresh(this.props.query);
    }

    handleSortChange(sort) {
        this.handlePageChange(1);
        return this.props.onSortChange(sort, this.props.query);
    }

    handlePageChange(page) {
        this.setState({lastSelectedIndex: null});
        return this.props.onPageChange(page, this.props.query);
    }

    handleFilterChange(name, value) {
        let cleanValue = value;
        if (cleanValue === '') cleanValue = null;

        const filters = {...this.props.query.filters, [name]: cleanValue};
        return this.props.onFilterChange(filters, this.props.query);
    }

    handleLimitChange(limit) {
        return this.props.onFilterChange(this.props.query.filters, {...this.props.query, limit});
    }

    resetFilters(additional = {}, title = null) {
        const filters = {...this.state.initialFilters, ...additional};
        this.setState({title});
        return this.props.onFilterChange(filters, this.props.query);
    }

    getFilterValue(name) {
        return (this.props.query.filters || {})[name];
    }

    getBoolFilterText(name, ifTrue, ifFalse, ifUnset = null) {
        const value = this.getFilterValue(name);
        if (value === true) {
            return ifTrue;
        }
        if (value === false) {
            return ifFalse;
        }
        return ifUnset;
    }

    getCurrentPageMeta() {
        const {query} = this.props;
        return getAvailableMetadata(query);
    }

    getTotalHits() {
        const meta = this.getCurrentPageMeta();
        if (!meta || !meta.hits) return 0;
        return parseInt(meta.hits, 10);
    }

    renderToFromLink(recps) {

        const first = recps[0] || {name: "Unknown", email: "Unknown"};
        const name = first.name || first.email;
        const email = first.email;
        const userId = first.user_id;

        let text = email;
        if (name && userId) {
            text = name;
        } else if (name && !userId) {
            text = `${name} (${email})`;
        }

        const props = {
            role: 'button',
            className: 'dark',
        };

        if (userId) {
            props.onClick = (e) => {
                preventDefaultIfPossible(e);
                this.props.openUserLightbox(userId);
            };
            props.href = `/manage/do/page/users/detail?id=${userId}`;
        }

        return (
            <a {...props}>{text}</a>
        );


    }
    renderSubjectCell(row, column) {
        const email = row.item;
        const profilePic = email.recipient_profile_pic || email.sender_profile_pic;
        const subject = limitChars(email.subject || '[Missing Subject]');
        const isUnread = email.label === 'unread' || email.label === 'needs attention' || email.is_read === false;
        const subjectStyle = {};
        let secondaryClass = 'bold';
        if (!isUnread) {
            subjectStyle.fontWeight = 300;
            secondaryClass = '';
        }
        const emailLink = `/manage/do/page/email/${this.props.useNewReadView ? 'read' : 'view'}?id=${email.id}`;
        const spinner = email.isFetching ? <i className="v3 icon spinner" style={{marginRight: 8}} /> : null;
        let subjectLink = <a
            className="dark lightbox-launcher"
            role="button"
            style={subjectStyle}
            href={emailLink}
        >{spinner}{subject}</a>;

        if (this.props.useNewReadView) {
            subjectLink = <a
                className="dark"
                role="button"
                style={subjectStyle}
                href={emailLink}
                onClick={this.handleLaunchEmail.bind(this, email)}
            >{spinner}{subject}</a>;

        }

        let secondary;
        const label = <span>{ucwords(email.label)}</span>;
        const fromLink = this.renderToFromLink(email.from || []);
        const toLink = this.renderToFromLink(email.to || []);

        if (this.props.box === 'inbox') {
            secondary = <span className={secondaryClass}>From {fromLink}</span>
        } else if (this.props.box === 'outbox') {
            secondary = <span className={secondaryClass}>To {toLink}</span>
        } else {
            secondary = <span className={secondaryClass}>From {fromLink}, to {toLink}</span>
        }

        const miniprofile = <MiniProfile
            primary={subjectLink}
            imageUrl={profilePic}
            secondary={secondary}
            switches={this.getSwitchesForEmail(email)}
        />;


        return (
            <BasicCell
                row={row} column={column} key={makeCellKey(row, column)}
                value={miniprofile}
            />
        );
    }

    getSwitchesForEmail(email) {

        const {is_read, is_archived} = email;
        const doesNeedAttention = email.label === 'needs attention';
        const iconStyle = {fontSize: '12px'};
        const onClickRead = () => this.props.createInteraction(email.id, 'MarkEmailRead', {read: !is_read})
            .then(() => this.props.fetchEmail(email.id));
        const onClickArchive = () => this.props.createInteraction(email.id, 'ArchiveEmail', {archive: !is_archived})
            .then(() => this.props.fetchEmail(email.id));
        const readIcon = is_read ? <i className="fas fa-envelope-open-text" style={iconStyle} /> : <i className="fas fa-envelope-open" style={iconStyle} />;
        const archiveIcon = is_archived ? <i className="fas fa-inbox-out" style={iconStyle} /> : <i className="fas fa-inbox-in" style={iconStyle} />;
        const readTooltip = is_read ? "Mark as Unread" : "Mark as Read";
        const archiveTooltip = is_archived ? "Unarchive Email" : "Archive Email";
        const switchStyle = {display: 'inline-block', float: 'none', opacity: 0.5};
        const currentLabel = ucwords(email.label);
        const needsAttentionIcon = doesNeedAttention ? <i className="v3 icon error" style={iconStyle} /> :<i className="v3 icon exclamation-triangle" style={iconStyle} />;

        return [
            {
                key: `email-switch-needsatten-${email.id}`,
                icon: needsAttentionIcon,
                tooltip: doesNeedAttention ? "This email needs attention. Click to un-mark." : "Flag this email as needing attention.",
                isActive: doesNeedAttention,
                style: switchStyle,
                onClick: () => {
                    if (doesNeedAttention) {
                        return this.props.createInteraction(email.id, 'MarkEmail', {label: email.is_read ? 'read' : 'unread'})
                            .then(() => this.props.fetchEmail(email.id));
                    } else {
                        return this.props.createInteraction(email.id, 'MarkEmail', {label: 'needs attention'})
                            .then(() => this.props.fetchEmail(email.id));

                    }
                }

            },
            {
                key: `email-switch-label-${email.id}`,
                icon: <i className="fas fa-tag" style={{fontSize: 11}} />,
                tooltip: `Current label: ${currentLabel}. Click to change.`,
                style: switchStyle,
                onClick: () => {
                    this.setState({
                        selectedIds: {[email.id]: true},
                        showMarkEmails: true,
                    });
                }

            },
            {
                key: `email-switch-read-${email.id}`,
                icon: readIcon,
                onClick: onClickRead,
                tooltip: readTooltip,
                style: switchStyle,
            },
            {
                key: `email-switch-archive-${email.id}`,
                icon: archiveIcon,
                onClick: onClickArchive,
                tooltip: archiveTooltip,
                style: switchStyle,
            },

        ];
    }

    renderToFromCell(row, column, data, profilePic = null) {

        const link = this.renderToFromLink(data);
        return (
            <BasicCell
                row={row}
                column={column}
                key={makeCellKey(row, column)}
                value={link}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
            />
        );

    }

    getColumns() {
        return [
            {
                key: 'select',
                title: <Checkbox
                    checked={this.state.selectAll}
                    label={null}
                    onClick={this.toggleSelectAll}
                    wrapperStyle={{marginTop: -8, marginBottom: -8}}
                />,
                default: true,
                sortable: false,
                width: 50,
                cell: (row, column) => {
                    const value = (<span className="godzilla-table-cell-checkbox-menu-wrapper">
                                      <Checkbox
                                          checked={row.isSelected}
                                          label={null}
                                          onClick={(e) => this.toggleRowSelected(row, e)}
                                      />
                                  </span>);
                    return (
                        <BasicCell row={row} column={column} key={makeCellKey(row, column)}
                                   value={value}
                        />
                    )
                }
            },
            {
                key: 'subject',
                title: 'Subject',
                default: true,
                sortable: false,
                width: 600,
                cell: (row, column) => this.renderSubjectCell(row, column),
            },
            {
                key: 'created_at',
                title: 'Date',
                default: true,
                sortable: true,
                sortDir: 'desc',
                width: 160,
                cell: (row, column) => {
                    return (
                        <DateCell row={row} column={column} key={makeCellKey(row, column)}
                                  value={row.item.created_at}
                        />
                    )
                }
            },
            {
                key: 'label',
                title: 'Label',
                default: true,
                sortable: false,
                width: 200,
                cell: (row, column) => {
                    const label = ucwords(row.item.label || '');
                    return (
                        <BasicCell row={row} column={column} key={makeCellKey(row, column)}
                                   value={label}
                        />
                    )
                }
            },
            {
                key: 'campaign',
                title: 'Campaign',
                default: true,
                sortable: false,
                width: 300,
                cell: (row, column) => {
                    let value = '';
                    if (row.item.campaign_id) {
                        const campaign = _find(this.props.campaigns, {id: row.item.campaign_id});

                        if (campaign) {
                            value = campaign.name;
                        }
                    }

                    return (
                        <BasicCell row={row} column={column} key={makeCellKey(row, column)}
                                   value={value}
                        />
                    )
                }
            },
            (this.props.teams && this.props.teams.length > 1) ? {
                key: 'teams',
                title: 'Teams',
                default: true,
                sortable: false,
                width: 200,
                cell: (row, column) => {
                    const content = _get(row, 'item.teams', [])
                        .map(team => <div key={team.id}>{team.name}</div>)
                    return (
                        <BasicCell row={row} column={column} key={makeCellKey(row, column)}
                                   value={content}
                        />
                    )
                }
            } : null,

            {
                key: 'was_opened',
                title: 'Opened',
                default: true,
                sortable: false,
                width: 120,
                cell: (row, column) => this.renderBooleanCell(row.item.was_opened, row, column),
            },
            {
                key: 'was_clicked',
                title: 'Clicked',
                default: true,
                sortable: false,
                width: 120,
                cell: (row, column) => this.renderBooleanCell(row.item.was_clicked, row, column),
            },
            {
                key: 'was_bounced',
                title: 'Bounced',
                default: true,
                sortable: false,
                width: 120,
                cell: (row, column) => this.renderBooleanCell(row.item.was_bounced, row, column),
            },
            {
                key: 'from',
                title: 'From',
                default: true,
                sortable: false,
                width: 220,
                cell: (row, column) => this.renderToFromCell(row, column, row.item.from, row.item.sender_profile_pic),
            },
            {
                key: 'to',
                title: 'To',
                default: true,
                sortable: false,
                width: 220,
                cell: (row, column) => this.renderToFromCell(row, column, row.item.to, row.item.recipient_profile_pic),
            },
        ].filter(c => !!c);
    }

    renderBooleanCell(val, row, column) {
        let text = (val ? 'Yes': 'No');
        return <BasicCell
            row={row}
            column={column}
            key={makeCellKey(row, column)}
            value={text}
        />;
    }

    renderSendMessagePopup() {
        const userId = this.props.query.filters['recipients.user_id'];
        return <Toolbox
            key={'contribute-dash-send-message-toolbox'}
            supportMobile={true}
            onClose={() => this.setState({showSendMessagePopup: false})}
            addlClasses={'contribute-dash-send-message-toolbox'}
            content={
                <SendMessageForm
                    onClose={() => this.setState({showSendMessagePopup: false})}
                    userId={userId}
                />
            }
            style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                position: 'fixed',
            }}
        />
    }

    renderAddToListPopup() {

        const selectedUserEntries = this.getSelectedUserEntries();
        return (
            <Toolbox
                key={'add-to-list-popup'}
                title={"Add Users to List"}
                onClose={() => this.setState({showAddToListPopup: false})}
                addlClasses={'toolbox-sm toolbox-fixed'}
                content={
                    <AddEntriesToList
                        isSubmitting={this.state.isRunningBulkAction}
                        onClickSubmit={(listId) => {
                            console.log('selectedUserEntries', selectedUserEntries);
                            this.setState({isRunningBulkAction: true});
                            this.props.createListInteraction(listId, 'AddToList', {objects: selectedUserEntries})
                                .then(() => {
                                    this.setState({isRunningBulkAction: false, showAddToListPopup: false});
                                });

                        }}
                    />
                }
            />
        )

    }
    getButtons() {
        if (this.props.showContributeDashButtons) {
            return [
                <Button
                    classes={['v3', 'btn-primary']}
                    content={"Send Message"}
                    onClick={() => this.setState({showSendMessagePopup: !this.state.showSendMessagePopup})}
                />
            ]
        }
        const hasComposeToUser = !!this.props.onClickComposeToUser;
        return [

            hasComposeToUser ? <Button
                classes={['btn-primary']}
                content={"Compose"}
                key={'composetouserbutton'}
                style={{marginRight: 10}}
                onClick={this.props.onClickComposeToUser}
            /> : null,

            <GenericQuickFiltersButton
                classes={['btn-secondary']}
                key={'emailsquickfiltersbutton'}
                apiUrl={'/manage/api/email/_/v3'}
                content={'Quick Filters'}
                filters={this.props.query.filters}
                checks={[
                    {
                        name: "Unread & Needs Attention",
                        filters: {
                            important: true,
                        }
                    },
                    {
                        name: "Show Spam",
                        filters: {
                            label: "spam,possible spam",
                            hide_spam: false,
                        }
                    },
                    {
                        name: "Include Archived",
                        filters: {
                            hide_archived: false,
                        }
                    },
                    {
                        name: "Reset Filters",
                        skipCheck: true,
                        filters: {}
                    }
                ]}
                onClickFilter={({filters}) => this.resetFilters(filters)}
                wrapperStyle={{marginRight: 10}}
            />,

            <GenericActionsButton
                content={<span>{this.state.isRunningBulkAction ? <i className="v3 icon spinner" style={{marginRight: 4}}/> : ''}Actions</span>}
                disabled={this.getSelectedIds().length === 0}
                classes={['v3', (hasComposeToUser ? 'btn-secondary' : 'btn-primary')]}
                actions={[
                    {
                        name: 'MarkEmails',
                        title: "Label Selected Messages",
                        onClick: () => this.setState({showMarkEmails: true}),
                    },
                    {
                        name: 'MarkEmailsRead',
                        title: "Mark as Read",
                        onClick: () => this.handleReadEmails(true),
                    },
                    {
                        name: 'MarkEmailsUnread',
                        title: "Mark Unread",
                        onClick: () => this.handleReadEmails(false),
                    },
                    {
                        name: 'ArchiveEmails',
                        title: "Archive Emails",
                        onClick: () => this.handleArchiveEmails(true),
                    },
                    {
                        name: 'UnarchiveEmails',
                        title: "Unarchive Emails",
                        onClick: () => this.handleArchiveEmails(false),
                    },
                    {
                        name: 'MarkEmailsSpam',
                        title: "Mark as Spam",
                        onClick: () => this.handleMarkEmails('spam'),
                    },
                    {
                        name: 'AddToList',
                        title: "Add Users to List",
                        onClick: () => this.setState({showAddToListPopup: true}),
                    }
                ]}
            />

        ];
    }

    getSwitches() {
        return [
            {
                name: 'Filters',
                icon: <i className={'v3 icon search'} />,
                tooltip: "Show search filters.",
                isActive: this.state.showFilters,
                onClick: () => this.setState({showFilters: !this.state.showFilters})
            }

        ];
    }

    getTabs() {
        if (!this.props.teams || this.props.teams.length === 0 || this.props.showTeamTabs === false) {
            return null;
        }

        const currentTeamFilter = _get(this.props, 'query.filters.team_ids', null);

        return [
            {
                name: 'all',
                title: 'All',
                isActive: currentTeamFilter === null,
                onClick: () => this.handleFilterChange('team_ids', null),
            },
            ...this.props.teams.map(team => {
                return {
                    name: team.id,
                    title: team.name,
                    isActive: parseInt(currentTeamFilter, 10) === parseInt(team.id, 10),
                    onClick: () => this.handleFilterChange('team_ids', team.id),
                }
            })
        ];
    }

    renderHeaderIcon() {
        const total = this.getTotalHits();
        if (total) {
            return (
                <span className={'badge large'}>{total}</span>
            )
        }
    }

    getMaxPages() {
        const hits = this.getTotalHits();
        if (!hits) return 1;
        return Math.ceil(hits / (this.props.query.limit || 10));
    }

    renderReadPopup() {
        if (!this.state.email) {
            return null;
        }

        const emailIndex = _findIndex(this.props.emails, {id: this.state.email.id});
        const nextIndex = parseInt(emailIndex, 10) + 1;
        const prevIndex = parseInt(emailIndex, 10) - 1;
        const onClickNext = typeof this.props.emails[nextIndex] === 'undefined' ? undefined : () => {
            this.handleLaunchEmail(this.props.emails[nextIndex]);
        };
        const onClickPrev = typeof this.props.emails[prevIndex] === 'undefined' ? undefined : () => {
            this.handleLaunchEmail(this.props.emails[prevIndex]);
        };

        return (
            <Toolbox
                key={'read-email-toolbox-'+this.state.email.id}
                addlClasses="toolbox-fixed toolbox-md"
                content={
                    <EmailRead
                        key={'read-email-'+this.state.email.id}
                        email={this.state.email}
                        showLightboxControls={true}
                        handleCloseLightbox={() => this.setState({email: null})}
                        openNextEmail={onClickNext}
                        openPrevEmail={onClickPrev}
                    />
                }
            />
        );
    }

    getPopups() {
        return [
            // Read
            this.renderReadPopup(),

            // Compose
            this.props.showCompose ? (
                <Toolbox
                    key={'email-table-compose-box'}
                    supportMobile={true}
                    content={
                        <EmailCompose
                            onClose={() => this.props.toggleCompose(false, null)}
                            campaignId={
                                (this.props.composeCampaignId && this.props.composeCampaignId !== 'all')
                                    ? this.props.composeCampaignId : null
                            }
                            onSent={() => {
                                setTimeout(() => this.props.toggleCompose(false, null), 2000);
                            }}
                        />
                    }
                    style={{
                        top: 60,
                        left: '50%',
                        width: 800,
                        marginLeft: -400,
                    }}
                />
            ) : null,


            this.state.showMarkEmails
                ? this.renderMarkEmailsToolbox()
                : null,

            this.state.showSendMessagePopup
            ? this.renderSendMessagePopup()
                : null,

            this.state.showAddToListPopup
            ? this.renderAddToListPopup()
                : null,

            // Search
            this.state.showFilters ? (
                <Toolbox
                    supportMobile={true}
                    key={'email-table-search-box'}
                    title={"Search Emails"}
                    onClose={() => this.setState({showFilters: false})}
                    content={
                        <EmailGodzillaFilters
                            filters={this.props.query.filters}
                            onClickReset={() => {
                                this.resetFilters({});
                                this.setState({showFilters: false});
                            }}
                            onClickSubmit={(filters) => this.resetFilters(filters)}
                            box={this.props.box}
                        />
                    }
                    style={{
                        top: 60,
                        right: 0
                    }}
                />

            ) : null
        ];
    }

    handleMarkEmail(id, label) {
        return this.props.createInteraction(id, 'MarkEmail', {label});
    }
    handleReadEmail(id, read) {
        return this.props.createInteraction(id, 'MarkEmailRead', {read});
    }

    handleArchiveEmail(id, archive) {
        return this.props.createInteraction(id, 'ArchiveEmail', {archive});
    }

    async handleArchiveEmails(archive) {
        const ids = this.getSelectedIds();
        this.setState({isRunningBulkAction: true});
        for (let id of ids) {
            await this.handleArchiveEmail(id, archive);
            this.setState(prevState => {
                return {markedEmailIds: [...prevState.markedEmailIds, id]};
            });
        }
        this.handleRefresh();
        setTimeout(() => {
            this.setState({
                showMarkEmails: false,
                markedEmailIds: [],
                isRunningBulkAction: false,
                selectedIds: {}
            });
        }, 1000);
    }
    async handleReadEmails(read) {
        const ids = this.getSelectedIds();
        this.setState({isRunningBulkAction: true});
        for (let id of ids) {
            await this.handleReadEmail(id, read);
            this.setState(prevState => {
                return {markedEmailIds: [...prevState.markedEmailIds, id]};
            });
        }
        this.handleRefresh();
        setTimeout(() => {
            this.setState({
                showMarkEmails: false,
                markedEmailIds: [],
                isRunningBulkAction: false,
                selectedIds: {}
            });
        }, 1000);
    }

    async handleMarkEmails(label) {
        const ids = this.getSelectedIds();
        this.setState({isRunningBulkAction: true});
        for (let id of ids) {
            await this.handleMarkEmail(id, label);
            this.setState(prevState => {
                return {markedEmailIds: [...prevState.markedEmailIds, id]};
            });
        }
        this.handleRefresh();
        setTimeout(() => {
            this.setState({
                showMarkEmails: false,
                markedEmailIds: [],
                isRunningBulkAction: false,
                selectedIds: {}
            });
        }, 1000);
    }

    renderMarkEmailsToolbox() {
        const count = this.getSelectedIds().length;
        const markedCount = (this.state.markedEmailIds || []).length;

        let title = `Mark ${count} Emails`;
        if (markedCount > 0) {
            title = `Mark ${markedCount} of ${count} Emails...`;
        }

        return (
            <Toolbox
                key={'emails-mark-emails-toolbox'}
                supportMobile={true}
                content={
                    <MarkEmails
                        onClickLabel={label => this.handleMarkEmails(label)}
                    />
                }
                title={title}
                onClose={() => this.setState({showMarkEmails: false})}
                style={{
                    top: 60,
                    left: 40,
                    position: 'fixed'
                }}
            />
        )
    }

    determineVisibleColumns() {
        let cols = [...this.props.cols];

        if (this.props.box === 'inbox') {
            // cols = _pull(cols, 'to');
            cols = _pull(cols, 'was_opened');
            cols = _pull(cols, 'was_clicked');
            cols = _pull(cols, 'was_bounced');
        }

        if (this.props.box === 'outbox') {
            // cols = _pull(cols, 'from');
        }

        return cols;
    }

    render() {

        const {showSwitches, showIcon, showTitle, showSort} = this.props;

        return (
            <TableWrapper

                title={showTitle ? this.props.title : null}
                headerIcon={showIcon ? this.renderHeaderIcon() : null}

                items={this.props.emails}
                columns={this.getColumns()}
                buttons={this.getButtons()}
                switches={showSwitches ? this.getSwitches() : []}
                tabs={this.getTabs()}
                popups={this.getPopups()}
                visibleColumns={this.determineVisibleColumns()}
                stickySortTabs={true}

                isRowSelected={row => this.isRowSelected(row)}

                onPageChange={this.handlePageChange}
                page={this.props.query.page}
                pages={this.getMaxPages()}
                blurry={this.props.query.isFetching}

                sort={this.props.query.sort}
                onSortChange={this.handleSortChange}
                showSort={showSort}

                wrapperClasses={this.props.wrapperClasses}

            />
        );

    }
}

const mapStateToProps = (state, props) => {
    return {
        teams: getTeams(state, props),
        emails: QuerySelectors.getPageItemsWithFallback(state.emails, props, DEFAULT_EMAILS_QUERY),
        query: QuerySelectors.getQuery(state.emails, props, DEFAULT_EMAILS_QUERY),
        campaigns: CampaignSelectors.getCampaignsByDate(state, props),
        showCompose: state.emails.compose.showCompose,
        composeCampaignId: state.emails.compose.campaignId,
    };
};

const mapDispatchToProps = (dispatch) => {
    const url = `/manage/api/email/_/v3`;
    return {
        onRefresh: (query) => dispatch(Query.fetchQuery(url, query, ActionTypes.EMAILS_REQUEST_QUERY, ActionTypes.EMAILS_RECEIVE_QUERY)),
        onSortChange: (sort, query) => dispatch(Query.updateQuerySort(sort, query, ActionTypes.EMAILS_UPDATE_QUERY_SORT)),
        onPageChange: (page, query) => dispatch(Query.updateQueryPage(page, query, ActionTypes.EMAILS_UPDATE_QUERY_PAGE)),
        onFilterChange: (filters, query) => dispatch(Query.updateQueryFilters(filters, query, ActionTypes.EMAILS_UPDATE_QUERY_FILTERS)),
        createInteraction: (id, type, ctx) => dispatch(createEmailInteraction(id, type, ctx)),
        createListInteraction: (id, type, ctx) => dispatch(createListInteraction(id, type, ctx)),
        openUserLightbox: (id, ctx, stack) => dispatch(openUserLightbox(id, ctx, stack)),
        toggleCompose: (show, campaignId) => dispatch(emailToggleCompose(show, campaignId)),
        fetchEmail: (id) => dispatch(fetchEmail(id)),

    };
};

const ConnectedEmails = connect(mapStateToProps, mapDispatchToProps)(Emails);

ConnectedEmails.propTypes = {
    title: PropTypes.string,
    onClickComposeToUser: PropTypes.object,
    showTeamTabs: PropTypes.bool,
};

export default ConnectedEmails;
