import React, {useMemo, useState} from "react";
import {capitalizeFirstLetter} from "../../../utilities";
import AudienceDonutChart from "../../Chart/Audience/AudienceDonutChart";
import AudienceBarChart from "../../Chart/Audience/AudienceBarChart";
import AudienceCategoryChart from "../../Chart/Audience/AudienceCategoryChart";
import _get from "lodash/get";
import tinygradient from "tinygradient";

function PortfolioAudience({item, list, limit=3}) {

    const [showMore, setShowMore] = useState(false);
    const getSetting = (key, defaultValue) => _get(item, `settings.${key}`, _get(list, `settings.${key}`, defaultValue));

    const customChartColors = useMemo(function () {

        const start = getSetting('onesheet_chart_color_start', null);
        const stop = getSetting('onesheet_chart_color_stop', null);
        if (!start || !stop) {
            return undefined;
        }
        return tinygradient([start, stop]).rgb(9).map(i => i.toHexString());
    }, [list]);

    const getTidalOrSocialDataField = (tidalField, socialdataField) => {
        const tidalData = getTidalAudienceFieldDistribution(tidalField);
        if (tidalData !== null) {
            return tidalData;
        }

        const sdData = getSocialDataAudienceField(socialdataField);
        if (sdData) {
            return sdData;
        }

        return null;
    };

    const getTidalAudienceFieldDistribution = (field) => {
        const audience = item.audience;
        if (!audience) return null;

        const distribution = audience.distribution;
        if (!distribution) return null;

        const data = distribution[field];
        if (!data) return null;

        return data;
    };

    const getRawSocialDataAudienceField = (field) => {
        const sd = item.socialdata;
        if (!sd) return null;

        const followersFieldKey = `audience_followers.data.${field}`;
        const likersFieldKey = `audience_likers.data.${field}`;

        const followersData = _get(sd, followersFieldKey, null);
        const likersData = _get(sd, likersFieldKey, null);

        if (followersData) {
            return followersData;
        }
        return likersData;
    }

    const getSocialDataAudienceField = (field) => {
        const rawValues = getRawSocialDataAudienceField(field);
        if (!rawValues) return null;
        return parseSocialDataRaw(rawValues);

    }

    const parseSocialDataRaw = (rawValues) => {
        let out = {};
        for (const row of rawValues) {
            const rawTitle = row.name || row.code;
            const title = capitalizeFirstLetter(rawTitle.toLowerCase());
            const val = row.weight;
            out[title] = val;
        }

        return out;
    }

    const wrapSocialDataChart = ({chart, title}) => {
        return (
            <div className="widget">
                <div className="title">
                    <h2>{title}</h2>
                </div>
                <div className="content">
                    {chart}
                </div>
            </div>

        );
    }

    const renderSocialDataGenderChart = () => {
        if (!getSetting('show_audience_gender', true)) return null;
        const data = getTidalOrSocialDataField('gender', 'audience_genders');
        if (!data) return null;

        return wrapSocialDataChart({
            title: 'Audience Gender',
            chart: <AudienceDonutChart data={data} title={"Audience Gender"} height={275} colors={customChartColors} />
        });
    }

    const renderSocialDataAgeChart = () => {
        if (!getSetting('show_audience_age', true)) return null;
        const data = getTidalOrSocialDataField('age', 'audience_ages');
        if (!data) return null;

        return wrapSocialDataChart({
            title: 'Audience Age',
            chart: <AudienceBarChart data={data} title={'Audience Age'} height={275} colors={customChartColors} />
        })
    }

    const renderSocialDataBrandAffinitiesChart = () => {
        if (!getSetting('show_audience_brands', true)) return null;
        const data = getSocialDataAudienceField('audience_brand_affinity');
        if (!data) return null;
        return wrapSocialDataChart({
            title: 'Audience Brands',
            chart: <AudienceCategoryChart data={data} limit={8} colors={customChartColors} />
        });
    }

    const renderSocialDataLanguagesChart = () => {
        if (!getSetting('show_audience_language', true)) return null;
        const data = getSocialDataAudienceField('audience_languages');
        if (!data) return null;
        return wrapSocialDataChart({
            title: 'Audience Language',
            chart: <AudienceCategoryChart data={data} limit={8} colors={customChartColors} />
        });
    }

    const renderSocialDataEthnicitiesChart = () => {
        if (!getSetting('show_audience_ethnicity', true)) return null;
        const data = getTidalOrSocialDataField('race', 'audience_ethnicities');
        if (!data) return null;
        return wrapSocialDataChart({
            title: 'Audience Ethnicity',
            chart: <AudienceCategoryChart data={data} limit={8} colors={customChartColors} />
        });
    }

    const renderSocialDataInterestsChart = () => {
        if (!getSetting('show_audience_interests', true)) return null;
        const data = getTidalOrSocialDataField('category', 'audience_interests');
        if (!data) return null;
        return wrapSocialDataChart({
            title: 'Audience Interests',
            chart: <AudienceCategoryChart data={data} limit={8} colors={customChartColors} />
        });
    }

    const sortLocationsForHighlights = (locations) => {

        let matching = [];
        let notMatching = [];

        const toHighlight = getSetting('highlight_locations', []);
        const highlightIds = (toHighlight || []).map(i => i.id);

        for (const loc of locations) {
            if (highlightIds.indexOf(loc.id) > -1) {
                matching.push(loc);
            } else {
                notMatching.push(loc);
            }
        }
        console.log({matching, notMatching, highlightIds});
        return [...matching, ...notMatching];
    };

    const renderSocialDataCountriesChart = () => {
        if (!getSetting('show_audience_countries', true)) return null;
        const geo = getRawSocialDataAudienceField('audience_geo');
        if (!geo) return null;
        const countries = geo.countries;
        if (!countries) return null;
        const data = parseSocialDataRaw(sortLocationsForHighlights(countries));
        return wrapSocialDataChart({
            title: 'Audience Countries',
            chart: <AudienceCategoryChart data={data} limit={8} autoSort={false} colors={customChartColors} />
        })
    }
    const renderSocialDataCitiesChart = () => {
        if (!getSetting('show_audience_cities', true)) return null;
        const geo = getRawSocialDataAudienceField('audience_geo');
        if (!geo) return null;
        const cities = geo.cities;
        if (!cities) return null;
        const data = parseSocialDataRaw(sortLocationsForHighlights(cities));
        return wrapSocialDataChart({
            title: 'Audience Cities',
            chart: <AudienceCategoryChart data={data} limit={8} autoSort={false} colors={customChartColors} />
        })
    }
    const renderSocialDataStatesChart = () => {
        if (!getSetting('show_audience_states', true)) return null;
        const geo = getRawSocialDataAudienceField('audience_geo');
        if (!geo) return null;
        const states = geo.states;
        if (!states) return null;
        const data = parseSocialDataRaw(sortLocationsForHighlights(states));
        return wrapSocialDataChart({
            title: 'Audience States',
            chart: <AudienceCategoryChart data={data} limit={8} autoSort={false} colors={customChartColors} />
        })
    }

    const renderSocialDataCredibilityChart = () => {
        if (!getSetting('show_audience_credibility', true)) return null;
        const sd = item.socialdata;
        if (!sd) return null;

        const followers = sd.audience_followers;
        const likers = sd.audience_likers;
        const commenters = sd.audience_commenters;

        let data = {};

        if (followers && followers.success && followers.data && followers.data.audience_credibility) {
            data['Followers'] = followers.data.audience_credibility;
        }

        if (likers && likers.success && likers.data && likers.data.audience_credibility) {
            data['Likers'] = likers.data.audience_credibility;
        }

        if (commenters && commenters.success && commenters.data && commenters.data.audience_credibility) {
            data['Commenters'] = commenters.data.audience_credibility;
        }

        if (Object.keys(data).length === 0) {
            return null;
        }

        return wrapSocialDataChart({
            title: 'Audience Credibility',
            chart: <AudienceBarChart data={data} title={'Audience Credibility'} height={275} colors={customChartColors} />
        });
    }


    const renderMoreLessLink = () => {

        if (!showMore) {
            return (<a role="button" className="v3" onClick={() => setShowMore(true)}>Show More <i className="v3 icon angle-right" /></a>);
        }
        return (<a role="button" className="v3" onClick={() => setShowMore(false)}>Show Less <i className="v3 icon angle-down" /></a> );
    };

    const renderSocialDataCharts = () => {

        const sliceLimit = showMore ? 20 : limit;
        return [

            // Audience gender
            renderSocialDataGenderChart(),
            // Audience age
            renderSocialDataAgeChart(),
            // Credibility
            renderSocialDataCredibilityChart(),
            // Audience location
            renderSocialDataCountriesChart(),
            renderSocialDataCitiesChart(),
            renderSocialDataStatesChart(),
            // Audience ethnicity
            renderSocialDataEthnicitiesChart(),
            // Language
            renderSocialDataLanguagesChart(),
            // Audience interests
            renderSocialDataInterestsChart(),
            // Audience brand affinities
            renderSocialDataBrandAffinitiesChart(),
        ].filter(chart => !!chart).slice(0, sliceLimit);
    }

    return (
        <>
            <div className="audience-box">
                {renderSocialDataCharts()}
            </div>
            <div className="audience-box-more-wrapper">
                {renderMoreLessLink()}
            </div>
        </>
    );
}


export default PortfolioAudience;