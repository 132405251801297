import React from 'react';
import {formatNumber, formatNumberK, makeMomentFromDate} from "../utilities";


export const getNumSocialPostsRequired = (activation) => {
    const stats = ((activation || {}).settings || {});

    const keys = [
        'num_facebook_posts',
        'num_facebook_page_posts',
        'num_instagram_posts',
        'num_instastories',
        'num_other_posts',
        'num_pinterest_posts',
        'num_twitter_posts',
        'num_youtube_posts',
        'num_tiktok_posts',
    ];

    let total = 0;
    for (let key of keys) {
        let val = parseInt((stats[key] || 0), 10);
        total += val;
    }

    return total;

};

export const getNumSocialPostsSubmitted = (activation) => {
    const stats = ((activation || {}).status || {});

    const keys = [
        'num_instagram_posts_uploaded',
        'num_instastories_uploaded',
        'num_facebook_posts_uploaded',
        'num_facebook_page_posts_uploaded',
        'num_pinterest_posts_uploaded',
        'num_twitter_posts_uploaded',
        'num_youtube_posts_uploaded',
        'num_tiktok_posts_uploaded',
    ];

    let total = 0;
    for (let key of keys) {
        let val = parseInt((stats[key] || 0), 10);
        total += val;
    }

    return total;

};

export const getContentRequirementsMap = (activation) => {
    let out = {};

    const settings = activation.settings;
    const status = activation.status;

    const settingsToCheck = [
        'use_blog_drafts',
        'use_social_drafts',
        'num_blog_posts',
        'num_instagram_posts',
        'num_twitter_posts',
        'num_pinterest_posts',
        'num_youtube_posts',
        'num_facebook_posts',
        'num_facebook_page_posts',
        'num_images',
        'num_videos',
        'num_instastories',
        'num_tiktok_posts',
        'num_other_posts'
    ];

    let numOtherPostsUploadedExcess = 0;
    for (let key of settingsToCheck) {
        const value = settings[key];

        let uploadedKey = key + '_uploaded';
        let uploadedValue = status[uploadedKey];

        if (uploadedValue > value && ['num_images', 'num_videos', 'num_other_posts', 'num_blog_posts', 'use_blog_drafts', 'use_social_drafts'].indexOf(key) === -1) {
            // add this to other posts counter
            numOtherPostsUploadedExcess += (uploadedValue - value);
        }

        if (key === 'num_other_posts') {
            // Special handling here; use the excess of required_content_count and content_total_count
            uploadedKey = 'other_posts_uploaded';
            uploadedValue = numOtherPostsUploadedExcess;
        }

        if (typeof value === 'undefined' || !value) {
            continue;
        }

        if (value === "0") {
            continue;
        }

        const plural = value !== 1 ? 's' : '';
        let line = null;

        switch (key) {
            case 'use_blog_drafts':
                line = 'Submit a draft for all blog posts.';
                break;

            case 'use_social_drafts':
                line = 'Submit a draft for all social posts.';
                break;

            case 'num_other_posts':
                line = `Submit ${value} social post${plural} anywhere.`;
                break;

            case 'num_blog_posts':
                line = `Submit ${value} blog post${plural}.`;
                break;

            case 'num_instagram_posts':
                line = `Submit ${value} Instagram post${plural}.`;
                break;

            case 'num_twitter_posts':
                line = `Submit ${value} Twitter post${plural}.`;
                break;
            case 'num_pinterest_posts':
                line = `Submit ${value} Pinterest post${plural}.`;
                break;
            case 'num_youtube_posts':
                line = `Submit ${value} YouTube post${plural}.`;
                break;
            case 'num_facebook_posts':
                line = `Submit ${value} personal Facebook post${plural}.`;
                break;
            case 'num_facebook_page_posts':
                line = `Submit ${value} Facebook Page post${plural}.`;
                break;
            case 'num_images':
                line = `Upload ${value} high-resolution image${plural}.`;
                break;
            case 'num_videos':
                line = `Upload ${value} video${plural}.`;
                break;
            case 'num_instastories':
                line = `Upload ${value} Instastories.`;
                break;
            case 'num_tiktok_posts':
                line = `Upload ${value} TikTok video${plural}.`;
                break;

        }

        out[key] = {
            text: line,
            key,
            value,
            uploadedKey,
            uploadedValue
        };

    }

    return out;
};

export const getContentRequirementsList = (activation) => {
    const mapping = getContentRequirementsMap(activation);
    return Object.values(mapping).map(i => i.text);
};

export const getContentRequirementsHtml = (activation) =>
{
    const items = getContentRequirementsList(activation);
    if (items.length === 0) {
        return '';
    }
    const mappedItems = items.map(item => `<li>${item}</li>`);
    const itemsHTML = mappedItems.join("\n");
    return '<ul>' + itemsHTML + '</ul>';
};

export const CAMPAIGN_HEALTH_CHECKS = [
    {
        name: 'Uninvited Activations',
        description: 'Users which have been added to the campaign but not invited yet. To fix, invite the uninvited influencers.',
        filters: {'status.was_invited': false},
        isValid: (campaign) => true,
        tab: 'invitation',
        key: 'uninvited'
    },
    {
        name: 'Pending Invitation',
        description: 'Users who have been invited but have not responded yet. To fix, send invitation reminders to the influencers.',
        filters: {'status.is_invitation_pending': true},
        isValid: (campaign) => true,
        tab: 'invitation',
        key: 'unresponded'
    },
    {
        name: 'Accepted Invitation',
        description: 'Users that have accepted the invitation to join this campaign.',
        filters: {'status.did_user_accept_invitation': true},
        isValid: (campaign) => true,
        tab: 'invitation',
        key: 'accepted-invitation'
    },
    {
        name: 'Accepted Invitation and Active',
        description: 'Users that have accepted the invitation and are not waitlisted, canceled, or complete.',
        filters: {'status.is_active': true},
        isValid: (campaign) => true,
        tab: 'influencers',
        key: 'accepted-invitation'
    },
    {
        name: "Ready to Ship",
        description: "Highlights users who need product samples shipped to them.",
        filters: {'status.has_shipping_address': true, 'status.is_product_shipped': false, 'status.is_active': true},
        isValid: (campaign) => campaign.settings && campaign.settings.use_products,
        tab: 'gifting',
        key: 'ready-to-ship',
    },
    {
        name: 'Missing Payment Details',
        description: 'Users who have not yet filled out their payment details.',
        filters: {'status.has_payment_profile': false, 'status.is_active': true},
        isValid: (campaign) => !!(campaign.settings && campaign.settings.use_payments),
        tab: 'gifting',
        key: 'missing-payment'
    },
    {
        name: 'Missing Shipping Address',
        description: 'Users who have not yet filled out their shipping address.',
        filters: {'status.has_shipping_address': false, 'status.is_active': true},
        isValid: (campaign) => campaign.settings && campaign.settings.use_products,
        tab: 'gifting',
        key: 'missing-shipping'
    },
    {
        name: 'Missing Coupon Code',
        description: 'Users who have not been assigned a coupon code.',
        filters: {'status.is_coupon_code_assigned': false, 'status.is_active': true},
        isValid: (campaign) => campaign.settings && campaign.settings.use_coupon_codes,
        tab: 'gifting',
        key: 'missing-coupon'
    },
    {
        name: 'Unpaid Activations',
        description: 'Users who have completed their requirements but have not had their payments approved yet.',
        filters: {'status.is_payment_owed': true},
        isValid: (campaign) => campaign.settings && campaign.settings.use_payments,
        tab: 'gifting',
        key: 'unpaid'
    },
    {
        name: "Submitted Posts",
        description: "Users who have submitted content.",
        filters: {"status.content_total_count": {min: 1}},
        isValid: (campaign) => true,
        tab: 'content',
        key: 'has-content',
    },
    {
        name: "Uploaded Videos",
        description: "Users who have uploaded videos.",
        filters: {"status.num_videos_uploaded": {min: 1}},
        isValid: (campaign) => campaign.settings && campaign.settings.num_videos > 0,
        tab: 'content',
        key: 'has-videos',
    },
    {
        name: 'Open Issues',
        description: 'Users with open issues. To fix, review and resolve any open issues.',
        filters: {'status.has_open_issues': true},
        isValid: (campaign) => true,
        tab: 'overview',
        key: 'issues'
    },
    {
        name: 'Open Drafts',
        description: 'Users with open drafts. To fix, review and accept any open drafts.',
        filters: {'status.has_open_drafts': true},
        isValid: (campaign) => campaign.settings && campaign.settings.use_blog_drafts || campaign.settings && campaign.settings.use_social_drafts,
        tab: 'content',
        key: 'drafts'
    },
];

export const INFLUENCER_REQUIREMENT_TYPES = [
    {
        key: 'SignNDA',
        title: "Non-Disclosure Agreement",
        shortTitle: 'NDA',
        isVisible: (activation) => !!activation.status.is_nda_required,
        isComplete: (activation) => !!activation.status.is_nda_signed,
        description: 'Completed when the user signs the NDA.',
    },
    {
        key: 'SubmitAdditionalInformation',
        title: 'Submit Additional Information',
        shortTitle: 'Additional Info',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !!activation.status.is_form_required,
        isComplete: (activation) => !!activation.status.is_form_submitted,
        isBlocked: (activation) => (activation.status.is_nda_required && !activation.status.is_nda_signed),
        description: 'Completed when the user submits their custom form.',
    },
    {
        key: 'ReviewOffer',
        title: "Review & Respond to Brief",
        shortTitle: 'Review Details',
        isComplete: (activation) => !!activation.status.did_user_accept_invitation || !!activation.status.did_user_decline_invitation,
        isBlocked: (activation) => (activation.status.is_nda_required && !activation.status.is_nda_signed),
        description: 'Completed when the user either accepts or declines the campaign invitation.',
    },
    {
        key: 'UserDeclinedInvitation',
        title: 'Offer Declined',
        shortTitle: 'Declined',
        description: 'Indicates the user declined their invitation to the campaign.',
        isVisible: (activation) => !!activation.status.did_user_decline_invitation,
        isComplete: () => false,
        isBlocked: () => true,
    },
    {
        key: 'CompleteYourProfile',
        title: "Complete Your Profile",
        shortTitle: "Complete Profile",
        isComplete: (activation, user) => ((user || {}).missingFields || []).length <= 3,
        isVisible: (activation) => !!activation.settings.force_complete_profile,
        isBlocked: (activation) => (activation.status.is_nda_required && !activation.status.is_nda_signed),
        description: 'Completed when the user has three or fewer missing profile fields.',
    },
    {
        key: 'Waitlisted',
        title: 'On Waitlist',
        description: 'Completed when the user is accepted into the campaign.',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !!activation.status.is_waitlisted,
        isComplete: () => false,
        isBlocked: () => false,
    },
    {
        key: 'Canceled',
        title: 'Campaign Canceled',
        shortTitle: 'Canceled',
        description: 'Indicates the activation has been canceled.',
        isVisible: (activation) => !!activation.status.is_canceled,
        isComplete: () => false,
        isBlocked: () => true,
    },
    {
        key: 'Ensure1099Policy',
        title: 'Policy Requirements',
        shortTitle: 'Policy',
        description: 'Completed when the user has appropriate policy coverage.',
        isVisible: (activation) => !!activation.settings.use_1099_policy,
        isComplete: () => false,
        isBlocked: () => false,
    },
    {
        key: 'SubmitShippingAddress',
        title: 'Update Shipping Address',
        shortTitle: 'Shipping',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !!activation.settings.use_products,
        isComplete: (activation) => !!activation.status.has_shipping_address,
        isBlocked: (activation) => (activation.status.is_nda_required && !activation.status.is_nda_signed),
        description: 'Completed when the user confirms their current shipping address.',
        tag: (activation, user, _t) => {
            // show an angry red triangle if there's no shipping address
            if (activation.settings.use_products && !activation.status.has_shipping_address) {
                return <><strong style={{color: '#FF3300'}}>Update Address</strong> <i className="v3 icon error" style={{position: 'relative', left: 'auto', right: 'auto', verticalAlign: "top" }}/> </>;
            }
            return null;
        }
    },
    {
        key: 'SelectProduct',
        title: 'Choose Product Samples',
        shortTitle: 'Product Samples',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !!activation.settings.use_products && !!activation.settings.product_catalog_id,
        isComplete: (activation) => !!activation.status.is_product_selected,
        isBlocked: (activation) => (activation.status.is_nda_required && !activation.status.is_nda_signed),
        tag: (activation, user, _t) => {
            const total = parseInt(activation.settings.number_samples, 10);
            const selected = (activation.products || []).length;
            const remaining = total - selected;
            if (remaining > 0) {
                return `${remaining} ${_t('campaign.reqs.remaining', 'Remaining')}`;
            }
            return null;
        },
        description: 'Completed when the user selects their product samples.',
    },
    {
        key: 'ReceiveProduct',
        title: 'Track Product Samples',
        shortTitle: 'Track Samples',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !activation.status.is_waitlisted && !!activation.settings.use_products,
        isComplete: (activation) => !!activation.status.is_product_received,
        isBlocked: (activation) => !activation.status.is_product_shipped,
        description: 'Completed when the user indicates that their product samples have arrived.',
    },
    {
        key: 'ClaimCouponCode',
        title: 'Get Coupon Code',
        shortTitle: 'Coupon Code',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !activation.status.is_waitlisted && !!activation.settings.use_coupon_codes,
        isComplete: (activation) => !!activation.status.is_coupon_code_claimed,
        isBlocked: (activation) => !activation.status.is_coupon_code_assigned,
        description: 'Completed when the user indicates that they received their coupon code.',
    },
    {
        key: 'SubmitDrafts',
        title: 'Submit Drafts',
        shortTitle: 'Drafts',
        isVisible: (activation) => !activation.status.did_user_decline_invitation
            && !activation.status.is_canceled
            && !activation.status.is_waitlisted
            && (!!activation.settings.use_social_drafts || !!activation.settings.use_blog_drafts),
        isComplete: (activation) => activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count >= activation.status.required_drafts_count,
        isBlocked: (activation) => (activation.status.is_nda_required && !activation.status.is_nda_signed),
        description: 'Completed when all submitted drafts are approved.',
        tag: (activation, user, _t) => {
            const required = (activation.status || {}).required_drafts_count;
            const submitted = (activation.status || {}).total_drafts_count;
            const remaining = required - submitted || null;
            const useDeadline = (activation.settings || {}).use_deadlines;
            const draftDeadline = (activation.settings || {}).draft_deadline_at;
            const dueDate = draftDeadline ? makeMomentFromDate(draftDeadline).format('MMM Do') : null;
            if (required > 0 && remaining > 0) {
                if (useDeadline && draftDeadline && dueDate) {
                    return `${remaining}x ${_t('campaign.reqs.due', 'Due')} ${dueDate}`;
                }
                return `${remaining} ${_t('campaign.reqs.remaining', 'Remaining')}`;
            }
            return null;
        }
    },
    {
        key: 'GenerateAffiliateLink',
        title: 'Get Affiliate Link',
        shortTitle: 'Affiliate Link',
        isVisible: (activation) => !!activation.settings.use_affiliate_links,
        isComplete: (activation) => !!((activation.metadata || {}).latest_affiliate_link || false),
        isBlocked: (activation) => (activation.status.is_nda_required && !activation.status.is_nda_signed),
        description: 'Completed when the user generates an affiliate link.',
    },
    {
        key: 'SubmitBlogPosts',
        title: 'Submit Blog Posts',
        shortTitle: 'Blog Posts',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !activation.status.is_waitlisted && activation.settings.num_blog_posts > 0,
        description: 'Completed when all required blog posts are approved.',
        tag: (activation, user, _t) => {
            const required = (activation.settings || {}).num_blog_posts;
            const submitted = (activation.status || {}).num_blog_posts_uploaded;
            const remaining = required-submitted;
            return getPostTag(activation, remaining, _t || ((k,v) => v));
        },
        isComplete: (activation) => {
            const required = activation.settings.num_blog_posts;
            const submitted = activation.status.num_blog_posts_uploaded;
            const remaining = required-submitted;
            return required > 0 && remaining <= 0;
        },
        isBlocked: (activation) => activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
    },
    {
        key: 'SubmitInstagramPosts',
        metadata: {type: 'instagram'},
        title: 'Submit Instagram Posts',
        shortTitle: 'Instagram Posts',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !activation.status.is_waitlisted && activation.settings.num_instagram_posts > 0,
        description: 'Completed when all required Instagram posts are approved.',
        tag: (activation, user, _t) => {
            const required = (activation.settings || {}).num_instagram_posts;
            const submitted = (activation.status || {}).num_instagram_posts_uploaded;
            const remaining = required-submitted;
            return getPostTag(activation, remaining, (_t || ((k, v) => v)));
        },
        isComplete: (activation) => {
            const required = activation.settings.num_instagram_posts;
            const submitted = activation.status.num_instagram_posts_uploaded;
            const remaining = required-submitted;
            return required > 0 && remaining <= 0;
        },
        isBlocked: (activation) => activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
    },
    {
        key: 'SubmitTwitterPosts',
        metadata: {type: 'twitter'},
        title: 'Submit Twitter Posts',
        shortTitle: 'Twitter Posts',
        isVisible: (activation) => !activation.status.did_user_decline_invitation
            && !activation.status.is_canceled
            && !activation.status.is_waitlisted
            && activation.settings.num_twitter_posts > 0,
        description: 'Completed when all required Twitter posts are approved.',
        tag: (activation, user, _t) => {
            const required = (activation.settings || {}).num_twitter_posts;
            const submitted = (activation.status || {}).num_twitter_posts_uploaded;
            const remaining = required-submitted;
            return getPostTag(activation, remaining, _t || ((k, v) => v));
        },
        isComplete: (activation) => {
            const required = activation.settings.num_twitter_posts;
            const submitted = activation.status.num_twitter_posts_uploaded;
            const remaining = required-submitted;
            return required > 0 && remaining <= 0;
        },
        isBlocked: (activation) => activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
    },
    {
        key: 'SubmitPinterestPosts',
        metadata: {type: 'pinterest'},
        title: 'Submit Pinterest Posts',
        shortTitle: 'Pinterest Posts',
        isVisible: (activation) => !activation.status.did_user_decline_invitation
            && !activation.status.is_canceled
            && !activation.status.is_waitlisted
            && activation.settings.num_pinterest_posts > 0,
        description: 'Completed when all required Pinterest posts are approved.',
        tag: (activation, user, _t) => {
            const required = (activation.settings || {}).num_pinterest_posts;
            const submitted = (activation.status || {}).num_pinterest_posts_uploaded;
            const remaining = required-submitted;
            return getPostTag(activation, remaining, _t || ((k, v) => v));
        },
        isComplete: (activation) => {
            const required = activation.settings.num_pinterest_posts;
            const submitted = activation.status.num_pinterest_posts_uploaded;
            const remaining = required-submitted;
            return required > 0 && remaining <= 0;
        },
        isBlocked: (activation) => activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
    },
    {
        key: 'SubmitYoutubePosts',
        metadata: {type: 'youtube'},
        title: 'Submit YouTube Videos',
        shortTitle: 'YouTube Videos',
        isVisible: (activation) => !activation.status.did_user_decline_invitation
            && !activation.status.is_canceled
            && !activation.status.is_waitlisted
            && activation.settings.num_youtube_posts > 0,
        description: 'Completed when all required YouTube posts are approved.',
        tag: (activation, user, _t) => {
            const required = (activation.settings || {}).num_youtube_posts;
            const submitted = (activation.status || {}).num_youtube_posts_uploaded;
            const remaining = required-submitted;
            return getPostTag(activation, remaining, _t || ((k, v) => v));
        },
        isComplete: (activation) => {
            const required = activation.settings.num_youtube_posts;
            const submitted = activation.status.num_youtube_posts_uploaded;
            const remaining = required-submitted;
            return required > 0 && remaining <= 0;
        },
        isBlocked: (activation) => activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
    },
    {
        key: 'SubmitFacebookPosts',
        metadata: {type: 'facebook'},
        title: 'Submit Facebook Posts',
        shortTitle: 'Facebook Posts',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !activation.status.is_waitlisted && activation.settings.num_facebook_posts > 0,
        description: 'Completed when all required Facebook posts are approved.',
        tag: (activation, user, _t) => {
            const required = (activation.settings || {}).num_facebook_posts;
            const submitted = (activation.status || {}).num_facebook_posts_uploaded;
            const remaining = required-submitted;
            return getPostTag(activation, remaining, _t || ((k, v) => v));
        },
        isComplete: (activation) => {
            const required = activation.settings.num_facebook_posts;
            const submitted = activation.status.num_facebook_posts_uploaded;
            const remaining = required-submitted;
            return required > 0 && remaining <= 0;
        },
        isBlocked: (activation) => activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
    },
    {
        key: 'SubmitFacebookPagePosts',
        metadata: {type: 'facebook-page'},
        title: 'Submit Facebook Page Posts',
        shortTitle: 'Facebook Page Posts',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !activation.status.is_waitlisted && activation.settings.num_facebook_page_posts > 0,
        description: 'Completed when all required Facebook Page posts are approved.',
        tag: (activation, user, _t) => {
            const required = (activation.settings || {}).num_facebook_page_posts;
            const submitted = (activation.status || {}).num_facebook_page_posts_uploaded;
            const remaining = required-submitted;
            return getPostTag(activation, remaining, _t);
        },
        isComplete: (activation) => {
            const required = activation.settings.num_facebook_page_posts;
            const submitted = activation.status.num_facebook_page_posts_uploaded;
            const remaining = required-submitted;
            return required > 0 && remaining <= 0;
        },
        isBlocked: (activation) => activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
    },
    {
        key: 'SubmitInstastory',
        metadata: {type: 'instagram', isInstastory: true},
        title: 'Submit Instastories',
        shortTitle: 'Instastories',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !activation.status.is_waitlisted && activation.settings.num_instastories > 0,
        description: 'Completed when all required Instastories are approved.',
        tag: (activation, user, _t) => {
            //Get number required
            const required = (activation.settings || {}).num_instastories;

            //Calculate posts where type is instastory.
            const submitted = (activation.status || {}).num_instastories_uploaded;
            const remaining = required-submitted;
            if (remaining > 0) {
                return `${remaining} ${_t('campaign.reqs.remaining', 'Remaining')}`;
            }

            return null;
        },
        isComplete: (activation) => {
            const required = (activation.settings || {}).num_instastories || 0;
            const submitted = activation.status.num_instastories_uploaded;
            const remaining = required-submitted;
            return required > 0 && remaining <= 0;
            return false;
        },
        isBlocked: (activation) => activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
    },
    {
        key: 'SubmitTiktokPosts',
        metadata: {type: 'tiktok'},
        title: 'Submit TikTok Videos',
        shortTitle: 'TikTok Videos',
        isVisible: (activation) => !activation.status.did_user_decline_invitation
            && !activation.status.is_canceled
            && !activation.status.is_waitlisted
            && activation.settings.num_tiktok_posts > 0,
        description: 'Completed when all required TikTok posts are approved.',
        tag: (activation, user, _t) => {
            const required = (activation.settings || {}).num_tiktok_posts;
            const submitted = (activation.status || {}).num_tiktok_posts_uploaded;
            const remaining = required-submitted;
            return getPostTag(activation, remaining, _t);
        },
        isComplete: (activation) => {
            const required = activation.settings.num_tiktok_posts;
            const submitted = activation.status.num_tiktok_posts_uploaded;
            const remaining = required-submitted;
            return required > 0 && remaining <= 0;
        },
        isBlocked: (activation) => activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
    },
    {
        key: 'SubmitSocialPosts',
        title: 'Submit Social Posts',
        shortTitle: 'Social Posts',
        isVisible: (activation) => !activation.status.did_user_decline_invitation
            && !activation.status.is_canceled
            && !activation.status.is_waitlisted
            && activation.settings.num_other_posts > 0,
        description: 'Completed when additional social posts are approved.',
        tag: (activation, user, _t) => {

            const required = getNumSocialPostsRequired(activation);
            const otherRequired = parseInt(((activation.settings || {}).num_other_posts || 0), 10);
            const submitted = getNumSocialPostsSubmitted(activation);

            const remaining = Math.min((required-submitted), otherRequired);

            if (remaining >= 0) {
                return getPostTag(activation, remaining, _t);
            } else {
                if (otherRequired > 0) {
                    return `${otherRequired} ${_t('campaign.reqs.required', 'Required')}`;
                }
            }
        },
        isComplete: (activation) => {
            const required = (activation.status || {}).required_content_count;
            const submitted = (activation.status || {}).content_total_count;
            const remaining = required-submitted;
            return required > 0 && remaining <= 0;
        },
        isBlocked: (activation) => activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
    },
    {
        key: 'SubmitImages',
        title: 'Upload Images',
        shortTitle: 'Upload Images',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !activation.status.is_waitlisted && activation.settings.num_images > 0,
        description: 'Completed when the user uploads all required high-resolution images.',
        tag: (activation, user, _t) => {
            const required = (activation.settings || {}).num_images || 0;
            const uploaded = (activation.image_uploads || []).length;
            const remaining = required - uploaded;
            return getPostTag(activation, remaining, _t);
        },
        isComplete: (activation) => {
            const required = activation.settings.num_images || 0;
            const uploaded = (activation.image_uploads || []).length;
            const remaining = required - uploaded;
            return required > 0 && remaining <= 0;
        },
        isBlocked: (activation) => (
            !activation.status.did_user_accept_invitation
                ||
            (activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count)
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
        )
    },
    {
        key: 'SubmitVideos',
        title: 'Upload Videos',
        shortTitle: 'Upload Videos',
        isVisible: (activation) => !activation.status.did_user_decline_invitation && !activation.status.is_canceled && !activation.status.is_waitlisted && activation.settings.num_videos > 0,
        description: 'Completed when the user uploads all required direct video uploads.',
        tag: (activation, user, _t) => {
            const required = (activation.settings || {}).num_videos || 0;
            const uploaded = (activation.video_uploads || []).length;
            const remaining = required - uploaded;
            return getPostTag(activation, remaining, _t);
        },
        isComplete: (activation) => {
            const required = activation.settings.num_videos || 0;
            const uploaded = (activation.video_uploads || []).length;
            const remaining = required - uploaded;
            return required > 0 && remaining <= 0;

        },
        isBlocked: (activation) => (
            !activation.status.did_user_accept_invitation
                ||
            (activation.status.required_drafts_count > 0
            && activation.status.accepted_drafts_count < activation.status.required_drafts_count)
            || (activation.status.is_nda_required && !activation.status.is_nda_signed)
        )
    },
    {
        key: 'SubmitPaymentDetails',
        title: 'Update Payment Details',
        shortTitle: 'Payment Info',
        isVisible: (activation) => !activation.status.did_user_decline_invitation
            && !activation.status.is_canceled
            && !!activation.settings.use_payments
            && !!activation.settings.collect_payment_details,
        isComplete: (activation) => !activation.settings.collect_payment_details
            || !!activation.status.has_payment_profile,
        description: 'Completed when the user confirms their current payment details.',
    },
    {
        key: 'Fulfilled',
        title: 'Requirements Complete!',
        shortTitle: 'Completed',
        description: "The user has completed all of their requirements.",
        isVisible: (activation) => !!activation.status.is_fulfilled,
        isComplete: (activation) => !!activation.status.is_fulfilled,
    },
];

/**
 * @param {Object} activation
 * @param {Object} user
 * @returns {*}
 */
export const getCompletedInfluencerRequirementTypes = (activation, user) => {
    const defaultActivation = {
        status: {},
        settings: {},
    };
    const cleanActivation = {...defaultActivation, ...activation};
    return getVisibleInfluencerRequirementTypes(activation, user)
        .filter(spec => {
            if (typeof spec.isComplete === 'function') {
                return spec.isComplete(cleanActivation, user);
            }
            return false;
        });
};

/**
 * @param {Object} activation
 * @param {Object} user
 * @returns {*[]}
 */
export const getVisibleInfluencerRequirementTypes = (activation, user) => {
    const defaultActivation = {
        status: {},
        settings: {},
    };
    const cleanActivation = {...defaultActivation, ...activation};
    return INFLUENCER_REQUIREMENT_TYPES
        .filter(spec => {
            if (typeof spec.isVisible === 'function') {
                return spec.isVisible(cleanActivation, user);
            }
            return true;
        });
};

export const getNiceRequirementName = (type) => {

    switch (type) {
        case "Tidal\\Campaign\\Requirement\\PostsPerChannel":
            return 'Submit Content';

        case "Tidal\\Campaign\\Requirement\\SubmitDraft":
            return 'Submit Drafts';

        case "Tidal\\Campaign\\Requirement\\ShippingAddress":
            return 'Submit Shipping Address';

        case "Tidal\\Campaign\\Requirement\\PaymentInformation":
            return 'Submit Payment Details';

        case "Tidal\\Campaign\\Requirement\\ReviewOffer":
            return 'Respond to Invitation';

        default:
            return type.replace('Tidal\\Campaign\\Requirement\\', '');
    }

};

export const getActivationMilestones = (activation, blacklist = ['added']) => {

    const validEvents = [
        'added',
        'invited',
        'accepted',
        'rejected',
        'declined',
        'waitlisted',
        'assigned',
        'shipped',
        'fulfilled',
        'invoiced',
        'completed',
        'canceled'
    ];

    let events = [];

    for (const eventName of validEvents) {

        if (blacklist.indexOf(eventName) !== -1) {
            continue;
        }

        const eventKey = eventName + '_at';

        if (typeof activation[eventKey] !== 'undefined' && activation[eventKey] !== null) {
            events.push(eventName);
        }
    }

    return events;

};

export const activationCost = (activation, feeRate) => {

    let total = 0;

    // TODO: I think releases may not be counted here?
    // if (activation.transactions.length) {
    //     activation.transactions.forEach(t => total += parseFloat(t.amount));
    // } else {
    total = parseFloat(activation.payment_amount || 0);
    total += feeRate * total;
    total = -1 * total;
    // }

    return total;

};

export const sumActivationCosts = (activations, feeRate) => {

    return activations.reduce((total, activation) => {
        return total += activationCost(activation, feeRate);
    }, 0);

};

export const filterNonDeclined = (activation) => {
    if (activation.declined_at || activation.canceled_at) {
        return false;
    }
    return true;
};

export const filterOffersSent = (activation) => {
    if (
        activation.accepted_at
        || activation.fulfilled_at
        || activation.rejected_at
        || activation.declined_at
        || activation.canceled_at
        || activation.completed_at
        || activation.invoiced_at
    ) {
        return false;
    }

    return true;
};

export const filterOffersAccepted = (activation) => {
    if (activation.accepted_at &&
        !(activation.canceled_at || activation.rejected_at || activation.fulfilled_at || activation.declined_at || activation.invoiced_at || activation.completed_at)
    ) {
        return true;
    }
    return false;
};

export const filterPaymentsOwed = (activation) => {
    if (activation.fulfilled_at || activation.completed_at || activation.invoiced_at) {
        return true;
    }
    return false;
};

export const ACTIVATION_STATUS_FULFILLED = 'ACTIVATION_STATUS_FULFILLED';
export const ACTIVATION_STATUS_ACCEPTED = 'ACTIVATION_STATUS_ACCEPTED';
export const ACTIVATION_STATUS_INVITED = 'ACTIVATION_STATUS_INVITED';
export const ACTIVATION_STATUS_CANCELED = 'ACTIVATION_STATUS_CANCELED';
export const ACTIVATION_STATUS_DECLINED = 'ACTIVATION_STATUS_DECLINED';

export const getActivationStatus = activation => {
    if (activation.completed_at || activation.fulfilled_at || activation.invoiced_at) {
        return ACTIVATION_STATUS_FULFILLED;
    }
    else if (activation.canceled_at) {
        return ACTIVATION_STATUS_CANCELED;
    }
    else if (activation.declined_at || activation.rejected_at) {
        return ACTIVATION_STATUS_DECLINED;
    }
    else if (activation.accepted_at) {
        return ACTIVATION_STATUS_ACCEPTED;
    }
    else {
        return ACTIVATION_STATUS_INVITED;
    }
};

export const getNiceActivationStatus = activation => {
    const status = getActivationStatus(activation);
    switch (status) {
        case ACTIVATION_STATUS_INVITED:
            return 'Invited';
        case ACTIVATION_STATUS_ACCEPTED:
            return 'Accepted Campaign';
        case ACTIVATION_STATUS_CANCELED:
            return 'Canceled Campaign';
        case ACTIVATION_STATUS_DECLINED:
            return 'Declined Invitation';
        case ACTIVATION_STATUS_FULFILLED:
            return 'Completed Campaign';
        default:
            return 'Unknown';
    }

};

export const getActivationStatusIcon = activation => {
    const status = getActivationStatus(activation);
    switch (status) {
        case ACTIVATION_STATUS_FULFILLED:
            return <i className={'fa fa-check-circle text-success'} data-tooltip="Creator has completed the campaign and is owed payment."></i>
        case ACTIVATION_STATUS_CANCELED:
            return <i className={'fa fa-times-circle'} data-tooltip="Creator has canceled or dropped out of the campaign."></i>
        case ACTIVATION_STATUS_DECLINED:
            return <i className={'fa fa-times-circle-o'} data-tooltip="Creator has declined the campaign."></i>
        case ACTIVATION_STATUS_ACCEPTED:
            return <i className={'fa fa-circle text-success'} data-tooltip="Creator has accepted the campaign."></i>
        case ACTIVATION_STATUS_INVITED:
            return <i className={'fa fa-circle-o'} data-tooltip="Creator has been invited to the campaign but has not responded yet."></i>

        default:
            return <i className={'fa fa-question-circle'} data-tooltip="Creator's status is unknown."></i>


    }
};

function getPostTag(activation, remaining, translate) {
    const _t = translate || ((k, v) => v);
    const useDeadline = (activation.settings || {}).use_deadlines;
    const postDeadline = (activation.settings || {}).post_deadline_at;
    if (remaining > 0) {
        if (useDeadline && postDeadline) {
            const dueDate = makeMomentFromDate(postDeadline).format('MMM Do');
            return `${remaining}x ${_t('campaign.reqs.due', 'Due')} ${dueDate}`;
        }
        return `${remaining} ${_t('campaign.reqs.remaining', 'Remaining')}`;
    }
    return null;
}

export const STATS_FORM_FIELDS_FOR_POST_TYPES = [
    {
        type: 'number',
        name: 'youtube-viewCount',
        title: 'YouTube Views',
        classes: ['v3', 'light'],
        forTypes: ['youtube'],
    },
    {
        type: 'number',
        name: 'youtube-likeCount',
        title: 'YouTube Likes',
        classes: ['v3', 'light'],
        forTypes: ['youtube'],
    },
    {
        type: 'number',
        name: 'youtube-dislikeCount',
        title: 'YouTube Dislikes',
        classes: ['v3', 'light'],
        forTypes: ['youtube'],
    },
    {
        type: 'number',
        name: 'youtube-commentCount',
        title: 'YouTube Comments',
        classes: ['v3', 'light'],
        forTypes: ['youtube'],
    },
    {
        type: 'number',
        name: 'pageviews',
        title: 'Measured Pageviews',
        classes: ['v3', 'light'],
        forTypes: ['blog', 'post'],
    },
    {
        type: 'number',
        name: 'blog-comments',
        title: 'Blog Comments',
        classes: ['v3', 'light'],
        forTypes: ['blog', 'post'],
    },
    {
        type: 'number',
        name: 'facebook-reaction_count',
        title: 'Facebook Reactions',
        classes: ['v3', 'light'],
        forTypes: ['facebook', 'facebook-page']
    },
    {
        type: 'number',
        name: 'facebook-share_count',
        title: 'Facebook Shares',
        classes: ['v3', 'light'],
        forTypes: ['facebook', 'facebook-page']
    },
    {
        type: 'number',
        name: 'facebook-comment_count',
        title: 'Facebook Comments',
        classes: ['v3', 'light'],
        forTypes: ['facebook', 'facebook-page']
    },
    {
        type: 'number',
        name: 'instagram-likes',
        title: 'Instagram Likes',
        classes: ['v3', 'light'],
        forTypes: ['instagram', 'instagram-business'],
    },
    {
        type: 'number',
        name: 'instagram-comments',
        title: 'Instagram Comments',
        classes: ['v3', 'light'],
        forTypes: ['instagram', 'instagram-business']
    },
    {
        type: 'number',
        name: 'instagram-saves',
        title: 'Instagram Saves',
        classes: ['v3', 'light'],
        forTypes: ['instagram', 'instagram-business']
    },
    {
        type: 'number',
        name: 'instagram-impressions',
        title: 'Instagram Impressions',
        classes: ['v3', 'light'],
        forTypes: ['instagram', 'instagram-business']
    },
    {
        type: 'number',
        name: 'instagram-video-views',
        title: 'Instagram Video Views',
        classes: ['v3', 'light'],
        forTypes: ['instagram', 'instagram-business']
    },
    {
        type: 'number',
        name: 'pinterest-likes',
        title: 'Pinterest Likes',
        classes: ['v3', 'light'],
        forTypes: ['pinterest']
    },
    {
        type: 'number',
        name: 'pinterest-comments',
        title: 'Pinterest Comments',
        classes: ['v3', 'light'],
        forTypes: ['pinterest']
    },
    {
        type: 'number',
        name: 'twitter-retweets',
        title: 'Twitter Retweets',
        classes: ['v3', 'light'],
        forTypes: ['twitter'],
    },
    {
        type: 'number',
        name: 'twitter-favorites',
        title: 'Twitter Favorites',
        classes: ['v3', 'light'],
        forTypes: ['twitter'],
    },

    {
        type: 'number',
        name: 'tiktok-views',
        title: 'TikTok Views',
        classes: ['v3', 'light'],
        forTypes: ['tiktok']
    },
    {
        type: 'number',
        name: 'tiktok-likes',
        title: 'TikTok Likes',
        classes: ['v3', 'light'],
        forTypes: ['tiktok']
    },
    {
        type: 'number',
        name: 'tiktok-shares',
        title: 'TikTok Shares',
        classes: ['v3', 'light'],
        forTypes: ['tiktok']
    },
    {
        type: 'number',
        name: 'tiktok-comments',
        title: 'TikTok Comments',
        classes: ['v3', 'light'],
        forTypes: ['tiktok']
    },
    {
        type: 'number',
        name: 'tiktok-clicks',
        title: 'TikTok Clicks',
        classes: ['v3', 'light'],
        forTypes: ['tiktok'],
        help: "For ad or promoted posts."
    },


    {
        type: 'number',
        name: 'instastory-reach',
        title: 'Instastory Reach',
        classes: ['v3', 'light'],
        forTypes: ['instastory']
    },
    {
        type: 'number',
        name: 'instastory-impressions',
        title: 'Instastory Impressions',
        classes: ['v3', 'light'],
        forTypes: ['instastory'],
        help: (user) => {
            if (!user) {return null;}
            const igFollowers = parseInt(user.instagram_followers || "0", 10);
            const percentage = 0.04;
            const estViews = percentage * igFollowers;
            return "Estimated views: " + formatNumber(estViews) + ` (${percentage * 100}% of ${user.name}'s ${formatNumberK(igFollowers)} Instagram followers)`;
        },
        defaultValue: (user) => {
            if (!user) {return null;}
            const igFollowers = parseInt(user.instagram_followers || "0", 10);
            const percentage = 0.04;
            const estViews = percentage * igFollowers;
            return estViews;
        }
    },
    {
        type: 'number',
        name: 'instastory-link_clicks',
        title: 'Instastory Link Clicks',
        classes: ['v3', 'light'],
        forTypes: ['instastory']
    },
    {
        type: 'number',
        name: 'instastory-sticker_taps',
        title: 'Instastory Sticker Taps',
        classes: ['v3', 'light'],
        forTypes: ['instastory']
    },
    {
        type: 'number',
        name: 'instastory-exits',
        title: 'Instastory Exits',
        classes: ['v3', 'light'],
        forTypes: ['instastory']
    },
    {
        type: 'number',
        name: 'instastory-replies',
        title: 'Instastory Replies',
        classes: ['v3', 'light'],
        forTypes: ['instastory']
    },
    {
        type: 'number',
        name: 'instastory-shares',
        title: 'Instastory Shares',
        classes: ['v3', 'light'],
        forTypes: ['instastory']
    },
    {
        type: 'number',
        name: 'instastory-taps_forward',
        title: 'Instastory Taps Forward',
        classes: ['v3', 'light'],
        forTypes: ['instastory']
    },
    {
        type: 'number',
        name: 'instastory-taps_back',
        title: 'Instastory Taps Back',
        classes: ['v3', 'light'],
        forTypes: ['instastory']
    }
];
