import React, {Component} from 'react';
import C3Chart from 'react-c3js';
import d3 from 'd3';
import {c3ColorPattern, formatNumber, formatNumberK} from '../../../utilities';
import RechartsDonutChart from "../../Chart/RechartsDonutChart";
import {getCampaigns} from "../../../selectors/campaigns";
import {connect} from "react-redux";

class ActivationsEMVChart extends Component {

    renderChart() {

        let totalEmv = 0;
        let data = [];
        for (const stats of this.props.activationStats) {
            const stat = stats[stats.length - 1];
            const campaignId = stat.campaign_id;
            const campaign = this.props.campaigns.find(c => c.id === campaignId);
            data.push({
                name: campaign ? campaign.name : `Campaign ${campaignId}`,
                value: stat.emv
            })
            totalEmv += parseFloat(stat.emv);
        }



        return (
            <div className="widget" style={{width: '350px', height: '360px', marginTop: 0, flex: 'none'}}>
                <div className="title">
                    <div className="icon left"><i className="fa fa-dollar"></i></div>
                    <h2>
                        Media Value
                        <span className="badge badge-darkgrey">${formatNumberK(totalEmv)}</span>
                    </h2>
                </div>
                <div className="content">
                    <RechartsDonutChart
                        data={data}
                        title="Earned Media Value"
                        labelFormatter={(value) => `$${formatNumberK(value)}`}
                        labelPercent={false}
                        tooltipFormatter={(value) => `$${formatNumber(value)}`}
                        height={275}
                        showLegend={false}
                    />

                </div>
            </div>
        );

    }

    render() {

        return this.renderChart();

    }
}

const mapStateToProps = (state, props) => ({
    campaigns: getCampaigns(state, props),
})

export default connect(mapStateToProps)(ActivationsEMVChart);