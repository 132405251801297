import React, {Component} from 'react';
import PropTypes from 'prop-types';
import NavStacked from "./NavStacked";
import _find from 'lodash/find';
import Form from "./Form/Form";
import Alert from "./Alert";

class GenericSettingsForm extends Component {

    static propTypes = {
        spec: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
            fields: PropTypes.array.isRequired,
            rightIcon: PropTypes.node,
            tag: PropTypes.node,
            style: PropTypes.object,
            content: PropTypes.node,
            classes: PropTypes.array,
        })).isRequired,

        values: PropTypes.object,
        onSave: PropTypes.func,
        onFieldChange: PropTypes.func,
        isDirty: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            tab: null,
            form: {},
            isSaving: false,
            didSave: false,
        };

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
    }

    componentDidMount() {

        if (this.state.tab === null) {
            if (this.props.spec.length > 0) {
                this.handleChangeTab(this.props.spec[0].key);
            }
        }

    }


    handleClickSave() {

        if (!this.props.onSave) {
            return null;
        }

        this.setState({
            isSaving: true,
            didSave: false,
        });

        let promise = this.props.onSave(this.state.form);

        if (!promise) {
            promise = Promise.resolve();
        }

        promise.then(() => {
            this.setState({
                isSaving: false,
                didSave: true,
                form: {},
            });
            setTimeout(() => this.setState({didSave: false}), 3000);
        });



    }

    handleFieldChange(key, value) {

        this.setState({
            form: {
                ...this.state.form,
                [key]: value,
            }
        }, () => {
            if (this.props.onFieldChange) {
                this.props.onFieldChange(key, value);
            }
        });
    }



    handleChangeTab(tab) {
        this.setState({tab});
    }

    renderMenu() {
        return (
            <div
                className="generic-settings-form-menu-wrapper"
                style={{
                    float: 'left',
                    width: '33%',
                }}
            >
                <NavStacked
                    items={this.props.spec.map(item => {
                        return {
                            ...item,
                            onClick: this.handleChangeTab.bind(this, item.key),
                            isActive: this.state.tab === item.key,
                        };
                    })}
                />
            </div>
        )
    }

    renderSaveAlert() {
        const count = Object.keys(this.state.form).length;
        const isDirty = count > 0;
        const {isSaving, didSave} = this.state;

        if (isSaving) {
            return (
                <Alert
                    classes={['info', 'stick-to-top']}
                    content={'Saving, please wait...'}
                />
            );
        }

        if (didSave) {
            return (
                <Alert
                    classes={['success', 'stick-to-top']}
                    content={'Changes saved!'}
                />
            );
        }

        if (!isDirty && !this.props.isDirty) {
            return null;
        }

        return (
            <Alert
                classes={['info', 'stick-to-top']}
                content={<span>You have unsaved changes. <a role="button" onClick={this.handleClickSave} className="v3 bold">Save now.</a></span>}
            />
        );
    }

    renderCurrentPane() {
        const _spec = _find(this.props.spec, {key: this.state.tab});
        if (!_spec) {
            return null;
        }

        return (
            <Form
                fields={_spec.fields || []}
                values={{...this.props.values, ...this.state.form}}
                onFieldChange={this.handleFieldChange}
            />
        );

    }

    renderBody() {
        return (
            <div
                className="generic-settings-form-right-wrapper"
                style={{
                    float: 'left',
                    width: '67%',
                    background: '#FFF',
                }}
            >
                {this.renderSaveAlert()}
                {this.renderCurrentPane()}
            </div>
        );

    }

    render() {
        return (

            <div
                className="generic-settings-form-wrapper clearfix"
                style={{background: "#eee"}}
            >
                {this.renderMenu()}
                {this.renderBody()}
            </div>

        );
    }
}

export default GenericSettingsForm;