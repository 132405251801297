import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tooltip from "../../Common/Tooltip";
import Image from "../../Common/Image";
import {base64_png_1pxSolidEEE, getImageUrl} from "../../../utilities";

const MIN_AREA = 40000;

class ImageSlider extends Component {

    static propTypes = {
        images: PropTypes.array,
    };

    state = {
        dead: {}
    };

    handleLoad(e) {
        const { naturalWidth, naturalHeight } = e.target;
        const area = naturalWidth * naturalHeight;
        if (area < MIN_AREA) {
            this.handleError(e);
        }
    };

    handleError(e) {
        const url = (e.target || {}).src;
        this.setState({
            dead: {
                ...this.state.dead,
                [url]: true,
            }
        })
    }

    render() {


        const postImages = (this.props.images)
            .map(img => ({id: img.mongo_id, url: getImageUrl(img)}))
            .filter(image => !this.state.dead[image.url])
            .map(image => {
                const { url, id } = image;
                return <div className='post-detail-image' key={url}>
                    <a className='post-detail-image-download'
                       title='Download Image'
                       href={`/manage/do/page/images/download?id=${id}`}>
                        <Tooltip title='Download Image'>
                            <i className="fas fa-file-download" />
                        </Tooltip>
                    </a>
                    <Image
                        onLoad={this.handleLoad.bind(this)}
                        onError={this.handleError.bind(this)}
                        className="post-detail-image-tile"
                        key={url}
                        image={{
                            original_url: url,
                            type: 'image/png',
                            preview: base64_png_1pxSolidEEE
                        }}
                    />
                </div>
            });

        return postImages.length
            ? (<div className='post-detail-image-slider'>{postImages}</div>)
            : null;

    }


}

export default ImageSlider;