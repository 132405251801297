import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createActivationInteraction} from "../../../../actions/activations";
import {connect} from 'react-redux';
import Button from "../../../Common/Form/Button";
import {socialIconForType, socialNameForType} from "../../../../utilities/social";
import SubmitSocialPost from "./SubmitSocialPost";
import DOMPurify from 'dompurify';
import Alert from "../../../Common/Alert";

const translateSocialType = input => {
    switch (input) {

        case 'instagram':
            return 'Tidal\\Social\\Instagram';
        case 'twitter':
            return 'Tidal\\Social\\Twitter';
        case 'pinterest':
            return 'Tidal\\Social\\Pinterest';
        case 'facebook':
            return 'Tidal\\Social\\Facebook';
        case 'facebook-page':
            return 'Tidal\\Social\\FacebookPage';
        case 'youtube':
            return 'Tidal\\Social\\Youtube';
        case 'tiktok':
            return 'Tidal\\Social\\TikTok';
    }
};
class SubmitOtherPosts extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        campaign: PropTypes.object.isRequired,
        translate: PropTypes.func,
        refreshActivation: PropTypes.func,
        createInteraction: PropTypes.func,
    };

    state = {
        type: null,
    };

    renderGuidelineText() {
        const {activation} = this.props;
        // Don't show Compliance Guidelines here, but leave this in just in case...
        // const guidelines = (activation.settings || {}).other_post_guidelines || '';
        const guidelines = false;

        if (!guidelines || guidelines.length < 3) {

            return (
                <p className="v3 light h7 pane-info"
                   style={{marginBottom: 20}}
                >You've been asked to submit <strong>{activation.settings.num_other_posts} social posts</strong> to the
                    social network(s) of your choice. Use the buttons below to select a social network.</p>
            );

        } else {
            return (
                <p className="v3 light h7 pane-info"
                   style={{marginBottom: 20}}
                   dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(guidelines)}}
                />

            );

        }

    }

    renderSocialButtons() {
        const allowed = ['instagram', 'tiktok', 'facebook-page', 'pinterest', 'youtube'];

        return allowed.map(type => {
            const icon = socialIconForType(type);
            const name = socialNameForType(type);
            return (
                <Button
                    content={<span><i className={icon} style={{marginRight: 5}} /> Submit {name} Post</span>}
                    classes={['v3', 'medium', 'bold', 'btn-secondary']}
                    onClick={() => this.setState({type})}
                    style={{
                        display: 'block',
                        width: 300,
                        margin: '10px 0'
                    }}
                />
            );
        })
    }

    renderSelector() {
        return (
            <div className="influencer-page-template submit-social-posts">
                <div className="widget">
                    <div className="content padded">
                        <h4 className="v3 bold action-pane-title">Submit Social Posts</h4>
                        {this.renderGuidelineText()}
                        {this.renderSocialButtons()}
                    </div>
                </div>
            </div>
        )


    }

    renderSocialPane() {
        return (
            <SubmitSocialPost
                campaign={this.props.campaign}
                activation={this.props.activation}
                type={translateSocialType(this.state.type)}
                refreshActivation={this.props.refreshActivation}
                createInteraction={this.props.createInteraction}
                facebookLinkMode={false}
                translate={this.props.translate}
                titleBtn={<a
                    style={{marginLeft: 10, fontSize: 16}}
                    role="button"
                    onClick={() => this.setState({type: null})}>Go Back</a>
                }
            />
        );
    }

    isBlockedByNDA() {
        const {activation} = this.props;
        return activation.status.is_nda_required && !activation.status.is_nda_signed;
    }

    renderNDAAlert() {
        return (
            <Alert classes={['danger']} content={"You must sign the NDA before you can view this content."} />
        )
    }
    render() {

        if (this.isBlockedByNDA()) {
            return this.renderNDAAlert();
        }

        if (!this.state.type) {
            return this.renderSelector();
        } else {
            return this.renderSocialPane();
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(null, mapDispatchToProps)(SubmitOtherPosts);
