import {connect} from 'react-redux';
import {
    fetchEmailIfNeeded,
    markEmails,
    updateEmailCampaign,
    fetchEmailThreadIfNeeded,
    fetchEmail, createEmailInteraction
} from "../../actions/emailById";
import EmailRead from '../../components/Email/Read';

const mapStateToProps = (state, ownProps) => {

    const email = ownProps.email ? ownProps.email : (state.emails.items[ownProps.emailId] || {isFetching: true, id: ownProps.emailId});
    return {
        email,
        campaignsById: state.campaignsById
    };

};

const mapDispatchToProps = (dispatch) => {

    return {
        createInteraction: (id, type, context) => dispatch(createEmailInteraction(id, type, context)),
        fetchEmail: (emailId) => dispatch(fetchEmail(emailId)),
        fetchThread: (emailId) => dispatch(fetchEmailThreadIfNeeded(emailId)),
    };

};

const ConnectedEmailRead = connect(mapStateToProps, mapDispatchToProps)(EmailRead);

export default ConnectedEmailRead;