/**
 * Created by bkroger on 8/8/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {makeMomentFromDate} from "../../../utilities";

export default class Date extends Component {
    constructor (props) {
        super(props);

        this.handleDateChange = this.handleDateChange.bind(this);
        this.getValue = this.getValue.bind(this);
    }

    handleDateChange(date) {
        if (this.props.onChange && date !== null) {
            this.props.onChange(date.toISOString());
        }
    }

    getValue() {
        if (!this.props.value) return null;
        if (typeof this.props.value.isValid !== 'undefined') {
            // have a moment already
            return this.props.value;
        }

        // Have a serialized Carbon object.
        if (typeof this.props.value === 'object' && typeof this.props.value.date !== 'undefined') {
            return makeMomentFromDate(this.props.value);
        }

        if (typeof this.props.value === 'string') {
            let dateObject = moment(this.props.value);
            if (dateObject.isValid()) {
                return dateObject;
            } else {
                return null;
            }
        }
        return null;
    }

    render() {
        return <DatePicker
            className="form-control"
            fixedHeight
            selected={this.getValue()}
            onChange={this.handleDateChange}
            placeholderText={this.props.placeholder}

            {...this.props.options}

            showTimeSelect={this.props.options.showTimeSelect}
            dateFormat={this.props.options.dateFormat}
            timeIntervals={this.props.options.timeIntervals || 60}
        />;
    }
}

Date.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any
};

Date.defaultProps = {
    options: {
        showTimeSelect: false,
        dateFormat: 'MM/DD/YYYY',
        inline: false
    }
}