import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createActivationInteraction} from "../../actions/activations";
import {fetchActivation} from "../../actions/campaigns";
import PropTypes from 'prop-types';
import Alert from '../Common/Alert';
import Button from '../Common/Form/Button';

class DeleteActivationForm extends Component {

    static propTypes = {
        activation: PropTypes.object.isRequired,
        onComplete: PropTypes.func,
        createInteraction: PropTypes.func,
        fetchActivation: PropTypes.func,
    };

    state = {
        isUpdating: false,
        didUpdate: false,
        error: false,
    };

    handleButtonClick() {

        const {activation, onComplete, createInteraction} = this.props;

        this.setState({isUpdating: true});

        return createInteraction(activation.id, 'DeleteActivation', [])
            .catch(err => this.setState({isUpdating: false, didUpdate: false, error: true}))
            .then(() => this.setState({isUpdating: false, didUpdate: true}))
            .then(() => {
                if (onComplete) {
                    onComplete();
                }
            });

    }

    renderAlert() {

        const {activation} = this.props;
        const {didUpdate, isUpdating, error} = this.state;

        if (didUpdate) {
            return <Alert classes={['success']} content={`${activation.user.name} was removed from the campaign. Please refresh the page.`} />
        }

        if (isUpdating) {
            return <Alert classes={['info']} content={`Removing ${activation.user.name}...`} />
        }

        if (error) {
            return <Alert classes={['danger']} content={`There was a problem removing ${activation.user.name}'s activation.`} />
        }

        return <Alert classes={['info']} content={`Remove ${activation.user.name} from this campaign. They will not be notified. This action is not reversible.`} /> ;

    }

    renderButtons() {
        const {isUpdating} = this.state;

        const style = {
            textAlign: 'center',
            padding: 20
        };

        return (
            <div style={style}>
                <Button
                    content={'Delete Activation'}
                    classes={['v3', 'large', 'btn-primary', (isUpdating ? 'disabled' : '')]}
                    onClick={this.handleButtonClick.bind(this)}
                    style={{background: "#FF0000"}}
                />
            </div>
        );

    }

    render() {

        return (
            <div className="activation-status-change-form">
                {this.renderAlert()}
                {this.renderButtons()}
            </div>
        );

    }
}


const mapDispatchToProps = dispatch => {
    return {
        createInteraction: (id, type, context) => dispatch(createActivationInteraction(id, type, context)),
        fetchActivation: (id) => dispatch(fetchActivation(id)),
    };
};

export default connect(null, mapDispatchToProps)(DeleteActivationForm);
