
import * as ActionTypes from "../actions/types";

const AUTH_DEFAULT_STATE = {
    isLoggingIn: false,
    isLoggedIn: false,
    isSigningUp: false,
    error: null,
    userId: null,
    token: null,
    channelUrl: null,
    sharedPassword: null
};

export const auth = (state = AUTH_DEFAULT_STATE, action) => {

    switch (action.type) {

        case ActionTypes.AUTH_SET_SHARED_PASSWORD:
            return {...state, sharedPassword: action.data};

        case ActionTypes.AUTH_LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                userId: null,
                token: null,
            };

        case ActionTypes.AUTH_SET_STATE:
            return {
                ...state,
                ...action.data
            };
        case ActionTypes.AUTH_REQUEST_LOGIN:
            return {
                ...state, 
                isLoggingIn: true
            };
        case ActionTypes.AUTH_LOGIN_SUCCESS:
            return {
                ...state, 
                isLoggingIn: false, 
                isLoggedIn: true, 
                token: action.data.token, 
                userId: action.data.user_id,
                error: null
            };
        case ActionTypes.AUTH_LOGIN_FAIL:
            return {
                ...state, 
                error: action.meta.error, 
                isLoggingIn: false, 
                isLoggedIn: false,
                token: null,
                userId: null,
            };

        case ActionTypes.AUTH_REQUEST_SIGNUP:
            return {
                ...state,
                isSigningUp: true
            };
        case ActionTypes.AUTH_SIGNUP_SUCCESS:
            return {
                ...state,
                isSigningUp: false,
                isLoggedIn: true,
                token: action.data.token,
                userId: action.data.user_id,
                error: null
            };
        case ActionTypes.AUTH_SIGNUP_FAIL:
            return {
                ...state,
                error: action.meta.error,
                isSigningUp: false,
                isLoggedIn: false
            };
        case ActionTypes.PRESENCE_SET_CHANNEL_URL:
            return {
                ...state,
                channelUrl: action.url
            };
            
        default: return state;
    }

};
