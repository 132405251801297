/**
 * Created by bkanber on 7/28/17.
 */

import React, {Component, PureComponent} from 'react';
import C3Chart from 'react-c3js';
import d3 from 'd3';
import {c3ColorPattern, formatNumber} from '../../utilities';
import moment from 'moment';

export default class GenericTimeseriesAreaChart extends PureComponent {

    getCleanName(key) {
        return this.props.fieldMap[key];
    }

    getStats() {

        let stats = [...(this.props.items || [])];
        // stats = stats.sort((a, b) => (a.id > b.id ? 1 : -1));
        // find out how many records at the beginning have 0 values
        let numZeroStats = 0;
        for (let stat of stats) {

            if (this.props.titleField && !stat[this.props.titleField]) {
                numZeroStats++;
            } else {
                break;
            }
        }
        // return slicing off unnecessary zeroes
        return numZeroStats > 1 ? stats.slice(numZeroStats-1) : stats;
    }

    getMostRecentStat() {
        const stats = this.getStats();
        if (stats.length === 0) return null;
        return stats[stats.length - 1];
    }

    getTotalFor(key) {
        const stat = this.getMostRecentStat();
        if (!stat) return null;
        return stat[key];
    }

    getTitleTotal() {
        if (!this.props.titleField) return null;
        return this.getTotalFor(this.props.titleField);
    }

    getNiceNameMap() {
        let map = {};

        for (let key in this.props.fieldMap) {
            let total = this.getTotalFor(key);
            if ((this.props.hideZeroSeries === false) || (total && total !== 0)) {
                let niceName = this.getCleanName(key);

                if (this.props.showLegendTotals) {
                    niceName = niceName + ' (total ' + formatNumber(total) + ')';
                }

                map[key] = niceName;
            }
        }

        return map;

    }

    /**
     * Translates data color keys to nicenames for data color definition
     * @returns {*}
     */
    getDataColors() {
        if (!this.props.dataColors) {
            return null;
        }

        const niceNameMap = this.getNiceNameMap();
        let out = {};

        for (let key in this.props.dataColors) {
            let niceName = niceNameMap[key];
            out[niceName] = this.props.dataColors[key];
        }

        return out;
    }

    /**
     * Translates type keys to nicenames for type definition
     * @returns {*}
     */
    getTypes() {
        if (!this.props.chartTypes) {
            return null;
        }

        const niceNameMap = this.getNiceNameMap();
        let out = {};

        for (let key in this.props.chartTypes) {
            let niceName = niceNameMap[key];
            out[niceName] = this.props.chartTypes[key];
        }

        return out;
    }

    /**
     * Translates data axes keys to nicenames for data axes definition
     * @returns {*}
     */
    getDataAxes() {
        if (!this.props.dataAxes) {
            return null;
        }

        const niceNameMap = this.getNiceNameMap();
        let out = {};

        for (let key in this.props.dataAxes) {
            let niceName = niceNameMap[key];
            out[niceName] = this.props.dataAxes[key];
        }

        return out;
    }

    /**
     * Translates group keys to nicenames for group definition
     * @returns {*}
     */
    getGroups() {

        const niceNameMap = this.getNiceNameMap();

        if (this.props.groups) {

            let groups = this.props.groups;
            for (let groupNo in groups) {
                let group = groups[groupNo];
                for (let index in group) {
                    let key = group[index];
                    let niceName = niceNameMap[key];
                    groups[groupNo][index] = niceName;
                }
            }

            return groups;
        }

        // Default
        return [ Object.values(niceNameMap) ];
    }

    renderChart() {
        const chartType = this.props.chartType;

        const stats = this.getStats();
        const niceNameMap = this.getNiceNameMap();

        if (stats.length === 0) return null;

        const json = stats.map(stat => {
            for (let key in this.props.fieldMap) {

                if (typeof stat.date === 'undefined') {
                    if (stat.ts) {
                        stat.date = moment(stat.ts).format('YYYY-MM-DD');
                    } else {
                        stat.date = moment(stat.created_at).format('YYYY-MM-DD');
                    }
                }

                let niceName = niceNameMap[key];
                if (typeof niceName !== 'undefined') {
                    let value = stat[key] || 0;
                    stat[niceName] = value;
                }
            }
            return stat;
        });


        let data = {
            json,
            keys: {
                x: 'date',
                value: Object.values(niceNameMap)
            },
            groups: this.getGroups(),
            type: chartType,
            order: 'desc',
            unload: true,
        };

        const types = this.getTypes();
        const dataAxes = this.getDataAxes();
        const dataColors = this.getDataColors();

        if (types) {
            data.types = types;
        }

        if (dataAxes) {
            data.axes = dataAxes;
        }

        if (dataColors) {
            data.colors = dataColors;
        }

        const axis = this.props.axis;
        const tooltip = {
            format: {
                value: this.props.tooltipValueFormatter,
                title: (x) => {
                    const date = axis.x.type === 'timeseries' ? x : json[x].date;
                    return moment(date).format('MMM Do, YYYY')
                }
            }
        };

        return (
            <C3Chart
                unloadBeforeLoad={true}
                data={data}
                color={c3ColorPattern}
                point={this.props.point}
                axis={axis}
                tooltip={tooltip}
            />
        );

    }

    render() {
        return this.renderChart() || <div className="alert tidal-alert alert-grey">Loading stats...</div> ;
    }

}

GenericTimeseriesAreaChart.defaultProps = {
    items: [],
    fieldMap: {},
    titleField: null,
    chartType: 'area',
    showLegendTotals: true,
    hideZeroSeries: true,
    groups: null,
    chartTypes: null,
    dataAxes: null,
    dataColors: null,
    point: {show: false},
    tooltipValueFormatter: (value, ratio, id) => d3.format(',')(value),
    axis: {
        x: {
            type: 'timeseries',
            tick: {
                fit: true,
                format: (x) => ''
            }
        },
        y: {
            tick: {
                format: d3.format(',')
            }
        }
    }
};
